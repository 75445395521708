/**
 * 全角数字を半角に変換
 * @param {*} str
 * @returns
 */
function change(str) {
  return String(str).replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (x) {
    return String.fromCharCode(x.charCodeAt(0) - 0xFEE0);
  });

}

/**
 * exam_typeの頭１文字を元に再計算
 * 小数点以下のケタ数を指定して表示する
 * @param {*} exam_type
 * @param {*} value
 * @returns
 */
const recalculate = (exam_type, value) => {
  if (value === '') return ''
  if (!value.match(/^[0-9]+$/)) {
    value = change(value)
  }
  let returnValue
  switch (exam_type) {
    case 'N':
      // 整数値で表示 小数点以下四捨五入　
      returnValue = Math.round(value)
      break
    case 'N1':
      // 小数点第1位まで表示　四捨五入
      returnValue = Math.round(value * 10) / 10
      returnValue = returnValue.toFixed(1)
      break
    case 'N2':
      // 小数点第2位まで表示　四捨五入
      returnValue = Math.round(value * 100) / 100
      returnValue = returnValue.toFixed(2)
      break
    case 'N3':
      // 小数点第3位まで表示　四捨五入
      returnValue = Math.round(value * 1000) / 1000
      returnValue = returnValue.toFixed(3)
      break
    case 'N4':
      // 小数点第4位まで表示　四捨五入
      returnValue = Math.round(value * 10000) / 10000
      returnValue = returnValue.toFixed(4)
      break
    default:
      returnValue = value
  }

  return typeof value === 'number' && isFinite(value) ? returnValue : value
}
export default recalculate
