import axios from "configs/axios";

const APP_LIST = {
  xmlmedicalexamitemref: "/api/data-range-check/xml-medical-exam-item-ref/index",
};

const XmlMedicalExamItemRefService = {
  async index(params) {
    return axios.get(APP_LIST.xmlmedicalexamitemref, params);
  },
};

export default XmlMedicalExamItemRefService;
