import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import XmlMedicalExamItemMasterSaveAction from 'redux/SpecificInsureMaintenance/XmlMedicalExamItemMaster/XmlMedicalExamItemMaster.action.js'
import { Card, Form, Table, Button, Modal, Space, Row, Col, Input, Menu, Dropdown, message, Select, InputNumber, TimePicker } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import WS1313006_XmlMedicalExamItemRef from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313006_XmlMedicalExamItemRef.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from "moment";


//Form labelの幅
const smGrid = {
  labelCol: { style: { width: '150px' } }
}


class WS1313012_DataRangeCheckChild extends React.Component {
  formRef = React.createRef();

  // document.title = 'JLAC10コードマスタ編集画面';

  //親画面の情報を取ってくる
  constructor(props) {
    super(props);
    //stateにdataSourceを保持　初期値[]を設定
    //stateに保持させるときは現画面で加工したいとき　propsでとってくるときは、加工せずそのままのデータが欲しいとき
    this.state = {
      dataSource: [],
      filteredData: [],
      //子画面を開くのに必要
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  componentDidMount() {
    this.formRef.current.setFieldsValue({
      id: this.props.id ?? '',
      date_of_adoption_history_on: this.props.date_of_adoption_history_on ?? '',
      DateAdoptionChars: this.props.DateAdoptionChars ?? '',
      item_code_jlac10_15: this.props.item_code_jlac10_15 ?? '',
      result_identification_jlac10_2: this.props.result_identification_jlac10_2 ?? '',
      division_number: this.props.division_number ?? '',
      order_number: this.props.order_number ?? '',
      input_min_value: this.props.input_min_value ?? '',
      input_max_value: this.props.xml_display_name ?? '',
      character_input_min_value: this.props.data_type ?? '',
      character_input_max_value: this.props.xml_pattern ?? '',
      max_bytes_or_format: this.props.max_bytes_or_format ?? '',
      xml_data_type: this.props.xml_data_type ?? '',
      option_remark: this.props.display_units ?? '',
    })
  }


  searchXmlMedicalExamItemRef = (index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '400px',
        component: (
          <WS1313006_XmlMedicalExamItemRef
            onFinishScreen={(output) => {
              this.closeModal();
              let data = [...this.state.dataSource];
              data[index].item_code_jlac10_15 = output;
              this.setState({ dataSource: data });
            }}
          />
        ),
      },
    });
  }


  onSave(params) {
    // InsureGuideCourseAction.addData(params.itemChange)
    //   .then(res => {
    //     this.loadMainSource();
    //   })
  }

  /**
   * 削除
   * @param {*} record
   */
  delete = (record) => {
    Modal.confirm({
      content: '削除してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        // const params = {
        //   id: record.id
        // }
        // PrintParamInputAction.deletePrintParamMaintainAction(params)
        //   .then(res => {
        //     message.success('削除が完了しました')
        //     this.index()
        //   })
      }
    })
  }


  render() {
    return (
      // ファイル名を小文字にして-でつなげたもの
      <div
        className="data-range-check-child"
      // style={{ width: '100%' }}
      >
        <Card title={'データ範囲チェック [' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'} stye={{ position: 'relative' }}>
          <Form
            ref={this.formRef}
            autoComplete="off"
          >
            <div className="box_container">
              <Form.Item
                name="DateAdoptionChars"
                label="採用日"
                {...smGrid}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="item_code_jlac10_15"
                label="項目コード"
                {...smGrid}
              >
                <Input.Search
                  readOnly
                  onSearch={() => {
                    this.searchXmlMedicalExamItemRef()
                  }}
                />
              </Form.Item>

              <Form.Item
                name="result_identification_jlac10_2"
                label="結果識別"
                {...smGrid}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="input_min_value"
                label="入力最小値"
                {...smGrid}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="input_max_value"
                label="入力最大値"
                {...smGrid}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="character_input_min_value"
                label="文字入力最小値"
                {...smGrid}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="character_input_max_value"
                label="文字入力最大値"
                {...smGrid}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="option_remark"
                label="オプション"
                {...smGrid}
              >
                <Input />
              </Form.Item>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              danger
              icon={<DeleteOutlined />}
              style={{ display: (this.props.newFlag) ? 'none' : '' }}
              onClick={() => {
                // 確認モーダルを表示してから、削除
                Modal.confirm({
                  content: `削除を行いますか?`,
                  okText: 'は　い',
                  cancelText: 'いいえ',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              // onClick={() => { this.Item_F12() }}
              // onClick={() => { this.onSave() }}
              icon={<SaveOutlined />}
              disabled={this.state.disabledSaveBtn}
              onClick={() => { this.onSave() }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>

        </Card>
      </div>
    )
  }


}
//以下画面の新規作成に必要な行
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

//ファイル名に書き換える
export default connect(mapStateToProps, mapDispatchToProps)(WS1313012_DataRangeCheckChild);
