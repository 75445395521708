import DataImportService from "services/DataMigration/DataImportService";
import { message } from "antd";

const DataImportAction = {
  getImportSettings(params) {
    return DataImportService.getImportSettings(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  saveImportSettings(params) {
    return DataImportService.saveImportSettings(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  importFileContents(params) {
    return DataImportService.importFileContents(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }
};

export default DataImportAction;