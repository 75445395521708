import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { SearchOutlined, MenuOutlined, MoreOutlined } from '@ant-design/icons'
import { InputNumber, Card, Form, Input, Radio, Select, Button, Table, Menu, Tooltip, Modal, Dropdown, message, } from 'antd'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import WS0956001_PaymentCorrectSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS0956001_PaymentCorrectSub'
import WS0963001_InvoiceMaintain from '../V4BL0031000_DepositWithdrawalInquiry/WS0963001_InvoiceMaintain'
import WS2631001_PaymentProcessSub from 'pages/BL_AccountingBusiness/V5BL0002000_OrganizationsPayment/WS2631001_PaymentProcessSub.jsx'
import WS2631002_TransferPaymentProcessSub from 'pages/BL_AccountingBusiness/V5BL0002000_OrganizationsPayment/WS2631002_TransferPaymentProcessSub.jsx'
import OrganizationsPaymentAction from 'redux/AccountingBusiness/OrganizationsPayment/OrganizationsPayment.actions'
import WS0958001_ReceiptMaintainIssue from './WS0958001_ReceiptMaintainIssue'
import WS2632001_DispensingProcessSub from './WS2632001_DispensingProcessSub'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import moment from 'moment'
import { number_format } from 'helpers/CommonHelpers'

const dateFormat = 'YYYY/MM/DD'
class WS2630001_OrganizationsPayment extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '団体入金'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedKey: [],
      isloadding: false,
      checkAll: false,
      DisplayItemList: '111110001111111',
      selectedRowKeys: [],
      selectedRows: [],
    }
    //this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    // this.getScreen()
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ])
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }
  getScreen() {
    OrganizationsPaymentAction.getScreenAction()
      .then((res) => {
        this.setState({
          dataSource: res,
          selectedKey: res,
          selectedRowKeys: [],
          selectedRows: []
        })
      })
      .finally(() => this.setState({ isloadding: false }))
  }

  async onCheckAll(value) {
    this.setState({ isloadding: true })
    let record = this.state.dataSource
    let arraySelect = []
    if (value) {
      record.map((x) => {
        x.W1_target = true
        arraySelect.push(x.id)
      })
    } else {
      record.map((x) => (x.checked = false))
    }
    await this.setState({
      dataSource: record,
      selectedKey: arraySelect,
    })
    const data = {
      StsSelectAll: value ? 1 : 0,
    }
    OrganizationsPaymentAction.selectAllAction(data)
      .then((res) => {
        this.getScreen()
        if (arraySelect.length < 1) {
          this.setFormFieldValue('checkAll', false)
        } else {
          this.setFormFieldValue('checkAll', true)
        }
      })
      .finally(() => this.setState({ isloadding: false }))
  }

  async onChangeCheckedRecord(index, value, record) {
    this.setState({ isloadding: true })
    let records = this.state.dataSource
    records[index].W1_target = value
    let arraySelect = this.state.selectedKey
    if (value) {
      if (arraySelect.indexOf(records[index].id) < 0) {
        arraySelect.push(records[index].id)
        this.setFormFieldValue('checkAll', true)
      }
    } else {
      this.setFormFieldValue('checkAll', false)
      // console.log('vào')
      // arraySelect.forEach((e) => {
      //   if (e.W1_target === true) {
      //     this.setFormFieldValue('checkAll', false)
      //   }else{
      //     this.setFormFieldValue('checkAll', true)
      //   }
      // })

      // if (arraySelect.indexOf(records[index].id) > -1) {
      //   arraySelect.splice(arraySelect.indexOf(records[index].id), 1)
      //   if (arraySelect.length < 1) {
      //     this.setFormFieldValue('checkAll', false)
      //   } else {
      //     this.setFormFieldValue('checkAll', true)
      //   }
      // }
    }
    this.setState({
      dataSource: records,
      selectedKey: arraySelect,
    })

    const data = {
      id: this.state.dataSource[index].id,
      W1_target: this.state.dataSource[index].W1_target ? 1 : 0,
    }
    OrganizationsPaymentAction.selectOneAction(data)
      .then((res) => {
        this.getScreen()
      })
      .finally(() => this.setState({ isloadding: false }))
  }

  onSearchOverall() {
    this.setState({ isloadding: true })
    let ClaimYearsCharF = this.formRef.current?.getFieldValue('ClaimYearsCharF')
    let ClaimYearsCharT = this.formRef.current?.getFieldValue('ClaimYearsCharT')

    ClaimYearsCharF = ClaimYearsCharF ? moment(ClaimYearsCharF).format('YYYY/MM') + '/01' : null
    ClaimYearsCharT = ClaimYearsCharT ? moment(ClaimYearsCharT).format('YYYY/MM') + '/01' : null

    let values = {
      ClaimYearsCharF: ClaimYearsCharF,
      ClaimYearsCharT: ClaimYearsCharT,
      ClaimNum: this.formRef.current?.getFieldValue('ClaimNum'),
      PayRemaining: this.formRef.current?.getFieldValue('PayRemaining'),
      ClaimIdentify: this.formRef.current?.getFieldValue('ClaimIdentify'),
      InsurerNum: this.formRef.current?.getFieldValue('InsurerNum'),
      OfficeNum: this.formRef.current?.getFieldValue('OfficeNum'),
      Search: this.formRef.current?.getFieldValue('Search'),
    }

    OrganizationsPaymentAction.DisplayBtnAction(values)
      .then((res) => {
        this.getScreen()
        this.setState({
          selectedKey: [],
        })
        this.setFormFieldValue('checkAll', false)
      })
      .finally(() => this.setState({ isloadding: false }))
  }

  f10() {
    let params = {
      selectedRows: this.state.selectedRows
    }
    let DepositAmount = 0
    // let OutAmount = 0
    
    this.state.selectedRows.map(row => {
      DepositAmount += row.deposit_price
      // OutAmount += row.billing_price
    })

    OrganizationsPaymentAction.f10Action(params)
      .then((res) => {
        if (res.message === 'WS2632001_DispensingProcessSub') {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 450,
              component: (
                <WS2632001_DispensingProcessSub
                  DepositAmount={DepositAmount}
                  OutAmount={DepositAmount}
                  onFinishScreen={(output) => {
                    this.closeModal()
                    this.getScreen()

                  }}
                />
              ),
            },
          })
        }
      })
      .catch((err) => {
        Modal.error({
          width: 380,
          title: '出金対象を一覧から選択してください',
        })
      })
      .finally(() => { })
  }
  PaymentProcess() {
    let params = {
      selectedRows: this.state.selectedRows
    }
    OrganizationsPaymentAction.f11Action(params)
      .then((res) => {
        if (res.message === 'WS2631001_PaymentProcessSub') {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 450,
              component: (
                <WS2631001_PaymentProcessSub
                  onFinishScreen={(output) => {
                    this.closeModal()
                    this.getScreen()
                  }}
                />
              ),
            },
          })
        }
      })
      .catch((err) => {
        Modal.error({
          width: 380,
          title: '入金対象を一覧から選択してください',
        })
      })
      .finally(() => { })
  }

  TransferPaymentProcess() {
    let params = {
      selectedRows: this.state.selectedRows
    }
    OrganizationsPaymentAction.f11Action(params)
      .then((res) => {
        if (res.message === 'WS2631001_PaymentProcessSub') {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 450,
              component: (
                <WS2631002_TransferPaymentProcessSub
                  Li_Window={1}
                  onFinishScreen={(output) => {
                    this.closeModal()
                    this.getScreen()
                  }}
                />
              ),
            },
          })
        }
      })
      .catch((err) => {
        Modal.error({
          width: 380,
          title: '入金対象を一覧から選択してください',
        })
      })
      .finally(() => { })
  }

  showReceiptMaintainIssue = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0958001_ReceiptMaintainIssue
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showInsurerInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0246001_InsurerInfoSearchQuery
            Li_insurerSearch={this.formRef.current?.getFieldValue('InsurerNum')}
            onFinishScreen={(output) => {
              this.setState({
                InsurerNum: output.Lo_InsurerCode,
                InsurerName: output.Lo_Name
              })
              this.formRef.current.setFieldsValue({
                InsurerNum: output.Lo_InsurerCode,
                InsurerName: output.Lo_Name
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showOfficeInfoRetrievalQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeNum')}
            onFinishScreen={(output) => {
              this.setState({
                OfficeNum: output.Lio_OfficeCode,
                OfficeName: output.Lo_Kanji_Name
              })
              this.formRef.current?.setFieldsValue({
                OfficeNum: output.Lio_OfficeCode,
                OfficeName: output.Lo_Kanji_Name
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    var today =
      new Date().getFullYear() +
      '/' +
      ('0' + (new Date().getMonth() + 1)).slice(-2) +
      '/' +
      ('0' + new Date().getDate()).slice(-2)
    return (
      <div className='organizations-payment'>
        <Card title='団体入金' className='mb-2'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.showReceiptMaintainIssue}>
                      領収再発行
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Form
            ref={this.formRef}
            //onFinish={this.onFinish}
            initialValues={{
              ClaimYearsCharF: moment(today),
              ClaimYearsCharT: moment(today),
              ClaimIdentify: 0,
              PayRemaining: 2,
            }}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <div className='box_inner_horizontal' style={{ marginRight: '10px' }}>
                      <Form.Item label='請求年月' name='ClaimYearsCharF'>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='ClaimYearsCharT'>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item name='ClaimNum' label='請求番号'>
                      <InputNumber
                        className='input-search-size-10'
                      // style={{ width: 100 }}
                      />
                    </Form.Item>
                    <Form.Item name='PayRemaining' label='　入　金'>
                      <Radio.Group>
                        <Radio value={0}>全て</Radio>
                        <Radio value={1}>済</Radio>
                        <Radio value={2}>未</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name='ClaimIdentify' label='　請求先'>
                      <Select
                        style={{ width: 120 }}
                      >
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={4}>保険者</Select.Option>
                        <Select.Option value={5}>事業所</Select.Option>
                        <Select.Option value={6}>他団体</Select.Option>
                        <Select.Option value={9}>個人未収</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='box_search_inner'>

                    <div className='box_inner_horizontal'>
                      <Form.Item name='InsurerNum' label='保険者'>
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-10'
                          style={{ textAlign: 'right' }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                InsurerName: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.showInsurerInfoSearchQuery()
                            }
                          }}
                          onPressEnter={() => {
                            this.showInsurerInfoSearchQuery()
                          }}
                          onBlur={(e) => {
                            let value = parseInt(e.target.value.slice(0, 10))
                            this.formRef.current?.setFieldsValue({
                              InsurerNum: value && value > 0 ? value : null
                            })
                            if (!value) {
                              this.formRef.current.setFieldsValue({
                                InsurerName: ''
                              })
                              this.forceUpdate()
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='InsurerName'>
                        <div>{this.formRef.current?.getFieldValue('InsurerName')}</div>
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item name='OfficeNum' label='事業所'>
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-10'
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                OfficeName: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.showOfficeInfoRetrievalQuery()
                            }
                          }}
                          onPressEnter={() => {
                            this.showOfficeInfoRetrievalQuery()
                          }}
                          onBlur={(e) => {
                            let value = e.target.value
                            if (!value) {
                              this.formRef.current.setFieldsValue({
                                OfficeName: ''
                              })
                              this.forceUpdate()
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='OfficeName'>
                        <div>{this.formRef.current?.getFieldValue('OfficeName')}</div>
                      </Form.Item>
                    </div>

                    <Form.Item name='Search' label='検　索'>
                      <Input type='text' />
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.onSearchOverall()}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Table
                size='small'
                dataSource={this.state.dataSource}
                pagination={false}
                bordered
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(100) }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected, selectedRows, nativeEvent) => {
                    let arrTemp = [...this.state.selectedRowKeys]
                    let arrTempRecord = [...this.state.selectedRows]
                    let idx = arrTemp.indexOf(record.id)
                    if (idx === -1) {
                      arrTemp.push(record.id)
                      arrTempRecord.push(record)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      })
                    } else {
                      arrTemp.splice(idx, 1)
                      arrTempRecord.splice(idx, 1)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      })
                    }
                    // this.selectRecord(record.id, selected)
                  },

                  onSelectAll: (selected, selectedRows, changeRows) => {
                    if (selected) {
                      let arrTemp = this.state.dataSource.map(item => item.id)
                      let arrTempRecord = this.state.dataSource
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord,
                        isSelectAll: true
                      })
                    } else {
                      this.setState({
                        selectedRowKeys: [],
                        selectedRows: [],
                        isSelectAll: false
                      })
                    }
                    // this.selectAll(selected)
                  },
                }}
              >
                <Table.Column
                  title='請求日'
                  dataIndex='billing_date_on'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{ textAlign: 'center' }}
                      >
                        {value === '0000-00-00'
                          ? ''
                          : moment(value).format('YYYY/MM/DD(ddd)')}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='請求先'
                  dataIndex='expression_5'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <span
                      >
                        {value}
                      </span>
                    )
                  }}
                />
                <Table.Column
                  title='請求番号'
                  dataIndex='invoice_number'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {value}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='請求発行日'
                  dataIndex='invoice_date_on'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{ textAlign: 'center' }}
                      >
                        {value === '0000-00-00'
                          ? ''
                          : value.replaceAll('-', '/')}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='宛　名'
                  dataIndex='according_to_destination_name'
                  className='column-size-60'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        {value}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='請求金額'
                  dataIndex='billing_price'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {value === 0 || !value ? '' : number_format(value)}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='入金額'
                  dataIndex='deposit_price'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {value === 0 || !value ? '' : number_format(value)}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='入金日'
                  dataIndex='payment_day_on'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{ textAlign: 'center' }}
                      >
                        {value === '0000-00-00'
                          ? ''
                          : value.replaceAll('-', '/')}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='方法'
                  dataIndex='expression_12'
                  className='column-size-5'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{ textAlign: 'center' }}
                      >
                        {value}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='受領金額'
                  dataIndex='amount_received'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {value === 0 || !value ? '' : number_format(value)}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title='手数料'
                  dataIndex='transfer_fee'
                  className='column-size-10'
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {value === 0 || !value ? '' : number_format(value)}
                      </div>
                    )
                  }}
                />
                <Table.Column
                  fixed='right'
                  className='column-size-2'
                  render={(value, record) => {
                    return (
                      <Button
                        icon={<MoreOutlined />}
                        size='small'
                        style={{ textAlign: 'center' }}
                        onClick={() => {
                          if (record.deposit_price == 0 || !record.deposit_price) {
                            message.error('未入金の請求です。')
                          } else {
                            this.setState({
                              ...this.state,
                              childModal: {
                                width: '70%',
                                visible: true,
                                component: (
                                  <WS0956001_PaymentCorrectSub
                                    Li_BillingManageNum={
                                      record?.W1_billing_manage_num
                                    }
                                    Li_Identify={record?.W1_identify}
                                    Li_ModificationLevel={
                                      record?.Li_ModificationLevel
                                    }
                                    onFinishScreen={() => {
                                      // this.closeModal()
                                      this.onSearchOverall()
                                    }}
                                  />
                                ),
                              },
                            })
                          }
                        }}
                      />
                    )
                  }}
                />
              </Table>

            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.f10()
                }}
              >
                出金
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.PaymentProcess()
                }}
              >
                窓口入金
              </Button>

              <Button
                type='primary'
                onClick={() => {
                  this.TransferPaymentProcess()
                }}
              >
                振込入金
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2630001_OrganizationsPayment)
