import axios from 'configs/axios';

const apiPaths = {
  findingsMove: '/api/radio-graphy-inspect-maintain/radio-graphy-site-findings-migration/findings-move',
};

const SiteFindingsMigrationService = {
  async findingsMove(params) {
    return axios.post(apiPaths.findingsMove, params);
  },
};

export default SiteFindingsMigrationService;