import XmlMedicalExamItemMasterService from "services/SpecificInsureMaintenance/XmlMedicalExamItemMaster/XmlMedicalExamItemMasterService"
import { message } from "antd";

const XmlMedicalExamItemMasterSaveAction = {
  saveXmlMedicalExamItemDataInput(data) {
    return XmlMedicalExamItemMasterService.saveXmlMedicalExamItemDataInput(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  xmlMedicalExamItemDataInput(data) {
    return XmlMedicalExamItemMasterService.xmlMedicalExamItemDataInput(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  deletexmlMedicalExamItemDataInput(data) {
    return XmlMedicalExamItemMasterService.deletexmlMedicalExamItemDataInput(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  getscreendata(data) {
    return XmlMedicalExamItemMasterService.getscreendata(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  dataRangeCheckIndex(data) {
    return XmlMedicalExamItemMasterService.dataRangeCheckIndex(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

}

export default XmlMedicalExamItemMasterSaveAction;
