import React from "react";
import { connect } from "react-redux";

import { Card, Table, Form, Select, message, Modal, Dropdown, Button, Menu } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import DestinationSubAction from "redux/AdvancePreparation/DocumentManageMaintain/DestinationSub.action";
import WS1546002_DestinationSubEdit from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1546002_DestinationSubEdit.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";

//const { Option } = Select;

const SpecifiedValueList = [{
  value: '1',
  label: '事業所'
},
{
  value: '2',
  label: '事業所(本店)'
},
{
  value: '3',
  label: '個人住所1'
},
{
  value: '4',
  label: '個人住所2'
},
{
  value: '5',
  label: '個人住所3'
},
{
  value: '9',
  label: '印字なし'
}]

class WS1546001_DestinationSub extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = '送付先SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoadingTable: false,
      selectedRowKeys: [],
      rowSelected: [],
      indexTable: 0,
      old_document_management_number: null,
    };

    this.handleAddRowTable = this.handleAddRowTable.bind(this);
  }

  componentDidMount() {
    this.getScreenData();
  }

  getScreenData() {
    this.setState({ isLoadingTable: true })
    DestinationSubAction.getScreenData()
      .then((res) => {
        let data = [...res]

        data.forEach((item, index) => {
          const obj = SpecifiedValueList.find(x => x.value === item.SpecifiedValue)
          item.label = obj.label ?? ''
        })

        this.setState({
          dataSource: data ? data : [],
          isLoadingTable: false,

          rowSelected: data && data.length > 0 ? [data[0]] : [],
          selectedRowKeys: data && data.length > 0 ? [data[0].id] : [],
          indexTable: 0,
          old_document_management_number: data && data.length > 0 ? data[0].document_management_number : null,
        })
      })
      .finally(() => this.setState({ isLoadingTable: false }))
  }

  createAndUpdateData(index) {
    let params = { ...this.state.dataSource[index] }
    if (this.checkDuplicateCode()) {
      message.warning('送付先管理番号 複製 !!');
    } else {
      DestinationSubAction.createAndUpdateData(params)
        .then((res) => {
          message.success('更新しました。')
          this.getScreenData();
        })
    }
  }

  deleteData(id) {
    let params = {
      id: id
    }
    Modal.confirm({
      width: "250px",
      content: "削除を実行しますか ?",
      okText: "は　い",
      cancelText: "いいえ",
      onOk: () => {
        DestinationSubAction.deleteData(params)
          .then(res => {
            message.success('正常に削除されました !');
            this.getScreenData();
          })
          .catch((err) => {
            const res = err.response;
            if (!res || !res.data || !res.data.message) {
              message.error("エラーが発生しました");
              return;
            }
            message.error(res.data.message);
          });
      }
    })
  }

  checkDuplicateCode() {
    let lstData = [...this.state.dataSource];
    const uniqueValues = new Set(lstData.map(v => v.document_management_number));

    if (uniqueValues.size < lstData.length) {
      return true;
    } return false;
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };


  // check id null
  checkIdTemp(id) {
    if (id === '') {
      return true
    }
    return false;
  }

  checkAddItem() {
    if (this.state.dataSource.length > 0) {
      let index = this.state.dataSource.findIndex(x => !x.document_management_number);
      if (index === -1) {
        return false;
      }
      return true
    }
  }

  checkDisabledBtnAdd() {
    if (this.state.rowSelected.length > 0) {
      if (this.checkAddItem() || this.checkIdTemp(this.state.rowSelected[0].id) ||
        (!this.checkIdTemp(this.state.rowSelected[0].id) &&
          this.state.rowSelected[0].document_management_number !== this.state.old_document_management_number)) {
        return true;
      } return false;
    } return false;
  }

  // add new record
  async handleAddRowTable(record) {
    let newflag = false

    if (!record.id) {
      newflag = true
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '450px',
        className: '',
        component:
          <WS1546002_DestinationSubEdit
            SpecifiedValueList={SpecifiedValueList}
            record={record}
            newFlag={newflag}

            //新規・編集
            onUpdate={(output) => {
              DestinationSubAction.createAndUpdateData(output)
                .then((res) => {
                  this.closeModal()
                  this.getScreenData();
                })
            }}

            //項目削除
            onDelete={(output) => {
              DestinationSubAction.deleteData(record)
                .then((res) => {
                  this.closeModal()
                  this.getScreenData();
                })
            }}
          />
      }
    })
  }

  // check selected record while add new
  changeRow(index) {
    let data = [...this.state.dataSource];

    let idTemp = false;
    data.forEach(item => {
      if (this.checkIdTemp(item.id)) {
        idTemp = true;
        return idTemp;
      }
    })

    if (idTemp) {
      this.setState({
        rowSelected: [data[0]],
        selectedRowKeys: [data[0].id],
        indexTable: 0
      });
    } else {
      this.setState({
        indexTable: index
      });
    }
  }

  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource];

    data[index][field] = value;

    this.setState({
      dataSource: data
    });
  }

  handleDeleteRowTable() {
    let data = [...this.state.dataSource];
    data.splice(0, 1);
    this.setState({
      dataSource: data,
      indexTable: 0,
      rowSelected: data.length > 0 ? [data[0]] : [],
      selectedRowKeys: data.length > 0 ? [data[0].id] : [],
      old_document_management_number: data.length > 0 ? data[0].document_management_number : null
    });
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };

  checkValueSpecified(value) {
    let nameSpecified = ''
    switch (value) {
      case '1':
        nameSpecified = '事業所'
        break;
      case '2':
        nameSpecified = '事業所(本店)'
        break;
      case '3':
        nameSpecified = '個人住所1'
        break;
      case '4':
        nameSpecified = '個人住所2'
        break;
      case '5':
        nameSpecified = '個人住所3'
        break;
      case '9':
        nameSpecified = '印字なし'
        break;
      default:
        break;
    }
    return nameSpecified
  }

  render() {
    return (
      <div className="destination-sub">
        <Card title="送付先">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              size='small'
              dataSource={this.state.dataSource}
              loading={this.state.isLoadingTable}
              pagination={false}
              rowKey={(record) => record.id}
              bordered
              scroll={{ x: 700 }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: async () => {
                    this.handleAddRowTable(record)
                  }
                };
              }}
            >

              <Table.Column
                title="送付先管理番号"
                dataIndex="document_management_number"
                width={110}
                render={(value) => (
                  <div
                    style={{ textAlign: 'right' }}>
                    {value}
                  </div>)}
              />

              <Table.Column
                title="送付先名称"
                dataIndex="document_title"
              />

              <Table.Column
                title="規定の送付先"
                dataIndex="label"
                width={150}
              />

              <Table.Column width={40}
                title={
                  <div style={{ textAlign: "center" }}>
                    <Button
                      size='small'
                      onClick={this.handleAddRowTable}
                      type="primary"
                      icon={<PlusOutlined />}>
                    </Button>
                  </div>
                }
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger="click"
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key="変更"
                            onClick={() => {
                              this.handleAddRowTable(record)
                            }}
                          >変更
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size="small"
                        icon={<MoreOutlined />}
                        style={{ width: "100%" }}
                      ></Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1546001_DestinationSub);
