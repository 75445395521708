import { message } from "antd";
import ImplementAgenciesMasterService from "services/SpecificInsureMaintenance/ImplementAgenciesMaster/ImplementAgenciesMasterService";

const ImplementAgenciesMasterAction = {
  index(params) {
    return ImplementAgenciesMasterService.index(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  delete(params) {
    return ImplementAgenciesMasterService.delete(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  // AddNewDivision(params) {
  //   return ImplementDivisionMasterService.addNewDivision(params)
  //     .then((res) => {
  //       return res?.data;
  //     })
  //     .catch((err) => {
  //       const res = err.response;
  //       if (!res || !res.data || !res.data.message) {
  //         message.error("エラーが発生しました");
  //         return;
  //       }
  //       message.error(res.data.message);
  //     });
  // },

  save(params) {
    return ImplementAgenciesMasterService.save(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

}

export default ImplementAgenciesMasterAction;