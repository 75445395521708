import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { ImportOutlined, MenuOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons'
import { Card, Form, Input, Button, Table, Tooltip, Modal, Menu, Dropdown, Upload, Tabs, message } from 'antd'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import TentativeReserveAction from 'redux/ReservationBusiness/SANAI_TentativeReserve/TentativeReserve.action.js'
import TentativeReserveCaptureConfirmedAction from 'redux/ReservationBusiness/SANAI_TentativeReserve/TentativeReserveCaptureConfirmed.action.js'
import moveFocusKeyEvent from 'components/Commons/MoveFocusKeyEvent'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import TimeSelectDisplay from 'pages/YK_ReservationBusiness/SANAI_TentativeReserve/TimeSelectDisplay.jsx'
import Color from 'constants/Color'
import WS2736001_MiraisElectronicMedicalRecordsSent from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2736001_MiraisElectronicMedicalRecordsSent.jsx'
import WS2727001_PatientInfoQueryStard from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS2727001_PatientInfoQueryStard.jsx'
import WS0343010_PatientInfoQueryOthers from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343010_PatientInfoQueryOthers.jsx'
import Cookies from 'js-cookie'

const OptionUser = 'IBARS'
class TentativeReserveCaptureConfirmed extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      columnList: [],
      selectedDate: {},
      selectedIndex: '',
      selectedRowKeys: [],

      conditionAddData: null,
      uploadBtnDisabled: true,
      confirmBtnDisabled: true,

      uploadFileName: '',
      nameCount: 0,
      capturingFlag: false
    }
  }

  /**
   * 処理表示処理
   */
  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      dataSource: [],
      ReserveDateFChar: moment(new Date()),
      ReserveDateTChar: moment(new Date()),
    })

    const dateTime = moment(new Date()).format('YYYYMMDDHHmmss')
    this.setState({
      uploadFileName: 'upload_' + dateTime + '.xlsx'
    })
  }

  /**
   * 検索ボタン、データ取得
   */
  index = () => {
    if (!this.formRef.current?.getFieldValue('ReserveDateFChar') || !this.formRef.current?.getFieldValue('ReserveDateTChar')) {
      Modal.error({
        width: '300px',
        content: '日付の範囲を指定してください'
      })
    } else {
      let params = {
        ReserveDateFChar: this.formRef.current?.getFieldValue('ReserveDateFChar').format('YYYY/MM/DD'),
        ReserveDateTChar: this.formRef.current?.getFieldValue('ReserveDateTChar').format('YYYY/MM/DD'),
        officeCode: this.formRef.current?.getFieldValue('OfficeCode'),
        conditionAddData: this.state.conditionAddData ?? {}
      }
      TentativeReserveAction.getTentativeReserve(params)
        .then(res => {
          //オプションを表示するためのカラムリストを作成
          let length = 0
          let newColumnList = []
          res.map((value) => {
            //要素数の最も多いoptionListをcolumnListとして再利用
            if (value.optionList.length > length) {
              length = value.optionList.length
              newColumnList = value.optionList
            }
          })
          this.formRef.current?.setFieldsValue({
            dataSource: res,
            columnList: newColumnList,
          })
          this.setState({
            dataSource: res,
            columnList: newColumnList,
            uploadBtnDisabled: false,
          })
        })
    }
  }

  /**
   * 確定ボタンの処理
   */
  confirmButton = () => {
    let selectList = []
    let data = [...this.state.dataSource]

    // 選択行だけを抽出
    data.forEach((item) => {
      const selectIndex = this.state.selectedRowKeys.indexOf(item.id)
      if (selectIndex > -1) {
        selectList.push(item)
      }
    })

    let check = false
    selectList.map((value, index) => {
      if (value.personal_number_id && !value.period_time) {
        return (
          check = true
        )
      }
    })
    if (check == true) {
      Modal.error({ title: '時間帯を設定してください' })
    } else {
      let params = {
        dataSource: selectList,
        officeCode: this.formRef.current.getFieldValue('OfficeCode'),
      }
      TentativeReserveAction.determineProcess(params)
        .then(res => {
          this.index()

          // let reserveNumList = res
          // if (reserveNumList.length > 0) {
          //   // Mirais電子カルテ送信
          //   this.callMiraisElectronicMedicalRecordsSent(
          //     this.formRef.current?.getFieldValue('ReserveDateFChar'),
          //     this.formRef.current?.getFieldValue('ReserveDateTChar'),
          //     reserveNumList
          //   )
          // }
          // // データ再取得
          // this.index()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  /**
   * Mirais単体送信を出力
   * @param {*} dataF
   * @param {*} dataT
   * @param {*} reserveNumList
   */
  callMiraisElectronicMedicalRecordsSent(dataF, dataT, reserveNumList) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2736001_MiraisElectronicMedicalRecordsSent
            DateFChar={dataF}
            DateTChar={dataT}
            reserve_nums={reserveNumList}
            onFinishScreen={(output) => {
              this.closeModal()
              if (reserveNumList.length !== 0) {
                // 明細画面
                this.callParticularsScreen(reserveNumList)
              }
            }}
          />
        ),
      },
    })
  }

  /**
   * 時間設定
   * @param {*} date
   * @param {*} index
   */
  showTimeSelectDisplay = (date, index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <TimeSelectDisplay
            Li_Date={date}
            dataSource={this.state.dataSource}
            onFinishScreen={(obj) => {
              // 受診日・時間帯設定
              let newDataSource = [...this.state.dataSource]
              let dataObj = { ...newDataSource[index] }
              dataObj.period_time = obj.timeZone
              newDataSource[index] = dataObj
              this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
              this.setState({ dataSource: newDataSource })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 受診日-開始 チェック
   */
  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current?.getFieldValue('ReserveDateFChar').format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current?.getFieldValue('ReserveDateTChar').format('YYYY/MM/DD')
      : null
    if (dateEnd && dateStart > dateEnd) {
      this.formRef.current?.setFieldsValue({
        ReserveDateFChar:
          this.formRef.current?.getFieldValue('ReserveDateTChar'),
      })
    }
  }

  /**
   * 受診日-終了 チェック
   */
  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current?.getFieldValue('ReserveDateFChar').format('YYYY/MM/DD')
      : null
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current?.getFieldValue('ReserveDateTChar').format('YYYY/MM/DD')
      : null
    if ((!dateEnd && dateStart) || (dateStart && dateStart > dateEnd)) {
      this.formRef.current?.setFieldsValue({
        ReserveDateTChar:
          this.formRef.current?.getFieldValue('ReserveDateFChar'),
      })
    }
  }

  /**
   * 事業所情報検索・照会
   */
  showWS0247001_OfficeInfoRetrievalQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCodeF')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output?.Lio_OfficeCode,
                BranchStoreCodeF: output?.Lio_BranchStoreCode,
                office_kanji_name: output?.recordData?.office_kanji_name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * Excelファイル 読み込み
   */
  readExcelFile(uploadFileName) {
    let params = {
      dataList: this.state.dataSource,
      uploadFileName: uploadFileName
    }
    TentativeReserveCaptureConfirmedAction.readExcelFile(params)
      .then((res) => {
        if (res) {
          console.log(res)

          const data = res.dataList

          // 選択行を設定
          let keys = []
          data.forEach(item => {
            if (item.dataType === 1) keys.push(item.id)
          })

          // 名前の入った人をカウント
          let nameCount = 0
          for (let i = 0; i < data.length; i++) {
            if (data[i].kanji_name !== '' || data[i].kana_name !== '') {
              nameCount = nameCount + 1
            }
          }

          this.setState({
            dataSource: data,
            selectedRowKeys: keys,
            nameCount: nameCount,
            capturingFlag: true
          })
          this.formRef.current?.setFieldsValue({
            dataSource: data,
          })
        }

        // アップロードファイル名を変更
        let dateTime = moment(new Date()).format('YYYYMMDDHHmmss')
        this.setState({
          uploadFileName: 'upload_' + dateTime + '.xlsx'
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  /**
   * 個人情報を取得
   * @param {*} value
   * @param {*} record
   * @param {*} index
   */
  getPersonalInfo = (value, record, index) => {
    let duplication = false

    //入力した個人番号の重複チェック
    this.state.dataSource.map((element) => {
      if (element.personal_number_id == value) {
        duplication = true
      }
    })

    if (duplication) {
      let newDataSource = [...this.state.dataSource]
      newDataSource[index]['personal_number_id'] = value
      newDataSource[index]['kanji_name'] = '登録済みです'
      newDataSource[index]['warningFlag'] = true
      this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
      this.setState({
        dataSource: newDataSource
      })
    } else {
      let params = {
        personal_number_id: value,
        office_code: record.office_code,
        branch_store_code: record.branch_store_code,
        date: record.date
      }
      TentativeReserveAction.getPersonalInfo(params)
        .then(res => {
          if (res) {
            let newArrTemp = [...this.state.dataSource]
            let obj = { ...newArrTemp[index] }
            obj.personal_number_id = value
            obj.kanji_name = res.kanji_name
            obj.kana_name = res.kana_name
            obj.gender = res.gender
            obj.genderColor = res.genderColor
            obj.birthday_on = moment(res.birthday_on)?.format('NNyy/MM/DD')
            obj.visit_age = res.visit_age
            obj.age = res.age
            obj.warningFlag = res.warningFlag
            newArrTemp[index] = obj
            this.formRef.current?.setFieldsValue({ dataSource: newArrTemp })
            this.setState({
              dataSource: newArrTemp
            })
          }
        })
    }
  }

  /**
 * チェックボックス 選択
 * @param {*} record
 * @param {*} selected
 */
  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  getPeriodTime = (event, index) => {
    TentativeReserveAction.getPeriodTime({ inputValue: event.target.value })
      .then(res => {
        if (res) {
          let newDataSource = [...this.state.dataSource]
          let dataObj = { ...newDataSource[index] }
          dataObj.period_time = res.time
          newDataSource[index] = dataObj
          this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
          this.setState({ dataSource: newDataSource })

          moveFocusKeyEvent(event, 'period_time', 1, false, 0)
        }
      })
  }

  render = () => {
    return (
      <div className='tentative-reserve-capture-confirmed-mock'>
        <Card title='仮予約取込確定'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1'
                      onClick={() => {
                        window.open(location.protocol + '//' + location.host + '/reserve-status-search-custom/reserve-status-search-custom')
                      }}
                    >
                      予約状況検索
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                style={{ position: 'absolute', left: '95%' }}
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_inner_horizontal'>
                  <div className='box_search' style={{ flexDirection: 'column' }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='受診日' name='ReserveDateFChar' style={{ marginBottom: 0 }}>
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          style={{ width: 112 }}
                          onBlur={() => {
                            this.checkDateStart()
                          }}
                        />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>~</Form.Item>
                      <Form.Item name='ReserveDateTChar' style={{ marginBottom: 0 }}>
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          style={{ width: 112 }}
                          onBlur={() => {
                            this.checkDateEnd()
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item label='事業所' name='OfficeCode' style={{ marginBottom: 0 }}>
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-allow-clear-8'
                          onPressEnter={() => {
                            if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                              this.showWS0247001_OfficeInfoRetrievalQuery()
                            }
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                                this.showWS0247001_OfficeInfoRetrievalQuery()
                              }
                            }
                          }
                          }
                        />
                      </Form.Item>

                      <Form.Item name='office_kanji_name' style={{ marginBottom: 0 }}>
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                          }}
                        >{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                      </Form.Item>
                    </div>
                  </div>

                  <div className='box_search_btn'>
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          if (!this.state.errorBranchStore && !this.state.errorOfficeCode)
                            this.formRef.current?.setFieldsValue({
                              updateFlag: false
                            })
                          // データ取得
                          this.index()
                        }}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>

                      <Tooltip title='詳細な条件追加'>
                        <Button size='small'
                          type='primary'
                          style={{ borderRadius: '12px' }}
                          className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                          icon={<PlusOutlined />}
                          onClick={() => {
                            if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                              this.setState({ isSearch: false })
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 900,
                                  component: (
                                    <WS2786001_ConditionAddSub
                                      Li_DateF={
                                        this.formRef.current?.getFieldValue('ReserveDateFChar')
                                          ? this.formRef.current?.getFieldValue('ReserveDateFChar')?.format('YYYY/MM/DD')
                                          : ''
                                      }
                                      Li_DateT={
                                        this.formRef.current?.getFieldValue('ReserveDateTChar')
                                          ? this.formRef.current?.getFieldValue('ReserveDateTChar')?.format('YYYY/MM/DD')
                                          : ''
                                      }
                                      Li_Office={this.formRef.current?.getFieldValue('OfficeCode')}
                                      Li_ConditionAddData={this.formRef.current?.getFieldValue('recordData')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current?.setFieldsValue({
                                          OutputOrder: output?.Lio_KeyInfo,
                                          recordData: output?.conditionAddData
                                        })
                                        this.setState({
                                          conditionAddData: output.conditionAddData
                                        })
                                        this.closeModal()
                                      }
                                      }
                                    />
                                  ),
                                },
                              })
                            }
                          }} />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className='box_inner_vertical'>
                  <div className='box_inner_horizontal' style={{ marginTop: 'auto', height: 'fit-content' }}>
                    <div style={{ marginLeft: 'auto' }}></div>
                    <div style={{ display: this.state.capturingFlag ? '' : 'none' }}>{this.state.nameCount}</div>
                    <div style={{ display: this.state.capturingFlag ? '' : 'none' }}>{'/'}</div>
                    <div >{this.state.dataSource.length}</div>
                  </div>
                  <div className='box_inner_horizontal' style={{ marginTop: 'auto', height: 'fit-content' }}>
                    <div className='example-row-over' style={{ marginLeft: 'auto' }}></div>
                    <div >{'仮予約枠を超過'}</div>
                    <div className='example-row-surplus'></div>
                    <div >{'空枠'}</div>
                    <div className='example-row-no-id'></div>
                    <div >{'個人の登録なし'}</div>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                rowKey={(record) => record.id}
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowClassName={(record, index) =>
                  (record?.dataType === 0) ? 'table-row-surplus' : //空枠
                    (record?.dataType === 2) ? 'table-row-no-id' : //個人の登録なし
                      (record?.dataType === 3) ? 'table-row-over' : //仮予約枠を超過
                        ''
                }
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected) => {
                    // 選択
                    this.onSelect(record, selected)
                  },
                  onSelectAll: (selected) => {
                    // 全選択
                    this.onSelectAll(selected)
                  }
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      this.setState({
                        selectedDate: record.date,
                        selectedIndex: rowIndex
                      })
                    }
                  }
                }}
              >
                <Table.Column
                  title='日付'
                  dataIndex='date'
                  className='column-size-10'
                  render={(value) => {
                    let day = new Date(value)
                    let dayOfWeek = day.getDay()
                    let dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeek]
                    return (
                      <div>{value + '(' + dayOfWeekStr + ')'}</div>
                    )
                  }}
                />

                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  render={(value, record, index) => {
                    return (
                      <Form.Item name={['dataSource', index, 'personal_number_id']}>
                        <Input.Search
                          allowClear
                          id={'spreadInput' + index}
                          className='input-search-size-number-allow-clear-10'
                          onSearch={(value, event) => {
                            if (event.target.localName !== 'input') {
                              // 虫眼鏡ボタンが押下された時
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <Card title={'検索'}>
                                      {OptionUser !== '' ?
                                        <Tabs
                                          defaultActiveKey='1'
                                          size='small'
                                        >
                                          <Tabs.TabPane
                                            tab='患者情報照会'
                                            key='1'
                                          >
                                            {OptionUser === 'COMMON' ?
                                              <WS2727001_PatientInfoQueryStard
                                                hideTitle={true} //タイトルを非表示
                                                onFinishScreen={async (output) => {
                                                  let personalTemp = null
                                                  if (output.recordData) {
                                                    // 患者情報が存在する場合、後で反映するためのデータを作成
                                                    const data = { ...output.recordData }
                                                    personalTemp = {
                                                      personal: {
                                                        kana_name: data.kana_name,
                                                        kanji_name: data.kanji_name,
                                                        birthday_on: data.birthday_on,
                                                        sex: data.sex,
                                                      },
                                                      address: {
                                                        postal_code: data.postal_code,
                                                        address_1: data.address_1,
                                                        address_2: data.address_2,
                                                        phone_number: data.phone_number,
                                                        cell_phone_number: data.cell_phone_number
                                                      }
                                                    }
                                                  }
                                                  await this.closeModal()
                                                }}
                                              />
                                              :
                                              <WS0343010_PatientInfoQueryOthers
                                                hideTitle={true} //タイトルを非表示
                                                Li_OptionUser={OptionUser}
                                                onFinishScreen={async (output) => {
                                                  let personalTemp = null
                                                  if (output.recordData) {
                                                    // 患者情報が存在する場合、後で反映するためのデータを作成
                                                    const data = { ...output.recordData }
                                                    personalTemp = {
                                                      personal: {
                                                        kana_name: data.kana_name,
                                                        kanji_name: data.kanji_name,
                                                        birthday_on: data.birthday_on,
                                                        sex: data.sex,
                                                      },
                                                      address: {
                                                        postal_code: data.postal_code,
                                                        address_1: data.address_1,
                                                        address_2: data.address_2,
                                                        phone_number: data.phone_number,
                                                        cell_phone_number: data.cell_phone_number
                                                      }
                                                    }
                                                  }
                                                  await this.closeModal()
                                                }}
                                              />
                                            }
                                          </Tabs.TabPane>

                                          <Tabs.TabPane
                                            tab='個人情報検索・照会'
                                            key='2'
                                          >
                                            <WS0248001_PersonalInfoSearchQuery
                                              date={moment(new Date()).format('YYYY/MM/DD')}
                                              createNewPersonal={this.createNewPersonal}
                                              getValueChild={this.getValueChild}
                                              hideTitle={true} //タイトルを非表示
                                              onFinishScreen={(output) => {
                                                // データ取得
                                                this.getPersonalInfo(output.Lo_PersonalNumId, record, index)
                                                this.closeModal()
                                              }}
                                            />
                                          </Tabs.TabPane>
                                        </Tabs>
                                        :
                                        <WS0248001_PersonalInfoSearchQuery
                                          date={moment(new Date()).format('YYYY/MM/DD')}
                                          createNewPersonal={this.createNewPersonal}
                                          getValueChild={this.getValueChild}
                                          hideTitle={true} //タイトルを非表示
                                          onFinishScreen={(output) => {
                                            // データ取得
                                            this.getPersonalInfo(output.Lo_PersonalNumId, record, index)
                                            this.closeModal()
                                          }}
                                        />
                                      }
                                    </Card>
                                  )
                                }
                              })
                            }
                          }}
                          onKeyDown={(e) => {
                            moveFocusKeyEvent(e, 'spreadInput', 1, false, 0)
                          }}
                          onPressEnter={(e) =>
                            this.getPersonalInfo(e.target.value, record, index)
                          }
                        />
                      </Form.Item>
                    )
                  }}
                />

                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  className='column-size-40'
                  render={(value, record, index) =>
                    <div style={{ color: record.warningFlag ? '#ea2c00' : '' }}>{value}</div>
                  }
                />

                <Table.Column
                  title='性別'
                  dataIndex='gender'
                  className='column-size-4'
                  render={(value, record) =>
                    <div
                      style={{
                        color: record.genderColor ? Color(record.genderColor)?.Foreground : '',
                        textAlign: 'center'
                      }}>
                      {value}
                    </div>
                  }
                />

                <Table.Column
                  title='生年月日'
                  dataIndex='birthday_on'
                  className='column-size-8'
                  render={(value) =>
                    <div style={{ textAlign: 'center' }}>{value}</div>
                  }
                />

                <Table.Column
                  title='時間'
                  dataIndex='period_time'
                  className='column-size-5'
                  render={(value, record, index) => {
                    return (
                      <Form.Item name={['dataSource', index, 'period_time']}>
                        <Input.Search
                          id={'period_time' + index}
                          placeholder='08:30'
                          className='input-search-size-4'
                          value={value}
                          onPressEnter={(e) => {
                            this.getPeriodTime(e, index)
                          }}
                          onSearch={(e) => {
                            // 時間帯設定　モーダル
                            this.showTimeSelectDisplay(record.date, index)
                          }}
                        />
                      </Form.Item>
                    )
                  }}
                />

                <Table.Column
                  title='コース'
                  dataIndex='course'
                  className='column-size-60'
                />

                {this.state.columnList.map((value, index) => {
                  return (
                    <Table.Column
                      key={index}
                      title={'オプション' + (index + 1)}
                      className='column-size-60'
                      render={(element, record) =>
                        <div>{record.optionList[index]}</div>
                      }
                    />
                  )
                })
                }
              </Table>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Upload
              headers={{
                'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
              }}
              withCredentials={true}
              showUploadList={false}
              data={{
                uploadFileName: this.state.uploadFileName
              }}
              action='/api/tentative-reserve/tentative-reserve-capture-confirmed/upload'
              onChange={(info) => {
                console.log('info:', info)
                if (info.file.status === 'uploading') {
                  this.setState({ isLoadingData: true })
                } else {
                  if (info.file.status === 'done') {
                    // Excelファイル 読み込み
                    this.readExcelFile(info.file.response.filePath)
                  } else if (info.file.status === 'error') {
                    message.error('エラーが発生しました')
                  }
                }
              }}
            >
              <Button
                type='primary'
                icon={<ImportOutlined />}
                disabled={this.state.uploadBtnDisabled}
              >
                <span className='btn_label'>
                  取込
                </span>
              </Button>
            </Upload>

            <Button
              type='primary'
              onClick={() => this.confirmButton()}
              disabled={this.state.selectedRowKeys.length === 0}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TentativeReserveCaptureConfirmed)
