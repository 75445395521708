import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React, { createRef, Component } from 'react'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Button, Tabs, Input, InputNumber, Popconfirm, Select, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import 'pages/TM_SpecificInsureMaintenance/V4TH0000500_InspectItemMaster/WS1400001_InspectItemMaster.scss'
import WS0084001_PostCodeSearchEngine from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx'
import AddApplyDate2 from './AddApplyDate2'
import FacilityConsumptionTaxSettingAction from 'redux/SystemMaintenance/FacilityConsumptionTaxSetting/FacilityConsumptionTaxSetting.action'

const { TabPane } = Tabs
const facilityList = [
  { date: '0000/00/00', name: '名称1' },
  { date: '2022/04/05', name: '名称2' },
  { date: '2022/04/10', name: '名称3' },
]
const taxList = [
  { date: '0000/00/00', name: '名称1' },
  { date: '2014/05/17', name: '名称2' },
  { date: '2019/12/06', name: '名称3' },
  { date: '2030/12/06', name: '名称4' },
]

const columns = [
  {
    title: '適用年月日',
    dataIndex: 'date',
    textAlign: 'center',
  },

  {
    title: '名称',
    dataIndex: 'name',
    textAlign: 'center',
  }
]
class WS1536500_FacilityConsumptionTaxSetting extends React.Component {
  formRef = createRef()

  static propTypes = {
    Lio_ZipCode: PropTypes.any,
    Lio_Address: PropTypes.any,
    Lio_Prefecture: PropTypes.any,
    Li_CurrentPublic: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '施設設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      facilityInfo: {},
      applyDateList: [],
      selectedId: -1,
    }
  }

  componentDidMount() {
    FacilityConsumptionTaxSettingAction.getApplyDate()
      .then(res => {
        this.setState({
          applyDateList: res.data
        })
        let idList = this.state.applyDateList.map(element => {
          return element.id
        })
        let latestId = Math.max.apply(null, idList)
        this.formRef.current.setFieldsValue({ date: latestId.toString() })
        this.handleDateChange(latestId)
      })
  }

  ReturnComponent = component => {
    let components = {
      WS0084001: WS0084001_PostCodeSearchEngine
    }
    return components[component]
  }

  callbackOpenModal = modal => {
    let Component = this.ReturnComponent(modal)
    const formInstance = this.formRef?.current

    // let arrBelongTemp = [...this.state.personal.personal_belongs]
    // let objBelongTemp = this.state.objBelong
    // let indexBelong = arrBelongTemp.findIndex(item => item.id === objBelongTemp.id)

    // let arrAddressTemp = [...this.state.personal.personal_addresses]
    let objAddressTemp = this.state.objAddress
    // let indexAddress = arrAddressTemp.findIndex(item => item.id === objAddressTemp.id)

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <Component
            Lio_ZipCode={objAddressTemp.postal_code}
            Lio_Address={objAddressTemp.address_1}
            onFinishScreen={({ Lio_ZipCode, Lio_Address }) => {
              // if (indexAddress !== -1) {
              //   objAddressTemp = {
              //     ...objAddressTemp,
              //     postal_code: Lio_ZipCode,
              //     address_1: Lio_Address
              //   }
              //   arrAddressTemp[indexAddress] = objAddressTemp
              //   formInstance?.setFieldsValue({
              //     personal_addresses: arrAddressTemp,
              //     objAddress: objAddressTemp
              //   })
              //   this.setState({
              //     personal: {
              //       ...this.state.personal,
              //       personal_addresses: arrAddressTemp
              //     },
              //     objAddress: objAddressTemp
              //   })
              // }
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: ''
      }
    })
  }

  handleDateChange = (value) => {
    let params = {
      id: Number(value)
    }
    FacilityConsumptionTaxSettingAction.getFacility(params)
      .then(res => {
        this.formRef.current.setFieldsValue(res.data)
        this.setState({
          facilityInfo: res.data,
        })
      })
  }

  onSave = () => {
    let params = {
      data: { ...this.state.facilityInfo }
    }
    console.log(params)
    FacilityConsumptionTaxSettingAction.postFacility(params)
  }

  /**
   * 施設の詳細変更処理
   * @param {*} event 
   * @param {*} keyName 
   */
  onChangeFacilityInfo = (event, keyName) => {
    if (
      keyName === 'account_type_1'
      || keyName === 'account_type_2'
      || keyName === 'account_type_3'
      || keyName === 'account_number_1'
      || keyName === 'account_number_2'
      || keyName === 'account_number_3'
    ) {
      this.setState({
        facilityInfo: {
          ...this.state.facilityInfo,
          [keyName]: event
        }
      })
    } else {
      this.setState({
        facilityInfo: {
          ...this.state.facilityInfo,
          [keyName]: event.target.value
        }
      })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  reloadApplyDate = (id) => {
    //idとapply_date_onのみ更新してstateへ
    FacilityConsumptionTaxSettingAction.getApplyDate()
      .then(res => {
        // findで新規追加idと一致する日付のデータを取得する
        const applyDate = res.data.find((v) => v.id === id)
        const applyDateOn = applyDate.apply_date_on
        this.setState({
          applyDateList: res.data,
          facilityInfo: {
            //idとapply_date_onのみ更新してstateへ
            ...this.state.facilityInfo,
            id: id,
            apply_date_on: applyDateOn
          }
        })
        this.formRef.current.setFieldsValue({ date: id.toString() }) //適用年月日に新規追加した日付を設定
        this.onSave() //既に入力されている情報を保存(ページの更新などで消えないようにするため)
      })
  }

  deleteFacilityInfo = () => {
    console.log('削除')
    let params = {
      id: Number(this.formRef.current.getFieldValue('date'))
    }
    console.log('params')
    console.log(params)
    FacilityConsumptionTaxSettingAction.deleteFacilityInfo(params)
      .then(res => {
        this.setState({
          applyDateList: res.data
        })
        this.formRef.current.resetFields()
      })
  }

  render() {

    return (
      <div className='facility-inquiry'>
        <Form ref={this.formRef}>
          <Card title='施設設定' style={{ width: '100%' }}>
            {/* <Card title='施設設定' style={{ width: 'fit-content' }}> */}
            {/* <Card title='施設設定' style={{ width: '867px' }}> */}
            <div className='box_inner_horizontal'>
              <Form.Item name='date' label='適用年月日'>
                <Select
                  className='input-size-10'
                  placeholder='選択してください'
                  // value={this.state.selectedId}
                  onChange={this.handleDateChange}
                  style={{ width: '200px' }}
                >
                  {this.state.applyDateList.map((item) => {
                    return (
                      <Select.Option
                        key={item.id}
                        value={item.id.toString()}//valueは文字列
                      >
                        {item.apply_date_on}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Tooltip title='施設設定の追加'>
                <Button
                  size='small'
                  type='primary'
                  style={{ borderRadius: '12px', marginTop: '0.2rem' }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.setState({
                      childModal: {
                        visible: true,
                        width: 250,
                        component:
                          // 渡すパラメータは適宜設定
                          <AddApplyDate2
                            onFinishScreen={(id) => {
                              this.closeModal()
                              this.reloadApplyDate(id)
                            }}
                          />
                      }
                    })
                  }}
                >
                </Button>
              </Tooltip>
            </div>
            <Tabs defaultActiveKey='1'>
              <TabPane tab='概要' key='1'>
                <div className='box_inner_vertical' >
                  <Form.Item name='version' label='医療機関' >
                    {/* <Input className='input-size-10' type='text' onChange={(event) => this.setState({ facilityInfo: { ...this.state.facilityInfo, version: event.target.value } })} /> */}
                    <Input className='input-size-10' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'version')} />
                  </Form.Item>
                  <Form.Item name={'corporate_name'} label='法人名称'>
                    <Input className='input-size-60' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'corporate_name')} />
                  </Form.Item>
                  <Form.Item name={'facility_name'} label='施設名称' >
                    <Input className='input-size-60' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'facility_name')} />
                  </Form.Item>
                  <Form.Item name={'representative_name'} label='代表者名'>
                    <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'representative_name')} />
                  </Form.Item>
                  <Form.Item name={'registration_number'} label='登録番号'>
                    <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'registration_number')} />
                  </Form.Item>
                </div>
              </TabPane>

              <TabPane tab='所在' key='2'>
                <div className='box_inner_vertical'>
                  <div className='box_inner_horizontal'>
                    <Form.Item name={'postal_code'} label='郵便番号'>
                      <Input.Search
                        className='input-size-11'
                        type='text'
                        maxLength={8}
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '60%',
                              component: (
                                <WS0084001_PostCodeSearchEngine
                                  Lio_ZipCode={''}
                                  Lio_Prefecture={''}
                                  Li_CurrentPublic={this.formRef.current.getFieldValue('prefecture_name')}
                                  // Li_CurrentPublic={''}
                                  onFinishScreen={({ Lio_ZipCode, Lio_Address, Lio_Prefecture }) => {
                                    // let data = [...this.formRef.current.getFieldValue('dataFacilityInfo')]
                                    // data.postal_code = Lio_ZipCode
                                    // data.address_1 = data[0].address_1 ? data[0].address_1 : Lio_Address

                                    this.formRef.current?.setFieldsValue({
                                      postal_code: Lio_ZipCode,
                                      address_1: Lio_Address,
                                      prefecture_name: Lio_Prefecture,
                                    })
                                    this.closeModal()
                                  }}
                                />)
                            }
                          })
                        }}
                        onChange={(event) => this.onChangeFacilityInfo(event, 'postal_code')}
                      />
                    </Form.Item>
                    <Form.Item name={'prefecture_name'} label='都道府県' >
                      {/* <Select style={{ width: '150px' }} >
                        {this.state.lstPrefectureName?.map(value => (
                      <Select.Option key={'prefecture_name-' + Math.random()} value={value.LinkedField}>{value.DisplayField}</Select.Option>
                    ))}
                      </Select> */}
                      <Input className='input-size-10' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'prefecture_name')} />
                    </Form.Item>
                  </div>
                  <Form.Item name={'address_1'} label='住所１　'>
                    <Input
                      className='input-size-60'
                      type='text'
                      onChange={(event) => this.onChangeFacilityInfo(event, 'address_1')}
                    />
                  </Form.Item>
                  <Form.Item name={'address_2'} label='住所２　'>
                    <Input className='input-size-60' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'address_2')} />
                  </Form.Item>
                  <div className='box_inner_horizontal'>
                    <Form.Item name={'phone_number'} label='電話番号' >
                      <Input className='input-size-11' type='text' maxLength={20} onChange={(event) => this.onChangeFacilityInfo(event, 'phone_number')} />
                    </Form.Item>
                    <Form.Item name={'innerline_number'} label='内線番号' >
                      <Input className='input-size-11' type='text' maxLength={10} onChange={(event) => this.onChangeFacilityInfo(event, 'innerline_number')} />
                    </Form.Item>
                    <Form.Item name={'fax'} label='FAX 番号'>
                      <Input className='input-size-11' type='text' maxLength={20} onChange={(event) => this.onChangeFacilityInfo(event, 'fax')} />
                    </Form.Item>
                  </div>
                </div>
              </TabPane>

              <TabPane tab='振込先(1)' key='3'>
                <div className='box_inner_horizontal'>
                  <div className='box_search_inner_border' style={{ width: '100%' }}>
                    <Form.Item name={'transfer_bank_1'} label='振込銀行'>
                      <Input className='input-size-40' type='text' style={{ marginRight: '20px' }} onChange={(event) => this.onChangeFacilityInfo(event, 'transfer_bank_1')} />
                    </Form.Item>
                    <Form.Item label='口座種別' name={'account_type_1'} >
                      {/* <Input className='input-size-10' type='text' maxLength={4} onChange={(event) => this.onChangeFacilityInfo(event, 'account_type_1')} /> */}
                      <Select
                        style={{ width: '120px' }}
                        onChange={(event) => this.onChangeFacilityInfo(event, 'account_type_1')}
                      >
                        <Select.Option key={1} value='普通'>
                          普通
                        </Select.Option>
                        <Select.Option key={2} value='当座'>
                          当座
                        </Select.Option>
                        <Select.Option key={3} value='定期預金'>
                          定期預金
                        </Select.Option>
                        <Select.Option key={4} value='総合口座'>
                          総合口座
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={'account_number_1'} label='口座番号'>
                      <InputNumber className='input-size-40' maxLength={7} onChange={(event) => this.onChangeFacilityInfo(event, 'account_number_1')} />
                    </Form.Item>
                  </div>
                  <div className='box_search_inner' style={{ width: '100%' }}>
                    <Form.Item name={'transfer_branch_1'} label='振込支店'>
                      <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'transfer_branch_1')} />
                    </Form.Item>
                    <Form.Item name={'bankaccount_name_1'} label='名義人　'>
                      <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'bankaccount_name_1')} />
                    </Form.Item>
                  </div>
                </div>
              </TabPane>

              <TabPane tab='振込先(2)' key='4'>
                <div className='box_inner_horizontal'>
                  <div className='box_search_inner_border' style={{ width: '100%' }}>
                    <Form.Item name={'transfer_bank_2'} label='振込銀行'>
                      <Input className='input-size-40' type='text' style={{ marginRight: '20px' }} onChange={(event) => this.onChangeFacilityInfo(event, 'transfer_bank_2')} />
                    </Form.Item>
                    <Form.Item label='口座種別' name={'account_type_2'} >
                      {/* <Input className='input-size-10' type='text' maxLength={4} onChange={(event) => this.onChangeFacilityInfo(event, 'account_type_2')} /> */}
                      <Select
                        style={{ width: '120px' }}
                        onChange={(event) => this.onChangeFacilityInfo(event, 'account_type_2')}
                      >
                        <Select.Option key={1} value='普通'>
                          普通
                        </Select.Option>
                        <Select.Option key={2} value='当座'>
                          当座
                        </Select.Option>
                        <Select.Option key={3} value='定期預金'>
                          定期預金
                        </Select.Option>
                        <Select.Option key={4} value='総合口座'>
                          総合口座
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={'account_number_2'} label='口座番号'>
                      <InputNumber className='input-size-40' maxLength={7} onChange={(event) => this.onChangeFacilityInfo(event, 'account_number_2')} />
                    </Form.Item>
                  </div>
                  <div className='box_search_inner' style={{ width: '100%' }}>
                    <Form.Item name={'transfer_branch_2'} label='振込支店'>
                      <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'transfer_branch_2')} />
                    </Form.Item>
                    <Form.Item name={'bankaccount_name_2'} label='名義人　'>
                      <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'bankaccount_name_2')} />
                    </Form.Item>
                  </div>
                </div>
              </TabPane>

              <TabPane tab='振込先(3)' key='5'>
                <div className='box_inner_horizontal'>
                  <div className='box_search_inner_border' style={{ width: '100%' }}>
                    <Form.Item name={'transfer_bank_3'} label='振込銀行'>
                      <Input className='input-size-40' type='text' style={{ marginRight: '20px' }} onChange={(event) => this.onChangeFacilityInfo(event, 'transfer_bank_3')} />
                    </Form.Item>
                    <Form.Item label='口座種別' name={'account_type_3'} >
                      {/* <Input className='input-size-10' type='text' maxLength={4} onChange={(event) => this.onChangeFacilityInfo(event, 'account_type_3')} /> */}
                      <Select
                        style={{ width: '120px' }}
                        onChange={(event) => this.onChangeFacilityInfo(event, 'account_type_3')}
                      >
                        <Select.Option key={1} value='普通'>
                          普通
                        </Select.Option>
                        <Select.Option key={2} value='当座'>
                          当座
                        </Select.Option>
                        <Select.Option key={3} value='定期預金'>
                          定期預金
                        </Select.Option>
                        <Select.Option key={4} value='総合口座'>
                          総合口座
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={'account_number_3'} label='口座番号'>
                      <InputNumber className='input-size-40' maxLength={7} onChange={(event) => this.onChangeFacilityInfo(event, 'account_number_3')} />
                    </Form.Item>
                  </div>
                  <div className='box_search_inner' style={{ width: '100%' }}>
                    <Form.Item name={'transfer_branch_3'} label='振込支店'>
                      <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'transfer_branch_3')} />
                    </Form.Item>
                    <Form.Item name={'bankaccount_name_3'} label='名義人　'>
                      <Input className='input-size-40' type='text' onChange={(event) => this.onChangeFacilityInfo(event, 'bankaccount_name_3')} />
                    </Form.Item>
                  </div>
                </div>
              </TabPane>
            </Tabs>
            <div className='box_button_bottom_right'>
              <Popconfirm
                title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                overlayStyle={{ width: '250px' }}
                overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                cancelButtonProps={{ style: { height: '32px' } }}
                icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                okText='削除'
                onConfirm={() => { this.deleteFacilityInfo() }}
                cancelText='キャンセル'
              >
                <Button
                  danger
                  type='primary'
                  style={{}}
                  icon={<DeleteOutlined />}
                // onClick={() => {
                //   this.deleteFacilityInfo()
                // }}
                >削除
                </Button>
              </Popconfirm>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  // props.onFinishScreen()
                  this.onSave()
                  // this.formRef.current.setFieldsValue({ date: '7' })
                  // this.setState({ selectedId: '7' })
                }}
              >保存
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }

}
export default (WS1536500_FacilityConsumptionTaxSetting)
