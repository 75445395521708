/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Input, Form, Card, Table, Button, Tooltip, Select, message, Space } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import {
  InfoCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  SearchOutlined,
  PlusOutlined
} from '@ant-design/icons'
import PersonalInfoMaintainAction from 'redux/basicInfo/PersonalInfoMaintain/PersonalInfoMaintain.action'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import moment from 'moment-timezone'
import WS0343001_PersonalInfoMaintain from './WS0343001_PersonalInfoMaintain'
import JapaneseDateCoustom from 'components/Commons/JapaneseDateCoustom'
import Color from 'constants/Color'

class WS0248001_PersonalInfoSearchQuery extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Lo_PersonalNumId: PropTypes.any,
    Li_OfficeCode: PropTypes.any,
    Li_BranchStoreCode: PropTypes.any,
    Li_office_kanji_name: PropTypes.any,

    // show/hide card title
    showTitle: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    // document.title = '個人情報検索・照会'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: '',

      dataSource: [],
      // selectedRowKey: [],
      selectedRow: {},
      indexTable: 0,

      disabledByOfficeCode: false,

      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      tablePageDataRes: {},
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      office_code: this.props.Li_OfficeCode ? this.props.Li_OfficeCode : '',
      branch_store_code: this.props.Li_BranchStoreCode ? this.props.Li_BranchStoreCode : '',
      office_kanji_name: this.props.Li_office_kanji_name ? this.props.Li_office_kanji_name : '',
      searchName: this.props.searchName ? this.props.searchName : ''
    })

    //IDもしくは事業所・支店コードが入力されていた場合のみ初期表示時に検索を行う
    if (this.props.searchName || this.props.Li_OfficeCode || this.props.Li_BranchStoreCode) {
      // 個人情報の検索処理
      this.getPersonalInfoSearch()
    }

    if (this.props.Li_Date) {
      console.log(this.props.Li_Date);
    }
  }


  /**
   * 個人情報の検索処理
   * @param {*} page
   * @param {*} pageSize
   */
  getPersonalInfoSearch = () => {

    if (this.formRef.current?.getFieldValue('DateOfBirthSearch') === false) { //明示的に[=== false]で比較する
      message.error('生年月日が不正な入力です')
      return
    }

    let params = {
      ...this.formRef.current?.getFieldValue(),
      date: this.props.date
    }

    PersonalInfoMaintainAction.getPersonalInfoSearch(params)
      .then((res) => {
        if (res) {
          const data = [...res]
          let selectIndex = 0
          if (this.props.Li_PersonalNum) {
            //親画面で個人番号が設定されている場合
            const index = data.findIndex((v) => v.personal_number_id === this.props.Li_PersonalNum)
            if (index > -1) {
              selectIndex = index
            }
          }
          this.setState({
            dataSource: data,
            selectedRow: (res.length > 0) ? data[selectIndex] : null,
          })
        }
      })
  }

  /**
   * 個人情報照会 モーダル
   */
  showWS2584019_PersonalInfoInquirySub = (personalNumId = '') => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={personalNumId}
            Li_SelectDay={this.props.Li_Date ?? ''}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='personal-info-search-query'>
        <Card
          title={this.props.hideTitle ? null : '個人情報検索・照会'} // ※[WS0343001_個人情報保守]にてthis.props.hideTitleをtrueにしている
          style={{ border: (this.props.hideTitle ?? false) ? 'none' : '' }}
          bodyStyle={{ padding: (this.props.hideTitle ?? false) ? 0 : '' }}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search' >
                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal' style={{ gap: 10 }}>

                      <Space direction="vertical" >
                        <Space>
                          <Form.Item
                            label='検索'
                            name='searchName'
                            style={{ marginBottom: 0 }}>
                            <Input
                              className='input-search-size-10'
                              onPressEnter={(event) => this.getPersonalInfoSearch()}
                            />
                          </Form.Item>
                          <Form.Item
                            name='DateOfBirthSearch'
                            label='生年月日'
                            style={{ marginBottom: 0, marginLeft: 10 }}>
                            <JapaneseDateCoustom
                              formRef={this.formRef}
                            />
                          </Form.Item>
                        </Space>
                        <Space>

                          <Form.Item
                            label='事業所'
                            name='office_code'
                            style={{ marginBottom: 0 }}
                          >
                            <Input.Search
                              className='input-search-size-number-5'
                              onSearch={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1200,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name }) => {
                                          this.formRef.current.setFieldsValue({
                                            office_code: Lio_OfficeCode,
                                            branch_store_code: Lio_BranchStoreCode,
                                            office_kanji_name: Lo_Kanji_Name,
                                          })
                                          this.getPersonalInfoSearch()
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}
                              onPressEnter={(event) => {
                                this.formRef.current.setFieldsValue({
                                  office_kanji_name: '',
                                  branch_store_code: event.target.value ? this.formRef.current.getFieldValue('branch_store_code') : ''
                                })
                                this.getPersonalInfoSearch()
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            name='branch_store_code'
                            style={{ marginBottom: 0 }}>
                            <Input.Search
                              className='input-search-size-number-2'
                              onPressEnter={(event) => this.getPersonalInfoSearch()}
                              onSearch={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1200,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name }) => {
                                          this.formRef.current.setFieldsValue({
                                            office_code: Lio_OfficeCode,
                                            branch_store_code: Lio_BranchStoreCode,
                                            office_kanji_name: Lo_Kanji_Name,
                                          })
                                          this.getPersonalInfoSearch()
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  },
                                })
                              }}
                            />
                          </Form.Item>

                          <Form.Item style={{ marginBottom: 0 }}>
                            <div>
                              {this.formRef.current?.getFieldValue('office_kanji_name')}
                            </div>
                          </Form.Item>

                        </Space>


                      </Space>









                    </div>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.getPersonalInfoSearch()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <div
                style={{ height: 'calc(100% - 1rem)' }}
              >
                <Table
                  size='small'
                  style={{ cursor: 'pointer' }}
                  dataSource={this.state.dataSource}
                  rowKey={(record) => record.id}
                  scroll={{ y: 450 }}
                  bordered
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        if (this.props.onFinishScreen) {
                          this.props.onFinishScreen({
                            Lo_PersonalNumId: record.personal_number_id,
                            recordData: record,
                          })
                        }
                      }
                    }
                  }}
                  pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                >
                  <Table.Column
                    title='個人番号'
                    dataIndex='personal_number_id'
                    width={120}
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value}</div>
                      )
                    }}
                  />
                  <Table.Column title='メモ' dataIndex='importance' width={40} align='center'
                    render={(value, record, index) => {
                      switch (record.importance) {
                        case 1:
                          return (
                            <Tooltip title={record?.content}>
                              <InfoCircleOutlined
                                style={{ fontSize: 20, color: '#1890ff' }}
                                onClick={() => {
                                  this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                                }}
                              />
                            </Tooltip>
                          )
                        case 3:
                          return (
                            <Tooltip title={record?.content}>
                              <WarningOutlined
                                style={{ fontSize: 20, color: '#faad14' }}
                                onClick={() => {
                                  this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                                }}
                              />
                            </Tooltip>
                          )
                        case 5:
                          return (
                            <Tooltip title={record?.content}>
                              <CloseCircleOutlined
                                style={{ fontSize: 20, color: '#ff4d4f' }}
                                onClick={() => {
                                  this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                                }}
                              />
                            </Tooltip>
                          )
                        default:
                          return <Button
                            size='small'
                            icon={<MoreOutlined />}
                            onClick={() => {
                              this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                            }}
                          />
                      }
                    }}
                  />
                  <Table.Column title='カナ氏名' dataIndex='kana_name' />
                  <Table.Column title='氏名' dataIndex='kanji_name' />
                  <Table.Column title='性別' dataIndex='sex' width={60}
                    render={(value, record, index) => {
                      let name = ''
                      if (value === 1) {
                        name = '男性'
                      } else if (value === 2) {
                        name = '女性'
                      }
                      return (
                        <div
                          style={{
                            color: record.colorNum
                              ? Color(record.colorNum)?.Foreground
                              : '',
                            textAlign: 'center'
                          }}>
                          {name}
                        </div>
                      )
                    }}
                  />
                  <Table.Column title='生年月日' dataIndex='birthday_on' width={160}
                    render={(value, record, index) => {
                      return (
                        value ? (
                          <div>
                            {`${moment(value).format('NNyy/MM/DD')} (${moment(value).format('YYYY')})`}
                          </div>
                        ) : ''
                      )
                    }}
                  />
                  <Table.Column title='年度年齢' dataIndex='age_in_years' width={80}
                    render={(value, record, index) => {
                      return (
                        <div style={{ textAlign: 'right' }}>
                          {value}
                        </div>
                      )
                    }}
                  />
                  <Table.Column title='続柄' dataIndex='name' width={60} />
                  <Table.Column title='事業所' dataIndex='office_kanji_name' />
                  <Table.Column
                    width={40}
                    title={
                      <Button
                        type='primary'
                        size='small'
                        icon={<PlusOutlined />}
                        onClick={() =>
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1600,
                              component: (
                                <WS0343001_PersonalInfoMaintain
                                  Li_PersonalNum={''}
                                  onFinishScreen={(output) => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })}
                      />}
                    render={(value, record) => {
                      return (
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          onClick={() =>
                            this.showWS2584019_PersonalInfoInquirySub(record.personal_number_id)
                          }
                        />
                      )
                    }} />
                </Table>
              </div>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0248001_PersonalInfoSearchQuery)
