import React from "react";
import { connect } from "react-redux";

import { Card, Table, Button, Form, Dropdown, Menu } from "antd";
import { PlusOutlined, MoreOutlined, CheckOutlined } from '@ant-design/icons';
import WS1872005_SiteFindingsMigration from './WS1872005_SiteFindingsMigration'
import ModalDraggable from "components/Commons/ModalDraggable";
import RadiographySiteFindingsMaintainAction from 'redux/InspectionMaintenance/RadiographyInspectMaintain/RadiographySiteFindingsMaintain.actions'
import WS1872002_ExpansionDisplay from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872002_ExpansionDisplay.jsx'
import WS1872006_EditDataSite1 from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872006_EditDataSite1.jsx'
import WS1872006_EditDataSite2 from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872007_EditDataSite2.jsx'
import WS1872008_EditDataFindings1 from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872008_EditDataFindings1.jsx'
import WS1872009_EditDataFindings2 from 'pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872009_EditDataFindings2.jsx'
class WS1872001_RadiographySiteFindingsMaintain extends React.Component {
  constructor(props) {
    super(props);

    // document.title = "読影部位所見保守";

    this.state = {
      initialValues: {
        Li_InspectClassifyCode: ""
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: true,
      site1: [],
      site2: [],
      findings1: [],
      findings2: [],
      selectedRowSite1: {},
      selectedRowSite2: {},
      selectedRowFindings1: {},
      selectedRowFindings2: {}
    };
  }

  componentDidMount() {
    this.setState({
      initialValues: {
        Li_InspectClassifyCode: this.props.Li_InspectClassifyCode
      }
    })
    this.getInitialValues()
  }

  // 部位1から順番にデータを取得
  getInitialValues = () => {
    let SiteParam = {
      Li_InspectClassifyCode: this.props.Li_InspectClassifyCode,
    }

    // 部位1情報　取得
    RadiographySiteFindingsMaintainAction.getSite1(SiteParam)
      .then(res => {
        this.setState({
          site1: res,
          selectedRowSite1: res[0]
        })

        let initialSiteDisplay = {
          // これがないと一部検査で部位所見開くとエラーになって固まる
          Li_InspectClassifyCode: res[0]?.exam_classification_code ?? '',
          Li_SiteClassify: res[0]?.site_classification_io ?? ''
        }

        // 部位2情報
        this.getSite2Values(initialSiteDisplay)
      })
  }

  // 部位2情報　取得
  getSite2Values = (param) => {
    RadiographySiteFindingsMaintainAction.getSite2(param)
      .then(res => {
        this.setState({
          site2: res,
          selectedRowSite2: res[0]
        })

        let FindingsClass = ''
        if (res.length > 0) {
          FindingsClass = res[0].findings_classification_i
        }

        let findingsParam = {
          Li_InspectClassifyCode: this.props.Li_InspectClassifyCode,
          Li_FindingsClassify1: this.state.selectedRowSite1?.findings_classification_i ?? '',
          Li_FindingsClassify2: FindingsClass
        }

        // 所見1情報
        this.getfindings1Values(findingsParam)
      })
  }

  // 所見1情報　取得
  getfindings1Values = (param) => {
    RadiographySiteFindingsMaintainAction.getfindings1(param)
      .then(res => {
        this.setState({
          findings1: res,
          selectedRowFindings1: res[0]
        })

        let FindingsClassFication = ''
        if (res.length > 0) {
          FindingsClassFication = res[0].findings_classification_io
        }

        let findingsParam = {
          Li_InspectClassifyCode: this.props.Li_InspectClassifyCode,
          Li_FindingsClassify1: this.state.selectedRowSite1?.findings_classification_i ?? '',
          Li_FindingsClassify2: this.state.selectedRowSite2?.findings_classification_i ?? '',
          Li_FindingsClassify3: FindingsClassFication
        }

        // 所見2情報
        this.getfindings2Values(findingsParam)
      })
  }

  // 所見2情報　取得
  getfindings2Values = (param) => {
    RadiographySiteFindingsMaintainAction.getfindings2(param)
      .then(res => {
        this.setState({
          findings2: res,
          selectedRowFindings2: res[0]
        })
      })
  }

  // 部位1が切り替わった時に動く処理
  RowSelectionUpdateSite1 = (record) => {
    this.setState({
      selectedRowSite1: record
    })

    let SelectionSiteDisplay = {
      Li_InspectClassifyCode: record.exam_classification_code,
      Li_SiteClassify: record.site_classification_io ?? ''
    }

    // 部位2以降の更新後の情報を取得
    this.getSite2Values(SelectionSiteDisplay)

  }

  // 部位2が切り替わった時に動く処理
  RowSelectionUpdateSite2 = (record) => {
    this.setState({
      selectedRowSite2: record
    })

    let SelectionSiteDisplay = {
      Li_InspectClassifyCode: record.exam_classification_code,
      Li_FindingsClassify1: this.state.selectedRowSite1?.findings_classification_i ?? '',
      Li_FindingsClassify2: record.findings_classification_i
    }

    // 所見1以降の更新後の情報を取得
    this.getfindings1Values(SelectionSiteDisplay)
  }

  // 所見1が切り替わった時に動く処理
  RowSelectionUpdateFindings1 = (record) => {
    this.setState({
      selectedRowFindings1: record
    })

    let SelectionSiteDisplay = {
      Li_InspectClassifyCode: this.props.Li_InspectClassifyCode,
      Li_FindingsClassify1: this.state.selectedRowSite1?.findings_classification_i ?? '',
      Li_FindingsClassify2: this.state.selectedRowSite2?.findings_classification_i ?? '',
      Li_FindingsClassify3: record.findings_classification_io ?? '',
    }

    // 所見2以降の更新後の情報を取得
    this.getfindings2Values(SelectionSiteDisplay)
  }

  // 所見2が切り替わった時に動く処理
  RowSelectionUpdateFindings2 = (record) => {
    this.setState({
      selectedRowFindings2: record
    })
  }

  // 部位1 新規・編集
  editDataSite1 = (newFlag, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1872006_EditDataSite1
            newFlag={newFlag}
            record={record}
            onSave={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode
              }
              RadiographySiteFindingsMaintainAction.saveSite1(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}

            onDelete={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode
              }
              RadiographySiteFindingsMaintainAction.deleteSite1(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}
          />
        ),
      },
    });
  }

  // 部位2 新規・編集
  editDataSite2 = (newFlag, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1872006_EditDataSite2
            newFlag={newFlag}
            record={record}
            onSave={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode,
                site_classification_io: this.state.selectedRowSite1.site_classification_io
              }
              RadiographySiteFindingsMaintainAction.saveSite2(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}

            onDelete={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode,
                site_classification_io: this.state.selectedRowSite1.site_classification_io
              }
              RadiographySiteFindingsMaintainAction.deleteSite2(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}
          />
        ),
      },
    });
  }

  // 所見1　新規・編集
  editDataFindings1 = (newFlag, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1872008_EditDataFindings1
            newFlag={newFlag}
            record={record}
            onSave={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode,
                Li_FindingsClassify1: this.state.selectedRowSite1?.findings_classification_i ?? '',
                Li_FindingsClassify2: this.state.selectedRowSite2?.findings_classification_i ?? '',
              }
              RadiographySiteFindingsMaintainAction.saveFindings1(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}

            onDelete={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode,
                site_classification_io: this.state.selectedRowSite1.site_classification_io
              }
              RadiographySiteFindingsMaintainAction.deleteFindings1(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}
          />
        ),
      },
    });
  }

  //  所見2 新規・編集
  editDataFindings2 = (newFlag, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1872009_EditDataFindings2
            newFlag={newFlag}
            record={record}
            onSave={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode,
                Li_FindingsClassify1: this.state.selectedRowSite1?.findings_classification_i ?? '',
                Li_FindingsClassify2: this.state.selectedRowSite2?.findings_classification_i ?? '',
                findings_classification_io: this.state.selectedRowFindings1?.findings_classification_io ?? '',
              }
              RadiographySiteFindingsMaintainAction.saveFindings2(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}

            onDelete={(output) => {
              let param = {
                ...output,
                exam_classification_code: this.props.Li_InspectClassifyCode,
                Li_FindingsClassify1: this.state.selectedRowSite1?.findings_classification_i ?? '',
                Li_FindingsClassify2: this.state.selectedRowSite2?.findings_classification_i ?? '',
                findings_classification_io: this.state.selectedRowFindings1?.findings_classification_io ?? '',
              }
              RadiographySiteFindingsMaintainAction.deleteFindings2(param)
                .then(res => {
                  this.closeModal()
                  this.getInitialValues()
                })
            }}
          />
        ),
      },
    });
  }

  // 拡大表示処理
  expansionDisplay = (number, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1872002_ExpansionDisplay
            number={number}
            record={record}
          />
        )
      }
    })
  }

  // 移動処理
  move = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS1872005_SiteFindingsMigration
            Li_InspectClassifyCode={this.state.initialValues.Li_InspectClassifyCode}
            onFinishScreen={() => {
              this.closeModal()
              this.props.onFinishScreen()
            }}
          />
        ),
      },
    });
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="radiography-site-findings-maintain">
        <Card title="読影部位所見保守" className="mb-2">
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            {/* <Button type="primary">出力複写</Button>　*/}

            <div className='box_inner_horizontal'>
              <Table
                bordered
                dataSource={this.state.site1}
                rowKey={(record) => record.id}
                pagination={false}
                size="small"
                style={{ width: '25%' }}
                scroll={{ x: 'max-content', y: 650 }}
                rowClassName={(record, index) => record.id === this.state.selectedRowSite1?.id ? 'table-row-light' : ''}
              >
                <Table.Column
                  className='column-size-5'
                  title="コード"
                  dataIndex="site_code"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateSite1(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  width={'10rem'}
                  title="部位1"
                  dataIndex="site_name"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateSite1(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="SEQ"
                  dataIndex="display_order"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateSite1(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  className='column-size-8'
                  title="部位分類"
                  dataIndex="site_classification_io"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateSite1(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  width={'10rem'}
                  title="所見分類1"
                  dataIndex="findings_classification_i"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateSite1(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  fixed="right"
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      style={{ textAlign: 'center' }}
                      disabled={this.state.isAddRow}
                      onClick={() => {
                        this.editDataSite1(true, false)
                      }}
                    />
                  }
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='変更'
                              onClick={() => {
                                this.editDataSite1(false, record)
                              }}
                            >変更
                            </Menu.Item>

                            <Menu.Item
                              key='移動'
                              onClick={() => {
                                this.move()
                              }}
                            >移動
                            </Menu.Item>

                            <Menu.Item
                              key='拡大'
                              onClick={() => {
                                this.expansionDisplay(1, this.state.site1)
                              }}
                            >拡大
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>

              <Table
                bordered
                dataSource={this.state.site2}
                rowKey={(record) => record.id}
                pagination={false}
                size="small"
                style={{ width: '25%' }}
                scroll={{ x: 'max-content', y: 650 }}
                rowClassName={(record, index) => record.id === this.state.selectedRowSite2?.id ? 'table-row-light' : ''}
              >
                <Table.Column
                  className='column-size-5'
                  title="コード"
                  dataIndex="site_code"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateSite2(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  width={'10rem'}
                  title="部位2"
                  dataIndex="site_name"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateSite2(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="SEQ"
                  dataIndex="display_order"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateSite2(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  width={'10rem'}
                  title="所見分類2"
                  dataIndex="findings_classification_i"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateSite2(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  fixed="right"
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      style={{ textAlign: 'center' }}
                      disabled={this.state.isAddRow}
                      onClick={() => {
                        this.editDataSite2(true, false)
                      }}
                    />
                  }
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='変更'
                              onClick={() => {
                                this.editDataSite2(false, record)
                              }}
                            >変更
                            </Menu.Item>

                            <Menu.Item
                              key='拡大'
                              onClick={() => {
                                this.expansionDisplay(2, this.state.site2)
                              }}
                            >拡大
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>

              <Table
                bordered
                dataSource={this.state.findings1}
                rowKey={(record) => record.id}
                pagination={false}
                size="small"
                style={{ width: '25%' }}
                scroll={{ x: 'max-content', y: 650 }}
                rowClassName={(record, index) => record.id === this.state.selectedRowFindings1?.id ? 'table-row-light' : ''}
              >
                <Table.Column
                  className='column-size-5'
                  title="コード"
                  dataIndex="findings_code"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateFindings1(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  width={'10rem'}
                  title="所見1"
                  dataIndex="findings_name"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateFindings1(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="判定"
                  dataIndex="judgement"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'center' }}
                      onClick={() => { this.RowSelectionUpdateFindings1(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="正常"
                  dataIndex="normal_value_flag"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'center' }}
                      onClick={() => { this.RowSelectionUpdateFindings1(record) }}
                    >
                      {text === 0 ? '' : <CheckOutlined />}
                    </div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="SEQ"
                  dataIndex="display_order"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateFindings1(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  width={'10rem'}
                  title="所見分類3"
                  dataIndex="findings_classification_io"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateFindings1(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  fixed="right"
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      style={{ textAlign: 'center' }}
                      disabled={this.state.isAddRow}
                      onClick={() => {
                        this.editDataFindings1(true, false)
                      }}
                    />
                  }
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='変更'
                              onClick={() => {
                                this.editDataFindings1(false, record)
                              }}
                            >変更
                            </Menu.Item>

                            <Menu.Item
                              key='拡大'
                              onClick={() => {
                                this.expansionDisplay(3, this.state.findings1)
                              }}
                            >拡大
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>

              <Table
                bordered
                dataSource={this.state.findings2}
                rowKey={(record) => record.id}
                pagination={false}
                size="small"
                style={{ width: '25%' }}
                scroll={{ x: 'max-content', y: 650 }}
                rowClassName={(record, index) => record.id === this.state.selectedRowFindings2?.id ? 'table-row-light' : ''}
              >
                <Table.Column
                  className='column-size-5'
                  title="コード"
                  dataIndex="findings_code"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateFindings2(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  width={'10rem'}
                  title="所見2"
                  dataIndex="findings_name"
                  render={(text, record, index) => (
                    <div onClick={() => { this.RowSelectionUpdateFindings2(record) }}>{text ? text : '　'}</div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="判定"
                  dataIndex="judgement"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'center' }}
                      onClick={() => { this.RowSelectionUpdateFindings2(record) }}
                    >
                      {text ? text : '　'
                      }</div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="正常"
                  dataIndex="normal_value_flag"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'center' }}
                      onClick={() => { this.RowSelectionUpdateFindings2(record) }}
                    >
                      {text === 0 ? '' : <CheckOutlined />}
                    </div>
                  )}
                />

                <Table.Column
                  className='column-size-4'
                  title="SEQ"
                  dataIndex="display_order"
                  render={(text, record, index) => (
                    <div
                      style={{ textAlign: 'right' }}
                      onClick={() => { this.RowSelectionUpdateFindings2(record) }}
                    >
                      {text ? text : '　'}
                    </div>
                  )}
                />

                <Table.Column
                  fixed="right"
                  align='center'
                  width={40}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      style={{ textAlign: 'center' }}
                      disabled={this.state.isAddRow}
                      onClick={() => {
                        this.editDataFindings2(true, false)
                      }}
                    />
                  }
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='変更'
                              onClick={() => {
                                this.editDataFindings2(false, record)
                              }}
                            >変更
                            </Menu.Item>

                            <Menu.Item
                              key='拡大'
                              onClick={() => {
                                this.expansionDisplay(4, this.state.findings2)
                              }}
                            >拡大
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1872001_RadiographySiteFindingsMaintain);
