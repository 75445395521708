import React from "react";
import { Input, Form, Card, Button, Table, Select, } from "antd";
import { SaveOutlined, } from "@ant-design/icons";
import OfficeInfoMaintainService from "services/basicInfo/OfficeInfoMaintain/OfficeInfoMaintainService.js";
import PropTypes from "prop-types";
import * as wanakana from "wanakana";

class WS0341500_OfficeInfoSupplement extends React.Component {
  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0,
      dataSource: [],
      disabledSaveBtn: true
    };
  }


  componentDidMount = () => {
    this.setState({
      dataSource: this.props.Li_SupplementaryInfo
    })
    this.formRef.current?.setFieldsValue({
      tableData: this.props.Li_SupplementaryInfo
    })
  }


  onChangeValue = (value, record, index) => {
    let chnageValue = ''
    if (record.condition_1 === 'X') {
      chnageValue = wanakana.toRomaji(value)
    } else if (record.condition_1 === 'K') {
      chnageValue = wanakana.toKatakana(value)
    } else if (record.condition_1 === 'J') {
      chnageValue = wanakana.toHiragana(value)
    }

    let arrTemp = [...this.state.dataSource]
    let obj = {
      ...record,
      content: chnageValue, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj
    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false
    })

    this.props.onUpdate(arrTemp)
  }


  onClick = () => {
    this.props.onUpdate({
      Li_dataSource: this.state.dataSource,
      Li_isFileEdited: true
    })
  }


  render() {
    return (
      <div className="office-info-supplement">
        <Card title='補足情報'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              size='small'
              pagination={false}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              scroll={{ y: 500 }}
              bordered
            >
              <Table.Column
                title='項目'
                dataIndex='Expression_22'
              />
              <Table.Column
                title='内容'
                dataIndex='content'
                render={(value, record, index) => {
                  return (
                    <Form.Item name={['tableData', index, 'content']}>
                      {(record.StsDirectInput && (record.contents ?? false)) ?
                        // 選択項目
                        <Select
                          onChange={(event) => {
                            this.onChangeValue(event, record, index)
                          }}
                        >
                          {record.contents.map((item, index) => (
                            <Select.Option
                              key={item.label + index}
                              value={item.value}
                            >
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                        :
                        <Input
                          maxLength={record?.condition_2 || 50}
                          onBlur={event => {
                            this.onChangeValue(event.target.value, record, index)
                          }}
                        />
                      }
                    </Form.Item>
                  )
                }}
              />
            </Table>

            {/* <div className="box_button_bottom_right">
              <Button
                type='primary'
                icon={<SaveOutlined />}
                htmlType='submit'
                disabled={this.state.disabledSaveBtn}
                onClick={() => this.onClick()}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div> */}
          </Form>
        </Card>
      </div>
    )
  }
}
export default WS0341500_OfficeInfoSupplement
