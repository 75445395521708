import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ReceiptProcessSubAction from 'redux/CounterBusiness/Counter/ReceiptProcessSub.action'
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Card, Col, Form, Input, Row, Button, Menu, Dropdown, message, Tooltip, Modal } from 'antd'
import { PlusOutlined, MoreOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import {
  getIntroduceCounterAction,
  AcceptButtonAction,
  eventEnterC_CounterAction,
  getListDataCounterAction,
  getReAcquisitionAction,
  getUseBarcodeAction,
  checkAcceptProcess
} from 'redux/CounterBusiness/Counter/Counter.action'
// import { ModalCustom } from 'components/Commons/ModalCustom'
import WS2621001_PaymentProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2621001_PaymentProcessSub.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2622003_ReceiptProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622003_ReceiptProcessSub.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub.jsx'
import WS2620036_Refine from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620036_Refine'
import WS2623001_DispensingProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2623001_DispensingProcessSub'
import WS2624003_VisitsCancelConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624003_VisitsCancelConfirm.jsx'
import Color from 'constants/Color'
import WS0956001_PaymentCorrectSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS0956001_PaymentCorrectSub.jsx'
import WS2624002_VisitsChangeConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624002_VisitsChangeConfirm'
import WS2537501_acceptablePersonalReserveProcessMock from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537501_acceptablePersonalReserveProcessMock.jsx'
import WS2622004_ReceiptProcessSubBulk from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622004_ReceiptProcessSubBulk.jsx'
import WS2620100_ReservationSelect from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620100_ReservationSelect'
import changeHankakuText from 'components/Commons/changeHankakuText'
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0946006_ReceiptIssueOnline from "pages/BL_AccountingBusiness/SMIYA0502_ReceiptPreIssue20/WS0946006_ReceiptIssueOnline";
import PaymentProcessSubAction from "redux/CounterBusiness/Counter/PaymentProcessSub.action";
import { loading } from 'redux/common/common.reducer'

const styleContentTableColumn = {
  color: Color(209).Foreground,
  fontWeight: 'lighter',
  fontSize: '18px',
  marginBottom: '5px',
  height: '22px',
  textAlign: 'center'
}

const styleDivTitle = {
  background: '#1C66B9',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em',
  color: '#FFFFFF'
}

const styleDivResult = {
  border: '1px solid rgba(0, 0, 0, 0.06)',
  textAlign: 'center',
  height: '32px',
  padding: '0.3em'
}

const styleDivExpression = {
  width: '50px',
  height: '24px',
  border: '1px solid #ABADB3',
  textAlign: 'center',
  lineHeight: '22px',
}

const dateFormat = 'YYYY/MM/DD'

class WS2620001_Counter extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '窓口'
    const queryParameter = {
      DateChar: moment().format(dateFormat),
      ReserveNumAndPersonalNum: '',
      FacilityType: 0,
    }
    const receiptNumber = ''
    // １文字以上の数字のみ
    if (receiptNumber.match(/^[0-9]+$/)) {
      queryParameter.receiptNumber = receiptNumber
    }

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      widthWS2622003: '',
      dataSource: [],
      record: {},
      selectedId: '',
      FacilityTypeList: [],
      iniObj: {},
      importance: 0,
      initParams: queryParameter,
      Li_Change: '',
      menuItems: [
        { id: 1, label: '再表示', handleClick: this.eventF10 },
        { id: 1, label: '新規受付', handleClick: this.eventF11 },
      ],
      examNameList: [],
      // AllMemoFunctionUpdateBtn: true,
      initialValues: {},
      //LoadPanel
      loadPanelVisible: false,
      showIndicator: true,
      shading: true,
      showPane: true,
      hideOnOutsideClick: false
    }
    this.HandelF7 = this.HandelF7.bind(this)

    //LoadPanel
    this.hideLoadPanel = this.hideLoadPanel.bind(this);
    this.onShowIndicatorChange = this.onShowIndicatorChange.bind(this);
    this.onShadingChange = this.onShadingChange.bind(this);
    this.onShowPaneChange = this.onShowPaneChange.bind(this);
    this.onHideOnOutsideClickChange = this.onHideOnOutsideClickChange.bind(this);

  }

  //LoadPanel

  hideLoadPanel() {
    this.setState({ loadPanelVisible: false });
  }

  onShowIndicatorChange(e) {
    this.setState({ showIndicator: e.value });
  }

  onShadingChange(e) {
    this.setState({ shading: e.value });
  }

  onShowPaneChange(e) {
    this.setState({ showPane: e.value });
  }

  onHideOnOutsideClickChange(e) {
    this.setState({ hideOnOutsideClick: e.value });
  }

  componentDidMount = () => {
    this.loadInitData(this.state.initParams)
  }

  loadInitData = (params) => {
    getIntroduceCounterAction(params)
      .then(res => {
        this.setState({
          iniObj: res.data,
          FacilityTypeList: res.data.FacilityType,
          loadPanelVisible: true
        })
        this.loadData()
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  loadData = async () => {
    let params = {
      ...this.state.initParams
    }
    await getListDataCounterAction(params)
      .then(res => {
        if (res.data) {
          let mapExamItem = []
          if (res?.data?.VisitList.length > 0) {
            Object.keys(res?.data.VisitList[0].itemList2).forEach((key) => {
              if (res.data.VisitList[0].itemList2[key]) {
                mapExamItem.push(res.data.VisitList[0].itemList2[key])
              } else {
                mapExamItem.push('')
              }
            })
            if (this.formRef.current?.getFieldValue('receiptNumber')) {
              if (res?.data.VisitList[0].Expression_23 === '○') {
                let props = {
                  Li_CourseLevel: res?.data.VisitList[0].course_level,
                  Li_ReserveNum: res?.data.VisitList[0].reservation_number,
                  Li_PersonalNum: res?.data.VisitList[0].personal_number_id,
                  Li_Date: this.state.initParams.DateChar,
                  Li_Getctxname: '',
                  Li_ProcessDivision: '',
                  Li_Option: '',
                  Li_Child: true
                }
                this.personalReserveProcess(props)
              }
            }
            // 一定時間後にLoadPanelを非表示にする
            setTimeout(this.hideLoadPanel, res?.data?.VisitList.length * 5);
          } else {
            this.hideLoadPanel()
          }

          this.setState({
            dataSource: res.data.VisitList,
            record: this.state.record?.id
              ? this.state.record
              : res.data.VisitList.length > 0 ? res.data.VisitList[0] : {},
            examNameList: mapExamItem,
          })
        }
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  checkWidthInWS2622003(props) {
    ReceiptProcessSubAction.getScreenData({ Li_ReserveNum: props.Li_ReserveNum }).then(item => {
      if (item) {
        const width25 = item.sts1 > 0 && item.sts2 === 0 && item.sts3 === 0 ? '25%' : null
        const width50 = item.sts1 > 0 && (item.sts2 > 0 || item.sts3 > 0) ? '50%' : null
        const width80 = item.sts1 > 0 && item.sts2 > 0 && item.sts3 > 0 ? '80%' : null
        const checkShow = item.sts1 === 0 && item.sts2 === 0 && item.sts3 === 0 ? false : true
        const width = width25 ? width25 : (width50 ? width50 : (width80 ? width80 : ''))
        this.setState({ widthWS2622003: width })
        if (checkShow) {
          this.callModal(props, this.state.widthWS2622003, 'WS2622003_ReceiptProcessSub')
        }
      }
    })
  }

  // 領収ボタンが押された時の処理
  displayWS2622004ReceiptProcessSubBulk(paymentFlag, record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2622004_ReceiptProcessSubBulk
            paymentFlag={paymentFlag}
            record={record}
            Li_ReserveNum={record.reservation_number}
            Li_SpecifiedIdentify={''}
            Li_SelectOptions={
              [
                { value: record.kanji_name, label: record.kanji_name },
                { value: record.office_kanji_name, label: record.office_kanji_name }
              ]
            }

            reloadData={() => {
              this.loadData()
            }}

            onFinishScreen={() => {
              this.loadData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }
  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }
  renderForm(i) {
    const value = this.state[`getIndex${i}`]
    this.setFormFieldValue(`ReceiptDateChar${i}`, value && value?.ReceiptDateChar ? moment(value?.ReceiptDateChar) : '')
    this.setFormFieldValue(`W1_destination_name${i}`, value && value?.W1_destination_name[0] ? value?.W1_destination_name[0] : '')
    this.setFormFieldValue(`receiptNumber${i}`, value && value?.receiptNumber ? value?.receiptNumber : '-')
    this.setFormFieldValue(`W1_subject${i}`, value && value?.W1_subject ? value?.W1_subject : '')
    this.setFormFieldValue(`DepositAndWithdrawalForm${i}`, value && value?.DepositAndWithdrawalForm ? value?.DepositAndWithdrawalForm : '')
    this.setFormFieldValue(`identify${i}`, value && value?.identify ? value?.identify : '')
  }
  // 入金が×の時に領収が押されたとき
  automaticdeposit = async (Li_ReserveNum) => {
    await PaymentProcessSubAction.getScreenData({ Li_ReserveNum }).then(res => {
      console.log(res)
      const data = res?.data;
      const obj = { Linked: '', Display: '' }
      const card = data.Card
      card.unshift(obj)
      this.setState({ initialValues: { ...data, Card: card } })
      const item = this.state.initialValues;
      const params = {
        PayDay: res.data.PayDay,
        AmountBilled: res.data.AmountBilled,
        DepositAmount: res.data.DepositAmount,
        ReceiptAmount: item.ReceiptAmount,
        Otsuri: item.Otsuri,
        PayManageNum: item.PayManageNum,
        StsAnotherDay1: res.data.StsAnotherDay1,
        Li_ReserveNum: item.Li_ReserveNum,
        BillingManageNum: item.BillingManageNum,
        PayType: res.data.PayType,
        Card: res.Card ? res.Card : '',
        RegisterClassify: item.RegisterClassify,
        Date: item.Date,
        StsPreReceipt: res.data.StsPreReceipt,
        ReceiptDateInitialValue: item.ReceiptDateInitialValue,
        StsAnotherDate2: res.data.StsAnotherDate2,
        StsAnotherDate3: res.data.StsAnotherDate3,
      }
      PaymentProcessSubAction.confirmF12(params).then(res => {
        const data = { Li_ReserveNum: res.data.Li_ReserveNum, Li_SpecifiedIdentify: res.data.Li_SpecifiedIdentify }
        PaymentProcessSubAction.getScreenData(data).then(res => {
          ReceiptProcessSubAction.getScreenData(data).then(res => {
            this.setFormFieldValue('sts1', res && res.sts1 ? res.sts1 : 0)
            this.setFormFieldValue('sts2', res && res.sts2 ? res.sts2 : 0)
            this.setFormFieldValue('sts3', res && res.sts3 ? res.sts3 : 0)
            let cards = [
              { sts: res.sts1, receipt: res.ReceiptManageNum1Sheet, title: res.Title1Sheet },
              { sts: res.sts2, receipt: res.ReceiptManageNum2Nd, title: res.Title2Nd },
              { sts: res.sts3, receipt: res.ReceiptManageNum3Rd, title: res.Title3Rd }
            ]
            this.setState({
              ...this.state,
              ScreenData: res,
              Cards: cards
            })
            const params = {
              Cards: cards,
              Li_ReserveNum: data.Li_ReserveNum,
            }
            ReceiptProcessSubAction.getIndex(params).then(res => {
              console.log(res)
              res.forEach((item, i) => {
                const index = item.identify
                let array = []
                item.W1_destination_name.forEach((item, l) => {
                  array = [...array, { value: item }]
                })
                this.setState({
                  ...this.state,
                  [`getIndex${index}`]: item,
                  [`OptionInspect${index}`]: item.OptionInspectData,
                  [`W1_destination_name${index}`]: array,
                })
                this.renderForm(index)
              })
              let list = [];
              let receiptManagementNumberList = [];
              this.state.Cards.forEach((s, i) => (
                list.push({
                  receipt: s.receipt,
                  price: s.title,
                  destination_name: this.formRef?.current?.getFieldValue(`W1_destination_name${i + 1}`),
                  subject: this.formRef?.current?.getFieldValue(`W1_subject${i + 1}`),
                  receiptDateChar: moment(this.formRef?.current?.getFieldValue(`ReceiptDateChar${i + 1}`)).format("YYYY/MM/DD"),
                  receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
                  flag: this.formRef?.current?.getFieldValue(`sts${i + 1}`) ? 1 : 0
                })
              ));
              let params = {
                Li_ReserveNum: data.Li_ReserveNum,
                list: list
              }
              //チェックが入っているモノのreceiptを取得
              this.state.Cards.forEach((s, i) => {
                if (this.formRef?.current?.getFieldValue(`sts${i + 1}`)) {
                  receiptManagementNumberList.push({
                    receiptManagementNumber: s.receipt,
                    receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
                  })
                }
              });
              ReceiptProcessSubAction.confirmF12(params)
            })
          })
        })
      })
    })

  }

  HandelF7 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2620036_Refine
            Lio_State={this.formRef.current.getFieldValue('State')}
            Lio_Pay={this.formRef.current.getFieldValue('Pay')}
            Lio_Facility={this.formRef.current.getFieldValue('FacilityType')}
            onFinishScreen={(output) => {
              if (output) {
                this.closeModal()
              }

            }}
          />
        ),
      },
    })
  }

  eventF10 = () => { }

  eventF11 = () => {
    let props = {
      Li_CourseLevel: '',
      Li_ReserveNum: '',
      Li_PersonalNum: '',
      Li_Date: this.state.initParams.DateChar,
      Li_Getctxname: '',
      Li_ProcessDivision: 1,
      Li_Option: '',
      Li_Child: true,
      state_flag: 0
    }
    this.personalReserveProcess(props)
  }

  personalReserveProcess(props, receipt) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2537001_PersonalReserveProcess
            {...props}
            onFinishScreen={(outPut) => {
              if (outPut?.Lo_Update) {
                this.setState({ Li_Change: Math.round(Math.random() * 1000) })
              }
              if (outPut?.reserveNum !== '' && outPut?.reserveNum !== undefined) {
                let params = {
                  ...outPut,
                  state_flag: props.state_flag ?? 0
                }
                this.acceptButton(params)
              } else {
                this.loadData()
              }
              if (receipt) {
                this.closeModal()
              }
            }}
          />
        ),
      },
    })
  }

  handleChange = (value, name) => {
    if (name === 'State') {
      this.formRef?.current?.setFieldsValue({ Pay: null })
      this.setState({
        initParams: {
          ...this.state.initParams,
          PersonalId: null,
          [name]: value,
          Pay: null,
        }
      }, () => this.loadInitData(this.state.initParams))
    } else if (name === 'Pay') {
      this.formRef?.current?.setFieldsValue({ State: null })
      this.setState({
        initParams: {
          ...this.state.initParams,
          PersonalId: null,
          [name]: value,
          State: null,
        }
      }, () => this.loadInitData(this.state.initParams))
    } else {
      if (name === 'DateChar') {
        this.formRef?.current?.setFieldsValue({ receiptNumber: null })
        // 日付のフォーマットを整える
        value = moment(value).format(dateFormat)
        this.setState({
          initParams: {
            ...this.state.initParams,
            PersonalId: null,
            [name]: value,
            receiptNumber: null,
          }
        }, () => this.loadInitData(this.state.initParams))
      } else if (name === 'receiptNumber') {
        // 全角数字の場合、半角数字へ
        value = changeHankakuText(value)
        this.setState({
          initParams: {
            ...this.state.initParams,
            PersonalId: null,
            [name]: value,
          }
        }, () => this.loadInitData(this.state.initParams))
      }
    }
  }

  ReturnComponent = (component) => {
    let components = {
      WS2583001_ConsultInquirySub,
      WS2537001_PersonalReserveProcess,
      WS2624002_VisitsChangeConfirm,
      WS2624003_VisitsCancelConfirm,
      WS2621001_PaymentProcessSub,
      WS2623001_DispensingProcessSub,
      WS2622003_ReceiptProcessSub,
      WS0956001_PaymentCorrectSub,
      WS0650001_DocumentBatchCreateSub
    }
    return components[component]
  }

  acceptButton = (reserveNum) => {
    const { record, iniObj } = this.state
    let params = {
      state_flag: reserveNum.state_flag ? reserveNum.state_flag : record.state_flag,
      DeskReceptistConfirmed: this.state.iniObj.DeskReceptistConfirmed ? 1 : 0,
      course_level: reserveNum?.courseLevel ? reserveNum.courseLevel : record.course_level,
      reserve_num: reserveNum.reserveNum,
      visit_date_on: record.visit_date_on,
      personal_1_total_price: record.personal_1_total_price,
      personal_2_total_price: record.personal_2_total_price,
      personal_3_total_price: record.personal_3_total_price,
      deposit_price_1: record.deposit_price_1,
      deposit_price_2: record.deposit_price_2,
      deposit_price_3: record.deposit_price_3,
      AcceptNum: 0
    }
    AcceptButtonAction(params)
      .then(res => {
        if (res.data) {
          let props = { Li_ReserveNum: res.data.Li_ReserveNum }
          this.loadData()
        }
      })
  }

  callModal = (props, width, nameScreen, className) => {
    let Component = this.ReturnComponent(nameScreen)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        className: className,
        component: (
          <Component
            {...props}
            onFinishScreen={(outPut) => {
              if (outPut?.nameScreen === nameScreen) {
                this.loadData()
                if (outPut.StsSend) {
                  this.closeModal()
                }
              }
              this.loadData()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  renderMenu = (record) => (
    <Menu >
      {/* user action 1 照会 2583*/}
      <Menu.Item
        key='1'
        onClick={() => {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 'fit-content',
              component:
                <WS2583001_ConsultInquirySub
                  Li_ReserveNum={record.reservation_number}
                  onFinishScreen={(output) => {
                    this.closeModal()
                  }}
                />
              ,
            },
          })
        }}>照会</Menu.Item>

      {/* user action 2 変更 2537*/}
      <Menu.Item
        key='2'
        onClick={() => {
          let props = {
            Li_CourseLevel: record.course_level,
            Li_ReserveNum: record.reservation_number,
            Li_PersonalNum: record.personal_number_id,
            Li_Date: this.state.initParams.DateChar,
            Li_Getctxname: '',
            Li_ProcessDivision: '',
            Li_Option: '',
            Li_Child: true
          }
          this.personalReserveProcess(props)
        }}>変更</Menu.Item>

      <Menu.Item
        key='4'
        hidden={!(record.state_flag === 1)}
        onClick={() => {
          let props = {
            Li_CourseLevel: record.course_level,
            Li_ReserveNum: record.reserve_num,
            Li_AcceptOrCancel: 1,
            Li_Date: '',
            Li_AcceptNum: record.AcceptNum,
            Li_DataSource: this.state.dataSource,
            Li_Record: record,
          }
          this.callModal(props, 600, 'WS2624003_VisitsCancelConfirm')
        }}>取消</Menu.Item>

      {/* user action 5 入金 2621*/}
      <Menu.Item
        key='5'
        hidden={record.Expression_23 == '×' || record.Expression_25 !== '×'}
        onClick={() => {
          let props = { Li_ReserveNum: record.reservation_number }
          this.callModal(props, 600, 'WS2621001_PaymentProcessSub')
        }}>入金</Menu.Item>

      {/* user action 6 出金 2623*/}
      <Menu.Item
        key='6'
        hidden={record.Expression_25 === '×' || record.Expression_25 === ''}
        onClick={() => {
          let props = { Li_ReserveNum: record.reservation_number }
          this.callModal(props, 500, 'WS2623001_DispensingProcessSub')
        }}>出金</Menu.Item>

      {/* user action 7 領収 2622*/}
      <Menu.Item
        key='7'
        hidden={record.Expression_27 === '' || record.Expression_23 == '×'}
        onClick={async () => {
          let props = {
            Li_SpecifiedIdentify: '',
            Li_ReserveNum: record.reservation_number,
          }
          let Li_ReserveNum = record.reservation_number
          // this.checkWidthInWS2622003(props)
          if (record.Expression_25 === '○') {
            this.displayWS2622004ReceiptProcessSubBulk(false, record)
          } else {
            await this.automaticdeposit(Li_ReserveNum)
            await this.displayWS2622004ReceiptProcessSubBulk(false, record)
          }
        }} >領収</Menu.Item>

      {/* user action 8 入金訂正 0956*/}
      <Menu.Item
        key='8'
        hidden={!record.Expression_38}
        onClick={() => {
          let props = {
            Li_BillingManageNum: record.Expression_61,
            Li_Identify: 1,
            Li_ModificationLevel: '',
          }
          this.callModal(props, 1200, 'WS0956001_PaymentCorrectSub')
        }} >{record.Expression_38}</Menu.Item>

      {/* user action 10 予約関連 0650*/}
      <Menu.Item
        key='9'
        onClick={() => {
          let props = {
            Li_CourseLevel: record.course_level,
            Li_ReserveNum: record.reservation_number,
            Li_OutputUnit: '',
            Li_OutputPattern: ''
          }
          this.callModal(props, 600, 'WS0650001_DocumentBatchCreateSub')
        }}>予約関連</Menu.Item>
    </Menu>
  )

  renderDivExpression = (start, end, object, startColor) => {
    let arrayValue = []
    if (object.id) {
      for (const property in object) {
        if (`Expression_${start}` === property && start <= end) {
          arrayValue.push({
            property: object[`Expression_${start}`] ? object[`Expression_${start}`] : '',
            color: object[`Expression_${startColor}`] ? object[`Expression_${startColor}`] : 210
          })
          start++
          startColor++
        }
      }
    } else {
      for (let index = start; index <= end; index++) {
        arrayValue.push({ property: '', color: 210 })
      }
    }
    return (
      <Row>
        <Col span={1}></Col>
        {arrayValue.map((item, index = start) => (
          <Col span={2} style={{ maxWidth: 'none' }} key={`Expression_${index}`}>
            <div style={{
              ...styleDivExpression,
              background: Color(item.color).Background,
              color: item.color === 156 ? '#FFFFFF' : '#ABADB3'
            }}>
              {item.property}
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  renderDivCacl = (title, price) => (
    <Col span={3}>
      <div style={styleDivTitle}>{title}</div>
      <div style={styleDivResult}>{
        price === 0 ? null : price?.toLocaleString()}
      </div>
    </Col>
  )

  receptionStatus = (record) => {
    // if (record.Expression_23 === '○' && record.Expression_25 === '×') {
    //   // 領収処理
    //   let params = {
    //     state_flag: record.state_flag,
    //     DeskReceptistConfirmed: this.state.iniObj.DeskReceptistConfirmed ? 1 : 0,
    //     course_level: record.course_level,
    //     reserve_num: record.reservation_number,
    //     visit_date_on: record.visit_date_on,
    //     personal_1_total_price: record.personal_1_total_price,
    //     personal_2_total_price: record.personal_2_total_price,
    //     personal_3_total_price: record.personal_3_total_price,
    //     deposit_price_1: record.deposit_price_1,
    //     deposit_price_2: record.deposit_price_2,
    //     deposit_price_3: record.deposit_price_3,
    //     AcceptNum: record.receipt_number
    //   }
    //   AcceptButtonAction(params)
    //     .then(res => {
    //       if (res.data) {
    //         let props = { Li_ReserveNum: res.data.Li_ReserveNum }
    //         if (res.data.message.includes('WS2621001_PaymentProcessSub')) {
    //           this.displayWS2622004ReceiptProcessSubBulk(true, record)
    //         } else {
    //           this.loadData()
    //         }
    //       }
    //     })
    // } else
    if (record.Expression_23 === '○') {
      // 受付変更
      let props = {
        Li_CourseLevel: record.course_level,
        Li_ReserveNum: record.reservation_number,
        Li_PersonalNum: record.personal_number_id,
        Li_Date: this.state.initParams.DateChar,
        Li_Getctxname: '',
        Li_ProcessDivision: '',
        Li_Option: '',
        Li_Child: true
      }
      this.personalReserveProcess(props)
    } else {
      // 個人受付
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 'fit-content',
          centered: true,
          component:
            <WS2537501_acceptablePersonalReserveProcessMock
              Li_iniObj={this.state.iniObj}
              Li_CourseLevel={record.course_level}
              Li_ReserveNum={record.reservation_number}
              Li_PersonalNum={record.personal_number_id}
              Li_Date={this.state.initParams.DateChar}
              Li_Getctxname={''}
              Li_ProcessDivision={''}
              Li_Option={''}
              Li_Child={true}
              onSendMirais={() => {
                this.loadData()
              }}
              onFinishScreen={() => {
                this.loadData()
                this.closeModal()
              }}
            />
        },
      })
    }
  }

  EnterC = () => {
    eventEnterC_CounterAction({
      ReserveNumAndPersonalNum: this.state.initParams.ReserveNumAndPersonalNum,
      DateChar: this.state.initParams.DateChar
    })
      .then(res => {
        if (res?.data) {
          let dataRes = res.data
          let props = {
            ...dataRes.variables,
            Li_Child: true
          }
          switch (dataRes.message) {
            case 'Call Screen WS2624002':
              this.callModal(props, 600, 'WS2624002_VisitsChangeConfirm')
              break
            case 'Call Screen WS2624003':
              this.callModal(props, 600, 'WS2624003_VisitsCancelConfirm')
              break
            case 'Call Screen WS2537001':
              this.personalReserveProcess(props)
              break
            default:
              break
          }
        }
      })
      .catch()
  }

  reAcquisition = (item) => {
    let params = {
      id: item
    }
    getReAcquisitionAction(params)
      .then(res => {
        const index = this.state.dataSource.findIndex((v) => v.id === res.data.VisitList[0].id)
        const data = [...this.state.dataSource]
        data.splice(index, 1, res.data.VisitList[0])
        this.setState({
          dataSource: data
        })
      })
  }

  useBarcode = (item) => {
    this.formRef.current.setFieldsValue({ ReserveNumAndPersonalNum: '' })
    let PersonalId = Number(item)
    let params = {
      PersonalId: PersonalId,
      DateChar: this.state.initParams.DateChar,
    }

    getUseBarcodeAction(params)
      .then(res => {
        this.loadData()
        if (!res.selectflg) {
          const targetRecord = this.state.dataSource.find((v) => v.reservation_number === res)

          if (targetRecord) {
            this.receptionStatus(targetRecord)
          }
        } else {
          message.warning(res.message)
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 'fit-content',
              centered: true,
              component:
                <WS2620100_ReservationSelect
                  VisitList={res?.visitBasic?.original?.VisitList}
                  onFinishScreen={(record) => {
                    this.closeModal()
                    this.receptionStatus(record)
                  }}
                />
            },
          })
        }
      })
      .catch(err =>
        this.setState({
          initParams: {
            ...this.state.initParams,
            PersonalId: null,
          }
        })
      )
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    const { FacilityTypeList, dataSource, record } = this.state
    const { loadPanelVisible, showIndicator, shading, showPane, hideOnOutsideClick } = this.state;

    return (
      <div className='counter'>
        <Card title='窓口' >
          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              initialValues={{ ...this.state.initParams, DateChar: moment(this.state.initParams.DateChar) }}
              autoComplete='off'
            >
              <div className='box_inner_vertical'>
                <div className='box_inner_horizontal'>
                  <Form.Item
                    name='DateChar'
                    label='日付'
                    style={{ marginBottom: 0 }}
                  >
                    <VenusDatePickerCustom
                      formRefDatePicker={this.formRef}
                      allowClear={false}
                      onChange={(date, dateString) => this.handleChange(dateString, 'DateChar')}
                    />
                  </Form.Item>
                  <Form.Item
                    name='receiptNumber'
                    label='受付番号'
                    style={{ marginBottom: 0 }}>
                    <Input
                      type='tel'
                      className='input-size-number-8'
                      onPressEnter={(e) => {
                        this.handleChange(e.target.value, 'receiptNumber')
                      }}
                    />
                  </Form.Item>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.eventF11()
                    }}
                  >
                    <span className='btn_label'>
                      新規追加
                    </span>
                  </Button>
                </div>

                <DataGrid id='load-panel-target'
                  style={{ height: 'calc(100vh - 215px)' }}
                  dataSource={this.state.dataSource}
                  showBorders={true} //周りの線
                  showRowLines={true} //横線
                  wordWrapEnabled={true} //折り返し
                  allowColumnReordering={true} //カラム移動
                  allowColumnResizing={true} //カラムの幅リサイズ
                  columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                  columnAutoWidth={true}
                  selection={{ mode: 'single' }}
                  hoverStateEnabled={true}
                  paging={{
                    // ペジネーション設定
                    enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                  }}
                  onRowPrepared={(record) => {
                    // 行ごとの背景色設定
                    if (record.rowType === 'data') {
                      let color = ''
                      // クラスネームが効かないので直接カラーコードを入れる
                      if (record.data.id === this.state.selectedId) {
                        color = '#b5dbff';  //table-row-light
                      } else if (record.data.receipt_number > 0) {
                        color = '#ecf8ff'; //table-row-accepted
                      }
                      record.rowElement.style.backgroundColor = color
                    }
                  }}
                  scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない　無限スクロール対応 それでも設定の入れ方によっては重くなる
                  onRowDblClick={(event) => {
                    const record = event.data
                    this.receptionStatus(record)
                  }}
                >
                  <Column
                    caption='ID'
                    cssClass='column-size-10'
                    dataField='personal_number_id'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value}</div>
                    )}
                  />

                  <Column
                    caption='受付No'
                    cssClass='column-size-5'
                    dataField='receipt_number'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' ? null : event.value}</div>
                    )}
                  />

                  <Column
                    caption='時間'
                    cssClass='column-size-4'
                    dataField='period_time'
                  />

                  <Column
                    caption='氏名'
                    cssClass='column-size-40'
                    dataField='kanji_name'
                  />

                  <Column
                    caption='カナ氏名'
                    cssClass='column-size-40'
                    dataField='kana_name'
                  />

                  <Column
                    caption='性別'
                    cssClass='column-size-4'
                    dataField='sex'
                    cellRender={(value) => {
                      return (
                        <div style={{
                          textAlign: 'center',
                          color: Color(value.data.sex_backGroundColor)?.Foreground
                        }}>
                          {value.value}
                        </div>
                      )
                    }}
                  />

                  <Column
                    caption='生年月日'
                    cssClass='column-size-10'
                    dataField='birthday'
                    cellRender={(event) => {
                      let ad = moment(event.value)
                      let wareki = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'narrow', year: 'numeric' }).format(ad);
                      wareki = wareki.replace('年', '')
                      return wareki + ' ' + ad.format('MM/DD')
                    }}
                  />

                  <Column
                    caption='年齢'
                    cssClass='column-size-4'
                    dataField='Age'
                    cellRender={(value) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value.value}</div>
                      )
                    }}
                  />

                  <Column
                    caption='メモ'
                    cssClass='column-size-4'
                    dataField='memo'
                    cellRender={(event) => {
                      const value = event.value
                      const record = event.data
                      return (
                        // 仮予約の人はメモを出さないようにするため
                        (record.tentativeReserve) ?
                          <div />
                          :
                          <Tooltip title='メモ画面表示'>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 'fit-content',
                                    centered: true,
                                    component: (
                                      <AllMemosFunction
                                        date={this.state.selectedDate}
                                        reservation_number={record.reservation_number}
                                        personal_number_id={record.personal_number_id}
                                        KanjiName={record.kanji_name}

                                        onFinishScreen={() => {
                                          this.handleChange(this.state.initParams.DateChar, 'DateChar')
                                          this.closeModal()
                                        }}
                                      />
                                    ),
                                  }
                                })
                              }}
                            >
                              {value.length > 0 ?
                                value.map((items, index) =>
                                  <div
                                    key={index}
                                    style={{ display: 'flex' }}
                                  >
                                    <div
                                      style={{ color: items.color }}
                                    >
                                      {items.text}
                                    </div>
                                    {((value.length - 1) > index) ?
                                      <div>
                                        {'・'}
                                      </div>
                                      :
                                      null
                                    }
                                  </div>
                                )
                                :
                                <div>{'　'}</div>
                              }
                            </div>
                          </Tooltip>
                      )
                    }}
                  />

                  <Column
                    caption='禁忌'
                    cssClass='column-size-4'
                    dataField='taboo'
                    cellRender={(event) => {
                      const value = event.value
                      const record = event.data
                      return (
                        // 仮予約の人はメモを出さないようにするため
                        (record.tentativeReserve) ?
                          <div />
                          :
                          <Tooltip title='禁忌画面表示'>

                            <div
                              style={{ textAlign: 'center' }}
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 'fit-content',
                                    centered: true,
                                    component: (
                                      <AllMemosFunction
                                        date={this.state.selectedDate}
                                        reservation_number={record.reservation_number}
                                        personal_number_id={record.personal_number_id}
                                        KanjiName={record.kanji_name}
                                        onFinishScreen={() => {
                                          this.handleChange(this.state.initParams.DateChar, 'DateChar')
                                          this.closeModal()

                                        }}
                                      />
                                    ),
                                  }
                                })
                              }}
                            >
                              {value.length > 0 ?
                                <div>{event.value}</div>
                                :
                                <div>{'　'}</div>
                              }
                            </div>
                          </Tooltip>
                      )
                    }}
                  />
                  {
                    this.state.examNameList.map((item, index) => {
                      if (item) {
                        return (
                          <Column
                            key={index}
                            caption={item}
                            cssClass='column-size-4'
                            dataField={item}
                            cellRender={(value) => {
                              const examStatus = value.row.data.exam_status.split(',')
                              const status = examStatus[index]
                              let label = ''
                              if (status === '1') {
                                label = '〇'
                              } else if (status === '0') {
                                label = '　'
                              } else {
                                label = status
                              }
                              return (
                                <div style={{ textAlign: 'center' }}>{label}</div>
                              )
                            }}
                          />
                        )
                      }

                    })
                  }
                  <Column
                    caption='担当'
                    cssClass='column-size-40'
                    dataField='user'
                  />

                  <Column
                    caption='コース'
                    cssClass='column-size-60'
                    dataField=''
                    cellRender={(text) => (
                      <div>
                        {text.data.visit_course} {text.data.contract_short_name}
                      </div>
                    )}
                  />

                  <Column
                    caption='事業所'
                    cssClass='column-size-60'
                    dataField='office_kanji_name'
                  />

                  <Column
                    caption='請求額'
                    cssClass='column-size-10'
                    dataField='Expression_21'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'right' }}>{event.value === 0 || event.value === '0' || !event.value ? null : event.value.toLocaleString()}</div>
                    )}
                  />

                  <Column
                    caption='受付'
                    cssClass='column-size-4'
                    dataField='Expression_23'
                    cellRender={(event) => (
                      <div
                        style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                      >
                        {event.value}
                      </div>
                    )}
                  />

                  <Column
                    caption='入金'
                    cssClass='column-size-4'
                    dataField='Expression_25'
                    cellRender={(event) => (
                      <div
                        style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                      >
                        {event.value}
                      </div>
                    )}
                  />

                  <Column
                    caption='領収'
                    cssClass='column-size-4'
                    dataField='Expression_27'
                    cellRender={(event) => (
                      <div
                        style={event.value === '×' ? styleContentTableColumn : { textAlign: 'center', height: '22px' }}
                      >
                        {event.value}
                      </div>
                    )}
                  />

                  <Column
                    width={45}
                    fixed={true}
                    fixedPosition='right'
                    cellRender={(event) => (
                      <div style={{ textAlign: 'center' }}>
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => this.renderMenu(event.data)}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                      </div>
                    )}
                  />
                </DataGrid>
              </div>

              <div className='box_button_bottom_right' style={{ justifyContent: 'left' }}>
                <Form.Item
                  name='ReserveNumAndPersonalNum'
                  label='バーコード'
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    type='number'
                    onPressEnter={(e) => this.useBarcode(e.target.value)}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </Card>

        <LoadPanel
          shadingColor="rgba(255,255,255,0)"
          position={{ of: '#load-panel-target' }}
          visible={loadPanelVisible}
          showIndicator={showIndicator}
          shading={shading}
          showPane={showPane}
          hideOnOutsideClick={hideOnOutsideClick}
        />

        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2620001_Counter)
