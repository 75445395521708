import axios from 'configs/axios';

const apiPaths = {
  index: '/api/associate-insure-param-maintain/copying-process/index',
  save: '/api/associate-insure-param-maintain/copying-process/setchangeData',
};

const CopyingProcessService = {

  async index() {
    return axios.get(apiPaths.index);
  },
  async save(params) {
    console.log(params)
    return axios.get(apiPaths.save, { params });
  }

};

export default CopyingProcessService;