import axios from 'configs/axios'

const apiPaths = {
  index: '/api/progress-setting/progress-set',
  getProgressList: '/api/progress-setting/progress-set/get-progress-list',
  update: '/api/progress-setting/progress-set/update',
  csvOutput: '/api/progress-setting/progress-set/csv-output',
}

const ProgressSetService = {
  async index() {
    return axios.get(apiPaths.index)
  },

  async getProgressList(params) {
    return axios.get(apiPaths.getProgressList, { params })
  },

  async update(params) {
    return axios.post(apiPaths.update, params)
  },

  async csvOutput(params) {
    return axios.post(apiPaths.csvOutput, params, { responseType: 'blob' });
  },
}

export default ProgressSetService
