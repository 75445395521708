import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Input, Select, message } from "antd";
import { SaveOutlined, } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'

const labelCol = {
  labelCol: { style: { width: 80 } }
}

class ComplexDecisionMasterMaintenanceSubTableEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "複合判定マスタ保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue(this.props.record)
  };


  showWS0285001_JudgeQuery = (key) => {
    let setValue = {}
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0285001_JudgeQuery
            onFinishScreen={async (output) => {
              if (output.recordData) {
                setValue[key] = output.recordData.judgment_result
                this.formRef.current?.setFieldsValue(setValue)
              }
              await this.closeModal()
            }}
          />
        )
      }
    })
  }


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };


  render() {
    return (
      <div className="spreadsheet-input-setting">
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <Card title="編集">
            {Object.keys(this.props.record).map(key => {
              if(key == 'exam_hl') {
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label='HL区分'
                    {...labelCol}
                  >
                    <Select>
                      <Select.Option key={0} value={0}>なし</Select.Option>
                      <Select.Option key={1} value={1}>あり</Select.Option>
                    </Select>
                  </Form.Item>
                )
              } else if(key == 'exam_judgment') {
                return (
                  <Form.Item
                    key={key}
                    name={key}
                    label='判定'
                    {...labelCol}
                  >
                    <Input.Search
                      allowClear
                      onPressEnter={() => {this.showWS0285001_JudgeQuery(key)}}
                      onSearch={(value, event) => {this.showWS0285001_JudgeQuery(key)}}
                    />
                  </Form.Item>
                )
              }
            })}

            {/* 保存ボタン */}
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.props.onFinishScreen({...this.formRef.current?.getFieldsValue()})
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplexDecisionMasterMaintenanceSubTableEdit);
