import axios from 'configs/axios';

const API_LIST = {
  getScreenData: '/api/district-info-maintain/district-info-maintain/get-screen-data',
  getDistrictInfo: '/api/district-info-maintain/district-info-maintain/get-district-info',
  save: '/api/district-info-maintain/district-info-maintain/save',
  delete: '/api/district-info-maintain/district-info-maintain/delete',
};

const DistrictInfoMaintainService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, {params});
  },

  async getDistrictInfo(params) {
    return axios.get(API_LIST.getDistrictInfo, {params});
  },

  async save(params) {
    return axios.post(API_LIST.save, params);
  },

  async delete(params) {
    return axios.delete(API_LIST.delete, {params});
  },
};

export default DistrictInfoMaintainService;