import axios from 'configs/axios';

const apiPaths = {
  getDataSupportItem: '/api/support-item/support-item-sub',
  getSelectDataSupportItem: '/api/support-item/support-item-sub/select',
  saveSupportItem: '/api/support-item/support-item-sub/save',
  deleteSupportItem: '/api/support-item/support-item-sub/delete',
};

const SupportItemService = {
  async getDataSupportItemService(params) {
    return axios.get(apiPaths.getDataSupportItem, { params });
  },
  async getSelectDataSupportItemService(params) {
    return axios.get(apiPaths.getSelectDataSupportItem, { params });
  },
  async saveSupportItemService(params) {
    return axios.post(apiPaths.saveSupportItem, params)
  },
  async deleteSupportItemService(params) {
    return axios.delete(apiPaths.deleteSupportItem, { params })
  }
};

export default SupportItemService;