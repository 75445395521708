/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { Table, Dropdown, Menu, Button, Tooltip } from 'antd'
import { InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, InfoOutlined } from '@ant-design/icons'
import { download_file } from 'helpers/CommonHelpers'
import Color from 'constants/Color'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import TargetSelectDocumentAction from 'redux/AdvancePreparation/DocumentBatchCreate/TargetSelectDocument.action'

class WS0650002_TargetSelectDocument extends React.Component {

  constructor(props) {
    super(props)

    // document.title = this.props.document ? this.props.document : '対象選択SUB'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      dataSourceRestore: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      isFilterTime: false
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.setState({ isFilterTime: false })
  }

  /**
   * 親画面でpropsが変更になった時
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    // visitDataの設定があるかないか？
    if (this.props.visitData) {
      if (this.props.visitData !== prevProps.visitData && this.props.isSearch) {
        // 再取得
        this.index(this.props.message)
        this.setState({ isFilterTime: false })
      }
    }
    // バーコード
    if (this.props.dataSource) {
      if (this.props.dataSource !== prevProps.dataSource) {
        this.setState({ dataSource: this.props.dataSource })
      }
    }
  }

  /**
   * 受診者一覧データを取得
   * @param {*} messag
   */
  index = (messag) => {
    if (messag === 'Success') {
      const params = {
        KeyInfo: this.props.KeyInfo ?? '',
        PrintType: this.props.PrintType,
        OutputOrder: this.props.OutputOrder,
        visitData: this.props.visitData ?? null,
        printCondition: this.props.printCondition ?? 0,
      }
      TargetSelectDocumentAction.index(params)
        .then(res => {
          if (res) {
            let data = res.filter(x => x.W1_logic_01)
            let dataKeys = data.map(x => x.id)
            if (!this.props.updateFlag) {
              this.setState({
                dataSource: res,
                dataSourceRestore: res,
                selectedRows: data,
                selectedRowKeys: dataKeys
              })
              if (this.props.onSelectedRowKeys) {
                // selectedRowKeysを親画面に渡す
                this.props.onSelectedRowKeys(dataKeys)
              }
              if (this.props.onSelectedData) {
                // 選択されているデータ配列を親画面に渡す
                this.props.onSelectedData(data)
              }
            } else {
              this.setState({
                dataSource: res,
                dataSourceRestore: res,
                selectedRows: [],
                selectedRowKeys: []
              })
              if (this.props.onSelectedRowKeys) {
                // selectedRowKeysを親画面に渡す
                this.props.onSelectedRowKeys(dataKeys)
              }
              if (this.props.onSelectedData) {
                // 選択されているデータ配列を親画面に渡す
                this.props.onSelectedData(data)
              }
            }
            if (this.props.onDataSource) {
              // 選択されているデータ配列を親画面に渡す
              this.props.onDataSource(res)
            }
          }
        })
    }
  }

  /**
   * EXCEL 処理
   */
  excelReport(record) {
    let datas = []
    let newDatas = {
      W1_course_level: record.W1_course_level,
      W1_reserve_num: record.W1_reserve_num
    }
    datas.push(newDatas)

    let params = {
      visitData: datas
    }
    TargetSelectDocumentAction.excelReport(params)
      .then((res) => {
        download_file(res)
      })
  }

  /**
   * 選択 処理
   * @param {*} record
   */
  onSelect = (record) => {
    let arrTemp = [...this.state.selectedRowKeys]
    let arrTempRecord = [...this.state.selectedRows]
    let idx = arrTemp.indexOf(record.id)
    if (idx === -1) {
      arrTemp.push(record.id)
      arrTempRecord.push(record)
    } else {
      arrTemp.splice(idx, 1)
      arrTempRecord.splice(idx, 1)
    }
    this.setState({
      selectedRowKeys: arrTemp,
      selectedRows: arrTempRecord
    })
    if (this.props.onSelectedRowKeys) {
      // selectedRowKeysを親画面に渡す
      this.props.onSelectedRowKeys(arrTemp)
    }
    if (this.props.onSelectedData) {
      // 選択されているデータ配列を親画面に渡す
      this.props.onSelectedData(arrTempRecord)
    }
  }

  /**
   * 全選択 処理
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let arrTemp = []
    let arrTempRecord = []
    if (selected) {
      arrTempRecord = [...this.state.dataSource]
      arrTemp = arrTempRecord.map(item => item.id)
      this.setState({
        selectedRowKeys: arrTemp,
        selectedRows: arrTempRecord
      })
    } else {
      this.setState({
        selectedRowKeys: [],
        selectedRows: []
      })
    }
    if (this.props.onSelectedRowKeys) {
      // selectedRowKeysを親画面に渡す
      this.props.onSelectedRowKeys(arrTemp)
    }
    if (this.props.onSelectedData) {
      // 選択されているデータ配列を親画面に渡す
      this.props.onSelectedData(arrTempRecord)
    }
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='target-select-sub'>
        <Table
          bordered
          size='small'
          className='scrollbar'
          dataSource={this.state.dataSource}
          pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
          rowKey={(record) => record.id}
          scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
          rowSelection={{
            type: 'checkbox',
            fixed: 'left',
            selectedRowKeys: this.state.selectedRowKeys,
            onSelect: (record) => {
              // 行選択
              this.onSelect(record)
            },
            onSelectAll: (selected) => {
              // 行全選択
              this.onSelectAll(selected)
            }
          }}
        >
          <Table.Column
            className='column-size-date'
            title='受診日'
            dataIndex='visit_date_on'
            render={(value, record, index) => {
              return (
                <div >
                  {value === '0000-00-00' ? '' : moment(value)?.format('YYYY/MM/DD (ddd)')}
                </div>
              )
            }}
          />

          <Table.Column
            className='column-size-10'
            title='個人番号'
            dataIndex='personal_number_id'
            render={(value, record, index) =>
              <div style={{ textAlign: 'right' }}>
                <span>{record.personal_number_id}</span>
              </div>
            }
          />

          <Table.Column
            width={40}
            title='メモ'
            dataIndex='importance'
            render={(value, record, index) => {
              let icon = null
              switch (value) {
                case 1:
                  icon = <InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />
                  break
                case 3:
                  icon = <WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />
                  break
                case 5:
                  icon = <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
                  break
                default:
                  icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                  break
              }
              return (
                <div
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  hidden={!record.personal_number_id}
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 1200,
                        component: (
                          <WS2584019_PersonalInfoInquirySub
                            Li_PersonalNum={record.personal_number_id}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: false,
                                },
                              })
                            }}
                          />
                        ),
                      },
                    })
                  }}>
                  {icon}
                </div>
              )
            }} />
          <Table.Column
            className='column-size-10'
            title='漢字氏名'
            dataIndex='KanjiName'
          />

          <Table.Column
            className='column-size-10'
            title='生年月日'
            dataIndex='DateBirth'
            render={(value, record, index) => {
              return (
                <div>
                  {value === '0000-00-00' ? '' : moment(value)?.format('YYYY/MM/DD')}
                </div>
              )
            }}
          />

          <Table.Column
            className='column-size-40'
            title='事業所'
            dataIndex='office_kanji_name'
          />

          <Table.Column
            className='column-size-10'
            title='所属'
            dataIndex='workplace_formal_name'
          />

          <Table.Column
            dataIndex='visit_course'
            className='column-size-40'
            title='契約情報'
            render={(value, record, index) => {
              const course = (record.visit_course) ? record.visit_course?.toString().substr(0, 1) + '-' + record.visit_course?.toString().substr(1, 2) : ''
              return (
                <div>
                  {course + record.contract_short_name}
                </div>
              )
            }} />
          <Table.Column
            width={60}
            title='状態'
            dataIndex='state'
            render={(value, record, index) =>
              <div style={{ color: Color(record.stateColorNum)?.Foreground, textAlign: 'center' }}>
                {value}
              </div>
            }
          />

          <Table.Column
            className='column-size-10'
            title='備考'
            dataIndex='remarks'
          />

          <Table.Column
            className='column-size-10'
            title='印刷日'
            dataIndex='printDate'
          />

          <Table.Column
            className='column-size-10'
            title='発送日'
            dataIndex='postDate'
          />

          <Table.Column
            width={40}
            align='center'
            fixed='right'
            render={(value, record) => (
              <Dropdown
                trigger='click'
                size='small'
                overlay={() => (
                  <Menu>
                    <Menu.Item
                      key='menu-1'
                      onClick={() => (
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 'fit-content',
                            component: (
                              <WS2583001_ConsultInquirySub
                                Li_ReserveNum={record.W1_reserve_num}
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      )}>
                      照会
                    </Menu.Item>
                    <Menu.Item
                      key='menu-2'
                      onClick={() => this.excelReport(record)}
                    >
                      EXCEL
                    </Menu.Item>
                  </Menu>
                )}>
                <Button size='small' icon={<MoreOutlined />} />
              </Dropdown>
            )}
          />
        </Table>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0650002_TargetSelectDocument)
