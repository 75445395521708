import React from "react";
import { connect } from "react-redux";
import { SaveOutlined } from '@ant-design/icons';
import { Card, Form, Input, Button, Select, message } from "antd";
import SetInfoChangeSubAction from "redux/basicInfo/SetInfoMaintain/SetInfoChangeSub.action";

class WS0333300_SetIncludesQueryInformationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = 'セット内容照会';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      changeData: {},
    };
  }
  componentDidMount() {
    this.getScreenData();
  }
  getScreenData() {
    this.formRef.current?.setFieldsValue(this.props.data)
    console.log(this.props.data)
  }

  handleFormChange(e) {
    let columnName = Object.keys(e)[0];
    let columnValue = Object.values(e)[0];
    this.setState({
      changeData: {
        ...this.state.changeData,
        [columnName]: columnValue
      }
    })
  }

  onFinish() {
    let param = {
      set_code: this.props.data.set_code ? this.props.data.set_code : this.formRef.current?.getFieldValue('set_code'),
      changeData: this.state.changeData,
      newFlag: this.props.newFlag
    }
    if (param.set_code === undefined || param.set_code === '') {
      message.error('コードを入力してください')
    } else {
      SetInfoChangeSubAction.updateTableData(param)
        .then((res) => {
          this.props.onFinishScreen()
        })
    }
  }

  render() {
    return (
      <div className="set-includes-query-information-edit">
        <Card title={'セット情報入力 [' + (this.props.newFlag ? '新規' : '編集') + ']'} >
          <Form
            layout={'vertical'}
            ref={this.formRef}
            onValuesChange={(event) => this.handleFormChange(event)}
            onFinish={(value) => this.onFinish(value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Enterキーが押されたとき、フォームの送信を防止
              }
            }}
          >
            {this.props.newFlag === true ?
              <div></div>
              : this.props.newFlag === false ?
                <div style={{ position: 'absolute', top: 16, right: 60, borderRadius: 10 }}>コード：{this.props.data.set_code}</div>
                : null
            }

            {this.props.newFlag === true ?
              <div className="box_container">
                <div className="box_inner_horizontal">
                  <div className="box_inner_vertical" >
                    <Form.Item
                      name='set_code'
                      label='コード'
                      rules={[{ max: 8, message: 'コードの入力値は8文字以内にしてください' }]}
                    >
                      <Input className="input-size-10" />
                    </Form.Item>
                    <Form.Item
                      name='set_name'
                      label='正式名称'
                    >
                      <Input className="input-size-20" />
                    </Form.Item>
                  </div>

                  <div className="box_inner_vertical" >
                    <Form.Item
                      name='set_short_name'
                      label='略名'
                    >
                      <Input className="input-size-20" />
                    </Form.Item>

                    <Form.Item name='set_identification' label='セット識別' >
                      <Select style={{ width: '120px' }}>
                        <Select.Option value={'Cos'}>コース</Select.Option>
                        <Select.Option value={'Opt'}>オプション</Select.Option>
                        <Select.Option value={'Set'}>セット(その他)</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name='association_adjustments' label='セット区分' >
                      <Select style={{ width: '120px' }}>
                        <Select.Option value={0}>共有</Select.Option>
                        <Select.Option value={1}>契約</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              :
              this.props.newFlag === false ?
                <div className="box_container">
                  <div className="box_inner_horizontal">
                    <div className="box_inner_vertical" >
                      <Form.Item
                        name='search_short_name'
                        label='検索'
                      >
                        <Input className="input-size-10" />
                      </Form.Item>
                      <Form.Item
                        name='set_name'
                        label='正式'
                      >
                        <Input className="input-size-20" />
                      </Form.Item>
                      <Form.Item
                        name='set_short_name'
                        label='略名'
                      >
                        <Input className="input-size-20" />
                      </Form.Item>
                    </div>

                    <div className="box_inner_vertical" >
                      <Form.Item
                        name='set_identification'
                        label='セット識別'
                      >
                        <Select style={{ width: '120px' }} disabled={this.props.data.set_identification === ''}>
                          <Select.Option value={'Cos'}>コース</Select.Option>
                          <Select.Option value={'Opt'}>オプション</Select.Option>
                          <Select.Option value={'Set'}>セット(その他)</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name='association_adjustments'
                        label='セット区分'
                      >
                        <Select style={{ width: '120px' }}>
                          <Select.Option value={0}>共有</Select.Option>
                          <Select.Option value={1}>契約</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name='priority_order'
                        label='順'
                      >
                        <Input className="input-size-10" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                :
                null
            }

            <Form.Item style={{ marginBottom: 0 }}>
              <div className="box_button_bottom_right" style={{ display: 'flex', gap: '20px' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div >
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0333300_SetIncludesQueryInformationEdit);
