import React from "react";
import { connect } from "react-redux";

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Table, message } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import InspectSetInfo from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/InspectSetInfo.action";


class WS1176001_InspectSetInfo extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      mainPageSize: 10,
      dataSourceMain: [],
      rowSelectMain: {},
      isLoadingTableMain: false,
      dataSourceSubSite: [],
      isLoadingTableSubSite: false,
      isLoadingTableSubFinding: false
    };
  }

  componentDidMount = () => {
    this.loadDataMainTable();
  };

  loadDataMainTable = () => {
    InspectSetInfo.getDataMainCosAction()
      .then(res => {
        if (res?.data) {
          this.setState({
            dataSourceMain: res.data,
            rowSelectMain: this.state.rowSelectMain.id ? this.state.rowSelectMain : res.data[0]
          });
          this.formRef.current?.setFieldsValue({
            tableMainData: res.data
          });
          this.forceUpdate();

          if (res.data.length > 0) {
            this.getCourseSubInput(res.data[0]["test_set_code"]);
          } else {
            this.setState({
              dataSourceSubSite: []
            });
          }
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  getCourseSubInput(Li_SetCode) {
    this.setState({
      isLoadingTableSubSite: true,
      isLoadingTableSubFinding: true
    });
    VenusApiRoutesV2.callApi("API001176001003", { Li_SetCode })
      .then(res => {
        this.formRef.current?.setFieldsValue({
          dataSourceSubSite: res
        });
        this.forceUpdate();
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() =>
        this.setState({
          isLoadingTableSubSite: false,
          isLoadingTableSubFinding: false
        })
      );
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  onFinishMainForm = () => { };

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex(item => recordID === item.id);
  };

  render() {
    return (
      <div className="inspect-set-info">
        <Card title="V4-VNS02600:検査セット情報">
          <Form ref={this.formRef} onFinish={this.onFinishMainForm}>
            <Table
              bordered
              size="small"
              dataSource={
                this.formRef.current?.getFieldValue("tableMainData")
                  ? this.formRef.current?.getFieldValue("tableMainData")
                  : []
              }
              loading={this.state.isLoadingTableMain}
              pagination={{
                defaultCurrent: 1,
                pageSize: this.state.mainPageSize,
                showSizeChanger: true,
                onChange: (page, pageSize) => {
                  this.setState({
                    mainPageSize: pageSize
                  });
                },
              }}
              onRow={(record) => ({
                onDoubleClick: () => {
                  this.props.onFinishScreen({
                    test_set: record.test_set_code,
                    test_set_name: record.test_set_name
                  })
                }
              })}
              rowKey={record => record.id}
            >
              <Table.Column
                title="コード"
                dataIndex="test_set_code"
                sorter={(a, b) => a.test_set_code.localeCompare(b.test_set_code)}
                showSorterTooltip={false}
                width={300}
              // render={(text, record) => {
              //   let index = this.findIndexByID(
              //     this.formRef.current?.getFieldValue("tableMainData"),
              //     record.id
              //   );
              //   return (
              //     <Form.Item
              //       name={["tableMainData", index, "test_set_code"]}
              //       style={{ marginBottom: "0px" }}
              //     >
              //       <Input style={{ border: "0px" }} />
              //     </Form.Item>
              //   );
              // }}
              />
              <Table.Column
                title="セット名称"
                dataIndex="test_set_name"
                sorter={(a, b) => a.test_set_name.localeCompare(b.test_set_name)}
                showSorterTooltip={false}
              // width={600}
              // render={(text, record) => {
              //   let index = this.findIndexByID(
              //     this.formRef.current?.getFieldValue("tableMainData"),
              //     record.id
              //   );
              //   return (
              //     <Form.Item
              //       name={["tableMainData", index, "test_set_name"]}
              //       style={{ marginBottom: "0px" }}
              //     >
              //       <Input style={{ border: "0px" }} />
              //     </Form.Item>
              //   );
              // }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1176001_InspectSetInfo);
