import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Select, Table, Checkbox, Dropdown, Menu, Button, Modal } from "antd";
import WS0887001_IntroduceLetterVariousMasterInquiry from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx';
import WS2804500_ReturnInfoEdit from 'pages/SK_IntroductionLetter/V4SK0006000_IntroduceLetterReturnInfoInput/WS2804500_ReturnInfoEdit.jsx';
import IntroduceLetterReturnInfoInputAction from 'redux/IntroductionLetter/IntroduceLetterReturnInfoInput/IntroduceLetterReturnInfoInput.action.js';
import { PlusOutlined, MoreOutlined, SaveOutlined } from '@ant-design/icons';
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx'

const dateFormat = 'YYYY/MM/DD';
class WS2804013_ReturnInfoInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '返送情報入力';

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      count: 1,
      tableData: [],
      reservationNumber: '',
      department: '',
      examDepartmentCode: '',
      department_name: '',
      kanji_name: '',
      ExamDateCharUnknown: false
    };
  }

  componentDidMount = async () => {
    this.formRef.current?.setFieldsValue({
      ExamDateChar: this.props.examDate,
      Lio_LandOldCoerciveGroupClassify: this.props.oldInsuranceGroupDivision,
      Lio_MedicalInstitutionCode: this.props.medicalInstitutionCode,
      Lio_LandOldCoercivePreciseResult: this.props.oldInsurancePreciseResult,
      Lio_Other: this.props.otherReturn
    })
    await this.setState({
      kanji_name: this.props.kanji_name,
      reservationNumber: this.props.reservationNumber,
      department: this.props.department,
      department_name: this.props.department_name,
      examDepartmentCode: this.props.examDepartmentCode
    })

    this.getScreenData()
  }

  getScreenData = () => {
    let params = {
      Li_ReserveNum: this.state.reservationNumber,
      Li_Department: this.state.department,
      Lio_ExamDate: this.formRef.current?.getFieldValue('ExamDateChar'),
      Lio_LandOldCoercivePreciseResult: this.formRef.current?.getFieldValue('Lio_LandOldCoercivePreciseResult'),
      Lio_LandOldCoerciveGroupClassify: this.formRef.current?.getFieldValue('Lio_LandOldCoerciveGroupClassify'),
      Lio_MedicalInstitutionCode: this.formRef.current?.getFieldValue('Lio_MedicalInstitutionCode'),
      Lio_Other: this.formRef.current?.getFieldValue('Lio_Other'),
    }

    IntroduceLetterReturnInfoInputAction.getScreenData(params)
      .then(res => {
        this.formRef.current?.setFieldsValue({
          ExamDateChar: res.ExamDateChar,
          Lio_LandOldCoerciveGroupClassify: res.Lio_LandOldCoerciveGroupClassify,
          category_name: res.category_name,
          Lio_MedicalInstitutionCode: res.Lio_MedicalInstitutionCode,
          medical_institutions_short_name: res.medical_institutions_short_name,
          Lio_LandOldCoercivePreciseResult: res.Lio_LandOldCoercivePreciseResult,
          Lio_Other: res.Lio_Other
        })
        this.setState({
          tableData: res.Sub
        })

        if (res.ExamDateChar == '9999/09/09') {
          this.setState({
            ExamDateCharUnknown: true
          })
        }
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }
  setDataSend(condition) {
    if (condition === "exam_code") {
      return 2
    } else if (condition === "findings_code") {
      return 8
    } else if (condition === "sick_name_code") {
      return 5
    } else if (condition === "treatment_code") {
      return 3
    } else {
      return 4
    }
  }
  setDataOutput(condition, output) {
    if (condition === "exam_code") {
      this.formRef.current?.setFieldsValue({
        exam_code: output?.Lo_VariousCodes,
        exam_content: output?.recordData?.exam_content
      })
    } else if (condition === "findings_code") {
      this.formRef.current?.setFieldsValue({
        findings_code: output?.Lo_VariousCodes,
        findings_content: output?.recordData?.findings_content
      })
    } else if (condition === "sick_name_code") {
      this.formRef.current?.setFieldsValue({
        sick_name_code: output?.Lo_VariousCodes,
        sick_name: output?.recordData?.sick_name
      })
    } else if (condition === "treatment_code") {
      this.formRef.current?.setFieldsValue({
        treatment_code: output?.Lo_VariousCodes,
        treatment_content: output?.recordData?.treatment_content
      })
    } else {
      this.formRef.current?.setFieldsValue({
        Lio_MedicalInstitutionCode: output?.recordData?.various_codes,
        medical_institutions_short_name: output?.recordData?.medical_institutions_short_name
      })
    }
  }

  showIntroduceLetterVariousMasterInquiry(condition = null) {
    const value = this.setDataSend(condition)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={value}
            onFinishScreen={(output) => {
              this.setDataOutput(condition, output)
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  Delete(record) {
    IntroduceLetterReturnInfoInputAction.deleteReturnInfo({ id: record.id })
      .then(res => {
        this.getScreenData()
      })
  }

  openReturnInfoEdit = (record, newFlag) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2804500_ReturnInfoEdit
            newFlag={newFlag}
            reservationNumber={this.state.reservationNumber}
            department={this.state.department}
            examDepartmentCode={this.state.examDepartmentCode}
            record={record}
            onFinishScreen={(output) => {
              this.getScreenData()
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  openCategorySearchQuerySingle = (value) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0267001_CategorySearchQuerySingle
            Lio_CategoryCode={value}
            onFinishScreen={(output) => {
              // 画面に反映
              this.formRef.current?.setFieldsValue({
                Lio_LandOldCoerciveGroupClassify: output?.Lio_CategoryCode,
                category_name: output?.Lio_CategoryName,
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  saveIntroduceLetterInfo = () => {
    let params = {
      reservationNumber: this.state.reservationNumber,
      department: this.state.department,
      examDepartmentCode: this.state.examDepartmentCode,
      ExamDateChar: this.formRef.current?.getFieldValue('ExamDateChar'),
      Lio_LandOldCoerciveGroupClassify: this.formRef.current?.getFieldValue('Lio_LandOldCoerciveGroupClassify'),
      Lio_MedicalInstitutionCode: this.formRef.current?.getFieldValue('Lio_MedicalInstitutionCode'),
      Lio_LandOldCoercivePreciseResult: this.formRef.current?.getFieldValue('Lio_LandOldCoercivePreciseResult'),
      Lio_Other: this.formRef.current?.getFieldValue('Lio_Other')
    }

    IntroduceLetterReturnInfoInputAction.saveIntroduceLetterInfo(params)
      .then(res => {
        if (this.props.onSave) {
          this.props.onSave()
        }
      })
  }

  onChangeChecked = (check) => {
    if (check) {
      this.formRef.current?.setFieldsValue({
        ExamDateChar: '9999/09/09'
      })
      this.setState({
        ExamDateCharUnknown: true
      })
    } else {
      this.formRef.current?.setFieldsValue({
        ExamDateChar: null
      })
      this.setState({
        ExamDateCharUnknown: false
      })
    }
  }

  render() {
    return (
      <div className="return-info-input" style={{ width: 1200 }}>
        <Card title="返送情報入力" className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className="box_container">
              <div className="box_inner_horizontal">
                <div className="box_search" style={{ flexDirection: 'column', gap: 3 }}>
                  <div className="box_inner_horizontal">
                    <div className="box_search_inner" style={{ marginRight: 10 }}>
                      <Form.Item
                        name="kanji_name"
                        label="&emsp;&emsp;氏名"
                      >
                        <div>{this.state.kanji_name}</div>
                      </Form.Item>

                      <div className="box_inner_horizontal">
                        <Form.Item
                          label="&emsp;検査日"
                          name="ExamDateChar"
                        >
                          {
                            this.state.ExamDateCharUnknown ?
                              <Input
                                disabled={this.state.ExamDateCharUnknown}
                                style={{ width: 160 }}
                              />
                              :
                              <VenusDatePickerCustom
                                formRefDatePicker={this.formRef}
                                format={dateFormat}
                                onChange={(value) => {
                                  this.formRef.current?.setFieldsValue({ ExamDateChar: value.format('YYYY/MM/DD') })
                                }}
                              />
                          }
                        </Form.Item>
                        <Form.Item
                          name="ExamDateCharUnknown"
                        >
                          <Checkbox
                            style={{ marginTop: 2 }}
                            checked={this.state.ExamDateCharUnknown}
                            onChange={(event) => {
                              this.onChangeChecked(event.target.checked);
                            }}
                          >
                            検査日不明
                          </Checkbox>
                        </Form.Item>
                      </div>

                      <div className="box_inner_horizontal">
                        <Form.Item
                          label="医療機関"
                          name="Lio_MedicalInstitutionCode"
                        >
                          <Input.Search
                            allowClear={true}
                            className="input-search-size-number-allow-clear-5"
                            onPressEnter={() => this.showIntroduceLetterVariousMasterInquiry()}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.formRef.current?.setFieldsValue({
                                  Lio_MedicalInstitutionCode: '',
                                  medical_institutions_short_name: ''
                                })
                                this.forceUpdate()
                              } else {
                                this.showIntroduceLetterVariousMasterInquiry()
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item>
                          <span>{this.formRef.current?.getFieldValue("medical_institutions_short_name")}</span>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="box_search_inner">
                      <Form.Item
                        name="department_name"
                        label="&emsp;診療科"
                      >
                        <div>{this.state.department_name}</div>
                      </Form.Item>
                      <div className="box_inner_horizontal">
                        <Form.Item
                          label="地域保健"
                          name="Lio_LandOldCoerciveGroupClassify"
                        >
                          <Input.Search
                            allowClear={true}
                            className='input-search-size-number-allow-clear-5'
                            onPressEnter={(event) => this.openCategorySearchQuerySingle(event.target.value)}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.formRef.current?.setFieldsValue({
                                  category_name: ''
                                })
                                this.forceUpdate()
                              } else {
                                this.openCategorySearchQuerySingle(value)
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item  >
                          <span>{this.formRef.current?.getFieldValue("category_name")}</span>
                        </Form.Item>
                      </div>

                      <Form.Item
                        label="精密結果"
                        name="Lio_LandOldCoercivePreciseResult"
                      >
                        <Select >
                          <Select.Option value={0}>対象外</Select.Option>
                          <Select.Option value={1}>異常認めず</Select.Option>
                          <Select.Option value={2}>がんであった者</Select.Option>
                          <Select.Option value={3}>がん疑いの者</Select.Option>
                          <Select.Option value={4}>がん以外の疾患であった者</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <Form.Item
                    name="Lio_Other"
                    label="&emsp;その他"
                  >
                    <Input maxLength={80} />
                  </Form.Item>
                </div>

                <div className="box_search_btn">
                  <div className="box_search_btn_inner">
                    <Button
                      type='primary'
                      icon={<SaveOutlined />}
                      onClick={() => { this.saveIntroduceLetterInfo() }}
                    >
                      <span className='btn_label'>
                        保存
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <Table
                bordered
                dataSource={this.state.tableData}
                size="small"
                pagination={false}
                rowKey={(record => record.id)}
                scroll={{ x: 'max-content', y: resizableTableScroll(200) }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: (event) => { this.openReturnInfoEdit(record, false) }
                  };
                }}
              >
                <Table.Column
                  title="連番"
                  className="column-size-5"
                  dataIndex='seq'
                  render={(value, record, index) => (
                    < div style={{ textAlign: 'right' }}>{value}</div>
                  )}
                />
                <Table.Column
                  title="検査"
                  dataIndex="exam_content"
                  className="column-size-40"
                />
                <Table.Column
                  title="所見"
                  dataIndex="findings_content"
                  className="column-size-40"
                />
                <Table.Column
                  title="傷病"
                  dataIndex="sick_name"
                  className="column-size-40"
                />
                <Table.Column
                  title="治療"
                  dataIndex="treatment_content"
                  className="column-size-40"
                />
                <Table.Column
                  width={45}
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      align="center"
                      style={{ textAlign: 'center' }}
                      onClick={() => this.openReturnInfoEdit(null, true)}
                    />
                  }
                  render={(text, record, index) => (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='edit'
                            onClick={() => this.openReturnInfoEdit(record, false)}
                          >
                            編集
                          </Menu.Item>
                          <Menu.Item
                            key='delete'
                            onClick={() => {
                              Modal.confirm({
                                content: '削除してもよろしいですか？',
                                okText: 'は　い',
                                cancelText: 'いいえ',
                                onOk: () => this.Delete(record)
                              })
                            }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />} />
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          destroyOnClose={true}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2804013_ReturnInfoInput);
