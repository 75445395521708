import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";


import { QuestionCircleOutlined } from "@ant-design/icons";
import { Card, Form, Input, Radio, Button, Upload, message, Modal, Row, Col, Space, Spin, Select } from "antd";

import moment from "moment";
import axios from "configs/axios";

import TransmissionCaptureConfirmAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/TransmissionCaptureConfirm.action";

import WS2683001_TransmissionContentsCheckFixFile from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2683001_TransmissionContentsCheckFixFile";
import WS2682001_TransmissionContentsCheckCorrectPersonal from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2682001_TransmissionContentsCheckCorrectPersonal";
import WS2686001_QualifyResultManuallyUpdate from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2686001_QualifyResultManuallyUpdate";



const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
  },
};
const formTailLayout = {
  labelCol: {
    span: 0,
  },
  wrapperCol: {
    span: 0,
    offset: 1,
  },
};

class WS2685001_TransmissionCaptureConfirm extends React.Component {
  formRef = React.createRef();


  static propTypes = {
    Li_ReserveNum: PropTypes.any,
  };

  constructor(props) {
    super(props);

    // document.title = '送信・取込内容確認';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      initParams: {
        personal_number_id: '',           // * "personal_number_id": string 10
        kanji_name: '',                    // * "kanji_name": string 40
        office_kanji_name: '',             // * "office_kanji_name": string 60
        contract_short_name: '',           // * "contract_short_name": string 80
        State: '',                         // * "State": string 30
        insurer_number1: '',                // * "insurer_number": int 10
        birthday_on1: '',                  // * "birthday_on": date ####/##/##
        insurer_card_symbol1: '',          // * "insurer_card_symbol": string 40
        visit_date_on1: '',                // * "visit_date_on": date ####/##/##
        insurer_card_number1: '',          // * "insurer_card_number": string 40
        medicalExamType: '',                // * "medicalExamType":
        insurer_number2: '',                // * "insurer_number": int 10
        birthday_on2: '',                  // * "birthday_on": date ####/##/##
        insurer_card_symbol2: '',          // * "insurer_card_symbol": string 40
        visit_date_on2: '',                // * "visit_date_on": date ####/##/##
        insurer_card_number2: '',          // * "insurer_card_number": string 40
        medicalExamType2: '',                // * "create_file_name": string 50
        updated_on: '',                    // * "updated_on": date ####/##/##
        insurer_number3: '',               // * "insurer_number": int 10
        birthday_on3: '',                  // * "birthday_on": date ####/##/##
        qualification_presence_or_absence: '',// * "qualification_presence_or_absence": string 8
        insurer_card_symbol3: '',             // * "insurer_card_symbol": string 40
        visit_date_on3: '',                 // * "visit_date_on": date ####/##/##
        additional_screening_breast: '',    // * "additional_screening_breast": string 8
        insurer_card_number3: '',           // * "insurer_card_number": string 40
        medicalExamType3: '',                 // * "medicalExamType3":
        additional_screening_uterine: '',   // * "additional_screening_uterine": string 8
        supplementInfo: '',                 // * "supplementInfo":
        OutputFile: ''                      // * "OutputFile": string 50
      },

      OutputFile_List: [],
      Li_ReserveNum: 0,
      isLoadingForm: false
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {

    if (this.props !== prv) {
      this.formRef.current.resetFields();
      this.getScreenData();
    }
  }

  getScreenData() {

    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum,
    }

    this.setState({
      isLoadingForm: true,
    })
    TransmissionCaptureConfirmAction.getScreenData(params)
      .then(res => {

        let data = res ? res : [];

        this.setState({
          isLoadingForm: false,
          OutputFile_List: res.OutputFile_List
        })

        this.formRef.current?.setFieldsValue({
          personal_number_id: res.personal_number_id,
          kanji_name: res.kanji_name,
          office_kanji_name: res.office_kanji_name,
          contract_short_name: res.contract_short_name,
          State: res.State,
          insurer_number1: res.insurer_number1,
          birthday_on1: res.birthday_on1,
          insurer_card_symbol1: res.insurer_card_symbol1,
          visit_date_on1: res.visit_date_on1,
          insurer_card_number1: res.insurer_card_number1,
          medicalExamType: res.medicalExamType,
          create_file_name: res.create_file_name,
          updated_on: res.updated_on,
          insurer_number3: res.insurer_number3,
          birthday_on3: res.birthday_on3,
          qualification_presence_or_absence: res.qualification_presence_or_absence,
          insurer_card_symbol3: res.insurer_card_symbol3,
          visit_date_on3: res.visit_date_on3,
          additional_screening_breast: res.additional_screening_breast,
          insurer_card_number3: res.insurer_card_number3,
          medicalExamType3: res.medicalExamType3,
          additional_screening_uterine: res.additional_screening_uterine,
          supplementInfo: res.supplementInfo,
          OutputFile: res.OutputFile
        })
        //出力内容は配列で取得。一番最後の履歴を取得する。

        const arrayLength = res.OutputFile_List.length;
        if (res.OutputFile_List.length > 0) {
          this.formRef.current?.setFieldsValue({
            insurer_number2: res.OutputFile_List[arrayLength - 1].insurer_number,
            birthday_on2: res.OutputFile_List[arrayLength - 1].birthday_on,
            insurer_card_symbol2: res.OutputFile_List[arrayLength - 1].insurer_card_symbol,
            visit_date_on2: res.OutputFile_List[arrayLength - 1].visit_date_on,
            insurer_card_number2: res.OutputFile_List[arrayLength - 1].insurer_card_number,
            medicalExamType2: res.OutputFile_List[arrayLength - 1].medicalExamType2
          })
        }
      })
      .finally(() => {
        this.setState({
          isLoadingForm: false
        })
      })
  }

  FileSpecificationBtn() {
    let parram = {
      Li_ReserveNumPersonalSelect: this.props.Li_ReserveNum ?? "",
      Li_CaptureFileYyyymmddXx: this.formRef.current?.getFieldsValue('OutputFile'),
    }
  }

  selectFileName = (value) => {

    let fileDataArray = this.state.OutputFile_List;
    let index = fileDataArray.findIndex(({ creating_file_name }) => creating_file_name === value);

    this.formRef.current.setFieldsValue({
      insurer_number2: fileDataArray[index].insurer_number,
      birthday_on2: fileDataArray[index].birthday_on,
      insurer_card_symbol2: fileDataArray[index].insurer_card_symbol,
      visit_date_on2: fileDataArray[index].visit_date_on,
      insurer_card_number2: fileDataArray[index].insurer_card_number,
      medicalExamType2: fileDataArray[index].medicalExamType2,
    })
  }


  onFinish(values) {

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="transmission-capture-confirm">
        <Card title="送信・取込内容確認">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={this.state.initParams}
          >
            <Row style={{ marginLeft: 15 }}>
              <Col span={24}>
                <Space>
                  <Form.Item {...formTailLayout} name="personal_number_id" label="個人番号_ID">
                    <Input type="text" style={{ border: '0', width: '90%' }} />
                  </Form.Item>
                  <Form.Item {...formTailLayout} name="kanji_name" label="漢字氏名">
                    <Input type="text" style={{ border: '0', width: '90%' }} />
                  </Form.Item>
                  <Form.Item {...formTailLayout} name="office_kanji_name" label="事業所名">
                    <Input type="text" style={{ border: '0', width: '90%' }} />
                  </Form.Item>
                </Space>
              </Col>
            </Row>

            <Row style={{ margin: 15 }}>
              <Col span={24}>
                <Form.Item name="contract_short_name" label="コース(契約内容_略称)" >
                  <Input type="text" style={{ border: '0', width: '50%' }} />
                </Form.Item>
              </Col>
            </Row>
            <hr></hr>
            <Row style={{ margin: 0 }}>
              <Col span={12} >
                <Form.Item name="State" label="受診情報&nbsp;&nbsp;&nbsp;状態">
                  <Input type="text" style={{ border: '0', width: '90%' }} />
                </Form.Item>
              </Col>
              <Col span={12} >
                <Form.Item>
                  <Button type="primary"
                    onClick={() => {
                      let Li_ReserveNum = this.props.Li_ReserveNum ?? "";
                      let Li_CaptureFileYyyymmddXx = this.formRef.current?.getFieldsValue("OutputFile").OutputFile;
                      let Li_State = this.formRef.current?.getFieldsValue("State").State;

                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '90%',
                          component: (
                            <WS2682001_TransmissionContentsCheckCorrectPersonal
                              onFinishScreen={(output) => {

                                this.formRef.current?.setFieldsValue({
                                })
                                this.closeModal();
                              }}
                              Li_ReserveNum={Li_ReserveNum}
                              Li_CaptureFileYyyymmddXx={Li_CaptureFileYyyymmddXx}
                              Li_State={Li_State}

                            />
                          ),
                        },
                      })
                    }}
                  >出力履歴</Button>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 15 }}>
              <Col span={12}>
                <Form.Item name="insurer_number1" label="保険者番号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="birthday_on1" label="生年月日">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 15 }}>
              <Col span={12}>
                <Form.Item name="insurer_card_symbol1" label="保険証記号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="visit_date_on1" label="受診日&nbsp;&nbsp;&nbsp;&nbsp;">
                  <Input type="text" style={{ border: '0', width: '88%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 15 }}>
              <Col span={12}>
                <Form.Item name="insurer_card_number1" label="保険証番号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="medicalExamType" label="健診種類">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <hr></hr>
            <Row style={{ margin: 0 }}>
              <Col span={12}>
                <Form.Item name="OutputFile" label="出力内容&nbsp;&nbsp;&nbsp;&nbsp;ファイル名" readOnly style={{ width: 350 }}>
                  <Select onChange={(e) => this.selectFileName(e)}>
                    {this.state.OutputFile_List?.map((value, index) => (
                      <Select.Option
                        key={value.creating_file_name + index}
                        value={value.creating_file_name}>{value.creating_file_name}
                      </Select.Option>
                    ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button type="primary"

                    onClick={() => {
                      let Li_ReserveNumPersonalSelect = this.props.Li_ReserveNum ?? "";
                      let Li_CaptureFileYyyymmddXx = this.formRef.current?.getFieldsValue("OutputFile").OutputFile;

                      if (Li_CaptureFileYyyymmddXx !== '') {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90%',
                            component: (
                              <WS2683001_TransmissionContentsCheckFixFile
                                onFinishScreen={(output) => {
                                  this.formRef.current?.setFieldsValue({
                                  })
                                  this.closeModal();
                                }}
                                Li_ReserveNumPersonalSelect={Li_ReserveNumPersonalSelect}
                                Li_CaptureFileYyyymmddXx={Li_CaptureFileYyyymmddXx}

                              />
                            ),
                          },
                        })

                      } else {
                        message.error('ファイルを選択してください。');
                      }
                    }

                    }

                  >ファイル明細</Button>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 15 }}>
              <Col span={12}>
                <Form.Item name="insurer_number2" label="保険者番号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="birthday_on2" label="生年月日">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 15, }}>
              <Col span={12}>
                <Form.Item name="insurer_card_symbol2" label="保険証記号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="visit_date_on2" label="受診日&nbsp;&nbsp;&nbsp;&nbsp;">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 15 }}>
              <Col span={12}>
                <Form.Item name="insurer_card_number2" label="保険証番号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="medicalExamType2" label="健診種類">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>
            <hr></hr>
            <Row style={{ marginLeft: 0 }}>
              <Col span={16}>
                <Space>
                  <Form.Item name="create_file_name" label="取込内容&nbsp;&nbsp;&nbsp;&nbsp;ファイル名">
                    <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                  </Form.Item>
                  <Form.Item name="updated_on" label="取得日">
                    <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={8}>
                <Form.Item name="" >
                  <Button type="primary"

                    onClick={() => {
                      let Li_ReserveNum = this.props.Li_ReserveNum ?? "";
                      let Li_State = this.formRef.current?.getFieldsValue("State").State;

                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '40%',
                          component: (
                            <WS2686001_QualifyResultManuallyUpdate
                              onFinishScreen={(output) => {
                                this.closeModal();
                                this.getScreenData();
                              }}
                              Li_ReserveNum={Li_ReserveNum}
                              Li_State={Li_State}

                            />
                          ),
                        },
                      })
                    }
                    }
                  >手動登録</Button>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginLeft: 15 }}>
              <Col span={8}>
                <Form.Item name="insurer_number3" label="保険者番号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="birthday_on3" label="生年月日">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="qualification_presence_or_absence" label="資格情報&nbsp;&nbsp;">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginLeft: 15 }}>
              <Col span={8}>
                <Form.Item name="insurer_card_symbol3" label="保険証記号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="visit_date_on3" label="受診日&nbsp;&nbsp;&nbsp;&nbsp;">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="additional_screening_breast" label="追加(乳)&nbsp;&nbsp;&nbsp;">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginLeft: 15 }}>
              <Col span={8}>
                <Form.Item name="insurer_card_number3" label="保険証番号">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="medicalExamType3" label="健診種類">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="additional_screening_uterine" label="追加(子宮)">
                  <Input type="text" style={{ border: '0', width: '90%' }} readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginLeft: 15 }}>
              <Col span={24}>
                <Form.Item name="supplementInfo" label="補足情報">
                  <textarea style={{ width: '80%' }} autosize={{ minRows: 4, maxRows: 10 }} readOnly />
                </Form.Item>
              </Col>
            </Row>


          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2685001_TransmissionCaptureConfirm);
