import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Input, Form, Card, Button, Table, Modal, message, Radio } from "antd";
import { MoreOutlined, PlusOutlined, DeleteOutlined, QuestionCircleOutlined, SaveOutlined, CheckOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { generateUUID } from 'components/Commons/generateUUID'


const ModalEdit = (props) => {
  const formRef = React.createRef()
  const [itemChange, setItemChange] = React.useState([])
  const [disabledSaveBtn, setDisabledSaveBtn] = React.useState(true)
  /**
   * Modalを開いた時の処理
   */
  React.useEffect(() => {
    formRef.current?.setFieldsValue(props.record)
  }, [props.record])

  /**
   * 入力　変更処理
   * @param {*} e
   */
  const handleFormChange = (e) => {
    let columnName = Object.keys(e)[0]
    let columnValue = Object.values(e)[0]
    setItemChange({
      ...itemChange,
      [columnName]: columnValue
    })
    // 登録ボタン 活性
    setDisabledSaveBtn(false)
  }

  return (
    <Card title={props.newFlag ? '新規' : '変更'}>
      <Form
        ref={formRef}
        onValuesChange={handleFormChange}
        autoComplete='off'
      >
        <label>有効</label>
        <Form.Item name='enabled'>
          <Radio.Group>
            <Radio value={1}>有効</Radio>
            <Radio value={0}>無効</Radio>
          </Radio.Group>
        </Form.Item>
        <label>コード</label>
        <Form.Item name="affiliation_cd">
          <Input type="number" className="input-size-12" />
        </Form.Item>
        <label>略式名称</label>
        <Form.Item name="short_name">
          <Input />
        </Form.Item>
        <label>正式名称</label>
        <Form.Item name="formal_name">
          <Input />
        </Form.Item>
      </Form>

      <div className='box_button_bottom_right'>
        <Button
          danger
          type='primary'
          style={{ display: (props.newFlag) ? 'none' : '' }}
          icon={<DeleteOutlined />}
          onClick={() => {
            // 親画面に反映する
            props.onDelete(props.record.id)

          }}
        >
          <span className='btn_label'>
            削除
          </span>
        </Button>
        {/* 登録ボタン */}

        <Button
          type='primary'
          icon={<SaveOutlined />}
          disabled={disabledSaveBtn}
          onClick={() => {
            // 親画面に反映
            props.onUpdate(formRef.current?.getFieldsValue())
          }}
        >
          <span className='btn_label'>
            登録
          </span>
        </Button>
      </div>
    </Card>
  )
}


class WS0341504_AffiliationInfo extends React.Component {

  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      AffiliationInfo: this.props.Li_AffiliationInfo
    })
    this.setState({
      AffiliationInfo: this.props.Li_AffiliationInfo,
      OfficeCode: this.props.Li_OfficeCode,
      BranchStoreCode: this.props.Li_BranchStoreCode
    })
  }


  saveData = (record, data) => {
    let AffiliationInfo = [...this.state.AffiliationInfo]
    let newAffiliationInfo = []

    if (data) {
      const newRow = {
        id: data.id,
        affiliation_cd: Number(record.affiliation_cd),
        short_name: record.short_name,
        formal_name: record.formal_name,
        enabled: record.enabled
      }

      AffiliationInfo.map((item, index) => {
        if (item.id == data.id) {
          newAffiliationInfo.push(newRow)
        } else {
          newAffiliationInfo.push(item)
        }
      })

      AffiliationInfo = [...newAffiliationInfo]
    } else {
      const newRow = {
        id: generateUUID(),
        affiliation_cd: Number(record.affiliation_cd),
        short_name: record.short_name,
        formal_name: record.formal_name,
        enabled: record.enabled
      }

      AffiliationInfo.push(newRow)
    }

    this.setState({ AffiliationInfo: AffiliationInfo })
    this.props.onUpdate(AffiliationInfo)
    this.forceUpdate();
  };


  deleteRecordTable = (record) => {
    let AffiliationInfo = [...this.state.AffiliationInfo]
    let newAffiliationInfo = []

    AffiliationInfo.map((item, index) => {
      if (item.id != record.id) {
        newAffiliationInfo.push(item)
      }
    })

    this.setState({ AffiliationInfo: newAffiliationInfo })
    this.props.onUpdate(newAffiliationInfo)
    this.forceUpdate();
  };


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  render() {
    return (
      <div className="affiliation-info">
        <Card title='所属'>
          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            autoComplete="off"
          >
            <Table
              dataSource={this.state.AffiliationInfo}
              rowKey={record => record.id}
              pagination={false}
              size="small"
              bordered
            >
              <Table.Column
                title="有効"
                width={80}
                dataIndex="enabled"
                render={(text, record, index) => {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      {record.enabled === 1 ? <CheckOutlined /> : ''}
                    </div>
                  )
                }}
              />
              <Table.Column
                width={100}
                title="コード"
                dataIndex="affiliation_cd"
                render={(value, record, index) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )
                }}
              />
              <Table.Column
                title="略式名称"
                dataIndex="short_name"
              />
              <Table.Column
                title="正式名称"
                dataIndex="formal_name"
              />
              <Table.Column
                width={40}
                key="action"
                align="center"
                title={() => (
                  <Button
                    size='small'
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (
                            <ModalEdit
                              newFlag={true}
                              onUpdate={(output) => {
                                Modal.confirm({
                                  content: '新規登録します。よろしいですか？',
                                  okText: 'は　い',
                                  cancelText: 'いいえ',
                                  onOk: () => {
                                    // 変更内容を反映
                                    this.saveData(output, false)
                                    this.closeModal()
                                  }
                                })
                              }}
                            />
                          )
                        }
                      })
                    }}
                  />
                )}
                render={(text, record, index) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => {
                        // 編集、削除
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 500,
                            className: '',
                            component:
                              <ModalEdit
                                newFlag={false}
                                record={record}
                                onUpdate={(output) => {
                                  Modal.confirm({
                                    content: '上書き登録します。よろしいですか？',
                                    okText: 'は　い',
                                    cancelText: 'いいえ',
                                    onOk: () => {
                                      // 保存ボタン押下、変更内容を反映
                                      this.saveData(output, record)
                                      this.closeModal()
                                    }
                                  })
                                }}
                                onDelete={(output) => {
                                  Modal.confirm({
                                    title: "確認",
                                    icon: <QuestionCircleOutlined style={{ color: "#1890ff" }} />,
                                    content: "削除しますか",
                                    okText: "削除",
                                    cancelText: "キャンセル",
                                    onOk: () => {
                                      this.deleteRecordTable(record)
                                      this.closeModal()
                                    }
                                  });
                                }}
                              />
                          },
                        })
                      }}
                    />
                  );
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div >
    )
  }
}
export default WS0341504_AffiliationInfo
