import React from "react";

import { Card, Table, Select, Form, message, Button, Space, Input, Modal } from "antd";
import {
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

import axios from 'configs/axios';
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS2715042_InspectTakingPartialConvertSub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '検査取込部分変換';

    this.state = {
      addCount: 0,
      tableData: [],
      disabledFlag: true
    };

    this.loadList = this.loadList.bind(this);
  }

  componentDidMount() {
    this.loadList();
  }

  loadList() {
    axios.get('/api/inspect-item-convert-internal/inspect-taking-partial-convert-sub')
      .then(res => {
        this.formRef.current.setFieldsValue({
          tableData: res.data
        });
        this.setState({
          tableData: res.data
        })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
  }

  updateTableData = (index, name, value) => {
    let data = [...this.state.tableData]
    data[index][name] = value
    this.formRef.current?.setFieldsValue({
      tableData: data
    })
    this.setState({
      tableData: data,
      disabledFlag: false
    })
  }

  /**
   * 新規追加、行追加
   */
  add = () => {
    let idCount = this.state.addCount + 1
    let newTableData = [...this.state.tableData]

    let newRecord = {
      id: 'new_' + String(idCount),
      conversion_before: '',
      conversion_before_position: '',
      conversion_after: '',
      conversion_after_position: ''
    }

    newTableData.unshift(newRecord)

    this.formRef.current.setFieldsValue({
      tableData: newTableData
    });
    this.setState({
      tableData: newTableData,
      addCount: idCount
    })
  }

  /**
   *  削除
   * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
   */
  delete = (id) => {
    let newTableData = [...this.state.tableData]
    const index = newTableData.findIndex((value) => (id === value.id))
    newTableData.splice(index, 1)

    this.formRef.current.setFieldsValue({
      tableData: newTableData
    });
    this.setState({
      tableData: newTableData,
      disabledFlag: false
    })
  }

  /**
   * 保存
   */
  save = () => {
    axios.post('/api/inspect-item-convert-internal/inspect-taking-partial-convert-sub', { tableData: this.state.tableData })
      .then(res => {
        message.success('保存完了！');
        this.setState({ disabledFlag: true })
        this.loadList();
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      })
  }

  render() {
    return (
      <div className="inspect-taking-partial-convert-sub" style={{ width: 800 }}>
        <Card title="検査取込部分変換">
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className="box_container">
              <Table
                bordered
                size='small'
                dataSource={this.state.tableData}
                pagination={false}
                rowKey={(record) => record.id || Math.random()}
                scroll={{ x: 'max-content', y: resizableTableScroll(100) }}
              >
                <Table.Column title="結果" dataIndex="conversion_before" render={(vlaue, record, index) => (
                  <Form.Item name={['tableData', index, "conversion_before"]}>
                    <Input
                      maxLength={10}
                      onBlur={(e) => this.updateTableData(index, 'conversion_before', e.target.value)}
                      onPressEnter={(e) => this.updateTableData(index, 'conversion_before', e.target.value)}
                    />
                  </Form.Item>
                )} />
                <Table.Column title="位置" dataIndex="conversion_before_position" render={(row, record, index) => (
                  <Form.Item name={['tableData', index, "conversion_before_position"]}>
                    <Select onChange={(e) => this.updateTableData(index, 'conversion_before_position', e)}>
                      <Select.Option value=""></Select.Option>
                      <Select.Option value="B">前</Select.Option>
                      <Select.Option value="A">後</Select.Option>
                    </Select>
                  </Form.Item>
                )} />
                <Table.Column title="" key="move-to" render={() => (
                  <div style={{ textAlign: 'center' }}>→</div>
                )} />
                <Table.Column title="結果" dataIndex="conversion_after" render={(vlaue, record, index) => (
                  <Form.Item name={['tableData', index, "conversion_after"]}>
                    <Input
                      maxLength={10}
                      onBlur={(e) => this.updateTableData(index, 'conversion_after', e.target.value)}
                      onPressEnter={(e) => this.updateTableData(index, 'conversion_after', e.target.value)}
                    />
                  </Form.Item>
                )} />
                <Table.Column title="位置" dataIndex="conversion_after_position" render={(row, record, index) => (
                  <Form.Item name={['tableData', index, "conversion_after_position"]}>
                    <Select onChange={(e) => this.updateTableData(index, 'conversion_after_position', e)}>
                      <Select.Option value=""></Select.Option>
                      <Select.Option value="B">前</Select.Option>
                      <Select.Option value="A">後</Select.Option>
                    </Select>
                  </Form.Item>
                )} />
                <Table.Column
                  title={() => (
                    <Button
                      type="primary"
                      size='small'
                      icon={<PlusOutlined />}
                      onClick={() => this.add()} />
                  )}
                  key="action"
                  width={50}
                  fixed={'right'}
                  render={(text, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        danger
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          this.delete(record.id)
                          // Modal.confirm({
                          //   title: "確認",
                          //   icon: (
                          //     <QuestionCircleOutlined
                          //       style={{ color: "#1890ff" }}
                          //     />
                          //   ),
                          //   content: "削除しますか",
                          //   okText: "削除",
                          //   cancelText: "キャンセル",
                          //   onOk: () => {
                          //     this.delete(record.id)
                          //   },
                          // });
                        }}
                      />
                    </div>
                  )}
                />
              </Table>
              <div className="box_button_bottom_right">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  disabled={this.state.disabledFlag}
                  onClick={() => this.save()}
                >
                  保存
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}

export default WS2715042_InspectTakingPartialConvertSub;
