import React from 'react'
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import { PlusOutlined, CheckOutlined, MoreOutlined } from '@ant-design/icons';
import RadiographyJudgeMaintainAction from "redux/InputBusiness/RadiographyJudgeMaintain/RadiographyJudgeMaintain.action"
import { Card, Table, message, Button, Space, Dropdown, Menu, } from 'antd'

const columns = [
  {
    title: '判定ﾚﾍﾞﾙ',
    dataIndex: 'judgment_level',
    type: 1,
  },
  {
    title: '読影判定',
    dataIndex: 'interpretation_judgment_result',
    type: 1,
  },
  {
    title: '健診判定',
    dataIndex: 'medical_exam_judgment_result',
    type: 1,
  },
  {
    title: '正常',
    dataIndex: 'normal_value_flag',
    type: 3,
    radioList: [
      {
        value: 1,
        label: 'はい'
      },
      {
        value: 0,
        label: 'いいえ'
      }],
  },
  {
    title: '備考',
    dataIndex: 'options',
    type: 1,
  }
]

class WS1876001_RadiographyJudgeMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '読影判定保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.loadMainSource()
  }


  loadMainSource = () => {
    this.setState({ isLoading: true })
    RadiographyJudgeMaintainAction.index()
      .then(res => {
        this.setState({dataSource: res.MainSource})
      })
      .finally(() => this.setState({ isLoading: false }))
  }


  // 編集モーダル
  showModalChange = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '550px',
        className: '',
        component:
          <ModalChange
            columns={columns}
            record={record}
            onUpdate={(output) => {
              this.save(output, record.id)
              this.closeModal()
            }}
            onDelete={(output) => {
              this.delete(record.id)
              this.closeModal()
            }}
          />
      },
    })
  }


  // 追加モーダル
  showModalAdd = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '550px',
        className: '',
        component:
          <ModalAdd
            columns={columns}
            onFinishScreen={(output) => {
              this.save(output)
              this.closeModal()
            }}
          />
      },
    });
  }


  //★★編集保存ボタン★★//
  save = (output, id) => {
    let params = {
      id: id ?? null,
    }
    let itemChange = output.itemChange
    Object.keys(itemChange).forEach(key => {
      params = {
        ...params,
        [key]: itemChange[key]
      }
    })
    RadiographyJudgeMaintainAction.save(params)
      .then((res) => {
        if(res?.message) {
          message.info(res.message)
        }
        this.loadMainSource()
      })
      .catch((err) => {
        console.log(err)
        const res = err.response
        if (!res || !res.data || !res.data.massage) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }


  //★★削除ボタン★★//
  delete = (id) => {
    let params = {
      id: Number(id),
    }
    RadiographyJudgeMaintainAction.delete(params)
      .then(res => {
        if(res?.message) {
          message.info(res.message)
        }
        this.loadMainSource()
      })
      .catch((err) => {
        console.log(err)
        const res = err.response
        if (!res || !res.data || !res.data.massage) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  checkJudgment(value) {
    switch (value) {
      case 1:
        return <CheckOutlined />
      case 0:
        return ''
      default: null
    }
  }


  render() {
    return (
      <div className='radiography-judge-maintain'>
        <Card title='読影判定保守'>
          <Table bordered
            dataSource={this.state.dataSource}
            loading={this.state.isLoading}
            pagination={false}
            rowKey={(record) => record.id}
            size='small'
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  this.showModalChange(record)
                }
              }
            }}
            scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
          >
            <Table.Column title='判定ﾚﾍﾞﾙ' showSorterTooltip={false} dataIndex='judgment_level' />
            <Table.Column title='読影判定' showSorterTooltip={false} dataIndex='interpretation_judgment_result' />
            <Table.Column title='健診判定' showSorterTooltip={false} dataIndex='medical_exam_judgment_result' />
            <Table.Column title="正常" showSorterTooltip={false} dataIndex="normal_value_flag" align='center' width={40}
              render={(value, record, index) => {
                return (
                  this.checkJudgment(value)
                )
              }}
            />
            <Table.Column title='重さ' showSorterTooltip={false} dataIndex='judgment_weight' />
            <Table.Column title='備考' showSorterTooltip={false} dataIndex='options' />

            {/* ＋ボタンの設定 */}
            <Table.Column align='center' width={40} fixed='right'
              title={
                <Button
                  type="primary"
                  size="small"
                  icon={<PlusOutlined />}
                  loading={this.state.isLoadingInsurersList}
                  onClick={() => {
                    this.showModalAdd()
                  }}
                />
              }
              render={(text, record, index) => (
                <Space>
                  <Dropdown
                    trigger='click'
                    overlay={() => (
                      <Menu>
                        <Menu.Item key='1' style={{ width: '100%' }}
                          size="small"
                          onClick={() => {
                            this.showModalChange(record)
                          }}
                        >編集</Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button size='small' icon={<MoreOutlined />}></Button>
                  </Dropdown>
                </Space>
              )}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    )
  }
}

export default WS1876001_RadiographyJudgeMaintain