import { DeleteOutlined, PlusOutlined, SaveOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, message, Modal, Row, Space, Table, Dropdown, Menu } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import axios from "configs/axios";
import { width } from "dom-helpers";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import WS0274001_InspectCmtSearchQuery from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery";
import WS1181003_Copy from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1181003_Copy";
import WS1181005_CmtConvert from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1181005_CmtConvert";
import React from "react";
import { connect } from "react-redux";


import { generateUUID } from 'components/Commons/generateUUID'

const styleDiv = {
  textAlign: "right",
  padding: "7px 0",
  color: "#14468C",
  fontWeight: "bold",
  background: "#C8DCF5"
};
const styleCol = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "5px"
};

class WS1181001_GovernmentTubeConvertMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06000:政管変換保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      dataAddNew: null,
      pagination: {
        defaultCurrent: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      dataSource: [],

      isLoading: false,
      isLoadingTable: false,

      selectedRow: {},
      selectedRowKeys: [],
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      tableData: []
    });
    this.getScreenData();
  }



  getScreenData = params => {
    this.setState({ ...this.state, isLoading: true });

    axios
      .get("/api/associate-insure-param-maintain/government-tube-convert-maintain/getScreenData", {
        params: {
          params: params
        }
      })
      .then(res => {
        const tmp = res.data.map(item => ({ ...item, newflg: false }))
        this.setState({ dataSource: [] });

        this.setState(() => { }, () => {
          this.setState({ dataSource: tmp });
        })

      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ ...this.state, isLoading: false });
      });
  };

  isEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false;
  }

  AddNewData() {
    this.handleAdd();
  }

  handleAdd() {
    const newData = {
      id: generateUUID(),
      conversion_after_exam_value: "",
      exam_code: "",
      exam_name: "",
      test_result: "",
      newflg: true
    }

    const dataSource = [...this.state.dataSource]
    this.setState({ dataSource: [] })
    dataSource.length > 0 ? dataSource.unshift(newData) : dataSource.push(newData)
    this.setState({ dataSource: dataSource })
  }

  Save(record) {
    this.setState({ isLoading: true });

    axios.post("/api/associate-insure-param-maintain/government-tube-convert-maintain/save"
      , { mainSource: [record] })
      .then(res => {
        this.getScreenData();
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  Delete(record) {

    this.setState({ isLoading: true });

    axios.post("/api/associate-insure-param-maintain/government-tube-convert-maintain/delete"
      , { mainSource: [{ id: record.id }] })
      .then(res => {
        this.getScreenData();
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  changeValue = (key, record, event) => {
    if (record[key] !== event.target.value) {
      record[key] = event.target.value
      record.changed = true
      this.forceUpdate()
    }
  }

  render() {
    return (
      <div className="government-tube-convert-maintain">
        <Card title="政管変換保守">
          <Form ref={this.formRef} onFinish={this.onFinish} autoComplete="off">
            <Table
              style={{ marginTop: "10px" }}
              size="small"
              bordered
              rowClassName={(record, index) =>
                record.id === this.state.selectedRow?.id ? "table-row-light" : ""
              }

              dataSource={this.state.dataSource}
              loading={this.state.isLoading}
              pagination={false}
              rowKey={record => record.id}
              scroll={{ x: 900 }}
              onRow={(record, index) => {

              }
              }
            >
              <Table.Column
                align={'center'}
                width={'10%'}
                title="コード"
                dataIndex="exam_code"
                showSorterTooltip={false}
                sorter={(a, b) => a.exam_code - b.exam_code}
                render={(value, record) => {


                  const changeValue = (key, value) => {

                    if (record[key] !== value) {
                      record[key] = value
                      record.changed = true
                    }
                  }

                  return (
                    !record.newflg ?
                      <div style={{ textAlign: 'right' }}>{value}</div>
                      :
                      <Input maxLength={40}
                        defaultValue={value}
                        readOnly
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: "60%",
                              component: (
                                <WS0271001_InspectItemSearchQuerySingle
                                  onFinishScreen={({ recordData }) => {
                                    changeValue('exam_code', recordData.test_item_code)
                                    changeValue('exam_name', recordData.exam_name)

                                    const tmp = [...this.state.dataSource]

                                    this.setState(() => { this.setState({ dataSource: [] }) }, () => {
                                      this.setState(() => { this.setState({ dataSource: tmp }) })
                                    })

                                    this.closeModal()
                                  }}
                                />

                              )
                            }
                          });
                        }}
                      />


                  );
                }}
              />

              <Table.Column
                align={'center'}
                width={'40%'}
                title="検査名称"
                dataIndex="exam_name"
                showSorterTooltip={false}
                sorter={(a, b) => a.exam_name.localeCompare(b.exam_name, "jp")}
                render={(value, record) => {
                  return (
                    <div style={{ textAlign: 'left' }}>{value}</div>
                  );
                }}
              />

              <Table.Column
                align={'center'}
                width={'20%'}
                title="検査値"
                dataIndex="test_result"
                showSorterTooltip={false}
                sorter={(a, b) => a.test_result.localeCompare(b.test_result, "jp")}
                render={(value, record) => {

                  return (
                    <Input
                      style={{ textAlign: 'right' }}
                      defaultValue={value}
                      maxLength={40}
                      onBlur={(e) => { this.changeValue('test_result', record, e) }}
                      onPressEnter={(e) => { this.changeValue('test_result', record, e) }}
                      onDoubleClick={() => {
                        this.setState({
                          ...this.state,
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            component: (
                              <WS0274001_InspectCmtSearchQuery
                                Lio_CommentsClassify={record.exam_comment_code}
                                LnkOutInspectCommentsScreen={value}
                                onFinishScreen={({ LnkOutInspectCmtScreen }) => {
                                  this.changeValue('test_result', record, LnkOutInspectCmtScreen)
                                  this.closeModal();
                                }}
                              />
                            ),
                            width: "70%"
                          }
                        });
                      }}
                    />

                  );
                }}
              />
              <Table.Column
                align={'center'}
                width={'20%'}
                title="変換後"
                dataIndex="conversion_after_exam_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.conversion_after_exam_value.localeCompare(b.conversion_after_exam_value, "jp")}
                render={(value, record) => {

                  return (
                    <Input
                      defaultValue={value}
                      maxLength={40}
                      onBlur={(e) => { this.changeValue('conversion_after_exam_value', record, e) }}
                      onPressEnter={(e) => { this.changeValue('conversion_after_exam_value', record, e) }}
                    />
                  );
                }}
              />
              <Table.Column
                width={'10%'}
                align="center"
                title={
                  <Button
                    size="small"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => this.AddNewData()}
                  ></Button>
                }
                render={(text, record) => {

                  return (

                    <Space>
                      <Button
                        size="small"
                        style={{ color: "#42b10b", border: "none", marginRight: "5px" }}
                        icon={<SaveOutlined style={{ color: !record.changed ? 'gainsboro' : 'green' }} />}
                        disabled={!record.changed}
                        onClick={() => {
                          this.Save(record);
                        }}
                      ></Button>
                      <Button
                        size="small"
                        style={{ color: "red" }}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            content: "消去してもよろしいですか？",
                            okText: "は　い",
                            cancelText: "いいえ",
                            onOk: () => this.Delete(record)
                          });
                        }}
                      ></Button>

                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>

                            <Menu.Item
                              key='1'
                              onClick={() => {

                                this.setState({
                                  ...this.state,
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: "600px",
                                    component: (
                                      <WS1181003_Copy
                                        Li_CopySourceCode={record.exam_code}
                                        onFinishScreen={() => {
                                          this.closeModal();
                                          this.getScreenData();
                                        }}
                                      />
                                    )
                                  }
                                });
                              }
                              }
                            >
                              複写
                            </Menu.Item>

                            {/* <Menu.Item
                              key='2'
                              onClick={() => {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: "600px",
                                    component: (
                                      <WS1181005_CmtConvert
                                        Li_InspectCode={record.exam_code}
                                        Li_ExamName={record.exam_name}
                                        onFinishScreen={() => {
                                          this.closeModal();
                                          this.getScreenData();
                                        }}
                                      />
                                    )
                                  }
                                });

                              }

                              }
                            >
                              検査追加
                            </Menu.Item> */}
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>

                    </Space>
                  );
                }}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1181001_GovernmentTubeConvertMaintain);
