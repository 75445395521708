import WS2706001_UserRegister from "pages/MN_Basis/V4MN0002002_UserInfo/WS2706001_UserRegister.jsx";
import WS2706002_UserRegisterForm from "pages/MN_Basis/V4MN0002002_UserInfo/WS2706002_UserRegisterForm.jsx";
import WS2518001_PasswordModify from "pages/MN_Basis/V4MN0002002_UserInfo/WS2518001_PasswordModify.jsx";
import WS0339001_InsurerInfoMaintain from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0339001_InsurerInfoMaintain.jsx";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery.jsx";
import WS0344001_SupplementalInfoSetting from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx";
import WS0344002_SelectedRange from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0344002_SelectedRange.jsx";
import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx";
import WS0086002_ZipCodeQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0086002_ZipCodeQuery.jsx";
import WS0308017_OrganizationCreateNew from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0308017_OrganizationCreateNew.jsx";
import WS0341001_OfficeInfoMaintain from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341001_OfficeInfoMaintain.jsx";
import WS0341008_BranchShopInquiry from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341008_BranchShopInquiry.jsx";
import WS0341009_BranchStoreGenerated from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0341009_BranchStoreGenerated.jsx";
import WS0343001_PersonalInfoMaintain from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343001_PersonalInfoMaintain.jsx";
import WS0343006_Extension from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343006_Extension.jsx";
import WS0343080_SelectOffice from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343080_SelectOffice.jsx";
import WS0382001_PersonalAttributesReacquire from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0382001_PersonalAttributesReacquire.jsx";
import WS0384006_DuplicateQueryYearOfBirthNameGender from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0384006_DuplicateQueryYearOfBirthNameGender.jsx";
import WS0384003_DuplicateQueryDateOfBirth from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0384003_DuplicateQueryDateOfBirth.jsx";
import WS2727001_PatientInfoQueryStard from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS2727001_PatientInfoQueryStard.jsx";
import WS0306001_ContractInfoMaintain from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0306001_ContractInfoMaintain.jsx";
import WS0306011_CreateNew from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0306011_CreateNew.jsx";
import WS0307008_ContractHistorySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307008_ContractHistorySub.jsx";
import WS0307071_HistorySwitching from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307071_HistorySwitching.jsx";
import WS0307076_CreateContract from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307076_CreateContract.jsx";
import WS0307082_CodeChanges from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307082_CodeChanges.jsx";
import WS0315002_ConditionCopy from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0315002_ConditionCopy.jsx";
import WS0315004_ContractNumberInquiry from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0315004_ContractNumberInquiry.jsx";
import WS0315007_ManageCopy from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0315007_ManageCopy.jsx";
import WS0316001_CopyContractSelect from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0316001_CopyContractSelect.jsx";
import WS0316026_ConfirmScreen from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0316026_ConfirmScreen.jsx";
import WS0328001_ContractOfficeInfoInquirySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0328001_ContractOfficeInfoInquirySub.jsx";
import WS0329001_ContractInsurerInfoInquirySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0329001_ContractInsurerInfoInquirySub.jsx";
import WS0330001_ContractHistoricalQuerySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0330001_ContractHistoricalQuerySub.jsx";
import WS0426001_MasterListOutputContractInfo from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0426001_MasterListOutputContractInfo.jsx";
import WS0275001_ContractQuerySelect from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0275001_ContractQuerySelect.jsx";
import WS0335001_ConsultInfoReconstruction from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS0335001_ConsultInfoReconstruction.jsx";
import WS0335011_InspectChanges from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS0335011_InspectChanges.jsx";
import WS0612001_PersonalActionItemTreeDisplay from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS0612001_PersonalActionItemTreeDisplay.jsx";
import WS0612002_CharacterStringSearch from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS0612002_CharacterStringSearch.jsx";
import WS2786001_ConditionAddSub from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx";
import WS2786013_AcceptanceNumberSelect from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786013_AcceptanceNumberSelect.jsx";
import WS2786020_SelectCourseList from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786020_SelectCourseList.jsx";
import WS3074001_AcceptanceProcessMenu from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS3074001_AcceptanceProcessMenu.jsx";
import WS0345001_DepartmentChangeOnce from "pages/BS_BasicInfo/V4MS0003100_DepartmentChangeOnce/WS0345001_DepartmentChangeOnce.jsx";
import WS0402001_PersonalNumberIntegration from "pages/BS_BasicInfo/V4MS0003310_PersonalNumberIntegration/WS0402001_PersonalNumberIntegration.jsx";
import WS0401001_PersonalNumberMigration from "pages/BS_BasicInfo/V4MS0003300_PersonalNumberMigration/WS0401001_PersonalNumberMigration.jsx";
import WS0251003_OfficeSpecialDisplay from "pages/BS_BasicInfo/V4MS0003300_PersonalNumberMigration/WS0251003_OfficeSpecialDisplay.jsx";
import WS0252001_OfficeSpecialMaintain from "pages/BS_BasicInfo/V4MS0003300_PersonalNumberMigration/WS0252001_OfficeSpecialMaintain.jsx";
import WS0264001_VisitHistoricalQuery from "pages/BS_BasicInfo/V4MS0003300_PersonalNumberMigration/WS0264001_VisitHistoricalQuery.jsx";
import WS2708001_SetInfoMaintain from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2708001_SetInfoMaintain.jsx";
import WS2708003_SetCsvCreate from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2708003_SetCsvCreate.jsx";
import WS2708010_CopySet from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2708010_CopySet.jsx";
import WS0299006_ConfirmScreen from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS0299006_ConfirmScreen.jsx";
import WS2709008_SetInfoChangeSub from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2709008_SetInfoChangeSub.jsx";
import WS2709068_CopyStartDate from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2709068_CopyStartDate.jsx";
import WS2710001_SetInfoBatchProcess from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2710001_SetInfoBatchProcess.jsx";
import WS2710014_StartDateSelect from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2710014_StartDateSelect.jsx";
import WS2710017_InspectAddDelete from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2710017_InspectAddDelete.jsx";
import WS2710022_SetIdentificationChange from "pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2710022_SetIdentificationChange.jsx";
import WS0317001_CreateContractHistoryTogether from "pages/BS_BasicInfo/V4KB0201200_CreatingContractHistoryTogether/WS0317001_CreateContractHistoryTogether.jsx";
import WS0331001_ContractInfoBatchProcess from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0331001_ContractInfoBatchProcess.jsx";
import WS0331019_BatchUpdateProcess from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0331019_BatchUpdateProcess.jsx";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import WS0302001_SetInfoSearch from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0302001_SetInfoSearch.jsx";
import WS0309017_ContractItemSub from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309017_ContractItemSub.jsx";
import WS0310004_ContractEditingSub from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0310004_ContractEditingSub.jsx";
import WS0310120_ConditionSetting from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0310120_ConditionSetting.jsx";
import WS0605127_ContractLineItemDisplay from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay.jsx";
import WS0605130_ContractInspectCondition from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605130_ContractInspectCondition.jsx";
import WS0605162_ContractInspectInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605162_ContractInspectInquiry.jsx";
import WS0606001_ContractQueryOld from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0606001_ContractQueryOld.jsx";
import WS2528001_ReserveStatusSearch from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2528001_ReserveStatusSearch.jsx";
import WS2528031_SettingDisplayItem from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2528031_SettingDisplayItem.jsx";
import WS2528047_DetailsExtract from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2528047_DetailsExtract.jsx";
import WS0206001_ScheduleRegisterScreen from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS0206001_ScheduleRegisterScreen.jsx";
import WS0548001_ReservesPersonsBulkUpdate from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS0548001_ReservesPersonsBulkUpdate.jsx";
import WS2529001_HourlyReserveList from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2529001_HourlyReserveList.jsx";
import WS2531063_DisplayContentChange from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2531063_DisplayContentChange.jsx";
import WS2553003_PeriodTimeInquiry from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2553003_PeriodTimeInquiry.jsx";
import WS2577003_CalendarEmptySub from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2577003_CalendarEmptySub.jsx";
import WS2580001_ScheduleChange from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2580001_ScheduleChange.jsx";
import WS2613145_CsvOutputConfirm from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2613145_CsvOutputConfirm.jsx";
import WS2532001_GroupBookings from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532001_GroupBookings.jsx";
import WS2532008_InsuranceCardInquiry from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532008_InsuranceCardInquiry.jsx";
import WS2532012_DateOfBirthInquiry from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2532012_DateOfBirthInquiry.jsx";
import WS0289012_ContractInfoInquiry from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx";
import WS2533001_ConfirmSub from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2533001_ConfirmSub.jsx";
import WS2542001_PreviousCourseAcquisitionSub from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2542001_PreviousCourseAcquisitionSub.jsx";
import WS2585001_OfficeInfoInquirySub from "pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx";
import WS2535001_FrameReserve from "pages/YK_ReservationBusiness/V5YK0003000_FrameReserve/WS2535001_FrameReserve.jsx";
import WS2535011_Verification from "pages/YK_ReservationBusiness/V5YK0003000_FrameReserve/WS2535011_Verification.jsx";
import WS2537001_PersonalReserveProcess from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx";
import WS2537046_SetAdd from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537046_SetAdd.jsx";
import WS2537059_VisitsChangeConfirm from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537059_VisitsChangeConfirm.jsx";
import WS2537084_InfoHeld from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537084_InfoHeld.jsx";
import WS0250001_PersonalSpecialMaintain from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0250001_PersonalSpecialMaintain.jsx";
import WS0273001_VisitsTargetSearchQuery from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0273001_VisitsTargetSearchQuery.jsx";
import WS0273011_DisplayContentChange from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0273011_DisplayContentChange.jsx";
import WS0311005_MoneyAmountInputSub from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0311005_MoneyAmountInputSub.jsx";
import WS0332001_ContractCourseBreakdownInquiry from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0332001_ContractCourseBreakdownInquiry.jsx";
import WS0333001_SetIncludesQuery from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333001_SetIncludesQuery.jsx";
import WS0333011_MoreDetail from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333011_MoreDetail.jsx";
import WS0381001_PersonalOfficeSearchQuery from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0381001_PersonalOfficeSearchQuery.jsx";
import WS0392004_VisitsSupplement from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0392004_VisitsSupplement.jsx";
import WS0392005_VisitsSupplementWk from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0392005_VisitsSupplementWk.jsx";
import WS1846001_CardIssuingSub from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS1846001_CardIssuingSub.jsx";
import WS1846011_ConfirmScreen from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS1846011_ConfirmScreen.jsx";
import WS2543001_ApplicationInfoRegister from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2543001_ApplicationInfoRegister.jsx";
import WS2555001_MedicalExamContentsInquirySub from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2555001_MedicalExamContentsInquirySub.jsx";
import WS2555012_List from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2555012_List.jsx";
import WS2581015_NumericInputSub from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2581015_NumericInputSub.jsx";
import WS2556001_ReservesBulkChanges from "pages/YK_ReservationBusiness/V5YK0005000_ReservesBulkChanges/WS2556001_ReservesBulkChanges.jsx";
import WS2556015_MoneyAmountInput from "pages/YK_ReservationBusiness/V5YK0005000_ReservesBulkChanges/WS2556015_MoneyAmountInput.jsx";
import WS2556064_ReserveChange from "pages/YK_ReservationBusiness/V5YK0005000_ReservesBulkChanges/WS2556064_ReserveChange.jsx";
import WS2783001_ExamineeSearch from "pages/YK_ReservationBusiness/V5YK0106000_ExamineeSearch/WS2783001_ExamineeSearch.jsx";
import WS0649001_CreateDocumentBatch from "pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0649001_CreateDocumentBatch.jsx";
import WS0649007_PersonalQueryProcess from "pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0649007_PersonalQueryProcess.jsx";
import WS0650001_DocumentBatchCreateSub from "pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub.jsx";
import WS1543001_DocumentManageMaintain from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1543001_DocumentManageMaintain.jsx";
import WS1543004_Copy from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1543004_Copy.jsx";
import WS1544001_DetailSub from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1544001_DetailSub.jsx";
import WS1544002_FormatQuery from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1544002_FormatQuery.jsx";
import WS1545001_OutputPatternSub from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1545001_OutputPatternSub.jsx";
import WS1546001_DestinationSub from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS1546001_DestinationSub.jsx";
import WS2592016_ParamPromptedQueryContent from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS2592016_ParamPromptedQueryContent.jsx";
import WS0638001_EscortManageMaintain from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638001_EscortManageMaintain.jsx";
import WS0638003_FormatQuery from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638003_FormatQuery.jsx";
import WS0638007_Copy from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638007_Copy.jsx";
import WS0638013_EscortInquiry from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0638013_EscortInquiry.jsx";
import WS0640001_EscortMaintain from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0640001_EscortMaintain.jsx";
import WS0640002_Retrieval from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0640002_Retrieval.jsx";
import WS0640003_Format from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0640003_Format.jsx";
import WS0640004_CopyingProcess from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0640004_CopyingProcess.jsx";
import WS0641001_EscortDetailSub from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641001_EscortDetailSub.jsx";
import WS0641008_BasicConditionSet from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641008_BasicConditionSet.jsx";
import WS0641021_InspectConditionSetting from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0641021_InspectConditionSetting.jsx";
import WS0642003_DocumentManageO from "pages/JZ_AdvancePreparation/V4MS0061000_DocumentManageMaintain/WS0642003_DocumentManageO.jsx";
import WS0643001_ConsultInfoList from "pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0643001_ConsultInfoList.jsx";
import WS0435012_PreviewConfirm from "pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0435012_PreviewConfirm.jsx";
import WS0578001_CourseMultipleExtractScreenSub from "pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0578001_CourseMultipleExtractScreenSub.jsx";
import WS2788013_TargetSelectSub from "pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS2788013_TargetSelectSub.jsx";
import WS2736001_MiraisElectronicMedicalRecordsSent from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2736001_MiraisElectronicMedicalRecordsSent.jsx";
import WS2738001_LaboratoryInspectConfirmSub from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2738001_LaboratoryInspectConfirmSub.jsx";
import WS2739001_ErrorConfirmSub from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2739001_ErrorConfirmSub.jsx";
import WS2740001_ExtractBatchProcess from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2740001_ExtractBatchProcess.jsx";
import WS2745009_ConfirmScreen from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2745009_ConfirmScreen.jsx";
import WS2749001_CheckAvailability from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2749001_CheckAvailability.jsx";
import WS2750001_MiraisTransmissionRecord from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2750001_MiraisTransmissionRecord.jsx";
import WS2751001_MiraisInspectMaintain from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2751001_MiraisInspectMaintain.jsx";
import WS2751002_InspectMaintain from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2751002_InspectMaintain.jsx";
import WS2752001_MiraisReserveMaintain from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2752001_MiraisReserveMaintain.jsx";
import WS2753001_MiraisImplementorMaintain from "pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2753001_MiraisImplementorMaintain.jsx";
import WS2737001_MiraisSingleTransmission from "pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737001_MiraisSingleTransmission.jsx";
import WS2737600_MiraisTransmissionInquiry from "pages/KS_CooperationRelated/FJYM01200_MiraisTransmissionInquiry/WS2737600_MiraisTransmissionInquiry.jsx";
import WS1046001_InspectAcquisitionUpdateCustomizedVersion from "pages/KS_CooperationRelated/V4CP0011000_InspectAcquisitionUpdateCustomizedVersion/WS1046001_InspectAcquisitionUpdateCustomizedVersion.jsx";
import WS1050001_AcquireSettingSub from "pages/KS_CooperationRelated/V4CP0011000_InspectAcquisitionUpdateCustomizedVersion/WS1050001_AcquireSettingSub.jsx";
import WS1050007_InspectSpecified from "pages/KS_CooperationRelated/V4CP0011000_InspectAcquisitionUpdateCustomizedVersion/WS1050007_InspectSpecified.jsx";
import WS1053004_ConsultSelect from "pages/KS_CooperationRelated/V4CP0011000_InspectAcquisitionUpdateCustomizedVersion/WS1053004_ConsultSelect.jsx";
import WS1063001_InspectRequestMain from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS1063001_InspectRequestMain.jsx";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx";
import WS3108001_OcrCaptureStartUp from "pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS3108001_OcrCaptureStartUp.jsx";
import WS3108008_ProcessSelectQuery from "pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS3108008_ProcessSelectQuery.jsx";
import WS3108015_ConfirmAcquisitionTarget from "pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS3108015_ConfirmAcquisitionTarget.jsx";
import WS3108016_IdConsultDateModify from "pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS3108016_IdConsultDateModify.jsx";
import WS1107001_SettingOcrCapture from "pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS1107001_SettingOcrCapture.jsx";
import WS1107002_OcrCheck from "pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS1107002_OcrCheck.jsx";
import WS2722001_PatientInfoCaptureScreen from "pages/KS_CooperationRelated/V5CP0013000_PatientInfoCaptureScreen/WS2722001_PatientInfoCaptureScreen.jsx";
import WS2722007_PatientInquiry from "pages/KS_CooperationRelated/V5CP0013000_PatientInfoCaptureScreen/WS2722007_PatientInquiry.jsx";
import WS2725001_AcquireSettingSub from "pages/KS_CooperationRelated/V5CP0013000_PatientInfoCaptureScreen/WS2725001_AcquireSettingSub.jsx";
import WS2720001_OnlineInstruction from "pages/KS_CooperationRelated/V5CP0014000_OnlineInstruction/WS2720001_OnlineInstruction.jsx";
import WS2720007_Configuration from "pages/KS_CooperationRelated/V5CP0014000_OnlineInstruction/WS2720007_Configuration.jsx";
import WS2766001_EMedicalRecordsBatchTransmission from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2766001_EMedicalRecordsBatchTransmission.jsx";
import WS2766004_SendingConfirm from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2766004_SendingConfirm.jsx";
import WS2776001_EMedicalRecordsTransmissionHistory from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2776001_EMedicalRecordsTransmissionHistory.jsx";
import WS2776003_InspectContent from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2776003_InspectContent.jsx";
import WS2776004_RetransmissionOrDelete from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2776004_RetransmissionOrDelete.jsx";
import WS2777001_EMedicalRecordsCourseBasicTypeSetting from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2777001_EMedicalRecordsCourseBasicTypeSetting.jsx";
import WS2777008_ContractInfoList from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2777008_ContractInfoList.jsx";
import WS2779001_EMedicalRecordsTransmissionHeaderMaintain from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2779001_EMedicalRecordsTransmissionHeaderMaintain.jsx";
import WS2780062_EMedicalRecordsBatchExtract from "pages/KS_CooperationRelated/YMGA0300_EMedicalRecordsBatchTransmission/WS2780062_EMedicalRecordsBatchExtract.jsx";
import WS2767001_EMedicalRecordsSingleTransmission from "pages/KS_CooperationRelated/YMGA0310_EMedicalRecordsSingleTransmission/WS2767001_EMedicalRecordsSingleTransmission.jsx";
import WS2767009_ConsultSelect from "pages/KS_CooperationRelated/YMGA0310_EMedicalRecordsSingleTransmission/WS2767009_ConsultSelect.jsx";
import WS2767010_SendingConfirm from "pages/KS_CooperationRelated/YMGA0310_EMedicalRecordsSingleTransmission/WS2767010_SendingConfirm.jsx";
import WS2767017_AnotherDayInspectSet from "pages/KS_CooperationRelated/YMGA0310_EMedicalRecordsSingleTransmission/WS2767017_AnotherDayInspectSet.jsx";
import WS2767023_Debug from "pages/KS_CooperationRelated/YMGA0310_EMedicalRecordsSingleTransmission/WS2767023_Debug.jsx";
import WS2768001_EMedicalRecordsInspectAddSub from "pages/KS_CooperationRelated/YMGA0310_EMedicalRecordsSingleTransmission/WS2768001_EMedicalRecordsInspectAddSub.jsx";
import WS2768004_InspectSelect from "pages/KS_CooperationRelated/YMGA0310_EMedicalRecordsSingleTransmission/WS2768004_InspectSelect.jsx";
import WS2778001_EMedicalRecordsInspectRequestMaintain from "pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS2778001_EMedicalRecordsInspectRequestMaintain.jsx";
import WS2778002_ExtractConfirm from "pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS2778002_ExtractConfirm.jsx";
import WS0087001_InspectListSettingSub from "pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS0087001_InspectListSettingSub.jsx";
import WS0104001_CoursesSettingSub from "pages/KS_CooperationRelated/YMGA0610_EMedicalRecordsInspectRequestMaintain/WS0104001_CoursesSettingSub.jsx";
import WS2700017_InspectItemConvertInternal from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS2700017_InspectItemConvertInternal.jsx";
import WS2700099_InputForm from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS2700099_InputForm.jsx";
import WS1035001_QuantitativeQualitativeTransformationSub from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1035001_QuantitativeQualitativeTransformationSub.jsx";
import WS1036001_InspectValueConvertSub from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1036001_InspectValueConvertSub.jsx";
import WS1038001_InspectCmtConvertInternal from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1038001_InspectCmtConvertInternal.jsx";
import WS1039001_InspectNameConvertInMaintainTa from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1039001_InspectNameConvertInMaintainTa.jsx";
import WS1040001_InspectValueConvertInternal from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1040001_InspectValueConvertInternal.jsx";
import WS1040003_Copy from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1040003_Copy.jsx";
import WS1041001_ExternalInspectAmountOfQualitativeTransformation from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS1041001_ExternalInspectAmountOfQualitativeTransformation.jsx";
import WS2715042_InspectTakingPartialConvertSub from "pages/KS_CooperationRelated/V4CP0001000_InspectItemConvertInternal/WS2715042_InspectTakingPartialConvertSub.jsx";
import WS1043001_InspectRequestConvertMasterMaintain from "pages/KS_CooperationRelated/V4CP0002000_InspectRequestConvertMasterMaintain/WS1043001_InspectRequestConvertMasterMaintain.jsx";
import WS1043008_CsvCaptureScreen from "pages/KS_CooperationRelated/V4CP0002000_InspectRequestConvertMasterMaintain/WS1043008_CsvCaptureScreen.jsx";
import WS0699001_AcceptanceBatchProcess from "pages/UK_CounterBusiness/V4UK0003000_AcceptanceBatchProcess/WS0699001_AcceptanceBatchProcess.jsx";
import WS2620001_Counter from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620001_Counter.jsx";
import WS2620036_Refine from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620036_Refine.jsx";
import WS0956001_PaymentCorrectSub from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS0956001_PaymentCorrectSub.jsx";
import WS2587021_InspectChangeQuerySub from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2587021_InspectChangeQuerySub.jsx";
import WS2621001_PaymentProcessSub from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2621001_PaymentProcessSub.jsx";
import WS2621008_SplitPayment from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2621008_SplitPayment.jsx";
import WS2622003_ReceiptProcessSub from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622003_ReceiptProcessSub.jsx";
import WS2623001_DispensingProcessSub from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2623001_DispensingProcessSub.jsx";
import WS2623007_SplitPayment from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2623007_SplitPayment.jsx";
import WS1860001_RadiographyFindingInput from "pages/IN_InputBusiness/HGHP6000_RadiographyFindingInput/WS1860001_RadiographyFindingInput.jsx";
import WS1860005_RadiographyDoctorInquiry from "pages/IN_InputBusiness/HGHP6000_RadiographyFindingInput/WS1860005_RadiographyDoctorInquiry.jsx";
import WS1863001_RadiographyFindingInputSub from "pages/IN_InputBusiness/HGHP6000_RadiographyFindingInput/WS1863001_RadiographyFindingInputSub.jsx";
import WS1865001_LeadershipMatterSearchSub from "pages/IN_InputBusiness/HGHP6000_RadiographyFindingInput/WS1865001_LeadershipMatterSearchSub.jsx";
import WS1868001_JudgeSelectQuerySub from "pages/IN_InputBusiness/HGHP6000_RadiographyFindingInput/WS1868001_JudgeSelectQuerySub.jsx";
import WS1870011_FindingsHistoryList from "pages/IN_InputBusiness/HGHP6000_RadiographyFindingInput/WS1870011_FindingsHistoryList.jsx";
import WS1871001_RadiographyInspectMaintain from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1871001_RadiographyInspectMaintain.jsx";
import WS1871014_Copy from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1871014_Copy.jsx";
import WS1876001_RadiographyJudgeMaintain from "pages/IN_InputBusiness/HGHP6240_RadiographyJudgeMaintain/WS1876001_RadiographyJudgeMaintain.jsx";
import WS1877001_RadiographyInfoMaintain from "pages/IN_InputBusiness/HGHP6250_RadiologistsInfoMaintain/WS1877001_RadiographyInfoMaintain.jsx";
import WS1878001_RadiographyFindingsSubmit from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS1878001_RadiographyFindingsSubmit.jsx";
import WS1878009_RadiographyInspectSelect from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS1878009_RadiographyInspectSelect.jsx";
import WS0304001_SetInfoSearchMultipleChoice from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0304001_SetInfoSearchMultipleChoice.jsx";
import WS0377002_SameSurnameSameNameSameBirthDateSelectScreen from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0377002_SameSurnameSameNameSameBirthDateSelectScreen.jsx";
import WS0540001_PeriodTimeDisplay from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0540001_PeriodTimeDisplay.jsx";
import WS0542001_CalendarDetailDisplay from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0542001_CalendarDetailDisplay.jsx";
import WS0544001_DateChange from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0544001_DateChange.jsx";
import WS0555001_FreeSearchSub1 from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0555001_FreeSearchSub1.jsx";
import WS0560001_GlobalEventRegisterCorrect from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0560001_GlobalEventRegisterCorrect.jsx";
import WS0565001_BookAcceptChangeProcess from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0565001_BookAcceptChangeProcess.jsx";
import WS0565008_VisitsChangeConfirm from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0565008_VisitsChangeConfirm.jsx";
import WS0565009_ReserveChangeConfirm from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0565009_ReserveChangeConfirm.jsx";
import WS0583001_ConsultHistory from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0583001_ConsultHistory.jsx";
import WS0592001_InspectChangeSetting from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0592001_InspectChangeSetting.jsx";
import WS0592021_MoneyAmountInput from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0592021_MoneyAmountInput.jsx";
import WS0627001_FreeSearchInn from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0627001_FreeSearchInn.jsx";
import WS0688001_ContractInspectContentSelectSub from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS0688001_ContractInspectContentSelectSub.jsx";
import WS1492001_SpecificDatePeriodTimePeopleNumSet from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS1492001_SpecificDatePeriodTimePeopleNumSet.jsx";
import WS1492011_EasyBasicDataSet from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS1492011_EasyBasicDataSet.jsx";
import WS1881002_RadiographySubjects from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS1881002_RadiographySubjects.jsx";
import WS1881003_RadiographyContentsOfQuery from "pages/IN_InputBusiness/HGHP6300_RadiographyFindingsSubmit/WS1881003_RadiographyContentsOfQuery.jsx";
import WS0735001_SpreadInput from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0735001_SpreadInput.jsx";
import WS0735028_DataUpdateConfirm from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0735028_DataUpdateConfirm.jsx";
import WS0274001_InspectCmtSearchQuery from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery.jsx";
import WS0456001_SpreadsheetInputSetting from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0456001_SpreadsheetInputSetting.jsx";
import WS0456005_BatchCapture from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0456005_BatchCapture.jsx";
import WS0456007_Copy from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0456007_Copy.jsx";
import WS0456009_Change from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0456009_Change.jsx";
import WS0719001_GuideMatterSet from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0719001_GuideMatterSet.jsx";
import WS0720001_NotesSet from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0720001_NotesSet.jsx";
import WS0728001_FindingsInputNormal from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0728001_FindingsInputNormal.jsx";
import WS0729001_FindingsInputRadiography from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0729001_FindingsInputRadiography.jsx";
import WS0730001_FindingsInputPhysiciiagnosis from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0730001_FindingsInputPhysiciiagnosis.jsx";
import WS0731001_FindingsInputNormalSelect from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0731001_FindingsInputNormalSelect.jsx";
import WS0737001_GuideMatterSettingSpread from "pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0737001_GuideMatterSettingSpread.jsx";
import WS0743001_NotInputCheckCategory from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0743001_NotInputCheckCategory.jsx";
import WS0738001_InputVoteResultInput from "pages/IN_InputBusiness/V4IN0201000_InputVoteResultInput/WS0738001_InputVoteResultInput.jsx";
import WS0743010_TypeQuery from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0743010_TypeQuery.jsx";
import WS0103001_CategoryListSettingSub from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0103001_CategoryListSettingSub.jsx";
import WS0714001_HistoryInputSub from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0714001_HistoryInputSub.jsx";
import WS0739001_InputVoteResultInputInspectInput from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0739001_InputVoteResultInputInspectInput.jsx";
import WS0739012_DataUpdateConfirm from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0739012_DataUpdateConfirm.jsx";
import WS0745001_NotInputCheckMaintain from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0745001_NotInputCheckMaintain.jsx";
import WS0745022_PatternChange from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0745022_PatternChange.jsx";
import WS0802001_PrintInstruction from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0802001_PrintInstruction.jsx";
import WS0804032_HistorySetting from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0804032_HistorySetting.jsx";
import WS0804062_CourseAll from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0804062_CourseAll.jsx";
import WS0804074_QueryV4MjPrintPatametaMem from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0804074_QueryV4MjPrintPatametaMem.jsx";
import WS2320001_QueryNameOfDisease from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2320001_QueryNameOfDisease.jsx";
import WS2637001_OverallResultDisplayInput from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput.jsx";
import WS2637019_DataUpdateConfirm from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637019_DataUpdateConfirm.jsx";
import WS2637031_CharacterStringSearch from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637031_CharacterStringSearch.jsx";
import WS2637036_DesignatedInspectVisitsHistory from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637036_DesignatedInspectVisitsHistory.jsx";
import WS2637041_GuideMatterContent from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637041_GuideMatterContent.jsx";
import WS2637046_TypeSelect from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637046_TypeSelect.jsx";
import WS2637054_NotesInput from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637054_NotesInput.jsx";
import WS2641030_PreviousGuideMatterInquiry from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2641030_PreviousGuideMatterInquiry.jsx";
import WS2644008_SpecifiedValueConfirm from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2644008_SpecifiedValueConfirm.jsx";
import WS3020036_CoupledPrintInstruction from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction.jsx";
import WS3020054_StyleQuery from "pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020054_StyleQuery.jsx";
import WS2635001_ExamineeList from "pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS2635001_ExamineeList.jsx";
import WS2635011_AutoJudge from "pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS2635011_AutoJudge.jsx";
import WS0723001_AutoJudgeScreen from "pages/IN_InputBusiness/V5IN0001000_ExamineeList/WS0723001_AutoJudgeScreen.jsx";
import WS0817001_CollectJudge from "pages/IN_InputBusiness/V4JG0001000_CollectJudge/WS0817001_CollectJudge.jsx";
import WS0817008_Verification from "pages/IN_InputBusiness/V4JG0001000_CollectJudge/WS0817008_Verification.jsx";
import WS0180001_QueryExaminee from "pages/IN_InputBusiness/V4JG0001000_CollectJudge/WS0180001_QueryExaminee.jsx";
import WS1337008_DebugScreen from "pages/IN_InputBusiness/V4JG0001000_CollectJudge/WS1337008_DebugScreen.jsx";
import WS0256001_ProgressSet from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0256001_ProgressSet.jsx";
import WS0061002_ConfirmCheckYes from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061002_ConfirmCheckYes.jsx";
import WS0061003_ConfirmCheckYesYesNo from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061003_ConfirmCheckYesYesNo.jsx";
import WS0061009_CheckYesNoYes from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061009_CheckYesNoYes.jsx";
import WS0061012_CheckYes from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061012_CheckYes.jsx";
import WS0061015_CheckYesNoNo from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo.jsx";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx";
import WS0535001_VisitsKeyInfoSetting from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0535001_VisitsKeyInfoSetting.jsx";
import WS0536001_KeyItemInquiry from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0536001_KeyItemInquiry.jsx";
import WS2576004_CalendarSunSub from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2576004_CalendarSunSub.jsx";
import WS2583001_ConsultInquirySub from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx";
import WS2586020_ConsultHistorySub from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2586020_ConsultHistorySub.jsx";
import WS2593056_BillingInquirySub from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2593056_BillingInquirySub.jsx";
import WS2977001_DispatchManage from "pages/KK_ResultOutput/KURM1100_DispatchManage/WS2977001_DispatchManage.jsx";
import WS2977026_ReservesSelectScreen from "pages/KK_ResultOutput/KURM1100_DispatchManage/WS2977026_ReservesSelectScreen.jsx";
import WS0855001_PrintParamMaintain from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0855001_PrintParamMaintain.jsx";
import WS0855009_PrintParamInput from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0855009_PrintParamInput.jsx";
import WS0855017_ScreenConfirm from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0855017_ScreenConfirm.jsx";
import WS0855021_CharacterStringSearch from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0855021_CharacterStringSearch.jsx";
import WS0855023_RecurrenceNumber from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0855023_RecurrenceNumber.jsx";
import WS0855028_StyleCopy from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0855028_StyleCopy.jsx";
import WS0267001_CategorySearchQuerySingle from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx";
import WS0282001_StyleSpecificInspectInquiry from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0282001_StyleSpecificInspectInquiry.jsx";
import WS0282019_PrintParamQuery from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0282019_PrintParamQuery.jsx";
import WS0282020_StyleQuery from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0282020_StyleQuery.jsx";
import WS0282021_InspectInquiry from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0282021_InspectInquiry.jsx";
import WS0282022_FindingInquiry from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0282022_FindingInquiry.jsx";
import WS0282023_CategoryQuery from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0282023_CategoryQuery.jsx";
import WS0282024_TeachingMatterInquiry from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0282024_TeachingMatterInquiry.jsx";
import WS0397001_ParamPromptedQuerySub from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397001_ParamPromptedQuerySub.jsx";
import WS0397005_InstructionDivisionMaintain from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0397005_InstructionDivisionMaintain.jsx";
import WS0398001_ParamIndicationItemDisplaySub from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0398001_ParamIndicationItemDisplaySub.jsx";
import WS0398003_XItemMaintain from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0398003_XItemMaintain.jsx";
import WS0406003_MasterHistorySelect from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0406003_MasterHistorySelect.jsx";
import WS0406004_PrintParam from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0406004_PrintParam.jsx";
import WS0434016_PrinterDesignScreenResultTbl from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0434016_PrinterDesignScreenResultTbl.jsx";
import WS0434022_PrinterDesignScreenReserveRelated from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0434022_PrinterDesignScreenReserveRelated.jsx";
import WS0446003_BusinessUnit from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0446003_BusinessUnit.jsx";
import WS0446004_CourseUnit from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0446004_CourseUnit.jsx";
import WS0446005_StyleUnit from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0446005_StyleUnit.jsx";
import WS0446006_StardStyleRegister from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0446006_StardStyleRegister.jsx";
import WS0857001_OldItem from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0857001_OldItem.jsx";
import WS0863001_PrintParamInputOutput from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0863001_PrintParamInputOutput.jsx";
import WS0863006_StyleQuery from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0863006_StyleQuery.jsx";
import WS0863008_UptakeFileList from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0863008_UptakeFileList.jsx";
import WS0865001_UseInspectInquiry from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0865001_UseInspectInquiry.jsx";
import WS2603003_FormatSelect from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS2603003_FormatSelect.jsx";
import WS0810001_ResultTblBatchCreate from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0810001_ResultTblBatchCreate.jsx";
import WS0810025_PrintSub from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0810025_PrintSub.jsx";
import WS0810036_FormInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0810036_FormInquiry.jsx";
import WS0457001_CourseSpecificStyleSettingExtend from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0457001_CourseSpecificStyleSettingExtend.jsx";
import WS0457004_StyleSetting from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0457004_StyleSetting.jsx";
import WS0457008_OfficeInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0457008_OfficeInquiry.jsx";
import WS0457009_PrintStyleInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0457009_PrintStyleInquiry.jsx";
import WS0457010_PattanCopy from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0457010_PattanCopy.jsx";
import WS0458001_CourseBasedDetermineByStyleSetting from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0458001_CourseBasedDetermineByStyleSetting.jsx";
import WS0458004_StyleSetting from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0458004_StyleSetting.jsx";
import WS0458008_OfficeInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0458008_OfficeInquiry.jsx";
import WS0458009_PrintStyleInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0458009_PrintStyleInquiry.jsx";
import WS0458010_PattanCopy from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS0458010_PattanCopy.jsx";
import WS1527001_SetupResultTblCollectOutput from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527001_SetupResultTblCollectOutput.jsx";
import WS1527008_ClassifyInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527008_ClassifyInquiry.jsx";
import WS1527012_StyleSetting from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527012_StyleSetting.jsx";
import WS1527022_OfficeInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527022_OfficeInquiry.jsx";
import WS1527016_OfficeInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527016_OfficeInquiry.jsx";
import WS1527018_StyleSettingHira from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527018_StyleSettingHira.jsx";
import WS1527023_PrintStyleInquiry from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527023_PrintStyleInquiry.jsx";
import WS1527024_PattanCopy from "pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527024_PattanCopy.jsx";
import WS0811001_CommunicationRosterOutput from "pages/KK_ResultOutput/V4KK0300000_CommunicationRosterOutput/WS0811001_CommunicationRosterOutput.jsx";
import WS0811004_StyleQuery from "pages/KK_ResultOutput/V4KK0300000_CommunicationRosterOutput/WS0811004_StyleQuery.jsx";
import WS3022038_BindingModeSetting from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS3022038_BindingModeSetting.jsx";
import WS0494004_ConditionExpressSet from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0494004_ConditionExpressSet.jsx";
import WS0494006_HeaderInput from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0494006_HeaderInput.jsx";
import WS0494007_DataInput from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0494007_DataInput.jsx";
import WS0494001_ConditionInfoMaintain from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0494001_ConditionInfoMaintain.jsx";
import WS0495001_ConditionExpressInfoInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0495001_ConditionExpressInfoInquiry.jsx";
import WS0445001_CourseSpecificStardStyleSetting from "pages/KK_ResultOutput/V4MS9900600_CourseSpecificStardStyleSetting/WS0445001_CourseSpecificStardStyleSetting.jsx";
import WS0445006_OfficeInquiry from "pages/KK_ResultOutput/V4MS9900600_CourseSpecificStardStyleSetting/WS0445006_OfficeInquiry.jsx";
import WS0445007_PrintStyleInquiry from "pages/KK_ResultOutput/V4MS9900600_CourseSpecificStardStyleSetting/WS0445007_PrintStyleInquiry.jsx";
import WS0460001_CsvCreateParamMaintain from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0460001_CsvCreateParamMaintain.jsx";
import WS0460005_ParamInput from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0460005_ParamInput.jsx";
import WS0460006_Inquiry from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0460006_Inquiry.jsx";
import WS0460008_Copy from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0460008_Copy.jsx";
import WS0451001_UserParamQuery from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0451001_UserParamQuery.jsx";
import WS0454003_ConvertTblSubInspect from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0454003_ConvertTblSubInspect.jsx";
import WS0454004_ConvertTblSubAll from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0454004_ConvertTblSubAll.jsx";
import WS0454005_Copy from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0454005_Copy.jsx";
import WS0461004_CharacterStringSearch from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0461004_CharacterStringSearch.jsx";
import WS0461005_UserParamInput0 from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0461005_UserParamInput0.jsx";
import WS0461006_UserParamInput1 from "pages/KK_ResultOutput/V4MS9901100_CsvCreateParamMaintain/WS0461006_UserParamInput1.jsx";
import WS1001001_RomotoArticle52 from "pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS1001001_RomotoArticle52.jsx";
import WS1001033_BreakdownInquiry from "pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS1001033_BreakdownInquiry.jsx";
import WS1001034_SelectBreakdown from "pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS1001034_SelectBreakdown.jsx";
import WS1004001_SettingRomotoArticle52 from "pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS1004001_SettingRomotoArticle52.jsx";
import WS1004007_JudgeSelect from "pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS1004007_JudgeSelect.jsx";
import WS2787004_AffiliationSelectSub from "pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS2787004_AffiliationSelectSub.jsx";
import WS0784001_MedicalExamDataOutputCsv from "pages/KK_ResultOutput/V4CP0041000_MedicalExamDataOutputCsv/WS0784001_MedicalExamDataOutputCsv.jsx";
import WS0784003_FormInquiry from "pages/KK_ResultOutput/V4CP0041000_MedicalExamDataOutputCsv/WS0784003_FormInquiry.jsx";
import WS0784004_FileNameInputUav02 from "pages/KK_ResultOutput/V4CP0041000_MedicalExamDataOutputCsv/WS0784004_FileNameInputUav02.jsx";
import WS0784005_InsuranceSymbolInquiry from "pages/KK_ResultOutput/V4CP0041000_MedicalExamDataOutputCsv/WS0784005_InsuranceSymbolInquiry.jsx";
import WS0784006_FacilityTypeQuery from "pages/KK_ResultOutput/V4CP0041000_MedicalExamDataOutputCsv/WS0784006_FacilityTypeQuery.jsx";
import WS1915001_ReceiptPreIssue from "pages/BL_AccountingBusiness/SMIYA0502_ReceiptPreIssue20/WS1915001_ReceiptPreIssue.jsx";
import WS0946006_ReceiptIssueOnline from "pages/BL_AccountingBusiness/SMIYA0502_ReceiptPreIssue20/WS0946006_ReceiptIssueOnline.jsx";
import WS2354001_BillingManageLedgerInstruction from "pages/BL_AccountingBusiness/V3BILL200_BillingManageLedgerInstruction/WS2354001_BillingManageLedgerInstruction.jsx";
import WS0947001_Invoice from "pages/BL_AccountingBusiness/V4BL0008000_BillingIntegration/WS0947001_Invoice.jsx";
import WS0961001_BillingIntegration from "pages/BL_AccountingBusiness/V4BL0008000_BillingIntegration/WS0961001_BillingIntegration.jsx";
import WS0961004_TamperProofRelease from "pages/BL_AccountingBusiness/V4BL0008000_BillingIntegration/WS0961004_TamperProofRelease.jsx";
import WS0961005_BillingDateChange from "pages/BL_AccountingBusiness/V4BL0008000_BillingIntegration/WS0961005_BillingDateChange.jsx";
import WS0975001_BillingInquiry from "pages/BL_AccountingBusiness/V4BL0030000_BillingInquiry/WS0975001_BillingInquiry.jsx";
import WS0975007_DisplayItem from "pages/BL_AccountingBusiness/V4BL0030000_BillingInquiry/WS0975007_DisplayItem.jsx";
import WS0977001_DepositWithdrawalInquiry from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0977001_DepositWithdrawalInquiry.jsx";
import WS0963001_InvoiceMaintain from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0963001_InvoiceMaintain.jsx";
import WS0963004_TamperProofRelease from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0963004_TamperProofRelease.jsx";
import WS0964001_Itemized from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0964001_Itemized.jsx";
import WS0964020_SelectBreakdown from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0964020_SelectBreakdown.jsx";
import WS0966001_BillingDataCopy from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0966001_BillingDataCopy.jsx";
import WS0967003_BillingNameQuerySub from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0967003_BillingNameQuerySub.jsx";
import WS0968001_AccordingToVariousNameDefinitionTbl from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0968001_AccordingToVariousNameDefinitionTbl.jsx";
import WS0971006_ItemDisplay from "pages/BL_AccountingBusiness/V4BL0031000_DepositWithdrawalInquiry/WS0971006_ItemDisplay.jsx";
import WS0978001_DepositWithdrawalListOutputInstruction from "pages/BL_AccountingBusiness/V4BL0050001_DepositWithdrawalListOutputInstruction/WS0978001_DepositWithdrawalListOutputInstruction.jsx";
import WS0981001_PaymentListOutput from "pages/BL_AccountingBusiness/V4BL0050100_PaymentListOutput/WS0981001_PaymentListOutput.jsx";
import WS0985001_AccountsReceivableListOutputInstruction from "pages/BL_AccountingBusiness/V4BL0052001_AccountsReceivableListOutputInstruction/WS0985001_AccountsReceivableListOutputInstruction.jsx";
import WS0948001_BillingAggregationProcess from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS0948001_BillingAggregationProcess.jsx";
import WS0952001_CounterPayment from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS0952001_CounterPayment.jsx";
import WS0952007_PaymentHistoryDisplaySingle from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS0952007_PaymentHistoryDisplaySingle.jsx";
import WS0952010_PaymentHistoryDisplayAll from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS0952010_PaymentHistoryDisplayAll.jsx";
import WS2626001_Invoice from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS2626001_Invoice.jsx";
import WS2626028_IntegrationReleased from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS2626028_IntegrationReleased.jsx";
import WS2628002_MultipleSelectOffice from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS2628002_MultipleSelectOffice.jsx";
import WS2628003_InsurerMultipleSelect from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS2628003_InsurerMultipleSelect.jsx";
import WS2630001_OrganizationsPayment from "pages/BL_AccountingBusiness/V5BL0002000_OrganizationsPayment/WS2630001_OrganizationsPayment.jsx";
import WS0958001_ReceiptMaintainIssue from "pages/BL_AccountingBusiness/V5BL0002000_OrganizationsPayment/WS0958001_ReceiptMaintainIssue.jsx";
import WS2631001_PaymentProcessSub from "pages/BL_AccountingBusiness/V5BL0002000_OrganizationsPayment/WS2631001_PaymentProcessSub.jsx";
import WS2632001_DispensingProcessSub from "pages/BL_AccountingBusiness/V5BL0002000_OrganizationsPayment/WS2632001_DispensingProcessSub.jsx";
import WS0892001_IntroduceLetterExtract from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0892001_IntroduceLetterExtract.jsx";
import WS0888026_IntroduceLetterSubjectCmtInquiry from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0888026_IntroduceLetterSubjectCmtInquiry.jsx";
import WS0898001_IntroduceLetterExtractMaintain from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain.jsx";
import WS0898003_IntroduceLetterInquiry from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898003_IntroduceLetterInquiry.jsx";
import WS0898028_InspectInput from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898028_InspectInput.jsx";
import WS0900001_DisplayConsultHistory from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0900001_DisplayConsultHistory.jsx";
import WS0903002_InspectInquiry from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0903002_InspectInquiry.jsx";
import WS0936002_IntroduceLetterReturnInput from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0936002_IntroduceLetterReturnInput.jsx";
import WS0936006_ReturnInfoInputSub from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0936006_ReturnInfoInputSub.jsx";
import WS2589011_FormatSelect from "pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS2589011_FormatSelect.jsx";
import WS0904001_IntroduceLetterIssuedMain from "pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0904001_IntroduceLetterIssuedMain.jsx";
import WS0433001_PrinterConfirm from "pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx";
import WS2804001_IntroduceLetterReturnInfoInput from "pages/SK_IntroductionLetter/V4SK0006000_IntroduceLetterReturnInfoInput/WS2804001_IntroduceLetterReturnInfoInput.jsx";
import WS2804013_ReturnInfoInput from "pages/SK_IntroductionLetter/V4SK0006000_IntroduceLetterReturnInfoInput/WS2804013_ReturnInfoInput.jsx";
import WS2804019_AskInput from "pages/SK_IntroductionLetter/V4SK0006000_IntroduceLetterReturnInfoInput/WS2804019_AskInput.jsx";
import WS0915001_AskIssued from "pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0915001_AskIssued.jsx";
import WS0887001_IntroduceLetterVariousMasterInquiry from "pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx";
import WS0917005_AskIssueInput from "pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0917005_AskIssueInput.jsx";
import WS0919001_IntroduceLetterMasterMaintain from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0919001_IntroduceLetterMasterMaintain.jsx";
import WS0270001_InspectItemSearchQueryMultipleChoice from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0270001_InspectItemSearchQueryMultipleChoice.jsx";
import WS0920003_IntroduceLetterTargetCmtMaintainAmks from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0920003_IntroduceLetterTargetCmtMaintainAmks.jsx";
import WS0920008_IntroduceLetterTargetCmtMaintain from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0920008_IntroduceLetterTargetCmtMaintain.jsx";
import WS0920010_CopyingProcess from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0920010_CopyingProcess.jsx";
import WS0921003_IntroduceLetterTargetCmtInspectMaintain from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0921003_IntroduceLetterTargetCmtInspectMaintain.jsx";
import WS0922003_MedicalInstitutionsMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922003_MedicalInstitutionsMaster.jsx";
import WS0922004_DoctorNameMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922004_DoctorNameMaster.jsx";
import WS0922005_InspectNameMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922005_InspectNameMaster.jsx";
import WS0922006_VictimNameMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922006_VictimNameMaster.jsx";
import WS0922007_FindingsContentMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922007_FindingsContentMaster.jsx";
import WS0922008_TreatmentContentMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922008_TreatmentContentMaster.jsx";
import WS0922009_DepartmentMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922009_DepartmentMaster.jsx";
import WS0922010_DayOfWeekBasedDeterminePhysicianMaster from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0922010_DayOfWeekBasedDeterminePhysicianMaster.jsx";
import WS0924001_IntroduceLetterTargetCmtList from "pages/SK_IntroductionLetter/V4SK0010000_IntroduceLetterMasterMaintain/WS0924001_IntroduceLetterTargetCmtList.jsx";
import WS0930001_PersonRequireExamIntroduceLetter from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0930001_PersonRequireExamIntroduceLetter.jsx";
import WS0930006_DisplayContentChange from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0930006_DisplayContentChange.jsx";
import WS0285001_JudgeQuery from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx";
import WS0927005_DepartmentInquiry from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0927005_DepartmentInquiry.jsx";
import WS0931001_CsvOutputSub from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0931001_CsvOutputSub.jsx";
import WS0931002_FormInquiry from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0931002_FormInquiry.jsx";
import WS0932001_RequiredPrecisionExaminerSetting from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0932001_RequiredPrecisionExaminerSetting.jsx";
import WS0932008_InspectInquiry from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0932008_InspectInquiry.jsx";
import WS0933003_RequiredPrecisionExaminerVariousMasterSetting from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0933003_RequiredPrecisionExaminerVariousMasterSetting.jsx";
import WS0933004_RequiredPrecisionExaminerVariousMasterInquiry from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0933004_RequiredPrecisionExaminerVariousMasterInquiry.jsx";
import WS0935001_RequiredPrecisionExaminerFollowUpMatterRegister from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0935001_RequiredPrecisionExaminerFollowUpMatterRegister.jsx";
import WS0935013_InspectInquiry from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0935013_InspectInquiry.jsx";
import WS1872001_RadiographySiteFindingsMaintain from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872001_RadiographySiteFindingsMaintain.jsx";
import WS1872005_SiteFindingsMigration from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872005_SiteFindingsMigration.jsx";
import WS1873001_RadiographySiteMaintain from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1873001_RadiographySiteMaintain.jsx";
import WS1873002_SiteClassifyInquiry from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1873002_SiteClassifyInquiry.jsx";
import WS1873003_SiteInquiry from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1873003_SiteInquiry.jsx";
import WS1874001_RadiographyFindingsMaintain from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1874001_RadiographyFindingsMaintain.jsx";
import WS1874002_SiteClassifyInquiry from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1874002_SiteClassifyInquiry.jsx";
import WS1874003_FindingsClassifyInquiry from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1874003_FindingsClassifyInquiry.jsx";
import WS1874004_FindingInquiry from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1874004_FindingInquiry.jsx";
import WS1875001_RadiographyGuideMaintain from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1875001_RadiographyGuideMaintain.jsx";
import WS2303001_DiseaseNameMasterMaintain from "pages/MS_InspectionMaintenance/MSS040_DiseaseNameMasterMaintain/WS2303001_DiseaseNameMasterMaintain.jsx";
import WS0286001_PrintStyleInquiry from "pages/KK_ResultOutput/OITA0310_BindingModeSetting/WS0286001_PrintStyleInquiry.jsx";
import WS2705001_AnotherInspectItemSettingCategory from "pages/MS_InspectionMaintenance/V4KB0011000_AnotherInspectItemSettingCategory/WS2705001_AnotherInspectItemSettingCategory.jsx";
import WS2705075_PatternSettingSub from "pages/MS_InspectionMaintenance/V4KB0011000_AnotherInspectItemSettingCategory/WS2705075_PatternSettingSub.jsx";
import WS2705076_CopyPattern from "pages/MS_InspectionMaintenance/V4KB0011000_AnotherInspectItemSettingCategory/WS2705076_CopyPattern.jsx";
import WS2696036_CourseBasicTypeSetting from "pages/MS_InspectionMaintenance/V4MS0101000_CourseBasicTypeSetting/WS2696036_CourseBasicTypeSetting.jsx";
import WS2696008_ContractInfoList from "pages/MS_InspectionMaintenance/V4MS0101000_CourseBasicTypeSetting/WS2696008_ContractInfoList.jsx";
import WS2696052_RegisterModification from "pages/MS_InspectionMaintenance/V4MS0101000_CourseBasicTypeSetting/WS2696052_RegisterModification.jsx";
import WS2704001_CategoryMasterMaintain from "pages/MS_InspectionMaintenance/V4MS0102000_CategoryMasterMaintain/WS2704001_CategoryMasterMaintain.jsx";
import WS0268001_SiteClassifySearchQuery from "pages/MS_InspectionMaintenance/V4MS0102000_CategoryMasterMaintain/WS0268001_SiteClassifySearchQuery.jsx";
import WS2716001_InspectItemInfoMaintain from "pages/MS_InspectionMaintenance/V4MS0103000_InspectItemInfoMaintain/WS2716001_InspectItemInfoMaintain.jsx";
import WS2716057_InspectByPatternCategoryDisplay from "pages/MS_InspectionMaintenance/V4MS0103000_InspectItemInfoMaintain/WS2716057_InspectByPatternCategoryDisplay.jsx";
import WS0253001_InternalInspectItemSearchQuery from "pages/MS_InspectionMaintenance/V4MS0103000_InspectItemInfoMaintain/WS0253001_InternalInspectItemSearchQuery.jsx";
import WS2717011_InspectItem1ChangeSub from "pages/MS_InspectionMaintenance/V4MS0103000_InspectItemInfoMaintain/WS2717011_InspectItem1ChangeSub.jsx";
import WS0356001_SiteFindingsMasterMaintain from "pages/MS_InspectionMaintenance/V4MS0104000_SiteFindingsMasterMaintain/WS0356001_SiteFindingsMasterMaintain.jsx";
import WS0356005_CategoryYouAreUsing from "pages/MS_InspectionMaintenance/V4MS0104000_SiteFindingsMasterMaintain/WS0356005_CategoryYouAreUsing.jsx";
import WS0351001_SiteFindingsCopySub from "pages/MS_InspectionMaintenance/V4MS0104000_SiteFindingsMasterMaintain/WS0351001_SiteFindingsCopySub.jsx";
import WS0358001_InspectCmtInfoMaintain from "pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS0358001_InspectCmtInfoMaintain.jsx";
import WS0358003_UseInspect from "pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS0358003_UseInspect.jsx";
import WS0359001_PhysicianNameInfoMaintain from "pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS0359001_PhysicianNameInfoMaintain.jsx";
import WS2712001_InspectCmtInfoMaintainSub from "pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS2712001_InspectCmtInfoMaintainSub.jsx";
import WS2712016_UseInspect from "pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS2712016_UseInspect.jsx";
import WS0362001_InspectItemJudgeValueSetting from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0362001_InspectItemJudgeValueSetting.jsx";
import WS0272001_CautionGuideNotesSearchQuery from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx";
import WS0363001_InspectDecisionAloneSettingSeparately from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0363001_InspectDecisionAloneSettingSeparately.jsx";
import WS0363005_EffectiveDateSelect from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0363005_EffectiveDateSelect.jsx";
import WS0364001_InspectDecisionAloneSettingMerged from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0364001_InspectDecisionAloneSettingMerged.jsx";
import WS0364005_EffectiveDateSelect from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0364005_EffectiveDateSelect.jsx";
import WS0364007_ConditionExpressSet from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0364007_ConditionExpressSet.jsx";
import WS0364008_HeaderInput from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0364008_HeaderInput.jsx";
import WS0364009_DataInput from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0364009_DataInput.jsx";
import WS0365001_DetailsExtract from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0365001_DetailsExtract.jsx";
import WS0367002_Copy from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0367002_Copy.jsx";
import WS0367003_Delete from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0367003_Delete.jsx";
import WS0367004_UseInspect from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0367004_UseInspect.jsx";
import WS0367005_CopyCategory from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0367005_CopyCategory.jsx";
import WS0367006_DeleteCategory from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0367006_DeleteCategory.jsx";
import WS0367007_UseInspectCategory from "pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0367007_UseInspectCategory.jsx";
import WS0371001_InspectRefValueSetting from "pages/MS_InspectionMaintenance/V4MS0106210_InspectRefValueSetting/WS0371001_InspectRefValueSetting.jsx";
import WS0482001_ConditionExpressCmtSetting from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0482001_ConditionExpressCmtSetting.jsx";
import WS0482007_CmtSelectGroup from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0482007_CmtSelectGroup.jsx";
import WS0482008_EffectiveDateSpecified from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0482008_EffectiveDateSpecified.jsx";
import WS0482010_EffectiveDateSelect from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0482010_EffectiveDateSelect.jsx";
import WS0482011_CmtContentModification from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0482011_CmtContentModification.jsx";
import WS0483001_ConditionExpressCmtSub from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0483001_ConditionExpressCmtSub.jsx";
import WS0483004_SpecialCondition from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0483004_SpecialCondition.jsx";
import WS0484001_ConditionExpressConfirmSub from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0484001_ConditionExpressConfirmSub.jsx";
import WS0484004_SpecialCondition from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0484004_SpecialCondition.jsx";
import WS0485001_CmtGroupConfirmSub from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0485001_CmtGroupConfirmSub.jsx";
import WS0486001_ConditionCorrectSub from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0486001_ConditionCorrectSub.jsx";
import WS0486002_Redisplay from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0486002_Redisplay.jsx";
import WS0487001_ConditionExpressAddSub from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487001_ConditionExpressAddSub.jsx";
import WS0487007_JudgeHighlow from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487007_JudgeHighlow.jsx";
import WS0487008_HeaderInput from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487008_HeaderInput.jsx";
import WS0493001_ConditionCopyingScreen from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0493001_ConditionCopyingScreen.jsx";
import WS2701001_CautionGuideMatterCmtMaintain from "pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS2701001_CautionGuideMatterCmtMaintain.jsx";
import WS2701025_CmtRegisterCopy from "pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS2701025_CmtRegisterCopy.jsx";
import WS0266001_CategorySearchQueryMultiple from "pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS0266001_CategorySearchQueryMultiple.jsx";
import WS0376001_AnotherGuideCmtMaintainCategory from "pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS0376001_AnotherGuideCmtMaintainCategory.jsx";
import WS0447001_DetermineLevelModifyMasterCoercive from "pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0447001_DetermineLevelModifyMasterCoercive.jsx";
import WS0447011_ColorSample from "pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0447011_ColorSample.jsx";
import WS0448001_DetermineLevelBulkModify from "pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0448001_DetermineLevelBulkModify.jsx";
import WS0448009_CopyScreen from "pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0448009_CopyScreen.jsx";
import WS2713014_NormalValueSetting from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713014_NormalValueSetting.jsx";
import WS2713035_NormalValueAutoSet from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713035_NormalValueAutoSet.jsx";
import WS2713042_PatternList from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713042_PatternList.jsx";
import WS2713067_CharacterStringSearch from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713067_CharacterStringSearch.jsx";
import WS2713087_FormatCopy from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS2713087_FormatCopy.jsx";
import WS0178001_QuerySiteInfo from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0178001_QuerySiteInfo.jsx";
import WS0179001_InquiryFindingInfo from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0179001_InquiryFindingInfo.jsx";
import WS0350001_SiteFindingsMaintainSub from "pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0350001_SiteFindingsMaintainSub.jsx";
import WS2699142_InspectValueCalculateAutoMaintain from "pages/MS_InspectionMaintenance/V4SM0051000_InspectValueCalculateAutoMaintain/WS2699142_InspectValueCalculateAutoMaintain.jsx";
import WS2699155_CalculateFormulaInput from "pages/MS_InspectionMaintenance/V4SM0051000_InspectValueCalculateAutoMaintain/WS2699155_CalculateFormulaInput.jsx";
import WS2699183_Copy from "pages/MS_InspectionMaintenance/V4SM0051000_InspectValueCalculateAutoMaintain/WS2699183_Copy.jsx";
import WS0998001_ComprehensiveJudgeClassifyTbl from "pages/TO_StatisticalServices/V4TO0002000_ComprehensiveJudgeClassifyTbl/WS0998001_ComprehensiveJudgeClassifyTbl.jsx";
import WS0999001_CategoryDeterminingClassifyTbl from "pages/TO_StatisticalServices/V4TO0003000_CategoryDeterminingClassifyTbl/WS0999001_CategoryDeterminingClassifyTbl.jsx";
import WS0176001_QueryCategoryInfo from "pages/TO_StatisticalServices/V4TO0003000_CategoryDeterminingClassifyTbl/WS0176001_QueryCategoryInfo.jsx";
import WS1000001_ItemJudgeClassifyTbl from "pages/TO_StatisticalServices/V4TO0004000_ItemJudgeClassifyTbl/WS1000001_ItemJudgeClassifyTbl.jsx";
import WS1015001_OfficeVisitsAchievementSituation from "pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS1015001_OfficeVisitsAchievementSituation.jsx";
import WS1015015_BusinessEstablishmentsSubjectToSelect from "pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS1015015_BusinessEstablishmentsSubjectToSelect.jsx";
import WS0431001_CsvPreviewConfirm from "pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS0431001_CsvPreviewConfirm.jsx";
import WS2694001_MasterListOutput from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS2694001_MasterListOutput.jsx";
import WS0410001_MasterListOutputSiteClassify from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0410001_MasterListOutputSiteClassify.jsx";
import WS0411001_MasterListOutputCategoryInfo from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0411001_MasterListOutputCategoryInfo.jsx";
import WS0412001_MasterListOutputSiteInfo from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0412001_MasterListOutputSiteInfo.jsx";
import WS0413001_MasterListOutputFindingInfo from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0413001_MasterListOutputFindingInfo.jsx";
import WS0414001_MasterListOutputInspectItem from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0414001_MasterListOutputInspectItem.jsx";
import WS0415001_MasterListOutputLeadershipMatter from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0415001_MasterListOutputLeadershipMatter.jsx";
import WS0416001_MasterListOutputCourseBasicType from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0416001_MasterListOutputCourseBasicType.jsx";
import WS0417001_MasterListOutputInspectValueJudgeInfo from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0417001_MasterListOutputInspectValueJudgeInfo.jsx";
import WS0417003_DetermineLevelSelect from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0417003_DetermineLevelSelect.jsx";
import WS0417025_CopyingProcess from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0417025_CopyingProcess.jsx";
import WS0417028_CopyingProcessEffectiveDate from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0417028_CopyingProcessEffectiveDate.jsx";
import WS0419001_MasterListOutputOfficeInfo from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0419001_MasterListOutputOfficeInfo.jsx";
import WS0420001_MasterListOutputInsuranceInfo from "pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0420001_MasterListOutputInsuranceInfo.jsx";
import WS0479001_PersonalInfoCsvOutput from "pages/TO_StatisticalServices/V2MS0140_PersonalInfoCsvOutput/WS0479001_PersonalInfoCsvOutput.jsx";
import WS0432001_CsvConfirm from "pages/TO_StatisticalServices/V2MS0140_PersonalInfoCsvOutput/WS0432001_CsvConfirm.jsx";
import WS0480003_OfficeInfoCsvOutput from "pages/TO_StatisticalServices/V2MS0150_OfficeInfoCsvOutput/WS0480003_OfficeInfoCsvOutput.jsx";
import WS1119001_InspectVariationInfoOutput from "pages/TO_StatisticalServices/V4CP0042100_InspectVariationInfoOutput/WS1119001_InspectVariationInfoOutput.jsx";
import WS1567001_FormCreateEditing from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS1567001_FormCreateEditing.jsx";
import WS1567009_OutputDestinationAcquisition from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS1567009_OutputDestinationAcquisition.jsx";
import WS0400001_OptionalInputSub from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS0400001_OptionalInputSub.jsx";
import WS1568001_FileNameAcquisition from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS1568001_FileNameAcquisition.jsx";
import WS1569001_SubVariableSettingScreen from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS1569001_SubVariableSettingScreen.jsx";
import WS1570001_SubOptionSelect from "pages/UT_UserTools/V4UT4010000_FormCreateEditing/WS1570001_SubOptionSelect.jsx";
import WS0801001_UserFormOutput from "pages/UT_UserTools/V4UT4050000_UserFormOutput/WS0801001_UserFormOutput.jsx";
import WS0801006_FormOutput from "pages/UT_UserTools/V4UT4050000_UserFormOutput/WS0801006_FormOutput.jsx";
import WS1583001_SubFileList from "pages/UT_UserTools/V4UT4050000_UserFormOutput/WS1583001_SubFileList.jsx";
import WS1585001_UserDocumentItemMaintain from "pages/UT_UserTools/V4UT4090000_UserDocumentItemMaintain/WS1585001_UserDocumentItemMaintain.jsx";
import WS1585014_DuplicateModify from "pages/UT_UserTools/V4UT4090000_UserDocumentItemMaintain/WS1585014_DuplicateModify.jsx";
import WS1585018_HeaderCaptureOutput from "pages/UT_UserTools/V4UT4090000_UserDocumentItemMaintain/WS1585018_HeaderCaptureOutput.jsx";
import WS1290001_InsurerNumberInquiry from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx";
import WS1302001_AgencyInquirySub from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302001_AgencyInquirySub.jsx";
import WS1302005_AddCorrectScreen from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302005_AddCorrectScreen.jsx";
import WS1376001_ConsultTicketInputProcessList from "pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1376001_ConsultTicketInputProcessList.jsx";
import WS2847001_InitInterviewTargetDetermineProcess from "pages/TK_SpecificMedicalExamination/V4TK3001000_InitInterviewTargetDetermineProcess/WS2847001_InitInterviewTargetDetermineProcess.jsx";
import WS2847005_ListProcess from "pages/TK_SpecificMedicalExamination/V4TK3001000_InitInterviewTargetDetermineProcess/WS2847005_ListProcess.jsx";
import WS1296010_ConsultTicketInputSub from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296010_ConsultTicketInputSub.jsx";
import WS1303006_ScreenInput from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1303006_ScreenInput.jsx";
import WS1349045_ContractSelect from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1349045_ContractSelect.jsx";
import WS1350013_SettleInputSub from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1350013_SettleInputSub.jsx";
import WS1378001_SpecificMedicalExamSettleProcess from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1378001_SpecificMedicalExamSettleProcess.jsx";
import WS1379001_SingleGroupClassifyInputSub from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1379001_SingleGroupClassifyInputSub.jsx";
import WS1394001_AgreementInquirySub from "pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1394001_AgreementInquirySub.jsx";
import WS1291001_XmlDocumentQuerySelectSub from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1291001_XmlDocumentQuerySelectSub.jsx";
import WS1351001_SpecificHealthDataXmlOutput from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1351001_SpecificHealthDataXmlOutput.jsx";
import WS1351003_MemberList from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1351003_MemberList.jsx";
import WS1351016_ResultConfirmNormal from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1351016_ResultConfirmNormal.jsx";
import WS1351017_ResultConfirmError from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1351017_ResultConfirmError.jsx";
import WS1362003_PrintProcess from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1362003_PrintProcess.jsx";
import WS1366001_SpecificHealthDataXmlOutput from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1366001_SpecificHealthDataXmlOutput.jsx";
import WS1366003_MemberList from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1366003_MemberList.jsx";
import WS1366004_SpecificHealthTargetDataExtractCondition from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1366004_SpecificHealthTargetDataExtractCondition.jsx";
import WS1366022_ResultConfirm from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1366022_ResultConfirm.jsx";
import WS1366023_ResultConfirmError from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1366023_ResultConfirmError.jsx";
import WS1367002_MemberList from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS1367002_MemberList.jsx";
import WS2655001_SpecificHealthDataXmlOutput from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2655001_SpecificHealthDataXmlOutput.jsx";
import WS2655078_OutputConfirm from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2655078_OutputConfirm.jsx";
import WS2655083_DetailsExtract from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2655083_DetailsExtract.jsx";
import WS2655106_ResultConfirm from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2655106_ResultConfirm.jsx";
import WS2669001_XmlErrorTargetList from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2669001_XmlErrorTargetList.jsx";
import WS2669006_DetailedErrorContentPersonal from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2669006_DetailedErrorContentPersonal.jsx";
import WS2670009_SpecificMedicalExamErrorCheckUserSetting from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2670009_SpecificMedicalExamErrorCheckUserSetting.jsx";
import WS2670033_InspectAdvanced from "pages/TK_SpecificMedicalExamination/V4TK5100000_SpecificHealthDataXmlOutput/WS2670033_InspectAdvanced.jsx";
import WS2650001_InsureGuideInitInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2650001_InsureGuideInitInput.jsx";
import WS2650016_ReserveChange from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2650016_ReserveChange.jsx";
import WS1384001_InsureGuideCourseInquiry from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1384001_InsureGuideCourseInquiry.jsx";
import WS1384002_FinancialSupportInquiry from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1384002_FinancialSupportInquiry.jsx";
import WS1385001_SentenceMasterInquiry from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1385001_SentenceMasterInquiry.jsx";
import WS1387001_CmtClassifyInquiry from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1387001_CmtClassifyInquiry.jsx";
import WS1422006_FirstDate from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1422006_FirstDate.jsx";
import WS1423001_SupportPlanManualCreate from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1423001_SupportPlanManualCreate.jsx";
import WS1470001_GuideInputResult from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1470001_GuideInputResult.jsx";
import WS1470005_ContentInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1470005_ContentInput.jsx";
import WS1470008_HealthCheckupInfoSelect from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS1470008_HealthCheckupInfoSelect.jsx";
import WS2653001_GuideInputProvenSub from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2653001_GuideInputProvenSub.jsx";
import WS2653004_EMailCompleted from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2653004_EMailCompleted.jsx";
import WS2653079_HealthCheckupInfoSelect from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2653079_HealthCheckupInfoSelect.jsx";
import WS2654013_ContentInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2654013_ContentInput.jsx";
import WS2654016_TargetivesResultInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2654016_TargetivesResultInput.jsx";
import WS2654025_AddInspect from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2654025_AddInspect.jsx";
import WS2657078_VouchersInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2657078_VouchersInput.jsx";
import WS2652001_InsureGuideInput from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS2652001_InsureGuideInput.jsx";
import WS1436001_ImplementRecordRef from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1436001_ImplementRecordRef.jsx";
import WS1444001_DunningInquiry from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444001_DunningInquiry.jsx";
import WS1444016_DunningInput from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444016_DunningInput.jsx";
import WS1444026_ContentInput from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444026_ContentInput.jsx";
import WS1444032_MethodSelect from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1444032_MethodSelect.jsx";
import WS1445001_DunningPrint from "pages/TH_SpecificInsureGuide/V5TH0010800_InsureGuideInput/WS1445001_DunningPrint.jsx";
import WS1462001_VouchersInputProcessList from "pages/TH_SpecificInsureGuide/V4TH0011502_VouchersInputProcessList/WS1462001_VouchersInputProcessList.jsx";
import WS1462006_ListProcess from "pages/TH_SpecificInsureGuide/V4TH0011502_VouchersInputProcessList/WS1462006_ListProcess.jsx";
import WS1464001_SpecificInsureGuideSettleProcessList from "pages/TH_SpecificInsureGuide/V4TH0011102_SpecificInsureGuideSettleProcessList/WS1464001_SpecificInsureGuideSettleProcessList.jsx";
import WS1464003_ListProcess from "pages/TH_SpecificInsureGuide/V4TH0011102_SpecificInsureGuideSettleProcessList/WS1464003_ListProcess.jsx";
import WS1465001_ContractSelectScreen from "pages/TH_SpecificInsureGuide/V4TH0011102_SpecificInsureGuideSettleProcessList/WS1465001_ContractSelectScreen.jsx";
import WS2656001_SpecificInsureXmlOutput from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS2656001_SpecificInsureXmlOutput.jsx";
import WS2656088_DetailsExtract from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS2656088_DetailsExtract.jsx";
import WS2656096_OutputConfirm from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS2656096_OutputConfirm.jsx";
import WS2656103_ResultConfirm from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS2656103_ResultConfirm.jsx";
import WS1442001_ContractDisplay from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS1442001_ContractDisplay.jsx";
import WS1443001_ContractSelectScreen from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS1443001_ContractSelectScreen.jsx";
import WS1443004_InitCollectionSystemInput from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS1443004_InitCollectionSystemInput.jsx";
import WS1456003_PrintProcess from "pages/TH_SpecificInsureGuide/V4TH0020000_SpecificCoerciveFingerXmlOutput/WS1456003_PrintProcess.jsx";
import WS1304001_CompiledClassify from "pages/TM_SpecificInsureMaintenance/V4TK0010000_CompiledClassify/WS1304001_CompiledClassify.jsx";
import WS1305001_ContractCompiledMaintain from "pages/TM_SpecificInsureMaintenance/V4TK0020000_ContractCompiledMaintain/WS1305001_ContractCompiledMaintain.jsx";
import WS1305004_CompiledSelect from "pages/TM_SpecificInsureMaintenance/V4TK0020000_ContractCompiledMaintain/WS1305004_CompiledSelect.jsx";
import WS1306001_SpecificHealthTokuhoOptionsFile from "pages/TM_SpecificInsureMaintenance/V4TK0030000_SpecificHealthTokuhoOptionsFile/WS1306001_SpecificHealthTokuhoOptionsFile.jsx";
import WS1306003_CopyingProcess from "pages/TM_SpecificInsureMaintenance/V4TK0030000_SpecificHealthTokuhoOptionsFile/WS1306003_CopyingProcess.jsx";
import WS1308001_SpecificHealthTokuhoParamMaintain from "pages/TM_SpecificInsureMaintenance/V4TK0040000_SpecificHealthTokuhoParamMaintain/WS1308001_SpecificHealthTokuhoParamMaintain.jsx";
import WS1308004_InputSupportPlanEtc from "pages/TM_SpecificInsureMaintenance/V4TK0040000_SpecificHealthTokuhoParamMaintain/WS1308004_InputSupportPlanEtc.jsx";
import WS1308005_InputMetabolicSyndromeHierarchical from "pages/TM_SpecificInsureMaintenance/V4TK0040000_SpecificHealthTokuhoParamMaintain/WS1308005_InputMetabolicSyndromeHierarchical.jsx";
import WS1308006_CopyingProcess from "pages/TM_SpecificInsureMaintenance/V4TK0040000_SpecificHealthTokuhoParamMaintain/WS1308006_CopyingProcess.jsx";
import WS1309001_XmlParamMaintain from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1309001_XmlParamMaintain.jsx";
import WS1309003_InputList from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1309003_InputList.jsx";
import WS1309004_Input from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1309004_Input.jsx";
import WS1309011_CopyingProcess from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1309011_CopyingProcess.jsx";
import WS1310001_ItemConvertCodeTblMaintain from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1310001_ItemConvertCodeTblMaintain.jsx";
import WS1310002_ItemCheckupsForXml from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1310002_ItemCheckupsForXml.jsx";
import WS1311001_XmlInspectValueConvertMaster from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1311001_XmlInspectValueConvertMaster.jsx";
import WS1311003_Copy from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1311003_Copy.jsx";
import WS1312001_XmlInspectValueConvertEM from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1312001_XmlInspectValueConvertEM.jsx";
import WS1315001_ItemConvertCdMaintainE from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1315001_ItemConvertCdMaintainE.jsx";
import WS1315003_ItemCheckupsForXml from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS1315003_ItemCheckupsForXml.jsx";
import WS2485001_InsurerByCourseCheckupsType from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS2485001_InsurerByCourseCheckupsType.jsx";
import WS2485002_MedicalExamTypeQuery from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS2485002_MedicalExamTypeQuery.jsx";
import WS2485003_MedicalExamTypeMaintain from "pages/TM_SpecificInsureMaintenance/V4TK0050000_XmlParamMaintain/WS2485003_MedicalExamTypeMaintain.jsx";
import WS1313001_XmlMedicalExamItemMaster from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313001_XmlMedicalExamItemMaster.jsx";
import WS1313008_DateDisplay from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313008_DateDisplay.jsx";
import WS1313005_DataRangeCheck from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313005_DataRangeCheck.jsx";
import WS1313006_XmlMedicalExamItemRef from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313006_XmlMedicalExamItemRef.jsx";
import WS1313007_CsvOutputScreen from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313007_CsvOutputScreen.jsx";
import WS1313010_CsvCaptureScreen from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313010_CsvCaptureScreen.jsx";
import WS1314001_DataRangeCheck from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314001_DataRangeCheck.jsx";
import WS1314008_DateDisplay from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314008_DateDisplay.jsx";
import WS1314005_XmlMedicalExamItemRef from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314005_XmlMedicalExamItemRef.jsx";
import WS1314006_XmlMedicalExamItemDataInput from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314006_XmlMedicalExamItemDataInput.jsx";
import WS1314007_CsvOutputScreen from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314007_CsvOutputScreen.jsx";
import WS1314010_CsvCaptureScreen from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314010_CsvCaptureScreen.jsx";
import WS1316001_ContractMasterMaintain from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1316001_ContractMasterMaintain.jsx";
import WS1288001_ContractYearSelectSub from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1288001_ContractYearSelectSub.jsx";
import WS1289001_ContractCompiledQuery from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1289001_ContractCompiledQuery.jsx";
import WS1317001_ContractMasterDetails from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1317001_ContractMasterDetails.jsx";
import WS1317008_AddInspectDetailedInput from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1317008_AddInspectDetailedInput.jsx";
import WS1317039_InspectItemSearchQuerySingle from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1317039_InspectItemSearchQuerySingle.jsx";
import WS1318001_ContractCopyingProcessSub from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1318001_ContractCopyingProcessSub.jsx";
import WS1318005_SerialNumberRef from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1318005_SerialNumberRef.jsx";
import WS1319001_InsurerInfo from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1319001_InsurerInfo.jsx";
import WS1319003_Copy from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1319003_Copy.jsx";
import WS1396001_SupportItem from "pages/TM_SpecificInsureMaintenance/V4TH0000100_SupportItem/WS1396001_SupportItem.jsx";
import WS1396009_SupportItemSub from "pages/TM_SpecificInsureMaintenance/V4TH0000100_SupportItem/WS1396009_SupportItemSub.jsx";
import WS1386001_InspectItemInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000100_SupportItem/WS1386001_InspectItemInquiry.jsx";
import WS1398001_InsureGuideCourse from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1398001_InsureGuideCourse.jsx";
import WS1398010_InsureGuideCourseDetail from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1398010_InsureGuideCourseDetail.jsx";
import WS1382001_ImplementorInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1382001_ImplementorInquiry.jsx";
import WS1383001_SupportItemInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1383001_SupportItemInquiry.jsx";
import WS1383002_InspectInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1383002_InspectInquiry.jsx";
import WS1388001_ImplementLocationQuery from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1388001_ImplementLocationQuery.jsx";
import WS1400001_InspectItemMaster from "pages/TM_SpecificInsureMaintenance/V4TH0000500_InspectItemMaster/WS1400001_InspectItemMaster.jsx";
import WS1389001_DocumentClassifyInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000500_InspectItemMaster/WS1389001_DocumentClassifyInquiry.jsx";
import WS1401001_InspectCmtMaster from "pages/TM_SpecificInsureMaintenance/V4TH0000600_InspectCmtMaster/WS1401001_InspectCmtMaster.jsx";
import WS1402001_DocumentClassifyMaster from "pages/TM_SpecificInsureMaintenance/V4TH0000700_DocumentClassifyMaster/WS1402001_DocumentClassifyMaster.jsx";
import WS1403009_SentenceMaster from "pages/TM_SpecificInsureMaintenance/V4TH0000800_SentenceMaster/WS1403009_SentenceMaster.jsx";
import WS1404001_ImplementDivisionMaster from "pages/TM_SpecificInsureMaintenance/V4TH0000900_ImplementationDivisionMaster/WS1404001_ImplementDivisionMaster.jsx";
import WS1405001_ImplementLocationMaster from "pages/TM_SpecificInsureMaintenance/V4TH0001000_ImplementationLocationMaster/WS1405001_ImplementLocationMaster.jsx";
import WS1390001_ImplementClassifyInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0001000_ImplementationLocationMaster/WS1390001_ImplementClassifyInquiry.jsx";
import WS1406001_ImplementorMaster from "pages/TM_SpecificInsureMaintenance/V4TH0001100_ImplementorMaster/WS1406001_ImplementorMaster.jsx";
import WS1391001_ImplementAgencyInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0001100_ImplementorMaster/WS1391001_ImplementAgencyInquiry.jsx";
import WS1407001_ImplementAgenciesMaster from "pages/TM_SpecificInsureMaintenance/V4TH0001200_ImplementAgenciesMaster/WS1407001_ImplementAgenciesMaster.jsx";
import WS2489001_AssociateInsureMoneyAmountSetting from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489001_AssociateInsureMoneyAmountSetting.jsx";
import WS2489003_FormatInquiry from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489003_FormatInquiry.jsx";
import WS2489005_InputNew from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489005_InputNew.jsx";
import WS2489006_ImplementMoneyAmountGeneral from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489006_ImplementMoneyAmountGeneral.jsx";
import WS2489007_MoneyAmountAddedGeneral from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489007_MoneyAmountAddedGeneral.jsx";
import WS2489008_MoneyAmountHepatitisGeneral from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489008_MoneyAmountHepatitisGeneral.jsx";
import WS2489009_NonMoneyAmountGeneral from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489009_NonMoneyAmountGeneral.jsx";
import WS2489010_NonMoneyAmountAdded from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489010_NonMoneyAmountAdded.jsx";
import WS2489011_CopyingProcess from "pages/KY_AssociationHealthInsuranceReport/H26KAI0200_AssociateInsureMoneyAmountSetting/WS2489011_CopyingProcess.jsx";
import WS1168001_AssociateInsureParamMaintain from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1168001_AssociateInsureParamMaintain.jsx";
import WS1168005_CopyingProcess from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1168005_CopyingProcess.jsx";
import WS0181001_QueryInspectSet from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS0181001_QueryInspectSet.jsx";
import WS0294001_UseInspectCheck from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS0294001_UseInspectCheck.jsx";
import WS1169001_DetailInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1169001_DetailInfoCorrect.jsx";
import WS1169004_YearSelect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1169004_YearSelect.jsx";
import WS1170001_InspectInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1170001_InspectInfoCorrect.jsx";
import WS1171001_AggregateInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1171001_AggregateInfoCorrect.jsx";
import WS1172001_ItemAmountCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1172001_ItemAmountCorrect.jsx";
import WS1173001_TotalAmountCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1173001_TotalAmountCorrect.jsx";
import WS1174003_CourseSelect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1174003_CourseSelect.jsx";
import WS1174006_CourseCopyingFunction from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1174006_CourseCopyingFunction.jsx";
import WS1176001_InspectSetInfo from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176001_InspectSetInfo.jsx";
import WS1176003_CopyRegisterScreenInput from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176003_CopyRegisterScreenInput.jsx";
import WS1177001_GovernmentTubePrimaryAggregate from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1177001_GovernmentTubePrimaryAggregate.jsx";
import WS1178001_GovernmentTubeHepatitisAggregate from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1178001_GovernmentTubeHepatitisAggregate.jsx";
import WS1180001_GovernmentTubeParam from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1180001_GovernmentTubeParam.jsx";
import WS1181001_GovernmentTubeConvertMaintain from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1181001_GovernmentTubeConvertMaintain.jsx";
import WS1181003_Copy from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1181003_Copy.jsx";
import WS1181005_CmtConvert from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1181005_CmtConvert.jsx";
import WS1220001_AssociateInsureDataCreate from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1220001_AssociateInsureDataCreate.jsx";
import WS1220004_CodeSetting from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1220004_CodeSetting.jsx";
import WS1225003_OutputDestinationSelect from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1225003_OutputDestinationSelect.jsx";
import WS1226001_FoundationFdInquiry from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1226001_FoundationFdInquiry.jsx";
import WS1226007_CorrectSelect from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1226007_CorrectSelect.jsx";
import WS1226009_MediaManage from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1226009_MediaManage.jsx";
import WS1226012_MedicalExamResultCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1226012_MedicalExamResultCorrect.jsx";
import WS1227001_AssociateInsureErrorListCreatedSub from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS1227001_AssociateInsureErrorListCreatedSub.jsx";
import WS2789013_TargetSelectAssociationSub from "pages/KY_AssociationHealthInsuranceReport/V4KY0016000_AssociateInsureDataCreate/WS2789013_TargetSelectAssociationSub.jsx";
import WS1229001_AssociationAcceptanceInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0017000_AssociationAcceptanceInfoCorrect/WS1229001_AssociationAcceptanceInfoCorrect.jsx";
import WS1229013_Inquiry from "pages/KY_AssociationHealthInsuranceReport/V4KY0017000_AssociationAcceptanceInfoCorrect/WS1229013_Inquiry.jsx";
import WS2674001_AssociateInsureQualifyConfirm from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2674001_AssociateInsureQualifyConfirm.jsx";
import WS2674011_InsuranceInfo from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2674011_InsuranceInfo.jsx";
import WS2674013_MultipleSelectOffice from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2674013_MultipleSelectOffice.jsx";
import WS2676001_RequestCsvOutput from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2676001_RequestCsvOutput.jsx";
import WS2677001_AssociationConsultQualifyResultCapture from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2677001_AssociationConsultQualifyResultCapture.jsx";
import WS2677012_CaptureResult from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2677012_CaptureResult.jsx";
import WS2681001_ResultConfirmCsvOutput from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2681001_ResultConfirmCsvOutput.jsx";
import WS2682001_TransmissionContentsCheckCorrectPersonal from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2682001_TransmissionContentsCheckCorrectPersonal.jsx";
import WS2683001_TransmissionContentsCheckFixFile from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2683001_TransmissionContentsCheckFixFile.jsx";
import WS2685001_TransmissionCaptureConfirm from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2685001_TransmissionCaptureConfirm.jsx";
import WS2686001_QualifyResultManuallyUpdate from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2686001_QualifyResultManuallyUpdate.jsx";
import WS2442001_AssociateInsureRequiredItem from "pages/KY_AssociationHealthInsuranceReport/V2KKRCV1_AssociateInsureRequiredItem/WS2442001_AssociateInsureRequiredItem.jsx";
import WS1536001_FacilityConsumptionTaxSetting from "pages/SM_SystemMaintenance/V4SM0000100_FacilityConsumptionTaxSetting/WS1536001_FacilityConsumptionTaxSetting.jsx";
import WS1536500_ConsumptionTaxSetting from "pages/SM_SystemMaintenance/V4SM0000100_FacilityConsumptionTaxSetting/WS1536500_ConsumptionTaxSetting.jsx";
import WS1536012_ImageSetting from "pages/SM_SystemMaintenance/V4SM0000100_FacilityConsumptionTaxSetting/WS1536012_ImageSetting.jsx";
import WS1537001_MedicalInstitutionNumberUpdateSub from "pages/SM_SystemMaintenance/V4SM0000100_FacilityConsumptionTaxSetting/WS1537001_MedicalInstitutionNumberUpdateSub.jsx";
import WS1538001_MedicalInstitutionNumberQuerySub from "pages/SM_SystemMaintenance/V4SM0000100_FacilityConsumptionTaxSetting/WS1538001_MedicalInstitutionNumberQuerySub.jsx";
import WS2702007_ReservesDisplayItemSetting from "pages/SM_SystemMaintenance/V4SM0001000_ReservesDisplayItemSetting/WS2702007_ReservesDisplayItemSetting.jsx";
import WS2702151_DisplayNameEdit from "pages/SM_SystemMaintenance/V4SM0001000_ReservesDisplayItemSetting/WS2702151_DisplayNameEdit.jsx";
import WS1485001_RefPeopleNumSettingInfo from "pages/SM_SystemMaintenance/V4SM0002000_RefPeopleNumSettingInfo/WS1485001_RefPeopleNumSettingInfo.jsx";
import WS1485009_NewHistory from "pages/SM_SystemMaintenance/V4SM0002000_RefPeopleNumSettingInfo/WS1485009_NewHistory.jsx";
import WS1485016_HistorySelect from "pages/SM_SystemMaintenance/V4SM0002000_RefPeopleNumSettingInfo/WS1485016_HistorySelect.jsx";
import WS2575001_CalendarMonthSub from "pages/SM_SystemMaintenance/V4SM0002000_RefPeopleNumSettingInfo/WS2575001_CalendarMonthSub.jsx";
import WS2697011_BasicPeriodTime from "pages/SM_SystemMaintenance/V4SM0003000_BasicPeriodTime/WS2697011_BasicPeriodTime.jsx";
import WS1490001_SpecificDatePeopleNumSetting from "pages/SM_SystemMaintenance/V4SM0004000_SpecificDatePeopleNumSetting/WS1490001_SpecificDatePeopleNumSetting.jsx";
import WS1494001_NonConsultDateSetting from "pages/SM_SystemMaintenance/V4SM0004200_NonConsultDateSetting/WS1494001_NonConsultDateSetting.jsx";
import WS1494003_CollectSetting from "pages/SM_SystemMaintenance/V4SM0004200_NonConsultDateSetting/WS1494003_CollectSetting.jsx";
import WS1494006_SingleSetting from "pages/SM_SystemMaintenance/V4SM0004200_NonConsultDateSetting/WS1494006_SingleSetting.jsx";
import WS1499001_ProgressInfoMaintain from "pages/SM_SystemMaintenance/V4SM0006001_ProgressInfoMaintain/WS1499001_ProgressInfoMaintain.jsx";
import WS0276001_ProgramSearch from "pages/SM_SystemMaintenance/V4SM0006001_ProgressInfoMaintain/WS0276001_ProgramSearch.jsx";
import WS1500001_FacilityHospitalOutsideInfoMaintain from "pages/SM_SystemMaintenance/V4SM0007000_FacilityCreateHospitalOutsideCreateInfoMaintain/WS1500001_FacilityHospitalOutsideInfoMaintain.jsx";
import WS1505001_AgeManageInfoMaintain from "pages/SM_SystemMaintenance/V4SM0010000_AgeManageInfoMaintain/WS1505001_AgeManageInfoMaintain.jsx";
import WS1505010_CopyRegister from "pages/SM_SystemMaintenance/V4SM0010000_AgeManageInfoMaintain/WS1505010_CopyRegister.jsx";
import WS1505013_CsvOutputScreen from "pages/SM_SystemMaintenance/V4SM0010000_AgeManageInfoMaintain/WS1505013_CsvOutputScreen.jsx";
import WS2698022_PrinterInfoMaintain from "pages/SM_SystemMaintenance/V4SM0021000_PrinterInfoMaintain/WS2698022_PrinterInfoMaintain.jsx";
import WS2698003_Copy from "pages/SM_SystemMaintenance/V4SM0021000_PrinterInfoMaintain/WS2698003_Copy.jsx";
import WS2698165_PrinterInfoInput from "pages/SM_SystemMaintenance/V4SM0021000_PrinterInfoMaintain/WS2698165_PrinterInfoInput.jsx";
import WS2698172_PrinterSelect from "pages/SM_SystemMaintenance/V4SM0021000_PrinterInfoMaintain/WS2698172_PrinterSelect.jsx";
import WS1512001_OptionalInfoMaintain from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512001_OptionalInfoMaintain.jsx";
import WS1512066_ItemModification from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512066_ItemModification.jsx";
import WS1512074_AddItem from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512074_AddItem.jsx";
import WS1512135_AddCode from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512135_AddCode.jsx";
import WS1512138_DirectEditing from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1512138_DirectEditing.jsx";
import WS0102001_InspectListSettingSub from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS0102001_InspectListSettingSub.jsx";
import WS1513001_OptionalOutputCapture from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1513001_OptionalOutputCapture.jsx";
import WS1515001_OptionsItemInfoMaintain from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1515001_OptionsItemInfoMaintain.jsx";
import WS1515015_CopyLevel1 from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1515015_CopyLevel1.jsx";
import WS1515023_CopyLevel2 from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1515023_CopyLevel2.jsx";
import WS1516001_OptionalItemCsvOutput from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1516001_OptionalItemCsvOutput.jsx";
import WS1517001_OptionsItemOutputCapture from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1517001_OptionsItemOutputCapture.jsx";
import WS1518001_ConfirmOptionItemInfo from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1518001_ConfirmOptionItemInfo.jsx";
import WS1518007_SelectSubject from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1518007_SelectSubject.jsx";
import WS1519002_OptionTypeSetting from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1519002_OptionTypeSetting.jsx";
import WS1520001_OptionsItemInfoConfirmingSub from "pages/SM_SystemMaintenance/V4SM0031000_OptionalInfoMaintain/WS1520001_OptionsItemInfoConfirmingSub.jsx";
import WS1523001_UserOptionInfoMaintain from "pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1523001_UserOptionInfoMaintain.jsx";
import WS1523004_SearchScreen from "pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1523004_SearchScreen.jsx";
import WS1523005_OptionTypeMaintain from "pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1523005_OptionTypeMaintain.jsx";
import WS1523007_UserOptionsInquiry from "pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1523007_UserOptionsInquiry.jsx";
import WS1525001_UserOptionsItemInfoMaintain from "pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1525001_UserOptionsItemInfoMaintain.jsx";
import WS1525016_CopyLevel1 from "pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1525016_CopyLevel1.jsx";
import WS1525026_CopyLevel2 from "pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1525026_CopyLevel2.jsx";
import WS0449001_UserParamMaintain from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449001_UserParamMaintain.jsx";
import WS0449005_CopyingProcess from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449005_CopyingProcess.jsx";
import WS0449007_ConvertTblReplicationProcess from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449007_ConvertTblReplicationProcess.jsx";
import WS0449009_InputOutput from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449009_InputOutput.jsx";
import WS0450004_UserParamInput from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0450004_UserParamInput.jsx";
import WS0450006_CharacterStringSearch from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0450006_CharacterStringSearch.jsx";
import WS0452003_ConvertTblSubInspect from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0452003_ConvertTblSubInspect.jsx";
import WS0452005_ConvertTblSubAll from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0452005_ConvertTblSubAll.jsx";
import WS0452006_Copy from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0452006_Copy.jsx";
import WS0452008_CmtConvert from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0452008_CmtConvert.jsx";
import WS0453001_InspectCaptureInspectConvertTblInputOutput from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0453001_InspectCaptureInspectConvertTblInputOutput.jsx";
import WS0453003_UserUnitFdParamtyleInquiry from "pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0453003_UserUnitFdParamtyleInquiry.jsx";
import WS0545001_PersonalActionItemInquiry from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS0545001_PersonalActionItemInquiry.jsx";
import WS0882001_CommunicationRosterOutput from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS0882001_CommunicationRosterOutput.jsx";
import WS0882004_StyleQuery from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS0882004_StyleQuery.jsx";
import WS0884004_IssueListInputControl from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS0884004_IssueListInputControl.jsx";
import WS2249001_CreateTestForMedicalExamInfo from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2249001_CreateTestForMedicalExamInfo.jsx";
import WS2249005_AutoCreate from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2249005_AutoCreate.jsx";
import WS2249013_ContinuousPrint from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2249013_ContinuousPrint.jsx";
import WS2624002_VisitsChangeConfirm from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624002_VisitsChangeConfirm.jsx";
import WS2624003_VisitsCancelConfirm from "pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624003_VisitsCancelConfirm.jsx";
import WS2290001_RisingDocumentCreate from "pages/ZZ_Others/DEVTOOL1000_RisingDocumentCreate/WS2290001_RisingDocumentCreate.jsx";
import WS2290003_JudgeUnitList from "pages/ZZ_Others/DEVTOOL1000_RisingDocumentCreate/WS2290003_JudgeUnitList.jsx";
import WS0262001_LogDisplay from "pages/ZZ_Others/V4DS0230000_LogDisplay/WS0262001_LogDisplay.jsx";
import WS0262064_CharacterSearch from "pages/ZZ_Others/V4DS0230000_LogDisplay/WS0262064_CharacterSearch.jsx";
import WS0262068_UserChoice from "pages/ZZ_Others/V4DS0230000_LogDisplay/WS0262068_UserChoice.jsx";
import WS2591001_SelectFolder from "pages/ZZ_Others/V4DS0230000_LogDisplay/WS2591001_SelectFolder.jsx";
import WS0315005_HistoryCopy from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0315005_HistoryCopy.jsx";
import WS1317034_InspectItemSearchQuerySingle from "pages/TM_SpecificInsureMaintenance/V4TK0080000_ContractMasterMaintain/WS1317034_InspectItemSearchQuerySingle.jsx";
import WS3059001_PassingManageProgress from "pages/ZZ_Others/V5IS01000_PassingManageProgress/WS3059001_PassingManageProgress.jsx";
import WS3059005_TerminalList from "pages/ZZ_Others/V5IS01000_PassingManageProgress/WS3059005_TerminalList.jsx";
import WS3059008_PassingList from "pages/ZZ_Others/V5IS01000_PassingManageProgress/WS3059008_PassingList.jsx";
import WS3061001_PassingControlInspectsDisplay from "pages/ZZ_Others/V5IS01010_PassingControlInspectsDisplay/WS3061001_PassingControlInspectsDisplay.jsx";
import WS3061002_PassingControlInspectsDisplay2 from "pages/ZZ_Others/V5IS01010_PassingControlInspectsDisplay/WS3061002_PassingControlInspectsDisplay2.jsx";
import WS3062001_PassingManageSettings from "pages/ZZ_Others/V5IS01100_PassingManageSettings/WS3062001_PassingManageSettings.jsx";
import WS3063001_PassingManageBatchExtract from "pages/ZZ_Others/V5IS01200_PassingManageBatchExtract/WS3063001_PassingManageBatchExtract.jsx";
import WS0927002_DepartmentInquiry from "pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0927002_DepartmentInquiry";
import WS2690001_ComprehensiveMenuMaintenance from "pages/MN_Basis/V5MN0901000_ComprehensiveMenuMaintenance/WS2690001_ComprehensiveMenuMaintenance.jsx";
import WS2692001_MenuCustomization from "pages/MN_Basis/V5MN0001000_MenuCustomization/WS2692001_MenuCustomization.jsx";
import WS2690501_ComprehensiveMenuMaintenanceSub from "pages/MN_Basis/V5MN0901000_ComprehensiveMenuMaintenance/WS2690501_ComprehensiveMenuMaintenanceSub.jsx";
import WS2519500_Dashboard from "pages/MN_Basis/V5MN0001000_Main/WS2519500_Dashboard.jsx";
import WS2520001_SendEmail from "pages/MN_Basis/V5MN0001000_Main/WS2520001_SendEmail.jsx";
import WS2522001_TodoCreate from "pages/MN_Basis/V5MN0001000_Main/WS2522001_TodoCreate.jsx";
import WS3123001_StartupAndMaintenance from "pages/MN_Basis/V5MN0011000_StartupAndMaintenance/WS3123001_StartupAndMaintenance.jsx";
// import WS3124001_KartecoCadaCooperation from "pages/ZZ_Others/SNIK0100_KartecoCadaCooperation/WS3124001_KartecoCadaCooperation.jsx";
import WS3127001_KartecoCooperationCsvOutput from "pages/ZZ_Others/MDV01000_KartecoCooperationCsvOutput/WS3127001_KartecoCooperationCsvOutput.jsx";
// import WS3135001_KartecoCooperationTargetMaster from "pages/ZZ_Others/MDV01300_KartecoCooperationTargetMaster/WS3135001_KartecoCooperationTargetMaster.jsx";
import ReserveStatusSearchCustom from "pages/ZZ_Others/SANAI_ReserveStatusSearch/ReserveStatusSearchCustom.jsx"
import ReserveStatusSearchCustom2 from "pages/ZZ_Others/SANAI_ReserveStatusSearch/ReserveStatusSearchCustom2.jsx"
import AuthenticationCheck from "pages/ZZ_Others/SANAI_AuthenticationCheck/AuthenticationCheck.jsx"
import CorporateHearingSheet from "pages/ZZ_Others/SANAI_CorporateHearingSheet/CorporateHearingSheet.jsx"
import MedicalCheckupCalendar from "pages/ZZ_Others/SANAI_MedicalCheckupCalendar/MedicalCheckupCalendar.jsx"
import CaptureRadiologicalFindings from "pages/ZZ_Others/TOJUN_CaptureRadiologicalFindings/CaptureRadiologicalFindings.jsx"
import WS1131001_EastFukyoConverseProcess from "pages/KK_ResultOutput/V4CP0044100_EastFukyoConverseProcess/WS1131001_EastFukyoConverseProcess"
import WS1552001_AddressLabel_Personal from "pages/UT_UserTools/V4UT0001000_LabelPrint/WS1552001_AddressLabel_Personal.jsx"
// import WS1557001_AddressLabel_Office from "pages/UT_UserTools/V4UT0001000_LabelPrint/WS1557001_AddressLabel_Office.jsx"
import WS1558001_AddressLabel_OfficeReserved from "pages/UT_UserTools/V4UT0001000_LabelPrint/WS1558001_AddressLabel_OfficeReserved.jsx"
import WS1556001_AddressLabel_Insurer from "pages/UT_UserTools/V4UT0001000_LabelPrint/WS1556001_AddressLabel_Insurer.jsx"
import WS0668001_SpecimenLabelPrint from "pages/JZ_AdvancePreparation/V4JZ0104000_SpecimenLabel/WS0668001_SpecimenLabelPrint.jsx"
import testActions from "pages/testActions/testActions"
import WS3008001_ResultAcquisitionProcess from "pages/KS_CooperationRelated/THYA0100_ResultAcquisitionProcess/WS3008001_ResultAcquisitionProcess.jsx"
import WS2692011_MyMenu from "pages/MN_Basis/V5MN0001000_MenuCustomization/WS2692011_MyMenu.jsx";
import WS3200001_ElectronicMedicalRecordsLinkageFrameSet from "pages/KS_CooperationRelated/V4CP0004000_ElectronicMedicalRecordsLinkageFrameSet/WS3200001_ElectronicMedicalRecordsLinkageFrameSet.jsx"
import FrameReserve from "pages/ZZ_Others/SANAI_FrameReserve/FrameReserve"
import ProgressManagement from "pages/ZZ_Others/SANAI_ProgressManagement/ProgressManagement"
import DetermineTentativeReserve from "pages/YK_ReservationBusiness/SANAI_TentativeReserve/DetermineTentativeReserve.jsx"
import DockTentativeReserve from "pages/YK_ReservationBusiness/SANAI_DockTentativeReserve/DockTentativeReserve.jsx"
import LogQuery from "pages/ZZ_Others/LogQuery/LogQuery.jsx"
import InterviewSupport from "pages/IN_InputBusiness/SANAI_InterviewSupport/InterviewSupport.jsx"
import WS0898001_IntroduceLetterExtractMaintainMock from "pages/Mock/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintainMock.jsx"
import WS2620001_CounterMock from "pages/Mock/UK_CounterBusiness/V5UK0001000_Counter/WS2620001_CounterMock.jsx"
import BankHolidaySetting from "pages/SM_SystemMaintenance/BankHolidaySetting/BankHolidaySetting"
import TentativeReserveCaptureConfirmedMock from "pages/Mock/YK_ReservationBusiness/SANAI_TentativeReserve/TentativeReserveCaptureConfirmedMock.jsx"
import MedicalCheckupCalendarMock from "pages/Mock/ZZ_Others/SANAI_MedicalCheckupCalendar/MedicalCheckupCalendarMock"
import WS0306001_ContractInfoMaintainMock from "pages/Mock/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0306001_ContractInfoMaintainMock.jsx"
import WS3059001_PassingManageProgressMock from "pages/Mock/ZZ_Others/V5IS01000_PassingManageProgress/WS3059001_PassingManageProgressMock.jsx"
import WS0743001_NotInputCheckCategoryMock from "pages/Mock/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS0743001_NotInputCheckCategoryMock.jsx"
import FollowUp from "pages/SK_IntroductionLetter/FollowUp/FollowUp.jsx"
import PostmanageAndTrackingFollowup from "pages/SK_IntroductionLetter/FollowUp/PostmanageAndTrackingFollowup.jsx"
import TentativeReserveCaptureConfirmed from "pages/YK_ReservationBusiness/SANAI_TentativeReserve/TentativeReserveCaptureConfirmed.jsx"
import WS1778001_MutualAidSubjectBillingItemMaintain from "pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1778001_MutualAidSubjectBillingItemMaintain.jsx"
import WS1780001_MutualAidTargetKenCourseMaintain from "pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1780001_MutualAidTargetKenCourseMaintain.jsx"
import WS1781001_ComprehensiveMedicalExamBilling from "pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1781001_ComprehensiveMedicalExamBilling.jsx"
import WS1784001_TableComprehensiveMedicalExamReport from "pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1784001_TableComprehensiveMedicalExamReport.jsx"
import WS1787001_FdCreateMunicipalMutualAidAssociation from "pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1787001_FdCreateMunicipalMutualAidAssociation.jsx"
import WS1789001_MunicipalMutualAidAssociationMaintain from "pages/BL_AccountingBusiness/SANAI_MutualAidAssociationBilling/WS1789001_MunicipalMutualAidAssociationMaintain.jsx"
import VirusCheckLog from "pages/ZZ_Others/VirusCheckLog/VirusCheckLog"
import WS0923001_CourseDepartmentspecificFormat from 'pages/SK_IntroductionLetter/V4SK0010010_CourseDepartmentspecificFormat/WS0923001_CourseDepartmentspecificFormat.jsx'
import FollowUpLetterCourseDepartmentspecificFormat from 'pages/SK_IntroductionLetter/FollowUpLetter/FollowUpLetterCourseDepartmentspecificFormat.jsx'
import OptionSetting from "pages/ZZ_Others/OptionSetting/OptionSetting"
import InspectGroupMaintain from "pages/MS_InspectionMaintenance/InspectGroupMaintain/InspectGroupMaintain.jsx"
import CancerScreeningCSVOutput from "pages/ZZ_Others/SANAI_CancerScreeningCSVOutput/CancerScreeningCSVOutput.jsx"
import WS3401001_AutoJudgmentManagement from "pages/SM_SystemMaintenance/AutoJudgmentManagement/WS3401001_AutoJudgmentManagement.jsx"
import WS2620001_Counter_SANAI from "pages/UK_CounterBusiness/SANAI_V5UK0001000_Counter/WS2620001_Counter.jsx";
import ConnectHostsSetting from "pages/MN_Basis/V5MN0902000_ConnectHostsSetting/ConnectHostsSetting.jsx";
import InsurerNumberConversionMaster from "pages/TM_SpecificInsureMaintenance/InsurerNumberConversionMaster/InsurerNumberConversionMaster"
import WS0439001_ProcessMenu from "pages/SM_SystemMaintenance/V4MS9900200_ProcessMenu/WS0439001_ProcessMenu"
import WS3064001_PassingManageCSV from "pages/ZZ_Others/V5IS1300_PassingManageCSV/WS3064001_PassingManageCSV"
import ResultDisplayView from "pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplayView.jsx"
import WS0983001_SalesSituationTableInstructs from "pages/BL_AccountingBusiness/V4BL0051001_SalesSituationTableInstructs/WS0983001_SalesSituationTableInstructs.jsx"
import WS3468001 from "pages/ZZ_Others/Amendment/2024/WS3468001.jsx"
import ApplicationLogQuery from "pages/ZZ_Others/LogQuery/ApplicationLogQuery.jsx"
import ProtectPersonalInfo from "pages/ZZ_Others/DEVTOOL0500_ProtectPersonalInfo/ProtectPersonalInfo.jsx";
import CsvInputOutput from "pages/ZZ_Others/CsvInputOutput/CsvInputOutput.jsx";
import BulkAutoCalculate from "pages/ZZ_Others/RCV00001300_BulkAutoCalculate/BulkAutoCalculate.jsx";
import RecoveryProcesses from "pages/ZZ_Others/RecoveryProcesses/RecoveryProcesses.jsx";
import SpaceTrimming from "pages/ZZ_Others/SpaceTrimming/SpaceTrimming.jsx";
import WS0807001_CreateAResultTable from "pages/KK_ResultOutput/V4KK0100000_CreateAResultTable/WS0807001_CreateAResultTable.jsx";
import PrinterSetting from "pages/ZZ_Others/PrinterSetting/PrinterSetting.jsx";


const Route = [
  {
    path: "/test/test",
    component: testActions,
    displayId: 'testActions',
    title: "test",
    exact: false
  },
  {
    path: "/user-info/user-register",
    component: WS2706001_UserRegister,
    displayId: 'WS2706001',
    title: "ユーザー登録",
    exact: false
  },
  {
    path: "/user-info/user-register-form/:idUser?",
    component: WS2706002_UserRegisterForm,
    displayId: 'WS2706002',
    title: "ユーザー登録フォーム",
    exact: false
  },
  {
    path: "/user-info/password-modify",
    component: WS2518001_PasswordModify,
    displayId: 'WS2518001',
    title: "パスワード変更",
    exact: false
  },
  {
    path: "/insurer-info-maintain/insurer-info-maintain",
    component: WS0339001_InsurerInfoMaintain,
    displayId: 'WS0339001',
    title: "保険者情報保守",
    exact: false
  },
  {
    path: "/insurer-info-maintain/insurer-info-search-query",
    component: WS0246001_InsurerInfoSearchQuery,
    displayId: 'WS0246001',
    title: "保険者情報検索・照会",
    exact: false
  },
  {
    path: "/insurer-info-maintain/supplemental-info-setting/:IdentifyChar",
    component: WS0344001_SupplementalInfoSetting,
    displayId: 'WS0344001',
    title: "補足情報設定",
    exact: false
  },
  {
    path: "/insurer-info-maintain/selected-range",
    component: WS0344002_SelectedRange,
    displayId: 'WS0344002',
    title: "選択範囲",
    exact: false
  },
  {
    path: "/insurer-info-maintain/post-code-search-engine",
    component: WS0084001_PostCodeSearchEngine,
    displayId: 'WS0084001',
    title: "郵便番号検索",
    exact: false
  },
  {
    path: "/insurer-info-maintain/zip-code-query",
    component: WS0086002_ZipCodeQuery,
    displayId: 'WS0086002',
    title: "郵便番号照会",
    exact: false
  },
  {
    path: "/contract-info-maintain/organization-create-new",
    component: WS0308017_OrganizationCreateNew,
    displayId: 'WS0308017',
    title: "新規作成",
    exact: false
  },
  {
    path: "/office-info-maintain-directly/office-info-maintain",
    component: WS0341001_OfficeInfoMaintain,
    displayId: 'WS0341001',
    title: "事業所情報保守",
    exact: false
  },
  {
    path: "/office-info-maintain-directly/office-info-retrieval-query",
    component: WS0247001_OfficeInfoRetrievalQuery,
    displayId: 'WS0247001',
    title: "事業所情報検索・照会",
    exact: false
  },
  {
    path: "/office-info-maintain-directly/branch-shop-inquiry",
    component: WS0341008_BranchShopInquiry,
    displayId: 'WS0341008',
    title: "支社店照会",
    exact: false
  },
  {
    path: "/office-info-maintain-directly/branch-store-generated",
    component: WS0341009_BranchStoreGenerated,
    displayId: 'WS0341009',
    title: "支社店生成",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/personal-info-maintain",
    component: WS0343001_PersonalInfoMaintain,
    displayId: 'WS0343001',
    title: "個人情報保守",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/personal-info-search-query",
    component: WS0248001_PersonalInfoSearchQuery,
    displayId: 'WS0248001',
    title: "個人情報検索・照会",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/extension",
    component: WS0343006_Extension,
    displayId: 'WS0343006',
    title: "拡張",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/select-office",
    component: WS0343080_SelectOffice,
    displayId: 'WS0343080',
    title: "事業所選択",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/personal-attributes-reacquire",
    component: WS0382001_PersonalAttributesReacquire,
    displayId: 'WS0382001',
    title: "個人属性再取得",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/duplicate-query-year-of-birth-name-gender",
    component: WS0384006_DuplicateQueryYearOfBirthNameGender,
    displayId: 'WS0384006',
    title: "重複照会[生年・名・性別]",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/duplicate-query-date-of-birth",
    component: WS0384003_DuplicateQueryDateOfBirth,
    displayId: 'WS0384003',
    title: "重複照会[生年月日]",
    exact: false
  },
  {
    path: "/personal-info-maintain-directly/patient-info-query-stard",
    component: WS2727001_PatientInfoQueryStard,
    displayId: 'WS2727001',
    title: "患者情報照会[標準]",
    exact: false
  },
  {
    path: "/contract-info-maintain/contract-info-maintain",
    component: WS0306001_ContractInfoMaintain,
    displayId: 'WS0306001',
    title: "契約情報保守",
    exact: false
  },
  {
    path: "/contract-info-maintain/create-new",
    component: WS0306011_CreateNew,
    displayId: 'WS0306011',
    title: "新規作成",
    exact: false
  },
  {
    path: "/contract-info-maintain/contract-history-sub",
    component: WS0307008_ContractHistorySub,
    displayId: 'WS0307008',
    title: "契約履歴",
    exact: false
  },
  {
    path: "/contract-info-maintain/history-switching",
    component: WS0307071_HistorySwitching,
    displayId: 'WS0307071',
    title: "履歴切替",
    exact: false
  },
  {
    path: "/contract-info-maintain/create-contract",
    component: WS0307076_CreateContract,
    displayId: 'WS0307076',
    title: "契約作成",
    exact: false
  },
  {
    path: "/contract-info-maintain/code-changes",
    component: WS0307082_CodeChanges,
    displayId: 'WS0307082',
    title: "コード変更",
    exact: false
  },
  {
    path: "/contract-info-maintain/condition-copy",
    component: WS0315002_ConditionCopy,
    displayId: 'WS0315002',
    title: "条件複写",
    exact: false
  },
  {
    path: "/contract-info-maintain/contract-number-inquiry",
    component: WS0315004_ContractNumberInquiry,
    displayId: 'WS0315004',
    title: "契約番号照会",
    exact: false
  },
  {
    path: "/contract-info-maintain/manage-copy",
    component: WS0315007_ManageCopy,
    displayId: 'WS0315007',
    title: "管理複写",
    exact: false
  },
  {
    path: "/contract-info-maintain/copy-contract-select",
    component: WS0316001_CopyContractSelect,
    displayId: 'WS0316001',
    title: "複写契約選択",
    exact: false
  },
  {
    path: "/contract-info-maintain/confirm-screen",
    component: WS0316026_ConfirmScreen,
    displayId: 'WS0316026',
    title: "確認画面",
    exact: false
  },
  {
    path: "/contract-info-maintain/contract-office-info-inquiry-sub",
    component: WS0328001_ContractOfficeInfoInquirySub,
    displayId: 'WS0328001',
    title: "契約事業所情報照会",
    exact: false
  },
  {
    path: "/contract-info-maintain/contract-insurer-info-inquiry-sub",
    component: WS0329001_ContractInsurerInfoInquirySub,
    displayId: 'WS0329001',
    title: "契約保険者情報照会",
    exact: false
  },
  {
    path: "/contract-info-maintain/contract-historical-query-sub",
    component: WS0330001_ContractHistoricalQuerySub,
    displayId: 'WS0330001',
    title: "契約履歴照会",
    exact: false
  },
  {
    path: "/contract-info-maintain/master-list-output-contract-info",
    component: WS0426001_MasterListOutputContractInfo,
    displayId: 'WS0426001',
    title: "マスタ一覧出力[契約情報]",
    exact: false
  },
  {
    path: "/contract-info-maintain/contract-query-select",
    component: WS0275001_ContractQuerySelect,
    displayId: 'WS0275001',
    title: "契約照会選択",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/consult-info-reconstruction",
    component: WS0335001_ConsultInfoReconstruction,
    displayId: 'WS0335001',
    title: "受診情報再構成",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/inspect-changes",
    component: WS0335011_InspectChanges,
    displayId: 'WS0335011',
    title: "検査変更内容",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/personal-action-item-tree-display",
    component: WS0612001_PersonalActionItemTreeDisplay,
    displayId: 'WS0612001',
    title: "個人実施項目ツリー表示",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/character-string-search",
    component: WS0612002_CharacterStringSearch,
    displayId: 'WS0612002',
    title: "文字列検索",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/condition-add-sub",
    component: WS2786001_ConditionAddSub,
    displayId: 'WS2786001',
    title: "条件追加",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/acceptance-number-select",
    component: WS2786013_AcceptanceNumberSelect,
    displayId: 'WS2786013',
    title: "受付番号選択",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/select-course-list",
    component: WS2786020_SelectCourseList,
    displayId: 'WS2786020',
    title: "コース一覧選択",
    exact: false
  },
  {
    path: "/consult-info-reconstruction/acceptance-process-menu",
    component: WS3074001_AcceptanceProcessMenu,
    displayId: 'WS3074001',
    title: "受付処理メニュー",
    exact: false
  },
  {
    path: "/department-change-once/department-change-once",
    component: WS0345001_DepartmentChangeOnce,
    displayId: 'WS0345001',
    title: "部署変更一括",
    exact: false
  },
  {
    path: "/personal-number-integration/personal-number-integration",
    component: WS0402001_PersonalNumberIntegration,
    displayId: 'WS0402001',
    title: "個人番号統合",
    exact: false
  },
  {
    path: "/personal-number-migration/personal-number-migration",
    component: WS0401001_PersonalNumberMigration,
    displayId: 'WS0401001',
    title: "個人番号移行",
    exact: false
  },
  {
    path: "/personal-number-migration/office-special-display",
    component: WS0251003_OfficeSpecialDisplay,
    displayId: 'WS0251003',
    title: "事業所特記表示",
    exact: false
  },
  {
    path: "/personal-number-migration/office-special-maintain",
    component: WS0252001_OfficeSpecialMaintain,
    displayId: 'WS0252001',
    title: "事業所特記保守",
    exact: false
  },
  {
    path: "/personal-number-migration/visit-historical-query",
    component: WS0264001_VisitHistoricalQuery,
    displayId: 'WS0264001',
    title: "受診履歴照会",
    exact: false
  },
  {
    path: "/set-info-maintain/set-info-maintain",
    component: WS2708001_SetInfoMaintain,
    displayId: 'WS2708001',
    title: "セット情報保守",
    exact: false
  },
  {
    path: "/set-info-maintain/set-csv-create",
    component: WS2708003_SetCsvCreate,
    displayId: 'WS2708003',
    title: "セットCSV作成",
    exact: false
  },
  {
    path: "/set-info-maintain/copy-set",
    component: WS2708010_CopySet,
    displayId: 'WS2708010',
    title: "複写セット",
    exact: false
  },
  {
    path: "/set-info-maintain/confirm-screen",
    component: WS0299006_ConfirmScreen,
    displayId: 'WS0299006',
    title: "確認画面",
    exact: false
  },
  {
    path: "/set-info-maintain/set-info-change-sub",
    component: WS2709008_SetInfoChangeSub,
    displayId: 'WS2709008',
    title: "セット情報変更",
    exact: false
  },
  {
    path: "/set-info-maintain/copy-start-date",
    component: WS2709068_CopyStartDate,
    displayId: 'WS2709068',
    title: "複写開始日",
    exact: false
  },
  {
    path: "/set-info-maintain/set-info-batch-process",
    component: WS2710001_SetInfoBatchProcess,
    displayId: 'WS2710001',
    title: "セット情報一括処理",
    exact: false
  },
  {
    path: "/set-info-maintain/start-date-select",
    component: WS2710014_StartDateSelect,
    displayId: 'WS2710014',
    title: "開始日選択",
    exact: false
  },
  {
    path: "/set-info-maintain/inspect-add-delete",
    component: WS2710017_InspectAddDelete,
    displayId: 'WS2710017',
    title: "検査追加・削除",
    exact: false
  },
  {
    path: "/set-info-maintain/set-identification-change",
    component: WS2710022_SetIdentificationChange,
    displayId: 'WS2710022',
    title: "セット識別変更",
    exact: false
  },
  {
    path: "/creating-contract-history-together/create-contract-history-together",
    component: WS0317001_CreateContractHistoryTogether,
    displayId: 'WS0317001',
    title: "契約履歴一括作成",
    exact: false
  },
  {
    path: "/contract-info-batch-process/contract-info-batch-process",
    component: WS0331001_ContractInfoBatchProcess,
    displayId: 'WS0331001',
    title: "契約情報一括処理",
    exact: false
  },
  {
    path: "/contract-info-batch-process/batch-update-process",
    component: WS0331019_BatchUpdateProcess,
    displayId: 'WS0331019',
    title: "一括更新処理",
    exact: false
  },
  {
    path: "/contract-info-batch-process/basic-course-inquiry",
    component: WS0265001_BasicCourseInquiry,
    displayId: 'WS0265001',
    title: "基本コース照会",
    exact: false
  },
  {
    path: "/contract-info-batch-process/inspect-item-search-query-single",
    component: WS0271001_InspectItemSearchQuerySingle,
    displayId: 'WS0271001',
    title: "検査項目検索・照会(単品)",
    exact: false
  },
  {
    path: "/contract-info-batch-process/set-info-search",
    component: WS0302001_SetInfoSearch,
    displayId: 'WS0302001',
    title: "セット情報検索",
    exact: false
  },
  {
    path: "/contract-info-batch-process/contract-item-sub",
    component: WS0309017_ContractItemSub,
    displayId: 'WS0309017',
    title: "契約明細",
    exact: false
  },
  {
    path: "/contract-info-batch-process/contract-editing-sub",
    component: WS0310004_ContractEditingSub,
    displayId: 'WS0310004',
    title: "契約内容編集",
    exact: false
  },
  {
    path: "/contract-info-batch-process/condition-setting",
    component: WS0310120_ConditionSetting,
    displayId: 'WS0310120',
    title: "条件設定",
    exact: false
  },
  {
    path: "/contract-info-batch-process/contract-line-item-display",
    component: WS0605127_ContractLineItemDisplay,
    displayId: 'WS0605127',
    title: "契約明細照会",
    exact: false
  },
  {
    path: "/contract-info-batch-process/contract-inspect-condition",
    component: WS0605130_ContractInspectCondition,
    displayId: 'WS0605130',
    title: "契約検査条件",
    exact: false
  },
  {
    path: "/contract-info-batch-process/contract-inspect-inquiry",
    component: WS0605162_ContractInspectInquiry,
    displayId: 'WS0605162',
    title: "契約検査照会",
    exact: false
  },
  {
    path: "/contract-info-batch-process/contract-query-old",
    component: WS0606001_ContractQueryOld,
    displayId: 'WS0606001',
    title: "契約照会(旧)",
    exact: false
  },
  {
    path: "/reserve-status-search/reserve-status-search",
    component: WS2528001_ReserveStatusSearch,
    displayId: 'WS2528001',
    title: "予約状況検索",
    exact: false
  },
  {
    path: "/reserve-status-search/setting-display-item",
    component: WS2528031_SettingDisplayItem,
    displayId: 'WS2528031',
    title: "表示項目設定",
    exact: false
  },
  {
    path: "/reserve-status-search/details-extract",
    component: WS2528047_DetailsExtract,
    displayId: 'WS2528047',
    title: "詳細抽出",
    exact: false
  },
  {
    path: "/reserve-status-search/schedule-register-screen",
    component: WS0206001_ScheduleRegisterScreen,
    displayId: 'WS0206001',
    title: "スケジュール登録画面",
    exact: false
  },
  {
    path: "/reserve-status-search/reserves-persons-bulk-update",
    component: WS0548001_ReservesPersonsBulkUpdate,
    displayId: 'WS0548001',
    title: "予約人数一括更新",
    exact: false
  },
  {
    path: "/reserve-status-search/hourly-reserve-list",
    component: WS2529001_HourlyReserveList,
    displayId: 'WS2529001',
    title: "時間別予約一覧",
    exact: false
  },
  {
    path: "/reserve-status-search/display-content-change",
    component: WS2531063_DisplayContentChange,
    displayId: 'WS2531063',
    title: "表示内容変更",
    exact: false
  },
  {
    path: "/reserve-status-search/period-time-inquiry",
    component: WS2553003_PeriodTimeInquiry,
    displayId: 'WS2553003',
    title: "時間帯照会",
    exact: false
  },
  {
    path: "/reserve-status-search/calendar-empty-sub",
    component: WS2577003_CalendarEmptySub,
    displayId: 'WS2577003',
    title: "空き状況検索",
    exact: false
  },
  {
    path: "/reserve-status-search/schedule-change",
    component: WS2580001_ScheduleChange,
    displayId: 'WS2580001',
    title: "スケジュール変更",
    exact: false
  },
  {
    path: "/reserve-status-search/csv-output-confirm",
    component: WS2613145_CsvOutputConfirm,
    displayId: 'WS2613145',
    title: "CSV出力確認",
    exact: false
  },
  {
    path: "/group-bookings/group-bookings",
    component: WS2532001_GroupBookings,
    displayId: 'WS2532001',
    title: "団体予約",
    exact: false
  },
  {
    path: "/group-bookings/insurance-card-inquiry",
    component: WS2532008_InsuranceCardInquiry,
    displayId: 'WS2532008',
    title: "保険証照会",
    exact: false
  },
  {
    path: "/group-bookings/date-of-birth-inquiry",
    component: WS2532012_DateOfBirthInquiry,
    displayId: 'WS2532012',
    title: "生年月日照会",
    exact: false
  },
  {
    path: "/group-bookings/contract-info-inquiry",
    component: WS0289012_ContractInfoInquiry,
    displayId: 'WS0289012',
    title: "契約情報照会",
    exact: false
  },
  {
    path: "/group-bookings/confirm-sub",
    component: WS2533001_ConfirmSub,
    displayId: 'WS2533001',
    title: "内容確認",
    exact: false
  },
  {
    path: "/group-bookings/previous-course-acquisition-sub",
    component: WS2542001_PreviousCourseAcquisitionSub,
    displayId: 'WS2542001',
    title: "前回コース取得",
    exact: false
  },
  {
    path: "/group-bookings/office-info-inquiry-sub",
    component: WS2585001_OfficeInfoInquirySub,
    displayId: 'WS2585001',
    title: "事業所情報照会",
    exact: false
  },
  {
    path: "/frame-reserve/frame-reserve",
    component: WS2535001_FrameReserve,
    displayId: 'WS2535001',
    title: "枠取予約",
    exact: false
  },
  {
    path: "/frame-reserve/verification",
    component: WS2535011_Verification,
    displayId: 'WS2535011',
    title: "確認",
    exact: false
  },
  {
    path: "/personal-reserve-process/personal-reserve-process",
    component: WS2537001_PersonalReserveProcess,
    displayId: 'WS2537001',
    title: "個人予約処理",
    exact: false
  },
  {
    path: "/personal-reserve-process/set-add",
    component: WS2537046_SetAdd,
    displayId: 'WS2537046',
    title: "セット追加",
    exact: false
  },
  {
    path: "/personal-reserve-process/visits-change-confirm",
    component: WS2537059_VisitsChangeConfirm,
    displayId: 'WS2537059',
    title: "受診変更確認",
    exact: false
  },
  {
    path: "/personal-reserve-process/info-held",
    component: WS2537084_InfoHeld,
    displayId: 'WS2537084',
    title: "情報保持",
    exact: false
  },
  {
    path: "/personal-reserve-process/personal-special-maintain",
    component: WS0250001_PersonalSpecialMaintain,
    displayId: 'WS0250001',
    title: "個人特記保守",
    exact: false
  },
  {
    path: "/personal-reserve-process/visits-target-search-query",
    component: WS0273001_VisitsTargetSearchQuery,
    displayId: 'WS0273001',
    title: "受診対象者検索・照会",
    exact: false
  },
  {
    path: "/personal-reserve-process/display-content-change",
    component: WS0273011_DisplayContentChange,
    displayId: 'WS0273011',
    title: "表示内容変更",
    exact: false
  },
  {
    path: "/personal-reserve-process/money-amount-input-sub",
    component: WS0311005_MoneyAmountInputSub,
    displayId: 'WS0311005',
    title: "金額入力",
    exact: false
  },
  {
    path: "/personal-reserve-process/contract-course-breakdown-inquiry",
    component: WS0332001_ContractCourseBreakdownInquiry,
    displayId: 'WS0332001',
    title: "契約コース内訳照会",
    exact: false
  },
  {
    path: "/personal-reserve-process/set-includes-query",
    component: WS0333001_SetIncludesQuery,
    displayId: 'WS0333001',
    title: "セット内容照会",
    exact: false
  },
  {
    path: "/personal-reserve-process/more-detail",
    component: WS0333011_MoreDetail,
    displayId: 'WS0333011',
    title: "詳細",
    exact: false
  },
  {
    path: "/personal-reserve-process/personal-office-search-query",
    component: WS0381001_PersonalOfficeSearchQuery,
    displayId: 'WS0381001',
    title: "個人事業所検索・照会",
    exact: false
  },
  {
    path: "/personal-reserve-process/visits-supplement",
    component: WS0392004_VisitsSupplement,
    displayId: 'WS0392004',
    title: "受診補足",
    exact: false
  },
  {
    path: "/personal-reserve-process/visits-supplement-wk",
    component: WS0392005_VisitsSupplementWk,
    displayId: 'WS0392005',
    title: "受診補足WK",
    exact: false
  },
  {
    path: "/personal-reserve-process/card-issuing-sub",
    component: WS1846001_CardIssuingSub,
    displayId: 'WS1846001',
    title: "カード発行",
    exact: false
  },
  {
    path: "/personal-reserve-process/confirm-screen",
    component: WS1846011_ConfirmScreen,
    displayId: 'WS1846011',
    title: "確認画面",
    exact: false
  },
  {
    path: "/personal-reserve-process/application-info-register",
    component: WS2543001_ApplicationInfoRegister,
    displayId: 'WS2543001',
    title: "申込情報登録",
    exact: false
  },
  {
    path: "/personal-reserve-process/medical-exam-contents-inquiry-sub",
    component: WS2555001_MedicalExamContentsInquirySub,
    displayId: 'WS2555001',
    title: "健診内容照会",
    exact: false
  },
  {
    path: "/personal-reserve-process/list",
    component: WS2555012_List,
    displayId: 'WS2555012',
    title: "一覧",
    exact: false
  },
  {
    path: "/personal-reserve-process/numeric-input-sub",
    component: WS2581015_NumericInputSub,
    displayId: 'WS2581015',
    title: "数値入力",
    exact: false
  },
  {
    path: "/reserves-bulk-changes/reserves-bulk-changes",
    component: WS2556001_ReservesBulkChanges,
    displayId: 'WS2556001',
    title: "予約一括変更",
    exact: false
  },
  {
    path: "/reserves-bulk-changes/money-amount-input",
    component: WS2556015_MoneyAmountInput,
    displayId: 'WS2556015',
    title: "金額入力",
    exact: false
  },
  {
    path: "/reserves-bulk-changes/reserve-change",
    component: WS2556064_ReserveChange,
    displayId: 'WS2556064',
    title: "予約変更",
    exact: false
  },
  {
    path: "/examinee-search/examinee-search",
    component: WS2783001_ExamineeSearch,
    displayId: 'WS2783001',
    title: "受診者検索",
    exact: false
  },
  {
    path: "/document-batch-create/create-document-batch",
    component: WS0649001_CreateDocumentBatch,
    displayId: 'WS0649001',
    title: "資料一括作成",
    exact: false
  },
  {
    path: "/document-batch-create/personal-query-process",
    component: WS0649007_PersonalQueryProcess,
    displayId: 'WS0649007',
    title: "個人照会処理",
    exact: false
  },
  {
    path: "/document-batch-create/document-batch-create-sub",
    component: WS0650001_DocumentBatchCreateSub,
    displayId: 'WS0650001',
    title: "資料一括作成",
    exact: false
  },
  {
    path: "/document-manage-maintain/document-manage-maintain",
    component: WS1543001_DocumentManageMaintain,
    displayId: 'WS1543001',
    title: "資料管理保守",
    exact: false
  },
  {
    path: "/document-manage-maintain/copy",
    component: WS1543004_Copy,
    displayId: 'WS1543004',
    title: "複写",
    exact: false
  },
  {
    path: "/document-manage-maintain/detail-sub",
    component: WS1544001_DetailSub,
    displayId: 'WS1544001',
    title: "明細",
    exact: false
  },
  {
    path: "/document-manage-maintain/format-query",
    component: WS1544002_FormatQuery,
    displayId: 'WS1544002',
    title: "フォーマット照会",
    exact: false
  },
  {
    path: "/document-manage-maintain/output-pattern-sub",
    component: WS1545001_OutputPatternSub,
    displayId: 'WS1545001',
    title: "出力パターン",
    exact: false
  },
  {
    path: "/document-manage-maintain/destination-sub",
    component: WS1546001_DestinationSub,
    displayId: 'WS1546001',
    title: "送付先",
    exact: false
  },
  {
    path: "/document-manage-maintain/param-prompted-query-content",
    component: WS2592016_ParamPromptedQueryContent,
    displayId: 'WS2592016',
    title: "パラメータ指示照会(内容)",
    exact: false
  },
  {
    path: "/document-manage-maintain/escort-manage-maintain",
    component: WS0638001_EscortManageMaintain,
    displayId: 'WS0638001',
    title: "エスコート管理保守",
    exact: false
  },
  {
    path: "/document-manage-maintain/format-query",
    component: WS0638003_FormatQuery,
    displayId: 'WS0638003',
    title: "フォーマット照会",
    exact: false
  },
  {
    path: "/document-manage-maintain/copy",
    component: WS0638007_Copy,
    displayId: 'WS0638007',
    title: "複写",
    exact: false
  },
  {
    path: "/document-manage-maintain/escort-inquiry",
    component: WS0638013_EscortInquiry,
    displayId: 'WS0638013',
    title: "エスコート照会",
    exact: false
  },
  {
    path: "/document-manage-maintain/escort-maintain",
    component: WS0640001_EscortMaintain,
    displayId: 'WS0640001',
    title: "エスコート保守",
    exact: false
  },
  {
    path: "/document-manage-maintain/retrieval",
    component: WS0640002_Retrieval,
    displayId: 'WS0640002',
    title: "検索",
    exact: false
  },
  {
    path: "/document-manage-maintain/format",
    component: WS0640003_Format,
    displayId: 'WS0640003',
    title: "FORMAT",
    exact: false
  },
  {
    path: "/document-manage-maintain/copying-process",
    component: WS0640004_CopyingProcess,
    displayId: 'WS0640004',
    title: "複写処理",
    exact: false
  },
  {
    path: "/document-manage-maintain/escort-detail-sub",
    component: WS0641001_EscortDetailSub,
    displayId: 'WS0641001',
    title: "エスコート明細",
    exact: false
  },
  {
    path: "/document-manage-maintain/basic-condition-set",
    component: WS0641008_BasicConditionSet,
    displayId: 'WS0641008',
    title: "基本条件設定",
    exact: false
  },
  {
    path: "/document-manage-maintain/inspect-condition-setting",
    component: WS0641021_InspectConditionSetting,
    displayId: 'WS0641021',
    title: "検査条件設定",
    exact: false
  },
  {
    path: "/document-manage-maintain/document-manage-o",
    component: WS0642003_DocumentManageO,
    displayId: 'WS0642003',
    title: "資料管理O",
    exact: false
  },
  {
    path: "/consult-info-list/consult-info-list",
    component: WS0643001_ConsultInfoList,
    displayId: 'WS0643001',
    title: "受診情報一覧表",
    exact: false
  },
  {
    path: "/consult-info-list/preview-confirm",
    component: WS0435012_PreviewConfirm,
    displayId: 'WS0435012',
    title: "プレビュー確認",
    exact: false
  },
  {
    path: "/consult-info-list/course-multiple-extract-screen-sub",
    component: WS0578001_CourseMultipleExtractScreenSub,
    displayId: 'WS0578001',
    title: "コース複数抽出画面",
    exact: false
  },
  {
    path: "/consult-info-list/target-select-sub",
    component: WS2788013_TargetSelectSub,
    displayId: 'WS2788013',
    title: "対象選択",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/mirais-electronic-medical-records-sent",
    component: WS2736001_MiraisElectronicMedicalRecordsSent,
    displayId: 'WS2736001',
    title: "Mirais電子カルテ送信",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/laboratory-inspect-confirm-sub",
    component: WS2738001_LaboratoryInspectConfirmSub,
    displayId: 'WS2738001',
    title: "検体検査確認",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/error-confirm-sub",
    component: WS2739001_ErrorConfirmSub,
    displayId: 'WS2739001',
    title: "エラー確認",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/extract-batch-process",
    component: WS2740001_ExtractBatchProcess,
    displayId: 'WS2740001',
    title: "抽出一括処理",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/confirm-screen",
    component: WS2745009_ConfirmScreen,
    displayId: 'WS2745009',
    title: "確認画面",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/check-availability",
    component: WS2749001_CheckAvailability,
    displayId: 'WS2749001',
    title: "空き状況確認",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/mirais-transmission-record",
    component: WS2750001_MiraisTransmissionRecord,
    displayId: 'WS2750001',
    title: "Mirais送信記録",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/mirais-inspect-maintain",
    component: WS2751001_MiraisInspectMaintain,
    displayId: 'WS2751001',
    title: "Mirais検査保守",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/inspect-maintain",
    component: WS2751002_InspectMaintain,
    displayId: 'WS2751002',
    title: "検査保守",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/mirais-reserve-maintain",
    component: WS2752001_MiraisReserveMaintain,
    displayId: 'WS2752001',
    title: "Mirais予約保守",
    exact: false
  },
  {
    path: "/mirais-electronic-medical-records-sent/mirais-implementor-maintain",
    component: WS2753001_MiraisImplementorMaintain,
    displayId: 'WS2753001',
    title: "Mirais実施者保守",
    exact: false
  },
  {
    path: "/mirais-single-transmission/mirais-single-transmission",
    component: WS2737001_MiraisSingleTransmission,
    displayId: 'WS2737001',
    title: "Mirais単体送信",
    exact: false
  },
  {
    path: "/mirais-transmission-inquiry/mirais-transmission-inquiry",
    component: WS2737600_MiraisTransmissionInquiry,
    displayId: 'WS2737600',
    title: "Mirais自動送信照会",
    exact: false
  },
  {
    path: "/inspect-acquisition-update-customized-version/inspect-acquisition-update-customized-version",
    component: WS1046001_InspectAcquisitionUpdateCustomizedVersion,
    displayId: 'WS1046001',
    title: "検査取込更新[カスタマイズ版]",
    exact: false
  },
  {
    path: "/inspect-acquisition-update-customized-version/acquire-setting-sub",
    component: WS1050001_AcquireSettingSub,
    displayId: 'WS1050001',
    title: "取込形式設定",
    exact: false
  },
  {
    path: "/inspect-acquisition-update-customized-version/inspect-specified",
    component: WS1050007_InspectSpecified,
    displayId: 'WS1050007',
    title: "検査指定",
    exact: false
  },
  {
    path: "/inspect-acquisition-update-customized-version/consult-select",
    component: WS1053004_ConsultSelect,
    displayId: 'WS1053004',
    title: "受診選択",
    exact: false
  },
  {
    path: "/inspect-request-main/inspect-request-main",
    component: WS1063001_InspectRequestMain,
    displayId: 'WS1063001',
    title: "検査依頼メイン",
    exact: false
  },
  {
    path: "/inspect-request-main/personal-info-inquiry-sub",
    component: WS2584019_PersonalInfoInquirySub,
    displayId: 'WS2584019',
    title: "個人情報照会",
    exact: false
  },
  {
    path: "/ocr-capture-start-up/ocr-capture-start-up",
    component: WS3108001_OcrCaptureStartUp,
    displayId: 'WS3108001',
    title: "OCR取込み起動",
    exact: false
  },
  {
    path: "/ocr-capture-start-up/process-select-query",
    component: WS3108008_ProcessSelectQuery,
    displayId: 'WS3108008',
    title: "処理選択照会",
    exact: false
  },
  {
    path: "/ocr-capture-start-up/confirm-acquisition-target",
    component: WS3108015_ConfirmAcquisitionTarget,
    displayId: 'WS3108015',
    title: "取込対象確認",
    exact: false
  },
  {
    path: "/ocr-capture-start-up/id-consult-date-modify",
    component: WS3108016_IdConsultDateModify,
    displayId: 'WS3108016',
    title: "ID・受診日修正",
    exact: false
  },
  {
    path: "/ocr-capture-start-up/setting-ocr-capture",
    component: WS1107001_SettingOcrCapture,
    displayId: 'WS1107001',
    title: "設定[OCR取込]",
    exact: false
  },
  {
    path: "/ocr-capture-start-up/ocr-check",
    component: WS1107002_OcrCheck,
    displayId: 'WS1107002',
    title: "OCRチェック",
    exact: false
  },
  {
    path: "/patient-info-capture-screen/patient-info-capture-screen",
    component: WS2722001_PatientInfoCaptureScreen,
    displayId: 'WS2722001',
    title: "患者情報取込[画面]",
    exact: false
  },
  {
    path: "/patient-info-capture-screen/patient-inquiry",
    component: WS2722007_PatientInquiry,
    displayId: 'WS2722007',
    title: "患者照会",
    exact: false
  },
  {
    path: "/patient-info-capture-screen/acquire-setting-sub",
    component: WS2725001_AcquireSettingSub,
    displayId: 'WS2725001',
    title: "取込形式設定",
    exact: false
  },
  {
    path: "/online-instruction/online-instruction",
    component: WS2720001_OnlineInstruction,
    displayId: 'WS2720001',
    title: "オンライン指示",
    exact: false
  },
  {
    path: "/online-instruction/configuration",
    component: WS2720007_Configuration,
    displayId: 'WS2720007',
    title: "設定",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/e-medical-records-batch-transmission",
    component: WS2766001_EMedicalRecordsBatchTransmission,
    displayId: 'WS2766001',
    title: "[E-カルテ]一括送信",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/sending-confirm",
    component: WS2766004_SendingConfirm,
    displayId: 'WS2766004',
    title: "送信確認",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/e-medical-records-transmission-history",
    component: WS2776001_EMedicalRecordsTransmissionHistory,
    displayId: 'WS2776001',
    title: "[E-カルテ]送信履歴",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/inspect-content",
    component: WS2776003_InspectContent,
    displayId: 'WS2776003',
    title: "検査内容",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/retransmission-or-delete",
    component: WS2776004_RetransmissionOrDelete,
    displayId: 'WS2776004',
    title: "再送・削除",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/e-medical-records-course-basic-type-setting",
    component: WS2777001_EMedicalRecordsCourseBasicTypeSetting,
    displayId: 'WS2777001',
    title: "[E-カルテ]コース基本種別設定",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/contract-info-list",
    component: WS2777008_ContractInfoList,
    displayId: 'WS2777008',
    title: "契約情報一覧",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/e-medical-records-transmission-header-maintain",
    component: WS2779001_EMedicalRecordsTransmissionHeaderMaintain,
    displayId: 'WS2779001',
    title: "[E-カルテ]送信ヘッダ保守",
    exact: false
  },
  {
    path: "/e-medical-records-batch-transmission/e-medical-records-batch-extract",
    component: WS2780062_EMedicalRecordsBatchExtract,
    displayId: 'WS2780062',
    title: "[E-カルテ]一括抽出",
    exact: false
  },
  {
    path: "/e-medical-records-single-transmission/e-medical-records-single-transmission",
    component: WS2767001_EMedicalRecordsSingleTransmission,
    displayId: 'WS2767001',
    title: "[E-カルテ]単体送信",
    exact: false
  },
  {
    path: "/e-medical-records-single-transmission/consult-select",
    component: WS2767009_ConsultSelect,
    displayId: 'WS2767009',
    title: "受診選択",
    exact: false
  },
  {
    path: "/e-medical-records-single-transmission/sending-confirm",
    component: WS2767010_SendingConfirm,
    displayId: 'WS2767010',
    title: "送信確認",
    exact: false
  },
  {
    path: "/e-medical-records-single-transmission/another-day-inspect-set",
    component: WS2767017_AnotherDayInspectSet,
    displayId: 'WS2767017',
    title: "別日検査設定",
    exact: false
  },
  {
    path: "/e-medical-records-single-transmission/debug",
    component: WS2767023_Debug,
    displayId: 'WS2767023',
    title: "デバッグ",
    exact: false
  },
  {
    path: "/e-medical-records-single-transmission/e-medical-records-inspect-add-sub",
    component: WS2768001_EMedicalRecordsInspectAddSub,
    displayId: 'WS2768001',
    title: "[E-カルテ] 検査追加",
    exact: false
  },
  {
    path: "/e-medical-records-single-transmission/inspect-select",
    component: WS2768004_InspectSelect,
    displayId: 'WS2768004',
    title: "検査選択",
    exact: false
  },
  {
    path: "/e-medical-records-inspect-request-maintain/e-medical-records-inspect-request-maintain",
    component: WS2778001_EMedicalRecordsInspectRequestMaintain,
    displayId: 'WS2778001',
    title: "[E-カルテ]検査依頼保守",
    exact: false
  },
  {
    path: "/e-medical-records-inspect-request-maintain/extract-confirm",
    component: WS2778002_ExtractConfirm,
    displayId: 'WS2778002',
    title: "抽出確認",
    exact: false
  },
  {
    path: "/e-medical-records-inspect-request-maintain/inspect-list-setting-sub",
    component: WS0087001_InspectListSettingSub,
    displayId: 'WS0087001',
    title: "検査一覧設定",
    exact: false
  },
  {
    path: "/e-medical-records-inspect-request-maintain/courses-setting-sub",
    component: WS0104001_CoursesSettingSub,
    displayId: 'WS0104001',
    title: "コース 一覧設定SUB",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/inspect-item-convert-internal",
    component: WS2700017_InspectItemConvertInternal,
    displayId: 'WS2700017',
    title: "検査項目変換(内部)",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/input-form",
    component: WS2700099_InputForm,
    displayId: 'WS2700099',
    title: "入力フォーム",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/quantitative-qualitative-transformation-sub",
    component: WS1035001_QuantitativeQualitativeTransformationSub,
    displayId: 'WS1035001',
    title: "定量定性変換",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/inspect-value-convert-sub",
    component: WS1036001_InspectValueConvertSub,
    displayId: 'WS1036001',
    title: "検査値変換",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/inspect-cmt-convert-internal",
    component: WS1038001_InspectCmtConvertInternal,
    displayId: 'WS1038001',
    title: "検査コメント変換(内部)",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/inspect-name-convert-in-maintain-ta",
    component: WS1039001_InspectNameConvertInMaintainTa,
    displayId: 'WS1039001',
    title: "検査名称変換(ＩＮ)保守[ＴＡ]",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/inspect-value-convert-internal",
    component: WS1040001_InspectValueConvertInternal,
    displayId: 'WS1040001',
    title: "検査値変換  (内部)",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/copy",
    component: WS1040003_Copy,
    displayId: 'WS1040003',
    title: "コピー",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/external-inspect-amount-of-qualitative-transformation",
    component: WS1041001_ExternalInspectAmountOfQualitativeTransformation,
    displayId: 'WS1041001',
    title: "外部検定量定性変換",
    exact: false
  },
  {
    path: "/inspect-item-convert-internal/inspect-taking-partial-convert-sub",
    component: WS2715042_InspectTakingPartialConvertSub,
    displayId: 'WS2715042',
    title: "検査取込部分変換",
    exact: false
  },
  {
    path: "/inspect-request-convert-master-maintain/inspect-request-convert-master-maintain",
    component: WS1043001_InspectRequestConvertMasterMaintain,
    displayId: 'WS1043001',
    title: "検査依頼変換マスタ保守",
    exact: false
  },
  {
    path: "/inspect-request-convert-master-maintain/inspect-capture-inquiry",
    component: WS1043008_CsvCaptureScreen,
    displayId: 'WS1043008',
    title: "検査取込照会",
    exact: false
  },
  {
    path: "/acceptance-batch-process/acceptance-batch-process",
    component: WS0699001_AcceptanceBatchProcess,
    displayId: 'WS0699001',
    title: "受付一括処理",
    exact: false
  },
  // {
  //   path: "/counter/counter",
  //   component: WS2620001_Counter,
  //   displayId: 'WS2620001',
  //   title: "窓口",
  //   exact: false
  // },
  {
    path: "/counter/counter",
    component: WS2620001_Counter_SANAI,
    displayId: 'WS2620001',
    title: "窓口",
    exact: false
  },
  {
    path: "/counter/refine",
    component: WS2620036_Refine,
    displayId: 'WS2620036',
    title: "絞り込み",
    exact: false
  },
  {
    path: "/counter/payment-correct-sub",
    component: WS0956001_PaymentCorrectSub,
    displayId: 'WS0956001',
    title: "入金訂正",
    exact: false
  },
  {
    path: "/counter/inspect-change-query-sub",
    component: WS2587021_InspectChangeQuerySub,
    displayId: 'WS2587021',
    title: "検査変動照会",
    exact: false
  },
  {
    path: "/counter/payment-process-sub",
    component: WS2621001_PaymentProcessSub,
    displayId: 'WS2621001',
    title: "入金処理",
    exact: false
  },
  {
    path: "/counter/split-payments",
    component: WS2621008_SplitPayment,
    displayId: 'WS2621008',
    title: "分割入金",
    exact: false
  },
  {
    path: "/counter/receipt-process-sub",
    component: WS2622003_ReceiptProcessSub,
    displayId: 'WS2622003',
    title: "領収処理",
    exact: false
  },
  {
    path: "/counter/dispensing-process-sub",
    component: WS2623001_DispensingProcessSub,
    displayId: 'WS2623001',
    title: "出金処理",
    exact: false
  },
  {
    path: "/counter/split-payment",
    component: WS2623007_SplitPayment,
    displayId: 'WS2623007',
    title: "分割入金",
    exact: false
  },
  {
    path: "/radiography-finding-input/radiography-finding-input",
    component: WS1860001_RadiographyFindingInput,
    displayId: 'WS1860001',
    title: "読影所見入力",
    exact: false
  },
  {
    path: "/radiography-finding-input/radiography-doctor-inquiry",
    component: WS1860005_RadiographyDoctorInquiry,
    displayId: 'WS1860005',
    title: "読影者照会",
    exact: false
  },
  {
    path: "/radiography-finding-input/radiography-finding-input-sub",
    component: WS1863001_RadiographyFindingInputSub,
    displayId: 'WS1863001',
    title: "読影所見入力 SUB",
    exact: false
  },
  {
    path: "/radiography-finding-input/leadership-matter-search-sub",
    component: WS1865001_LeadershipMatterSearchSub,
    displayId: 'WS1865001',
    title: "指導事項検索 SUB",
    exact: false
  },
  {
    path: "/radiography-finding-input/judge-select-query-sub",
    component: WS1868001_JudgeSelectQuerySub,
    displayId: 'WS1868001',
    title: "判定選択照会 SUB",
    exact: false
  },
  {
    path: "/radiography-finding-input/findings-history-list",
    component: WS1870011_FindingsHistoryList,
    displayId: 'WS1870011',
    title: "所見履歴一覧",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/radiography-inspect-maintain",
    component: WS1871001_RadiographyInspectMaintain,
    displayId: 'WS1871001',
    title: "読影検査保守",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/copy",
    component: WS1871014_Copy,
    displayId: 'WS1871014',
    title: "複写",
    exact: false
  },
  {
    path: "/radiography-judge-maintain/radiography-judge-maintain",
    component: WS1876001_RadiographyJudgeMaintain,
    displayId: 'WS1876001',
    title: "読影判定保守",
    exact: false
  },
  {
    path: "/radiologists-info-maintain/radiography-info-maintain",
    component: WS1877001_RadiographyInfoMaintain,
    displayId: 'WS1877001',
    title: "読影者情報保守",
    exact: false
  },
  {
    path: "/radiography-findings-submit/radiography-findings-submit",
    component: WS1878001_RadiographyFindingsSubmit,
    displayId: 'WS1878001',
    title: "読影所見送信",
    exact: false
  },
  {
    path: "/radiography-findings-submit/radiography-inspect-select",
    component: WS1878009_RadiographyInspectSelect,
    displayId: 'WS1878009',
    title: "読影検査選択",
    exact: false
  },
  {
    path: "/radiography-findings-submit/set-info-search-multiple-choice",
    component: WS0304001_SetInfoSearchMultipleChoice,
    displayId: 'WS0304001',
    title: "セット情報検索[複数選択]",
    exact: false
  },
  {
    path: "/radiography-findings-submit/same-surname-same-name-same-birth-date-select-screen",
    component: WS0377002_SameSurnameSameNameSameBirthDateSelectScreen,
    displayId: 'WS0377002',
    title: "同姓/同名/同生年月日選択画面",
    exact: false
  },
  {
    path: "/radiography-findings-submit/period-time-display",
    component: WS0540001_PeriodTimeDisplay,
    displayId: 'WS0540001',
    title: "時間帯表示",
    exact: false
  },
  {
    path: "/radiography-findings-submit/calendar-detail-display",
    component: WS0542001_CalendarDetailDisplay,
    displayId: 'WS0542001',
    title: "カレンダー詳細表示",
    exact: false
  },
  {
    path: "/radiography-findings-submit/date-change",
    component: WS0544001_DateChange,
    displayId: 'WS0544001',
    title: "年月変更",
    exact: false
  },
  {
    path: "/radiography-findings-submit/free-search-sub1",
    component: WS0555001_FreeSearchSub1,
    displayId: 'WS0555001',
    title: "空き検索SUB1",
    exact: false
  },
  {
    path: "/radiography-findings-submit/global-event-register-correct",
    component: WS0560001_GlobalEventRegisterCorrect,
    displayId: 'WS0560001',
    title: "グローバルイベント登録修正",
    exact: false
  },
  {
    path: "/radiography-findings-submit/book-accept-change-process",
    component: WS0565001_BookAcceptChangeProcess,
    displayId: 'WS0565001',
    title: "予約・受付変更処理",
    exact: false
  },
  {
    path: "/radiography-findings-submit/visits-change-confirm",
    component: WS0565008_VisitsChangeConfirm,
    displayId: 'WS0565008',
    title: "受診変更確認",
    exact: false
  },
  {
    path: "/radiography-findings-submit/reserve-change-confirm",
    component: WS0565009_ReserveChangeConfirm,
    displayId: 'WS0565009',
    title: "予約変更確認",
    exact: false
  },
  {
    path: "/radiography-findings-submit/consult-history",
    component: WS0583001_ConsultHistory,
    displayId: 'WS0583001',
    title: "受診履歴",
    exact: false
  },
  {
    path: "/radiography-findings-submit/inspect-change-setting",
    component: WS0592001_InspectChangeSetting,
    displayId: 'WS0592001',
    title: "検査変動設定",
    exact: false
  },
  {
    path: "/radiography-findings-submit/money-amount-input",
    component: WS0592021_MoneyAmountInput,
    displayId: 'WS0592021',
    title: "金額入力",
    exact: false
  },
  {
    path: "/radiography-findings-submit/free-search-inn",
    component: WS0627001_FreeSearchInn,
    displayId: 'WS0627001',
    title: "空き検索(宿)",
    exact: false
  },
  {
    path: "/radiography-findings-submit/contract-inspect-content-select-sub",
    component: WS0688001_ContractInspectContentSelectSub,
    displayId: 'WS0688001',
    title: "契約検査内容選択         SUB",
    exact: false
  },
  {
    path: "/radiography-findings-submit/specific-date-period-time-people-num-set",
    component: WS1492001_SpecificDatePeriodTimePeopleNumSet,
    displayId: 'WS1492001',
    title: "特定日時間帯人数設定",
    exact: false
  },
  {
    path: "/radiography-findings-submit/easy-basic-data-set",
    component: WS1492011_EasyBasicDataSet,
    displayId: 'WS1492011',
    title: "簡単基礎データ設定",
    exact: false
  },
  {
    path: "/radiography-findings-submit/radiography-subjects",
    component: WS1881002_RadiographySubjects,
    displayId: 'WS1881002',
    title: "読影対象者",
    exact: false
  },
  {
    path: "/radiography-findings-submit/radiography-contents-of-query",
    component: WS1881003_RadiographyContentsOfQuery,
    displayId: 'WS1881003',
    title: "読影内容照会",
    exact: false
  },
  {
    path: "/spread-input/spread-input",
    component: WS0735001_SpreadInput,
    displayId: 'WS0735001',
    title: "スプレッド入力",
    exact: false
  },
  {
    path: "/spread-input/data-update-confirm",
    component: WS0735028_DataUpdateConfirm,
    displayId: 'WS0735028',
    title: "データ更新確認",
    exact: false
  },
  {
    path: "/spread-input/inspect-cmt-search-query",
    component: WS0274001_InspectCmtSearchQuery,
    displayId: 'WS0274001',
    title: "検査コメント検索・照会",
    exact: false
  },
  {
    path: "/spread-input/spreadsheet-input-setting",
    component: WS0456001_SpreadsheetInputSetting,
    displayId: 'WS0456001',
    title: "スプレットシート入力設定",
    exact: false
  },
  {
    path: "/spread-input/batch-capture",
    component: WS0456005_BatchCapture,
    displayId: 'WS0456005',
    title: "一括取込",
    exact: false
  },
  {
    path: "/spread-input/copy",
    component: WS0456007_Copy,
    displayId: 'WS0456007',
    title: "複写",
    exact: false
  },
  {
    path: "/spread-input/change",
    component: WS0456009_Change,
    displayId: 'WS0456009',
    title: "変更",
    exact: false
  },
  {
    path: "/spread-input/guide-matter-set",
    component: WS0719001_GuideMatterSet,
    displayId: 'WS0719001',
    title: "指導事項設定",
    exact: false
  },
  {
    path: "/spread-input/notes-set",
    component: WS0720001_NotesSet,
    displayId: 'WS0720001',
    title: "注意事項設定",
    exact: false
  },
  {
    path: "/spread-input/findings-input-normal",
    component: WS0728001_FindingsInputNormal,
    displayId: 'WS0728001',
    title: "所見入力[通常]",
    exact: false
  },
  {
    path: "/spread-input/findings-input-radiography",
    component: WS0729001_FindingsInputRadiography,
    displayId: 'WS0729001',
    title: "所見入力[読影]",
    exact: false
  },
  {
    path: "/spread-input/findings-input-physiciiagnosis",
    component: WS0730001_FindingsInputPhysiciiagnosis,
    displayId: 'WS0730001',
    title: "所見入力[医師診断]",
    exact: false
  },
  {
    path: "/spread-input/findings-input-normal-select",
    component: WS0731001_FindingsInputNormalSelect,
    displayId: 'WS0731001',
    title: "所見入力[通常選択]",
    exact: false
  },
  {
    path: "/spread-input/guide-matter-setting-spread",
    component: WS0737001_GuideMatterSettingSpread,
    displayId: 'WS0737001',
    title: "指導事項設定(スプレッド)",
    exact: false
  },
  {
    path: "/not-input-check-category/not-input-check-category",
    component: WS0743001_NotInputCheckCategory,
    displayId: 'WS0743001',
    title: "未入力チェック[カテゴリ]",
    exact: false
  },
  {
    path: "/input-vote-result-input/input-vote-result-input",
    component: WS0738001_InputVoteResultInput,
    displayId: 'WS0738001',
    title: "伝票単位入力",
    exact: false
  },
  {
    path: "/not-input-check-category/type-query",
    component: WS0743010_TypeQuery,
    displayId: 'WS0743010',
    title: "種別照会",
    exact: false
  },
  {
    path: "/not-input-check-category/category-list-setting-sub",
    component: WS0103001_CategoryListSettingSub,
    displayId: 'WS0103001',
    title: "カテゴリ一覧設定",
    exact: false
  },
  {
    path: "/not-input-check-category/history-input-sub",
    component: WS0714001_HistoryInputSub,
    displayId: 'WS0714001',
    title: "既往歴入力",
    exact: false
  },
  {
    path: "/not-input-check-category/input-vote-result-input-inspect-input",
    component: WS0739001_InputVoteResultInputInspectInput,
    displayId: 'WS0739001',
    title: "入力票結果入力 (検査入力)",
    exact: false
  },
  {
    path: "/not-input-check-category/data-update-confirm",
    component: WS0739012_DataUpdateConfirm,
    displayId: 'WS0739012',
    title: "データ更新確認",
    exact: false
  },
  {
    path: "/not-input-check-category/not-input-check-maintain",
    component: WS0745001_NotInputCheckMaintain,
    displayId: 'WS0745001',
    title: "未入力チェック保守",
    exact: false
  },
  {
    path: "/not-input-check-category/pattern-change",
    component: WS0745022_PatternChange,
    displayId: 'WS0745022',
    title: "パターン変更",
    exact: false
  },
  {
    path: "/not-input-check-category/print-instruction",
    component: WS0802001_PrintInstruction,
    displayId: 'WS0802001',
    title: "印刷指示",
    exact: false
  },
  {
    path: "/not-input-check-category/history-setting",
    component: WS0804032_HistorySetting,
    displayId: 'WS0804032',
    title: "履歴設定",
    exact: false
  },
  {
    path: "/not-input-check-category/course-all",
    component: WS0804062_CourseAll,
    displayId: 'WS0804062',
    title: "コース(全体)",
    exact: false
  },
  {
    path: "/not-input-check-category/query-v4-mj-print-patameta-mem",
    component: WS0804074_QueryV4MjPrintPatametaMem,
    displayId: 'WS0804074',
    title: "照会 - V4-[MJ]印刷パタメータ[MEM]",
    exact: false
  },
  {
    path: "/not-input-check-category/query-name-of-disease",
    component: WS2320001_QueryNameOfDisease,
    displayId: 'WS2320001',
    title: "照会:病名",
    exact: false
  },
  {
    path: "/not-input-check-category/overall-result-display-input",
    component: WS2637001_OverallResultDisplayInput,
    displayId: 'WS2637001',
    title: "総合結果表示/入力",
    exact: false
  },
  {
    path: "/not-input-check-category/data-update-confirm",
    component: WS2637019_DataUpdateConfirm,
    displayId: 'WS2637019',
    title: "データ更新確認",
    exact: false
  },
  {
    path: "/not-input-check-category/character-string-search",
    component: WS2637031_CharacterStringSearch,
    displayId: 'WS2637031',
    title: "文字列検索",
    exact: false
  },
  {
    path: "/not-input-check-category/designated-inspect-visits-history",
    component: WS2637036_DesignatedInspectVisitsHistory,
    displayId: 'WS2637036',
    title: "指定検査受診履歴",
    exact: false
  },
  {
    path: "/not-input-check-category/guide-matter-content",
    component: WS2637041_GuideMatterContent,
    displayId: 'WS2637041',
    title: "指導事項内容",
    exact: false
  },
  {
    path: "/not-input-check-category/type-select",
    component: WS2637046_TypeSelect,
    displayId: 'WS2637046',
    title: "種別選択",
    exact: false
  },
  {
    path: "/not-input-check-category/notes-input",
    component: WS2637054_NotesInput,
    displayId: 'WS2637054',
    title: "注意事項入力",
    exact: false
  },
  {
    path: "/not-input-check-category/previous-guide-matter-inquiry",
    component: WS2641030_PreviousGuideMatterInquiry,
    displayId: 'WS2641030',
    title: "前回指導事項照会",
    exact: false
  },
  {
    path: "/not-input-check-category/specified-value-confirm",
    component: WS2644008_SpecifiedValueConfirm,
    displayId: 'WS2644008',
    title: "規定値確認",
    exact: false
  },
  {
    path: "/not-input-check-category/coupled-print-instruction",
    component: WS3020036_CoupledPrintInstruction,
    displayId: 'WS3020036',
    title: "結合印刷指示",
    exact: false
  },
  {
    path: "/not-input-check-category/style-query",
    component: WS3020054_StyleQuery,
    displayId: 'WS3020054',
    title: "様式照会",
    exact: false
  },
  {
    path: "/examinee-list/examinee-list",
    component: WS2635001_ExamineeList,
    displayId: 'WS2635001',
    title: "受診者一覧",
    exact: false
  },
  {
    path: "/examinee-list/auto-judge",
    component: WS2635011_AutoJudge,
    displayId: 'WS2635011',
    title: "自動判定",
    exact: false
  },
  {
    path: "/examinee-list/auto-judge-screen",
    component: WS0723001_AutoJudgeScreen,
    displayId: 'WS0723001',
    title: "自動判定：画面",
    exact: false
  },
  {
    path: "/collect-judge/collect-judge",
    component: WS0817001_CollectJudge,
    displayId: 'WS0817001',
    title: "一括判定",
    exact: false
  },
  {
    path: "/collect-judge/verification",
    component: WS0817008_Verification,
    displayId: 'WS0817008',
    title: "確認",
    exact: false
  },
  {
    path: "/collect-judge/query-examinee",
    component: WS0180001_QueryExaminee,
    displayId: 'WS0180001',
    title: "受診者",
    exact: false
  },
  {
    path: "/collect-judge/debug-screen",
    component: WS1337008_DebugScreen,
    displayId: 'WS1337008',
    title: "デバッグ画面",
    exact: false
  },
  {
    path: "/progress-setting/progress-set",
    component: WS0256001_ProgressSet,
    displayId: 'WS0256001',
    title: "進捗状況設定",
    exact: false
  },
  {
    path: "/progress-setting/confirm-check-yes",
    component: WS0061002_ConfirmCheckYes,
    displayId: 'WS0061002',
    title: "確認[チェック有]",
    exact: false
  },
  {
    path: "/progress-setting/confirm-check-yes-yes-no",
    component: WS0061003_ConfirmCheckYesYesNo,
    displayId: 'WS0061003',
    title: "確認[チェック有]はい・いいえ",
    exact: false
  },
  {
    path: "/progress-setting/check-yes-no-yes",
    component: WS0061009_CheckYesNoYes,
    displayId: 'WS0061009',
    title: "確認[はい・いいえ]はい",
    exact: false
  },
  {
    path: "/progress-setting/check-yes",
    component: WS0061012_CheckYes,
    displayId: 'WS0061012',
    title: "確認[はい]",
    exact: false
  },
  {
    path: "/progress-setting/check-yes-no-no",
    component: WS0061015_CheckYesNoNo,
    displayId: 'WS0061015',
    title: "確認[はい・いいえ]いいえ",
    exact: false
  },
  {
    path: "/progress-setting/visits-key-info-setting",
    component: WS0535001_VisitsKeyInfoSetting,
    displayId: 'WS0535001',
    title: "受診キー情報設定",
    exact: false
  },
  {
    path: "/progress-setting/key-item-inquiry",
    component: WS0536001_KeyItemInquiry,
    displayId: 'WS0536001',
    title: "キー項目照会",
    exact: false
  },
  {
    path: "/progress-setting/calendar-sun-sub",
    component: WS2576004_CalendarSunSub,
    displayId: 'WS2576004',
    title: "カレンダー[日]",
    exact: false
  },
  {
    path: "/progress-setting/consult-inquiry-sub",
    component: WS2583001_ConsultInquirySub,
    displayId: 'WS2583001',
    title: "受診照会",
    exact: false
  },
  {
    path: "/progress-setting/consult-history-sub",
    component: WS2586020_ConsultHistorySub,
    displayId: 'WS2586020',
    title: "受診履歴",
    exact: false
  },
  {
    path: "/progress-setting/billing-inquiry-sub",
    component: WS2593056_BillingInquirySub,
    displayId: 'WS2593056',
    title: "請求照会",
    exact: false
  },
  {
    path: "/dispatch-manage/dispatch-manage",
    component: WS2977001_DispatchManage,
    displayId: 'WS2977001',
    title: "発送管理",
    exact: false
  },
  {
    path: "/dispatch-manage/reserves-select-screen",
    component: WS2977026_ReservesSelectScreen,
    displayId: 'WS2977026',
    title: "予約選択画面",
    exact: false
  },
  {
    path: "/print-param-maintain/print-param-maintain",
    component: WS0855001_PrintParamMaintain,
    displayId: 'WS0855001',
    title: "印刷パラメータ保守",
    exact: false
  },
  {
    path: "/print-param-maintain/print-param-input",
    component: WS0855009_PrintParamInput,
    displayId: 'WS0855009',
    title: "印刷ﾊﾟﾗﾒｰﾀ入力",
    exact: false
  },
  {
    path: "/print-param-maintain/screen-confirm",
    component: WS0855017_ScreenConfirm,
    displayId: 'WS0855017',
    title: "画面確認",
    exact: false
  },
  {
    path: "/print-param-maintain/character-string-search",
    component: WS0855021_CharacterStringSearch,
    displayId: 'WS0855021',
    title: "文字列検索",
    exact: false
  },
  {
    path: "/print-param-maintain/recurrence-number",
    component: WS0855023_RecurrenceNumber,
    displayId: 'WS0855023',
    title: "再発番",
    exact: false
  },
  {
    path: "/print-param-maintain/style-copy",
    component: WS0855028_StyleCopy,
    displayId: 'WS0855028',
    title: "様式複写",
    exact: false
  },
  {
    path: "/print-param-maintain/category-search-query-single",
    component: WS0267001_CategorySearchQuerySingle,
    displayId: 'WS0267001',
    title: "カテゴリ検索・照会(単体)",
    exact: false
  },
  {
    path: "/print-param-maintain/style-specific-inspect-inquiry",
    component: WS0282001_StyleSpecificInspectInquiry,
    displayId: 'WS0282001',
    title: "様式別検査照会",
    exact: false
  },
  {
    path: "/print-param-maintain/print-param-query",
    component: WS0282019_PrintParamQuery,
    displayId: 'WS0282019',
    title: "印刷パラメータ照会",
    exact: false
  },
  {
    path: "/print-param-maintain/style-query",
    component: WS0282020_StyleQuery,
    displayId: 'WS0282020',
    title: "様式照会",
    exact: false
  },
  {
    path: "/print-param-maintain/inspect-inquiry",
    component: WS0282021_InspectInquiry,
    displayId: 'WS0282021',
    title: "検査照会",
    exact: false
  },
  {
    path: "/print-param-maintain/finding-inquiry",
    component: WS0282022_FindingInquiry,
    displayId: 'WS0282022',
    title: "所見照会",
    exact: false
  },
  {
    path: "/print-param-maintain/category-query",
    component: WS0282023_CategoryQuery,
    displayId: 'WS0282023',
    title: "カテゴリ照会",
    exact: false
  },
  {
    path: "/print-param-maintain/teaching-matter-inquiry",
    component: WS0282024_TeachingMatterInquiry,
    displayId: 'WS0282024',
    title: "指導事項照会",
    exact: false
  },
  {
    path: "/print-param-maintain/param-prompted-query-sub",
    component: WS0397001_ParamPromptedQuerySub,
    displayId: 'WS0397001',
    title: "ﾊﾟﾗﾒｰﾀ指示照会 SUB",
    exact: false
  },
  {
    path: "/print-param-maintain/instruction-division-maintain",
    component: WS0397005_InstructionDivisionMaintain,
    displayId: 'WS0397005',
    title: "指示区分保守",
    exact: false
  },
  {
    path: "/print-param-maintain/param-indication-item-display-sub",
    component: WS0398001_ParamIndicationItemDisplaySub,
    displayId: 'WS0398001',
    title: "ﾊﾟﾗﾒｰﾀ指示明細照会 SUB",
    exact: false
  },
  {
    path: "/print-param-maintain/x-item-maintain",
    component: WS0398003_XItemMaintain,
    displayId: 'WS0398003',
    title: "x項目保守",
    exact: false
  },
  {
    path: "/print-param-maintain/master-history-select",
    component: WS0406003_MasterHistorySelect,
    displayId: 'WS0406003',
    title: "マスタ履歴選択",
    exact: false
  },
  {
    path: "/print-param-maintain/print-param",
    component: WS0406004_PrintParam,
    displayId: 'WS0406004',
    title: "印刷パラメータ",
    exact: false
  },
  {
    path: "/print-param-maintain/printer-design-screen-result-tbl",
    component: WS0434016_PrinterDesignScreenResultTbl,
    displayId: 'WS0434016',
    title: "ﾌﾟﾘﾝﾀｰ指定画面[結果表]",
    exact: false
  },
  {
    path: "/print-param-maintain/printer-design-screen-reserve-related",
    component: WS0434022_PrinterDesignScreenReserveRelated,
    displayId: 'WS0434022',
    title: "ﾌﾟﾘﾝﾀｰ指定画面[予約関連]",
    exact: false
  },
  {
    path: "/print-param-maintain/business-unit",
    component: WS0446003_BusinessUnit,
    displayId: 'WS0446003',
    title: "事業所単位",
    exact: false
  },
  {
    path: "/print-param-maintain/course-unit",
    component: WS0446004_CourseUnit,
    displayId: 'WS0446004',
    title: "コース単位",
    exact: false
  },
  {
    path: "/print-param-maintain/style-unit",
    component: WS0446005_StyleUnit,
    displayId: 'WS0446005',
    title: "様式単位",
    exact: false
  },
  {
    path: "/print-param-maintain/stard-style-register",
    component: WS0446006_StardStyleRegister,
    displayId: 'WS0446006',
    title: "標準様式登録",
    exact: false
  },
  {
    path: "/print-param-maintain/old-item",
    component: WS0857001_OldItem,
    displayId: 'WS0857001',
    title: "旧明細",
    exact: false
  },
  {
    path: "/print-param-maintain/print-param-input-output",
    component: WS0863001_PrintParamInputOutput,
    displayId: 'WS0863001',
    title: "印刷パラメータ入出力",
    exact: false
  },
  {
    path: "/print-param-maintain/style-query-output",
    component: WS0863006_StyleQuery,
    displayId: 'WS0863006',
    title: "様式照会",
    exact: false
  },
  {
    path: "/print-param-maintain/uptake-file-list",
    component: WS0863008_UptakeFileList,
    displayId: 'WS0863008',
    title: "取り込みファイル一覧",
    exact: false
  },
  {
    path: "/print-param-maintain/use-inspect-inquiry",
    component: WS0865001_UseInspectInquiry,
    displayId: 'WS0865001',
    title: "使用検査照会",
    exact: false
  },
  {
    path: "/print-param-maintain/format-select",
    component: WS2603003_FormatSelect,
    displayId: 'WS2603003',
    title: "書式選択",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/result-tbl-batch-create",
    component: WS0810001_ResultTblBatchCreate,
    displayId: 'WS0810001',
    title: "結果表一括作成",
    exact: false
  },
  {
    path: "/create-result-tbl/create-result-tbl",
    component: WS0807001_CreateAResultTable,
    displayId: 'WS080700',
    title: "結果表一括作成（帳票別）",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/print-sub",
    component: WS0810025_PrintSub,
    displayId: 'WS0810025',
    title: "印刷",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/form-inquiry",
    component: WS0810036_FormInquiry,
    displayId: 'WS0810036',
    title: "帳票照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/course-specific-style-setting-extend",
    component: WS0457001_CourseSpecificStyleSettingExtend,
    displayId: 'WS0457001',
    title: "コース別様式設定[拡張]",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/style-setting_WS0457004",
    component: WS0457004_StyleSetting,
    displayId: 'WS0457004',
    title: "様式設定",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/office-inquiry",
    component: WS0457008_OfficeInquiry,
    displayId: 'WS0457008',
    title: "事業所照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/print-style-inquiry",
    component: WS0457009_PrintStyleInquiry,
    displayId: 'WS0457009',
    title: "印刷様式照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/pattan-copy",
    component: WS0457010_PattanCopy,
    displayId: 'WS0457010',
    title: "パターン複写",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/course-based-determine-by-style-setting",
    component: WS0458001_CourseBasedDetermineByStyleSetting,
    displayId: 'WS0458001',
    title: "コース別判定別様式設定",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/style-setting",
    component: WS0458004_StyleSetting,
    displayId: 'WS0458004',
    title: "様式設定",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/office-inquiry_WS0458008",
    component: WS0458008_OfficeInquiry,
    displayId: 'WS0458008',
    title: "事業所照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/print-style-inquiry_WS0458009",
    component: WS0458009_PrintStyleInquiry,
    displayId: 'WS0458009',
    title: "印刷様式照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/pattan-copy_WS0458010",
    component: WS0458010_PattanCopy,
    displayId: 'WS0458010',
    title: "パターン複写",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/setup-result-tbl-collect-output",
    component: WS1527001_SetupResultTblCollectOutput,
    displayId: 'WS1527001',
    title: "設定[結果表一括出力]",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/classify-inquiry",
    component: WS1527008_ClassifyInquiry,
    displayId: 'WS1527008',
    title: "区分照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/style-setting_WS1527012",
    component: WS1527012_StyleSetting,
    displayId: 'WS1527012',
    title: "様式設定",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/office-inquiry-22",
    component: WS1527022_OfficeInquiry,
    displayId: 'WS1527022',
    title: "事業所照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/office-inquiry-16",
    component: WS1527016_OfficeInquiry,
    displayId: 'WS1527016',
    title: "事業所照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/style-setting-hira",
    component: WS1527018_StyleSettingHira,
    displayId: 'WS1527018',
    title: "様式設定[HIRA]",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/print-style-inquiry-23",
    component: WS1527023_PrintStyleInquiry,
    displayId: 'WS1527023',
    title: "印刷様式照会",
    exact: false
  },
  {
    path: "/results-tbl-collect-output/pattan-copy_WS1527024",
    component: WS1527024_PattanCopy,
    displayId: 'WS1527024',
    title: "パターン複写",
    exact: false
  },
  {
    path: "/communication-roster-output/communication-roster-output",
    component: WS0811001_CommunicationRosterOutput,
    displayId: 'WS0811001',
    title: "連名簿出力",
    exact: false
  },
  {
    path: "/communication-roster-output/style-query",
    component: WS0811004_StyleQuery,
    displayId: 'WS0811004',
    title: "様式照会",
    exact: false
  },
  {
    path: "/binding-mode-setting/binding-mode-setting",
    component: WS3022038_BindingModeSetting,
    displayId: 'WS3022038',
    title: "結合様式設定",
    exact: false
  },
  {
    path: "/binding-mode-setting/condition-express-set",
    component: WS0494004_ConditionExpressSet,
    displayId: 'WS0494004',
    title: "条件式設定",
    exact: false
  },
  {
    path: "/binding-mode-setting/header-input",
    component: WS0494006_HeaderInput,
    displayId: 'WS0494006',
    title: "ヘッダ入力",
    exact: false
  },
  {
    path: "/binding-mode-setting/data-input",
    component: WS0494007_DataInput,
    displayId: 'WS0494007',
    title: "データ入力",
    exact: false
  },
  {
    path: "/binding-mode-setting/condition-info-maintain",
    component: WS0494001_ConditionInfoMaintain,
    displayId: 'WS0494001',
    title: "条件式情報保守",
    exact: false
  },
  {
    path: "/binding-mode-setting/condition-express-info-inquiry",
    component: WS0495001_ConditionExpressInfoInquiry,
    displayId: 'WS0495001',
    title: "条件式情報照会",
    exact: false
  },
  {
    path: "/course-specific-stard-style-setting/course-specific-stard-style-setting",
    component: WS0445001_CourseSpecificStardStyleSetting,
    displayId: 'WS0445001',
    title: "コース別標準様式設定",
    exact: false
  },
  {
    path: "/course-specific-stard-style-setting/office-inquiry",
    component: WS0445006_OfficeInquiry,
    displayId: 'WS0445006',
    title: "事業所照会",
    exact: false
  },
  {
    path: "/course-specific-stard-style-setting/print-style-inquiry",
    component: WS0445007_PrintStyleInquiry,
    displayId: 'WS0445007',
    title: "印刷様式照会",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/csv-create-param-maintain",
    component: WS0460001_CsvCreateParamMaintain,
    displayId: 'WS0460001',
    title: "CSV作成パラメータ保守",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/param-input",
    component: WS0460005_ParamInput,
    displayId: 'WS0460005',
    title: "パラメータ入力",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/inquiry",
    component: WS0460006_Inquiry,
    displayId: 'WS0460006',
    title: "照会",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/copy",
    component: WS0460008_Copy,
    displayId: 'WS0460008',
    title: "複写",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/user-param-query",
    component: WS0451001_UserParamQuery,
    displayId: 'WS0451001',
    title: "ユーザーパラメータ照会",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/convert-tbl-sub-inspect",
    component: WS0454003_ConvertTblSubInspect,
    displayId: 'WS0454003',
    title: "変換ﾃｰﾌﾞﾙSUB[検査]",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/convert-tbl-sub-all",
    component: WS0454004_ConvertTblSubAll,
    displayId: 'WS0454004',
    title: "変換ﾃｰﾌﾞﾙSUB[全て]",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/copy_WS0454005",
    component: WS0454005_Copy,
    displayId: 'WS0454005',
    title: "複写",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/character-string-search",
    component: WS0461004_CharacterStringSearch,
    displayId: 'WS0461004',
    title: "文字列検索",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/user-param-input0",
    component: WS0461005_UserParamInput0,
    displayId: 'WS0461005',
    title: "ユーザーパラメータ入力0",
    exact: false
  },
  {
    path: "/csv-create-param-maintain/user-param-input1",
    component: WS0461006_UserParamInput1,
    displayId: 'WS0461006',
    title: "ユーザーパラメータ入力1",
    exact: false
  },
  {
    path: "/romoto-article52/romoto-article52",
    component: WS1001001_RomotoArticle52,
    displayId: 'WS1001001',
    title: "労基５２条",
    exact: false
  },
  {
    path: "/romoto-article52/breakdown-inquiry",
    component: WS1001033_BreakdownInquiry,
    displayId: 'WS1001033',
    title: "内訳照会",
    exact: false
  },
  {
    path: "/romoto-article52/select-breakdown",
    component: WS1001034_SelectBreakdown,
    displayId: 'WS1001034',
    title: "内訳選択",
    exact: false
  },
  {
    path: "/romoto-article52/setting-romoto-article52",
    component: WS1004001_SettingRomotoArticle52,
    displayId: 'WS1004001',
    title: "設定[労基５２条]",
    exact: false
  },
  {
    path: "/romoto-article52/judge-select",
    component: WS1004007_JudgeSelect,
    displayId: 'WS1004007',
    title: "判定選択",
    exact: false
  },
  {
    path: "/romoto-article52/affiliation-select-sub",
    component: WS2787004_AffiliationSelectSub,
    displayId: 'WS2787004',
    title: "所属選択",
    exact: false
  },
  {
    path: "/medical-exam-data-output-csv/medical-exam-data-output-csv",
    component: WS0784001_MedicalExamDataOutputCsv,
    displayId: 'WS0784001',
    title: "健診データ出力[CSV]",
    exact: false
  },
  {
    path: "/medical-exam-data-output-csv/form-inquiry",
    component: WS0784003_FormInquiry,
    displayId: 'WS0784003',
    title: "帳票照会",
    exact: false
  },
  {
    path: "/medical-exam-data-output-csv/file-name-input-uav02",
    component: WS0784004_FileNameInputUav02,
    displayId: 'WS0784004',
    title: "ファイル名入力（UAV02）",
    exact: false
  },
  {
    path: "/medical-exam-data-output-csv/insurance-symbol-inquiry",
    component: WS0784005_InsuranceSymbolInquiry,
    displayId: 'WS0784005',
    title: "保険記号照会",
    exact: false
  },
  {
    path: "/medical-exam-data-output-csv/facility-type-query",
    component: WS0784006_FacilityTypeQuery,
    displayId: 'WS0784006',
    title: "施設区分照会",
    exact: false
  },
  {
    path: "/receipt-pre-issue20/receipt-pre-issue",
    component: WS1915001_ReceiptPreIssue,
    displayId: 'WS1915001',
    title: "領収書事前発行",
    exact: false
  },
  {
    path: "/receipt-pre-issue20/receipt-issue-online",
    component: WS0946006_ReceiptIssueOnline,
    displayId: 'WS0946006',
    title: "領収書発行（オンライン）",
    exact: false
  },
  {
    path: "/billing-manage-ledger-instruction/billing-manage-ledger-instruction",
    component: WS2354001_BillingManageLedgerInstruction,
    displayId: 'WS2354001',
    title: "請求管理台帳指示",
    exact: false
  },
  {
    path: "/billing-integration/invoice",
    component: WS0947001_Invoice,
    displayId: 'WS0947001',
    title: "請求書発行",
    exact: false
  },
  {
    path: "/billing-integration/billing-integration",
    component: WS0961001_BillingIntegration,
    displayId: 'WS0961001',
    title: "請求統合",
    exact: false
  },
  {
    path: "/billing-integration/tamper-proof-release",
    component: WS0961004_TamperProofRelease,
    displayId: 'WS0961004',
    title: "改竄防止解除",
    exact: false
  },
  {
    path: "/billing-integration/billing-date-change",
    component: WS0961005_BillingDateChange,
    displayId: 'WS0961005',
    title: "請求年月変更",
    exact: false
  },
  {
    path: "/billing-inquiry/billing-inquiry",
    component: WS0975001_BillingInquiry,
    displayId: 'WS0975001',
    title: "請求問合せ",
    exact: false
  },
  {
    path: "/billing-inquiry/display-item",
    component: WS0975007_DisplayItem,
    displayId: 'WS0975007',
    title: "表示項目",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/deposit-withdrawal-inquiry",
    component: WS0977001_DepositWithdrawalInquiry,
    displayId: 'WS0977001',
    title: "入出金問合せ",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/invoice-maintain",
    component: WS0963001_InvoiceMaintain,
    displayId: 'WS0963001',
    title: "請求書保守",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/tamper-proof-release",
    component: WS0963004_TamperProofRelease,
    displayId: 'WS0963004',
    title: "改竄防止解除",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/itemized",
    component: WS0964001_Itemized,
    displayId: 'WS0964001',
    title: "明細内訳",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/select-breakdown",
    component: WS0964020_SelectBreakdown,
    displayId: 'WS0964020',
    title: "内訳選択",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/billing-data-copy",
    component: WS0966001_BillingDataCopy,
    displayId: 'WS0966001',
    title: "請求データ複写",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/billing-name-query-sub",
    component: WS0967003_BillingNameQuerySub,
    displayId: 'WS0967003',
    title: "請求名称照会",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/according-to-various-name-definition-tbl",
    component: WS0968001_AccordingToVariousNameDefinitionTbl,
    displayId: 'WS0968001',
    title: "請求各種名定義テーブル",
    exact: false
  },
  {
    path: "/deposit-withdrawal-inquiry/item-display",
    component: WS0971006_ItemDisplay,
    displayId: 'WS0971006',
    title: "明細表示",
    exact: false
  },
  {
    path: "/deposit-withdrawal-list-output-instruction/deposit-withdrawal-list-output-instruction",
    component: WS0978001_DepositWithdrawalListOutputInstruction,
    displayId: 'WS0978001',
    title: "入出金一覧表出力指示",
    exact: false
  },
  {
    path: "/payment-list-output/payment-list-output",
    component: WS0981001_PaymentListOutput,
    displayId: 'WS0981001',
    title: "入金一覧出力",
    exact: false
  },
  {
    path: "/accounts-receivable-list-output-instruction/accounts-receivable-list-output-instruction",
    component: WS0985001_AccountsReceivableListOutputInstruction,
    displayId: 'WS0985001',
    title: "未収金一覧表出力指示",
    exact: false
  },
  {
    path: "/invoice/billing-aggregation-process",
    component: WS0948001_BillingAggregationProcess,
    displayId: 'WS0948001',
    title: "請求集計処理",
    exact: false
  },
  {
    path: "/invoice/counter-payment",
    component: WS0952001_CounterPayment,
    displayId: 'WS0952001',
    title: "窓口入金",
    exact: false
  },
  {
    path: "/invoice/payment-history-display-single",
    component: WS0952007_PaymentHistoryDisplaySingle,
    displayId: 'WS0952007',
    title: "入金履歴表示[個別]",
    exact: false
  },
  {
    path: "/invoice/payment-history-display-all",
    component: WS0952010_PaymentHistoryDisplayAll,
    displayId: 'WS0952010',
    title: "入金履歴表示[全体]",
    exact: false
  },
  {
    path: "/invoice/invoice",
    component: WS2626001_Invoice,
    displayId: 'WS2626001',
    title: "請求書発行",
    exact: false
  },
  {
    path: "/invoice/integration-released",
    component: WS2626028_IntegrationReleased,
    displayId: 'WS2626028',
    title: "統合解除",
    exact: false
  },
  {
    path: "/invoice/multiple-select-office",
    component: WS2628002_MultipleSelectOffice,
    displayId: 'WS2628002',
    title: "事業所複数選択",
    exact: false
  },
  {
    path: "/invoice/insurer-multiple-select",
    component: WS2628003_InsurerMultipleSelect,
    displayId: 'WS2628003',
    title: "保険者複数選択",
    exact: false
  },
  {
    path: "/organizations-payment/organizations-payment",
    component: WS2630001_OrganizationsPayment,
    displayId: 'WS2630001',
    title: "団体入金",
    exact: false
  },
  {
    path: "/organizations-payment/receipt-maintain-issue",
    component: WS0958001_ReceiptMaintainIssue,
    displayId: 'WS0958001',
    title: "領収書保守/発行",
    exact: false
  },
  {
    path: "/organizations-payment/payment-process-sub",
    component: WS2631001_PaymentProcessSub,
    displayId: 'WS2631001',
    title: "入金処理",
    exact: false
  },
  {
    path: "/organizations-payment/dispensing-process-sub",
    component: WS2632001_DispensingProcessSub,
    displayId: 'WS2632001',
    title: "出金処理",
    exact: false
  },
  {
    path: "/introduce-letter-extract/introduce-letter-extract",
    component: WS0892001_IntroduceLetterExtract,
    displayId: 'WS0892001',
    title: "紹介状抽出",
    exact: false
  },
  {
    path: "/introduce-letter-extract/introduce-letter-subject-cmt-inquiry",
    component: WS0888026_IntroduceLetterSubjectCmtInquiry,
    displayId: 'WS0888026',
    title: "紹介状対象コメント照会",
    exact: false
  },
  {
    path: "/introduce-letter-extract/introduce-letter-extract-maintain",
    component: WS0898001_IntroduceLetterExtractMaintain,
    displayId: 'WS0898001',
    title: "紹介状抽出保守",
    exact: false
  },
  {
    path: "/introduce-letter-extract/introduce-letter-inquiry",
    component: WS0898003_IntroduceLetterInquiry,
    displayId: 'WS0898003',
    title: "紹介状照会",
    exact: false
  },
  {
    path: "/introduce-letter-extract/inspect-input",
    component: WS0898028_InspectInput,
    displayId: 'WS0898028',
    title: "検査入力",
    exact: false
  },
  {
    path: "/introduce-letter-extract/display-consult-history",
    component: WS0900001_DisplayConsultHistory,
    displayId: 'WS0900001',
    title: "SK0004002:照会:受診履歴",
    exact: false
  },
  {
    path: "/introduce-letter-extract/inspect-inquiry",
    component: WS0903002_InspectInquiry,
    displayId: 'WS0903002',
    title: "照会:検査照会",
    exact: false
  },
  {
    path: "/introduce-letter-extract/introduce-letter-return-input",
    component: WS0936002_IntroduceLetterReturnInput,
    displayId: 'WS0936002',
    title: "紹介状返送入力",
    exact: false
  },
  {
    path: "/introduce-letter-extract/return-info-input-sub",
    component: WS0936006_ReturnInfoInputSub,
    displayId: 'WS0936006',
    title: "返送情報入力",
    exact: false
  },
  {
    path: "/introduce-letter-extract/format-select",
    component: WS2589011_FormatSelect,
    displayId: 'WS2589011',
    title: "書式選択",
    exact: false
  },
  {
    path: "/introduce-letter-issued-main/introduce-letter-issued-main",
    component: WS0904001_IntroduceLetterIssuedMain,
    displayId: 'WS0904001',
    title: "紹介状発行[Main]",
    exact: false
  },
  {
    path: "/introduce-letter-issued-main/printer-confirm",
    component: WS0433001_PrinterConfirm,
    displayId: 'WS0433001',
    title: "プリンター確認",
    exact: false
  },
  {
    path: "/introduce-letter-return-info-input/introduce-letter-return-info-input",
    component: WS2804001_IntroduceLetterReturnInfoInput,
    displayId: 'WS2804001',
    title: "紹介状返送情報入力",
    exact: false
  },
  {
    path: "/introduce-letter-return-info-input/return-info-input",
    component: WS2804013_ReturnInfoInput,
    displayId: 'WS2804013',
    title: "返送情報入力",
    exact: false
  },
  {
    path: "/introduce-letter-return-info-input/ask-input",
    component: WS2804019_AskInput,
    displayId: 'WS2804019',
    title: "おたずね入力",
    exact: false
  },
  {
    path: "/ask-issued/ask-issued",
    component: WS0915001_AskIssued,
    displayId: 'WS0915001',
    title: "おたずね発行",
    exact: false
  },
  {
    path: "/ask-issued/introduce-letter-various-master-inquiry",
    component: WS0887001_IntroduceLetterVariousMasterInquiry,
    displayId: 'WS0887001',
    title: "紹介状各種マスタ照会",
    exact: false
  },
  {
    path: "/ask-issued/ask-issue-input",
    component: WS0917005_AskIssueInput,
    displayId: 'WS0917005',
    title: "おたずね発行[入力]",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/introduce-letter-master-maintain",
    component: WS0919001_IntroduceLetterMasterMaintain,
    displayId: 'WS0919001',
    title: "紹介状マスタ保守",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/inspect-item-search-query-multiple-choice",
    component: WS0270001_InspectItemSearchQueryMultipleChoice,
    displayId: 'WS0270001',
    title: "検査項目検索・照会(複数選択)",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/introduce-letter-target-cmt-maintain-amks",
    component: WS0920003_IntroduceLetterTargetCmtMaintainAmks,
    displayId: 'WS0920003',
    title: "紹介状対象コメント保守[AMKS]",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/introduce-letter-target-cmt-maintain",
    component: WS0920008_IntroduceLetterTargetCmtMaintain,
    displayId: 'WS0920008',
    title: "紹介状対象コメント保守",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/copying-process",
    component: WS0920010_CopyingProcess,
    displayId: 'WS0920010',
    title: "複写処理",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/introduce-letter-target-cmt-inspect-maintain",
    component: WS0921003_IntroduceLetterTargetCmtInspectMaintain,
    displayId: 'WS0921003',
    title: "紹介状対象コメント検査保守",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/medical-institutions-master",
    component: WS0922003_MedicalInstitutionsMaster,
    displayId: 'WS0922003',
    title: "医療機関マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/doctor-name-master",
    component: WS0922004_DoctorNameMaster,
    displayId: 'WS0922004',
    title: "ドクター名称マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/inspect-name-master",
    component: WS0922005_InspectNameMaster,
    displayId: 'WS0922005',
    title: "検査名称マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/victim-name-master",
    component: WS0922006_VictimNameMaster,
    displayId: 'WS0922006',
    title: "傷病名称マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/findings-content-master",
    component: WS0922007_FindingsContentMaster,
    displayId: 'WS0922007',
    title: "所見内容マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/treatment-content-master",
    component: WS0922008_TreatmentContentMaster,
    displayId: 'WS0922008',
    title: "治療内容マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/department-master",
    component: WS0922009_DepartmentMaster,
    displayId: 'WS0922009',
    title: "診療科マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/day-of-week-based-determine-physician-master",
    component: WS0922010_DayOfWeekBasedDeterminePhysicianMaster,
    displayId: 'WS0922010',
    title: "曜日別判定医マスタ",
    exact: false
  },
  {
    path: "/introduce-letter-master-maintain/introduce-letter-target-cmt-list",
    component: WS0924001_IntroduceLetterTargetCmtList,
    displayId: 'WS0924001',
    title: "紹介状対象コメント一覧",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/person-require-exam-introduce-letter",
    component: WS0930001_PersonRequireExamIntroduceLetter,
    displayId: 'WS0930001',
    title: "要精検者紹介状発行",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/display-content-change",
    component: WS0930006_DisplayContentChange,
    displayId: 'WS0930006',
    title: "表示内容変更",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/judge-query",
    component: WS0285001_JudgeQuery,
    displayId: 'WS0285001',
    title: "判定照会",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/department-inquiry_WS0927005",
    component: WS0927005_DepartmentInquiry,
    displayId: 'WS0927005',
    title: "診療科照会",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/department-inquiry_WS0927002",
    component: WS0927002_DepartmentInquiry,
    displayId: 'WS0927002',
    title: "診療科照会",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/csv-output-sub",
    component: WS0931001_CsvOutputSub,
    displayId: 'WS0931001',
    title: "CSV出力",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/form-inquiry",
    component: WS0931002_FormInquiry,
    displayId: 'WS0931002',
    title: "帳票照会",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/required-precision-examiner-setting",
    component: WS0932001_RequiredPrecisionExaminerSetting,
    displayId: 'WS0932001',
    title: "要精検者設定",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/inspect-inquiry_WS0932008",
    component: WS0932008_InspectInquiry,
    displayId: 'WS0932008',
    title: "検査照会",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/required-precision-examiner-various-master-setting",
    component: WS0933003_RequiredPrecisionExaminerVariousMasterSetting,
    displayId: 'WS0933003',
    title: "要精検者各種マスタ設定",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/required-precision-examiner-various-master-inquiry",
    component: WS0933004_RequiredPrecisionExaminerVariousMasterInquiry,
    displayId: 'WS0933004',
    title: "要精検者各種マスタ照会",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/required-precision-examiner-follow-up-matter-register",
    component: WS0935001_RequiredPrecisionExaminerFollowUpMatterRegister,
    displayId: 'WS0935001',
    title: "要精検者フォロー事項登録",
    exact: false
  },
  {
    path: "/person-require-exam-introduce-letter/inspect-inquiry_WS0935013",
    component: WS0935013_InspectInquiry,
    displayId: 'WS0935013',
    title: "検査照会",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/radiography-site-findings-maintain",
    component: WS1872001_RadiographySiteFindingsMaintain,
    displayId: 'WS1872001',
    title: "読影部位所見保守",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/site-findings-migration",
    component: WS1872005_SiteFindingsMigration,
    displayId: 'WS1872005',
    title: "部位所見移行",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/radiography-site-maintain",
    component: WS1873001_RadiographySiteMaintain,
    displayId: 'WS1873001',
    title: "読影部位保守",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/site-classify-inquiry_WS1873002",
    component: WS1873002_SiteClassifyInquiry,
    displayId: 'WS1873002',
    title: "部位分類照会",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/site-inquiry",
    component: WS1873003_SiteInquiry,
    displayId: 'WS1873003',
    title: "部位照会",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/radiography-findings-maintain",
    component: WS1874001_RadiographyFindingsMaintain,
    displayId: 'WS1874001',
    title: "読影所見保守",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/site-classify-inquiry",
    component: WS1874002_SiteClassifyInquiry,
    displayId: 'WS1874002',
    title: "部位分類照会",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/findings-classify-inquiry",
    component: WS1874003_FindingsClassifyInquiry,
    displayId: 'WS1874003',
    title: "所見分類照会",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/finding-inquiry",
    component: WS1874004_FindingInquiry,
    displayId: 'WS1874004',
    title: "所見照会",
    exact: false
  },
  {
    path: "/radiography-inspect-maintain/radiography-guide-maintain",
    component: WS1875001_RadiographyGuideMaintain,
    displayId: 'WS1875001',
    title: "読影指導保守",
    exact: false
  },
  {
    path: "/disease-name-master-maintain/disease-name-master-maintain",
    component: WS2303001_DiseaseNameMasterMaintain,
    displayId: 'WS2303001',
    title: "病名マスタ保守",
    exact: false
  },
  {
    path: "/binding-mode-setting/print-style-inquiry",
    component: WS0286001_PrintStyleInquiry,
    displayId: 'WS0286001',
    title: "印刷様式照会",
    exact: false
  },
  {
    path: "/another-inspect-item-setting-category/another-inspect-item-setting-category",
    component: WS2705001_AnotherInspectItemSettingCategory,
    displayId: 'WS2705001',
    title: "カテゴリ別検査項目設定",
    exact: false
  },
  {
    path: "/another-inspect-item-setting-category/pattern-setting-sub",
    component: WS2705075_PatternSettingSub,
    displayId: 'WS2705075',
    title: "パターン設定",
    exact: false
  },
  {
    path: "/another-inspect-item-setting-category/copy-pattern",
    component: WS2705076_CopyPattern,
    displayId: 'WS2705076',
    title: "複写パターン",
    exact: false
  },
  {
    path: "/course-basic-type-setting/course-basic-type-setting",
    component: WS2696036_CourseBasicTypeSetting,
    displayId: 'WS2696036',
    title: "コース基本種別設定",
    exact: false
  },
  {
    path: "/course-basic-type-setting/contract-info-list",
    component: WS2696008_ContractInfoList,
    displayId: 'WS2696008',
    title: "契約情報一覧",
    exact: false
  },
  {
    path: "/course-basic-type-setting/register-modification",
    component: WS2696052_RegisterModification,
    displayId: 'WS2696052',
    title: "登録・修正",
    exact: false
  },
  {
    path: "/category-master-maintain/category-master-maintain",
    component: WS2704001_CategoryMasterMaintain,
    displayId: 'WS2704001',
    title: "カテゴリーマスタ保守",
    exact: false
  },
  {
    path: "/category-master-maintain/site-classify-search-query",
    component: WS0268001_SiteClassifySearchQuery,
    displayId: 'WS0268001',
    title: "部位分類検索・照会",
    exact: false
  },
  {
    path: "/inspect-item-info-maintain/inspect-item-info-maintain",
    component: WS2716001_InspectItemInfoMaintain,
    displayId: 'WS2716001',
    title: "検査項目情報保守",
    exact: false
  },
  {
    path: "/inspect-item-info-maintain/inspect-by-pattern-category-display",
    component: WS2716057_InspectByPatternCategoryDisplay,
    displayId: 'WS2716057',
    title: "検査別パターン・カテゴリ表示",
    exact: false
  },
  {
    path: "/inspect-item-info-maintain/internal-inspect-item-search-query",
    component: WS0253001_InternalInspectItemSearchQuery,
    displayId: 'WS0253001',
    title: "内部検査項目検索・照会",
    exact: false
  },
  {
    path: "/inspect-item-info-maintain/inspect-item1-change-sub",
    component: WS2717011_InspectItem1ChangeSub,
    displayId: 'WS2717011',
    title: "検査項目1件変更",
    exact: false
  },
  {
    path: "/site-findings-master-maintain/site-findings-master-maintain",
    component: WS0356001_SiteFindingsMasterMaintain,
    displayId: 'WS0356001',
    title: "部位所見マスタ保守",
    exact: false
  },
  {
    path: "/site-findings-master-maintain/category-you-are-using",
    component: WS0356005_CategoryYouAreUsing,
    displayId: 'WS0356005',
    title: "使用しているカテゴリ",
    exact: false
  },
  {
    path: "/site-findings-master-maintain/site-findings-copy-sub",
    component: WS0351001_SiteFindingsCopySub,
    displayId: 'WS0351001',
    title: "部位所見複写",
    exact: false
  },
  {
    path: "/exam-cmt-info-maintain/inspect-cmt-info-maintain",
    component: WS0358001_InspectCmtInfoMaintain,
    displayId: 'WS0358001',
    title: "検査コメント情報保守",
    exact: false
  },
  {
    path: "/exam-cmt-info-maintain/use-inspect",
    component: WS0358003_UseInspect,
    displayId: 'WS0358003',
    title: "使用検査",
    exact: false
  },
  {
    path: "/exam-cmt-info-maintain/physician-name-info-maintain",
    component: WS0359001_PhysicianNameInfoMaintain,
    displayId: 'WS0359001',
    title: "医師名情報保守",
    exact: false
  },
  {
    path: "/exam-cmt-info-maintain/inspect-cmt-info-maintain-sub",
    component: WS2712001_InspectCmtInfoMaintainSub,
    displayId: 'WS2712001',
    title: "検査コメント情報保守 SUB",
    exact: false
  },
  {
    path: "/exam-cmt-info-maintain/use-inspect_WS2712016",
    component: WS2712016_UseInspect,
    displayId: 'WS2712016',
    title: "使用検査",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/inspect-item-judge-value-setting",
    component: WS0362001_InspectItemJudgeValueSetting,
    displayId: 'WS0362001',
    title: "検査項目判定値設定",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/caution-guide-notes-search-query",
    component: WS0272001_CautionGuideNotesSearchQuery,
    displayId: 'WS0272001',
    title: "注意・指導事項検索・照会",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/inspect-decision-alone-setting-separately",
    component: WS0363001_InspectDecisionAloneSettingSeparately,
    displayId: 'WS0363001',
    title: "検査判定単体設定[単品]",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/effective-date-select",
    component: WS0363005_EffectiveDateSelect,
    displayId: 'WS0363005',
    title: "適用日選択",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/inspect-decision-alone-setting-merged",
    component: WS0364001_InspectDecisionAloneSettingMerged,
    displayId: 'WS0364001',
    title: "検査判定単体設定[併合]",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/effective-date-select",
    component: WS0364005_EffectiveDateSelect,
    displayId: 'WS0364005',
    title: "適用日選択",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/condition-express-set",
    component: WS0364007_ConditionExpressSet,
    displayId: 'WS0364007',
    title: "条件式設定",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/header-input",
    component: WS0364008_HeaderInput,
    displayId: 'WS0364008',
    title: "ヘッダ入力",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/data-input",
    component: WS0364009_DataInput,
    displayId: 'WS0364009',
    title: "データ入力",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/details-extract",
    component: WS0365001_DetailsExtract,
    displayId: 'WS0365001',
    title: "詳細抽出",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/copy",
    component: WS0367002_Copy,
    displayId: 'WS0367002',
    title: "複写",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/delete",
    component: WS0367003_Delete,
    displayId: 'WS0367003',
    title: "削除",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/use-inspect",
    component: WS0367004_UseInspect,
    displayId: 'WS0367004',
    title: "使用検査",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/copy-category",
    component: WS0367005_CopyCategory,
    displayId: 'WS0367005',
    title: "複写[カテゴリ]",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/delete-category",
    component: WS0367006_DeleteCategory,
    displayId: 'WS0367006',
    title: "削除[カテゴリ]",
    exact: false
  },
  {
    path: "/inspect-item-judge-value-setting/use-inspect-category",
    component: WS0367007_UseInspectCategory,
    displayId: 'WS0367007',
    title: "使用検査[カテゴリ]",
    exact: false
  },
  {
    path: "/inspect-ref-value-setting/inspect-ref-value-setting",
    component: WS0371001_InspectRefValueSetting,
    displayId: 'WS0371001',
    title: "検査基準値設定",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/condition-express-cmt-setting",
    component: WS0482001_ConditionExpressCmtSetting,
    displayId: 'WS0482001',
    title: "条件式コメント設定",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/cmt-select-group",
    component: WS0482007_CmtSelectGroup,
    displayId: 'WS0482007',
    title: "コメント群選択",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/effective-date-specified",
    component: WS0482008_EffectiveDateSpecified,
    displayId: 'WS0482008',
    title: "適用日指定",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/effective-date-select",
    component: WS0482010_EffectiveDateSelect,
    displayId: 'WS0482010',
    title: "適用日選択",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/cmt-content-modification",
    component: WS0482011_CmtContentModification,
    displayId: 'WS0482011',
    title: "コメント内容修正",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/condition-express-cmt-sub",
    component: WS0483001_ConditionExpressCmtSub,
    displayId: 'WS0483001',
    title: "条件式コメント",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/special-condition_WS0483004",
    component: WS0483004_SpecialCondition,
    displayId: 'WS0483004',
    title: "特殊条件",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/condition-express-confirm-sub",
    component: WS0484001_ConditionExpressConfirmSub,
    displayId: 'WS0484001',
    title: "条件式確認",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/special-condition",
    component: WS0484004_SpecialCondition,
    displayId: 'WS0484004',
    title: "特殊条件",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/cmt-group-confirm-sub",
    component: WS0485001_CmtGroupConfirmSub,
    displayId: 'WS0485001',
    title: "コメント群確認",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/condition-correct-sub",
    component: WS0486001_ConditionCorrectSub,
    displayId: 'WS0486001',
    title: "条件式訂正",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/redisplay",
    component: WS0486002_Redisplay,
    displayId: 'WS0486002',
    title: "再表示",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/condition-express-add-sub",
    component: WS0487001_ConditionExpressAddSub,
    displayId: 'WS0487001',
    title: "条件式追加",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/judge-highlow",
    component: WS0487007_JudgeHighlow,
    displayId: 'WS0487007',
    title: "判定のHighLow",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/header-input",
    component: WS0487008_HeaderInput,
    displayId: 'WS0487008',
    title: "ヘッダ入力",
    exact: false
  },
  {
    path: "/condition-express-cmt-setting/condition-copying-screen",
    component: WS0493001_ConditionCopyingScreen,
    displayId: 'WS0493001',
    title: "条件式複写画面",
    exact: false
  },
  {
    path: "/caution-guide-matter-cmt-maintain/caution-guide-matter-cmt-maintain",
    component: WS2701001_CautionGuideMatterCmtMaintain,
    displayId: 'WS2701001',
    title: "注意・指導事項コメント保守",
    exact: false
  },
  {
    path: "/caution-guide-matter-cmt-maintain/cmt-register-copy",
    component: WS2701025_CmtRegisterCopy,
    displayId: 'WS2701025',
    title: "コメント登録・複写",
    exact: false
  },
  {
    path: "/caution-guide-matter-cmt-maintain/category-search-query-multiple",
    component: WS0266001_CategorySearchQueryMultiple,
    displayId: 'WS0266001',
    title: "カテゴリ検索・照会(複数)",
    exact: false
  },
  {
    path: "/caution-guide-matter-cmt-maintain/another-guide-cmt-maintain-category",
    component: WS0376001_AnotherGuideCmtMaintainCategory,
    displayId: 'WS0376001',
    title: "カテゴリ別指導コメント保守",
    exact: false
  },
  {
    path: "/determine-level-modify/determine-level-modify-master-coercive",
    component: WS0447001_DetermineLevelModifyMasterCoercive,
    displayId: 'WS0447001',
    title: "判定レベル変換マスタ保",
    exact: false
  },
  {
    path: "/determine-level-modify/color-sample",
    component: WS0447011_ColorSample,
    displayId: 'WS0447011',
    title: "色サンプル",
    exact: false
  },
  {
    path: "/determine-level-modify/determine-level-bulk-modify",
    component: WS0448001_DetermineLevelBulkModify,
    displayId: 'WS0448001',
    title: "判定レベル一括修正",
    exact: false
  },
  {
    path: "/determine-level-modify/copy-screen",
    component: WS0448009_CopyScreen,
    displayId: 'WS0448009',
    title: "複写画面",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/normal-value-setting",
    component: WS2713014_NormalValueSetting,
    displayId: 'WS2713014',
    title: "正常値設定",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/normal-value-auto-set",
    component: WS2713035_NormalValueAutoSet,
    displayId: 'WS2713035',
    title: "正常値自動設定",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/pattern-list",
    component: WS2713042_PatternList,
    displayId: 'WS2713042',
    title: "パターン一覧",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/character-string-search",
    component: WS2713067_CharacterStringSearch,
    displayId: 'WS2713067',
    title: "文字列検索",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/format-copy",
    component: WS2713087_FormatCopy,
    displayId: 'WS2713087',
    title: "フォーマット複写",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/query-site-info",
    component: WS0178001_QuerySiteInfo,
    displayId: 'WS0178001',
    title: "照会:部位情報",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/inquiry-finding-info",
    component: WS0179001_InquiryFindingInfo,
    displayId: 'WS0179001',
    title: "照会:所見情報",
    exact: false
  },
  {
    path: "/normal-value-setting-maintain/site-findings-maintain-sub",
    component: WS0350001_SiteFindingsMaintainSub,
    displayId: 'WS0350001',
    title: "部位所見保守",
    exact: false
  },
  {
    path: "/inspect-value-calculate-auto-maintain/inspect-value-calculate-auto-maintain",
    component: WS2699142_InspectValueCalculateAutoMaintain,
    displayId: 'WS2699142',
    title: "検査値自動計算保守",
    exact: false
  },
  {
    path: "/inspect-value-calculate-auto-maintain/calculate-formula-input",
    component: WS2699155_CalculateFormulaInput,
    displayId: 'WS2699155',
    title: "計算式入力",
    exact: false
  },
  {
    path: "/inspect-value-calculate-auto-maintain/copy",
    component: WS2699183_Copy,
    displayId: 'WS2699183',
    title: "複写",
    exact: false
  },
  {
    path: "/comprehensive-judge-classify-tbl/comprehensive-judge-classify-tbl",
    component: WS0998001_ComprehensiveJudgeClassifyTbl,
    displayId: 'WS0998001',
    title: "総合判定分類表",
    exact: false
  },
  {
    path: "/category-determining-classify-tbl/category-determining-classify-tbl",
    component: WS0999001_CategoryDeterminingClassifyTbl,
    displayId: 'WS0999001',
    title: "カテゴリ判定分類表",
    exact: false
  },
  {
    path: "/category-determining-classify-tbl/query-category-info",
    component: WS0176001_QueryCategoryInfo,
    displayId: 'WS0176001',
    title: "照会:カテゴリー情報",
    exact: false
  },
  {
    path: "/item-judge-classify-tbl/item-judge-classify-tbl",
    component: WS1000001_ItemJudgeClassifyTbl,
    displayId: 'WS1000001',
    title: "項目判定分類表",
    exact: false
  },
  {
    path: "/office-visits-achievement-situation/office-visits-achievement-situation",
    component: WS1015001_OfficeVisitsAchievementSituation,
    displayId: 'WS1015001',
    title: "事業所受診実績状況",
    exact: false
  },
  {
    path: "/office-visits-achievement-situation/business-establishments-subject-to-select",
    component: WS1015015_BusinessEstablishmentsSubjectToSelect,
    displayId: 'WS1015015',
    title: "対象事業所選択",
    exact: false
  },
  {
    path: "/office-visits-achievement-situation/csv-preview-confirm",
    component: WS0431001_CsvPreviewConfirm,
    displayId: 'WS0431001',
    title: "CSV・プレビュー確認",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output",
    component: WS2694001_MasterListOutput,
    displayId: 'WS2694001',
    title: "マスタ一覧出力",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-site-classify",
    component: WS0410001_MasterListOutputSiteClassify,
    displayId: 'WS0410001',
    title: "マスタ一覧出力[部位分類]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-category-info",
    component: WS0411001_MasterListOutputCategoryInfo,
    displayId: 'WS0411001',
    title: "マスタ一覧出力[カテゴリ情報]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-site-info",
    component: WS0412001_MasterListOutputSiteInfo,
    displayId: 'WS0412001',
    title: "マスタ一覧出力[部位情報]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-finding-info",
    component: WS0413001_MasterListOutputFindingInfo,
    displayId: 'WS0413001',
    title: "マスタ一覧出力[所見情報]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-inspect-item",
    component: WS0414001_MasterListOutputInspectItem,
    displayId: 'WS0414001',
    title: "マスタ一覧出力[検査項目]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-leadership-matter",
    component: WS0415001_MasterListOutputLeadershipMatter,
    displayId: 'WS0415001',
    title: "マスタ一覧出力[指導事項]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-course-basic-type",
    component: WS0416001_MasterListOutputCourseBasicType,
    displayId: 'WS0416001',
    title: "マスタ一覧出力[コース基本種別]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-inspect-value-judge-info",
    component: WS0417001_MasterListOutputInspectValueJudgeInfo,
    displayId: 'WS0417001',
    title: "マスタ一覧出力[検査値判定情報]",
    exact: false
  },
  {
    path: "/master-list-output/determine-level-select",
    component: WS0417003_DetermineLevelSelect,
    displayId: 'WS0417003',
    title: "判定レベル選択",
    exact: false
  },
  {
    path: "/master-list-output/copying-process",
    component: WS0417025_CopyingProcess,
    displayId: 'WS0417025',
    title: "複写処理",
    exact: false
  },
  {
    path: "/master-list-output/copying-process-effective-date",
    component: WS0417028_CopyingProcessEffectiveDate,
    displayId: 'WS0417028',
    title: "複写処理(適用日)",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-office-info",
    component: WS0419001_MasterListOutputOfficeInfo,
    displayId: 'WS0419001',
    title: "マスタ一覧出力[事業所情報]",
    exact: false
  },
  {
    path: "/master-list-output/master-list-output-insurance-info",
    component: WS0420001_MasterListOutputInsuranceInfo,
    displayId: 'WS0420001',
    title: "マスタ一覧出力[保険者情報]",
    exact: false
  },
  {
    path: "/personal-info-csv-output/personal-info-csv-output",
    component: WS0479001_PersonalInfoCsvOutput,
    displayId: 'WS0479001',
    title: "個人情報CSV出力",
    exact: false
  },
  {
    path: "/personal-info-csv-output/csv-confirm",
    component: WS0432001_CsvConfirm,
    displayId: 'WS0432001',
    title: "CSV確認",
    exact: false
  },
  {
    path: "/office-info-csv-output/office-info-csv-output",
    component: WS0480003_OfficeInfoCsvOutput,
    displayId: 'WS0480003',
    title: "事業所情報CSV出力",
    exact: false
  },
  {
    path: "/inspect-variation-info-output/inspect-variation-info-output",
    component: WS1119001_InspectVariationInfoOutput,
    displayId: 'WS1119001',
    title: "検査変動情報出力",
    exact: false
  },
  {
    path: "/form-create-editing/form-create-editing",
    component: WS1567001_FormCreateEditing,
    displayId: 'WS1567001',
    title: "帳票作成・編集",
    exact: false
  },
  {
    path: "/form-create-editing/output-destination-acquisition",
    component: WS1567009_OutputDestinationAcquisition,
    displayId: 'WS1567009',
    title: "出力先取得",
    exact: false
  },
  {
    path: "/form-create-editing/optional-input-sub",
    component: WS0400001_OptionalInputSub,
    displayId: 'WS0400001',
    title: "オプション入力         SUB",
    exact: false
  },
  {
    path: "/form-create-editing/file-name-acquisition",
    component: WS1568001_FileNameAcquisition,
    displayId: 'WS1568001',
    title: "ファイル名取得",
    exact: false
  },
  {
    path: "/form-create-editing/sub-variable-setting-screen",
    component: WS1569001_SubVariableSettingScreen,
    displayId: 'WS1569001',
    title: "SUB変数設定画面",
    exact: false
  },
  {
    path: "/form-create-editing/sub-option-select",
    component: WS1570001_SubOptionSelect,
    displayId: 'WS1570001',
    title: "SUBオプション選択",
    exact: false
  },
  {
    path: "/user-form-output/user-form-output",
    component: WS0801001_UserFormOutput,
    displayId: 'WS0801001',
    title: "ユーザー帳票出力",
    exact: false
  },
  {
    path: "/user-form-output/form-output",
    component: WS0801006_FormOutput,
    displayId: 'WS0801006',
    title: "帳票出力",
    exact: false
  },
  {
    path: "/user-form-output/sub-file-list",
    component: WS1583001_SubFileList,
    displayId: 'WS1583001',
    title: "SUBファイル一覧",
    exact: false
  },
  {
    path: "/user-document-item-maintain/user-document-item-maintain",
    component: WS1585001_UserDocumentItemMaintain,
    displayId: 'WS1585001',
    title: "ユーザー帳票項目保守",
    exact: false
  },
  {
    path: "/user-document-item-maintain/duplicate-modify",
    component: WS1585014_DuplicateModify,
    displayId: 'WS1585014',
    title: "重複修正",
    exact: false
  },
  {
    path: "/user-document-item-maintain/header-capture-output",
    component: WS1585018_HeaderCaptureOutput,
    displayId: 'WS1585018',
    title: "ヘッダ取込・出力",
    exact: false
  },
  {
    path: "/consult-ticket-input-process-list/insurer-number-inquiry",
    component: WS1290001_InsurerNumberInquiry,
    displayId: 'WS1290001',
    title: "保険者番号照会",
    exact: false
  },
  {
    path: "/consult-ticket-input-process-list/agency-inquiry-sub",
    component: WS1302001_AgencyInquirySub,
    displayId: 'WS1302001',
    title: "代行機関照会",
    exact: false
  },
  {
    path: "/consult-ticket-input-process-list/add-correct-screen",
    component: WS1302005_AddCorrectScreen,
    displayId: 'WS1302005',
    title: "追加・訂正画面",
    exact: false
  },
  {
    path: "/consult-ticket-input-process-list/consult-ticket-input-process-list",
    component: WS1376001_ConsultTicketInputProcessList,
    displayId: 'WS1376001',
    title: "受診券入力処理[一覧]",
    exact: false
  },
  {
    path: "/init-interview-target-determine-process/init-interview-target-determine-process",
    component: WS2847001_InitInterviewTargetDetermineProcess,
    displayId: 'WS2847001',
    title: "初回面談対象判定処理",
    exact: false
  },
  {
    path: "/init-interview-target-determine-process/list-process",
    component: WS2847005_ListProcess,
    displayId: 'WS2847005',
    title: "一覧処理",
    exact: false
  },
  {
    path: "/specific-medical-exam-settle-process/consult-ticket-input-sub",
    component: WS1296010_ConsultTicketInputSub,
    displayId: 'WS1296010',
    title: "受診券入力",
    exact: false
  },
  {
    path: "/specific-medical-exam-settle-process/screen-input",
    component: WS1303006_ScreenInput,
    displayId: 'WS1303006',
    title: "画面入力",
    exact: false
  },
  {
    path: "/specific-medical-exam-settle-process/contract-select",
    component: WS1349045_ContractSelect,
    displayId: 'WS1349045',
    title: "契約選択",
    exact: false
  },
  {
    path: "/specific-medical-exam-settle-process/settle-input-sub",
    component: WS1350013_SettleInputSub,
    displayId: 'WS1350013',
    title: "決済入力",
    exact: false
  },
  {
    path: "/specific-medical-exam-settle-process/specific-medical-exam-settle-process",
    component: WS1378001_SpecificMedicalExamSettleProcess,
    displayId: 'WS1378001',
    title: "特定健診決済処理",
    exact: false
  },
  {
    path: "/specific-medical-exam-settle-process/single-group-classify-input-sub",
    component: WS1379001_SingleGroupClassifyInputSub,
    displayId: 'WS1379001',
    title: "個別集団区分入力",
    exact: false
  },
  {
    path: "/specific-medical-exam-settle-process/agreement-inquiry-sub",
    component: WS1394001_AgreementInquirySub,
    displayId: 'WS1394001',
    title: "契約内容照会",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/xml-document-query-select-sub",
    component: WS1291001_XmlDocumentQuerySelectSub,
    displayId: 'WS1291001',
    title: "XML帳票照会選択",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/member-list",
    component: WS1351003_MemberList,
    displayId: 'WS1351003',
    title: "該当者一覧",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/result-confirm-normal",
    component: WS1351016_ResultConfirmNormal,
    displayId: 'WS1351016',
    title: "結果確認[正常]",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/result-confirm-error",
    component: WS1351017_ResultConfirmError,
    displayId: 'WS1351017',
    title: "結果確認[エラー]",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/print-process",
    component: WS1362003_PrintProcess,
    displayId: 'WS1362003',
    title: "印刷処理",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/member-list",
    component: WS1366003_MemberList,
    displayId: 'WS1366003',
    title: "該当者一覧",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/specific-health-target-data-extract-condition",
    component: WS1366004_SpecificHealthTargetDataExtractCondition,
    displayId: 'WS1366004',
    title: "特定保健対象データ抽出条件確認",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/result-confirm",
    component: WS1366022_ResultConfirm,
    displayId: 'WS1366022',
    title: "結果確認",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/result-confirm-error",
    component: WS1366023_ResultConfirmError,
    displayId: 'WS1366023',
    title: "結果確認[エラー]",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/member-list",
    component: WS1367002_MemberList,
    displayId: 'WS1367002',
    title: "該当者一覧",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/specific-health-data-xml-output",
    component: WS2655001_SpecificHealthDataXmlOutput,
    displayId: 'WS2655001',
    title: "特健データXML出力",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/output-confirm",
    component: WS2655078_OutputConfirm,
    displayId: 'WS2655078',
    title: "出力確認",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/details-extract",
    component: WS2655083_DetailsExtract,
    displayId: 'WS2655083',
    title: "詳細抽出",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/result-confirm",
    component: WS2655106_ResultConfirm,
    displayId: 'WS2655106',
    title: "結果確認",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/xml-error-target-list",
    component: WS2669001_XmlErrorTargetList,
    displayId: 'WS2669001',
    title: "XMLエラー対象者一覧",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/detailed-error-content-personal",
    component: WS2669006_DetailedErrorContentPersonal,
    displayId: 'WS2669006',
    title: "エラー内容詳細(個人)",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/specific-medical-exam-error-check-user-setting",
    component: WS2670009_SpecificMedicalExamErrorCheckUserSetting,
    displayId: 'WS2670009',
    title: "特定健診エラーチェックユーザー設定",
    exact: false
  },
  {
    path: "/specific-health-data-xml-output/inspect-advanced",
    component: WS2670033_InspectAdvanced,
    displayId: 'WS2670033',
    title: "検査詳細設定",
    exact: false
  },
  {
    path: "/insure-guide-init-input/insure-guide-init-input",
    component: WS2650001_InsureGuideInitInput,
    displayId: 'WS2650001',
    title: "保健指導初回入力",
    exact: false
  },
  {
    path: "/insure-guide-init-input/reserve-change",
    component: WS2650016_ReserveChange,
    displayId: 'WS2650016',
    title: "予約・変更",
    exact: false
  },
  {
    path: "/insure-guide-init-input/insure-guide-course-inquiry",
    component: WS1384001_InsureGuideCourseInquiry,
    displayId: 'WS1384001',
    title: "保健指導コース照会",
    exact: false
  },
  {
    path: "/insure-guide-init-input/financial-support-inquiry",
    component: WS1384002_FinancialSupportInquiry,
    displayId: 'WS1384002',
    title: "支援内容照会",
    exact: false
  },
  {
    path: "/insure-guide-init-input/sentence-master-inquiry",
    component: WS1385001_SentenceMasterInquiry,
    displayId: 'WS1385001',
    title: "文章マスタ照会",
    exact: false
  },
  {
    path: "/insure-guide-init-input/cmt-classify-inquiry",
    component: WS1387001_CmtClassifyInquiry,
    displayId: 'WS1387001',
    title: "コメント区分照会",
    exact: false
  },
  {
    path: "/insure-guide-init-input/first-date",
    component: WS1422006_FirstDate,
    displayId: 'WS1422006',
    title: "初回①日付",
    exact: false
  },
  {
    path: "/insure-guide-init-input/support-plan-manual-create",
    component: WS1423001_SupportPlanManualCreate,
    displayId: 'WS1423001',
    title: "支援計画手動作成",
    exact: false
  },
  {
    path: "/insure-guide-init-input/guide-input-result",
    component: WS1470001_GuideInputResult,
    displayId: 'WS1470001',
    title: "指導入力[結果]",
    exact: false
  },
  {
    path: "/insure-guide-init-input/content-input",
    component: WS1470005_ContentInput,
    displayId: 'WS1470005',
    title: "内容入力",
    exact: false
  },
  {
    path: "/insure-guide-init-input/health-checkup-info-select",
    component: WS1470008_HealthCheckupInfoSelect,
    displayId: 'WS1470008',
    title: "健診情報選択",
    exact: false
  },
  {
    path: "/insure-guide-init-input/guide-input-proven-sub",
    component: WS2653001_GuideInputProvenSub,
    displayId: 'WS2653001',
    title: "指導入力[実績]",
    exact: false
  },
  {
    path: "/insure-guide-init-input/e-mail-completed",
    component: WS2653004_EMailCompleted,
    displayId: 'WS2653004',
    title: "メール完了",
    exact: false
  },
  {
    path: "/insure-guide-init-input/health-checkup-info-select",
    component: WS2653079_HealthCheckupInfoSelect,
    displayId: 'WS2653079',
    title: "健診情報選択",
    exact: false
  },
  {
    path: "/insure-guide-init-input/content-input",
    component: WS2654013_ContentInput,
    displayId: 'WS2654013',
    title: "内容入力",
    exact: false
  },
  {
    path: "/insure-guide-init-input/targetives-result-input",
    component: WS2654016_TargetivesResultInput,
    displayId: 'WS2654016',
    title: "目標・結果入力",
    exact: false
  },
  {
    path: "/insure-guide-init-input/add-inspect",
    component: WS2654025_AddInspect,
    displayId: 'WS2654025',
    title: "検査追加",
    exact: false
  },
  {
    path: "/insure-guide-init-input/vouchers-input",
    component: WS2657078_VouchersInput,
    displayId: 'WS2657078',
    title: "利用券入力",
    exact: false
  },
  {
    path: "/insure-guide-input/insure-guide-input",
    component: WS2652001_InsureGuideInput,
    displayId: 'WS2652001',
    title: "保健指導入力",
    exact: false
  },
  {
    path: "/insure-guide-input/implement-record-ref",
    component: WS1436001_ImplementRecordRef,
    displayId: 'WS1436001',
    title: "実施状況[実績照会]",
    exact: false
  },
  {
    path: "/insure-guide-input/dunning-inquiry",
    component: WS1444001_DunningInquiry,
    displayId: 'WS1444001',
    title: "督促照会",
    exact: false
  },
  {
    path: "/insure-guide-input/dunning-input",
    component: WS1444016_DunningInput,
    displayId: 'WS1444016',
    title: "督促入力",
    exact: false
  },
  {
    path: "/insure-guide-input/content-input",
    component: WS1444026_ContentInput,
    displayId: 'WS1444026',
    title: "内容入力",
    exact: false
  },
  {
    path: "/insure-guide-input/method-select",
    component: WS1444032_MethodSelect,
    displayId: 'WS1444032',
    title: "方法選択",
    exact: false
  },
  {
    path: "/insure-guide-input/dunning-print",
    component: WS1445001_DunningPrint,
    displayId: 'WS1445001',
    title: "督促状印刷",
    exact: false
  },
  {
    path: "/vouchers-input-process-list/vouchers-input-process-list",
    component: WS1462001_VouchersInputProcessList,
    displayId: 'WS1462001',
    title: "利用券入力処理[一覧]",
    exact: false
  },
  {
    path: "/vouchers-input-process-list/list-process",
    component: WS1462006_ListProcess,
    displayId: 'WS1462006',
    title: "一覧処理",
    exact: false
  },
  {
    path: "/specific-insure-guide-settle-process-list/specific-insure-guide-settle-process-list",
    component: WS1464001_SpecificInsureGuideSettleProcessList,
    displayId: 'WS1464001',
    title: "特定保健指導決済処理[一覧]",
    exact: false
  },
  {
    path: "/specific-insure-guide-settle-process-list/list-process",
    component: WS1464003_ListProcess,
    displayId: 'WS1464003',
    title: "一覧処理",
    exact: false
  },
  {
    path: "/specific-insure-guide-settle-process-list/contract-select-screen",
    component: WS1465001_ContractSelectScreen,
    displayId: 'WS1465001',
    title: "契約選択画面",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/specific-insure-xml-output",
    component: WS2656001_SpecificInsureXmlOutput,
    displayId: 'WS2656001',
    title: "特定保指XML出力",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/details-extract",
    component: WS2656088_DetailsExtract,
    displayId: 'WS2656088',
    title: "詳細抽出",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/output-confirm",
    component: WS2656096_OutputConfirm,
    displayId: 'WS2656096',
    title: "出力確認",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/result-confirm",
    component: WS2656103_ResultConfirm,
    displayId: 'WS2656103',
    title: "結果確認",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/contract-display",
    component: WS1442001_ContractDisplay,
    displayId: 'WS1442001',
    title: "*  契約表示",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/contract-select-screen",
    component: WS1443001_ContractSelectScreen,
    displayId: 'WS1443001',
    title: "*  契約選択画面",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/init-collection-system-input",
    component: WS1443004_InitCollectionSystemInput,
    displayId: 'WS1443004',
    title: "初回徴収方式入力",
    exact: false
  },
  {
    path: "/specific-coercive-finger-xml-output/print-process",
    component: WS1456003_PrintProcess,
    displayId: 'WS1456003',
    title: "印刷処理",
    exact: false
  },
  {
    path: "/compiled-classify/compiled-classify",
    component: WS1304001_CompiledClassify,
    displayId: 'WS1304001',
    title: "取りまとめ区分",
    exact: false
  },
  {
    path: "/contract-compiled-maintain/contract-compiled-maintain",
    component: WS1305001_ContractCompiledMaintain,
    displayId: 'WS1305001',
    title: "契約取りまとめ保守",
    exact: false
  },
  {
    path: "/contract-compiled-maintain/compiled-select",
    component: WS1305004_CompiledSelect,
    displayId: 'WS1305004',
    title: "取りまとめ選択",
    exact: false
  },
  {
    path: "/specific-health-tokuho-options-file/specific-health-tokuho-options-file",
    component: WS1306001_SpecificHealthTokuhoOptionsFile,
    displayId: 'WS1306001',
    title: "特健特保オプションファイル",
    exact: false
  },
  {
    path: "/specific-health-tokuho-options-file/copying-process",
    component: WS1306003_CopyingProcess,
    displayId: 'WS1306003',
    title: "複写処理",
    exact: false
  },
  {
    path: "/specific-health-tokuho-param-maintain/specific-health-tokuho-param-maintain",
    component: WS1308001_SpecificHealthTokuhoParamMaintain,
    displayId: 'WS1308001',
    title: "特健特保パラメータ保守",
    exact: false
  },
  {
    path: "/specific-health-tokuho-param-maintain/input-support-plan-etc",
    component: WS1308004_InputSupportPlanEtc,
    displayId: 'WS1308004',
    title: "入力[支援計画等]",
    exact: false
  },
  {
    path: "/specific-health-tokuho-param-maintain/input-metabolic-syndrome-hierarchical",
    component: WS1308005_InputMetabolicSyndromeHierarchical,
    displayId: 'WS1308005',
    title: "入力[メタボ階層化]",
    exact: false
  },
  {
    path: "/specific-health-tokuho-param-maintain/copying-process",
    component: WS1308006_CopyingProcess,
    displayId: 'WS1308006',
    title: "複写処理",
    exact: false
  },
  {
    path: "/xml-param-maintain/xml-param-maintain",
    component: WS1309001_XmlParamMaintain,
    displayId: 'WS1309001',
    title: "XMLパラメータ保守",
    exact: false
  },
  {
    path: "/xml-param-maintain/input-list",
    component: WS1309003_InputList,
    displayId: 'WS1309003',
    title: "入力[一覧]",
    exact: false
  },
  {
    path: "/xml-param-maintain/input",
    component: WS1309004_Input,
    displayId: 'WS1309004',
    title: "入力",
    exact: false
  },
  {
    path: "/xml-param-maintain/copying-process",
    component: WS1309011_CopyingProcess,
    displayId: 'WS1309011',
    title: "複写処理",
    exact: false
  },
  {
    path: "/xml-param-maintain/item-convert-code-tbl-maintain",
    component: WS1310001_ItemConvertCodeTblMaintain,
    displayId: 'WS1310001',
    title: "項目変換コード表保守",
    exact: false
  },
  {
    path: "/xml-param-maintain/item-checkups-for-xml",
    component: WS1310002_ItemCheckupsForXml,
    displayId: 'WS1310002',
    title: "XML用健診項目",
    exact: false
  },
  {
    path: "/xml-param-maintain/xml-inspect-value-convert-master",
    component: WS1311001_XmlInspectValueConvertMaster,
    displayId: 'WS1311001',
    title: "XML検査値変換マスタ",
    exact: false
  },
  {
    path: "/xml-param-maintain/copy",
    component: WS1311003_Copy,
    displayId: 'WS1311003',
    title: "複写",
    exact: false
  },
  {
    path: "/xml-param-maintain/xml-inspect-value-convert-e-m",
    component: WS1312001_XmlInspectValueConvertEM,
    displayId: 'WS1312001',
    title: "XML検査値変換(保)M",
    exact: false
  },
  {
    path: "/xml-param-maintain/item-convert-cd-maintain-e",
    component: WS1315001_ItemConvertCdMaintainE,
    displayId: 'WS1315001',
    title: "項目変換CD保守(保)",
    exact: false
  },
  {
    path: "/xml-param-maintain/item-checkups-for-xml",
    component: WS1315003_ItemCheckupsForXml,
    displayId: 'WS1315003',
    title: "XML用健診項目",
    exact: false
  },
  {
    path: "/xml-param-maintain/insurer-by-course-checkups-type",
    component: WS2485001_InsurerByCourseCheckupsType,
    displayId: 'WS2485001',
    title: "保険者別コース健診種別",
    exact: false
  },
  {
    path: "/xml-param-maintain/medical-exam-type-query",
    component: WS2485002_MedicalExamTypeQuery,
    displayId: 'WS2485002',
    title: "健診種別照会",
    exact: false
  },
  {
    path: "/xml-param-maintain/medical-exam-type-maintain",
    component: WS2485003_MedicalExamTypeMaintain,
    displayId: 'WS2485003',
    title: "健診種別保守",
    exact: false
  },
  {
    path: "/xml-medical-exam-item-master/xml-medical-exam-item-master",
    component: WS1313001_XmlMedicalExamItemMaster,
    displayId: 'WS1313001',
    title: "XML用健診項目マスタ",
    exact: false
  },
  {
    path: "/xml-medical-exam-item-master/date-display",
    component: WS1313008_DateDisplay,
    displayId: 'WS1313008',
    title: "日付表示",
    exact: false
  },
  {
    path: "/xml-medical-exam-item-master/data-range-check",
    component: WS1313005_DataRangeCheck,
    displayId: 'WS1313005',
    title: "XML03001:データ範囲チェック",
    exact: false
  },
  {
    path: "/xml-medical-exam-item-master/xml-medical-exam-item-ref",
    component: WS1313006_XmlMedicalExamItemRef,
    displayId: 'WS1313006',
    title: "XML用健診項目参照",
    exact: false
  },
  {
    path: "/xml-medical-exam-item-master/csv-output-screen",
    component: WS1313007_CsvOutputScreen,
    displayId: 'WS1313007',
    title: "CSV出力画面",
    exact: false
  },
  {
    path: "/xml-medical-exam-item-master/csv-capture-screen",
    component: WS1313010_CsvCaptureScreen,
    displayId: 'WS1313010',
    title: "CSV取込画面",
    exact: false
  },
  {
    path: "/data-range-check/data-range-check",
    component: WS1314001_DataRangeCheck,
    displayId: 'WS1314001',
    title: "データ範囲チェック",
    exact: false
  },
  {
    path: "/data-range-check/date-display",
    component: WS1314008_DateDisplay,
    displayId: 'WS1314008',
    title: "日付表示",
    exact: false
  },
  {
    path: "/data-range-check/xml-medical-exam-item-ref",
    component: WS1314005_XmlMedicalExamItemRef,
    displayId: 'WS1314005',
    title: "XML用健診項目参照",
    exact: false
  },
  {
    path: "/data-range-check/xml-medical-exam-item-data-input",
    component: WS1314006_XmlMedicalExamItemDataInput,
    displayId: 'WS1314006',
    title: "XML用健診項目(データ入力)",
    exact: false
  },
  {
    path: "/data-range-check/csv-output-screen",
    component: WS1314007_CsvOutputScreen,
    displayId: 'WS1314007',
    title: "CSV出力画面",
    exact: false
  },
  {
    path: "/data-range-check/csv-capture-screen",
    component: WS1314010_CsvCaptureScreen,
    displayId: 'WS1314010',
    title: "CSV取込画面",
    exact: false
  },
  {
    path: "/contract-master-maintain/contract-master-maintain",
    component: WS1316001_ContractMasterMaintain,
    displayId: 'WS1316001',
    title: "契約マスタ保守",
    exact: false
  },
  {
    path: "/contract-master-maintain/contract-year-select-sub",
    component: WS1288001_ContractYearSelectSub,
    displayId: 'WS1288001',
    title: "契約年度選択",
    exact: false
  },
  {
    path: "/contract-master-maintain/contract-compiled-query",
    component: WS1289001_ContractCompiledQuery,
    displayId: 'WS1289001',
    title: "契約取りまとめ照会",
    exact: false
  },
  {
    path: "/contract-master-maintain/contract-master-details",
    component: WS1317001_ContractMasterDetails,
    displayId: 'WS1317001',
    title: "契約マスタ詳細",
    exact: false
  },
  {
    path: "/contract-master-maintain/add-inspect-detailed-input",
    component: WS1317008_AddInspectDetailedInput,
    displayId: 'WS1317008',
    title: "追加検査詳細入力",
    exact: false
  },
  {
    path: "/contract-master-maintain/inspect-item-search-query-single",
    component: WS1317039_InspectItemSearchQuerySingle,
    displayId: 'WS1317039',
    title: "検査項目検索・照会(単品)",
    exact: false
  },
  {
    path: "/contract-master-maintain/contract-copying-process-sub",
    component: WS1318001_ContractCopyingProcessSub,
    displayId: 'WS1318001',
    title: "契約 複写処理 SUB",
    exact: false
  },
  {
    path: "/contract-master-maintain/serial-number-ref",
    component: WS1318005_SerialNumberRef,
    displayId: 'WS1318005',
    title: "連番参照",
    exact: false
  },
  {
    path: "/contract-master-maintain/insurer-info",
    component: WS1319001_InsurerInfo,
    displayId: 'WS1319001',
    title: "保険者情報",
    exact: false
  },
  {
    path: "/contract-master-maintain/copy",
    component: WS1319003_Copy,
    displayId: 'WS1319003',
    title: "複写",
    exact: false
  },
  {
    path: "/support-item/support-item",
    component: WS1396001_SupportItem,
    displayId: 'WS1396001',
    title: "支援項目",
    exact: false
  },
  {
    path: "/support-item/support-item-sub",
    component: WS1396009_SupportItemSub,
    displayId: 'WS1396009',
    title: "支援項目サブ",
    exact: false
  },
  {
    path: "/support-item/inspect-item-inquiry",
    component: WS1386001_InspectItemInquiry,
    displayId: 'WS1386001',
    title: "検査項目照会",
    exact: false
  },
  {
    path: "/insure-guide-course/insure-guide-course",
    component: WS1398001_InsureGuideCourse,
    displayId: 'WS1398001',
    title: "保健指導コース",
    exact: false
  },
  {
    path: "/insure-guide-course/insure-guide-course-detail",
    component: WS1398010_InsureGuideCourseDetail,
    displayId: 'WS1398010',
    title: "保健指導コース明細",
    exact: false
  },
  {
    path: "/insure-guide-course/implementor-inquiry",
    component: WS1382001_ImplementorInquiry,
    displayId: 'WS1382001',
    title: "実施者照会",
    exact: false
  },
  {
    path: "/insure-guide-course/support-item-inquiry",
    component: WS1383001_SupportItemInquiry,
    displayId: 'WS1383001',
    title: "支援項目照会",
    exact: false
  },
  {
    path: "/insure-guide-course/inspect-inquiry",
    component: WS1383002_InspectInquiry,
    displayId: 'WS1383002',
    title: "検査照会",
    exact: false
  },
  {
    path: "/insure-guide-course/implement-location-query",
    component: WS1388001_ImplementLocationQuery,
    displayId: 'WS1388001',
    title: "実施場所照会",
    exact: false
  },
  {
    path: "/inspect-item-master/inspect-item-master",
    component: WS1400001_InspectItemMaster,
    displayId: 'WS1400001',
    title: "検査項目マスタ",
    exact: false
  },
  {
    path: "/inspect-item-master/document-classify-inquiry",
    component: WS1389001_DocumentClassifyInquiry,
    displayId: 'WS1389001',
    title: "文章分類照会",
    exact: false
  },
  {
    path: "/inspect-cmt-master/inspect-cmt-master",
    component: WS1401001_InspectCmtMaster,
    displayId: 'WS1401001',
    title: "検査コメントマスタ",
    exact: false
  },
  {
    path: "/document-classify-master/document-classify-master",
    component: WS1402001_DocumentClassifyMaster,
    displayId: 'WS1402001',
    title: "文章分類マスタ",
    exact: false
  },
  {
    path: "/sentence-master/sentence-master",
    component: WS1403009_SentenceMaster,
    displayId: 'WS1403009',
    title: "文章マスタ",
    exact: false
  },
  {
    path: "/implementation-division-master/implement-division-master",
    component: WS1404001_ImplementDivisionMaster,
    displayId: 'WS1404001',
    title: "実施区分マスタ",
    exact: false
  },
  {
    path: "/implementation-location-master/implement-location-master",
    component: WS1405001_ImplementLocationMaster,
    displayId: 'WS1405001',
    title: "実施場所マスタ",
    exact: false
  },
  {
    path: "/implementation-location-master/implement-classify-inquiry",
    component: WS1390001_ImplementClassifyInquiry,
    displayId: 'WS1390001',
    title: "実施区分照会",
    exact: false
  },
  {
    path: "/implementor-master/implementor-master",
    component: WS1406001_ImplementorMaster,
    displayId: 'WS1406001',
    title: "実施者マスタ",
    exact: false
  },
  {
    path: "/implementor-master/implement-agency-inquiry",
    component: WS1391001_ImplementAgencyInquiry,
    displayId: 'WS1391001',
    title: "実施機関照会",
    exact: false
  },
  {
    path: "/implement-agencies-master/implement-agencies-master",
    component: WS1407001_ImplementAgenciesMaster,
    displayId: 'WS1407001',
    title: "実施機関マスタ",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/associate-insure-money-amount-setting",
    component: WS2489001_AssociateInsureMoneyAmountSetting,
    displayId: 'WS2489001',
    title: "協会けんぽ金額設定",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/format-inquiry",
    component: WS2489003_FormatInquiry,
    displayId: 'WS2489003',
    title: "FORMAT照会",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/input-new",
    component: WS2489005_InputNew,
    displayId: 'WS2489005',
    title: "入力[新]",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/implement-money-amount-general",
    component: WS2489006_ImplementMoneyAmountGeneral,
    displayId: 'WS2489006',
    title: "実施金額一般",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/money-amount-added-general",
    component: WS2489007_MoneyAmountAddedGeneral,
    displayId: 'WS2489007',
    title: "金額付加一般",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/money-amount-hepatitis-general",
    component: WS2489008_MoneyAmountHepatitisGeneral,
    displayId: 'WS2489008',
    title: "金額肝炎一般",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/non-money-amount-general",
    component: WS2489009_NonMoneyAmountGeneral,
    displayId: 'WS2489009',
    title: "未金額一般",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/non-money-amount-added",
    component: WS2489010_NonMoneyAmountAdded,
    displayId: 'WS2489010',
    title: "未金額付加",
    exact: false
  },
  {
    path: "/associate-insure-money-amount-setting/copying-process",
    component: WS2489011_CopyingProcess,
    displayId: 'WS2489011',
    title: "複写処理",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/associate-insure-param-maintain",
    component: WS1168001_AssociateInsureParamMaintain,
    displayId: 'WS1168001',
    title: "協会けんパラメータ保守",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/copying-process",
    component: WS1168005_CopyingProcess,
    displayId: 'WS1168005',
    title: "複写処理",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/query-inspect-set",
    component: WS0181001_QueryInspectSet,
    displayId: 'WS0181001',
    title: "照会:検査セット",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/use-inspect-check",
    component: WS0294001_UseInspectCheck,
    displayId: 'WS0294001',
    title: "使用検査チェック",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/detail-info-correct",
    component: WS1169001_DetailInfoCorrect,
    displayId: 'WS1169001',
    title: "明細情報(修正)",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/year-select",
    component: WS1169004_YearSelect,
    displayId: 'WS1169004',
    title: "年度選択",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/inspect-info-correct",
    component: WS1170001_InspectInfoCorrect,
    displayId: 'WS1170001',
    title: "検査情報(修正)",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/aggregate-info-correct",
    component: WS1171001_AggregateInfoCorrect,
    displayId: 'WS1171001',
    title: "集計情報(修正)",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/item-amount-correct",
    component: WS1172001_ItemAmountCorrect,
    displayId: 'WS1172001',
    title: "明細金額(修正)",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/total-amount-correct",
    component: WS1173001_TotalAmountCorrect,
    displayId: 'WS1173001',
    title: "合計金額(修正)",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/course-select",
    component: WS1174003_CourseSelect,
    displayId: 'WS1174003',
    title: "コース選択",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/course-copying-function",
    component: WS1174006_CourseCopyingFunction,
    displayId: 'WS1174006',
    title: "コース複写機能",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/inspect-set-info",
    component: WS1176001_InspectSetInfo,
    displayId: 'WS1176001',
    title: "検査セット情報",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/copy-register-screen-input",
    component: WS1176003_CopyRegisterScreenInput,
    displayId: 'WS1176003',
    title: "複写登録（画面入力）",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/government-tube-primary-aggregate",
    component: WS1177001_GovernmentTubePrimaryAggregate,
    displayId: 'WS1177001',
    title: "政管一次集計",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/government-tube-hepatitis-aggregate",
    component: WS1178001_GovernmentTubeHepatitisAggregate,
    displayId: 'WS1178001',
    title: "政管肝炎集計",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/government-tube-param",
    component: WS1180001_GovernmentTubeParam,
    displayId: 'WS1180001',
    title: "政管パラメータ",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/government-tube-convert-maintain",
    component: WS1181001_GovernmentTubeConvertMaintain,
    displayId: 'WS1181001',
    title: "政管変換保守",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/copy",
    component: WS1181003_Copy,
    displayId: 'WS1181003',
    title: "複写",
    exact: false
  },
  {
    path: "/associate-insure-param-maintain/cmt-convert",
    component: WS1181005_CmtConvert,
    displayId: 'WS1181005',
    title: "コメント変換",
    exact: false
  },
  {
    path: "/associate-insure-data-create/associate-insure-data-create",
    component: WS1220001_AssociateInsureDataCreate,
    displayId: 'WS1220001',
    title: "協会けんぽデータ作成",
    exact: false
  },
  {
    path: "/associate-insure-data-create/code-setting",
    component: WS1220004_CodeSetting,
    displayId: 'WS1220004',
    title: "コード設定",
    exact: false
  },
  {
    path: "/associate-insure-data-create/output-destination-select",
    component: WS1225003_OutputDestinationSelect,
    displayId: 'WS1225003',
    title: "出力先選択",
    exact: false
  },
  {
    path: "/associate-insure-data-create/foundation-fd-inquiry",
    component: WS1226001_FoundationFdInquiry,
    displayId: 'WS1226001',
    title: "財団ＦＤ照会",
    exact: false
  },
  {
    path: "/associate-insure-data-create/correct-select",
    component: WS1226007_CorrectSelect,
    displayId: 'WS1226007',
    title: "訂正選択",
    exact: false
  },
  {
    path: "/associate-insure-data-create/media-manage",
    component: WS1226009_MediaManage,
    displayId: 'WS1226009',
    title: "媒体管理",
    exact: false
  },
  {
    path: "/associate-insure-data-create/medical-exam-result-correct",
    component: WS1226012_MedicalExamResultCorrect,
    displayId: 'WS1226012',
    title: "健診結果訂正",
    exact: false
  },
  {
    path: "/associate-insure-data-create/associate-insure-error-list-created-sub",
    component: WS1227001_AssociateInsureErrorListCreatedSub,
    displayId: 'WS1227001',
    title: "協会けんぽエラーリスト作成",
    exact: false
  },
  {
    path: "/associate-insure-data-create/target-select-association-sub",
    component: WS2789013_TargetSelectAssociationSub,
    displayId: 'WS2789013',
    title: "対象選択[協会]",
    exact: false
  },
  {
    path: "/association-acceptance-info-correct/association-acceptance-info-correct",
    component: WS1229001_AssociationAcceptanceInfoCorrect,
    displayId: 'WS1229001',
    title: "協会受付情報訂正",
    exact: false
  },
  {
    path: "/association-acceptance-info-correct/inquiry",
    component: WS1229013_Inquiry,
    displayId: 'WS1229013',
    title: "照会",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/associate-insure-qualify-confirm",
    component: WS2674001_AssociateInsureQualifyConfirm,
    displayId: 'WS2674001',
    title: "協会けんぽ資格確認",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/insurance-info",
    component: WS2674011_InsuranceInfo,
    displayId: 'WS2674011',
    title: "保険情報",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/multiple-select-office",
    component: WS2674013_MultipleSelectOffice,
    displayId: 'WS2674013',
    title: "事業所複数選択",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/request-csv-output",
    component: WS2676001_RequestCsvOutput,
    displayId: 'WS2676001',
    title: "依頼CSV出力",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/association-consult-qualify-result-capture",
    component: WS2677001_AssociationConsultQualifyResultCapture,
    displayId: 'WS2677001',
    title: "協会受診資格結果取込",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/capture-result",
    component: WS2677012_CaptureResult,
    displayId: 'WS2677012',
    title: "取込結果表示",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/result-confirm-csv-output",
    component: WS2681001_ResultConfirmCsvOutput,
    displayId: 'WS2681001',
    title: "結果確認CSV出力",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/transmission-contents-check-correct-personal",
    component: WS2682001_TransmissionContentsCheckCorrectPersonal,
    displayId: 'WS2682001',
    title: "送信内容確認・修正(個人)",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/transmission-contents-check-fix-file",
    component: WS2683001_TransmissionContentsCheckFixFile,
    displayId: 'WS2683001',
    title: "送信内容確認・修正(ファイル)",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/transmission-capture-confirm",
    component: WS2685001_TransmissionCaptureConfirm,
    displayId: 'WS2685001',
    title: "送信・取込内容確認",
    exact: false
  },
  {
    path: "/associate-insure-qualify-confirm/qualify-result-manually-update",
    component: WS2686001_QualifyResultManuallyUpdate,
    displayId: 'WS2686001',
    title: "資格結果手動更新",
    exact: false
  },
  {
    path: "/associate-insure-required-item/associate-insure-required-item",
    component: WS2442001_AssociateInsureRequiredItem,
    displayId: 'WS2442001',
    title: "協会けんぽ必須項目",
    exact: false
  },
  {
    path: "/facility-consumption-tax-setting/facility-consumption-tax-setting",
    component: WS1536001_FacilityConsumptionTaxSetting,
    displayId: 'WS1536001',
    title: "施設設定",
    exact: false
  },
  {
    path: "/facility-consumption-tax-setting/consumption-tax-setting",
    component: WS1536500_ConsumptionTaxSetting,
    displayId: 'WS1536500',
    title: "消費税設定",
    exact: false
  },
  {
    path: "/facility-consumption-tax-setting/image-setting",
    component: WS1536012_ImageSetting,
    displayId: 'WS1536012',
    title: "画像設定",
    exact: false
  },
  {
    path: "/facility-consumption-tax-setting/medical-institution-number-update-sub",
    component: WS1537001_MedicalInstitutionNumberUpdateSub,
    displayId: 'WS1537001',
    title: "医療機関番号更新",
    exact: false
  },
  {
    path: "/facility-consumption-tax-setting/medical-institution-number-query-sub",
    component: WS1538001_MedicalInstitutionNumberQuerySub,
    displayId: 'WS1538001',
    title: "医療機関番号照会",
    exact: false
  },
  {
    path: "/reserves-display-item-setting/reserves-display-item-setting",
    component: WS2702007_ReservesDisplayItemSetting,
    displayId: 'WS2702007',
    title: "予約表示項目設定",
    exact: false
  },
  {
    path: "/reserves-display-item-setting/display-name-edit",
    component: WS2702151_DisplayNameEdit,
    displayId: 'WS2702151',
    title: "表示名編集",
    exact: false
  },
  {
    path: "/ref-people-num-setting-info/ref-people-num-setting-info",
    component: WS1485001_RefPeopleNumSettingInfo,
    displayId: 'WS1485001',
    title: "基準人数設定情報",
    exact: false
  },
  {
    path: "/ref-people-num-setting-info/new-history",
    component: WS1485009_NewHistory,
    displayId: 'WS1485009',
    title: "新規履歴",
    exact: false
  },
  {
    path: "/ref-people-num-setting-info/history-select",
    component: WS1485016_HistorySelect,
    displayId: 'WS1485016',
    title: "履歴選択",
    exact: false
  },
  {
    path: "/ref-people-num-setting-info/calendar-month-sub",
    component: WS2575001_CalendarMonthSub,
    displayId: 'WS2575001',
    title: "カレンダー[月]",
    exact: false
  },
  {
    path: "/basic-period-time/basic-period-time",
    component: WS2697011_BasicPeriodTime,
    displayId: 'WS2697011',
    title: "基本時間帯",
    exact: false
  },
  {
    path: "/specific-date-people-num-setting/specific-date-people-num-setting",
    component: WS1490001_SpecificDatePeopleNumSetting,
    displayId: 'WS1490001',
    title: "特定日人数設定",
    exact: false
  },
  {
    path: "/non-consult-date-setting/non-consult-date-setting",
    component: WS1494001_NonConsultDateSetting,
    displayId: 'WS1494001',
    title: "休診日設定",
    exact: false
  },
  {
    path: "/bank-holiday-setting",
    component: BankHolidaySetting,
    displayId: 'BankHolidaySetting',
    title: "法定休日設定",
    exact: false
  },
  {
    path: "/non-consult-date-setting/collect-setting",
    component: WS1494003_CollectSetting,
    displayId: 'WS1494003',
    title: "一括設定",
    exact: false
  },
  {
    path: "/non-consult-date-setting/single-setting",
    component: WS1494006_SingleSetting,
    displayId: 'WS1494006',
    title: "個別設定",
    exact: false
  },
  {
    path: "/progress-info-maintain/progress-info-maintain",
    component: WS1499001_ProgressInfoMaintain,
    displayId: 'WS1499001',
    title: "進捗情報保守",
    exact: false
  },
  {
    path: "/progress-info-maintain/program-search",
    component: WS0276001_ProgramSearch,
    displayId: 'WS0276001',
    title: "プログラム検索",
    exact: false
  },
  {
    path: "/facility-create-hospital-outside-create-info-maintain/facility-hospital-outside-info-maintain",
    component: WS1500001_FacilityHospitalOutsideInfoMaintain,
    displayId: 'WS1500001',
    title: "施設(院内/外)情報保守",
    exact: false
  },
  {
    path: "/age-manage-info-maintain/age-manage-info-maintain",
    component: WS1505001_AgeManageInfoMaintain,
    displayId: 'WS1505001',
    title: "年齢管理情報保守",
    exact: false
  },
  {
    path: "/age-manage-info-maintain/copy-register",
    component: WS1505010_CopyRegister,
    displayId: 'WS1505010',
    title: "複写登録",
    exact: false
  },
  {
    path: "/age-manage-info-maintain/csv-output-screen",
    component: WS1505013_CsvOutputScreen,
    displayId: 'WS1505013',
    title: "CSV出力画面",
    exact: false
  },
  {
    path: "/printer-info-maintain/printer-info-maintain",
    component: WS2698022_PrinterInfoMaintain,
    displayId: 'WS2698022',
    title: "プリンタ情報保守",
    exact: false
  },
  {
    path: "/printer-info-maintain/copy",
    component: WS2698003_Copy,
    displayId: 'WS2698003',
    title: "複写",
    exact: false
  },
  {
    path: "/printer-info-maintain/printer-info-input",
    component: WS2698165_PrinterInfoInput,
    displayId: 'WS2698165',
    title: "プリンタ情報入力",
    exact: false
  },
  {
    path: "/printer-info-maintain/printer-select",
    component: WS2698172_PrinterSelect,
    displayId: 'WS2698172',
    title: "プリンタ選択",
    exact: false
  },
  // {
  //   path: "/optional-info-maintain/optional-info-maintain",
  //   component: WS1512001_OptionalInfoMaintain,
  //   displayId: 'WS1512001',
  //   title: "オプション情報保守",
  //   exact: false
  // },
  {
    path: "/optional-info-maintain/item-modification",
    component: WS1512066_ItemModification,
    displayId: 'WS1512066',
    title: "項目修正",
    exact: false
  },
  {
    path: "/optional-info-maintain/add-item",
    component: WS1512074_AddItem,
    displayId: 'WS1512074',
    title: "項目追加",
    exact: false
  },
  {
    path: "/optional-info-maintain/add-code",
    component: WS1512135_AddCode,
    displayId: 'WS1512135',
    title: "コード追加",
    exact: false
  },
  {
    path: "/optional-info-maintain/direct-editing",
    component: WS1512138_DirectEditing,
    displayId: 'WS1512138',
    title: "直接編集",
    exact: false
  },
  {
    path: "/optional-info-maintain/inspect-list-setting-sub",
    component: WS0102001_InspectListSettingSub,
    displayId: 'WS0102001',
    title: "検査一覧設定",
    exact: false
  },
  {
    path: "/optional-info-maintain/optional-output-capture",
    component: WS1513001_OptionalOutputCapture,
    displayId: 'WS1513001',
    title: "オプション出力・取込",
    exact: false
  },
  {
    path: "/optional-info-maintain/options-item-info-maintain",
    component: WS1515001_OptionsItemInfoMaintain,
    displayId: 'WS1515001',
    title: "オプションアイテム情報保守",
    exact: false
  },
  {
    path: "/optional-info-maintain/copy-level1",
    component: WS1515015_CopyLevel1,
    displayId: 'WS1515015',
    title: "コピー[Level1]",
    exact: false
  },
  {
    path: "/optional-info-maintain/copy-level2",
    component: WS1515023_CopyLevel2,
    displayId: 'WS1515023',
    title: "コピー[Level2]",
    exact: false
  },
  {
    path: "/optional-info-maintain/optional-item-csv-output",
    component: WS1516001_OptionalItemCsvOutput,
    displayId: 'WS1516001',
    title: "オプションアイテムCSV出力",
    exact: false
  },
  {
    path: "/optional-info-maintain/options-item-output-capture",
    component: WS1517001_OptionsItemOutputCapture,
    displayId: 'WS1517001',
    title: "オプションアイテム出力・取込",
    exact: false
  },
  {
    path: "/optional-info-maintain/confirm-option-item-info",
    component: WS1518001_ConfirmOptionItemInfo,
    displayId: 'WS1518001',
    title: "オプションアイテム情報確認",
    exact: false
  },
  {
    path: "/optional-info-maintain/select-subject",
    component: WS1518007_SelectSubject,
    displayId: 'WS1518007',
    title: "選択対象",
    exact: false
  },
  {
    path: "/optional-info-maintain/option-type-setting",
    component: WS1519002_OptionTypeSetting,
    displayId: 'WS1519002',
    title: "オプション種別設定",
    exact: false
  },
  {
    path: "/optional-info-maintain/options-item-info-confirming-sub",
    component: WS1520001_OptionsItemInfoConfirmingSub,
    displayId: 'WS1520001',
    title: "オプションアイテム情報確認 SUB",
    exact: false
  },
  {
    path: "/user-option-info-maintain/user-option-info-maintain",
    component: WS1523001_UserOptionInfoMaintain,
    displayId: 'WS1523001',
    title: "ユーザーオプション情報保守",
    exact: false
  },
  {
    path: "/user-option-info-maintain/search-screen",
    component: WS1523004_SearchScreen,
    displayId: 'WS1523004',
    title: "検索画面",
    exact: false
  },
  {
    path: "/user-option-info-maintain/option-type-maintain",
    component: WS1523005_OptionTypeMaintain,
    displayId: 'WS1523005',
    title: "オプション種別保守",
    exact: false
  },
  {
    path: "/user-option-info-maintain/user-options-inquiry",
    component: WS1523007_UserOptionsInquiry,
    displayId: 'WS1523007',
    title: "ユーザーオプション照会",
    exact: false
  },
  {
    path: "/user-option-info-maintain/user-options-item-info-maintain",
    component: WS1525001_UserOptionsItemInfoMaintain,
    displayId: 'WS1525001',
    title: "ユーザーオプションアイテム情報保守",
    exact: false
  },
  {
    path: "/user-option-info-maintain/copy-level1",
    component: WS1525016_CopyLevel1,
    displayId: 'WS1525016',
    title: "コピー[Level1]",
    exact: false
  },
  {
    path: "/user-option-info-maintain/copy-level2",
    component: WS1525026_CopyLevel2,
    displayId: 'WS1525026',
    title: "コピー[Level2]",
    exact: false
  },
  {
    path: "/user-param-maintain/user-param-maintain",
    component: WS0449001_UserParamMaintain,
    displayId: 'WS0449001',
    title: "ユーザーパラメータ保守",
    exact: false
  },
  {
    path: "/user-param-maintain/copying-process",
    component: WS0449005_CopyingProcess,
    displayId: 'WS0449005',
    title: "複写処理",
    exact: false
  },
  {
    path: "/user-param-maintain/convert-tbl-replication-process",
    component: WS0449007_ConvertTblReplicationProcess,
    displayId: 'WS0449007',
    title: "変換テーブル複写処理",
    exact: false
  },
  {
    path: "/user-param-maintain/input-output",
    component: WS0449009_InputOutput,
    displayId: 'WS0449009',
    title: "入出力",
    exact: false
  },
  {
    path: "/user-param-maintain/user-param-input",
    component: WS0450004_UserParamInput,
    displayId: 'WS0450004',
    title: "ユーザーパラメータ入力",
    exact: false
  },
  {
    path: "/user-param-maintain/character-string-search",
    component: WS0450006_CharacterStringSearch,
    displayId: 'WS0450006',
    title: "文字列検索",
    exact: false
  },
  {
    path: "/user-param-maintain/convert-tbl-sub-inspect",
    component: WS0452003_ConvertTblSubInspect,
    displayId: 'WS0452003',
    title: "変換テーブルSUB[検査]",
    exact: false
  },
  {
    path: "/user-param-maintain/convert-tbl-sub-all",
    component: WS0452005_ConvertTblSubAll,
    displayId: 'WS0452005',
    title: "変換テーブルSUB[全て]",
    exact: false
  },
  {
    path: "/user-param-maintain/copy",
    component: WS0452006_Copy,
    displayId: 'WS0452006',
    title: "複写",
    exact: false
  },
  {
    path: "/user-param-maintain/cmt-convert",
    component: WS0452008_CmtConvert,
    displayId: 'WS0452008',
    title: "コメント変換",
    exact: false
  },
  {
    path: "/user-param-maintain/inspect-capture-inspect-convert-tbl-input-output",
    component: WS0453001_InspectCaptureInspectConvertTblInputOutput,
    displayId: 'WS0453001',
    title: "検査取込検査変換テーブル入出力",
    exact: false
  },
  {
    path: "/user-param-maintain/user-unit-fd-paramtyle-inquiry",
    component: WS0453003_UserUnitFdParamtyleInquiry,
    displayId: 'WS0453003',
    title: "ユーザ単位FDパラメータ様式照会",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/personal-action-item-inquiry",
    component: WS0545001_PersonalActionItemInquiry,
    displayId: 'WS0545001',
    title: "個人実施項目照会",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/communication-roster-output",
    component: WS0882001_CommunicationRosterOutput,
    displayId: 'WS0882001',
    title: "連名簿出力",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/style-query",
    component: WS0882004_StyleQuery,
    displayId: 'WS0882004',
    title: "様式照会",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/issue-list-input-control",
    component: WS0884004_IssueListInputControl,
    displayId: 'WS0884004',
    title: "発行一覧(入力制御)",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/create-test-for-medical-exam-info",
    component: WS2249001_CreateTestForMedicalExamInfo,
    displayId: 'WS2249001',
    title: "テスト用受診情報作成",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/auto-create",
    component: WS2249005_AutoCreate,
    displayId: 'WS2249005',
    title: "自動作成",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/continuous-print",
    component: WS2249013_ContinuousPrint,
    displayId: 'WS2249013',
    title: "連続印刷",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/visits-change-confirm",
    component: WS2624002_VisitsChangeConfirm,
    displayId: 'WS2624002',
    title: "受診変更確認",
    exact: false
  },
  {
    path: "/create-test-for-medical-exam-info/visits-cancel-confirm",
    component: WS2624003_VisitsCancelConfirm,
    displayId: 'WS2624003',
    title: "受診取消確認",
    exact: false
  },
  {
    path: "/rising-document-create/rising-document-create",
    component: WS2290001_RisingDocumentCreate,
    displayId: 'WS2290001',
    title: "立上資料作成",
    exact: false
  },
  {
    path: "/rising-document-create/judge-unit-list",
    component: WS2290003_JudgeUnitList,
    displayId: 'WS2290003',
    title: "判定・単位一覧表",
    exact: false
  },
  {
    path: "/log-display/log-display",
    component: WS0262001_LogDisplay,
    displayId: 'WS0262001',
    title: "ログ照会",
    exact: false
  },
  {
    path: "/log-display/character-search",
    component: WS0262064_CharacterSearch,
    displayId: 'WS0262064',
    title: "文字検索",
    exact: false
  },
  {
    path: "/log-display/user-choice",
    component: WS0262068_UserChoice,
    displayId: 'WS0262068',
    title: "ユーザー選択",
    exact: false
  },
  {
    path: "/log-display/select-folder",
    component: WS2591001_SelectFolder,
    displayId: 'WS2591001',
    title: "フォルダ選択",
    exact: false
  },
  {
    path: "/contract-info-maintain/history-copy",
    component: WS0315005_HistoryCopy,
    displayId: 'WS0315005',
    title: "管理複写",
    exact: false
  },
  {
    path: "/contract-master-maintain/inspect-item-search-query-single034",
    component: WS1317034_InspectItemSearchQuerySingle,
    displayId: 'WS1317034',
    title: "検査項目検索・照会(単品)",
    exact: false
  },
  {
    path: "/comprehensive-menu-maintenance",
    component: WS2690001_ComprehensiveMenuMaintenance,
    displayId: 'WS2690001',
    title: "包括的なメニューメンテナンス",
    exact: false
  },
  {
    path: "/comprehensive-menu-maintenance-sub",
    component: WS2690501_ComprehensiveMenuMaintenanceSub,
    displayId: 'WS2690501',
    title: "WS2690501_ComprehensiveMenuMaintenance",
    exact: false
  },
  {
    path: "/menu-customization/menu-customization",
    component: WS2692001_MenuCustomization,
    displayId: 'WS2692001',
    title: "WS2692001_MenuCustomization",
    exact: false
  },

  {
    path: "/passing-manage-progress/passing-manage-progress",
    component: WS3059001_PassingManageProgress,
    displayId: 'WS3059001',
    title: "通過管理進捗状況",
    exact: false
  },

  {
    path: "/passing-manage-progress/terminal-list",
    component: WS3059005_TerminalList,
    displayId: 'WS3059005',
    title: "端末項目一覧",
    exact: false
  },

  {
    path: "/passing-manage-progress/passing-list",
    component: WS3059008_PassingList,
    displayId: 'WS3059008',
    title: "通過項目一覧",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display/:num/:date/:passingFalg", //引数指定バージョン（端末番号と受診日と通過項目かどうか）
    component: WS3061001_PassingControlInspectsDisplay,
    displayId: 'WS3061001',
    title: "通過管理検査表示",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display/:num/:date", //引数指定バージョン（端末番号と受診日）
    component: WS3061001_PassingControlInspectsDisplay,
    displayId: 'WS3061001',
    title: "通過管理検査表示",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display/:num", //引数指定バージョン(端末番号だけ)
    component: WS3061001_PassingControlInspectsDisplay,
    displayId: 'WS3061001',
    title: "通過管理検査表示",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display",
    component: WS3061001_PassingControlInspectsDisplay,
    displayId: 'WS3061001',
    title: "通過管理検査表示",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display2/:num/:date/:passingFalg", //引数指定バージョン（端末番号と受診日と通過項目かどうか）
    component: WS3061002_PassingControlInspectsDisplay2,
    displayId: 'WS3061001',
    title: "通過管理検査表示[診察介助]",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display2/:num/:date", //引数指定バージョン（端末番号と受診日）
    component: WS3061002_PassingControlInspectsDisplay2,
    displayId: 'WS3061001',
    title: "通過管理検査表示[診察介助]",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display2/:num", //引数指定バージョン(端末番号だけ)
    component: WS3061002_PassingControlInspectsDisplay2,
    displayId: 'WS3061002',
    title: "通過管理検査表示[診察介助]",
    exact: false
  },

  {
    path: "/passing-control-inspects-display/passing-control-inspects-display2",
    component: WS3061002_PassingControlInspectsDisplay2,
    displayId: 'WS3061002',
    title: "通過管理検査表示[診察介助]",
    exact: false
  },

  {
    path: "/passing-manage-settings/passing-manage-settings",
    component: WS3062001_PassingManageSettings,
    displayId: 'WS3062001',
    title: "通過管理設定",
    exact: false
  },

  {
    path: "/passing-manage-batch-extract/passing-manage-batch-extract",
    component: WS3063001_PassingManageBatchExtract,
    displayId: 'WS3063001',
    title: "通過管理一括抽出",
    exact: false
  },

  {
    path: "/main/dashboard",
    component: WS2519500_Dashboard,
    displayId: 'WS2519500',
    title: "ダッシュボード",
    exact: false
  },

  {
    path: "/main/dashboard",
    component: WS2520001_SendEmail,
    displayId: 'WS2520001',
    title: "メール送信",
    exact: false
  },

  {
    path: "/main/dashboard",
    component: WS2522001_TodoCreate,
    displayId: 'WS2522001',
    title: "TODO作成",
    exact: false
  },

  {
    path: "/startup-and-maintenance",
    component: WS3123001_StartupAndMaintenance,
    displayId: 'WS3123001',
    title: "メニューメンテナンス",
    exact: false
  },

  // {
  //   path: "/karteco-cada-cooperation/karteco-cada-cooperation",
  //   component: WS3124001_KartecoCadaCooperation, displayId: 'WS3124001',
  //   title: "カルテコ・CADA連携",
  //   exact: false
  // },
  {
    path: "/karteco-cooperation-csv-output/karteco-cooperation-csv-output",
    component: WS3127001_KartecoCooperationCsvOutput, displayId: 'WS3127001',
    title: "カルテコ連携CSV出力",
    exact: false
  },
  // {
  //   path: "/karteco-cooperation-target-master/karteco-cooperation-target-master",
  //   component: WS3135001_KartecoCooperationTargetMaster, displayId: 'WS3135001',
  //   title: "カルテコ連携対象マスタ",
  //   exact: false
  // }
  {
    path: "/reserve-status-search-custom/reserve-status-search-custom",
    component: ReserveStatusSearchCustom,
    displayId: 'ReserveStatusSearchCustom',
    title: "予約状況検索カスタマイズ",
    exact: false
  },
  {
    path: "/reserve-status-search-custom/reserve-status-search-custom2",
    component: ReserveStatusSearchCustom2,
    displayId: 'ReserveStatusSearchCustom2',
    title: "予約状況検索カスタマイズ2",
    exact: false
  },
  {
    path: "/progress-management/progress-management",
    component: ProgressManagement,
    displayId: 'ProgressManagement',
    title: "保健指導進捗管理",
    exact: false
  },
  {
    path: "/authentication-check/authentication-check",
    component: AuthenticationCheck,
    title: "3点認証チェック",
    exact: false
  },
  {
    path: "/corporate-hearing-sheet/corporate-hearing-sheet",
    component: CorporateHearingSheet,
    title: "企業ヒアリングシート",
    exact: false
  },
  {
    path: "/provisional-reservation/provisional-reservation",
    component: FrameReserve,
    title: "仮予約",
    exact: false
  },
  {
    path: "/medical-checkup-calendar/medical-checkup-calendar",
    component: MedicalCheckupCalendar,
    displayId: 'MedicalCheckupCalendar',
    title: "人間ドックカレンダー",
    exact: false
  },
  {
    path: "/capture-radiological-findings/capture-radiological-findings",
    component: CaptureRadiologicalFindings,
    displayId: 'CaptureRadiologicalFindings',
    title: "放射線所見取込",
    exact: false
  },
  {
    path: "/east-fukyo-converse-process/east-fukyo-converse-process",
    component: WS1131001_EastFukyoConverseProcess,
    title: "東振協変換処理",
    exact: false
  },

  {
    path: "/user-document-item-maintain/address-label-personal",
    component: WS1552001_AddressLabel_Personal,
    displayId: 'WS1552001',
    title: "住所ラベル[個人]",
    exact: false
  },

  // {
  //   path: "/user-document-item-maintain/address-label-office",
  //   component: WS1557001_AddressLabel_Office,
  //   displayId: 'WS1557001',
  //   title: "住所ラベル[事業所]",
  //   exact: false
  // },

  {
    path: "/user-document-item-maintain/address-label-office-reserved",
    component: WS1558001_AddressLabel_OfficeReserved,
    displayId: 'WS1558001',
    title: "住所ラベル[事業所受診]",
    exact: false
  },

  {
    path: "/user-document-item-maintain/address-label-insurer",
    component: WS1556001_AddressLabel_Insurer,
    displayId: 'WS1556001',
    title: "住所ラベル[保険者]",
    exact: false
  },

  {
    path: "/specimen-label-print/specimen-label-print",
    component: WS0668001_SpecimenLabelPrint,
    displayId: 'WS0668001',
    title: "検体ラベル",
    exact: false,
  },

  {
    path: "/result-acquisition-process/result-acquisition-process",
    component: WS3008001_ResultAcquisitionProcess,
    displayId: 'WS3008001_ResultAcquisitionProcess',
    title: 'Mirais検査結果取込',
    exact: false
  },

  {
    path: "/menu-customization/my-menu",
    component: WS2692011_MyMenu,
    displayId: 'WS2692011',
    title: "マイメニュー",
    exact: false,
  },

  {
    path: "/electronic-medical-records-linkage-frame-set/electronic-medical-records-linkage-frame-set",
    component: WS3200001_ElectronicMedicalRecordsLinkageFrameSet,
    displayId: 'WS3200001',
    title: "電子カルテ連携枠設定",
    exact: false,
  },

  {
    path: "/determine-tentative-reserve/determine-tentative-reserve",
    component: DetermineTentativeReserve,
    displayId: 'DetermineTentativeReserve',
    title: "仮予約確定処理",
    exact: false,
  },

  {
    path: "/dock-tentative-reserve/dock-tentative-reserve",
    component: DockTentativeReserve,
    displayId: 'DockTentativeReserve',
    title: "人間ドック仮予約",
    exact: false,
  },

  {
    path: "/log-query/log-query",
    component: LogQuery,
    displayId: 'LogQuery',
    title: "システムログ照会",
    exact: false,
  },

  {
    path: "/application-log-query/application-log-query",
    component: ApplicationLogQuery,
    displayId: 'ApplicationLogQuery',
    title: "アプリケーションログ照会",
    exact: false,
  },

  {
    path: "/interview-support/interview-support",
    component: InterviewSupport,
    displayId: 'InterviewSupport',
    title: "面談支援",
    exact: false,
  },

  {
    path: "/external/interview-support",
    component: ResultDisplayView,
    displayId: 'ResultDisplayView',
    title: "面談支援（外部）",
    name: 'interview-support',
    exact: false,
    query: { id: '', user: '' }
  },

  {
    path: "/mock/introduce-letter-extract-maintain",
    component: WS0898001_IntroduceLetterExtractMaintainMock,
    displayId: 'WS0898001_IntroduceLetterExtractMaintainMock',
    title: "紹介状抽出保守(モック)",
    exact: false,
  },

  {
    path: "/mock/counter-mock",
    component: WS2620001_CounterMock,
    displayId: 'WS2620001_CounterMock',
    title: "窓口(モック)",
    exact: false,
  },

  {
    path: "/mock/tentative-reserve-capture-confirmed-mock",
    component: TentativeReserveCaptureConfirmedMock,
    displayId: 'TentativeReserveCaptureConfirmedMock',
    title: "仮予約取込確定(モック)",
    exact: false,
  },
  {
    path: "/mock/medical-checkup-calendar-mock",
    component: MedicalCheckupCalendarMock,
    displayId: 'MedicalCheckupCalendarMock',
    title: "人間ドックカレンダー(モック)",
    exact: false,
  },
  {
    path: "/mock/contract-info-maintain-mock",
    component: WS0306001_ContractInfoMaintainMock,
    displayId: 'WS0306001_ContractInfoMaintainMock',
    title: "契約情報保守(モック)",
    exact: false,
  },
  {
    path: "/mock/passing-manage-progress-mock",
    component: WS3059001_PassingManageProgressMock,
    displayId: 'WS3059001_PassingManageProgressMock',
    title: "通過管理進捗状況(モック)",
    exact: false,
  },
  {
    path: "/mock/not-input-check-category-mock",
    component: WS0743001_NotInputCheckCategoryMock,
    displayId: 'WS0743001_NotInputCheckCategoryMock',
    title: "未入力チェック(モック)",
    exact: false,
  },
  {
    path: "/follow-up/follow-up",
    component: FollowUp,
    displayId: 'FollowUp',
    title: "経過観察",
    exact: false,
  },
  {
    path: "/follow-up/postmanage-and-tracking-follow-up",
    component: PostmanageAndTrackingFollowup,
    displayId: 'PostmanageAndTrackingFollowup',
    title: "事後管理・追跡経過観察",
    exact: false,
  },
  {
    path: "/tentative-reserve-capture-confirmed/tentative-reserve-capture-confirmed",
    component: TentativeReserveCaptureConfirmed,
    displayId: 'TentativeReserveCaptureConfirmed',
    title: "仮予約取込確定",
    exact: false,
  },

  {
    path: "/mutual-aid-association-billing/mutual-aid-subject-billing-item-maintain",
    component: WS1778001_MutualAidSubjectBillingItemMaintain,
    displayId: 'WS1778001_MutualAidSubjectBillingItemMaintain',
    title: "共済組合請求項目保守",
    exact: false,
  },

  {
    path: "/mutual-aid-association-billing/mutual-aid-target-ken-course-maintain",
    component: WS1780001_MutualAidTargetKenCourseMaintain,
    displayId: 'WS1780001_MutualAidTargetKenCourseMaintain',
    title: "共済対象健コース保守",
    exact: false,
  },

  {
    path: "/mutual-aid-association-billing/comprehensive-medical-exam-billing",
    component: WS1781001_ComprehensiveMedicalExamBilling,
    displayId: 'WS1781001_ComprehensiveMedicalExamBilling',
    title: "総合健診請求集計",
    exact: false,
  },

  {
    path: "/mutual-aid-association-billing/table-comprehensive-medical-exam-report",
    component: WS1784001_TableComprehensiveMedicalExamReport,
    displayId: 'WS1784001_TableComprehensiveMedicalExamReport',
    title: "総合健診受診者報告",
    exact: false,
  },

  {
    path: "/mutual-aid-association-billing/fd-create-municipal-mutual-aid-association",
    component: WS1787001_FdCreateMunicipalMutualAidAssociation,
    displayId: 'WS1787001_FdCreateMunicipalMutualAidAssociation',
    title: "市町村職員共済組合結果FD作成",
    exact: false,
  },

  {
    path: "/mutual-aid-association-billing/municipal-mutual-aid-association-maintain",
    component: WS1789001_MunicipalMutualAidAssociationMaintain,
    displayId: 'WS1789001_MunicipalMutualAidAssociationMaintain',
    title: "市町村職員共済組合部位所見変換保守",
    exact: false,
  },
  {
    path: "/virus-check-log/virus-check-log",
    component: VirusCheckLog,
    displayId: 'VirusCheckLog',
    title: "ウイルスチェック結果確認",
    exact: false,
  },
  {
    path: "/course-departmentspecific-format/course-departmentspecific-format",
    component: WS0923001_CourseDepartmentspecificFormat,
    displayId: 'WS0923001_CourseDepartmentspecificFormat',
    title: "コース・診療科別書式保守",
    exact: false,
  },
  {
    path: "/option-setting/option-setting",
    component: OptionSetting,
    displayId: 'OptionSetting',
    title: "オプション設定",
    exact: false,
  },
  {
    path: "/inspect-group-maintain/inspect-group-maintain",
    component: InspectGroupMaintain,
    displayId: 'InspectGroupMaintain',
    title: "検査グループマスタ保守",
    exact: false,
  },
  {
    path: "/cancer-screening-csv-output/cancer-screening-csv-output",
    component: CancerScreeningCSVOutput,
    displayId: 'CancerScreeningCSVOutput',
    title: "大分県庁がん健診提出",
    exact: false,
  },
  {
    path: "/follow-up-letter/follow-up-letter-course-departmentspecific-format",
    component: FollowUpLetterCourseDepartmentspecificFormat,
    displayId: 'FollowUpLetterCourseDepartmentspecificFormat',
    title: "コース・診療科別書式保守(経過観察用)",
    exact: false,
  },
  {
    path: "/auto-judgment-management/auto-judgment-management",
    component: WS3401001_AutoJudgmentManagement,
    displayId: 'WS3401001_AutoJudgmentManagement',
    title: "自動判定管理",
    exact: false,
  },
  {
    path: "/connect-hosts-setting/connect-hosts-setting",
    component: ConnectHostsSetting,
    displayId: 'ConnectHostsSetting',
    title: "ホスト接続設定",
    exact: false,
  },
  {
    path: "/insurer-number-conversion-master/insurer-number-conversion-master",
    component: InsurerNumberConversionMaster,
    displayId: 'InsurerNumberConversionMaster',
    title: "保険者番号変換マスタ",
    exact: false,
  },
  {
    path: "/process-menu/index",
    component: WS0439001_ProcessMenu,
    displayId: 'WS0439001_ProcessMenu',
    title: "処理メニュー メンテナンス",
    exact: false,
  },
  {
    path: "/passing-manage-csv/passing-manage-csv",
    component: WS3064001_PassingManageCSV,
    displayId: 'WS3064001_PassingManageCSV',
    title: "通過管理CSV出力",
    exact: false,
  },
  {
    path: "/sales-situation-table-instructs/sales-situation-table-instructs",
    component: WS0983001_SalesSituationTableInstructs,
    displayId: 'WS0983001_SalesSituationTableInstructs',
    title: "売上状況表",
    exact: false,
  },
  {
    path: "/specific-medical-examination-revision-2024/specific-medical-examination-revision-2024",
    component: WS3468001,
    displayId: 'WS3468001',
    title: "特定健診・特定保健指導改正",
    exact: false,
  },
  {
    path: "/protect-personal-info/protect-personal-info",
    component: ProtectPersonalInfo,
    displayId: 'ProtectPersonalInfo',
    title: "個人情報保護",
    exact: false,
  },
  {
    path: "/csv-input-output/csv-input-output",
    component: CsvInputOutput,
    displayId: 'CsvInputOutput',
    title: "データ一括入出力",
    exact: false,
  },
  {
    path: "/bulk-auto-calculate/bulk-auto-calculate",
    component: BulkAutoCalculate,
    displayId: 'BulkAutoCalculate',
    title: "一括自動計算",
    exact: false,
  },
  {
    path: "/recovery-processes/recovery-processes",
    component: RecoveryProcesses,
    displayId: 'RecoveryProcesses',
    title: "リカバリ処理関係",
    exact: false,
  },
  {
    path: "/space-trimming/space-trimming",
    component: SpaceTrimming,
    displayId: 'SpaceTrimming',
    title: "検査コメント関係",
    exact: false,
  },
  {
    path: "/printer-setting/printer-setting",
    component: PrinterSetting,
    displayId: 'PrinterSetting',
    title: "プリンター設定",
    exact: false,
  },
];

export default Route;
