import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Select, Button, Col, Row, Space } from "antd";
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx';
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery.jsx';
import moment from 'moment';
import { download_file } from 'helpers/CommonHelpers'

import MasterListOutputAttributeAction from "redux/StatisticalServices/MasterListOutput/MasterListOutputAttribute.action";

class WS0479001_PersonalInfoCsvOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '個人情報CSV出力';

    this.state = {
      checkName: 1,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  checkLable(value) {
    let val = ""
    switch (value) {
      case 1: val = '個　人'
        break
      case 2: val = '事業所'
        break
      default: val = '保険者'
    }
    return val;
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  showModalNum(NumRange) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component: this.checkComponent(NumRange)
        ,
      },
    });
  }

  checkComponent(NumRange) {
    let setValue = {}
    let specified = this.formRef.current?.getFieldValue("SpecifiedCategory")
    if (specified === 1) {
      return (<WS0248001_PersonalInfoSearchQuery
        date={moment(new Date()).format('YYYY/MM/DD')}
        onFinishScreen={(output) => {
          if(NumRange == "NumRangeF") {
            setValue = {NumRangeF: output.Lo_PersonalNumId}
          } else if (NumRange == "NumRangeT") {
            setValue = {NumRangeT: output.Lo_PersonalNumId}
          }
          this.formRef.current?.setFieldsValue(setValue)
          this.closeModal()
        }}
      />)
    } else if (specified === 2) {
      return (<WS0247001_OfficeInfoRetrievalQuery
        onFinishScreen={(output) => {
          if(NumRange == "NumRangeF") {
            setValue = {NumRangeF: output.Lio_OfficeCode}
          } else if (NumRange == "NumRangeT") {
            setValue = {NumRangeT: output.Lio_OfficeCode}
          }
          this.formRef.current?.setFieldsValue(setValue)
          this.closeModal()
        }}
      />)
    } else if (specified === 3) {
      return (<WS0246001_InsurerInfoSearchQuery
        onFinishScreen={(output) => {
          if(NumRange == "NumRangeF") {
            setValue = {NumRangeF: output.Lo_InsurerCode}
          } else if (NumRange == "NumRangeT") {
            setValue = {NumRangeT: output.Lo_InsurerCode}
          }
          this.formRef.current?.setFieldsValue(setValue)
          this.closeModal()
        }}
      />)
    }
  }

  outputCsvFile() {
    let params = {
      SpecifiedCategory: this.formRef.current?.getFieldValue("SpecifiedCategory"),
      NumRangeF: this.formRef.current?.getFieldValue("NumRangeF"),
      NumRangeT: this.formRef.current?.getFieldValue("NumRangeT"),
      AgeReferenceDateChar: this.formRef.current?.getFieldValue("AgeReferenceDateChar"),
    }
    console.log(params)
    MasterListOutputAttributeAction.outputCsvFile(params)
      .then((res) => {
        console.log(res)
        if (res) {
          // csvファイルダウンロード
          download_file(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    var today = new Date().getFullYear() + '/' + ("0" + (new Date().getMonth() + 1)).slice(-2) + '/' + ("0" + new Date().getDate()).slice(-2)
    return (
      <div className="personal-info-csv-output" style={{ width: 700 }}>
        <Card className="mb-2" title="個人情報CSV出力">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ SpecifiedCategory: 1, AgeReferenceDateChar: moment(today) }}
          >
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.06)', padding: '1em' }} >
              <Row>
                <Col span={6}>
                  <Form.Item name="SpecifiedCategory" label="区　分">
                    <Select onChange={(val) => this.setState({ checkName: val })} >
                      <Select.Option value={1}>個人番号</Select.Option>
                      <Select.Option value={2}>事業所</Select.Option>
                      <Select.Option value={3}>保険者</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                  <Space className="mb-3">
                    <Form.Item name="NumRangeF" label={this.checkLable(this.state.checkName)}>
                      <Input.Search onSearch={() => this.showModalNum("NumRangeF")} />
                    </Form.Item>
                    <Form.Item>~</Form.Item>
                    <Form.Item name="NumRangeT" >
                      <Input.Search onSearch={() => this.showModalNum("NumRangeT")} />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item name="AgeReferenceDateChar" label="基準日">
                    <VenusDatePickerCustom formRefDatePicker={this.formRef} style={{ width: 150 }} allowCleaf={true} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Button type="primary" style={{ float: 'right', marginTop: '1em' }} onClick={() => this.outputCsvFile()} >出力</Button>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0479001_PersonalInfoCsvOutput);


