/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import moment from 'moment'
import { parseInt } from 'lodash'
import { connect } from 'react-redux'
import { Button, Card, Form, Table, Modal, Space, message, Tooltip, Dropdown, Menu } from 'antd'
import { DownloadOutlined, MenuOutlined, RedoOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS3108008_ProcessSelectQuery from 'pages/KS_CooperationRelated/V4CP0030000_OcrCaptureStartUp/WS3108008_ProcessSelectQuery.jsx'
import OcrCaptureStartUpAction from 'redux/CooperationRelated/OcrCaptureStartUp/OcrCaptureStartUp.action'
import WS1107001_SettingOcrCapture from './WS1107001_SettingOcrCapture'
import WS1523001_UserOptionInfoMaintain from 'pages/SM_SystemMaintenance/V4SM0031010_UserOptionInfoMaintain/WS1523001_UserOptionInfoMaintain'
import WS0449001_UserParamMaintain from 'pages/SM_SystemMaintenance/V4MS9900800_UserParamMaintain/WS0449001_UserParamMaintain'
import WS3108002_OcrResultConfirm from './WS3108002_OcrResultConfirm'
import WS3108015_ConfirmAcquisitionTarget from './WS3108015_ConfirmAcquisitionTarget'


// 後でバックエンドから取ってくるが仕様が確定するまで固定とする
let today1 = moment(new Date().setDate(new Date().getDate() - 20))
let today2 = moment(new Date())
const modalWidth = 420
class WS3108001_OcrCaptureStartUp extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'OCR取込み起動'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isShowUnder: false,
      isLoadingForm: false,
      dataSource: []
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期データ取得
   */
  index = () => {
    OcrCaptureStartUpAction.index()
      .then((res) => {
        this.getInitialData()
      })
  }

  /**
   * 初期表示用のsy5100のデータを取得
   */
  getInitialData = () => {
    //sy5100のデータを取得する
    OcrCaptureStartUpAction.getInitialData()
      .then((res) => {
        if (res) {
          const data = [...res.ocrData]
          this.setState({ dataSource: data })

          this.formRef.current?.setFieldsValue({
            ProcessSelect: '',
            ProcessSelectCopy: '',
            options: res.options,
          })
          this.forceUpdate()
        }
      })
  }

  /**
   * OCRの読み込み処理
   * @param {*} data
   */
  read = (params) => {
    OcrCaptureStartUpAction.read(params)
      .then((res) => {
        if (res) {
          if (res?.showModalFlag) {
            // 取込対象確認 モーダル
            this.showWS3108015_ConfirmAcquisitionTarget(res)
          } else {
            // OCRデータなし
            message.warning('OCRデータが存在しません')
          }
        }
      })
  }

  /**
   * 取込データ削除
   */
  deleteBatchBefore = () => {
    let params = {}
    OcrCaptureStartUpAction.deleteBatchBefore(params)
      .then((res) => {
        if (res) {
          if (res?.call === 'Call after') {
            let content = (
              <div>
                OCRの読み取りデータを初期化しますか。
              </div>
            )
            Modal.confirm({
              width: modalWidth,
              content: content,
              okText: '初期化',
              cancelText: 'キャンセル',
              onOk: () => {
                this.batchDelete()
              },
            })
          } else {
            Modal.info({
              width: modalWidth,
              content: '読み込み済みのデータはありません。',
              okText: 'OK',
            })
          }
        }
      })
  }

  batchDelete = () => {
    OcrCaptureStartUpAction.batchDelete()
      .then((res) => {
        this.index()
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(err.response.data.message)
      })
  }

  /**
   * 取込対象確認 モーダル
   * @param {*} value
   */
  showWS3108015_ConfirmAcquisitionTarget = (data) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS3108015_ConfirmAcquisitionTarget
            ProcessSelect={this.formRef.current?.getFieldValue('ProcessSelect')}
            DateF={moment(this.formRef.current.getFieldValue('DateFChar')).format('YYYY/MM/DD')}
            DateT={moment(this.formRef.current.getFieldValue('DateTChar')).format('YYYY/MM/DD')}
            options={this.formRef.current?.getFieldValue('options')}
            Li_GetTargetConfirm={data?.GetTargetConfirm}
            ocrData={data?.ocrDataList}
            onFinishScreen={async (output) => {
              await this.closeModal()
              // 結果確認モーダル
              await this.showWS3108002_OcrResultConfirm(output)

              // OCRデータ再取得
              this.getOcrCaptures()
            }}
            onUpdate={(output) => { this.index() }}
          />
        ),
      }
    })
  }

  /**
   * 結果確認
   */
  showWS3108002_OcrResultConfirm = (output) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS3108002_OcrResultConfirm
            message={output.message}
            updateFlag={output.updateFlag}
            errorLogFlag={output.errorLogFlag}
            logPath={output.logPath}
            errorLogPath={output.errorLogPath}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * OCRデータ取得
   */
  getOcrCaptures = () => {
    OcrCaptureStartUpAction.getOcrCaptures()
      .then((res) => {
        if (res) {
          const data = [...res]
          this.setState({ dataSource: data })
        }
      })
  }

  /**
   * 処理選択照会 モーダル
   */
  showWS3108008_ProcessSelectQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS3108008_ProcessSelectQuery
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                ProcessSelect: output.recordData.No,
                ProcessSelectCopy: (parseInt(output.recordData.No) === 0) ? '' : output.recordData.No,
                options: output.recordData.ProcessSelectName,
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 「取込」ボタン押下
   *  読み込み前の確認処理
   */
  confirmRead = () => {
    const dateF = moment(this.formRef.current.getFieldValue('DateFChar'))
    const dateT = moment(this.formRef.current.getFieldValue('DateTChar'))
    const dateFChar = dateF?.format('YYYY/MM/DD')
    const dateTChar = dateT?.format('YYYY/MM/DD')

    if (dateF.valueOf() > dateT.valueOf()) {
      // エラーメッセージ
      let content = '「' + dateFChar + '」' + ' 以降の日付を設定してください'
      Modal.error({
        width: modalWidth,
        content: content,
        okText: 'OK',
      })

    } else {
      let content = (
        <div>
          OCRのデータ読み取りを行います
          <br />
          よろしいですか？
        </div>
      )
      Modal.confirm({
        width: modalWidth,
        title: '確認',
        content: content,
        okText: '読込開始',
        cancelText: 'キャンセル',
        onOk: () => {
          let data = {
            ...this.formRef.current.getFieldValue(),
            DateFChar: dateFChar,
            DateTChar: dateTChar,
          }
          // OCR読み込み
          this.read(data)
        },
      })
    }
  }

  /**
   * 設定[OCR取込] モーダル
   */
  showWS1107001_SettingOcrCapture = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS1107001_SettingOcrCapture
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * ユーザーオプション情報保守 モーダル
   */
  showWS1523001_UserOptionInfoMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS1523001_UserOptionInfoMaintain
            Li_OptionCode={'V3OCR,CP0030001'}
            Li_Expansion={2}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * ユーザーパラメータ保守 モーダル
   */
  showWS0449001_UserParamMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0449001_UserParamMaintain
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル　閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='ocr-capture-start-up' >
        <Card
          title='OCR取込み起動'
          style={{ width: 1200 }}
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      onClick={() => {
                        // 設定[OCR取込] モーダル
                        this.showWS1107001_SettingOcrCapture()
                      }}
                    >
                      取込設定
                    </Menu.Item>
                    <Menu.Item
                      key='2'
                      onClick={() => {
                        // ユーザーオプション情報保守 モーダル
                        this.showWS1523001_UserOptionInfoMaintain()
                      }}
                    >
                      ユーザーオプション
                    </Menu.Item>
                    <Menu.Item
                      key='3'
                      onClick={() => {
                        // ユーザーパラメータ保守 モーダル
                        this.showWS0449001_UserParamMaintain()
                      }}
                    >
                      ユーザーパラメータ
                    </Menu.Item>
                    <Menu.Item
                      key='4'
                      onClick={() => {
                        // 取込データ削除
                        this.deleteBatchBefore()
                      }}
                    >
                      取込データ削除
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            initialValues={{
              DateFChar: today1,
              DateTChar: today2,
            }}
          >
            <Form.Item
              label='処理選択'
            >
              <div className='box_inner_horizontal'>
                <Button
                  onClick={() => {
                    // 処理選択照会 モーダル
                    this.showWS3108008_ProcessSelectQuery()
                  }}
                >
                  選択
                </Button>
                <div style={{ marginTop: 5 }}>
                  {this.formRef.current?.getFieldValue('options')}
                </div>
              </div>

            </Form.Item>

            <Form.Item label='対象日付'>
              <Space>
                <Form.Item name='DateFChar' style={{ marginBottom: 0 }}>
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    allowClear={false}
                  />
                </Form.Item>
                <span>~</span>
                <Form.Item name='DateTChar' style={{ marginBottom: 0 }}>
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    allowClear={false}
                  />
                </Form.Item>
                <span
                >
                  に受診の方
                </span>
              </Space>
            </Form.Item>
          </Form>

          <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
            <Button
              type='primary'
              icon={<RedoOutlined />}
              onClick={() => {
                // 再読み込みボタン押下
                this.index()
              }}
            >
              <span className='btn_label'>
                再読み込み
              </span>
            </Button>

            <Space
              style={{ display: (!this.state.isShowUnder) ? 'none' : '' }}
            >
              <span
                style={{ marginRight: '15px', fontWeight: 'bold' }}
                hidden={!this.state.isShowUnder}
              >
                ･･･取込中
              </span>
            </Space>

            <Button
              type='primary'
              icon={<DownloadOutlined />}
              onClick={() => {
                // 取込ボタン押下
                this.confirmRead()
              }}
            >
              <span className='btn_label'>
                取込
              </span>
            </Button>
          </div>

          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{ y: 500 }}
            style={{ marginTop: 10 }}
          >
            <Table.Column
              title='ID'
              dataIndex='id'
              width={50}
              render={(value) =>
                <div style={{ textAlign: 'right' }}>
                  {value}
                </div>
              }
            />
            <Table.Column
              title='取込内容'
              dataIndex='capture_content'
            />
            <Table.Column
              title='ファイル名'
              dataIndex='file_name'
              width={200}
            />
            <Table.Column
              title='反映'
              dataIndex='status'
              width={60}
              render={(value) =>
                <div style={{ textAlign: 'center' }}>
                  {(value === 0) ? '未' : (value === 9) ? '不可' : '済'}
                </div>
              }
            />
            <Table.Column
              title='取込日時'
              dataIndex='system_at'
              width={180}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3108001_OcrCaptureStartUp)
