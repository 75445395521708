import { message } from "antd";
import SiteFindingsMigrationService from "services/InspectionMaintenance/RadiographyInspectMaintain/SiteFindingsMigrationService";

const SiteFindingsMigrationAction = {
  findingsMove(params) {
    return SiteFindingsMigrationService.findingsMove(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}

export default SiteFindingsMigrationAction;