import React from 'react'
import { connect } from 'react-redux'
import { Card, Table, Input, Modal, Button, Form, Space, message, Tooltip } from 'antd'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import WS0445007_PrintStyleInquiry from 'pages/KK_ResultOutput/V4MS9900600_CourseSpecificStardStyleSetting/WS0445007_PrintStyleInquiry.jsx'
import WS0445006_OfficeInquiry from 'pages/KK_ResultOutput/V4MS9900600_CourseSpecificStardStyleSetting/WS0445006_OfficeInquiry.jsx'
import CourseSpecificStardStyleSettingAction from 'redux/ResultOutput/CourseSpecificStardStyleSetting/CourseSpecificStardStyleSetting.action.js'
import { PlusOutlined, SaveOutlined, DeleteOutlined, QuestionCircleOutlined, WarningOutlined, ImportOutlined, CheckOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0445001_CourseSpecificStardStyleSetting extends React.Component {
  formRef = React.createRef()

  commonName = '【　共　通　】'
  defaultName = '【　デフォルト　】'

  constructor(props) {
    super(props)

    // document.title = 'コース別標準様式設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      oldDataSource: [],
      dataSource: [],
      selectedRows: [],
      indexTable: 0,
      rowSelect: {},
      addCount: 0,


      disabledSaveBtn: true, // [保存]ボタンの活性フラグ
      disabledCaptureBtn: false, // [コース取込]ボタンの活性フラグ
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    CourseSpecificStardStyleSettingAction.index()
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res,
            oldDataSource: res,
            addCount: 0
          })
        }
      })
  }

  /**
   * 配列の行番号を取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    if (arrayData && arrayData.length > 0) {
      return arrayData.findIndex((item) => recordID === item.id)
    }
  }

  updateDataSourceById = (arrayUpdate, id) => {
    this.setState({
      dataSource: this.state.dataSource.map((item) => {
        if (item.id === id) {
          arrayUpdate.map((data) => {
            item = { ...item, [data.name]: data.value }
          })
          return item
        }
        return item
      }),
    })
  }

  /**
   * 保存
   * @param {*} record
   */
  save = () => {
    const params = {
      dataSource: this.state.dataSource,
      oldDataSource: this.state.oldDataSource
    }
    CourseSpecificStardStyleSettingAction.save(params)
      .then((res) => {
        if (res) {
          this.setState({
            disabledSaveBtn: true,
            disabledCaptureBtn: false,
          })
          this.index()
        }
      })
  }

  /**
   * コース取込
   */
  captureCourse = () => {
    CourseSpecificStardStyleSettingAction.captureCourse()
      .then((res) => {
        if (res) {
          Modal.success({
            title: '完了',
            content: '取込が完了しました',
            okText: 'OK',
          })
          this.index()
        }
      })
  }

  ChangeOfficeCode(record) {
    CourseSpecificStardStyleSettingAction.ChangeOfficeCode(
      { office_code: record.office_code }
    )
      .then((res) => {
        this.updateDataSourceById(
          [
            {
              name: 'office_code',
              value: res.office_code,
            },
            {
              name: 'v4_branch_store_code',
              value: res.branch_store_code,
            },
            {
              name: 'OfficeDivision',
              value: res.office_kanji_name ?? this.commonName,
            },
          ],
          record.id
        )
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res || !res.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.message)
      })
  }

  ChangeMedicalExamCourse(record) {
    CourseSpecificStardStyleSettingAction.ChangeMedicalExamCourse(
      { medical_exam_course: record.medical_exam_course }
    )
      .then((res) => {
        this.updateDataSourceById(
          [
            {
              name: 'medical_exam_course',
              value: res.medical_exam_course ?? '',
            },
            {
              name: 'MedicalExamCourseName',
              value: res.MedicalExamCourseName ?? this.defaultName,
            },
          ],
          record.id
        )
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res || !res.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.message)
      })
  }

  ChangeStandardPrintingStyle(record) {
    CourseSpecificStardStyleSettingAction.ChangeStandardPrintingStyle(
      { standard_printing_style: record.standard_printing_style }
    )
      .then((res) => {
        this.updateDataSourceById(
          [
            {
              name: 'standard_printing_style',
              value: res.style_code ?? '',
            },
            {
              name: 'format_name',
              value: res.format_name ?? '',
            },
          ],
          record.id
        )
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res || !res.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.message)
      })
  }

  /**
   * 新規追加、行追加
   */
  add = async () => {
    let idCount = this.state.addCount + 1
    let newRow = {
      id: 'new_' + String(idCount),
      OfficeDivision: this.commonName,
      MedicalExamCourseName: this.defaultName,
    }

    let data = [...this.state.dataSource]
    // 行の一番上に追加
    data.unshift(newRow)

    await this.setState({
      dataSource: data,
      addCount: idCount,
    })
  }

  /**
   * 削除　
   * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
   * @param {*} id
   */
  delete = (id) => {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.formRef.current?.setFieldsValue({
      dataSource: data
    })
    this.setState({
      dataSource: data,
      disabledSaveBtn: false,
      disabledCaptureBtn: true,
    })
  }

  /**
   * それぞれの値の変更
   * @param {*} id
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(id, field, value) {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    let tempArr = [], obj
    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })

    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false,
      disabledCaptureBtn: true
    })
  }

  /**
   * 事業所照会 モーダル
   * @param {*} record
   */
  showWS0445006_OfficeInquiry = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 1200,
        component: (
          <WS0445006_OfficeInquiry
            officeCode={record.office_code ?? ''}
            onFinishScreen={async ({
              Lo_branch_store_code,
              Lo_office_code,
              recordData,
            }) => {
              await this.updateDatasource(record.id, 'office_code', Lo_office_code)
              await this.updateDatasource(record.id, 'v4_branch_store_code', Lo_branch_store_code)
              await this.updateDatasource(record.id, 'OfficeDivision', recordData.office_kanji_name)
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 基本コース照会 モーダル
   * @param {*} record
   */
  showWS0265001_BasicCourseInquiry = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 1200,
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={async ({
              Lo_CourseCode,
              Lo_CourseName,
            }) => {
              await this.updateDatasource(record.id, 'medical_exam_course', Lo_CourseCode)
              await this.updateDatasource(record.id, 'MedicalExamCourseName', Lo_CourseName)
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 印刷様式選択 モーダル
   * @param {*} record
   */
  showWS0445007_PrintStyleInquiry = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS0445007_PrintStyleInquiry
            onFinishScreen={async ({
              Lo_standard_printing_style,
              Lo_format_name,
            }) => {
              await this.updateDatasource(record.id, 'standard_printing_style', Lo_standard_printing_style)
              await this.updateDatasource(record.id, 'format_name', Lo_format_name)
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  render() {
    return (
      <div className='course-specific-stard-style-setting'>
        <Form
          ref={this.formRef}
          autoComplete='off'
        >
          <Card title='コース別標準様式設定'>
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              // rowClassName={(record, index) =>
              //   record.id === this.state.selectedRows[0]?.id
              //     ? 'table-row-light'
              //     : ''
              // }
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
              onRow={(record, index) => ({
                onClick: (e) => {
                  this.setState({
                    rowSelect: record,
                    indexTable: index,
                    selectedRows: [record],
                  })
                },
              })}
            >
              <Table.Column
                title='事業所コード'
                dataIndex='office_code'
                className='column-size-20'
                sorter={(a, b) => a.office_code - b.office_code}
                render={(value, record) => (
                  <Input.Search
                    style={{ textAlign: 'right' }}
                    value={record.office_code}
                    onChange={(e) => {
                      this.updateDatasource(record.id, 'office_code', e.target.value)
                    }}
                    onSearch={() => {
                      this.showWS0445006_OfficeInquiry(record)
                    }}
                  />
                )}
              />
              <Table.Column
                title='支社店コード'
                dataIndex='v4_branch_store_code'
                className='column-size-10'
                sorter={(a, b) => a.v4_branch_store_code - b.v4_branch_store_code}
                render={(value, record) => {
                  if (!record.office_code) {
                    record.v4_branch_store_code = ''
                  }
                  return (
                    <Input.Search
                      style={{ textAlign: 'right' }}
                      value={record.v4_branch_store_code}
                      readOnly={record.v4_branch_store_code}
                      onChange={(e) => {
                        this.updateDatasource(record.id, 'v4_branch_store_code', e.target.value)
                      }}
                      onSearch={() => {
                        if (record.v4_branch_store_code !== '') {
                          this.showWS0445006_OfficeInquiry(record)
                        }
                      }}
                    />
                  )
                }}
              />
              <Table.Column
                title='事業所名称'
                dataIndex='OfficeDivision'
                sorter={(a, b) => a.OfficeDivision.localeCompare(b.OfficeDivision, 'ja')}
              />
              <Table.Column
                title='健診コース'
                dataIndex='medical_exam_course'
                sorter={(a, b) => a.medical_exam_course - b.medical_exam_course}
                render={(value, record) => {
                  return (
                    <Space >
                      <Input.Search
                        style={{ width: 100 }}
                        value={record.medical_exam_course}
                        onChange={(e) => {
                          this.updateDatasource(record.id, 'medical_exam_course', e.target.value)
                        }}
                        onSearch={() => {
                          this.showWS0265001_BasicCourseInquiry(record)
                        }}
                      />
                      <span style={{ marginLeft: '10px' }}>
                        {record.MedicalExamCourseName}
                      </span>
                    </Space>
                  )
                }}
              />
              <Table.Column
                title='印刷様式'
                dataIndex='standard_printing_style'
                key=''
                sorter={(a, b) => a.standard_printing_style - b.standard_printing_style}
                render={(value, record) => {
                  return (
                    <Space align='center'>
                      <Input.Search
                        style={{ width: 100 }}
                        value={record.standard_printing_style}
                        onChange={(e) => {
                          this.updateDatasource(record.id, 'standard_printing_style', e.target.value)
                        }}
                        onSearch={() => {
                          this.showWS0445007_PrintStyleInquiry(record)
                        }}
                      />
                      <span style={{ marginLeft: '10px' }}>
                        {record.format_name}
                      </span>
                    </Space>
                  )
                }}
              />
              {/* <Table.Column
                title={() => (
                  <>
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        if (this.state.dataSource === undefined) {
                          this.setState({
                            dataSource: [
                              {
                                id: 'create_new',
                                OfficeDivision: this.commonName,
                                MedicalExamCourseName: this.defaultName,
                              },
                            ],
                          })
                        } else {
                          let isCreatedNew = this.state.dataSource.filter(
                            (item) => {
                              if (item.id === 'create_new') {
                                return item
                              }
                            }
                          )
                          if (isCreatedNew.length) return
                          this.setState({
                            dataSource: [
                              {
                                id: 'create_new',
                                OfficeDivision: this.commonName,
                                MedicalExamCourseName: this.defaultName,
                              },
                              ...this.state.dataSource,
                            ],
                            currentPage: 1,
                          })
                        }
                      }}
                    />
                  </>
                )}
                align='center'
                width={80}
                render={(text, record, index) => {
                  return (
                    <Space> */}
              {/* <Button
                        size='small'
                        hidden={
                          this.state.indexTable !==
                          this.findIndexByID(this.state.dataSource, record.id)
                        }
                        style={{
                          color: 'green',
                          border: 'none',
                          marginRight: '5px',
                        }}
                        icon={<SaveOutlined />}
                        onClick={() => {
                          this.save(
                            record
                          )
                        }}
                      ></Button> */}

              {/* <Button
                        size='small'
                        hidden={
                          this.state.indexTable !==
                          this.findIndexByID(this.state.dataSource, record.id)
                        }
                        style={{ border: 'none', color: 'red' }}
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            title: '確認',
                            icon: (
                              <QuestionCircleOutlined
                                style={{ color: '#1890ff' }}
                              />
                            ),
                            content: '削除しますか',
                            onOk: () => {
                              this.delete(
                                record
                              )
                            },
                          })
                        }}
                      ></Button> */}
              {/* </Space>
                  )
                }}
              /> */}
              <Table.Column
                width={40}
                fixed='right'
                align='center'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      onClick={() => this.add()}
                      type='primary'
                      icon={<PlusOutlined />}
                    />
                  </div>
                }
                render={(text, record, index) => {
                  return (
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        this.delete(record.id)
                      }}
                    />
                  )
                }}
              />
            </Table>

            <div className='box_button_bottom_right'>
              <Tooltip title='保存ボタンを押下するまで編集内容や削除はデータベースに反映されません'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.save()
                  }}
                  disabled={this.state.disabledSaveBtn}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </Tooltip>

              <Tooltip title='変更した内容が保存されるまで取込はできません'>
                <Button
                  type='primary'
                  icon={<ImportOutlined />}
                  disabled={this.state.disabledCaptureBtn}
                  onClick={() => {
                    Modal.confirm({
                      title: '確認',
                      icon: <QuestionCircleOutlined style={{ color: '#1890ff' }} />,
                      content: '標準コースを取込みますか',
                      okText: '取　込',
                      cancelText: 'キャンセル',
                      onOk: () => {
                        this.captureCourse()
                      },
                    })
                  }}
                >
                  <span className='btn_label'>
                    コース取込
                  </span>
                </Button>
              </Tooltip>
            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0445001_CourseSpecificStardStyleSetting)
