import React from "react";

import {
  Card, Table, message, Button, Form, Input, Popconfirm, Space, Checkbox
} from "antd";
import {
  MoreOutlined, PlusOutlined
} from '@ant-design/icons';

import ModalDraggable from "components/Commons/ModalDraggable";
import ImplementAgenciesMasterAction from "redux/SpecificInsureMaintenance/ImplementAgenciesMaster/ImplementAgenciesMaster.action";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"

const columns = [
  {
    title: '実施機関コード',
    dataIndex: 'implementing_agency_code',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },

  {
    title: '機関番号',
    dataIndex: 'institution_number',
    classNumber: '10',
    type: 1,
  },
  {
    title: '機関名カナ',
    dataIndex: 'kana_name',
    classNumber: '14',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '機関名漢字',
    dataIndex: 'kanji_name',
    classNumber: '14',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '個別A',
    dataIndex: 'support_form_individual_a',
    classNumber: '10',
    textAlign: 'right',
    radioList: [
      {
        value: 1,
        label: '設定'
      },
      {
        value: 0,
        label: '未設定'
      }],
    type: 3,
  },
  {
    title: '個別B',
    dataIndex: 'support_form_individual_b',
    classNumber: '10',
    textAlign: 'right',
    radioList: [
      {
        value: 1,
        label: '設定'
      },
      {
        value: 0,
        label: '未設定'
      }],
    type: 3,
  },
  {
    title: 'グループ',
    dataIndex: 'support_form_group',
    classNumber: '10',
    textAlign: 'right',
    radioList: [
      {
        value: 1,
        label: '設定'
      },
      {
        value: 0,
        label: '未設定'
      }],
    type: 3,
  },
  {
    title: '電話A',
    dataIndex: 'support_form_telephone_a',
    classNumber: '10',
    textAlign: 'right',
    radioList: [
      {
        value: 1,
        label: '設定'
      },
      {
        value: 0,
        label: '未設定'
      }],
    type: 3,
  },
  {
    title: '電話B',
    dataIndex: 'support_form_phone_b',
    classNumber: '10',
    textAlign: 'right',
    radioList: [
      {
        value: 1,
        label: '設定'
      },
      {
        value: 0,
        label: '未設定'
      }],
    type: 3,
  },
  {
    title: 'メールA',
    dataIndex: 'support_form_email_a',
    classNumber: '10',
    textAlign: 'right',
    radioList: [
      {
        value: 1,
        label: '設定'
      },
      {
        value: 0,
        label: '未設定'
      }],
    type: 3,
  },
  {
    title: 'メールB',
    dataIndex: 'support_form_email_b',
    classNumber: '10',
    textAlign: 'right',
    radioList: [
      {
        value: 1,
        label: '設定'
      },
      {
        value: 0,
        label: '未設定'
      }],
    type: 3,
  },
  {
    title: 'オプション',
    dataIndex: 'option_remark',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  }
]

class WS1407001_ImplementAgenciesMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '実施機関マスタ';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true });
    ImplementAgenciesMasterAction.index()
      .then(res => {
        console.log(res)
        this.setState({
          dataSource: res
        })
        this.formRef.current.setFieldsValue({
          MainSource: res,
        });
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  SaveData(record) {
    this.setState({ isLoading: true });
    ImplementAgenciesMasterAction.save(record)
      .then(res => {
        console.log(res)
        if (!res) {
          return;
        }
        message.success('保存完了');
        this.loadMainSource();
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card className="mb-2" title="実施機関マスタ">
          <Form ref={this.formRef}>
            <Table bordered size="small"
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={false}
              rowKey={(record) => (record.id || Math.random())}
            >
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.implementing_agency_code.localeCompare(b.implementing_agency_code, 'ja')} title="実施機関コード" dataIndex="implementing_agency_code" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.institution_number.localeCompare(b.institution_number, 'ja')} title="機関番号" dataIndex="institution_number" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.kana_name.localeCompare(b.kana_name, 'ja')} width={350} title="機関名カナ" dataIndex="kana_name" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.kanji_name.localeCompare(b.kanji_name, 'ja')} width={350} title="機関名" dataIndex="kanji_name" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormpersonA.localeCompare(b.SupportFormpersonA, 'ja')} title="個別A" align="center" dataIndex="SupportFormpersonA"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_individual_a']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormpersonB.localeCompare(b.SupportFormpersonB, 'ja')} title="個別B" align="center" dataIndex="SupportFormpersonB"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_individual_b']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormgroup.localeCompare(b.SupportFormgroup, 'ja')} title="ｸﾞﾙｰﾌﾟ" align="center" dataIndex="SupportFormgroup"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_group']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormphoneA.localeCompare(b.SupportFormphoneA, 'ja')} title="電話A" align="center" dataIndex="SupportFormphoneA"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_telephone_a']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormphoneB.localeCompare(b.SupportFormphoneB, 'ja')} title="電話B" align="center" dataIndex="SupportFormphoneB"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_phone_b']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormeMailA.localeCompare(b.SupportFormeMailAv, 'ja')} title="ﾒｰﾙA" align="center" dataIndex="SupportFormeMailA"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_email_a']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormeMailB.localeCompare(b.SupportFormeMailB, 'ja')} title="ﾒｰﾙB" align="center" dataIndex="SupportFormeMailB"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_email_b']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.option_remark.localeCompare(b.option_remark, 'ja')} title="オプション" dataIndex="option_remark" />
              <Table.Column
                key="action" align="center"
                title={(
                  <Button type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalAdd
                              columns={columns}
                              onFinishScreen={(output) => {
                                this.SaveData(output)
                                this.setState({
                                  childModal: {
                                    visible: false,
                                  }
                                })
                              }}
                            />
                        },
                      });
                    }} />
                )}
                render={(value, record) => (
                  <>
                    <Space>
                      <Button
                        size="small"
                        style={{ color: "green", marginBottom: "5px" }}
                        icon={<MoreOutlined />}
                        onClick={() => {
                          ImplementAgenciesMasterAction.index({ id: record.id })
                            .then(res => {
                              console.log(res)
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '550px',
                                  className: '',
                                  component:
                                    <ModalChange
                                      columns={columns}
                                      record={res}
                                      onUpdate={(output) => {
                                        this.SaveData(output)
                                        this.setState({
                                          childModal: {
                                            visible: false,
                                          }
                                        })
                                      }}
                                      onDelete={(output) => {
                                        const { id } = output.itemChange
                                        return ImplementAgenciesMasterAction.delete({ id: id })
                                          .then(() => {
                                            message.success('削除しました')
                                            this.loadMainSource()
                                            this.setState({
                                              childModal: {
                                                visible: false,
                                              }
                                            })
                                          })
                                          .catch(error => {
                                            console.log(error);
                                            this.setState({ isLoading: false });
                                            const res = error.response;
                                            if (!res || !res.data || !res.data.message) {
                                              message.error('エラーが発生しました');
                                              return;
                                            }

                                            message.error(res.data.message);
                                          });
                                      }}
                                    />
                                },
                              })
                            })
                            .catch(error => {
                              console.log(error);
                              const res = error.response;
                              if (!res || !res.data || !res.data.message) {
                                message.error('エラーが発生しました');
                                return;
                              }
                              message.error(res.data.message);
                            })
                        }}
                      ></Button>
                    </Space>
                  </>)}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          afterClose={this.state.childModal.afterClose}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS1407001_ImplementAgenciesMaster;
