import {
  AppstoreOutlined,
  HeartOutlined,
  AlipayCircleOutlined,
  SettingOutlined,
  FundProjectionScreenOutlined
} from "@ant-design/icons";

const MenuList = [
  // 1 基本情報
  {
    "title": "基本情報",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "保険者情報保守",
        "path": "/insurer-info-maintain/insurer-info-maintain",
        "priority": "4　",
      },
      {
        "title": "事業所情報保守",
        "path": "/office-info-maintain-directly/office-info-maintain",
        "priority": "5　",
      },
      {
        "title": "個人情報保守",
        "path": "/personal-info-maintain-directly/personal-info-maintain",
        "priority": "5　",
      },
      {
        "title": "契約情報保守",
        "path": "/contract-info-maintain/contract-info-maintain",
        "priority": "5　",
      },
      {
        "title": "契約情報一括処理",
        "path": "/contract-info-batch-process/contract-info-batch-process",
        "priority": "4　",
      },
      {
        "title": "受診情報再構成",
        "path": "/consult-info-reconstruction/consult-info-reconstruction",
        "priority": "4　",
      },
      {
        "title": "セット情報マスタ",
        "path": "/set-info-maintain/set-info-maintain",
        "priority": "5　",
      },
      {
        "title": "契約履歴一括作成",
        "path": "/creating-contract-history-together/create-contract-history-together",
        "priority": "4　",
      },
      {
        "title": "個人番号統合",
        "path": "/personal-number-integration/personal-number-integration",
        "priority": "2　",
      },
      {
        "title": "個人番号変更",
        "path": "/personal-number-migration/personal-number-migration",
        "priority": "2　",
      },
      {
        "title": "部署変更一括",
        "path": "/department-change-once/department-change-once",
        "priority": "2　",
      },
    ]
  },
  // 2 予約業務
  {
    "title": "予約業務",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "予約状況検索",
        "path": "/reserve-status-search/reserve-status-search",
        "priority": "5　",
      },
      {
        "title": "団体予約",
        "path": "/group-bookings/group-bookings",
        "priority": "4　",
      },
      {
        "title": "枠取予約",
        "path": "/frame-reserve/frame-reserve",
        "priority": "4　",
      },
      {
        "title": "個人予約",
        "path": "/personal-reserve-process/personal-reserve-process",
        "priority": "5　",
      },
      {
        "title": "予約一括変更",
        "path": "/reserves-bulk-changes/reserves-bulk-changes",
        "priority": "4　",
      },
      {
        "title": "受診者検索",
        "path": "/examinee-search/examinee-search",
        "priority": "4　",
      },
      {
        "title": "仮予約確定処理",
        "path": "/determine-tentative-reserve/determine-tentative-reserve",
        "priority": "",
      },
      {
        "title": "仮予約取込確定",
        "path": "/tentative-reserve-capture-confirmed/tentative-reserve-capture-confirmed",
        "priority": "",
      },
      {
        "title": "人間ドック仮予約",
        "path": "/dock-tentative-reserve/dock-tentative-reserve",
        "priority": "",
      },
    ]
  },
  // 3 事前準備
  {
    "title": "事前準備",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "資料管理保守",
        "path": "/document-manage-maintain/document-manage-maintain",
        "priority": "4　",
      },
      {
        "title": "予約関連資料出力",
        "path": "/document-batch-create/create-document-batch",
        "priority": "5　",
      },
      {
        "title": "受診情報一覧表",
        "path": "/consult-info-list/consult-info-list",
        "priority": "2　",
      },
      {
        "title": "住所ラベル[個人]",
        "path": "/user-document-item-maintain/address-label-personal",
        "priority": "3　",
      },
      // {
      //   "title": "住所ラベル[事業所]",
      //   "path": "/user-document-item-maintain/address-label-office",
      //   "priority": "3　",
      // },
      {
        "title": "住所ラベル[事業所受診]",
        "path": "/user-document-item-maintain/address-label-office-reserved",
        "priority": "3　",
      },
      {
        "title": "住所ラベル[保険者]",
        "path": "/user-document-item-maintain/address-label-insurer",
        "priority": "3　",
      },
      {
        "title": "検体ラベル",
        "path": "/specimen-label-print/specimen-label-print",
        "priority": "3　",
      },
    ]
  },
  // 4 連携関連
  {
    "title": "連携関連",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "検査依頼メイン",
        "path": "/inspect-request-main/inspect-request-main",
        "priority": "4　",
      },
      {
        "title": "検査結果ファイル取込",
        "path": "/inspect-acquisition-update-customized-version/inspect-acquisition-update-customized-version",
        "priority": "2　",
      },
      {
        "title": "オンライン指示",
        "path": "/online-instruction/online-instruction",
        "priority": "0　",
      },
      {
        "title": "検査値変換",
        "path": "/inspect-item-convert-internal/inspect-item-convert-internal",
        "priority": "1　",
      },
      {
        "title": "検査依頼変更マスタ保守",
        "path": "/inspect-request-convert-master-maintain/inspect-request-convert-master-maintain",
        "priority": "1　",
      },
      {
        "title": "OCRデータ取込",
        "path": "/ocr-capture-start-up/ocr-capture-start-up",
        "priority": "4　",
      },
      {
        "title": "患者情報取込[画面]",
        "path": "/patient-info-capture-screen/patient-info-capture-screen",
        "priority": "2　",
      },
      {
        "title": "Mirais電子カルテ送信",
        "path": "/mirais-electronic-medical-records-sent/mirais-electronic-medical-records-sent",
        "priority": "4　",
      },
      {
        "title": "Mirais単体送信",
        "path": "/mirais-single-transmission/mirais-single-transmission",
        "priority": "4　",
      },
      {
        "title": "Mirais自動送信照会",
        "path": "/mirais-transmission-inquiry/mirais-transmission-inquiry",
        "priority": "4　",
      },
      {
        "title": "[E-ｶﾙﾃ]一括送信",
        "path": "/e-medical-records-batch-transmission/e-medical-records-batch-transmission",
        "priority": "4　",
      },
      {
        "title": "[E-ｶﾙﾃ]単体送信",
        "path": "/e-medical-records-single-transmission/e-medical-records-single-transmission",
        "priority": "4　",
      },
      {
        "title": "[E-ｶﾙﾃ]検査依頼保守",
        "path": "/e-medical-records-inspect-request-maintain/e-medical-records-inspect-request-maintain",
        "priority": "1　",
      },
      {
        "title": "Mirais検査結果取込",
        "path": "/result-acquisition-process/result-acquisition-process",
        "priority": "1　",
      },
      {
        "title": "電子カルテ連携枠設定",
        "path": "/electronic-medical-records-linkage-frame-set/electronic-medical-records-linkage-frame-set",
        "priority": "3　",
      },
      // {
      //   "title": "バッチ処理",
      //   "submenu": [
      //     {
      //       "title": "常駐処理［患者］",
      //       "path": "/",
      //     },
      //     {
      //       "title": "常駐処理［取込］",
      //       "path": "/",
      //     },
      //   ]
      // },
    ]
  },
  // 5 受付業務
  {
    "title": "受付業務",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "窓口",
        "path": "/counter/counter",
        "priority": "5　",
      },
      {
        "title": "受付処理（一括）",
        "path": "/acceptance-batch-process/acceptance-batch-process",
        "priority": "4　",
      },
      {
        "title": "通過管理進捗状況",
        "path": "/passing-manage-progress/passing-manage-progress",
        "priority": "4　",
      },
      {
        "title": "通過管理設定",
        "path": "/passing-manage-settings/passing-manage-settings",
        "priority": "4　",
      },
      {
        "title": "通過管理一括抽出",
        "path": "/passing-manage-batch-extract/passing-manage-batch-extract",
        "priority": "4　",
      },
      {
        "title": "通過管理検査表示",
        "path": "/passing-control-inspects-display/passing-control-inspects-display",
        "priority": "4　",
      },
      {
        "title": "通過管理検査表示[診察介助]",
        "path": "/passing-control-inspects-display/passing-control-inspects-display2",
        "priority": "4　",
      },
      {
        "title": "通過管理CSV出力",
        "path": "/passing-manage-csv/passing-manage-csv",
        "priority": "4　",
      },
    ]
  },
  // 6 入力業務
  {
    "title": "入力業務",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "面談支援",
        "path": "/interview-support/interview-support",
        "priority": "1　",
      },
      {
        "title": "個人単位入力（結果入力）",
        "path": "/examinee-list/examinee-list",
        "priority": "1　",
      },
      {
        "title": "スプレッド入力",
        "path": "/spread-input/spread-input",
        "priority": "4　",
      },
      {
        "title": "未入力チェック",
        "path": "/not-input-check-category/not-input-check-category",
        "priority": "5　",
      },
      {
        "title": "一括判定",
        "path": "/collect-judge/collect-judge",
        "priority": "1　",
      },
      {
        "title": "進捗状況設定",
        "path": "/progress-setting/progress-set",
        "priority": "2　",
      },
      {
        "title": "読影所見入力",
        "path": "/radiography-finding-input/radiography-finding-input",
        "priority": "4　",
      },
      {
        "title": "読影所見送信",
        "path": "/radiography-findings-submit/radiography-findings-submit",
        "priority": "4　",
      },
      {
        "title": "読影判定保守",
        "path": "/radiography-judge-maintain/radiography-judge-maintain",
        "priority": "1　",
      },
      {
        "title": "読影者情報保守",
        "path": "/radiologists-info-maintain/radiography-info-maintain",
        "priority": "1　",
      },
      {
        "title": "読影検査保守",
        "path": "/radiography-inspect-maintain/radiography-inspect-maintain",
        "priority": "1　",
      }
    ]
  },
  // 07結果出力
  {
    "title": "結果出力",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "結果表一括出力（コース別自動選択）",
        "path": "/results-tbl-collect-output/result-tbl-batch-create",
        "priority": "5　",
      },
      {
        "title": "結果表一括出力（帳票別）",
        "path": "/create-result-tbl/create-result-tbl",
        "priority": "4　",
      },
      {
        "title": "結果連名簿作成",
        "path": "/communication-roster-output/communication-roster-output",
        "priority": "4　",
      },
      {
        "title": "労基５２条様式",
        "path": "/romoto-article52/romoto-article52",
        "priority": "4　",
      },
      {
        "title": "発送管理",
        "path": "/dispatch-manage/dispatch-manage",
        "priority": "2　",
      },
      {
        "title": "健診データ出力「CSV」",
        "path": "/medical-exam-data-output-csv/medical-exam-data-output-csv",
        "priority": "4　",
      },
      {
        "title": "印刷パラメータ保守",
        "path": "/print-param-maintain/print-param-maintain",
        "priority": "2　",
      },
      {
        "title": "結合様式設定",
        "path": "/binding-mode-setting/binding-mode-setting",
        "priority": "2　",
      },
      {
        "title": "コース別標準様式設定",
        "path": "/course-specific-stard-style-setting/course-specific-stard-style-setting",
        "priority": "2　",
      },
      {
        "title": "CSV作成パラメータ保守",
        "path": "/csv-create-param-maintain/csv-create-param-maintain",
        "priority": "2　",
      },
      {
        "title": "東振協変換処理",
        "path": "/east-fukyo-converse-process/east-fukyo-converse-process",
        "priority": "",
      },
    ]
  },
  // 08会計業務
  {
    "title": "会計業務",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "請求書発行",
        "path": "/invoice/invoice",
        "priority": "4　",
      },
      {
        "title": "請求統合",
        "path": "/billing-integration/billing-integration",
        "priority": "2　",
      },
      {
        "title": "団体入金",
        "path": "/organizations-payment/organizations-payment",
        "priority": "4　",
      },
      {
        "title": "入出金問合せ",
        "path": "/deposit-withdrawal-inquiry/deposit-withdrawal-inquiry",
        "priority": "2　",
      },
      {
        "title": "請求問合せ",
        "path": "/billing-inquiry/billing-inquiry",
        "priority": "2　",
      },
      {
        "title": "入出金一覧表",
        "path": "/deposit-withdrawal-list-output-instruction/deposit-withdrawal-list-output-instruction",
        "priority": "0　",
      },
      {
        "title": "入出金一覧出力",
        "path": "/payment-list-output/payment-list-output",
        "priority": "2　",
      },
      {
        "title": "未収金一覧表出力指示",
        "path": "/accounts-receivable-list-output-instruction/accounts-receivable-list-output-instruction",
        "priority": "2　",
      },
      {
        "title": "領収書事前発行",
        "path": "/receipt-pre-issue20/receipt-pre-issue",
        "priority": "1　",
      },
      {
        "title": "請求管理台帳",
        "path": "/billing-manage-ledger-instruction/billing-manage-ledger-instruction",
        "priority": "2　",
      },
      {
        "title": "総合健診請求集計",
        "path": "/mutual-aid-association-billing/comprehensive-medical-exam-billing",
        "priority": "2　",
      },
      {
        "title": "総合健診受診者報告",
        "path": "/mutual-aid-association-billing/table-comprehensive-medical-exam-report",
        "priority": "2　",
      },
      {
        "title": "市町村職員共済組合結果FD作成",
        "path": "/mutual-aid-association-billing/fd-create-municipal-mutual-aid-association",
        "priority": "2　",
      },
      {
        "title": "共済対象健コース保守",
        "path": "/mutual-aid-association-billing/mutual-aid-target-ken-course-maintain",
        "priority": "2　",
      },
      {
        "title": "共済組合請求項目保守",
        "path": "/mutual-aid-association-billing/mutual-aid-subject-billing-item-maintain",
        "priority": "2　",
      },
      {
        "title": "市町村職員共済組合部位所見変換保守",
        "path": "/mutual-aid-association-billing/municipal-mutual-aid-association-maintain",
        "priority": "2　",
      },
      {
        "title": "売上状況表",
        "path": "/sales-situation-table-instructs/sales-situation-table-instructs",
        "priority": "2　",
      },
    ]
  },
  // 09紹介状
  {
    "title": "紹介状",
    "icon": <AppstoreOutlined />,
    "submenu": [
      // {
      //   "title": "紹介状抽出",
      //   "path": "/introduce-letter-extract/introduce-letter-extract",
      //   "priority": "1　",
      // },
      {
        "title": "紹介状発行",
        "path": "/introduce-letter-issued-main/introduce-letter-issued-main",
        "priority": "1　",
      },
      {
        "title": "要精検者紹介状発行",
        "path": "/person-require-exam-introduce-letter/person-require-exam-introduce-letter",
        "priority": "4　",
      },
      {
        "title": "おたずね発行",
        "path": "/ask-issued/ask-issued",
        "priority": "4　",
      },
      {
        "title": "紹介状返送情報入力",
        "path": "/introduce-letter-return-info-input/introduce-letter-return-info-input",
        "priority": "4　",
      },
      {
        "title": "紹介状マスタ",
        "path": "/introduce-letter-master-maintain/introduce-letter-master-maintain",
        "priority": "2　",
      },
      {
        "title": "経過観察",
        "path": "/follow-up/follow-up",
        "priority": "2　",
      },
      {
        "title": "事後管理・追跡経過観察",
        "path": "/follow-up/postmanage-and-tracking-follow-up",
        "priority": "2　",
      },
      {
        "title": "コース・診療科別書式保守",
        "path": "/course-departmentspecific-format/course-departmentspecific-format",
        "priority": "",
      },
      {
        "title": "コース・診療科別書式保守(経過観察用)",
        "path": "/follow-up-letter/follow-up-letter-course-departmentspecific-format",
        "priority": "",
      },
    ]
  },
  // 10検査保守
  {
    "title": "検査保守",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "検査項目マスタ",
        // "path": "/inspect-item-master/inspect-item-master"
        "path": "/inspect-item-info-maintain/inspect-item-info-maintain",
        "priority": "4　",
      },
      {
        "title": "カテゴリ検査項目マスタ",
        "path": "/another-inspect-item-setting-category/another-inspect-item-setting-category",
        "priority": "4　",
      },
      {
        "title": "カテゴリーマスタ保守",
        "path": "/category-master-maintain/category-master-maintain",
        "priority": "2　",
      },
      {
        "title": "コース基本種別設定",
        "path": "/course-basic-type-setting/course-basic-type-setting",
        "priority": "4　",
      },
      {
        "title": "検査コメント情報保守",
        "path": "/exam-cmt-info-maintain/inspect-cmt-info-maintain",
        "priority": "4　",
      },
      {
        "title": "部位所見マスタ",
        "path": "/site-findings-master-maintain/site-findings-master-maintain",
        "priority": "4　",
      },
      {
        "title": "注意・指導コメント",
        // "path": "/caution-guide-matter-cmt-maintain/another-guide-cmt-maintain-category",
        "path": "/caution-guide-matter-cmt-maintain/caution-guide-matter-cmt-maintain",
        "priority": "2　",
      },
      {
        "title": "病名マスタ保守",
        "path": "/disease-name-master-maintain/disease-name-master-maintain",
        "priority": "2　",
      },
      {
        "title": "検査値自動計算保守",
        "path": "/inspect-value-calculate-auto-maintain/inspect-value-calculate-auto-maintain",
        "priority": "2　",
      },
      {
        "title": "検査基準値設定",
        "path": "/inspect-ref-value-setting/inspect-ref-value-setting",
        "priority": "2　",
      },
      {
        "title": "検査項目判定値マスタ",
        "path": "/inspect-item-judge-value-setting/inspect-item-judge-value-setting",
        "priority": "4　",
      },
      {
        "title": "判定レベル変換マスタ保",
        "path": "/determine-level-modify/determine-level-modify-master-coercive",
        "priority": "1　",
      },
      {
        "title": "正常値設定保守",
        "path": "/normal-value-setting-maintain/normal-value-setting",
        "priority": "2　",
      },
      {
        "title": "条件式コメント設定",
        "path": "/condition-express-cmt-setting/condition-express-cmt-setting",
        "priority": "2　",
      },
      {
        "title": "検査グループマスタ保守",
        "path": "/inspect-group-maintain/inspect-group-maintain",
        "priority": "2　",
      },
    ]
  },
  // 11統計業務
  {
    "title": "統計業務",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "総合判定分類表",
        "path": "/comprehensive-judge-classify-tbl/comprehensive-judge-classify-tbl",
        "priority": "2　",
      },
      {
        "title": "カテゴリ判定分類表",
        "path": "/category-determining-classify-tbl/category-determining-classify-tbl",
        "priority": "2　",
      },
      {
        "title": "項目判定分類表",
        "path": "/item-judge-classify-tbl/item-judge-classify-tbl",
        "priority": "2　",
      },
      {
        "title": "事業所受診実績状況",
        "path": "/office-visits-achievement-situation/office-visits-achievement-situation",
        "priority": "2　",
      },
      {
        "title": "検査変動情報出力",
        "path": "/inspect-variation-info-output/inspect-variation-info-output",
        "priority": "2　",
      },
      {
        "title": "個人情報CSV出力",
        "path": "/personal-info-csv-output/personal-info-csv-output",
        "priority": "2　",
      },
      {
        "title": "事業所情報CSV出力",
        "path": "/office-info-csv-output/office-info-csv-output",
        "priority": "2　",
      },
      {
        "title": "マスター覧出力",
        "path": "/master-list-output/master-list-output",
        "priority": "2　",
      },
    ]
  },
  // 12ユーザーツール
  {
    "title": "ユーザーツール",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "ユーザー帳票出力",
        "path": "/user-form-output/user-form-output",
        "priority": "1　",
      },
      {
        "title": "帳票作成・編集",
        "path": "/form-create-editing/form-create-editing",
        "priority": "1　",
      },
      {
        "title": "ユーザー帳票項目保守",
        "path": "/user-document-item-maintain/user-document-item-maintain",
        "priority": "1　",
      },
      // {
      //   "title": "住所ラベル[個人]",
      //   "path": "/user-document-item-maintain/address-label-personal",
      //   "priority": "3　",
      // },
    ]
  },
  // 13 特定健診
  {
    "title": "特定健診",
    "icon": <HeartOutlined />,
    "submenu": [
      {
        title: "受診券入力処理[一覧]",
        path: "/consult-ticket-input-process-list/consult-ticket-input-process-list",
        "priority": "4　",
      },
      {
        title: "特定健診決済処理",
        path: "/specific-medical-exam-settle-process/specific-medical-exam-settle-process",
        "priority": "4　",
      },
      {
        title: "特健データXML出力",
        path: "/specific-health-data-xml-output/specific-health-data-xml-output",
        "priority": "5　",
      },
      {
        title: "初回面談対象判定処理",
        path: "/init-interview-target-determine-process/init-interview-target-determine-process",
        "priority": "4　",
      }
    ]
  },
  // 14 特保指導
  {
    "title": "特保指導",
    "icon": <HeartOutlined />,
    "submenu": [
      {
        "title": "指導初回入力",
        "path": "/insure-guide-init-input/insure-guide-init-input",
        "priority": "4　",
      },
      {
        "title": "実施入力",
        "path": "/insure-guide-input/insure-guide-input",
        "priority": "4　",
      },
      {
        "title": "利用券入力処理",
        "path": "/vouchers-input-process-list/vouchers-input-process-list",
        "priority": "4　",
      },
      {
        "title": "特定保健指導ＸＭＬ出力",
        "path": "/specific-coercive-finger-xml-output/specific-insure-xml-output",
        "priority": "4　",
      },
      {
        "title": "特定保健指導決済処理",
        "path": "/specific-insure-guide-settle-process-list/specific-insure-guide-settle-process-list",
        "priority": "4　",
      },
    ]
  },
  // 15 特保保守
  {
    "title": "特保保守",
    "icon": <HeartOutlined />,
    "submenu": [
      {
        "title": "実施者マスタ",
        "path": "/implementor-master/implementor-master",
        "priority": "1　",
      },
      {
        "title": "実施場所マスタ",
        "path": "/implementation-location-master/implement-location-master",
        "priority": "1　",
      },
      {
        "title": "保健指導コース",
        "path": "/insure-guide-course/insure-guide-course",
        "priority": "1　",
      },
      {
        "title": "文章マスタ",
        "path": "/sentence-master/sentence-master",
        "priority": "1　",
      },
      {
        "title": "検査コメントマスタ",
        "path": "/inspect-cmt-master/inspect-cmt-master",
        "priority": "1　",
      },
      {
        "title": "支援項目",
        "path": "/support-item/support-item",
        "priority": "1　",
      },
      {
        "title": "検査項目マスタ",
        "path": "/inspect-item-master/inspect-item-master",
        "priority": "1　",
      },
      {
        "title": "文章分類マスタ",
        "path": "/document-classify-master/document-classify-master",
        "priority": "1　",
      },
      {
        "title": "実施区分マスタ",
        "path": "/implementation-division-master/implement-division-master",
        "priority": "1　",
      },
      {
        "title": "実施機関マスタ",
        "path": "/implement-agencies-master/implement-agencies-master",
        "priority": "1　",
      },
      {
        "title": "特健特保パラメータ保守",
        "path": "/specific-health-tokuho-param-maintain/specific-health-tokuho-param-maintain",
        "priority": "1　",
      },
      {
        "title": "ＸＭＬパラメータ保守",
        "path": "/xml-param-maintain/xml-param-maintain",
        "priority": "1　",
      },
      {
        "title": "契約マスタ保守",
        "path": "/contract-master-maintain/contract-master-maintain",
        "priority": "5　",
      },
      {
        "title": "取りまとめ区分",
        "path": "/compiled-classify/compiled-classify",
        "priority": "1　",
      },
      {
        "title": "契約取りまとめ保守",
        "path": "/contract-compiled-maintain/contract-compiled-maintain",
        "priority": "1　",
      },
      {
        "title": "特健特保オプション設定",
        "path": "/specific-health-tokuho-options-file/specific-health-tokuho-options-file",
        "priority": "1　",
      },
      {
        "title": "ＪＬＡＣ１０コードマスタ",
        "path": "/xml-medical-exam-item-master/xml-medical-exam-item-master",
        "priority": "1　",
      },
      {
        "title": "データ範囲チェック",
        "path": "/data-range-check/data-range-check",
        "priority": "1　",
      },
      {
        "title": "保険者番号変換マスタ",
        "path": "/insurer-number-conversion-master/insurer-number-conversion-master",
        "priority": "1　",
      }
    ]
  },
  // 16 協会けんぽ報告
  {
    "title": "協会けんぽ報告",
    "icon": <AlipayCircleOutlined />,
    "submenu": [
      {
        "title": "協会けんぽデータ作成",
        "path": "/associate-insure-data-create/associate-insure-data-create",
        "priority": "5　",
      },
      {
        "title": "協会けんぽ受付情報訂正",
        "path": "/association-acceptance-info-correct/association-acceptance-info-correct",
        "priority": "1　",
      },
      {
        "title": "協会けんぽ必要項目",
        "path": "/associate-insure-required-item/associate-insure-required-item",
        "priority": "1　",
      },
      {
        "title": "協会けんぽ資格確認",
        "path": "/associate-insure-qualify-confirm/associate-insure-qualify-confirm",
        "priority": "1　",
      },
      {
        "title": "協会けんぽ契約金額変更",
        "path": "/associate-insure-money-amount-setting/associate-insure-money-amount-setting",
        "priority": "1　",
      },
      {
        "title": "協会けんぽ設定",
        "path": "/associate-insure-param-maintain/associate-insure-param-maintain",
        "priority": "1　",
      },
    ]
  },
  // 17 システム保守
  {
    "title": "システム保守",
    "icon": <SettingOutlined />,
    "submenu": [
      {
        "title": "施設設定",
        "path": "/facility-consumption-tax-setting/facility-consumption-tax-setting",
        "priority": "5　",
      },
      {
        "title": "消費税設定",
        "path": "/facility-consumption-tax-setting/consumption-tax-setting",
        "priority": "5　",
      },
      {
        "title": "予約表示項目設定",
        "path": "/reserves-display-item-setting/reserves-display-item-setting",
        "priority": "5　",
      },
      {
        "title": "基準人数設定情報",
        "path": "/ref-people-num-setting-info/ref-people-num-setting-info",
        "priority": "2　",
      },
      {
        "title": "基本時間帯",
        "path": "/basic-period-time/basic-period-time",
        "priority": "2　",
      },
      {
        "title": "特定日人数設定",
        "path": "/specific-date-people-num-setting/specific-date-people-num-setting",
        "priority": "5　",
      },
      {
        "title": "休診日設定",
        "path": "/non-consult-date-setting/non-consult-date-setting",
        "priority": "4　",
      },
      {
        "title": "法定休日設定",
        "path": "/bank-holiday-setting",
        "priority": "4　",
      },
      {
        "title": "進捗情報保守",
        "path": "/progress-info-maintain/progress-info-maintain",
        "priority": "1　",
      },
      {
        "title": "施設(院内/外)情報保守",
        "path": "/facility-create-hospital-outside-create-info-maintain/facility-hospital-outside-info-maintain",
        "priority": "4　",
      },
      {
        "title": "年齢管理情報保守",
        "path": "/age-manage-info-maintain/age-manage-info-maintain",
        "priority": "4　",
      },
      {
        "title": "プリンタ情報保守",
        "path": "/printer-info-maintain/printer-info-maintain",
        "priority": "1　",
      },
      {
        "title": "ｵﾌﾟｼｮﾝ情報保守",
        "path": "/optional-info-maintain/optional-info-maintain",
        "priority": "1　",
      },
      {
        "title": "ﾕｰｻﾞｰｵﾌﾟｼｮﾝ情報保守",
        "path": "/user-option-info-maintain/user-option-info-maintain",
        "priority": "1　",
      },
      {
        "title": "ﾕｰｻﾞｰﾊﾟﾗﾒｰﾀ保守",
        "path": "/user-param-maintain/user-param-maintain",
        "priority": "1　",
      },
      {
        "title": "自動判定管理",
        "path": "/auto-judgment-management/auto-judgment-management",
        "priority": "1　",
      },
    ]
  },
  // 18 ZZ.その他
  {
    "title": "その他",
    "icon": <SettingOutlined />,
    "submenu": [
      {
        "title": "地区情報保守",
        "path": "/district-info-maintain/district-info-maintain",
        "priority": "1　",
      },
      {
        "title": "予約関連ファイル出力",
        "path": "/reserves-related-files-output/reserves-related-files-output",
        "priority": "1　",
      },
      {
        "title": "ﾃｽﾄ用受診情報作成",
        "path": "/create-test-for-medical-exam-info/create-test-for-medical-exam-info",
        "priority": "1　",
      },
      {
        "title": "立上資料作成",
        "path": "/rising-document-create/rising-document-create",
        "priority": "1　",
      },
      {
        "title": "個人情報保護",
        "path": "/protect-personal-info/protect-personal-info",
        "priority": "1　",
      },
      {
        "title": "データ一括入出力",
        "path": "/csv-input-output/csv-input-output",
        "priority": "1　",
      },
      {
        "title": "一括自動計算",
        "path": "/bulk-auto-calculate/bulk-auto-calculate",
        "priority": "1　",
      },
      {
        "title": "検査コメント関係",
        "path": "/space-trimming/space-trimming",
        "priority": "1　",
      },
      {
        "title": "リカバリ処理関係",
        "path": "/recovery-processes/recovery-processes",
        "priority": "",
      },
      {
        "title": "ログ照会",
        "path": "/log-display/log-display",
        "priority": "2　",
      },
      {
        "title": "カルテコ連携CSV出力",
        "path": "/karteco-cooperation-csv-output/karteco-cooperation-csv-output",
        "priority": "4　",
      },
      {
        "title": "カルテコ連携対象マスタ",
        "path": "/karteco-cooperation-target-master/karteco-cooperation-target-master",
        "priority": "4　",
      },
      {
        "title": "予約状況検索カスタム",
        "path": "/reserve-status-search-custom/reserve-status-search-custom",
        "priority": "",
      },
      {
        "title": "予約状況検索カスタム2",
        "path": "/reserve-status-search-custom/reserve-status-search-custom2",
        "priority": "",
      },
      {
        "title": "3点認証チェック",
        "path": "/authentication-check/authentication-check",
        "priority": "",
      },
      {
        "title": "企業ヒアリングシート",
        "path": "/corporate-hearing-sheet/corporate-hearing-sheet",
        "priority": "",
      },
      {
        "title": "仮予約",
        "path": "/provisional-reservation/provisional-reservation",
        "priority": "",
      },
      {
        "title": "人間ドックカレンダー",
        "path": "/medical-checkup-calendar/medical-checkup-calendar",
        "priority": "",
      },
      {
        "title": "放射線所見取込",
        "path": "/capture-radiological-findings/capture-radiological-findings",
        "priority": "",
      },
      {
        "title": "保健指導進捗管理",
        "path": "/progress-management/progress-management",
        "priority": "",
      },
      {
        "title": "オプション設定",
        "path": "/option-setting/option-setting",
        "priority": "",
      },
      {
        "title": "大分県庁がん健診",
        "path": "/cancer-screening-csv-output/cancer-screening-csv-output",
        "priority": "",
      },
      {
        "title": "特定健診・特定保健指導改正",
        "path": "/specific-medical-examination-revision-2024/specific-medical-examination-revision-2024",
        "priority": "",
      },
      {
        "title": "複合判定マスタ保守",
        "path": "/complex-decision-master-maintenance/complex-decision-master-maintenance",
        "priority": "",
      },
    ]
  },
  // 19 Mock モック
  {
    "title": "モック",
    "icon": <FundProjectionScreenOutlined />,
    "submenu": [
      {
        "title": "紹介状抽出保守",
        "path": "/mock/introduce-letter-extract-maintain",
        "priority": "",
      },
      {
        "title": "人間ドックカレンダー",
        "path": "/mock/medical-checkup-calendar-mock",
        "priority": "",
      },
      {
        "title": "窓口",
        "path": "/mock/counter-mock",
        "priority": "",
      },
      {
        "title": "仮予約取込確定",
        "path": "/mock/tentative-reserve-capture-confirmed-mock",
        "priority": "",
      },
      {
        "title": "契約情報保守",
        "path": "/mock/contract-info-maintain-mock",
        "priority": "",
      },
      {
        "title": "通過管理進捗状況",
        "path": "/mock/passing-manage-progress-mock",
        "priority": "",
      },
      {
        "title": "未入力チェック",
        "path": "/mock/not-input-check-category-mock",
        "priority": "",
      },
    ]
  }
];

export default MenuList;
