import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Menu, Select, Space, Table, Dropdown, message, Modal, Button } from 'antd'
import { MoreOutlined, PlusOutlined } from '@ant-design/icons'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS2699155_CalculateFormulaInput from './WS2699155_CalculateFormulaInput'
import WS2699183_Copy from './WS2699183_Copy'
import InspectValueCalculateAutoMaintainAction from 'redux/InspectionMaintenance/InspectValueCalculateAutoMaintain/InspectValueCalculateAutoMaintain.actions'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
class WS2699142_InspectValueCalculateAutoMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '検査値自動計算保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      exam_name: '',
      dataSource: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 検査値自動計算の値一覧を取得
   */
  index() {
    const data = {
      SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      InspectItemsSearch: this.formRef.current?.getFieldValue('InspectItemsSearch'),
      GenderSearch: this.formRef.current?.getFieldValue('GenderSearch')
    }
    InspectValueCalculateAutoMaintainAction.index(data)
      .then(res => {
        this.setState({
          dataSource: res
        })
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }

      })
  }

  /**
   * 削除
   * @param {*} record
   */
  delete = (record) => {
    const params = {
      id: record.id
    }
    InspectValueCalculateAutoMaintainAction.delete(params)
      .then(res => {
        if (res) {
          // データ再取得
          this.index()
        }
      })
  }

  /**
   * 計算式入力 モーダル
   */
  showWS2699155_CalculateFormulaInput = (record = null) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2699155_CalculateFormulaInput
            Li_RowSelect={record}
            onFinishScreen={(output) => {
              this.index()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  render() {
    return (
      <div className='inspect-value-calculate-auto-maintain'>
        <Card title='検査値自動計算保守'>
          <Form
            ref={this.formRef}
            autoComplete='off'
            initialValues={{
              GenderSearch: '',
              SearchChar: '',
              InspectItemsSearch: ''
            }}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>

                <Form.Item
                  name='SearchChar'
                  label='検索'
                >
                  <Input
                    maxLength={100}
                    onPressEnter={() => this.index()}
                  />
                </Form.Item>

                <Space>
                  <Form.Item
                    name='InspectItemsSearch'
                    label='検査コード' >
                    <Input.Search
                      allowClear
                      className='input-search-size-number-allow-clear-8'
                      onPressEnter={(e) => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS0271001_InspectItemSearchQuerySingle
                                InspectCode={e.target.value}
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    InspectItemsSearch: output.recordData.test_item_code,
                                    exam_name: output.recordData.exam_name
                                  })
                                  this.forceUpdate()
                                  this.closeModal()
                                  this.index()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                      onSearch={(value, event) => {
                        if (event.target.localName === 'input') {
                          // ✕ボタン
                          this.formRef.current.setFieldsValue({
                            InspectItemsSearch: '',
                            exam_name: '',
                          })
                          this.forceUpdate()
                          this.index()
                        } else {
                          // 虫眼鏡マーク
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              component: (
                                <WS0271001_InspectItemSearchQuerySingle
                                  InspectCode={value}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      InspectItemsSearch: output.recordData.test_item_code,
                                      exam_name: output.recordData.exam_name
                                    })
                                    this.forceUpdate()
                                    this.closeModal()
                                    this.index()
                                  }}
                                />
                              ),
                            },
                          })
                        }
                      }}
                    />
                  </Form.Item>
                  <div style={{ marginTop: 5 }}>
                    {this.formRef.current?.getFieldValue('exam_name')}
                  </div>
                </Space>

                <Form.Item name='GenderSearch' label='性別' >
                  <Select
                    style={{ width: '100px' }}
                    onChange={() => this.index()}
                  >
                    <Select.Option value={''}>　　</Select.Option>
                    <Select.Option value={0}>共通</Select.Option>
                    <Select.Option value={1}>男性</Select.Option>
                    <Select.Option value={2}>女性</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: resizableTableScroll(100) }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.showWS2699155_CalculateFormulaInput(record)
                    }
                  }
                }}
              >
                <Table.Column
                  title='検査コード'
                  dataIndex='exam_item'
                  className='column-size-10'
                  render={(value) => (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )}
                />
                <Table.Column title='名称' dataIndex='exam_name' />
                <Table.Column
                  title='性別'
                  dataIndex='Gender'
                  className='column-size-4'
                />
                <Table.Column
                  title='端数処理'
                  dataIndex='Rounding'
                  className='column-size-8'
                />
                <Table.Column
                  title='有効'
                  dataIndex='EffectivePresence'
                  className='column-size-4'
                  align='center'
                />
                <Table.Column
                  title='入力'
                  dataIndex='InputPresence'
                  className='column-size-4'
                  align='center'
                />
                <Table.Column title='計算式' dataIndex='formula' />
                <Table.Column title='備考' dataIndex='remark' />
                <Table.Column
                  width={40}
                  align='center'
                  title={
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS2699155_CalculateFormulaInput
                                onFinishScreen={(output) => {
                                  this.index()
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }
                    />
                  }
                  render={(text, record) => (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='menu-1'
                            onClick={() => (
                              this.showWS2699155_CalculateFormulaInput(record)
                            )}>
                            修正
                          </Menu.Item>
                          <Menu.Item
                            key='menu-2'
                            onClick={() => {
                              Modal.confirm({
                                content: '削除を行いますか?',
                                onOk: () => {
                                  this.delete(record)
                                },
                              })
                            }}>
                            削除
                          </Menu.Item>
                          <Menu.Item
                            key='menu-3'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  centered: true,
                                  width: 400,
                                  component: (
                                    <WS2699183_Copy
                                      Li_InspectItemCopySource={record.exam_item}
                                      Li_GenderCopySource={record?.sex}
                                      Li_TableCheck={this.state.dataSource}
                                      onFinishScreen={(output) => {
                                        this.index()
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}>
                            複写
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2699142_InspectValueCalculateAutoMaintain)
