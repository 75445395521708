import axios from 'configs/axios';

const apiPaths = {
  getDataSupportItem: '/api/support-item/support-item',
  saveAndUpdateSupportItem: '/api/support-item/support-item/save-and-update',
  deleteSupportItem: '/api/support-item/support-item/delete',
  batchSaveItem: '/api/support-item/support-item/batch-save'
};

const SupportItemService = {
  async getDataSupportItemService() {
    return axios.get(apiPaths.getDataSupportItem);
  },
  async saveAndUpdateSupportItemService(params) {
    return axios.post(apiPaths.saveAndUpdateSupportItem, params)
  },
  async deleteSupportItemService(params) {
    return axios.delete(apiPaths.deleteSupportItem, { params })
  },
  async batchSaveItemService(data) {
    return axios.post(apiPaths.batchSaveItem, data)
  },
};

export default SupportItemService;