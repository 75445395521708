import React from "react";
import { connect } from "react-redux";

import { Card, Button, message, Table } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import moment from "moment";
import XmlMedicalExamItemMasterSaveAction from 'redux/SpecificInsureMaintenance/XmlMedicalExamItemMaster/XmlMedicalExamItemMaster.action.js'


class WS1313008_DateDisplay extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '日付表示';

    this.state = {
      dataSource: [],
      selectedRow: {},
      isLoadingTable: false,
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }


  // loadScreenData = () => {
  //   this.setState({ isLoadingTable: true });
  //   VenusApiRoutesV2.callApi("API001313008001", {})
  //     .then(res => {
  //       console.log(res);
  //       this.setState({ dataSource: res.Date_List, isLoadingTable: false });
  //     });
  // };

  loadScreenData(params) {
    this.setState({ isLoadingTable: true });
    XmlMedicalExamItemMasterSaveAction.getscreendata(params)
      .then(res => {
        this.setState({ dataSource: res.Date_List, isLoadingTable: false });
      });
    console.log(params)
  }

  onSelect(record) {
    if (this.props.onFinishScreen && record.date_of_adoption_history_on) {
      // this.props.onFinishScreen({ selectedDate: moment(record.date_of_adoption_history_on).format("YYYY/MM/DD") });
      this.props.onFinishScreen(moment(record.date_of_adoption_history_on).format("YYYY/MM/DD"));
    }
    // if (this.props.onFinishScreen && this.state.date_of_adoption_history_on) {
    //   this.props.onFinishScreen({ selectedDate: moment(this.state.date_of_adoption_history_on).format("YYYY/MM/DD") });
    // }

  }

  render() {
    return (
      <div className="date-display">
        <Card title="日付表示">
          <Table
            bordered
            dataSource={this.state.dataSource ?? []}
            loading={this.state.isLoadingTable}
            pagination={true}
            rowKey={(record) => record.date_of_adoption_history_on}
            //下記必要か？
            disabled={!this.state.selectedRow.date_of_adoption_history_on}
            onRow={(record, rowIndex) => ({
              onDoubleClick: () => {
                this.onSelect(record)
              }
            })}
          //既存のコード
          // rowSelection={{
          //   type: "radio",
          //   onChange: (selectedRowKeys, selectedRows) => {
          //     this.setState({
          //       selectedRow: selectedRows[0]
          //     })
          //   }
          // }}
          >
            <Table.Column dataIndex="date_of_adoption_history_on" />
          </Table>
          {/* <Button
            type="primary"
            style={{ float: "right" }}
            disabled={!this.state.selectedRow.date_of_adoption_history_on}
            onClick={() => this.onSelect()}
          >
            選択
          </Button> */}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313008_DateDisplay);
