import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { Card, Form, Input, Select, Button, Dropdown, Menu, Modal, Tooltip, Table, message } from 'antd'
import { SearchOutlined, PlusOutlined, InfoOutlined, MoreOutlined } from '@ant-design/icons'

import {
  getScreenReservesBulkChangesAction, ExtractAction, InspectChanges_F09_01Action, GetNameOfficeCodeAction,
  InspectChanges_F09_02Action, Cancel_F10_01Action, Cancel_F10_02Action, ReserveChange_F11_01Action, ReserveChange_F11_02Action, excelReportAction
} from 'redux/ReservationBusiness/ReservesBulkChanges/ReservesBulkChanges.action'

import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS2786002_ConditionAddSubPlus from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786002_ConditionAddSubPlus.jsx'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS2788013_TargetSelectSub from 'pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS2788013_TargetSelectSub.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx'
import WS2556015_MoneyAmountInput from 'pages/YK_ReservationBusiness/V5YK0005000_ReservesBulkChanges/WS2556015_MoneyAmountInput.jsx'
import WS2556064_ReserveChange from 'pages/YK_ReservationBusiness/V5YK0005000_ReservesBulkChanges/WS2556064_ReserveChange.jsx'
import WS2533001_ConfirmSub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2533001_ConfirmSub.jsx'
import PropTypes from 'prop-types'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import { download_file } from 'helpers/CommonHelpers'

class WS2556001_ReservesBulkChanges extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Li_ExtractDateF: PropTypes.any,
    LnkinExtractDateT: PropTypes.any,
    Li_ExtractOffice: PropTypes.any,
    Li_ExtractBranchStore: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '予約一括変更'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      FacilityType: [],
      message: '',
      dataSource: [],
      isLoading: false,
      selectedRows: [],
      selectedRowKeys: [],
      office_kanji_name: '',
      contract_short_name: '',
      colorConditionAddBtn: 163,
      initObj: {},
      objChild: {
        record: [],
        recordsID: []
      },
      initParams: {
        Facility: 0,
        DateFChar: '',
        DateTChar: '',
        OfficeCode: '',
        BranchStoreCode: '',
        CourseCode: '',
        KeyInfo: '',
      },
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      conditionAddData: null,
      isSearch: false,
    }
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.loadInitData()
  }

  loadInitData = () => {
    getScreenReservesBulkChangesAction()
      .then(res => {
        this.setState({
          FacilityType: res?.data?.FacilityType,
          initObj: res.data,
          initParams: {
            ...this.state.initParams,
            KeyInfo: res.data.KeyInfo
          }
        })
        const { Li_MenuOption, Li_ExtractDateF, LnkinExtractDateT, Li_ExtractOffice, Li_ExtractBranchStore } = this.props
        if (Li_MenuOption || Li_ExtractDateF || LnkinExtractDateT || Li_ExtractOffice || Li_ExtractBranchStore) {
          let params = {
            ...this.state.initParams,
            DateFChar: Li_ExtractDateF || '',
            DateTChar: LnkinExtractDateT || '',
            OfficeCode: Li_ExtractOffice || '',
            BranchStoreCode: Li_ExtractBranchStore || '',
          }
          this.setState({ initParams: { ...params } })
          this.formRef?.current?.setFieldsValue({
            DateFChar: moment(Li_ExtractDateF),
            DateTChar: moment(LnkinExtractDateT),
            OfficeCode: Li_ExtractOffice || '',
            BranchStoreCode: Li_ExtractBranchStore || '',
          })
          this.loadData(params)
        }
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  loadData = (params) => {
    this.setState({
      // isLoading: true,
      objChild: { record: [], recordsID: [] }
    })
    ExtractAction(params)
      .then((res) => {
        if (res.data)
          this.setState({
            message: res.data.message,
            isLoading: false,
            isSearch: true,
            dataSource: res.data,
            selectedRowKeys: []
          })
      })
      .catch(err => {
        this.setState({ isLoading: false })
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      })
      .finally(() => this.setState({ message: '' }))
  }

  InspectChanges_F09 = () => {
    InspectChanges_F09_01Action()
      .then(res => {
        if (res?.data?.message === 'WS2556015_MoneyAmountInput') {
          let dateF = this.formRef?.current?.getFieldValue('DateFChar')
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 800,
              component: (
                <WS2556015_MoneyAmountInput
                  Li_ContractType={0}
                  Li_ContractOrgCode={''}
                  Li_ContractStartDate={'0000/00/00'}
                  Li_ContractNum={0}
                  Li_Date={moment(dateF, 'YYYY/MM/DD', true).isValid() ? moment(dateF).format('YYYY/MM/DD') : null}
                  Lio_NewAndDelete={0}
                  Lio_ContractPriority={true}
                  Lio_Tax={0.10}
                  Lo_StsEnter={false}
                  onFinishScreen={({ Lio_NewAndDelete, Lio_ContractPriority, Lio_Tax, Lo_StsEnter, values }) => {
                    if (Lo_StsEnter) {
                      InspectChanges_F09_02Action({
                        Lio_NewAndDelete,
                        StsContractPriority: Lio_ContractPriority,
                        Tax: Lio_Tax,
                        StsEnter: Lo_StsEnter,
                        selectedRows: this.state.selectedRows,
                        values: values
                      })
                    }
                    this.closeModal()
                  }}
                />
              ),
            },
          })
        }
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  Cancel_F10 = () => {
    if (this.state.selectedRowKeys.length > 0) {
      Cancel_F10_01Action()
        .then((res) => {
          if (res?.data?.message === 'WS2533001_ConfirmSub') {
            let props = {
              Lo_ReturnBookReview: this.state.initObj.StsConfirmN,
              Li_Title: '【予約取消】受診情報を取り消しますか？',
            }
            this.Modal_WS2533001_ConfirmSub(props, 'F10')
          }
        })
        .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
    }
  }

  ReserveChange_F11 = () => {
    ReserveChange_F11_01Action()
      .then((res) => {
        if (res?.data?.message === 'WS2556064_ReserveChange') {
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 600,
              component: (
                <WS2556064_ReserveChange
                  Lo_StsRun={this.state.initObj.StsConfirmL}
                  selectedRows={this.state.selectedRows}
                  onFinishScreen={(output) => {
                    this.closeModal()
                    if (output.Lo_StsRun && this.state.selectedRowKeys.length > 0) {
                      let props = {
                        Lo_ReturnBookReview: this.state.initObj.StsConfirmL,
                        Li_Title: '【予約変更】受診情報を変更しますか？',
                        CourseCode: output.CourseCode,
                        Date: output.Date,
                        TimeZone: output.TimeZone,
                        FacilityType: output.FacilityType,
                        ContractType: output.ContractType,
                        ContractOrgCode: output.ContractOrgCode,
                        ContractStartDate: output.ContractStartDate,
                        ContractNum: output.ContractNum
                      }
                      this.Modal_WS2533001_ConfirmSub(props, 'F11')
                    }
                  }}
                />
              ),
            },
          })
        }
      })
      .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
  }

  Modal_WS2533001_ConfirmSub = (props, event) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2533001_ConfirmSub
            Lo_ReturnBookReview={this.state.initObj.StsConfirmL}
            Li_Title={props.Li_Title}
            list_id={this.state.selectedRowKeys}
            BulkChangesFlg={true}
            CourseCode={props.CourseCode}
            Date={props.Date}
            TimeZone={props.TimeZone}
            FacilityType={props.FacilityType}
            ContractType={props.ContractType}
            ContractOrgCode={props.ContractOrgCode}
            ContractStartDate={props.ContractStartDate}
            ContractNum={props.ContractNum}
            onFinishScreen={({ Lo_ReturnBookReview }) => {
              if (Lo_ReturnBookReview === 6) {
                const params = {
                  ...this.state.initParams,
                  Facility: this.formRef?.current?.getFieldValue('Facility'),
                  DateFChar: moment(this.formRef?.current?.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
                  DateTChar: moment(this.formRef?.current?.getFieldValue('DateTChar')).format('YYYY/MM/DD'),
                  OfficeCode: this.formRef?.current?.getFieldValue('OfficeCode'),
                  BranchStoreCode: this.formRef?.current?.getFieldValue('BranchStoreCode'),
                  CourseCode: this.formRef?.current?.getFieldValue('CourseCode')
                }
                event === 'F10'
                  ? Cancel_F10_02Action({
                    StsConfirmN: Lo_ReturnBookReview,
                    list_id: this.state.selectedRowKeys
                  }).then(() => {
                    Modal.info({
                      content: '削除しました。',
                      okText: 'OK',
                      onOk: () => { this.loadData(params) }
                    })
                  })
                  : ReserveChange_F11_02Action({ StsConfirmN: Lo_ReturnBookReview, list_id: this.state.selectedRowKeys, props }).then(() => this.loadData(params))
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  onSelectedData = (arr) => {
    let recordsID = arr?.map(item => item.id ? item.id : null)
    this.setState({
      objChild: {
        ...this.state.objChild,
        record: arr,
        recordsID: recordsID
      }
    })
  }

  setStateInitParams = (value, name) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        [name]: value
      }
    })
    if (name === 'OfficeCode' || name === 'BranchStoreCode') {
      this.setState({ office_kanji_name: '' })
    } else if (name === 'CourseCode') {
      this.setState({ contract_short_name: '' })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  onFinish = () => {
    const params = {
      DateFChar: moment(this.formRef.current?.getFieldValue('DateFChar')).format('YYYY/MM/DD'),
      DateTChar: moment(this.formRef.current?.getFieldValue('DateTChar')).format('YYYY/MM/DD'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode') ?? '',
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') ?? '',
      CourseCode: this.formRef.current?.getFieldValue('CourseCode') ?? '',
      Facility: this.formRef.current?.getFieldValue('Facility') ?? '',
      KeyInfo: this.state.KeyInfo ?? '',
      conditionAddData: this.state.conditionAddData ?? {}
    }

    this.loadData(params)
  }

  renderTargetSub() {
    return (
      <WS2788013_TargetSelectSub
        isSearch={this.state.isSearch}
        message={this.state.message}
        onSelectedData={(output) => this.onSelectedData(output)}
        params={this.formRef.current?.getFieldValue()}
        OutputOrder={1}
        onSelectedRowKeys={(output) => {
          this.setState({ selectedRowKeys: output })
        }}
      />
    )
  }

  getNameOfficeCode = (objChange) => {
    let params = {
      OfficeCode: this.state.initParams.OfficeCode,
      BranchStoreCode: this.state.initParams.BranchStoreCode
    }
    GetNameOfficeCodeAction({ ...params, ...objChange })
      .then((res) => {
        this.setState({ office_kanji_name: res?.data?.office_kanji_name || '' })
      })
      .catch(err => message.error(err?.response?.data?.message || 'エラーが発生しました'))
  }

  excelReport(record) {
    console.log(record)

    let datas = []
    let newDatas = {
      W1_course_level: record.W1_course_level,
      W1_reserve_num: record.W1_reserve_num
    }
    datas.push(newDatas)

    let params = {
      visitData: datas
    }
    excelReportAction(params)
      .then((res) => {
        download_file(res)
      })
  }

  render() {
    return (
      <div className='reserves-bulk-changes'>
        <Form ref={this.formRef} onFinish={this.onFinish} initialValues={{ Facility: 0, DateFChar: moment(), DateTChar: moment() }}>
          <Card title='予約一括変更'>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='DateFChar' label='日　付'>
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          onChange={(date, dateString) => this.setStateInitParams(dateString, 'DateFChar')} />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='DateTChar'>
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          onChange={(date, dateString) => this.setStateInitParams(dateString, 'DateTChar')} />
                      </Form.Item>
                    </div>

                    <Form.Item name='Facility' label='施　設' >
                      <Select style={{ width: '120px' }} onChange={(value) => this.setStateInitParams(value, 'Facility')}>
                        {this.state.FacilityType?.map((item, index) => (
                          <Select.Option key={index + item.value} value={item.key}>{item.value}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item name='OfficeCode' label='事業所' >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-4'
                          style={{ textAlign: 'right' }}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1200,
                                component: (
                                  <WS0247001_OfficeInfoRetrievalQuery
                                    Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                    onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name, recordData }) => {
                                      this.formRef.current.setFieldsValue({
                                        OfficeCode: Lio_OfficeCode,
                                        BranchStoreCode: Lio_BranchStoreCode !== 0 ? Lio_BranchStoreCode : null,
                                        office_kanji_name: Lo_Kanji_Name
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                        initParams: {
                                          ...this.state.initParams,
                                          OfficeCode: Lio_OfficeCode
                                        },
                                        office_kanji_name: Lo_Kanji_Name
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                BranchStoreCode: '',
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, Lo_Kanji_Name, recordData }) => {
                                        this.formRef.current.setFieldsValue({
                                          OfficeCode: Lio_OfficeCode,
                                          BranchStoreCode: Lio_BranchStoreCode !== 0 ? Lio_BranchStoreCode : null,
                                          office_kanji_name: Lo_Kanji_Name
                                        })
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                          initParams: {
                                            ...this.state.initParams,
                                            OfficeCode: Lio_OfficeCode
                                          },
                                          office_kanji_name: Lo_Kanji_Name
                                        })
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='BranchStoreCode' >
                        <Input
                          readOnly
                          style={{ textAlign: 'right' }}
                          className='input-search-size-2'
                        />
                      </Form.Item>
                      <Form.Item name='office_kanji_name'>
                        <div>{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item name='CourseCode' label='コース' style={{ marginBottom: 0 }}>
                        <Input.Search style={{ width: '120px' }}
                          allowClear={true}
                          onPressEnter={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    Li_CourseName={this.formRef.current?.getFieldValue('CourseCode')}
                                    onFinishScreen={({ Lo_CourseCode, recordData }) => {
                                      this.formRef.current.setFieldsValue({
                                        CourseCode: Lo_CourseCode,
                                        contract_short_name: recordData.course_name_short_name
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                        contract_short_name: recordData.course_name_short_name
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                contract_short_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 800,
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      Li_CourseName={this.formRef.current?.getFieldValue('CourseCode')}
                                      onFinishScreen={({ Lo_CourseCode, recordData }) => {
                                        this.formRef.current.setFieldsValue({
                                          CourseCode: Lo_CourseCode,
                                          contract_short_name: recordData.course_name_short_name
                                        })
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                          contract_short_name: recordData.course_name_short_name
                                        })
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='contract_short_name' style={{ marginBottom: 0 }}>
                        <div>{this.formRef.current?.getFieldValue('contract_short_name')}</div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => { this.onFinish() }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>

                    <Tooltip title='詳細な条件追加'>
                      <Button
                        size='small'
                        icon={<PlusOutlined />}
                        type='primary'
                        style={{ borderRadius: '12px' }}
                        className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                        onClick={() => {
                          let dateF = this.formRef?.current?.getFieldValue('DateFChar')
                          let dateT = this.formRef?.current?.getFieldValue('DateTChar')
                          this.setState({ isSearch: false })
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              centered: true,
                              component: (
                                <WS2786002_ConditionAddSubPlus
                                  Li_DateF={moment(dateF, 'YYYY/MM/DD', true).isValid() ? moment(dateF).format('YYYY/MM/DD') : null}
                                  Li_DateT={moment(dateT, 'YYYY/MM/DD', true).isValid() ? moment(dateT).format('YYYY/MM/DD') : null}
                                  Li_CourseF={this.formRef?.current?.getFieldValue('CourseCode')}
                                  Li_CourseT={this.formRef?.current?.getFieldValue('CourseCode')}
                                  Li_FacilityType={this.formRef?.current?.getFieldValue('Facility')}
                                  Li_Office={this.formRef?.current?.getFieldValue('OfficeCode')}
                                  Li_BranchShop={this.formRef?.current?.getFieldValue('BranchStoreCode')}
                                  Li_ConditionAddData={this.state.conditionAddData}

                                  onFinishScreen={(output) => {
                                    this.setState({
                                      conditionAddData: output.conditionAddData
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <Table
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                bordered={true}
                rowKey={(record) => record.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(130) }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected, selectedRows, nativeEvent) => {
                    let arrTemp = [...this.state.selectedRowKeys]
                    let arrTempRecord = [...this.state.selectedRows]
                    let idx = arrTemp.indexOf(record.id)
                    if (idx === -1) {
                      arrTemp.push(record.id)
                      arrTempRecord.push(record)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      })
                    } else {
                      arrTemp.splice(idx, 1)
                      arrTempRecord.splice(idx, 1)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      })
                    }
                  },

                  onSelectAll: (selected, selectedRows, changeRows) => {
                    if (selected) {
                      let arrTemp = this.state.dataSource.map(item => item.id)
                      let arrTempRecord = this.state.dataSource
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord,
                        isSelectAll: true
                      })
                    } else {
                      this.setState({
                        selectedRowKeys: [],
                        selectedRows: [],
                        isSelectAll: false
                      })
                    }
                  },
                }}
              >
                <Table.Column
                  className='column-size-date'
                  title='受診日'
                  dataIndex='visit_date_on'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        {value === '0000-00-00' ? '' : moment(value)?.format('YYYY/MM/DD (ddd)')}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-5'
                  title='時間'
                  dataIndex='period_time'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        {value ? value?.substr(0, 5) : ''}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-5'
                  title='受付No'
                  dataIndex='receipt_number'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        <span>{record.receipt_number === 0 ? '' : record.receipt_number}</span>
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-10'
                  title='個人番号'
                  dataIndex='personal_number_id'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        <span>{record.personal_number_id}</span>
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-5'
                  title='メモ'
                  dataIndex='importance'
                  render={(value, record, index) => {
                    let icon = ''
                    switch (record.importance) {
                      default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                    }
                    return (
                      <div style={{ textAlign: 'center', cursor: 'pointer' }}
                        hidden={!record.personal_number_id}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '90%',
                              component: (
                                <WS2584019_PersonalInfoInquirySub
                                  Li_PersonalNum={record.personal_number_id}
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}>
                        {icon}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-40'
                  title='漢字氏名'
                  dataIndex='KanjiName'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'left' }}>
                        {value}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-5'
                  title='性別'
                  dataIndex='Gender'
                  render={(value, record, index) => {
                    return (<div style={{ textAlign: 'center' }}>{value}</div>)
                  }} />
                <Table.Column
                  className='column-size-10'
                  title='生年月日'
                  dataIndex='DateBirth'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        {value === '0000-00-00' ? '' : moment(value)?.format('NNy/MM/DD')}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-5'
                  title='年齢'
                  dataIndex='Age'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        <span>{record.Age > 0 ? record.Age : ''}</span>
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-5'
                  title='メモ'
                  dataIndex='expression_27'
                  render={(value, record, index) => {
                    let icon = ''
                    switch (record.expression_27) {

                      default: icon = (<Button size='small' style={{ borderRadius: '12px' }} icon={<InfoOutlined />}></Button>)
                    }
                    return (
                      <div style={{ textAlign: 'center', cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              component: (
                                <WS2585001_OfficeInfoInquirySub
                                  Li_OfficeCode={record.office_code}
                                  Li_BranchCode={record.branch_store_code}
                                  onClick={() => {
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }}>
                        {icon}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-60'
                  title='事業所'
                  dataIndex='office_kanji_name'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'left' }}>
                        {value}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-60'
                  title='契約情報'
                  render={(value, record, index) => {
                    return <div style={{ textAlign: 'left' }}>
                      <span >
                        {record.visit_course ? record.visit_course?.toString().substr(0, 1) + '-' + record.visit_course?.toString().substr(1, 2) : ''}
                      </span>
                      <span >{record.contract_short_name}
                      </span>
                    </div>
                  }} />
                <Table.Column
                  className='column-size-5'
                  title='状態'
                  dataIndex='state_flag'
                  render={(value, record, index) => {
                    return <div style={{ textAlign: 'center' }}>{value}</div>
                  }} />
                <Table.Column
                  className='column-size-60'
                  title='備考'
                  dataIndex='remarks'
                  render={(value, record, index) => {
                    return (
                      <div>
                        {value}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-60'
                  title='検査状況'
                  dataIndex='InspectStatus'
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'left' }}>
                        {value}
                      </div>
                    )
                  }} />
                <Table.Column
                  className='column-size-5'
                  fixed='right'
                  render={(value, record, index) => (
                    <div style={{ textAlign: 'center' }}>
                      <Dropdown
                        trigger={['click']}
                        size='small'
                        overlay={() => (
                          <Menu >
                            <Menu.Item onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  component: (
                                    <WS2583001_ConsultInquirySub
                                      Li_ReserveNum={record.W1_reserve_num}
                                      onClick={() => {
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            )}>照会</Menu.Item>
                            <Menu.Item
                              onClick={() => this.excelReport(record)}
                            >EXCEL</Menu.Item>
                          </Menu>
                        )}>
                        <Button size='small' icon={<MoreOutlined />}></Button>
                      </Dropdown>
                    </div>
                  )}
                />
              </Table>

            </div>
            <div className='box_button_bottom_right'>
              <Button type='primary' disabled={this.state.selectedRowKeys.length === 0} onClick={() => this.InspectChanges_F09()}>
                <span className='btn_label'>
                  検査変更
                </span>
              </Button>
              <Button type='primary' disabled={this.state.selectedRowKeys.length === 0} onClick={() => this.Cancel_F10()}>
                <span className='btn_label'>
                  予約取消
                </span>
              </Button>
              <Button type='primary' disabled={this.state.selectedRowKeys.length === 0} onClick={() => this.ReserveChange_F11()}>
                <span className='btn_label'>
                  予約変更
                </span>
              </Button>
            </div>
          </Card >
        </Form >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2556001_ReservesBulkChanges)
