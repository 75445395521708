import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Button, message } from "antd";
import { download_file_binary } from "helpers/CommonHelpers";
import ReservesRelatedFilesOutputAction from "redux/Others/ReservesRelatedFilesOutput/ReservesRelatedFilesOutput.action";
import { ModalError } from "components/Commons/ModalConfirm";

class ReservesRelatedFilesOutputModal extends React.Component {
  static propTypes = {
    Lio_Output: PropTypes.any,
    Lo_StsOutput: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '予約関連ファイル出力';

    this.state = {
      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      selectedRows: [],
      isSelectAll: true
    };
  }

  componentDidMount = () => {
  }

  outputBtn = () => {
    if(this.props.selectedRows.length == 0) {
      ModalError('出力するファイルを選択してください')
      return
    }
    const params = {
      rows: this.props.selectedRows
    }
    ReservesRelatedFilesOutputAction.output(params)
      .then((res) => {
        if(res) {
          download_file_binary(res);
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }


  render() {
    return (
      <div className="inspect-request-convert-master-maintain">
        <Card title="出力確認">
          <div>
            下記管理番号の予約関連ファイルを出力します
          </div>
          {this.props.selectedRows.map(row => {
            return (
              <div key={row.id}>
                {row.ManagementNumber}
              </div>
            )
          })}
          <div className='box_button_bottom_right'>
            <Button
              type="primary"
              onClick={() => {
                this.outputBtn()
              }}
            >
              <span className='btn_label'>
                出力
              </span>
            </Button>
          </div>
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservesRelatedFilesOutputModal);
