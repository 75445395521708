import axios from 'configs/axios'

const APP_LIST = {
  getScreenData: '/api/medical-exam-location/medical-exam-location/get-screen-data',
  save: '/api/medical-exam-location/medical-exam-location/saveData',
  delete: '/api/medical-exam-location/medical-exam-location/delete'
}

const MedicalLocationService = {
  async getScreenData(params) {
    console.log(params)
    return axios.get(APP_LIST.getScreenData, { params })
  },
  async save(params) {
    return axios.post(APP_LIST.save, params)
  },
  async Delete(params) {
    return axios.delete(APP_LIST.delete, { params })
  },
}

export default MedicalLocationService
