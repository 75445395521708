import {
  AppstoreOutlined,
  HeartOutlined,
  AlipayCircleOutlined,
  SettingOutlined,
  FundProjectionScreenOutlined
} from "@ant-design/icons";

const MenuList = [

  // 1 基本情報
  {
    "title": "取込",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "取込",
        "path": "/data-import/data-import-screen",
        "priority": "4　",
      },
      {
        "title": "取込設定",
        "path": "/data-import/data-import-setting",
      },
    ]
  },
  // 2 予約業務
  {
    "title": "変換",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "部位所見グループ設定",
        "path": "/data-conversion/site-findings-group-setting",
        "priority": "5　",
      },
    ]
  },
  // 2 予約業務
  {
    "title": "移行",
    "icon": <AppstoreOutlined />,
    "submenu": [
      {
        "title": "マスタ",
        "path": "/data-migration/master-data-migration",
        "priority": "5　",
      },
      {
        "title": "データ",
        "path": "/group-bookings/group-bookings",
        "priority": "4　",
      },
    ]
  },
];

export default MenuList;
