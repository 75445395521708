import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Select, Radio, Button, Row, Col, Space, Modal, message, Menu, InputNumber, Dropdown } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import DepositWithdrawalListOutputInstructionAction from 'redux/AccountingBusiness/DepositWithdrawalListOutputInstruction/DepositWithdrawalListOutputInstruction.actions'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx';
import WS0432001_CsvConfirm from 'pages/TO_StatisticalServices/V2MS0140_PersonalInfoCsvOutput/WS0432001_CsvConfirm.jsx';
import { download_file } from "helpers/CommonHelpers";
import moment from "moment-timezone";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};
class WS0978001_DepositWithdrawalListOutputInstruction extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '入出金一覧表出力指示';

    this.state = {
      Gl0DateFChar: null,
      Gl0DateTChar: null,
      RegisterClassifyF: 0,
      RegisterClassifyT: 0,
      PayFormChar: "00",
      ComboBox_PayFormChar: [],
      OutputOrder: 0,
      CsvOutputFile: "",
      childModal: {
        visible: false,
        width: 0,
        component: null
      },

      isLoadingPrint: false,
      isLoadingCSV: false,
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    this.GetScreenData()
  }

  GetScreenData() {
    this.setState({ isLoading: true })
    DepositWithdrawalListOutputInstructionAction.GetScreenData()
      .then(res => {
        this.setState({
          Gl0DateFChar: moment(res.Gl0DateFChar),
          Gl0DateTChar: moment(res.Gl0DateTChar),
          RegisterClassifyF: res.RegisterClassifyF,
          RegisterClassifyT: res.RegisterClassifyT,
          ComboBox_PayFormChar: res.ComboBox_PayFormChar,
          OutputOrder: res.OutputOrder,
          CsvOutputFile: res.CsvOutputFile
        });
        this.formRef.current?.setFieldsValue({
          ...res,
          Gl0DateFChar: moment(res.Gl0DateFChar),
          Gl0DateTChar: moment(res.Gl0DateTChar),
        });
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  OutputCsv() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      Gl0DateFChar: moment(this.formRef.current.getFieldValue('Gl0DateFChar')).format('YYYY/MM/DD'),
      Gl0DateTChar: moment(this.formRef.current.getFieldValue('Gl0DateTChar')).format('YYYY/MM/DD'),
    };
    this.setState({ isLoadingCSV: true })
    DepositWithdrawalListOutputInstructionAction.Output_csv(params)
      .then(res => {
        console.log(res.data.message)

        if (res.data.message) {
          Modal.warning({
            title: res.data.message,
            width: 300,
          });
        } else {
          download_file(res);
          message.success("完了！");
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoadingCSV: false }))
  }

  Print_F12 = () => {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      Gl0DateFChar: moment(this.formRef.current.getFieldValue('Gl0DateFChar')).format('YYYY/MM/DD'),
      Gl0DateTChar: moment(this.formRef.current.getFieldValue('Gl0DateTChar')).format('YYYY/MM/DD'),
    };
    if (this.formRef.current?.getFieldValue('PreviewPresence')) {
      // プレビュー
      DepositWithdrawalListOutputInstructionAction.preview(params)
        .then(res => {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          window.open(fileURL)
        })
        .catch(error => {
          const res = error.response
          if (res) {
            if (res.request.responseType === 'blob') {
              res.data.text()
                .then(str => {
                  let json = JSON.parse(str);
                  message.error(json.message)
                })
            }
            if (error.message.includes('504')) {
              return Modal.error({
                title: 'タイムアウトしました',
                content: '',
              });
            }
          }
        })
    } else {
      // 印刷
      DepositWithdrawalListOutputInstructionAction.print(params)
        .then(res => {
          if (res.data === 'true') {
            message.success('印刷が完了しました')
          } else {
            Modal.error({
              title: '処理の途中でエラーが発生しました',
              width: 400,
              okText: 'OK'
            })
          }
        }).catch((err) => {
          message.error(err.response.data.message);
        });
    }
  }

  onFinish(values) {

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  showPrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0433001_PrinterConfirm
            Li_PreviewSpecifiedValue={this.formRef.current?.getFieldValue("PreviewPresence")}
            Li_PrinterNoSpecifiedValue={this.formRef.current?.getFieldValue("PrinterNum")}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                PreviewPresence: output.Lo_Preview,
                PrinterNum: output.Lo_PrinterNo,
                StsOutput: output.Lo_StsOutput
              })
              this.Print_F12();
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  showCsvConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS0432001_CsvConfirm
            Lio_Output={this.formRef.current?.getFieldValue("CsvOutputFile").toLowerCase().endsWith('.csv') ? this.formRef.current?.getFieldValue("CsvOutputFile").slice(0, -4) : this.formRef.current?.getFieldValue("CsvOutputFile")}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                StsOutputConfirm: output.Lo_StsOutput ? 1 : 0,
                CsvOutputFile: output.Lio_Output.toLowerCase().endsWith('.csv') ? output.Lio_Output : output.Lio_Output + '.csv'
              })
              this.OutputCsv();
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  render() {
    return (
      <div className='invoice'>
        <Card
          className="mb-2"
          style={{
            position: 'relative',
            width: 'fit-content',
          }}
          title="入出金一覧表出力指示"
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ PayFormChar: "00" }}
          >
            <div className='box_container' >
              <div className='box_search'>
                <div className='box_search_inner_border' >
                  <div className="box_inner_horizontal">
                    <Form.Item name="Gl0DateFChar" label="入出金日">
                      <VenusDatePickerCustom />
                    </Form.Item>
                    <Form.Item name="Gl0DateTChar" label="~" style={{ marginRight: '10px' }}>
                      <VenusDatePickerCustom />
                    </Form.Item>
                  </div>
                  <div className='box_inner_horizontal'>
                    <Form.Item name="RegisterClassifyF" label="レジ区分">
                      <InputNumber style={{ width: '40px' }} maxLength={1} />
                    </Form.Item>
                    <Form.Item name="RegisterClassifyT" label="〜">
                      <InputNumber style={{ width: '40px' }} maxLength={1} />
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_inner'>
                  <Form.Item name="PayFormChar" label="入金形態">
                    <Select style={{ width: '120px' }}>
                      <Select.Option value="00">全て</Select.Option>
                      {this.state.ComboBox_PayFormChar?.map((value, index) => (
                        <Select.Option key={index} value={value.LinkedField}>{value.DisplayField}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="OutputOrder" label="出力順" >
                    <Radio.Group style={{ marginLeft: '15px' }}>
                      <Radio value={0} style={radioStyle}>入金日</Radio>
                      <Radio value={1} style={radioStyle}>領収番号</Radio>
                      <Radio value={2} style={radioStyle}>処理日</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
          <div className='box_button_bottom_right'>
            <Button type="primary" onClick={() => {
              this.showCsvConfirm()
            }} >CSV</Button>
            <Button type="primary" onClick={() => {
              this.showPrinterConfirm()
            }} >印刷</Button>
          </div>
          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            bodyStyle={{ margin: 0, padding: 0 }}
            maskClosable={false}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0978001_DepositWithdrawalListOutputInstruction);
