import { message } from "antd";
import CopyService from "services/InspectionMaintenance/RadiographyInspectMaintain/CopyService";

const CopyAction = {
  getNameCopy(params) {
    return CopyService.getNameCopy(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },

  execCopy(params) {
    return CopyService.execCopy(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  },
}

export default CopyAction;