import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Input, Select, message } from "antd";
import { SaveOutlined, } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'

const labelCol = {
  labelCol: { style: { width: 80 } }
}

class ComplexDecisionMasterMaintenanceFormatNameEdit extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "複合判定マスタ保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({format: this.props.record?.formatName ?? ''})
  };


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };


  render() {
    return (
      <div className="spreadsheet-input-setting">
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <Card title={this.props.record ? "FORMAT 編集" : "FORMAT 追加"}>
            <Form.Item
              name='format'
              label='FORMAT'
              {...labelCol}
            >
              <Input
                allowClear
                onPressEnter={() => {
                  this.props.onFinishScreen({...this.formRef.current?.getFieldsValue()})
                }}
              />
            </Form.Item>

            {/* 保存ボタン */}
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.props.onFinishScreen({...this.formRef.current?.getFieldsValue()})
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplexDecisionMasterMaintenanceFormatNameEdit);
