import React from "react";
import { connect } from "react-redux";

import { Card, Table, message, Row, Col, Button } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
class WS1390001_ImplementClassifyInquiry extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'V4-TSB00200:実施区分照会';

    this.state = {
      divisionCode: this.props.divisionCode,
      dataSource: [],
      selectedRows: []
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true });
    VenusApiRoutesV2.callApi("API001390001002")
      .then(res => {
        console.log(res);
        this.setState({
          dataSource: res
        });
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    return (
      <div className="implement-classify-inquiry">
        <Card className="mb-2" title="V4-TSB00200:実施区分照会">
          <Table bordered
            dataSource={this.state.dataSource}
            loading={false}
            pagination={{
              defaultCurrent: 1,
              total: this.state.dataSource.length,
              pageSize: 10,
              showSizeChanger: true,
              onChange: (page, pageSize) => { },
              onShowSizeChange: (page, pageSize) => { },
            }}
            onRow={(record, index) => ({
              onClick: () => {
                this.setState({
                  ...this.state.selectedRows,
                  selectedRows: record,
                });
              },
              onDoubleClick: () => {
                if (this.props.onFinishScreen) {
                  this.props.onFinishScreen({
                    implement_division: this.state.selectedRows.implement_division_code,
                    // implement_division_code: this.state.selectedRows.implement_division_code
                  });
                }
              },
            })
            }

            rowKey={(record) => record.id}
          >
            <Table.Column showSorterTooltip={false} sorter={(a, b) => a.implement_division_code.localeCompare(b.implement_division_code, 'ja')} title="実施区分コード" dataIndex="implement_division_code" key="implement_division_code" />
            <Table.Column showSorterTooltip={false} sorter={(a, b) => a.implement_division.localeCompare(b.implement_division, 'ja')} title="実施区分" dataIndex="implement_division" key="implement_division" />

          </Table>
          {/* <Row>
            <Col span={24} className="text-end" style={{ textAlign: "right" }}>
              <Button type="primary"
                disabled={this.state.selectedRows.length === 0}
                onClick={() => {
                  if (this.props.onFinishScreen) {
                    this.props.onFinishScreen({
                      implement_division: this.state.selectedRows[0].implement_division,
                      implement_division_code: this.state.selectedRows[0].implement_division_code
                    });
                  }
                }}>
                選択
              </Button>
            </Col>
          </Row> */}
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1390001_ImplementClassifyInquiry);
