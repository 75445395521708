import React from "react";
import { connect } from "react-redux";

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, InputNumber, Input, Select, Button, Table, Row, Col, Space, DatePicker, Modal, Menu, Dropdown, message, Spin, Tooltip } from "antd";

import { SearchOutlined, MoreOutlined, PlusOutlined, CaretDownOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import WS1178001_GovernmentTubeHepatitisAggregateSub from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1178001_GovernmentTubeHepatitisAggregateSub";

// import AssociateInsureQualifyConfirmAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociateInsureQualifyConfirm.action";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import GovernmentTubeHepatitisAggregateAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/GovernmentTubeHepatitisAggregate.actions";

class WS1178001_GovernmentTubeHepatitisAggregate extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS05300:政管肝炎集計';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoadingForm: false,
      isLoadingTable: false,
      count: "a",
      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      selected: 0,
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {
    if (this.props !== prv) {
      this.getScreenData();
    }
  }

  findIndexByID = (recordID) => {
    return this.state.dataSource.findIndex((item) => recordID === item.id);
  };

  getScreenData = () => {
    this.setState({ isLoading: true, isLoadingTable: true, dataAddNew: null });

    GovernmentTubeHepatitisAggregateAction.index()
      .then(res => {
        console.log(res)
        this.setState({
          dataSource: res,
          isLoadingTable: false,
          isLoading: false
        })
      })
      .catch(error => {
        this.setState({ isLoading: false, isLoadingTable: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  addNewData() {
    if (!this.state.dataSource.length || this.state.dataAddNew) {
      return;
    }
    const { count } = this.state;
    const newData = {
      id: 0,
      invoice_summary_division: 0,
      medical_exam_course: "",
      course_exam_basic_health: ""
    };

    this.forceUpdate();
    this.setState({
      ...this.state,
      count: count + 1,
      dataAddNew: newData,
      dataSource: [newData, ...this.state.dataSource]
    });
  }

  saveData(record) {
    VenusApiRoutesV2.callApi("API001178001007", {
      mainSource: [{
        id: record.id,
        course_exam_basic_health: record.course_exam_basic_health,
        medical_exam_course: record.medical_exam_course,
        invoice_summary_division: record.invoice_summary_division,
      }]
    }).then(res => {
      this.getScreenData();
    }).catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
  }

  deleteData(record) {
    VenusApiRoutesV2.callApi("API001178001008", {
      mainSource: [{ id: record.id }]
    }).then(res => {
      this.getScreenData();
    }).catch(error => {
      const res = error.response;
      if (!res || !res.data || !res.data.message) {
        message.error("エラーが発生しました");
        return;
      }
      message.error(res.data.message);
    });
  }

  render() {
    return (
      <div className="government-tube-hepatitis-aggregate">
        <Card title="V4-VNS05300:政管肝炎集計">
          <Table
            bordered
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={{
              defaultCurrent: 1,
              pageSize: 10,
              showSizeChanger: true,
              onChange: (page, pageSize) => { },
              onShowSizeChange: (page, pageSize) => { },
            }}
            rowClassName={(record, index) =>
              record.id === this.state.selectedRow.id ? "table-row-light" : ""
            }
            rowKey={(record) => record.id}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  this.setState({
                    ...this.state,
                    selectedRow: record,
                    selectedRowKeys: [record.id]
                  });
                }
              };
            }}
          >
            <Table.Column title="コース" dataIndex="medical_exam_course"
              sorter={(a, b) => a.medical_exam_course.localeCompare(b.medical_exam_course, "ja")}
              render={(value, record) => {
                return (
                  <Col span={5} style={{ textAlign: "center" }}>
                    {record.medical_exam_course}
                  </Col>
                );
              }}
            />
            <Table.Column title="コース名" dataIndex="course_name_short_name" width={150} sorter={(a, b) => a.invoice_summary_division - b.invoice_summary_division}
              render={(value, record,) => {
                return (
                  <Col span={10} style={{ textAlign: "center" }}>
                    {record.course_name_short_name}
                  </Col>
                );
              }}
            />
            <Table.Column title="集計区分" dataIndex="invoice_summary_division" width={150} sorter={(a, b) => a.invoice_summary_division - b.invoice_summary_division}
              render={(value, record,) => {
                return (
                  <Col span={10} style={{ textAlign: "center" }}>
                    {record.invoice_summary_division === 1 ? '肝炎' : ''}
                  </Col>
                );
              }}
            />
            <Table.Column title="基本コース" dataIndex="course_exam_basic_health" key="" sorter={(a, b) => a.course_exam_basic_health.localeCompare(b.course_exam_basic_health, "ja")}
              render={(value, record) => {
                return (
                  <div>
                    <Row gutter={5}>
                      <Col span={10} style={{ textAlign: "center" }}>
                        {record.course_exam_basic_health}
                      </Col>
                    </Row>
                  </div>
                )
              }}
            />
            <Table.Column title="基本コース名" dataIndex="course_exam_basic_health" key="" sorter={(a, b) => a.course_exam_basic_health.localeCompare(b.course_exam_basic_health, "ja")}
              render={(value, record) => {
                return (
                  <div>
                    <Row gutter={5}>
                      <Col span={8}>
                        {record.course_name_short_name_2}
                      </Col>
                    </Row>
                  </div>
                )
              }}
            />
            <Table.Column
              width={70}
              align="center"
              title={
                <Button
                  size="small"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 600,
                        component: (
                          <WS1178001_GovernmentTubeHepatitisAggregateSub
                            newFlag={true}
                            onFinishScreen={() => this.closeModal()}
                            getScreenData={() => this.getScreenData()}
                          />
                        ),
                      },
                    })
                  }}
                ></Button>
              }
              render={(text, record, index) => (
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    // 編集、削除
                    // this.setState(() => {
                    //   this.setState({
                    //     childModal: {
                    //       ...this.state.childModal,
                    //       visible: true,
                    //       width: 600,
                    //       component: (
                    //         <WS1178001_GovernmentTubeHepatitisAggregateSub
                    //           newFlag={false}
                    //           record={record}
                    //         />
                    //       ),
                    //     },
                    //   })
                    // })
                    this.setState(() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS1178001_GovernmentTubeHepatitisAggregateSub
                              newFlag={false}
                              onFinishScreen={() => this.closeModal()}
                              getScreenData={() => this.getScreenData()}
                              record={record}
                            />
                          ),
                        },
                      })
                    })
                  }}
                />

              )}
            />

          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1178001_GovernmentTubeHepatitisAggregate);
