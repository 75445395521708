import axios from 'configs/axios';

const apiPaths = {
  index: '/api/associate-insure-param-maintain/government-tube-hepatitis-aggregate/index',
  save: '/api/associate-insure-param-maintain/government-tube-hepatitis-aggregate/save',
  delete: '/api/associate-insure-param-maintain/government-tube-hepatitis-aggregate/delete',
};

const GovernmentTubeHepatitisAggregateService = {

  async index() {
    return axios.get(apiPaths.index);
  },
  async save(params) {
    return axios.get(apiPaths.save, { params });
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  }
};

export default GovernmentTubeHepatitisAggregateService;