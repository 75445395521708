import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import XmlMedicalExamItemMasterSaveAction from 'redux/SpecificInsureMaintenance/XmlMedicalExamItemMaster/XmlMedicalExamItemMaster.action.js'
import { Card, Form, Table, Button, Modal, Space, Row, Col, Input, Menu, Dropdown, message, Select, InputNumber, TimePicker } from "antd";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from "moment";



const smGrid = {
  labelCol: { style: { width: 150 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


class WS1313002_XmlMedicalExamItemMasterChild extends React.Component {
  formRef = React.createRef();

  // document.title = 'JLAC10コードマスタ編集画面';

  //親画面の情報を取ってくる
  constructor(props) {
    super(props);
    //stateにdataSourceを保持　初期値[]を設定
    //stateに保持させるときは現画面で加工したいとき　propsでとってくるときは、加工せずそのままのデータが欲しいとき
    this.state = {
      dataSource: [],
      filteredData: [],
      //子画面を開くのに必要
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  //componentDidMountが初期画面表示処理
  componentDidMount() {
    this.formRef.current.setFieldsValue({
      id: this.props.id ?? '',
      date_of_adoption_history_on: this.props.date_of_adoption_history_on ?? '',
      DateAdoptionChars: this.props.DateAdoptionChars ?? '',
      item_code_jlac10_15: this.props.item_code_jlac10_15 ?? '',
      result_identification_jlac10_2: this.props.result_identification_jlac10_2 ?? '',
      division_number: this.props.division_number ?? '',
      order_number: this.props.order_number ?? '',
      ministry_item_name: this.props.ministry_item_name ?? '',
      xml_display_name: this.props.xml_display_name ?? '',
      data_type: this.props.data_type ?? '',
      xml_pattern: this.props.xml_pattern ?? '',
      max_bytes_or_format: this.props.max_bytes_or_format ?? '',
      xml_data_type: this.props.xml_data_type ?? '',
      display_units: this.props.display_units ?? '',
      ucum_unit_code: this.props.ucum_unit_code ?? '',
      observation_element: this.props.observation_element ?? '',
      auther_element: this.props.auther_element ?? '',
      auther_element_item_number: this.props.auther_element_item_number ?? '',
      series_exam_group_identification: this.props.series_exam_group_identification ?? '',
      series_exam_group_relationship_code: this.props.series_exam_group_relationship_code ?? '',
      identity_item_code: this.props.identity_item_code ?? '',
      identity_item_name: this.props.identity_item_name ?? '',
      xml_exam_method_code: this.props.xml_exam_method_code ?? '',
      exam_methods: this.props.exam_methods ?? '',
      result_code_oid: this.props.result_code_oid ?? '',
      item_code_oid: this.props.item_code_oid ?? '',
      condition_code_string_12: this.props.condition_code_string_12 ?? '',
    })
    console.log(this.props.id)
  }


  onSave() {
    let mainSourceChild = [{
      //php:front
      id: this.formRef.current.getFieldValue('id'),
      DateAdoptionChars: this.formRef.current.getFieldValue('DateAdoptionChars'),
      item_code_jlac10_15: this.formRef.current.getFieldValue('item_code_jlac10_15'),
      result_identification_jlac10_2: this.formRef.current.getFieldValue('result_identification_jlac10_2'),
      division_number: this.formRef.current.getFieldValue('division_number'),
      order_number: this.formRef.current.getFieldValue('order_number'),
      condition_code_string_12: this.formRef.current.getFieldValue('condition_code_string_12'),
      ministry_item_name: this.formRef.current.getFieldValue('ministry_item_name'),
      xml_display_name: this.formRef.current.getFieldValue('xml_display_name'),
      data_type: this.formRef.current.getFieldValue('data_type'),
      xml_pattern: this.formRef.current.getFieldValue('xml_pattern'),
      max_bytes_or_format: this.formRef.current.getFieldValue('max_bytes_or_format'),
      xml_data_type: this.formRef.current.getFieldValue('xml_data_type'),
      display_units: this.formRef.current.getFieldValue('display_units'),
      ucum_unit_code: this.formRef.current.getFieldValue('ucum_unit_code'),
      observation_element: this.formRef.current.getFieldValue('observation_element'),
      auther_element: this.formRef.current.getFieldValue('auther_element'),
      auther_element_item_number: this.formRef.current.getFieldValue('auther_element_item_number'),
      series_exam_group_identification: this.formRef.current.getFieldValue('series_exam_group_identification'),
      series_exam_group_relationship_code: this.formRef.current.getFieldValue('series_exam_group_relationship_code'),
      identity_item_code: this.formRef.current.getFieldValue('identity_item_code'),
      identity_item_name: this.formRef.current.getFieldValue('identity_item_name'),
      result_code_oid: this.formRef.current.getFieldValue('result_code_oid'),
      item_code_oid: this.formRef.current.getFieldValue('item_code_oid'),
      //PHPになし
      xml_exam_method_code: this.formRef.current.getFieldValue('xml_exam_method_code'),
      exam_methods: this.formRef.current.getFieldValue('exam_methods'),
    }]
    let params = {
      // ...this.formRef.current.getFieldsValue()
      mainSource: mainSourceChild
    }
    console.log(params)
    if (this.formRef.current.getFieldValue('DateAdoptionChars') == '') {
      message.error("日付を入力してください")
    } else {
      XmlMedicalExamItemMasterSaveAction.saveXmlMedicalExamItemDataInput(params)
        .then((res) => {
          // .then((res) => {
          message.success('更新しました!')
          this.props.getScreenData()
        })
        .catch((err) => {
          const res = err.response;
          if (!res || !res.data || !res.data.message) {
            message.error("エラーが発生しました");
            return;
          }
          message.error(res.data.message);
        });
    }
  }

  // 削除
  delete() {
    let params = {
      id: this.formRef.current.getFieldValue('id')
    }
    console.log(params)
    XmlMedicalExamItemMasterSaveAction.deletexmlMedicalExamItemDataInput(params)
      .then(res => {
        this.props.getScreenData()
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    });
  }

  render() {
    return (
      // ファイル名を小文字にして-でつなげたもの
      <div
        className="insure-guide-course-detail-child"
        style={{ width: '100%' }}
      >
        <Card title={'JLAC10コードマスタ [' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'}>
          <Form
            ref={this.formRef}
          >
            {/* 新規の時はすべて編集できる　編集の時は採用日と項目コードは編集できないようにする */}
            {this.props.newFlag ?
              <div>
                <Form.Item name="DateAdoptionChars" label="採用日" {...smGrid}>
                  <Input style={{ width: 150 }}>
                  </Input>
                </Form.Item>

                <Form.Item name="item_code_jlac10_15" label="項目コード" {...smGrid}>
                  <Input style={{ width: 210 }}>
                  </Input>
                </Form.Item>
              </div>

              :

              <div>
                <Form.Item name="DateAdoptionChars" label="採用日" {...smGrid}>
                  <div style={{ marginTop: -2 }}>{this.props.DateAdoptionChars}</div>
                </Form.Item>

                <Form.Item name="item_code_jlac10_15" label="項目コード" {...smGrid}>
                  <div style={{ marginTop: -2 }}>{this.props.item_code_jlac10_15}</div>
                </Form.Item>
              </div>
            }

            <Form.Item name="result_identification_jlac10_2" label="結果識別" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="division_number" label="区分番号" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="order_number" label="順番号" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="condition_code_string_12" label="条件コード" {...smGrid}>
              <Input style={{ width: 180 }}>
              </Input>
            </Form.Item>

            <Form.Item name="ministry_item_name" label="厚労省項目名" {...smGrid}>
              <Input style={{ width: 250 }}>
              </Input>
            </Form.Item>

            <Form.Item name="xml_display_name" label="ＸＭＬ表示名" {...smGrid}>
              <Input style={{ width: 250 }}>
              </Input>
            </Form.Item>

            <Form.Item name="data_type" label="ﾃﾞｰﾀﾀｲﾌﾟ" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="xml_pattern" label="XMLﾊﾟﾀｰﾝ" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="max_bytes_or_format" label="最大ﾊﾞｲﾄORﾌｫｰﾏｯﾄ" {...smGrid}>
              <Input style={{ width: 250 }}>
              </Input>
            </Form.Item>

            <Form.Item name="xml_data_type" label="XMLﾃﾞｰﾀ型" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="display_units" label="表示単位" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="ucum_unit_code" label="UCUM単位ｺｰﾄﾞ" {...smGrid}>
              <Input style={{ width: 170 }}>
              </Input>
            </Form.Item>

            <Form.Item name="observation_element" label="observation要素" {...smGrid}>
              <Input style={{ width: 200 }}>
              </Input>
            </Form.Item>

            <Form.Item name="auther_element" label="auther要素" {...smGrid}>
              <Input style={{ width: 150 }}>
              </Input>
            </Form.Item>

            <Form.Item name="auther_element_item_number" label="auther要素項目番号" {...smGrid}>
              <Input style={{ width: 230 }}>
              </Input>
            </Form.Item>

            <Form.Item name="series_exam_group_identification" label="一連検査ｸﾞﾙｰﾌﾟ識別" {...smGrid}>
              <Input style={{ width: 230 }}>
              </Input>
            </Form.Item>

            <Form.Item name="series_exam_group_relationship_code" label="一連検査ｸﾞﾙｰﾌﾟ関係ｺｰﾄﾞ" {...smGrid}>
              <Input style={{ width: 230 }}>
              </Input>
            </Form.Item>

            <Form.Item name="identity_item_code" label="同一性項目ｺｰﾄﾞ" {...smGrid}>
              <Input style={{ width: 180 }}>
              </Input>
            </Form.Item>

            <Form.Item name="identity_item_name" label="同一性項目名称" {...smGrid}>
              <Input style={{ width: 250 }}>
              </Input>
            </Form.Item>

            <Form.Item name="xml_exam_method_code" label="XML検査方法ｺｰﾄﾞ" {...smGrid}>
              <Input style={{ width: 200 }}>
              </Input>
            </Form.Item>

            <Form.Item name="exam_methods" label="検査方法" {...smGrid}>
              <Input style={{ width: 300 }}>
              </Input>
            </Form.Item>

            <Form.Item name="result_code_oid" label="結果ｺｰﾄﾞOID" {...smGrid}>
              <Input style={{ width: 250 }}>
              </Input>
            </Form.Item>

            <Form.Item name="item_code_oid" label="項目ｺｰﾄﾞOID" {...smGrid}>
              <Input style={{ width: 250 }}>
              </Input>
            </Form.Item>


          </Form>
          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              danger
              icon={<DeleteOutlined />}
              style={{ display: (this.props.newFlag) ? 'none' : '' }}
              onClick={() => {
                // 確認モーダルを表示してから、削除
                Modal.confirm({
                  content: `削除を行いますか?`,
                  okText: 'は　い',
                  cancelText: 'いいえ',
                  onOk: () => {
                    this.delete()
                  }
                })
              }}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              // onClick={() => { this.Item_F12() }}
              // onClick={() => { this.onSave() }}
              icon={<SaveOutlined />}
              disabled={this.state.disabledSaveBtn}
              onClick={() => { this.onSave() }}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>


        </Card>
      </div>
    )
  }
}

//以下画面の新規作成に必要な行
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

//ファイル名に書き換える
export default connect(mapStateToProps, mapDispatchToProps)(WS1313002_XmlMedicalExamItemMasterChild);
