import React from 'react'
import { connect } from 'react-redux'
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import { history } from 'constants/BrowserHistory'
import { Modal, Layout, Menu, message, Button, Tooltip, Spin, Dropdown } from 'antd'
import {
  UserOutlined,
  SettingOutlined,
  DashboardOutlined,
  FolderOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons'
import { logoutUserInfo, setSubMenus, setDisplayComponent, getMenuList, getMyMenuList } from 'redux/user/user.actions'
import MenuCustomizationAction from 'redux/MN_Basis/MenuCustomization/MenuCustomization.action'
import getDocumentTitle from 'components/Commons/getDocumentTitle'
import ModalDraggable from "components/Commons/ModalDraggable";
import { UserActionTypes } from 'redux/user/user.types'
import RouteDefined from 'constants/Route'
import MenuList from 'constants/MenuList'
import DataMigrationMenuList from 'constants/DataMigrationMenuList'
import axios from 'configs/axios'
import './WS2519001_MainMenu.scss'

import OptionSetting from "pages/ZZ_Others/OptionSetting/OptionSetting.jsx";
import WS0211001_SupportMenu from 'pages/ZZ_Others/SupportMenu/WS0211001_SupportMenu.jsx'

// メニュー文字
const menuStyle = { fontWeight: 'bold', fontFamily: 'Meiryo', fontSize: 15 }

// import { history } from 'constants/BrowserHistory'
class WS2519001_MainMenu extends React.Component {
  constructor(props) {
    super(props)

    // document.title = 'メインメニュー'

    this.state = {
      showMenu: false,
      fullScreen: false,
      menuList: [],
      myMenusList: [],
      changedValue: [],
      selectedId: '',
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
    }

    this.getScreenID = this.getScreenID.bind(this)
    this.copyScreenID = this.copyScreenID.bind(this)
    this.logout = this.logout.bind(this)

    if (!props.loggedIn) {
      props.logoutUserInfo(props.hospitalCode)
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // Check is logged in
    const hide = message.loading('ログイン情報を確認中', 0)
    axios
      .get('/api/user')
      .then(res => {

        // ユーザの有効期限のチェック
        const validDays = Number(process.env.REACT_APP_ACCOUNT_VALID_DAYS ?? 0)
        if (validDays > 0) {
          // 有効期限チェックが有効の場合
          const valid_f = res.data.valid_f //有効期限開始日
          const valid_t = res.data.valid_t //有効期限終了日
          const fromDate = valid_f ? new Date(valid_f) : new Date()
          const toDate = valid_t ? new Date(valid_t) : new Date()
          const today = new Date() //本日

          // 有効期限が切れている場合
          if (today < fromDate || toDate < today) {
            console.log('有効期限切れのパスワード更新');
            // パスワード更新画面に遷移
            const path = '/password-update'
            history.push(path)
            return
          }
        }

        this.props.setUserInfo(res.data, this.props.hospitalCode)
        /* メニューカスタマイズを反映させる場合は下のmapのコメントを外す */
        if (process.env.REACT_APP_MENU_CUSTOMIZE === 'true') {
          this.props.getMenuList()
        } else if (process.env.REACT_APP_MENU_CUSTOMIZE === 'false') {
          if (process.env.REACT_APP_DATA_MIGRATION !== 'true') {
            // メニュー一覧、メニュー開く
            this.setState({ menuList: MenuList })
            // メニューサイドバーの開閉
            this.setShowMenu()
            //ドキュメントタイトルの変更
            document.title = getDocumentTitle(MenuList)
          } else {
            // メニュー一覧、メニュー開く
            this.setState({ menuList: DataMigrationMenuList })
            // メニューサイドバーの開閉
            this.setShowMenu()
            //ドキュメントタイトルの変更
            document.title = getDocumentTitle(DataMigrationMenuList)
          }
        }

        this.getMyMenuItem()
      })
      .catch(error => {
        const res = error.response
        if (this.props.loggedIn && res && res.status === 401) {
          this.props.logoutUserInfo(this.props.hospitalCode)
          return
        } else if (!res || !res.data || !res.data.message) {
          console.log(error)
          message.error('エラーが発生しました')
          this.props.logoutUserInfo(this.props.hospitalCode)
          return
        }

        if (res.data.message !== 'Unauthenticated.') {
          message.error(res.data.message)
        }

        if (this.props.hospitalCode !== '') {
          this.props.logoutUserInfo(this.props.hospitalCode)
        }
      })
      .finally(() => {
        setTimeout(hide, 0)
      })
    this.getScreenID()
  }

  /**
   * メニュー一覧の更新状態を取得
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.menuList !== prevProps.menuList) {
      const data = [...this.props.menuList]
      // メニュー一覧、メニュー開く
      this.setState({ menuList: data })
      // メニューサイドバーの開閉
      this.setShowMenu()
      //ドキュメントタイトルの変更
      document.title = getDocumentTitle(data)
    }
    if (this.props.myMenuList !== prevProps.myMenuList) {
      this.setState({ myMenusList: this.props.myMenuList })
    }
  }

  /**
   * ログアウト
   */
  logout() {
    Modal.confirm({
      title: 'ログアウト',
      content: '別のユーザーでログインし直しますか？',
      onOk: () => {
        return this.props.logoutUserInfo(this.props.hospitalCode)
      }
    })
  }

  /**
   * メニューサイドバーの開閉
   */
  handleShowMenu = () => {
    if (this.state.showMenu) {
      this.setState({ showMenu: false })
    } else {
      this.setState({ showMenu: true })
    }
  }

  handleFullScreen = () => {
    if (!this.state.fullScreen) {
      this.setState(
        {
          fullScreen: true
        },
        () => {
          document.documentElement.requestFullscreen()
        }
      )
    } else {
      this.setState(
        {
          fullScreen: false
        },
        () => {
          document.exitFullscreen()
        }
      )
    }
  }

  /**
   * サポート メニュー
   */
  handleSupport = () => {

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS0211001_SupportMenu
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  /**
   * 画面IDを取得する処理（開発の時だけ表示）
   * @param {*} event
   * @returns
   */
  getScreenID(event) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (typeof event === 'undefined') return

    const pathKey = event?.key
    let pathname = pathKey
    if (pathKey.indexOf('_') >= 0) {
      pathname = pathKey?.substring(0, pathKey.indexOf('_'))
    }

    let screenName = ''
    for (let i = 0; i < RouteDefined.length; i++) {
      if (RouteDefined[i].path === pathname) {
        screenName = RouteDefined[i].component?.WrappedComponent?.name
        break
      }
    }

    if (this.props.componentName === screenName) return
    // redux
    this.props.setDisplayComponent(screenName)

    // メニューサイドバーの開閉
    this.setShowMenu()

    //ドキュメントタイトルの変更
    if (process.env.REACT_APP_MENU_CUSTOMIZE === 'true') {
      document.title = getDocumentTitle(this.props.menuList)
    } else if (process.env.REACT_APP_MENU_CUSTOMIZE === 'false') {
      document.title = getDocumentTitle(MenuList)
    }
  }

  // Get a screen ID for testing (Copy and paste)
  copyScreenID() {
    const value = this.props.componentName
    if (typeof value === 'string' || value instanceof String) {
      const arr = value.split('_')
      if (arr.length > 0) {
        const pathStr = arr[0]

        if (typeof navigator.clipboard == 'undefined') {
          const textField = document.createElement('textarea')
          textField.innerText = pathStr
          document.body.appendChild(textField)
          textField.select()
          document.execCommand('copy')
          textField.remove()
          return
        }

        navigator.clipboard.writeText(arr[0])
      }
    }
  }

  /**
   * 設定（環境変数）によって、メニューサイドバーの開閉を判定
   * .env の MENU_CLOSE にて設定
   */
  setShowMenu = () => {
    if (process.env.REACT_APP_MENU_CLOSE === 'true') {
      this.setState({ showMenu: false })
    } else {
      this.setState({ showMenu: true })
    }
  }

  /**
   * マイメニューの情報を取得
   * @returns
   */
  getMyMenuItem = () => {
    let param = {
      userName: this.props.user.username
    }
    this.props.getMyMenuList(param)
  }

  optionModalOpen = (componentName) => {
    console.log(componentName)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <OptionSetting
            componentName={componentName}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <Layout style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', }}>
        <Layout.Header className='px-0' style={{ width: '100%' }}>
          <div style={{ height: '100%', width: '100%', display: 'flex', position: 'relative' }}>
            <Button
              className='menu-btn'
              type='text'
              icon={<MenuOutlined />}
              onClick={this.handleShowMenu}
              style={{ width: '60px', borderRadius: 0 }}
            />

            <Button
              className='menu-btn'
              type='text'
              icon={<DashboardOutlined />}
              onClick={() => history.push('/main/dashboard')}
            >
              ダッシュボード
            </Button>

            {/* <Button
              className='menu-btn'
              type='text'
              icon={<MenuUnfoldOutlined />}
              onClick={() => history.push('/menu-customization/my-menu')}
            >
              {'マイメニュー'}
            </Button> */}

            <Button
              className='menu-btn'
              type='text'
              icon={<FolderOutlined />}
              onClick={() => history.push('/')}
            >
              Explorer
            </Button>

            <Dropdown
              type='text'
              trigger='click'
              size='small'
              icon={<SettingOutlined />}
              overlay={() => {
                return (
                  <Menu onClick={this.getScreenID}>
                    <Menu.SubMenu
                      key={`menu_1`}
                      title={'システム設定'}
                    >
                      <Menu.Item key='/specific-medical-examination-revision-2024/specific-medical-examination-revision-2024'>
                        <Link className='menu-link' to='/specific-medical-examination-revision-2024/specific-medical-examination-revision-2024'>
                          2024年度改正対応
                        </Link>
                      </Menu.Item>
                      <Menu.SubMenu
                        key={`menu_1-1`}
                        title={'リカバリー'}
                      >
                        <Menu.Item onClick={() => axios.post('/api/common/recovery/setStartDate')}>
                          セット日付
                        </Menu.Item>
                        <Menu.Item onClick={() => axios.post('/api/common/recovery/reCreateBillingAddress')}>
                          契約再展開
                        </Menu.Item>
                      </Menu.SubMenu>
                      <Menu.Item key={'/option-setting/option-setting'} onClick={() => this.optionModalOpen(this.props.componentName)}>
                        オプション情報
                      </Menu.Item>
                      <Menu.Item key='/comprehensive-menu-maintenance'>
                        <Link className='menu-link' to='/comprehensive-menu-maintenance'>
                          メニュー設定
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='/virus-check-log/virus-check-log'>
                        <Link className='menu-link' to='/virus-check-log/virus-check-log'>
                          ウイルスチェック結果確認
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='/connect-hosts-setting/connect-hosts-setting'>
                        <Link
                          className='menu-link'
                          to={{
                            pathname: '/connect-hosts-setting/connect-hosts-setting',
                            state: {
                              title: 'ホスト接続設定'
                            }
                          }}
                        >
                          ホスト接続設定
                        </Link>
                      </Menu.Item>

                      <Menu.Item key='/process-menu'>
                        <Link
                          className='menu-link'
                          to={{
                            pathname: '/process-menu/index',
                            state: {
                              title: '処理メニュー'
                            }
                          }}
                        >
                          処理メニュー
                        </Link>
                      </Menu.Item>


                    </Menu.SubMenu>
                    {
                      this.props.user.StsAdministrator ? (
                        <Menu.Item key='/user-info/user-register'>
                          <Link className='menu-link' to='/user-info/user-register'>
                            ユーザー設定
                          </Link>
                        </Menu.Item>
                      ) : null
                    }
                    <Menu.Item key='/menu-customization/menu-customization'>
                      <Link className='menu-link' to='/menu-customization/menu-customization'>
                        マイメニュー設定
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='/log-query/log-query'>
                      <Link className='menu-link' to='/log-query/log-query'>
                        システムログ照会
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='/application-log-query/application-log-query'>
                      <Link className='menu-link' to='/application-log-query/application-log-query'>
                        アプリケーションログ照会
                      </Link>
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='menu-btn'
                type='text'
                icon={<SettingOutlined />}
              >
                設定
              </Button>
            </Dropdown>

            <Button
              className='menu-btn'
              type='text'
              icon={this.state.fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
              onClick={this.handleFullScreen}
            >
              {this.state.fullScreen ? 'Restore Down' : 'Full Screen'}
            </Button>

            <Button
              className='menu-btn'
              type='text'
              icon={<SettingOutlined />}
              onClick={this.handleSupport}
            >
              {'サポート'}
            </Button>

            {/* マイメニュー */}
            <Dropdown
              //style={{ position: 'absolute', right: 100 }}
              type='text'
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    {this.state.myMenusList.map((menuItem, j) => {
                      let item
                      if (Number(menuItem.invalid) || menuItem.name === '') {
                        // 無効 または データが無い時
                        item = null
                      } else {
                        item = (
                          <Menu.Item key={'myMenu' + j}>
                            <Link
                              className='menu-link'
                              to={menuItem.options}
                            >
                              {menuItem.name}
                            </Link>
                          </Menu.Item>
                        )
                      }
                      return item
                    })}
                  </Menu>
                )
              }}
            >
              <Button
                className='menu-btn'
                type='text'
                icon={<MenuUnfoldOutlined />}
                style={{ position: 'absolute', right: 100 }}
              //onClick={() => history.push('/main/dashboard')}
              >
                {'マイメニュー'}
              </Button>
            </Dropdown>

            <Dropdown
              style={{ position: 'absolute', right: 10 }}
              type='text'
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='/user-info/password-modify'>
                      <Link className='menu-link' to='/user-info/password-modify'>
                        パスワード変更
                      </Link>
                    </Menu.Item>
                    <Menu.Item key='logout' onClick={this.logout}>
                      ログアウト
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='menu-btn'
                type='text'
                icon={<UserOutlined />}
                style={{ position: 'absolute', right: 10 }}
              >
                {this.props.user ? this.props.user.name : 'ユーザー'}
              </Button>
            </Dropdown>

            <ModalDraggable
              width={this.state.childModal.width}
              visible={this.state.childModal.visible}
              component={this.state.childModal.component}
              bodyStyle={{ margin: 0, padding: 0 }}
              maskClosable={false}
              onCancel={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: false,
                  },
                });
              }}
            />
          </div>

          {/* ※表示している画面IDを表示、開発中のみ表示で本番環境ではコメントアウトする */}
          <Tooltip title={'クリックで画面IDをコピーする'}>
            <Button onClick={this.copyScreenID} style={{ height: '26px', fontSize: '12px', position: 'absolute', left: 650, top: 2 }}>
              {'画面ID：' + this.props.componentName}
            </Button>
          </Tooltip>
        </Layout.Header >

        <Layout>
          <Layout.Sider
            className={`scrollbar ${this.state.showMenu ? 'showMenu' : 'hiddenMenu'}`}
          >
            <Menu
              mode='inline'
              className='show-menu-inline'
              defaultSelectedKeys={[this.props.location.pathname]}
              defaultOpenKeys={[this.props.location.pathname]}
              style={{ paddingBottom: '50px', ...menuStyle }}
              onSelect={this.getScreenID}
              inlineIndent={25} //メニューのインデント幅
              theme={'dark'}
            >
              {/* メニューカスタマイズを反映させる場合は下のmapのコメントを外す */}
              {/* {MenuList.map((menuItem, i) => { */}
              {this.state.menuList.map((menuItem, i) => {
                return (
                  <Menu.SubMenu
                    key={`menu_lv1_${i}`}
                    icon={menuItem.icon}
                    title={menuItem.title}
                    style={menuStyle}
                  >
                    {menuItem.submenu.map((subMenuItem, j) => {
                      return subMenuItem.submenu ? (
                        <Menu.SubMenu
                          key={`menu_lv2_${i}_${j}`}
                          icon={subMenuItem.icon}
                          title={subMenuItem.title}
                          style={menuStyle}
                        >
                          {subMenuItem.submenu.map((subMenuItemLV2, k) => {
                            return (
                              <Menu.Item
                                key={subMenuItemLV2.path + `_${i}_${j}_${k}`}
                                icon={subMenuItemLV2.icon}
                                title={subMenuItemLV2.title}
                                style={menuStyle}
                              >
                                {/* <Link to={subMenuItemLV2.path}>{subMenuItemLV2.title}</Link> */}
                                <Link
                                  to={{
                                    pathname: subMenuItemLV2.path,
                                    state: {
                                      title: subMenuItemLV2.title
                                    }
                                  }}
                                  target={process.env.REACT_APP_OPEN_NEW_TAB === 'true' ? '_blank' : ''}
                                >
                                  {/* {(subMenuItemLV2.priority ?? '') + subMenuItemLV2.title} */}
                                  {subMenuItemLV2.title}
                                </Link>
                              </Menu.Item>
                            )
                          })}
                        </Menu.SubMenu>
                      ) : (
                        <Menu.Item
                          key={subMenuItem.path + `_${i}_${j}`}
                          icon={subMenuItem.icon}
                          title={subMenuItem.title}
                          style={menuStyle}
                        >
                          {/* <Link to={subMenuItem.path}>{subMenuItem.title}</Link> */}
                          <Link
                            to={{
                              pathname: subMenuItem.path,
                              state: {
                                title: subMenuItem.title
                              }
                            }}
                            target={process.env.REACT_APP_OPEN_NEW_TAB === 'true' ? '_blank' : ''}
                          >
                            {/* {(subMenuItem.priority ?? '') + subMenuItem.title} */}
                            {subMenuItem.title}
                          </Link>
                        </Menu.Item>
                      )
                    })}
                  </Menu.SubMenu>
                )
              })}
            </Menu>
            {/* <div ><Button style={{float: 'right', marginRight: 10}} onClick={() => this.setState({showMenu: false})} icon={ <DoubleLeftOutlined />}></Button></div> */}
          </Layout.Sider>

          <Layout.Content className='scrollbar' style={{ overflow: 'auto', padding: '5px' }}>
            <Spin spinning={this.props.isLoading} style={{ height: '100%', width: '100%' }}>
              <Switch>
                {RouteDefined.map((routeItem, i) => (
                  <Route
                    path={routeItem.path}
                    component={routeItem.component}
                    exact={routeItem.exact}
                    key={`route_${i}`}
                  />
                ))}
                {/* TODO: Display ID Redirecter. remove before production. */}
                {RouteDefined.map((routeItem, i) => (
                  <Redirect
                    from={`/${routeItem.displayId}`}
                    to={routeItem.path}
                    key={`route_rd_${i}`}
                  />
                ))}
              </Switch>
            </Spin>
          </Layout.Content>
        </Layout>
      </Layout >
    )
  }
}

const mapStateToProps = ({ userReducer, commonReducer }) => ({
  loggedIn: userReducer.loggedIn,
  hospitalCode: userReducer.hospitalCode,
  user: userReducer.user,
  subMenus: userReducer.subMenus,
  componentName: userReducer.component,
  isLoading: commonReducer.loading,
  menuList: userReducer.menuList,
  myMenuList: userReducer.myMenuList
})

const mapDispatchToProps = dispatch => ({
  logoutUserInfo: hospitalCode => dispatch(logoutUserInfo(hospitalCode)),
  setSubMenus: objMenu => dispatch(setSubMenus(objMenu)),
  getMenuList: () => dispatch(getMenuList()),
  setUserInfo: (userInfo, hospitalCode) =>
    dispatch({
      type: UserActionTypes.SET_USER,
      payload: {
        user: userInfo,
        loggedIn: true,
        hospitalCode
      }
    }),
  setDisplayComponent: componentName => dispatch(setDisplayComponent(componentName)),
  getMyMenuList: username => dispatch(getMyMenuList(username))
})
export default connect(mapStateToProps, mapDispatchToProps)(WS2519001_MainMenu)
