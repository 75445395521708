import axios from "configs/axios";

const apiPaths = {
  loadMainSource: '/api/facility-create-hospital-outside-create-info-maintain/facility-hospital-outside-info-maintain/load-mainsource',
  save: '/api/facility-create-hospital-outside-create-info-maintain/facility-hospital-outside-info-maintain/save',
  delete: '/api/facility-create-hospital-outside-create-info-maintain/facility-hospital-outside-info-maintain/delete'
};

const FacilityHospitalOutsideInfoMaintainService = {
  async loadMainSource() {
    return axios.get(apiPaths.loadMainSource);
  },
  async save(params) {
    return axios.post(apiPaths.save, params);
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params });
  }
};

export default FacilityHospitalOutsideInfoMaintainService;
