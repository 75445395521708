import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Space, Menu, Dropdown, message, InputNumber } from 'antd'
import { SearchOutlined, MoreOutlined, DownloadOutlined, UploadOutlined, InfoOutlined } from '@ant-design/icons'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import moment from 'moment'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS2674011_InsuranceInfo from 'pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2674011_InsuranceInfo'
import WS2685001_TransmissionCaptureConfirm from 'pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2685001_TransmissionCaptureConfirm'
import WS2677001_AssociationConsultQualifyResultCapture from 'pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2677001_AssociationConsultQualifyResultCapture'
import WS2674002_R6AmendmentCsvOutput from 'pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2674002_R6AmendmentCsvOutput'

import AssociateInsureQualifyConfirmAction from 'redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociateInsureQualifyConfirm.action'

const labelCol = {
  labelCol: { style: { width: 80 } },
}
const smGrid = {
  labelCol: { style: { width: 65 } },
}
// 右側の幅
const sideWidth = 300

class WS2674001_AssociateInsureQualifyConfirm extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Lio_ItemCodeList: PropTypes.any,
    Lio_ItemNameList: PropTypes.any,
  }

  constructor(props) {
    super(props)

    // document.title = '協会けんぽ資格確認'

    const today = new Date()
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      dataSource: [],
      selectedRows: [],
      selectedRowKeys: [],
      rowDisplayed: [],
      // chk_arr: {},

      listID: [],
      initObj: {},
      KeyInfos: [],
      ItemNameList: [],


      SpecifiedDateChar: moment(new Date())?.format('YYYY/MM/DD'),
      paramsUpdate: [],
      dataOfficeInfoRetrievalQuery: [],

      initParams: {
        DateCharF: moment(new Date())?.format('YYYY/MM/DD'),
        DateCharT: moment(new Date())?.format('YYYY/MM/DD'),
        OfficeCode: '',
        BranchStoreCode: '',
        office_kanji_name: '',
        PersonalNum: '',
        kanji_name: '',
        GetDateCharF: '',
        GetDateCharT: '',
        OutputDateCharF: '',
        OutputDateCharT: '',
        StsNotOutput: 1,
        StsNotYetReceived: 1,
        StsQualificationThere: 1,
        StsWithoutQualification: 1,
        StsError: 1,
        KeyInfo: ''
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current.resetFields()
  }

  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource]

    data[index][field] = value

    this.setState({
      dataSource: data
    })

    this.formRef.current?.setFieldsValue({
      dataTable: data
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  /********************   ****************************** */

  show_sub(params) {
    if (params) {
      let val = params

      this.formRef.current.setFieldsValue({
        visit_date_on_1: val.visit_date_on ? val.visit_date_on : '',
        Li_KanjiName_1: val.kanji_name ? val.kanji_name : '',
        gender: val.gender ? val.gender : '',
        birthdayView: val.birthdayView ? val.birthdayView : '',
        associateAge: val.associateAge ? val.associateAge : '',
        insurer_number: val.insurer_number ? val.insurer_number : '',
        insurer_card_symbol: val.insurer_card_symbol ? val.insurer_card_symbol : '',
        insurer_card_number: val.insurer_card_number ? val.insurer_card_number : '',
        State: val.State ? val.State : '',
        additional_screening_breast: val.additional_screening_breast ? val.additional_screening_breast : '',
        additional_screening_uterine: val.additional_screening_uterine ? val.additional_screening_uterine : '',
        creating_file_name: val.creating_file_name ? val.creating_file_name : '',
        create_file_name: val.create_file_name ? val.create_file_name : '',
        SupplementalInfoWithLineBreaks: val.SupplementalInfoWithLineBreaks ? val.SupplementalInfoWithLineBreaks : '',
      })
    }
  }

  /**
   * 検索処理　データ取得
   */
  searchData() {
    this.setState({
      ItemNameList: []
    })

    let params = {
      ...this.formRef.current?.getFieldValue(),
      dataTable: [],
      DateCharF: moment(this.formRef.current?.getFieldValue('DateCharF'))?.format('YYYY/MM/DD'),
      DateCharT: moment(this.formRef.current?.getFieldValue('DateCharT'))?.format('YYYY/MM/DD'),
      GetDateCharF: moment(this.formRef.current?.getFieldValue('GetDateCharF'))?.format('YYYY/MM/DD'),
      GetDateCharT: moment(this.formRef.current?.getFieldValue('GetDateCharT'))?.format('YYYY/MM/DD'),
      OutputDateCharF: moment(this.formRef.current?.getFieldValue('OutputDateCharF'))?.format('YYYY/MM/DD'),
      OutputDateCharT: moment(this.formRef.current?.getFieldValue('OutputDateCharT'))?.format('YYYY/MM/DD'),

    }
    AssociateInsureQualifyConfirmAction.searchData(params)
      .then((res) => {
        this.setState({
          selectedRows: res ? res.filter(x => x.InsuranceInfoFlag) : [],
          selectedRowKeys: res ? res.filter(x => x.InsuranceInfoFlag).map(x => x.id) : [],
          dataSource: res ? res : []
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }
  /***************************************************** */

  searchOfficeCode = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output.Lio_OfficeCode,
                BranchStoreCode: output.Lio_BranchStoreCode,
                office_kanji_name: output.Lo_Kanji_Name
              })
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 出力　モーダル
   */
  showWS2674002_R6AmendmentCsvOutput = (record) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 370,
        visible: true,
        centered: true,
        component: (
          <WS2674002_R6AmendmentCsvOutput
            selectedRows={(this.state.selectedRows)}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />)
      }
    })
  }

  /**
   * 送信・取込内容確認　モーダル
   */
  showWS2685001_TransmissionCaptureConfirm = (record) => {
    this.setState({
      ...this.state,
      childModal: {
        width: 1200,
        visible: true,
        centered: true,
        component: (
          <WS2685001_TransmissionCaptureConfirm
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />)
      }
    })
  }

  /**
   * 資格結果取り込み モーダル
   */
  showWS2677001_AssociationConsultQualifyResultCapture = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS2677001_AssociationConsultQualifyResultCapture
            onFinishScreen={(output) => {
              this.closeModal()
              //取り込んだ結果を表示
              this.setState({
                selectedRows: output ? output.filter(x => x.InsuranceInfoFlag) : [],
                selectedRowKeys: output ? output.filter(x => x.InsuranceInfoFlag).map(x => x.id) : [],
                dataSource: output ? output : []
              })
            }}
          />
        ),
      },
    })
  }

  /**
   * 個人情報照会　モーダル
   * @param {*} record
   */
  showWS2584019_PersonalInfoInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={record.personal_number_id}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 保険情報 モーダル
   */
  showWS2674011_InsuranceInfo = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS2674011_InsuranceInfo
            Li_PersonalNum={record.personal_number_id}
            Li_CourseLevel={record.course_level}
            Li_ReserveNum={record.reservation_number}
            Li_Date={record.visit_date_on}
            Li_kanjiname={record.kanji_name}
            Li_insurerError={record.insurerError}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 受診照会
   * @param {*} record
   */
  consultReference(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  render() {
    return (
      <div className='associate-insure-qualify-confirm'>
        <Form
          ref={this.formRef}
          initialValues={this.state.initParams}
          autoComplete='off'
        >
          <Card title='協会けんぽ資格確認'>
            <div className='box_inner_horizontal' style={{ marginBottom: 10 }}>
              <div className='box_search'>
                <div className='box_search_inner_border'>
                  <Space>
                    <Form.Item name='DateCharF' label='受診日' {...smGrid}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} />
                    </Form.Item>

                    <Form.Item name='DateCharT' label='~' >
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} />
                    </Form.Item>
                  </Space>

                  <Space>
                    <Form.Item name='OfficeCode' label='事業所' {...smGrid}>
                      <Input.Search
                        className='input-search-size-allow-clear-4'
                        type='text'
                        maxLength={8}
                        allowClear={true}
                        onPressEnter={() => {
                          this.searchOfficeCode()
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.searchOfficeCode()
                          }
                        }
                        }
                        onChange={(e) => {
                          if (e.target.value == '') {
                            this.formRef.current.setFieldsValue({
                              BranchStoreCode: '',
                              office_kanji_name: ''
                            })
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='BranchStoreCode'>
                      <InputNumber maxLength={5} style={{ width: 60, textAlign: 'right' }} />
                    </Form.Item>
                    <Form.Item name='office_kanji_name' >
                      <Input type='text' readOnly style={{ border: 'none' }} />
                    </Form.Item>
                  </Space>

                  <Space>
                    <Form.Item name='OutputDateCharF' label='出力日' {...smGrid} style={{ marginBottom: 0 }}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} />
                    </Form.Item>

                    <Form.Item name='OutputDateCharT' label='~' style={{ marginBottom: 0 }}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} />
                    </Form.Item>
                  </Space>
                </div>
                <div className='box_search_inner' >
                  <Space>
                    <Form.Item name='PersonalNum' label='個人番号' {...smGrid}>
                      <Input.Search
                        type='number'
                        className='input-search-size-number-10'
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              component:
                                <WS0248001_PersonalInfoSearchQuery
                                  date={moment(new Date()).format('YYYY/MM/DD')}
                                  searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current.setFieldsValue({
                                      PersonalNum: output.recordData.personal_number_id,
                                      kanji_name: output.recordData.kanji_name
                                    })
                                    this.closeModal()
                                  }}
                                />
                              ,
                            },
                          })
                        }}
                        onChange={(event) => {
                          this.formRef.current.setFieldsValue({
                            PersonalNum: event.target.value,
                            kanji_name: ''
                          })
                        }}
                      />

                    </Form.Item>
                    <Form.Item name='kanji_name' style={{ width: '100px' }}>
                      <Input type='text' readOnly style={{ border: 'none' }} />
                    </Form.Item>
                  </Space>

                  <Space>
                    <Form.Item name='GetDateCharF' label='取得日' {...smGrid}>
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} />
                    </Form.Item>
                    <Form.Item name='GetDateCharT' label='~' >
                      <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} />
                    </Form.Item>
                  </Space>

                  <Space>
                    <Form.Item name='StsNotOutput' label='未出力' valuePropName='checked' {...smGrid} style={{ marginBottom: 0 }}>
                      <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item name='StsNotYetReceived' label='未送信' valuePropName='checked' style={{ marginBottom: 0 }}>
                      <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item name='StsQualificationThere' label='資格有り' valuePropName='checked' style={{ marginBottom: 0 }}>
                      <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item name='StsWithoutQualification' label='資格無し' valuePropName='checked' style={{ marginBottom: 0 }}>
                      <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item name='StsError' label='エラー' valuePropName='checked' style={{ marginBottom: 0 }}>
                      <Checkbox></Checkbox>
                    </Form.Item>
                  </Space>
                </div>
              </div>

              <div className='box_search_btn'>
                <Button
                  icon={<SearchOutlined />}
                  onClick={() => {
                    this.searchData()
                  }}
                >
                  <span className='btn_label'>
                    検索
                  </span>
                </Button>
              </div>
            </div>

            <div className='box_inner_horizontal'>
              <div style={{ width: 'calc(100% - ' + sideWidth + 'px)' }}>
                <Table
                  bordered
                  size='small'
                  dataSource={this.state.dataSource}
                  pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'max-content', y: resizableTableScroll(130) }}
                  rowSelection={{
                    selectedRowKeys: this.state.selectedRowKeys,
                    onSelect: (record) => {
                      this.show_sub(record)
                      let arrTemp = [...this.state.selectedRowKeys]
                      let arrTempRecord = [...this.state.selectedRows]
                      let idx = arrTemp.indexOf(record.id)
                      if (idx === -1) {
                        arrTemp.push(record.id)
                        arrTempRecord.push(record)
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord,
                          selectedRowOnly: arrTempRecord,
                        })
                      } else {
                        arrTemp.splice(idx, 1)
                        arrTempRecord.splice(idx, 1)
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord,
                          selectedRowOnly: arrTempRecord,
                        })
                      }
                    },
                    onSelectAll: (selected) => {
                      if (selected) {
                        let arrTemp = []
                        let arrTempRecord = []
                        this.state.dataSource.forEach(item => {
                          if (item.InsuranceInfoFlag) {
                            arrTemp.push(item.id)
                            arrTempRecord.push(item)
                          }
                        })
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord,
                          selectedRowOnly: arrTempRecord
                        })
                      } else {
                        this.setState({
                          selectedRowKeys: [],
                          selectedRows: [],
                          selectedRowOnly: []
                        })
                      }
                    },
                    getCheckboxProps: (record) => ({
                      // チェックボックスのdisabled設定
                      disabled: !record.InsuranceInfoFlag
                    }),
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.show_sub(record)
                      },
                      onDoubleClick: () => {
                        this.showWS2685001_TransmissionCaptureConfirm(record)
                      }
                    }
                  }}
                >
                  <Table.Column
                    title='保険'
                    dataIndex='InsuranceInfo'
                    width={50}
                    render={(value, record, index) => {
                      // 保険情報がない場合
                      if (!record.InsuranceInfoFlag) {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              size='small'
                              onClick={() => {
                                this.showWS2674011_InsuranceInfo(record)
                              }}
                            >
                              {record.InsuranceInfo ? record.InsuranceInfo : ''}
                            </Button>
                          </div>
                        )
                      }
                    }}
                  />
                  <Table.Column
                    title='受診日'
                    dataIndex='visit_date_on'
                    width={100}
                  />
                  <Table.Column
                    title='個人番号'
                    dataIndex='personal_number_id'
                    width={120}
                    render={(value) => (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    )}
                  />
                  <Table.Column
                    title='メモ'
                    dataIndex=''
                    width={40}
                    align='center'
                    render={(text, record) => {
                      let icon = null
                      icon = <InfoOutlined />
                      return (
                        <Button
                          size='small'
                          // style={{ color: Color(record.Expression_11)?.Foreground }}
                          style={{ borderRadius: '12px' }}
                          icon={icon}
                          onClick={() => {
                            this.showWS2584019_PersonalInfoInquirySub()
                          }}
                        />
                      )
                    }}
                  />
                  <Table.Column title='氏名' dataIndex='kanji_name' />
                  <Table.Column title='事業所名' dataIndex='office_kanji_name' />
                  <Table.Column title='種別' dataIndex='divisionName' width={50} />
                  <Table.Column title='資格' dataIndex='Qualification' width={40} />
                  <Table.Column title='状態' dataIndex='State' width={80} />
                  <Table.Column title='乳' dataIndex='QualificationBreast' width={40} />
                  <Table.Column title='子' dataIndex='QualificationUterus' width={40} />
                  <Table.Column title='補足情報' dataIndex='SupplementalInfo' className='column-size-40' />
                  <Table.Column
                    width={40}
                    key='action'
                    align='center'
                    fixed='right'
                    render={(row, record) => {
                      return (
                        <Dropdown
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='menu-1'
                                onClick={() => {
                                  this.showWS2584019_PersonalInfoInquirySub(row)
                                }}>
                                個人照会
                              </Menu.Item>
                              <Menu.Item
                                key='menu-2'
                                onClick={() => {
                                  this.consultReference(row)
                                }}>
                                受診照会
                              </Menu.Item>
                              <Menu.Item
                                key='menu-3'
                                onClick={() => {
                                  this.resultListCSV(row)
                                }}>
                                結果一覧CSV
                              </Menu.Item>
                              <Menu.Item
                                key='menu-4'
                                onClick={() => {
                                  this.showWS2685001_TransmissionCaptureConfirm(record)
                                }}>
                                出力取込内容
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button size='small' icon={<MoreOutlined />} />
                        </Dropdown>
                      )
                    }} />
                </Table>
              </div>
              <div style={{
                display: 'flex', flexDirection: 'column', gap: 10,
                minWidth: sideWidth, maxWidth: sideWidth
              }}>
                <div style={{ padding: 8, border: '1px solid #cfcfcf' }}>
                  <Form.Item name='visit_date_on_1' label='受診日' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='Li_KanjiName_1' label='氏名' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='gender' label='性別' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='birthdayView' label='生年月日' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='associateAge' label='協会年齢' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='insurer_number' label='保険者番号' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='insurer_card_symbol' label='保険証記号' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='insurer_card_number' label='保険証番号' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                </div>

                <div
                  style={{ padding: 8, border: '1px solid #cfcfcf', }}>
                  <Form.Item name='State' label='状態' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='additional_screening_breast' label='追加(乳)' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='additional_screening_uterine' label='追加(子宮)' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='creating_file_name' label='出力ﾌｧｲﾙ(最終)' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='create_file_name' label='取込ﾌｧｲﾙ' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='' label='返送補足情報' style={{ marginBottom: 0 }} {...labelCol}>
                    <Input type='text' readOnly style={{ border: 'none' }} />
                  </Form.Item>
                  <Form.Item name='SupplementalInfoWithLineBreaks' style={{ border: '1px solid #cfcfcf', marginBottom: 0 }}>
                    <Input.TextArea rows={3} readOnly style={{ border: 'none', background: 'transparent' }} />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                icon={<DownloadOutlined />}
                type='primary'
                onClick={() => this.showWS2674002_R6AmendmentCsvOutput()}>
                <span className='btn_label'>
                  出力
                </span>
              </Button>

              <Button
                icon={<UploadOutlined />}
                type='primary'
                onClick={() => {
                  this.showWS2677001_AssociationConsultQualifyResultCapture()
                }}>
                <span className='btn_label'>
                  取込
                </span>
              </Button>

            </div>
          </Card>
        </Form>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2674001_AssociateInsureQualifyConfirm)
