import DataRangeCheckInputService from "services/SpecificInsureMaintenance/DataRangeCheck/DataRangeCheckInputService";

import { message } from "antd";

const DataRangeCheckInputAction = {
  dataRangeCheckInput(data) {
    return DataRangeCheckInputService.dataRangeCheckInput(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw err
      });
  },

  saveDataRangeCheckInput(data) {
    return DataRangeCheckInputService.saveDataRangeCheckInput(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw err
      })
  },

  deleteDataRangeCheckInput(data) {
    return DataRangeCheckInputService.deleteDataRangeCheckInput(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw err
      })
  },

}
export default DataRangeCheckInputAction;
