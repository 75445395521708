import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import { MoreOutlined, CheckOutlined, MenuOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Checkbox, Table, message, Button, Dropdown, Menu, Tooltip } from "antd";

import ModalDraggable from "components/Commons/ModalDraggable";
import WS0483001_ConditionExpressCmtSub from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0483001_ConditionExpressCmtSub.jsx';
import WS0482011_CmtContentModification from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0482011_CmtContentModification.jsx';
import ConditionExpressCmtSettingAction from "redux/InspectionMaintenance/ConditionExpressCmtSetting/ConditionExpressCmtSetting.action";
import WS0493002_ConditionCopying from "pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0493002_ConditionCopying.jsx";
import moment from "moment";
import WS2701001_CautionGuideMatterCmtMaintain from "pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS2701001_CautionGuideMatterCmtMaintain.jsx"
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0482001_ConditionExpressCmtSetting extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    // document.title = '条件式コメント設定';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      CommentGroup: [],
      dataSource: [],
    };
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getCommentGroupCombBox();
  }

  getCommentGroupCombBox() {
    ConditionExpressCmtSettingAction.getCommentGroupCbx()
      .then((res) => {
        this.setState({
          CommentGroup: res ? res : []
        })

        this.getDataTeachChingItemList();
      })
  }

  getDataTeachChingItemList() {
    let params = {
      ...this.formRef.current?.getFieldValue(),
      SpecifiedDateChar: this.formRef.current?.getFieldValue('SpecifiedDateChar')?.format("YYYY/MM/DD"),
      dataTableEnabled: []
    }

    ConditionExpressCmtSettingAction.getDataTeachChingItemList(params)
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
        })

        this.formRef.current?.setFieldsValue({
          dataTableEnabled: res ? res.map(x => x.StsEnable) : [],
        })
      })
  }

  /**
   *  条件変更
   * @param {*} record
   */
  ConditionsChange(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0483001_ConditionExpressCmtSub
            record={record}

            onFinishScreen={(output) => {
              this.getDataTeachChingItemList();
            }}
          />
        ),
      },
    })
  }

  /**
   *  変更
   * @param {*} record
   */
  cmtContentModification(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 500,
        component: (
          <WS0482011_CmtContentModification
            record={record}

            onFinishScreen={(output) => {
              let prams = {
                ...output,
                id: record.id,
                old_start_date_on: record.start_date_on,
                comment_code: record.comment_code,
                identification_code: record.identification_code,
                search_key: record.search_key
              }

              ConditionExpressCmtSettingAction.updateTeachChingItemList(prams)
                .then(res => {
                  this.getCommentGroupCombBox();
                })
                .catch((err) => {
                  const res = err.response;
                  if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                  }
                  message.error(res.data.message);
                });

              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
 * 複写処理
 */
  originCopy(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component:
          <WS0493002_ConditionCopying
            record={record}

            onFinishScreen={(output) => {
              let prams = {
                ...output,
                StsGuideCmt: true, // コメント
                StsStartDate: true, // 開始日
                StsSerialNum: false, // 連番
              }

              ConditionExpressCmtSettingAction.originCopy(prams)
              this.closeModal()
            }}
          />
        ,
      },
    });
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  eventF10 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS2701001_CautionGuideMatterCmtMaintain
            onFinishScreen={(output) => {
              this.getDataTeachChingItemList();
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <div className="condition-express-cmt-setting">
        <Card title="条件式コメント設定">
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top'
              trigger='click'
              size='small'
              style={{ position: 'absolute', top: 16, right: 60, borderRadius: 10 }}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={this.eventF10}>
                      注意・指導事項コメント保守
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Form
            ref={this.formRef}
            initialValues={{
              SpecifiedDateChar: moment(new Date()),
              AlreadySet: 0
            }}
            autoComplete='off'
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>

                <Form.Item
                  name="SearchChar"
                  style={{ marginBottom: 0 }}
                >
                  <Input className="input-size-30" />
                </Form.Item>
                <Button
                  style={{ marginBottom: 0, marginRight: '10px' }}
                  onClick={() => { this.getDataTeachChingItemList() }}
                >
                  検索
                </Button>

                <Form.Item
                  name="CommentGroup"
                  label="コメント群"
                  style={{ marginRight: '10px', marginBottom: 0 }}
                >
                  <Select
                    style={{ width: '200px' }}
                    allowClear={true}
                    onChange={(value) => { this.getDataTeachChingItemList() }}
                  >
                    {this.state.CommentGroup?.map(value => (
                      <Select.Option
                        key={"CommentGroup" + Math.random()}
                        value={value.CommentGroup}
                      >
                        {value.CommentGroup}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="SpecifiedDateChar"
                  label="指定日付"
                  style={{ marginRight: '10px', marginBottom: 0 }}
                >
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    format='YYYY/MM/DD'
                    onChange={() => { this.getDataTeachChingItemList() }}
                  />
                </Form.Item>

                <Form.Item
                  name="AlreadySet"
                  label="設定"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox
                    onChange={(event) => {
                      this.formRef.current?.setFieldsValue({ AlreadySet: event.target.checked ? 1 : 0 });
                      this.getDataTeachChingItemList();
                    }}
                  />
                </Form.Item>
              </div>

              <Table
                bordered size='small'
                dataSource={this.state.dataSource}
                scroll={{ y: resizableTableScroll(80) }}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                rowKey={(record) => record.id}
                onRow={(record, index) => ({
                  onDoubleClick: () => { this.ConditionsChange(record) }
                })}
              >
                <Table.Column
                  title="条件"
                  dataIndex="StsEnable"
                  align='center'
                  width={45}
                  render={(value, record, index) => {
                    if (value) {
                      return <CheckOutlined />
                    } else {
                      return ''
                    }
                  }}
                />

                <Table.Column
                  title="コード"
                  dataIndex="comment_code"
                  width={100}
                  render={(record) => {
                    return <div style={{ textAlign: 'right' }}>{record}</div>
                  }}
                />

                <Table.Column
                  title="指導内容"
                  dataIndex="comment_content"
                />

                <Table.Column
                  title="コメント群"
                  dataIndex="comment_group"
                  width={200}
                />

                <Table.Column
                  title="重み"
                  dataIndex="WeightOfJudge"
                  width={70}
                  render={(record) => {
                    return <div style={{ textAlign: 'right' }}>{record}</div>
                  }}
                />

                <Table.Column
                  title="優先"
                  dataIndex="priority"
                  width={70}
                  render={(record) => {
                    return <div style={{ textAlign: 'right' }}>{record}</div>
                  }}
                />

                <Table.Column
                  title="適用日"
                  dataIndex="start_date_on"
                  width={100}
                />

                <Table.Column
                  width={40}
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='変更'
                              onClick={() => (
                                this.cmtContentModification(record)
                              )}
                            >
                              変更
                            </Menu.Item>

                            <Menu.Item
                              key='条件変更'
                              onClick={() => (
                                this.ConditionsChange(record)
                              )}
                            >条件変更
                            </Menu.Item>

                            {/* <Menu.Item
                              key='複写'
                            onClick={() => (
                              this.originCopy(record)
                            )}
                            >
                              複写
                            </Menu.Item> */}
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                          style={{ width: '100%' }}
                        ></Button>
                      </Dropdown>
                    )
                  }}
                />

              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0482001_ConditionExpressCmtSetting);