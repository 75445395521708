import axios from 'configs/axios'

const API_LIST = {
  index: '/api/radiography-judge-maintain/radiography-judge-maintain',
  delete: '/api/radiography-judge-maintain/radiography-judge-maintain/delete',
  save: '/api/radiography-judge-maintain/radiography-judge-maintain/save',
}

const RadiographyJudgeMaintainService = {
  async index() {
    return axios.get(API_LIST.index)
  },

  async delete(params) {
    return axios.post(API_LIST.delete, params);
  },

  async save(params) {
    return axios.post(API_LIST.save, params);
  },
}

export default RadiographyJudgeMaintainService
