import React from "react";
import { connect } from "react-redux";
import { PlusOutlined, MoreOutlined } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Button, Table, Row, Col, Space, Modal, message, Dropdown, Menu } from "antd";
import { getCourseSubInputAction, BatchProcess_F10Action, deleteCourseAction, getSetSubprocessAction } from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/CourseSelect.action";

import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import WS1174006_CourseCopyingFunction from "./WS1174006_CourseCopyingFunction";
import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import WS2704001_CategoryMasterMaintain from "pages/MS_InspectionMaintenance/V4MS0102000_CategoryMasterMaintain/WS2704001_CategoryMasterMaintain.jsx";

import WS1176001_InspectSetInfo from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176001_InspectSetInfo.jsx";
import WS0181001_QueryInspectSet from "./WS0181001_QueryInspectSet";
import WS1174003_CourseSelect_InspectGroup from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1174003_CourseSelect_InspectGroup.jsx";

const styleCol = {
  borderRight: "1px solid #F0F0F0"
};

class WS1174003_CourseSelect_sub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      mainPageSize: 10,
      rowSelectMain: {},
      isLoadingTableMain: false,
      dataSourceSubSite: [],
      isLoadingTableSubSite: false,
      courseCode: this.props.course_code,
      dataSourceSubFinding: [],
    };
  }

  componentDidMount = () => {
    this.loadDataMainTable();
  };

  BatchProcess_F10 = () => {
    BatchProcess_F10Action()
      .then(res => {
        if (res?.data) {
          message.info(res.data.message);
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  loadDataMainTable = () => {
    getCourseSubInputAction(this.state.courseCode)
      .then(res => {
        if (res) {
          this.setState({
            dataSourceSubSite: res.data
          });
          this.formRef.current?.setFieldsValue({
            dataSourceSubSite: res.data
          });
        }
      })
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  ReturnComponent = component => {
    let components = {
      WS2704001_CategoryMasterMaintain,
      WS1176001_InspectSetInfo,
      WS1174006_CourseCopyingFunction,
      WS0181001_QueryInspectSet
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  getExamItem(params) {
    const data = { Li_SetCode: params.test_set }
    getSetSubprocessAction(data)
      .then(res => {
        console.log(res)

        if (res) {
          this.setState({
            dataSourceSubFinding: res.data
          });
        }
      })
      .catch()
  }

  renderMenuBar = () => (
    <Space>
      <Button
        type="primary"
        onClick={() => {
          this.callModal({ Li_Flag: true }, "80%", "WS2704001_CategoryMasterMaintain");
        }}
      >
        カテゴリ
      </Button>
      <Button
        type="primary"
        onClick={() =>
          this.callModal({ Li_BillingManageNum: "" }, "70%", "WS1176001_InspectSetInfo")
        }
      >
        セット
      </Button>
      {/* <Button
        type="primary"
        onClick={() => {
          this.callModal(
            {
              Li_course_code: this.state.rowSelectMain ? this.state.rowSelectMain.course_code : "",
              Li_course_name_short_name: this.state.rowSelectMain
                ? this.state.rowSelectMain.course_name_short_name
                : "",
              Li_course_name_formal: this.state.rowSelectMain
                ? this.state.rowSelectMain.course_name_formal
                : ""
            },
            "70%",
            "WS1174006_CourseCopyingFunction"
          );
        }}
      >
        複写
      </Button> */}
      <Button type="primary" onClick={this.BatchProcess_F10}>
        一括処理
      </Button>
    </Space>
  );

  render() {
    return (
      <div className="course-select">
        <Card title="グループ選択">
          {this.renderMenuBar()}

          <hr></hr>

          <Form
            ref={this.formRef}
            style={{ display: "flex" }}
            autoComplete='off'
          >
            <div className="box_inner_horizontal">
              <div style={{ width: '40%' }}>
                <Table
                  bordered
                  size="small"
                  scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                  dataSource={this.state.dataSourceSubSite}
                  loading={this.state.isLoadingTableMain}
                  pagination={this.state.dataSourceSubSite.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  rowKey={record => record.id}
                  onRow={(record, index) => ({
                    onClick: event => { this.getExamItem(record) }
                  })}
                >
                  <Table.Column
                    title="SEQ"
                    dataIndex="screen_display_order"
                    width={100}
                  />
                  <Table.Column
                    title="グループ"
                    dataIndex="group_name"
                  />
                  <Table.Column
                    title="セットコード"
                    dataIndex="test_set"
                    width={110}
                  />

                  <Table.Column
                    align='center'
                    width={40}
                    fixed='right'
                    title={
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        loading={this.state.isLoadingInsurersList}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              component: (
                                <WS1174003_CourseSelect_InspectGroup
                                  newFlag={true}
                                  courseCode={this.props.course_code}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                    this.loadDataMainTable();
                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      />
                    }
                    render={(text, record, index) => (
                      <Space >
                        <Dropdown
                          trigger='click'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='2'
                                style={{ width: '100%' }}
                                onClick={() =>
                                  this.callModal({ Li_BillingManageNum: "" }, "70%", "WS1176001_InspectSetInfo")
                                }
                              // onClick={() => (
                              //   this.setState({
                              //     childModal: {
                              //       ...this.state.childModal,
                              //       visible: true,
                              //       centered: true,
                              //       width: 600,
                              //       component: (
                              //         <WS0271001_InspectItemSearchQuerySingle
                              //           onFinishScreen={output => {
                              //             this.closeModal();
                              //           }}
                              //         />
                              //       ),
                              //     },
                              //   })
                              // )}
                              >
                                セットコード照会
                              </Menu.Item>

                              <Menu.Item
                                key='1'
                                style={{ width: '100%' }}
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 600,
                                      component: (
                                        <WS1174003_CourseSelect_InspectGroup
                                          newFlag={false}
                                          courseCode={this.props.course_code}
                                          record={record}
                                          onFinishScreen={() => {
                                            this.closeModal()
                                            this.loadDataMainTable();
                                          }}
                                          onDelete={() => {
                                            this.closeModal()
                                            const params = {
                                              id: record.id
                                            }
                                            deleteCourseAction(params)
                                              .then(res => {
                                                this.closeModal()
                                                this.loadDataMainTable();
                                                this.getExamItem({ Li_SetCode: null })
                                                message.info('削除しました');
                                              }).catch(error => {
                                                const res = error.response;
                                                if (!res || res.data || res.data.message) {
                                                  message.error('エラーが発生しました');
                                                }
                                              }).finally(() => this.setState({ isLoadingTableSubSite: false }))
                                          }}
                                        />
                                      ),
                                    },
                                  })
                                }}
                              >
                                修正
                              </Menu.Item>

                              <Menu.Item
                                key='3'
                                style={{ width: '100%' }}
                                onClick={() => {
                                  // 確認モーダルを表示してから、削除
                                  Modal.confirm({
                                    content: `削除を行いますか?`,
                                    okText: 'は　い',
                                    cancelText: 'いいえ',
                                    onOk: () => {
                                      const params = {
                                        id: record.id
                                      }
                                      deleteCourseAction(params)
                                        .then(res => {
                                          this.closeModal()
                                          this.loadDataMainTable();
                                          this.getExamItem({ Li_SetCode: null })
                                          message.info('削除しました');
                                        }).catch(error => {
                                          const res = error.response;
                                          if (!res || res.data || res.data.message) {
                                            message.error('エラーが発生しました');
                                          }
                                        }).finally(() => this.setState({ isLoadingTableSubSite: false }))
                                    }
                                  })
                                }}
                              >
                                削除
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />}
                          >
                          </Button>
                        </Dropdown>
                      </Space>
                    )}
                  />
                </Table>
              </div>

              <div style={{ width: '60%' }}>
                <Table
                  bordered
                  size="small"
                  scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                  loading={this.state.isLoadingTableMain}
                  pagination={this.state.dataSourceSubFinding.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  dataSource={this.state.dataSourceSubFinding}
                  rowKey={record => record.id}
                >
                  <Table.Column
                    title="SEQ"
                    dataIndex="screen_display_order"
                    width={100}
                  />

                  <Table.Column
                    title="コード"
                    dataIndex="exam_item"
                    width={100}
                  />

                  <Table.Column
                    title="検査名称"
                    dataIndex="exam_name"
                  />
                  <Table.Column
                    title="タイプ"
                    dataIndex="exam_type"
                    width={100}
                  />
                </Table>
              </div>
            </div>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1174003_CourseSelect_sub);
