///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Table, Checkbox, Modal, Menu, Dropdown, Button, message, Tooltip } from 'antd'
import { MoreOutlined, QuestionCircleOutlined, MenuOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { WS0299006_ConfirmScreen } from './WS0299006_ConfirmScreen'
import { debounce } from 'lodash'

import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS2708010_CopySet from 'pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2708010_CopySet.jsx'
import WS0333001_SetIncludesQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333001_SetIncludesQuery'
import WS2710001_SetInfoBatchProcess from './WS2710001_SetInfoBatchProcess'
import WS0333300_SetIncludesQueryInformationEdit from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0333300_SetIncludesQueryInformationEdit'
import SetInfoMaintainAction from 'redux/basicInfo/SetInfoMaintain/SetInfoMaintain.action'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import SetInfoChangeSubAction from 'redux/basicInfo/SetInfoMaintain/SetInfoChangeSub.action'
import WS0061009_CheckYesNoYes from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061009_CheckYesNoYes'
import ConfirmScreenAction from 'redux/basicInfo/SetInfoMaintain/ConfirmScreen.action'
import Color from 'constants/Color'
import WS0431001_CsvPreviewConfirm from 'pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS0431001_CsvPreviewConfirm'
class WS2708001_SetInfoMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'セット情報保守'

    this.state = {
      dataSource: [],
      selectedRowKeys: [],
      rowSelected: [],
      showLainspectOnly: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      IndividualItem: false,
      lstSetIdentify: [],

      changeValueInfoChangeSub: false,
      stsChangeSub: '',
      dataInfoChangeSub: {},
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getListSetIdentify()
  }

  getListSetIdentify() {
    SetInfoMaintainAction.getListSetIdentify()
      .then((res) => {
        this.setState({
          lstSetIdentify: res ? res.ComboBox_SetIdentify : []
        })

        this.formRef.current?.setFieldsValue({
          SetIdentify: res && res.ComboBox_SetIdentify.length > 0 ? res.ComboBox_SetIdentify[0].LinkField : ''
        })

        this.getDataTableSubSet(true)
      })
  }

  getDataTableSubSet = (reload = false) => {
    const params = { ...this.formRef.current.getFieldsValue() }

    SetInfoMaintainAction.getSetInfoTableDataSubSet(params)
      .then(
        (res) => {
          let index = reload ? 0 : res?.findIndex(x => x.id === this.state.rowSelected[0]?.id)
          for (let i = 0; i < res.length; i++) {
            if (res[i].set_identification === 'Cos') {
              res[i].set_identification = 'コース'
            }
            if (res[i].set_identification === 'Opt') {
              res[i].set_identification = 'オプション'
            }
            if (res[i].set_identification === 'Set') {
              res[i].set_identification = 'セット'
            }
          }
          this.setState({
            dataSource: res ? res : [],
            rowSelected: res && res.length > 0 ? [res[index]] : [],
            selectedRowKeys: res && res.length > 0 ? [res[index]?.id] : [],
          })
        }
      )
  }

  onChangeInput(event, values, field) {
    if (field === 'DesignatedInspectCode') {
      if (event.target.value) {
        this.setState({ showLainspectOnly: true })
        this.formRef.current.setFieldsValue({
          exam_name: '',
        })
      } else {
        this.setState({ showLainspectOnly: false })
        this.formRef.current.setFieldsValue({
          exam_name: '',
          StsLainspectOnly: 0
        })
      }
    }

    this.getDataTableSubSet()
  }

  onFinish(values) {
    this.getDataTableSubSet()
  }

  changeDivision(id) {
    let params = {
      id: id
    }

    SetInfoMaintainAction.changeDivision(params)
      .then((res) => {
        this.getDataTableSubSet()
      })
  }

  deleteData(set_code, id) {
    let params = {
      id: id,
      set_code: set_code,
      SetIdentify: this.formRef.current.getFieldValue('SetIdentify')
    }

    let data = [...this.state.dataSource]
    let index = data.findIndex(x => x.id === id)

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0061009_CheckYesNoYes
            Li_DisplayContent={'削除を実行しますか ?'}
            onFinishScreen={(output) => {
              if (output.Lio_StsReturn) {
                SetInfoMaintainAction.deleteData(params)
                  .then((res) => {
                    // this.getDataTableSubSet()
                    if (index > -1) {
                      data.splice(index, 1)
                      this.setState({
                        dataSource: data,
                        rowSelected: data.length > 0 ? [data[0]] : [],
                        selectedRowKeys: data.length > 0 ? [data[0].id] : [],
                      })
                    }
                    message.success('正常に削除されました !')
                  })
                  .catch((err) => {
                    const res = err.response
                    if (!res || !res.data || !res.data.message) {
                      message.error('エラーが発生しました')
                      return
                    }
                    message.error(res.data.message)
                  })
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  callApiGetScreenDataConFirmScreen() {
    let params = {
      SetCode: this.state.dataInfoChangeSub.Li_SetCode,
      StartDate: this.state.dataInfoChangeSub.StartDate
    }
    ConfirmScreenAction.GetDataScreen(params)
      .then(async (res) => {
        if (res?.data && res?.data?.ContractInspection.length > 0) {
          this.showModalConFirmScreen(res?.data)
        } else {
          this.updateSetInfoChangeSub()
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => { })
  }

  showModalConFirmScreen(data) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0299006_ConfirmScreen
            Li_SetCode={this.state.dataInfoChangeSub.Li_SetCode}
            Li_StartDate={this.state.dataInfoChangeSub.StartDate}
            Li_Values={this.state.dataInfoChangeSub}
            Li_DataScreen={data}
            onFinishScreen={() => {
              this.closeModal()
              this.getDataTableSubSet(false)
            }}
          />
        ),
      },
    })
  }

  updateSetInfoChangeSub() {
    let params = {
      ...this.state.dataInfoChangeSub
    }
    SetInfoChangeSubAction.updateButton(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
            Lo_SetIdentify: params.SetIdentify,
            Lio_SetCode: params.SetCode,
            Lio_start_date_on: params.StartDate,
          })
        }
        this.getDataTableSubSet(false)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  createDataSetInfoChangeSub() {
    let params = {
      ...this.state.dataInfoChangeSub
    }
    SetInfoChangeSubAction.createData(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
            Lo_SetIdentify: params.SetIdentify,
            Lio_SetCode: params.SetCode,
            Lio_start_date_on: params.StartDate,
          })
        }
        this.getDataTableSubSet(true)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  changeCondition(value) {
    let params = {
      Li_SetCode: value.Li_SetCode,
      SetCode: value.SetCode,
      ConditionEffective: value.ConditionEffective,
    }
    SetInfoChangeSubAction.changeCondition(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
            Lo_SetIdentify: params.SetIdentify,
            Lio_SetCode: params.SetCode,
            Lio_start_date_on: params.StartDate,
          })
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  getExamName() {
    let params = {
      DesignatedInspectCode: this.formRef.current?.getFieldValue('DesignatedInspectCode')
    }

    SetInfoChangeSubAction.getExamName(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          exam_name: res?.data?.exam_name
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
      changeValueInfoChangeSub: false
    })
  }

  BtnF09(data) {
    SetInfoMaintainAction.btnf09({ SetIdentify: data })
      .then(res => {
        this.CallScreenWS0431(res.data.variables)
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => { })
  }

  CallScreenWS0431(data) {
    this.setState({
      childModal: {
        visible: true,
        width: 400,
        component: (
          <WS0431001_CsvPreviewConfirm
            Lio_OutputMethod={data.Lio_OutputMethod}
            Lio_Output={data.Lio_Output}
            Lio_Preview={data.Lio_Preview}
            onFinishScreen={(output) => {
              let parram = {
                OutputMethod: output.Lio_OutputMethod,
                Output: output.Lio_Output,
                Preview: output.Lio_Preview,
                Li_SetIdentify: data.Li_SetIdentify,
                Li_SetCode: data.Li_SetCode,
              }
              this.OutBtnF09(parram)
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  OutBtnF09(data) {
    let parram = {
      ...data,
    }
    SetInfoMaintainAction.outbtnf09(parram)
      .then(res => {
        console.log(res)
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => { })
  }

  render() {
    return (
      <div className='set-info-maintain'>
        <Card title='セット情報マスタ'>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.Li_Flag) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.BtnF09(this.formRef.current?.getFieldValue('SetIdentify'))}
                    >
                      出力
                    </Menu.Item>

                    <Menu.Item key='2' style={{ width: '100%' }}
                      onClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS2710001_SetInfoBatchProcess
                                onChangeValue={(obj) => {
                                  if (obj.Lo_stsChangeValue) {
                                    this.getDataTableSubSet(true)
                                  }
                                }}

                                onFinishScreen={(output) => {
                                  this.getDataTableSubSet(true)
                                }}
                              />
                            ),
                          },
                        })}
                    >
                      一括処理
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              StsContract: 0,
              StsLainspectOnly: 0,
            }}
            onClick={() => this.setState({ rowSelected: [], selectedRowKeys: [] })}
          >
            <div className='box_inner_horizontal'>
              <div className='box_search'>
                <div className='box_search_inner'>
                  <div className='box_inner_horizontal'>
                    <Form.Item name='SetIdentify' label='識別' style={{ marginBottom: 0 }}>
                      <Select style={{ width: '120px' }}
                        onChange={(value) => {
                          this.getDataTableSubSet(true)
                        }}
                      >
                        {this.state.lstSetIdentify.map((value) => (
                          <Select.Option
                            key={'SetIdentify_' + Math.random()}
                            value={value.LinkField}
                          >
                            {value.DisplayField}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Form.Item
                        name='StsContract'
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox
                          onChange={(e) => {
                            this.formRef.current.setFieldsValue({
                              StsContract: e.target.checked ? 1 : 0
                            })
                            this.getDataTableSubSet(true)
                          }}
                        >
                          共有のみ
                        </Checkbox>
                      </Form.Item>

                      <Form.Item
                        name='IndividualItem'
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox
                          onChange={(e) => {
                            this.formRef.current.setFieldsValue({
                              IndividualItem: e.target.checked ? 1 : 0
                            })
                            this.setState({
                              IndividualItem: e.target.checked ? true : false
                            })
                            this.getDataTableSubSet(true)
                          }}
                        >
                          単品表示
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='Name'
                      label='検索'
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        type='text'
                        onPressEnter={(event) => this.onChangeInput(event, this.formRef.current.getFieldValue(), 'Name')}
                      />
                    </Form.Item>

                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Form.Item
                        label='検査'
                        name='DesignatedInspectCode'
                        style={{ width: 160, marginRight: 5, marginBottom: 0 }}
                      >
                        <Input.Search
                          type='number'
                          min={0}
                          onSearch={(value, event) => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0271001_InspectItemSearchQuerySingle
                                    onFinishScreen={(output) => {
                                      this.formRef.current.setFieldsValue({
                                        DesignatedInspectCode: output.recordData.test_item_code,
                                        exam_name: output.recordData.exam_name,
                                      })

                                      if (output.recordData.test_item_code) {
                                        this.setState({ showLainspectOnly: true })
                                      }
                                      this.getDataTableSubSet(true)
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                          onPressEnter={debounce((event) => {
                            this.onChangeInput(event, this.formRef.current.getFieldValue(), 'DesignatedInspectCode')
                            this.getExamName()
                          }, 300)}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='exam_name'
                      style={{ width: '150px', marginBottom: 0 }}
                    >
                      <div>{this.formRef.current?.getFieldValue('exam_name')}</div>
                    </Form.Item>

                    <div
                      style={{ display: 'flex', gap: '8px' }} hidden={!this.state.showLainspectOnly}>
                      <Form.Item
                        name='StsLainspectOnly'
                        valuePropName='checked'
                        style={{ marginBottom: 0 }}
                      >
                        <Checkbox
                          onChange={(e) => {
                            this.formRef.current.setFieldsValue({
                              StsLainspectOnly: e.target.checked ? 1 : 0
                            })
                            this.getDataTableSubSet(true)
                          }}
                        >
                          最新のみ
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>

          <Table
            size='small'
            style={{ cursor: 'pointer', marginTop: '10px' }}
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            rowKey={(record) => record.id}
            scroll={{ y: resizableTableScroll(130) }}
            bordered
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '60%',
                      component: (
                        <WS0333001_SetIncludesQuery
                          Li_SetCode={record.set_code}
                          Li_CourseCode={record.set_code}
                          onRow={record}
                          Li_StartDate={record.start_date_on ? record.start_date_on.replace(/-/g, '/') : ''}
                          batch={true}
                          onFinishScreen={(output) => {
                            this.getDataTableSubSet(true)
                          }}
                          closeModal={() => {
                            this.closeModal()
                            this.getDataTableSubSet(true)
                          }}
                        />
                      ),
                    },
                  })
                },
                onClick: () => {
                  this.setState({
                    rowSelected: [record],
                    selectedRowKeys: [record.id]
                  })
                }
              }
            }}
          >
            <Table.Column
              title='コード'
              dataIndex='set_code'
              width={100}
            />

            <Table.Column
              title='正式名称'
              dataIndex='set_name'
            />

            <Table.Column
              title='略式名称'
              dataIndex='set_short_name'
            />

            <Table.Column
              title='条'
              width={40}
              align='center'
              dataIndex='Expression_34'
              render={(value, record) => {
                return (
                  <div
                    style={{
                      background: Color(record.Expression_34).Background,
                      border: '1px solid',
                      borderColor: Color(record.Expression_34).Foreground,
                      textAlign: 'center',
                      color: Color(record.Expression_34).Foreground,
                    }}
                  >
                    条
                  </div>
                )
              }}
            />

            <Table.Column
              title='単価'
              dataIndex='unit_price'
              width={120}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>
                    {value === 0 ? '' : value?.toLocaleString()}
                  </div>
                )
              }}
            />

            <Table.Column
              title='開始日'
              dataIndex='start_date_on'
              width={120}
            />

            <Table.Column
              title='区分'
              dataIndex='Expresstion_32'
              width={80}
            />

            <Table.Column
              title='識別'
              dataIndex='set_identification'
              width={100}
            />

            <Table.Column
              width={40}
              align='center'
              title={
                this.state.IndividualItem ? null :
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 550,
                          component: (
                            <WS0333300_SetIncludesQueryInformationEdit
                              newFlag={true}
                              data={{ id: null }}
                              onFinishScreen={(output) => {
                                this.closeModal()
                                this.getDataTableSubSet(true)
                              }}

                            />
                          )
                        }
                      })
                    }
                  />
              }

              render={(value, record) => (
                <Dropdown
                  trigger='click'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='1'
                        onClick={() =>
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '60%',
                              component: (
                                <WS0333001_SetIncludesQuery
                                  Li_SetCode={record.set_code}
                                  Li_CourseCode={record.set_code}
                                  onRow={record}
                                  Li_StartDate={record.start_date_on ? record.start_date_on.replace(/-/g, '/') : ''}
                                  batch={true}
                                  onFinishScreen={(output) => {
                                    this.getDataTableSubSet(true)
                                  }}
                                  closeModal={() => {
                                    this.closeModal()
                                    this.getDataTableSubSet(true)
                                  }}
                                />
                              ),
                            },
                          })
                        }
                      >
                        編集
                      </Menu.Item>

                      <Menu.Item
                        key='2'
                        onClick={() => {
                          Modal.confirm({
                            content: '削除しますか？',
                            okText: <span className='btn_label'>{'削除'}</span>,
                            okButtonProps: {
                              icon: <DeleteOutlined />,
                              type: 'primary',
                              danger: true,
                            },
                            cancelText: 'キャンセル',
                            onOk: () => {
                              let params = {
                                id: record.id,
                                set_code: record.set_code,
                                start_date_on: record.start_date_on,
                              }
                              SetInfoChangeSubAction.deleteTable(params)
                                .then((res) => {
                                  message.success('削除しました')
                                  this.closeModal()
                                  this.getDataTableSubSet(true)
                                })
                                .catch((err) => {
                                  const res = err.response
                                  if (!res || !res.data || !res.data.message) {
                                    message.error('エラーが発生しました')
                                    return
                                  }
                                  message.error(res.data.message)
                                })
                            }
                          })
                        }}
                      >
                        削除
                      </Menu.Item>

                      <Menu.Item
                        key='3'
                        hidden={this.formRef.current?.getFieldValue('SetIdentify') === 'Tan' || this.state.IndividualItem}
                        onClick={() =>
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 500,
                              component: (
                                <WS2708010_CopySet
                                  Li_SetIdentifyCopySource={this.formRef.current.getFieldValue('SetIdentify')}
                                  Li_CopySourceSetCode={record.set_code}
                                  Li_SetName={record.set_name}
                                  Li_start_date_on={record.start_date_on}

                                  onFinishScreen={async (dataClone) => {
                                    await this.getDataTableSubSet(true)
                                    await this.closeModal()
                                    await this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: true,
                                        width: '80%',
                                        component: (
                                          <WS0333001_SetIncludesQuery
                                            Li_SetCode={dataClone.CopySetCode}
                                            Li_CourseCode={dataClone.CopySetCode}
                                            onRow={dataClone.rowData}
                                            Li_StartDate={dataClone.start_date_on}
                                            onFinishScreen={(output) => {
                                              this.getDataTableSubSet(true)
                                            }}
                                            closeModal={() => {
                                              this.closeModal()
                                              this.getDataTableSubSet(true)
                                            }}
                                          />
                                        ),
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }
                      >
                        複写
                      </Menu.Item>

                      <Menu.Item
                        key='4'
                        hidden={this.state.IndividualItem}
                        onClick={() =>
                          this.changeDivision(record.id)
                        }
                      >
                        区分変更
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  >
                  </Button>
                </Dropdown>
              )}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            if (this.state.changeValueInfoChangeSub) {
              Modal.confirm({
                width: 250,
                title: '更新しますか？',
                icon: <QuestionCircleOutlined style={{ fontSize: '25px', color: '#08c' }} />,
                onOk: () => {
                  if (this.state.stsChangeSub === 'Create') {
                    this.createDataSetInfoChangeSub()
                  } else {
                    this.callApiGetScreenDataConFirmScreen()
                  }
                  this.setState({ changeValueInfoChangeSub: false })
                  this.closeModal()
                },
                onCancel: () => {
                  this.setState({ changeValueInfoChangeSub: false })
                  this.closeModal()
                }
              })
            } else {
              this.closeModal()
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2708001_SetInfoMaintain)
