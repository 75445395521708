import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";

import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Card, Form, Input, Select, Button, Table, Menu, Modal, Row, Col, Dropdown, InputNumber, Space, Tooltip, message } from "antd";
import WS0975007_DisplayItem from "./WS0975007_DisplayItem";

import { SearchOutlined, MenuOutlined } from '@ant-design/icons';
import WS0273001_VisitsTargetSearchQuery from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0273001_VisitsTargetSearchQuery";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import ModalDraggable from "components/Commons/ModalDraggable";
import moment from "moment";
import BillingInquiryAction from "redux/AccountingBusiness/BillingInquiry/BillingInquiry.action";
import Color from "constants/Color";
import { number_format } from "helpers/CommonHelpers";
import WS0963001_InvoiceMaintain from "../V4BL0031000_DepositWithdrawalInquiry/WS0963001_InvoiceMaintain";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
class WS0975001_BillingInquiry extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_ProcessDivision: PropTypes.any,
    Li_BillingPeriodBeginningInitialDisplay: PropTypes.any,
    Li_BillingPeriodFinalInitialDisplay: PropTypes.any,
    Li_IdentifyInitialDisplay: PropTypes.any,
    Li_TargetInitialDisplay: PropTypes.any,
    Li_PayRemainingInitialDisplay: PropTypes.any,
    Li_OutstandingInitialDisplay: PropTypes.any,
    Lo_BillingManageNum: PropTypes.any,
    Lo_Identify: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '請求問合せ';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      dataSource: [],
      selectedRow: {},
      isLoadingTable: true,
      ClaimIdentify: 0,
      ComboBox_ClaimIdentify: [],

      pagination: {
        size: 1,
        showQuickJumper: false
      },

      DisplayItemList: '111110001111111',
      DisplayItemArray: [1,1,1,1,1,0,0,0,1,1,1,1,1,1,1]
    };

    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {
    if (this.props !== prv) {
      this.getScreenData();
    }
  }

  getScreenData() {
    let params = {
      // $ProcessDivision: this.props.Li_ProcessDivision,
      // $BillingPeriodAStart: this.props.Li_BillingPeriodAStart,
      // $BillingPeriodAFinal: this.props.Li_BillingPeriodAFinal,
      // $Outstanding: this.props.Li_OutstandingLi_ClaimNum,
      // $ClaimNum: this.props.Li_ClaimNum,
      // $PayRemaining: this.props.Li_PayRemaining,
      // $Target: this.props.Li_Target,
      // $AddressSearch: this.props.Li_AddressSearch,
      // $ClaimIdentify: this.props.Li_ClaimIdentify,
      // $ReserveNum: this.props.Li_ReserveNum,

      Li_ProcessDivision: this.props.Li_ProcessDivision,
      Li_BillingPeriodBeginningInitialDisplay: this.props.Li_BillingPeriodBeginningInitialDisplay,
      Li_BillingPeriodFinalInitialDisplay: this.props.Li_BillingPeriodFinalInitialDisplay,
      Li_IdentifyInitialDisplay: this.props.Li_IdentifyInitialDisplay,
      Li_TargetInitialDisplay: this.props.Li_TargetInitialDisplay,
      Li_PayRemainingInitialDisplay: this.props.Li_PayRemainingInitialDisplay,
      Li_OutstandingInitialDisplay: this.props.Li_OutstandingInitialDisplay,
      Lo_BillingManageNum: this.props.Lo_BillingManageNum,
      Lo_Identify: this.props.Lo_Identify,
    };

    BillingInquiryAction.getScreenData(params)
      .then((res) => {
        this.setState({
          ComboBox_ClaimIdentify: res?.ComboBox_ClaimIdentify,
          DisplayItemList: res?.DisplayItemList,
          ClaimIdentify: res?.ClaimIdentify,
        })
        this.formRef.current?.setFieldsValue({
          ProcessDivision: res.ProcessDivision,
          BillingPeriodAStart: moment(res?.BillingPeriodAStart),
          BillingPeriodAFinal: moment(res?.BillingPeriodAFinal),
          Outstanding: res.Outstanding,
          PayRemaining: res.PayRemaining,
          Target: res.Target,
          ClaimIdentify: res.ClaimIdentify
        })
        this.onSearch();
      });
  }

  onSearch() {
    let values = this.formRef.current?.getFieldValue()
    let data = {
      ...values,
      BillingPeriodAStart: values.BillingPeriodAStart?.format("YYYY/MM/DD"),
      BillingPeriodAFinal: values.BillingPeriodAFinal?.format("YYYY/MM/DD"),
    };

    this.setState({ isLoadingTable: true });

    console.log(data)
    BillingInquiryAction.index(data)
      .then((res) => {
        this.setState({
          dataSource: res,
          isLoadingTable: false,
          selectedRow: res?.data && res?.data.length > 0 ? res?.data[0] : {}
        });
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  onFinish(values) {
    this.onSearch(values);
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  sortDate = (a, b) => {
    let dateA = new Date(a);
    let dateB = new Date(b);
    return isFinite(dateA - dateB)
      ? dateA - dateB
      : isFinite(dateA) ? 1 : -1
  };

  renderItem(ClaimIdentify) {
    switch (ClaimIdentify) {
      case 1:
      case 9:
        this.formRef.current?.setFieldsValue({
          insurer_kanji_name: ''
        });
        this.formRef.current?.setFieldsValue({
          office_kanji_name: ''
        });
        return (
          <Col span={18} style={{ paddingRight: '5px', paddingLeft: '5px' }}>
            <Row>
              <Col span={7} style={{ paddingRight: '5px' }}>
                <Form.Item name="Lio_PersonalNum" label="個　人" >
                  <Input.Search type='number' style={{ textAlign: "right" }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '90%',
                          className: '',
                          component:
                            <WS0248001_PersonalInfoSearchQuery
                              date={moment(new Date()).format('YYYY/MM/DD')}
                              searchName={this.formRef.current?.getFieldValue('Lio_PersonalNum')}
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  Lio_PersonalNum: output.Lo_PersonalNumId,
                                  kanji_name: output.recordData?.kanji_name,
                                });
                                this.closeModal()
                              }} />
                        },
                      });
                    }}
                    onChange={(e) => {
                      this.formRef.current?.setFieldsValue({
                        kanji_name: ''
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={17} style={{ paddingLeft: '5px' }}>
                <Form.Item name="kanji_name" >
                  <Input type="text" readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )
      case 4:
        this.formRef.current?.setFieldsValue({
          kanji_name: ''
        });
        this.formRef.current?.setFieldsValue({
          office_kanji_name: ''
        });
        return (
          <Col span={18} style={{ paddingRight: '5px', paddingLeft: '5px' }}>
            <Row>
              <Col span={7} style={{ paddingRight: '5px' }}>
                <Form.Item name="Lio_InsurerNum" label="保険者" >
                  <Input.Search type='number' style={{ textAlign: "right" }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '90%',
                          className: '',
                          component:
                            <WS0246001_InsurerInfoSearchQuery
                              Li_insurerSearch={this.formRef.current?.getFieldValue('Lio_InsurerNum')}
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  Lio_InsurerNum: output.Lo_InsurerCode,
                                  insurer_kanji_name: output.Lo_Name
                                })

                                this.closeModal()
                              }} />
                        },
                      });
                    }}
                    onChange={(e) => {
                      this.formRef.current?.setFieldsValue({
                        insurer_kanji_name: ''
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={17} style={{ paddingLeft: '5px' }}>
                <Form.Item name="insurer_kanji_name">
                  <Input type="text" readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )
      case 5:
        this.formRef.current?.setFieldsValue({
          kanji_name: ''
        });
        this.formRef.current?.setFieldsValue({
          insurer_kanji_name: ''
        });
        return (
          <Col span={18} style={{ paddingRight: '5px', paddingLeft: '5px' }}>
            <Row>
              <Col span={7} style={{ paddingRight: '5px' }}>
                <Form.Item name="Lio_OfficeCode" label="事業所"  >
                  <Input.Search type="text" style={{ textAlign: 'right' }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '90%',
                          className: '',
                          component:
                            <WS0247001_OfficeInfoRetrievalQuery
                            Lio_OfficeCode={this.formRef.current?.getFieldValue('Lio_OfficeCode')}
                              onFinishScreen={(output) => {
                                this.formRef.current?.setFieldsValue({
                                  Lio_OfficeCode: output.Lio_OfficeCode,
                                  Lio_BranchStoreCode: output.Lio_BranchStoreCode,
                                  Lio_BranchStoreCodeCopy: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode,
                                  office_kanji_name: output.Lo_Kanji_Name
                                })

                                this.closeModal()
                              }} />
                        },
                      });
                    }}
                    onChange={(e) => {
                      this.formRef.current?.setFieldsValue({
                        office_kanji_name: ''
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <Form.Item name="Lio_BranchStoreCode" style={{ display: 'none' }}></Form.Item>
                <Form.Item name="Lio_BranchStoreCodeCopy" >
                  <Input.Search type='number' style={{ textAlign: "right" }}
                  />
                </Form.Item>
              </Col>
              <Col span={13} style={{ paddingLeft: '5px' }}>
                <Form.Item name="office_kanji_name" >
                  <Input type="text" readOnly style={{ border: 'none', background: 'transparent' }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )
      default:
          this.formRef.current?.setFieldsValue({
            kanji_name: ''
          });
          this.formRef.current?.setFieldsValue({
            office_kanji_name: ''
          });
          this.formRef.current?.setFieldsValue({
            insurer_kanji_name: ''
          });
        break;
    }
  }

  selectRecord(record) {
    if (!(this.props.Li_ProcessDivision > 0)) {
      let condition = record.W1_identify == 1 || record.W1_billing_manage_num == 2 || record.W1_billing_manage_num == 3
      if (!condition) {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '90%',
            className: 'custom-button-close',
            component: (
              <WS0963001_InvoiceMaintain
                Li_BillingManageNum={record.W1_billing_manage_num}
                onFinishScreen={(output) => {
                  this.closeModal()
                }} />
            )
          }
        })
      }
    } else {
      if (this.props.onFinishScreen) {
        this.props.onFinishScreen({
          Lo_BillingManageNum: record.W1_billing_manage_num,
          Lo_Identify: record.W1_identify,
          recordData: record
        });
      }
    }
  }


  showDisplayItem = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        className: '',
        component:
          <WS0975007_DisplayItem
            Lio_DisplayItems={this.state.DisplayItemList}
            onFinishScreen={(output) => {
              let DisplayItemList = "";
              let DisplayItemArray = [];
              Object.keys(output.Lio_DisplayItems).forEach(function (key) {
                DisplayItemList += output.Lio_DisplayItems[key] ? "1" : "0";
                DisplayItemArray.push(output.Lio_DisplayItems[key]);
              })
              
              this.setState({
                DisplayItemList: DisplayItemList,
                DisplayItemArray: DisplayItemArray
              })

              this.closeModal()
            }} />
      },
    });
  }

  changeClaimIdentify = (value) => {
    this.setState({ ClaimIdentify: value })
    this.formRef.current?.setFieldsValue({
      Lio_PersonalNum: null,
      kanji_name: '',
      Lio_InsurerNum: null,
      insurer_kanji_name: '',
      Lio_OfficeCode: '',
      Lio_BranchStoreCode: null,
      Lio_BranchStoreCodeCopy: null,
      Lo_Kanji_Name: ''
    })
  }

  searchReserveNum = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        className: '',
        component:
          <WS0273001_VisitsTargetSearchQuery
            LnkOutReserveNum={this.formRef.current?.getFieldValue('ReserveNum')}
            Li_StateFlagSpecify={1}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                ReserveNum: output.LnkOutReserveNum
              })
              this.closeModal()
            }} />
      },
    });
  }

  render() {
    return (
      <div className="billing-inquiry">
        <Card className="mb-2" title="請求問合せ">
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className='option_btn_right_top_modal'
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => this.showDisplayItem()}
                      disabled={Object.keys(this.state.selectedRow).length > 0}
                    >
                      表示項目
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.Li_flag) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <div className="box_container">
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
            // onValuesChange={(changedValues, allValues) => {
            //   this.onFinish(allValues)
            // }}
            >
              <div className="box_inner_horizontal">
                <div className="box_search">
                  <div className="box_inner_vertical">
                    <div style={{ display: 'none' }}>
                      <Form.Item name="OfficeNum"><Input type="text" /></Form.Item>
                      <Form.Item name="BranchStoreCode"><Input type="text" /></Form.Item>
                    </div>
                    <div className="box_inner_horizontal">
                      <Form.Item label='請求年月日' name="BillingPeriodAStart" >
                        <VenusDatePickerCustom allowClear={true} formRefDatePicker={this.formRef} format='YYYY/MM/DD' style={{ width: 122 }} />
                      </Form.Item>
                      <Form.Item > ~ </Form.Item>
                      <Form.Item name="BillingPeriodAFinal" >
                        <VenusDatePickerCustom allowClear={true} formRefDatePicker={this.formRef} format='YYYY/MM/DD' style={{ width: 122 }} />
                      </Form.Item>
                      <Form.Item name="Outstanding" label="発行" >
                        <Select style={{ width: 112 }}>
                          <Select.Option value={0}>全て</Select.Option>
                          <Select.Option value={1}>未発行</Select.Option>
                          <Select.Option value={2}>発行済</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name="ClaimNum" label="請求番号">
                        <InputNumber style={{ width: 100 }} />
                      </Form.Item>
                      <Form.Item name="PayRemaining" label="入金残"  >
                        <Select style={{ width: 112 }}>
                          <Select.Option value={0}>全て</Select.Option>
                          <Select.Option value={1}>無し</Select.Option>
                          <Select.Option value={2}>有り</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name="Target" label="対象"  >
                        <Select style={{ width: 112 }}>
                          <Select.Option value={0}>有効</Select.Option>
                          <Select.Option value={1}>削除</Select.Option>
                          <Select.Option value={2}>統合</Select.Option>
                          <Select.Option value={3}>全て</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <Form.Item label='　　　宛名' name="AddressSearch" >
                      <Input type="text" />
                    </Form.Item>

                    <div className="box_inner_horizontal">
                      <Form.Item label='　請求対象' name="ClaimIdentify" >
                        <Select style={{ width: 112 }}
                          onChange={(value) => {
                            this.changeClaimIdentify(value)
                          }}>
                          {/* {this.state.ComboBox_ClaimIdentify?.map((item, index) => (
                            <Select.Option key={index} value={item.LinkedField}>{item.DisplayField}</Select.Option>
                          ))} */}
                          <Select.Option value={0}>全て</Select.Option>
                          {/* <Select.Option value={1}>個人</Select.Option> */}
                          <Select.Option value={4}>保険者</Select.Option>
                          <Select.Option value={5}>事業所</Select.Option>
                          <Select.Option value={6}>他団体</Select.Option>
                          <Select.Option value={9}>個人未収</Select.Option>
                        </Select>
                      </Form.Item>
                      {this.renderItem(this.state.ClaimIdentify)}
                    </div>

                    {/* <Form.Item label='予約番号' name="ReserveNum" >
                      <Input.Search type='number' style={{ width: 170, textAlign: "right" }}
                        onSearch={() => {
                          this.searchReserveNum()
                        }}
                      />
                    </Form.Item> */}
                  </div>
                </div>
                <div className="box_search_btn">
                  {/* <Form.Item style={{ textAlign: 'right' }}> */}
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      this.onSearch()
                    }}
                  // htmlType="submit"
                  // style={{ float: "right", color: '#14468c', borderColor: '#76a6ea' }}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                  {/* </Form.Item> */}
                </div>
              </div>
            </Form>

            <Table bordered
              size='small'
              style={{ cursor: 'pointer' }}
              rowClassName={(record, index) => record.id === this.state.selectedRow.id ? 'table-row-light' : ''}
              dataSource={this.state.dataSource}
              pagination={false}
              // pagination={{ ...this.state.pagination }}
              // scroll={{ y: resizableTableScroll(240), x: 'max-content' }}
              scroll={{ x: 'max-content' }}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    this.setState({
                      selectedRow: record
                    });
                  },
                  onDoubleClick: () => {
                    this.selectRecord(record);
                  }
                };
              }}
            >
              {this.state.DisplayItemArray[0] && (
                <Table.Column
                  title="請求先"
                  dataIndex="Expression_9"
                  // width={60}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_10)?.Foreground }}>{value}</div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[2] && (
                <Table.Column
                  title="統合"
                  dataIndex="Expression_14"
                  // width={60}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_10)?.Foreground }}>{value}</div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[3] && (
                <Table.Column
                  title="請求番号"
                  dataIndex="W1_invoice_num"
                  // width={80}
                  // sorter={(a, b) => a.W1_invoice_num - b.W1_invoice_num}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{
                          textAlign: 'right',
                          color: Color(record.Expression_10)?.Foreground
                        }}
                      >
                        {value === 0 ? '' : value}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[4] && (
                <Table.Column
                  title="請求日"
                  dataIndex="W1_billing_date"
                  // width={80}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_10)?.Foreground }}>
                        {value === '0000-00-00' ? '' : value?.replace(/-/g, '/')}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[5] && (
                <Table.Column
                  title="発行日"
                  dataIndex="W1_invoice_date"
                  // width={80}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div>
                        {value === '0000-00-00' ? '' : value?.replace(/-/g, '/')}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[6] && (
                <Table.Column
                  title="受診開始日"
                  dataIndex="W1_consult_start_date"
                  // width={100}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_10)?.Foreground }}>
                        {value === '0000-00-00' ? '' : value?.replace(/-/g, '/')}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[7] && (
                <Table.Column
                  title="受診終了日"
                  dataIndex="W1_consult_end_date"
                  // width={100}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_10)?.Foreground }}>
                        {value === '0000-00-00' ? '' : value?.replace(/-/g, '/')}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[8] && (
                <Table.Column
                  title="請求金額"
                  dataIndex="W1_billing_amount"
                  // width={80}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{
                        textAlign: 'right',
                        color: Color(record.Expression_10)?.Foreground
                      }}>
                        {value === 0 ? '' : number_format(value)}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[9] && (
                <Table.Column
                  title="入金額"
                  dataIndex="W1_deposit_amount"
                  // width={80}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{
                        textAlign: 'right',
                        color: Color(record.Expression_10)?.Foreground
                      }}>
                        {value === 0 ? '' : number_format(value)}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[10] && (
                <Table.Column
                  title="未収金額"
                  dataIndex="W1_accrued_price"
                  // width={80}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{
                        textAlign: 'right',
                        color: Color(record.Expression_10)?.Foreground
                      }}>
                        {value === 0 ? '' : number_format(value)}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[11] && (
                <Table.Column
                  title="入出金日"
                  dataIndex="payment_day_on"
                  // width={80}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_10)?.Foreground }}>
                        {value === '0000-00-00' ? '' : value?.replace(/-/g, '/')}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[12] && (
                <Table.Column
                  title="宛名"
                  dataIndex="W1_destination_name"
                  showSorterTooltip={false}
                  className="column-size-40"
                  render={(value, record, index) => {
                    return (
                      <div style={{ color: Color(record.Expression_10)?.Foreground }}>
                        {value}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[13] && (
                <Table.Column
                  title="コード"
                  dataIndex="Expression_25"
                  // width={60}
                  className="column-size-10"
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        {value == 0 ? '' : value}
                      </div>
                    )
                  }} />
              )}
              {this.state.DisplayItemArray[14] && (
                <Table.Column
                  title="個人・団体名称"
                  dataIndex="Expression_26"
                  // width={130}
                  className="column-size-40"
                  render={(value, record, index) => {
                    return (
                      <Tooltip title={record.Expression_17}>
                        <div style={{ color: Color(record.Expression_10)?.Foreground }}>
                          {value}
                        </div>
                      </Tooltip>
                    )
                  }} />
              )}
              <Table.Column
                title="削除"
                dataIndex="Expression_11"
                //  width={60} 
                className="column-size-10"
              />
            </Table>
            {/* <div className="box_button_bottom_right" style={{ paddingTop: 0 }}>
              <Button type="primary" disabled={Object.keys(this.state.selectedRow).length === 0}
                onClick={() => {
                  this.selectRecord(this.state.selectedRow)
                }}>
                選択
              </Button>
            </div> */}
          </div>
        </Card >
        <ModalDraggable
          className={this.state.childModal.className}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0975001_BillingInquiry);
