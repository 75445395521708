import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Input, Form, Card, Button, Select, Modal, message, Checkbox, Space, Tag, Tooltip, Dropdown, Menu } from 'antd'
import { WarningOutlined, DeleteOutlined, FileTextOutlined, SaveOutlined, MenuOutlined, InfoCircleOutlined, CloseCircleOutlined, MoreOutlined, } from '@ant-design/icons'

import WS0084001_PostCodeSearchEngine from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx'
import WS0247001_OfficeInfoRetrievalQuery from './WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0341008_BranchShopInquiry from './WS0341008_BranchShopInquiry.jsx'
import WS0341009_BranchStoreGenerated from './WS0341009_BranchStoreGenerated.jsx'
import WS0308017_OrganizationCreateNew from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0308017_OrganizationCreateNew.jsx'
import WS0246001_InsurerInfoSearchQuery from '../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0307008_ContractHistorySub from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307008_ContractHistorySub.jsx'
import moment from 'moment-timezone'
import OfficeInfoMaintainService from 'services/basicInfo/OfficeInfoMaintain/OfficeInfoMaintainService.js'
import WS0341500_OfficeInfoSupplement from './WS0341500_OfficeInfoSupplement'
import WS0341501_SpecialInfo from './WS0341501_SpecialInfo'
import WS0341502_DestinationInfo from './WS0341502_DestinationInfo'
import WS0341503_ResultTable from './WS0341503_ResultTable'
import WS0341504_AffiliationInfo from './WS0341504_AffiliationInfo'
import WS0341505_PersonalInfo from './WS0341505_PersonalInfo'
import PropTypes from 'prop-types'
import WS0344001_SupplementalInfoSetting from '../V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx'
import WSFileManager_FileManager from 'pages/ZZ_Others/CUSTOMIZE_Custom/WSFileManager_FileManager.jsx'
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'
import CorporateHearingSheet from 'pages/ZZ_Others/SANAI_CorporateHearingSheet/CorporateHearingSheet.jsx'
import * as wanakana from 'wanakana'
import hankakuMoji from 'components/Commons/HankakuMoji'

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

const styleBorder = {
  width: '100%',
  border: '1px solid #CCCCCC',
  padding: '20px 10px 10px 10px',
  display: 'flex',
  flexDirection: 'column'
}

class WS0341001_OfficeInfoMaintain extends React.Component {
  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  }
  formRef = React.createRef()
  buttonSubmmitRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = '事業所情報保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      //New code
      screenData: {},
      statusPage: 2, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: '',
      BranchStoreCode: '',
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0,
      SupplementaryInfo: [],
      DestinationInfo: [],
      importance: 0,
      content: '',
      autoNumbering: false
    }
  }

  componentDidMount = () => {
    this.getScreenData()
  }

  componentDidUpdate = prevProps => {
    const { Lio_OfficeCode, Lio_BranchStoreCode } = this.props
    if (prevProps != this.props) {
      if (Lio_OfficeCode) {
        this.getInitialDisplayCorrespondDataDisplay(Lio_OfficeCode, Lio_BranchStoreCode)
      }
    }
  }

  getInitialDisplayCorrespondDataDisplay = (OfficeCode, BranchStoreCode) => {
    OfficeInfoMaintainService.getInitialDisplayCorrespondDataDisplayService({
      OfficeCode,
      BranchStoreCode
    })
      .then(res => {
        this.setState({
          SupplementaryInfo: res.data.SupplementaryInfo,
          DestinationInfo: res.data.DestinationInfo,

          RecordedInfo: res.data.RecordedInfo,
          selectedRowsRecordedInfo: [res.data.RecordedInfo[0]],
          indexTableRecordedInfo: 0,

          AffiliationInfo: res.data.AffiliationInfo[0],
          selectedRowsAffiliationInfo: [res.data.AffiliationInfo[0]],
          indexTableAffiliationInfo: 0,

          ResultsTable: res.data.ResultsTable,
          selectedRowsResultsTable: [res.data.ResultsTable[0]],
          indexTableResultsTable: 0
        })
        this.formRef.current?.setFieldsValue({
          SupplementaryInfo: res.data.SupplementaryInfo,
          DestinationInfo: res.data.DestinationInfo,
          RecordedInfo: res.data.RecordedInfo,
          AffiliationInfo: res.data.AffiliationInfo[0],
          ResultsTable: res.data.ResultsTable,
        })
        const { SupplementaryInfo, DestinationInfo } = this.state.screenData
        if (res.data.ProcessDivision === 3) {
          this.resetStateToRegister_CaseStatusPage(
            3,
            res.data,
            res.data.OfficeCode,
            res.data.BranchStoreCode
          )
        }
        if (res.data.ProcessDivision === 2) {
          this.resetStateToRegister_CaseStatusPage(
            2,
            { ...res.data, SupplementaryInfo, DestinationInfo },
            res.data.OfficeCode,
            res.data.BranchStoreCode
          )
        }
        if (res.data.ProcessDivision === 1) {
          this.resetStateToRegister_CaseStatusPage(
            1,
            { ...res.data, SupplementaryInfo, DestinationInfo },
            res.data.OfficeCode,
            res.data.BranchStoreCode
          )
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
      })
  }

  getScreenData = () => {
    OfficeInfoMaintainService.getScreenDataService()
      .then(res => {
        this.formRef.current.setFieldsValue({
          SupplementaryInfo: res.data.SupplementaryInfo,
          DestinationInfo: res.data.DestinationInfo,
        })
        this.setState({
          screenData: res.data,
          SupplementaryInfo: res.data.SupplementaryInfo,
          DestinationInfo: res.data.DestinationInfo,
        })

        if (res.data.auto) {
          this.setState({
            autoNumbering: true,
            statusPage: 1
          })
        }

        if (this.props.Lio_OfficeCode) {
          this.getInitialDisplayCorrespondDataDisplay(this.props.Lio_OfficeCode, this.props.Lio_BranchStoreCode)
        }

      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onChangeOfficeAndBranchCode = event => {
    const { name, value } = event.target
    const onChangeOfficeAndBranchCodefunc = () => {
      this.setState(
        {
          [name]: value,
          isFileEdited: false
        },
        () => {
          const { SupplementaryInfo, DestinationInfo } = this.state.screenData
          if (this.state.OfficeCode === '' || this.state.OfficeCode === '0') {
            this.resetStateToRegister_CaseStatusPage(1, {
              SupplementaryInfo,
              DestinationInfo
            })
            return
          }
          this.getInitialDisplayCorrespondDataDisplay(
            this.state.OfficeCode,
            this.state.BranchStoreCode
          )
        }
      )
    }
    onChangeOfficeAndBranchCodefunc()
  }

  resetStateToRegister_CaseStatusPage = (
    statusPage,
    officeInfoDisplay,
    OfficeCode,
    BranchStoreCode
  ) => {
    switch (statusPage) {
      case 1:
        this.formRef.current.resetFields()
        this.formRef.current.setFieldsValue({
          ...officeInfoDisplay
        })
        this.setState({
          isFileEdited: false,
          statusPage: statusPage,
          officeInfoDisplay: officeInfoDisplay,
          OfficeCode: '',
          BranchStoreCode: ''
        })
        break
      case 2:
        this.formRef.current.resetFields()
        this.formRef.current.setFieldsValue({
          ...officeInfoDisplay
        })
        this.setState({
          isFileEdited: false,
          statusPage: statusPage,
          officeInfoDisplay: officeInfoDisplay,
          OfficeCode: OfficeCode,
          BranchStoreCode: BranchStoreCode
        })
        break
      case 3:
        this.formRef.current.resetFields()
        this.formRef.current.setFieldsValue({
          ...officeInfoDisplay
        })
        this.setState({
          isFileEdited: false,
          statusPage: statusPage,
          officeInfoDisplay: officeInfoDisplay,
          OfficeCode: OfficeCode,
          BranchStoreCode: BranchStoreCode
        })
        break
      default:
        break
    }
  }

  onValuesChange = changedValues => {
    if (changedValues.OfficeCode) {
      this.setState({
        isFileEdited: false
      })
    } else {
      this.setState({
        isFileEdited: true
      })
    }
  }

  /**
   * 登録処理 その１
   * @param {*} values
   */
  saveOfficeInfo = values => {
    switch (this.state.statusPage) {
      //自動発番
      case 1:
        Modal.confirm({
          content: '新規登録します。よろしいですか？',
          onOk: () => {
            this.registerOrUpdateOfficeInfoData()
          }
        })
        break
      //手動発番
      case 2:
        Modal.confirm({
          content: '新規登録します。よろしいですか？',
          onOk: () => {
            this.registerOrUpdateOfficeInfoData()
          }
        })
        break
      //編集
      case 3:
        if (this.state.isFileEdited) {
          Modal.confirm({
            content: '上書き登録します。よろしいですか？',
            onOk: () => {
              this.registerOrUpdateOfficeInfoData()
            }
          })
        }
        break
      default:
        break
    }
  }

  /**
   * 登録処理 その２
   */
  registerOrUpdateOfficeInfoData = () => {
    const values = this.formRef.current?.getFieldsValue(true)
    switch (this.state.statusPage) {
      //自動発番
      case 1:
        this.registerData({
          ...values,
          BranchStoreCode: 0,
          ProcessDivision: this.state.statusPage,
          RecordedInfo: this.state.RecordedInfo,
          DestinationInfo: this.state.DestinationInfo,
          ResultsTable: this.state.ResultsTable,
          AffiliationInfo: this.state.AffiliationInfo,
          SupplementaryInfo: this.state.SupplementaryInfo,
        })
        break
      //手動発番
      case 2:
        this.registerData({
          ...values,
          ProcessDivision: this.state.statusPage,
          RecordedInfo: this.state.RecordedInfo,
          DestinationInfo: this.state.DestinationInfo,
          ResultsTable: this.state.ResultsTable,
          AffiliationInfo: this.state.AffiliationInfo,
          SupplementaryInfo: this.state.SupplementaryInfo,
        })
        break
      //編集
      case 3:
        this.registerData({
          ...values,
          OfficeCode: this.state.OfficeCode,
          BranchStoreCode: this.state.BranchStoreCode,
          ProcessDivision: this.state.statusPage,
          RecordedInfo: this.state.RecordedInfo,
          DestinationInfo: this.state.DestinationInfo,
          ResultsTable: this.state.ResultsTable,
          AffiliationInfo: this.state.AffiliationInfo,
          SupplementaryInfo: this.state.SupplementaryInfo,
        })
        break
      default:
        break
    }
  }

  /**
   * 登録処理 その３
   * @param {*} OfficeInfoData
   */
  registerData = (OfficeInfoData) => {
    OfficeInfoMaintainService.registerDataService(OfficeInfoData)
      .then(res => {
        message.success('保存が完了しました')
        this.getInitialDisplayCorrespondDataDisplay(res.data.OfficeCode, res.data.BranchStoreCode)
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 照会画面の分岐
   * @param {*} statusSearch
   * @param {*} value
   */
  onSearchOfficeCodeOrBranchCode = (statusSearch, value) => {
    const handleOfficeCodeOrBranchCode = () => {
      switch (statusSearch) {
        case 'OfficeCode': //事業所照会
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 1400,
              component: (
                <WS0247001_OfficeInfoRetrievalQuery
                  Lio_OfficeCode={value}
                  onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, recordData, Li_ContractType }) => {
                    this.setState(
                      {
                        OfficeCode: Lio_OfficeCode,
                        BranchStoreCode: Lio_BranchStoreCode,
                        importance: recordData.importance,
                        content: recordData.content,
                        ContractType: Li_ContractType
                      },
                      () => {
                        this.getInitialDisplayCorrespondDataDisplay(
                          Lio_OfficeCode,
                          Lio_BranchStoreCode
                        )
                      }
                    )
                    this.closeModal()
                  }}
                />
              )
            }
          })
          break
        case 'BranchStoreCode': //支社店照会
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 700,
              component: (
                <WS0341008_BranchShopInquiry
                  Li_OfficeCode={this.state.OfficeCode}
                  onFinishScreen={({ Li_OfficeCode, Lo_BranchStoreCode }) => {
                    this.setState(
                      {
                        OfficeCode: Li_OfficeCode,
                        BranchStoreCode: Lo_BranchStoreCode
                      },
                      () => {
                        this.getInitialDisplayCorrespondDataDisplay(
                          Li_OfficeCode,
                          Lo_BranchStoreCode
                        )
                      }
                    )
                    this.closeModal()
                  }}
                />
              )
            }
          })
          break
        case 'CreateBranchCode': //支社店作成
          this.setState({
            childModal: {
              ...this.state.childModal,
              visible: true,
              width: 700,
              component: (
                <WS0341009_BranchStoreGenerated
                  Li_OfficeCode={this.state.OfficeCode}
                  onFinishScreen={({
                    Li_OfficeCode,
                    Lo_BranchStoreCode,
                    OfficeCodeParent,
                    BranchStoreCodeParent,
                    office_kana_name,
                    office_kanji_name
                  }) => {
                    this.setState(
                      {
                        OfficeCode: Li_OfficeCode,
                        BranchStoreCode: Lo_BranchStoreCode
                      },
                      () => {
                        this.cloneOfficeDataDisplayToNewOffice(
                          Li_OfficeCode,
                          Lo_BranchStoreCode,
                          OfficeCodeParent,
                          BranchStoreCodeParent,
                          office_kana_name,
                          office_kanji_name
                        )
                      }
                    )
                    this.closeModal()
                  }}
                />
              )
            }
          })
      }
    }
    handleOfficeCodeOrBranchCode()
  }

  /**
   * 支店作成
   * @param {*} OfficeCodeNew
   * @param {*} BranchStoreCodeNew
   * @param {*} OfficeCodeParent
   * @param {*} BranchStoreCodeParent
   * @param {*} OfficeNameKana
   * @param {*} OfficeNameKanji
   */
  cloneOfficeDataDisplayToNewOffice = (
    OfficeCodeNew,
    BranchStoreCodeNew,
    OfficeCodeParent,
    BranchStoreCodeParent,
    OfficeNameKana,
    OfficeNameKanji
  ) => {

    const params = {
      OfficeCode: OfficeCodeParent,
      BranchStoreCode: BranchStoreCodeParent
    }
    OfficeInfoMaintainService.getInitialDisplayCorrespondDataDisplayService(params)
      .then(res => {
        if (res.data.ProcessDivision === 3) {
          this.formRef.current.setFieldsValue({
            ...res.data,
            ProcessDivision: 2,
            OfficeCode: OfficeCodeNew,
            BranchStoreCode: BranchStoreCodeNew,
            OfficeNameKana: OfficeNameKana,
            OfficeNameKanji: OfficeNameKanji
          })
          this.setState({
            isFileEdited: true,
            statusPage: 2,
            officeInfoDisplay: {
              ...res.data,
              ProcessDivision: 2,
              OfficeCode: OfficeCodeNew,
              BranchStoreCode: BranchStoreCodeNew,
              OfficeNameKana: OfficeNameKana,
              OfficeNameKanji: OfficeNameKanji
            },
            OfficeCode: OfficeCodeNew,
            BranchStoreCode: BranchStoreCodeNew
          })
        } else {
          this.getInitialDisplayCorrespondDataDisplay(OfficeCodeNew, BranchStoreCodeNew)
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  deleteOfficeF11_1 = () => {
    OfficeInfoMaintainService.deleteF11API_1_Service({
      OfficeCode: this.state.OfficeCode,
      BranchStoreCode: this.state.BranchStoreCode
    })
      .then(res => {
        Modal.confirm({
          title: '確認',
          icon: <WarningOutlined />,
          content: (
            <div>
              事業所の情報がすべて削除されます。
              <br />
              削除しますか？
            </div>
          ),
          onOk: () => {
            this.deleteOfficeF11_2()
          }
        })
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 削除
   */
  deleteOfficeF11_2 = () => {
    OfficeInfoMaintainService.deleteF11API_2_Service({
      OfficeCode: this.state.OfficeCode,
      BranchStoreCode: this.state.BranchStoreCode
    })
      .then(res => {
        message.success('削除が完了しました')
        const { SupplementaryInfo, DestinationInfo } = this.state.screenData
        this.resetStateToRegister_CaseStatusPage(1, {
          SupplementaryInfo,
          DestinationInfo
        })
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 郵便番号 存在チェック
   * @param {*} code
   */
  getNamePostalCode = (code) => {
    // 空の場合は取得しない
    if (code == '') return

    const params = {
      postalCode: code
    }
    OfficeInfoMaintainService.getNamePostalCode(params)
      .then(res => {
        if (res) {
          if (res.data.address_1 !== '') {
            let newZipCode3 = res.data.zipCode.slice(0, 3)
            let newZipCode4 = res.data.zipCode.slice(3, 7)
            this.formRef.current?.setFieldsValue({
              ZipCode: newZipCode3 + '-' + newZipCode4,
              Location1: res.data.address_1
            })
            this.setState({
              isFileEdited: true
            })
          } else {
            message.error('入力された郵便番号に該当する住所はありませんでした')
          }
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      })
  }

  detailsMenu = () => {
    return (
      <Menu>
        <Menu.Item
          key='1'
          style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 800,
                component: (
                  <WS0341501_SpecialInfo
                    Li_RecordedInfo={this.state.RecordedInfo}
                    Li_OfficeCode={this.state.OfficeCode}
                    Li_BranchStoreCode={this.state.BranchStoreCode}
                    Li_Date={moment(this.state.officeInfoDisplay.Date).format('YYYY/MM/DD') ?? ''}
                    onUpdate={(output) => {
                      this.setState({
                        RecordedInfo: output,
                        isFileEdited: true
                      })
                    }}
                  />
                )
              }
            })
          }}
        >
          特記
        </Menu.Item>
        <Menu.Item
          key='2'
          style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 800,
                component: (
                  <WS0341502_DestinationInfo
                    Li_DestinationInfo={this.formRef.current?.getFieldValue('DestinationInfo')}
                    Li_OfficeCode={this.state.OfficeCode}
                    Li_BranchStoreCode={this.state.BranchStoreCode}
                    onFinishScreen={(output) => {
                      this.setState({
                        DestinationInfo: output,
                        isFileEdited: true
                      })
                      this.closeModal()
                    }}
                  />
                )
              }
            })
          }}
        >
          送付先
        </Menu.Item>
        <Menu.Item
          key='3'
          style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 1000,
                component: (
                  <WS0341503_ResultTable
                    Li_ResultsTable={this.state.ResultsTable}
                    Li_OfficeCode={this.state.OfficeCode}
                    Li_BranchStoreCode={this.state.BranchStoreCode}
                    onUpdate={(output) => {
                      this.setState({
                        ResultsTable: output,
                        isFileEdited: true
                      })
                    }}
                  />
                )
              }
            })
          }}
        >
          結果表
        </Menu.Item>
        <Menu.Item
          key='4'
          style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 800,
                component: (
                  <WS0341504_AffiliationInfo
                    Li_AffiliationInfo={this.state.AffiliationInfo}
                    Li_OfficeCode={this.state.OfficeCode}
                    Li_BranchStoreCode={this.state.BranchStoreCode}
                    onUpdate={(output) => {
                      this.setState({
                        AffiliationInfo: output,
                        isFileEdited: true
                      })
                    }}
                  />
                )
              }
            })
          }}
        >
          所属
        </Menu.Item>
        <Menu.Item
          key='5'
          style={{ width: '100%' }}
          disabled={this.state.statusPage === 1 || this.state.statusPage === 2 ? true : false}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 800,
                component: (
                  <WS0341505_PersonalInfo
                    Li_PersonalInfo={this.formRef.current.getFieldValue('PersonalInfo')}
                    onFinishScreen={() => {
                      this.closeModal()
                    }}
                  />
                )
              }
            })
          }}
        >
          個人
        </Menu.Item>
        <Menu.Item
          key='6'
          style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 800,
                component: (
                  <WS0341500_OfficeInfoSupplement
                    Li_SupplementaryInfo={this.state.SupplementaryInfo}
                    Li_OfficeCode={this.state.OfficeCode}
                    Li_BranchStoreCode={this.state.BranchStoreCode}
                    onUpdate={(output) => {
                      this.setState({
                        SupplementaryInfo: output,
                        isFileEdited: true,
                      })
                    }}
                  />
                )
              }
            })
          }}
        >
          補足情報
        </Menu.Item>
      </Menu>
    )
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  render() {
    const office_addresses_render = (name, text, record, index) => {
      const namePath = ['DestinationInfo', index, name]
      return (
        <Form.Item name={namePath} valuePropName='checked'>
          <Checkbox
            onChange={e => {
              const formIns = this.formRef.current
              formIns.setFields([
                {
                  name: ['DestinationInfo', index, 'destination_office_1'],
                  value: 0
                },
                {
                  name: ['DestinationInfo', index, 'destination_office_2'],
                  value: 0
                },
                {
                  name: ['DestinationInfo', index, 'destination_personal_1'],
                  value: 0
                },
                {
                  name: ['DestinationInfo', index, 'destination_personal_2'],
                  value: 0
                },
                {
                  name: ['DestinationInfo', index, 'destination_personal_3'],
                  value: 0
                },
                {
                  name: namePath,
                  value: e.target.checked ? 1 : 0
                }
              ])
            }}
          />
        </Form.Item>
      )
    }

    return (
      <div className='office-info-maintain'>
        <Card
          title={'事業所情報保守 [' + (this.state.statusPage == 3 ? '編集' : '新規') + ']'}
          style={{ position: 'relative', minWidth: '890px' }}
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.Lio_OfficeCode) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 700,
                            component: (
                              <WSFileManager_FileManager
                                Params_2612={{
                                  Li_Division: '事業所',
                                  Li_Identify: MagicXpaFunc.Str(MagicXpaFunc.Val(this.formRef.current?.getFieldValue('OfficeCode'), '8'), '8P0')
                                }}
                              />
                            )
                          }
                        })
                      }}
                      disabled={!this.formRef.current?.getFieldValue('OfficeCode')}
                    >
                      フォルダ
                    </Menu.Item>

                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS0344001_SupplementalInfoSetting
                                Li_IdentifyChar='MAST2'
                                onFinishScreen={() => {
                                  this.getInitialDisplayCorrespondDataDisplay(
                                    this.formRef.current?.getFieldValue('OfficeCode'),
                                    this.formRef.current?.getFieldValue('BranchStoreCode')
                                  )
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false
                                    }
                                  })
                                }}
                              />
                            )
                          }
                        })
                      }}
                    >
                      補足情報設定
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.Lio_OfficeCode) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            autoComplete='off'
          >
            <div className='box_border' style={{ marginBottom: 10 }}>
              <div className='box_inner_horizontal'>
                <Form.Item name='OfficeCode' style={{ marginBottom: 0 }}>
                  <Input.Search
                    allowClear={true}
                    className='input-search-size-number-allow-clear-8'
                    onPressEnter={(e) => this.getInitialDisplayCorrespondDataDisplay(e.target.value)}
                    onBlur={(e) => this.getInitialDisplayCorrespondDataDisplay(e.target.value)}
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.setState({
                          RecordedInfo: [],
                          DestinationInfo: [],
                          ResultsTable: [],
                          AffiliationInfo: [],
                          SupplementaryInfo: [],
                          isFileEdited: [],
                          OfficeCode: '',
                          BranchStoreCode: '',
                        })
                        this.formRef.current?.resetFields()

                        this.getScreenData()
                      } else {
                        this.onSearchOfficeCodeOrBranchCode('OfficeCode', value)
                      }
                    }}
                    maxLength={8}
                    min={1}
                  />
                </Form.Item>

                <Form.Item name='BranchStoreCode' style={{ marginBottom: 0 }}>
                  <Input.Search
                    allowClear={true}
                    className='input-search-size-number-allow-clear-4'
                    value={this.state.BranchStoreCode}
                    onPressEnter={event => {
                      this.onSearchOfficeCodeOrBranchCode('BranchStoreCode')
                    }}
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.getInitialDisplayCorrespondDataDisplay(this.formRef.current?.getFieldValue('OfficeCode'), 0)
                      } else {
                        this.onSearchOfficeCodeOrBranchCode('BranchStoreCode')
                      }
                    }}
                    type='number'
                    min={0}
                  />
                </Form.Item>

                <div style={{ display: this.state.importance === 0 ? 'none' : 'flex', marginTop: 6 }}>
                  {this.state.importance === 1 ?
                    <Tooltip title={this.state.content}>
                      <InfoCircleOutlined
                        style={{ color: '#1890ff', cursor: 'pointer', fontSize: '20px' }}
                      />
                    </Tooltip>
                    :
                    this.state.importance === 3 ?
                      <Tooltip title={this.state.content}>
                        <WarningOutlined
                          placement='topRight'
                          style={{ color: '#faad14', cursor: 'pointer', fontSize: '20px' }}
                        />
                      </Tooltip>
                      :
                      this.state.importance === 5 ?
                        <Tooltip title={this.state.content}>
                          <CloseCircleOutlined
                            style={{ color: '#ff4d4f', cursor: 'pointer', fontSize: '20px' }}
                          />
                        </Tooltip>
                        :
                        <div />
                  }
                </div>

                <Button
                  type='primary'
                  icon={<FileTextOutlined />}
                  size='middle'
                  onClick={() => {
                    this.onSearchOfficeCodeOrBranchCode('CreateBranchCode')
                  }}
                >
                  <span className='btn_label'>
                    支店作成
                  </span>
                </Button>

                <div style={{ marginTop: 5, marginLeft: 10 }}>
                  <Tag
                    color='green'
                    visible={this.state.statusPage == 1}
                  >
                    自動発番
                  </Tag>
                </div>

                <div className='box_button_bottom_right' style={{ paddingTop: 5 }}>
                  <div>
                    登録日{': '}
                    {this.state.officeInfoDisplay.RegisterDate &&
                      moment(this.state.officeInfoDisplay.RegisterDate).isValid() ? (
                      moment(this.state.officeInfoDisplay.RegisterDate).format('YYYY/MM/DD(ddd)')
                    ) : (
                      <span style={{ opacity: '0' }}>0000/00/00</span>
                    )}
                  </div>
                  <div>
                    変更日{': '}
                    {this.state.officeInfoDisplay.Date &&
                      moment(this.state.officeInfoDisplay.Date).isValid() ? (
                      moment(this.state.officeInfoDisplay.Date).format('YYYY/MM/DD(ddd)')
                    ) : (
                      <span style={{ opacity: '0' }}>0000/00/00</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='box_inner_horizontal'>
              <div style={{ ...styleBorder, position: 'relative' }}>
                <Form.Item
                  label='カナ名称'
                  name='OfficeNameKana'
                  {...smGrid}
                >
                  <Input.TextArea
                    className='input-size-60'
                    autoSize={{ minRows: 1 }}
                    onBlur={e => {
                      let zenkaku_kana = wanakana.toKatakana(e.target.value)
                      let hannkaku_kana = hankakuMoji(zenkaku_kana)

                      this.formRef.current?.setFieldsValue({
                        OfficeNameKana: hannkaku_kana
                      })
                      this.forceUpdate()
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label='漢字名称'
                  name='OfficeNameKanji'
                  {...smGrid}
                >
                  <Input.TextArea
                    className='input-size-60'
                    autoSize={{ minRows: 1 }}
                  />
                </Form.Item>

                <Form.Item
                  label='代表者'
                  name='Representative'
                  {...smGrid}
                >
                  <Input className='input-size-30' />
                </Form.Item>
                <Form.Item
                  label='先方担当'
                  name='OtherPartyInCharge'
                  {...smGrid}
                >
                  <Input className='input-size-30' />
                </Form.Item>

                <Form.Item
                  label='担当営業'
                  name='SalesRepresentative'
                  {...smGrid}
                >
                  <Input className='input-size-30' />
                </Form.Item>

                <Form.Item
                  name='Userlist'
                  label='担当者'
                  {...smGrid}
                >
                  <Select allowClear style={{ width: '200px' }}>
                    {this.state.screenData.Userlist
                      ? this.state.screenData.Userlist.map((value) => {
                        return (
                          <Select.Option value={value.username} key={value.id}>
                            {value.name}
                          </Select.Option>
                        )
                      })
                      : null}
                  </Select>
                </Form.Item>

                <Form.Item
                  label='状況区分'
                  name='SituationClassify'
                  {...smGrid}
                >
                  <Select
                    allowClear
                    style={{ width: '112px' }}
                  >
                    {this.state.screenData.SituationClassify
                      ? this.state.screenData.SituationClassify.map((item, index) => {
                        return (
                          <Select.Option value={item.LinkedField} key={index}>
                            {item.DisplayedField}
                          </Select.Option>
                        )
                      })
                      : null}
                  </Select>
                </Form.Item>

                <Space>
                  <Form.Item
                    label='保険者'
                    name='InsurerCode'
                    {...smGrid}
                  >
                    <Input.Search
                      className='input-size-12'
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS0246001_InsurerInfoSearchQuery
                                Li_insurerSearch={this.formRef.current.getFieldValue('InsurerCode')}
                                onFinishScreen={({ Lo_InsurerCode, Lo_Name }) => {
                                  if (
                                    this.formRef.current.getFieldValue('InsurerCode') !==
                                    Lo_InsurerCode
                                  ) {
                                    this.formRef.current.setFieldsValue({
                                      InsurerCode: Lo_InsurerCode,
                                      Expression_188: Lo_Name
                                    })
                                    this.setState({
                                      isFileEdited: true
                                    })
                                  }
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        })
                      }}
                    />
                  </Form.Item>
                  <div style={{ marginTop: '5px' }}>
                    {this.formRef.current
                      ? this.formRef.current.getFieldValue('Expression_188')
                      : ''}
                  </div>
                </Space>
                <Form.Item
                  label='保険証'
                  name='InsuranceCardSymbol'
                  {...smGrid}
                >
                  <Input className='input-size-10' />
                </Form.Item>

                <Tooltip title='特記、送付先、結果表、所属、個人'>
                  <Dropdown
                    placement='bottomRight'
                    trigger='click'
                    style={{
                      top: '10px',
                      right: '10px',
                      position: 'absolute'
                    }}
                    overlay={() => this.detailsMenu()}
                  >
                    <Button
                      size='small'
                      style={{
                        top: '10px',
                        right: '10px',
                        position: 'absolute'
                      }}
                      icon={<MenuOutlined />}
                    />
                  </Dropdown>
                </Tooltip>
              </div>

              <div style={{ ...styleBorder, position: 'relative' }}>
                <div className='box_inner_horizontal'>
                  <Form.Item
                    label='〒'
                    name='ZipCode'
                    {...smGrid}
                  >
                    <Input.Search
                      className='input-size-12'
                      onPressEnter={() => { this.getNamePostalCode(this.formRef.current?.getFieldValue('ZipCode') ?? '') }}
                      onSearch={(value, event) => {
                        // gZoom GL0_郵便番号
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 600,
                            component: (
                              <WS0084001_PostCodeSearchEngine
                                onFinishScreen={({ Lio_ZipCode, Lio_Address }) => {
                                  const zipcode = Lio_ZipCode ? Lio_ZipCode.trim() : ''
                                  if (
                                    zipcode !== '' &&
                                    this.formRef.current.getFieldValue('ZipCode') != zipcode
                                  ) {
                                    const formInstance = this.formRef.current
                                    formInstance.setFieldsValue({
                                      ZipCode: zipcode
                                    })
                                    const address = Lio_Address ? Lio_Address.trim() : ''
                                    if (address !== '') {
                                      formInstance.setFieldsValue({
                                        Location1: address
                                      })
                                    }
                                    this.setState({ isFileEdited: true })
                                  }
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        })
                      }}
                    />
                  </Form.Item>
                  <Tooltip title={'入力された郵便番号から住所を取得'}>
                    <Button
                      size='small'
                      disabled={!(this.formRef.current?.getFieldValue('ZipCode') ?? '' === '')} //郵便番号に何かしら入力されたらボタン活性
                      onClick={() => {
                        const code = this.formRef.current?.getFieldValue('ZipCode') ?? ''
                        // 住所検索
                        this.getNamePostalCode(code)
                      }}
                      style={{ marginTop: 5, marginLeft: 8 }}
                    >
                      住所取得
                    </Button>
                  </Tooltip>
                </div>
                <Form.Item
                  name='AreaCode'
                  label='地区'
                  {...smGrid}
                >
                  <Select
                    allowClear
                    style={{ width: '150px' }}
                  >
                    {this.state.screenData.AreaCode
                      ? this.state.screenData.AreaCode.map((item, index) => {
                        return (
                          <Select.Option value={item.LinkedField} key={index}>
                            {item.DisplayedField}
                          </Select.Option>
                        )
                      })
                      :
                      null
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label='住所'
                  name='Location1'
                  {...smGrid}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label=' '
                  name='Location2'
                  {...smGrid}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label='電話'
                  name='PhoneNum'
                  {...smGrid}
                >
                  <Input className='input-size-12' />
                </Form.Item>
                <Form.Item
                  label='FAX'
                  name='FaxNum'
                  {...smGrid}
                >
                  <Input className='input-size-12' />
                </Form.Item>
                <Form.Item
                  name='IndustrialClassify'
                  label='産業分類'
                  {...smGrid}
                >
                  <Select
                    allowClear
                    style={{ width: '200px' }}
                  >
                    {this.state.screenData.IndustrialClassify
                      ? this.state.screenData.IndustrialClassify.map((item, index) => {
                        return (
                          <Select.Option value={item.LinkedField} key={index}>
                            {item.DisplayedField}
                          </Select.Option>
                        )
                      })
                      : null}
                  </Select>
                </Form.Item>
                <Form.Item
                  label='産業医'
                  name='IndustrialPhysician'
                  {...smGrid}
                >
                  <Input className='input-size-30' />
                </Form.Item>
              </div>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                type='danger'
                icon={<DeleteOutlined />}
                onClick={() => {
                  this.deleteOfficeF11_1()
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                disabled={this.props.screen !== 'CorporateHearingSheet' && this.formRef.current?.getFieldValue('OfficeCode') ? false : true}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 'fit-content',
                      centered: true,
                      component: (
                        <CorporateHearingSheet
                          Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                          Lio_branch_store_code={this.formRef.current?.getFieldValue('BranchStoreCode')}
                          Lio_insurer_code={this.formRef.current?.getFieldValue('InsurerCode')}
                          Lio_office_kanji_name={this.formRef.current?.getFieldValue('OfficeNameKanji')}
                          screen={'WS0341001_OfficeInfoMaintain'}
                          onFinishScreen={() => {
                            this.closeModal()
                          }}
                        />
                      ),
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  ヒアリングシート
                </span>
              </Button>

              <Button
                disabled={
                  this.state.statusPage === 1 || this.state.statusPage === 2 ? true : false
                }
                type='primary'
                icon={<FileTextOutlined />}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: this.state.officeInfoDisplay?.StsContractCreated ? 1200 : 700,
                      component: this.state.officeInfoDisplay?.StsContractCreated ? (
                        <WS0307008_ContractHistorySub
                          Li_ContractType={2}
                          Li_ContractOrgCode={this.state.OfficeCode}
                          Li_ContractStartDate={''}
                          onFinishScreen={() => {
                            this.closeModal()
                          }}
                        />
                      ) : (
                        <WS0308017_OrganizationCreateNew
                          Li_ContractType={''}
                          Li_ContractOrgCode={this.state.OfficeCode}
                          onFinishScreen={() => {
                            this.setState({
                              officeInfoDisplay: {
                                ...this.state.officeInfoDisplay,
                                StsContractCreated: true
                              }
                            })
                            this.closeModal()
                          }}
                        />
                      )
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  契約作成
                </span>
              </Button>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => { this.saveOfficeInfo() }}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0341001_OfficeInfoMaintain)
