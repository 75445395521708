import React from 'react'
import { connect } from 'react-redux'
import { Card, Tabs, Table } from 'antd'
import { Link } from 'react-router-dom'

// タブ用配列
const tabList = [
  { key: '1', title: '属性設定', keyName: 'attribute' },
  { key: '2', title: '検査設定', keyName: 'inspect' },
  { key: '3', title: 'システム', keyName: 'system' },
  { key: '4', title: '特定保健', keyName: 'tokuho' },
  { key: '5', title: '改正関係', keyName: 'amendment' },
  { key: '6', title: '社内立上用', keyName: 'internal' },
]

// 画面リスト用の配列
/**
 * keyName:タブ区分
 * no:ナンバー
 * screenName:画面名
 * screenUrl:画面URL
 * stringFlag:文字列表示（リンクなし）例)'---①システム設定----------------------------'
 *
 * ※Backlogのカテゴリー「マスタ開発」から参照
 */
const screenList = [

  // 属性設定
  { keyName: 'attribute', no: 1, screenName: '保険者情報保守', screenUrl: '/insurer-info-maintain/insurer-info-maintain' },
  { keyName: 'attribute', no: 2, screenName: '事業所情報保守', screenUrl: '/office-info-maintain-directly/office-info-maintain' },
  { keyName: 'attribute', no: 3, screenName: '個人情報保守', screenUrl: '/personal-info-maintain-directly/personal-info-maintain' },
  { keyName: 'attribute', no: 4, screenName: '個人番号統合', screenUrl: '/personal-number-integration/personal-number-integration' },
  { keyName: 'attribute', no: 5, screenName: '個人番号移行', screenUrl: '/personal-number-migration/personal-number-migration' },
  { keyName: 'attribute', no: 6, screenName: '部署変更一括', screenUrl: '/department-change-once/department-change-once' },
  { keyName: 'attribute', no: 7, screenName: 'マスタ一覧出力', screenUrl: '/master-list-output/master-list-output' },
  { keyName: 'attribute', no: 8, screenName: '個人情報CSV出力', screenUrl: '/personal-info-csv-output/personal-info-csv-output' },
  { keyName: 'attribute', no: 9, screenName: '事業所情報CSV出力', screenUrl: '/office-info-csv-output/office-info-csv-output' },
  { keyName: 'attribute', no: 10, screenName: 'マスタ一覧出力[個人情報]', screenUrl: '' }, //URLなし

  // 検査設定
  { keyName: 'inspect', no: 1, screenName: '契約情報保守', screenUrl: '/contract-info-maintain/contract-info-maintain' },
  { keyName: 'inspect', no: 2, screenName: '契約情報一括処理', screenUrl: '/contract-info-batch-process/contract-info-batch-process' },
  { keyName: 'inspect', no: 3, screenName: '受診情報再構成', screenUrl: '/consult-info-reconstruction/consult-info-reconstruction' },
  { keyName: 'inspect', no: 4, screenName: 'セット情報マスタ', screenUrl: '/set-info-maintain/set-info-maintain' },
  { keyName: 'inspect', no: 5, screenName: '検査項目マスタ', screenUrl: '/inspect-item-info-maintain/inspect-item-info-maintain' },
  { keyName: 'inspect', no: 6, screenName: 'カテゴリ検査項目マスタ', screenUrl: '/another-inspect-item-setting-category/another-inspect-item-setting-category' },
  { keyName: 'inspect', no: 7, screenName: 'カテゴリマスタ', screenUrl: '/category-master-maintain/category-master-maintain' },
  { keyName: 'inspect', no: 8, screenName: '検査コメントマスタ', screenUrl: '/exam-cmt-info-maintain/inspect-cmt-info-maintain' },
  { keyName: 'inspect', no: 9, screenName: '検査項目判定値マスタ', screenUrl: '/inspect-item-judge-value-setting/inspect-item-judge-value-setting' },
  { keyName: 'inspect', no: 10, screenName: '検査基準値マスタ', screenUrl: '/inspect-ref-value-setting/inspect-ref-value-setting' },
  { keyName: 'inspect', no: 11, screenName: '複合判定マスタ', screenUrl: '' }, //URLなし
  { keyName: 'inspect', no: 12, screenName: '検査値自動計算保守', screenUrl: '/inspect-value-calculate-auto-maintain/inspect-value-calculate-auto-maintain' },
  { keyName: 'inspect', no: 13, screenName: 'コース基本種別マスタ', screenUrl: '/course-basic-type-setting/course-basic-type-setting' },
  { keyName: 'inspect', no: 14, screenName: '注意・指導コメント', screenUrl: '/caution-guide-matter-cmt-maintain/caution-guide-matter-cmt-maintain' },
  { keyName: 'inspect', no: 15, screenName: '部位所見マスタ', screenUrl: '/site-findings-master-maintain/site-findings-master-maintain' },
  { keyName: 'inspect', no: 16, screenName: '検査項目変換(内部)', screenUrl: '/inspect-item-convert-internal/inspect-item-convert-internal' },
  { keyName: 'inspect', no: 17, screenName: '読影検査保守', screenUrl: '/radiography-inspect-maintain/radiography-inspect-maintain' },
  { keyName: 'inspect', no: 18, screenName: '読影判定保守', screenUrl: '/radiography-judge-maintain/radiography-judge-maintain' },
  { keyName: 'inspect', no: 19, screenName: '読影者情報保守', screenUrl: '/radiologists-info-maintain/radiography-info-maintain' },
  { keyName: 'inspect', no: 20, screenName: '条件式ｺﾒﾝﾄ設定', screenUrl: '/condition-express-cmt-setting/condition-express-cmt-setting' },
  { keyName: 'inspect', no: 21, screenName: '自動判定管理', screenUrl: '' }, //URLなし

  // システム
  { keyName: 'system', no: 1, screenName: '施設・消費税設定', screenUrl: '/facility-consumption-tax-setting/facility-consumption-tax-setting' },
  { keyName: 'system', no: 2, screenName: '予約表示項目設定', screenUrl: '/reserves-display-item-setting/reserves-display-item-setting' },
  { keyName: 'system', no: 3, screenName: '年齢管理情報保守', screenUrl: '/age-manage-info-maintain/age-manage-info-maintain' },
  { keyName: 'system', no: 4, screenName: '基本時間帯', screenUrl: '/basic-period-time/basic-period-time' },
  { keyName: 'system', no: 5, screenName: '休診日設定', screenUrl: '/non-consult-date-setting/non-consult-date-setting' },
  { keyName: 'system', no: 6, screenName: '正常値設定保守', screenUrl: '/normal-value-setting-maintain/normal-value-setting' },
  { keyName: 'system', no: 7, screenName: '病名マスタ', screenUrl: '/disease-name-master-maintain/disease-name-master-maintain' },
  { keyName: 'system', no: 8, screenName: '地区情報', screenUrl: '' }, //URLなし
  { keyName: 'system', no: 9, screenName: '健診場所マスタ', screenUrl: '' }, //URLなし
  { keyName: 'system', no: 10, screenName: '基準人数設定情報', screenUrl: '/ref-people-num-setting-info/ref-people-num-setting-info' },
  { keyName: 'system', no: 11, screenName: 'ＣＳＶパラメータ設定', screenUrl: '/csv-create-param-maintain/csv-create-param-maintain' },
  { keyName: 'system', no: 12, screenName: '印刷パラメータ保守', screenUrl: '/print-param-maintain/print-param-maintain' },
  { keyName: 'system', no: 13, screenName: '結合様式設定', screenUrl: '/binding-mode-setting/binding-mode-setting' },
  { keyName: 'system', no: 14, screenName: 'ユーザパラメータ保守', screenUrl: '/user-param-maintain/user-param-maintain' },
  { keyName: 'system', no: 15, screenName: 'ユーザオプション情報保守', screenUrl: '/user-option-info-maintain/user-option-info-maintain' },
  { keyName: 'system', no: 16, screenName: 'オプション情報保守', screenUrl: '/option-setting/option-setting' },
  { keyName: 'system', no: 17, screenName: '資料管理保守', screenUrl: '/document-manage-maintain/document-manage-maintain' },
  { keyName: 'system', no: 18, screenName: '院内/外情報保守', screenUrl: '/facility-create-hospital-outside-create-info-maintain/facility-hospital-outside-info-maintain' },
  { keyName: 'system', no: 19, screenName: '特定日人数設定', screenUrl: '/specific-date-people-num-setting/specific-date-people-num-setting' },
  { keyName: 'system', no: 20, screenName: '進捗情報保守', screenUrl: '/progress-info-maintain/progress-info-maintain' },
  { keyName: 'system', no: 21, screenName: 'Ｄｒ照会表示項目設定', screenUrl: '' }, //URLなし
  { keyName: 'system', no: 22, screenName: '判定レベル変換マスタ', screenUrl: '/determine-level-modify/determine-level-modify-master-coercive' },
  { keyName: 'system', no: 23, screenName: 'プリンタ情報', screenUrl: '/printer-info-maintain/printer-info-maintain' },
  { keyName: 'system', no: 24, screenName: 'ユーザー帳票項目保守', screenUrl: '/user-document-item-maintain/user-document-item-maintain' },
  { keyName: 'system', no: 25, screenName: '集計パラメータ', screenUrl: '' }, //URLなし
  { keyName: 'system', no: 26, screenName: 'ﾛｯｸﾌｧｲﾙ削除', screenUrl: '' }, //URLなし
  { keyName: 'system', no: 27, screenName: '不正データ削除', screenUrl: '' }, //URLなし
  { keyName: 'system', no: 28, screenName: '協会けんぽ設定', screenUrl: '/associate-insure-param-maintain/associate-insure-param-maintain' },
  { keyName: 'system', no: 29, screenName: 'ｺｰｽ別標準様式設定', screenUrl: '/course-specific-stard-style-setting/course-specific-stard-style-setting' },

  // 特定保健
  { keyName: 'tokuho', no: 1, screenName: '契約マスタ保守', screenUrl: '/contract-master-maintain/contract-master-maintain' },
  { keyName: 'tokuho', no: 2, screenName: '特健特保パラメータ保守', screenUrl: '/specific-health-tokuho-param-maintain/specific-health-tokuho-param-maintain' },
  { keyName: 'tokuho', no: 3, screenName: 'ＸＭＬパラメータ保守', screenUrl: '/xml-param-maintain/xml-param-maintain' },
  { keyName: 'tokuho', no: 4, screenName: 'ＪＬＡＣ１０コードマスタ', screenUrl: '/xml-medical-exam-item-master/xml-medical-exam-item-master' },
  { keyName: 'tokuho', no: 5, screenName: 'データ範囲チェック', screenUrl: '/data-range-check/data-range-check' },
  { keyName: 'tokuho', no: 6, screenName: '実施者マスタ', screenUrl: '/implementor-master/implementor-master' },
  { keyName: 'tokuho', no: 7, screenName: '実施場所マスタ', screenUrl: '/implementation-location-master/implement-location-master' },
  { keyName: 'tokuho', no: 8, screenName: '実施機関マスタ', screenUrl: '/implement-agencies-master/implement-agencies-master' },
  { keyName: 'tokuho', no: 9, screenName: '実施区分マスタ', screenUrl: '/implementation-division-master/implement-division-master' },
  { keyName: 'tokuho', no: 10, screenName: '検査コメントマスタ', screenUrl: '/inspect-cmt-master/inspect-cmt-master' },
  { keyName: 'tokuho', no: 11, screenName: '検査項目マスタ', screenUrl: '/inspect-item-info-maintain/inspect-item-info-maintain' },
  { keyName: 'tokuho', no: 12, screenName: '支援項目', screenUrl: '/support-item/support-item' },
  { keyName: 'tokuho', no: 13, screenName: '支援項目サブ', screenUrl: '/support-item/support-item-sub' },
  { keyName: 'tokuho', no: 14, screenName: '文章分類マスタ', screenUrl: '/document-classify-master/document-classify-master' },
  { keyName: 'tokuho', no: 15, screenName: '文章マスタ', screenUrl: '/sentence-master/sentence-master' },
  { keyName: 'tokuho', no: 16, screenName: '保健指導コース', screenUrl: '/insure-guide-course/insure-guide-course ' },
  { keyName: 'tokuho', no: 17, screenName: '特健特保ｵﾌﾟｼｮﾝﾌｧｲﾙ', screenUrl: '/specific-health-tokuho-options-file/specific-health-tokuho-options-file' },

  // 改正関係
  { keyName: 'amendment', no: 1, screenName: '出荷取得機能', screenUrl: '' }, //URLなし
  { keyName: 'amendment', no: 2, screenName: 'テキスト取込', screenUrl: '' }, //URLなし
  { keyName: 'amendment', no: 3, screenName: 'ファイル出力関連', screenUrl: '' }, //URLなし
  { keyName: 'amendment', no: 4, screenName: '契約検査条件リカバリ', screenUrl: '' }, //URLなし
  { keyName: 'amendment', no: 5, screenName: '協会けんぽ情報取得', screenUrl: '' }, //URLなし

  // 社内立上用
  { keyName: 'internal', no: 1, screenName: '---①システム設定----------------------------', screenUrl: '', stringFlag: true },
  { keyName: 'internal', no: 2, screenName: '施設・消費税設定', screenUrl: '/facility-consumption-tax-setting/facility-consumption-tax-setting' },
  { keyName: 'internal', no: 3, screenName: '年齢管理情報保守', screenUrl: '/age-manage-info-maintain/age-manage-info-maintain' },
  { keyName: 'internal', no: 4, screenName: '基本時間帯', screenUrl: '/basic-period-time/basic-period-time' },
  { keyName: 'internal', no: 5, screenName: '休診日設定', screenUrl: '/non-consult-date-setting/non-consult-date-setting' },
  { keyName: 'internal', no: 6, screenName: '病名マスタ保守', screenUrl: '/disease-name-master-maintain/disease-name-master-maintain' },
  { keyName: 'internal', no: 7, screenName: '地区情報保守', screenUrl: '' }, //URLなし
  { keyName: 'internal', no: 8, screenName: '健診場所ﾏｽﾀ設定', screenUrl: '' }, //URLなし
  { keyName: 'internal', no: 9, screenName: '施設(院内/外)情報保守', screenUrl: '/facility-create-hospital-outside-create-info-maintain/facility-hospital-outside-info-maintain' },
  { keyName: 'internal', no: 10, screenName: 'ｵﾌﾟｼｮﾝ情報保守', screenUrl: '/option-setting/option-setting' },
  { keyName: 'internal', no: 11, screenName: '判定ﾚﾍﾞﾙ変換ﾏｽﾀ', screenUrl: '/determine-level-modify/determine-level-modify-master-coercive' },
  { keyName: 'internal', no: 12, screenName: 'ﾌﾟﾘﾝﾀ情報保守', screenUrl: '/printer-info-maintain/printer-info-maintain' },
  { keyName: 'internal', no: 13, screenName: '---②検査等設定-------------------------------', screenUrl: '', stringFlag: true },
  { keyName: 'internal', no: 14, screenName: '検査項目情報保守', screenUrl: '/inspect-item-info-maintain/inspect-item-info-maintain' },
  { keyName: 'internal', no: 15, screenName: 'ｶﾃｺﾞﾘｰﾏｽﾀ保守', screenUrl: '/category-master-maintain/category-master-maintain' },
  { keyName: 'internal', no: 16, screenName: '検査ｺﾒﾝﾄ情報保守', screenUrl: '/exam-cmt-info-maintain/inspect-cmt-info-maintain' },
  { keyName: 'internal', no: 17, screenName: 'ｶﾃｺﾞﾘ別検査項目設定', screenUrl: '/another-inspect-item-setting-category/another-inspect-item-setting-category' },
  { keyName: 'internal', no: 18, screenName: '検査項目判定値設定', screenUrl: '/inspect-item-judge-value-setting/inspect-item-judge-value-setting' },
  { keyName: 'internal', no: 19, screenName: '検査基準値設定', screenUrl: '/inspect-ref-value-setting/inspect-ref-value-setting' },
  { keyName: 'internal', no: 20, screenName: '検査値自動計算保守', screenUrl: '/inspect-value-calculate-auto-maintain/inspect-value-calculate-auto-maintain' },
  { keyName: 'internal', no: 21, screenName: 'ｾｯﾄ情報保守', screenUrl: '/set-info-maintain/set-info-maintain' },
  { keyName: 'internal', no: 22, screenName: '注意・指導事項ｺﾒﾝﾄ保守', screenUrl: '/caution-guide-matter-cmt-maintain/caution-guide-matter-cmt-maintain' },
  { keyName: 'internal', no: 23, screenName: '部位所見ﾏｽﾀ保守', screenUrl: '/site-findings-master-maintain/site-findings-master-maintain' },
  { keyName: 'internal', no: 24, screenName: '予約表示項目設定', screenUrl: '/reserves-display-item-setting/reserves-display-item-setting' },
  { keyName: 'internal', no: 25, screenName: '正常値設定保守', screenUrl: '/normal-value-setting-maintain/normal-value-setting' },
  { keyName: 'internal', no: 26, screenName: '基準人数設定情報', screenUrl: '/ref-people-num-setting-info/ref-people-num-setting-info' },
  { keyName: 'internal', no: 27, screenName: '複合判定ﾏｽﾀ保守', screenUrl: '' }, //URLなし
  { keyName: 'internal', no: 28, screenName: '条件式ｺﾒﾝﾄ設定', screenUrl: '/condition-express-cmt-setting/condition-express-cmt-setting' },
  { keyName: 'internal', no: 29, screenName: '旧.検査項目変換', screenUrl: '' }, //URLなし
  { keyName: 'internal', no: 30, screenName: '---③コース設定--------------------------------', screenUrl: '', stringFlag: true },
  { keyName: 'internal', no: 31, screenName: 'コース基本種別設定', screenUrl: '/course-basic-type-setting/course-basic-type-setting' },
  { keyName: 'internal', no: 32, screenName: 'ﾕｰｻﾞｰ帳票項目保守', screenUrl: '/user-document-item-maintain/user-document-item-maintain' },
  { keyName: 'internal', no: 33, screenName: '---④契約作成----------------------------------', screenUrl: '', stringFlag: true },
  { keyName: 'internal', no: 34, screenName: '契約情報保守', screenUrl: '/contract-info-maintain/contract-info-maintain' },
  { keyName: 'internal', no: 35, screenName: '契約情報一括処理', screenUrl: '/contract-info-batch-process/contract-info-batch-process' },
  { keyName: 'internal', no: 36, screenName: '契約履歴一括作成', screenUrl: '/creating-contract-history-together/create-contract-history-together' },
  { keyName: 'internal', no: 37, screenName: '---⑤CSV、結果票、資料管理---------------------', screenUrl: '', stringFlag: true },
  { keyName: 'internal', no: 38, screenName: 'CSV作成ﾊﾟﾗﾒｰﾀ保守', screenUrl: '/csv-create-param-maintain/csv-create-param-maintain' },
  { keyName: 'internal', no: 39, screenName: '印刷ﾊﾟﾗﾒｰﾀ保守', screenUrl: '/print-param-maintain/print-param-maintain' },
  { keyName: 'internal', no: 40, screenName: 'Ｄｒ表示項目設定', screenUrl: '' }, //URLなし
  { keyName: 'internal', no: 41, screenName: '集計ﾊﾟﾗﾒｰﾀ', screenUrl: '' }, //URLなし
  { keyName: 'internal', no: 42, screenName: '資料管理保守', screenUrl: '/document-manage-maintain/document-manage-maintain' },
  { keyName: 'internal', no: 43, screenName: '進捗情報保守', screenUrl: '/progress-info-maintain/progress-info-maintain' },
  { keyName: 'internal', no: 44, screenName: 'ｺｰｽ基本（結果票様式の規定）', screenUrl: '/course-basic-type-setting/course-basic-type-setting' },
  { keyName: 'internal', no: 45, screenName: '---⑥マスタ保守--------------------------------', screenUrl: '', stringFlag: true },
  { keyName: 'internal', no: 46, screenName: '保険者情報保守', screenUrl: '/insurer-info-maintain/insurer-info-maintain' },
  { keyName: 'internal', no: 47, screenName: '事業所情報保守', screenUrl: '/office-info-maintain-directly/office-info-maintain' },
  { keyName: 'internal', no: 48, screenName: '個人情報保守', screenUrl: '/personal-info-maintain-directly/personal-info-maintain' },
  { keyName: 'internal', no: 49, screenName: '---※出荷前ﾁｪｯｸ--------------------------------', screenUrl: '', stringFlag: true },
  { keyName: 'internal', no: 50, screenName: '立上資料作成', screenUrl: '/rising-document-create/rising-document-create' },
  { keyName: 'internal', no: 51, screenName: 'Phase③データ作成', screenUrl: '' }, //URLなし
]

class WS0211001_SupportMenu extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = 'サポート'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
  }

  /**
   * タブのレイアウト設定
   * @param {*} keyName
   * @returns
   */
  renderBodyTab = (keyName) => {
    let list = []
    // 選択タブのリストに絞り込み
    list = screenList.filter((screenInfo) => screenInfo.keyName === keyName)

    return (
      <Table
        bordered
        dataSource={list}
        size='small'
        pagination={false}
        scroll={{ x: 'max-content', y: 600 }}
        rowKey={(record) => record.no}
        key={'table_' + keyName}
      >
        <Table.Column
          title='No'
          dataIndex='no'
          className='column-size-2'
          render={(value) => {
            return (
              <div style={{ textAlign: 'right' }}>{value == 0 ? '' : value}</div>
            )
          }}
        />
        <Table.Column
          title='画面名'
          dataIndex='screenName'
          className='column-size-30'
          render={(value, record, index) => {
            return (
              (record?.stringFlag) ?
                // 文字だけの場合
                <div>{record.screenName}</div>
                :
                (record?.screenUrl !== '') ?
                  // URL(path)が設定されている場合
                  <Link
                    to={{
                      pathname: record.screenUrl,
                      state: {
                        title: record.screenName
                      }
                    }}
                    target={'_blank'} //別タブに新しく画面を開く
                  >
                    {record.screenName}
                  </Link>
                  :
                  // URLがない場合、非活性
                  // 「href="#"」と表記させることで、クリックをしてもどこにも飛ばないように設定する
                  <a href='#' disabled>
                    {record.screenName}
                  </a>
            )
          }}
        />
      </Table >
    )
  }

  render() {
    return (
      <div className='support-menu'>
        <Card
          title='サポート'
          style={{ width: '600px' }}
        >
          <Tabs
            type='card'
            key='defalt'
            defaultActiveKey='1'
          >
            {/* タブリスト */}
            {tabList.map((item) =>
              <Tabs.TabPane
                tab={item.title}
                key={item.key}
              >
                {/* タブのレイアウト設定 */}
                {this.renderBodyTab(item.keyName)}
              </Tabs.TabPane>
            )}
          </Tabs>
        </Card>

      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0211001_SupportMenu)
