import React from "react";

import {
  Card, Table, message, Button, Form, Input, Popconfirm, Space, Checkbox,
  Empty
} from "antd";
import {
  PlusOutlined, DeleteOutlined, SaveOutlined
} from '@ant-design/icons';

import ModalDraggable from "components/Commons/ModalDraggable";
import ImplementAgenciesMasterAction from "redux/SpecificInsureMaintenance/ImplementAgenciesMaster/ImplementAgenciesMaster.action";
class WS1407001_ImplementAgenciesMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '実施機関マスタ';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true });
    ImplementAgenciesMasterAction.index()
      .then(res => {
        console.log(res)
        this.setState({
          dataSource: res
        })
        this.formRef.current.setFieldsValue({
          MainSource: res,
        });
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  Save(record) {
    let dataSave = [...this.formRef.current?.getFieldValue("MainSource")];
    for (let index = 0; index < dataSave.length; index++) {
      const id = dataSave[index].id
      if (record.id === id) {
        if (isNaN(record.id)) {
          let objSave = {
            id: "",
            implementing_agency_code: dataSave[index].implementing_agency_code,
            institution_number: dataSave[index].institution_number,
            kana_name: dataSave[index].kana_name,
            kanji_name: dataSave[index].kanji_name,
            option_remark: dataSave[index].option_remark,
            SupportFormeMailA: dataSave[index].SupportFormeMailA,
            SupportFormeMailB: dataSave[index].SupportFormeMailB,
            SupportFormgroup: dataSave[index].SupportFormgroup,
            SupportFormpersonA: dataSave[index].SupportFormpersonA,
            SupportFormpersonB: dataSave[index].SupportFormpersonB,
            SupportFormphoneB: dataSave[index].SupportFormphoneB,
            SupportFormphoneA: dataSave[index].SupportFormphoneA,
          }
          this.SaveData(objSave)
        } else {
          let objSave = dataSave[index]
          this.SaveData(objSave)
        }
        return
      }
    }
  }

  SaveData(record) {
    console.log(record)
    this.setState({ isLoading: true });
    ImplementAgenciesMasterAction.save({ mainSource: [record] })
      .then(res => {
        console.log(res)
        if (!res) {
          return;
        }
        message.success('保存完了');
        this.loadMainSource();
      })
      .catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error("エラーが発生しました");
        }
        alert(444)
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  handleAddRowTable() {
    let newRow = {
      id: null,
      implementing_agency_code: "",
      institution_number: "",
      kana_name: "",
      kanji_name: "",
      option_remark: "",
      SupportFormeMailA: false,
      SupportFormeMailB: false,
      SupportFormgroup: false,
      SupportFormpersonA: false,
      SupportFormpersonB: false,
      SupportFormphoneB: false,
      SupportFormphoneA: false,
    };

    let data = [...this.state.dataSource];

    data.unshift(newRow);

    this.setState({
      dataSource: data,
    });

    this.formRef.current.setFieldsValue({
      MainSource: [...data],
    });

    this.forceUpdate();
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card className="mb-2" title="実施機関マスタ">
          <Form ref={this.formRef}>
            <Table bordered size="small"
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={false}
              rowKey={(record) => (record.id || Math.random())}
            >
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.implementing_agency_code.localeCompare(b.implementing_agency_code, 'ja')} title="実施機関コード" dataIndex="implementing_agency_code"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <>
                    <Form.Item name={['MainSource', index, 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['MainSource', index, 'implementing_agency_code']}>
                      <Input />
                    </Form.Item>
                  </>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.institution_number.localeCompare(b.institution_number, 'ja')} title="機関番号" dataIndex="institution_number"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'institution_number']}>
                    <Input />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.kana_name.localeCompare(b.kana_name, 'ja')} width={350} title="カナ／機関名" key="name"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <>
                    <Form.Item name={['MainSource', index, 'kana_name']}>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['MainSource', index, 'kanji_name']}>
                      <Input />
                    </Form.Item>
                  </>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormpersonA.localeCompare(b.SupportFormpersonA, 'ja')} title="個別A" align="center" dataIndex="SupportFormpersonA"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_individual_a']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormpersonB.localeCompare(b.SupportFormpersonB, 'ja')} title="個別B" align="center" dataIndex="SupportFormpersonB"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_individual_b']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormgroup.localeCompare(b.SupportFormgroup, 'ja')} title="ｸﾞﾙｰﾌﾟ" align="center" dataIndex="SupportFormgroup"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_group']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormphoneA.localeCompare(b.SupportFormphoneA, 'ja')} title="電話A" align="center" dataIndex="SupportFormphoneA"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_telephone_a']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormphoneB.localeCompare(b.SupportFormphoneB, 'ja')} title="電話B" align="center" dataIndex="SupportFormphoneB"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_phone_b']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormeMailA.localeCompare(b.SupportFormeMailAv, 'ja')} title="ﾒｰﾙA" align="center" dataIndex="SupportFormeMailA"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_email_a']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.SupportFormeMailB.localeCompare(b.SupportFormeMailB, 'ja')} title="ﾒｰﾙB" align="center" dataIndex="SupportFormeMailB"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'support_form_email_b']} valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                }} />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.option_remark.localeCompare(b.option_remark, 'ja')} title="オプション" dataIndex="option_remark"
                render={(value, record) => {
                  let index = this.findIndexByID(this.state.dataSource, record.id);
                  return <Form.Item name={['MainSource', index, 'option_remark']}>
                    <Input />
                  </Form.Item>
                }} />
              <Table.Column
                key="action" align="center"
                title={(
                  <Button type="primary" size="small" icon={<PlusOutlined />} loading={this.state.isLoadingInsurersList} onClick={() => { this.handleAddRowTable() }} />
                )}
                render={(value, record) => (
                  <>
                    <Space>
                      <Button
                        size="small"
                        style={{ color: "green", marginBottom: "5px" }}
                        icon={<SaveOutlined />}
                        onClick={() => {
                          this.Save(record);
                        }}
                      ></Button>
                    </Space>
                    <Space>
                      <Popconfirm title="削除しますか？" onConfirm={() => {
                        this.setState({ isLoading: true });

                        return ImplementAgenciesMasterAction.delete({ id: record.id })
                          .then(() => {
                            message.success('削除しました')
                            this.loadMainSource()
                          })
                          .catch(error => {
                            console.log(error);
                            this.setState({ isLoading: false });
                            const res = error.response;
                            if (!res || !res.data || !res.data.message) {
                              message.error('エラーが発生しました');
                              return;
                            }

                            message.error(res.data.message);
                          });
                      }}>
                        <Button type="primary" size="small" danger icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Space></>)}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          afterClose={this.state.childModal.afterClose}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS1407001_ImplementAgenciesMaster;
