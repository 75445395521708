import React, { createRef, Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, Form, Input, Select, List, Button } from 'antd'
// import { debounce } from 'lodash'
// import { getPublics, getCities, getAreas, setCurrentArea } from '../../../redux/basicInfo/InsurerInfoMaintain/InsurerInfoMaintain.actions'
import PostCodeSearchEngineAction from 'redux/basicInfo/InsurerInfoMaintain/PostCodeSearchEngine.actions'

class WS0084001_PostCodeSearchEngine extends Component {
  formRef = createRef()

  static propTypes = {
    // Lio_ZipCode: PropTypes.any,
    // Lio_Address: PropTypes.any,
    Li_CurrentPublic: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '郵便番号検索'

    this.state = {
      publics: [], // 都道府県
      tcities: [], // 市区町村
      areas: [], // 町名
      selectedPublic: '',
      selectedCity: '',
      selectedArea: '',
      selectedPostalCode: ''// 郵便番号
    }

    // this.onChangeCitySearch = debounce(this.onChangeCitySearch, 500)
    // this.onChangeAreaSearch = debounce(this.onChangeAreaSearch, 500)
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 初期データ取得
    PostCodeSearchEngineAction.index()
      .then(res => {
        if (res) {
          console.log(res);
          this.setState({
            publics: res.publics, // 都道府県
            cities: res.cities, // 市区町村
            areas: res.areas, // 町名
            selectedPublic: res.selectedPublicName,
            selectedCity: res.selectedCityName,
            selectedArea: res.selectedAreaName,
            selectedPostalCode: res.selectedPostalCode // 郵便番号
          })
        }
      })
  }

  /**
   * 都道府県の選択変更
   * @param {*} publicName
   */
  changePublic = (publicName) => {
    if (publicName === '') return

    // 選択された都道府県を変更
    this.setState({ selectedPublic: publicName })

    const params = {
      selectedPublic: publicName,
      citySearch: this.formRef.current.getFieldValue('citySearch'),
      areaSearch: this.formRef.current.getFieldValue('areaSearch')
    }
    PostCodeSearchEngineAction.changePublic(params)
      .then(res => {
        if (res) {
          console.log(res);
          this.setState({
            cities: res.cities, // 市区町村
            areas: res.areas, // 町名
            selectedCity: res.selectedCityName,
            selectedArea: res.selectedAreaName,
            selectedPostalCode: res.selectedPostalCode // 郵便番号
          })
        }
      })
  }

  /**
   * 市区町村の選択変更
   *
   * @param {*} cityName
   */
  changeCity = (cityName) => {

    // 選択された市区町村を変更
    this.setState({ selectedCity: cityName })

    const params = {
      selectedPublic: this.state.selectedPublic,
      selectedCity: cityName,
      citySearch: this.formRef.current.getFieldValue('citySearch'),
      areaSearch: this.formRef.current.getFieldValue('areaSearch')
    }
    PostCodeSearchEngineAction.changeCity(params)
      .then(res => {
        if (res) {
          console.log(res);
          this.setState({
            cities: res.cities, // 市区町村
            areas: res.areas, // 町名
            selectedCity: res.selectedCityName,
            selectedArea: res.selectedAreaName,
            selectedPostalCode: res.selectedPostalCode // 郵便番号
          })
        }
      })
  }

  /**
   * 町名の検索絞り込み
   *
   * @param {*} cityName
   */
  changeArea = () => {

    const params = {
      selectedPublic: this.state.selectedPublic,
      selectedCity: this.state.selectedCity,
      citySearch: this.formRef.current.getFieldValue('citySearch'),
      areaSearch: this.formRef.current.getFieldValue('areaSearch')
    }
    PostCodeSearchEngineAction.changeArea(params)
      .then(res => {
        if (res) {
          console.log(res);
          this.setState({
            areas: res.areas, // 町名
            selectedArea: res.selectedAreaName,
            selectedPostalCode: res.selectedPostalCode // 郵便番号
          })
        }
      })
  }

  /**
   * 市区町村の検索文字を変更
   * ※debounceを使用
   */
  onChangeCitySearch = () => {
    // 市区町村の選択変更
    this.changeCity(this.state.selectedCity)
  }

  /**
   * 町名の検索文字を変更
   * ※debounceを使用
   */
  onChangeAreaSearch = () => {
    // 町名の選択変更
    this.changeArea(this.state.selectedArea)
  }


  /**
   * 郵便番号と住所を親画面に反映
   */
  setPotalCode = () => {

    let areaName = this.state.selectedArea
    if (this.state.selectedArea.indexOf('(以下に掲載がない場合)') > -1) {
      // 町名に「(以下に掲載がない場合)」が含まれる場合、空文字にする
      areaName = ''
    }
    // 住所
    const address = this.state.selectedPublic + this.state.selectedCity + areaName

    if (this.props.onFinishScreen) {
      // 親画面に反映
      this.props.onFinishScreen({
        Lio_ZipCode: this.state.selectedPostalCode,
        Lio_Prefecture: this.state.selectedPublic,
        Lio_Address: address,
        postal_code: this.state.selectedPostalCode,
      })
    }
  }

  render() {
    const year = '2021年度'

    return (
      <div className='post-code-search-engine'>
        <Card title={`郵便番号検索 - ${year}`}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container' style={{ gap: 0 }}>
              <div className='table_header_filter' style={{ fontWeight: 'bold' }}>
                〒{this.state.selectedPostalCode} {this.state.selectedPublic}{this.state.selectedCity}{this.state.selectedArea}
              </div>

              <Form.Item label='都道府県' style={{ marginTop: 10 }}>
                <Select
                  value={this.state.selectedPublic}
                  className='select-after'
                  style={{ width: 200 }}
                  onChange={(value) => {
                    // 都道府県の選択変更
                    this.changePublic(value)
                  }}>
                  {this.state.publics.map((item, index) => (
                    <Select.Option
                      key={index}
                      value={item.public}
                    >
                      {item.public}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div
                className='box_inner_horizontal'
                style={{ width: '100%' }}
              >
                <Form.Item
                  name='citySearch'
                  style={{ width: '30%', marginBottom: 0 }}
                >
                  <Input
                    placeholder='市区町村を検索'
                    onPressEnter={(event) => {
                      // 市区町村の検索文字を変更
                      this.changeCity(this.state.selectedCity)
                    }} />
                </Form.Item>
                <Form.Item
                  name='areaSearch'
                  style={{ width: '70%', marginBottom: 0 }}
                >
                  <Input
                    placeholder='町名を検索'
                    onPressEnter={(event) => {
                      // 町名の検索文字を変更
                      this.changeArea()
                    }} />
                </Form.Item>
              </div>

              <div
                className='box_inner_horizontal'
                style={{ width: '100%' }}
              >
                <List
                  size='small'
                  bordered
                  dataSource={this.state.cities}
                  className='scrollbar'
                  style={{ width: '30%', height: '400px', overflowY: 'auto' }}
                  // loading={this.state.isLoadingPublic || this.state.isLoadingCity}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        cursor: 'pointer',
                        background: (this.state.selectedCity === item.city) ? '#a3d3ff' : ''
                      }}
                      onClick={() => {
                        // 市区町村の選択変更
                        this.changeCity(item.city)
                      }}
                    >
                      {item.city}
                    </List.Item>
                  )}
                />
                <List
                  size='small'
                  bordered
                  dataSource={this.state.areas}
                  className='scrollbar'
                  style={{ width: '70%', height: '400px', overflowY: 'auto' }}
                  // loading={this.state.isLoadingPublic || this.state.isLoadingCity || this.state.isLoadingArea}
                  renderItem={(item) => (
                    <List.Item
                      style={{
                        cursor: 'pointer',
                        background: (this.state.selectedArea === item.area) ? '#a3d3ff' : ''
                      }}
                      onClick={() => {
                        // 選択された町名と郵便番号を変更
                        this.setState({
                          selectedArea: item.area,
                          selectedPostalCode: item.postal_code
                        })
                      }}
                      onDoubleClick={() => {
                        // 郵便番号と住所を親画面に反映
                        this.setPotalCode()
                      }
                      }
                    >
                      {item.area}
                    </List.Item>
                  )}
                />
              </div>

            </div>

            {/* <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => this.setPotalCode()}
              >
                <span className='btn_label'>
                  反映
                </span>
              </Button>
            </div> */}

          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({})

// ※reduxを使用する必要がない為、コメントアウト
// const mapStateToProps = (state) => {
// return {
//   publics: state.InsurerInfoMaintainReducer.publics,
//   cities: state.InsurerInfoMaintainReducer.cities,
//   areas: state.InsurerInfoMaintainReducer.areas,
//   selectedPublic: state.InsurerInfoMaintainReducer.selectedPublic,
//   selectedCity: state.InsurerInfoMaintainReducer.selectedCity,
//   selectedArea: state.InsurerInfoMaintainReducer.selectedArea
// }
// }

// ※reduxを使用する必要がない為、コメントアウト
// const mapDispatchToProps = (dispatch) => {
// return {
//   getPublics: data => dispatch(getPublics(data)),
//   getCities: data => dispatch(getCities(data)),
//   getAreas: data => dispatch(getAreas(data)),
//   setCurrentArea: area => dispatch(setCurrentArea(area))
// }
// }

export default connect(mapStateToProps, mapDispatchToProps)(WS0084001_PostCodeSearchEngine)
