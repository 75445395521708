import { message } from "antd";
import MasterListOutputAttributeService from "services/StatisticalServices/MasterListOutput/MasterListOutputAttributeService.js";


const MasterListOutputAttributeAction = {
  outputCsvFile(data) {
    return MasterListOutputAttributeService.outputCsvFile(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  personalInfoSearchBtn(data) {
    return MasterListOutputAttributeService.personalInfoSearchBtn(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  personalInfoDetail(data) {
    return MasterListOutputAttributeService.personalInfoDetail(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
  personalInfoOutputBtn(data) {
    return MasterListOutputAttributeService.personalInfoOutputBtn(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err)
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default MasterListOutputAttributeAction;