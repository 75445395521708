import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Button, Card, Form, Input, Select, Table, Dropdown, Menu, message, Modal } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import WS2701025_CmtRegisterCopy from 'pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS2701025_CmtRegisterCopy.jsx'
import CautionGuideMatterCmtMaintainAction from 'redux/InspectionMaintenance/CautionGuideMatterCmtMaintain/CautionGuideMatterCmtMaintain.actions'
import CmtRegisterCopyAction from 'redux/InspectionMaintenance/CmtRegisterCopy/CmtRegisterCopy.actions'
import WS0376001_AnotherGuideCmtMaintainCategory from 'pages/MS_InspectionMaintenance/V4MS0107000_CautionGuideMatterCmtMaintain/WS0376001_AnotherGuideCmtMaintainCategory.jsx'

class WS2701001_CautionGuideMatterCmtMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '注意・指導事項コメント保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      rowSelect: [],
      selectedRow: [],
    }
  }

  /**
   * 初期表示
   */
  componentDidMount() {
    this.formRef.current.setFieldsValue({ IdentifyCode: 'S' })
    this.getCommentLists()
  }

  /**
   * コメント一覧取得
   */
  getCommentLists() {
    let data = {
      IdentifyCode: this.formRef.current?.getFieldValue('IdentifyCode'),
      SearchKey: this.formRef.current?.getFieldValue('SearchKey')
    }
    CautionGuideMatterCmtMaintainAction.getCommentLists(data)
      .then(res => {
        this.setState({
          dataSource: res ? res : [],
          selectedRow: []
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  onFinish(values) {

  }

  DeleteComment(record) {
    console.log(record)
    let data = {
      IdentifyCode: this.formRef.current.getFieldValue('IdentifyCode') ?? '',
      comment_code: record.comment_code ? record.comment_code : ""
    }
    CmtRegisterCopyAction.DeleteComment(data)
      .then(res => {
        this.getCommentLists()
      })
  }

  showAnotherGuideCmtMaintainCategory() {
    this.setState({
      ...this.state,
      childModal: {
        width: '80%',
        visible: true,
        component: (
          <WS0376001_AnotherGuideCmtMaintainCategory
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  editForm(status, record, stsCopy, stsEdit) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS2701025_CmtRegisterCopy
            Li_IdentifyCode={this.formRef.current.getFieldValue('IdentifyCode')}
            Li_CommentCode={record ? record.comment_code : null}
            Li_Copy={stsCopy}
            Li_Edit={stsEdit}
            Li_status={status}
            onFinishScreen={(output) => {
              console.log('onFinishScreen');
              this.getCommentLists()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
  * モーダルを閉じる
  */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='caution-guide-matter-cmt-maintain'>
        <Card title='注意・指導事項コメント保守'  >
          <div className='box_container'>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              autoComplete='off'
              initialValues={{
                IdentifyCode: 'C',
                SearchKey: ''
              }}
            >
              <Form.Item
                name='IdentifyCode'
                label='タイプ'
                style={{ width: 200, float: 'left', marginRight: '15px', marginBottom: 0 }}
              >
                <Select onChange={() => this.getCommentLists()} >
                  <Select.Option value='S'>指導事項</Select.Option>
                  <Select.Option value='C'>注意事項</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='SearchKey'
                label='検索'
                style={{ width: '40%', marginBottom: 0 }}>
                <Input
                  maxLength={50}
                  onPressEnter={() => this.getCommentLists()}
                />
              </Form.Item>
            </Form>
            <Table
              bordered
              size='small'
              pagination={false}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              scroll={{ y: 500 }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    // 選択状態の行を保持
                    this.setState({ selectedRow: record })
                  },
                  onDoubleClick: () =>
                    this.editForm('UpdateComment', record, true, true)
                }
              }}
            >
              <Table.Column
                title='コード'
                dataIndex='comment_code'
                width={100}
                render={(value) => (
                  <div style={{ textAlign: 'right' }}>{value}</div>
                )}
              />
              <Table.Column
                title='検索キー'
                dataIndex='search_key'
                width={100} />
              <Table.Column
                title='コメント内容'
                dataIndex='comment_content'
              />
              <Table.Column
                width={45}
                style={{ textAlign: 'center' }}
                title={
                  <Button
                    type='primary'
                    size='small'
                    icon={(<PlusOutlined />)}
                    onClick={() => {
                      // 新規追加の場合
                      this.editForm('RegisterComment', null, false, false)
                    }}
                  />
                }
                render={(item, record, index) => (
                  <Dropdown
                    trigger='click'
                    size='small'
                    overlay={() => {
                      return (
                        <Menu>
                          <Menu.Item
                            key='1' style={{ width: '100%' }}
                            onClick={() => {
                              this.editForm('UpdateComment', record, true, true)
                            }}
                          >
                            変更
                          </Menu.Item>

                          <Menu.Item
                            key='2' style={{ width: '100%' }}
                            onClick={() => {
                              this.editForm('CopyComment', record, true, false)
                            }}
                          >
                            複写
                          </Menu.Item>

                          <Menu.Item
                            key='3' style={{ width: '100%' }}
                            onClick={() => {
                              // 確認モーダルを表示してから、削除
                              Modal.confirm({
                                content: `削除を行いますか?`,
                                okText: 'は　い',
                                cancelText: 'いいえ',
                                onOk: () => {
                                  this.DeleteComment(record)
                                }
                              })
                            }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )
                    }}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )}
              />
            </Table>

            <Input.TextArea
              readOnly
              cols={5}
              value={this.state.selectedRow.comment_content ?? ''}
            />

          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2701001_CautionGuideMatterCmtMaintain)
