import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Button, Space, message } from "antd";
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import CopyAction from 'redux/InspectionMaintenance/RadiographyInspectMaintain/Copy.action'

class WS1871014_Copy extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "複写";

    this.state = {
      initialValues: {
        Li_CopySource: "",
        Li_CopySourceName: "",
        Copy: "",
        interpretation_exam_name: "",
      }
    };
  }

  componentDidMount() {
    this.getInitialValues()
  }
  componentDidUpdate(PropPev) {
    if (this.props !== PropPev) {
      this.getInitialValues()
    }
  }
  getInitialValues = () => {
    this.setState({
      initialValues: {
        Li_CopySource: this.props.Li_CopySource,
        Li_CopySourceName: this.props.Li_CopySourceName,
        Copy: this.props.Lo_Copy,
      }
    })
    this.formRef.current?.setFieldsValue({'Li_CopySource': this.props.Li_CopySource})
  }

  getNameCopy = (value) => {
    let params = {
      Copy: value
    }
    CopyAction.getNameCopy(params)
      .then(res => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            interpretation_exam_name: res.data.interpretation_exam_name ?? ""
          }
        })
      }).catch(error => {
        console.log('error->', error)
      })
  }

  execCopy() {
    let Li_CopySource = this.formRef.current?.getFieldValue('Li_CopySource')
    let Copy = this.formRef.current?.getFieldValue('Copy')
    if(Li_CopySource == Copy) {
      message.error('複写先が同一です')
      return
    }
    let params = {
      Li_CopySource: Li_CopySource,
      Copy: Copy
    }

    ModalConfirm({
      content: '部位・所見・指導事項を複写しますか',
      styleIconModal: { color: '#006CC9' },
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        CopyAction.execCopy(params)
          .then(res => {
            if(res.message) {
              message.info(res.message)
            }
            if(this.props.onFinishScreen) {
              this.props.onFinishScreen()
            }
          })
          .catch(error => {
            const res = error.response
            if (!res || !res.data || !res.data.message) {
              message.error('エラーが発生しました')
              return
            }
            message.error(res.data.message)
          })
      }
    })
  }

  render() {
    return (
      <div className="copy">
        <Card title="複写" className="mb-2">
          <Form ref={this.formRef}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: "space-between"
                }}>
                <Form.Item
                  name="Li_CopySource"
                  label="複写元読影コード"
                >
                  <Input type="text" readOnly/>
                </Form.Item>
                <Form.Item
                  style={{
                    marginRight: '70px',
                    marginLeft: '12px'
                  }}
                  name="Li_CopySourceName"
                  label=""
                >
                  <span>{this.state.initialValues.Li_CopySourceName}</span>
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: "space-between"
                }} >
                <Form.Item
                  name="Copy"
                  label="複写先読影コード"
                >
                  <Input type="text" onBlur={(e) => this.getNameCopy(e.target.value)} />
                </Form.Item>
                <Form.Item
                  style={{
                    marginRight: '70px',
                    marginLeft: '12px'
                  }}
                  name="interpretation_exam_name"
                  label=""
                >
                  <span>{this.state.initialValues.interpretation_exam_name}</span>
                </Form.Item>
              </div>
            </div>
            <Form.Item
              style={{
                textAlign: "end",
                marginTop: "20px"
              }}>
              <Button type="primary" onClick={() => this.execCopy()}>複写</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1871014_Copy);
