import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { DoubleRightOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Button, Table, InputNumber, Modal, message, Col, Row, TimePicker } from 'antd'
import WS2736001_MiraisElectronicMedicalRecordsSent from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2736001_MiraisElectronicMedicalRecordsSent'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub'
import WS0289012_ContractInfoInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry'
import { MoreOutlined } from '@ant-design/icons'
import WS2537500_PersonalReserveOption from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537500_PersonalReserveOption'
import moment from 'moment'
import FrameReserveService from 'services/ReservationBusiness/FrameReserve/FrameReserveService'
import WS2580001_ScheduleChange from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2580001_ScheduleChange'
import GetImage from 'constants/Images'
import NumberFormat from 'components/Commons/NumberFormat'
import ProvisionalReservationAction from 'redux/Others/ProvisionalReservation/ProvisionalReservation.action'
import ReservationStatus from 'pages/ZZ_Others/SANAI_FrameReserve/ReservationStatus'
import ParticularsScreen from 'pages/ZZ_Others/SANAI_FrameReserve/ParticularsScreen'
import { getAllOfficeInfoRetrievalQueryAction } from 'redux/basicInfo/OfficeInfoMaintain/OfficeInfoMaintain.action'

const minWidth = '500px'

const labelStyle = {
  labelCol: {
    style: {
      width: 50,
      textAlignLast: 'justify',
      textJustify: 'inter-ideograph'
    }
  },
  wrapperCol: {
    style: {
      marginLeft: '10px',
    }
  }
}
class FrameReserve extends React.Component {
  Li_ReserveDate
  static propTypes = {
    Li_ReserveDate: PropTypes.any,
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '仮予約'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      WakutoAtPage: [],
      isLoading: false,
      OfficeCode: '',
      BranchStoreCode: '',
      office_kanji_name: '',
      Course: '',
      contract_short_name: '',
      recordData: {},
      ContractType: '',
      ContractOrgCode: '',
      ContractStartDate: '',
      ContractNum: '',
      count: 0,
      discount: 0,
      totalAmount: 0,
      dataSourceInspectChange: [],
      discountNameList: [],
      columnList: [],
      Li_TimeDivision: 'AM',
      dataList: [],
      patternCode: '',
      dataSourceSelect: []
    }
  }

  componentDidMount = () => {
    FrameReserveService.getScreenDataService({
      Li_Years: this.props.Li_ReserveDate ? moment(this.props.Li_ReserveDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD'),
      Li_FacilityType: 1,
      Li_TimeDivision: 'AM',
      Li_SkyReal: 0,
      Li_StsAlreadySet: 0
    })
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          ...res.data,
          ReserveDisplayItems: '1',
          WakutoYearsChar: this.props.Li_ReserveDate ? moment(this.props.Li_ReserveDate) : moment(),
          user: this.props.user.name,
          // defaultValueだと変更しなかった場合取れないので、初期表示で値を入れる
          TimeZone1: moment('08:30', 'HH:mm')
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  getCourse() {
    let params = {
      Course: this.formRef.current?.getFieldValue('Course')
    }
    FrameReserveService.getCourseService(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          ReserveDisplayItems: res.data.toString(),
        })
        this.onChangeFilter()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onUpdateChangeWakuto = (indexUpdate) => {
    let reserve = this.formRef.current?.getFieldValue('Wakuto')[indexUpdate].person

    let Wakuto = this.formRef.current
      .getFieldValue('Wakuto')
      .map((item, index) => {
        if (indexUpdate === index) {
          if (reserve) {
            return { ...item, change: 'true' }
          } else {
            return { ...item, change: 'false' }
          }
        }
        return item
      })

    this.formRef.current.setFieldsValue({ Wakuto: Wakuto })

    this.forceUpdate()
  }

  onUpdateTitles = (indexUpdate, title) => {
    let Wakuto = this.formRef.current
      .getFieldValue('Wakuto')
      .map((item, index) => {
        if (indexUpdate === index) {
          return { ...item, title: title }
        }
        return item
      })

    this.formRef.current.setFieldsValue({ Wakuto: Wakuto })
    this.setState({ Wakuto: Wakuto })

    // this.forceUpdate()
  }

  onChangeStsFrameAlreadySet = () => {
    if (this.formRef.current.getFieldValue('StsFrameAlreadySet') === true) {
      this.setState({
        WakutoAtPage: this.formRef.current.getFieldValue('Wakuto'),
      })
      let isWakuto = this.formRef.current.getFieldValue('Wakuto')
        ? this.formRef.current.getFieldValue('Wakuto').filter((item) => {
          if (item.change === 'true') return item
        })
        : []
      this.formRef.current.setFieldsValue({ Wakuto: isWakuto })
    } else {
      this.formRef.current.setFieldsValue({ Wakuto: this.state.WakutoAtPage })
      this.setState({
        WakutoAtPage: [],
      })
    }
    this.forceUpdate()
  }

  getTableData(data, dataSourceInspectChange, changeFlag) {
    let params = {
      Lio_ConsultCourse: data.Lio_ConsultCourse,
      Lo_ContractType: data.Lo_ContractType,
      Lo_ContractNum: data.Lo_ContractNum,
      Lo_ContractStartDate: data.Lo_ContractStartDate,
      Lo_ContractDate: data.Lo_ContractStartDate,
      dataSourceInspectChange: dataSourceInspectChange,
      ContractClassifyCode: 0,
    }
    ProvisionalReservationAction.getCourseData(params)
      .then((res) => {
        let dataScreen = {
          ...this.state.dataScreen,
          ContractNum: data.Lo_ContractNum,
          ContractType: data.Lo_ContractType,
          ContractDate: data.Lo_ContractStartDate,
          ContractClassifyCode: data.Lo_ContractOrgCode,
          ConsultCourse: data.chkup_course,
        }
        this.formRef.current.setFieldsValue({
          ConsultCourse: data.Lio_ConsultCourse
        })
        if (!changeFlag) {
          this.setState({
            dataSourceInspectChange: res.result,
            dataList: res.dataList,
            columnList: res.columnList,
            patternCode: data.pattern_code,
            dataScreen: data
          })
        } else {
          this.setState({
            dataList: res.dataList,
            columnList: res.columnList,
            patternCode: data.pattern_code,
            dataScreen: dataScreen
          })
        }
        this.forceUpdate()
      })
  }

  checkTimeZone(value = '08:30') {
    var arr = value.split(':')
    if (parseInt(arr[0]) >= 12) {
      if (parseInt(arr[1]) > 0) {
        return 'PM'
      }
    }
    return 'AM'
  }

  onFinish = () => {
    console.log(this.formRef.current.getFieldValue('TimeZone1'))
    let Gender = this.formRef.current.getFieldValue('Gender')
    if (Gender === 0) {
      Gender = ''
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <ReservationStatus
            Lio_Date={moment(this.formRef.current.getFieldValue('WakutoYearsChar')).format('YYYY/MM/01')}
            Lio_CourSe={this.formRef.current.getFieldValue('ConsultCourse')}
            Lio_DataScreen={this.state.dataScreen}
            dataSourceInspectChange={this.state.dataSourceInspectChange}
            columnList={this.state.columnList}
            dataList={this.state.dataList}
            // Li_Am_Pm={this.checkTimeZone(this.formRef.current.getFieldValue('TimeZone'))}
            // TimeZone={this.formRef.current.getFieldValue('TimeZone')}
            Li_Am_Pm={this.checkTimeZone(this.formRef.current.getFieldValue('TimeZone1').format('HH:mm'))}
            TimeZone={this.formRef.current.getFieldValue('TimeZone1').format('HH:mm')}

            Gender={Gender}
            facility={this.formRef.current.getFieldValue('FacilityNum')}
            OfficeCode={this.formRef.current.getFieldValue('OfficeCode')}
            BranchStoreCode={this.formRef.current.getFieldValue('BranchStoreCode')}
            user={this.props.user.name}
            onFinishScreen={(data, DateFChar, DateTChar) => {
              this.closeModal()
              if (data.reserve_nums.length !== 0) {
                // Mirais電子カルテ送信
                // this.callParticularsScreen(data)
                // this.callMiraisElectronicMedicalRecordsSent(DateFChar, DateTChar, data)
              }
            }}
          />
        ),
      },
    })
  }

  callMiraisElectronicMedicalRecordsSent(dataF, dataT, data) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2736001_MiraisElectronicMedicalRecordsSent
            DateFChar={dataF}
            DateTChar={dataT}
            reserve_nums={data.reserve_nums}
            onFinishScreen={(output) => {
              this.closeModal()
              if (data.reserve_nums.length !== 0) {
                // 明細画面
                this.callParticularsScreen(data)
              }
            }}
          />
        ),
      },
    })
  }

  callParticularsScreen(data) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <ParticularsScreen
            OfficeCode={this.formRef.current.getFieldValue('OfficeCode')}
            BranchStoreCode={this.formRef.current.getFieldValue('BranchStoreCode')}
            reserve_nums={data.reserve_nums}
            dataScreen={this.state.dataScreen}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    })
  }

  showWS2537500_PersonalReserveOption = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2537500_PersonalReserveOption
            Li_TentativeReservation={true}
            Li_dataSourceSelect={this.state.dataSourceSelect}
            Li_dataSourceInspectChange={this.state.dataSourceInspectChange}
            Li_dataScreen={{
              'ContractType': this.state.dataScreen.Lo_ContractType,
              'ContractClassifyCode': this.state.dataScreen.Lo_ContractOrgCode,
              'ContractDate': this.state.dataScreen.Lo_ContractStartDate,
              'ContractNum': this.state.dataScreen.Lo_ContractNum,
              'ComboBox_AddedOrUnnecessary': [
                { LinkedField: 2, DisplayField: 'ｵﾌﾟｼｮﾝ' },
                { LinkedField: 0, DisplayField: '追加指定' },
                { LinkedField: 1, DisplayField: '不要指定' }
              ],
            }}
            Li_AddedOrUnnecessary={2}
            Li_Expression_140={'40'}
            Li_ConsultCourse={this.formRef.current?.getFieldValue('ConsultCourse')}
            Li_Date={moment(this.formRef.current.getFieldValue('WakutoYearsChar')).format('YYYY/MM/01')}
            Li_Gender={this.formRef.current.getFieldValue('Gender')}
            // Li_TimeZone={this.formRef.current.getFieldValue('TimeZone')}
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode') || 0}
            Li_FacilityNumHospital_Out={1}
            Li_NClassify={1}
            Li_StsOption={false}
            Li_ChangeClassify={0}
            // TimeZone={this.formRef.current.getFieldValue('TimeZone')}
            Li_PatternCode={this.state.patternCode}
            columnList={this.state.columnList}
            dataList={this.state.dataList}
            onFinishScreen={(output) => {
              if (output.dataSourceInspectChange) {
                let array = output.dataSourceInspectChange.map((value, index, array) => (value.totalPrice))
                let totalAmount = array.reduce((sum, element) => { return (sum + element) })
                totalAmount = NumberFormat.formatter(totalAmount)
                this.setState({ totalAmount: totalAmount })
              }
              this.setState({
                dataSourceInspectChange: output.dataSourceInspectChange
              })
              this.getTableData(this.state.dataScreen, output.dataSourceInspectChange, true)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 契約情報照会 モーダル
   * @param {*} save
   */
  showWS0289012_ContractInfoInquiry(save) {
    this.setState({
      nameScreen: 'WS0289012_ContractInfoInquiry',
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0289012_ContractInfoInquiry
            Li_State={0}
            Li_EffectiveLimitedDisplay={1}
            Lio_ConsultCourse={this.formRef.current.getFieldValue('ConsultCourse') ?? ''}
            Li_OfficeCode={this.formRef.current.getFieldValue('OfficeCode') ?? ''}
            Li_BranchStoreCode={this.formRef.current.getFieldValue('BranchStoreCode') ?? 0}
            Li_Date={moment(this.formRef.current.getFieldValue('WakutoYearsChar')).format('YYYY/MM/01')}
            Li_Gender={this.formRef.current.getFieldValue('Gender') ?? ''}
            Li_DateBirth={''}
            Li_Relationship={''}
            Li_HospitalOut={1}
            // Li_Am_Pm={this.checkTimeZone(this.formRef.current.getFieldValue('TimeZone'))}
            Li_NTsugikenmi={''}
            Li_Other={''}
            Lio_ContractType={0}
            Lio_ContractOrgCode={''}
            Lio_ContractStartDate={''}
            Lio_ContractNum={0}
            Lo_Status={0}
            Lo_ErrorMessage={''}
            onFinishScreen={(output) => {
              if (output.Lio_ConsultCourse) {
                let dataChange = [{
                  set_code: output.recordData.Lio_ConsultCourse,
                  changeType: 10
                }]
                this.getTableData(output.recordData, dataChange, false)
              } else {
                message.error('受診コースを入力してください')
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  editMemo = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS2580001_ScheduleChange
            Li_Date={record.date}
            onFinishScreen={(output) => {
              this.onChangeFilter()
              // this.onSearchRecreateDisplay(false, false, record.date)
              // this.closeModal()
            }}
          />
        ),
      },
      isCloseModalLoadReserveStatusDisplayAgain: false,
    })
  }

  getOfficeInfo = () => {
    let params = {
      KanaSearch: this.formRef.current?.getFieldValue('OfficeCode')
    }
    getAllOfficeInfoRetrievalQueryAction(params)
      .then((res) => {
        if (res.data.length == 1) {
          this.formRef.current?.setFieldsValue({
            OfficeCode: res.data[0].office_code,
            BranchStoreCode: res.data[0].branch_store_code > 0 ? res.data.branch_store_code : '',
            office_kanji_name: res.data[0].office_kanji_name,
          })
          this.setState({
            OfficeCode: res.data[0].office_code,
            BranchStoreCode: res.data[0].branch_store_code > 0 ? res.data.branch_store_code : '',
            office_kanji_name: res.data[0].office_kanji_name,
          })
        } else {
          Modal.error({
            title: '指定の事業所は存在しません',
            width: 330,
            onOk: () => {
              this.formRef.current?.getFieldInstance('OfficeCode').focus()
            }
          })
          this.formRef.current?.setFieldsValue({
            OfficeCode: '',
            BranchStoreCode: '',
            office_kanji_name: '',
          })
          this.setState({
            OfficeCode: '',
            BranchStoreCode: '',
            office_kanji_name: '',
          })
        }
      })
  }

  render() {
    return (
      <div className='frame-reserve'>
        <Card id='card' title='仮予約'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
            initialValues={{
              Gender: 0,
              SkyRealWakuto: 0,
              StsFrameAlreadySet: 0,
              FacilityNum: 1,
              TimeZone: '08:30',
            }}
          >
            <div className='box_container' id='container'>
              <div className='box_search'>
                <div className='box_search_inner_border' style={{ width: 'fit-content' }}>
                  <div className='box_inner_horizontal'>
                    <Form.Item
                      name='OfficeCode'
                      label='事業所'
                      {...labelStyle}
                    >
                      <Input.Search
                        allowClear={true}
                        className='input-search-size-allow-clear-4'
                        style={{ textAlign: 'right' }}
                        onPressEnter={() => {
                          this.getOfficeInfo()
                          // let params = {
                          //   KanaSearch: this.formRef.current?.getFieldValue('OfficeCode')
                          // }
                          // getAllOfficeInfoRetrievalQueryAction(params)
                          //   .then((res) => {
                          //     if (res) {
                          //       console.log(res.data)
                          //       this.formRef.current?.setFieldsValue({
                          //         OfficeCode: res.data.office_code,
                          //         BranchStoreCode: res.data.branch_store_code > 0 ? res.data.branch_store_code : '',
                          //         office_kanji_name: res.data.office_kanji_name,
                          //       })
                          //       this.setState({
                          //         OfficeCode: res.data.office_code,
                          //         BranchStoreCode: res.data.branch_store_code > 0 ? res.data.branch_store_code : '',
                          //         office_kanji_name: res.data.office_kanji_name,
                          //       })
                          //     }
                          //   })

                          // this.setState({
                          //   childModal: {
                          //     ...this.state.childModal,
                          //     visible: true,
                          //     width: '80%',
                          //     component: (<WS0247001_OfficeInfoRetrievalQuery
                          //       Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                          //       Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                          //       onFinishScreen={(output) => {
                          //         this.formRef.current?.setFieldsValue({
                          //           OfficeCode: output.Lio_OfficeCode,
                          //           BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                          //           office_kanji_name: output.Lo_Kanji_Name,
                          //         })
                          //         this.setState({
                          //           OfficeCode: output.Lio_OfficeCode,
                          //           BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                          //           office_kanji_name: output.Lo_Kanji_Name,
                          //         })
                          //         this.closeModal()
                          //       }}
                          //     />)
                          //   }
                          // })
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName === 'input') {
                            this.formRef.current?.setFieldsValue({
                              BranchStoreCode: '',
                              office_kanji_name: '',
                            })
                            this.setState({
                              OfficeCode: '',
                              BranchStoreCode: '',
                              office_kanji_name: '',
                            })
                          } else {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (<WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                  Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current?.setFieldsValue({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.setState({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.closeModal()
                                  }}
                                />)
                              }
                            })
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='BranchStoreCode' label=''>
                      {this.state.OfficeCode ?
                        <Input.Search
                          className='input-search-size-4'
                          type='number'
                          style={{ textAlign: 'right' }}
                          onSearch={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (<WS0247001_OfficeInfoRetrievalQuery
                                  Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                  Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
                                  onFinishScreen={(output) => {
                                    this.formRef.current?.setFieldsValue({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.setState({
                                      OfficeCode: output.Lio_OfficeCode,
                                      BranchStoreCode: output.Lio_BranchStoreCode > 0 ? output.Lio_BranchStoreCode : '',
                                      office_kanji_name: output.Lo_Kanji_Name,
                                    })
                                    this.closeModal()
                                  }}
                                />)
                              }
                            })
                          }}
                        />
                        :
                        <InputNumber
                          className='input-search-size-4'
                          readOnly
                        />
                      }
                    </Form.Item>
                    <Button
                      disabled={
                        this.formRef.current?.getFieldValue('OfficeCode')
                          ? this.formRef.current.getFieldValue('OfficeCode')
                            ? false
                            : true
                          : true
                      }
                      icon={<MoreOutlined />}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 700,
                            component: (
                              <WS2585001_OfficeInfoInquirySub
                                Li_OfficeCode={this.formRef.current?.getFieldValue(
                                  'OfficeCode'
                                )}
                                Li_BranchCode={this.formRef.current.getFieldValue(
                                  'BranchStoreCode'
                                )}
                                onFinishScreen={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  })
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    ></Button>

                    <Form.Item
                      name='office_kanji_name'
                      style={{ marginRight: '10px' }}
                    >
                      <div>{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                    </Form.Item>
                  </div>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name='WakutoYearsChar'
                        label='年月'
                        {...labelStyle}
                      >
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          picker='month'
                          format='YYYY年MM月'
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='TimeZone1'>
                        <TimePicker
                          format={'HH:mm'}
                          showNow={false}
                          placeholder={'時間帯を選択'}
                          // defaultValue={moment('08:30', 'HH:mm')}
                          minuteStep={30}></TimePicker>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <div className='box_search_inner' >
                  <Form.Item
                    name='FacilityNum'
                    label='施設'
                    {...labelStyle}
                    style={{ width: 'fit-content' }}
                  >
                    <Select style={{ width: '150px' }}>
                      {this.formRef.current
                        ? this.formRef.current.getFieldValue(
                          'SelectListFacilityNum'
                        )
                          ? this.formRef.current
                            .getFieldValue('SelectListFacilityNum')
                            .map((item, index) => {
                              return (
                                <Select.Option
                                  value={item.facility_type}
                                  key={index}
                                >
                                  {item.facility_name}
                                </Select.Option>
                              )
                            })
                          : null
                        : null
                      }
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name='Gender'
                    label='性別'
                    {...labelStyle}
                    style={{ width: 'fit-content' }}
                  >
                    <Select
                      style={{ width: '150px' }}
                    >
                      <Select.Option value={0}> </Select.Option>
                      <Select.Option value={1}>男性</Select.Option>
                      <Select.Option value={2}>女性</Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                {/* <div className='box_search_inner'>
                  <Form.Item
                    name='user'
                    label='担当'
                    {...labelStyle}
                    style={{ width: 'fit-content' }}
                  >
                    <Select style={{ width: '150px' }}>
                      {this.state.userList.map((item, index) => {
                        return (
                          <Select.Option
                            key={item.id}
                            value={item.name}
                          >
                            {item.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </div> */}
              </div>

              <div className='box_border'>
                <div className='box_inner_horizontal'>
                  <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0 }}  >
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='コース'
                        name='ConsultCourse'
                      >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-4'
                          disabled={!this.formRef.current?.getFieldValue('OfficeCode')}
                          onPressEnter={() => {
                            this.showWS0289012_ContractInfoInquiry()
                            this.setState({
                              changeConsultCourse: true
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {

                              this.formRef.current?.setFieldsValue({
                                dataSourceInspectChange: [],
                                columnList: [],
                                dataList: []
                              })
                              this.setState({
                                dataSourceInspectChange: [],
                                columnList: [],
                                dataList: []
                              })
                            } else {

                              this.showWS0289012_ContractInfoInquiry()
                              this.setState({
                                changeConsultCourse: true
                              })
                            }
                          }}

                          onFocus={() => {
                            this.setState({
                              changeConsultCourse: false
                            })
                          }}
                        />
                      </Form.Item>
                      <Button
                        disabled={!this.formRef.current?.getFieldValue('ConsultCourse')}
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '90%',
                              component: (
                                <WS0605127_ContractLineItemDisplay
                                  Li_ContractType={this.state.dataScreen.ContractType}
                                  Li_ContractOrgCode={this.state.dataScreen.ContractClassifyCode}
                                  Li_ContractStartDate={this.state.dataScreen.ContractDate}
                                  Li_ContractNum={this.state.dataScreen.ContractNum}
                                  onFinishScreen={(output) => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className='box_inner_horizontal' style={{ marginLeft: 'auto' }}>
                    <Button
                      type='primary'
                      onClick={() => this.showWS2537500_PersonalReserveOption()}
                      style={{ marginLeft: 'auto' }}
                      disabled={!this.formRef.current?.getFieldValue('ConsultCourse')}
                    >
                      オプション変更
                    </Button>
                  </div>
                </div>

                <Table
                  size='small'
                  //協会けんぽ調整用配列を結合
                  dataSource={this.state.dataSourceInspectChange}
                  rowKey={(record) => record.id}
                  bordered
                  scroll={{ x: 'max-content', y: 250 }}
                  pagination={this.state.dataSourceInspectChange.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  onRow={() => {
                    return {
                      onDoubleClick: () => { this.showWS2537500_PersonalReserveOption() }
                    }
                  }}
                >

                  <Table.Column
                    title=''
                    dataIndex='changeType'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return <div style={{ textAlign: 'center' }}>
                        {record.changeType ?
                          <img style={{ width: 16 }} src={GetImage(record.changeType)} alt='icon' />
                          : ''
                        }
                      </div>
                    }} />
                  <Table.Column
                    title='名称'
                    dataIndex='Expression_17'
                    className='column-size-40'
                  />
                  {
                    this.state.columnList.map((value, index) => {
                      return (
                        <Table.Column
                          title={() => {
                            return (
                              <div>{value.kanji_name.substr(0, 7)}</div>
                            )
                          }}
                          key={index}
                          width='140px'
                          render={(item, record, index) => {
                            let total_price = 0
                            //協会けんぽ調整用配列を結合
                            let neoDataList = [...this.state.dataList]
                            return (
                              <div
                                style={{ textAlign: 'right' }}
                              >
                                {neoDataList.map((element, index) => {
                                  if (element.set_code == record.set_code && element.serial_number == value.serial_number) {
                                    if (element.data_division == 30 || element.data_division == 60 || element.data_division == 90) {
                                      total_price = element.totalPrice * (-1)
                                    } else {
                                      total_price = element.totalPrice
                                    }
                                  }
                                })}
                                {total_price}
                              </div>
                            )
                          }}
                        />
                      )
                    })
                  }
                </Table>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                icon={<DoubleRightOutlined />}
                onClick={() => {
                  if (!this.state.OfficeCode) {
                    Modal.error({
                      title: 'Error',
                      content: `事業所を選択してください`,
                    })
                  } else if (!this.formRef.current?.getFieldValue('ConsultCourse')) {
                    Modal.error({
                      title: 'Error',
                      content: `コースを選択してください`,
                    })
                  } else {
                    this.onFinish()
                  }
                }}
              >
                <span className='btn_label'>
                  次へ
                </span>
              </Button>
            </div>
          </Form >

          <ModalDraggable
            width={this.state.childModal.width}
            visible={this.state.childModal.visible}
            component={this.state.childModal.component}
            onCancel={() => {
              this.closeModal()
            }}
          />
        </Card >
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrameReserve)
