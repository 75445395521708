import DataMigrationService from "services/DataMigration/DataMigrationService.js";
import { message } from "antd";

const DataMigrationAction = {
  executeMasterDataMigration(params) {
    return DataMigrationService.executeMasterDataMigration(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};

export default DataMigrationAction;