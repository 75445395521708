import axios from 'configs/axios';

const API_LIST = {
  outputCsvFile: '/api/master-list-output/attribute/personal/output-csv-file',
  personalInfoSearchBtn: '/api/master-list-output/attribute/personal/personal-info-search-btn',
  personalInfoDetail: '/api/master-list-output/attribute/personal/personal-info-detail',
  personalInfoOutputBtn: '/api/master-list-output/attribute/personal/personal-info-output-btn',
};

const MasterListOutputPersonalInfoService = {
  async outputCsvFile(params) {
    return axios.post(API_LIST.outputCsvFile, params, { responseType: 'blob' });
  },
  async personalInfoSearchBtn(params) {
    return axios.get(API_LIST.personalInfoSearchBtn, { params });
  },
  async personalInfoDetail(params) {
    return axios.get(API_LIST.personalInfoDetail, { params });
  },
  async personalInfoOutputBtn(params) {
    return axios.post(API_LIST.personalInfoOutputBtn, params, { responseType: 'arraybuffer' });
  },
};

export default MasterListOutputPersonalInfoService;