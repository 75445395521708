import axios from 'configs/axios';

const API_LIST = {
  getScreenData: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/get-screen-data',
  getLeftTable: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/get-left-table',
  getRightTable: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/get-right-table',
  getSubTable: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/get-sub-table',
  editSubTable: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/edit-sub-table',
  formatChange: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/format-change',
  sexDivisionChange: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/sex-division-change',
  addOrEdit: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/add-or-edit',
  delete: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/delete',
  getFormatLists: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/get-format-lists',
  addOrEditFormatName: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/add-or-edit-format-name',
  deleteFormat: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/delete-format',
  editFormat: '/api/complex-decision-master-maintenance/complex-decision-master-maintenance/edit-format',
};

const ComplexDecisionMasterMaintenanceService = {
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, {params});
  },

  async getLeftTable(params) {
    return axios.get(API_LIST.getLeftTable, {params});
  },

  async getRightTable(params) {
    return axios.get(API_LIST.getRightTable, {params});
  },

  async getSubTable(params) {
    return axios.get(API_LIST.getSubTable, {params});
  },

  async formatChange(params) {
    return axios.post(API_LIST.formatChange, params);
  },

  async sexDivisionChange(params) {
    return axios.post(API_LIST.sexDivisionChange, params);
  },

  async addOrEdit(params) {
    return axios.post(API_LIST.addOrEdit, params);
  },

  async delete(params) {
    return axios.delete(API_LIST.delete, {params});
  },

  async editSubTable(params) {
    return axios.post(API_LIST.editSubTable, params);
  },

  async getFormatLists(params) {
    return axios.get(API_LIST.getFormatLists, {params});
  },

  async addOrEditFormatName(params) {
    return axios.post(API_LIST.addOrEditFormatName, params);
  },

  async deleteFormat(params) {
    return axios.delete(API_LIST.deleteFormat, {params});
  },

  async editFormat(params) {
    return axios.post(API_LIST.editFormat, params);
  },
};

export default ComplexDecisionMasterMaintenanceService;