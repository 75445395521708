import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Table,
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  Space,
  message
} from "antd";
import { PlusOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import WS1169001_DetailInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1169001_DetailInfoCorrect.jsx";
import WS1170001_InspectInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1170001_InspectInfoCorrect.jsx";
import WS1172001_ItemAmountCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1172001_ItemAmountCorrect.jsx";
import WS0294001_UseInspectCheck from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS0294001_UseInspectCheck";

import WS1177001_GovernmentTubePrimaryAggregate from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1177001_GovernmentTubePrimaryAggregate";
import WS1178001_GovernmentTubeHepatitisAggregate from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1178001_GovernmentTubeHepatitisAggregate";
import WS1168005_CopyingProcess from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1168005_CopyingProcess";
import WS1180001_GovernmentTubeParam from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1180001_GovernmentTubeParam";
import WS1174003_CourseSelect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1174003_CourseSelect";
import AssociateInsureParamMaintainAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/AssociateInsureParamMaintain.actions";
class WS1168001_AssociateInsureParamMaintain extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06900:協会けんぽパラメータ保守';

    this.state = {
      isLoading: true,
      isLoadingTable: true,
      tableData: [],
      format: "",
      remarks: "",
      arrKeyCheck: [],
      count: "a",
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        defaultPageSize: 10,
        size: "small"
        // showQuickJumper: true
      }
    };
  }

  componentDidMount() {
    this.getInit();
  }

  getInit() {
    this.setState({ isLoading: true });

    AssociateInsureParamMaintainAction.getInit()
      .then(res => {
        this.setState({
          tableData: res ? res : [],
          remarks: res ? res[0].remarks : ""
        });
        this.getDataScreenInput(res[0].format);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  getDataScreenInput(value) {
    this.setState({ isLoadingTable: true, format: value });
    AssociateInsureParamMaintainAction.input({ format: value })
      .then(res => {
        this.formRef.current?.setFieldsValue({
          tableData: res
        });
        this.forceUpdate();
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  }

  getParamCreate() {
    let param = { format: this.state.format }
    AssociateInsureParamMaintainAction.getParamCreate(param)
      .then(res => {
        message.success(res);
      });
  }

  save() {
    let dataSave = [];
    console.log("run")
    if (this.state.arrKeyCheck.length > 0) {
      this.state.arrKeyCheck.map(val => {
        this.formRef.current?.getFieldValue("tableData").find(value => {
          if (value.id === val) {
            dataSave.push(value);
          }
        });
      });
      if (this.state.arrKeyCheck.length === dataSave.length) {
        AssociateInsureParamMaintainAction.save(dataSave).then(res => {
          message.success(res.message);
          this.getInit();
          this.setState({
            arrKeyCheck: []
          });
        });
      }
    }
  }

  ReturnComponent = component => {
    let components = {
      WS0294001_UseInspectCheck,
      WS1177001_GovernmentTubePrimaryAggregate,
      WS1178001_GovernmentTubeHepatitisAggregate,
      WS1168005_CopyingProcess,
      WS1180001_GovernmentTubeParam,
      WS1174003_CourseSelect
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal();
              if (nameScreen == "WS1168005_CopyingProcess") {
                this.getInit();
              }
            }}
          />
        )
      }
    });
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  renderMenuBar = () => (
    <Space>
      <Button
        type="primary"
        onClick={() => {
          this.callModal({}, "80%", "WS1177001_GovernmentTubePrimaryAggregate");
        }}
      >
        一般設定 (F7)
      </Button>
      <Button
        type="primary"
        onClick={() => this.callModal({}, "70%", "WS1178001_GovernmentTubeHepatitisAggregate")}
      >
        肝炎設定 (F8)
      </Button>
      <Button
        type="primary"
        onClick={() => {
          this.callModal(
            {
              Li_format: this.state.format,
              Li_remarks: this.state.remarks
            },
            "70%",
            "WS1168005_CopyingProcess"
          );
        }}
      >
        複写 (F9)
      </Button>
      <Button type="primary" onClick={() => this.callModal({}, "90%", "WS1174003_CourseSelect")}>
        コース設定 (F10)
      </Button>
      <Button
        type="primary"
        onClick={() => this.callModal({}, "90%", "WS1180001_GovernmentTubeParam")}
      >
        協会データ (F11)
      </Button>
      <Button type="primary" onClick={() => this.callModal({}, "70%", "WS0294001_UseInspectCheck")}>
        使用検査照会 (F12)
      </Button>
    </Space>
  );

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  AddNewData() {
    this.handleAdd();
  }

  handleAdd() {
    const { count } = this.state;
    const newData = {
      id: count, seq: 0, remarks: "", kind: "", format: this.state.format,
      attribute: "", number_of_digits: 0, position: 0, set_pattern: "", error_checking: ""
    };
    let data = [...this.formRef.current?.getFieldValue("tableData")];
    data.length > 0 ? data.unshift(newData) : data.push(newData)
    this.formRef.current?.setFieldsValue({
      tableData: data,
    });
    this.forceUpdate()
  }

  Save(record) {
    let arr = [...this.formRef.current?.getFieldValue("tableData")];
    for (let indx = 0; indx < arr.length; indx++) {
      if (arr[indx].id === record.id) {
        if (isNaN(record.id)) {
          let obj = { ...arr[indx] };
          obj.id = ""
          this.SaveData(obj)
          return
        } else {
          this.SaveData(arr[indx])
          return
        }
      }
    }
  }

  SaveData(record) {
    this.setState({ isLoading: true })
    AssociateInsureParamMaintainAction.save(record)
      .then((res) => {
        message.info(res.message)
        this.setState({ mainSource: res })
        this.getDataScreenInput(this.state.format)
      })
      .catch(error => {
        message.error(res.message);
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  Delete(record) {
    this.setState({ isLoading: true })
    AssociateInsureParamMaintainAction.delete(record)
      .then((res) => {
        message.info(res.message)
        this.setState({ mainSource: res })
        this.getDataScreenInput(this.state.format)
      })
      .catch(error => {
        message.error(res.message);
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    return (
      <div className="associate-insure-param-maintain">
        <Card title="V4-VNS06900:協会けんぽパラメータ保守">
          {this.renderMenuBar()}
          <hr></hr>
          <Form ref={this.formRef} onFinish={this.onFinish} autoComplete="off">
            <div style={{ display: "none" }}>
              <Form.Item name="StsInspect">
                <Input />
              </Form.Item>
              <Form.Item name="StsGuidance">
                <Input />
              </Form.Item>
            </div>
            <Row style={{ marginBottom: "1em" }}>
              <Col span={12}></Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Space>
                  <Button type="primary" onClick={() => this.getParamCreate()}>
                    ﾊﾟﾗﾒｰﾀ生成
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "80%",
                          component: (
                            <WS1169001_DetailInfoCorrect
                              Li_Format={this.state.format}
                              onFinishScreen={output => {
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }}
                  >
                    個人明細
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "80%",
                          component: (
                            <WS1170001_InspectInfoCorrect
                              Li_Format={this.state.format}
                              onFinishScreen={output => {
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }}
                  >
                    個人詳細
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "80%",
                          component: (
                            <WS1172001_ItemAmountCorrect
                              Li_Format={this.state.format}
                              onFinishScreen={output => {
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }}
                  >
                    個人金額
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Table
                  bordered
                  dataSource={this.state.tableData}
                  size="small"
                  pagination={false}
                  loading={this.state.isLoading}
                  rowKey={record => record.id}
                  scroll={{ y: 650 }}
                  style={{ width: "98%" }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.setState({
                          format: record.format,
                          remarks: record.remarks
                        });
                        this.getDataScreenInput(record.format);
                      }
                    };
                  }}
                >
                  <Table.Column
                    title="FORMAT"
                    dataIndex="format"
                    sorter={(a, b) => a.format.localeCompare(b.format, "ja")}
                    render={(value, record, index) => {
                      return (
                        <Form.Item style={{ marginBottom: "0px" }}>
                          <span>{value}</span>
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="備　　考"
                    dataIndex="remarks"
                    sorter={(a, b) => a.remarks.localeCompare(b.remarks, "ja")}
                    render={(value, record, index) => {
                      return (
                        <Form.Item style={{ marginBottom: "0px" }}>
                          <span>{value}</span>
                        </Form.Item>
                      );
                    }}
                  />
                </Table>
              </Col>
              <Col span={18}>
                <Table
                  bordered
                  dataSource={
                    this.formRef.current?.getFieldValue("tableData")
                      ? this.formRef.current?.getFieldValue("tableData")
                      : []
                  }
                  size="small"
                  pagination={this.state.pagination}
                  loading={this.state.isLoadingTable}
                  rowKey={record => record.id}
                  scroll={{ y: 650 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        const found = this.state.arrKeyCheck.indexOf(record.id);
                        if (found < 0) {
                          let data = [...this.state.arrKeyCheck];
                          data.push(record.id);
                          this.setState({
                            arrKeyCheck: data
                          });
                        }
                      }
                    };
                  }}
                >
                  <Table.Column
                    title="SEQ"
                    width={100}
                    dataIndex="seq"
                    sorter={(a, b) => a.seq - b.seq}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "seq"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input type="number" style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="備　考"
                    dataIndex="remarks"
                    sorter={(a, b) => a.remarks.localeCompare(b.remarks, "ja")}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "remarks"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="種　別"
                    dataIndex="kind"
                    sorter={(a, b) => a.kind.localeCompare(b.kind, "ja")}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "kind"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="属"
                    width={100}
                    dataIndex="attribute"
                    sorter={(a, b) => a.attribute.localeCompare(b.attribute, "ja")}
                    render={(value, record,) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "attribute"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="桁数"
                    width={100}
                    dataIndex="number_of_digits"
                    sorter={(a, b) => a.number_of_digits - b.number_of_digits}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "number_of_digits"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input type="number" style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="位置"
                    width={100}
                    dataIndex="position"
                    sorter={(a, b) => a.position - b.position}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "position"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input type="number" style={{ border: "none" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="設定"
                    width={100}
                    dataIndex="set_pattern"
                    sorter={(a, b) => a.set_pattern.localeCompare(b.set_pattern, "ja")}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "set_pattern"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="チェック"
                    dataIndex="error_checking"
                    sorter={(a, b) => a.error_checking.localeCompare(b.error_checking, "ja")}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "error_checking"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="オ　プ　シ　ョ　ン"
                    dataIndex="option_remark"
                    sorter={(a, b) => a.option_remark.localeCompare(b.option_remark, "ja")}
                    render={(value, record) => {
                      let index = this.findIndexByID(this.formRef.current?.getFieldValue("tableData"), record.id);
                      return (
                        <Form.Item
                          name={["tableData", index, "option_remark"]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input style={{ border: "0px" }} />
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column width={80} title={<Button size='small' type='primary' icon={<PlusOutlined />} onClick={() => this.AddNewData()}  ></Button>}
                    render={(text, record, index) => {
                      return <>
                        <Button size='small' style={{ border: 'none' }} icon={<SaveOutlined style={{ color: 'green' }} />}
                          onClick={() => this.Save(record)}
                        ></Button>
                        <Button size='small' style={{ border: 'none' }} danger icon={<DeleteOutlined />}
                          onClick={() => {
                            Modal.confirm({
                              content: '消去してもよろしいですか？',
                              okText: 'は　い',
                              cancelText: 'いいえ',
                              onOk: () => this.Delete(record)
                            })
                          }}
                        ></Button>
                      </>
                    }}
                  />
                </Table>
              </Col>
            </Row>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1168001_AssociateInsureParamMaintain);
