import React from 'react'
import { connect } from 'react-redux'

import { Button, Card, Form, Input, Table, Dropdown, Menu } from 'antd'
import { MoreOutlined, PlusOutlined, CheckOutlined, SearchOutlined } from '@ant-design/icons'
import InspectCmtInfoMaintainAction from 'redux/ExamCmtInfoMaintain/InspectCmtInfoMaintain.action'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2712001_InspectCmtInfoMaintainSub from 'pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/WS2712001_InspectCmtInfoMaintainSub'
import CopyRegister from 'pages/MS_InspectionMaintenance/V4MS0105000_ExamCmtInfoMaintain/CopyRegister'

class WS0358001_InspectCmtInfoMaintain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '検査コメント情報保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initValue: {
        SearchChar: '',
        ExamCode: ''
      },
      dataSource: [],
      isLoadingPage: false,
      colorText: 208,

      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    // 検査コメントデータを取得
    this.loadData()
  }

  /**
   * 検査コメントデータを取得
   */
  loadData = () => {
    let params = {
      SearchChar: this.formRef.current.getFieldValue('SearchChar'),
      ExamCode: this.formRef.current.getFieldValue('ItemCodeMedicalExam'),
    }
    this.setState({ isLoadingPage: true })
    InspectCmtInfoMaintainAction.index(params)
      .then(res => {
        if (res) {
          let data = res.data;
          this.setState({
            dataSource: data,
          })
        }
      })
  }

  handleChangeValue = (objChange) => {
    this.loadData()
  }

  getExamName = (params) => {
    InspectCmtInfoMaintainAction.getExamName(params)
      .then(res => {
        if (res) {
          this.formRef.current?.setFieldsValue({ 'exam_name': res.data.exam_name });
        }
      })
  }

  inspectChange(event) {
    let columnName = Object.keys(event)[0]
    let columnValue = Object.values(event)[0]
    if (columnName === 'ItemCodeMedicalExam' && columnValue === '') {
      this.formRef.current?.setFieldsValue({ 'exam_name': '' })
    }
  }

  editForm(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS2712001_InspectCmtInfoMaintainSub
            Lio_CmtClassify={record.comment_division}
            Lio_CmtCode={record.exam_comment_code}
            record={record}
            newFlag={false}
            StsEnable={record.StsEnable}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  copyRegister(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <CopyRegister
            record={record}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  addForm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 800,
        component: (
          <WS2712001_InspectCmtInfoMaintainSub
            Lio_CmtClassify={0}
            Lio_CmtCode={''}
            newFlag={true}
            StsEnable={true}
            onFinishScreen={() => {
              this.reviewScreen()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  reviewScreen() {
    this.loadData(this.state.initValue)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='use-inspect'>
        <Card title='検査コメント情報保守'>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            onValuesChange={(event) =>
              this.inspectChange(event)
            }
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <Form.Item
                    name='SearchChar'
                    label='検索'
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      maxLength={256}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          this.loadData();
                        }
                      }} />
                  </Form.Item>

                  <Form.Item
                    name='ItemCodeMedicalExam'
                    label='検査コード'
                    style={{ marginBottom: 0 }}
                  >
                    <Input.Search
                      allowClear
                      className='input-search-size-number-allow-clear-8'
                      maxLength={8}
                      style={{ textAlign: 'right' }}
                      onSearch={(value, event) => {
                        if (event.target.localName !== 'input') {
                          this.formRef.current.setFieldsValue({ exam_name: '' })
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 800,
                              component: (
                                <WS0271001_InspectItemSearchQuerySingle
                                  onFinishScreen={({
                                    recordData
                                  }) => {
                                    this.formRef.current.setFieldsValue({
                                      ItemCodeMedicalExam: recordData.test_item_code,
                                      exam_name: recordData.exam_name
                                    })
                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    })
                                  }}
                                />
                              ),
                            },
                          })
                        }
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          if (e.target.value) {
                            this.getExamName({ ExamCode: e.target.value });
                          }
                        }
                      }}
                    />
                  </Form.Item>
                </div>

                <div className='box_search_btn'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      this.loadData();
                    }}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>


              <Table bordered
                scroll={{ y: 400 }}
                dataSource={this.state.dataSource}
                loading={false}
                size='small'
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () =>
                    this.editForm(record)
                })}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
              >

                <Table.Column width={60} title='' align='center' dataIndex='StsEnable'
                  render={(value, record) => (
                    <div >{!value ? '' : <CheckOutlined />}</div>
                  )} />
                <Table.Column width={100} title='表示順' dataIndex='display_order' />
                <Table.Column width={100} title='コメント区分' dataIndex='comment_division' />
                <Table.Column width={150} title='コード' dataIndex='exam_comment_code' />
                <Table.Column width={150} title='コメント内容' dataIndex='exam_comment_screen' />
                <Table.Column title='出力内容' dataIndex='exam_comment_form' />
                <Table.Column width={200} title='オプション' dataIndex='exam_comment_options' />
                <Table.Column
                  width={40}
                  align='center'
                  title={
                    <Button
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={this.addForm}
                      size='small'
                    >
                    </Button>
                  }
                  render={(value, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='menu-1'
                              onClick={() => {
                                this.editForm(record)
                              }}
                            >
                              編集
                            </Menu.Item>
                            <Menu.Item
                              key='menu-2'
                              onClick={() => {
                                this.copyRegister(record)
                              }}
                            >
                              複写
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button size='small' icon={<MoreOutlined />} />
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0358001_InspectCmtInfoMaintain)
