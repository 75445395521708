import axios from "configs/axios";

const APP_LIST = {
  //ここ変更する
  saveXmlMedicalExamItemDataInput: "/api/xml-medical-exam-item-master/xml-medical-exam-item-master/save-xml-medical-exam-item-data-input",
  xmlMedicalExamItemDataInput: "/api/xml-medical-exam-item-master/xml-medical-exam-item-master/xml-medical-exam-item-data-input",
  deletexmlMedicalExamItemDataInput: "/api/xml-medical-exam-item-master/xml-medical-exam-item-master/delete-xml-medical-exam-item-data-input",
  getscreendata: "/api/xml-medical-exam-item-master/date-display_WS1313008/get-screen-data",
  dataRangeCheckIndex: "/api/xml-medical-exam-item-master/xml-medical-exam-item-master/data-range-check/index",
};

const XmlMedicalExamItemMasterSaveService = {
  //get　データを取得するだけのAPI　第二引数に{}がいる
  //post　DBのデータを書き換えるとき、frontから渡したいデータが大きいとき
  //delete　あまり使わない。削除だけの時
  //★routeと対応させないといけない。
  async saveXmlMedicalExamItemDataInput(params) {
    return axios.post(APP_LIST.saveXmlMedicalExamItemDataInput, params);
  },
  async xmlMedicalExamItemDataInput(params) {
    return axios.get(APP_LIST.xmlMedicalExamItemDataInput, { params });
  },
  async deletexmlMedicalExamItemDataInput(params) {
    return axios.delete(APP_LIST.deletexmlMedicalExamItemDataInput, { params });
  },
  async getscreendata(params) {
    return axios.get(APP_LIST.getscreendata, { params });
  },
  async dataRangeCheckIndex(params) {
    return axios.get(APP_LIST.dataRangeCheckIndex, { params });
  },
};

export default XmlMedicalExamItemMasterSaveService;
