import OfficeInfoCsvOutputService from 'services/StatisticalServices/OfficeInfoCsvOutput/OfficeInfoCsvOutputService.js';
import { message, } from "antd"

const OfficeInfoCsvOutputAction = {
  /**
   * csv出力
   */
  csvOutput(params) {
    return OfficeInfoCsvOutputService.csvOutput(params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
};
export default OfficeInfoCsvOutputAction;
