import { message } from 'antd'
import ProgressSetService from 'services/InputBusiness/ProgressSetting/ProgressSetService'

const ProgressSetAction = {
  index() {
    return ProgressSetService.index()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getProgressList(data) {
    return ProgressSetService.getProgressList(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  update(data) {
    return ProgressSetService.update(data)
      .then((res) => {
        message.success(res?.data.message)
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  csvOutput(data) {
    return ProgressSetService.csvOutput(data)
  },
}

export default ProgressSetAction
