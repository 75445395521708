import axios from "configs/axios";

const APP_LIST = {
  xmlMedicalExamItemDataInput: "/api/xml-medical-exam-item-master/xml-medical-exam-item-data-input",
  saveXmlMedicalExamItemDataInput: "/api/xml-medical-exam-item-master/save-xml-medical-exam-item-data-input"
};

const xmlMedicalExamItemMasterService = {
  async xmlMedicalExamItemDataInput(params) {
    return axios.get(APP_LIST.xmlMedicalExamItemDataInput, { params });
  },

  async saveXmlMedicalExamItemDataInput(params) {
    return axios.post(APP_LIST.saveXmlMedicalExamItemDataInput, params);
  }
};

export default xmlMedicalExamItemMasterService;
