import { MoreOutlined, MenuOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Space, Tabs, message, Tooltip, Dropdown, Menu } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx'
import WS1302001_AgencyInquirySub from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1302001_AgencyInquirySub.jsx'
import WS1303006_ScreenInput from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1303006_ScreenInput.jsx'
import WS1349045_ContractSelect from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1349045_ContractSelect.jsx'
import WS1350013_SettleInputSub from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1350013_SettleInputSub.jsx'
import WS1296100_ConsultTicketInputSubDetailedInspection from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296100_ConsultTicketInputSubDetailedInspection'
import WS1296200_ConsultTicketInputSubAdditionalMedicalExamination from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296200_ConsultTicketInputSubAdditionalMedicalExamination'
import WS1296300_ConsultTicketInputSubConcurrentMedicalExaminations from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296300_ConsultTicketInputSubConcurrentMedicalExaminations'
import WS1379001_SingleGroupClassifyInputSub from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1379001_SingleGroupClassifyInputSub'
import ConsultTicketInputSubAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/ConsultTicketInputSub.action'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

class WS1296010_ConsultTicketInputSub extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    Li_Tab: PropTypes.any,
    Lio_DocketNum: PropTypes.any,

    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)

    // document.title = '受診券入力SUB'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      disabledSpecificMedicalExambasicburdenC: true,
      disabledSpecificMedicalExamdetailsburde: true,
      disabledOtheraddburdenContent: true,
      disabledOtherdockburdenContent: true,
      disabledother_insurer_pay_max: true,
      data: {},
      activeTab: null
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  getScreenData() {
    let params = {
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_insurer_num: this.formRef.current?.getFieldValue('insurer_num')
    }
    ConsultTicketInputSubAction.getScreenData(params)
      .then(res => {
        if (res) {
          let date_japanese_calendar = res.data.date_japanese_calendar
          date_japanese_calendar = date_japanese_calendar.substr(0, 4)
          if (date_japanese_calendar > 1988) {
            // 値４
            res.data.DateBirthEraScreen = '平成'
          } else if (date_japanese_calendar > 1925) {
            // 値３
            res.data.DateBirthEraScreen = '昭和'
          } else if (date_japanese_calendar > 1911) {
            // 値２
            res.data.DateBirthEraScreen = '大正'
          } else if (date_japanese_calendar > 1867) {
            // 値１
            res.data.DateBirthEraScreen = '明治'
          }
          if (res.data.GenderScreen === '0') {
            res.data.GenderScreen = '1'
          }
          if (res.data.GenderScreen === 1) {
            res.data.GenderScreen = '男'
          } else if (res.data.GenderScreen === 2) {
            res.data.GenderScreen = '女'
          }
          if (res.data.BasicPartScreen === '3') {
            // 小数点第3位まで表示　四捨五入
            res.data.SpecificMedicalExambasicburdenContent = Math.round(res.data.SpecificMedicalExambasicburdenContent * 1000) / 1000
            res.data.SpecificMedicalExambasicburdenContent = res.data.SpecificMedicalExambasicburdenContent.toFixed(3)
          } else if (res.data.DetailsScreen === '3') {
            // 小数点第3位まで表示　四捨五入
            res.data.SpecificMedicalExamdetailsburdenContent = Math.round(res.data.SpecificMedicalExamdetailsburdenContent * 1000) / 1000
            res.data.SpecificMedicalExamdetailsburdenContent = res.data.SpecificMedicalExamdetailsburdenContent.toFixed(3)
          } else if (res.data.AddItemsScreen === '3') {
            // 小数点第3位まで表示　四捨五入
            res.data.OtheraddburdenContent = Math.round(res.data.OtheraddburdenContent * 1000) / 1000
            res.data.OtheraddburdenContent = res.data.OtheraddburdenContent.toFixed(3)
          } else if (res.data.CheckupScreen === '3') {
            // 小数点第3位まで表示　四捨五入
            res.data.OtherdockburdenContent = Math.round(res.data.OtherdockburdenContent * 1000) / 1000
            res.data.OtherdockburdenContent = res.data.OtherdockburdenContent.toFixed(3)
          }
          // res.data.date_japanese_calendar = moment(res.data.date_japanese_calendar).format('yMMDD')
          // 和暦変換
          let date = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'short' }).format(moment(res.data.date_japanese_calendar))
          // 月の数値のみ取得
          let year_japanese = new Date(res.data.date_japanese_calendar).getMonth() + 1
          // 日付のみ取得
          let date_japanese = new Date(res.data.date_japanese_calendar).getDate()
          // スラッシュで区切られているため、最初のスラッシュまでの文字を取る
          date = date.substring(0, date.indexOf('/'))
          // 数値以外削除
          date = date.replace(/[^0-9]/g, '')
          // 年月日をくっつける
          res.data.date_japanese_calendar = date + '年' + ('00' + year_japanese).slice(-2) + '月' + date_japanese + '日'
          let result
          let year = moment(res.data.visit_date_on).format('YYYYMMDD')
          // 年度計算
          var y = Number(year.substring(0, 4))
          var m = Number(year.substring(4, 6))
          var d = Number(year.substring(6, 8))
          var dt = new Date(y, m - 1, d)
          if (dt.getFullYear() == y &&
            dt.getMonth() == m - 1 &&
            dt.getDate() == d) {
            if (m < 4) {  //4月はじまり
              result = y - 1
            } else {
              result = y
            }
          }
          this.setState({ data: res.data })
          this.formRef.current.setFieldsValue({
            personal_number_id: res.data.personalbasic.personal_number_id,
            consult_ticket_docket_num: res.data.consult_ticket_docket_num,
            GrantDateChars: res.data.GrantDateChars,
            kana_name: res.data.kana_name,
            GenderScreen: res.data.GenderScreen,
            DateBirthEraScreen: res.data.DateBirthEraScreen,
            date_japanese_calendar: res.data.date_japanese_calendar,
            ExpirationDateChars: res.data.ExpirationDateChars,
            MedicalExamContentClassifyScreen: res.data.MedicalExamContentClassifyScreen,
            PresenceExamEmployerKenScreen: res.data.PresenceExamEmployerKenScreen,
            BasicPartScreen: res.data.BasicPartScreen,
            SpecificMedicalExambasicburdenContent: res.data.SpecificMedicalExambasicburdenContent,
            DetailsScreen: res.data.DetailsScreen,
            SpecificMedicalExamdetailsburdenContent: res.data.SpecificMedicalExamdetailsburdenContent,
            AddItemsScreen: res.data.AddItemsScreen,
            OtheraddburdenContent: res.data.OtheraddburdenContent,
            CheckupScreen: res.data.CheckupScreen,
            OtherdockburdenContent: res.data.OtherdockburdenContent,
            other_insurer_pay_max: res.data.other_insurer_pay_max,
            date_issuance: res.data.date_issuance,
            insurer_num: res.data.insurer_num,
            other_agency: res.data.other_agency,
            OthersScreen: res.data.OthersScreen,
            version: res.data.version,
            ContractScreen: res.data.ContractScreen,
            zenmamoruren_agency: res.data.zenmamoruren_agency,
            ZenmamorurenScreen: res.data.ZenmamorurenScreen,
            tuberculosis_agency: res.data.tuberculosis_agency,
            TuberculosisKyokaiScreen: res.data.TuberculosisKyokaiScreen,
            human_de_agency: res.data.human_de_agency,
            CheckupSocietyScreen: res.data.CheckupSocietyScreen,
            prevent_doctor_agency: res.data.prevent_doctor_agency,
            PreventiveMedicineBusinessScreen: res.data.PreventiveMedicineBusinessScreen,
            doctor_agency: res.data.doctor_agency,
            JapanTotalMedicalExamScreen: res.data.JapanTotalMedicalExamScreen,
            nha_agency: res.data.nha_agency,
            JapanHospitalKyokaiScreen: res.data.JapanHospitalKyokaiScreen,
            tokyo_agency: res.data.tokyo_agency,
            HigashifukyoScreen: res.data.HigashifukyoScreen,
            insurer_name: res.data.insurer_name,
            insurer_party_location: res.data.insurer_party_location,
            insurer_telephone_num: res.data.insurer_telephone_num,
            contract_compiled_sect: res.data.contract_compiled_sect,
            contract_compiled_name: res.data.contract_compiled_name,
            other_chkup_content: res.data.other_chkup_content,
            year: result,
            Expresstion_156: res.data.Expresstion_156,
            contract_number: res.data.contract_number,
            contract_name: res.data.contract_name,
            Expresstion_115: res.data.Expresstion_115,
            Expresstion_116: res.data.Expresstion_116,
          })
          this.forceUpdate()
        }
      })
  }

  componentDidMount() {
    this.setState({ activeTab: this.props.Li_Tab, })
    this.formRef.current.setFieldsValue({ insurer_num: this.props.Li_insurer_num })
    this.getScreenData()
  }

  activeChange(e) {
    this.setState({ activeTab: e })
  }

  onSave() {
    let getFieldValue = this.formRef.current?.getFieldValue()
    let ExpirationDateChars = moment(getFieldValue.ExpirationDateChars).format('YYYYMMDD')
    let date_issuance = moment(getFieldValue.date_issuance).format('YYYY/MM/DD')
    // 年月日の形になっているため数値以外削除
    let date_japanese_calendar = getFieldValue.date_japanese_calendar.replace(/[^0-9]/g, '')
    getFieldValue.date_japanese_calendar = date_japanese_calendar
    getFieldValue.ExpirationDateChars = ExpirationDateChars
    if (getFieldValue.GenderScreen === '男') {
      getFieldValue.GenderScreen = 1
    } else if (getFieldValue.GenderScreen === '女') {
      getFieldValue.GenderScreen = 2
    }
    if (getFieldValue.DateBirthEraScreen === '平成') {
      getFieldValue.DateBirthEraScreen = 4
    } else if (getFieldValue.DateBirthEraScreen === '昭和') {
      getFieldValue.DateBirthEraScreen = 3
    } else if (getFieldValue.DateBirthEraScreen === '大正') {
      getFieldValue.DateBirthEraScreen = 2
    } else if (getFieldValue.DateBirthEraScreen === '明治') {
      getFieldValue.DateBirthEraScreen = 1
    }
    let params = {
      ...getFieldValue,
      version: '',
      date_issuance: date_issuance,
      basic_burden_content: this.state.data.basic_burden_content,
      details_burden_content: this.state.data.details_burden_content,
      other_add_burden_content: this.state.data.other_add_burden_content,
      other_dock_burden_content: this.state.data.other_dock_burden_content,
      zenmamoruren_can_chkup: this.state.data.zenmamoruren_can_chkup,
      sect_1: this.state.data.sect_1,
      tuberculosis_can_chkup: this.state.data.tuberculosis_can_chkup,
      human_de_can_chkup: this.state.data.human_de_can_chkup,
      doctor_can_chkup: this.state.data.doctor_can_chkup,
      nha_can_chkup: this.state.data.nha_can_chkup,
      tokyo_can_chkup: this.state.data.tokyo_can_chkup,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum
    }
    ConsultTicketInputSubAction.onSave(params)
      .then(res => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  contractSelect(output) {
    let params = {
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      Li_insurer_num: this.formRef.current?.getFieldValue('insurer_num'),
      visit_date_on: this.state.data.visitbasic.visit_date_on,
      contract_serial_number: output.contract_number
    }

    ConsultTicketInputSubAction.contractSelect(params)
      .then(res => {
        this.getScreenData()
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      })
  }

  check(str) {
    var regex = new RegExp('.[0-9]+$')
    if (str.search(regex)) {
      return false
    } else {
      return true
    }
  }

  handleChage(value, getName, setName) {
    let getFieldValue = this.formRef.current?.getFieldValue(getName)
    let check = this.check(value)
    if (check) {
      if (value !== '') {
        if (getFieldValue === '3') {
          // 小数点第3位まで表示　四捨五入
          value = Math.round(value * 1000) / 1000
          value = value.toFixed(3)
          this.formRef.current?.setFieldsValue({
            [setName]: value
          })
        } else if (getFieldValue === '5') {
          // 小数点第3位まで表示　四捨五入
          value = Math.round(value * 1000) / 1000
          value = value.toFixed(3)
          this.formRef.current?.setFieldsValue({
            [setName]: value
          })
        }
      }
    }
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    })
  }

  renderButtons() {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: 0, gap: 10 }}>
        <Button type='primary'
          // disabled={this.props.Li_Expression_33 === '新規'}
          onClick={() => {
            this.setState({
              ...this.state,
              childModal: {
                width: 950,
                visible: true,
                component: (
                  <WS1303006_ScreenInput
                    Li_ReserveNum={this.props.Li_ReserveNum}
                    Li_CourseLevel={this.props.Li_CourseLevel}
                    Li_Division={0}
                    onFinishScreen={() => {
                      this.closeModal()
                    }}
                  />
                )
              }
            })
          }}
        >
          請求情報
        </Button>

        <Button type='primary'
          // disabled={this.props.Li_Expression_33 === '新規' || this.formRef.current?.getFieldValue('insurer_num') === 0}
          onClick={() => {
            this.setState({
              ...this.state,
              childModal: {
                width: 900,
                visible: true,
                component: (
                  <WS1349045_ContractSelect
                    Li_Year={this.formRef.current?.getFieldValue('year')}
                    Li_InsurerNum={this.formRef.current?.getFieldValue('insurer_num')}
                    onFinishScreen={(output) => {
                      this.contractSelect(output)
                      this.closeModal()
                    }}
                  />
                )
              }
            })
          }}
        >
          契約選択
        </Button>

        <Button type='primary'
          // disabled={this.props.Li_Expression_33 === '新規' || this.formRef.current?.getFieldValue('insurer_num') === 0}
          onClick={() => {
            this.setState({
              ...this.state,
              childModal: {
                width: 650,
                visible: true,
                component: (
                  <WS1379001_SingleGroupClassifyInputSub
                    // year={this.state.data.specificmedicalexamvisitticket.contract_year}
                    // serial_number={this.state.data.specificmedicalexamvisitticket.contract_serial_number}

                    year={this.formRef.current?.getFieldValue('year')}
                    serial_number={this.formRef.current?.getFieldValue('contract_number')}
                    Li_ReserveNum={this.props.Li_ReserveNum}
                    Li_CourseLevel={this.props.Li_CourseLevel}
                    onFinishScreen={(output) => {
                      this.getScreenData()
                      this.closeModal()
                    }}
                  />
                )
              }
            })
          }}
        >
          区分選択
        </Button>

        <Button
          type='primary'
          // disabled={this.props.Li_Expression_33 === '新規'}
          onClick={() => {
            this.setState({
              ...this.state,
              childModal: {
                width: 650,
                visible: true,
                component: (
                  <WS1350013_SettleInputSub
                    onFinishScreen={output => {
                      this.getScreenData()
                      this.closeModal()
                    }}
                    Li_CourseLevel={this.props.Li_CourseLevel}
                    Li_ReserveNum={this.props.Li_ReserveNum}
                  />
                )
              }
            })
          }}
        >
          決済入力
        </Button>
        {
          this.state.activeTab !== '1' ?
            <Button
              type='primary'
              onClick={() => this.onSave()}
            >
              更新
            </Button>
            :
            null
        }
      </div >
    )
  }

  render() {
    return (
      <div className='consult-ticket-input-sub'>
        <Card title='受診券入力' style={{ marginTop: 0 }}>
          <Form ref={this.formRef}>
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className='option_btn_right_top_modal'
                trigger='click'
                size='small'
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item
                        key='1' style={{ width: '100%' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 650,
                              component: (
                                <WS1296100_ConsultTicketInputSubDetailedInspection
                                  Li_CourseLevel={this.props.Li_CourseLevel}
                                  Li_ReserveNum={this.props.Li_ReserveNum}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                  }}
                                />
                              )
                            }
                          })
                        }}
                      >
                        詳細検査
                      </Menu.Item>

                      <Menu.Item
                        key='2' style={{ width: '100%' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 650,
                              component: (
                                <WS1296200_ConsultTicketInputSubAdditionalMedicalExamination
                                  Li_CourseLevel={this.props.Li_CourseLevel}
                                  Li_ReserveNum={this.props.Li_ReserveNum}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                  }}
                                />
                              )
                            }
                          })
                        }}
                      >
                        追加健診
                      </Menu.Item>

                      <Menu.Item
                        key='3' style={{ width: '100%' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 650,
                              component: (
                                <WS1296300_ConsultTicketInputSubConcurrentMedicalExaminations
                                  Li_CourseLevel={this.props.Li_CourseLevel}
                                  Li_ReserveNum={this.props.Li_ReserveNum}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                  }}
                                />
                              )
                            }
                          })
                        }}
                      >
                        同時実施健診
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className={'option_btn_right_top_modal'}
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <Tabs
              defaultActiveKey={this.props.Li_Tab}
              onChange={(e) => this.activeChange(e)}
              activeKey={this.state.activeTab}
            >
              <Tabs.TabPane tab='受診券' key='0'>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label='受診券番号'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space
                        style={{ marginBottom: '0' }}>
                        <Form.Item
                          name='consult_ticket_docket_num'
                          style={{ marginBottom: '0' }}
                        >
                          <Input type='text' />
                        </Form.Item>
                        <Form.Item
                          name='date_issuance'
                          label='交付年月日'
                          style={{ marginBottom: '0' }}
                        >
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <div className='box_border_bottom_no_padding' style={{ marginBottom: '10px', marginTop: '10px' }} />

                    <Form.Item
                      label='カナ氏名'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='kana_name'
                          style={{ marginBottom: 0 }}
                        >
                          <div>{this.state.data.kana_name}</div>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }}>
                          <Button icon={<MoreOutlined />}
                            size='small'
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  width: 850,
                                  visible: true,
                                  component: (
                                    <WS2584019_PersonalInfoInquirySub
                                      Li_PersonalNum={this.formRef.current?.getFieldValue('personal_number_id')}
                                      onFinishScreen={output => {
                                        this.closeModal()
                                      }}
                                    />
                                  )
                                }
                              })
                            }}
                          ></Button>
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      name='GenderScreen'
                      label='性別'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <div>{this.state.data.GenderScreen}</div>
                    </Form.Item>

                    <Form.Item
                      label='生年月日'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='DateBirthEraScreen'
                          style={{ marginBottom: '0' }}
                        >
                          <div>{this.state.data.DateBirthEraScreen}</div>
                        </Form.Item>
                        <Form.Item
                          name='date_japanese_calendar'
                          style={{ marginBottom: '0' }}
                        >
                          <div>{this.state.data.date_japanese_calendar}</div>
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      name='ExpirationDateChars'
                      label='有効期限'
                      {...grid}
                      rules={[
                        {
                          required: true,
                          message: '必須項目です'
                        }
                      ]}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>

                    <Form.Item
                      name='MedicalExamContentClassifyScreen'
                      label='健診内容'
                      {...grid}
                    >
                      <Select style={{ width: '170px' }}>
                        <Select.Option value={'1'}>特定健診診査</Select.Option>
                        <Select.Option value={'2'}>その他</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='PresenceExamEmployerKenScreen'
                      label='その他'
                      {...grid}
                    >
                      <Select style={{ width: '170px' }}>
                        <Select.Option value={'1'}>事業主健診を含む</Select.Option>
                        <Select.Option value={'2'}>被扶養者として受診</Select.Option>
                      </Select>
                    </Form.Item>

                    <div className='box_border_bottom_no_padding' style={{ marginBottom: '10px' }} />

                    <Form.Item
                      label='基本部分'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='BasicPartScreen'
                        >
                          <Select
                            style={{ width: '170px' }}
                            onChange={value => {
                              this.formRef.current?.setFieldsValue({
                                BasicPartScreen: value,
                                SpecificMedicalExambasicburdenContent: ''
                              })
                              this.forceUpdate()
                            }}
                          >
                            <Select.Option value={'0'}> </Select.Option>
                            <Select.Option value={'1'}>負担なし</Select.Option>
                            <Select.Option value={'2'}>定額負担</Select.Option>
                            <Select.Option value={'3'}>定率負担</Select.Option>
                            <Select.Option value={'4'}>保険者上限負担</Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name='SpecificMedicalExambasicburdenContent'
                        >
                          <Input
                            disabled={this.formRef.current?.getFieldValue('BasicPartScreen') === '1'}
                            onPressEnter={(e) => this.handleChage(e.target.value, 'BasicPartScreen', 'SpecificMedicalExambasicburdenContent')}
                            onBlur={(e) => this.handleChage(e.target.value, 'BasicPartScreen', 'SpecificMedicalExambasicburdenContent')}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      label='詳細部分'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='DetailsScreen'
                        >
                          <Select
                            style={{ width: '170px' }}
                            onChange={value => {
                              this.formRef.current?.setFieldsValue({
                                DetailsScreen: value,
                                SpecificMedicalExamdetailsburdenContent: ''
                              })
                              this.forceUpdate()
                            }}
                          >
                            <Select.Option value={'0'}> </Select.Option>
                            <Select.Option value={'1'}>負担なし</Select.Option>
                            <Select.Option value={'2'}>定額負担</Select.Option>
                            <Select.Option value={'3'}>定率負担</Select.Option>
                            <Select.Option value={'4'}>保険者上限負担</Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name='SpecificMedicalExamdetailsburdenContent'
                        >
                          <Input
                            disabled={this.formRef.current?.getFieldValue('DetailsScreen') === '1'}
                            onPressEnter={(e) => this.handleChage(e.target.value, 'DetailsScreen', 'SpecificMedicalExamdetailsburdenContent')}
                            onBlur={(e) => this.handleChage(e.target.value, 'DetailsScreen', 'SpecificMedicalExamdetailsburdenContent')}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      label='追加項目'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='AddItemsScreen'
                        >
                          <Select
                            style={{ width: '170px' }}
                            onChange={value => {
                              this.formRef.current?.setFieldsValue({
                                AddItemsScreen: value,
                                OtheraddburdenContent: ''
                              })
                              this.forceUpdate()
                            }}
                          >
                            <Select.Option value={'0'}> </Select.Option>
                            <Select.Option value={'1'}>負担なし</Select.Option>
                            <Select.Option value={'2'}>定額負担</Select.Option>
                            <Select.Option value={'3'}>定率負担</Select.Option>
                            <Select.Option value={'4'}>保険者上限負担</Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name='OtheraddburdenContent'
                        >
                          <Input
                            disabled={this.formRef.current?.getFieldValue('AddItemsScreen') === '1'}
                            onPressEnter={(e) => this.handleChage(e.target.value, 'AddItemsScreen', 'OtheraddburdenContent')}
                            onBlur={(e) => this.handleChage(e.target.value, 'AddItemsScreen', 'OtheraddburdenContent')}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <Form.Item
                      label='人間ドック'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='CheckupScreen'
                          style={{ marginBottom: '0' }}
                        >
                          <Select
                            style={{ width: '170px' }}
                            onChange={value => {
                              if (Number(value) < 3) {
                                this.formRef.current?.setFieldsValue({
                                  CheckupScreen: value,
                                  other_insurer_pay_maxFlag: false
                                })
                              } else {
                                this.formRef.current?.setFieldsValue({
                                  CheckupScreen: value,
                                  other_insurer_pay_maxFlag: true
                                })
                              }
                              this.forceUpdate()
                            }}
                          >
                            <Select.Option value={'0'}> </Select.Option>
                            <Select.Option value={'1'}>負担なし</Select.Option>
                            <Select.Option value={'2'}>定額負担</Select.Option>
                            <Select.Option value={'3'}>定率負担</Select.Option>
                            <Select.Option value={'4'}>保険者上限負担</Select.Option>
                            <Select.Option value={'5'}>定率負担(上限以下)</Select.Option>
                            <Select.Option value={'6'}>定額負担(上限以下)</Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name='OtherdockburdenContent'
                          style={{ marginBottom: '0' }}
                        >
                          <Input
                            disabled={this.formRef.current?.getFieldValue('CheckupScreen') === '1'}
                            onPressEnter={(e) => this.handleChage(e.target.value, 'CheckupScreen', 'OtherdockburdenContent')}
                            onBlur={(e) => this.handleChage(e.target.value, 'CheckupScreen', 'OtherdockburdenContent')}
                          />
                        </Form.Item>

                        <Form.Item
                          name='other_insurer_pay_max'
                          label='上限額'
                          style={{ marginBottom: '0' }}
                        >
                          <Input disabled={!this.formRef.current?.getFieldValue('other_insurer_pay_maxFlag')} />
                        </Form.Item>
                      </Space>
                    </Form.Item>

                    <div className='box_border_bottom_no_padding' style={{ marginBottom: '10px', marginTop: '10px' }} />

                    <Form.Item
                      label='保険者番号'
                      name='insurer_num'
                      {...grid}
                    >
                      <Input.Search
                        className='input-search-size-single-byte-10'
                        onSearch={() => {
                          this.setState({
                            ...this.state,
                            childModal: {
                              width: 700,
                              visible: true,
                              component: (
                                <WS1290001_InsurerNumberInquiry
                                  onFinishScreen={output => {
                                    this.formRef.current.setFieldsValue({
                                      insurer_num: output.Lo_InsurerNum
                                    })
                                    this.closeModal()
                                  }}
                                />
                              )
                            }
                          })
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label='代行機関'
                      style={{ marginBottom: '0' }}
                      {...grid}
                    >
                      <Space>
                        <Form.Item
                          name='other_agency'
                        >
                          <Input
                            type='text'
                            onDoubleClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  width: 500,
                                  visible: true,
                                  component: (
                                    <WS1302001_AgencyInquirySub
                                      onFinishScreen={output => {
                                        this.formRef.current.setFieldsValue({
                                          other_agency: output.Gl0AgencyNum
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  )
                                }
                              })
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          name='OthersScreen'
                          valuePropName='checked'
                        >
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>

              <Tabs.TabPane tab='契約情報' key='1'>
                <div className='box_inner_horizontal'>
                  <div className='div_label'>契約年度</div>
                  {this.formRef.current?.getFieldValue('Expresstion_156') !== '' ?
                    <div className='div_data' style={{ marginTop: 0 }}>
                      {this.formRef.current?.getFieldValue('Expresstion_156')}
                    </div>
                    :
                    <div className='div_data' style={{ marginTop: 0 }}>--</div>
                  }
                  <div className='div_label'>契約番号</div>
                  {this.formRef.current?.getFieldValue('contract_number') !== '' ?
                    <div className='div_data' style={{ marginTop: 0 }}>
                      {this.formRef.current?.getFieldValue('contract_number')}
                    </div>
                    :
                    <div className='div_data' style={{ marginTop: 0 }}>--</div>
                  }
                </div>
                <div className='box_inner_horizontal'>
                  <div className='div_label'>契約名称</div>
                  {this.formRef.current?.getFieldValue('contract_name') !== '' ?
                    <div className='div_data' style={{ marginTop: 0 }}>
                      {this.formRef.current?.getFieldValue('contract_name')}
                    </div>
                    :
                    <div className='div_data' style={{ marginTop: 0 }}>--</div>
                  }
                </div>
                <div className='box_inner_horizontal'>
                  <div className='div_label'>契約形態</div>
                  {this.formRef.current?.getFieldValue('Expresstion_115') !== '' ?
                    <div className='div_data' style={{ marginTop: 0 }}>
                      {this.formRef.current?.getFieldValue('Expresstion_115')}
                    </div>
                    :
                    <div className='div_data' style={{ marginTop: 0 }}>--</div>
                  }
                  <div className='div_label'>請求区分</div>
                  {this.formRef.current?.getFieldValue('Expresstion_116') !== '' ?
                    <div className='div_data' style={{ marginTop: 0 }}>
                      {this.formRef.current?.getFieldValue('Expresstion_116')}
                    </div>
                    :
                    <div className='div_data' style={{ marginTop: 0 }}>--</div>
                  }
                </div>
              </Tabs.TabPane>
            </Tabs>
            {this.renderButtons()}
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS1296010_ConsultTicketInputSub)
