import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Button, Select, Input, Modal, Tooltip, Dropdown, Menu } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import DataImportAction from 'redux/DataMigration/DataImport.action.js'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: 70 } }
}

class SiteFindingsGroupDetailEdit extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
    }
  }

  /**
   * コードと名称をリセット
   * @param {*} formName
   */
  resetForm = (code, name) => {
    this.formRef.current?.setFieldsValue({
      [code]: '',
      [name]: ''
    })
    this.forceUpdate()
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='site-findings-group-detail-setting' style={{ width: 'fit-content' }}>
        <Card title='部位所見グループ詳細設定' style={{ width: 500 }}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <Form.Item
                label='連番'
                name='seq'
                readOnly
                {...labelCol}
              >
                <Input
                  className='input-size-number-5'
                />
              </Form.Item>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='部位1'
                  name='site_code_1'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('site_code_1', 'site_name_1')
                      } else {
                        console.log('部位1検索')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('site_name_1')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='部位2'
                  name='site_code_2'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('site_code_2', 'site_name_2')
                      } else {
                        console.log('部位2検索')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('site_name_2')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='所見1'
                  name='findings_code_1'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('findings_code_1', 'findings_name_1')
                      } else {
                        console.log('所見1検索')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('findings_name_1')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='所見2'
                  name='findings_code_2'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('findings_code_2', 'findings_name_2')
                      } else {
                        console.log('所見2検索')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('findings_name_2')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='接尾語'
                  name='findings_code_3'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.resetForm('findings_code_3', 'findings_name_3')
                      } else {
                        console.log('所見3検索')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('findings_name_3')}</div>
              </div>

            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.record) ? '' : 'none' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    okButtonProps: {
                      danger: true,
                      icon: <DeleteOutlined />
                    },
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    cancelText: 'キャンセル',
                    onOk: () => {
                      console.log('削除')
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  console.log('保存')
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteFindingsGroupDetailEdit)