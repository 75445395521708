import axios from "configs/axios";

const API_LIST = {
  executeMasterDataMigration: "/api/data-migration/data-migration/execute-master-data-migration",
};

const DataMigrationService = {
  async executeMasterDataMigration(params) {
    return axios.post(API_LIST.executeMasterDataMigration, params);
  },
};

export default DataMigrationService;