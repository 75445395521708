import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Button, Input } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";

import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";


const labelCol = {
  labelCol: { style: { width: 80 } }
}

class WS1176002_InspectSetInfoEditModal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      exam_short_name: null
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      test_set_code: this.props.test_set_code ?? null,
      test_set_name: this.props.test_set_name ?? null,
      screen_display_order: this.props.screen_display_order ?? null,
      exam_item: this.props.exam_item ?? null,
      exam_short_name: this.props.exam_short_name ?? null,
      exam_name: this.props.exam_name ?? null,
      exam_type: this.props.exam_type ?? null,
    });

    this.setState({
      exam_short_name: this.props.exam_short_name ?? null,
    })
  };

  onSave = () => {
    this.props.onSave(this.formRef.current?.getFieldsValue())
  }

  onDelete = () => {
    this.props.onDelete(this.formRef.current?.getFieldsValue())
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  render() {
    return (
      <div className="inspect-set-info">
        <Card title={(this.props.setFlag ? 'セット' : '検査') + '情報' + (this.props.newFlag ? '新規' : '編集')}>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            {
              this.props.setFlag ?
                <div>
                  <Form.Item
                    name='test_set_code'
                    label='コード'
                    {...labelCol}
                  >
                    <Input
                      style={{ textAlign: 'right', width: 120 }}
                    />
                  </Form.Item>

                  <Form.Item
                    name='test_set_name'
                    label='セット名称'
                    {...labelCol}
                  >
                    <Input />
                  </Form.Item>
                </div>
                :
                <div>
                  <Form.Item
                    name='screen_display_order'
                    label='SEQ'
                    {...labelCol}
                  >
                    <Input
                      style={{ textAlign: 'right', width: 100 }}
                    />
                  </Form.Item>

                  <Form.Item
                    name='exam_item'
                    label='コード'
                    {...labelCol}
                  >
                    <Input.Search
                      className='input-search-size-number-10'
                      onSearch={(value, event) =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: "60%",
                            component: (
                              <WS0271001_InspectItemSearchQuerySingle
                                InspectCode={value}
                                onFinishScreen={(output) => {
                                  this.formRef.current?.setFieldsValue({
                                    exam_item: output.recordData.test_item_code ?? null,
                                    exam_short_name: output.recordData.exam_short_name ?? null,
                                    exam_name: output.recordData.exam_name ?? null,
                                    exam_type: output.recordData.exam_type ?? null,
                                  });

                                  this.closeModal();
                                }}
                              />
                            )
                          }
                        })
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name='exam_short_name'
                    label='略称名'
                    {...labelCol}
                  >
                    <div>
                      {this.formRef.current?.getFieldValue('exam_short_name')}
                    </div>
                  </Form.Item>

                  <Form.Item
                    name='exam_name'
                    label='検査名称'
                    {...labelCol}
                  >
                    <div>
                      {this.formRef.current?.getFieldValue('exam_name')}
                    </div>
                  </Form.Item>

                  <Form.Item
                    name='exam_type'
                    label='タイプ'
                    {...labelCol}
                  >
                    <div>
                      {this.formRef.current?.getFieldValue('exam_type')}
                    </div>
                  </Form.Item>
                </div>
            }
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='danger'
              icon={<DeleteOutlined />}
              onClick={() => {
                this.onDelete()
              }}
              disabled={this.state.disabledRegisterBtn}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                this.onSave()
              }}
              disabled={this.state.disabledRegisterBtn}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1176002_InspectSetInfoEditModal);
