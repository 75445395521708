import axios from "configs/axios";

const apiPaths = {
  getDataScreen: "/api/inspect-acquisition-update-customized-version/acquire-setting-sub",
  userParamMaintain: "/api/inspect-acquisition-update-customized-version/acquire-setting-sub/user-param-maintain",
  updateData: '/api/inspect-acquisition-update-customized-version/acquire-setting-sub/update-btn'
};

const AcquireSettingSubService = {
  async getDataScreen(params) {
    return axios.get(apiPaths.getDataScreen, { params });
  },

  async userParamMaintain(params) {
    return axios.get(apiPaths.userParamMaintain, { params });
  },

  async updateData(params) {
    return axios.post(apiPaths.updateData, params);
  }
};

export default AcquireSettingSubService;
