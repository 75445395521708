import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Table, Form, Input, Button, Row, Col, Space, message } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import WS1169001_DetailInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1169001_DetailInfoCorrect.jsx";
import WS1170001_InspectInfoCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1170001_InspectInfoCorrect.jsx";
import WS1172001_ItemAmountCorrect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1172001_ItemAmountCorrect.jsx";
import WS0294001_UseInspectCheck from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS0294001_UseInspectCheck";

import WS1177001_GovernmentTubePrimaryAggregate from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1177001_GovernmentTubePrimaryAggregate";
import WS1178001_GovernmentTubeHepatitisAggregate from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1178001_GovernmentTubeHepatitisAggregate";
import WS1168005_CopyingProcess from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1168005_CopyingProcess";
import WS1180001_GovernmentTubeParam from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1180001_GovernmentTubeParam";
import WS1174003_CourseSelect from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1174003_CourseSelect";
import AssociateInsureParamMaintainAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/AssociateInsureParamMaintain.actions";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"

const columns = [
  {
    title: 'SEQ',
    dataIndex: 'seq',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '備考',
    dataIndex: 'remarks',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '種別',
    dataIndex: 'kind',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '属',
    dataIndex: 'attribute',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '桁数',
    dataIndex: 'number_of_digits',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '位置',
    dataIndex: 'position',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '設定',
    dataIndex: 'set_pattern',
    // classNumber: '40',
    type: 1,
  },
  {
    title: 'チェック',
    dataIndex: 'error_checking',
    // classNumber: '40',
    type: 1,
  },
  {
    title: 'オプション',
    dataIndex: 'option_remark',
    // classNumber: '40',
    type: 1,
  }
]

class WS1168001_AssociateInsureParamMaintain extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06900:協会けんぽパラメータ保守';

    this.state = {
      isLoading: true,
      isLoadingTable: true,
      tableData: [],
      format: "",
      remarks: "",
      arrKeyCheck: [],
      count: "a",
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        defaultPageSize: 10,
        size: "small"
        // showQuickJumper: true
      }
    };
  }

  componentDidMount() {
    this.getInit();
  }

  getInit() {
    this.setState({ isLoading: true });

    AssociateInsureParamMaintainAction.getInit()
      .then(res => {
        this.setState({
          tableData: res ? res : [],
          remarks: res ? res[0].remarks : ""
        });
        this.getDataScreenInput(res[0].format);
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  getDataScreenInput(value) {
    this.setState({ isLoadingTable: true, format: value });
    AssociateInsureParamMaintainAction.input({ format: value })
      .then(res => {
        this.formRef.current?.setFieldsValue({
          tableData: res
        });
        this.forceUpdate();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  }

  getParamCreate() {
    let param = { format: this.state.format }
    AssociateInsureParamMaintainAction.getParamCreate(param)
      .then(res => {
        message.success(res);
      });
  }

  save() {
    let dataSave = [];
    console.log("run")
    if (this.state.arrKeyCheck.length > 0) {
      this.state.arrKeyCheck.map(val => {
        this.formRef.current?.getFieldValue("tableData").find(value => {
          if (value.id === val) {
            dataSave.push(value);
          }
        });
      });
      if (this.state.arrKeyCheck.length === dataSave.length) {
        AssociateInsureParamMaintainAction.save(dataSave).then(res => {
          message.success(res.message);
          this.getInit();
          this.setState({
            arrKeyCheck: []
          });
        });
      }
    }
  }

  ReturnComponent = component => {
    let components = {
      WS0294001_UseInspectCheck,
      WS1177001_GovernmentTubePrimaryAggregate,
      WS1178001_GovernmentTubeHepatitisAggregate,
      WS1168005_CopyingProcess,
      WS1180001_GovernmentTubeParam,
      WS1174003_CourseSelect
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal();
              if (nameScreen == "WS1168005_CopyingProcess") {
                this.getInit();
              }
            }}
          />
        )
      }
    });
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  renderMenuBar = () => (
    <Space>
      <Button
        type="primary"
        onClick={() => {
          this.callModal({}, "80%", "WS1177001_GovernmentTubePrimaryAggregate");
        }}
      >
        一般設定 (F7)
      </Button>
      <Button
        type="primary"
        onClick={() => this.callModal({}, "70%", "WS1178001_GovernmentTubeHepatitisAggregate")}
      >
        肝炎設定 (F8)
      </Button>
      <Button
        type="primary"
        onClick={() => {
          this.callModal(
            {
              Li_format: this.state.format,
              Li_remarks: this.state.remarks
            },
            "70%",
            "WS1168005_CopyingProcess"
          );
        }}
      >
        複写 (F9)
      </Button>
      <Button type="primary" onClick={() => this.callModal({}, "40%", "WS1174003_CourseSelect")}>
        コース設定 (F10)
      </Button>
      <Button
        type="primary"
        onClick={() => this.callModal({}, "90%", "WS1180001_GovernmentTubeParam")}
      >
        協会データ (F11)
      </Button>
      <Button type="primary" onClick={() => this.callModal({}, "70%", "WS0294001_UseInspectCheck")}>
        使用検査照会 (F12)
      </Button>
    </Space>
  );

  SaveData(record) {
    this.setState({ isLoading: true })
    AssociateInsureParamMaintainAction.save(record)
      .then((res) => {
        message.info(res.message)
        this.setState({ mainSource: res })
        this.getDataScreenInput(this.state.format)
      })
      .catch(error => {
        message.error(res.message);
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  Delete(record) {
    this.setState({ isLoading: true })
    AssociateInsureParamMaintainAction.delete(record)
      .then((res) => {
        message.info(res.message)
        this.setState({ mainSource: res })
        this.getDataScreenInput(this.state.format)
      })
      .catch(error => {
        message.error(error.message);
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    return (
      <div className="associate-insure-param-maintain">
        <Card title="V4-VNS06900:協会けんぽパラメータ保守">
          {this.renderMenuBar()}
          <hr></hr>
          <Form ref={this.formRef} onFinish={this.onFinish} autoComplete="off">
            <div style={{ display: "none" }}>
              <Form.Item name="StsInspect">
                <Input />
              </Form.Item>
              <Form.Item name="StsGuidance">
                <Input />
              </Form.Item>
            </div>
            <Row style={{ marginBottom: "1em" }}>
              <Col span={12}></Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Space>
                  <Button type="primary" onClick={() => this.getParamCreate()}>
                    ﾊﾟﾗﾒｰﾀ生成
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "80%",
                          component: (
                            <WS1169001_DetailInfoCorrect
                              Li_Format={this.state.format}
                              onFinishScreen={output => {
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }}
                  >
                    個人明細
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "80%",
                          component: (
                            <WS1170001_InspectInfoCorrect
                              Li_Format={this.state.format}
                              onFinishScreen={output => {
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }}
                  >
                    個人詳細
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: "80%",
                          component: (
                            <WS1172001_ItemAmountCorrect
                              Li_Format={this.state.format}
                              onFinishScreen={output => {
                                this.closeModal();
                              }}
                            />
                          )
                        }
                      });
                    }}
                  >
                    個人金額
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Table
                  bordered
                  dataSource={this.state.tableData}
                  size="small"
                  pagination={false}
                  loading={this.state.isLoading}
                  rowKey={record => record.id}
                  scroll={{ y: 650 }}
                  style={{ width: "98%" }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.setState({
                          format: record.format,
                          remarks: record.remarks
                        });
                        this.getDataScreenInput(record.format);
                      }
                    };
                  }}
                >
                  <Table.Column
                    title="FORMAT"
                    dataIndex="format"
                    sorter={(a, b) => a.format.localeCompare(b.format, "ja")}
                    render={(value, record, index) => {
                      return (
                        <Form.Item style={{ marginBottom: "0px" }}>
                          <span>{value}</span>
                        </Form.Item>
                      );
                    }}
                  />
                  <Table.Column
                    title="備　　考"
                    dataIndex="remarks"
                    sorter={(a, b) => a.remarks.localeCompare(b.remarks, "ja")}
                    render={(value, record, index) => {
                      return (
                        <Form.Item style={{ marginBottom: "0px" }}>
                          <span>{value}</span>
                        </Form.Item>
                      );
                    }}
                  />
                </Table>
              </Col>
              <Col span={18}>
                <Table
                  bordered
                  dataSource={
                    this.formRef.current?.getFieldValue("tableData")
                      ? this.formRef.current?.getFieldValue("tableData")
                      : []
                  }
                  size="small"
                  pagination={this.state.pagination}
                  loading={this.state.isLoadingTable}
                  rowKey={record => record.id}
                  scroll={{ y: 650 }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        const found = this.state.arrKeyCheck.indexOf(record.id);
                        if (found < 0) {
                          let data = [...this.state.arrKeyCheck];
                          data.push(record.id);
                          this.setState({
                            arrKeyCheck: data
                          });
                        }
                      }
                    };
                  }}
                >
                  <Table.Column
                    title="SEQ"
                    width={100}
                    dataIndex="seq"
                    sorter={(a, b) => a.seq - b.seq}
                    render={(value, record) => {
                      return (
                        <Col span={10} style={{ textAlign: "left" }}>
                          {record.seq}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="備　考"
                    dataIndex="remarks"
                    sorter={(a, b) => a.remarks.localeCompare(b.remarks, "ja")}
                    render={(value, record) => {
                      return (
                        <Col span={100} style={{ textAlign: "left" }}>
                          {record.remarks}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="種　別"
                    dataIndex="kind"
                    sorter={(a, b) => a.kind.localeCompare(b.kind, "ja")}
                    render={(value, record) => {
                      return (
                        <Col span={100} style={{ textAlign: "left" }}>
                          {record.kind}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="属"
                    width={100}
                    dataIndex="attribute"
                    sorter={(a, b) => a.attribute.localeCompare(b.attribute, "ja")}
                    render={(value, record,) => {
                      return (
                        <Col span={10} style={{ textAlign: "center" }}>
                          {record.attribute}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="桁数"
                    width={100}
                    dataIndex="number_of_digits"
                    sorter={(a, b) => a.number_of_digits - b.number_of_digits}
                    render={(value, record) => {
                      return (
                        <Col span={100} style={{ textAlign: "left" }}>
                          {record.number_of_digits}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="位置"
                    width={100}
                    dataIndex="position"
                    sorter={(a, b) => a.position - b.position}
                    render={(value, record) => {
                      return (
                        <Col span={100} style={{ textAlign: "left" }}>
                          {record.position}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="設定"
                    width={100}
                    dataIndex="set_pattern"
                    sorter={(a, b) => a.set_pattern.localeCompare(b.set_pattern, "ja")}
                    render={(value, record) => {
                      return (
                        <Col span={100} style={{ textAlign: "left" }}>
                          {record.set_pattern}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="チェック"
                    dataIndex="error_checking"
                    sorter={(a, b) => a.error_checking.localeCompare(b.error_checking, "ja")}
                    render={(value, record) => {
                      return (
                        <Col span={100} style={{ textAlign: "left" }}>
                          {record.error_checking}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column
                    title="オ　プ　シ　ョ　ン"
                    dataIndex="option_remark"
                    sorter={(a, b) => a.option_remark.localeCompare(b.option_remark, "ja")}
                    render={(value, record) => {
                      return (
                        <Col span={100} style={{ textAlign: "left" }}>
                          {record.option_remark}
                        </Col>
                      );
                    }}
                  />
                  <Table.Column width={80}
                    title={
                      <Button
                        size='small'
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <ModalAdd
                                  columns={columns}
                                  onFinishScreen={(output) => {
                                    const data = {
                                      ...output.itemChange,
                                      format: this.state.format
                                    }
                                    this.SaveData(data)
                                    this.closeModal()
                                  }}
                                />
                            },
                          });
                        }}
                      >
                      </Button>}
                    render={(text, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          // 編集、削除
                          const data = { id: record.id }
                          AssociateInsureParamMaintainAction.getScreenData(data)
                            .then(res => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '550px',
                                  className: '',
                                  component:
                                    <ModalChange
                                      columns={columns}
                                      record={res}
                                      onUpdate={(output) => {
                                        const data = output.itemChange
                                        this.SaveData(data)
                                        this.closeModal()
                                      }}
                                      onDelete={(output) => {
                                        const data = output.itemChange
                                        this.Delete(data)
                                        this.closeModal()
                                      }}
                                    />
                                },
                              })
                            })
                            .catch(error => {
                              console.log(error);

                              const res = error.response;
                              if (!res || !res.data || !res.data.message) {
                                message.error('エラーが発生しました');
                                return;
                              }

                              message.error(res.data.message);
                            })
                        }}
                      />
                    )}
                  />
                </Table>
              </Col>
            </Row>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1168001_AssociateInsureParamMaintain);
