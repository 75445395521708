import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card, Form, Input, message, Button, Table, Dropdown, Menu } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import IdConsultDateModifyAction from 'redux/CooperationRelated/OcrCaptureStartUp/IdConsultDateModify.action'
import { MoreOutlined, SearchOutlined } from '@ant-design/icons'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS2637001_OverallResultDisplayInput from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637001_OverallResultDisplayInput'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain'
import WS2624002_VisitsChangeConfirm from 'pages/ZZ_Others/DEVTOOL0200_CreateTestForMedicalExamInfo/WS2624002_VisitsChangeConfirm'
import WS3020036_CoupledPrintInstruction from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction'
import { times } from 'lodash'

const grid = {
  labelCol: { style: { width: 70 } },
}
class WS3108016_IdConsultDateModify extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'ID・受診日修正'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      isLoadingTable: false,
      dataSource: [],
      isLoadingForm: false,
      kanji_name: '',
      PersonalNumChar: '',
      DateChar: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  index() {
    const data = {
      person_num: this.props.Li_PersonalNum,
      consult_date: this.props.Li_Date,
      DateF: this.props.DateF,
      DateT: this.props.DateT
    }
    IdConsultDateModifyAction.getScreenDataAction(data)
      .then((res) => {
        if (res) {
          this.formRef.current.setFieldsValue({
            PersonalNumChar: res.PersonalNumChar,
            DateChar: res.DateChar,
            kanji_name: res.kanji_name
          })
        }

        this.dataConsultHistorySub()
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(err.response.data.message)
      })
  }

  dataConsultHistorySub() {
    const data = {
      person_num: this.formRef.current.getFieldValue('PersonalNumChar'),
      DateF: this.props.DateF,
      DateT: this.props.DateT
    }
    IdConsultDateModifyAction.dataConsultHistorySubAction(data)
      .then((res) => {
        if (res) {
          this.setState({
            dataSource: res ? res : []
          })
        }
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(err.response.data.message)
      })
  }

  onChangeInputPersonalNumChar(event) {
    this.setState({
      PersonalNumChar: event.target.value
    })
  }

  update(record) {
    const data = {
      person_num: record.personal_number_id,
      Old_PersonalNum: this.props.Old_PersonalNum,
      consult_date: record.visit_date_on,
      sy5100Id: this.props.Li_sy5100Id,
      processFormId: this.props.ME_FORMAT,
    }
    IdConsultDateModifyAction.updateAction(data)
      .then((res) => {
        let output = {
          person_num: record.personal_number_id,
          consult_date: record.visit_date_on,
        }

        this.props.onFinishScreen(output)
      })
      .catch((err) => message.error('エラー'))
  }

  /**
   * 受診照会 モーダル
   * @param {*} record
   */
  showWS2583001_ConsultInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS2583001_ConsultInquirySub') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 予約関連 モーダル
   * @param {*} record
   */
  showWS0650001_DocumentBatchCreateSub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0650001_DocumentBatchCreateSub
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            Li_OutputUnit={''}
            Li_OutputPattern={''}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS0650001_DocumentBatchCreateSub') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 結果照会 モーダル
   * @param {*} record
   */
  showWS2637001_OverallResultDisplayInput = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2637001_OverallResultDisplayInput
            Li_MenuOption={''}
            Li_MenuAdminRights={''}
            Li_MenuAuthority={''}
            Li_SubjectNotBeChangedMode={''}
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS2637001_OverallResultDisplayInput') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 結果印刷 モーダル
   * @param {*} record
   */
  showWS3020036_CoupledPrintInstruction = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS3020036_CoupledPrintInstruction') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 紹介状 モーダル
   * @param {*} record
   */
  showWS0898001_IntroduceLetterExtractMaintain = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0898001_IntroduceLetterExtractMaintain
            Li_MenuOption={''}
            Li_PersonalNum={record.id}
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS0898001_IntroduceLetterExtractMaintain') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 受付 モーダル
   * @param {*} record
   */
  showWS2624002_VisitsChangeConfirm = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2624002_VisitsChangeConfirm
            Li_CourseLevel={''}
            Li_ReserveNum={record.reservation_number}
            Li_AcceptOrCancel={''}
            Li_Date={record.visit_date_on}
            Li_AcceptNum={''}
            onFinishScreen={(outPut) => {
              if (outPut.nameScreen === 'WS2624002_VisitsChangeConfirm') {
                this.loadData(this.state.initParams)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 個人情報検索・照会 モーダル
   */
  showWS0248001_PersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component:
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            searchName={this.formRef.current?.getFieldValue('PersonalNumChar')}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                PersonalNumChar: output.recordData.personal_number_id,
                kanji_name: output.recordData.kanji_name
              })
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * メニュー一覧
   * @param {*} record
   * @returns
   */
  renderMenu = (record) => (
    <Menu>
      <Menu.Item
        key={'menu-1'}
        onClick={() => {
          // 受診照会 モーダル
          this.showWS2583001_ConsultInquirySub(record)
        }}
      >
        受診照会
      </Menu.Item>

      <Menu.Item
        key={'menu-2'}
        onClick={() => {
          // 予約関連 モーダル
          this.showWS0650001_DocumentBatchCreateSub(record)
        }}
      >
        予約関連
      </Menu.Item>

      <Menu.Item
        key={'menu-3'}
        hidden={record.Expression_7 === '予約'}
        onClick={() => {
          // 結果照会 モーダル
          this.showWS2637001_OverallResultDisplayInput(record)
        }}
      >
        結果照会
      </Menu.Item>

      <Menu.Item
        key={'menu-4'}
        hidden={record.Expression_7 === '予約'}
        onClick={() => {
          // 結果印刷 モーダル
          this.showWS3020036_CoupledPrintInstruction(record)
        }}
      >
        結果印刷
      </Menu.Item>

      <Menu.Item
        key={'menu-5'}
        hidden={record.Expression_7 === '予約'}
        onClick={() => {
          // 紹介状 モーダル
          this.showWS0898001_IntroduceLetterExtractMaintain(record)
        }}
      >
        紹介状
      </Menu.Item>

      <Menu.Item
        key={'menu-6'}
        hidden={record.Expression_7 === '受付'}
        onClick={() => {
          // 受付 モーダル
          this.showWS2624002_VisitsChangeConfirm(record)
        }}
      >
        受付
      </Menu.Item>
    </Menu>
  )

  /**
   * モーダル　閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='id-consult-date-modify'>
        <Card title='取込対象修正'>
          <div className='box_container'>
            <Form ref={this.formRef}>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_inner_vertical'>

                    <Form.Item
                      name='PersonalNumChar'
                      label='対象日付'
                      {...grid}
                    >
                      <div style={{ marginBottom: 1 }}>
                        {this.props.DateF + ' ~ ' + this.props.DateT}
                      </div>
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='PersonalNumChar'
                        label='個人番号'
                        {...grid}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-10'
                          type='text'
                          allowClear={true}
                          onSearch={(value, event) => {
                            if (value !== '' || event.target.localName !== 'input') {
                              // 個人情報検索・照会 モーダル
                              this.showWS0248001_PersonalInfoSearchQuery()
                            }
                          }}
                          onChange={(event) => {
                            if (event.target.value === '') {
                              // 漢字名を空に変更
                              this.formRef.current.setFieldsValue({
                                PersonalNumChar: event.target.value,
                                kanji_name: ''
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <div style={{ marginTop: 5 }}>
                        {this.formRef.current?.getFieldValue('kanji_name')}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.dataConsultHistorySub()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Form>


            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              rowKey={(res) => res.id}
              pagination={false}
              scroll={{ y: 500 }}
              onRow={(record, index) => ({
                onDoubleClick: (e) => {
                  this.update(record)
                },
              })}
            >
              <Table.Column
                title='受診日'
                dataIndex='visit_date_on'
                width={100}
              />

              <Table.Column
                title='状態'
                dataIndex='Expression_7'
                width={80}
                render={(value) => (
                  <div style={{
                    color: (value === '受付') ? 'red' : 'blue',
                    textAlign: 'center'
                  }}
                  >
                    {value}
                  </div>
                )}
              />

              <Table.Column
                title='契約情報'
                dataIndex='Expression_9'
              />

              <Table.Column
                width={45}
                render={(text, record, index) => (
                  <Dropdown
                    trigger='click'
                    overlay={() => this.renderMenu(record)}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )}
              />
            </Table>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS3108016_IdConsultDateModify)
