import axios from 'configs/axios';

const apiPaths = {
  index: '/api/associate-insure-param-maintain/government-tube-primary-aggregate/index',
  save: '/api/associate-insure-param-maintain/government-tube-primary-aggregate/save',
  delete: '/api/associate-insure-param-maintain/government-tube-primary-aggregate/delete',
};

const GovernmentTubePrimaryAggregateService = {

  async index() {
    return axios.get(apiPaths.index);
  },
  async save(params) {
    return axios.post(apiPaths.save, params);
  },
  async delete(params) {
    return axios.delete(apiPaths.delete, { params })
  }
};

export default GovernmentTubePrimaryAggregateService;