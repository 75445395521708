import React from "react";
import { connect } from "react-redux";
import { Card, Table, Form, Button, message } from "antd";
import WS1386001_InspectItemInquiry from "./WS1386001_InspectItemInquiry";
import ModalDraggable from "components/Commons/ModalDraggable";
import { PlusOutlined, MoreOutlined } from "@ant-design/icons";
import { getDataSupportItemAction, getSelectDataSupportItemAction, saveSupportItemAction, deleteSupportItemAction } from "redux/SpecificInsureMaintenance/SupportItem/SupportItemSub.actions";
import WS1396009_SupportItemSubModal from "./WS1396009_SupportItemSubModal"

const columns = [
  {
    title: '支援コード',
    dataIndex: 'support_code',
    classNumber: '10',
    type: 1,
  },
  {
    title: '検査コード',
    dataIndex: 'exam_code',
    classNumber: '10',
    type: 4,
    childModal: {
      width: '60%',
      component: WS1386001_InspectItemInquiry
    }
  },
  {
    title: '表示順',
    dataIndex: 'display_order',
    classNumber: '10',
    type: 1,
  }
]

class WS1396009_SupportItemSub extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    // document.title = '支援項目サブ';

    this.state = {
      isLoading: true,
      dataSource: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      count: 'a',
    };
  }

  componentDidMount() {
    this.getData(this.props.SupportCode);
  }

  getData(params) {
    const data = { SupportCode: params }
    this.setState({ isLoading: true });

    getDataSupportItemAction(data)
      .then(res => {
        if (res) {
          this.setState({ dataSource: res.data });
          this.formRef?.current?.setFieldsValue({ 'dataSource': res.data });
        }
      })
      .catch((err) => {
        const res = err.response;
        console.log(res)
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  saveData = async (record) => {
    console.log(record)
    this.setState({ isLoading: true });

    saveSupportItemAction(record)
      .then(res => {
        message.info('保存しました')
        this.closeModal()
        this.getData()
      })
      .catch((err) => {
        const res = err.response;
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData = async (record) => {
    deleteSupportItemAction({ id: record.id })
      .then(res => {
        message.info('削除しました')
        this.closeModal()
        this.getData()
      })
      .catch((err) => {
        const res = err.response;
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="support-item-sub">
        <Card className="mb-2" title="支援項目サブ">
          <Form ref={this.formRef}>
            <Table bordered
              dataSource={this.formRef.current?.getFieldValue("dataSource") ? this.formRef.current?.getFieldValue("dataSource") : []}
              loading={this.state.isLoading}
              rowKey={(record) => record.id}
            >
              <Table.Column title="コード" dataIndex="exam_code" showSorterTooltip={false} sorter={(a, b) => a.exam_code - b.exam_code} />
              <Table.Column title="検査名" dataIndex="formal_name" showSorterTooltip={false} sorter={(a, b) => a.formal_name.localeCompare(b.formal_name, 'ja')} />
              <Table.Column title="表示順" width={150} dataIndex="display_order" showSorterTooltip={false} sorter={(a, b) => a.display_order - b.display_order} />
              <Table.Column
                align="center"
                width={100}
                key="action"
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <WS1396009_SupportItemSubModal
                              columns={columns}
                              newFlag={true}
                              onUpdate={(output) => {
                                this.saveData(output)
                              }}
                            />
                        },
                      });
                    }}
                  />
                }
                render={(text, record) => (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      // 編集、削除
                      getSelectDataSupportItemAction({ id: record.id })
                        .then(res => {
                          const record = res.data
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <WS1396009_SupportItemSubModal
                                  columns={columns}
                                  newFlag={false}
                                  record={record.mainSource}
                                  onUpdate={(output) => {
                                    this.saveData(output)
                                  }}
                                  onDelete={(output) => {
                                    this.deleteData(output.itemChange)
                                  }}
                                />
                            },
                          })
                        })
                        .catch((err) => {
                          const res = err.response;
                          console.log(res)
                        })
                        .finally(() => this.setState({ isLoading: false }))
                    }}
                  />
                )}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1396009_SupportItemSub);
