import axios from "configs/axios";

const API_LIST = {
  index: "/api/reserves-related-files-output/reserves-related-files-output/index",
  output: "/api/reserves-related-files-output/reserves-related-files-output/output",
};

const ReservesRelatedFilesOutputService = {
  async index() {
    return axios.get(API_LIST.index);
  },

  async output(params) {
    return axios.post(API_LIST.output, params, { responseType: 'arraybuffer' });
  },
};

export default ReservesRelatedFilesOutputService;
