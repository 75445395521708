import axios from "configs/axios";

const API_LIST = {
  getImportSettings: "/api/data-migration/data-import/get-import-settings",
  saveImportSettings: "/api/data-migration/data-import/save-import-settings",
  importFileContents: "/api/data-migration/data-import/import-file-contents"
};

const DataImportService = {
  async getImportSettings(params) {
    return axios.get(API_LIST.getImportSettings, { params });
  },
  async saveImportSettings(params) {
    return axios.post(API_LIST.saveImportSettings, params);
  },
  async importFileContents(params) {
    return axios.post(API_LIST.importFileContents, params);
  }
};

export default DataImportService;