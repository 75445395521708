import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Button, Card, message, Upload, Dropdown, Menu, Checkbox } from 'antd'
import { DeleteOutlined, DownloadOutlined, UploadOutlined, MoreOutlined } from '@ant-design/icons'
import { captureFile } from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociationConsultQualifyResultCapture.action";
import AssociateInsureQualifyConfirmAction from 'redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociateInsureQualifyConfirm.action'

import WS2677012_CaptureResult from "pages/KY_AssociationHealthInsuranceReport/V4KY0030000_AssociateInsureQualifyConfirm/WS2677012_CaptureResult";
import Cookies from 'js-cookie'


class WS2677001_AssociationConsultQualifyResultCapture extends React.Component {

  formRef = React.createRef();

  static propTypes = {
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)

    // document.title = '資格結果取り込み'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      list: [],
      readList: [],
      selectList: [],
      listId: 0,
      contentList: [],
      FilterList: '',
      fileName: '',
      isLoadingData: false,
      defaultF12: true,
      isChecked: false, //R6改正対応チェックボックスON,OFF
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getCheckbox()
  }

  /*
  * //オプション設定側で協会情報提供サービス、令和6年改正対応の設定によってCheckboxが変化
  * 出力の方で使っていたのが流用できそうだったので使用
  */

  getCheckbox() {
    AssociateInsureQualifyConfirmAction.getScreenData(this.params)
      .then((res) => {
        if (res.isChecked === true) { //R06改正対応をONにしている場合
          this.setState({ isChecked: true })
        } else { //R06改正対応をOFFにしている場合
          this.setState({ isChecked: false, })
        }
      })
  }


  organizeUploadData = () => {
    //select:アップロード対象ファイル
    //read:読み取ったデータ一覧
    //upload:実際にアップロードするデータ
    let select = this.state.selectList;
    let read = this.state.readList;
    let upload = [];

    //readの中にあるファイル名がselectに存在し、まだuplodに入力していないものをpush
    for (var i = 0; i < read.length; i++) {
      if (select.find(({ name }) => name == read[i].name) &&
        !upload.find(({ name }) => name == read[i].name)) {
        upload.push(read[i])
      }
    }

    let params = { dataList: upload, isChecked: this.state.isChecked }

    this.captureAction(params)
  }

  handleChange = (event) => {
    const isChecked = event.target.checked;
    this.setState({ isChecked });
  };


  captureAction = (params) => {
    captureFile(params)
      .then(res => {
        let count = res.data.countList;
        let result = res.data.data;
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '40%',
            component: (
              <WS2677012_CaptureResult
                Li_warning={count.warning}
                Li_KekkaNumFiles={count.kekkaFileCount}
                Li_ErrorNumFiles={count.errorFileCount}
                Li_QualificationsThereNum={count.qualificationCount}
                Li_QualificationWithoutNum={count.unQualificationCount}
                Li_ErrorCount={count.errorCount}
                Li_ReserveNonGetNum={count.unacquiredReserveCount}

                onFinishScreen={(output) => {
                  this.props.onFinishScreen(result);
                }}
              />
            ),
          },
        });

      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className='issue-list-input-control'>
        <Card title='資格結果取り込み'>

          {/* アップロード */}
          <Upload
            headers={{
              'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
            }}
            directory
            withCredentials={true}

            //dataList:テキスト内容を含むデータ一覧
            //uploadList:アップロード対象のファイル一覧
            beforeUpload={() => false}
            onChange={(info) => {
              let files = info.file;
              //アップロードボタンを押した場合の処理
              if (files.status !== 'removed') {
                const reader = new FileReader();
                reader.readAsText(files, 'SJIS');
                reader.onload = (e) => {

                  let tempArry = [...this.state.readList]
                  let obj = new Object();
                  obj.id = this.state.listId;
                  obj.name = files.name;
                  obj.text = reader.result;

                  tempArry.push(obj)
                  this.setState({
                    readList: tempArry,
                    listId: obj.id + 1,
                    selectList: info.fileList
                  })

                };
                //削除（ゴミ箱）ボタンを押した場合の処理
              } else {
                this.setState({
                  selectList: info.fileList
                })
              }
            }}
          >
            <Button
              type='primary'
              icon={<UploadOutlined />} >
              アップロード
            </Button>
          </Upload>

          <div className='box_button_bottom_right'>
            <div style={{ marginBottom: '0' }}> {/* marginBottom: 0 を指定 */}
              <label style={{ marginRight: '10px' }}>令和6年改正対応</label> {/* ラベル */}
              <Checkbox
                checked={this.state.isChecked} // チェック状態をstateから取得
                onChange={this.handleChange} // チェックが変更されたときにhandleChangeを呼ぶ
                style={{ marginRight: '10px' }}
              />
            </div>

            <Button type="primary" style={{ float: "right" }}
              disabled={this.state.selectList.length == 0}
              onClick={() => this.organizeUploadData()}>
              取込
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2677001_AssociationConsultQualifyResultCapture);
