import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Table, message, Button, Form, Input, Select, Space, Modal, } from "antd";
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined, } from '@ant-design/icons';

import FacilityHospitalOutsideInfoMaintainAction from "redux/SM_SystemMaintenance/FacilityHospitalOutsideInfoMaintain/FacilityHospitalOutsideInfoMaintain.action";

class WS1500001_FacilityHospitalOutsideInfoMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '施設(院内/外)情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },

      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true });
    FacilityHospitalOutsideInfoMaintainAction.loadMainSource()
      .then(res => {
        this.formRef.current.setFieldsValue({
          MainSource: res.MainSource,
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  onFinish = (values) => {
    this.setState({ isLoading: true });
    FacilityHospitalOutsideInfoMaintainAction.save(values)
      .then(res => {
        message.success('保存完了');
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  deleteProc = (record) => {
    this.setState({ isLoading: true });
    FacilityHospitalOutsideInfoMaintainAction.delete({ id: record.id })
      .then(res => {
        message.success('削除完了');
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card title="施設(院内/外)情報保守">
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <Table bordered
              dataSource={this.formRef.current?.getFieldValue('MainSource')}
              loading={this.state.isLoading}
              pagination={false}
              rowKey={(record) => (record.id || Math.random())}
            >
              <Table.Column title="区分" dataIndex="facility_type"
                render={(value, record, index) => (
                  <>
                    <Form.Item name={['MainSource', index, 'id']} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['MainSource', index, 'facility_type']}>
                      <Input />
                    </Form.Item>
                  </>
                )}
              />
              <Table.Column title="施設名称" dataIndex="facility_name"
                /*sorter={(a, b) => ('' + a.facility_name).localeCompare(b.facility_name)}*/
                render={(value, record, index) => (
                  <Form.Item name={['MainSource', index, 'facility_name']}>
                    <Input />
                  </Form.Item>
                )}
              />
              <Table.Column title="院内/外区分" dataIndex="conditions_in_out_hospital"
                /*sorter={(a, b) => a.conditions_in_out_hospital - b.conditions_in_out_hospital}*/
                render={(value, record, index) => (
                  <Form.Item name={['MainSource', index, 'conditions_in_out_hospital']}>
                    <Select>
                      <Select.Option value={1}>院内</Select.Option>
                      <Select.Option value={2}>院外</Select.Option>
                    </Select>
                  </Form.Item>
                )}
              />
              <Table.Column
                key="action"
                title={(
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.formRef.current.setFieldsValue({
                        MainSource: [
                          ...this.formRef.current.getFieldValue('MainSource'),
                          {},
                        ]
                      });
                      this.forceUpdate();
                    }} />
                )}
                render={(value, record) => (
                  <Space>
                    <Button
                      size="small"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: "確認",
                          icon: (
                            <QuestionCircleOutlined
                              style={{ color: "#1890ff" }}
                            />
                          ),
                          content: "削除しますか",
                          okText: "削除",
                          cancelText: "キャンセル",
                          onOk: () => {
                            this.deleteProc(record)
                          },
                        });
                      }}
                    ></Button>
                  </Space>
                )}
              />
            </Table>

            <Form.Item
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
              <Button type="primary" htmlType="submit" loading={this.state.isLoading}>保存</Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          afterClose={this.state.childModal.afterClose}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS1500001_FacilityHospitalOutsideInfoMaintain;
