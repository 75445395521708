import axios from "configs/axios";

const APP_LIST = {
  dataRangeCheckInput: "/api/data-range-check/data-range-check/data-range-check-input",
  saveDataRangeCheckInput: "/api/data-range-check/data-range-check/save-data-range-check-input",
  deleteDataRangeCheckInput: "/api/data-range-check/data-range-check/delete-data-range-check-input",
};

const DataRangeCheckInputService = {
  async dataRangeCheckInput(params) {
    return axios.get(APP_LIST.dataRangeCheckInput, params);
  },

  async saveDataRangeCheckInput(params) {
    return axios.post(APP_LIST.saveDataRangeCheckInput, params);
  },

  async deleteDataRangeCheckInput(params) {
    return axios.delete(APP_LIST.deleteDataRangeCheckInput, { params });
  },

};

export default DataRangeCheckInputService;
