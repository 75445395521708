import axios from 'configs/axios';

const apiPaths = {
  getNameCopy: '/api/radio-graphy-inspect-maintain/copy/get-name-copy',
  execCopy: '/api/radio-graphy-inspect-maintain/copy/exec-copy',
};

const CopyService = {
  async getNameCopy(params) {
    return axios.get(apiPaths.getNameCopy, { params });
  },

  async execCopy(params) {
    return axios.post(apiPaths.execCopy, params);
  },
};

export default CopyService;