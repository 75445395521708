
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";

import React from "react";
import { connect } from "react-redux";


import { Card, Form, Input, Select, Button, Table, Row, Col, DatePicker, Modal, Dropdown, Menu, InputNumber, message, Space } from "antd";
import { SearchOutlined, MoreOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery";
import WS2583001_ConsultInquirySub from "pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub";
import WS0963001_InvoiceMaintain from "./WS0963001_InvoiceMaintain";
import WS0963001_ExportData from "./WS0963001_ExportData";
import WS2630001_OrganizationsPayment from "../V5BL0002000_OrganizationsPayment/WS2630001_OrganizationsPayment";
import WS2620001_Counter from "pages/UK_CounterBusiness/V5UK0001000_Counter/WS2620001_Counter";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import DepositWithdrawalInquiryAction from "redux/AccountingBusiness/DepositWithdrawalInquiry/DepositWithdrawalInquiry.action";

import moment from "moment";
import Color from "constants/Color";
import { number_format } from "helpers/CommonHelpers";

import ResultTblBatchCreateAction from "redux/ResultOutput/ResultsTblCollectOutput/ResultTblBatchCreate.action";
import PersonalReserveProcessAction from "redux/ReservationBusiness/PersonalReserveProcess/PersonalReserveProcess.action";
import axios from "axios";
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import DataGrid, { Column, ColumnChooser, ColumnFixing, Selection } from 'devextreme-react/data-grid';


class WS0977001_DepositWithdrawalInquiry extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "入出金問合せ";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      dataSource: [],
      isLoadingTable: true,
      stateIdentify: null,

      Code: '',
      Expression_50: ''
    };

    this.onFinish = this.onFinish.bind(this);
  }

  componentDidMount() {
    this.onSearch(this.formRef.current.getFieldValue());
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onSearch = (values) => {
    let data = {
      DepositWithdrawalPeriodAStart: values.DepositWithdrawalPeriodAStart?.format("YYYY/MM/DD"),
      DepositWithdrawalPeriodAFinal: values.DepositWithdrawalPeriodAFinal?.format("YYYY/MM/DD"),
      ReceiptAndPayClassify: values.ReceiptAndPayClassify,
      Target: values.Target,
      PayTotalAmount: values.PayTotalAmount,
      RegisterClassify: values.RegisterClassify,
      Identify: values.Identify,
      Code: values.Code,
    };

    this.setState({ isLoadingTable: true });
    DepositWithdrawalInquiryAction.getDataOnSearch(data).then((res) => {
      console.log("res->", res.QueryResultsDisplay)
      this.setState({
        dataSource: res ? res.QueryResultsDisplay : [],
        isLoadingTable: false
      });

      if (res) {
        this.formRef.current.setFieldsValue({
          PayTotalAmount: res?.Total?.PayTotalAmount === 0 ? '' : number_format(res?.Total?.PayTotalAmount),
          PayNum: res?.Total?.PayNum === 0 ? '' : res?.Total?.PayNum + '件',
          WithdrawalTotalAmount: res?.Total?.WithdrawalTotalAmount === 0 ? '' : number_format(res?.Total?.WithdrawalTotalAmount),
          WithdrawalNum: res?.Total?.WithdrawalNum === 0 ? '' : res?.Total?.WithdrawalNum + '件',
          FeesSum: res?.Total?.FeesSum === 0 ? '' : number_format(res?.Total?.FeesSum),
          CommissionNum: res?.Total?.CommissionNum === 0 ? '' : res?.Total?.CommissionNum + '件',
          InputAndAmountSum: res?.Total?.InputAndAmountSum === 0 ? '' : number_format(res?.Total?.InputAndAmountSum)
        })
      }
    });
  };

  onFinish(values) {
    this.onSearch(values);
  }

  gzoomCode() {
    let component = null
    switch (this.state.stateIdentify) {
      case 1:
      case 9:
        component = (<WS0248001_PersonalInfoSearchQuery
          date={moment(new Date()).format('YYYY/MM/DD')}
          onFinishScreen={(output) => {
            this.formRef.current.setFieldsValue({
              Code: output.Lo_PersonalNumId,
              Expression_50: output.recordData.kanji_name,
            });
            this.setState({
              Code: output.Lo_PersonalNumId,
              Expression_50: output.recordData.kanji_name,
            })
            this.closeModal();
          }}
        />)
        break;
      case 4:
        component = (<WS0246001_InsurerInfoSearchQuery
          onFinishScreen={(output) => {
            this.formRef.current.setFieldsValue({
              Code: output.Lo_InsurerCode,
              Expression_50: output.Lo_Name,
            });
            this.setState({
              Code: output.Lo_InsurerCode,
              Expression_50: output.Lo_Name,
            })
            this.closeModal();
          }}
        />)
        break;
      case 5:
        component = (<WS0247001_OfficeInfoRetrievalQuery
          onFinishScreen={(output) => {
            this.formRef.current.setFieldsValue({
              Code: output.Lio_OfficeCode,
              Expression_50: output.Lo_Kanji_Name,
            });
            this.setState({
              Code: output.Lio_OfficeCode,
              Expression_50: output.Lo_Kanji_Name,
            })
            this.closeModal();
          }}
        />)
        break;
      default:
        break;
    }

    return component
  }

  GetNameInsurerCode() {
    let params = {
      insurer_code: this.formRef.current?.getFieldValue('Code')
    }
    axios.get('/api/insurer-info-maintain/insurer-info-maintain', { params })
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          Expression_50: res?.data?.insurer_kanji_name
        })

        this.setState({
          Expression_50: res?.data?.insurer_kanji_name,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  getNameOffice() {
    let params = {
      GofficeCd: this.formRef.current?.getFieldValue('Code'),
    }
    ResultTblBatchCreateAction.getNameOffice(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          Expression_50: res?.data?.office_kanji_name
        })

        this.setState({
          Expression_50: res?.data?.office_kanji_name,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  getInfoPersonal() {
    let params = {
      PersonalNumId: this.formRef.current?.getFieldValue('Code'),
    }

    PersonalReserveProcessAction.getInfoPersonal(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          Expression_50: res?.data?.Expression_76
        })

        this.setState({
          Expression_50: res?.data?.Expression_76,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  getNameCode() {
    switch (this.state.stateIdentify) {
      case 1:
      case 9:
        this.getInfoPersonal()
        break;
      case 4:
        this.GetNameInsurerCode()
        break;
      case 5:
        this.getNameOffice()
        break;
      default:
        break;
    }
  }

  sortDate = (a, b) => {
    let dateA = new Date(a);
    let dateB = new Date(b);
    return isFinite(dateA - dateB)
      ? dateA - dateB
      : isFinite(dateA) ? 1 : -1
  };

  /**
   * テーブルのコンテキストメニュー押下時の処理
   * @param {Event} event クリックイベント
   * @param {Object} record 選択行のデータ
   */
  onClickContextMenu = (event, record) => {
    switch (event.key) {
      case '0': // 出力
        this.exportData();
        break;
      case '1': // 受診照会
        this.showConsultInquirySub(record);
        break;
      case '2': // 請求照会
        this.showInvoiceMaintain(record);
        break;
      case '3': // 団体入金
        this.showOrganizationsPayment();
        break;
      case '4': // 窓口入金
        this.showCounter();
        break;
    }
  }
  /**
   * 出力モーダルを開く
   */
  exportData = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        component: (
          <WS0963001_ExportData
            exportData={this.state.dataSource}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }
  /**
   * 受診照会モーダルを開く
   * @param {Object} value 選択行のデータ
   */
  showConsultInquirySub = (value) => {
    this.setState({
      ...this.state,
      childModal: {
        width: "fit-content",
        visible: true,
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={value.data.W1_reserve_num}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }
  /**
   * 請求照会モーダルを開く
   * @param {Object} value 選択行のデータ
   */
  showInvoiceMaintain = (value) => {
    this.setState({
      ...this.state,
      childModal: {
        width: "90%",
        visible: true,
        component: (
          <WS0963001_InvoiceMaintain
            Li_BillingManageNum={value.data.W1_billing_manage_num}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }
  /**
   * 団体入金モーダルを開く
   */
  showOrganizationsPayment = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: "90%",
        visible: true,
        component: (
          <WS2630001_OrganizationsPayment
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }
  /**
   * 窓口入金モーダルを開く
   */
  showCounter = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: "90%",
        visible: true,
        component: (
          <WS2620001_Counter
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        ),
      },
    });
  }

  render() {
    return (
      <div className="deposit-withdrawal-inquiry">
        <Card title="入出金問合せ" className="mb-2">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            // onValuesChange={(changedValues, allValues) => {
            //   this.onFinish(allValues)
            // }}
            initialValues={{
              DepositWithdrawalPeriodAStart: moment(new Date()),
              DepositWithdrawalPeriodAFinal: moment(new Date()),
              Target: 0,
              ReceiptAndPayClassify: 0
            }}
          >
            <div className='box_inner_vertical'>
              <Row>

                <div className='box_inner_horizontal'>
                  <div className='box_search'>
                    <div className='box_search_inner'>

                      <Form.Item label="入金日" style={{ marginBottom: 0, width: 500 }}>
                        <div className='box_inner_horizontal'>
                          <Form.Item name="DepositWithdrawalPeriodAStart">
                            <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} style={{ width: 60 }} />
                          </Form.Item>
                          <Form.Item>~</Form.Item>
                          <Form.Item name="DepositWithdrawalPeriodAFinal">
                            <VenusDatePickerCustom formRefDatePicker={this.formRef} allowClear={true} style={{ width: 60 }} />
                          </Form.Item>
                          <Form.Item name="RegisterClassify" label="レジ区分" style={{ width: 80 }}>
                            {/* <div className='box_inner_horizontal'> */}
                            <InputNumber min={0} maxLength={1} style={{ width: 30 }} />
                            {/* </div>y */}
                          </Form.Item>
                        </div>
                      </Form.Item>
                      <Form.Item label="入出金" style={{ margin: 0 }}>
                        <div className='box_inner_horizontal'>
                          <Form.Item name="ReceiptAndPayClassify" style={{ marginRight: 15 }}>
                            <Select style={{ width: 112 }}>
                              <Select.Option value={0}>全て</Select.Option>
                              <Select.Option value={1}>入金</Select.Option>
                              <Select.Option value={2}>出金</Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item label="対象">
                            <div className='box_inner_horizontal'>
                              <Form.Item name="Target">
                                <Select style={{ width: 78 }}>
                                  <Select.Option value={0}>有効</Select.Option>
                                  <Select.Option value={1}>無効</Select.Option>
                                  <Select.Option value={9}>全て</Select.Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Form.Item>
                        </div>
                      </Form.Item>
                      <Form.Item label="請求先">
                        <div className='box_inner_horizontal'>
                          <Row gutter={24}>
                            <Col span={24} style={{ display: "flex" }}>
                              <Form.Item name="Identify" style={{ marginRight: 3 }}>
                                <Select style={{ width: 112 }}
                                  onChange={(value) => {
                                    this.setState({ stateIdentify: value })
                                    this.formRef.current.setFieldsValue({
                                      Code: '',
                                      Expression_50: '',
                                    });
                                    this.setState({
                                      Code: '',
                                      Expression_50: '',
                                    })
                                  }}
                                >
                                  <Select.Option value={0}> </Select.Option>
                                  <Select.Option value={1}>個人</Select.Option>
                                  <Select.Option value={4}>保険者</Select.Option>
                                  <Select.Option value={5}>事業所</Select.Option>
                                  <Select.Option value={6}>他団体</Select.Option>
                                  <Select.Option value={9}>個人未収</Select.Option>
                                </Select>
                              </Form.Item>
                              <Form.Item name="Code" style={{ marginRight: 3 }}
                                hidden={
                                  this.state.stateIdentify === 0 ||
                                  this.state.stateIdentify === 6 ||
                                  !this.state.stateIdentify
                                }
                              >
                                <Input.Search style={{ width: 110, textAlign: "right" }}
                                  type="text" maxLength={10}
                                  // onDoubleClick={() => {
                                  onSearch={(value, event) => {
                                    if (event.target.localName !== 'input') {
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: true,
                                          width: "90%",
                                          component: this.gzoomCode(),
                                        },
                                      });
                                    }
                                  }}

                                  onBlur={(e) => {
                                    let val = e.target.value
                                    if (this.state.Code != val) {
                                      this.setState({
                                        Code: val,
                                      })
                                      if (val) {
                                        this.getNameCode()
                                      } else {
                                        this.formRef.current?.setFieldsValue({
                                          Expression_50: ''
                                        })

                                        this.setState({
                                          Expression_50: '',
                                        })
                                      }
                                    }
                                  }}
                                />
                              </Form.Item>
                              <Form.Item name="Expression_50">
                                <span>{this.state.Expression_50}</span>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Form.Item>

                    </div>
                  </div>
                  <div className='box_search_btn'>
                    <Button
                      icon={<SearchOutlined />}
                      htmlType="submit"
                    // type="primary"
                    // onClick={() => {
                    //   this.onFinish()
                    // }}
                    // style={{ float: "right" }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>

                  <div style={{ padding: 10, float: 'right', position: 'fixed', right: '10px', top: '103.14px' }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='入金額合計' style={{ marginBottom: 0 }}>

                        <div className='box_inner_horizontal'>
                          <Form.Item name="PayTotalAmount" style={{ paddingRight: "5px", width: 80 }} >

                            <Input readOnly style={{ textAlign: 'right', width: 80 }} />

                          </Form.Item>

                          <Form.Item name="PayNum" style={{ width: 80 }}>
                            <Input readOnly style={{ textAlign: 'right', width: 80 }} />
                          </Form.Item>
                        </div>

                      </Form.Item>
                    </div>
                    <Form.Item label='出金額合計' style={{ marginBottom: 0 }}>
                      <div className='box_inner_horizontal'>
                        <Form.Item name="WithdrawalTotalAmount"
                          style={{ paddingRight: "5px", width: 80 }}>
                          <Input readOnly style={{ textAlign: 'right', width: 80 }} />
                        </Form.Item>
                        <Form.Item name="WithdrawalNum" style={{ width: 80 }}>
                          <InputNumber readOnly style={{ textAlign: 'right', width: 80 }} />
                        </Form.Item>
                      </div>

                    </Form.Item>
                    <Form.Item label='手数料合計' style={{ marginBottom: 0 }}>
                      <div className='box_inner_horizontal'>
                        <Form.Item name="FeesSum" style={{ paddingRight: "5px", width: 80 }}>
                          <Input readOnly style={{ textAlign: 'right', width: 80 }} />
                        </Form.Item>
                        <Form.Item name="CommissionNum" style={{ width: 80 }}>
                          <InputNumber readOnly style={{ textAlign: 'right', width: 80 }} />
                        </Form.Item>
                      </div>

                    </Form.Item>
                    <Form.Item label='入出金差額' style={{ marginBottom: 0 }}>
                      <div className='box_inner_horizontal'>
                        <Form.Item name="InputAndAmountSum" style={{ paddingRight: "5px", width: 80 }}>
                          <Input readOnly style={{ textAlign: 'right', width: 80 }} />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </div>
                </div>

              </Row>

              <DataGrid
                style={{ height: '625px' }}
                dataSource={this.state.dataSource}
                showBorders={true} //周りの線
                showRowLines={true} //横線
                wordWrapEnabled={true} //折り返し
                allowColumnReordering={true} //カラム移動
                allowColumnResizing={true} //カラムの幅リサイズ
                columnResizingMode={'nextColumn'} // 'nextColumn' | 'widget' カラムサイズを変更する方法
                columnAutoWidth={true}
                selection={{ mode: 'single' }}
                hoverStateEnabled={true}
                Fill={false}
                paging={{
                  // ペジネーション設定
                  enabled: (this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE)
                }}

                scrolling={{ mode: 'standard' }} //スクロールがもっさりしないおまじない　無限スクロール対応 それでも設定の入れ方によっては重くなる
              >

                <Column
                  caption='区分'
                  cssClass='column-size-5'
                  dataField='Expression_5'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ color: Color(value.data.Expression_6)?.Foreground, textAlign: 'center' }} >
                        {value.value}
                      </div>
                    )
                  }} >
                </Column>


                <Column
                  caption='入金日'
                  cssClass='column-size-6'
                  dataField='W1_payment_day'
                  cellRender={(value, record, index) => {
                    console.log(value)
                    return (
                      <div style={{ textAlign: 'center' }}>{value.data.W1_payment_day === '0000-00-00' ? '' : moment(value.data.W1_payment_day).format('YYYY/MM/DD(ddd)')}</div>
                    )
                  }}
                >
                </Column>

                <Column
                  caption='入出金額'
                  cssClass='column-size-6'
                  dataField='Expression_15'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{
                        textAlign: 'right',
                        color: Color(value.data.Expression_6)?.Foreground
                      }} >
                        {value.data.Expression_15 === 0 ? '' : number_format(value.data.Expression_15)}
                      </div>
                    )
                  }}
                >
                </Column>

                <Column
                  caption='領収書番号'
                  cssClass='column-size-8'
                  dataField='W1_receipt_issue_num'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }} >
                        {value.data.W1_receipt_issue_num === 0 ? '' : value.data.W1_receipt_issue_num}
                      </div>
                    )
                  }}
                >
                </Column>

                <Column
                  caption='形態'
                  cssClass='column-size-5'
                  dataField='Expression_7'
                  style={{ textAlign: 'center' }}
                >
                </Column>

                <Column
                  caption='ｸﾚｼﾞｯﾄ'
                  cssClass='Expression_8'
                  dataField='column-size-5'                  >
                </Column>

                <Column
                  caption='受取額'
                  cssClass='column-size-5'
                  dataField='W1_amount_received' s
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }} >
                        {value.data.W1_amount_received === 0 ? '' : number_format(value.data.W1_amount_received)}
                      </div>
                    )
                  }}
                >
                </Column>


                <Column
                  caption='釣銭'
                  cssClass='column-size-5'
                  dataField='W1_change'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }} >
                        {value.data.W1_change === 0 ? '' : number_format(value.data.W1_change)}
                      </div>
                    )
                  }}
                >
                </Column>


                <Column
                  caption='手数料'
                  cssClass='column-size-5'
                  dataField='W1_transfer_fee'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }} >
                        {value.data.W1_transfer_fee === 0 ? '' : number_format(value.data.W1_transfer_fee)}
                      </div>
                    )
                  }}
                >
                </Column>


                <Column
                  caption='個人番号'
                  cssClass='column-size-6'
                  dataField='W1_person_num'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }} >
                        {value.data.W1_person_num === 0 ? '' : value.data.W1_person_num}
                      </div>
                    )
                  }}
                >
                </Column>

                <Column
                  caption='メモ'
                  cssClass='column-size-4'
                  style={{ textAlign: 'center' }}
                  cellRender={(value, record) => {
                    let icon = ''
                    switch (value.data.Expression_14) {
                      case 1:
                        icon = (<InfoCircleOutlined style={{ fontSize: 20, color: "#1890ff" }} />)
                        break;
                      case 3:
                        icon = (<WarningOutlined style={{ fontSize: 20, color: "#faad14" }} />);
                        break;
                      case 5:
                        icon = (<CloseCircleOutlined style={{ fontSize: 20, color: "#ff4d4f" }} />);
                        break;
                      default:
                        icon = (<Button size='small' icon={<MoreOutlined />}></Button>)
                        break;
                    }
                    return (
                      <div style={{ cursor: 'pointer' }}
                        onClick={() => {
                          // let title = '個人情報照会' + ' [' + record.W1_person_num + ']'
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '90%',
                              component: (
                                // <Card title={title}>
                                <WS2584019_PersonalInfoInquirySub
                                  Li_PersonalNum={value.data.W1_person_num}
                                  onFinishScreen={() => {
                                    this.closeModal();
                                  }}
                                />
                                // </Card>
                              ),
                            },
                          });
                        }} >{icon}
                      </div>
                    );
                  }}
                >
                </Column>


                <Column
                  caption='宛名'
                  cssClass='column-size-20'
                  dataField='W1_payment_comments'
                >
                </Column>

                <Column
                  caption='受診日'
                  cssClass='column-size-8'
                  dataField='visit_date_on'
                  cellRender={(value, record, index) => {
                    return (
                      <div>{value.data.visit_date_on === '0000-00-00' ? '' : value.data.visit_date_on.replace(/-/g, '/')}</div>
                    )
                  }}
                >
                </Column>

                <Column
                  caption='ｺｰｽ'
                  cssClass='column-size-4'
                  dataField='visit_course'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'center' }}>{value.data.visit_course?.toString().substr(0, 1) + '-' + value.data.visit_course?.toString().substr(1, 2)}</div>
                    )
                  }}
                >
                </Column>

                <Column
                  caption='事業所'
                  cssClass='column-size-60'
                  dataField='office_kanji_name'
                >
                </Column>

                <Column
                  caption='請求管理番号'
                  cssClass='column-size-10'
                  dataField='W1_billing_manage_num'
                  cellrender={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'right' }} >
                        {value.data.W1_billing_manage_num === 0 ? '' : value.data.W1_billing_manage_num}
                      </div>
                    )
                  }}
                >
                </Column>

                <Column
                  caption='有効'
                  cssClass='column-size-4'
                  dataField='Expression_10'
                  cellRender={(value, record, index) => {
                    return (
                      <div style={{ color: Color(value.data.Expression_9)?.Foreground, textAlign: 'center' }} >
                        {value.value}
                      </div>
                    )
                  }}
                >
                </Column>

                <Column
                  cssClass='column-size-2'
                  fixed={true}
                  fixedPosition='right'
                  cellRender={(value) => (

                    <div style={{ textAlign: 'center' }}>
                      <Dropdown
                        overlay={() => (
                          <Menu
                            onClick={(e) => this.onClickContextMenu(e, value)}>
                            {/* <Menu.Item
                            key="0">
                            出力
                          </Menu.Item> */}
                            <Menu.Item
                              hidden={!value.data.W1_reserve_num}
                              key="1">
                              受診照会
                            </Menu.Item>
                            {/* <Menu.Item
                              hidden={!value.data.W1_billing_manage_num || value.data.W1_identify < 4 || value.data.W1_identify > 9}
                              key="2">
                              請求照会
                            </Menu.Item>
                            <Menu.Item
                              hidden={!value.data.W1_billing_manage_num || value.data.W1_identify < 4 || value.data.W1_identify > 9}
                              key="3">
                              団体入金
                            </Menu.Item> */}
                            {/* <Menu.Item
                            hidden={!record.W1_billing_manage_num || record.W1_identify < 1 || record.W1_identify > 3}
                            key="4">
                            窓口入金
                          </Menu.Item> */}

                          </Menu>
                        )}
                        placement="bottomRight"
                        trigger={['click']}
                        arrow>
                        <Button icon={<MoreOutlined />} size="small" />
                      </Dropdown>
                    </div>
                  )}
                >
                </Column>
              </DataGrid>


            </div>

          </Form >
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0977001_DepositWithdrawalInquiry);
