import React from "react";
import { connect } from "react-redux";

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Button, Table, Col, message } from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";

// import AssociateInsureQualifyConfirmAction from "redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociateInsureQualifyConfirm.action";
import WS1177001_GovernmentTubePrimaryAggregate_sub from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1177001_GovernmentTubePrimaryAggregate_sub";
import GovernmentTubePrimaryAggregateAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/GovernmentTubePrimaryAggregate.actions";

class WS1177001_GovernmentTubePrimaryAggregate extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS05200:政管一次集計';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      isLoadingForm: false,
      isLoadingTable: false,

      count: "a",
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true,
      },

      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      selected: 0
    };

    this.onFinish = this.onFinish.bind(this);
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prv) {
    if (this.props !== prv) {
      this.getScreenData();
    }
  }

  getScreenData = () => {
    this.setState({ isLoadingTable: true });
    GovernmentTubePrimaryAggregateAction.index()
      .then(res => {
        console.log(res)
        this.setState({
          dataSource: res ?? []
        });

        if (!res) {
          message.error("エラーが発生しました");
          return;
        }
      }).catch(error => {
        message.error('エラーが発生しました');
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  };

  onFinish() { }

  render() {
    return (
      <div className="government-tube-primary-aggregate">
        <Card title="V4-VNS05200:政管一次集計">
          <Table
            bordered
            dataSource={this.state.dataSource}
            loading={this.state.isLoadingTable}
            pagination={this.state.pagination}
            rowClassName={(record, index) =>
              record.id === this.state.selectedRow.id ? "table-row-light" : ""
            }
            rowKey={record => record.id}
            scroll={{ y: "85vh" }}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  this.setState({
                    ...this.state,
                    selectedRow: record,
                    selectedRowKeys: [record.id]
                  });
                }
              };
            }}
          >
            <Table.Column
              title="健診コース"
              dataIndex=""
              showSorterTooltip={false}
              sorter={(a, b) => a.medical_exam_course.localeCompare(b.medical_exam_course)}
              render={(value, record, index) => {
                return (
                  <Col span={14} style={{ textAlign: "left" }}>
                    {record.medical_exam_course}&nbsp;&nbsp;{record.medical_exam_course_name}
                  </Col>
                );
              }}
            />
            <Table.Column
              title="集計区分"
              dataIndex="invoice_summary_division"
              showSorterTooltip={false}
              sorter={(a, b) => a.invoice_summary_division - b.invoice_summary_division}
              width={150}
              render={(value, record, index) => {
                return (
                  <Col span={10} style={{ textAlign: "center" }}>
                    {record.invoice_summary_division == '1' ? '一般' : ''}
                    {record.invoice_summary_division == '2' ? '付加' : ''}
                    {record.invoice_summary_division == '3' ? '子宮' : ''}
                  </Col>
                );
              }}
            />
            <Table.Column
              title="基本コース"
              dataIndex=""
              showSorterTooltip={false}
              sorter={(a, b) =>
                a.course_exam_basic_health.localeCompare(b.course_exam_basic_health)
              }
              render={(value, record, index) => {
                return (
                  <Col span={14} style={{ textAlign: "center" }}>
                    <div style={{ textAlign: "left" }}>{record.course_exam_basic_health}&nbsp;&nbsp;{record.course_name_short_name}</div>
                  </Col>
                );
              }}
            />
            <Table.Column
              width={70}
              align="center"
              title={
                <Button
                  size="small"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 600,
                        component: (
                          <WS1177001_GovernmentTubePrimaryAggregate_sub
                            newFlag={true}
                            onFinishScreen={() => this.closeModal()}
                            getScreenData={() => this.getScreenData()}
                          />
                        ),
                      },
                    })
                  }}
                ></Button>
              }
              render={(text, record, index) => (
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    console.log(record)
                    // 編集、削除
                    this.setState(() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS1177001_GovernmentTubePrimaryAggregate_sub
                              newFlag={false}
                              onFinishScreen={() => this.closeModal()}
                              getScreenData={() => this.getScreenData()}
                              record={record}
                            />
                          ),
                        },
                      })
                    })
                  }}
                />

              )}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1177001_GovernmentTubePrimaryAggregate);
