import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, Form, Table, Input, Select, Button, Modal, Dropdown, Menu, message } from 'antd'
import { SearchOutlined, MoreOutlined } from '@ant-design/icons'

import {
  getContractInfoBatchProcessAction,
  confirmDeleteLineAction,
  deleteLineAction,
  SelectOneContractInfoBatchProcessAction,
  SelectAllContractInfoBatchProcessAction,
} from 'redux/basicInfo/ContractInfoBatchProcess/ContractInfoBatchProcess.actions'

import WS0246001_InsurerInfoSearchQuery from '../V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS0247001_OfficeInfoRetrievalQuery from '../V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0265001_BasicCourseInquiry from './WS0265001_BasicCourseInquiry'
import WS0302001_SetInfoSearch from './WS0302001_SetInfoSearch.jsx'
import WS0271001_InspectItemSearchQuerySingle from './WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0605127_ContractLineItemDisplay from './WS0605127_ContractLineItemDisplay'
import WS0309017_ContractItemSub from './WS0309017_ContractItemSub'
import WS0331019_BatchUpdateProcess from './WS0331019_BatchUpdateProcess'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'


const styleFormItem = {
  marginBottom: 0
}

const grid = {
  labelCol: { style: { width: 50 } }
};

class WS0331001_ContractInfoBatchProcess extends Component {

  constructor(props) {
    super(props)
    // document.title = '契約情報一括処理'
    this.formRef = React.createRef()
    this.state = {
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      initialValues: {
        ContractType: '9',
        OrgCode: '',
        MedicalExamCourseF: '',
        MedicalExamCourseT: '',
        ContractStartDateFChar: '',
        ContractStartDateTChar: '',
        SetInspect: '0',
        SetCode: '',
        StartDateClassify: '0',
        FreeSearch: ''
      },
      selectedRowKeys: [],
      selectedRows: [],
      dataContract: [],
      exam_name: ''
    }
  }

  /**
   * 契約情報一覧を取得
   */
  getContractInfoBatchProcess = () => {
    const params = { ...this.state.initialValues }

    getContractInfoBatchProcessAction(params)
      .then((res) => {
        if (res) {
          let data = res.map(item => item.id)
          this.setState({
            dataContract: res,
            selectedRowKeys: data,
            selectedRows: res
          })
        }
      })
  }

  handleChange = (value, name) => {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        [name]: value
      }
    })
  }

  SelectRecord = (params) => {
    SelectOneContractInfoBatchProcessAction(params)
      .then()
      .catch()
  }

  SelectAllRecord = (params) => {
    SelectAllContractInfoBatchProcessAction(params)
      .then()
      .catch()
  }

  StartDateClassifyChange(e) {
    if (e === '0') {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          StartDateClassify: e,
          ContractStartDateFChar: ''
        }
      })
      this.formRef.current.setFieldsValue({ 'ContractStartDateFChar': '' })
    } else {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          StartDateClassify: e,
        }
      })
    }
  }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centerd: false
      },
    })
  }

  render() {
    const { initialValues, childModal, dataContract, exam_name } = this.state
    const { ContractType, SetInspect } = initialValues

    return (
      <div className='contract-info-batch-process'>
        <Card title='契約情報一括処理'>
          <Form
            ref={this.formRef}
            initialValues={initialValues}
            autoComplete='off'
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <div className='box_inner_horizontal' style={styleFormItem}>
                      <Form.Item
                        label='種別'
                        name='ContractType'
                        {...grid}
                      >
                        <Select onChange={(value) => {
                          this.formRef?.current?.setFieldsValue({ 'OrgCode': '' })
                          this.handleChange(value, 'ContractType')
                        }}>
                          <Select.Option value='9'>全　て</Select.Option>
                          <Select.Option value='0'>共　通</Select.Option>
                          <Select.Option value='1'>保険者</Select.Option>
                          <Select.Option value='2'>事業所</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name='OrgCode'
                        style={{ display: (ContractType === '1' || ContractType === '2') ? '' : 'none' }}
                      >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-allow-clear-8'
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {

                              this.formRef?.current?.setFieldsValue({
                                OrgCode: ''
                              })
                              this.handleChange('', 'OrgCode')

                            } else {
                              ContractType === '1' ?
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: true,
                                    width: 1500,
                                    component: (
                                      <WS0246001_InsurerInfoSearchQuery
                                        onFinishScreen={({ Lo_InsurerCode, Lo_Name, recordData }) => {
                                          this.formRef?.current?.setFieldsValue({
                                            OrgCode: Lo_InsurerCode
                                          })
                                          this.handleChange(Lo_InsurerCode, 'OrgCode')
                                          this.closeModal()
                                        }}
                                      />),
                                  },
                                })
                                :
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: true,
                                    width: 1500,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        onFinishScreen={({ Lio_OfficeCode, Lio_BranchStoreCode, recordData }) => {
                                          this.formRef?.current?.setFieldsValue({
                                            OrgCode: Lio_OfficeCode
                                          })
                                          this.handleChange(Lio_OfficeCode, 'OrgCode')
                                          this.closeModal()
                                        }}
                                      />),
                                  },
                                })
                            }
                          }}
                          onChange={(e) => this.handleChange(e.target.value, 'OrgCode')}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal' style={styleFormItem}>
                      <Form.Item
                        label='コース'
                        name='MedicalExamCourseF'
                        {...grid}
                      >
                        <Input.Search
                          style={{ width: 100 }}
                          onChange={e => {
                            this.handleChange(e.target.value, 'MedicalExamCourseF')
                            this.formRef.current.setFieldsValue({
                              MedicalExamCourseF: e.target.value,
                            })
                          }}
                          onDoubleClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    onFinishScreen={({ Lo_CourseCode }) => {
                                      this.formRef.current.setFieldsValue({
                                        MedicalExamCourseF: Lo_CourseCode,
                                      })
                                      this.closeModal()
                                      this.setState({
                                        initialValues: {
                                          ...this.state.initialValues,
                                          MedicalExamCourseF: Lo_CourseCode,
                                          MedicalExamCourseT: Lo_CourseCode,
                                        }
                                      })
                                    }}
                                  />),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.handleChange(event.target.value, 'MedicalExamCourseF')
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={({ Lo_CourseCode }) => {
                                        this.formRef.current.setFieldsValue({
                                          MedicalExamCourseF: Lo_CourseCode,
                                        })
                                        this.closeModal()
                                        this.setState({
                                          initialValues: {
                                            ...this.state.initialValues,
                                            MedicalExamCourseF: Lo_CourseCode,
                                            MedicalExamCourseT: Lo_CourseCode,
                                          }
                                        })
                                      }}
                                    />),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item style={{ textAlign: 'center' }}>～</Form.Item>
                      <Form.Item name='MedicalExamCourseT' >
                        <Input.Search
                          style={{ width: 100 }}
                          onChange={(e) => this.handleChange(e.target.value, 'MedicalExamCourseT')}
                          onDoubleClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <WS0265001_BasicCourseInquiry
                                    onFinishScreen={({ Lo_CourseCode }) => {
                                      this.formRef.current.setFieldsValue({
                                        MedicalExamCourseT: Lo_CourseCode
                                      })
                                      this.handleChange(Lo_CourseCode, 'MedicalExamCourseT')
                                      this.closeModal()
                                    }}
                                  />),
                              },
                            })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.handleChange(event.target.value, 'MedicalExamCourseT')
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1500,
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      onFinishScreen={({ Lo_CourseCode }) => {
                                        this.formRef.current.setFieldsValue({
                                          MedicalExamCourseT: Lo_CourseCode,
                                        })
                                        this.closeModal()
                                        this.setState({
                                          initialValues: {
                                            ...this.state.initialValues,
                                            MedicalExamCourseT: Lo_CourseCode,
                                          }
                                        })
                                      }}
                                    />),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal' style={styleFormItem}>
                      <Form.Item
                        label='開始日'
                        name='StartDateClassify'
                        {...grid}
                      >
                        <Select
                          onChange={(value) => this.StartDateClassifyChange(value)}
                        >
                          <Select.Option value='0'>最新</Select.Option>
                          <Select.Option value='1'>指定</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name='ContractStartDateFChar'
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          onChange={(date, dateString) => this.handleChange(dateString, 'ContractStartDateFChar')}
                          disabled={this.state.initialValues.StartDateClassify === '0'}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal' style={styleFormItem}>
                      <Form.Item
                        label='明細'
                        name='SetInspect'
                        {...grid}
                      >
                        <Select onChange={(value) => {
                          this.handleChange(value, 'SetInspect')
                          this.setState({ exam_name: '' })
                          this.formRef?.current?.setFieldsValue({ 'SetCode': '' })
                        }}>
                          <Select.Option value='0'>セット</Select.Option>
                          <Select.Option value='1'>検　査</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item name='SetCode' >
                        <Input.Search
                          allowClear={true}
                          style={{ textAlign: 'right' }}
                          maxLength={8}
                          onChange={(e) => this.handleChange(e.target.value, 'SetCode')}
                          onDoubleClick={() => {
                            SetInspect === '0' ?
                              this.setState({
                                childModal: {
                                  ...childModal,
                                  visible: true,
                                  width: 1000,
                                  component: (
                                    <WS0302001_SetInfoSearch
                                      Li_SetIdentify={''}
                                      Li_StartDate={''}
                                      Li_CourseCode={''}
                                      _Lo_Return={''}
                                      Li_ContextId={''}
                                      Li_RangeSetting={1}
                                      Li_CourseLevel={''}
                                      Li_ReserveNum={''}
                                      Li_ContractType={''}
                                      Li_ContractOrgCode={''}
                                      Li_ContractStartDate={''}
                                      Li_ContractNum={''}
                                      Lo_SetCode={this.formRef?.current?.getFieldValue('SetCode')}
                                      onFinishScreen={({ Lo_SetCode, recordData }) => {
                                        this.formRef?.current?.setFieldsValue({
                                          SetCode: Lo_SetCode
                                        })
                                        this.handleChange(Lo_SetCode, 'SetCode')
                                        this.closeModal()
                                        this.setState({
                                          exam_name: recordData.set_name
                                        })
                                      }}
                                    />),
                                },
                              })
                              :
                              this.setState({
                                childModal: {
                                  ...childModal,
                                  visible: true,
                                  width: 900,
                                  component: (<WS0271001_InspectItemSearchQuerySingle
                                    onFinishScreen={(output) => {
                                      this.formRef?.current?.setFieldsValue({
                                        SetCode: output.recordData.test_item_code
                                      })
                                      this.handleChange(output.recordData.test_item_code, 'SetCode')
                                      this.closeModal()
                                      this.setState({
                                        exam_name: output.recordData.exam_name
                                      })
                                    }}
                                  />),
                                },
                              })
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.handleChange(event.target.value, 'SetCode')
                              this.setState({
                                exam_name: ''
                              })
                            } else {
                              SetInspect === '0' ?
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: true,
                                    width: 1000,
                                    component: (
                                      <WS0302001_SetInfoSearch
                                        Li_SetIdentify={''}
                                        Li_StartDate={''}
                                        Li_CourseCode={''}
                                        _Lo_Return={''}
                                        Li_ContextId={''}
                                        Li_RangeSetting={1}
                                        Li_CourseLevel={''}
                                        Li_ReserveNum={''}
                                        Li_ContractType={''}
                                        Li_ContractOrgCode={''}
                                        Li_ContractStartDate={''}
                                        Li_ContractNum={''}
                                        Lo_SetCode={this.formRef?.current?.getFieldValue('SetCode')}
                                        onFinishScreen={({ Lo_SetCode, recordData }) => {
                                          this.formRef?.current?.setFieldsValue({
                                            SetCode: Lo_SetCode
                                          })
                                          this.handleChange(Lo_SetCode, 'SetCode')
                                          this.closeModal()
                                          this.setState({
                                            exam_name: recordData.set_name
                                          })
                                        }}
                                      />),
                                  },
                                })
                                :
                                this.setState({
                                  childModal: {
                                    ...childModal,
                                    visible: true,
                                    width: 900,
                                    component: (<WS0271001_InspectItemSearchQuerySingle
                                      onFinishScreen={(output) => {
                                        this.formRef?.current?.setFieldsValue({
                                          SetCode: output.recordData.test_item_code
                                        })
                                        this.handleChange(output.recordData.test_item_code, 'SetCode')
                                        this.closeModal()
                                        this.setState({
                                          exam_name: output.recordData.exam_name
                                        })
                                      }}
                                    />),
                                  },
                                })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <div>{exam_name}</div>
                      </Form.Item>
                    </div>

                    <Form.Item
                      label='検索'
                      name='FreeSearch'
                      style={{ marginBottom: 0 }}
                      {...grid}
                    >
                      <Input onChange={(e) => this.handleChange(e.target.value, 'FreeSearch')} />
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_inner_horizontal' style={styleFormItem}>
                    <Form.Item style={{ float: 'right', marginBottom: 0 }}>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => this.getContractInfoBatchProcess()}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Table
                size='small'
                bordered
                rowSelection={{
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record) => {
                    let arrTemp = [...this.state.selectedRowKeys]
                    let arrTempRecord = [...this.state.selectedRows]
                    let idx = arrTemp.indexOf(record.id)
                    if (idx === -1) {
                      arrTemp.push(record.id)
                      arrTempRecord.push(record)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord,
                        selectedRowOnly: arrTempRecord,
                      })
                    } else {
                      arrTemp.splice(idx, 1)
                      arrTempRecord.splice(idx, 1)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord,
                        selectedRowOnly: arrTempRecord,
                      })
                    }
                  },
                  onSelectAll: (selected) => {
                    if (selected) {
                      let arrTemp = this.state.dataContract.map(item => item.id)
                      let arrTempRecord = this.state.dataContract
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord,
                        selectedRowOnly: arrTempRecord
                      })
                    } else {
                      this.setState({
                        selectedRowKeys: [],
                        selectedRows: [],
                        selectedRowOnly: []
                      })
                    }
                  }
                }}
                pagination={dataContract.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                dataSource={dataContract}
                rowKey={(record) => record.id}
                scroll={{ y: resizableTableScroll(130) }}
                onRow={(record, rowIndex) => ({
                  onDoubleClick: () =>
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 850,
                        component: (
                          <WS0605127_ContractLineItemDisplay
                            ContractType={this.formRef.current.getFieldValue('ContractType')}
                            Li_ContractType={record.Lo_contractType}
                            Li_ContractOrgCode={record.Lo_contractOrgs}
                            Li_ContractStartDate={record.contract_start_date_on}
                            Li_ContractNum={record.contract_number}

                            onClick={() => {
                              this.closeModal()
                            }}
                          />
                        ),
                      }
                    })
                })}
              >
                <Table.Column
                  title='種別'
                  dataIndex='contract_type'
                  width={80}
                />

                <Table.Column
                  title='契約団体'
                  dataIndex='contractOrgs'
                />

                <Table.Column
                  title='開始日'
                  dataIndex='contract_start_date_on'
                  width={135}
                  render={(text) =>
                    <div>{
                      moment(text).isValid() ?
                        moment(text).format('YYYY/MM/DD(ddd)')
                        :
                        '0000/00/00'
                    }</div>
                  }
                />

                <Table.Column
                  title='番号'
                  dataIndex='contract_number'
                  width={80}
                  render={(text) => (
                    <div style={{ textAlign: 'right' }}>{text}</div>
                  )}
                />

                <Table.Column
                  title='コース'
                  dataIndex='medical_exam_course'
                  width={70}
                />

                <Table.Column
                  title='コース略称'
                  dataIndex='contract_short_name'
                />

                <Table.Column
                  title='税区分'
                  dataIndex='tax_division'
                  width={70}
                />

                <Table.Column
                  title='合　計'
                  dataIndex='total'
                  width={90}
                  render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                />

                <Table.Column
                  title='保険者'
                  dataIndex='insurer_total_price'
                  width={90}
                  render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                />

                <Table.Column
                  title='事業所'
                  dataIndex='office_total_price'
                  width={90}
                  render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                />

                <Table.Column
                  title='他団体'
                  dataIndex='organization_total_price'
                  width={90}
                  render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                />

                <Table.Column
                  title='個人'
                  dataIndex='total_price'
                  width={90}
                  render={(text) => <div style={{ textAlign: 'right' }}>{text === 0 || text === '0' ? '' : text.toLocaleString()}</div>}
                />

                <Table.Column
                  align='center'
                  width={40}
                  fixed='right'
                  render={(text, record, index) => (
                    <Dropdown
                      size='small'
                      trigger='click'
                      overlay={() => (
                        <Menu >
                          <Menu.Item
                            key='1'
                            onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 850,
                                  component: (
                                    <WS0605127_ContractLineItemDisplay
                                      ContractType={this.formRef.current.getFieldValue('ContractType')}
                                      Li_ContractType={record.Lo_contractType}
                                      Li_ContractOrgCode={record.Lo_contractOrgs}
                                      Li_ContractStartDate={record.contract_start_date_on}
                                      Li_ContractNum={record.contract_number}
                                      onClick={() => { this.closeModal() }}
                                    />
                                  ),
                                },
                              })
                            )}
                          >
                            照会
                          </Menu.Item>

                          <Menu.Item
                            key='2'
                            onClick={() => (
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  centered: true,
                                  width: 750,
                                  component: (
                                    <WS0309017_ContractItemSub
                                      Li_ContractType={record.Lo_contractType}
                                      Li_ContractOrgCode={record.Lo_contractOrgs}
                                      Li_ContractStartDate={record.contract_start_date_on}
                                      Li_ContractNum={record.contract_number}
                                      onClick={() => { this.closeModal() }}
                                      onFinishScreen={() => { this.getContractInfoBatchProcess() }}
                                    />
                                  ),
                                },
                              })
                            )}
                          >
                            修正
                          </Menu.Item>

                          <Menu.Item
                            key='3'
                            onClick={() => {
                              const params = {
                                W_contract_type: record.Lo_contractType,
                                W_contract_org_code: record.Lo_contractOrgs,
                                W_contract_start_date: record.contract_start_date_on,
                                W_contract_number: record.contract_number
                              }

                              confirmDeleteLineAction(params)
                                .then(res => {
                                  if (res.status) {
                                    message.error('使用中の契約です。削除出来ません。')
                                  } else {
                                    Modal.confirm({
                                      content: '削除を行いますか',
                                      onOk: () => {
                                        deleteLineAction(params).then(() => {
                                          message.success('正常に削除されました')
                                          let arr = [...this.state.dataContract]
                                          let index = arr.indexOf(record)
                                          if (index !== -1) {
                                            arr.splice(index, 1)
                                            this.setState({
                                              dataContract: arr,
                                            })
                                            this.getContractInfoBatchProcess()
                                          }
                                        })
                                      }
                                    })
                                  }
                                })
                            }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      >
                      </Button>
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              disabled={this.state.dataContract?.length === 0}
              type='primary'
              onClick={() => {
                this.setState({
                  childModal: {
                    ...childModal,
                    visible: true,
                    width: 1100,
                    component: (
                      <WS0331019_BatchUpdateProcess
                        selectedRows={this.state.selectedRows}
                        onFinishScreen={(data) => {
                          this.getContractInfoBatchProcess()
                          this.closeModal()
                        }}
                      />),
                  },
                })
              }}>
              <span className='btn_label'>
                一括追加
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  contractInfos: state.ContractInfoBatchProcessReducer.contractInfos,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0331001_ContractInfoBatchProcess)
