import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Form, Card, Button, Table, Modal, message, } from "antd";
import { MoreOutlined, PlusOutlined, QuestionCircleOutlined, } from "@ant-design/icons";
import WS0341506_ResultTableEdit from "./WS0341506_ResultTableEdit.jsx";
import PropTypes from "prop-types";
import { generateUUID } from 'components/Commons/generateUUID'

class WS0341503_ResultTable extends React.Component {

  static propTypes = {
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any
  };
  formRef = React.createRef();
  buttonSubmmitRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "事業所情報保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      pagination: {
        size: 1,
        showQuickJumper: false
      },

      //New code
      screenData: {},
      statusPage: 1, // statusPage: 1 create auto num, statusPage: 2 create input num, statusPage: 3 update
      isFileEdited: false,
      isLoadingData: false,
      OfficeCode: "",
      BranchStoreCode: "",
      officeInfoDisplay: {},
      count: 0,
      ResultsTable: [],
      AffiliationInfo: [],
      RecordedInfo: [],
      selectedRowsResultsTable: [],
      indexTableResultsTable: 0,
      selectedRowsAffiliationInfo: [],
      indexTableAffiliationInfo: 0,
      selectedRowsRecordedInfo: [],
      indexTableRecordedInfo: 0
    };
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      ResultsTable: this.props.Li_ResultsTable
    })
    this.setState({
      ResultsTable: this.props.Li_ResultsTable,
      OfficeCode: this.props.Li_OfficeCode,
      BranchStoreCode: this.props.Li_BranchStoreCode
    })
  }


  saveData = (record, data) => {
    let ResultsTable = [...this.state.ResultsTable]
    let newResultsTable = []

    if (data) {
      const newRow = {
        id: data.id,
        medical_exam_course: record.Li_medical_exam_course,
        standard_printing_style_1: record.Li_standard_printing_style_1,
        format_name_1: record.Li_format_name_1
      }

      ResultsTable.map((item, index) => {
        if (item.id == data.id) {
          newResultsTable.push(newRow)
        } else {
          newResultsTable.push(item)
        }
      })

      ResultsTable = [...newResultsTable]
    } else {
      const newRow = {
        id: generateUUID(),
        medical_exam_course: record.Li_medical_exam_course,
        standard_printing_style_1: record.Li_standard_printing_style_1,
        format_name_1: record.Li_format_name_1
      }

      ResultsTable.push(newRow)
    }

    this.setState({ ResultsTable: ResultsTable })
    this.props.onUpdate(ResultsTable)
    this.forceUpdate();
  };


  deleteRecordTable = (record) => {
    let ResultsTable = [...this.state.ResultsTable]
    let newResultsTable = []

    ResultsTable.map((item, index) => {
      if (item.id != record.id) {
        newResultsTable.push(item)
      }
    })

    this.setState({ ResultsTable: newResultsTable })
    this.props.onUpdate(newResultsTable)
    this.forceUpdate();
  };


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };


  render() {
    return (
      <div className="result-table">
        <Card title='結果表'>
          <Form
            ref={this.formRef}
            onValuesChange={this.onValuesChange}
            autoComplete="off"
          >
            <Table
              dataSource={this.state.ResultsTable}
              rowKey={record => record.id}
              pagination={false}
              size="small"
              bordered
            >
              <Table.Column
                title="コース"
                dataIndex="medical_exam_course"
                width={100}
              />
              <Table.Column
                title="独自様式"
                dataIndex="standard_printing_style_1"
                render={(text, record, index) => (
                  <div className="box_inner_horizontal">
                    <span>{record.standard_printing_style_1}</span>
                    <span>{record.format_name_1}</span>
                  </div>
                )}
              />
              <Table.Column
                title="標準様式"
                dataIndex="standard_printing_style_2"
                render={(text, record, index) => (
                  <>
                    <span>{text}</span>
                    <span style={{ marginLeft: "5px" }}>{record.format_name_2}</span>
                  </>
                )}
              />
              <Table.Column
                width={40}
                key="action"
                align="center"
                title={() => (
                  <Button
                    type="primary"
                    size='small'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 400,
                          className: '',
                          component: (
                            <WS0341506_ResultTableEdit
                              newFlag={true}
                              onUpdate={(output) => {
                                if (!output.Li_medical_exam_course) {
                                  message.error('コースが設定されていません')
                                  return
                                }
                                Modal.confirm({
                                  content: '新規登録します。よろしいですか？',
                                  okText: 'は　い',
                                  cancelText: 'いいえ',
                                  onOk: () => {
                                    // 保存ボタン押下、変更内容を反映
                                    this.saveData(output, false)
                                    this.closeModal()
                                  }
                                })
                              }}
                            />
                          )
                        },
                      })
                    }}
                  />
                )}

                render={(text, record, index) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => {
                        // 編集、削除
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 400,
                            className: '',
                            component: (
                              <WS0341506_ResultTableEdit
                                newFlag={false}
                                record={record}
                                onUpdate={(output) => {
                                  Modal.confirm({
                                    content: '上書き登録します。よろしいですか？',
                                    okText: 'は　い',
                                    cancelText: 'いいえ',
                                    onOk: () => {
                                      // 保存ボタン押下、変更内容を反映
                                      this.saveData(output, record)
                                      this.closeModal()
                                    }
                                  })
                                }}
                                onDelete={(output) => {
                                  Modal.confirm({
                                    title: "確認",
                                    icon: <QuestionCircleOutlined style={{ color: "#1890ff" }} />,
                                    content: "削除しますか？",
                                    okText: "削除",
                                    cancelText: "キャンセル",
                                    onOk: () => {
                                      this.deleteRecordTable(record)
                                      this.closeModal()
                                    }
                                  });
                                }}
                              />
                            )
                          },
                        })
                      }}
                    />
                  );
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    )
  }
}
export default WS0341503_ResultTable
