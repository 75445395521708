import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Table, Button, message } from "antd";
import ReservesRelatedFilesOutputAction from "redux/Others/ReservesRelatedFilesOutput/ReservesRelatedFilesOutput.action";
import ReservesRelatedFilesOutputModal from 'pages/ZZ_Others/DEVTOOL2020_ReservesRelatedFilesOutput/ReservesRelatedFilesOutputModal.jsx'
import { ModalError } from "components/Commons/ModalConfirm";
import ModalDraggable from "components/Commons/ModalDraggable"

class ReservesRelatedFilesOutput extends React.Component {
  static propTypes = {
    Lio_Output: PropTypes.any,
    Lo_StsOutput: PropTypes.any,
    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = '予約関連ファイル出力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      selectedRows: [],
      isSelectAll: true
    };
  }
  componentDidMount = () => {
    ReservesRelatedFilesOutputAction.index()
      .then((res) => {
        this.setState({ dataSource: res })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  onOK() {
    if(this.state.selectedRows.length == 0) {
      ModalError('出力するファイルを選択してください')
      return
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ReservesRelatedFilesOutputModal
            selectedRows={this.state.selectedRows}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />)
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="inspect-request-convert-master-maintain">
        <Card title="予約関連ファイル出力">
          <Table
            size='small'
            bordered={true}
            dataSource={this.state.dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            scroll={{ y: 700 }}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: this.state.selectedRowKeys,
              onSelect: (record, selected, selectedRows, nativeEvent) => {
                let arrTemp = [...this.state.selectedRowKeys]
                let arrTempRecord = [...this.state.selectedRows]
                let idx = arrTemp.indexOf(record.id)
                if (idx === -1) {
                  arrTemp.push(record.id)
                  arrTempRecord.push(record)
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord
                  })
                } else {
                  arrTemp.splice(idx, 1)
                  arrTempRecord.splice(idx, 1)
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord
                  })
                }
                // this.selectRecord(record.id, selected)
              },

              onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected) {
                  let arrTemp = this.state.dataSource.map(item => item.id)
                  let arrTempRecord = this.state.dataSource
                  this.setState({
                    selectedRowKeys: arrTemp,
                    selectedRows: arrTempRecord,
                    isSelectAll: true
                  })
                } else {
                  this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                    isSelectAll: false
                  })
                }
                // this.selectAll(selected)
              },
            }}
          >
            <Table.Column title="管理番号" dataIndex='ManagementNumber'>

            </Table.Column>
            <Table.Column title="様式名称" dataIndex='FormatName'>

            </Table.Column>
          </Table>
          <div className='box_button_bottom_right'>
            <Button
              type="primary"
              disabled={!this.state.selectedRows}
              onClick={() => {
                this.onOK()
              }}
            >
              <span className='btn_label'>
                確定
              </span>
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservesRelatedFilesOutput);
