import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import IntroduceLetterExtractMaintainAction from 'redux/IntroductionLetter/IntroduceLetterExtract/IntroduceLetterExtractMaintain.action'
import WS0887001_IntroduceLetterVariousMasterInquiry from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry.jsx'
import WS0888026_IntroduceLetterSubjectCmtInquiry from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0888026_IntroduceLetterSubjectCmtInquiry.jsx'
import WS0936002_IntroduceLetterReturnInput from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0936002_IntroduceLetterReturnInput.jsx'
import WS0898028_InspectInput from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898028_InspectInput.jsx'
import { Checkbox, Card, Form, Input, Button, Table, Tooltip, Modal, AutoComplete, message } from 'antd'
import { PrinterOutlined, SaveOutlined } from '@ant-design/icons'

const labelSize = {
  labelCol: { style: { width: 110 } },
}

class WS0898002_IntroduceLetterEdit extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_PersonalNum: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }
  constructor(props) {
    super(props)

    // document.title = '紹介状編集'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSourceInspectData: [],
      introduceLetterVariousMasters: [],
      subTitle: '',
      accepted_no: '',
      beforeDepartment: 0,
      previousMode: false,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    let judgment = true
    if (this.props.record) {
      judgment = false
    }

    let params = {
      newaddjudgment: judgment,
      Li_PersonalNum: judgment ? this.props.values.personal_number_id : this.props.record.personal_number_id,
      Li_ReserveNum: judgment ? this.props.values.reservation_number : this.props.record.reservation_number,
      visit_date_on: judgment ? this.props.values.visit_date_on : this.props.record.visit_date_on,
      accepted_no: judgment ? '' : this.props.record.accepted_no,
      department: judgment ? '' : this.props.record.department,
    }

    IntroduceLetterExtractMaintainAction.getDetailedData(params)
      .then(res => {
        this.setFormFieldValue('issued_flg', res.issued_flg ?? false)
        this.setFormFieldValue('exam_department_code', res.exam_department_code ?? '')
        this.setFormFieldValue('exam_department_name', res.exam_department_name ?? '')
        this.setFormFieldValue('department', res.department ?? '')
        this.setFormFieldValue('department_name', res.department_name ?? '')
        this.setFormFieldValue('doctor_code', res.doctor_code ?? '')
        this.setFormFieldValue('doctor_name', res.doctor_name ?? '')
        this.setFormFieldValue('general_comment_code', res.general_comment_code ?? '')
        this.setFormFieldValue('comment_content', res.comment_content ?? '')
        this.setFormFieldValue('Remarks', res.Remarks ?? '')

        this.setFormFieldValue('course_level', res.course_level ?? '')
        this.setFormFieldValue('pattern_code', res.pattern_code ?? '')
        this.setFormFieldValue('personal_number_id', res.personal_number_id ?? '')
        this.setFormFieldValue('reservation_number', res.reservation_number ?? '')
        this.setFormFieldValue('receipt_number', res.receipt_number ?? '')
        this.setFormFieldValue('visit_date_on', res.visit_date_on ?? '')

        this.setState({
          subTitle: ` [${res.personal_number_id ?? ''}] ${res.kanji_name ?? ''}`,
          previousMode: this.props.previousMode ?? false,
          introduceLetterVariousMasters: this.props.introduceLetterVariousMasters,
          accepted_no: res.accepted_no,
          beforeDepartment: res.exam_department_code,
        })
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
    this.forceUpdate()
  }

  /**
   * 紹介状各種マスタ照会　モーダル
   * 6 : 医師照会
   * 7 : 診療科照会
   * @param {*} manageCode
   */
  showWS0887001_IntroduceLetterVariousMasterInquiry = (manageCode, searchChar = '', detailFlag = false) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_ManageCode={manageCode}
            Li_SearchChar={searchChar}
            onFinishScreen={(output) => {
              if (manageCode === 6) {
                // 医師
                this.formRef.current?.setFieldsValue({
                  doctor_code: output.variousCodes,
                  doctor_name: output.recordData.doctor_name
                })
              } else if (manageCode === 7) {
                // 診療科（検査部門）
                this.formRef.current?.setFieldsValue({
                  exam_department_code: output.variousCodes,
                  exam_department_name: output.recordData.department_name,
                  department_name: output.recordData.remarks
                })
              }
              this.forceUpdate()
              this.closeModal()
            }
            }
          />
        ),
      },
    })
  }

  /**
   * 紹介状対象コメント照会
   */
  showWS0888026_IntroduceLetterSubjectCmtInquiry = () => {
    this.setState({
      ...this.state,
      childModal: {
        width: 1200,
        visible: true,
        component: (
          <WS0888026_IntroduceLetterSubjectCmtInquiry
            onFinishScreen={({ Lo_CommentCode, recordData }) => {
              this.setFormFieldValue('general_comment_code', Lo_CommentCode)
              this.setFormFieldValue('comment_content', recordData?.FL_comment_content)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * getFieldValue　Formから取得
   */
  getRawValue(name) {
    return this.formRef?.current?.getFieldValue(name) ?? ''
  }

  /**
   * setFields Formに設定
   * @param {*} namePath
   * @param {*} value
   */
  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      }
    ])
  }

  /**
   * 保存ボタン処理
   */
  save = () => {
    const params = {
      id: this.props.record?.id ?? null,
      personal_number_id: this.getRawValue('personal_number_id'),
      receipt_number: this.getRawValue('receipt_number'),
      reservation_number: this.getRawValue('reservation_number'),
      visit_date_on: this.getRawValue('visit_date_on'),
      issued_flg: this.getRawValue('issued_flg') ? 1 : 0,
      exam_department_code: this.getRawValue('exam_department_code'),
      department_name: this.getRawValue('department_name'),
      doctor_code: this.getRawValue('doctor_code'),
      general_comment_code: this.getRawValue('general_comment_code'),
      Remarks: this.getRawValue('Remarks'),
      beforeDepartment: this.state.beforeDepartment
    }

    IntroduceLetterExtractMaintainAction.save(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }

  /**
   * 検査入力 モーダル
   */
  showWS0898028_InspectInput() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component:
          <WS0898028_InspectInput
            Li_CourseLevel={this.getRawValue('course_level')}
            Li_PatternCode={this.getRawValue('pattern_code')}
            Li_PersonalNum={this.getRawValue('personal_number_id')}
            Li_ReserveNum={this.getRawValue('reservation_number')}
            Li_AcceptNum={this.getRawValue('receipt_number')}
            Li_Date={this.getRawValue('visit_date_on')}
            Li_Department={this.getRawValue('exam_department_code')}
            subTitle={this.state.subTitle}
          />,
      },
    })
  }

  /**
   * 紹介状返送入力　モーダル
   */
  showWS0936002_IntroduceLetterReturnInput() {
    const values = { ...this.props.values }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component:
          <WS0936002_IntroduceLetterReturnInput
            Li_PatternCode={this.getRawValue('pattern_code')}
            Li_PersonalNum={this.getRawValue('personal_number_id')}
            Li_ReserveNum={this.getRawValue('reservation_number')}
            Li_AcceptNum={this.getRawValue('receipt_number')}
            Li_Date={this.getRawValue('visit_date_on')}
            Li_CourseLevel={this.getRawValue('course_level')}
            Li_Department={this.getRawValue('exam_department_code')}
            subTitle={this.state.subTitle}
            Li_ReturnInfoDisplay={1}
          />,
      },
    })
  }

  deleteIntroduceLetter = () => {
    const params = {
      id: this.props.record?.id ?? null,
      reservation_number: this.props.record?.reservation_number ?? '',
      department: this.props.record?.department ?? ''
    }
    IntroduceLetterExtractMaintainAction.deleteData(params)
      .then(res => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
  }


  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-extract-maintain'>
        <Card
          title={`紹介状作成[${(this.props.record) ? '変更' : '新規追加'}] ${this.state.subTitle}`}
        >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container' style={{ gap: 0 }}>
              <Form.Item
                label='受診日'
                {...labelSize}
              >
                <div>{this.props.values.visit_date_on ?? ''}</div>
              </Form.Item>
              <Form.Item
                label='受付No'
                {...labelSize}
              >
                <div>{this.props.values.receipt_number ?? ''}</div>
              </Form.Item>
              <Form.Item
                label='発行'
                name='issued_flg'
                valuePropName='checked'
                {...labelSize}
              >
                <Checkbox
                  readOnly={this.state.previousMode}
                />
              </Form.Item>
              <div className='box_inner_horizontal'>
                {/* 三愛用 */}
                <Form.Item
                  label='診療科(検査部門)'
                  name='exam_department_code'
                  {...labelSize}
                >
                  <Input.Search
                    style={{ width: 100 }}
                    onSearch={() => {
                      if (this.state.previousMode) return
                      const searchChar = this.formRef.current.getFieldValue('exam_department_code')
                      // WS0898002_IntroduceLetterEdit照会
                      this.showWS0887001_IntroduceLetterVariousMasterInquiry(7, searchChar)
                    }}
                    readOnly={this.state.previousMode}
                  >
                  </Input.Search>
                </Form.Item>

                <div style={{ marginTop: 5 }}>{this.getRawValue('exam_department_name')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='受診科'
                  name='department_name'
                  {...labelSize}
                >
                  <AutoComplete
                    style={{ width: 200 }}
                    options={this.state.introduceLetterVariousMasters}
                    filterOption={(inputValue, option) =>
                      (option?.label ?? '').toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='判定医'
                  name='doctor_code'
                  {...labelSize}
                >
                  <Input.Search
                    style={{ width: 100 }}
                    onSearch={() => {
                      if (this.state.previousMode) return
                      const searchChar = this.formRef.current.getFieldValue('doctor_code')
                      // 医師照会
                      this.showWS0887001_IntroduceLetterVariousMasterInquiry(6, searchChar)
                    }}
                    readOnly={this.state.previousMode}
                  >
                  </Input.Search>
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.getRawValue('doctor_name')}</div>
              </div>

              <div className='box_inner_horizontal'>
                <Form.Item
                  label='指導'
                  name='general_comment_code'
                  {...labelSize}
                >
                  <Input.Search
                    style={{ width: 130 }}
                    onSearch={() => {
                      if (this.state.previousMode) return
                      // 指導
                      this.showWS0888026_IntroduceLetterSubjectCmtInquiry()
                    }}
                    readOnly={this.state.previousMode}
                  />
                </Form.Item>

                <div style={{ marginTop: 5, maxWidth: 350 }}>{this.getRawValue('comment_content')}</div>
              </div>

              <Form.Item
                name='Remarks'
                label='備考'
                {...labelSize}
                style={{ width: 450, marginBottom: 0 }}
              >
                <Input.TextArea
                  maxLength={256}
                  rows={5}
                  onDoubleClick={() => {
                    if (this.state.previousMode) return
                    this.onDoubleClickRemarks()
                  }}
                  readOnly={this.state.previousMode}
                />
              </Form.Item>

            </div>

            {/* ↓↓非表示↓↓ */}
            <div style={{ display: 'none' }}>
              <div
                className='table_header_filter'
                style={{ justifyContent: 'end' }}
              >
                <Button
                  disabled={!this.getRawValue('Expression_21_3')}
                  style={{ float: 'right' }}
                  onClick={() => {
                    this.onDoubleClickRemarks()
                  }}
                >
                  内容編集
                </Button>
              </div>

              <Table
                bordered
                scroll={{ y: 300 }}
                size='small'
                pagination={false}
                dataSource={this.state.dataSourceInspectData}
                rowKey={(record) => record.id}
                style={{ height: '100%' }}
              >
                <Table.Column
                  width={100}
                  title='検査コード'
                  dataIndex='exam_code'
                  render={(value, record, index) =>
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  }
                />
                <Table.Column
                  title='検査名'
                  dataIndex='exam_name'
                  render={(value, record, index) =>
                    <Tooltip title={record.exam_name}>
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    </Tooltip>
                  }
                />
              </Table>
            </div>
            {/* ↑↑非表示↑↑ */}

            {/* Footer */}
            <div
              className='box_button_bottom_right'
              style={{ display: this.state.previousMode ? 'none' : '' }} //前回データの場合、非表示
            >
              <Button
                danger
                type='primary'
                hidden={!(this.props.record)}
                onClick={() => {
                  Modal.confirm({
                    content: `削除を行いますか?`,
                    okText: 'は　い',
                    cancelText: 'いいえ',
                    onOk: () => {
                      this.deleteIntroduceLetter()
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>
              <Button
                type='primary'
                icon={<PrinterOutlined />}
                hidden={!(this.props.record)}
                onClick={() => {
                  if (this.props.onPrint) {
                    let recordId = null
                    if (this.props.record) {
                      recordId = this.props.record.id
                    }
                    this.props.onPrint(recordId)
                  }
                }}
              >
                <span className='btn_label'>
                  紹介状発行
                </span>
              </Button>

              <Button
                type='primary'
                onClick={() =>
                  this.showWS0898028_InspectInput()
                }
              >
                <span className='btn_label'>
                  検査入力
                </span>
              </Button>
              <Button
                type='primary'
                onClick={() =>
                  this.showWS0936002_IntroduceLetterReturnInput()
                }
              >
                <span className='btn_label'>
                  返送入力
                </span>
              </Button>


              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() =>
                  this.save()
                }
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0898002_IntroduceLetterEdit)
