import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'

import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Space, Card, Form, Input, Select, Button, Table, Menu, message, Dropdown } from 'antd'
import { SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MoreOutlined, InfoOutlined } from '@ant-design/icons'
import moment from 'moment'
import Color from 'constants/Color'
import IntroduceLetterReturnInfoInputService from 'services/IntroductionLetter/IntroduceLetterReturnInfoInput/IntroduceLetterReturnInfoInputService'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery'
import WS0887001_IntroduceLetterVariousMasterInquiry from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0887001_IntroduceLetterVariousMasterInquiry'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import WS2804013_ReturnInfoInput from 'pages/SK_IntroductionLetter/V4SK0006000_IntroduceLetterReturnInfoInput/WS2804013_ReturnInfoInput'
import WS0917005_AskIssueInput from 'pages/SK_IntroductionLetter/V4SK0009000_AskIssued/WS0917005_AskIssueInput'
import WS0898001_IntroduceLetterExtractMaintain from 'pages/SK_IntroductionLetter/V4SK0003000_IntroduceLetterExtract/WS0898001_IntroduceLetterExtractMaintain'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const labelCol = {
  labelCol: { style: { width: '60px' } },
}
class WS2804001_IntroduceLetterReturnInfoInput extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '紹介状返送情報入力'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      isLoading: false,
      displayList: []
    }
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue({
      DateFChar: moment().subtract(1, 'years'),
      DateTChar: moment(),
      StsIssue: 1,
      StsReturn: 0
    })
    this.forceUpdate()
    this.setState({ selectedRows: {}, isLoading: true })
    IntroduceLetterReturnInfoInputService.filterIntroduceLetterReturnInfoInputService({
      DateFChar: moment().format('YYYY/MM/DD'),
      DateTChar: moment().format('YYYY/MM/DD')
    })
      .then(res => {
        this.setState({ isLoading: false })
        this.formRef.current.setFieldsValue({
          displayList: res.data
        })
        this.setState({
          displayList: res.data
        })
      })
      .catch(error => {
        this.setState({ isLoading: false })
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => this.setState({ isLoading: false }))
  }
  handleClick = (event, row) => {
    switch (event) {
      case '受診照会':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 'fit-content',
            component: (
              <WS2583001_ConsultInquirySub
                Li_ReserveNum={row.reservation_number}
              />
            )
          }
        })
        break
      case '返送情報入力':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 'fit-content',
            component: (
              <WS2804013_ReturnInfoInput
                kanji_name={row.kanji_name}
                department_name={row.department_name}
                visitDate={row.visit_date_on}
                examDate={row.exam_date_on}
                reservationNumber={row.reservation_number}
                department={row.department}
                examDepartmentCode={row.exam_department_code}
                oldInsuranceGroupDivision={row.old_insurance_group_division}
                oldInsurancePreciseResult={row.old_insurance_precise_result}
                medicalInstitutionCode={row.medical_institution_code}
                otherReturn={row.other_return}
                onSave={() => { this.onFinish() }}
              />
            )
          }
        })
        break
      case 'おたずね入力':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: '1500',
            component: <WS0917005_AskIssueInput />
          }
        })
        break
      case '紹介状保守':
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1200,
            component: <WS0898001_IntroduceLetterExtractMaintain
              Li_PersonalNum={row.personal_number_id}
              Li_ReserveNum={row.reservation_number}
              Li_VisitDate={row.visit_date_on}
            />
          }
        })
        break
      default:
        break
    }
  }

  onFinish = (values) => {
    const {
      DateFChar,
      DateTChar,
      Department,
      MedicalInstitutions,
      PersonalNum,
      StsIssue,
      StsReturn
    } = this.formRef.current.getFieldsValue()

    this.setState({ isLoading: true })
    IntroduceLetterReturnInfoInputService.filterIntroduceLetterReturnInfoInputService({
      DateFChar:
        DateFChar > DateTChar ? DateTChar.format('YYYY/MM/DD') : DateFChar.format('YYYY/MM/DD'),
      DateTChar: DateTChar.format('YYYY/MM/DD'),
      PersonalNum: PersonalNum,
      Department: Department,
      MedicalInstitutions: MedicalInstitutions,
      StsIssue: StsIssue,
      StsReturn: StsReturn
    })
      .then(res => {
        console.log(res.data)
        this.setState({ isLoading: false })
        this.formRef.current.setFieldsValue({
          displayList: res.data
        })
        this.setState({
          displayList: res.data
        })
      })
      .catch(error => {
        this.setState({ isLoading: false })
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
        this.formRef.current.setFieldsValue({
          displayList: []
        })
        this.setState({
          displayList: []
        })
      })
      .finally(() => this.setState({ isLoading: false }))

    this.formRef.current.setFieldsValue({
      DateFChar: DateFChar > DateTChar ? DateTChar : DateFChar
    })
  }

  showPersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                PersonalNum: output.recordData.personal_number_id,
                kanji_name: output.recordData.kanji_name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  showIntroduceLetterVariousMasterInquiry = (manageCode) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0887001_IntroduceLetterVariousMasterInquiry
            Li_SearchChar={this.formRef.current?.getFieldValue('Department')}
            Li_ManageCode={manageCode}
            onFinishScreen={(output) => {
              console.log(output)
              if (manageCode == 7) {
                this.formRef.current?.setFieldsValue({
                  Department: output.recordData.various_codes,
                  department_name: output.recordData.department_name
                })
              } else if (manageCode == 4) {
                this.formRef.current?.setFieldsValue({
                  MedicalInstitutions: output.recordData.various_codes,
                  medical_institution_name: output.recordData.medical_institution_name
                })
              }
              this.forceUpdate()
              this.closeModal()
            }}
          />
        )
      }
    })
  }

  showPersonalInfoInquirySub = record => {
    // let title = '個人情報照会' + ' [' + record.personal_number_id + ']'
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2584019_PersonalInfoInquirySub Li_PersonalNum={record.personal_number_id} />
        )
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='introduce-letter-return-info-input'>
        <Card title='紹介状返送情報入力'>
          <Form ref={this.formRef} onFinish={this.onFinish} >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='DateFChar'
                        label='受診日'
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={true}
                        />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name='DateTChar' style={{ marginRight: '10px' }}>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          allowClear={true}
                        />
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        label='個人番号'
                        name='PersonalNum'
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-8'
                          allowClear={true}
                          onPressEnter={() => this.showPersonalInfoSearchQuery()}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.showPersonalInfoSearchQuery()
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='kanji_name'>
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content'
                          }}
                        >
                          {this.formRef.current?.getFieldValue('kanji_name')}
                        </div>
                      </Form.Item>
                    </div>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='Department'
                        label='診療科'
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-5'
                          allowClear={true}
                          onPressEnter={() => this.showIntroduceLetterVariousMasterInquiry(7)}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                Department: '',
                                department_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.showIntroduceLetterVariousMasterInquiry(7)
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='department_name'>
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content'
                          }}
                        >
                          {this.formRef.current?.getFieldValue('department_name')}
                        </div>
                      </Form.Item>
                    </div>
                  </div>

                  <div className='box_search_inner'>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='MedicalInstitutions'
                        label='医療機関'
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-5'
                          allowClear={true}
                          onPressEnter={() => this.showIntroduceLetterVariousMasterInquiry(4)}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                MedicalInstitutions: '',
                                medical_institution_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.showIntroduceLetterVariousMasterInquiry(4)
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='medical_institution_name'>
                        <div
                          style={{
                            display: 'flex',
                            width: '200px'
                          }}
                        >
                          {this.formRef.current?.getFieldValue('medical_institution_name')}
                        </div>
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='StsIssue'
                      label='紹介状'
                      {...labelCol}
                    >
                      <Select
                        style={{ width: '100px' }}
                      >
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={1}>発行</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='StsReturn'
                      label='返送結果'
                      {...labelCol}
                    >
                      <Select
                        style={{ width: '100px' }}
                      >
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={1}>あり</Select.Option>
                        <Select.Option value={2}>なし</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <Button
                    icon={<SearchOutlined />}
                    style={{ float: 'right' }}
                    htmlType='submit'
                    type='primary'
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>

              <Table
                size='small'
                bordered
                dataSource={this.state.displayList}
                rowKey={record => record.id}
                pagination={this.state.displayList.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: resizableTableScroll(100) }}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: (event) => { this.handleClick('返送情報入力', record) }
                  };
                }}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date_on'
                  className='column-size-date'
                  render={(value) => (
                    <div>{moment(value)?.format('YYYY/MM/DD (ddd)')}</div>
                  )}
                />
                <Table.Column
                  title='受付No'
                  dataIndex='accepted_no'
                  className='column-size-6'
                  render={(value) => (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )}
                />
                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  className='column-size-10'
                  render={(value, record) => (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )}
                />
                <Table.Column
                  title='メモ'
                  dataIndex='Expression_34'
                  className='column-size-2'
                  render={(text, record) => {
                    let icon = null
                    switch (text) {
                      case 1:
                        icon = <InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />
                        break
                      case 3:
                        icon = <WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />
                        break
                      case 5:
                        icon = <CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />
                        break
                      default:
                        icon = <InfoOutlined />
                        break
                    }
                    return (
                      <Button
                        icon={icon}
                        size='small'
                        style={{ borderRadius: '12px' }}
                        onClick={() => this.showPersonalInfoInquirySub(record)}
                      />
                    )
                  }}
                />
                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  className='column-size-40'
                />
                <Table.Column
                  title='診療科'
                  dataIndex='department_name'
                  className='column-size-60'
                />
                <Table.Column
                  title='医療機関'
                  dataIndex='medical_institutions_short_name'
                  className='column-size-60'
                />
                <Table.Column
                  title='発行'
                  dataIndex='Expression_21'
                  className='column-size-6'
                  render={(value, record) => (
                    <div style={{ textAlign: 'center', color: Color(record.Expression_22)?.Foreground }}>
                      {value}
                    </div>
                  )}
                />
                <Table.Column
                  title='返送'
                  dataIndex='Expression_23'
                  className='column-size-6'
                  render={(value, record) => (
                    <div style={{ textAlign: 'center', color: Color(record.Expression_24)?.Foreground }}>
                      {value}
                    </div>
                  )}
                />
                <Table.Column
                  title='おたずね'
                  dataIndex='Expression_25'
                  className='column-size-6'
                  render={(value, record) => (
                    <div style={{ textAlign: 'center', color: Color(record.Expression_26)?.Foreground }}>
                      {value}
                    </div>
                  )}
                />
                <Table.Column
                  title='返送内容'
                  dataIndex='Expression_27'
                  className='column-size-60'
                />
                <Table.Column
                  className='column-size-2'
                  key='action'
                  fixed='right'
                  render={(row) => (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='inquiry'
                            onClick={() => this.handleClick('受診照会', row)}
                          >
                            受診照会
                          </Menu.Item>
                          <Menu.Item
                            key='input'
                            onClick={() => this.handleClick('返送情報入力', row)}
                          >
                            返送情報入力
                          </Menu.Item>
                          <Menu.Item
                            key='maintenance'
                            onClick={() => this.handleClick('紹介状保守', row)}
                          >
                            紹介状保守
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />} />
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          destroyOnClose={true}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2804001_IntroduceLetterReturnInfoInput)
