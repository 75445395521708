import { message } from "antd";
import DistrictInfoMaintainService from "services/Others/DistrictInfoMaintain/DistrictInfoMaintainService";


const DistrictInfoMaintainAction = {
  getScreenData(data) {
    return DistrictInfoMaintainService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getDistrictInfo(data) {
    return DistrictInfoMaintainService.getDistrictInfo(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  save(data) {
    return DistrictInfoMaintainService.save(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  delete(data) {
    return DistrictInfoMaintainService.delete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default DistrictInfoMaintainAction;