import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Button, Select, Input, Modal, Tooltip, Dropdown, Menu } from 'antd'
import { SaveOutlined, MenuOutlined } from '@ant-design/icons'
import DataImportAction from 'redux/DataMigration/DataImport.action.js'

class DataImportSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      disabledSaveBtn: true,
    }
  }

  componentDidMount() {
    this.getImportSettings(0)
  }

  getImportSettings = (value) => {
    DataImportAction.getImportSettings({ selectedTable: value })
      .then((res) => {
        this.formRef.current?.setFieldsValue({ dataSource: res })
        this.setState({ dataSource: res })
      })
  }

  /**
 * 値の変更
 * @param {*} value
 * @param {*} record
 * @param {*} index
 */
  onChangeValue = (value, record, index) => {
    let arrTemp = [...this.state.dataSource]
    let obj = {
      ...record,
      importColumn: Number(value), //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj

    this.setState({
      dataSource: arrTemp, // テーブル更新
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      dataSource: arrTemp
    })
  }

  save = () => {
    let params = {
      selectedTable: this.formRef.current?.getFieldValue('table'),
      dataSource: this.state.dataSource
    }
    DataImportAction.saveImportSettings(params)
      .then((res) => {
        this.getImportSettings(this.formRef.current?.getFieldValue('table'))
      })
  }

  render() {
    return (
      <div className='data-import-settings'>
        <Card title='データ取込設定' style={{ width: 'fit-content' }}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={this.props.Li_Child ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => {
                        console.log('検査情報出力')
                      }}>
                      検査情報出力
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.Li_PersonalNum || this.props.Li_ReserveNum) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Form ref={this.formRef} autoComplete='off'>
            <div className='box_container' style={{ width: 'fit-content' }}>
              <Form.Item
                name='table'
                label='テーブル'
              >
                <Select
                  style={{ width: 200 }}
                  defaultValue={0}
                  onChange={(value) => this.getImportSettings(value)}
                >
                  <Select.Option value={0} >{'個人情報'}</Select.Option>
                  <Select.Option value={1} >{'個人情報（住所）'}</Select.Option>
                  <Select.Option value={2} >{'事業所情報'}</Select.Option>
                  <Select.Option value={3} >{'事業所情報（職場）'}</Select.Option>
                  <Select.Option value={4} >{'保険者情報'}</Select.Option>
                  <Select.Option value={5} >{'パターン'}</Select.Option>
                  <Select.Option value={6} >{'コース'}</Select.Option>
                  <Select.Option value={7} >{'カテゴリ'}</Select.Option>
                  <Select.Option value={8} >{'検査項目情報'}</Select.Option>
                  <Select.Option value={9} >{'部位分類'}</Select.Option>
                  <Select.Option value={10} >{'部位マスタ'}</Select.Option>
                  <Select.Option value={11} >{'所見マスタ'}</Select.Option>
                  <Select.Option value={12} >{'指導事項'}</Select.Option>
                </Select>
              </Form.Item>
              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 550 }}
                rowKey={(record) => record.columnName}
                style={{ width: 500 }}
              >
                <Table.Column title='カラム名' dataIndex='columnName' />
                <Table.Column
                  title='取込列'
                  dataIndex='importColumn'
                  render={(value, record, index) => {
                    return (
                      <Form.Item name={['dataSource', index, 'importColumn']}>
                        <Input
                          style={{
                            fontWeight: (record.changeFlag ?? 0) ? 'bold' : 'normal', //変更時は太字
                          }}
                          onPressEnter={event => {
                            this.onChangeValue(event.target.value, record, index)
                          }}
                          onBlur={event => {
                            this.onChangeValue(event.target.value, record, index)
                          }}
                        />

                      </Form.Item>
                    )
                  }}
                />
              </Table>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  disabled={this.state.disabledSaveBtn}
                  onClick={() => this.save()}
                >
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DataImportSetting)