import React from "react";
import { connect } from "react-redux";
import { Card, Table, Form, Menu, Button, message, Dropdown } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import ModalDraggable from 'components/Commons/ModalDraggable'
import {
  getRadioGraphyInspectMaintainAction,
  saveAndUpdateRadioGraphyInspectMaintainAction,
  deleteRadioGraphyInspectMaintainAction
} from "redux/InspectionMaintenance/RadiographyInspectMaintain/RadiographyInspectMaintain.actions";
import WS1871002_RadiographyInspectEdit from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1871002_RadiographyInspectEdit.jsx";
import WS1871014_Copy from "./WS1871014_Copy";
// 部位所見
import WS1872001_RadiographySiteFindingsMaintain from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1872001_RadiographySiteFindingsMaintain.jsx";
// 指導
import WS1875001_RadiographyGuideMaintain from "pages/MS_InspectionMaintenance/HGHP6200_RadiographyInspectMaintain/WS1875001_RadiographyGuideMaintain.jsx";
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS1871001_RadiographyInspectMaintain extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '読影検査保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      isLoading: true,
      rowSelect: {},
      isAddRow: false,
      current_page: 1,
      pageSize: 15,
    };
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = () => {
    this.setState({ isLoading: true });
    getRadioGraphyInspectMaintainAction()
      .then(res => {
        if (res?.data) {
          this.setState({
            dataSource: res.data,
            rowSelect: res.data.length > 0 ? res.data[0] : {}
          });
        }
      })
      .catch(err => message.error(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  getDataThisComponent = (current = this.state.current_page, pageSize = this.state.pageSize) => {
    return this.state.dataSource.slice((current - 1) * pageSize, current * pageSize);
  }

  // 指導処理
  Guidance = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS1875001_RadiographyGuideMaintain
            Li_InspectClassifyCode={this.state.rowSelect.exam_classification_code}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  // 部位所見
  siteFindings = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '95%',
        component: (
          <WS1872001_RadiographySiteFindingsMaintain
            Li_InspectClassifyCode={record.exam_classification_code}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  // 複写
  copy = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS1871014_Copy
            Li_CopySource={this.state.rowSelect.interpretation_exam_item_code}
            Li_CopySourceName={this.state.rowSelect.interpretation_exam_name}
            Lo_Copy={''}
            onFinishScreen={() => {
              this.closeModal()
              this.loadData();
            }}
          />
        ),
      },
    });
  }

  // データ新規・編集
  editData = (newFlag, record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS1871002_RadiographyInspectEdit
            newFlag={newFlag}
            record={record}
            onSave={(output) => {
              saveAndUpdateRadioGraphyInspectMaintainAction(output)
                .then(res => {
                  this.closeModal()
                  this.loadData()
                })
            }}
            onDelete={(output) => {
              deleteRadioGraphyInspectMaintainAction(output)
                .then(res => {
                  this.closeModal()
                  this.loadData()
                })
            }}
          />
        ),
      },
    });
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="radiography-inspect-maintain">
        <Card title="読影検査保守" className="mb-2">
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Table
              bordered
              dataSource={this.getDataThisComponent(this.state.current_page)}
              loading={this.state.isLoading}
              size="small"
              className='mt-3'
              pagination={false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(25) }}
              onRow={(record, index) => ({
                onClick: e => this.setState({ rowSelect: record }),
                onDoubleClick: () => { this.editData(false, record) }
              })}
            >

              <Table.Column
                className="column-size-10"
                title="読影コード"
                dataIndex="interpretation_exam_item_code"
              />

              <Table.Column
                className="column-size-10"
                title="検査コード"
                dataIndex="medical_exam_exam_code"
                render={(value) => (
                  <div
                    style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                className="column-size-30"
                title="読影略称"
                dataIndex="interpretation_exam_short_name"
              />

              <Table.Column
                title="読影名称"
                dataIndex="interpretation_exam_name"
              />

              <Table.Column
                className="column-size-8"
                title="モダリティ"
                dataIndex="modality_code"
              />

              <Table.Column
                className="column-size-10"
                title="検査項目群"
                dataIndex="exam_item_group"
              />

              <Table.Column
                className="column-size-10"
                title="マスタ分類"
                dataIndex="exam_classification_code"
              />

              <Table.Column
                className="column-size-10"
                title="前回分類"
                dataIndex="group"
              />

              <Table.Column
                className="column-size-5"
                title="医師"
                dataIndex="doctor_persons"
                render={(value) => (
                  <div
                    style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                className="column-size-5"
                title="技師"
                dataIndex="technician_persons"
                render={(value) => (
                  <div
                    style={{ textAlign: 'right' }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                width={250}
                title="メモ"
                dataIndex="options"
                render={(value) => (
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: 250
                    }}
                  >
                    {value}
                  </div>
                )}
              />

              <Table.Column
                align='center'
                width={40}
                title={
                  <Button
                    size='small'
                    type='primary'
                    icon={<PlusOutlined />}
                    style={{ textAlign: 'center' }}
                    disabled={this.state.isAddRow}
                    onClick={() => {
                      this.editData(true, false)
                    }}
                  />
                }
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='変更'
                            onClick={() => {
                              this.editData(false, record)
                            }}
                          >変更
                          </Menu.Item>

                          <Menu.Item
                            hidden={record.exam_classification_code == ''}
                            key='指導'
                            onClick={() => {
                              this.Guidance(record)
                            }}
                          >指導
                          </Menu.Item>

                          <Menu.Item
                            hidden={record.exam_classification_code == ''}
                            key='部位所見'
                            onClick={() => {
                              this.siteFindings(record)
                            }}
                          >部位所見
                          </Menu.Item>

                          <Menu.Item
                            hidden={record.interpretation_exam_item_code == '' || record.interpretation_exam_name == ''}
                            key='複写'
                            onClick={() => {
                              this.copy(record)
                            }}
                          >複写
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        style={{ width: '100%' }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            })
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1871001_RadiographyInspectMaintain);
