import React from 'react'
import { connect } from 'react-redux'
import GuideMatterContentAction from 'redux/InputBusiness/NotInputCheckCategory/GuideMatterContent.action'
import { Card, Form, Input, Checkbox, Button, Modal, message } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx'
import WS2637046_TypeSelect from 'pages/IN_InputBusiness/V4IN0302000_NotInputCheckCategory/WS2637046_TypeSelect.jsx'
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx'
import WS0272001_CautionGuideNotesSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx'
import CautionGuideNotesSearchQueryAction from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/CautionGuideNotesSearchQuery.actions'
import Color from 'constants/Color'
import ModalDraggable from 'components/Commons/ModalDraggable'

const smGrid = {
  labelCol: { style: { width: 70 } },
}

const boxTitleStyle = {
  textAlign: 'center', backgroundColor: '#4cb0a4', color: '#ffffff',
  padding: '5px 10px', margin: '-10px 0 0 -10px'
}
class WS2637041_GuideMatterContent extends React.Component {

  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '指導事項内容'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      manualDisabled: false,
      serialNum: null
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 初期処理
   */
  index = () => {
    const record = { ...this.props.record }
    const Lio_AutomaticJudgeWeight = record.auto_judgment_weight > 0 ? record.auto_judgment_weight : ''

    // 連番
    let num = record.serial_number ?? null
    this.setState({
      serialNum: num
    })

    const params = {
      general_comment_code: this.checkData(record.general_comment_code, 'number'),
      overall_comment: this.checkData(record.overall_comment, 'text'),
      input_flag: this.checkData(record.input_flag, 'text'),
      judgment_category_code: this.checkData(record.judgment_category_code, 'number'),
    }

    GuideMatterContentAction.index(params)
      .then(res => {
        if (res) {

          const data = {
            id: record.id ?? null,
            serial_number: this.state.serialNum ?? '',
            general_comment_code: record.general_comment_code ?? '',
            overall_comment: record.overall_comment ?? '',
            input_flag: record.input_flag ?? '',
            judgment_category_code: record.judgment_category_code ?? '',
            judgment_basic: record.judgment_basic ?? '',
            auto_judgment_weight: Lio_AutomaticJudgeWeight,
            auto_judgment_output_type: record.auto_judgment_output_type ?? '',
            course_level_2: record.course_level_2 ?? '',
            course_level_3: record.course_level_3 ?? '',
            judgment_weight: record.judgment_weight ?? '',
            judgeBasicColorNum: record.judgeBasicColorNum ?? '',
            ...res
          }
          // Formに一括設定
          this.setFieldsValueAll(data)
          this.setState({ manualDisabled: res?.manualDisabled })
        }
      })
  }

  isEmpty(val) {
    return (val === undefined || val == null || val.length <= 0) ? true : false
  }
  checkData(value, type) {
    const val = type === 'number' ? 0 : ''
    return !this.isEmpty(value) ? value : val
  }

  /**
   * Form設定
   * @param {*} namePath
   * @param {*} value
   */
  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFieldsValue({ [namePath]: value })
  }

  /**
   * Formに一括設定
   * @param {*} data
   */
  setFieldsValueAll = (data) => {
    if (data) {
      const keys = !this.isEmpty(Object.keys(data)) ? Object.keys(data) : []
      const values = !this.isEmpty(Object.values(data)) ? Object.values(data) : []
      if (values.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          for (let y = 0; y < values.length; y++) {
            if (keys[i] === 'judgment_category_code' || keys[i] === 'auto_judgment_weight') {
              const check = values[i] > 0
              this.setFormFieldValue(keys[i], check ? values[i] : '')
            } else {
              this.setFormFieldValue(keys[i], values[i])
            }
          }
        }
      }
    }
  }

  /**
   * 判定変更後、重みも変更する
   */
  changeAutomaticJudgeBasicJudge() {
    const judge = this.checkData(this.formRef?.current?.getFieldValue('judgment_basic'), 'string')
    if (judge === '') return

    const params = {
      Li_JudgeLevel: this.checkData(this.props.Li_JudgeLevel, 'number'),
      judgment_basic: judge
    }
    GuideMatterContentAction.changeAutomaticJudgeBasicJudge(params)
      .then(res => {
        this.setFormFieldValue('auto_judgment_weight', res.auto_judgment_weight)
      })
  }

  /**
   * シークレットモード変更時処理
   */
  changeManualBySecretMode() {
    let secretMode = this.formRef?.current?.getFieldValue('secretMode')
    if (!secretMode && !this.state.manualDisabled) {
      // 手動で変更されている:チェック変更
      this.setFormFieldValue('manual', 1)
    }
  }

  /**
 * テキストコード処理
 */
  changeCommentCode = () => {
    this.setState({ isloadding: true })

    let params = {
      Li_IdentifyCode: 'S',
      Search: '',
      Li_CategoryCode: 0,
      GuidanceContentKeyword: ''
    }

    CautionGuideNotesSearchQueryAction.getGuideItemInfo(params)
      .then(res => {
        const comment_code = this.formRef?.current?.getFieldValue('general_comment_code');
        const guideItem = res.find(item => item.comment_code == comment_code);
        if (guideItem) {
          this.setFormFieldValue('overall_comment', guideItem.comment_content)
        }
      }).catch(error => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
        }
      }).
      finally(() => this.setState({ isloadding: false }))
  }


  /**
   * テキストエリア変更時処理
   */
  changeManualByLeadershipMatters = () => {
    const manual = this.formRef?.current?.getFieldValue('secretMode')
    if (!manual) {
      // 手動で変更されている:チェック変更
      this.setFormFieldValue('manual', 1)
    }
  }

  /**
   * 注意・指導事項検索・照会 モーダル
   */
  showWS0272001_CautionGuideNotesSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0272001_CautionGuideNotesSearchQuery
            Li_IdentifyCode={'S'}
            Li_PatternCode={this.props.Li_PatternCode}
            onFinishScreen={(output) => {
              if (output.Lo_recordData) {
                // コード
                this.setFormFieldValue('general_comment_code', output.Lo_recordData.comment_code)
                // 指導事項内容
                this.setFormFieldValue('overall_comment', output.Lo_recordData.comment_content)
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * カテゴリ検索・照会(単体) モーダル
   */
  showCategorySearchQuerySingle() {
    let categoryCode = this.formRef.current?.getFieldValue('judgment_category_code') ?? ''
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0267001_CategorySearchQuerySingle
            Lio_CategoryCode={categoryCode}
            onFinishScreen={(output) => {
              // 画面に反映
              this.formRef.current?.setFieldsValue({
                judgment_category_code: output?.Lio_CategoryCode,
                category_name: output?.Lio_CategoryName,
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 種別選択 モーダル
   */
  showWS2637046_TypeSelect = () => {
    const TypeSelectList = this.props.TypeSelectList
    if (TypeSelectList !== '') {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 400,
          component:
            <WS2637046_TypeSelect
              Li_TypeSelectList={TypeSelectList}
              onScreenFinish={(output) => {
                // 画面に反映
                this.formRef.current?.setFieldsValue({
                  auto_judgment_output_type: output?.Lio_Type
                })
                this.closeModal()
              }}
            />
          ,
        },
      })
    }
  }

  /**
   * 判定選択
   * @param {*} data
   */
  showWS0285001_JudgeQuery = (data) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0285001_JudgeQuery
            Li_JudgeLevel={this.props.Li_JudgeLevel}
            onFinishScreen={async (output) => {
              if (output.recordData) {
                // 判定 変更
                await this.setFormFieldValue('judgment_basic', output.recordData.judgment_result)
                await this.setFormFieldValue('judgeBasicColorNum', output.recordData.judgmentColorNum)
                // 重み 変更
                await this.changeAutomaticJudgeBasicJudge()
              }
              await this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 保存ボタン押下処理
   */
  save = () => {
    // Formの入力値をすべて取得
    const value = this.formRef?.current?.getFieldsValue(true)

    if (value.overall_comment === '') {
      message.error('コメント内容が入力されていません')
      return
    }

    const params = {
      ...this.props.record,
      id: this.props.record?.id ?? null, // 新規追加の場合、idはnull
      Li_ReserveNum: this.checkData(this.props.Li_ReserveNum, 'number'),
      serial_number: this.checkData(value.serial_number, 'number'),
      general_comment_code: this.checkData(value.general_comment_code, 'number'),
      overall_comment: this.checkData(value.overall_comment, 'text'),
      judgment_category_code: this.checkData(value.judgment_category_code, 'number'),
      judgment_basic: this.checkData(value.judgment_basic, 'text'),
      auto_judgment_weight: this.checkData(value.auto_judgment_weight, 'number'),
      auto_judgment_output_type: this.checkData(value.auto_judgment_output_type, 'text'),
      course_level_2: value.course_level_2 ? 1 : 0,
      course_level_3: value.course_level_3 ? 1 : 0,
      secretMode: this.checkData(value.secretMode, 'number'),
      manual: this.checkData(value.manual, 'number'),
      judgeBasicColorNum: this.checkData(value.judgeBasicColorNum, 'number'),
      input_flag: value.secretMode ? 'S' : 'H', //保存ボタンを押した場合、手動にチェックがつく状態にする為
      Li_IdentifyCode: 'S'
    }

    GuideMatterContentAction.save(params)
      .then(res => {
        if (this.props.onUpdateGuide) {
          // 親画面の表示内容更新させる　
          // （最終的にWS2637001_OverallResultDisplayInputのgetGuideCommentInputを実行させる）
          this.props.onUpdateGuide()
        }
      })
  }

  /**
   * 削除ボタン押下処理
   */
  delete = () => {
    // 削除確認
    Modal.confirm({
      content: '削除します。よろしいですか？',
      okText: '削　除',
      cancelText: 'キャンセル',
      okType: 'danger primary',
      // okButtonProps: 'danger',
      onOk: () => {
        const params = {
          id: this.props.record.id
        }
        GuideMatterContentAction.delete(params)
          .then(res => {
            if (this.props.onUpdateGuide) {
              // 親画面の表示内容更新させる　
              // （最終的にWS2637001_OverallResultDisplayInputのgetGuideCommentInputを実行させる）
              this.props.onUpdateGuide()
            }
          })
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  onFinish(values) {

  }

  setSelectCheckBox(name, value) {
    this.setFormFieldValue(name, value ? 1 : 0)
    this.forceUpdate()
  }

  render() {
    return (
      <div className='guide-matter-content'>
        <Card
          title={`指導事項内容 [${(this.props.record === null) ? '新規追加' : '変更'}] : [${this.props.personalNumberId}] ${this.props.KanjiName}`}
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal' style={{ alignItems: 'start' }}>
                <div className='box_container'>
                  <Form.Item
                    label='連　番'
                    name='serial_number'
                  >
                    <Input
                      style={{ textAlign: 'right' }}
                      className='input-size-10'
                      disabled={(this.state.serialNum != null) ? false : true}
                    />
                  </Form.Item>
                  <Form.Item
                    label='コード'
                    name='general_comment_code'
                  >
                    <Input
                      style={{ textAlign: 'right' }}
                      className='input-size-10'
                      // onBlur={() => {
                      //   this.changeCommentCode()
                      // }}
                      onPressEnter={() => {
                        this.changeCommentCode()
                      }}
                    />
                  </Form.Item>
                </div>

                {/* 指導事項内容　入力 */}
                <Form.Item
                  name='overall_comment'
                >
                  <Input.TextArea
                    rows={3}
                    type='text'
                    style={{ width: 500 }}
                    onBlur={() => {
                      this.changeManualByLeadershipMatters()
                    }}
                  />
                </Form.Item>
                <Button
                  type='primary'
                  onClick={() => {
                    // 注意・指導事項検索・照会 モーダル
                    this.showWS0272001_CautionGuideNotesSearchQuery()
                  }}
                >
                  参照
                </Button>
              </div>

              <div className='box_inner_horizontal'>
                <div className='box_border'>
                  <div className='box_inner_horizontal'>
                    <div style={boxTitleStyle}>
                      自動判定
                    </div>
                    <span style={{ marginTop: '-3px' }}>
                      コメントの作成事由
                    </span>
                  </div>
                  <div className='box_inner_vertical' style={{ gap: 0, padding: '10px' }}>
                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='judgment_category_code'
                        label='カテゴリ'
                        {...smGrid}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-4'
                          onSearch={() => this.showCategorySearchQuerySingle()}
                          onPressEnter={() => this.showCategorySearchQuerySingle()}
                        />
                      </Form.Item>
                      <span style={{ marginTop: '5px' }}>
                        {this.formRef?.current?.getFieldValue('category_name')}
                      </span>
                    </div>

                    <Form.Item
                      name='judgment_basic'
                      label='判定'
                      {...smGrid}
                    >
                      <Input.Search
                        className='input-search-size-judgment'
                        onSearch={() => {
                          // 判定選択
                          this.showWS0285001_JudgeQuery()
                        }}
                        style={{ color: Color(this.formRef?.current?.getFieldValue('judgeBasicColorNum'))?.Foreground }}
                        onBlur={() =>
                          // 重みを変更
                          this.changeAutomaticJudgeBasicJudge()
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name='auto_judgment_weight'
                      label='重み'
                      {...smGrid}
                    >
                      <Input className='input-size-number-6' />
                    </Form.Item>
                  </div>
                </div>

                <div className='box_border'>
                  <div className='box_inner_horizontal'>
                    <div style={boxTitleStyle}>
                      結果表印字
                    </div>
                    <span style={{ marginTop: '-3px' }}>
                      結果表の設定が必要
                    </span>
                  </div>

                  <div className='box_inner_vertical' style={{ gap: 0, padding: '10px' }}>
                    <Form.Item
                      name='auto_judgment_output_type'
                      className='input-size-12'
                      label='種別'
                    >
                      <Input
                        className='input-search-size-10'
                      // onSearch={() => {
                      //   // 種別選択 モーダルを開く
                      //   this.showWS2637046_TypeSelect()
                      // }}
                      />
                    </Form.Item>
                    <Form.Item name='secretMode' valuePropName='checked'>
                      <Checkbox
                        onChange={async (event) => {
                          await this.setSelectCheckBox('secretMode', event.target.checked)
                          // 手動で変更されている:チェック変更
                          await this.changeManualBySecretMode()
                        }}>
                        シークレットモード
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name='course_level_2' valuePropName='checked'>
                      <Checkbox
                        onChange={(event) => {
                          this.setSelectCheckBox('course_level_2', event.target.checked)
                        }}>
                        レベル２
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name='course_level_3' valuePropName='checked'>
                      <Checkbox
                        onChange={(event) => {
                          this.setSelectCheckBox('course_level_3', event.target.checked)
                        }}>
                        レベル３
                      </Checkbox>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form.Item
                  name='manual'
                  valuePropName='checked'
                >
                  <Checkbox
                    disabled={this.state.manualDisabled}
                  >
                    手動で変更されている
                  </Checkbox>
                </Form.Item>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button
                    type='primary'
                    danger
                    onClick={() => this.delete()}
                    icon={<DeleteOutlined />}
                    style={{ display: ((this.props.record === null) ? 'none' : '') }} //新規追加の場合、削除ボタンは非表示
                  >
                    <span className='btn_label'>
                      削除
                    </span>
                  </Button>
                  <Button
                    type='primary'
                    onClick={() => this.save()}
                    icon={<SaveOutlined />}
                  >
                    <span className='btn_label'>
                      保存
                    </span>
                  </Button>
                </div>
              </div>

            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2637041_GuideMatterContent)
