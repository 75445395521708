
import JapaneseDateCoustom from 'components/Commons/JapaneseDateCoustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { debounce, isNull } from 'lodash'
import GetImage from 'constants/Images.js'
import MagicXpaFunc from 'helpers/MagicXpaFunc.js'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Regex } from 'helpers/CommonHelpers.js'
import { Form, Card, Row, Col, Input, Button, Select, Tabs, Modal, Radio, Space, message, Tag, Tooltip, Dropdown, Menu } from 'antd'
import { MoreOutlined, PlusOutlined, MenuOutlined, SwitcherOutlined, SaveOutlined, DeleteOutlined, WarningOutlined } from '@ant-design/icons'
import WS0248001_PersonalInfoSearchQuery from './WS0248001_PersonalInfoSearchQuery.jsx'
import WS0343010_PatientInfoQueryOthers from './WS0343010_PatientInfoQueryOthers.jsx'
import WS0341001_OfficeInfoMaintain from '../V4MS0002000_OfficeInfoMaintainDirectly/WS0341001_OfficeInfoMaintain'
import WS0343101_SpecialInfo from './WS0343001_PersonalInfoMaintain/WS0343101_SpecialInfo.jsx'
import WS0343102_SupplementtaryInfo from './WS0343001_PersonalInfoMaintain/WS0343102_SupplementtaryInfo.jsx'
import WS0343103_DestinationInfo from './WS0343001_PersonalInfoMaintain/WS0343103_DestinationInfo.jsx'
import WS0343104_CodeInfo from './WS0343001_PersonalInfoMaintain/WS0343104_CodeInfo.jsx'
import WS0343105_BelongsInfo from './WS0343001_PersonalInfoMaintain/WS0343105_BelongsInfo.jsx'
import WS0084001_PostCodeSearchEngine from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS1290001_InsurerNumberInquiry from 'pages/TK_SpecificMedicalExamination/V4TK0200003_ConsultTicketInputProcessList/WS1290001_InsurerNumberInquiry.jsx'
import WS2787004_AffiliationSelectSub from 'pages/KK_ResultOutput/V4TO0005000_RomotoArticle52/WS2787004_AffiliationSelectSub.jsx'
import WSFileManager_FileManager from 'pages/ZZ_Others/CUSTOMIZE_Custom/WSFileManager_FileManager.jsx'
import WS0344001_SupplementalInfoSetting from '../V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx'
import WS0382001_PersonalAttributesReacquire from './WS0382001_PersonalAttributesReacquire.jsx'
import WS2727001_PatientInfoQueryStard from './WS2727001_PatientInfoQueryStard.jsx'
import WS2586020_ConsultHistorySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2586020_ConsultHistorySub'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import PersonalInfoMaintainAction from 'redux/basicInfo/PersonalInfoMaintain/PersonalInfoMaintain.action'
import * as wanakana from 'wanakana'
import hankakuMoji from 'components/Commons/HankakuMoji'

// import NumberFormat from 'react-number-format'

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

const styleNumberFormat = {
  border: '1px solid #d9d9d9',
  outline: '#d9d9d9',
  width: '150px',
  height: '32px',
  borderRadius: '2px',
  padding: '0 7px'
}

const styleBorder = {
  width: '100%',
  border: '1px solid #CCCCCC',
  padding: '20px 10px 10px 10px',
  display: 'flex',
  flexDirection: 'column'
}

// アドレス タブ用　初期表示タブ
const initialAddressList = [{ key: '1', title: '住所1(既定)', closable: false }]

class WS0343001_PersonalInfoMaintain extends React.Component {
  static propTypes = {
    Li_PersonalNum: PropTypes.any
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.ref_input_email = React.createRef()
    this.ref_input_mobile_mail = React.createRef()

    // document.title = '個人情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: ''
      },
      personal: {},
      infoBasicPage: {
        registerDate: '', // 登録日 registration_date_on
        updateDate: '', // 変更日 updated_on
        birthYear: '', // 生年月日
        ageOnDay: '', // 現時点での年齢
        ageYearEnd: '', // 年度末時点での年齢
        office_kanji_name: '', // 事業所名称
        insurer_kanji_name: '', // 保険者名称
        short_name: '' // 所属名称
      },
      objBelongs: {}, // 所属
      objAddress: {}, // アドレス
      objOptions: {},
      // statusPage: 0, //  0：新規追加,  1：編集
      statusPage: 2, // statusPage: 1 自動発番の新規, statusPage: 2 手動発番の新規, statusPage: 3 変更
      addressList: initialAddressList, // アドレス タブ用
      addressHideAdd: true, // アドレス 追加タブの表示フラグ
      addressActiveKey: '1', // アドレス タブで選択されているkey
      disabledRegisterBtn: true, // 登録ボタンの活性
      disabledDeleteBtn: true, // 削除ボタンの活性
    }

    this.getValueChild = this.getValueChild.bind(this)
    this.createNewPersonal = this.createNewPersonal.bind(this)
    this.onChangePersonalInfo = debounce(this.onChangePersonalInfo, 300)
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.index()
  }

  /**
   * 初期表示処理
   */
  index = () => {
    PersonalInfoMaintainAction.index()
      .then(res => {
        // オプション情報保持
        this.setState({
          objOptions: res.data,
          statusPage: res.data.autoFlag ? 1 : 2
        })

        if (this.props.Li_PersonalNum) {
          // 個人情報取得
          this.searchPersonalNumberID(this.props.Li_PersonalNum)
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 再取得
   * @param {*} objData
   */
  setDataToPersonal = async (objData) => {
    let birthYear = objData?.Lo_DateBirth
      ? moment(objData?.Lo_DateBirth).format('YYYY')
      : this.state.infoBasicPage.birthYear

    // 所属
    let objBelongTemp = {
      ...this.state.objBelongs,
      insurer_number: objData.Lo_InsurerNum || this.state.objBelongs.insurer_number,
      insurer_card_symbol: objData.Lo_InsuranceCardSymbol || this.state.objBelongs.insurer_card_symbol,
      insurer_card_number: objData.Lo_InsuranceCardNum || this.state.objBelongs.insurer_card_number,
    }

    if (objData.Lo_InsurerNum || objData.Lo_InsuranceCardSymbol || objData.Lo_InsuranceCardNum) {
      // 医事属性にデータが存在する場合
      objBelongTemp = {
        ...objBelongTemp,
        changeFlag: 1 // 所属が変更されたフラグ
      }
    }

    // アドレス
    let objAddressTemp = {
      ...this.state.objAddress,
      postal_code: objData.Lo_ZipCode || this.state.objAddress.postal_code,
      address_1: objData.Lo_Address1 || this.state.objAddress.address_1,
      address_2: objData.Lo_Address2 || this.state.objAddress.address_2,
      phone_number: objData.Lo_PhoneNum1Fixed || this.state.objAddress.phone_number,
      cell_phone_number: objData.Lo_PhoneNum2Mobile || this.state.objAddress.cell_phone_number
    }

    if (objData.Lo_ZipCode || objData.Lo_Address1 || objData.Lo_Address2 || objData.Lo_PhoneNum1Fixed || objData.Lo_PhoneNum2Mobile) {
      // 医事属性にデータが存在する場合
      objAddressTemp = {
        ...objAddressTemp,
        changeFlag: 1 // アドレスが変更されたフラグ
      }
    }

    // personal_addresses用に加工
    let arrAddressTemp = [this.state.personal.personal_addresses]
    if (arrAddressTemp.length > 0) {
      arrAddressTemp[0] = objAddressTemp
    } else {
      arrAddressTemp.push(arrAddressTemp)
    }

    // 個人情報
    let personalTemp = {
      ...this.state.personal,
      kana_name: objData.Lo_KanaName || this.state.personal.kana_name,
      kanji_name: objData.Lo_KanjiName || this.state.personal.kanji_name,
      sex: objData.Lo_Gender || this.state.personal.sex,
      birthday_on: objData.Lo_DateBirth ? objData.Lo_DateBirth : this.state.personal.birthday_on,
      old_kana_name: objData.Lo_OldKanaName || this.state.personal.old_kana_name,
      personal_addresses: arrAddressTemp
    }

    await this.setState({
      personal: personalTemp,
      objBelongs: objBelongTemp,
      objAddress: objAddressTemp,
      infoBasicPage: {
        ...this.state.infoBasicPage,
        birthYear: birthYear,
        ageOnDay: objData?.Lo_DateBirth
          ? parseInt(moment().year() - birthYear).toString()
          : this.state.infoBasicPage.ageOnDay,
        ageYearEnd: objData?.Lo_DateBirth
          ? parseInt(moment().year() - birthYear + 1).toString()
          : this.state.infoBasicPage.ageYearEnd
      }
    })

    await this.formRef?.current?.setFieldsValue({
      ...personalTemp,
      objBelongs: objBelongTemp,
      objAddress: objAddressTemp
    })

    await this.forceUpdate()
    await this.onChangePersonalInfo(personalTemp.birthday_on, 'birthday_on')
  }


  /**
   * 表示用にデータを設定
   * @param {*} personal
   * @param {患者情報照会からのデータ} personalTemp
   */
  setDataToFields = (personal, personalTemp) => {
    let objBelongTemp = {}, objAddressTemp = {}
    let formatPersonal = {}
    let personal_belongs = []
    let personal_addresses = []
    let personal_specials = []
    let personal_supplements = []
    let personal_individual_addresses = []
    let personal_codes = []

    // 所属情報
    if (personal.personal_belongs?.length > 0) {
      personal_belongs = personal.personal_belongs.map(item => (
        {
          ...item,
          workplace_code: item.personal_workplaces?.workplace_code ?? '',
          office_kanji_name: item.office?.office_kanji_name ?? '',
          insurer_kanji_name: item.insurer?.insurer_kanji_name ?? '',
          short_name: item.personal_workplaces?.office_workplace?.short_name ?? '',
          insurer_card_serial_number: item.personal_workplace_expansions?.insurer_card_serial_number ?? '',
        }
      ))
    } else {
      // 患者情報照会からのデータを反映
      if (personalTemp) {
        objBelongTemp = {
          id: Math.round(Math.random() * 1000),
          personal_number_id: personal.personal_number_id,
          insurer_card_number: '',
          insurer_card_symbol: '',
          insurer_number: '',
          enabled_disabled: 1 //新規は有効
        }
        objBelongTemp = {
          ...objBelongTemp,
          ...personalTemp.insurer,
          changeFlag: 1 // アドレスが変更されたフラグ
        }
        personal_belongs.push(objBelongTemp)
      }
    }

    // アドレス
    // 初期値をコピー
    let tempAddressList = [...initialAddressList]
    // アドレス情報設定
    if (personal.personal_addresses?.length > 0) {
      personal_addresses = personal.personal_addresses
      // 住所2、住所3がある場合、タブ増やす
      for (let i = 1; i < personal.personal_addresses?.length; i++) {
        let number = String(i + 1)
        tempAddressList.push({ key: number, title: `住所${number}`, closable: true })
      }
    } else {
      // 患者情報照会からのデータを反映
      if (personalTemp) {
        objAddressTemp = {
          id: Math.round(Math.random() * 1000),
          personal_number_id: personal.personal_number_id,
          address_category: 1,
          postal_code: '',
          phone_number: '',
          cell_phone_number: '',
          email: '',
          mobile_mail: '',
          address_1: '',
          address_2: '',
          address: '',
          enabled_disabled: 1 //新規は有効
        }
        objAddressTemp = {
          ...objAddressTemp,
          ...personalTemp.address,
          changeFlag: 1 // アドレスが変更されたフラグ
        }
        personal_addresses.push(objAddressTemp)
      }
    }
    // アドレスタブ
    this.setState({
      addressList: tempAddressList,
      addressActiveKey: '1',
      addressHideAdd: (tempAddressList.length < 3) ? false : true,
    })

    // 特記
    if (personal.personal_specials?.length > 0) {
      personal_specials = personal.personal_specials;
    }
    // 補足
    if (personal.SupplementaryInfo?.length > 0) {
      personal_supplements = personal.SupplementaryInfo.map(item => ({
        ...item,
        personal_number_id: personal.personal_number_id
      }))
    }
    // 送付先
    if (personal.DestinationInfo?.length > 0) {
      personal.DestinationInfo.forEach(element => {
        if (element.send_document_code !== null) {
          personal_individual_addresses.push({
            ...element,
            personal_number_id: personal.personal_number_id
          })
        }
      })
    }
    // コード
    if (personal.CodeInfo?.length > 0) {
      personal_codes = personal.CodeInfo.map(item => ({
        ...item,
        personal_number_id: personal.personal_number_id
      }))
    }

    if (personalTemp) {
      // 患者情報照会からのデータを反映
      personal = {
        ...personal,
        ...personalTemp.personal
      }
    }
    if (personal.personal_number_id) {
      // 生年月日　関連
      let birthYear = personal?.birthday_on ? moment(personal?.birthday_on).format('YYYY') : '';

      let objTemp = {
        // 登録日
        registerDate: personal.registration_date_on ? moment(personal.registration_date_on).format('YYYY/MM/DD') : '',
        // 更新日
        updateDate: personal.updated_on ? moment(personal.updated_on).format('YYYY/MM/DD') : '',
        birthYear: birthYear,
        ageOnDay: this.calculateAge(personal.birthday_on, 0).toString(),
        ageYearEnd: this.calculateAge(personal.birthday_on, 1).toString(),
        office_kanji_name: personal_belongs[0]?.office?.office_kanji_name || '',
        insurer_kanji_name: personal_belongs[0]?.insurer?.insurer_kanji_name || '',
      }

      if (personal.personal_belongs?.length > 0) {
        objBelongTemp = { ...personal_belongs[0] }
      }
      if (personal.personal_addresses?.length > 0) {
        objAddressTemp = { ...personal_addresses[0] }
      }

      this.setState({
        infoBasicPage: {
          ...this.state.infoBasicPage,
          ...objTemp
        }
      })
    }

    delete personal.SupplementaryInfo
    delete personal.DestinationInfo
    delete personal.CodeInfo

    formatPersonal = {
      ...personal,
      personal_number_id: personal.personal_number_id,
      kana_name: personal.kana_name,
      kanji_name: personal.kanji_name,
      sex: personal.sex,
      birthday_on: personal.birthday_on ? personal.birthday_on : '',
      personal_belongs,
      personal_addresses,
      personal_specials,
      personal_supplements,
      personal_individual_addresses,
      personal_codes
    }

    this.setState({
      personal: formatPersonal,
      objBelongs: objBelongTemp,
      objAddress: objAddressTemp
    })

    this.formRef?.current?.setFieldsValue({
      ...formatPersonal,
      objBelongs: objBelongTemp,
      objAddress: objAddressTemp
    })
  }

  /**
   * 個人番号を検索する
   * @param {*} id
   * @param {患者情報がある場合設定する} personalTemp
   */
  searchPersonalNumberID = (id, personalTemp = null, specialFlag = false) => {

    const newNum = this.state.objOptions.autoFlag ? 1 : 2 // 自動発番か手動発番の新規追加モードの番号

    if (id) {
      const params = {
        personal_number_id: id,
        Option: this.state.objOptions.Option,
        OptionUser: this.state.objOptions.OptionUser,
        OptionDataAutoOnsetNum: this.state.objOptions.OptionDataAutoOnsetNum,
      }
      PersonalInfoMaintainAction.getPersonalInfo(params)
        .then(async (res) => {
          // リセット
          await this.resetPersonalInfo()
          if (res) {
            // res.ProcessDivision === 2 => 新規　、res.ProcessDivision === 3 => 変更
            let status // 0：新規追加,  1：編集
            if (res.personal_number_id && res.ProcessDivision === 3) {
              status = 3
            } else if (res.personal_number_id && res.ProcessDivision === 2) {
              status = newNum
            }
            if (res.kanji_name === '' && isNull(personalTemp)) {
              message.error('使用できない番号です')
              status = newNum
            }
            if (res.personal_number_id && res.kana_name) {
              // 既にms0300に個人情報が登録されている場合、personalTempよりresを優先する
              personalTemp = null
            }
            if (res.personalIdReference) {
              // 連携先で個人情報があった場合
              personalTemp = res.personalIdReference
            }
            await this.setDataToFields(res, personalTemp)
            this.setState({
              statusPage: status,
            })

            if (specialFlag) {
              await this.specialMention(this.state.personal)
            }
          }
        })
    } else {
      this.resetPersonalInfo()
      this.setState({
        statusPage: newNum, // 自動発番か手動発番の新規追加モードに切替
        // アドレス タブ 初期化
        addressList: initialAddressList,
        addressHideAdd: true, //新規追加モード時は非表示
        addressActiveKey: '1',
      })
    }

    // 登録ボタンの非活性
    this.setState({
      disabledRegisterBtn: true,
      disabledDeleteBtn: true
    })
  }

  /**
   * 個人情報のフィールドデータをリセット
   */
  resetPersonalInfo = () => {
    this.setState({
      infoBasicPage: {},
      personal: {},
      objBelongs: {},
      objAddress: {},
      disabledDeleteBtn: false
    })
    this.formRef.current?.resetFields()
  }

  /**
   * モーダルでの変更処理 (WS0248001_PersonalInfoSearchQuery, WS0343006_Extension)
   * @param {*} objChild
   * @param {*} screen
   */
  getValueChild = (objChild, screen) => {
    if (objChild.personal_number_id) {
      this.searchPersonalNumberID(objChild.personal_number_id)
    }
    if (screen === 'WS0343006') {
      let arrTemp = [...this.state.personal.personal_belongs]
      let index = arrTemp.findIndex(item => item.id === objChild.id)
      let objTemp = {
        ...arrTemp[index],
        ...objChild
      }
      arrTemp[index] = objTemp
      this.setState({
        personal: {
          ...this.state.personal,
          personal_belongs: arrTemp
        }
      })
    }
    this.closeModal()
  }

  /**
   * 新規追加　個人情報
   */
  createNewPersonal = () => {
    this.closeModal()
    // idを空にする 新規追加モードへ
    this.searchPersonalNumberID('')
  }

  /**
   * タブ変更処理　アドレス用
   * @param {*} activeKey
   */
  onChangeTabAddress = (activeKey) => {
    let objTemp = {}
    // タブ切替
    this.setState({ addressActiveKey: activeKey })
    // 登録済みの住所がない場合
    if (!this.state.personal.personal_addresses) return

    // アドレス情報リスト
    let arrAddressState = [...this.state.personal.personal_addresses]
    // アドレス登録件数
    let length = arrAddressState.length;

    if (length >= Number(activeKey)) {
      // 既存を表示
      const index = (Number(activeKey) - 1)
      objTemp = arrAddressState[index]
    }

    if (length < Number(activeKey)) {
      // 新規追加
      objTemp = {
        // ...prevObj,
        id: Math.random(), // 新しくidを設定
        personal_number_id: this.state.personal.personal_number_id
      };
      arrAddressState.push(objTemp)
    }

    this.setState({
      personal: {
        ...this.state.personal,
        personal_addresses: arrAddressState
      },
      objAddress: objTemp,
      addressActiveKey: activeKey
    })
  }

  /**
   * タブの追加、削除 アドレス用
   * @param {*} targetKey
   * @param {*} action
   */
  onEditTabAddress = (targetKey, action) => {
    if (action === 'add') {
      // 新規追加ボタンの場合
      this.addTabAddress(targetKey)
    } else if (action === 'remove') {
      // 削除ボタンの場合
      // 確認モーダルを表示してから、削除
      Modal.confirm({
        content: `[住所${targetKey}] の削除を行いますか`,
        okText: 'は　い',
        cancelText: 'いいえ',
        onOk: () => {
          this.removeTabAddress(targetKey)
        }
      })
    }
  }

  /**
   * タブ　追加　アドレス
   */
  addTabAddress() {
    let objTemp = {}
    let tabList = [...this.state.addressList]
    // keyを＋１する
    const activeKey = String(tabList.length + 1)

    // 住所が２つ以上ある場合、削除可
    tabList[0] = {
      ...tabList[0],
      closable: false
    }

    // タブ一覧に追加
    tabList.push({ key: activeKey, title: `住所${activeKey}`, closable: true })

    // 新規追加
    objTemp = {
      // ...prevObj,
      id: Math.random(), // 新しくidを設定
      personal_number_id: this.state.personal.personal_number_id
    }

    this.setState({
      addressList: tabList,
      objAddress: objTemp,
      addressActiveKey: activeKey
    })

    // 住所の設定は３つまで
    if (tabList.length === 3) {
      // 追加タブを非表示にする
      this.setState({ addressHideAdd: true })
    }
  }

  /**
   * タブ　削除　アドレス
   * @param {*} targetKey
   */
  removeTabAddress = async (targetKey) => {
    // async/await　実行の順番大事

    let tabList = [...this.state.addressList]
    // 最終行を削除
    tabList.pop()
    const activeKey = String(tabList.length)

    // 住所が１つだけの場合、削除不可
    if (tabList.length === 1) {
      tabList[0] = {
        ...tabList[0],
        closable: false
      }
    }
    // タブ一覧を更新
    await this.setState({
      addressList: tabList,
      addressActiveKey: activeKey
    })

    // 削除する要素番号
    const deleteIndex = Number(targetKey) - 1
    // アドレスの情報変更
    let arrTemp = []
    if (this.state.personal.personal_addresses) {
      arrTemp = [...this.state.personal.personal_addresses]
    }
    // 住所2が削除　かつ　住所3があった場合
    if (targetKey === '2' && arrTemp.length === 3) {
      // address_categoryを2⇒3に変更
      let obj = { ...arrTemp[2] }
      obj.address_category = 2
      arrTemp.splice(2, 1, obj)
    }
    // 削除
    await arrTemp.splice(deleteIndex, 1)

    await this.setState({
      personal: {
        ...this.state.personal,
        personal_addresses: arrTemp
      }
    })
    // タブ変更
    await this.onChangeTabAddress(activeKey)

    if (this.state.addressHideAdd) {
      // 追加タブを表示する
      this.setState({ addressHideAdd: false })
    }
  }

  /**
   *　変更処理 個人情報　名前、生年月日など　
   * @param {*} value
   * @param {*} name
   */
  onChangePersonalInfo = (value, name) => {
    if (name !== 'birthday_on') {
      // 生年月日以外
      this.setState({
        personal: {
          ...this.state.personal,
          [name]: value
        }
      })
    } else if (name === 'birthday_on') {
      // 生年月日
      let birthYear = moment(value).isValid() ? moment(value).format('YYYY') : ''
      let ageOnDay = '', ageYearEnd = ''
      if (birthYear !== '') {
        const birthdayDate = moment(value).format('YYYY/MM/DD')
        ageOnDay = this.calculateAge(birthdayDate, 0).toString()
        ageYearEnd = this.calculateAge(birthdayDate, 1).toString()
      }
      this.setState({
        infoBasicPage: {
          ...this.state.infoBasicPage,
          birthYear: birthYear,
          ageOnDay: ageOnDay,
          ageYearEnd: ageYearEnd
        },
        personal: {
          ...this.state.personal,
          birthday_on: value ? value : null
        }
      })
    }
    // 登録ボタンの活性
    if (this.state.disabledRegisterBtn) {
      this.setState({ disabledRegisterBtn: false })
    }
  }

  /**
   * 変更処理　所属情報
   * @param {変更された値} value
   * @param {変更されたキー名} name
   * @returns
   */
  onChangeBelongs = (value, name) => {
    // 現行で表示されている所属情報
    const prevObj = { ...this.state.objBelongs }

    // 所属情報リスト
    let arrTemp = []
    if (this.state.personal.personal_belongs ?? false) {
      // 既に登録されている場合
      arrTemp = [...this.state.personal.personal_belongs]
    }

    if (name === 'office_code' || name === 'branch_store_code') {
      this.setState({
        infoBasicPage: {
          ...this.state.infoBasicPage,
          office_kanji_name: ''
        }
      });
    } else if (name === 'insurer_number') {
      this.setState({
        infoBasicPage: {
          ...this.state.infoBasicPage,
          insurer_kanji_name: ''
        }
      });
    }

    // 数値型に変換
    if (name === 'insurer_number' || name === 'branch_store_code') {
      if (value) {
        value = parseInt(value)
      }
    }

    // 変更内容を配列に格納
    let obj = {
      ...prevObj,
      [name]: value,
      changeFlag: 1 // 所属が変更されたフラグ
    }

    const index = arrTemp.findIndex(item => item.id === prevObj.id)
    if (index > -1) {
      // 既に登録されている場合
      arrTemp[index] = obj
    } else {
      // 新規追加の場合、必ず有効にする
      obj.enabled_disabled = 1
      arrTemp.push(obj)
    }

    this.formRef.current.setFieldsValue({ personal_belongs: arrTemp })
    this.setState({
      personal: {
        ...this.state.personal,
        personal_belongs: arrTemp
      },
      objBelongs: obj
    })
    // 登録ボタンの活性
    if (this.state.disabledRegisterBtn) {
      this.setState({ disabledRegisterBtn: false })
    }
  }

  /**
   * 変更処理　アドレス情報
   * @param {変更された値} value
   * @param {変更されたキー名} name
   * @returns
   */
  onChangeAddress = (value, name) => {
    // 現行で表示されているアドレス情報
    const prevObj = { ...this.state.objAddress }
    // アドレス情報リスト
    let arrTemp = []
    if (this.state.personal.personal_addresses) {
      arrTemp = [...this.state.personal.personal_addresses]
    }

    // 数値型に変換
    if (name === 'address_category') {
      value = parseInt(value)
    }

    // 変更内容を配列に格納
    let obj = {
      ...prevObj,
      [name]: value,
      changeFlag: 1 // アドレスが変更されたフラグ
    }

    const index = arrTemp.findIndex(item => item.id === prevObj.id)
    if (index > -1) {
      // 既に登録されている場合
      arrTemp[index] = obj
    } else {
      // 新規
      arrTemp.push(obj)
    }

    this.formRef.current.setFieldsValue({ personal_addresses: arrTemp })
    this.setState({
      personal: {
        ...this.state.personal,
        personal_addresses: arrTemp
      },
      objAddress: obj
    })
    // 登録ボタンの活性
    if (this.state.disabledRegisterBtn) {
      this.setState({ disabledRegisterBtn: false })
    }
  }

  /**
   * 住所「既定」ボタン　押下処理　
   * @param {*} currentKey
   */
  setAddressToDefault = async (currentKey) => {
    // async/await必須
    let arrTemp = [...this.state.personal.personal_addresses]
    let newObj, arrNew = []
    if (arrTemp.length > 1) {
      const currentIndex = Number(currentKey) - 1
      const currentObj = { ...arrTemp[currentIndex] }
      // 一旦、削除
      arrTemp.splice(currentIndex, 1)
      // 先頭（住所1）の位置に追加
      arrTemp.unshift(currentObj)

      // address_categoryを振りなおす
      for (let i = 0; i < arrTemp.length; i++) {
        newObj = { ...arrTemp[i] }
        newObj.address_category = i + 1
        newObj.changeFlag = 1 //変更フラグ
        arrNew.push(newObj)
      }

      await this.setState({
        personal: {
          ...this.state.personal,
          personal_addresses: arrNew
        }
      })
      // 既定のタブに変更
      await this.onChangeTabAddress('1')

      // 登録ボタンの活性
      if (this.state.disabledRegisterBtn) {
        this.setState({ disabledRegisterBtn: false })
      }
    }
  }

  /**
   * 登録用のパラメータを整形
   * @returns
   */
  formatPersonal = () => {
    const { personal } = this.state

    let personal_belongs_submit = []
    let personal_addresses_submit = []
    let personal_specials_submit = []
    let personal_supplements_submit = []
    let personal_individual_addresses_submit = []
    let personal_codes_submit = []
    let birthdayOn = null

    if (personal.personal_belongs) {
      personal_belongs_submit = personal.personal_belongs
    }
    if (personal.personal_addresses) {
      personal_addresses_submit = personal.personal_addresses
    }
    if (personal.personal_specials) {
      personal_specials_submit = personal.personal_specials
    }
    if (personal.personal_supplements) {
      personal_supplements_submit = personal.personal_supplements
    }
    if (personal.personal_individual_addresses) {
      personal_individual_addresses_submit = personal.personal_individual_addresses
    }
    if (personal.personal_codes) {
      personal_codes_submit = personal.personal_codes
    }
    if (personal.birthday_on) {
      birthdayOn = moment(personal.birthday_on).isValid() ?
        moment(personal.birthday_on).format('YYYY/MM/DD')
        :
        null
    }

    return {
      ...personal,
      birthday_on: birthdayOn,
      personal_belongs: personal_belongs_submit,
      personal_addresses: personal_addresses_submit,
      personal_specials: personal_specials_submit,
      personal_supplements: personal_supplements_submit,
      personal_individual_addresses: personal_individual_addresses_submit,
      personal_codes: personal_codes_submit
    }
  }

  /**
   * 事業所、支店、保険者、郵便番号、住所1 選択モーダル表示
   * @param {*} modal
   */
  callbackOpenModal = (modal) => {
    switch (modal) {
      case 'WS0247001': // WS0247001_OfficeInfoRetrievalQuery 事業所情報検索・照会
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1200,
            component: (
              <WS0247001_OfficeInfoRetrievalQuery
                Lio_OfficeCode={this.state.objBelongs?.office_code}
                onFinishScreen={async ({
                  Lio_OfficeCode,
                  Lio_BranchStoreCode,
                  Lo_Kanji_Name,
                  recordData
                }) => {
                  //事業所コード
                  await this.onChangeBelongs(Lio_OfficeCode, 'office_code')
                  //支店コード
                  await this.onChangeBelongs(Lio_BranchStoreCode, 'branch_store_code')
                  //保険者コード
                  await this.onChangeBelongs(recordData.insurer_number, 'insurer_number')


                  //名称
                  await this.setState({
                    infoBasicPage: {
                      ...this.state.infoBasicPage,
                      office_kanji_name: Lo_Kanji_Name,
                      insurer_kanji_name: recordData.insurer_kanji_name
                    }
                  })
                  // 続柄　
                  if (!this.state.objBelongs.relationship) {
                    // 何も設定がない場合、初期値「本人」とする
                    await this.onChangeBelongs('0', 'relationship')
                  }
                  //所属コード
                  await this.onChangeBelongs('', 'workplace_code')
                  //所属名称
                  await this.onChangeBelongs('', 'short_name')
                  // 保険証枝番
                  await this.onChangeBelongs('', 'insurer_card_serial_number')
                  // 保険証
                  await this.onChangeBelongs(recordData.insurer_card_symbol, 'insurer_card_symbol')

                  await this.closeModal()
                }}
              />
            )
          }
        })
        break

      case 'WS1290001': // WS1290001_InsurerNumberInquiry 保険者番号照会
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1200,
            component: (
              <WS1290001_InsurerNumberInquiry
                InputValue={this.state.objBelongs?.insurer_number}
                onFinishScreen={async ({
                  Lo_InsurerNum,
                  Lo_InsurerKanjiName,
                  recordData
                }) => {
                  //保険者コード
                  await this.onChangeBelongs(Lo_InsurerNum, 'insurer_number')
                  // 名称
                  await this.setState({
                    infoBasicPage: {
                      ...this.state.infoBasicPage,
                      insurer_kanji_name: Lo_InsurerKanjiName
                    }
                  })
                  await this.closeModal()
                }}
              />
            )
          }
        })
        break
      case 'WS2787004': // WS2787004_AffiliationSelectSub 所属選択
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 600,
            component: (
              <WS2787004_AffiliationSelectSub
                Li_OfficeCode={this.state.objBelongs.office_code}
                Li_BranchStoreCode={this.state.objBelongs.branch_store_code}
                onFinishScreen={async ({
                  Lio_AffiliationCode,
                  recordData
                }) => {
                  //所属コード
                  await this.onChangeBelongs(Lio_AffiliationCode, 'workplace_code')
                  //所属名称
                  await this.onChangeBelongs(recordData.short_name, 'short_name')

                  await this.closeModal()
                }}
              />
            )
          }
        })
        break
      case 'WS0084001': // WS0084001_PostCodeSearchEngine 郵便番号検索
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 800,
            component: (
              <WS0084001_PostCodeSearchEngine
                // Lio_ZipCode={this.state.objAddress.postal_code}
                // Lio_Address={this.state.objAddress.address_1}
                onFinishScreen={async ({ Lio_ZipCode, Lio_Address }) => {
                  // 郵便番号
                  await this.onChangeAddress(Lio_ZipCode, 'postal_code')
                  // 住所1
                  await this.onChangeAddress(Lio_Address, 'address_1')
                  await this.closeModal()
                }}
              />
            )
          }
        })
        break
      default:
        break
    }
  }

  /**
   * 個人予約　モーダル
   */
  showWS2537001_PersonalReserveProcess = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2537001_PersonalReserveProcess
            Li_Date={this.props.Li_SelectDay ?? ''}
            Li_PersonalNum={this.state.personal?.personal_number_id ?? ''}
            Li_Child={true}
            onFinishScreen={() => {
              // 個人番号再取得
              this.searchPersonalNumberID(this.state.personal?.personal_number_id)
            }}
          />
        ),
      }
    })
  }

  /**
   * 登録ボタン押下処理
   */
  onSave = () => {
    const formData = this.formRef.current.getFieldsValue()

    //パラメーター調整
    let params = this.formatPersonal(formData)

    // 入力チェック
    if (params.kana_name === '' || !params.kana_name) {
      message.error('カナ氏名を入力してください')
      return
    }
    if (params.kanji_name === '' || !params.kanji_name) {
      message.error('漢字氏名を入力してください')
      return
    }
    if (params.sex === -1 || !params.sex) {
      message.error('性別を設定してください')
      return
    }
    if (params.birthday_on === null || !params.birthday_on) {
      message.error('生年月日を設定してください')
      return
    }
    if (params.personal_belongs.length === 0) {
      message.error('所属を設定してください')
      return
    } else {
      if (this.state.objBelongs.office_code === '' || !this.state.objBelongs.office_code) {
        message.error('事業所コードを設定してください')
        return
      }
    }
    if (params.personal_addresses.length > 0) {
      let errFlag1 = false, errFlag2 = false
      if (this.state.objAddress.email) {
        if (this.state.objAddress.email !== '') {
          errFlag1 = this.validateEmail(this.state.objAddress.email, 'ref_input_email')
        }
      }
      if (this.state.objAddress.mobile_mail) {
        if (this.state.objAddress.mobile_mail !== '') {
          errFlag2 = this.validateEmail(this.state.objAddress.mobile_mail, 'ref_input_mobile_mail')
        }
      }
      if (errFlag1 || errFlag2) {
        message.error('メールアドレスの形式が異なります。')
        return
      }
    }

    params = {
      ...params,
      Option: this.state.objOptions.Option
    }

    if (this.state.statusPage !== 3) {
      // 新規登録の場合

      // 個人番号が入力にて指定されているか？
      if (formData?.personal_number_id) {
        params = {
          ...params,
          personal_number_id: formData?.personal_number_id
        }
      }

      Modal.confirm({
        content: '新規登録します。よろしいですか？',
        okText: '登　録',
        cancelText: 'キャンセル',
        onOk: () =>
          // 新規登録
          PersonalInfoMaintainAction.updatePersonalInfo(params)
            .then(res => {
              // 個人番号更新
              this.searchPersonalNumberID(res.personal_number_id)
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen()
              }
            })
      })
    } else {
      // 変更の場合
      Modal.confirm({
        content: '上書き登録します。よろしいですか？',
        okText: '登　録',
        cancelText: 'キャンセル',
        onOk: () =>
          // 更新
          PersonalInfoMaintainAction.updatePersonalInfo(params)
            .then(res => {
              // 個人番号更新
              this.searchPersonalNumberID(res.personal_number_id)
              if (this.props.onFinishScreen) {
                this.props.onFinishScreen()
              }
            })
      })
    }
  }

  /**
   * 郵便番号 存在チェック
   * @param {*} code
   */
  getNamePostalCode = (code) => {
    // 空の場合は取得しない
    if (code === '') return

    this.checkPostalCode(code)

    const params = {
      postalCode: code
    }
    PersonalInfoMaintainAction.getNamePostalCode(params)
      .then(res => {
        if (res) {
          let address_1 = res.address_1
          if (address_1 !== '') {
            this.onChangeAddress(address_1, 'address_1')
          } else {
            message.error('入力された郵便番号に該当する住所はありませんでした')
          }
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message || 'エラーが発生しました')
      })
  }


  /**
   * 7ケタの数字かどうかチェックしてハイフンをいれる
   * @param {*} code
   */
  checkPostalCode = (code) => {
    // 7ケタの数字かどうか
    if (code.match(/^([0-9]{7})$/)) {
      // ハイフン追加
      let postalCode = code.slice(0, 3) + '-' + code.slice(3, code.length)
      this.onChangeAddress(postalCode, 'postal_code')
    }
  }

  /**
   * メールアドレス　入力チェック
   * @param {*} value
   * @param {*} fieldName
   * @returns
   */
  validateEmail = (value, fieldName) => {
    const mailformat =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (Regex(String(value).toLowerCase(), mailformat) === false) {

      this[fieldName]?.current?.focus()
      return true
    }
    return false
  }

  /**
   * 特記、補足、送付先、コード、履歴 変更処理
   * @param {*} changeData
   */
  updateDetails = (changeData, specialFlag = false) => {
    const params = { ...changeData }
    // personal_specials を変更
    PersonalInfoMaintainAction.updateDetails(params)
      .then(res => {
        // 個人情報に変更内容を反映
        this.searchPersonalNumberID(this.state.personal?.personal_number_id, null, specialFlag)
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({ flg: 1 })
        }
      })
      .catch(err => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message);
      })
  }

  /**
   * 特記、補足、送付先、コード、履歴
   * @param {*} personal
   * @return
   */
  getDetailsMenu(personal) {
    return (
      <Menu>
        <Menu.Item
          key='1' style={{ width: '100%' }}
          disabled={personal.personal_number_id > 0 ? false : true}
          onClick={() => {
            this.specialMention(personal)
          }}
        >
          特記
        </Menu.Item>
        <Menu.Item
          key='2' style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 750,
                component: (
                  <WS0343102_SupplementtaryInfo
                    personal={personal}
                    onUpdate={(output) => {
                      this.updateDetails(output)
                    }}
                  />
                )
              }
            })
          }}
        >
          補足
        </Menu.Item>
        <Menu.Item
          key='3' style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 600,
                component: (
                  <WS0343103_DestinationInfo
                    personal={personal}
                    onUpdate={(output) => {
                      this.updateDetails(output)
                    }}
                  />
                )
              }
            })
          }}
        >
          送付先
        </Menu.Item>
        <Menu.Item
          key='4' style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 600,
                component: (
                  <WS0343104_CodeInfo
                    personal={personal}
                    onUpdate={(output) => {
                      this.updateDetails(output)
                    }}
                  />
                )
              }
            })
          }}
        >
          コード
        </Menu.Item>
        <Menu.Item
          key='5' style={{ width: '100%' }}
          onClick={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 600,
                component: (
                  <WS2586020_ConsultHistorySub
                    Li_PersonalNum={this.state.personal?.personal_number_id}
                    pageSize={7}
                  />
                )
              }
            })
          }}
        >
          履歴
        </Menu.Item>
      </Menu>
    )
  }

  /**
   * 特記処理　別関数化
   */
  specialMention = (personal) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0343101_SpecialInfo
            personal={personal}
            onUpdate={(output) => {
              this.updateDetails(output, true)
              // 特記登録後に予約状況に反映させるため
              if (this.props.onUpdate) {
                this.props.onUpdate()
              }
            }}
            onDelete={(output) => {
              this.deleteSpecials(output)
              // 特記登録後に予約状況に反映させるため
              if (this.props.onUpdate) {
                this.props.onUpdate()
              }
            }}
          />
        )
      }
    })
  }

  /**
   * 特記　削除処理　(delete personal_specials)
   * @param {*} data
   */
  deleteSpecials = (data) => {
    this.setState({
      personal: {
        ...this.state.personal,
        personal_specials: data
      }
    })
    this.formRef?.current?.setFieldsValue({ personal_specials: data })
  }

  /**
   * 個人番号　入力時処理
   * 　debounceで実行タイミング遅らせている
   * 　連続でAPI実行するのを防ぐため
   * @param {*} id
   */
  onChangePersonalNumberID = (id) => {

    // 入力チェック
    if (id !== null && id !== '') {
      if (!Regex(id, /[1-9]/)) {
        // 数値以外の場合
        message.error('半角数字以外は使用できません')
        id = null
      }
    }
    // 個人情報取得
    this.searchPersonalNumberID(id)
  }

  /**
   * 登録日、変更日を取得
   * @param {*} label
   * @param {*} date
   * @returns
   */
  getDate = (label, date) => {
    let strDate = ''
    if (date && date !== '0000/00/00') {
      strDate = `${label}: ${date}`
    }
    return strDate
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }

  /**
   * 年齢計算
   * @param {生年月日} birthDate
   * @param {年齢計算区分} ageDivision //0:受診日、1：年度末、2：学童年齢
   * @returns
   */
  calculateAge = (birthDate, ageDivision) => {
    let ageDivisionMonth = 0
    let ageDivisionDay = 0
    let ageCalculateMethod = ''
    let fromDate = ''
    let resultAge = ''
    // 生年月日を年、月、日で分ける
    const year = Number(moment(birthDate).format('YYYY'))
    const month = Number(moment(birthDate).format('Mo'))
    const date = Number(moment(birthDate).format('D'))

    if (birthDate) {
      if (birthDate > '0000/00/00') {
        if (ageDivision === 0) {
          ageCalculateMethod = '受診日'
        } else if (ageDivision === 1) {
          ageCalculateMethod = '年度末'
          // 基準日：3月31日
          ageDivisionMonth = 3
          ageDivisionDay = 31
        } else if (ageDivision === 2) {
          ageCalculateMethod = '学童年齢'
          // 基準日：4月1日
          ageDivisionMonth = 4
          ageDivisionDay = 1
        }

        if (ageDivisionMonth != '' && ageDivisionDay !== '') {
          // 基準日： 年度末 または 学童年齢
          // 今日
          const todayDate = new Date()
          // 基準日を一旦取得
          fromDate = new Date(todayDate.getFullYear(), ageDivisionMonth - 1, ageDivisionDay)

          // 今日の日付と比較して正しい基準日を取得
          if (todayDate > fromDate) {
            fromDate = new Date(todayDate.getFullYear() + 1, ageDivisionMonth - 1, ageDivisionDay)
          }

        } else {
          // 基準日：受診日
          // 今日
          fromDate = new Date()
        }

        //今年の誕生日
        const thisYearsBirthday = new Date(fromDate.getFullYear(), month - 1, date)
        //基準日から年齢計算
        resultAge = fromDate.getFullYear() - year
        if (fromDate < thisYearsBirthday) {
          //今年まだ誕生日が来ていない
          resultAge--
        }

      } else {
        if (resultAge != 0) {
          resultAge = 0
        }
      }
    }
    return resultAge
  }

  getAge = (birthday) => {
    const year = moment(birthday).format('YYYY')
    const month = moment(birthday).format('Mo')
    const date = moment(birthday).format('D')

    //今日
    const today = new Date()

    //今年の誕生日
    const thisYearsBirthday = new Date(today.getFullYear(), month - 1, date)

    //年齢
    let age = today.getFullYear() - year

    if (today < thisYearsBirthday) {
      //今年まだ誕生日が来ていない
      age--
    }
    return age
  }

  /**
   * 個人情報　削除処理　確認モーダル
   */
  deletePersonalInfo = () => {
    let param = {
      personalNumberId: this.formRef.current?.getFieldValue('personal_number_id')
    }
    PersonalInfoMaintainAction.deleteNotification(param)
      .then(res => {
        if (res?.flag) {
          Modal.confirm({
            title: '確認',
            icon: <WarningOutlined />,
            content: (
              <div>
                個人の情報がすべて削除されます。
                <br />
                削除しますか？
              </div>
            ),
            onOk: () => {
              this.deletePersonalInfoExecution()
            }
          })
        }
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   *  個人情報　削除処理　実行
   */
  deletePersonalInfoExecution = () => {
    let param = {
      personalNumberId: this.formRef.current?.getFieldValue('personal_number_id')
    }

    PersonalInfoMaintainAction.deleteExecution(param)
      .then(res => {
        this.resetPersonalInfo()
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 連携先（MiraisやIBARS）の個人情報を取得
   * @param {*} personalNum
   */
  getIntegrationPersonalInfo = (personalNum) => {

    let param = {
      personalNumberId: personalNum,
      optionUser: this.state.objOptions.OptionUser
    }

    PersonalInfoMaintainAction.getIntegrationPersonalInfo(param)
      .then(res => {


        // 個人番号変更時の処理
        this.onChangePersonalNumberID(personalNum)
      })

  }

  render() {
    const { personal, infoBasicPage } = this.state
    return (
      <div className='personal-info-maintain'>
        <Card
          title={'個人情報保守 [' + ((this.state.statusPage === 3) ? '編集' : '新規') + ']'}
          style={{ position: 'relative', minWidth: '890px' }}
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.Li_PersonalNum || this.props.Li_PersonalNum === '') ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='2' style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WSFileManager_FileManager
                                Params_2612={{
                                  Li_Division: '個人',
                                  Li_Identify: MagicXpaFunc.Str(
                                    MagicXpaFunc.Val(
                                      this.formRef.current?.getFieldValue('personal_number_id'),
                                      '10'
                                    ),
                                    '10P0'
                                  )
                                }}
                              />
                            )
                          }
                        });
                      }}
                      disabled={!this.formRef.current?.getFieldValue('personal_number_id')}
                    >
                      フォルダ
                    </Menu.Item>

                    <Menu.Item
                      key='3' style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 850,
                            component: (
                              <WS0344001_SupplementalInfoSetting
                                Li_IdentifyChar='MAST-'
                                onFinishScreen={() => {
                                  // this.getScreenData();
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        })
                      }}
                    >
                      補足設定
                    </Menu.Item>

                    <Menu.Item
                      key='4' style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1200,
                            component: (
                              <WS0382001_PersonalAttributesReacquire
                                Li_User={this.state.objOptions.OptionUser}
                                Li_ReAcquireItems={this.state.objOptions.OptionDataReAcquireItems}
                                Li_PatientNum={this.state.personal.personal_number_id}
                                onFinishScreen={data => {
                                  if (data) {
                                    // 再取得データを反映
                                    this.setDataToPersonal(data)
                                  }
                                  this.closeModal()
                                }}
                              />
                            )
                          }
                        })
                      }}
                    >
                      再取得
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.Li_PersonalNum || this.props.Li_PersonalNum === '') ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_border'>
                <div
                  className='box_inner_horizontal'
                  style={{ justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Form.Item
                      name='personal_number_id'
                      style={{ margin: 0, padding: 0 }}>
                      <Input.Search
                        allowClear={true}
                        cleartriggersearch='true'
                        style={{ margin: 0 }}
                        className='input-search-size-number-allow-clear-10'
                        onPressEnter={(event) => {
                          // Enterキー押下時に実行
                          const personalNum = event.target.value

                          // // 連携先（MiraisやIBARS）の個人情報を取得
                          // this.getIntegrationPersonalInfo(personalNum)

                          // 個人番号変更時の処理
                          this.onChangePersonalNumberID(personalNum)
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            // 虫眼鏡ボタンが押下された時
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 1500,
                                component: (
                                  <Card title={'検索'}>
                                    {this.state.objOptions.OptionUser !== '' ?
                                      <Tabs
                                        defaultActiveKey='1'
                                        size='small'
                                      >
                                        {
                                          this.state.objOptions.OptionUser !== 'FUJIYA' ?
                                            <Tabs.TabPane
                                              tab='患者情報照会'
                                              key='1'
                                            >
                                              {this.state.objOptions.OptionUser === 'COMMON' ?
                                                <WS2727001_PatientInfoQueryStard
                                                  hideTitle={true} //タイトルを非表示
                                                  onFinishScreen={async (output) => {
                                                    let personalTemp = null
                                                    if (output.recordData) {
                                                      // 患者情報が存在する場合、後で反映するためのデータを作成
                                                      const data = { ...output.recordData }
                                                      personalTemp = {
                                                        personal: {
                                                          kana_name: data.kana_name,
                                                          kanji_name: data.kanji_name,
                                                          birthday_on: data.birthday_on,
                                                          sex: data.sex,
                                                        },
                                                        address: {
                                                          postal_code: data.postal_code,
                                                          address_1: data.address_1,
                                                          address_2: data.address_2,
                                                          phone_number: data.phone_number,
                                                          cell_phone_number: data.cell_phone_number
                                                        }
                                                      }
                                                    }
                                                    await this.searchPersonalNumberID(output.Lo_PersonalNumId, personalTemp)
                                                    await this.closeModal()
                                                  }}
                                                />
                                                :
                                                <WS0343010_PatientInfoQueryOthers
                                                  hideTitle={true} //タイトルを非表示
                                                  Li_OptionUser={this.state.objOptions.OptionUser}
                                                  onFinishScreen={async (output) => {
                                                    let personalTemp = null
                                                    if (output.recordData) {
                                                      // 患者情報が存在する場合、後で反映するためのデータを作成
                                                      const data = { ...output.recordData }
                                                      personalTemp = {
                                                        personal: {
                                                          kana_name: data.kana_name,
                                                          kanji_name: data.kanji_name,
                                                          birthday_on: data.birthday_on,
                                                          sex: data.sex,
                                                        },
                                                        address: {
                                                          postal_code: data.postal_code,
                                                          address_1: data.address_1,
                                                          address_2: data.address_2,
                                                          phone_number: data.phone_number,
                                                          cell_phone_number: data.cell_phone_number
                                                        }
                                                      }
                                                    }
                                                    await this.searchPersonalNumberID(output.Lo_PersonalNumId, personalTemp)
                                                    await this.closeModal()
                                                  }}
                                                />
                                              }
                                            </Tabs.TabPane>
                                            : ''

                                        }
                                        <Tabs.TabPane
                                          tab='個人情報検索・照会'
                                          key='2'
                                        >
                                          <WS0248001_PersonalInfoSearchQuery
                                            date={moment(new Date()).format('YYYY/MM/DD')}
                                            createNewPersonal={this.createNewPersonal}
                                            getValueChild={this.getValueChild}
                                            hideTitle={true} //タイトルを非表示
                                            onFinishScreen={(output) => {
                                              // データ取得
                                              this.searchPersonalNumberID(output.Lo_PersonalNumId)
                                              this.closeModal()
                                            }}
                                          />
                                        </Tabs.TabPane>
                                      </Tabs>
                                      :
                                      <WS0248001_PersonalInfoSearchQuery
                                        date={moment(new Date()).format('YYYY/MM/DD')}
                                        createNewPersonal={this.createNewPersonal}
                                        getValueChild={this.getValueChild}
                                        hideTitle={true} //タイトルを非表示
                                        onFinishScreen={(output) => {
                                          // データ取得
                                          this.searchPersonalNumberID(output.Lo_PersonalNumId)
                                          this.closeModal()
                                        }}
                                      />
                                    }
                                  </Card>
                                )
                              }
                            })
                          } else {
                            // allowClearのボタンが押下された時
                            // idを空にする 新規追加モードへ
                            this.searchPersonalNumberID('')
                          }
                        }}
                      />
                    </Form.Item>

                    <Tooltip title={(this.state.statusPage === 3) ? '個人情報の新規追加' : ''}>
                      <Button
                        size='small'
                        type='primary'
                        style={{
                          borderRadius: '12px',
                          display: (this.state.statusPage === 3) ? '' : 'none' //新規追加モード時は非表示
                        }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                          // idを空にする
                          this.searchPersonalNumberID('')
                        }}
                      />
                    </Tooltip>
                    <Tag
                      color='green'
                      visible={this.state.statusPage === 1} //新規追加モード時のみ表示
                    >
                      自動発番
                    </Tag>
                  </div>
                  <div className='box_button_bottom_right' style={{ paddingTop: 0 }}>
                    <div>
                      登録日{': '}
                      {infoBasicPage.registerDate ? (
                        moment(infoBasicPage.registerDate).format('YYYY/MM/DD (ddd)')
                      ) : (
                        <span style={{ opacity: '0' }}>0000/00/00</span>
                      )}
                    </div>
                    <div>
                      変更日{': '}
                      {infoBasicPage.updateDate ? (
                        moment(infoBasicPage.updateDate).format('YYYY/MM/DD (ddd)')
                      ) : (
                        <span style={{ opacity: '0' }}>0000/00/00</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='box_inner_horizontal'>
                <div className='box_inner_vertical'>
                  {/* Personal */}
                  <div style={{ ...styleBorder, position: 'relative' }}>
                    <Form.Item label='カナ氏名' name='kana_name' {...smGrid}>
                      <Input
                        style={{ width: '300px' }}
                        onChange={e => {
                          this.onChangePersonalInfo(e.target.value, 'kana_name');
                        }}
                        onBlur={e => {
                          let zenkaku_kana = wanakana.toKatakana(e.target.value)
                          let hannkaku_kana = hankakuMoji(zenkaku_kana)

                          this.setState({
                            personal: {
                              ...this.state.personal,
                              kana_name: hannkaku_kana,

                            }
                          })
                          this.formRef?.current?.setFieldsValue({
                            kana_name: hannkaku_kana,
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label='漢字氏名' name='kanji_name' {...smGrid}>
                      <Input
                        style={{ width: '300px' }}
                        onChange={e => this.onChangePersonalInfo(e.target.value, 'kanji_name')}
                      />
                    </Form.Item>
                    <Row>
                      <Col span={16}>
                        <Form.Item label='性別' {...smGrid}>
                          <Radio.Group
                            onChange={e => this.onChangePersonalInfo(e.target.value, 'sex')}
                            value={personal.sex}
                          >
                            <Radio value={1}>男性</Radio>
                            <Radio value={2}>女性</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                    </Row>
                    <Row>
                      <Col span={16}>
                        <Form.Item label='生年月日' {...smGrid} >
                          <Space>
                            <JapaneseDateCoustom
                              value={personal.birthday_on}
                              onChange={date => {
                                if (date) {
                                  this.onChangePersonalInfo(date, 'birthday_on')
                                }
                              }}
                            />

                            {/* 右側に西暦を表示 */}
                            <div style={{ display: (infoBasicPage.birthYear) ? '' : 'none' }}>
                              {`(${infoBasicPage.birthYear})`}
                            </div>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <div style={{ paddingLeft: 80, display: 'flex', gap: '10px' }}>
                        <Form.Item
                          label='受診日'
                          hidden={!infoBasicPage.ageOnDay}
                          style={{ marginBottom: 0 }}
                        >
                          <div>{infoBasicPage.ageOnDay}歳</div>
                        </Form.Item>
                        <div style={{ marginTop: 5, display: infoBasicPage.ageOnDay ? '' : 'none' }}>/</div>
                        <Form.Item
                          label='年度末'
                          hidden={!infoBasicPage.ageYearEnd}
                          style={{ marginBottom: 0 }}
                        >
                          <div>{infoBasicPage.ageYearEnd}歳</div>
                        </Form.Item>
                      </div>
                    </Row>

                    <Row
                      style={{
                        bottom: '20px',
                        right: '0',
                        position: 'absolute'
                      }}
                    >
                      {(personal.sex ?? false) ?
                        <img
                          src={personal.sex === 1 ? GetImage('man') : GetImage('woman')}
                          alt='sex'
                        />
                        :
                        null
                      }
                    </Row>

                    {/* 特記、補足、送付先、コード、履歴 */}
                    <Tooltip title='特記、補足、送付先、コード、履歴'>
                      <Dropdown
                        placement='bottomRight'
                        trigger='click'
                        style={{
                          top: '10px',
                          right: '10px',
                          position: 'absolute'
                        }}
                        overlay={() => this.getDetailsMenu(personal)}
                        disabled={!this.state.statusPage}
                      >
                        <Button
                          size='small'
                          style={{
                            top: '10px',
                            right: '10px',
                            position: 'absolute'
                          }}
                          icon={<MenuOutlined />}
                        />
                      </Dropdown>
                    </Tooltip>
                  </div>

                  {/* Belongs 事業所コード等 */}
                  <div style={{ ...styleBorder, position: 'relative' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Form.Item label='事業所' {...smGrid}>
                        <Input.Search
                          className='input-search-size-number-10'
                          value={this.state.objBelongs.office_code}
                          maxLength={8}
                          onChange={e =>
                            this.onChangeBelongs(e.target.value, 'office_code')
                          }
                          onSearch={() =>
                            // 保険者番号照会
                            this.callbackOpenModal('WS0247001')
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input.Search
                          className='input-search-size-number-4'
                          maxLength={5}
                          min={0}
                          value={
                            this.state.objBelongs.branch_store_code !== 0
                              ? this.state.objBelongs.branch_store_code
                              : null
                          }
                          onChange={value =>
                            this.onChangeBelongs((Regex(value, /[1-9]/) ? value : null), 'branch_store_code')
                          }
                          onSearch={() =>
                            // 保険者番号照会
                            this.callbackOpenModal('WS0247001')
                          }
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          size='small'
                          style={{ marginTop: '4px' }}
                          icon={<MoreOutlined />}
                          disabled={!this.state.objBelongs.office_code}
                          onClick={() =>
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '80%',
                                component: (
                                  <WS0341001_OfficeInfoMaintain
                                    Li_ExecModeDef_Sdi={''}
                                    Lio_OfficeCode={this.state.objBelongs.office_code}
                                    Lio_BranchStoreCode={
                                      this.state.objBelongs.branch_store_code
                                    }
                                  />
                                )
                              }
                            })
                          }
                        />
                      </Form.Item>
                      {/* 事業所名称 */}
                      <div style={{ marginTop: '5px' }}>
                        {infoBasicPage?.office_kanji_name}
                      </div>
                    </div>

                    <Space>
                      <Form.Item label='保険者' {...smGrid}>
                        <Input.Search
                          className='input-search-size-number-10'
                          min={0}
                          maxLength={8}
                          value={this.state.objBelongs.insurer_number}
                          onChange={e =>
                            this.onChangeBelongs(e.target.value, 'insurer_number')
                          }
                          onSearch={() =>
                            // 事業所情報検索・照会
                            this.callbackOpenModal('WS1290001')
                          }
                        />
                      </Form.Item>
                      {/* 保険者名称 */}
                      <div style={{ marginTop: '5px' }}>
                        {infoBasicPage?.insurer_kanji_name}
                      </div>
                    </Space>

                    <Space>
                      <Form.Item label='保険証' {...smGrid}>
                        <Input
                          className='input-size-10'
                          value={this.state.objBelongs.insurer_card_symbol}
                          onChange={e =>
                            this.onChangeBelongs(e.target.value, 'insurer_card_symbol')
                          }
                        />
                      </Form.Item>
                      <div style={{ marginTop: 5 }}>/</div>
                      <Form.Item>
                        <Input
                          className='input-size-10'
                          value={this.state.objBelongs.insurer_card_number}
                          onChange={e =>
                            this.onChangeBelongs(e.target.value, 'insurer_card_number')
                          }
                        />
                      </Form.Item>
                      {/* 保険証枝番 */}
                      <Form.Item>
                        <Input
                          className='input-size-2'
                          maxLength={2}
                          value={this.state.objBelongs.insurer_card_serial_number}
                          onChange={e =>
                            this.onChangeBelongs(e.target.value, 'insurer_card_serial_number')
                          }
                        />
                      </Form.Item>
                    </Space>

                    <Form.Item label='続柄' {...smGrid}>
                      <Select
                        style={{ width: '100px' }}
                        value={this.state.objBelongs.relationship}
                        onChange={value =>
                          this.onChangeBelongs(value, 'relationship')
                        }
                      >
                        <Select.Option value='0'>本人</Select.Option>
                        <Select.Option value='1'>配偶者</Select.Option>
                        <Select.Option value='2'>家族</Select.Option>
                      </Select>
                    </Form.Item>

                    <Space>
                      <Form.Item label='所属' {...smGrid}>
                        <Input.Search
                          className='input-search-size-number-10'
                          maxLength={8}
                          min={0}
                          value={this.state.objBelongs.workplace_code}
                          type={'tel'}
                          onChange={e =>
                            this.onChangeBelongs(e.target.value, 'workplace_code')
                          }
                          onSearch={() =>
                            // 所属選択
                            this.callbackOpenModal('WS2787004')
                          }
                        />
                      </Form.Item>
                      {/* 所属 名称 */}
                      <div style={{ marginTop: '5px' }}>
                        {this.state.objBelongs?.short_name}
                      </div>
                    </Space>

                    {/* 所属情報選択 */}
                    <Tooltip title='所属情報選択'>
                      <Button
                        size='small'
                        style={{
                          top: '10px',
                          right: '10px',
                          position: 'absolute'
                        }}
                        disabled={!this.state.statusPage}
                        icon={<SwitcherOutlined />}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 1200,
                              component: (
                                <WS0343105_BelongsInfo
                                  objBelongs={this.state.objBelongs}
                                  personal={personal}
                                  onUpdate={async (obj, arrBelongs, closeFlag) => {
                                    // async/awaitが必要
                                    // 選択された所属情報データを表示
                                    await this.setState({
                                      personal: {
                                        ...this.state.personal,
                                        personal_belongs: arrBelongs
                                      },
                                      infoBasicPage: {
                                        ...this.state.infoBasicPage,
                                        office_kanji_name: obj?.office_kanji_name ?? '',
                                        insurer_kanji_name: obj?.insurer_kanji_name ?? '',
                                      },
                                      objBelongs: obj,
                                    })
                                    await this.onChangePersonalNumberID(personal.personal_number_id)
                                    if (closeFlag) {
                                      // モーダルを閉じる
                                      await this.closeModal()
                                    }
                                  }}
                                />
                              )
                            }
                          })
                        }}
                      />
                    </Tooltip>

                  </div>
                </div>

                <div className='box_inner_vertical'>
                  {/* StreetAddress 住所・電話番号等 */}
                  <Tabs
                    type='editable-card'
                    activeKey={this.state.addressActiveKey}
                    hideAdd={this.state.addressHideAdd}
                    onChange={this.onChangeTabAddress}
                    onEdit={this.onEditTabAddress}
                    style={{ height: '100%' }}
                  >
                    {this.state.addressList.map((item) => {
                      return (
                        <Tabs.TabPane
                          key={item.key}
                          tab={item.title}
                          closable={item.closable}
                        >
                          <div>
                            <Form.Item
                              {...smGrid}
                              wrapperCol={{ span: 17 }}
                              label={
                                <Space>
                                  <Button
                                    type='primary'
                                    size='small'
                                    hidden={
                                      this.state.objAddress.address_category === 1 || //[address_category:1] は既定
                                      !(personal?.personal_addresses?.length > 0) // アドレスデータが１件もない場合
                                    }
                                    onClick={() => {
                                      // 既定　設定確認
                                      Modal.confirm({
                                        title: 'この住所を既定としますか？',
                                        cancelText: 'いいえ',
                                        okText: 'はい',
                                        onOk: () => {
                                          // 既定の住所を変更
                                          this.setAddressToDefault(item.key)
                                        }
                                      })
                                    }}
                                  >
                                    既定
                                  </Button>
                                  <div>〒</div>
                                </Space>
                              }
                            >
                              <Input.Search
                                className='input-search-size-single-byte-8'
                                value={this.state.objAddress.postal_code ?? ''}
                                onSearch={(value, event) => {
                                  if (event.type === 'click') {
                                    // 郵便番号検索
                                    this.callbackOpenModal('WS0084001')
                                  } else {
                                    // 郵便番号 存在チェック
                                    this.checkPostalCode(value)
                                  }
                                }}
                                onChange={e =>
                                  this.onChangeAddress(e.target.value, 'postal_code')
                                }
                              />

                              <Tooltip title={'入力された郵便番号から住所を取得'}>
                                <Button
                                  size='small'
                                  disabled={!(this.state.objAddress.postal_code ?? '' === '')} //郵便番号に何かしら入力されたらボタン活性
                                  onClick={() => {
                                    const code = this.state.objAddress.postal_code ?? ''
                                    // 住所検索
                                    this.getNamePostalCode(code)
                                  }}
                                  style={{ marginTop: 5, marginLeft: 8 }}
                                >
                                  住所取得
                                </Button>
                              </Tooltip>
                            </Form.Item>

                            <Form.Item label='住所' {...smGrid}>
                              <Input
                                value={this.state.objAddress.address_1}
                                onDoubleClick={() =>
                                  // 郵便番号検索
                                  this.callbackOpenModal('WS0084001')
                                }
                                onChange={e =>
                                  this.onChangeAddress(e.target.value, 'address_1')
                                }
                              />
                            </Form.Item>

                            <Form.Item label=' ' {...smGrid}>
                              <Input
                                value={this.state.objAddress.address_2}
                                onChange={e =>
                                  this.onChangeAddress(e.target.value, 'address_2')
                                }
                              />
                            </Form.Item>

                            <Form.Item label='宛名'  {...smGrid}>
                              <Input
                                value={this.state.objAddress.address}
                                onChange={e =>
                                  this.onChangeAddress(e.target.value, 'address')
                                }
                              />
                            </Form.Item>

                            <Form.Item label='自宅電話' {...smGrid}>
                              <Input
                                style={styleNumberFormat}
                                value={this.state.objAddress.phone_number ?? ''}
                                onChange={e =>
                                  this.onChangeAddress(e.target.value, 'phone_number')
                                }
                              />
                            </Form.Item>

                            <Form.Item label='PCメール' {...smGrid}>
                              <Input
                                type='email'
                                ref={this.ref_input_email}
                                value={this.state.objAddress.email}
                                onChange={(e) =>
                                  this.onChangeAddress(e.target.value, 'email')
                                }
                              />
                            </Form.Item>

                            <Form.Item label='携帯電話' {...smGrid}>
                              <Input
                                style={styleNumberFormat}
                                value={this.state.objAddress.cell_phone_number ?? ''}
                                onChange={e =>
                                  this.onChangeAddress(e.target.value, 'cell_phone_number')
                                }
                              />
                            </Form.Item>

                            <Form.Item label='携帯メール' {...smGrid}>
                              <Input
                                type='email'
                                ref={this.ref_input_mobile_mail}
                                value={this.state.objAddress.mobile_mail}
                                onChange={e =>
                                  this.onChangeAddress(e.target.value, 'mobile_mail')
                                }
                              />
                            </Form.Item>
                          </div>
                        </Tabs.TabPane>
                      )
                    })}
                  </Tabs>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className='box_button_bottom_right'>
              <Button
                type='danger'
                icon={<DeleteOutlined />}
                onClick={() => {
                  this.deletePersonalInfo()
                }}
                disabled={this.state.disabledDeleteBtn}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                onClick={() => {
                  // 個人予約
                  this.showWS2537001_PersonalReserveProcess()
                }}
              >
                <span className='btn_label'>
                  予約
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.onSave()
                }}
                disabled={this.state.disabledRegisterBtn}
              >
                <span className='btn_label'>
                  登録
                </span>
              </Button>

            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS0343001_PersonalInfoMaintain)
