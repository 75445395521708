import axios from 'configs/axios';

const API_LIST = {
  csvOutputFile: '/api/office-info-csv-output/office-info-csv-output/csv-output'
};

const OfficeInfoCsvOutputService = {
  async csvOutput(params) {
    // return axios.post(API_LIST.csvOutputFile, params);
    return axios.post(API_LIST.csvOutputFile, params, { responseType: 'blob' });
  }
};

export default OfficeInfoCsvOutputService;
