import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import AggregateInfoCorrectAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/AggregateInfoCorrect.actions";
import { Card, Form, Input, Col, Row, Button, message, Tag, Space } from "antd";

const styleBlueTitle = {
  backgroundColor: "#419087",
  textAlign: 'center',
  padding: "5px 0",
  color: "#fff"
}

const styleSubTitle = {
  backgroundColor: "#419087",
  textAlign: 'center',
  color: "#fff"
}

const styleDetailTitle = {
  marginLeft: '10px'
}

const styleDetail_1 = { width: '130px', textAlign: 'right' }

const styleDetail_2 = { width: '120px', textAlign: 'right' }


const styleSpaceBetween = {
  justifyContent: "space-between"
}
const styleMargin05 = {
  margin: '0 5px'
}
const styleTextRight = {
  textAlign: 'right'
}
class WS1171001_AggregateInfoCorrect extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_Format: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = 'V4-VNS06930:   集計情報(修正)';

    this.state = {
      tableData: [],
      isLoading: true,
      isLoadingTable: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.Li_Format !== prevProps.Li_Format) {
      this.formRef.current.setFieldsValue({ Li_Format: this.props.Li_Format });
      this.setState({ isLoading: true })
      this.getScreenData(this.props.Li_Format)

    }
  }

  componentDidMount() {
    this.getInit()
  }

  getInit() {
    this.setState({ isLoading: true })
    this.getScreenData(this.props.Li_Format)
  }

  getScreenData = (value) => {
    this.setState({ isLoadingTable: true })
    this.setState({
      isLoadingForm: true,
    })


    AggregateInfoCorrectAction.getInit({ Li_Format: value }).then(res => {

      this.setState({
        tableData: res
      });
    }).finally(() => this.setState({ isLoadingTable: false }));

  }

  render() {
    return (
      <div className="aggregate-info-correct">
        <Card title="V4-VNS06930:   集計情報(修正)">



          <Space direction="vertical">

            <Space>
              <div style={{ ...styleBlueTitle, width: '150px' }}>FORMAT</div>
              <div style={{ marginLeft: '10px' }}>{this.props.Li_Format}</div>
            </Space>

            <Space style={{ display: 'flex', alignItems: 'flex-start' }}>

              <Space direction="vertical">
                <div style={{ ...styleSubTitle, height: '20px', width: '200px', marginTop: '20px' }}>健診種類</div>
                <Space>
                  <div style={styleDetailTitle}>{'[一般]'}</div>
                  <div style={styleDetail_1}>{this.state.tableData.medical_exam_type_general}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[付加]'}</div>
                  <div style={styleDetail_1}>{this.state.tableData.medical_exam_type_additional}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[乳子]'}</div>
                  <div style={styleDetail_1}>{this.state.tableData.medical_exam_type_infant}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[肝炎]'}</div>
                  <div style={styleDetail_1}>{this.state.tableData.medical_exam_type_hepatitis}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[ﾌｫﾛｰ]'}</div>
                  <div style={styleDetail_1}>{this.state.tableData.medical_exam_type_follow}</div>
                </Space>

              </Space>

              <Space direction="vertical">
                <div style={{ ...styleSubTitle, height: '20px', width: '250px', marginTop: '20px' }}>実施一般</div>
                <Space>
                  <div style={styleDetailTitle}>{'01[胸間胃間]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_01_chest_between_stomach}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'02[胸間胃直]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_02_chest_straight_stomach}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'03[胸直胃間]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_03_chest_between_stomach}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'04[胸直胃直]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_04_chest_straight_stomach}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'05[子宮がん]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_05_uterine_cancer}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'06[乳子宮]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_06_breast_uterus}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'07[胸胃未実施]'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_07_chest_stomach_not_performed}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'08[胸間のみ]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_08_chest_only}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'09[胸直のみ]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_09_chest_straight_only}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'10[胃間のみ]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_10_stomach_only}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'11[胃直のみ]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_11_stomach_straight_only}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'胃内視鏡検査　 '}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_out_gastroscope}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'乳房ﾏﾝﾓ1方向　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_out_breast_1_direction}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'乳房ﾏﾝﾓ2方向　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_out_breast_2_direction}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'眼底　　　　　  '}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_general_out_fundus}</div>
                </Space>
                <div style={{ ...styleSubTitle, height: '20px', width: '250px', marginTop: '10px' }}>実施付加</div>
                <Space>
                  <div style={styleDetailTitle}>{'[付加]　　　　 '}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_addition_added}</div>
                </Space>
                <div style={{ ...styleSubTitle, height: '20px', width: '250px', marginTop: '10px' }}>実施肝炎</div>
                <Space>
                  <div style={styleDetailTitle}>{'[同日実施]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_hepatitis_same_day_implement}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[後日実施]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_hepatitis_later_day_implement}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[RNA実施]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_hepatitis_rna_implement}</div>
                </Space>
                <div style={{ ...styleSubTitle, height: '20px', width: '250px', marginTop: '10px' }}>実施ﾌｫﾛｰ</div>
                <Space>
                  <div style={styleDetailTitle}>{'[追加なし]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_followup_add_none}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[糖負荷追加]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_followup_add_sugar_load}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[HbA1c追加]'}</div>
                  <div style={{ ...styleDetail_2, marginLeft: '12px' }}>{this.state.tableData.implement_followup_add_hba1c}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[全て追加]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.implement_followup_add_all}</div>
                </Space>
              </Space>

              <Space direction="vertical">
                <div style={{ ...styleSubTitle, height: '20px', width: '250px', marginTop: '20px' }}>未実施付加</div>
                <Space>
                  <div style={styleDetailTitle}>{'[眼底]　　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_added_fundus}</div>
                </Space>

                <div style={{ ...styleSubTitle, height: '20px', width: '250px', marginTop: '20px' }}>未実施ﾌｫﾛｰ</div>
                <Space>
                  <div style={styleDetailTitle}>{'[血糖]　　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_foo_blood_sugar}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[総ｺﾚ]　　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_foo_total_kore}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[中性脂肪]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_foo_neutral_fat}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[HDL-ｺﾚ]'}</div>
                  <div style={{ ...styleDetail_2, marginLeft: '35px' }}>{this.state.tableData.unimplement_foo_hdl_kore}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[ＧＯＴ]　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_foo_got}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[ＧＰＴ]　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_foo_gpt}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[γ-GTP]　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_foo_gamma_gtp}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[尿酸]　　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_foo_uric_acid}</div>
                </Space>

              </Space>

              <Space direction="vertical">
                <div style={{ ...styleSubTitle, height: '20px', width: '250px', marginTop: '20px' }}>未実施一般[内訳]</div>

                <Space>
                  <div style={styleDetailTitle}>{'[空腹時血糖]　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_fasting_blood_sugar}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[総ｺﾚ]　　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_total_kore}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[ＧＯＴ]　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_got}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[ＧＰＴ]　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_gpt}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[ＡＬＰ]　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_alp}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[γ－ＧＴＰ]'}</div>
                  <div style={{ ...styleDetail_2, marginLeft: '20px' }}>{this.state.tableData.unimplement_gamma_gtp}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[中性脂肪]　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_neutral_fat}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[尿酸]　　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_uric_acid}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[ｸﾚｱﾁﾆﾝ]　　　'}</div>
                  <div style={styleDetail_2}>{this.state.tableData.unimplement_creatinine}</div>
                </Space>
                <Space>
                  <div style={styleDetailTitle}>{'[HDL-ｺﾚｽﾃ]'}</div>
                  <div style={{ ...styleDetail_2, marginLeft: '21px' }}>{this.state.tableData.unimplement_hdl_cholesterol}</div>
                </Space>


              </Space>

            </Space>

          </Space>

        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1171001_AggregateInfoCorrect);
