import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Select, Button, message } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import ConditionExpressCmtSubAction from 'redux/InspectionMaintenance/ConditionExpressCmtSetting/ConditionExpressCmtSub.action'
import WS0487008_HeaderInput from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487008_HeaderInput.jsx';
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx';
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry.jsx';
import WS0267001_CategorySearchQuerySingle from 'pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx';
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery.jsx';
import WS0178001_QuerySiteInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0178001_QuerySiteInfo.jsx';
import WS0179001_InquiryFindingInfo from 'pages/MS_InspectionMaintenance/V4MS9901300_NormalValueSettingMaintain/WS0179001_InquiryFindingInfo.jsx';
import WS0487007_JudgeHighlow from 'pages/MS_InspectionMaintenance/V4MS0106300_ConditionExpressCmtSetting/WS0487007_JudgeHighlow.jsx';
import ConditionExpressAddSubAction from 'redux/InspectionMaintenance/ConditionExpressCmtSetting/ConditionExpressAddSub.action.js';
import ModalDraggable from "components/Commons/ModalDraggable";


// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '40px' } }
}

class WS0487001_ConditionExpressAddSub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '条件式追加SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      minuteSelectDisabled: true,
      minuteDetailSelectDisabled: true,
      SelectOrSearchOrInput: 'Input',
      screenName: '',
      Code: '',
      andBox: '',

      HeaderCodeList: [],
      HeaderNameList: [],
      minuteSelect: [],
      selectDetailDataList: [],
    };
  }

  componentDidMount() {
    console.log(this.props.params);
    console.log(this.props.callScreen);
    ConditionExpressAddSubAction.getScreenData()
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          Operator: res.Operator,
          itemSelect: res.MainDivision
        })

        this.setState({
          HeaderCodeList: res.HeaderCodeList,
          HeaderNameList: res.HeaderNameList,
        })
      })
  }

  /**
   * 入力フォームのリセット
   */
  itemFormReset() {
    this.formRef.current?.setFieldsValue({
      CodeName: '',
      minuteSelect: '',
      minuteDetailSelect: ''
    })
    this.setState({
      minuteSelect: [],
      minuteDetailSelect: [],
      SelectOrSearchOrInput: 'Input',
      minuteDetailSelectDisabled: true,
    })
  }

  /**
   * CodeNameのサーチが押された場合
   */
  showScreenByBefore() {
    let key = this.formRef.current?.getFieldValue('itemSelect')

    if (key == undefined) {
      message.error('検査・カテゴリ・その他 を選んで下さい')
    } else if (key == 'K') {
      // 検査
      this.showWS0271001_InspectItemSearchQuerySingle()
    } else if (key == 'C') {
      // カテゴリ
      this.ShowWS0267001_CategorySearchQuerySingle()
    } else if (key == 'H') {
      // その他
      this.showWS0487008_HeaderInput()
    }
  }

  /**
   * 検査
   */
  showWS0271001_InspectItemSearchQuerySingle() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 800,
        component:
          <WS0271001_InspectItemSearchQuerySingle
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                CodeName: output.recordData.exam_name
              })
              this.setState({
                examType: output.recordData.exam_type,
                Code: output.recordData.test_item_code,
              })

              let params = {
                MainDivision: this.formRef.current?.getFieldValue('itemSelect'),
                Code: output.recordData.test_item_code,
              }

              // 上段の選択判定
              this.judgementSelectDataList(params)
              this.closeModal()
            }}
          />
        ,
      },
    });
  }

  /**
   * カテゴリ
   */
  ShowWS0267001_CategorySearchQuerySingle() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 600,
        component: (
          <WS0267001_CategorySearchQuerySingle
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                CodeName: output.Lio_CategoryName
              })
              console.log(output);
              this.setState({
                Code: output.Lio_CategoryCode
              })
              let params = {
                MainDivision: this.formRef.current?.getFieldValue('itemSelect'),
                Code: output.Lio_CategoryCode,
              }

              // 上段の選択判定
              this.judgementSelectDataList(params)
              this.closeModal()
            }}
          />
        ),

      },
    })
  }

  /**
   * その他
   */
  showWS0487008_HeaderInput() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 200,
        component: (
          <WS0487008_HeaderInput
            HeaderCodeList={this.state.HeaderCodeList}
            HeaderNameList={this.state.HeaderNameList}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                CodeName: output.name
              })
              this.setState({
                Code: output.value
              })
              let params = {
                MainDivision: this.formRef.current?.getFieldValue('itemSelect'),
                Code: output.value
              }

              // 上段の選択判定
              this.judgementSelectDataList(params)

              // 下段の選択判定
              this.selectReflectData(params)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * minuteSelectの表示内容を条件に合わせて表示する
   * @param {*} params
   */
  judgementSelectDataList(params) {
    ConditionExpressAddSubAction.changeCode(params)
      .then((res) => {
        let all = [];
        let minuteSelectDisabled = true;

        if (res[0].length > 1 || this.formRef.current?.getFieldValue('itemSelect') == 'C') {
          for (let i = 0; i < res[0].length; i++) {
            all.push({ value: res[0][i], name: res[1][i] });
          }

          minuteSelectDisabled = false
        }

        this.setState({
          minuteSelect: all,
          minuteSelectDisabled: minuteSelectDisabled,
        })
      })
  }

  // 選択されている条件の詳細選択画面を表示
  selectReflectData(params) {
    if (params.MainDivision == 'K') {
      let name = ''
      // 検査
      if (params.examType == 'S') {
        if (params.Code == ' ') {
          name = 'S'
        }
        else if (params.Code == 'b') {
          name = 'WS0178001_QuerySiteInfo'
        } else if (params.Code == 's') {
          name = 'WS0179001_InquiryFindingInfo'
        } else if (params.Code == 'a') {
          name = 'PartAndFindings'
        } else if (params.Code == 'B') {
          name = 'WS0178001_QuerySiteInfo'
        } else if (params.Code == 'S') {
          name = 'WS0179001_InquiryFindingInfo'
        } else if (params.Code == 'A') {
          name = 'PartAndFindings'
        } else if (params.Code == 'H') {
          name = 'WS0285001_JudgeQuery'
        }
      } else {
        if (params.Code == ' ') {
          name = 'WS0274001_InspectCmtSearchQuery'
        } else if (params.Code == 'H') {
          name = 'WS0285001_JudgeQuery'
        } else if (params.Code == 'h') {
          name = 'JudgeQueryHL'
        } else if (params.Code == 'L') {
          name = 'L'
        }
      }

      if (name == '') {
        this.setState({
          SelectOrSearchOrInput: 'Input',
          minuteDetailSelectDisabled: false
        })
      } else if (name == 'S' || name == 'L') {
        let data = []
        data.push({ value: 1, name: 1 })
        data.push({ value: 2, name: 2 })

        this.setState({
          SelectOrSearchOrInput: 'Select',
          minuteDetailSelectDisabled: false,
          minuteDetailSelect: data
        })
      } else {
        this.setState({
          SelectOrSearchOrInput: 'Search',
          minuteDetailSelectDisabled: false,
          screenName: name
        })
      }

    } else if (params.MainDivision == 'C') {
      // カテゴリ
      // 判定
      this.setState({
        SelectOrSearchOrInput: 'Search',
        minuteDetailSelectDisabled: false,
        screenName: 'WS0285001_JudgeQuery'
      })

    } else if (params.MainDivision == 'H') {
      // その他
      if (params.Code == '02010006' || params.Code == '5010011' || params.Code == '5010033') {
        // 年齢・施設・判定レベル
        this.setState({
          SelectOrSearchOrInput: 'Input',
          minuteDetailSelectDisabled: false
        })

      } else if (params.Code == '2010004' || params.Code == '2020007') {
        // 性別・続柄
        let data = []
        if (params.Code == '2010004') {
          data.push({ value: 1, name: 1 })
          data.push({ value: 2, name: 2 })
        } else if (params.Code == '2020007') {
          data.push({ value: 0, name: 0 })
          data.push({ value: 1, name: 1 })
          data.push({ value: 2, name: 2 })
        }

        this.setState({
          SelectOrSearchOrInput: 'Select',
          minuteDetailSelectDisabled: false,
          minuteDetailSelect: data
        })

      } else if (params.Code == '5010006') {
        // コース
        this.setState({
          SelectOrSearchOrInput: 'Search',
          minuteDetailSelectDisabled: false,
          screenName: 'WS0265001_BasicCourseInquiry'
        })

      } else if (params.Code == '5010029') {
        // 総合判定
        this.setState({
          SelectOrSearchOrInput: 'Search',
          minuteDetailSelectDisabled: false,
          screenName: 'WS0285001_JudgeQuery'
        })
      }
    }
  }

  /**
   * 下段の選択判定　分岐
   */
  selectSearchScreen() {
    switch (this.state.screenName) {
      // コース
      case 'WS0265001_BasicCourseInquiry':
        this.ShowWS0265001_BasicCourseInquiry()
        break;

      // 総合判定
      case 'WS0285001_JudgeQuery':
        this.ShowWS0285001_JudgeQuery()
        break;

      // 部位
      case 'WS0178001_QuerySiteInfo':
        this.righthandSideQuery()
        break;

      // 所見
      case 'WS0179001_InquiryFindingInfo':
        this.righthandSideQuery()
        break;

      // 検査コメント
      case 'WS0274001_InspectCmtSearchQuery':
        this.righthandSideQuery()
        break;

      // 部位・所見
      case 'PartAndFindings':
        this.righthandSideQuery()
        break;

      // 総合判定 HL
      case 'JudgeQueryHL':
        this.ShowWS0285001_JudgeQuery()
        break;
    }
  }

  // 部位・所見・検査コメント用事前準備
  righthandSideQuery() {
    let param = {
      Li_MainDivision: this.formRef.current?.getFieldValue('itemSelect'),
      internal_exam_code: this.state.Code,
      Li_Minute: this.formRef.current?.getFieldValue('minuteSelect'),
    }

    ConditionExpressAddSubAction.righthandSideQuery(param)
      .then((res) => {
        switch (this.state.screenName) {
          // 部位
          case 'WS0178001_QuerySiteInfo':
            this.ShowWS0178001_QuerySiteInfo(res)
            break;

          // 所見
          case 'WS0179001_InquiryFindingInfo':
            this.ShowWS0179001_InquiryFindingInfo(res)
            break;

          // 検査コメント
          case 'WS0274001_InspectCmtSearchQuery':
            this.ShowWS0274001_InspectCmtSearchQuery(res)
            break;

          // 部位・所見
          case 'PartAndFindings':
            this.ShowWS0178001_QuerySiteInfo(res)
            break;
        }
      })
  }

  /**
   * 部位
   */
  ShowWS0178001_QuerySiteInfo(param) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 600,
        component: (
          <WS0178001_QuerySiteInfo
            Li_SiteClasify={param.category_code}
            onFinishScreen={async (output) => {
              let data = ('00000' + output.Lo_SiteCode).slice(-5)
              let flag = this.formRef.current?.getFieldValue('minuteSelect')

              await this.closeModal()

              switch (flag) {
                case 'b':
                  this.formRef.current.setFieldsValue({ minuteDetailSelect: data })
                  break;

                case 'B':
                  this.formRef.current.setFieldsValue({ minuteDetailSelect: output.Lo_SiteName })
                  break;

                case 'a':
                  await this.setState({ andBox: data })
                  await this.ShowWS0179001_InquiryFindingInfo(param)
                  break;

                case 'A':
                  await this.setState({ andBox: output.Lo_SiteName })
                  await this.ShowWS0179001_InquiryFindingInfo(param)
                  break;
              }
            }}
          />
        ),
      },
    })
  }

  /**
   * 所見
   */
  ShowWS0179001_InquiryFindingInfo(param) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 600,
        component: (
          <WS0179001_InquiryFindingInfo
            Li_FindingsClassify={param.category_code}
            onFinishScreen={(output) => {
              let data = ('00000' + output.Lo_FindingsCode).slice(-5)
              let flag = this.formRef.current?.getFieldValue('minuteSelect')
              let andDate = ''

              switch (flag) {
                case 's':
                  this.formRef.current.setFieldsValue({ minuteDetailSelect: data })
                  break;

                case 'S':
                  this.formRef.current.setFieldsValue({ minuteDetailSelect: output.Lo_FindingsName })
                  break;

                case 'a':
                  andDate = this.state.andBox + ':' + data
                  this.formRef.current.setFieldsValue({ minuteDetailSelect: andDate })
                  break;

                case 'A':
                  andDate = this.state.andBox + ':' + output.Lo_FindingsName
                  this.formRef.current.setFieldsValue({ minuteDetailSelect: andDate })
                  break;
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * コース選択画面
   */
  ShowWS0265001_BasicCourseInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                minuteDetailSelect: output.Lo_CourseCode
              })
              this.closeModal()
            }}
          />),
      },
    })
  }

  /**
   * 総合判定
   */
  ShowWS0285001_JudgeQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 600,
        component: (
          <WS0285001_JudgeQuery
            onFinishScreen={async (output) => {
              await this.closeModal()

              if (this.formRef.current?.getFieldValue('minuteSelect') == 'h') {
                await this.setState({ andBox: output.recordData.judgment_result })
                await this.ShowWS0487007_JudgeHighlow(output)
              } else {
                this.formRef.current?.setFieldsValue({
                  minuteDetailSelect: output.recordData.judgment_result
                })
              }
            }}
          />
        ),
      },
    })
  }

  /**
   * 検査コメント検索
   */
  ShowWS0274001_InspectCmtSearchQuery(param) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 600,
        component: (
          <WS0274001_InspectCmtSearchQuery
            Lio_CmtClassify={param.category_code}

            onFinishScreen={(output) => {
              console.log(output);
              this.formRef.current?.setFieldsValue({
                minuteDetailSelect: output.LnkOutInspectCmtScreen,
                code: output?.recordData.exam_comment_code
              })
              this.setState({
                code: output?.recordData.exam_comment_code
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 判定のHL
   */
  ShowWS0487007_JudgeHighlow(data) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 250,
        component: (
          <WS0487007_JudgeHighlow
            comment={data.recordData.judgment_result}
            onFinishScreen={(output) => {
              if (output == 'Large') {
                let andDate = this.state.andBox + '↑'
                this.formRef.current.setFieldsValue({ minuteDetailSelect: andDate })
              } else if (output == 'Small') {
                let andDate = this.state.andBox + '↓'
                this.formRef.current.setFieldsValue({ minuteDetailSelect: andDate })
              }

              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 保存が押された場合
   */
  save() {
    let values = this.formRef.current?.getFieldsValue()
    let params
    if (this.props.callScreen !== 'WS0486001_ConditionCorrectSub') {
      // 条件式訂正画面'以外'から呼ばれている場合
      if (values.minuteSelect === " ") {
        params = {
          ...values,
          id: '',
          code: this.state.Code,
          serial_number: this.props.params.serial_number,
          branch_number: values.serial_number,
          guidance_comment_code: this.props.params.guidance_comment_code
        }
      } else {
        params = {
          ...values,
          id: '',
          code: this.state.Code,
          serial_number: this.props.params.serial_number,
          branch_number: values.serial_number,
          guidance_comment_code: this.props.params.guidance_comment_code
        }
      }

      ConditionExpressCmtSubAction.saveDetails(params)
        .then((res) => {
          this.props.onSave()
        })
    } else {
      // 条件式訂正画面から呼ばれている場合
      params = {
        ...values,
        code: this.state.Code
      }
      ConditionExpressAddSubAction.save(params)
        .then((res) => {
          console.log(res);
          this.props.onFinishScreen(res.Lo_Setting)
        })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="condition-express-add-sub">
        <Card title="条件式追加">
          <Form
            ref={this.formRef}
          // onFinish={this.onFinish}
          >
            <div className="box_inner_vertical">
              {this.props.callScreen !== 'WS0486001_ConditionCorrectSub' ?
                <Form.Item
                  name="serial_number"
                  label='SEQ'
                  {...labelCol}
                >
                  <Input style={{ marginBottom: 10, width: 110 }} />
                </Form.Item> :
                null
              }

              <div
                className="box_inner_horizontal"
                style={{ justifyContent: 'flex-end' }}
              >
                <Form.Item
                  name="itemSelect"
                >
                  <Select
                    style={{ width: 150 }}
                    onChange={(event) => {
                      this.itemFormReset()
                    }}
                  >
                    <Select.Option value="K">検査</Select.Option>
                    <Select.Option value="C">カテゴリ</Select.Option>
                    <Select.Option value="H">その他</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="CodeName"
                  style={{ width: '100%' }}
                >
                  <Input.Search
                    onSearch={() => this.showScreenByBefore()}
                  />
                </Form.Item>

                <div style={{ marginTop: "5px" }}>の</div>

                <Form.Item
                  name="minuteSelect"
                >
                  <Select
                    style={{ width: 250 }}
                    disabled={this.state.minuteSelectDisabled}
                    onChange={(value) => this.selectReflectData({
                      MainDivision: this.formRef.current?.getFieldValue('itemSelect'),
                      Code: value,
                      examType: this.state.examType
                    })}
                  >
                    {this.state.minuteSelect?.map((item, index) => (
                      <Select.Option
                        key={index}
                        value={item.value}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="HistoryDivision"
                >
                  <Select style={{ width: 100 }}>
                    <Select.Option value={''}>今回</Select.Option>
                    <Select.Option value="1">前回</Select.Option>
                    <Select.Option value="2">前々回</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <div
                className="box_inner_horizontal"
                style={{ justifyContent: 'flex-end' }}
              >
                <div style={{ marginTop: "5px" }}>が</div>

                {
                  this.state.SelectOrSearchOrInput == 'Select' ?
                    <Form.Item
                      name="minuteDetailSelect"
                    >
                      <Select
                        style={{ width: 200 }}
                        disabled={this.state.minuteDetailSelectDisabled}
                      >
                        {this.state.minuteDetailSelect?.map((item, index) => (
                          <Select.Option
                            key={index}
                            value={item.value}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    :
                    this.state.SelectOrSearchOrInput == 'Search' ?
                      <Form.Item
                        name="minuteDetailSelect"
                      >
                        <Input.Search
                          style={{ width: 200 }}
                          disabled={this.state.minuteDetailSelectDisabled}
                          onSearch={() => this.selectSearchScreen()}
                        />
                      </Form.Item>
                      :
                      <Form.Item
                        name="minuteDetailSelect"
                      >
                        <Input
                          style={{ width: 200 }}
                          disabled={this.state.minuteDetailSelectDisabled}
                        />
                      </Form.Item>
                }

                <Form.Item
                  name="Operator"
                  style={{ width: 150 }}
                >
                  <Select>
                    <Select.Option value="=">と等しい</Select.Option>
                    <Select.Option value="≠">と等しくない</Select.Option>
                    <Select.Option value="≦">以下</Select.Option>
                    <Select.Option value="≧">以上</Select.Option>
                    <Select.Option value="＜">より小さい</Select.Option>
                    <Select.Option value="＞">より大きい</Select.Option>
                  </Select>
                </Form.Item>

              </div>

              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.save()
                  }}
                >
                  <span className='btn_label'>
                    {this.props.callScreen !== 'WS0486001_ConditionCorrectSub' ? '保存' : '更新'}
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0487001_ConditionExpressAddSub);