import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Table, Space, Dropdown, Menu, message, Modal } from "antd";
import { MoreOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import ComplexDecisionMasterMaintenanceAction from "redux/Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenance.action";
import ComplexDecisionMasterMaintenanceFormatEdit from "pages/ZZ_Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenanceFormatEdit.jsx";
import ComplexDecisionMasterMaintenanceFormatNameEdit from "pages/ZZ_Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenanceFormatNameEdit.jsx";

const labelCol = {
  labelCol: { style: { width: 85 } }
}

class ComplexDecisionMasterMaintenanceFormat extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "複合判定マスタ保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRow: [],
      leftTable: [],
      rightTable: [],
      formatDetails: [],
    };
  }

  componentDidMount = () => {
    this.getFormatLists()
  };


  getFormatLists = () => {
    ComplexDecisionMasterMaintenanceAction.getFormatLists()
      .then(res => {
        this.setState({
          leftTable: res.formatNameList,
          formatDetails: res.formatDetails,
        })
        if(this.state.selectedRow) {
          this.setState({
            rightTable: res.formatDetails[this.state.selectedRow.formatName]
          });
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableParent: false });
      });
  }


  addOrEditFormatName = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ComplexDecisionMasterMaintenanceFormatNameEdit
            record={record}
            onFinishScreen={(output) => {
              let params = {
                ...output,
                id: record?.id ?? 0
              }
              ComplexDecisionMasterMaintenanceAction.addOrEditFormatName(params)
                .then(res => {
                  message.info(res.message);
                  if(res.format) {
                    let newSelectedRow = {
                      ...this.state.selectedRow,
                      formatName: res.format
                    }
                    this.setState({
                      selectedRow: newSelectedRow
                    })
                  }
                  this.getFormatLists()
                })
                .catch((err) => {
                  const res = err.response;
                  if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                  }
                  message.error(res.data.message);
                })
                .finally(() => {
                  this.closeModal();
                });
            }}
          />
        )
      }
    })
  }


  deleteFormat = (record) => {
    let params = {
      id: record.id,
    }
    ComplexDecisionMasterMaintenanceAction.deleteFormat(params)
      .then(res => {
        if(res?.message) {
          message.info(res.message);
        }
        this.setState({
          selectedRow: [],
          rightTable: [],
        });
        this.getFormatLists()
        this.closeModal()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableParent: false });
      });
  }


  editFormat = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ComplexDecisionMasterMaintenanceFormatEdit
            record={record}
            onFinishScreen={(output) => {
              let params = {
                ...output,
                ...this.state.selectedRow,
                i: record.i
              }
              ComplexDecisionMasterMaintenanceAction.editFormat(params)
                .then(res => {
                  message.info(res.message);
                  this.getFormatLists()
                })
                .catch((err) => {
                  const res = err.response;
                  if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                  }
                  message.error(res.data.message);
                })
                .finally(() => {
                  this.closeModal();
                  this.setState({isLoadingTableParent: false});
                });
            }}
          />
        )
      }
    })
  }


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };


  render() {
    return (
      <div className="spreadsheet-input-setting">
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <Card title="複合判定マスタ保守 FORMAT">
            <div className="box_inner_horizontal" >
              {/* 左テーブル */}
              <div>
                <Table
                  bordered={true}
                  dataSource={this.state.leftTable}
                  style={{ width: 220 }}
                  size='small'
                  pagination={false}
                  rowKey={(record) => record.id}
                  scroll={{ y: "69vh" }}
                  rowClassName={(record, index) => {
                    if (this.state.selectedRow) {
                      return record.id === this.state.selectedRow.id ? "ant-table-row-selected" : "";
                    }
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.setState({
                          selectedRow: record,
                          rightTable: this.state.formatDetails[record.formatName]
                        });
                      },
                      onDoubleClick: () => {
                        this.props.onFinishScreen(record.formatName)
                      },
                    };
                  }}
                >
                  <Table.Column
                    width={150}
                    title="FORMAT"
                    dataIndex="formatName"
                  />
                  <Table.Column
                    width={50}
                    key="action"
                    align="center"
                    title={() =>
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.addOrEditFormatName()
                        }}
                      />
                    }
                    render={(text, record, index) => {
                      return (
                        <Space>
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='1'
                                  onClick={() => {
                                    this.addOrEditFormatName(record)
                                  }}
                                >
                                  編集
                                </Menu.Item>
                                <Menu.Item
                                  key='2'
                                  onClick={() => {
                                    Modal.confirm({
                                      title: "確認",
                                      icon: (
                                        <QuestionCircleOutlined
                                          style={{ color: "#1890ff" }}
                                        />
                                      ),
                                      content: "削除しますか",
                                      onOk: () => {
                                        this.deleteFormat(record)
                                      },
                                    });
                                  }}
                                >
                                  削除
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button
                              icon={<MoreOutlined />}
                              size="small"
                            />
                          </Dropdown>
                        </Space>
                      );
                    }}
                  />
                </Table>
              </div>
              {/* 右テーブル */}
              <div>
                <Table
                  bordered={true}
                  dataSource={this.state.rightTable}
                  size={'small'}
                  pagination={false}
                  scroll={{ x: 'max-content', y: "69vh" }}
                  rowKey={(record) => record.exam_name + Math.random()}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.editFormat(record)
                      },
                    };
                  }}
                >
                  <Table.Column title='コード' dataIndex="exam_i_code" />
                  <Table.Column title='検査' dataIndex="exam_name" />
                  <Table.Column title='HL区分' dataIndex="exam_i_hl_division" />
                  <Table.Column title='判定置換オプション' dataIndex="exam_i_judgment_replacement" />
                  <Table.Column
                    width={50}
                    key="action"
                    render={(text, record, index) => {
                      return (
                        <Space>
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='1'
                                  onClick={() => {
                                    this.editFormat(record)
                                  }}
                                >
                                  編集
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button
                              icon={<MoreOutlined />}
                              size="small"
                            />
                          </Dropdown>
                        </Space>
                      );
                    }}
                  />
                </Table>
              </div>
            </div>

            {/* 決定ボタン */}
            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.props.onFinishScreen(this.state.selectedRow.formatName)
                }}
              >
                <span className='btn_label'>
                  決定
                </span>
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplexDecisionMasterMaintenanceFormat);
