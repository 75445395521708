import React from 'react'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { connect } from 'react-redux'
import { Card, Form, Input, Button, Table, } from 'antd'
import { MoreOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import { getAllOfficeInfoRetrievalQueryAction } from 'redux/basicInfo/OfficeInfoMaintain/OfficeInfoMaintain.action'

class WS0649003_CreateDocumentBatchConditionAddOfficeInfoRetrieval extends React.Component {
  static propTypes = {
    Li_NewlyRegisteredPresence: PropTypes.any,
    Lio_OfficeCode: PropTypes.any,
    Lio_BranchStoreCode: PropTypes.any,
    Lo_Kanji_Name: PropTypes.any,
  }

  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = '事業所情報検索・照会'
    this.state = {
      dataSource: [],
      initialValues: {
        KanaSearch: '',
        PhoneNumSearch: '',
        InsuranceCardSymbolSearch: '',
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowKeys: [],
      selectedRows: [],
      selectedOfficeCodes: [],
    }
  }

  async componentDidMount() {
    const officeCode = this.props.Lio_OfficeCode ?? ''
    //親画面の検索ボックスに入力した場合
    this.formRef.current.setFieldsValue({ KanaSearch: officeCode })
    this.handleSearch(this.props.Lio_OfficeCode, 'KanaSearch')
  }

  handleSearch = (value, name) => {
    let params = {
      KanaSearch: this.formRef.current?.getFieldValue('KanaSearch'),
      PhoneNumSearch: this.formRef.current?.getFieldValue('PhoneNumSearch'),
      InsuranceCardSymbolSearch: this.formRef.current?.getFieldValue('InsuranceCardSymbolSearch'),
    }
    this.loadData(params)
  }

  loadData = (params) => {
    getAllOfficeInfoRetrievalQueryAction(params)
      .then((res) => {
        let dataRes = res
        if (dataRes) {
          this.setState({
            dataSource: dataRes.data
          })
        }
        if(this.props.Lio_OfficeCode && this.props.Lio_OfficeCode == params.KanaSearch) {
          this.onSelectAll(true)
        }
      })
  }

  renderImpotant = (record) => {
    let icon = ''
    const iconSize = 20
    switch (record.importance) {
      case 1:
        icon = <InfoCircleOutlined style={{ fontSize: iconSize, color: '#1890ff' }} />
        break
      case 3:
        icon = <WarningOutlined style={{ fontSize: iconSize, color: '#faad14' }} />
        break
      case 5:
        icon = <CloseCircleOutlined style={{ fontSize: iconSize, color: '#ff4d4f' }} />
        break
      default:
        icon = <Button size='small' icon={<MoreOutlined />} />
    }
    return (
      <Form.Item style={{ cursor: 'pointer' }} onClick={() => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 1200,
            component: (
              <WS2585001_OfficeInfoInquirySub
                Li_OfficeCode={record.office_code}
                Li_BranchCode={record.branch_store_code}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: false,
                    },
                  })
                }}
              />
            ),
          },
        })

      }}>{icon}</Form.Item>
    )
  }

  GetDataBySearch = () => {
    this.setState(prevState => ({
      initialValues: {
        ...prevState.initialValues,
        KanaSearch: this.formRef.current?.getFieldValue('KanaSearch'),
        PhoneNumSearch: this.formRef.current?.getFieldValue('PhoneNumSearch'),
        InsuranceCardSymbolSearch: this.formRef.current?.getFieldValue('InsuranceCardSymbolSearch'),
      }
    }), () => this.loadData(this.state.initialValues))
  }

  /**
   * 選択 処理
   * @param {*} record
   */
  onSelect = (record) => {
    let arrTemp = [...this.state.selectedRowKeys]
    let arrTempRecord = [...this.state.selectedRows]
    let idx = arrTemp.indexOf(record.id)

    let officeCode = record.office_code + '-' + record.branch_store_code
    let arrOfficeCodes = [...this.state.selectedOfficeCodes]
    let idxOfficeCode = arrOfficeCodes.indexOf(officeCode)

    if (idx === -1) {
      arrTemp.push(record.id)
      arrTempRecord.push(record)
      arrOfficeCodes.push(officeCode)
    } else {
      arrTemp.splice(idx, 1)
      arrTempRecord.splice(idx, 1)
      arrOfficeCodes.splice(idxOfficeCode, 1)
    }
    this.setState({
      selectedRowKeys: arrTemp,
      selectedRows: arrTempRecord,
      selectedOfficeCodes: arrOfficeCodes,
    })
  }

  /**
   * 全選択 処理
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let arrTemp = []
    let arrTempRecord = []
    let arrOfficeCodes = []

    if (selected) {
      arrTempRecord = [...this.state.dataSource]
      arrTemp = arrTempRecord.map(item => item.id)
      arrOfficeCodes = arrTempRecord.map(item => item.office_code + '-' + item.branch_store_code)
      this.setState({
        selectedRowKeys: arrTemp,
        selectedRows: arrTempRecord,
        selectedOfficeCodes: arrOfficeCodes,
      })
    } else {
      this.setState({
        selectedRowKeys: [],
        selectedRows: [],
        selectedOfficeCodes: [],
      })
    }
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        className: ''
      }
    })
  }

  render() {
    const { initialValues, } = this.state
    return (
      <div className='office-info-retrieval-query'>
        <Card title='事業所情報検索・照会（複数）'>
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={initialValues} autoComplete='off'>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search' style={{ gap: '20px' }}>
                  <Form.Item name='KanaSearch' label='検索' style={{ marginBottom: 0 }}>
                    <Input onPressEnter={(e) => this.handleSearch(e.target.value, 'KanaSearch')} />
                  </Form.Item>
                  <Form.Item name='PhoneNumSearch' label='電話番号' style={{ marginBottom: 0 }}>
                    <Input onPressEnter={(e) => this.handleSearch(e.target.value, 'PhoneNumSearch')} />
                  </Form.Item>
                  <Form.Item name='InsuranceCardSymbolSearch' label='保険証記号' style={{ marginBottom: 0 }}>
                    <Input onPressEnter={(e) => this.handleSearch(e.target.value, 'InsuranceCardSymbolSearch')} />
                  </Form.Item>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.GetDataBySearch()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={record => record.id}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ y: 500, x: 'max-content' }}
                onRow={(record, index) => ({
                  onClick: (event) => {
                    this.onSelect(record)
                  },
                })}
                rowSelection={
                  this.props.multipleSelect ?
                    {
                      type: 'checkbox',
                      fixed: 'left',
                      selectedRowKeys: this.state.selectedRowKeys,
                      onSelect: (record) => {
                        // 行選択
                        this.onSelect(record)
                      },
                      onSelectAll: (selected) => {
                        // 行全選択
                        this.onSelectAll(selected)
                      },
                    }
                    :
                    undefined
                }
              >
                <Table.Column
                  title='コード'
                  dataIndex='office_code'
                  className='column-size-10'
                  render={(text, record) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        {text === 0 || text === '0' ? '' : text}
                      </div>
                    )
                  }}
                />
                <Table.Column title='枝番' dataIndex='branch_store_code' width={60}
                  render={(text, record) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        {text === 0 || text === '0' ? '' : text}
                      </div>
                    )
                  }}
                />
                <Table.Column title='メモ' align='center' width={40}
                  render={(text, record, index) => this.renderImpotant(record)} />
                <Table.Column title='カナ名称' dataIndex='office_kana_name' className='column-size-30' />
                <Table.Column title='漢字名称' dataIndex='office_kanji_name' className='column-size-30' />
                <Table.Column title='電話番号' dataIndex='phone_number' width={130} />
                <Table.Column title='保険証記号' dataIndex='insurer_card_symbol' width={100} />
                <Table.Column title='保険者' dataIndex='insurer_kanji_name' width={150} />
                <Table.Column title='契約' dataIndex='ContractPresence' width={45} />
              </Table>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  disabled={(this.props.multipleSelect && this.state.selectedRowKeys.length > 0) ? false : true}
                  onClick={() => {
                    this.props.multipleSelect({
                      selectedRowKeys: this.state.selectedRowKeys,
                      selectedRows: this.state.selectedRows,
                      selectedOfficeCodes: this.state.selectedOfficeCodes
                    })
                  }}
                  style={{ display: this.props.multipleSelect ? '' : 'none' }}
                >
                  <span className='btn_label'>
                    確定
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0649003_CreateDocumentBatchConditionAddOfficeInfoRetrieval)
