import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Row, Col, Typography } from "antd";

class WS2677012_CaptureResult extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    warning: PropTypes.any,
    Li_KekkaNumFiles: PropTypes.any,
    Li_ErrorNumFiles: PropTypes.any,
    Li_QualificationsThereNum: PropTypes.any,
    Li_QualificationWithoutNum: PropTypes.any,
    Li_ErrorCount: PropTypes.any,
    Li_ReserveNonGetNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);

    // document.title = '取込結果表示';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      initParams: {
        warning: '',
        Li_KekkaNumFiles: '',
        Li_ErrorNumFiles: '',
        Li_QualificationsThereNum: '',
        Li_QualificationWithoutNum: '',
        Li_ErrorCount: '',
        Li_ReserveNonGetNum: '',
        isChecked: false, //追加
      },

      isLoadingForm: false,
    };
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  onFinish(values) {
    this.closeModal();
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      Li_warning: this.props.Li_warning,
      Li_KekkaNumFiles: this.props.Li_KekkaNumFiles,
      Li_ErrorNumFiles: this.props.Li_ErrorNumFiles,
      Li_QualificationsThereNum: this.props.Li_QualificationsThereNum,
      Li_QualificationWithoutNum: this.props.Li_QualificationWithoutNum,
      Li_ErrorCount: this.props.Li_ErrorCount,
      Li_ReserveNonGetNum: this.props.Li_ReserveNonGetNum
    })
  };


  render() {
    return (
      <div className="capture-result">
        <Card title="取込結果表示">
          <Form ref={this.formRef} onFinish={this.onFinish} initialValues={this.state.initParams}>
            <Row>
              <Col span={24}>
                {this.props.Li_warning ?
                  <Form.Item name="warning" label="">
                    <Typography.Text>{this.props.warning}</Typography.Text>
                  </Form.Item>
                  : null}
              </Col>
            </Row>
            <div style={{ width: '45%' }}>
              <div className='box_inner_horizontal' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Row style={{ flex: 1 }}>
                  <Col span={2}></Col>
                  <div style={{ flex: '1' }}>資格結果ファイル件数</div>
                  <div style={{ flex: '1', textAlign: 'right' }}>{this.props.Li_KekkaNumFiles}</div>
                </Row>
              </div>

              <div className='box_inner_horizontal' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Row style={{ flex: 1 }}>
                  <Col span={2}></Col>
                  <div style={{ flex: '1' }}>エラーファイル件数</div>
                  <div style={{ flex: '1', textAlign: 'right' }}>{this.props.Li_ErrorNumFiles}</div>
                </Row>
              </div>
            </div>

            <div style={{ width: '70%' }}>
              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Row style={{ flex: 1 }}>
                  <Col span={12}></Col>
                  <div style={{ flex: '1' }}>資格あり件数</div>
                  <div style={{ flex: '1', textAlign: 'right' }}>{this.props.Li_QualificationsThereNum}</div>
                </Row>
              </div>

              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Row style={{ flex: 1 }}>
                  <Col span={12}></Col>
                  <div style={{ flex: '1' }}>資格なし件数</div>
                  <div style={{ flex: '1', textAlign: 'right' }}>{this.props.Li_QualificationWithoutNum}</div>
                </Row>
              </div>

              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Row style={{ flex: 1 }}>
                  <Col span={12}></Col>
                  <div style={{ flex: '1' }}>エラー件数</div>
                  <div style={{ flex: '1', textAlign: 'right' }}>{this.props.Li_ErrorCount}</div>
                </Row>
              </div>
              <div className='box_inner_horizontal' style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Row style={{ flex: 1 }}>
                  <Col span={12}></Col>
                  <div style={{ flex: '1' }}>予約取得不可件数</div>
                  <div style={{ flex: '1', textAlign: 'right' }}>{this.props.Li_ReserveNonGetNum}</div>
                </Row>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Form.Item>
                <Button type="primary"
                  onClick={(e) => {
                    if (this.props.onFinishScreen) {
                      this.props.onFinishScreen({ test: '12345' });
                    }
                  }}
                >OK</Button>
              </Form.Item>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2677012_CaptureResult);
