import React from "react";
import { connect } from "react-redux";
import { MoreOutlined } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Button, Table, Row, Col, Space, message } from "antd";
import { BatchProcess_F10Action, getCourseSelectAction } from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/CourseSelect.action";
import WS1174006_CourseCopyingFunction from "./WS1174006_CourseCopyingFunction";
import WS2704001_CategoryMasterMaintain from "pages/MS_InspectionMaintenance/V4MS0102000_CategoryMasterMaintain/WS2704001_CategoryMasterMaintain.jsx";
import WS1176001_InspectSetInfo from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176001_InspectSetInfo.jsx";
import WS0181001_QueryInspectSet from "./WS0181001_QueryInspectSet";
import WS1174003_CourseSelect_sub from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1174003_CourseSelect_sub.jsx";
class WS1174003_CourseSelect extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      mainPageSize: 10,
      subSitePageSize: 10,
      subFindingPageSize: 10,
      dataSourceMain: [],
      rowSelectMain: {},
      isLoadingTableMain: false
    };
  }

  componentDidMount = () => {
    this.loadDataMainTable();
  };

  BatchProcess_F10 = () => {
    BatchProcess_F10Action()
      .then(res => {
        if (res?.data) {
          message.info(res.data.message);
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  };

  loadDataMainTable = () => {
    getCourseSelectAction()
      .then(res => {
        if (res) {
          this.setState({
            dataSourceMain: res.data
          });
        }
      });
  };

  ReturnComponent = component => {
    let components = {
      WS2704001_CategoryMasterMaintain,
      WS1176001_InspectSetInfo,
      WS1174006_CourseCopyingFunction,
      WS0181001_QueryInspectSet
    };
    return components[component];
  };

  callModal = (props, width, nameScreen) => {
    let Component = this.ReturnComponent(nameScreen);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: width,
        component: (
          <Component
            {...props}
            onFinishScreen={() => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }

  renderMenuBar = () => (
    <Space>
      <Button
        type="primary"
        onClick={() => {
          this.callModal({ Li_Flag: true }, "80%", "WS2704001_CategoryMasterMaintain");
        }}
      >
        カテゴリ
      </Button>
      <Button
        type="primary"
        onClick={() =>
          this.callModal({ Li_BillingManageNum: "" }, "80%", "WS1176001_InspectSetInfo")
        }
      >
        セット
      </Button>
      <Button
        type="primary"
        onClick={() => {
          this.callModal(
            {
              Li_course_code: this.state.rowSelectMain ? this.state.rowSelectMain.course_code : "",
              Li_course_name_short_name: this.state.rowSelectMain
                ? this.state.rowSelectMain.course_name_short_name
                : "",
              Li_course_name_formal: this.state.rowSelectMain
                ? this.state.rowSelectMain.course_name_formal
                : ""
            },
            "70%",
            "WS1174006_CourseCopyingFunction"
          );
        }}
      >
        複写
      </Button>
      <Button type="primary" onClick={this.BatchProcess_F10}>
        一括処理
      </Button>
    </Space>
  );


  render() {
    return (
      <div className="course-select">
        <Card title="コース選択">
          {this.renderMenuBar()}

          <hr></hr>

          <Table
            bordered
            size="small"
            scroll={{ y: "85vh" }}
            dataSource={this.state.dataSourceMain}
            loading={this.state.isLoadingTableMain}
            rowKey={record => record.id}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  // 編集
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '60%',
                      className: '',
                      component:
                        <WS1174003_CourseSelect_sub
                          course_code={record.course_code}
                        />
                    },
                  })
                }
              }
            }}
          >
            <Table.Column
              title="基本コース"
              dataIndex="course_code"
              sorter={(a, b) => a.course_code.localeCompare(b.course_code)}
              showSorterTooltip={false}
              render={(value, record, index) => {
                return (
                  <div className="box_inner_horizontal">
                    <div style={{ width: 80 }}> {record.course_code}</div>
                    <div> {record.course_name_formal}</div>
                  </div>
                );
              }}
            />

            <Table.Column
              align='center'
              width={40}
              fixed='right'
              title={''}
              render={(text, record, index) => (
                <Button
                  size='small'
                  icon={<MoreOutlined />}
                  onClick={() => {
                    // 編集
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '80%',
                        className: '',
                        component:
                          <WS1174003_CourseSelect_sub
                            course_code={record.course_code}
                          />
                      },
                    })
                  }}
                />
              )}
            />
          </Table>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1174003_CourseSelect);
