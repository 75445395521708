import React from "react";
import { connect } from "react-redux";
import { Card, Form, Input, Modal, Button, Table, Space, Row, Col, message, Dropdown, Tooltip, Select, Menu } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { MoreOutlined, SearchOutlined, ScissorOutlined, CopyOutlined, SnippetsOutlined, ZoomInOutlined } from '@ant-design/icons';
import WS1313007_CsvOutputScreen from 'pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313007_CsvOutputScreen.jsx';
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1313008_DateDisplay from 'pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313008_DateDisplay.jsx';
import WS1313005_DataRangeCheck from 'pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313005_DataRangeCheck.jsx';
import WS1313010_CsvCaptureScreen from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313010_CsvCaptureScreen.jsx";
import WS1313002_XmlMedicalExamItemMasterChild from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313002_XmlMedicalExamItemMasterChild.jsx";
import XmlMedicalExamItemMasterSaveAction from 'redux/SpecificInsureMaintenance/XmlMedicalExamItemMaster/XmlMedicalExamItemMaster.action.js'


class WS1313001_XmlMedicalExamItemMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-XML03000:XML用健診項目マスタ';

    this.state = {
      dataSource: [],
      filteredData: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchValue: '',
      rowSelected: [],
      date: []
    };
  }

  componentDidMount() {
    this.loadScreenData();
    this.StartDateChange();
  }
  //APIを呼んでデータを取得している？
  // loadScreenData = () => {
  // VenusApiRoutesV2.callApi("API001313001002", {})
  //     .then(res => {
  //       this.setState({ dataSource: res, filteredData: res });
  //       console.log(res)
  //     })
  //     .catch(error => {
  //       message.error("エラーが発生しました");
  //     }).finally();
  // }


  //アクション作成済み
  loadScreenData() {
    // ★検索欄用
    let params = {
      SearchChar: this.formRef.current.getFieldValue('SearchChar'),
      StartDate: this.formRef.current.getFieldValue('StartDate'),
    }
    console.log(params)
    this.setState({ isLoading: true });
    XmlMedicalExamItemMasterSaveAction.xmlMedicalExamItemDataInput(params)
      .then(res => {
        console.log(res);
        let params = res ? res : [];
        this.setState({ dataSource: params, filteredData: params });
        // this.index(res[0])
      })
      .catch((error) => {
        message.error("エラーが発生しました");
      }).finally(
        this.setState({ isLoading: false })
      );
  }



  /**
   * 入力範囲
   */
  eventF8(record, flug) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component: (
          <WS1313005_DataRangeCheck
            date_of_adoption_history_on={record ? record.date_of_adoption_history_on : ''}
            item_code_jlac10_15={record ? record.item_code_jlac10_15 : ''}
            result_identification_jlac10_2={record ? record.result_identification_jlac10_2 : ''}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />),
      },
    })
  }

  /**
  * 編集(新規画面必要)
  */
  eventF11(record, flug) {
    console.log(record)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS1313002_XmlMedicalExamItemMasterChild
            getScreenData={() => {
              this.closeModal()
              this.loadScreenData()
              this.StartDateChange();
            }}
            //左：次の画面でつかう名前　右：現在の画面で使用している名称　持ってくる前に書く
            newFlag={flug}
            id={record ? record.id : ''}
            date_of_adoption_history_on={this.state.rowSelected.date_of_adoption_history_on}
            DateAdoptionChars={record ? record.DateAdoptionChars : ''}
            item_code_jlac10_15={record ? record.item_code_jlac10_15 : ''}
            result_identification_jlac10_2={record ? record.result_identification_jlac10_2 : ''}
            division_number={record ? record.division_number : ''}
            order_number={record ? record.order_number : ''}
            condition_code_string_12={record ? record.condition_code_string_12 : ''}
            ministry_item_name={record ? record.ministry_item_name : ''}
            xml_display_name={record ? record.xml_display_name : ''}
            data_type={record ? record.data_type : ''}
            xml_pattern={record ? record.xml_pattern : ''}
            max_bytes_or_format={record ? record.max_bytes_or_format : ''}
            xml_data_type={record ? record.xml_data_type : ''}
            display_units={record ? record.display_units : ''}
            ucum_unit_code={record ? record.ucum_unit_code : ''}
            observation_element={record ? record.observation_element : ''}
            auther_element={record ? record.auther_element : ''}
            auther_element_item_number={record ? record.auther_element_item_number : ''}
            series_exam_group_identification={record ? record.series_exam_group_identification : ''}
            series_exam_group_relationship_code={record ? record.series_exam_group_relationship_code : ''}
            identity_item_code={record ? record.identity_item_code : ''}
            identity_item_name={record ? record.identity_item_name : ''}
            xml_exam_method_code={record ? record.xml_exam_method_code : ''}
            exam_methods={record ? record.exam_methods : ''}
            result_code_oid={record ? record.result_code_oid : ''}
            item_code_oid={record ? record.item_code_oid : ''}

          />
        ),
      },
    })
  }

  /**
   * 削除
   * @param {*} record
   */
  // deleteRecord = (record) => {
  //   Modal.confirm({
  //     content: '削除してもよろしいですか？',
  //     okText: 'は　い',
  //     cancelText: 'いいえ',
  //     onOk: () => {
  //       const params = {
  //         id: record.id
  //       }
  //       // PrintParamInputAction.deletePrintParamMaintainAction(params)
  //       //   .then(res => {
  //       //     message.success('削除が完了しました')
  //       //     this.index()
  //       //   })
  //     }
  //   })
  // }

  //CSV出力に使ってたいらないやつ
  // eventF9 = () => {
  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: 400,
  //       component: (
  //         <WS1313007_CsvOutputScreen

  //         />),
  //     },
  //   })
  // }

  // eventF10 = () => {
  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: 400,
  //       component: (
  //         <WS1313010_CsvCaptureScreen

  //         />),
  //     },
  //   })
  // }


  StartDateChange = () => {
    //res = phpのresult
    this.setState({ isLoadingTable: true });
    XmlMedicalExamItemMasterSaveAction.getscreendata()
      .then(res => {
        this.setState({ date: res, isLoadingTable: false });
      });
  }


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  renderMenuBar = () => (
    <Space>
      {/* <Button
        type='primary'
        onClick={() => { this.eventF8() }}
        disabled={this.state.rowSelected.length === 0}
      >
        入力範囲
      </Button> */}

      {/* <Button
        type='primary'
        onClick={() => { this.eventF9() }}
      >
        出力
      </Button>

      <Button
        type='primary'
        onClick={() => { this.eventF10() }}
      >
        取込
      </Button> */}
    </Space>
  )

  handleFilter() {
    this.setState({
      filteredData: this.state.dataSource
    })
    if (this.state.searchValue) {
      this.setState({
        filteredData: this.state.dataSource.filter(item => item.DateAdoptionChars === this.state.searchValue)
      })
    }
  }

  render() {
    return (
      <div className="xml-medical-exam-item-master">
        <Card title="JLAC10コードマスタ">
          <Form ref={this.formRef}
            // 以下WS2701001_CautionGuideMatterCmtMaintainを参考に追加
            initialValues={{
              SearchChar: '',
              StartDate: ''
            }}
          //ここまで
          >
            <div className='box_inner_horizontal'>

              {/* 検索欄 */}
              <Form.Item name='SearchChar' label='検索'>
                <Input
                  maxLength={256}
                  onPressEnter={(e) => {
                    if (e.key === 'Enter') {
                      this.loadScreenData()
                    }
                  }}
                />
              </Form.Item>

              <Form.Item name='StartDate' label='開始日' >
                {/* モーダルからプルダウン型にしたため非活性化
                <Input.Search
                  type="text"
                  // readOnly
                  // value={this.formRef.current?.getFieldValue('StartDate')}
                  onSearch={() =>
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: 400,
                        component:
                          <WS1313008_DateDisplay
                            onFinishScreen={(selectedDate) => {
                              this.formRef.current?.setFieldsValue({
                                StartDate: selectedDate
                              });
                              console.log(selectedDate)
                              // this.setState({ StartDate: selectedDate });
                              this.closeModal();
                            }}
                          />
                      }
                    })
                  }
                /> */}
                <Select
                  style={{ width: '200px' }}
                  allowClear
                >
                  {this.state.date.map((item, index) => (
                    <Select.Option
                      value={item.Date}
                      key={index}
                    >
                      <Tooltip title={item.Date}>
                        {item.Date}
                      </Tooltip>
                    </Select.Option>
                  ))
                  }
                </Select>
              </Form.Item>

              {/* <div className='box_search_btn'> */}
              <Button
                icon={<SearchOutlined />}
                onClick={() => {
                  this.loadScreenData()
                }}
              >
                <span className='btn_label'>
                  検索
                </span>
              </Button>
              {/* </div> */}
            </div>

            <div style={{ width: '100%' }}>
              <Table
                scroll={{ x: 4300 }}
                bordered
                size='small'
                dataSource={this.state.filteredData}
                rowKey={(record) => record.id}
              >

                <Table.Column
                  width={100}
                  title="採用日"
                  dataIndex="DateAdoptionChars"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.DateAdoptionChars?.localeCompare(b.DateAdoptionChars, 'ja')}
                />

                <Table.Column
                  width={160}
                  title="項目コード"
                  dataIndex="item_code_jlac10_15"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.item_code_jlac10_15?.localeCompare(b.item_code_jlac10_15, 'ja')}
                />

                <Table.Column
                  width={100}
                  title="結果識別"
                  dataIndex="result_identification_jlac10_2"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.result_identification_jlac10_2?.localeCompare(b.result_identification_jlac10_2, 'ja')}
                />

                <Table.Column
                  width={100}
                  title="区分番号"
                  dataIndex="division_number"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.division_number - b.division_number}
                />

                <Table.Column
                  width={100}
                  title="順番号"
                  dataIndex="order_number"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.order_number - b.order_number}
                />

                <Table.Column
                  width={130}
                  title="条件コード"
                  dataIndex="condition_code_string_12"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.condition_code_string_12?.localeCompare(b.condition_code_string_12, 'ja')}
                />

                <Table.Column
                  width="10%"
                  title="厚労省項目名"
                  dataIndex="ministry_item_name"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.ministry_item_name?.localeCompare(b.ministry_item_name, 'ja')}
                />

                <Table.Column
                  width="10%"
                  title="ＸＭＬ表示名"
                  dataIndex="xml_display_name"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.xml_display_name?.localeCompare(b.xml_display_name, 'ja')}
                />

                <Table.Column
                  width={100}
                  title="ﾃﾞｰﾀﾀｲﾌﾟ"
                  dataIndex="data_type"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.data_type?.localeCompare(b.data_type, 'ja')}
                />

                <Table.Column
                  width={100}
                  title="XMLﾊﾟﾀｰﾝ"
                  dataIndex="xml_pattern"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.xml_pattern?.localeCompare(b.xml_pattern, 'ja')}
                />

                <Table.Column
                  width={150}
                  title="最大ﾊﾞｲﾄORﾌｫｰﾏｯﾄ"
                  dataIndex="max_bytes_or_format"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.max_bytes_or_format?.localeCompare(b.max_bytes_or_format, 'ja')}
                />

                <Table.Column
                  width={100}
                  title="XMLﾃﾞｰﾀ型"
                  dataIndex="xml_data_type"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.xml_data_type?.localeCompare(b.xml_data_type, 'ja')}
                />

                <Table.Column
                  width={100}
                  title="表示単位"
                  dataIndex="display_units"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.display_units?.localeCompare(b.display_units, 'ja')}
                />

                <Table.Column
                  width={120}
                  title="UCUM単位ｺｰﾄﾞ"
                  dataIndex="ucum_unit_code"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.ucum_unit_code?.localeCompare(b.ucum_unit_code, 'ja')}
                />

                <Table.Column
                  width={150}
                  title="observation要素"
                  dataIndex="observation_element"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.observation_element - b.observation_element}
                />

                <Table.Column
                  width={100}
                  title="auther要素"
                  dataIndex="auther_element"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.auther_element - b.auther_element}
                />

                <Table.Column
                  width={180}
                  title="auther要素項目番号"
                  dataIndex="auther_element_item_number"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.auther_element_item_number?.localeCompare(b.auther_element_item_number, 'ja')}
                />

                <Table.Column
                  width={180}
                  title="一連検査ｸﾞﾙｰﾌﾟ識別"
                  dataIndex="series_exam_group_identification"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.series_exam_group_identification?.localeCompare(b.series_exam_group_identification, 'ja')}
                />

                <Table.Column
                  width={180}
                  title="一連検査ｸﾞﾙｰﾌﾟ関係ｺｰﾄﾞ"
                  dataIndex="series_exam_group_relationship_code"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.series_exam_group_relationship_code?.localeCompare(b.series_exam_group_relationship_code, 'ja')}
                />

                <Table.Column
                  width={130}
                  title="同一性項目ｺｰﾄﾞ"
                  dataIndex="identity_item_code"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.identity_item_code?.localeCompare(b.identity_item_code, 'ja')}
                />

                <Table.Column
                  title="同一性項目名称"
                  dataIndex="identity_item_name"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.identity_item_name?.localeCompare(b.identity_item_name, 'ja')}
                />

                <Table.Column
                  width={150}
                  title="XML検査方法ｺｰﾄﾞ"
                  dataIndex="xml_exam_method_code"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.xml_exam_method_code?.localeCompare(b.xml_exam_method_code, 'ja')}
                />

                <Table.Column
                  width={250}
                  title="検査方法"
                  dataIndex="exam_methods"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.exam_methods?.localeCompare(b.exam_methods, 'ja')}
                />

                <Table.Column
                  width={190}
                  title="結果ｺｰﾄﾞOID"
                  dataIndex="result_code_oid"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.result_code_oid?.localeCompare(b.result_code_oid, 'ja')}
                />

                <Table.Column
                  width={190}
                  title="項目ｺｰﾄﾞOID"
                  dataIndex="item_code_oid"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.item_code_oid?.localeCompare(b.item_code_oid, 'ja')}
                />


                <Table.Column
                  align='center'
                  width={40}
                  fixed='right'
                  title={
                    <Button
                      size='small'
                      type='primary'
                      disabled={this.state.isAddRow}
                      icon={<PlusOutlined />}
                      onClick={() => this.eventF11(null, true)}
                    />
                  }
                  render={(text, record, index) => {
                    return (
                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='1'
                              onClick={() => this.eventF11(record, false)}
                            >
                              編集
                            </Menu.Item>
                            {/* 入力範囲は開かないようにする。
                          <Menu.Item
                            key='5'
                            onClick={() => { this.eventF8(record) }}
                          // disabled={this.state.rowSelected.length === 0}
                          >
                            入力範囲
                          </Menu.Item> */}
                          </Menu>
                        )}
                      >
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313001_XmlMedicalExamItemMaster);
