import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Button, Select, Input, Modal, Tooltip, Dropdown, Menu } from 'antd'
import { PlusOutlined, MoreOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import DataImportAction from 'redux/DataMigration/DataImport.action.js'
import SiteFindingsGroupDetailSetting from 'pages/DataMigration/DataConversion/SiteFindingsGroupDetailSetting.jsx'
import SiteFindingsGroupInspectionSetting from 'pages/DataMigration/DataConversion/SiteFindingsGroupInspectionSetting.jsx'

class SiteFindingsGroupSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
    }
  }

  openInspectionSetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <SiteFindingsGroupInspectionSetting
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
      }
    })
  }

  openDetailSetting = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <SiteFindingsGroupDetailSetting
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='site-findings-group-setting'>
        <Card title='部位所見グループ設定' style={{ width: 'fit-content' }}>
          <div className='box_container' >
            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              scroll={{ x: 'max-content', y: 550 }}
              rowKey={(record) => record.columnName}
              style={{ width: 500 }}
            >
              <Table.Column title='検査コード' dataIndex='inspectionCode' />
              <Table.Column
                title='検査名称'
                dataIndex='inspectionName'
              />
              <Table.Column
                width={40}
                title={
                  //新規登録のボタン
                  <Button
                    size='small'
                    type='primary'
                    style={{ textAlign: 'center' }}
                    icon={<PlusOutlined />}
                    onClick={() => { this.openInspectionSetting() }}
                  >
                  </Button>
                }
                render={(text, record, index) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='編集'
                            onClick={() => { this.openDetailSetting() }}
                          >
                            編集
                          </Menu.Item>
                          <Menu.Item
                            key='削除'
                            onClick={() => { console.log('削除ボタン') }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        style={{ width: '100%' }}
                      ></Button>
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteFindingsGroupSetting)