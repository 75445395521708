
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import PropTypes from 'prop-types'
import Color from 'constants/Color'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { connect } from 'react-redux'
import { download_file } from 'helpers/CommonHelpers'
import { Card, Form, Input, Button, Table, Menu, Dropdown, message, Modal, Tooltip } from 'antd'
import { PlusOutlined, SearchOutlined, MoreOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS1296010_ConsultTicketInputSub from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296010_ConsultTicketInputSub.jsx'
import status_input_lock from 'assets/img/status_input_lock.png'
import status_input_lock_disabled from 'assets/img/status_input_lock_disabled.png'
import ExamineeListAction from 'redux/InputBusiness/ExamineeList/ExamineeList.action'
import WS3020036_CoupledPrintInstruction from '../V4IN0302000_NotInputCheckCategory/WS3020036_CoupledPrintInstruction'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import ResultDisplay from "pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplay.jsx"
import ResultDisplayAction from 'redux/InputBusiness/InterviewSupport/ResultDisplay.action.js'
import MemoFunction from 'pages/ZZ_Others/SANAI_Memo/MemoFunction'

const today = moment(new Date().toISOString())

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: '65px' } }
}


const dateFormat = 'YYYY/MM/DD'
class InterviewSupport extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_MenuOption: PropTypes.any,
    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '面談支援'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        // eslint-disable-next-line no-undef
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      conditionAddData: null,
      ResultDisplayUpdateBtn: true,
      MemoFunctionUpdateBtn: true,
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    // 受診日と個人番号の初期値設定
    this.formRef.current.setFieldsValue({
      PersonalNum: '',
      DateFChar: today.format('YYYY/MM/DD'),
    })
  }

  /**
   * 受診者一覧を取得
   */
  getExamineeList = () => {
    let params = {
      ...this.formRef.current.getFieldValue(),
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar'))?.format('YYYY/MM/DD'),
      conditionAddData: this.state.conditionAddData,
    }

    ExamineeListAction.getExamineeList(params)
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res.result
          })
        }
      })
  }

  excelReport() {
    let params = {
      ...this.formRef.current.getFieldValue(),
      DateFChar: moment(this.formRef.current.getFieldValue('DateFChar'))?.format('YYYY/MM/DD'),
    }
    ExamineeListAction.excelReport(params)
      .then((res) => {
        download_file(res)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 印刷前処理
   */
  eventF11(record) {
    this.setState({ isLoadingPage: true })
    ExamineeListAction.eventF11()
      .then((res) => {
        if (res.data.message === 'CallScreenWS0802001') {
          this.showModalPrintInstruction(record)
        } else {
          if (res.data.message === 'CallScreenWS3020036') {
            this.showModalCoupledPrintInstruction(record)
          }
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  showModalPrintInstruction(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_CourseLevel={record.course_level}
            Li_ReserveNum={record.reservation_number}
            // Li_Parameters={this.state.values.Li_Parameters}
            onFinishScreen={(obj) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showModalCoupledPrintInstruction(record) {
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        component: (
          <WS3020036_CoupledPrintInstruction
            Li_ReserveNum={record.reservation_number}
            Li_CourseLevel={record.course_level}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 総合結果表示/入力 モーダル
   * @param {*} record
   */
  showResultDisplay = (record, index) => {
    let protectImage = record.protectImage === 'アンロック.png' ? true : false
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <ResultDisplay
            Li_ReserveNum={record.reservation_number}
            Li_MenuOption={this.props.Li_MenuOption}
            protectFlag={record.protectFlag}
            provisionalFlag={record.provisionalFlag}
            dataSource={this.state.dataSource}
            currentIndex={index}
            protectImage={protectImage}
            onUpdate={() => {
              // 子モーダルでテーブルが更新された為、受診者一覧の最新を取得する
              this.getExamineeList()
            }}

            // 結果入力画面での保存ボタンの状態
            onFlagUpdate={(bool) => {
              this.setState({ ResultDisplayUpdateBtn: bool })
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    })
  }

  changeProgressData = (check, record) => {
    let params = {
      check: check,
      reserveNum: record.reservation_number,
      name: 'データ保護',
      program: '結果表'
    }
    ResultDisplayAction.changeProgressData(params)
      .then(res => {
        // 受診者一覧の最新を取得する
        this.getExamineeList()
      })
  }

  render() {
    return (
      <div className='examinee-list'>
        <Card title='面談支援'>
          <div className='box_container'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_inner_vertical'>
                    <Form.Item
                      name='DateFChar'
                      label='受診日'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                      />
                    </Form.Item>

                    <div className='box_inner_horizontal'>
                      <Form.Item
                        name='PersonalNum'
                        label='個人番号'
                        style={{ marginBottom: 0 }}
                        {...labelCol}
                      >
                        <Input.Search
                          className='input-search-size-number-allow-clear-10'
                          type='text'
                          allowClear={true}
                          onSearch={(value, event) => {
                            if (value !== '' || event.target.localName !== 'input') {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '85%',
                                  component:
                                    <WS0248001_PersonalInfoSearchQuery
                                      date={moment(new Date()).format('YYYY/MM/DD')}
                                      searchName={this.formRef.current?.getFieldValue('PersonalNum')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          PersonalNum: output.recordData.personal_number_id,
                                          kanji_name: output.recordData.kanji_name
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                }
                              })
                            }
                          }}

                          onChange={(event) => {
                            if (event.target.value === '') {
                              // 漢字名を空に変更
                              this.formRef.current.setFieldsValue({
                                kanji_name: ''
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='kanji_name'
                        style={{ marginBottom: 0 }}
                      >
                        <Input
                          readOnly
                          style={{ background: 'transparent', border: 'none' }}
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name='Search'
                      label='検索'
                      style={{ marginBottom: 0 }}
                      {...labelCol}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          this.getExamineeList()
                        }}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: 0 }}>
                      <Tooltip title='詳細な条件追加'>
                        <Button
                          icon={<PlusOutlined />}
                          size='small'
                          type='primary'
                          style={{ borderRadius: '12px' }}
                          className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 900,
                                component:
                                  <WS2786001_ConditionAddSub
                                    Li_DateF={this.formRef.current.getFieldValue('DateFChar')}
                                    Li_DateT={this.formRef.current.getFieldValue('DateFChar')}
                                    Li_PersonalNum={this.formRef.current.getFieldValue('PersonalNum')}
                                    Lio_KeyInfo={this.formRef.current.getFieldValue('KeyInfo')}
                                    Li_State={'1'}
                                    Li_ConditionAddData={this.state.conditionAddData}
                                    onFinishScreen={(output) => {
                                      // 追加条件のデータをstate変数に保持
                                      this.setState({
                                        conditionAddData: output.conditionAddData,
                                      })
                                      this.formRef.current.setFieldsValue({
                                        KeyInfo: output.Lio_KeyInfo ? output.Lio_KeyInfo : ''
                                      })

                                      this.closeModal()
                                    }}
                                  />
                                ,
                              },
                            })
                          }} />
                      </Tooltip>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>

            <Table
              size='small'
              style={{ cursor: 'pointer' }}
              dataSource={this.state.dataSource}
              bordered={true}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: resizableTableScroll(80) }}
              onRow={(record, rowIndex) => {
                return {
                  onDoubleClick: () => {
                    // 総合結果表示/入力 モーダル
                    this.showResultDisplay(record, rowIndex)
                  }
                }
              }}
              // eslint-disable-next-line no-undef
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            >
              <Table.Column
                width={45}
                dataIndex='protectImage'
                title={<img src={status_input_lock} />}
                align='center'
                render={(value, record) => {
                  return (
                    <div>
                      <img src={(value === 'アンロック.png') ? status_input_lock_disabled : status_input_lock} />
                    </div>
                  )
                }}
              />

              <Table.Column
                title='受診日'
                dataIndex='visit_date_on'
                className='column-size-date'
                render={(text) => (<div>{moment(text).format('YYYY/MM/DD (ddd)')}</div>)}
              />

              <Table.Column
                title='メモ'
                width={45}
                dataIndex='consultationMemo'
                render={(value, record, index) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 'fit-content',
                            component: (
                              <MemoFunction
                                date={record.visit_date_on}
                                KanjiName={record.PersonalName}
                                personal_number_id={record.personal_number_id}
                                reservation_number={record.reservation_number}
                                onSaveMemos={async () => {
                                  await this.closeModal()
                                  await this.getExamineeList()
                                }}

                                // メモ入力画面での保存ボタンの状態
                                onFlagUpdate={(bool) => {
                                  console.log('onFlagUpdate:' + bool);
                                  this.setState({
                                    MemoFunctionUpdateBtn: bool
                                  })
                                }}
                              />
                            ),
                          }
                        })
                      }}
                    >
                      {value.map((items, index) =>
                        <div
                          key={index}
                          style={{ display: 'flex', }}
                        >
                          <div
                            style={{ color: items.color }}
                          >
                            {items.text}
                          </div>
                          {((value.length - 1) > index) ?
                            <div>
                              {'・'}
                            </div>
                            :
                            null
                          }
                        </div>
                      )
                      }
                    </div>
                  )
                }}
              />

              <Table.Column
                width={45}
                title='禁忌'
                dataIndex='taboos'
                render={(value) => (
                  <div style={{ textAlign: 'center', fontSize: '17px' }}>{value}</div>
                )}
              />

              <Table.Column
                className='column-size-5'
                title='受付No'
                dataIndex='receipt_number'
                render={(value, record, index) =>
                  <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                }
              />

              <Table.Column
                className='column-size-10'
                title='個人番号'
                dataIndex='personal_number_id'
                render={(value, record, index) =>
                  <div style={{ textAlign: 'right' }}>
                    {value === 0 ? '' : value}
                  </div>
                }
              />

              <Table.Column
                title='カナ氏名'
                dataIndex='PersonalKanaName'
              />

              <Table.Column
                title='氏名'
                dataIndex='PersonalName'
              />

              <Table.Column
                className='column-size-4'
                title='性別'
                dataIndex='gender'
                render={(value, record) => (
                  <div style={{
                    textAlign: 'center',
                    color: Color(record.genderColorNum)?.Foreground
                  }}>
                    {value}
                  </div>
                )}
              />

              <Table.Column
                className='column-size-20'
                title='生年月日'
                dataIndex='personalBirthdayOn'
              />

              <Table.Column
                className='column-size-4'
                title='年齢'
                dataIndex='age'
                render={(value, record, index) =>
                  <div style={{ textAlign: 'right' }}>{value === 0 ? '' : value}</div>
                }
              />

              <Table.Column
                title='契約情報'
                dataIndex=''
                render={(value, record) => (
                  <div className='box_inner_horizontal'>
                    <div>{record.visit_course}</div>
                    <div style={{ marginLeft: '3px' }}>{record.contract_short_name}</div>
                  </div>
                )}
              />

              <Table.Column
                className='column-size-10'
                title='医師名'
                dataIndex='DoctorName'
              />

              <Table.Column
                className='column-size-5'
                title='階層化'
                dataIndex='hierarchicalCheck'
                render={(value) => (
                  <div style={{ textAlign: 'center' }}>{value}</div>
                )}
              />

              <Table.Column
                width={45}
                align='center'
                fixed='right'
                render={(value, record) => {
                  return (
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='menu-1'
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  width: 'fit-content',
                                  visible: true,
                                  component: (
                                    <WS2583001_ConsultInquirySub
                                      Li_ReserveNum={record.reservation_number}
                                      onFinishScreen={() => {
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            照会
                          </Menu.Item>

                          <Menu.Item
                            key='menu-2'
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  visible: true,
                                  width: 'fit-content',
                                  centered: true,
                                  component: (
                                    <WS2537001_PersonalReserveProcess
                                      Li_ReserveNum={record.reservation_number}
                                      Li_Child={true}
                                      onSaveMemos={() => {
                                        this.getExamineeList()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            変更
                          </Menu.Item>

                          <Menu.Item
                            key='menu-4'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 'fit-content',
                                  component: (
                                    <WS3020036_CoupledPrintInstruction
                                      Li_ReserveNum={record.reservation_number}
                                      Li_CourseLevel={record.course_level}
                                      onFinishScreen={(obj) => {
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }}
                          >
                            印刷
                          </Menu.Item>

                          <Menu.Item
                            key='menu-5'
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                childModal: {
                                  width: 1000,
                                  visible: true,
                                  component: (
                                    <WS1296010_ConsultTicketInputSub
                                      Li_ReserveNum={record.reservation_number}
                                      Li_CourseLevel={record.course_level}
                                      onFinishScreen={() => {
                                        this.closeModal()
                                      }}
                                    />
                                  )
                                },
                              })
                            }}
                          >
                            受診券
                          </Menu.Item>

                          <Menu.Item
                            key='menu-6'
                            onClick={() => {
                              this.excelReport()
                            }}>
                            EXCEL
                          </Menu.Item>
                          <Menu.Item
                            key='menu-7'
                            onClick={() => this.changeProgressData((record.protectImage === 'アンロック.png' ? 1 : 0), record)}>
                            保護
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  )
                }}
              />
            </Table>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}

          onCancel={() => {
            if (this.state.ResultDisplayUpdateBtn && this.state.MemoFunctionUpdateBtn) {
              console.log('画面を閉じる')
              this.closeModal()
            } else {
              console.log('保存しますかを聞く')
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(InterviewSupport)
