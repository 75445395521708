import XmlMedicalExamItemMasterService from "services/SpecificInsureMaintenance/DataRangeCheck/XmlMedicalExamItemMasterService";

import { message } from "antd";

const XmlMedicalExamItemMasterAction = {
  xmlMedicalExamItemDataInput(data) {
    return XmlMedicalExamItemMasterService.xmlMedicalExamItemDataInput(data)
      .then((res) => {
        return res

      })
      .catch((err) => {
        // message.error(err.response.data.message);
        throw err
      });
  },

  saveXmlMedicalExamItemDataInput(data) {
    return XmlMedicalExamItemMasterService.saveXmlMedicalExamItemDataInput(data)
      .then((res) => {
        return res
      })
      .catch((err) => {
        throw err
      })
  }
}
export default XmlMedicalExamItemMasterAction;
