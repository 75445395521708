import SupportItemServiceSub from "services/SpecificInsureMaintenance/SupportItem/SupportItemServiceSub";

export const getDataSupportItemAction = (params) => {
  return SupportItemServiceSub.getDataSupportItemService(params)
}

export const getSelectDataSupportItemAction = (params) => {
  return SupportItemServiceSub.getSelectDataSupportItemService(params)
}

export const saveSupportItemAction = (params) => {
  return SupportItemServiceSub.saveSupportItemService(params)
}

export const deleteSupportItemAction = (params) => {
  return SupportItemServiceSub.deleteSupportItemService(params)
}
