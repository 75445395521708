import React from "react";
import { connect } from "react-redux";
import PrintProcessAction from "redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/PrintProcess.action";
import WS0435012_PreviewConfirm from "pages/JZ_AdvancePreparation/V4JZ0101000_ConsultInfoList/WS0435012_PreviewConfirm.jsx";
import { Card, Table, Button, Space, message, } from "antd";
import ModalDraggable from "components/Commons/ModalDraggable";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import ResultConfirmAction from 'redux/SpecificMedicalExamination/SpecifiHealthDataXMLOutput/ResultConfirm.action'
import { download_file } from 'helpers/CommonHelpers'

class WS1362003_PrintProcess extends React.Component {
  constructor(props) {
    super(props);

    // document.title = '特定健診チェック一覧';

    this.state = {
      printProcessing: [],
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  getScreenData() {
    let params = {
      dataList: this.props.dataList
    }
    PrintProcessAction.getScreenData(params)
      .then((res) => {
        this.setState({
          printProcessing: this.props?.selectedIDCD ? res.filter(item => this.props?.selectedIDCD.includes(item.W1_id_cd)) : res,
        })
      })
  }

  makeCSV() {
    let params = {
      datalist: this.state.printProcessing
    };
    ResultConfirmAction.makeCSV(params)
      .then((res) => {
        console.log(res)
        download_file(res)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }


  renderMenuBar = () => (
    <Space>
      <Button onClick={() => {
        VenusApiRoutesV2.callApi("API001362003004")
          .then(res => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: true,
                width: 500,
                component: (
                  <WS0435012_PreviewConfirm
                    Lio_Preview={res.variables?.Lio_Preview}
                    Lo_StsOutput={res.variables?.Lo_StsOutput}
                    onFinishScreen={(output) => {
                      this.closeModal()
                    }}
                  />
                ),
              },
            });
          })
          .catch(error => {
            message.error('エラーが発生しました');
          })
      }}
        type='primary' style={{ marginBottom: '10px' }}>印刷</Button>
    </Space>
  )

  render() {
    return (
      <div className="print-process">
        <Card title="特定健診チェック一覧">
          <Table
            dataSource={this.state.printProcessing}
            loading={false}
            size='small'
            bordered
            scroll={{ x: 'max-content', y: resizableTableScroll(70) }}
            pagination={false}
            rowKey={(record) => record.index}
          >
            <Table.Column
              className="column-size-40"
              title="カナ氏名"
              onCell={(record, index) => ({
                colSpan: (record.key == '2' || record.key == '3') ? 5 : 1
              })}
              render={(text, record) => {
                return (
                  record.key == '2' ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ marginRight: 20, marginLeft: 20 }}>{record.W1_insurers}</div> <div style={{ marginRight: 20, marginLeft: 20 }}>{record.W2_insurer_num_char}</div>
                    </div>
                    :
                    record.key == '3' ?
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginRight: 20, marginLeft: 20 }}>{record.insurer_kanji_name}</div><div style={{ marginRight: 20, marginLeft: 20 }}>{record.people}</div>
                      </div>
                      :
                      <div>{record.W1_kana_name}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="受診日"
              align="center"
              dataIndex="W1_consult_date"
              onCell={(record, index) => ({
                colSpan: (record.key == '2' || record.key == '3') ? 0 : 1
              })}
            />
            <Table.Column
              className="column-size-60"
              title="ＩＤコード"
              dataIndex="W1_id_cd"
              onCell={(record, index) => ({
                colSpan: (record.key == '2' || record.key == '3') ? 0 : 1
              })}
            />
            <Table.Column
              className="column-size-10"
              title="コース"
              dataIndex="W1_visits_courses"
              onCell={(record, index) => ({
                colSpan: (record.key == '2' || record.key == '3') ? 0 : 1
              })}
            />
            <Table.Column
              className="column-size-10"
              title="受診券"
              onCell={(record, index) => ({
                colSpan: (record.key == '2' || record.key == '3') ? 0 : 1
              })}
            />
            <Table.Column
              className="column-size-10"
              title="基本"
              dataIndex="W4_basic_commissioned_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="貧血"
              dataIndex="W4_anemia_chkup_unit_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="心電図"
              dataIndex="W4_ecg_chkup_unit_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="眼底"
              dataIndex="W4_unit_price_exam_fundus_ken"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="クレアチニン"
              dataIndex="W4_creatinine_chkup_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="追加検査"
              dataIndex="W5_unit_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="基本窓口"
              dataIndex="W4_basic_part_burden_content"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="詳細窓口"
              dataIndex="W4_detail_burden_content"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="追加窓口"
              dataIndex="W4_add_item_burden_content"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="単価合計"
              dataIndex="W4_unit_price_total_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="窓口合計"
              dataIndex="W4_window_burden_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="同時実施"
              dataIndex="W4_simultaneous_burden_price"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
            <Table.Column
              className="column-size-10"
              title="請求金額"
              dataIndex="W4_amount_billed_to_insurer"
              onCell={(record, index) => ({
                rowSpan: record.key == '2' ? 0 : record.key == '3' ? 1 : 2
              })}
              render={(value, record, index) => {
                return (
                  <div style={{ textAlign: 'right' }}>{value == 0 ? 0 : value?.toLocaleString()}</div>
                )
              }}
            />
          </Table>

          <div className="box_button_bottom_right">
            <Button
              type='primary'
              style={{ marginBottom: '5px' }}
              onClick={() => {
                this.makeCSV()
              }}
            >
              CSV
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1362003_PrintProcess);
