import { message } from "antd";
import CopyingProcessService from "services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/CopyingProcessService";


export const indexAction = () => {
  return CopyingProcessService.index()
}

export const saveAction = (params) => {
  return CopyingProcessService.save(params)
}