import axios from "configs/axios";

const API_LIST = {
  getScreenData: "/api/associate-insure-param-maintain/detail-info-correct",
  getMainData: "/api/associate-insure-param-maintain/detail-info-correct/get-main-data",
}


const DetailInfoCorrect = {
  async getScreenData(params) {
    console.log('services_params->', params)
    return axios.get(API_LIST.getScreenData, { params });
  }
  ,
  async getMainData(params) {

    return axios.get(API_LIST.getMainData, { params });
  }
};
export default DetailInfoCorrect;