import React from "react";
import { connect } from "react-redux";
import { Card, Table, Form, Button, Input } from "antd";
import { MoreOutlined, PlusOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'

import UserParamMaintainAction from 'redux/SystemMaintenance/UserParamMaintain/UserParamMaintain.actions'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import setScrollToTop from 'components/Commons/setScrollToTop'

// 1ページで表示する件数
const pageSize = 30
class WS0450004_UserParamInput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'ユーザーパラメータ入力';

    this.state = {
      dataSource: [],
      propsData: this.props.record,
      addCount: 0,
      disabledSaveBtn: true,

      pagination: {
        pageSize: pageSize,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false,
        onChange: (current) => {
          this.setState({
            pagination: {
              ...this.state.pagination,
              current: current  //ページ番号変更
            },
            selectedRow: {} // 選択状態ナシ
          })
        }
      },
    };
  }

  componentDidMount = async () => {

    this.getScreenData()
  }

  /**
   * state更新時処理
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    // 保存ボタンの活性フラグが変更された時の処理
    if (this.state.disabledSaveBtn !== prevState.disabledSaveBtn) {
      if (this.props.onEdit) {
        //  保存ボタンが活性の場合、true
        let bool = this.state.disabledSaveBtn ? false : true
        // 親画面のonEditイベント実行
        this.props.onEdit(bool)
      }
    }
  }

  getScreenData() {

    let prams = {
      ...this.state.propsData,
      Li_format: this.state.propsData.format,
    }

    UserParamMaintainAction.UserParamInputIndex(prams)
      .then((res) => {
        this.setState({
          dataSource: res,
          addCount: 0,
        })
        this.formRef.current?.setFieldsValue({
          tableData: res
        })
      })
  }

  /**
 * それぞれの値の変更
 * @param {*} id
 * @param {*} field
 * @param {*} value
 */
  updateDatasource(id, field, value) {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    let tempArr = [], obj
    // data[index][field] = value

    // 該当の行・カラムを変更
    data.forEach((item, i) => {
      obj = { ...item }
      if (i === index) {
        obj[field] = value
        // 変更フラグ
        obj.changeFlag = true
      }
      tempArr.push(obj)
    })

    this.setState({
      dataSource: tempArr,
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      tableData: tempArr
    })
  }

  /**
  * idから行番号を取得
  * @param {*} arrayData
  * @param {*} recordID
  * @returns
  */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * 新規追加、行追加
   */
  add = async () => {
    let idCount = this.state.addCount + 1
    let newRow = { id: 'new_' + String(idCount) }

    let data = [...this.state.dataSource]

    // 選択されている行を取得
    let selectIndex = (this.state.selectedRow?.id) ? this.state.selectedRow?.id : 0
    // 新規追加する行番号を取得
    let newIndex = this.findIndexByID(data, selectIndex) + 1

    // 選択されている行の１つ下の行に差し込み
    data.splice(newIndex, 0, newRow)

    await this.setState({
      dataSource: data,
      selectedRow: { ...newRow },
      addCount: idCount,
    })
    this.formRef.current?.setFieldsValue({
      tableData: data
    })

    // 1ページの行数
    let defaultPageSize = pageSize
    if (defaultPageSize <= newIndex) {
      // 次ページをを取得、商だけ計算
      let nextPage = Math.floor(newIndex / defaultPageSize) + 1
      // ページの末尾かどうか、余りを計算
      let remainder = newIndex % defaultPageSize

      // 余りが0の場合はページの末尾が選択されている
      if (remainder === 0) {
        // 新規追加した行が次ページに追加されているのでcurrentでページを移動
        await this.setState({
          pagination: {
            ...this.state.pagination,
            current: nextPage
          }
        })
        // <Tabale>のスクロールをtopに移動
        setScrollToTop('table-scroll-top')
      }
    }
    this.forceUpdate()
  }

  /**
  *  削除
  * ※フロントのデータが消えるだけで「保存」するまではデータベースからは消えない
  */
  delete = (id) => {
    let data = [...this.state.dataSource]
    const index = this.findIndexByID(this.state.dataSource, id)

    // 該当の行を削除
    data.splice(index, 1)

    this.setState({
      dataSource: data,
      disabledSaveBtn: false
    })
    this.formRef.current?.setFieldsValue({
      tableData: data
    })
  }

  /**
  * 保存ボタンが押された場合
  */
  save() {
    let params = {
      data: this.state.dataSource,
      Li_format: this.state.propsData.format,
    }

    console.log(params);

    UserParamMaintainAction.UserParamInputSave(params)
      .then(res => {
        if (res) {
          this.getScreenData()
          this.props.onFinishScreen()
        }
      })
  }

  render() {
    return (
      <div className="user-param-input" style={{ width: '100%' }}>
        <Card title={'ユーザーパラメータ入力[' + ((this.props.newFlag) ? '新規追加' : '編集') + ']'} className="mb-2">
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <Form.Item
              label='FORMAT'
            >
              <div className="box_inner_horizontal">
                <div>{this.state.propsData.format ?? ''}</div>
                <div>{this.state.propsData.remarks ?? ''}</div>
              </div>
            </Form.Item>

            <Table
              bordered
              size='small'
              dataSource={this.state.dataSource}
              pagination={this.state.dataSource.length > pageSize ? this.state.pagination : false}
              rowKey={(record) => record.id}
              rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light table-scroll-top' : ''}
              scroll={{ x: 'max-content', y: resizableTableScroll(220) }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: async () => {
                    await this.setState({
                      selectedRow: record
                    })
                  }
                }
              }}
            >
              <Table.Column
                title='SEQ'
                dataIndex='seq'
                className='column-size-10'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'seq']}
                    >
                      <Input
                        style={{ textAlign: 'right' }}
                        maxLength={8}
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'seq', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'seq', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='備考'
                dataIndex='remarks'
                className='column-size-30'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'remarks']}
                    >
                      <Input
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'remarks', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'remarks', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='種別'
                dataIndex='kind'
                className='column-size-20'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'kind']}
                    >
                      <Input
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'kind', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'kind', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='属'
                dataIndex='attribute'
                className='column-size-5'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'attribute']}
                    >
                      <Input
                        maxLength={1}
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'attribute', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'attribute', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='桁数'
                dataIndex='number_of_digits'
                className='column-size-8'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'number_of_digits']}
                    >
                      <Input
                        style={{ textAlign: 'right' }}
                        maxLength={5}
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'number_of_digits', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'number_of_digits', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='位置'
                dataIndex='position'
                className='column-size-8'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'position']}
                    >
                      <Input
                        style={{ textAlign: 'right' }}
                        maxLength={4}
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'position', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'position', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='設定'
                dataIndex='set_pattern'
                className='column-size-8'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'set_pattern']}
                    >
                      <Input
                        maxLength={5}
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'set_pattern', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'set_pattern', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='C'
                dataIndex='error_checking'
                className='column-size-5'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'error_checking']}
                    >
                      <Input
                        maxLength={1}
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'error_checking', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'error_checking', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                title='オプション'
                dataIndex='option_remark'
                render={(value, record, index) => {
                  return (
                    <Form.Item
                      name={['tableData', this.findIndexByID(this.state.dataSource, record.id), 'option_remark']}
                    >
                      <Input
                        onBlur={(e) => {
                          this.updateDatasource(record.id, 'option_remark', e.target.value)
                        }}
                        onPressEnter={(e) => {
                          this.updateDatasource(record.id, 'option_remark', e.target.value)
                        }}
                      />
                    </Form.Item>
                  )
                }}
              />

              <Table.Column
                width={40}
                fixed='right'
                title={
                  <div style={{ textAlign: 'center' }}>
                    <Button size='small'
                      onClick={() => this.add()}
                      type='primary'
                      icon={<PlusOutlined />}
                    />
                  </div>
                }

                render={(text, record, index) => {
                  return (
                    <Button
                      danger
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        this.delete(record.id)
                      }}
                    />
                  )
                }}
              />
            </Table>

          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<SaveOutlined />}
              disabled={this.state.disabledSaveBtn}
              onClick={() => this.save()}
            >
              <span className='btn_label'>
                保存
              </span>
            </Button>
          </div>
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0450004_UserParamInput);
