/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
///* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import Color from 'constants/Color'
import man from 'assets/img/性別-男性.png'
import woman from 'assets/img/性別-女性.png'
import GetImage from 'constants/Images'
import moment from 'moment-timezone'
import NumberFormat from 'components/Commons/NumberFormat'
import MemoFunction from 'pages/ZZ_Others/SANAI_Memo/MemoFunction.jsx'
import * as wanakana from 'wanakana'
import { Tooltip, Card, Form, Input, Button, Select, Modal, Dropdown, Menu, message, Table, Tabs } from 'antd'
import { MoreOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, MenuOutlined, SaveOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import { VisitsInspectConsultInquirySubAction } from 'redux/InputBusiness/ProgressSetting/ConsultInquirySub.actions'
import { AcceptButtonAction, } from 'redux/CounterBusiness/Counter/Counter.action'
import WS2542001_PreviousCourseAcquisitionSub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2542001_PreviousCourseAcquisitionSub.jsx'
import WS2543001_ApplicationInfoRegister from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2543001_ApplicationInfoRegister.jsx'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS0381001_PersonalOfficeSearchQuery from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0381001_PersonalOfficeSearchQuery.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0289012_ContractInfoInquiry from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS0289012_ContractInfoInquiry.jsx'
import WS0306001_ContractInfoMaintain from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0306001_ContractInfoMaintain'
import WS2708001_SetInfoMaintain from 'pages/BS_BasicInfo/V4KB0301000_SetInfoMaintain/WS2708001_SetInfoMaintain'
import WS2537084_InfoHeld from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537084_InfoHeld.jsx'
import WS0650001_DocumentBatchCreateSub from 'pages/JZ_AdvancePreparation/V4JZ0102003_DocumentBatchCreate/WS0650001_DocumentBatchCreateSub'
import WS2555001_MedicalExamContentsInquirySub from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2555001_MedicalExamContentsInquirySub.jsx'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub'
import PersonalReserveProcessAction from 'redux/ReservationBusiness/PersonalReserveProcess/PersonalReserveProcess.action'
import WS1296010_ConsultTicketInputSub from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1296010_ConsultTicketInputSub.jsx'
import WS2737001_MiraisSingleTransmission from 'pages/KS_CooperationRelated/FJYM01100_MiraisSingleTransmission/WS2737001_MiraisSingleTransmission'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay'
import WS2537500_PersonalReserveOption from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537500_PersonalReserveOption.jsx'
import WS2577003_CalendarEmptySub from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2577003_CalendarEmptySub.jsx'
import WS2553003_PeriodTimeInquiry from 'pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS2553003_PeriodTimeInquiry.jsx'
import PersonalOfficeSearchQueryAction from 'redux/ReservationBusiness/PersonalReserveProcess/PersonalOfficeSearchQuery.actions'
import ContractInfoInquiryAction from 'redux/ReservationBusiness/GroupBookings/ContractInfoInquiry.actions'
import WS0061015_CheckYesNoNo from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS0061015_CheckYesNoNo'
import ReserveAvailability from 'pages/ZZ_Others/SANAI_ReserveStatusSearch/ReserveAvailability'
import WS0343001_PersonalInfoMaintain from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0343001_PersonalInfoMaintain'
import WS0309503_BillingAddressAdjust from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309503_BillingAddressAdjust.jsx'
import WS0344001_SupplementalInfoSetting from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0344001_SupplementalInfoSetting.jsx'
import CorporateHearingSheet from 'pages/ZZ_Others/SANAI_CorporateHearingSheet/CorporateHearingSheet.jsx'
import WS2622003_ReceiptProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622003_ReceiptProcessSub.jsx'
import WS2622004_ReceiptProcessSubBulk from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2622004_ReceiptProcessSubBulk.jsx'
import WS2621001_PaymentProcessSub from 'pages/UK_CounterBusiness/V5UK0001000_Counter/WS2621001_PaymentProcessSub.jsx'
import ReceiptProcessSubAction from 'redux/CounterBusiness/Counter/ReceiptProcessSub.action'
import PaymentProcessSubAction from 'redux/CounterBusiness/Counter/PaymentProcessSub.action';
import PersonalInfoMaintainAction from 'redux/basicInfo/PersonalInfoMaintain/PersonalInfoMaintain.action'
import AnotherInspectItemSettingCategory from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0309017_ContractItemSub/AnotherInspectItemSettingCategory'
import AllMemosFunction from 'pages/ZZ_Others/SANAI_Memo/AllMemosFunction.jsx'

const minWidth = '500px'

const { TabPane } = Tabs

const labelStyle = {
  labelCol: {
    style: {
      width: 80,
      textAlignLast: 'justify',
      textJustify: 'inter-ideograph'
    }
  },
  wrapperCol: {
    style: {
      marginLeft: '10px',
    }
  }
}

const fontStyle = {
  fontWeight: 'bold'
}

const marginBottomSize = 5

const { TextArea } = Input

class WS2537001_PersonalReserveProcess extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,         // ReserveNum code - dt0420_inspection_value_results
    Li_PersonalNum: PropTypes.any,        // dt0420_inspection_value_results
    Li_Date: PropTypes.any,
    Li_Getctxname: PropTypes.any,
    Li_ProcessDivision: PropTypes.any,
    Li_Option: PropTypes.any,
    Li_Child: PropTypes.any, // required - value: true

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)
    // document.title = '個人予約処理'
    this.state = {
      valuePersonalNumber: '',
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSourceSelect: [],
      selectedRowSelect: [],
      selectedRowKeySelect: [],

      dataSourceInspectChange: [],
      selectedRowInspectChange: [],
      selectedRowKeyInspectChange: [],

      selectedOptions: [],

      FacilityNumHospitaList: [],

      personalData: {},

      ReserveNum: null,
      ReserveState: null,
      ReserveStateColorNum: 211,
      Continuous: false,
      Li_ProcessDivision: 0,
      StateFlag: 0,
      Age: '',
      totalAmount: 0,
      sex: null,
      importancePersonal: null,
      contentPersonal: '',
      importanceOffice: null,
      contentOffice: null,
      PersonalNumId: '',
      OfficeCode: '',
      ConsultCourse: null,
      FacilityNumHospital_Out: 0,
      Am_Pm: '',

      // Table
      Expression_140: 10,
      AddedOrUnnecessary: 0,
      ChangeClassify: false,

      dataScreen: {},
      dataOffice: [],
      dataTax: [],

      columnList: [],
      dataList: [],
      discountList: [],
      discountNameList: [],
      discount: 0,
      taxRate: '',
      rounding: '',
      courseChangeFlag: false,

      Lo_Update: false,

      dataOfficeByPersonal: [],

      isChangeData: false,
      isChangeForm: false,

      changeConsultCourse: false,
      isUnValidConsultCourse: true,
      isUnValidOffice: false,
      nameScreen: '',

      dataPersonal_2543: {},

      functionName: '',

      examIdExamCodeList: [],
      selectedExamIdTimeList: [], //時間帯にて選択された検査ごとの予約時間
      selectedExamIdTimesStr: '', //検査ごとの予約時間（省略版）
      selectedExamIdTimesTooltip: '', //検査ごとの予約時間（ツールチップ用）
      userList: [],
      facilityList: [],//施設情報
      dataSourceSupplements: [], //補足
      patternCode: '',

      reserveSituationList: [
        { value: 0, label: '予約' },
        { value: 2, label: '保留' },
        { value: 3, label: 'キャンセル' },
      ],

      stsMiraisSendError: false,
      counter_total_amount: 0,

      // 全体メモ保存ボタンの活性化情報
      AllMemoFunctionUpdateBtn: true,

      //請求済みの請求先リスト
      unchangeableSerialNumberList: [],

      // 特定の検査を追加もしくは削除した際に消すor追加の検査コード
      addSet: [],
      deleteSet: [],

      initialValues: {}
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current.resetFields()
    // 親画面から引き継いだ個人情報を設定
    if (parseInt(this.props.Li_ReserveNum) > 0) {
      //予約番号を持っている場合
      this.formRef.current?.setFieldsValue({
        ReserveNum: this.props.Li_ReserveNum ? this.props.Li_ReserveNum : '',
        counter_total_amount: 0,
        PersonalNumId: this.props.Li_PersonalNum ? this.props.Li_PersonalNum : null,
        Continuous: ((this.props.Li_ReserveNum && parseInt(this.props.Li_ReserveNum) > 0)
          || this.props.Li_PersonalNum
          || this.props.Li_Date
          || this.props.Li_ProcessDivision === 1
          || this.props.Li_Getctxname) ? false : true
      })
    } else {
      //予約番号を持っていない場合
      this.formRef.current?.setFieldsValue({
        ReserveNum: this.props.Li_ReserveNum ? this.props.Li_ReserveNum : '',
        counter_total_amount: 0,
        PersonalNumId: this.props.Li_PersonalNum ? this.props.Li_PersonalNum : null,
        // DateChar: this.props.Li_Date ? this.props.Li_Date : moment(new Date())?.format('YYYY/MM/DD'),
        DateChar: this.props.Li_Date ? moment(this.props.Li_Date)?.format('YYYY/MM/DD (ddd)') : moment(new Date())?.format('YYYY/MM/DD (ddd)'),
        Continuous: ((this.props.Li_ReserveNum && parseInt(this.props.Li_ReserveNum) > 0)
          || this.props.Li_PersonalNum
          || this.props.Li_Date
          || this.props.Li_ProcessDivision === 1
          || this.props.Li_Getctxname) ? false : true
      })
    }

    this.setState({
      dataScreen: {},
      Li_ProcessDivision: this.props.Li_ProcessDivision || 0,
      // DateChar: this.props.Li_Date ? this.props.Li_Date : moment(new Date())?.format('YYYY/MM/DD'),
      DateChar: this.props.Li_Date ? moment(this.props.Li_Date)?.format('YYYY/MM/DD (ddd)') : moment(new Date())?.format('YYYY/MM/DD (ddd)'),
      Continuous: ((this.props.Li_ReserveNum && parseInt(this.props.Li_ReserveNum) > 0)
        || this.props.Li_PersonalNum
        || this.props.Li_Date
        || this.props.Li_ProcessDivision === 1
        || this.props.Li_Getctxname) ? false : true,
    })

    // 個人の予約データを取得
    this.index()
  }

  /**
   * state更新時処理
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedExamIdTimeList !== prevState.selectedExamIdTimeList) {
      // this.state.selectedExamIdTimeList(時間帯にて選択された検査ごとの予約時間) が更新された時
      let str = '', tooltip = ''
      const data = [...this.state.selectedExamIdTimeList]
      data.forEach(item => {
        if (item) {
          if (item.app_time === null) {
            item.app_time = 'error'
          }
          str += item.exam_name + '：' + item.app_time + '　'
          tooltip += item.exam_name + '：' + item.app_time + '\n'
        }
      })
      // 時間帯の右横に表示する検査項目ごとの予約時間
      this.setState({
        selectedExamIdTimesStr: str,
        selectedExamIdTimesTooltip: tooltip,
      })
    }
  }

  /**
   * 個人の予約データを取得
   */
  index() {
    let params = {
      Li_ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      Li_PersonalNum: this.formRef.current?.getFieldValue('PersonalNumId'),
      Li_OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      Li_BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') || 0,
      Li_ContractType: this.state.dataScreen.ContractType,
      Li_ContractOrgs: this.state.dataScreen.ContractClassifyCode,
      Li_ContractStartDate: this.state.dataScreen.ContractDate,
      Li_ContractNum: this.state.dataScreen.ContractNum,
      // Li_Date: this.formRef.current?.getFieldValue('DateChar'),
      Li_Date: moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD')
    }
    PersonalReserveProcessAction.index(params)
      .then(async (res) => {
        if (res) {
          const data = { ...res }
          await this.setData(data, true)
          this.setState({
            dataPersonal_2543: {},
            userList: data.UserList,
            facilityList: data.facilityList,
            stsMiraisSendError: data.stsMiraisSendError
          })

          this.formRef.current?.setFieldsValue({
            memo: data.memo,
            payment: data.payment
          })

          if (this.props.Li_PersonalNum && !this.props.Li_ReserveNum) {
            // 個人情報を取得
            this.getInfoPersonal()
          } else {
            // 個人番号に紐づく事業所一覧を取得
            this.getListOfficeByPersonal()
          }

          //補足設定
          this.setSupplements(res?.supplements ?? [])
        }
      })
  }

  /**
   * 変更 処理
   */
  changeReserveNum() {
    // 備考で改行が入っている場合は、改行コードを削除
    let remarks = this.formRef.current?.getFieldValue('Remarks')
    remarks = remarks.replace(/\r?\n/g, '')

    let params = {
      Li_ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      Li_PersonalNum: this.formRef.current?.getFieldValue('PersonalNumId'),
      Li_OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      Li_BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') || 0,
      Li_ContractType: this.state.dataScreen.ContractType,
      Li_ContractOrgs: this.state.dataScreen.ContractClassifyCode,
      Li_ContractStartDate: this.state.dataScreen.ContractDate,
      Li_ContractNum: this.state.dataScreen.ContractNum,
      Li_Date: moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD'),
      ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      Date: moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD'),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode'),
      FacilityNumHospital_Out: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      NClassify: this.formRef.current?.getFieldValue('NClassify'),
      MedicalExamLocation: this.formRef.current?.getFieldValue('MedicalExamLocation'),
      Remarks: remarks,
      Continuous: this.formRef.current?.getFieldValue('Continuous') ? 1 : 0,
      ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify') ? 1 : 0,
      AddedOrUnnecessary: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),

      StateFlag: this.state.dataScreen.StateFlag,
      AcceptNum: this.state.dataScreen.AcceptNum,
      Am_PmDivision: this.state.Am_Pm ? this.state.Am_Pm : this.state.dataScreen.Am_PmDivision,
      OtherCondition: this.state.dataScreen.OtherCondition,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      KyokaiBillingDate: this.state.dataScreen.KyokaiBillingDate,
      KyokaiAcceptNum: this.state.dataScreen.KyokaiAcceptNum,
      InfoHoldingOp: this.state.dataScreen.InfoHoldingOp,
      InsurerModify: this.state.dataScreen.InsurerModify,
      OfficeModify: this.state.dataScreen.OfficeModify,
      OtherGroupModify: this.state.dataScreen.OtherGroupModify,
      Personal1Modify: this.state.dataScreen.Personal1Modify,
      Person2Modify: this.state.dataScreen.Person2Modify,
      Personal3Modify: this.state.dataScreen.Personal3Modify,
      ContractInfo: this.state.dataScreen.ContractInfo,
      JInspectGCategory: this.state.dataScreen.JInspectGCategory,
      NonDisplaySetContract: this.state.dataScreen.NonDisplaySetContract,
      NonDisplaySetAdd: this.state.dataScreen.NonDisplaySetAdd,
      ApplicationAttributeReDisplay: this.state.dataScreen.ApplicationAttributeReDisplay,
      StsOption: this.state.dataScreen.StsOption ? 1 : 0,
      AddedOrUnnecessaryList: this.state.dataScreen.AddedOrUnnecessaryList,
      Mode: this.state.dataScreen.Mode,
      DisplayContent: this.state.dataScreen.DisplayContent,
      StsPersonalInfoBasic: this.state.dataScreen.StsPersonalInfoBasic ? 1 : 0,
      StsContractInquiry: this.state.dataScreen.StsContractInquiry,
      StsFreeNum: this.state.dataScreen.StsFreeNum,
      FreeNumErrorTarget: this.state.dataScreen.FreeNumErrorTarget,
      ProcessNum: this.state.dataScreen.ProcessNum,
      StsReserveStatus: this.state.dataScreen.StsReserveStatus,
      OneOClockTotalAmount: this.state.dataScreen.OneOClockTotalAmount,
      StsInspectChange: this.state.dataScreen.StsInspectChange ? 1 : 0,
      ContractQueryErrorMessage: this.state.dataScreen.ContractQueryErrorMessage,
      Age: this.state.Age,
      LockState: this.state.dataScreen.LockState,
      LimitErrorMessage: this.state.dataScreen.LimitErrorMessage,
      Li_ProcessDivision: this.state.Li_ProcessDivision,
      Li_Option: this.props.Li_Option
    }

    PersonalReserveProcessAction.changeReserveNum(params)
      .then(async res => {
        await this.setData(res?.data, false)
        this.setState({
          dataPersonal_2543: {}
        })
        message.success('登録が完了しました')
        await this.formRef.current?.setFieldsValue({
          ReserveNum: '',
          PersonalNumId: '',
          Expression_75: '',
          Expression_76: '',
          Expression_78: '',
          Expression_79: '',
          Expression_80: '',
          Age: '',
          OfficeCode: '',
          BranchStoreCode: '',
          office_kanji_name: '',
          ConsultCourse: '',
          Expression_118: '',
        })
        let data = {
          ...this.state.dataScreen,
          ContractNum: 0,
          ContractType: 0,
          ContractDate: 0,
          ContractClassifyCode: 0,
          ConsultCourse: '',
          ComboBox_AddedOrUnnecessary: [
            { LinkedField: 0, DisplayField: '追加指定' },
            { LinkedField: 1, DisplayField: '不要指定' }
          ]
        }
        await this.setState({
          dataScreen: data,
          ConsultCourse: '',
          dataSourceInspectChange: [],
          dataSourceSelect: [],
          AddedOrUnnecessary: 0,
          Expression_140: 20,
          ReserveState: '新規',
          ReserveStateColorNum: 211,
        })
      })
      .catch((err) => {

        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  setData(data, getTable) {
    this.setState({
      Age: data ? data.Age : '',
      ReserveState: data ? data.ReserveState : '新規',
      ReserveStateColorNum: data ? data.ReserveStateColorNum : 211,
      isChangeData: false,
      isChangeForm: false
    })

    data.TimeZone = data.TimeZone ? data.TimeZone.substr(0, 5) : ''
    data.Date = data.Date ? moment(data.Date)?.format('YYYY/MM/DD (ddd)') : moment(new Date())?.format('YYYY/MM/DD (ddd)')
    data.DateChar = data.Date ? moment(data.Date)?.format('YYYY/MM/DD (ddd)') : moment(new Date())?.format('YYYY/MM/DD (ddd)')
    data.Expression_80 = data.Expression_80 ? moment(data.Expression_80)?.format('NNyy/MM/DD') : null
    data.Age = data.Age === 0 ? '' : (data.Age + '歳')
    data.BranchStoreCode = data.BranchStoreCode === 0 ? '' : data.BranchStoreCode
    data.KyokaiAcceptNum = data.KyokaiAcceptNum === 0 ? '' : data.KyokaiAcceptNum
    data.NClassify = data.NClassify === 0 ? '' : data.NClassify
    data.Expression_170 = data.Expression_170 === 0 ? '' : data.Expression_170?.toLocaleString()
    data.Expression_171 = data.Expression_171 === 0 ? '' : data.Expression_171?.toLocaleString()
    data.Expression_172 = data.Expression_172 === 0 ? '' : data.Expression_172?.toLocaleString()
    data.Expression_173 = data.Expression_173 === 0 ? '' : data.Expression_173?.toLocaleString()
    data.Expression_174 = data.Expression_174 === 0 ? '' : data.Expression_174?.toLocaleString()
    data.user = data.user ? data.user : this.props.user.name
    data.examIdDetailList = data.examIdDetailList ?? [] //空き状況チェック用の検査コード一覧

    let personalSpecial = ''
    data.personalSpecialList.forEach((value) => {
      personalSpecial = personalSpecial + value + '\n'
    })

    this.formRef.current?.setFieldsValue({
      AcceptNum: data.AcceptNum,
      ReserveNum: data.ReserveNum > 0 ? data.ReserveNum : '',
      PersonalNumId: this.props.Li_PersonalNum ?? data.PersonalNumId,
      importance_personal: data.importance_personal,
      Expression_75: data.Expression_75,
      Expression_76: data.Expression_76,
      Expression_78: data.Expression_78,
      Expression_80: data.Expression_80,
      Age: data.Age,
      examDayAge: data.examDayAge,
      yearAge: data.yearAge,
      Expression_79: data.Expression_79,
      OfficeCode: data.OfficeCode,
      BranchStoreCode: data.BranchStoreCode,
      importance_office: data.importance_office,
      office_kanji_name: data.office_kanji_name,
      ConsultCourse: data.ConsultCourse,
      Expression_118: data.Expression_118,
      DateChar: data.DateChar,
      Expression_131: data.Expression_131,
      KyokaiAcceptNum: data.KyokaiAcceptNum,
      TimeZone: data.TimeZone,
      TimeZoneCopy: data.TimeZone,
      NClassify: data.NClassify,
      FacilityNumHospital_Out: data.FacilityNumHospital_Out,
      MedicalExamLocation: data.MedicalExamLocation,
      short_name: data.short_name,
      Remarks: data.Remarks,
      Expression_170: data.Expression_170,
      Expression_171: data.Expression_171,
      Expression_172: data.Expression_172,
      Expression_173: data.Expression_173,
      Expression_174: data.Expression_174,
      AddedOrUnnecessary: data.AddedOrUnnecessary,
      ChangeClassify: data.ChangeClassify,
      SearchChar: '',
      postal_code: data.postal_code,
      address_1: data.address_1,
      address_2: data.address_2,
      insurer_code: data.insurer_code,
      insurer_number: data.insurer_number,
      insurer_kanji_name: data.insurer_kanji_name,
      insurer_kana_name: data.insurer_kana_name,
      insurer_card_symbol: data.insurer_card_symbol,
      insurer_card_number: data.insurer_card_number,
      insurer_start_date_on: data.insurer_start_date_on,
      insurer_end_date_on: data.insurer_end_date_on,
      relationship: data.relationship,
      phone_number: data.phone_number,
      email: data.email,
      mobile_mail: data.mobile_mail,
      office_kana_name: data.office_kana_name,
      office_postal_code: data.office_postal_code,
      office_address_1: data.office_address_1,
      office_address_2: data.office_address_2,
      area_code: data.area_code,
      office_phone_number: data.office_phone_number,
      industrial_classification: data.industrial_classification,
      industrial_doctor_name: data.industrial_doctor_name,
      user: data.user,
      reserveSituation: data.ReserveState,
      personalSpecial: personalSpecial
    })

    this.setState({
      patternCode: data.PatternCode,
      StateFlag: data.StateFlag,
      ReserveNum: data.ReserveNum > 0 ? data.ReserveNum : '',

      FacilityNumHospitaList: data.FacilityNumHospitaList,

      sex: data.Expression_79,
      PersonalNumId: data.PersonalNumId,
      importancePersonal: data.importance_personal,
      contentPersonal: data.content_personal,
      OfficeCode: data.OfficeCode,
      importanceOffice: data.importance_office,
      contentOffice: data.content_office,
      ConsultCourse: data.ConsultCourse,
      FacilityNumHospital_Out: data.FacilityNumHospitaList?.length > 0 ? data.FacilityNumHospitaList[0].LinkedField : '',

      Expression_140: data.Expression_140,
      AddedOrUnnecessary: data.AddedOrUnnecessary,
      ChangeClassify: data.ChangeClassify,

      dataScreen: data,
      dataTax: [],

      isUnValidConsultCourse: data.ConsultCourse ? false : true,
      selectedExamIdTimeList: data.selectedExamIdTimeList ?? [], //時間帯にて選択された検査ごとの予約時間
      addSet: data.addSet,
      deleteSet: data.deleteSet
    })
    if (getTable) {
      this.getDataTableInspectChange()
    }
  }

  getDataTableLeft() {
    if (this.formRef.current?.getFieldValue('ChangeClassify')) {
      this.getDataTableInspectSelect()
    } else {
      if (this.formRef.current?.getFieldValue('AddedOrUnnecessary') === 2) {
        this.getDataTableSelectOptions()
      } else {
        this.getDataTableSetSelect()
      }
    }
  }

  getDataTableSetSelect() {
    let params = {
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      Li_AddedOrDeleted: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      Li_ContractType: this.state.dataScreen.ContractType,
      Li_ContractOrgs: this.state.dataScreen.ContractClassifyCode,
      Li_ContractStartDate: this.state.dataScreen.ContractDate,
      Li_ContractNum: this.state.dataScreen.ContractNum,
      // Li_Date: this.formRef.current?.getFieldValue('DateChar'),
      Li_Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      Li_Gender: this.state.personalData.Expression_79,
      Li_DateBirth: this.state.personalData.Expression_80,
      Li_Relationship: this.state.personalData.relationship,
      Li_HospitalOut: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      Li_NClassify: this.formRef.current?.getFieldValue('NClassify'),
      personalNumberId: this.formRef.current?.getFieldValue('PersonalNumId')
    }

    PersonalReserveProcessAction.getDataSetSelect(params)
      .then((res) => {
        this.setState({
          ///////
          dataSourceSelect: res ? res : [],
          selectedRowSelect: res && res.length > 0 ? [res[0]] : [],
          selectedRowKeySelect: res && res.length > 0 ? [res[0].id] : [],
        })
      })
  }

  getDataTableInspectSelect() {
    let params = {
      Li_Pattern: this.state.dataScreen.PatternCode,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      Li_AddedOrDeleted: this.formRef.current?.getFieldValue('AddedOrUnnecessary') === 2 ? 0 : this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify')
    }

    PersonalReserveProcessAction.getDataInspectSelect(params)
      .then(async (res) => {
        let index = res?.ComboBox_AddedOrUnnecessary.findIndex(x => x.LinkedField === this.state.AddedOrUnnecessary)

        let Expression_140 = index === -1 ? (res?.ComboBox_AddedOrUnnecessary[0].LinkedField === 2 ? 40
          : res?.ComboBox_AddedOrUnnecessary[0].LinkedField === 0 ? 20
            : res?.ComboBox_AddedOrUnnecessary[0].LinkedField === 1 ? 30
              : null) : this.state.Expression_140

        let AddedOrUnnecessary = index === -1 ? res?.ComboBox_AddedOrUnnecessary[0].LinkedField : this.state.AddedOrUnnecessary
        let data = {
          ...this.state.dataScreen,
          ComboBox_AddedOrUnnecessary: res?.ComboBox_AddedOrUnnecessary,
        }

        await this.setState({
          dataScreen: data,
          Expression_140: Expression_140,
          AddedOrUnnecessary: AddedOrUnnecessary,

          dataSourceSelect: res ? res.data : [],
          selectedRowSelect: res && res.data.length > 0 ? [res.data[0]] : [],
          selectedRowKeySelect: res && res.data.length > 0 ? [res.data[0].id] : [],
        })

        this.formRef.current?.setFieldsValue({
          Expression_140: Expression_140,
          AddedOrUnnecessary: AddedOrUnnecessary
        })
      })
  }

  getDataTableSelectOptions() {
    let params = {
      Li_ContractType: this.state.dataScreen.ContractType,
      Li_ContractOrgs: this.state.dataScreen.ContractClassifyCode,
      Li_ContractStartDate: this.state.dataScreen.ContractDate,
      Li_ContractNum: this.state.dataScreen.ContractNum,
      Li_SearchChar: this.formRef.current?.getFieldValue('SearchChar'),
      Li_AddedOrDeleted: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      Li_ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      Li_ContractDate: this.state.dataScreen.ContractDate,
      // Li_Date: this.formRef.current?.getFieldValue('DateChar'),
      Li_Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      Li_Gender: this.state.personalData.Expression_79,
      Li_DateBirth: this.state.personalData.Expression_80,
      Li_Relationship: this.state.personalData.relationship,
      Li_HospitalOut: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      Li_NClassify: this.formRef.current?.getFieldValue('NClassify'),
      personalNumberId: this.formRef.current?.getFieldValue('PersonalNumId')
    }
    PersonalReserveProcessAction.getDataSetSelect(params)
      .then((res) => {
        this.setState({
          dataSourceSelect: res ? res : [],
          selectedRowSelect: res && res.length > 0 ? [res[0]] : [],
          selectedRowKeySelect: res && res.length > 0 ? [res[0].id] : [],
        })
      })
  }

  getDataTableInspectChange() {
    let params = {
      Li_ContractType: this.state.dataScreen.ContractType,
      Li_ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      Li_ContractDate: this.state.dataScreen.ContractDate,
      Li_ContractNum: this.state.dataScreen.ContractNum,
      Li_ConsultCourse: this.state.dataScreen.ConsultCourse,
      Li_Date: this.state.dataScreen.Date,
      reservation_number: this.state.dataScreen.ReserveNum,
      courseChangeFlag: this.state.courseChangeFlag ? 1 : 0
    }
    PersonalReserveProcessAction.getDataInspectChange(params)
      .then((res) => {
        const data = [...res.data] ?? []
        if (data[0]) {
          let array = data.map((value, index, array) => (value.totalPrice))
          let counter_array = data.map((value, index, array) => (value.personalTotalPrice))
          let totalAmount = array.reduce((sum, element) => { return (sum + element) })
          let counter_total_amount = counter_array.reduce((sum, element) => { return (sum + element) })
          totalAmount = NumberFormat.formatter(totalAmount)
          this.setState({
            totalAmount: totalAmount
          })
          this.formRef.current?.setFieldsValue({
            counter_total_amount: counter_total_amount.toLocaleString()
          })
        }

        //選択したオプションを残してコースだけ変更する
        let newData = []
        let dataSourceInspectChange = [...this.state.dataSourceInspectChange]
        if (this.state.dataSourceInspectChange.length > 0) {
          let data2 = data.filter(item => (item.changeType <= 20))
          let newDataSource = dataSourceInspectChange.filter(item => (item.changeType >= 40))
          newData = [...data2, ...newDataSource]
        }

        this.setState({
          dataSourceInspectChange: newData.length > 0 ? newData : data,
          // columnList: [],
          // dataList: [],
          selectedRowInspectChange: data && data.length > 0 ? [data[0]] : [],
          selectedRowKeyInspectChange: data && data.length > 0 ? [data[0].id] : []
        })

        if (this.formRef.current?.getFieldValue('ConsultCourse')) {
          this.getBillingAddressList(null)
        }

        // this.getDataTableLeft()
      })
  }

  setChange(payActive, record, changeType, Personal3Modify) {
    let params = {
      Li_Payer: payActive,
      Li_ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      Li_SetCode: record.set_code ? record.set_code : '',
      Li_ChangeTypeClassify: changeType,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      Date: this.state.dataScreen.Date,
      InsurerModify: this.state.dataScreen.InsurerModify,
      OfficeModify: this.state.dataScreen.OfficeModify,
      OtherGroupModify: this.state.dataScreen.OtherGroupModify,
      Personal1Modify: this.state.dataScreen.Personal1Modify,
      Person2Modify: this.state.dataScreen.Person2Modify,
      Personal3Modify: this.state.dataScreen.Personal3Modify,
    }

    PersonalReserveProcessAction.setChange(params)
      .then((res) => {
        this.getDataTableInspectChange()
        let data = {
          ...this.state.dataScreen,
          StsInspectChange: 1
        }
        this.setState({
          isChangeData: true,
          dataScreen: data
        })
      })
  }

  setDelete(record) {
    let params = {
      Li_ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      W2_change_type: record.W2_change_type,
      W2_set_inspect_cd: record.W2_set_inspect_cd,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      Date: this.state.dataScreen.Date,
      InsurerModify: this.state.dataScreen.InsurerModify,
      OfficeModify: this.state.dataScreen.OfficeModify,
      OtherGroupModify: this.state.dataScreen.OtherGroupModify,
      Personal1Modify: this.state.dataScreen.Personal1Modify,
      Person2Modify: this.state.dataScreen.Person2Modify,
      Personal3Modify: this.state.dataScreen.Personal3Modify,
    }

    PersonalReserveProcessAction.setDelete(params)
      .then((res) => {
        this.getDataTableInspectChange()
        let data = {
          ...this.state.dataScreen,
          StsInspectChange: 1
        }
        this.setState({
          isChangeData: true,
          dataScreen: data
        })
      })
  }

  contractRedisplay() {
    let params = {
      ...this.state.dataScreen,
      Li_PersonalNum: this.formRef.current?.getFieldValue('PersonalNumId'),
      Li_ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      Li_ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      // Li_Date: this.formRef.current?.getFieldValue('DateChar'),
      Li_Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      Li_TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      Li_OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      Li_BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') || 0,
      Li_FacilityNumHospital_Out: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      Li_NClassify: this.formRef.current?.getFieldValue('NClassify'),
      Li_StsOption: this.state.dataScreen.StsOption,
      Li_ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify'),
      ...this.formRef.current?.getFieldValue(),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
    }

    PersonalReserveProcessAction.contractRedisplay(params)
      .then(async (res) => {
        let Expression_140 = res?.data?.ComboBox_AddedOrUnnecessary[0].LinkedField === 2 ? 40
          : res?.data?.ComboBox_AddedOrUnnecessary[0].LinkedField === 0 ? 20
            : res?.data?.ComboBox_AddedOrUnnecessary[0].LinkedField === 1 ? 30
              : null
        let data = {
          ...this.state.dataScreen,
          StsReserveChange: res?.data?.StsReserveChange,
          StsCourseWarning: res?.data?.StsCourseWarning,
          ContractInfo: res?.data?.ContractInfo,
          ComboBox_AddedOrUnnecessary: res?.data?.ComboBox_AddedOrUnnecessary,
          AddedOrUnnecessaryList: res?.data?.ComboBox_AddedOrUnnecessary.map((row) => { return [row['LinkedField']] }).join(','),
          OneOClockTotalAmount: res?.data?.OneOClockTotalAmount,
        }

        await this.setState({
          dataScreen: data,
          Expression_140: Expression_140,
          AddedOrUnnecessary: res?.data?.ComboBox_AddedOrUnnecessary[0].LinkedField,
          isChangeForm: true,
        })
        this.formRef.current?.setFieldsValue({
          Expression_118: res?.data?.Expression_118,
          Expression_140: Expression_140,
          AddedOrUnnecessary: res?.data?.ComboBox_AddedOrUnnecessary[0].LinkedField
        })

        this.getDataTableInspectChange()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 予約登録直前処理
   * @param {*} personalNumberId
   */
  checkLatestReserve = (personalNumberId) => {
    //新規：this.state.StateFlag = 0
    //変更：this.state.StateFlag = 2

    let param = {
      personal_number_id: personalNumberId ?? '',
      reservation_number: this.formRef.current?.getFieldValue('ReserveNum') ?? '',
    }

    PersonalReserveProcessAction.checkLatestReserve(param)
      .then(res => {
        if (res.data.stateFlag) {
          Modal.warning({
            title: '保留・キャンセル',
            width: 400,
            content: <div style={{ whiteSpace: 'pre-wrap' }}> {res.data.messageList + '上記の予約が登録されています。'} </div>,
            zIndex: 9999,
            onOk: () => { }
          })
        }
        this.ConfirmProcessBtn_F12()
      })
  }

  /**
   * 登録・変更前チェック
   */
  ConfirmProcessBtn_F12() {
    if (!this.formRef.current?.getFieldValue('ConsultCourse') || this.state.isUnValidConsultCourse) {
      // コースが設定されていない場合
      // 契約情報照会 モーダル
      this.showWS0289012_ContractInfoInquiry(true)
    } else {
      if (this.state.isUnValidOffice) {
        // 個人番号や事業所コードが無い場合
        // if (this.state.PersonalNumId) {
        //   // 個人事業所検索・照会 モーダル
        //   this.showWS0381001_PersonalOfficeSearchQuery()
        // } else {
        // 事業所情報検索・照会 モーダル
        this.showWS0247001_OfficeInfoRetrievalQuery()
        // }
        // } else if (this.formRef.current?.getFieldValue('TimeZone') == '' || this.formRef.current?.getFieldValue('TimeZone') == '00:00') {
        //   //時間帯が設定されていない場合
        //   this.showReserveAvailability()
      } else {
        // 登録・変更
        this.ConfirmProcessBtn_F12_After()
      }
    }
  }

  /**
   * 登録・変更前の確認
   */
  ConfirmProcessBtn_F12_After() {
    let params = {
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode'),
      FacilityNumHospital_Out: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      MedicalExamLocation: this.formRef.current?.getFieldValue('MedicalExamLocation'),
      ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      // Date: this.formRef.current?.getFieldValue('DateChar'),
      Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      NClassify: this.formRef.current?.getFieldValue('NClassify'),
      ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify') ? 1 : 0,
      AddedOrUnnecessary: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      ApplicationAttributeReDisplay: this.state.dataScreen.ApplicationAttributeReDisplay,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      OneOClockTotalAmount: this.state.dataScreen.OneOClockTotalAmount,
      StsInspectChange: this.state.dataScreen.StsInspectChange ? 1 : 0,
      Am_PmDivision: this.state.Am_Pm ? this.state.Am_Pm : this.state.dataScreen.Am_PmDivision,
      OtherCondition: this.state.dataScreen.OtherCondition,
      StsContractInquiry: this.state.dataScreen.StsContractInquiry,
      ContractQueryErrorMessage: this.state.dataScreen.ContractQueryErrorMessage,
      ContractInfo: this.state.dataScreen.ContractInfo,
      InsurerModify: this.state.dataScreen.InsurerModify,
      OfficeModify: this.state.dataScreen.OfficeModify,
      OtherGroupModify: this.state.dataScreen.OtherGroupModify,
      Personal1Modify: this.state.dataScreen.Personal1Modify,
      Person2Modify: this.state.dataScreen.Person2Modify,
      Personal3Modify: this.state.dataScreen.Personal3Modify,
      NonDisplaySetContract: this.state.dataScreen.NonDisplaySetContract,
      NonDisplaySetAdd: this.state.dataScreen.NonDisplaySetAdd,
      StsOption: this.state.dataScreen.StsOption ? 1 : 0,
      AddedOrUnnecessaryList: this.state.dataScreen.AddedOrUnnecessaryList,
      JInspectGCategory: this.state.dataScreen.JInspectGCategory,
      Age: this.state.Age,
      LockState: this.state.dataScreen.LockState,
      LimitErrorMessage: this.state.dataScreen.LimitErrorMessage,
      Li_ProcessDivision: this.state.Li_ProcessDivision,
      StsReserveChange: this.state.dataScreen.StsReserveChange ? 1 : 0,
      StateFlag: this.state.dataScreen.StateFlag,
      StateRe: this.state.dataScreen.StateRe,
      dataSourceInspectChange: this.state.dataSourceInspectChange,
      ContractOrgs: this.state.dataScreen.ContractClassifyCode,
      ContractStartDate: this.state.dataScreen.ContractDate,
      w2ApplicationAttribute1: this.state.dataScreen.w2ApplicationAttribute1,
      StsPersonalInfoBasic: this.state.dataScreen.StsPersonalInfoBasic ? 1 : 0,
      ReserveCheckFlag: 1, //予約の空き状況を確認する
      selectedExamIdTimeList: this.state.selectedExamIdTimeList //検査ごとの予約時間
    }

    PersonalReserveProcessAction.ConfirmProcessBtn_F12(params)
      .then(async (res) => {
        let getMessage = res?.data?.message
        let data = {
          ...this.state.dataScreen,
          ...res?.data
        }

        await this.setState({
          dataScreen: data,
          functionName: res?.data.function
        })

        if (res.data.Mode === 'T') {
          //検査ごとの時間が選択されていない場合
          this.closeModal()
          message.warning(res?.data?.warning)
          this.showReserveAvailability()
        } else if (res.data.Mode !== 'E') {
          if (Array.isArray(res?.data?.warning)) {
            Modal.warning({
              content: <div>
                {res?.data?.warning.map((value, index) => (
                  <div key={index} >{value}</div>
                ))}
              </div>,
              width: 400,
              onOk: () => {
                if (params.FacilityNumHospital_Out === 0) {
                  Modal.warning({
                    content: "施設情報が無効です",
                    width: 400,
                    onOk: () => {
                    }
                  })
                } else {
                  this.showWS0061015_CheckYesNoNo()
                }
              }
            })
          } else if (params.FacilityNumHospital_Out === 0) {
            Modal.warning({
              content: "施設情報が無効です",
              width: 400,
              onOk: () => {
              }
            })
          } else {
            this.showWS0061015_CheckYesNoNo()
          }
        } else {
          if (Array.isArray(res?.data?.warning)) {
            Modal.warning({
              content: <div>
                {res?.data?.warning.map((value, index) => (
                  <div key={index} >{value}</div>
                ))}
              </div>,
              width: 400,
              onOk: () => {
              }
            })
          } else {
            message.error(res?.data?.warning)
          }
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 登録・変更前の確認 モーダル
   */
  showWS0061015_CheckYesNoNo() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0061015_CheckYesNoNo
            Li_Message={(this.formRef.current?.getFieldValue('ReserveNum') === 0 || !this.formRef.current?.getFieldValue('ReserveNum')) ? 'この内容で予約しますか？' : 'この内容に変更しますか？'}
            onFinishScreen={({ Lio_StsReturn }) => {
              if (Lio_StsReturn) {
                // if (this.formRef.current?.getFieldValue('Expression_75')) {
                //   if (this.formRef.current?.getFieldValue('OfficeCode')) {
                //     if (this.checkOfficeCode()) {
                //       if (this.formRef.current?.getFieldValue('DateChar')) {
                if (this.state.functionName === 'NewConfirm' || this.state.functionName === 'NewReceptConfirmed') {
                  // console.log('新規');
                  // 新規登録、新規「受付」
                  this.NewConfirm()
                }
                if (this.state.functionName === 'AcceptChangeConfirm') {
                  // console.log('受付');
                  // 「受付」変更
                  this.AcceptChangeConfirm()
                }
                if (this.state.functionName === 'ReserveChangeConfirm') {
                  // console.log('予約');
                  // 「予約」変更
                  this.ReserveChangeConfirm()
                }
                //       } else {
                //         Modal.error({
                //           title: '受診日を入力してください',
                //           width: 300,
                //         })
                //       }
                //     } else {
                //       Modal.error({
                //         width: '450px',
                //         title: '指定の事業所コードは所属情報に存在しません',
                //       })
                //     }
                //   } else {
                //     Modal.error({
                //       width: '350px',
                //       title: '事業所コードを入力してください',
                //     })
                //   }
                // } else {
                //   Modal.error({
                //     width: '330px',
                //     title: 'カナ氏名を入力してください',
                //   })
                // }
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 新規登録、新規「受付」
   */
  NewConfirm() {
    // 備考で改行が入っている場合は、改行コードを削除
    let remarks = this.formRef.current?.getFieldValue('Remarks')
    remarks = remarks.replace(/\r?\n/g, '')

    let params = {
      ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      // Date: this.formRef.current?.getFieldValue('DateChar'),
      Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode'),
      FacilityNumHospital_Out: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      NClassify: this.formRef.current?.getFieldValue('NClassify'),
      MedicalExamLocation: this.formRef.current?.getFieldValue('MedicalExamLocation'),
      Remarks: remarks,
      personalSpecial: this.formRef.current.getFieldValue('personalSpecial'),
      Continuous: this.formRef.current?.getFieldValue('Continuous') ? 1 : 0,
      ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify') ? 1 : 0,
      AddedOrUnnecessary: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      Mode: this.state.dataScreen.Mode,
      DisplayContent: this.state.dataScreen.DisplayContent,
      OtherCondition: this.state.dataScreen.OtherCondition,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      StsPersonalInfoBasic: this.state.dataScreen.StsPersonalInfoBasic ? 1 : 0,
      personalbasic: this.state.dataScreen.personalbasic,
      w2ApplicationAttribute1: this.state.dataScreen.w2ApplicationAttribute1,
      personalbelong: this.state.dataScreen.personalbelong,
      StsContractInquiry: this.state.dataScreen.StsContractInquiry,
      StsFreeNum: this.state.dataScreen.StsFreeNum,
      FreeNumErrorTarget: this.state.dataScreen.FreeNumErrorTarget,
      Am_PmDivision: this.state.Am_Pm ? this.state.Am_Pm : this.state.dataScreen.Am_PmDivision,
      ProcessNum: this.state.dataScreen.ProcessNum,
      AcceptNum: this.state.dataScreen.AcceptNum,
      KyokaiBillingDate: this.state.dataScreen.KyokaiBillingDate,
      KyokaiAcceptNum: this.state.dataScreen.KyokaiAcceptNum,
      StsReserveStatus: this.state.dataScreen.StsReserveStatus,
      InfoHoldingOp: this.state.dataScreen.InfoHoldingOp,
      StateFlag: this.state.dataScreen.StateFlag,
      contractterm: this.state.dataScreen.contractterm,
      InsurerModify: this.state.dataScreen.InsurerModify,
      OfficeModify: this.state.dataScreen.OfficeModify,
      OtherGroupModify: this.state.dataScreen.OtherGroupModify,
      Personal1Modify: this.state.dataScreen.Personal1Modify,
      Person2Modify: this.state.dataScreen.Person2Modify,
      Personal3Modify: this.state.dataScreen.Personal3Modify,
      JInspectGCategory: this.state.dataScreen.JInspectGCategory,
      ApplicationAttributeReDisplay: this.state.dataScreen.ApplicationAttributeReDisplay,
      OneOClockTotalAmount: this.state.dataScreen.OneOClockTotalAmount,
      StsInspectChange: this.state.dataScreen.StsInspectChange ? 1 : 0,
      ContractQueryErrorMessage: this.state.dataScreen.ContractQueryErrorMessage,
      ContractInfo: this.state.dataScreen.ContractInfo,
      NonDisplaySetContract: this.state.dataScreen.NonDisplaySetContract,
      NonDisplaySetAdd: this.state.dataScreen.NonDisplaySetAdd,
      StsOption: this.state.dataScreen.StsOption ? 1 : 0,
      AddedOrUnnecessaryList: this.state.dataScreen.AddedOrUnnecessaryList,
      Age: this.state.Age,
      LockState: this.state.dataScreen.LockState,
      LimitErrorMessage: this.state.dataScreen.LimitErrorMessage,
      StsReserveChange: this.state.dataScreen.StsReserveChange,
      Li_ProcessDivision: this.state.Li_ProcessDivision,
      user: this.formRef.current?.getFieldValue('user') ?? '',
      selectedExamIdTimeList: this.state.selectedExamIdTimeList, //検査ごとの予約時間
      columnList: this.state.columnList,
      dataList: this.state.dataList,
      supplementList: this.formRef.current?.getFieldValue('tableSupplements') ?? [], //補足
    }

    PersonalReserveProcessAction.newConfirm(params)
      .then(async res => {
        let ReserveNum = {
          reserveNum: res?.data?.ReserveNum,
          courseLevel: res?.data?.CourseLevel,
          closeFlag: res.data.miraisOpenFlag
        }

        this.formRef.current?.setFieldsValue({
          ReserveNum: res?.data?.ReserveNum
        })

        this.returnModal(ReserveNum)

        if (res.data.miraisOpenFlag) {
          let miraisParams = {
            Li_ReserveNum: res.data.ReserveNum,
            Li_CourseLevel: res.data.CourseLevel
          }
          this.MiraisSingleTransmission(miraisParams)
        } else {
          message.success('登録が完了しました')
          let data = {
            ...this.state.dataScreen,
            OneOClockTotalAmount: res?.data?.OneOClockTotalAmount,
          }

          await this.setState({
            Lo_Update: true,
            isChangeData: false,
            isChangeForm: false,

            dataScreen: data,
          })
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 登録、変更、受付後の処理
   * Mirais単体送信 モーダル 表示
   * @param {*} params
   */
  MiraisSingleTransmission(params) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2737001_MiraisSingleTransmission
            {...params}
            onFinishScreen={({ Lio_StsReturn }) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  resetData = () => {
    this.formRef.current?.setFieldsValue({
      ReserveNum: '',
      PersonalNumId: '',
      Expression_75: '',
      Expression_76: '',
      Expression_78: '',
      Expression_79: '',
      Expression_80: '',
      Age: '',
      OfficeCode: '',
      BranchStoreCode: '',
      office_kanji_name: '',
      ConsultCourse: '',
      Expression_118: '',
    })
    let data = {
      ...this.state.dataScreen,
      ContractNum: 0,
      ContractType: 0,
      ContractDate: 0,
      ContractClassifyCode: 0,
      ConsultCourse: '',
      ComboBox_AddedOrUnnecessary: [
        { LinkedField: 0, DisplayField: '追加指定' },
        { LinkedField: 1, DisplayField: '不要指定' }
      ]
    }
    this.setState({
      dataScreen: data,
      dataSourceInspectChange: [],
      dataSourceSelect: [],
      AddedOrUnnecessary: 0,
      Expression_140: 20,
      ReserveState: '新規',
      ReserveStateColorNum: 211,
    })
  }

  /**
   * 「受付」変更
   */
  AcceptChangeConfirm() {
    // 備考で改行が入っている場合は、改行コードを削除
    let remarks = this.formRef.current?.getFieldValue('Remarks')
    remarks = remarks.replace(/\r?\n/g, '')

    let params = {
      ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      // Date: this.formRef.current?.getFieldValue('DateChar'),
      Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode'),
      FacilityNumHospital_Out: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      NClassify: this.formRef.current?.getFieldValue('NClassify'),
      MedicalExamLocation: this.formRef.current?.getFieldValue('MedicalExamLocation'),
      Remarks: remarks,
      personalSpecial: this.formRef.current?.getFieldValue('personalSpecial'),
      Continuous: this.formRef.current?.getFieldValue('Continuous') ? 1 : 0,
      ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify') ? 1 : 0,
      AddedOrUnnecessary: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      Mode: this.state.dataScreen.Mode,
      DisplayContent: this.state.dataScreen.DisplayContent,
      OtherCondition: this.state.dataScreen.OtherCondition,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      StsPersonalInfoBasic: this.state.dataScreen.StsPersonalInfoBasic ? 1 : 0,
      personalbasic: this.state.dataScreen.personalbasic,
      w2ApplicationAttribute1: this.state.dataScreen.w2ApplicationAttribute1,
      personalbelong: this.state.dataScreen.personalbelong,
      StsContractInquiry: this.state.dataScreen.StsContractInquiry,
      StsFreeNum: this.state.dataScreen.StsFreeNum,
      FreeNumErrorTarget: this.state.dataScreen.FreeNumErrorTarget,
      Am_PmDivision: this.state.Am_Pm ? this.state.Am_Pm : this.state.dataScreen.Am_PmDivision,
      ProcessNum: this.state.dataScreen.ProcessNum,
      AcceptNum: this.state.dataScreen.AcceptNum,
      KyokaiBillingDate: this.state.dataScreen.KyokaiBillingDate,
      KyokaiAcceptNum: this.state.dataScreen.KyokaiAcceptNum,
      StsReserveStatus: this.state.dataScreen.StsReserveStatus,
      InfoHoldingOp: this.state.dataScreen.InfoHoldingOp,
      StateFlag: this.state.dataScreen.StateFlag,
      contractterm: this.state.dataScreen.contractterm,
      InsurerModify: this.state.dataScreen.InsurerModify,
      OfficeModify: this.state.dataScreen.OfficeModify,
      OtherGroupModify: this.state.dataScreen.OtherGroupModify,
      Personal1Modify: this.state.dataScreen.Personal1Modify,
      Person2Modify: this.state.dataScreen.Person2Modify,
      Personal3Modify: this.state.dataScreen.Personal3Modify,
      JInspectGCategory: this.state.dataScreen.JInspectGCategory,
      ApplicationAttributeReDisplay: this.state.dataScreen.ApplicationAttributeReDisplay,
      OneOClockTotalAmount: this.state.dataScreen.OneOClockTotalAmount,
      StsInspectChange: this.state.dataScreen.StsInspectChange ? 1 : 0,
      ContractQueryErrorMessage: this.state.dataScreen.ContractQueryErrorMessage,
      ContractInfo: this.state.dataScreen.ContractInfo,
      NonDisplaySetContract: this.state.dataScreen.NonDisplaySetContract,
      NonDisplaySetAdd: this.state.dataScreen.NonDisplaySetAdd,
      StsOption: this.state.dataScreen.StsOption ? 1 : 0,
      AddedOrUnnecessaryList: this.state.dataScreen.AddedOrUnnecessaryList,
      Age: this.state.Age,
      LockState: this.state.dataScreen.LockState,
      LimitErrorMessage: this.state.dataScreen.LimitErrorMessage,
      StsReserveChange: this.state.dataScreen.StsReserveChange,
      Li_ProcessDivision: this.state.Li_ProcessDivision,
      selectedExamIdTimeList: this.state.selectedExamIdTimeList, //検査ごとの予約時間
      columnList: this.state.columnList,
      dataList: this.state.dataList,
      dataListIncludeDiscount: [...this.state.dataList, ...this.state.discountList],
      supplementList: this.formRef.current?.getFieldValue('tableSupplements') ?? [], //補足
      user: this.formRef.current?.getFieldValue('user') ?? '',
    }

    PersonalReserveProcessAction.acceptChangeConfirm(params)
      .then(async res => {
        if (res.data.miraisOpenFlag) {
          let miraisParams = {
            Li_ReserveNum: res.data.ReserveNum,
            Li_CourseLevel: res.data.CourseLevel ?? 0,
          }
          this.MiraisSingleTransmission(miraisParams)
        } else {
          let data = {
            ...this.state.dataScreen,
            OneOClockTotalAmount: res?.data?.OneOClockTotalAmount,
          }

          await this.setState({
            Lo_Update: true,
            isChangeData: false,
            isChangeForm: false,

            dataScreen: data,
          })
        }
        let OnFinish = {
          closeFlag: res.data.miraisOpenFlag
        }
        this.returnModal(OnFinish)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 「予約」変更
   */
  ReserveChangeConfirm() {
    // 備考で改行が入っている場合は、改行コードを削除
    let remarks = this.formRef.current?.getFieldValue('Remarks')
    remarks = remarks.replace(/\r?\n/g, '')

    let params = {
      ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      // Date: this.formRef.current?.getFieldValue('DateChar'),
      Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
      TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode'),
      FacilityNumHospital_Out: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      NClassify: this.formRef.current?.getFieldValue('NClassify'),
      MedicalExamLocation: this.formRef.current?.getFieldValue('MedicalExamLocation'),
      Remarks: remarks,
      personalSpecial: this.formRef.current?.getFieldValue('personalSpecial'),
      Continuous: this.formRef.current?.getFieldValue('Continuous') ? 1 : 0,
      ChangeClassify: this.formRef.current?.getFieldValue('ChangeClassify') ? 1 : 0,
      AddedOrUnnecessary: this.formRef.current?.getFieldValue('AddedOrUnnecessary'),
      StateFlag: this.formRef.current?.getFieldValue('reserveSituation'),

      Mode: this.state.dataScreen.Mode,
      DisplayContent: this.state.dataScreen.DisplayContent,
      OtherCondition: this.state.dataScreen.OtherCondition,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      StsPersonalInfoBasic: this.state.dataScreen.StsPersonalInfoBasic ? 1 : 0,
      personalbasic: this.state.dataScreen.personalbasic,
      w2ApplicationAttribute1: this.state.dataScreen.w2ApplicationAttribute1,
      personalbelong: this.state.dataScreen.personalbelong,
      StsContractInquiry: this.state.dataScreen.StsContractInquiry,
      StsFreeNum: this.state.dataScreen.StsFreeNum,
      FreeNumErrorTarget: this.state.dataScreen.FreeNumErrorTarget,
      Am_PmDivision: this.state.Am_Pm ? this.state.Am_Pm : this.state.dataScreen.Am_PmDivision,
      ProcessNum: this.state.dataScreen.ProcessNum,
      AcceptNum: this.state.dataScreen.AcceptNum,
      KyokaiBillingDate: this.state.dataScreen.KyokaiBillingDate,
      KyokaiAcceptNum: this.state.dataScreen.KyokaiAcceptNum,
      StsReserveStatus: this.state.dataScreen.StsReserveStatus,
      InfoHoldingOp: this.state.dataScreen.InfoHoldingOp,
      contractterm: this.state.dataScreen.contractterm,
      InsurerModify: this.state.dataScreen.InsurerModify,
      OfficeModify: this.state.dataScreen.OfficeModify,
      OtherGroupModify: this.state.dataScreen.OtherGroupModify,
      Personal1Modify: this.state.dataScreen.Personal1Modify,
      Person2Modify: this.state.dataScreen.Person2Modify,
      Personal3Modify: this.state.dataScreen.Personal3Modify,
      JInspectGCategory: this.state.dataScreen.JInspectGCategory,
      ApplicationAttributeReDisplay: this.state.dataScreen.ApplicationAttributeReDisplay,
      OneOClockTotalAmount: this.state.dataScreen.OneOClockTotalAmount,
      StsInspectChange: this.state.dataScreen.StsInspectChange ? 1 : 0,
      ContractQueryErrorMessage: this.state.dataScreen.ContractQueryErrorMessage,
      ContractInfo: this.state.dataScreen.ContractInfo,
      NonDisplaySetContract: this.state.dataScreen.NonDisplaySetContract,
      NonDisplaySetAdd: this.state.dataScreen.NonDisplaySetAdd,
      StsOption: this.state.dataScreen.StsOption ? 1 : 0,
      AddedOrUnnecessaryList: this.state.dataScreen.AddedOrUnnecessaryList,
      Age: this.state.Age,
      LockState: this.state.dataScreen.LockState,
      LimitErrorMessage: this.state.dataScreen.LimitErrorMessage,
      StsReserveChange: this.state.dataScreen.StsReserveChange,
      Li_ProcessDivision: this.state.Li_ProcessDivision,
      user: this.formRef.current?.getFieldValue('user'),
      selectedExamIdTimeList: this.state.selectedExamIdTimeList, //検査ごとの予約時間
      columnList: this.state.columnList,
      dataList: this.state.dataList,
      supplementList: this.formRef.current?.getFieldValue('tableSupplements') ?? [], //補足
    }

    PersonalReserveProcessAction.reserveChangeConfirm(params)
      .then(async res => {
        if (res.data.miraisOpenFlag) {
          let miraisParams = {
            Li_ReserveNum: res.data.ReserveNum,
            Li_CourseLevel: res.data.CourseLevel ?? 0,
          }
          this.MiraisSingleTransmission(miraisParams)
        } else {
          let data = {
            ...this.state.dataScreen,
            OneOClockTotalAmount: res?.data?.OneOClockTotalAmount,
          }

          await this.setState({
            Lo_Update: true,
            isChangeData: false,
            isChangeForm: false,

            dataScreen: data,
          })
        }
        let OnFinish = {
          closeFlag: res.data.miraisOpenFlag
        }
        this.returnModal(OnFinish)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
        if (this.props.onDetermineReserve) {
          this.props.onDetermineReserve({
            personal_number_id: this.formRef.current?.getFieldValue('PersonalNumId'),
            period_time: this.formRef.current?.getFieldValue('TimeZone'),
          })
        }
      })
  }

  /**
   * ※どこからも呼ばれていない関数
   */
  // updateData() {
  //   let params = {
  //     ...this.state.dataScreen,
  //     ...this.formRef.current?.getFieldValue(),
  //     // DateChar: this.formRef.current?.getFieldValue('DateChar'),
  //     DateChar: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
  //     TimeZone: this.formRef.current?.getFieldValue('TimeZone'),
  //     // Date: this.formRef.current?.getFieldValue('DateChar')
  //     Date: moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD'),
  //   }

  //   PersonalReserveProcessAction.updateData(params)
  //     .then((res) => {
  //       // message.success('成功')
  //       if (res?.data?.reservation_number) {
  //         this.formRef.current?.setFieldsValue({
  //           ReserveNum: res?.data?.reservation_number
  //         })
  //       }
  //       if (this.state.dataTax.length > 0) {
  //         this.updateDataTax()
  //       } else {
  //         // 画面を閉じて、親画面のデータを再取得させる
  //         this.returnModal()
  //         this.index()
  //       }
  //     })
  //     .catch((err) => {

  //       const res = err.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }
  //       message.error(res.data.message)
  //     })
  // }

  /**
   * ※どこからも呼ばれていない関数
   */
  // updateDataTax() {
  //   let params = {
  //     Li_ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
  //     data: this.state.dataTax
  //   }

  //   PersonalReserveProcessAction.updateDataTax(params)
  //     .then(async (res) => {
  //       await this.setState({
  //         Lo_Update: true
  //       })
  //       this.updateDataBilling()
  //     })
  //     .catch((err) => {
  //       const res = err.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }
  //       message.error(res.data.message)
  //     })
  // }

  /**
   * ※どこからも呼ばれていない関数
   */
  // updateDataBilling() {
  //   let params = {
  //     Li_ReserveNum: this.formRef.current?.getFieldValue('ReserveNum')
  //   }

  //   PersonalReserveProcessAction.updateDataBilling(params)
  //     .then((res) => {
  //       // 画面を閉じて、親画面のデータを再取得させる
  //       this.returnModal()
  //       this.index()
  //     })
  //     .catch((err) => {
  //       const res = err.response
  //       if (!res || !res.data || !res.data.message) {
  //         message.error('エラーが発生しました')
  //         return
  //       }
  //       message.error(res.data.message)
  //     })
  // }

  checkColor(price) {
    if (price < 0) {
      return 209
    } else {
      return 208
    }
  }

  showWS2584019_PersonalInfoInquirySub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNumId')}
            importancePersonal={this.state.importancePersonal}
          />
        ),
      },
    })
  }

  showModalOfficeInfoInquirySub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component: (
          <WS2585001_OfficeInfoInquirySub
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchCode={this.formRef.current?.getFieldValue('BranchStoreCode') ? this.formRef.current?.getFieldValue('BranchStoreCode') : 0}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 個人検索・照会　モーダル
   */
  showWS0248001_PersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0248001_PersonalInfoSearchQuery
            // searchName={PersonalNumId}
            date={moment(new Date()).format('YYYY/MM/DD')}
            searchName={this.formRef.current?.getFieldValue('PersonalNumId')}
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode')}
            onFinishScreen={(output) => {
              if (output.recordData.personal_number_id !== this.state.PersonalNumId) {
                this.formRef.current?.setFieldsValue({
                  PersonalNumId: output.recordData.personal_number_id
                })
                this.forceUpdate()
                this.getInfoPersonal()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 契約情報照会 モーダル
   * @param {*} save
   */
  showWS0289012_ContractInfoInquiry(save) {
    this.setState({
      nameScreen: 'WS0289012_ContractInfoInquiry',
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0289012_ContractInfoInquiry
            Li_EffectiveLimitedDisplay={1}
            Li_Relationship={this.formRef.current?.getFieldValue('relationship') ?? this.state.dataScreen.relationship}
            Lio_ConsultCourse={this.formRef.current?.getFieldValue('ConsultCourse')}
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode') || 0}
            Li_Date={this.formRef.current?.getFieldValue('DateChar') ? moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD') : ''}
            Li_Other={this.state.dataScreen.OtherCondition}
            Li_Gender={this.state.sex}
            Li_DateBirth={this.formRef.current?.getFieldValue('Expression_80')}
            Li_HospitalOut={this.formRef.current?.getFieldValue('FacilityNumHospital_Out')}
            Li_NTsugikenmi={this.formRef.current?.getFieldValue('NClassify')}
            Li_Am_Pm={this.state.Am_Pm ? this.state.Am_Pm : this.state.dataScreen.Am_PmDivision}
            Lio_ContractType={this.state.dataScreen.ContractType}
            Lio_ContractOrgCode={this.state.dataScreen.ContractClassifyCode}
            Lio_ContractStartDate={this.state.dataScreen.ContractDate}
            Lio_ContractNum={this.state.dataScreen.ContractNum}
            Lo_Status=''
            Lo_ErrorMessage=''

            onFinishScreen={(output) => {
              if (output.Lio_ConsultCourse) {
                this.setState({
                  isUnValidConsultCourse: false,
                })
                this.setFieldConsultCourse(output.recordData, save)
              } else {
                this.setState({
                  isUnValidConsultCourse: true,
                })
                message.error('受診コースを入力してください')
              }

              this.setState({
                nameScreen: '',
                patternCode: output.pattern_code,
                // selectedExamIdTimeList: [] //selectedExamIdTimeListを初期化
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  getDataContract() {
    if (this.formRef.current?.getFieldValue('ConsultCourse') !== this.state.dataScreen.ConsultCourse) {
      if (this.formRef.current?.getFieldValue('ConsultCourse')) {
        let initParams = {
          Li_EffectiveLimitedDisplay: '',
          Lio_ConsultCourse: '',
          Li_MsgOutput: '',
          Li_Gender: '',
          Li_Relationship: '',
          Li_DateBirth: '',
          Li_AgeDate: '',
          Li_AgeYearEnd: '',
          Li_AgeSchool: '',
          Li_HospitalOut: '',
          Li_Am_Pm: '',
          Li_NTsugikenmi: '',
          Li_Other: '',
          Lo_ContractType: '',
          Lo_ContractOrgCode: '',
          Lo_ContractStartDate: '',
          Lo_ContractNum: '',
          Lo_Status: '',
          ContractType: '',
          Course: '',
          Search: '',
          Gender: 0,
          Relationship: 0,
          HospitalOut: 0,
          Ampm: 0,
          NTsugikenmi: 0,
          Age: 0,
          Other: 0,
          StsCurrentYearOnly: 0,
          DateYear: '',
          limit: 18,
          page: 1,
        }

        let params = {
          ...initParams,
          Li_EffectiveLimitedDisplay: 1,
          Li_Relationship: this.formRef.current?.getFieldValue('relationship') ?? this.state.dataScreen.relationship,
          Lio_ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
          Li_OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
          Li_BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') || 0,
          Li_Date: this.formRef.current?.getFieldValue('DateChar') ? moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD') : '',
          Li_Other: this.state.dataScreen.OtherCondition,
          Li_Gender: this.state.sex,
          Li_DateBirth: this.formRef.current?.getFieldValue('Expression_80'),
          Li_HospitalOut: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
          Li_NTsugikenmi: this.formRef.current?.getFieldValue('NClassify'),
          Li_Am_Pm: this.state.dataScreen.Am_PmDivision,
          Lio_ContractType: this.state.dataScreen.ContractType,
          Lio_ContractOrgCode: this.state.dataScreen.ContractClassifyCode,
          Lio_ContractStartDate: this.state.dataScreen.ContractDate,
          Lio_ContractNum: this.state.dataScreen.ContractNum,
          Lo_Status: '',
          Lo_ErrorMessage: ''
        }

        ContractInfoInquiryAction.index(params)
          .then((res) => {
            let data = res
            if (data) {
              let olParam = {
                ...params,
                Li_AgeDate: data.Li_AgeDate,
                Li_AgeSchool: data.Li_AgeSchool,
                Li_AgeYearEnd: data.Li_AgeYearEnd,
                Li_Am_Pm: data.Li_Am_Pm || '',
                Li_DateBirth: data.Li_DateBirth || '',
                Li_EffectiveLimitedDisplay: data.Li_EffectiveLimitedDisplay,
                Li_Gender: data.Li_Gender,
                Li_HospitalOut: data.Li_HospitalOut,
                Li_MsgOutput: data.Li_MsgOutput,
                Li_NTsugikenmi: data.Li_NTsugikenmi,
                Li_Other: data.Li_Other || '',
                Li_Relationship: data.Li_Relationship || '',
                Lio_ConsultCourse: data.Lio_ConsultCourse || '',
                Lo_ContractNum: data.Lo_ContractNum,
                Lo_ContractOrgCode: data.Lo_ContractOrgCode,
                Lo_ContractStartDate: data.Lo_ContractStartDate,
                Lo_ContractType: data.Lo_ContractType,
                Lo_Status: data.Lo_Status,
                DateYear: data.DateYear,
                ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
              }

              this.getInfoConsultCourse(olParam)
            }
          })
      } else {
        this.setFieldConsultCourse()
      }
    }
  }

  getInfoConsultCourse(params) {
    PersonalReserveProcessAction.getInfoConsultCourse(params)
      .then((res) => {
        let leng = res?.data?.SUB.length
        if (leng > 0) {
          if (leng > 1) {
            this.showWS0289012_ContractInfoInquiry()
          } else {
            let result = res?.data?.SUB[0]
            this.setFieldConsultCourse(result)
            this.setState({
              isUnValidConsultCourse: false
            })
          }
        } else {
          this.setState({
            isUnValidConsultCourse: true
          })
          this.setFieldConsultCourse()
          this.showWS0289012_ContractInfoInquiry()
        }

      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  async setFieldConsultCourse(result, save) {
    if (result) {
      let total_price = result.Expression_42 + result.Expression_43 + result.Expression_44 + result.Expression_36
      this.formRef.current?.setFieldsValue({
        ConsultCourse: result.chkup_course,
        Expression_170: result.Expression_42 === 0 ? '' : result.Expression_42?.toLocaleString(),
        Expression_171: result.Expression_43 === 0 ? '' : result.Expression_43?.toLocaleString(),
        Expression_172: result.Expression_44 === 0 ? '' : result.Expression_44?.toLocaleString(),
        Expression_173: result.Expression_36 === 0 ? '' : result.Expression_36?.toLocaleString(),
        Expression_174: total_price === 0 ? '' : total_price?.toLocaleString(),
      })
      let data = {
        ...this.state.dataScreen,
        ContractNum: result.Lo_ContractNum,
        ContractType: result.Lo_ContractType,
        ContractDate: result.Lo_ContractStartDate,
        ContractClassifyCode: result.Lo_ContractOrgCode,
        ConsultCourse: result.chkup_course,
      }

      await this.setState({
        dataScreen: data,
        courseChangeFlag: true
      })
      await this.contractRedisplay()
      if (save) {
        this.ConfirmProcessBtn_F12()
      }
    } else {
      this.formRef.current?.setFieldsValue({
        Expression_170: '',
        Expression_171: '',
        Expression_172: '',
        Expression_173: '',
        Expression_174: '',
      })
      let data = {
        ...this.state.dataScreen,
        ContractNum: 0,
        ContractType: 0,
        ContractDate: 0,
        ContractClassifyCode: 0,
        ConsultCourse: '',
      }
      await this.setState({
        dataScreen: data,
        courseChangeFlag: true
      })

      this.contractRedisplay()
    }
  }

  showModalMedicalExamContentsInquirySub() {
    let params = {
      Date: this.state.dataScreen.Date,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
      JInspectGCategory: this.state.dataScreen.JInspectGCategory,
    }

    PersonalReserveProcessAction.breakDown(params)
      .then(async (res) => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 550,
            component: (
              <WS2555001_MedicalExamContentsInquirySub
                Li_CourseCode={this.formRef.current?.getFieldValue('ConsultCourse')}
                Li_JInspectGCategory={this.state.dataScreen.JInspectGCategory}
                onFinishScreen={(output) => {
                  this.closeModal()
                }}
              />
            ),
          },
        })
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 個人番号に紐づく事業所一覧を取得
   */
  getListOfficeByPersonal() {
    const params = {
      PersonalNum: this.formRef.current?.getFieldValue('PersonalNumId')
    }
    PersonalOfficeSearchQueryAction.index(params)
      .then(res => {
        this.setState({
          dataOfficeByPersonal: res
        })
      })
      .catch(err => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 事業所コードの存在チェック
   * @returns
   */
  checkOfficeCode() {
    if (this.state.PersonalNumId) {
      // 個人番号が設定されている場合
      let OfficeCode = this.formRef.current?.getFieldValue('OfficeCode')
      let data = this.state.dataOfficeByPersonal

      let index = data.findIndex(x => x.office_code == OfficeCode)

      this.setState({ isUnValidOffice: false })
      if (index >= 0) {
        return true
      } else {
        return false
      }
    } else {
      // 個人番号が設定されていない場合
      let office_kanji_name = this.formRef.current?.getFieldValue('office_kanji_name')
      if (office_kanji_name) {
        this.setState({ isUnValidOffice: false })
        return true
      } else {
        this.setState({ isUnValidOffice: true })
        return false
      }
    }
  }

  getInfoOffice() {
    if (this.formRef.current?.getFieldValue('OfficeCode')) {
      let params = {
        OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
        BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') || 0,
        PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId')
      }
      PersonalReserveProcessAction.getInfoOffice(params)
        .then((res) => {
          this.formRef.current?.setFieldsValue({
            office_kanji_name: res?.data?.office_kanji_name,
            BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') == 0 ? '' : this.formRef.current?.getFieldValue('BranchStoreCode'),
            office_kana_name: res.data.office_kana_name,
            office_postal_code: res.data.office_postal_code,
            office_address_1: res.data.office_address_1,
            office_address_2: res.data.office_address_2,
            area_code: res.data.area_code,
            office_phone_number: res.data.office_phone_number,
            industrial_classification: res.data.industrial_classification,
            industrial_doctor_name: res.data.industrial_doctor_name,
            insurer_code: res.data.insurer_code,
            insurer_number: res.data.insurer_number,
            insurer_kanji_name: res.data.insurer_kanji_name,
            insurer_kana_name: res.data.insurer_kana_name,
            insurer_card_symbol: res.data.insurer_card_symbol,
            insurer_card_number: res.data.insurer_card_number,
            insurer_start_date_on: res.data.insurer_start_date_on,
            insurer_end_date_on: res.data.insurer_end_date_on,
            relationship: res.data.relationship,
          })
          this.setState({
            OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
            importanceOffice: res?.data?.importance_office,
            contentOffice: res?.data?.content_office,
            isChangeForm: true,
          })

          if (!res?.data?.office_kanji_name) {
            this.setState({ isUnValidOffice: true })
            Modal.error({
              width: '350px',
              title: '指定の事業所コードは存在しません',
              onOk: () => {
                this.formRef.current?.getFieldInstance('OfficeCode').focus()
              }
            })
          }
          // } else {
          //   if (!this.checkOfficeCode()) {
          //     Modal.error({
          //       width: '425px',
          //       title: '指定の事業所コードは所属情報に存在しません',
          //     })
          //   }
          // }

        })
        .catch((err) => {
          const res = err.response
          if (!res || !res.data || !res.data.message) {
            message.error('エラーが発生しました')
            return
          }
          message.error(res.data.message)
        })
    } else {
      this.formRef.current?.setFieldsValue({
        office_kanji_name: '',
        BranchStoreCode: '',
      })
      this.setState({
        OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
        importanceOffice: 0,
        contentOffice: '',
        isChangeForm: true,
      })
    }
  }

  /**
   * 個人事業所検索・照会 モーダル
   */
  showWS0381001_PersonalOfficeSearchQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS0381001_PersonalOfficeSearchQuery
            Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNumId')}
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode') || 0}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output.Lio_OfficeCode,
                BranchStoreCode: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode,
                office_kanji_name: output.recordData.office_kanji_name,
                insurer_kanji_name: output.recordData.insurer_kanji_name,
                insurer_card_symbol: output.recordData.insurer_card_symbol,
                insurer_card_number: output.recordData.insurer_card_number
              })

              this.setState({
                OfficeCode: output.Lio_OfficeCode,
                importanceOffice: output.recordData.Expression_14,
                contentOffice: '', //後で取得するようにする
                isChangeForm: true,
                isUnValidOffice: false,
                dataOfficeByPersonal: output.data
              })
              this.getInfoOffice()
              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * 事業所情報検索・照会 モーダル
   */
  showWS0247001_OfficeInfoRetrievalQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '1200px',
        component:
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode') || 0}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output.Lio_OfficeCode,
                BranchStoreCode: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode,
                office_kanji_name: output.Lo_Kanji_Name,
                insurer_kanji_name: output.recordData.insurer_kanji_name,
                insurer_card_symbol: output.recordData.insurer_card_symbol,
                insurer_card_number: output.recordData.insurer_card_number
              })

              this.setState({
                OfficeCode: output.Lio_OfficeCode,
                importanceOffice: output.recordData.importance,
                contentOffice: '', // 後で取得するようにする
                isChangeForm: true,
                isUnValidOffice: false
              })
              if (this.state.PersonalNumId) {
                this.changeBelongOffice(output.Lio_OfficeCode, output.Lio_BranchStoreCode)
              } else {
                this.getInfoOffice()
              }
              this.closeModal()
            }}
          />
      }
    })
  }

  changeBelongOffice = (office_code, branch_store_code) => {
    let params = {
      personal_number_id: this.state.PersonalNumId,
      office_code: office_code,
      branch_store_code: branch_store_code
    }
    PersonalReserveProcessAction.changeBelongOffice(params)
      .then((res) => { this.getInfoOffice() })
  }

  /**
   * 時間帯設定モーダル
   */
  showReserveAvailability() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ReserveAvailability
            Li_Date={moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD')}
            TimeZone={this.formRef.current?.getFieldValue('TimeZone') ?? ''}
            Li_SelectedExamIdTimeList={this.state.selectedExamIdTimeList}
            Li_SetCodeList={this.state.dataSourceInspectChange}
            dataScreen={this.state.dataScreen}
            onFinishScreen={(obj) => {
              // 受診日・時間帯設定
              this.formRef.current.setFieldsValue({
                DateChar: moment(obj.dateChar)?.format('YYYY/MM/DD (ddd)'),
                TimeZone: obj.timeZone
              })
              // 検査ごとの予約時間
              this.setState({
                selectedExamIdTimeList: obj.selectedRows,
                Am_Pm: obj.timeZone.substr(0, 2) < 12 ? 'AM' : 'PM'
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  setDataByPersonal(data) {
    this.formRef.current?.setFieldsValue({
      importance_personal: data.importance_personal,
      Expression_75: data.Expression_75,
      Expression_76: data.Expression_76,
      Expression_78: data.Expression_78,
      Expression_79: data.Expression_79,
      Expression_80: moment(data.Expression_80)?.format('NNyy/MM/DD'),
      Age: data.Age + '歳',
      examDayAge: data.examDayAge,
      yearAge: data.yearAge,
      postal_code: data.postal_code,
      address_1: data.address_1,
      address_2: data.address_2,
      phone_number: data.phone_number,
      email: data.email,
      mobile_mail: data.mobile_mail,
    })

    this.setState({
      personalData: data,
      isChangeForm: true,
      sex: data.Expression_79,
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      importancePersonal: data.importance_personal,
      contentPersonal: data.content_personal,
      Age: data.Age
    })
    if (data.message === 'Call Screen WS0381001') {
      this.showWS0381001_PersonalOfficeSearchQuery()
    } else {
      this.formRef.current?.setFieldsValue({
        OfficeCode: data.OfficeCode,
        BranchStoreCode: data.BranchStoreCode === 0 ? '' : data.BranchStoreCode,
        office_kanji_name: data.office_kanji_name,
        importance_office: data.importance_office,
        office_kana_name: data.office_kana_name,
        office_postal_code: data.office_postal_code,
        office_address_1: data.office_address_1,
        office_address_2: data.office_address_2,
        area_code: data.area_code,
        office_phone_number: data.office_phone_number,
        industrial_classification: data.industrial_classification,
        industrial_doctor_name: data.industrial_doctor_name,
        insurer_code: data.insurer_code,
        insurer_number: data.insurer_number,
        insurer_kanji_name: data.insurer_kanji_name,
        insurer_kana_name: data.insurer_kana_name,
        insurer_card_symbol: data.insurer_card_symbol,
        insurer_card_number: data.insurer_card_number,
        insurer_start_date_on: data.insurer_start_date_on,
        insurer_end_date_on: data.insurer_end_date_on,
        relationship: data.relationship,
      })

      this.setState({
        OfficeCode: data.OfficeCode,
        importanceOffice: data.importance_office,
        contentOffice: data.content_office ?? '',
      })
    }
  }

  /**
   * 個人番号の変更
   * @param {*} data
   */
  onChangePersonalNumber(data) {
    this.getListOfficeByPersonal()

    if (this.formRef.current?.getFieldValue('ReserveNum') && this.state.StateFlag == 1 && (this.state.PersonalNumId !== this.formRef.current?.getFieldValue('PersonalNumId'))) {
      Modal.confirm({
        title: '受付済みの受診者を変更しますが、よろしいですか？',
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        onOk: () => {
          this.setDataByPersonal(data)
        },
        onCancel: () => {
          this.formRef.current?.setFieldsValue({
            PersonalNumId: this.state.PersonalNumId,
          })
        }
      })
    } else {
      this.setDataByPersonal(data)
    }
  }

  /**
   * 個人情報を取得
   */
  getInfoPersonal() {
    if (this.formRef.current?.getFieldValue('PersonalNumId') !== '' && this.formRef.current?.getFieldValue('PersonalNumId') !== undefined) {
      let params = {
        // Li_Date: this.formRef.current?.getFieldValue('DateChar'),
        Li_Date: moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD'),
        ApplicationAttributeReDisplay: this.state.dataScreen.ApplicationAttributeReDisplay,
        PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
        ContractType: this.state.dataScreen.ContractType,
        ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
        ContractDate: this.state.dataScreen.ContractDate,
        ContractNum: this.state.dataScreen.ContractNum,
      }

      PersonalReserveProcessAction.getInfoPersonal(params)
        .then((res) => {
          if (res) {
            //補足設定
            this.setSupplements(res.data.supplements ?? [])

            let personalSpecial = ''
            res.data.personalSpecialList.forEach((value) => {
              personalSpecial = personalSpecial + value + '\n'
            })

            // メモボタンの色情報 と 特記情報
            this.formRef.current?.setFieldsValue({
              memo: res.data.memo,
              personalSpecial: personalSpecial
            })

            if (res.data.Expression_75) {
              this.onChangePersonalNumber(res.data)
            } else {
              Modal.error({
                title: '指定の個人番号は存在しません',
                width: 330,
                onOk: () => {
                  this.formRef.current?.getFieldInstance('PersonalNumId').focus()
                }
              })
              this.formRef.current?.setFieldsValue({
                Expression_75: '',
                Expression_76: '',
                Expression_78: '',
                Expression_80: '',
                Age: '',
              })
              this.setState({
                sex: null,
                importancePersonal: null,
                contentPersonal: '',
                PersonalNumId: '',
                Age: '',
                dataOfficeByPersonal: []
              })
            }
          }
        })
    } else {
      if (Object.keys(this.state.dataPersonal_2543).length > 0) {
        let age = moment(new Date())?.format('YYYY') - moment(this.state.dataPersonal_2543.DateBirthChar)?.format('YYYY')
        this.formRef.current?.setFieldsValue({
          PersonalNumId: this.state.dataPersonal_2543.PersonalNumId,
          importance_personal: null,
          Expression_75: this.state.dataPersonal_2543.KanaName,
          Expression_76: this.state.dataPersonal_2543.KanjiName,
          Expression_78: this.state.dataPersonal_2543.Gender == 1 ? '男性' : this.state.dataPersonal_2543.Gender == 2 ? '女性' : '',
          Expression_79: this.state.dataPersonal_2543.Gender,
          Expression_80: moment(this.state.dataPersonal_2543.DateBirthChar)?.format('NNyy/MM/DD'),
          Age: age > 0 ? age + '歳' : '',
        })

        this.setState({
          isChangeForm: true,
          sex: this.state.dataPersonal_2543.Gender,
          PersonalNumId: this.state.dataPersonal_2543.PersonalNumId,
          importancePersonal: null,
          contentPersonal: '',
          Age: age,
          dataPersonal_2543: this.state.dataPersonal_2543,
          dataOfficeByPersonal: []
        })
      } else {
        this.formRef.current?.setFieldsValue({
          Expression_75: '',
          Expression_76: '',
          Expression_78: '',
          Expression_80: '',
          Age: '',
        })
        this.setState({
          sex: null,
          importancePersonal: null,
          contentPersonal: '',
          PersonalNumId: '',
          Age: '',
          dataOfficeByPersonal: []
        })
      }
    }
  }

  /**
   *  画面を閉じて、親画面のデータを再取得させる
   * @param {*} value
   */
  returnModal(value) {
    if (this.props.onFinishScreen) {
      this.props.onFinishScreen(value)
    }
  }

  /**
   * 時間帯照会 モーダル
   */
  showWS2553003_PeriodTimeInquiry() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 250,
        // height: 475,
        component: (
          <WS2553003_PeriodTimeInquiry
            Lio_TimeZone={this.formRef.current?.getFieldValue('TimeZone')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                TimeZone: output.Lio_TimeZone,
                TimeZoneCopy: output.Lio_TimeZone
              })
              this.setState({
                isChangeForm: true,
              })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  ConsultHistory() {
    let params = {
      ReserveNum: this.formRef.current?.getFieldValue('ReserveNum'),
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId') || 0,
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') || 0,
      FacilityNumHospital_Out: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
      MedicalExamLocation: this.formRef.current?.getFieldValue('MedicalExamLocation'),
      // DateChar: this.formRef.current?.getFieldValue('DateChar'),
      DateChar: moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD'),
      ApplicationAttributeReDisplay: this.state.dataScreen.ApplicationAttributeReDisplay,
      ContractType: this.state.dataScreen.ContractType,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractNum: this.state.dataScreen.ContractNum,
      OneOClockTotalAmount: this.state.dataScreen.OneOClockTotalAmount,
      Age: this.state.Age,
    }
    PersonalReserveProcessAction.ConsultHistory(params)
      .then((res) => {
        let age = res?.data?.Age
        this.formRef.current?.setFieldsValue({
          Age: age > 0 ? age + '歳' : '',
        })

        this.setState({
          isChangeForm: true,
          Age: age,
        })
      })
      .catch((err) => {

        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 予約状況照会 カレンダー　モーダル
   */
  showWS2577003_CalendarEmptySub = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 900,
        component: (
          <WS2577003_CalendarEmptySub
            // Lio_Date={isNaN(new Date(this.formRef.current?.getFieldValue('DateChar')).getDate()) ? new Date() : this.formRef.current?.getFieldValue('DateChar')}
            Lio_Date={
              isNaN(new Date(this.formRef.current?.getFieldValue('DateChar')).getDate()) ?
                new Date()
                :
                moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD')
            }
            Lo_ContractOrgCode={this.state.dataScreen.ContractClassifyCode}
            Lo_ContractStartDate={this.state.dataScreen.ContractDate}
            Lo_ContractNum={this.state.dataScreen.ContractNum}
            dataSourceInspectChange={this.state.dataSourceInspectChange}
            Lo_ContractType={this.state.dataScreen.ContractType}
            Lio_CourseCode={this.formRef.current?.getFieldValue('ConsultCourse')}
            // 予約空き状況用
            Li_SetCodeList={this.state.dataSourceInspectChange}
            dataScreen={this.state.dataScreen}

            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                // DateChar: output
                DateChar: moment(output)?.format('YYYY/MM/DD (ddd)')
              })
              this.setState({
                isChangeForm: true
              })

              // 日付変更の場合、selectedExamIdTimeListを初期化
              this.setState({
                selectedExamIdTimeList: []
              })

              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * オプション変更　モーダル
   */
  showWS2537500_PersonalReserveOption = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        centered: true,
        component: (
          <WS2537500_PersonalReserveOption
            Li_dataSourceSelect={this.state.dataSourceSelect}
            Li_dataSourceInspectChange={this.state.dataSourceInspectChange}
            Li_dataScreen={this.state.dataScreen}
            Li_AddedOrUnnecessary={this.state.AddedOrUnnecessary}
            Li_Expression_140={this.state.Expression_140}
            Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNumId')}
            Li_ReserveNum={this.formRef.current?.getFieldValue('ReserveNum')}
            Li_ConsultCourse={this.formRef.current?.getFieldValue('ConsultCourse')}
            // Li_Date={this.formRef.current?.getFieldValue('DateChar')}
            Li_Date={moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD')}
            Li_Gender={this.state.personalData.Expression_79 ?? this.state.dataScreen.Expression_79}
            Li_DateBirth={this.state.personalData.Expression_80 ?? this.state.dataScreen.Expression_80}
            Li_Relationship={this.state.personalData.relationship ?? this.state.dataScreen.relationship}
            Li_TimeZone={this.formRef.current?.getFieldValue('TimeZone')}
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode') || 0}
            Li_FacilityNumHospital_Out={this.formRef.current?.getFieldValue('FacilityNumHospital_Out')}
            Li_NClassify={this.formRef.current?.getFieldValue('NClassify')}
            Li_StsOption={this.state.dataScreen.StsOption}
            Li_ChangeClassify={this.formRef.current?.getFieldValue('ChangeClassify')}
            TimeZone={this.formRef.current?.getFieldValue('TimeZone')}
            Li_PatternCode={this.state.patternCode}
            Li_JInspectGCategory={this.state.dataScreen.JInspectGCategory}
            columnList={this.state.columnList}
            dataList={this.state.dataList}
            onFinishScreen={(output) => {
              if (output.dataSourceInspectChange) {
                let array = output.dataSourceInspectChange.map((value, index, array) => (value.totalPrice))
                let counter_array = output.dataSourceInspectChange.map((value, index, array) => (value.personalTotalPrice))
                let totalAmount = array.reduce((sum, element) => { return (sum + element) })
                let counter_total_amount = counter_array.reduce((sum, element) => { return (sum + element) })
                totalAmount = NumberFormat.formatter(totalAmount)
                counter_total_amount = NumberFormat.formatter(counter_total_amount)
                this.setState({
                  totalAmount: totalAmount
                })
                this.formRef.current?.setFieldsValue({
                  counter_total_amount: counter_total_amount
                })
              }

              this.setState({
                dataSourceInspectChange: output.dataSourceInspectChange,
                selectedRowInspectChange: output.selectedRowInspectChange,
                selectedRowKeyInspectChange: output.selectedRowKeyInspectChange,
                selectedOptions: output.dataSourceInspectChange.filter(item => item.changeType >= 40)
              })

              this.getBillingAddressList(output.dataSourceInspectChange)

              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 補足情報設定　モーダル
   */
  showWS0344001_SupplementalInfoSetting() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS0344001_SupplementalInfoSetting
            Li_IdentifyChar={'MAST4'}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 重複要素を取得
   * @param {*} param0
   * @returns
   */
  getDuplicateValues = ([...array]) => {
    return array.filter((value, index, self) => self.indexOf(value) === index && self.lastIndexOf(value) !== index);
  }

  /**
   * 補足情報を設定
   */
  setSupplements = (supplements) => {
    this.formRef.current?.setFieldsValue({
      tableSupplements: supplements
    })
    this.setState({
      dataSourceSupplements: supplements
    })
  }

  /**
   * 入力変更処理
   * @param {*} value
   * @param {*} record
   * @param {*} index
   */
  onChangeSupplement = (value, record, index) => {
    let chnageValue = ''
    if (record.condition_1 === 'X') {
      chnageValue = wanakana.toRomaji(value)
    } else if (record.condition_1 === 'K') {
      chnageValue = wanakana.toKatakana(value)
    } else if (record.condition_1 === 'J') {
      chnageValue = value
      // chnageValue = wanakana.toHiragana(value)
    }

    let arrTemp = [...this.state.dataSourceSupplements]
    // 内容を反映
    let obj = {
      ...record,
      content: chnageValue, //変更内容
      changeFlag: 1 //変更フラグ
    }
    arrTemp[index] = obj

    this.formRef.current?.setFieldsValue({
      tableSupplements: arrTemp
    })
    this.setState({
      dataSourceSupplements: arrTemp, // テーブル更新
    })
  }

  /**
   * モーダル　閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centerd: false
      },
    })
  }

  getBillingAddressList = (newInspectChange) => {
    let params = {
      // Date: this.formRef.current?.getFieldValue('DateChar'),
      Date: moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD'),
      ContractNum: this.state.dataScreen.ContractNum,
      ContractType: this.state.dataScreen.ContractType,
      ContractDate: this.state.dataScreen.ContractDate,
      ContractClassifyCode: this.state.dataScreen.ContractClassifyCode,
      ConsultCourse: this.state.dataScreen.ConsultCourse,
      reservation_number: this.state.dataScreen.ReserveNum,
      dataSourceInspectChange: newInspectChange ? newInspectChange : this.state.dataSourceInspectChange,
      selectedExamIdTimeList: this.state.selectedExamIdTimeList,
      courseChangeFlag: this.state.courseChangeFlag ? 1 : 0,  //コースを変更したときtrue
      office_code: this.formRef.current?.getFieldValue('OfficeCode'),
      PersonalNumId: this.formRef.current?.getFieldValue('PersonalNumId'),
      dataList: this.state.dataList
    }
    // ここ
    PersonalReserveProcessAction.getBillingAddressList(params)
      .then(res => {
        if (res.contractTerm_id_flag == true) {
          Modal.warning({
            title: '警告',
            width: 400,
            content: <div style={{ whiteSpace: 'pre-wrap' }}>  {"コースが登録されていません。\n  コースを選択してください。"}</div>,
            okText: "は　い",
            onOk: () => { this.closeModal() }
          })
        } else {
          let newDataList = res.dataList ?? []
          let oldDataList = this.state.dataList ?? []

          if (oldDataList.length > 0) {
            newDataList.map((newValue, index) => {
              let neoOldDataList = []
              oldDataList.map((oldValue, index) => {
                if (newValue.data_division == oldValue.data_division && newValue.set_code == oldValue.set_code && newValue.serial_number == oldValue.serial_number && newValue.contract_id == oldValue.contract_id) {
                  neoOldDataList = oldValue
                }
              })
              if (neoOldDataList.length !== 0) {
                newDataList[index].organization_unit_price = neoOldDataList.organization_unit_price
                newDataList[index].organization_consumption_tax = neoOldDataList.organization_consumption_tax
                newDataList[index].organization_total_price = neoOldDataList.organization_total_price
                newDataList[index].billing_limit = neoOldDataList.billing_limit
              }
            })
          }
          let newTotalAmount = 0
          let new_counter_total_amount = 0
          newDataList.map((value, index) => {
            if (value.data_division == 30 || value.data_division == 60) {
              newTotalAmount -= value.organization_total_price
            } else {
              newTotalAmount += value.organization_total_price
            }
            if (value.serial_number === 4 || value.serial_number === 5 || value.serial_number === 6) {
              if (value.data_division == 30 || value.data_division == 60) {
                new_counter_total_amount -= value.organization_total_price
              } else {
                new_counter_total_amount += value.organization_total_price
              }
            }
          })
          this.formRef.current?.setFieldsValue({
            total_amount: newTotalAmount,
            counter_total_amount: new_counter_total_amount.toLocaleString()
          })


          let newDiscount = this.state.discount
          res.discountList.map((value) => {
            newDiscount += value.organization_total_price
          })

          this.setState({
            columnList: res.columnList ?? [],
            dataList: newDataList,
            taxRate: res.taxRate,
            rounding: res.rounding,
            totalAmount: newTotalAmount ?? 0,
            selectedExamIdTimeList: res.selectedExamIdTimeList,
            discountList: res.discountList,
            discountNameList: res.discountNameList,
            discount: newDiscount,
            unchangeableSerialNumberList: res.unchangeableSerialNumberList
          })
        }
      })
  }

  medicalExamContentsInquiry() {
    let params = {
      Li_ReserveNum: this.props.Li_ReserveNum ? this.props.Li_ReserveNum : this.formRef.current?.getFieldValue('ReserveNum'),
      // date: this.formRef.current?.getFieldValue('DateChar'),
      date: moment(this.formRef.current?.getFieldValue('DateChar'))?.format('YYYY/MM/DD'),
      contractType: this.state.dataScreen.ContractType,
      contractOrgCode: this.state.dataScreen.ContractClassifyCode,
      ContractNum: this.state.dataScreen.ContractNum,
      ConsultCourse: this.state.dataScreen.ConsultCourse,
      courseLevel: this.state.dataScreen.course_level,
      addressCategory: this.state.dataScreen.address_category,
      jInspectGCategory: this.state.dataScreen.JInspectGCategory,
      dataSourceInspectChange: this.state.dataSourceInspectChange,
      contractStartDate: this.state.dataScreen.ContractDate,
      personalFlag: 1,
    }

    VisitsInspectConsultInquirySubAction(params)
      .then(res => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "1150px",
            style: { top: 10 },
            component: (
              <AnotherInspectItemSettingCategory
                ContractType={this.state.dataScreen.ContractType}
                ContractStartDate={this.state.dataScreen.ContractDate}
                ContractOrgCode={this.state.dataScreen.ContractClassifyCode}
                ContractNum={this.state.dataScreen.ContractNum}
                Medical_exam_course={this.state.dataScreen.ConsultCourse}
                checkFlag={true}
                viewOnly={true}
                JInspectGCategory={res.data.JInspectGCategory}
              />

            )
          }
        })

        // this.setState({
        //   childModal: {
        //     ...this.state.childModal,
        //     visible: true,
        //     width: 600,
        //     component:
        //       <WS2555001_MedicalExamContentsInquirySub
        //         Li_CourseCode={this.formRef.current?.getFieldValue('ConsultCourse')}
        //         Li_JInspectGCategory={res.data.JInspectGCategory}
        //         onFinishScreen={() => {
        //           this.closeModal()
        //         }}
        //       />
        //     ,
        //   },
        // })
      })
    // .catch((err) => {
    //   console.log(err.response)
    //   const res = err.response
    //   if (!res || !res.data || !res.data.message) {
    //     message.error('エラーが発生しました')
    //     return
    //   }
    //   message.error(res.data.message)
    // })
  }

  /**
   * 電子カルテ起動のAPIを実行
   */
  runEMedicalRecords = () => {
    let params = {
      PersonalNum: this.formRef.current?.getFieldValue('PersonalNumId')
    }

    PersonalReserveProcessAction.runEMedicalRecords(params)
      .then((res) => {
        if (res) {
          let paramsUrl = {
            exe: res.exe,
            personal: res.personal
          }

          // URL実行
          axios({
            method: 'post',
            url: res.url,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              // 'Access-Control-Allow-Origin': '*'
            },
            params: paramsUrl,
          })
        }
      })
  }

  /**
   * 請求調整で追加または削除したときに動かす
   */
  billingAddressSave(params) {
    PersonalReserveProcessAction.billingAddressSave(params)
      .then((res) => {
        this.getBillingAddressList(null)
      })
  }

  /**
   * 受付処理
   * @param {*} reserveNum
   */
  acceptButton = (record) => {
    let params = {
      state_flag: record.state_flag ? reserveNum.state_flag : record.state_flag,
      DeskReceptistConfirmed: this.props.iniObj.DeskReceptistConfirmed ? 1 : 0,
      course_level: record?.courseLevel ? record.courseLevel : record.course_level,
      reserve_num: this.formRef.current?.getFieldValue('ReserveNum'),
      visit_date_on: record.visit_date_on,
      personal_1_total_price: record.personal_1_total_price,
      personal_2_total_price: record.personal_2_total_price,
      personal_3_total_price: record.personal_3_total_price,
      deposit_price_1: record.deposit_price_1,
      deposit_price_2: record.deposit_price_2,
      deposit_price_3: record.deposit_price_3,
      AcceptNum: 0
    }
    AcceptButtonAction(params)
      .then(res => {
        if (res.data) {
          let props = { Li_ReserveNum: res.data.Li_ReserveNum }
          this.props.onFinishScreen({ closeFlag: false })
        }
      })
  }

  /**
   * 領収書ボタンが押された時の処理
   * 入金有り
   */
  checkWidthInWS2622003 = () => {
    ReceiptProcessSubAction.getScreenData({ Li_ReserveNum: this.state.ReserveNum })
      .then(item => {
        if (item) {
          const width25 = item.sts1 > 0 && item.sts2 === 0 && item.sts3 === 0 ? '25%' : null
          const width50 = item.sts1 > 0 && (item.sts2 > 0 || item.sts3 > 0) ? '50%' : null
          const width80 = item.sts1 > 0 && item.sts2 > 0 && item.sts3 > 0 ? '80%' : null
          const checkShow = item.sts1 === 0 && item.sts2 === 0 && item.sts3 === 0 ? false : true
          const width = width25 ? width25 : (width50 ? width50 : (width80 ? width80 : ''))
          this.setState({ widthWS2622003: width })
          if (checkShow) {
            this.callModal(props, this.state.widthWS2622003, 'WS2622003_ReceiptProcessSub')
          }
        }
      })
  }

  displayWS2622004ReceiptProcessSubBulk() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component:
          <WS2622004_ReceiptProcessSubBulk
            Li_ReserveNum={this.state.ReserveNum}
            Li_SpecifiedIdentify={''}
            Li_SelectOptions={[
              // {
              //   value: [this.formRef.current?.getFieldValue('Expression_76') ?? '', this.formRef.current?.getFieldValue('office_kanji_name') ?? '']
              // }
              // [
              //   this.formRef.current?.getFieldValue('Expression_76') ?? '',
              //   this.formRef.current?.getFieldValue('office_kanji_name') ?? ''
              // ]
              {
                label: this.formRef.current?.getFieldValue('Expression_76'),
                value: this.formRef.current?.getFieldValue('Expression_76'),
              },
              {
                label: this.formRef.current?.getFieldValue('office_kanji_name'),
                value: this.formRef.current?.getFieldValue('office_kanji_name'),
              }
            ]}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />

        // <WS2622003_ReceiptProcessSub
        //   Li_ReserveNum={this.state.ReserveNum}
        //   Li_SpecifiedIdentify=''
        //   onFinishScreen={() => {
        //     this.closeModal()
        //   }}
        // />
        ,
      },
    })
  }
  /**
   * 領収書ボタンが押された時の処理
   * 入金無し
   */
  // receptionStatus = () => {
  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: 600,
  //       component:
  //         <WS2621001_PaymentProcessSub
  //           Li_ReserveNum={this.state.ReserveNum}
  //           onFinishScreen={() => {
  //             this.closeModal()
  //           }}
  //         />
  //       ,
  //     },
  //   })
  // }
  setFormFieldValue(namePath, value) {
    this.formRef?.current?.setFields([
      {
        name: namePath,
        value,
      },
    ]);
  }
  renderForm(i) {
    const value = this.state[`getIndex${i}`]
    this.setFormFieldValue(`ReceiptDateChar${i}`, value && value?.ReceiptDateChar ? moment(value?.ReceiptDateChar) : '')
    this.setFormFieldValue(`W1_destination_name${i}`, value && value?.W1_destination_name[0] ? value?.W1_destination_name[0] : '')
    this.setFormFieldValue(`receiptNumber${i}`, value && value?.receiptNumber ? value?.receiptNumber : '-')
    this.setFormFieldValue(`W1_subject${i}`, value && value?.W1_subject ? value?.W1_subject : '')
    this.setFormFieldValue(`DepositAndWithdrawalForm${i}`, value && value?.DepositAndWithdrawalForm ? value?.DepositAndWithdrawalForm : '')
    this.setFormFieldValue(`identify${i}`, value && value?.identify ? value?.identify : '')
  }
  automaticdeposit = async (Li_ReserveNum) => {
    await PaymentProcessSubAction.getScreenData({ Li_ReserveNum })
      .then(res => {
        const data = res?.data;
        const obj = { Linked: '', Display: '' }
        const card = data.Card
        card.unshift(obj)
        this.setState({ initialValues: { ...data, Card: card } })

        const item = this.state.initialValues;
        const params = {
          PayDay: res.data.PayDay,
          AmountBilled: res.data.AmountBilled,
          DepositAmount: res.data.DepositAmount,
          ReceiptAmount: item.ReceiptAmount,
          Otsuri: item.Otsuri,
          PayManageNum: item.PayManageNum,
          StsAnotherDay1: res.data.StsAnotherDay1,
          Li_ReserveNum: item.Li_ReserveNum,
          BillingManageNum: item.BillingManageNum,
          PayType: res.data.PayType,
          Card: res.Card ? res.Card : '',
          RegisterClassify: item.RegisterClassify,
          Date: item.Date,
          StsPreReceipt: res.data.StsPreReceipt,
          ReceiptDateInitialValue: item.ReceiptDateInitialValue,
          StsAnotherDate2: res.data.StsAnotherDate2,
          StsAnotherDate3: res.data.StsAnotherDate3,
        }
        PaymentProcessSubAction.confirmF12(params)
          .then(res => {
            const data = { Li_ReserveNum: res.data.Li_ReserveNum, Li_SpecifiedIdentify: res.data.Li_SpecifiedIdentify }
            PaymentProcessSubAction.getScreenData(data)
              .then(res => {
                ReceiptProcessSubAction.getScreenData(data)
                  .then(res => {
                    this.setFormFieldValue('sts1', res && res.sts1 ? res.sts1 : 0)
                    this.setFormFieldValue('sts2', res && res.sts2 ? res.sts2 : 0);
                    this.setFormFieldValue('sts3', res && res.sts3 ? res.sts3 : 0);
                    let cards = [
                      { sts: res.sts1, receipt: res.ReceiptManageNum1Sheet, title: res.Title1Sheet },
                      { sts: res.sts2, receipt: res.ReceiptManageNum2Nd, title: res.Title2Nd },
                      { sts: res.sts3, receipt: res.ReceiptManageNum3Rd, title: res.Title3Rd }
                    ]
                    this.setState({
                      ...this.state,
                      ScreenData: res,
                      Cards: cards
                    })
                    const params = {
                      Cards: cards,
                      Li_ReserveNum: data.Li_ReserveNum,
                    }
                    ReceiptProcessSubAction.getIndex(params)
                      .then(res => {
                        res.forEach((item, i) => {
                          const index = item.identify
                          let array = []
                          item.W1_destination_name.forEach((item, l) => {
                            array = [...array, { value: item }]
                          })
                          this.setState({
                            ...this.state,
                            [`getIndex${index}`]: item,
                            [`OptionInspect${index}`]: item.OptionInspectData,
                            [`W1_destination_name${index}`]: array,
                          })
                          this.renderForm(index)
                        })
                        let list = [];
                        let receiptManagementNumberList = [];
                        this.state.Cards.forEach((s, i) => (
                          list.push({
                            receipt: s.receipt,
                            price: s.title,
                            destination_name: this.formRef?.current?.getFieldValue(`W1_destination_name${i + 1}`),
                            subject: this.formRef?.current?.getFieldValue(`W1_subject${i + 1}`),
                            receiptDateChar: moment(this.formRef?.current?.getFieldValue(`ReceiptDateChar${i + 1}`)).format("YYYY/MM/DD"),
                            receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
                            flag: this.formRef?.current?.getFieldValue(`sts${i + 1}`) ? 1 : 0
                          })
                        ));
                        let params = {
                          Li_ReserveNum: data.Li_ReserveNum,
                          list: list
                        }
                        //チェックが入っているモノのreceiptを取得
                        this.state.Cards.forEach((s, i) => {
                          if (this.formRef?.current?.getFieldValue(`sts${i + 1}`)) {
                            receiptManagementNumberList.push({
                              receiptManagementNumber: s.receipt,
                              receiptNumber: this.formRef.current?.getFieldValue(`receiptNumber${i + 1}`),
                            })
                          }
                        });
                        ReceiptProcessSubAction.confirmF12(params)
                      })
                  })
              })
          })
      })
  }

  /**
   * 「 Mirais送信」ボタン押下
   * Mirais単体送信 モーダル 表示
   */
  openMiraisSingleTransmission = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centerd: true,
        component:
          <WS2737001_MiraisSingleTransmission
            Li_ReserveNum={this.state.ReserveNum}
          />
        ,
      },
    })
  }

  render() {
    return (
      <div className='personal-reserve-process' style={{ width: 1200 }}>
        {/* 受付状態の人は受付変更、そうじゃない人は個人予約処理 */}
        <Card title={this.state.dataScreen.StateFlag === 1 ? '受付変更' : '個人予約処理'}>
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={this.props.Li_Child ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90%',
                            component: (
                              <WS0306001_ContractInfoMaintain
                                Li_Flag={1}
                                onFinishScreen={(output) => {
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}>
                      契約情報
                    </Menu.Item>
                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '90%',
                            component: (
                              <WS2708001_SetInfoMaintain
                                Li_Flag={1}
                                onFinishScreen={(output) => {
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    >
                      セット情報
                    </Menu.Item>
                    <Menu.Item
                      key='3'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 200,
                            component: (
                              <WS2537084_InfoHeld
                                onFinishScreen={(output) => {
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    >
                      情報保持
                    </Menu.Item>
                    <Menu.Item
                      key='4'
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (this.state.ReserveNum && this.state.ReserveNum > 0) {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 600,
                              component: (
                                <WS0650001_DocumentBatchCreateSub
                                  Li_ReserveNum={this.state.ReserveNum}
                                  onFinishScreen={(output) => {
                                    this.closeModal()
                                  }}
                                />
                              ),
                            },
                          })
                        } else {
                          Modal.warning({
                            width: '280px',
                            title: '予約後に使用可能です',
                            okText: 'Ok',
                            icon: <WarningOutlined />,
                          })
                        }
                      }}
                    >
                      予約関連
                    </Menu.Item>
                    <Menu.Item
                      key='6'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.showWS0344001_SupplementalInfoSetting()
                      }}
                    >
                      補足情報設定
                    </Menu.Item>
                    <Menu.Item
                      key='7'
                      style={{ width: '100%' }}
                      disabled={!this.formRef.current?.getFieldValue('PersonalNumId')}
                      onClick={() => {
                        this.openMiraisSingleTransmission()
                      }}
                    >
                      Mirais送信
                    </Menu.Item>
                    <Menu.Item
                      key='8'
                      style={{ width: '100%' }}
                      disabled={!this.formRef.current?.getFieldValue('PersonalNumId')}
                      onClick={() => {
                        this.runEMedicalRecords()
                      }}
                    >
                      Mirais起動
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className={(this.props.Li_PersonalNum || this.props.Li_ReserveNum) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
            labelAlign='left'
          >
            <div className='box_inner_horizontal' style={{ marginBottom: 5 }}>
              <Form.Item
                label='ID'
                name='PersonalNumId'
                style={{ marginBottom: 0 }}>
                <Input.Search
                  allowClear={true}
                  type='tel'
                  className='input-search-size-number-allow-clear-10'
                  style={{ textAlign: 'right' }}
                  onPressEnter={() => {
                    this.getInfoPersonal()
                  }}
                  onSearch={(value, event) => {
                    if (event.target.localName !== 'input') {
                      this.showWS0248001_PersonalInfoSearchQuery()
                    }
                  }}
                />
              </Form.Item>
              <div style={{ width: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {this.state.importancePersonal === 1 ?
                  <Tooltip title={this.state.contentPersonal}>
                    <InfoCircleOutlined
                      style={{ color: '#1890ff', cursor: 'pointer', fontSize: 24 }}
                      onClick={() => {
                        this.showWS2584019_PersonalInfoInquirySub()
                      }} />
                  </Tooltip>
                  :
                  this.state.importancePersonal === 3 ?
                    <Tooltip title={this.state.contentPersonal}>
                      <WarningOutlined
                        style={{ color: '#faad14', cursor: 'pointer', fontSize: 24 }}
                        onClick={() => {
                          this.showWS2584019_PersonalInfoInquirySub()
                        }} />
                    </Tooltip>
                    :
                    this.state.importancePersonal === 5 ?
                      <Tooltip title={this.state.contentPersonal}>
                        <CloseCircleOutlined
                          style={{ color: '#ff4d4f', cursor: 'pointer', fontSize: 24 }}
                          onClick={() => {
                            this.showWS2584019_PersonalInfoInquirySub()
                          }} />
                      </Tooltip>
                      :
                      <Tooltip title='個人情報照会'>
                        <Button
                          icon={<MoreOutlined />}
                          disabled={!this.state.PersonalNumId}
                          onClick={() => {
                            this.showWS2584019_PersonalInfoInquirySub()
                          }}
                        />
                      </Tooltip>
                }
              </div>
              <Button
                type='primary'
                hidden={this.state.PersonalNumId}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 500,
                      component: (
                        <WS2543001_ApplicationInfoRegister
                          Li_ReserveNum={this.formRef.current?.getFieldValue('ReserveNum')}
                          Li_Data={this.state.dataPersonal_2543}
                          onFinishScreen={async (output) => {
                            let age = output.data.DateBirthChar ? moment(new Date())?.format('YYYY') - moment(output.data.DateBirthChar)?.format('YYYY') : ''
                            this.formRef.current?.setFieldsValue({
                              PersonalNumId: output.PersonalNumId,
                              importance_personal: null,
                              Expression_75: output.data.KanaName,
                              Expression_76: output.data.KanjiName,
                              Expression_78: output.data.Gender == 1 ? '男性' : output.data.Gender == 2 ? '女性' : '',
                              Expression_79: output.data.Gender,
                              Expression_80: output.data.DateBirthChar ? moment(output.data.DateBirthChar)?.format('NNyy/MM/DD') : '',
                            })

                            await this.setState({
                              isChangeForm: true,
                              sex: output.data.Gender,
                              PersonalNumId: output.PersonalNumId,
                              importancePersonal: null,
                              contentPersonal: '',
                              Age: age,
                              dataPersonal_2543: output.data
                            })

                            this.forceUpdate()

                            this.ConsultHistory()
                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  })
                }} >直接</Button>
              <Button
                type='primary'
                hidden={!this.state.PersonalNumId}
                onClick={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: 700,
                      component: (
                        <WS2542001_PreviousCourseAcquisitionSub
                          Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNumId')}
                          Lio_CourseCode={this.formRef.current?.getFieldValue('ConsultCourse')}
                          Lio_ContractType={this.state.dataScreen.ContractType}
                          Lio_ContractOrgCode={this.state.dataScreen.ContractClassifyCode}
                          Lio_ContractStartDate={this.state.dataScreen.ContractDate}
                          Lio_ContractNum={this.state.dataScreen.ContractNum}
                          onFinishScreen={async (output) => {
                            this.formRef.current?.setFieldsValue({
                              ConsultCourse: output?.Lio_CourseCode,
                              StsReturn: output?.Lo_Sts,
                              Expression_118: output.recordData.contract_short_name + ' (' + moment(output.recordData.visit_date_on)?.format('YYYY') + '年度' + ')',

                              Expression_170: '',
                              Expression_171: '',
                              Expression_172: '',
                              Expression_173: '',
                              Expression_174: '',
                            })

                            let data = {
                              ...this.state.dataScreen,
                              ContractType: output?.Lio_ContractType,
                              ContractClassifyCode: output?.Lio_ContractOrgCode,
                              ContractDate: output?.Lio_ContractStartDate,
                              ContractNum: output?.Lio_ContractNum,
                              ConsultCourse: output?.Lio_CourseCode,
                            }

                            await this.setState({
                              importanceOffice: 1,
                              ConsultCourse: output?.Lio_CourseCode,
                              dataScreen: data,
                              isUnValidConsultCourse: output?.Lio_CourseCode ? false : true,
                            })
                            this.contractRedisplay()
                            this.forceUpdate()

                            this.closeModal()
                          }}
                        />
                      ),
                    },
                  })
                }}
              >
                履歴
              </Button>
              <Form.Item
                label='事業所'
                name='OfficeCode'
                style={{
                  marginLeft: 20,
                  marginBottom: 0
                }}
              >
                <Input.Search
                  allowClear={true}
                  className='input-search-size-number-allow-clear-8'
                  onPressEnter={() => {
                    this.showWS0247001_OfficeInfoRetrievalQuery()
                  }}
                  onSearch={(value, event) => {
                    if (event.target.localName === 'input') {
                      this.formRef.current?.setFieldsValue({
                        BranchStoreCode: '',
                        office_kanji_name: '',
                        importance_office: ''
                      })
                      this.setState({
                        BranchStoreCode: '',
                        office_kanji_name: '',
                        importanceOffice: '',
                        contentOffice: '',
                      })
                    } else {
                      this.showWS0247001_OfficeInfoRetrievalQuery()
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name='BranchStoreCode'
                style={{ marginBottom: 0 }}
              >
                <Input.Search
                  type='number'
                  allowClear={true}
                  className='input-search-size-number-allow-clear-4'
                  disabled={!this.formRef.current?.getFieldValue('PersonalNumId')}
                  style={{ textAlign: 'right' }}
                  onPressEnter={() => {
                    this.showWS0247001_OfficeInfoRetrievalQuery()
                  }}
                  onSearch={(value, event) => {
                    if (event.target.localName !== 'input') {
                      this.showWS0247001_OfficeInfoRetrievalQuery()
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name='importance_office'
                style={{ marginBottom: 0 }}
              >
                <div style={{ width: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {this.state.importanceOffice === 1 ?
                    <Tooltip title={this.state.contentOffice}>
                      <InfoCircleOutlined
                        style={{ color: '#1890ff', cursor: 'pointer', fontSize: 24 }}
                        onClick={() => {
                          this.showModalOfficeInfoInquirySub()
                        }}
                      />
                    </Tooltip>
                    :
                    this.state.importanceOffice === 3 ?
                      <Tooltip title={this.state.contentOffice}>
                        <WarningOutlined
                          style={{ color: '#faad14', cursor: 'pointer', fontSize: 24 }}
                          onClick={() => {
                            this.showModalOfficeInfoInquirySub()
                          }} />
                      </Tooltip>
                      :
                      this.state.importanceOffice === 5 ?
                        <Tooltip title={this.state.contentOffice}>
                          <CloseCircleOutlined
                            style={{ color: '#ff4d4f', cursor: 'pointer', fontSize: 24 }}
                            onClick={() => {
                              this.showModalOfficeInfoInquirySub()
                            }} />
                        </Tooltip>
                        :
                        <Tooltip title='事業所情報照会'>
                          <Button
                            icon={<MoreOutlined />}
                            disabled={!this.formRef.current?.getFieldValue('OfficeCode')}
                            onClick={() => {
                              this.showModalOfficeInfoInquirySub()
                            }} />
                        </Tooltip>
                  }
                </div>
              </Form.Item>
              <div style={{ marginTop: 5 }}>
                {this.formRef.current?.getFieldValue('office_kanji_name') ?? ''}
              </div>

              {/* 予約状態の変更処理 開始 */}
              {(this.state.ReserveState === '新規' || this.state.StateFlag === 1) ?
                // 新規、受付の場合
                <div style={{
                  width: '50px',
                  border: '1px solid',
                  borderColor: Color(this.state.ReserveStateColorNum)?.Foreground,
                  textAlign: 'center',
                  color: Color(this.state.ReserveStateColorNum)?.Foreground,
                  marginLeft: 'auto',
                  height: '24px'
                }}
                >
                  {this.state.ReserveState}
                </div>
                :
                (this.state.ReserveState) ?
                  // 予約、保留、キャンセルの場合
                  <Form.Item
                    label='状態'
                    name='reserveSituation'
                    style={{ marginLeft: 'auto', marginBottom: 0 }}
                  >
                    <Select style={{ width: 120 }}>
                      {this.state.reserveSituationList?.map((value, index) => (
                        <Select.Option
                          key={index}
                          value={value.value}
                        >
                          {value.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  :
                  null
              }
              {/* 予約状態の変更処理 終了 */}
            </div>

            <div className='box_container' style={{ position: 'relative' }}>
              <div
                className='box_inner_vertical'
                style={{
                  width: 'fit-content',
                  gap: '2px',
                  position: 'absolute',
                  right: 0
                }}
              >
                <div
                  style={{
                    visibility: this.state.stsMiraisSendError ? 'visible' : 'hidden',
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                >
                  {'※電子カルテに送信エラーがあります'}
                </div>
                <div
                  style={{ marginLeft: 'auto' }}
                >
                  予約番号　　{this.state.ReserveNum == '' ? ''.padStart(7, '　') : this.state.ReserveNum}
                </div>
              </div>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='基本情報' key='1'>
                  <div className='box_inner_horizontal'>
                    <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0, position: 'relative' }}>
                      <Form.Item
                        label='カナ氏名'
                        name='Expression_75'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('Expression_75') !== '' ? this.formRef.current?.getFieldValue('Expression_75') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        label='漢字氏名'
                        name='Expression_76'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('Expression_76') !== '' ? this.formRef.current?.getFieldValue('Expression_76') : '-'}</div>
                      </Form.Item>
                      <div className='box_inner_horizontal'>
                        <div className='box_inner_vertical' style={{ gap: 0 }}>
                          <Form.Item
                            label='性別'
                            name='Expression_78'
                            {...labelStyle}
                            style={{ marginBottom: marginBottomSize }}
                          >
                            <div style={fontStyle}>{this.formRef.current?.getFieldValue('Expression_78') !== '' ? this.formRef.current?.getFieldValue('Expression_78') : '-'}</div>
                          </Form.Item>
                          {(this.state.sex === 1 || this.state.sex === 2) ?
                            <img
                              src={(this.state.sex === 1) ? man : woman}
                              style={{ position: 'absolute', top: '10px', right: '10px', width: '50px' }}
                            />
                            :
                            null
                          }
                          <Form.Item
                            label='生年月日'
                            name='Expression_80'
                            {...labelStyle}
                            style={{ marginBottom: marginBottomSize }}
                          >
                            <div style={fontStyle}>{this.formRef.current?.getFieldValue('Expression_80') ? this.formRef.current?.getFieldValue('Expression_80') : '-'}</div>
                          </Form.Item>
                        </div>
                        <div className='box_inner_vertical' style={{ gap: 0 }}>
                          <Form.Item
                            label='受診日'
                            name='examDayAge'
                            {...labelStyle}
                            style={{ marginBottom: marginBottomSize }}
                          >
                            <div style={fontStyle}>{(this.formRef.current?.getFieldValue('examDayAge') ?? '') ? (this.formRef.current?.getFieldValue('examDayAge') + '歳') : '-'}</div>
                          </Form.Item>
                          <Form.Item
                            label='年度末'
                            name='yearAge'
                            {...labelStyle}
                            style={{ marginBottom: marginBottomSize }}
                          >
                            <div style={fontStyle}>{(this.formRef.current?.getFieldValue('yearAge') ?? '') ? this.formRef.current?.getFieldValue('yearAge') + '歳' : '-'}</div>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className='box_search_inner' style={{ paddingTop: 0 }}>
                      <Form.Item
                        name='insurer_number'
                        label='保険者番号'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_number') ? this.formRef.current?.getFieldValue('insurer_number') : '-'}</div>
                      </Form.Item>
                      <div className='box_inner_horizontal'>
                        <Form.Item
                          name='insurer_card_symbol'
                          label='保険証'
                          {...labelStyle}
                          style={{ marginBottom: marginBottomSize }}
                        >
                          <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_card_symbol') ? this.formRef.current?.getFieldValue('insurer_card_symbol') : '-'}</div>
                        </Form.Item>
                        <Form.Item
                          style={{ display: (this.formRef.current?.getFieldValue('insurer_card_number') ? '' : 'none'), marginBottom: marginBottomSize }}
                        >
                          /
                        </Form.Item>
                        <Form.Item
                          name='insurer_card_number'
                          style={{ display: (this.formRef.current?.getFieldValue('insurer_card_number') ? '' : 'none'), marginBottom: marginBottomSize }}>
                          <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_card_number') ? this.formRef.current?.getFieldValue('insurer_card_number') : '-'}</div>
                        </Form.Item>
                      </div>
                      <Form.Item
                        name='relationship'
                        label='続柄'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>
                          {this.formRef.current?.getFieldValue('relationship') == '0' ? '本人' : this.formRef.current?.getFieldValue('relationship') == '1' ? '配偶者' : this.formRef.current?.getFieldValue('relationship') == '2' ? '家族' : '-'}
                        </div>
                      </Form.Item>
                      <Form.Item
                        name='postal_code'
                        label='〒'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>
                          {this.formRef.current?.getFieldValue('postal_code') ? this.formRef.current?.getFieldValue('postal_code') : '-'}
                        </div>
                      </Form.Item>
                      <Form.Item
                        name='address_1'
                        label='住所'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div className='box_inner_horizontal'>
                          <div style={fontStyle}>{this.formRef.current?.getFieldValue('address_1') ? this.formRef.current?.getFieldValue('address_1') : '-'}</div>
                          <div style={fontStyle}>{this.formRef.current?.getFieldValue('address_2') ? this.formRef.current?.getFieldValue('address_2') : ''}</div>
                        </div>
                      </Form.Item>
                      <Form.Item
                        label='電話'
                        name='phone_number'
                        {...labelStyle}
                        style={{ marginBottom: 0 }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('phone_number') ? this.formRef.current?.getFieldValue('phone_number') : '-'}</div>
                      </Form.Item>
                      {/* <Form.Item
                        name='address_2'
                        label=' '
                        {...labelStyle}
                        style={{ display: (this.formRef.current?.getFieldValue('address_2') ? '' : 'none'), marginBottom: 0 }}>
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('address_2') ? this.formRef.current?.getFieldValue('address_2') : '-'}</div>
                      </Form.Item> */}

                    </div>
                  </div>
                </TabPane>
                <TabPane tab='事業所情報' key='2'>
                  <div className='box_inner_horizontal'>
                    <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0 }}>
                      <Form.Item
                        label='漢字名称'
                        name='office_kanji_name'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('office_kanji_name') !== '' ? this.formRef.current?.getFieldValue('office_kanji_name') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='office_kana_name'
                        label='カナ名称'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('office_kana_name') !== '' ? this.formRef.current?.getFieldValue('office_kana_name') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='industrial_classification'
                        label='産業分類'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('industrial_classification') !== '' ? this.formRef.current?.getFieldValue('industrial_classification') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='industrial_doctor_name'
                        label='産業医'
                        {...labelStyle}
                        style={{ marginBottom: 0 }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('industrial_doctor_name') !== '' ? this.formRef.current?.getFieldValue('industrial_doctor_name') : '-'}</div>
                      </Form.Item>
                    </div>
                    <div className='box_search_inner' style={{ paddingTop: 0 }}>
                      <div className='box_inner_horizontal'>
                        <Form.Item
                          name='office_postal_code'
                          label='〒'
                          style={{ width: '100%', marginBottom: marginBottomSize }}
                          {...labelStyle}
                        >
                          <div style={{ width: 120, ...fontStyle }}>
                            {this.formRef.current?.getFieldValue('office_postal_code') !== '' ? this.formRef.current?.getFieldValue('office_postal_code') : '-'}
                          </div>
                        </Form.Item>
                      </div>
                      <Form.Item
                        name='address_1'
                        label='所在地'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('office_address_1') !== '' ? this.formRef.current?.getFieldValue('office_address_1') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='address_2'
                        label=' '
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('office_address_2') !== '' ? this.formRef.current?.getFieldValue('office_address_2') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='phone_number'
                        label='電話'
                        {...labelStyle}
                        style={{ marginBottom: 0 }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('office_phone_number') !== '' ? this.formRef.current?.getFieldValue('office_phone_number') : '-'}</div>
                      </Form.Item>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='保険者情報' key='3'>
                  <div className='box_inner_horizontal'>
                    <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0 }}>
                      {/* BackLog(ALPHASALUS-8) 対応で続柄を一番上に表示 */}
                      <Form.Item
                        name='relationship'
                        label='続柄'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>
                          {this.formRef.current?.getFieldValue('relationship') == 0 ? '本人' : this.formRef.current?.getFieldValue('relationship') == 1 ? '配偶者' : this.formRef.current?.getFieldValue('relationship') == 2 ? '家族' : '-'}
                        </div>
                      </Form.Item>
                      <Form.Item
                        name='insurer_number'
                        label='保険者番号'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_number') !== '' ? this.formRef.current?.getFieldValue('insurer_number') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='insurer_kanji_name'
                        label='漢字名称'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_kanji_name') !== '' ? this.formRef.current?.getFieldValue('insurer_kanji_name') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='insurer_kana_name'
                        label='カナ名称'
                        {...labelStyle}
                        style={{ marginBottom: 0 }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_kana_name') !== '' ? this.formRef.current?.getFieldValue('insurer_kana_name') : '-'}</div>
                      </Form.Item>
                    </div>
                    <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0 }}>
                      <div className='box_inner_horizontal'>
                        <Form.Item
                          name='insurer_card_symbol'
                          label='保険証'
                          {...labelStyle}
                          style={{ marginBottom: marginBottomSize }}
                        >
                          <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_card_symbol') !== '' ? this.formRef.current?.getFieldValue('insurer_card_symbol') : '-'}</div>
                        </Form.Item>
                        <Form.Item
                          style={{ display: (this.formRef.current?.getFieldValue('insurer_card_number') ? '' : 'none'), marginBottom: marginBottomSize }}>
                          /
                        </Form.Item>
                        <Form.Item
                          name='insurer_card_number'
                          style={{ display: (this.formRef.current?.getFieldValue('insurer_card_number') ? '' : 'none'), marginBottom: marginBottomSize }}>
                          <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_card_number') !== '' ? this.formRef.current?.getFieldValue('insurer_card_number') : '-'}</div>
                        </Form.Item>
                      </div>
                      <Form.Item
                        name='insurer_start_date_on'
                        label='資格取得日'
                        {...labelStyle}
                        style={{ marginBottom: marginBottomSize }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_start_date_on') !== '' ? this.formRef.current?.getFieldValue('insurer_start_date_on') : '-'}</div>
                      </Form.Item>
                      <Form.Item
                        name='insurer_end_date_on'
                        label='有効期限'
                        {...labelStyle}
                        style={{ marginBottom: 0 }}
                      >
                        <div style={fontStyle}>{this.formRef.current?.getFieldValue('insurer_end_date_on') !== '' ? moment(this.formRef.current?.getFieldValue('insurer_end_date_on')).format("YYYY/MM/DD (ddd)") : '-'}</div>
                      </Form.Item>
                    </div>
                  </div>
                </TabPane>

                <TabPane tab='補足' key='4'>
                  <Table
                    bordered
                    size='small'
                    dataSource={this.state.dataSourceSupplements}
                    pagination={false}
                    rowKey={(record) => record.id}
                    scroll={{ y: 140 }}
                  >
                    <Table.Column
                      title='項目'
                      dataIndex='item'
                      width={250}
                    />
                    <Table.Column
                      title='内容'
                      dataIndex='content'
                      className='supplements-content'
                      render={(value, record, index) => (
                        <Form.Item name={['tableSupplements', index, 'content']} >
                          {(record.StsDirectInput && (record.contents ?? false)) ?
                            // 選択項目
                            <Select
                              onChange={(event) => {
                                this.onChangeSupplement(event, record, index)
                              }}
                            >
                              {record.contents.map((item, index) => (
                                <Select.Option
                                  key={item.label + index}
                                  value={item.value}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                            :
                            // テキスト入力
                            <Input
                              maxLength={record?.condition_2 || 50}
                              onChange={(event) => {
                                this.onChangeSupplement(event.target.value, record, index)
                              }}
                            />
                          }
                        </Form.Item>
                      )}
                    />
                  </Table>
                </TabPane>
              </Tabs>

              <div className='box_border'>
                <div className='box_inner_horizontal'>
                  <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0 }}  >
                    <div className='box_inner_horizontal'>
                      <Tooltip
                        title={this.state.unchangeableSerialNumberList.length > 0 ? '請求済みのため変更できません' : ''}
                      >
                        <Form.Item
                          label='コース'
                          name='ConsultCourse'
                          {...labelStyle}
                        >
                          <Input.Search
                            allowClear={true}
                            className='input-search-size-4'
                            disabled={!this.formRef.current?.getFieldValue('OfficeCode') || this.state.unchangeableSerialNumberList.length > 0}
                            onPressEnter={() => {

                              const params = {
                                Li_EffectiveLimitedDisplay: 1,
                                Li_Relationship: this.formRef.current?.getFieldValue('relationship') ?? this.state.dataScreen.relationship,
                                Lio_ConsultCourse: this.formRef.current?.getFieldValue('ConsultCourse'),
                                Li_OfficeCode: this.formRef.current?.getFieldValue('OfficeCode'),
                                Li_BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') || 0,
                                Li_Date: this.formRef.current?.getFieldValue('DateChar') ? moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD') : '',
                                Li_Other: this.state.dataScreen.OtherCondition,
                                Li_Gender: this.state.sex,
                                Li_DateBirth: this.formRef.current?.getFieldValue('Expression_80'),
                                Li_HospitalOut: this.formRef.current?.getFieldValue('FacilityNumHospital_Out'),
                                Li_NTsugikenmi: this.formRef.current?.getFieldValue('NClassify'),
                                Li_Am_Pm: this.state.dataScreen.Am_PmDivision,
                                Lio_ContractType: this.state.dataScreen.ContractType,
                                Lio_ContractOrgCode: this.state.dataScreen.ContractClassifyCode,
                                Lio_ContractStartDate: this.state.dataScreen.ContractDate,
                                Lio_ContractNum: this.state.dataScreen.ContractNum,
                                Lo_Status: '',
                                Lo_ErrorMessage: ''
                              }


                              ContractInfoInquiryAction.index(params).then((res) => {

                                if (res.SUB.length === 1) {
                                  console.log('ContractInfoInquiryAction:index', res.SUB[0])

                                  this.setFieldConsultCourse(res.SUB[0])

                                } else {
                                  this.showWS0289012_ContractInfoInquiry()
                                }

                                this.setState({
                                  changeConsultCourse: true
                                })


                              }).catch((err) => {
                                console.log('ContractInfoInquiryAction:index', err)
                              })


                            }}
                            onSearch={(value, event) => {
                              if (event.target.localName === 'input') {
                                this.formRef.current?.setFieldsValue({
                                  ConsultCourse: ''
                                })
                              } else {
                                this.showWS0289012_ContractInfoInquiry()
                                this.setState({
                                  changeConsultCourse: true
                                })
                              }
                            }}

                            onFocus={() => {
                              this.setState({
                                changeConsultCourse: false
                              })
                            }}
                          />
                        </Form.Item>
                      </Tooltip>
                      <Tooltip title='契約情報照会'>
                        <Button
                          disabled={!this.formRef.current?.getFieldValue('Expression_118')}
                          icon={<MoreOutlined />}
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: '90%',
                                component: (
                                  <WS0605127_ContractLineItemDisplay
                                    Li_ContractType={this.state.dataScreen.ContractType}
                                    Li_ContractOrgCode={this.state.dataScreen.ContractClassifyCode}
                                    Li_ContractStartDate={this.state.dataScreen.ContractDate}
                                    Li_ContractNum={this.state.dataScreen.ContractNum}
                                    onFinishScreen={(output) => {
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            })
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className='box_inner_horizontal' style={{ minWidth: '350px' }}>
                    <Form.Item
                      name='total_amount'
                      label='合計金額'
                      {...labelStyle}>
                      <div style={fontStyle}>
                        {this.state.discount ? (this.state.totalAmount - this.state.discount).toLocaleString() : this.state.totalAmount.toLocaleString()}
                      </div>
                    </Form.Item>
                    <Form.Item
                      name='counter_total_amount'
                      label='窓口合計金額'
                      {...labelStyle}>
                      <div style={fontStyle}>
                        {this.formRef.current?.getFieldValue('counter_total_amount')}
                      </div>
                    </Form.Item>
                  </div>
                  <div className='box_inner_horizontal' style={{ marginLeft: 'auto' }}>
                    <Button
                      type='primary'
                      onClick={() => this.showWS2537500_PersonalReserveOption()}
                      style={{ marginLeft: 'auto' }}
                      disabled={!this.formRef.current?.getFieldValue('ConsultCourse')}
                    >
                      オプション変更
                    </Button>
                    <Button
                      type='primary'
                      disabled={!this.formRef.current?.getFieldValue('ConsultCourse')}
                      onClick={() => {
                        this.setState({
                          nameScreen: 'WS0309503_BillingAddressAdjust',
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS0309503_BillingAddressAdjust
                                reservation_number={this.state.dataScreen.ReserveNum}
                                course_level={this.state.dataScreen.course_level}
                                dataSourceInspectChange={this.state.dataSourceInspectChange}
                                columnList={this.state.columnList}
                                dataList={this.state.dataList}
                                taxRate={this.state.taxRate}
                                rounding={this.state.rounding}
                                totalAmount={this.formRef.current?.getFieldValue('total_amount')}
                                contractTermData={{
                                  contract_type: this.state.dataScreen.ContractType,
                                  ContractId: this.state.dataScreen.ContractId,
                                  contract_office_code: this.state.dataScreen.ContractClassifyCode,
                                  contract_start_date_on: this.state.dataScreen.ContractDate,
                                  contract_number: this.state.dataScreen.ContractNum,
                                  medical_exam_course: this.state.dataScreen.ConsultCourse
                                }}
                                unchangeableSerialNumberList={this.state.unchangeableSerialNumberList}
                                onFinishScreen={(output) => {
                                  let newTotalAmount = 0
                                  let new_counter_total_amount = 0
                                  output.newDataList.map((value, index) => {
                                    if (value.data_division == 30 || value.data_division == 60) {
                                      newTotalAmount -= value.organization_total_price
                                    } else {
                                      newTotalAmount += value.organization_total_price
                                    }
                                    if (value.serial_number === 4 || value.serial_number === 5 || value.serial_number === 6) {
                                      if (value.data_division == 30 || value.data_division == 60) {
                                        new_counter_total_amount -= value.organization_total_price
                                      } else {
                                        new_counter_total_amount += value.organization_total_price
                                      }
                                    }
                                  })
                                  this.formRef.current?.setFieldsValue({
                                    dataList: output.newDataList,
                                    total_amount: output.newTotalAmount,
                                    counter_total_amount: new_counter_total_amount.toLocaleString()
                                  })
                                  this.setState({
                                    dataList: output.newDataList,
                                    totalAmount: output.newTotalAmount
                                  })
                                  this.closeModal()
                                  if (output.addFlag) {
                                    let params = {
                                      Li_CourseLevel: this.state.dataScreen.course_level,
                                      ReserveNum: this.state.dataScreen.ReserveNum,
                                      columnList: output.newColumnList,
                                      dataList: output.newDataList
                                    }
                                    this.billingAddressSave(params)
                                  }
                                }}
                              />
                            ),
                          },
                        });
                      }}
                    >
                      請求先調整
                    </Button>
                  </div>
                </div>
                <Table
                  size='small'
                  //協会けんぽ調整用配列を結合
                  dataSource={[...this.state.dataSourceInspectChange, ...this.state.discountNameList]}
                  rowKey={(record) => record.id}
                  bordered
                  scroll={{ x: 'max-content', y: 145 }}
                  pagination={this.state.dataSourceInspectChange.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  onRow={() => {
                    return {
                      onDoubleClick: () => { this.showWS2537500_PersonalReserveOption() }
                    }
                  }}
                >
                  <Table.Column
                    title=''
                    dataIndex='changeType'
                    className='column-size-2'
                    render={(value, record, index) => {
                      return <div style={{ textAlign: 'center' }}>
                        {record.changeType ?
                          <img style={{ width: 16 }} src={GetImage(record.changeType)} alt='icon' />
                          : ''
                        }
                      </div>
                    }} />
                  <Table.Column
                    title='名称'
                    dataIndex='Expression_17'
                    className='column-size-40'
                  />
                  {
                    this.state.columnList.map((value, index) => {
                      return (
                        <Table.Column
                          title={() => {
                            return (
                              <Tooltip title={value.full_name}>
                                <div>{value.kanji_name.substr(0, 7)}</div>
                              </Tooltip>
                            )
                          }}
                          key={'tableColumn_' + index}
                          width='140px'
                          render={(item, record, index) => {
                            let total_price = 0
                            let data_division = record.data_division > 0 ? record.data_division : record.changeType

                            //協会けんぽ調整用配列を結合
                            let neoDataList = [...this.state.dataList, ...this.state.discountList]
                            return (
                              <div
                                style={{ textAlign: 'right' }}
                              >
                                {neoDataList.map((element, index) => {
                                  if (element.data_division == data_division && element.set_code == record.set_code && element.serial_number == value.serial_number) {
                                    if (element.data_division == 30 || element.data_division == 60 || element.data_division == 90) {
                                      total_price = element.organization_total_price == 0 ? 0 : element.organization_total_price * (-1)
                                      total_price = total_price.toLocaleString()
                                    } else {
                                      total_price = element.organization_total_price
                                      total_price = total_price.toLocaleString()
                                    }
                                  }
                                })}
                                {total_price}
                              </div>
                            )
                          }}
                        />
                      )
                    })
                  }
                </Table>
              </div>

              <div className='box_border' style={{ flexDirection: 'row' }}>
                <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0 }}  >
                  <div className='box_inner_horizontal'>
                    <Tooltip
                      title={this.state.unchangeableSerialNumberList.length > 0 ? '請求済みのため変更できません' : ''}
                    >
                      <Form.Item
                        label='受診日'
                        name='DateChar'
                        {...labelStyle}
                      >
                        <Input.Search
                          className='input-size-20'
                          disabled={this.state.unchangeableSerialNumberList.length > 0}
                          onSearch={(e) => {
                            this.showWS2577003_CalendarEmptySub()
                          }}
                        />
                      </Form.Item>
                    </Tooltip>
                    <Form.Item hidden={this.formRef.current?.getFieldValue('AcceptNum') === 0} style={{ marginBottom: 10, marginRight: '5px' }} name='AcceptNum' label='受付No'>
                      <div>{this.formRef.current?.getFieldValue('AcceptNum') !== '' ? this.formRef.current?.getFieldValue('AcceptNum') : '-'}</div>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 10, marginRight: '5px' }} name='Expression_131' >
                      <div hidden={this.state.dataScreen.AcceptNum === 0}></div>
                    </Form.Item>
                  </div>

                  <div className='box_inner_horizontal'>
                    {/* <Space> */}
                    <Form.Item
                      label='時間帯'
                      name='TimeZone'
                      {...labelStyle}
                      style={{ minWidth: 200 }}
                      initialValue={''}
                    >
                      <Input.Search
                        className='input-search-size-4'
                        onSearch={(e) => {
                          // 時間帯設定　モーダル
                          this.showReserveAvailability()
                        }}
                      />
                    </Form.Item>
                    <div style={{ minWidth: 300, maxWidth: 750, marginTop: 5 }}>
                      <Tooltip
                        overlayStyle={{ whiteSpace: 'pre-line' }}
                        title={this.state.selectedExamIdTimesTooltip}>
                        <div style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: 'red'
                        }}>
                          {this.state.selectedExamIdTimesStr}
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <Form.Item
                    name='FacilityNumHospital_Out'
                    label='施設'
                    {...labelStyle}
                  >
                    <Select style={{ width: '200px' }}>
                      {this.state.facilityList.map((value) => {
                        return (
                          <Select.Option
                            key={value.id}
                            value={value.facility_type}
                          >
                            {value.facility_name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name='user'
                    label='担当'
                    {...labelStyle}
                    style={{ marginBottom: 0 }}
                  >
                    <Select style={{ width: '200px' }}>
                      {this.state.userList.map((value) => {
                        return (
                          <Select.Option
                            key={value.id}
                            value={value.username}
                          >
                            {value.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>

                </div>
                <div className='box_search_inner' style={{ minWidth: minWidth, paddingTop: 0 }}  >
                  <Form.Item
                    label='特記事項'
                    name='personalSpecial'
                    {...labelStyle}
                  >
                    <TextArea
                      style={{ height: 64 }}
                      onChange={() => {
                        this.setState({
                          isChangeForm: true
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label='備考'
                    name='Remarks'
                    style={{ marginBottom: 0 }}
                    {...labelStyle}
                  >
                    <TextArea
                      style={{ height: 64 }}
                      onChange={() => {
                        this.setState({
                          isChangeForm: true
                        })
                      }} />
                  </Form.Item>
                </div>
              </div>

            </div>
          </Form >
          <div className='box_button_bottom_right'>

            <Button
              type='primary'
              disabled={this.state.ReserveState == '受付' ? (this.formRef.current?.getFieldValue('payment') == false ? true : false) : true}
              onClick={async () => {
                let Li_ReserveNum = this.state.ReserveNum
                if (this.formRef.current?.getFieldValue('payment') == 1) {
                  this.displayWS2622004ReceiptProcessSubBulk()
                } else {
                  await this.automaticdeposit(Li_ReserveNum)
                  await this.displayWS2622004ReceiptProcessSubBulk()
                }

              }}
            >
              領収書
            </Button>

            <Button
              type='primary'
              disabled={this.formRef.current?.getFieldValue('OfficeCode') ? false : true}
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 'fit-content',
                    component: (
                      <CorporateHearingSheet
                        Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                        Lio_branch_store_code={this.formRef.current?.getFieldValue('BranchStoreCode') ?? 0}
                        Lio_insurer_code={this.formRef.current?.getFieldValue('insurer_code')}
                        Lio_office_kanji_name={this.formRef.current?.getFieldValue('office_kanji_name')}
                        onFinishScreen={() => {
                          this.closeModal()
                        }}
                      />
                    ),
                  }
                })
              }}
            >
              <span className='btn_label'>
                ヒアリングシート
              </span>
            </Button>
            <Button
              type='primary'
              disabled={!this.formRef.current?.getFieldValue('PersonalNumId')}
              className={this.formRef.current?.getFieldValue('memo') ? 'memo-add-btn' : ''}
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: 'fit-content',
                    centered: true,
                    component: (
                      <AllMemosFunction
                        date={moment(this.formRef.current?.getFieldValue('DateChar')).format('YYYY/MM/DD')}
                        reservation_number={this.state.dataScreen.ReserveNum}
                        personal_number_id={this.formRef.current?.getFieldValue('PersonalNumId')}
                        KanjiName={this.formRef.current?.getFieldValue('Expression_76')}
                        onFinishScreen={async () => {
                          await this.getInfoPersonal()
                          if (this.props.onSaveMemos) {
                            this.props.onSaveMemos()
                          }
                          await this.closeModal()
                        }}

                        // メモ全体入力画面での保存ボタンの状態
                        onFlagUpdate={(bool) => {
                          this.setState({
                            AllMemoFunctionUpdateBtn: bool
                          })
                        }}
                      />
                    ),
                  }
                })
              }}
            >
              <span className='btn_label'>
                メモ
              </span>
            </Button>
            <Button
              type='primary'
              disabled={(this.state.ReserveState === '新規' || this.state.ReserveState === '')}
              onClick={() => (
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    centered: true,
                    width: 750,
                    component: (
                      <WS1296010_ConsultTicketInputSub
                        Li_CourseLevel={this.state.dataScreen.course_level}
                        Li_ReserveNum={this.state.dataScreen.ReserveNum}
                        onFinishScreen={(output) => {
                          this.closeModal()
                        }}
                      />
                    ),
                  },
                })
              )}>
              受診券
            </Button>
            <Button
              type='primary'
              disabled={!this.formRef.current?.getFieldValue('PersonalNumId')}
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    component: (
                      <WS0343001_PersonalInfoMaintain
                        Li_PersonalNum={this.formRef.current?.getFieldValue('PersonalNumId')}
                        onFinishScreen={(output) => {
                          console.log('a')
                          this.getInfoPersonal()
                          this.closeModal()
                          // if (output.flg === 1) {
                          //   this.loadData()
                          // }
                        }}
                      />
                    ),
                    width: 1200,
                  },
                })
              }}
            >
              個人情報保守
            </Button>
            <Button
              type='primary'
              disabled={!this.formRef.current?.getFieldValue('ReserveNum')}
              // disabled={!this.props.Li_ReserveNum}
              onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: '40%',
                    component: (
                      <WS0650001_DocumentBatchCreateSub
                        Li_CourseLevel={this.state.dataScreen.course_level}
                        Li_ReserveNum={this.formRef.current?.getFieldValue('ReserveNum')}
                        onFinishScreen={() => {
                          this.closeModal()
                        }}
                      />
                    ),
                  },
                })
              }}
            >
              資料出力
            </Button>

            <Button
              type='primary'
              onClick={() => {
                this.medicalExamContentsInquiry()
              }}
            >
              検査照会
            </Button>

            <Button
              type='primary'
              icon={<SaveOutlined />}
              onClick={() => {
                if (this.props.receipt) {
                  this.acceptButton(this.props.record)
                } else if (this.formRef.current?.getFieldValue('ReserveNum') > 0) {
                  //新規登録の場合
                  this.ConfirmProcessBtn_F12()
                } else {
                  //編集の場合
                  this.checkLatestReserve(this.formRef.current?.getFieldValue('PersonalNumId'))
                }
              }}
            >
              <span className='btn_label'>
                {this.props.Li_ProcessDivision === 1 ? '予約' :
                  (this.formRef.current?.getFieldValue('ReserveNum') && this.formRef.current?.getFieldValue('ReserveNum')) > 0 ? '変更' : '登録'}
              </span>
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.nameScreen === 'WS0289012_ContractInfoInquiry' && this.state.isUnValidConsultCourse) {
              Modal.error({
                title: '受診コースを入力してください',
                width: 350
              })
              this.setState({
                nameScreen: ''
              })
            }

            if (this.state.nameScreen === 'WS0309503_BillingAddressAdjust') {
              this.getBillingAddressList()
              this.setState({
                nameScreen: ''
              })
            }

            if (!this.state.AllMemoFunctionUpdateBtn) {
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  this.setState({
                    AllMemoFunctionUpdateBtn: true
                  })
                  this.closeModal()
                },
                onCancel: () => {
                  console.log('閉じません');
                }
              })
            } else {
              this.closeModal()
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
  user: userReducer.user,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS2537001_PersonalReserveProcess)
