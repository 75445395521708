import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Table, Space, message, Tooltip } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import XmlMedicalExamItemMasterAction from "redux/SpecificInsureMaintenance/DataRangeCheck/XmlMedicalExamItemMaster.action"
//frontend/src/redux/SpecificInsureMaintenance/DataRangeCheck/XmlMedicalExamItemMaster.action.js

import ModalDraggable from "components/Commons/ModalDraggable";

import { PlusOutlined, SaveOutlined, } from '@ant-design/icons';
import { generateUUID } from 'components/Commons/generateUUID'

class WS1313001_XmlMedicalExamItemMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-XML03000:XML用健診項目マスタ';

    this.state = {
      dataSource: [],
      filteredData: [],
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchValue: '',
      rowSelected: [],
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  onFinish(values) {

  }


  loadScreenData = () => {

    XmlMedicalExamItemMasterAction.xmlMedicalExamItemDataInput(this.props.record)
      .then(res => {

        const tmp = res.data.map(item => ({ ...item, changed: false }))
        this.setState({ dataSource: tmp })

      })
      .catch(error => {
        message.error(error.response.data.message);
      }).finally();
  };

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  handleAddRowTable() {

    let template = {}
    const dataSouce = JSON.parse(JSON.stringify(this.state.dataSource))

    if (dataSouce.length > 0) {
      template = dataSouce[0]
    }

    if (!template) return

    const date_of_adoption_history_on = template.date_of_adoption_history_on
    const item_code_jlac10_15 = template.item_code_jlac10_15

    Object.keys(template).forEach(key => {
      template[key] = '';
    })

    const newRow = {
      ...template,
      id: generateUUID(),
      date_of_adoption_history_on: date_of_adoption_history_on,
      item_code_jlac10_15: item_code_jlac10_15,
      changed: false
    };


    const dataSource = [...this.state.dataSource]

    dataSource.length > 0 ? dataSource.unshift(newRow) : dataSource.push(newRow)

    this.setState({ dataSource: dataSource })

    this.forceUpdate();
  }

  createOrUpdateData = (record) => {

    const param = record ?? [...this.state.dataSource].filter(item => item.changed)

    const params = Array.isArray(param) ? param : [param]

    for (let index = 0; index < params.length; index++) {
      if (this.dataExists(params[index])) {
        return;
      }
    }


    if (param.length === 0) {
      message.warning('変更対象はありません')
      return
    }

    XmlMedicalExamItemMasterAction.saveXmlMedicalExamItemDataInput(params)
      .then((res) => {
        message.success(res.data.message);
        this.loadScreenData();
      })
      .catch(err => {
        message.error(err.response.data.message)
      })

  }


  isNumber = (value) => {
    // 数値または小数点付き数値を許容する正規表現
    const decimalPattern = /^-?\d+(\.\d+)?$/;

    return decimalPattern.test(value);
  }

  dataExists = (record) => {

    const data = [...this.state.dataSource]

    const tmp = data.filter(item =>
      item.date_of_adoption_history_on === record.date_of_adoption_history_on
      && item.item_code_jlac10_15 === record.item_code_jlac10_15
      && item.result_identification_jlac10_2 == record.result_identification_jlac10_2)

    // 自身と同一のデータは無視
    if (tmp.length > 1
    ) {
      message.error('既にデータが存在します')
      record.changed = false
      return true
    } else {
      return false
    }
  }


  render() {

    const validation = (record, dataIndex, value) => {

      if (!this.isNumber(value)) {
        message.error('数値を入力して下さい')
        record.changed = true
        record[dataIndex] = ''
        return false
      }

      return true
    };



    return (
      <div className="xml-medical-exam-item-master">
        <Card title="V4-XML03000:XML用健診項目マスタ">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              size="small"
              scroll={{ x: 4000 }}
              bordered
              dataSource={this.state.dataSource}
              rowKey={(record) => record.id}
              pagination={false}
            >

              <Table.Column width={'50px'} align='center'
                title={() => (
                  <>
                    <Space>
                      <Button size='small' type='primary' icon={<PlusOutlined />}
                        onClick={() => {
                          this.handleAddRowTable()
                        }} />
                      <Tooltip title="一括保存">
                        <Button size='small' style={{ border: 'none', }}
                          icon={<SaveOutlined style={{ color: 'green' }} />}
                          onClick={() => {
                            this.createOrUpdateData()
                          }} />
                      </Tooltip>
                    </Space>
                  </>
                )}
                render={(text, record, index) => (

                  <Button size='small' style={{ border: 'none', }}
                    icon={<SaveOutlined style={{ color: 'green' }} />}
                    onClick={() => {
                      this.createOrUpdateData(record)
                    }} />
                )}
              />
              <Table.Column title="採用日" dataIndex="date_of_adoption_history_on" showSorterTooltip={false} sorter={(a, b) => a.DateAdoptionChars?.localeCompare(b.DateAdoptionChars, 'ja')} />
              <Table.Column title="項目コード" dataIndex="item_code_jlac10_15" showSorterTooltip={false} sorter={(a, b) => a.item_code_jlac10_15?.localeCompare(b.item_code_jlac10_15, 'ja')} />

              <Table.Column
                width={'50px'}
                title="結果識別"
                dataIndex="result_identification_jlac10_2"
                showSorterTooltip={false}
                sorter={(a, b) => a.result_identification_jlac10_2?.localeCompare(b.result_identification_jlac10_2, 'ja')}
                render={(text, record, index) => (
                  <Input
                    style={{ textAlign: "center" }}
                    defaultValue={text}
                    onBlur={(e) => {
                      if (validation(record, 'result_identification_jlac10_2', e.target.value)) {
                        record.changed = true
                        record.result_identification_jlac10_2 = e.target.value
                      }

                    }}
                    onPressEnter={(e) => {
                      if (validation(record, 'result_identification_jlac10_2', e.target.value)) {
                        record.changed = true
                        record.result_identification_jlac10_2 = e.target.value
                      }
                    }}
                  />
                )}
              />
              <Table.Column
                width={'50px'}
                title="区分番号"
                dataIndex="division_number"
                showSorterTooltip={false}
                sorter={(a, b) => a.division_number - b.division_number}
                render={(text, record, index) => (
                  <Input
                    style={{ textAlign: "center" }}
                    defaultValue={text}
                    onBlur={(e) => {
                      if (e.target.value === '') {
                        return
                      }
                      if (validation(record, 'division_number', e.target.value)) {
                        record.changed = true
                        record.division_number = e.target.value
                      }

                    }}
                    onPressEnter={(e) => {
                      if (e.target.value === '') {
                        return
                      }

                      if (validation(record, 'division_number', e.target.value)) {
                        record.changed = true
                        record.division_number = e.target.value
                      }
                    }}
                  />
                )}
              />
              <Table.Column
                width={'50px'}
                title="順番号"
                dataIndex="order_number"
                showSorterTooltip={false}
                sorter={(a, b) => a.order_number - b.order_number}
                render={(text, record, index) => (
                  <Input
                    style={{ textAlign: "right" }}
                    defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.order_number = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.order_number = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                width={'100px'}
                title="条件コード"
                dataIndex="condition_code_string_12"
                showSorterTooltip={false}
                sorter={(a, b) => a.condition_code_string_12?.localeCompare(b.condition_code_string_12, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.condition_code_string_12 = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.condition_code_string_12 = e.target.value
                    }}

                  />
                )}
              />
              <Table.Column
                title="厚労省項目名"
                width="15%"
                dataIndex="ministry_item_name"
                showSorterTooltip={false}
                sorter={(a, b) => a.ministry_item_name?.localeCompare(b.ministry_item_name, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.ministry_item_name = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.ministry_item_name = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="ＸＭＬ表示名"
                dataIndex="xml_display_name"
                showSorterTooltip={false}
                sorter={(a, b) => a.xml_display_name?.localeCompare(b.xml_display_name, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.xml_display_name = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.xml_display_name = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="データタイプ"
                dataIndex="data_type"
                showSorterTooltip={false}
                sorter={(a, b) => a.data_type?.localeCompare(b.data_type, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.data_type = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.data_type = e.target.value
                    }}
                  />

                )}

              />
              <Table.Column
                title="XMLパターン"
                dataIndex="xml_pattern"
                showSorterTooltip={false}
                sorter={(a, b) => a.xml_pattern?.localeCompare(b.xml_pattern, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.xml_pattern = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.xml_pattern = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="最大バイトＯＲフォーマット"
                dataIndex="max_bytes_or_format"
                showSorterTooltip={false}
                sorter={(a, b) => a.max_bytes_or_format?.localeCompare(b.max_bytes_or_format, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.max_bytes_or_format = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.max_bytes_or_format = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="XMLデータ型"
                dataIndex="xml_data_type"
                showSorterTooltip={false}
                sorter={(a, b) => a.xml_data_type?.localeCompare(b.xml_data_type, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.xml_data_type = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.xml_data_type = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="表示単位"
                dataIndex="display_units"
                showSorterTooltip={false}
                sorter={(a, b) => a.display_units?.localeCompare(b.display_units, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.display_units = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.display_units = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="UCUM単位コード"
                dataIndex="ucum_unit_code"
                showSorterTooltip={false}
                sorter={(a, b) => a.ucum_unit_code?.localeCompare(b.ucum_unit_code, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.ucum_unit_code = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.ucum_unit_code = e.target.value
                    }}

                  />
                )}
              />
              <Table.Column
                title="observation要素"
                dataIndex="observation_element"
                showSorterTooltip={false}
                sorter={(a, b) => a.observation_element - b.observation_element}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.observation_element = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.observation_element = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="auther要素"
                dataIndex="auther_element"
                showSorterTooltip={false}
                sorter={(a, b) => a.auther_element - b.auther_element}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.auther_element = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.auther_element = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="auther要素項目番号"
                dataIndex="auther_element_item_number"
                showSorterTooltip={false} sorter={(a, b) => a.auther_element_item_number?.localeCompare(b.auther_element_item_number, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.auther_element_item_number = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.auther_element_item_number = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="一連検査グループ識別"
                dataIndex="series_exam_group_identification"
                showSorterTooltip={false}
                sorter={(a, b) => a.series_exam_group_identification?.localeCompare(b.series_exam_group_identification, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.series_exam_group_identification = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.series_exam_group_identification = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="一連検査グループ関係コード"
                dataIndex="series_exam_group_relationship_code"
                showSorterTooltip={false} sorter={(a, b) => a.series_exam_group_relationship_code?.localeCompare(b.series_exam_group_relationship_code, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.series_exam_group_relationship_code = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.series_exam_group_relationship_code = e.target.value
                    }}

                  />
                )}
              />
              <Table.Column
                title="同一性項目コード"
                dataIndex="identity_item_code"
                showSorterTooltip={false}
                sorter={(a, b) => a.identity_item_code?.localeCompare(b.identity_item_code, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.identity_item_code = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.identity_item_code = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="同一性項目名称"
                dataIndex="identity_item_name"
                showSorterTooltip={false}
                sorter={(a, b) => a.identity_item_name?.localeCompare(b.identity_item_name, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.identity_item_name = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.identity_item_name = e.target.value
                    }}

                  />
                )}
              />
              <Table.Column
                title="XML検査方法コード"
                dataIndex="xml_exam_method_code"
                showSorterTooltip={false} sorter={(a, b) => a.xml_exam_method_code?.localeCompare(b.xml_exam_method_code, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.xml_exam_method_code = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.xml_exam_method_code = e.target.value
                    }}

                  />
                )}
              />
              <Table.Column
                title="検査方法"
                dataIndex="exam_methods"
                showSorterTooltip={false}
                sorter={(a, b) => a.exam_methods?.localeCompare(b.exam_methods, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.exam_methods = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.exam_methods = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="結果コードＯＩＤ"
                dataIndex="result_code_oid"
                showSorterTooltip={false}
                sorter={(a, b) => a.result_code_oid?.localeCompare(b.result_code_oid, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.result_code_oid = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.result_code_oid = e.target.value
                    }}
                  />
                )}
              />
              <Table.Column
                title="項目コードＯＩＤ"
                dataIndex="item_code_oid"
                showSorterTooltip={false}
                sorter={(a, b) => a.item_code_oid?.localeCompare(b.item_code_oid, 'ja')}
                render={(text, record, index) => (
                  <Input defaultValue={text}
                    onBlur={(e) => {
                      record.changed = true
                      record.item_code_oid = e.target.value

                    }}
                    onPressEnter={(e) => {
                      record.changed = true
                      record.item_code_oid = e.target.value
                    }}

                  />
                )}
              />

            </Table>
          </Form>
        </Card>
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313001_XmlMedicalExamItemMaster);
