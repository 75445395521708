import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Select, Button, Table, message, Dropdown, Menu, Modal, TimePicker, Popconfirm } from "antd";
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import { DeleteOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import man_img from 'assets/img/性別-男性.png';
import woman_img from 'assets/img/性別-女性.png';
import moment from "moment";
import WS2653079_HealthCheckupInfoSelect from "./WS2653079_HealthCheckupInfoSelect";
import GuideInputProvenSubAction from "redux/SpecificInsureGuide/InsureGuideInitInput/GuideInputProvenSub.action";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS2654025_AddInspect from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2654025_AddInspect.jsx";
import WS2654013_ContentInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2654013_ContentInput.jsx";
import WS2654016_TargetivesResultInput from "pages/TH_SpecificInsureGuide/V5TH0010100_InsureGuideInitInput/WS2654016_TargetivesResultInput";
import WS1382001_ImplementorInquiry from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1382001_ImplementorInquiry";
import WS1388001_ImplementLocationQuery from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1388001_ImplementLocationQuery";

const style = {
  marginBottom: 0,
}

const smGrid = {
  labelCol: { style: { width: 90 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 90px)' } }
}


const format = 'HH:mm'

class WS2653001_GuideInputProvenSub extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '指導入力[実績]SUB';

    this.state = {
      isLoading: false,
      DisplayList: [],
      rowSelect: {},
      supportCodeList: [],
      supportCode: '',
      supportUnitInfoUnitDivision: '',
      EvaluationDivisionScreen: '',
      point: '',
      pagination: {
        defaultPageSize: 4,
        size: 1,
        showQuickJumper: true
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
    this.carriedOutInspectInquiry();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
      this.carriedOutInspectInquiry();
    }
  }

  getScreenData = async () => {
    this.setState({ isLoading: true, rowSelect: {} });
    let params = {
      Li_Date: this.props.Li_Date,
      Li_PersonalNum: this.props.Li_PersonalNum,
      Li_SerialNum: this.props.Li_SerialNum,
    }

    GuideInputProvenSubAction.getScreenData(params)
      .then((res) => {
        res.TimeZonestartTime = moment(res.TimeZonestartTime, format)
        res.TimeZoneendTime = moment(res.TimeZoneendTime, format)
        let supportCode, EvaluationDivisionScreen
        for (let i = 0; i < res.SupportCode_List.length; i++) {
          if (res.SupportCode === res.SupportCode_List[i].support_code) {
            supportCode = res.SupportCode_List[i].support
          }
        }
        switch (res.EvaluationDivisionScreen) {
          case '0':
            EvaluationDivisionScreen = '初回①'
            break;
          case '2':
            EvaluationDivisionScreen = '初期面談'
            break;
          case '3':
            EvaluationDivisionScreen = '中間評価'
            break;
          case '4':
            EvaluationDivisionScreen = '継続中'
            break;
          case '8':
            EvaluationDivisionScreen = '中断'
            break;
          case '9':
            EvaluationDivisionScreen = '終了評価'
            break;
        }
        this.setState({ dataSource: res });
        this.formRef.current.setFieldsValue(res);
        this.forceUpdate();
        this.setState({
          supportCodeList: res.SupportCode_List,
          supportCode: supportCode,
          EvaluationDivisionScreen: EvaluationDivisionScreen,
          supportUnitInfoUnitDivision: res.SupportUnitInfoUnitDivision,
          point: res.Point
        });
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
  }

  carriedOutInspectInquiry = () => {
    this.setState({ isLoading: true, rowSelect: {} });

    let params = {
      Li_Date: this.props.Li_Date,
      Li_Id: this.props.Li_PersonalNum,
      Li_SerialNum: this.props.Li_SerialNum,
    }

    GuideInputProvenSubAction.carriedOutInspectInquiry(params)
      .then((res) => {
        this.setState({ DisplayList: res });
        if (res[0]) {
          this.setState({
            rowSelect: res[0],
          });
        }
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  handleUpdate = () => {
    let SupportCodeOriginal = this.formRef.current?.getFieldValue("SupportCode")
    let EvaluationDivisionScreenOriginal = this.formRef.current?.getFieldValue("EvaluationDivisionScreen")
    let TimeZonestartTime
    let TimeZoneendTime

    Modal.confirm({
      content: 'この内容で登録しますか',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        this.setState({ isLoading: true });

        if (this.formRef.current?.getFieldValue("TimeZonestartTime")) {
          TimeZonestartTime = this.formRef.current?.getFieldValue("TimeZonestartTime").format(format)
        }
        if (this.formRef.current?.getFieldValue("TimeZoneendTime")) {
          TimeZoneendTime = this.formRef.current?.getFieldValue("TimeZoneendTime").format(format)
        }

        let params = {
          ConfirmStatus: true,
          Li_Date: this.props.Li_Date,
          Li_PersonalNum: this.props.Li_PersonalNum,
          Li_SerialNum: this.props.Li_SerialNum,
          ImplementDate: this.formRef.current?.getFieldValue("ImplementDateScreen"),
          SupportCode: this.formRef.current?.getFieldValue("SupportCode"),
          SupportAmount: this.formRef.current?.getFieldValue("SupportAmount"),
          EvaluationDivisionScreen: this.formRef.current?.getFieldValue("EvaluationDivisionScreen"),
          TimeZonestartTime: TimeZonestartTime ?? null,
          TimeZoneendTime: TimeZoneendTime ?? null,
          PractitionerCode: this.formRef.current?.getFieldValue("PractitionerCode"),
          ImplementLocationCode: this.formRef.current?.getFieldValue("ImplementLocationCode"),
        }

        GuideInputProvenSubAction.Update(params)
          .then((res) => {
            this.carriedOutInspectInquiry();
            if (this.props.onUpdate) {
              this.props.onUpdate()
            }
          })
          .catch((error) => {
            const res = error.response;
            if (!res || !res.data || !res.data.message) {
              message.error("エラーが発生しました");
              return;
            }
            message.error(res.data.message);
          })
      }
    });
  }

  handleDelete = () => {
    Modal.confirm({
      content: '削除してもよろしいですか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        let params = {
          Li_Date: this.props.Li_Date,
          Li_PersonalNum: this.props.Li_PersonalNum,
          Li_SerialNum: this.props.Li_SerialNum,
          ImplementDate: this.formRef.current?.getFieldValue("ImplementDateScreen"),
          SupportCode: this.formRef.current?.getFieldValue("SupportCode"),
          SupportAmount: this.formRef.current?.getFieldValue("SupportAmount"),
          EvaluationDivisionScreen: this.formRef.current?.getFieldValue("EvaluationDivisionScreen"),
          TimeZonestartTime: this.formRef.current?.getFieldValue("TimeZonestartTime").format(format),
          TimeZoneendTime: this.formRef.current?.getFieldValue("TimeZoneendTime").format(format),
          PractitionerCode: this.formRef.current?.getFieldValue("PractitionerCode"),
          ImplementLocationCode: this.formRef.current?.getFieldValue("ImplementLocationCode"),
          StsImplement: true,
          ConfirmStatus: true,
        }
        GuideInputProvenSubAction.delete_f3(params)
          .then((res) => {
            if (this.props.onUpdate) {
              this.props.onUpdate()
            } else {
              this.getScreenData();
              this.carriedOutInspectInquiry();
            }
          }).catch((error) => {
            const res = error.response;
            if (!res || !res.data || !res.data.message) {
              message.error("エラーが発生しました");
              return;
            }
            message.error(res.data.message);
          }).finally(() => this.setState({ isLoading: false }))
      }
    });
  }

  createNew() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 450,
        component: (
          <WS2654025_AddInspect
            Li_Date={this.props.Li_Date}
            Li_Id={this.props.Li_PersonalNum}
            Li_SerialNum={this.props.Li_SerialNum}
            dataSource={this.state.DisplayList}
            onFinishScreen={() => {
              this.closeModal();
              this.carriedOutInspectInquiry();
            }}
          />
        ),
      },
    });
  }

  edit(record) {
    if (record.inspectionitemmaster.exam_type === "J" || record.inspectionitemmaster.exam_type === "B") {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 850,
          component: (
            <WS2654013_ContentInput
              Li_Date={this.props.Li_Date}
              Li_Id={this.props.Li_PersonalNum}
              Li_SerialNum={this.props.Li_SerialNum}
              Li_InspectCode={record._2healthguideresultdata.exam_code}
              Li_NumDigits={record.NumDigits}
              onFinishScreen={() => {
                this.carriedOutInspectInquiry();
              }}
            />
          ),
        },
      });
    } else {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 300,
          component: (
            <WS2654016_TargetivesResultInput
              Li_InspectCode={record.exam_code}
              display_order={record.display_order}
              healthguideresultdata={record._2healthguideresultdata}
              inspectionitemmaster={record.inspectionitemmaster}
              goal={record.goal}
              result={record.result}
              Comment={record.Comment}
              onFinishScreen={() => {
                this.closeModal();
                this.carriedOutInspectInquiry();
              }}
            />
          ),
        },
      });
    }
  }

  delete(record) {
    Modal.confirm({
      content: '削除してもよろしいですか？',
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        let params = {
          id: record._2healthguideresultdata.id
        }
        GuideInputProvenSubAction.delete(params)
          .then(res => {
            this.carriedOutInspectInquiry();
          })
      }
    });
  }

  formatDate(dateString) {
    if (dateString) {
      let year = dateString.split('-')[0];
      if (parseInt(year) > 2018) {
        let convertedYear = 'R' + (parseInt(year) - 2018).toString().padStart(2, '0');
        let convertedDateString = convertedYear + '/' + dateString.split('-')[1] + '/' + dateString.split('-')[2];
        return convertedDateString;
      } else if (parseInt(year) > 1988) {
        let convertedYear = 'H' + (parseInt(year) - 1998).toString().padStart(2, '0');
        let convertedDateString = convertedYear + '/' + dateString.split('-')[1] + '/' + dateString.split('-')[2];
        return convertedDateString;
      } else if (parseInt(year) > 1925) {
        let convertedYear = 'S' + (parseInt(year) - 1925).toString().padStart(2, '0');
        let convertedDateString = convertedYear + '/' + dateString.split('-')[1] + '/' + dateString.split('-')[2];
        return convertedDateString;
      }
    } else {
      return '';
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="guide-input-proven-sub">
        <Card className="mb-2" title="指導入力[実績]">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className="box_inner_horizontal" style={{ marginBottom: '10px' }}>
              <div className="box_inner_vertical" style={{ width: '50%' }}>
                <div className="box_border" style={{ position: 'relative' }}>
                  <Form.Item
                    label="カナ氏名"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('kana_name')}
                  </Form.Item>

                  <Form.Item
                    label="漢字氏名"
                    {...smGrid}
                  >
                    {this.props.kanji_name}
                  </Form.Item>
                  <div className="box_inner_horizontal">
                    <Form.Item
                      name="birthday_on"
                      label="生年月日"
                      style={style}
                      {...smGrid}
                    >
                      <div>{this.formatDate(this.formRef.current?.getFieldsValue('birthday_on')?.birthday_on)}</div>
                    </Form.Item>

                    <Form.Item
                      label="年齢"
                      style={style}
                      {...smGrid}
                    >
                      {this.formRef.current?.getFieldValue('Expression_10') + '歳'}
                    </Form.Item>
                  </div>
                  <img style={{ bottom: '10px', right: 10, position: 'absolute' }}
                    src={this.formRef.current?.getFieldValue("Expression_9") ? this.formRef.current?.getFieldValue("Expression_9").includes('男性.png') ? man_img : woman_img : ""}
                    width={50}
                  />
                </div>

                <div className="box_border">
                  <Form.Item
                    style={style}
                    label="保険者番号"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('insurer_number')}
                  </Form.Item>

                  <Form.Item
                    style={style}
                    label="保険者名"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('insurer_kanji_name')}
                  </Form.Item>

                  <Form.Item
                    style={style}
                    label="保険証"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('Expression_72')}
                  </Form.Item>
                </div>

                <div className="box_border">
                  <Form.Item
                    style={style}
                    label="コース場所"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('Expression_13')}
                  </Form.Item>

                  <Form.Item
                    style={style}
                    label="コース名"
                    {...smGrid}
                  >
                    {this.formRef.current?.getFieldValue('course_name')}
                  </Form.Item>
                </div>
              </div>

              <div className="box_border" style={{ width: '50%' }}>
                <Form.Item name="ImplementDateScreen" label="実施日" {...smGrid}>
                  <VenusDatePickerCustom
                    formRefDatePicker={this.formRef}
                    style={{ width: "120px" }}
                  />
                </Form.Item>

                <Form.Item name="EvaluationDivisionScreen" label="評価" {...smGrid}>
                  {this.state.DisplayList.length !== 0 || this.state.EvaluationDivisionScreen === '9' ?
                    <div>{this.state.EvaluationDivisionScreen}</div> :
                    <Select style={{ width: '128px' }}>
                      <Select.Option value="0">初回①</Select.Option>
                      <Select.Option value="2">初期面談</Select.Option>
                      <Select.Option value="3">中間評価</Select.Option>
                      <Select.Option value="4">継続中</Select.Option>
                      <Select.Option value="8">中断</Select.Option>
                      <Select.Option value="9">終了評価</Select.Option>
                    </Select>
                  }
                </Form.Item>

                <Form.Item
                  name="SupportCode"
                  label="項目"
                  {...smGrid}
                >
                  {this.state.DisplayList.length !== 0 ?
                    <div>{this.state.supportCode}</div> :
                    <Select
                      style={{ width: '220px' }}
                      onChange={() => this.forceUpdate()}
                    >
                      {this.state.supportCodeList?.map(value => (
                        <Select.Option
                          key={"SupportCode" + Math.random()}
                          value={value.support_code}
                        >
                          {value.support}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                </Form.Item>

                <div className='box_inner_horizontal'>
                  <Form.Item
                    name="SupportAmount"
                    label="支援量"
                    {...smGrid}
                  >
                    <Input
                      type="text"
                      className="input-size-number-5"
                    />
                  </Form.Item>
                  <Form.Item >
                    {this.state.supportUnitInfoUnitDivision == '1' ? '分' : this.state.supportUnitInfoUnitDivision == '2' ? '往復' : ''}
                  </Form.Item>
                  <Form.Item style={{ marginLeft: 10 }}>
                    ポイント： {this.state.point}
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="TimeZonestartTime"
                    label="時間帯"
                    {...smGrid}
                  >
                    <TimePicker
                      style={{ width: '100px' }}
                      format={format}
                      minuteStep={10}
                      onChange={(e) => console.log(e)}
                      onSelect={(e) => console.log(e)}
                    />
                  </Form.Item>

                  <Form.Item name="TimeZoneendTime" label="~">
                    <TimePicker
                      style={{ width: '100px' }}
                      value={this.formRef.current?.getFieldValue('TimeZoneendTime')}
                      format={format}
                      minuteStep={10}
                    />
                  </Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="PractitionerCode"
                    label="実施者"
                    {...smGrid}
                  >
                    <Input
                      className="input-size-number-5"
                      type="text"
                      onDoubleClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '70%',
                            component: (
                              <WS1382001_ImplementorInquiry
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    PractitionerCode: output.practitioner_code,
                                    PractitionerName: output.kanji_name
                                  })
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })}
                    />
                  </Form.Item>
                  <Form.Item>{this.formRef.current?.getFieldValue('PractitionerName')}</Form.Item>
                </div>

                <div className="box_inner_horizontal">
                  <Form.Item
                    name="ImplementLocationCode"
                    label="場所"
                    {...smGrid}
                  >
                    <Input
                      className="input-size-number-5"
                      style={{ textAlign: 'left' }}
                      type="text"
                      onDoubleClick={() =>
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 850,
                            component: (
                              <WS1388001_ImplementLocationQuery
                                onFinishScreen={(output) => {
                                  this.formRef.current.setFieldsValue({
                                    ImplementLocationCode: output.implement_location_code,
                                    implement_location: output.implement_location
                                  })
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })}
                    />
                  </Form.Item>
                  <Form.Item>{this.formRef.current?.getFieldValue('implement_location')}</Form.Item>
                </div>

                <div className="box_button_bottom_right">
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    okText='削除'
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    onConfirm={() => {
                      this.handleDelete()
                    }}
                    cancelText='キャンセル'
                    cancelButtonProps={{ style: { height: '32px' } }}
                  >
                    <Button
                      danger
                      type='primary'
                      icon={<DeleteOutlined />}
                    >
                      削除
                    </Button>
                  </Popconfirm>

                  {this.state.DisplayList.length > 0 ?
                    <Button
                      type="primary"
                      onClick={() => this.handleUpdate()}
                    >
                      更新
                    </Button>
                    :
                    <Button
                      type="primary"
                      onClick={() => this.handleUpdate()}
                    >
                      登録
                    </Button>
                  }
                </div>
              </div>
            </div>

            <Table
              bordered
              size="small"
              rowKey={(record) => record.inspectionitemmaster.id}
              rowClassName={(record, index) => record.display_order === this.state.rowSelect.display_order ? 'hightlight-row-selected' : ''}
              onRow={(record, index) => ({
                onClick: (event) => this.setState({ rowSelect: record }),
                onDoubleClick: (event) => this.edit(record)
              })}
              pagination={false}
              scroll={{ y: 200 }}
              dataSource={
                this.state.DisplayList ? this.state.DisplayList : []
              }>
              <Table.Column
                title="表示"
                dataIndex="display_order"
                showSorterTooltip={false}
              />

              <Table.Column
                title="実施検査"
                dataIndex="formal_name"
                showSorterTooltip={false}
              />

              <Table.Column
                title="内容"
                dataIndex="Expression_5"
              />

              <Table.Column width={40}
                title={
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={() => this.createNew()}>
                  </Button>
                }
                align='center'
                render={(value, record) => {
                  return (
                    <Dropdown
                      trigger='click'
                      size='small'
                      overlay={() => (
                        <Menu>
                          <Menu.Item key="menu-2" onClick={() => this.edit(record)}>修正</Menu.Item>
                          <Menu.Item key="menu-3" onClick={() => this.delete(record)}>削除</Menu.Item>
                        </Menu>
                      )}>
                      <Button size="small" icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  )
                }} />
            </Table>

            <div style={{ marginTop: '1em', textAlign: 'right' }}>
              <Button type="primary" disabled={this.state.DisplayList.length <= 0} onClick={() => {
                this.setState({
                  childModal: {
                    ...this.state.childModal,
                    visible: true,
                    width: "60%",
                    component: (
                      <WS2653079_HealthCheckupInfoSelect
                        Li_Id={this.props.Li_PersonalNum}
                        Li_Date={this.props.Li_Date}
                        Li_SerialNum={this.props.Li_SerialNum}
                        onFinishScreen={(output) => {
                          this.closeModal();
                          this.getScreenData()
                          this.carriedOutInspectInquiry()
                        }}
                      />
                    ),
                  },
                });
              }}>結果取得</Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2653001_GuideInputProvenSub);
