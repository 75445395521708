import React from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

import { Card, Form, Input, Button, Modal, message, } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { getScreenDataAction, buttonF12Action } from 'redux/basicInfo/PersonalNumberMigration/PersonalNumberMigration.actions'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0248001_PersonalInfoSearchQuery from '../V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery'
import WS0343001_PersonalInfoMaintain from '../V4MS0003000_PersonalInfoMaintainDirectly/WS0343001_PersonalInfoMaintain'
import WS0264001_VisitHistoricalQuery from './WS0264001_VisitHistoricalQuery'
import moment from 'moment-timezone'

class WS0401001_PersonalNumberMigration extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '個人番号移行'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      PersonalNum: {
        kana_name: '',
        kanji_name: '',
        birthday_on: '',
        sex: '',
        hiden: true
      }
    }
  }

  /**
   * 個人番号を基に個人情報を取得
   * @param {*} id
   */
  searchPersonalNumberID = (id) => {
    if (id !== '') {
      // 個人情報を取得
      getScreenDataAction({ PersonalNumF: id })
        .then(res => {
          if (res) {
            let year = moment(res.birthday_on.slice(0, 4)).format('NNNNy')
            let birthday_on = res.birthday_on.slice(4)
            this.setState({
              PersonalNum: {
                kana_name: res.kana_name,
                kanji_name: res.kanji_name,
                birthday_on: year + birthday_on,
                sex: res.Expression_5,
                hiden: false
              }
            })
          }
        })
    } else {
      // 個人情報_初期化
      this.setState({
        PersonalNum: {
          kana_name: '',
          kanji_name: '',
          birthday_on: '',
          sex: '',
          hiden: true
        }
      })
    }
  }

  /**
   * 個人情報保守　モーダル
   */
  showWS0343001_PersonalInfoMaintain = () => {
    if (this.formRef.current.getFieldValue('PersonalNumF')) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 1500,
          component: (
            <WS0343001_PersonalInfoMaintain
              Li_PersonalNum={this.formRef?.current?.getFieldValue('PersonalNumF')}
              onFinishScreen={() => {
                this.closeModal()
              }}
            />
          ),
        },
      })
    } else {
      Modal.error({
        content: '変更元の個人番号が無効です',
        icon: <CloseCircleOutlined />
      })
    }
  }

  /**
   * 個人情報照会　モーダル
   */
  showWS0248001_PersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            onFinishScreen={({ Lo_PersonalNumId, recordData }) => {
              this.formRef.current.setFieldsValue({
                PersonalNumF: Lo_PersonalNumId
              })
              this.searchPersonalNumberID(Lo_PersonalNumId)
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 受診履歴照会 モーダル
   */
  showWS0264001_VisitHistoricalQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0264001_VisitHistoricalQuery
            Li_PersonalNum={this.formRef.current.getFieldValue('PersonalNumF')}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }


  /**
   * 移行処理（個人番号変更）
   */
  onChangePersonalNum = () => {
    let values = this.formRef.current.getFieldsValue()

    // 入力値チェック
    if (!values.PersonalNumF) {
      // 変更元の個人番号が未入力の場合
      Modal.error({
        content: '変更元の個人番号を設定してください',
        icon: <CloseCircleOutlined />
      })
    } else if (!values.PersonalNumT) {
      // 変更先の個人番号が未入力の場合
      Modal.error({
        content: '変更先の個人番号を設定してください',
        icon: <CloseCircleOutlined />
      })
    } else if (values.PersonalNumF === values.PersonalNumT) {
      // 変更先の個人番号が未入力の場合
      Modal.error({
        content: '変更先の個人番号が存在します',
        icon: <CloseCircleOutlined />
      })
    }


    if (values.PersonalNumF && values.PersonalNumT && (values.PersonalNumF !== values.PersonalNumT)) {
      let content = `個人番号 [${values.PersonalNumF}] を [${values.PersonalNumT}] へ変更しますか？`
      Modal.confirm({
        content: content,
        onOk: async () => {
          buttonF12Action(values)
            .then(() => {
              // Inputの表示を変更
              this.formRef.current.setFieldsValue({
                PersonalNumF: values.PersonalNumT,
                PersonalNumT: ''
              })
              // 変更後の個人番号で情報を再取得
              this.searchPersonalNumberID(values.PersonalNumT)
              message.success('個人番号の移行が完了しました')
            })
            .catch((error) => message.error(error.response.data.message || 'エラーが発生しました'))
        },
        okText: 'は　い',
        cancelText: 'いいえ',
      })
    }
  }

  /**
 * モーダル閉じる
 */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='personal-number-migration' style={{ width: 500 }}>
        <Card title='個人番号移行'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal' style={{ alignItems: 'center' }}>
                <div>{'個人番号'}</div>
                <Form.Item
                  name='PersonalNumF'
                  label=''
                  style={{ margin: 0 }}
                >
                  <Input.Search
                    allowClear={true}
                    cleartriggersearch='true'
                    className='input-search-size-number-allow-clear-10'
                    onPressEnter={(event) => {
                      // Enterキー押下時に実行
                      const personalNum = event.target.value
                      // 個人番号変更時の処理
                      this.searchPersonalNumberID(personalNum)
                    }}
                    onSearch={(value, event) => {
                      if (event.target.localName !== 'input') {
                        // 虫眼鏡ボタンが押下された時
                        this.showWS0248001_PersonalInfoSearchQuery()
                      } else {
                        // allowClearのボタンが押下された時
                        this.searchPersonalNumberID('')
                      }
                    }}
                  />
                </Form.Item>
                <div>{'を'}</div>
                <Form.Item
                  name='PersonalNumT'
                  label=''
                  style={{ margin: 0 }}
                >
                  <Input type='number'
                    min={0}
                    className='input-size-number-10'
                  />
                </Form.Item>
                <div>{'に変更'}</div>
              </div>

              <div className='box_inner_vertical'>
                <div className='box_search'
                  style={{
                    display: this.state.PersonalNum.hiden ? 'none' : '', //変更元の個人番号が入力されていない場合、非表示
                    width: '100%'
                  }}>
                  <div className='box_search_inner' style={{ width: '100%' }}>
                    <p style={{ margin: '0' }}>{this.state.PersonalNum.kana_name}</p>
                    <p style={{ margin: '0' }}>{this.state.PersonalNum.kanji_name}</p>

                    <div
                      className='box_inner_horizontal'
                      style={{ alignItems: 'center' }}
                    >
                      <div>{this.state.PersonalNum.birthday_on}</div>
                      <div
                        style={{
                          padding: '4px 8px',
                          color: 'white',
                          backgroundColor: this.state.PersonalNum.sex === '女性' ? '#FF0000' : '#0000FF'
                        }}
                      >
                        {this.state.PersonalNum.sex}
                      </div>

                      <Button
                        onClick={() => {
                          this.showWS0264001_VisitHistoricalQuery()
                        }}
                      >
                        履歴
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.showWS0343001_PersonalInfoMaintain()
                }}
              >
                <span className='btn_label'>
                  個人保守
                </span>
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  this.onChangePersonalNum()
                }}
              >
                <span className='btn_label'>
                  移行処理
                </span>
              </Button>
            </div>

          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0401001_PersonalNumberMigration)
