import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Button, Radio, Checkbox, Table, Row, Col, DatePicker, Space, Modal, message, Tooltip } from "antd";
import { PlusOutlined, InfoOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import WS2786001_ConditionAddSub from "./WS2786001_ConditionAddSub";
import WS0247001_OfficeInfoRetrievalQuery from "../V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0275001_ContractQuerySelect from "../V4KB0201000_ContractInfoMaintain/WS0275001_ContractQuerySelect";
import WS0265001_BasicCourseInquiry from "../V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import ConsultInfoReconstructionService from "services/basicInfo/ConsultInfoReconstruction/ConsultInfoReconstructionService.js";
import WS2584019_PersonalInfoInquirySub from "pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx";
import WS0335011_InspectChanges from "pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS0335011_InspectChanges.jsx";
import WS2786002_ConditionAddSubPlus from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786002_ConditionAddSubPlus.jsx'

const dateFormat = "YYYY/MM/DD";
class WS0335001_ConsultInfoReconstruction extends React.Component {
  static propTypes = {
    Li_personal_num: PropTypes.any,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "受診情報再構成";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoadingSearch: false,
      isLoadingTable: false,
      isLoadingRedeploy: false,
      DateFChars: "",
      DateTChars: "",
      dateF: "",
      dateT: "",
      flag: 0,
      disabled: false,
      checkAll: true,
      checkboxDisabled: false,

      pagination: {
        size: 1,
        showQuickJumper: false
      },

      selectedRowKeys: [],
      selectedRows: [],

      tableData: [],

      tableDataPaginate: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      conditionAddData: null
    };
  }

  showPersonalInfoInquirySub = (id) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "80vw",
        component: (
          <WS2584019_PersonalInfoInquirySub Li_PersonalNum={id} />
        ),
      },
    });
  };

  componentDidMount() {
    // this.getListData();
  }

  getListData() {
    this.setState({ isLoadingTable: true });
    let params = {
      flg_btn: this.state.flag,
      BranchStoreCodeT: this.formRef.current.getFieldsValue("BranchStoreCodeT").BranchStoreCodeT,
      DateFChars: this.formRef.current.getFieldsValue("DateFChars").DateFChars.format("YYYY/MM/DD"),
      DateTChars: this.formRef.current.getFieldsValue("DateTChars").DateTChars.format("YYYY/MM/DD"),
      StsCourseAmount: this.formRef.current.getFieldsValue("StsCourseAmount").StsCourseAmount,
      TargetSegment: this.formRef.current.getFieldsValue("TargetSegment").TargetSegment,
      UpdateClassify: this.formRef.current.getFieldsValue("UpdateClassify").UpdateClassify,
      OfficeCode: this.formRef.current.getFieldsValue("OfficeCode").OfficeCode,
      CourseCodeF: this.formRef.current?.getFieldValue('Course'),
      CourseCodeT: this.formRef.current?.getFieldValue('Course'),
      ContractType: this.formRef.current?.getFieldValue('ContractType'),
      ContractOrgCode: this.formRef.current?.getFieldValue('ContractOrgCode'),
      ContractStartDate: this.formRef.current?.getFieldValue('ContractStartDate'),
      ContractNum: this.formRef.current?.getFieldValue('ContractNum'),

      conditionAddData: this.state.conditionAddData
    }
    ConsultInfoReconstructionService.getListData(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({ tableData: res.data });
        this.setState({
          tableData: res.data,
          selectedRows: res.data,
          selectedRowKeys: res.data.map(x => x.id),
        })
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTable: false });
      });
  }

  handleSearch() {
    this.setState({ isLoadingSearch: true });
    // const values = this.formRef.current.getFieldsValue();
    const values = {
      flg_btn: this.state.flag,
      BranchStoreCodeT:
        this.formRef.current.getFieldsValue("BranchStoreCodeT").BranchStoreCodeT,
      DateFChars:
        this.formRef.current.getFieldsValue("DateFChars").DateFChars,
      DateTChars:
        this.formRef.current.getFieldsValue("DateTChars").DateTChars,
      StsCourseAmount:
        this.formRef.current.getFieldsValue("StsCourseAmount").StsCourseAmount,
      TargetSegment:
        this.formRef.current.getFieldsValue("TargetSegment").TargetSegment,
      UpdateClassify:
        this.formRef.current.getFieldsValue("UpdateClassify").UpdateClassify,
      OfficeCode:
        this.formRef.current.getFieldsValue("OfficeCode").OfficeCode,
      CourseCodeF:
        this.formRef.current?.getFieldValue('Course'),
      CourseCodeT:
        this.formRef.current?.getFieldValue('Course'),
      ContractType:
        this.formRef.current?.getFieldValue('ContractType'),
      ContractOrgCode:
        this.formRef.current?.getFieldValue('ContractOrgCode'),
      ContractStartDate:
        this.formRef.current?.getFieldValue('ContractStartDate'),
      ContractNum:
        this.formRef.current?.getFieldValue('ContractNum'),

    };

    ConsultInfoReconstructionService.DisplayBtn(values)
      .then((res) => {
        // this.forceUpdate();
        this.setState({ flag: 0 });
        this.getListData();
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoadingSearch: false }));
  }

  Redeploy_F12() {
    this.setState({ isLoadingRedeploy: true });

    let values = {
      selectedRowKeys: this.state.selectedRowKeys,
      StsContractStartDateChange: this.formRef.current?.getFieldValue('StsContractStartDateChange'),
      UpdateClassify: this.formRef.current?.getFieldValue('UpdateClassify'),
      StsOptionAmount: this.formRef.current?.getFieldValue('StsOptionAmount'),
      StsCourseAmount: this.formRef.current?.getFieldValue('StsCourseAmount'),
    }

    ConsultInfoReconstructionService.Redeploy_F12(values)
      .then((res) => {
        this.formRef.current.setFieldsValue(res.data);
        message.success("再展開処理　終了！！");
        this.setState({ flag: 1 });
        this.getListData();
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoadingRedeploy: false }));
  }

  ShowInspectChanges(record) {
    if (record?.InspectDifference?.length > 0) {
      this.setState({
        childModal: {
          ...this.state.childModal,
          visible: true,
          width: 600,
          component: (
            <WS0335011_InspectChanges
              Li_InspectDifference={record?.W1_inspect_difference}
              W1_reserve_num={record?.W1_reserve_num}
              W1_course_level={record?.W1_course_level}
              onFinishScreen={(output) => {
                this.closeModal();
              }}
            />
          ),
        },
      });
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false
      },
    });
  }

  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue("DateFChars")
      ? this.formRef.current?.getFieldValue("DateFChars").format("YYYY/MM/DD")
      : null;
    let dateEnd = this.formRef.current?.getFieldValue("DateTChars")
      ? this.formRef.current?.getFieldValue("DateTChars").format("YYYY/MM/DD")
      : null;
    if (dateEnd && dateStart > dateEnd) {
      this.formRef.current?.setFieldsValue({
        DateFChars: this.formRef.current?.getFieldValue("DateFChars"),
      });
    }
  }

  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue("DateFChars")
      ? this.formRef.current?.getFieldValue("DateFChars").format("YYYY/MM/DD")
      : null;
    let dateEnd = this.formRef.current?.getFieldValue("DateTChars")
      ? this.formRef.current?.getFieldValue("DateTChars").format("YYYY/MM/DD")
      : null;
    if ((!dateEnd && dateStart) || (dateStart && dateStart > dateEnd)) {
      this.formRef.current?.setFieldsValue({
        DateTChars: this.formRef.current?.getFieldValue("DateFChars"),
      });
    }
  }

  changeOfficeCode(e) {
    const data = {
      OfficeCode: e,
    };
    ConsultInfoReconstructionService.changeOfficeCode(data)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          OfficeCode: res.data.office_code,
          // BranchStoreCodeF: "",
          office_kanji_name: res.data.office_kanji_name,
        });
        this.setState({ disabled: true });
      })
      .catch((error) => { });
  }

  selectChangeAll(e) {
    this.setState({ checkAll: e.target.checked })
    let data = {
      flg_all: 1,
      SelectAll: e.target.checked ? 1 : 0,
    }
    ConsultInfoReconstructionService.selectChange(data)
      .then((res) => {
        this.getListData();
      })
      .catch((error) => {
      })
      .finally(() => { });
  }

  selectChangeOne(e, record) {
    let data = {
      flg_all: 0,
      SelectAll: this.state.checkAll ? 1 : 0,
      id: record.id,
      SelectOne: e.target.checked ? 1 : 0
    }
    ConsultInfoReconstructionService.selectChange(data)
      .then((res) => {
        this.getListData();
      })
      .catch((error) => {
      })
      .finally(() => { });
  }

  radioChange() {
    if (this.formRef.current?.getFieldValue('UpdateClassify') == 1) {
      this.setState({
        checkboxDisabled: true
      })
    } else {
      this.setState({
        checkboxDisabled: false
      })
    }
  }

  render() {
    return (
      <div className="consult-info-reconstruction">
        <Card
          title='受診情報再構成'
          style={{ position: 'relative' }}
        >
          <Form
            ref={this.formRef}
            initialValues={{
              DateFChars: moment(),
              DateTChars: moment(),
              TargetSegment: "0",
              UpdateClassify: 0,
              BranchStoreCodeT: "9999",
              StsCourseAmount: true,
            }}
            onFinish={this.onFinish}
          >
            <Input.Group style={{ display: "none" }}>
              <Form.Item name="ContractType">
                <Input />
              </Form.Item>
              <Form.Item name="ContractOrgCode">
                <Input />
              </Form.Item>
              <Form.Item name="ContractStartDate">
                <Input />
              </Form.Item>
              <Form.Item name="ContractNum">
                <Input />
              </Form.Item>
              <Form.Item name="TargetSegment">
                <Input />
              </Form.Item>
              <Form.Item name="UpdateClassify">
                <Input />
              </Form.Item>
              <Form.Item name="BranchStoreCodeT">
                <Input />
              </Form.Item>
            </Input.Group>
            <Row>
              <div className="box_inner_vertical">
                <div className="box_inner_horizontal">
                  <div className="box_search">
                    <div className="box_search_inner_border">
                      <div className="box_inner_horizontal">
                        <Form.Item name="DateFChars" label="受診日">
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            onChange={() => {
                              this.checkDateStart();
                            }}
                          />
                        </Form.Item>
                        <Form.Item>~</Form.Item>
                        <Form.Item name="DateTChars">
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            onBlur={() => {
                              this.checkDateEnd();
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="box_inner_horizontal">
                        <Form.Item name="OfficeCode" label="事業所">
                          <Input.Search
                            style={{ width: "122px", textAlign: "left" }}
                            onPressEnter={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue("OfficeCode")}
                                      onFinishScreen={(data) => {
                                        this.formRef.current.setFieldsValue({
                                          OfficeCode: data.Lio_OfficeCode,
                                          BranchStoreCodeF:
                                            data.Lio_BranchStoreCode === 0
                                              ? null
                                              : data.Lio_BranchStoreCode,
                                          office_kanji_name:
                                            data.recordData.office_kanji_name,
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                            allowClear={true}
                            onSearch={(value, event) => {
                              if (event.target.localName === "input") {
                                // ×ボタンを押したときの処理を記述
                                this.formRef.current.setFieldsValue({
                                  office_kanji_name: ''
                                })
                                this.forceUpdate()
                              } else {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1200,
                                    component: (
                                      <WS0247001_OfficeInfoRetrievalQuery
                                        Lio_OfficeCode={this.formRef.current?.getFieldValue("OfficeCode")}
                                        onFinishScreen={(data) => {
                                          this.formRef.current.setFieldsValue({
                                            OfficeCode: data.Lio_OfficeCode,
                                            BranchStoreCodeF:
                                              data.Lio_BranchStoreCode === 0
                                                ? null
                                                : data.Lio_BranchStoreCode,
                                            office_kanji_name:
                                              data.recordData.office_kanji_name,
                                          });
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: false,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                  },
                                });
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name="BranchStoreCodeF">
                          <Input
                            style={{ width: "60px", textAlign: "right" }}
                            disabled={!this.state.disabled}
                          />
                        </Form.Item>
                        <Form.Item name="office_kanji_name">
                          <div
                            className="input-size-20"
                          >
                            {this.formRef.current?.getFieldValue("office_kanji_name")}
                          </div>
                        </Form.Item>
                      </div>
                      <div className="box_inner_horizontal">
                        <Form.Item
                          name="Course"
                          label="コース"
                          style={{ width: 'fit-content' }}
                        >
                          <Input.Search
                            allowClear={true}
                            style={{ width: '122px' }}
                            onPressEnter={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS0265001_BasicCourseInquiry
                                      Li_CourseName={this.formRef.current?.getFieldValue('Course')}
                                      onFinishScreen={(data) => {
                                        this.formRef.current.setFieldsValue({
                                          Course: data.Lo_CourseCode,
                                          CourseName: data.Lo_CourseName
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                            onSearch={(value, event) => {
                              if (event.target.localName === "input") {
                                // ×ボタンを押したときの処理を記述
                                this.formRef.current.setFieldsValue({
                                  CourseName: ''
                                })
                                this.forceUpdate()
                              } else {
                                this.setState({
                                  childModal: {
                                    ...this.state.childModal,
                                    visible: true,
                                    width: 1200,
                                    component: (
                                      <WS0265001_BasicCourseInquiry
                                        Li_CourseName={this.formRef.current?.getFieldValue('Course')}
                                        onFinishScreen={(data) => {
                                          this.formRef.current.setFieldsValue({
                                            Course: data.Lo_CourseCode,
                                            CourseName: data.Lo_CourseName
                                          });
                                          this.setState({
                                            childModal: {
                                              ...this.state.childModal,
                                              visible: false,
                                            },
                                          });
                                        }}
                                      />
                                    ),
                                  },
                                });
                              }
                            }}
                          />
                        </Form.Item>
                        <Form.Item name='CourseName'>
                          <div
                            className="input-size-20"
                          >
                            {this.formRef.current?.getFieldValue("CourseName")}
                          </div>
                        </Form.Item>
                      </div>
                      <div className="box_inner_horizontal">
                        <Form.Item label="契　約">
                          <Button
                            // type="primary"
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: "80%",
                                  component: (
                                    <WS0275001_ContractQuerySelect
                                      Li_SpecifyCourse={this.formRef.current.getFieldValue(
                                        "Course"
                                      )}
                                      onFinishScreen={(data) => {
                                        this.formRef.current.setFieldsValue({
                                          Course: data.Lo_MedicalExamCourse,
                                          ContractType: data.Lo_ContractType,
                                          ContractOrgCode: data.Lo_ContractOrgCode,
                                          ContractStartDate:
                                            data.Lo_ContractStartDate,
                                          ContractNum: data.Lo_ContractNum,
                                          contract_short_name:
                                            data.recordData?.contract_short_name,
                                        });
                                        this.setState({
                                          childModal: {
                                            ...this.state.childModal,
                                            visible: false,
                                          },
                                        });
                                      }}
                                    />
                                  ),
                                },
                              });
                            }}
                          >
                            選択
                          </Button>
                        </Form.Item>
                        <Form.Item name="contract_short_name">
                          <div >
                            {this.formRef.current?.getFieldValue(
                              "contract_short_name"
                            )}
                          </div>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            icon={<CloseOutlined />}
                            size='small'
                            style={{
                              border: 'none',
                              display: this.formRef.current?.getFieldValue('contract_short_name') ? '' : 'none'
                            }}
                            onClick={() => {
                              this.setState({
                                Course: '',
                                ContractType: '',
                                ContractOrgCode: '',
                                ContractStartDate: '',
                                ContractNum: '',
                                contract_short_name: ''
                              })
                              this.formRef.current?.setFieldsValue({
                                Course: '',
                                ContractType: '',
                                ContractOrgCode: '',
                                ContractStartDate: '',
                                ContractNum: '',
                                contract_short_name: ''
                              })
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="box_search_inner">
                      {/* <Row style={{ marginRight: "1rem" }}> */}
                      <div className="box_inner_horizontal">
                        <Form.Item name="UpdateClassify">
                          <Radio.Group
                            onChange={() => this.radioChange()}
                          >
                            <div className="box_inner_vertical" style={{ width: '100px' }}>
                              <Form.Item>
                                <Radio value={0}>全て</Radio>
                              </Form.Item>
                              <Form.Item>
                                <Radio value={1}>検査のみ</Radio>
                              </Form.Item>
                              <Form.Item>
                                <Radio value={2}>請求のみ</Radio>
                              </Form.Item>
                            </div>
                          </Radio.Group>
                        </Form.Item>
                        <div className="box_inner_vertical">
                          <Form.Item name="StsCourseAmount" valuePropName="checked">
                            <Checkbox disabled={this.state.checkboxDisabled}>コース金額を再取得する</Checkbox>
                          </Form.Item>
                          <Form.Item name="StsOptionAmount" valuePropName="checked">
                            <Checkbox disabled={this.state.checkboxDisabled}>オプション金額を再取得する</Checkbox>
                          </Form.Item>
                          <Form.Item
                            name="StsContractStartDateChange"
                            valuePropName="checked"
                          >
                            <Checkbox>契約開始日を再取得する</Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                      {/* </Row> */}
                    </div>
                  </div>
                  <div className="box_search_btn">
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          // this.handleSearch();
                          this.getListData();
                        }}
                        loading={this.state.isLoadingSearch}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                      <Tooltip title='詳細な条件追加'>
                        <Button
                          icon={<PlusOutlined />}
                          type='primary'
                          size='small'
                          style={{ borderRadius: '12px' }}
                          className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                          onClick={() => {
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                centered: true,
                                component: (
                                  <WS2786002_ConditionAddSubPlus
                                    Li_DateF={this.formRef.current.getFieldValue("DateFChars")}
                                    Li_DateT={this.formRef.current.getFieldValue("DateTChars")}
                                    Li_CourseF={this.formRef.current.getFieldValue("Course")}
                                    Li_CourseT={this.formRef.current.getFieldValue("Course")}
                                    Li_Office={this.formRef.current.getFieldValue("OfficeCode")}
                                    Li_BranchShop={this.formRef.current.getFieldValue("BranchStoreCodeF")}
                                    Li_ConditionAddData={this.state.conditionAddData}

                                    // Li_PersonalNum={this.formRef.current.getFieldValue('')}
                                    // Lio_KeyInfo={this.formRef.current.getFieldValue('')}
                                    // Li_AcceptNoF={this.formRef.current.getFieldValue('')}
                                    // Li_AcceptNoT={this.formRef.current.getFieldValue('')}
                                    // Li_TimeDivision={this.formRef.current.getFieldValue('')}
                                    // Li_FacilityType={this.formRef.current.getFieldValue('')}
                                    // Li_State={this.formRef.current.getFieldValue('')}
                                    // Li_Insurer={this.formRef.current.getFieldValue('')}

                                    onFinishScreen={(output) => {
                                      // 追加条件のデータをstate変数に保持
                                      this.setState({
                                        conditionAddData: output.conditionAddData,
                                      })
                                      this.closeModal()
                                    }}
                                  />
                                ),
                              },
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <Table
                  dataSource={this.formRef.current?.getFieldValue("tableData")}
                  rowKey={(record) => record.id}
                  size="small"
                  bordered
                  scroll={{ x: 'max-content', y: resizableTableScroll(130) }}
                  pagination={this.state.tableData.length > process.env.REACT_APP_PAGE_SIZE ? this.state.tableDataPaginate : false}
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: this.state.selectedRowKeys,
                    onSelect: (record, selected, selectedRows, nativeEvent) => {
                      let arrTemp = [...this.state.selectedRowKeys];
                      let arrTempRecord = [...this.state.selectedRows];
                      let idx = arrTemp.indexOf(record.id);
                      if (idx === -1) {
                        arrTemp.push(record.id);
                        arrTempRecord.push(record)
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord
                        });
                      } else {
                        arrTemp.splice(idx, 1);
                        arrTempRecord.splice(idx, 1);
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord
                        });
                      }
                    },

                    onSelectAll: (selected, selectedRows, changeRows) => {
                      if (selected) {
                        let arrTemp = this.formRef.current?.getFieldValue("tableData").map(item => item.id);
                        let arrTempRecord = this.formRef.current?.getFieldValue("tableData");
                        this.setState({
                          selectedRowKeys: arrTemp,
                          selectedRows: arrTempRecord,
                          isSelectAll: true
                        });
                      } else {
                        this.setState({
                          selectedRowKeys: [],
                          selectedRows: [],
                          isSelectAll: false
                        });
                      }
                    },
                  }}
                >
                  <Table.Column
                    title="入金"
                    className="column-size-5"
                    key="Expression_17"
                    render={(value, record) => (
                      <div style={{ textAlign: 'center' }}>
                        {record.payment_enab_flg ? "○" : ""}
                      </div>
                    )}
                  />
                  <Table.Column
                    title="状態"
                    className="column-size-5"
                    key="Expression_26"
                    render={(value, record) => {
                      if (record.state_flag === 0) {
                        return <div style={{ textAlign: 'center' }}>予約</div>;
                      } else if (record.state_flag === 1) {
                        return <div style={{ textAlign: 'center' }}>受付</div>;
                      } else if (record.state_flag === 2) {
                        return <div style={{ textAlign: 'center' }}>保留</div>;
                      } else if (record.state_flag === 3) {
                        return <div style={{ textAlign: 'center' }}>キャンセル</div>;
                      } else {
                        return <div style={{ textAlign: 'center' }}></div>;
                      }
                    }}
                  />
                  <Table.Column
                    title="受診日"
                    className="column-size-10"
                    dataIndex="W1_consult_date"
                    render={(value, record, index) => (
                      <Form.Item>
                        <div style={{ textAlign: 'center' }}>
                          {moment(record.visit_date_on).format("YYYY/MM/DD(ddd)")}
                        </div>
                      </Form.Item>
                    )}
                  />
                  <Table.Column
                    title="個人番号"
                    className="column-size-10"
                    dataIndex="W1_person_num_id"
                    render={(value, record, index) => (
                      <Form.Item style={{ textAlign: "right" }}>
                        <span>{record.personal_number_id}</span>
                      </Form.Item>
                    )}
                  />
                  <Table.Column
                    title="メモ"
                    className="column-size-5"
                    key="Expression_29"
                    dataIndex={["personal_specials", "importance"]}
                    render={(text, record) => {
                      let icon = null;
                      switch (record?.personal_special?.importance) {
                        // case 1:
                        //   icon = (
                        //     <InfoCircleOutlined
                        //       style={{ fontSize: 20, color: "#1890ff" }}
                        //     />
                        //   );
                        //   break;

                        // case 3:
                        //   icon = (
                        //     <WarningOutlined
                        //       style={{ fontSize: 20, color: "#faad14" }}
                        //     />
                        //   );
                        //   break;

                        // case 5:
                        //   icon = (
                        //     <CloseCircleOutlined
                        //       style={{ fontSize: 20, color: "#ff4d4f" }}
                        //     />
                        //   );
                        //   break;

                        default:
                          icon = <InfoOutlined />;
                      }
                      return (
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            size='small'
                            style={{ borderRadius: '12px' }}
                            onClick={() =>
                              this.showPersonalInfoInquirySub(
                                record.personal_number_id
                              )
                            }
                            icon={icon}
                            disabled={
                              !(
                                record.personal_number_id &&
                                record.personal_number_id !== ""
                              )
                            }
                          />
                        </div>
                      );
                    }}
                  />
                  <Table.Column
                    title="氏　名"
                    className="column-size-40"
                    key="Expression_14"
                    render={(record) => {
                      return (
                        <div style={{ textAlign: 'left' }}>{record.kanji_name}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="事業所情報"
                    className="column-size-60"
                    dataIndex="office_kanji_name"
                    render={(record) => {
                      return (
                        <div style={{ textAlign: 'left' }}>{record}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="契約情報"
                    className="column-size-60"
                    dataIndex="contract_term"
                    render={(record) => {
                      return (
                        <div style={{ textAlign: 'left' }}>{record}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="検査"
                    className="column-size-10"
                    dataIndex="InspectDifference"
                    render={(value, record, index) => (
                      <div
                        style={{ cursor: "pointer", textAlign: 'center' }}
                        onClick={() => this.ShowInspectChanges(record)}
                      >
                        {value}
                      </div>
                    )}
                  />
                  <Table.Column
                    title="金額"
                    className="column-size-10"
                    dataIndex="AmountDifference"
                    render={(value) => (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    )}
                  />
                  <Table.Column
                    title="契約金額"
                    className="column-size-10"
                    dataIndex="contract_price"
                    render={(value) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="コース金額"
                    className="column-size-10"
                    dataIndex="course_price"
                    render={(value) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="請求金額"
                    className="column-size-10"
                    dataIndex="claim_price"
                    render={(value) => {
                      return (
                        <div style={{ textAlign: 'right' }}>{value}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="事業所コード"
                    className="column-size-10"
                    dataIndex="officeCode"
                    render={(value) => {
                      return (
                        <div style={{ textAlign: 'center' }}>{value}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title="契約番号"
                    className="column-size-30"
                    render={(value, record) => (
                      <div style={{ textAlign: 'center' }}>
                        {
                          record.contract_type +
                          "-" +
                          record.contract_organization_code +
                          "-" +
                          moment(record.contract_start_date_on).format("YYYYMMDD") +
                          "-" +
                          record.contract_number
                        }
                      </div>
                    )}
                  />
                </Table>
                <div className="box_button_bottom_right" style={{ paddingTop: 0 }}>
                  <Col span={3}>
                    <Form.Item style={{ float: "right" }}>
                      <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        onClick={() => this.Redeploy_F12()}
                      >
                        再展開
                      </Button>
                    </Form.Item>
                  </Col>
                </div>
              </div>
            </Row>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS0335001_ConsultInfoReconstruction;
