import XmlMedicalExamItemRefService from "services/SpecificInsureMaintenance/DataRangeCheck/XmlMedicalExamItemRefService";


import { message } from "antd";

const XmlMedicalExamItemRefAction = {
  index(data) {
    return XmlMedicalExamItemRefService.index(data)
      .then((res) => {
        return res

      })
      .catch((err) => {
        // message.error(err.response.data.message);
        throw err
      });
  },
}
export default XmlMedicalExamItemRefAction;
