import React from "react";
import { connect } from "react-redux";

import { Card, Form, Select, Input, Button, message, Popconfirm } from "antd";
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";

import WS1527008_ClassifyInquiry from 'pages/KK_ResultOutput/V4KK0200000_ResultsTblCollectOutput/WS1527008_ClassifyInquiry.jsx';
import StyleListListDataAction from "redux/ResultOutput/ResultsTblCollectOutput/SetupResultTblCollectOutput/StyleListListData.action";

const smGrid = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { paddingLeft: '11px', width: 'calc(100% - 60px)' } }
}
class WS1527100_SetupResultTblCollectOutputEdit extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '設定[結果表一括出力]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: [],
      listCombobox: [],
      selectedPrescribed: true,
      Preview: 0,
      PrinterNum: 0,
      dataSource: [],
      isLoadingTable: true,
      selectedRowKeys: [],
      rowSelected: [],
      indexTable: 0,
    };
  }

  componentDidMount() {
    if (this.props.record) {
      this.formRef.current.setFieldsValue(this.props.record);
    }
  }

  updateRecordData() {
    let getFieldValue = this.formRef?.current?.getFieldValue()

    let params = {
      id: this.props.record ? this.props.record.id : null,
      OldCode: this.props.record ? this.props.record.Code : null,
      Code: getFieldValue.Code,
      DocumentName: getFieldValue.DocumentName,
      Division: getFieldValue.Division,
      OutputOrder: getFieldValue.OutputOrder,
      Select: getFieldValue.Select,
      NewFlag: this.props.newFlag
    }
    StyleListListDataAction.saveData(params)
      .then((res) => {
        message.success('更新しました!')
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  // check selected record while add new
  changeRow(index) {
    let data = [...this.state.dataSource];

    let idTemp = false;
    data.forEach(item => {
      if (this.checkIdTemp(item.id)) {
        idTemp = true;
        return idTemp;
      }
    })

    if (idTemp) {
      this.setState({
        selectedRows: [data[0]],
        rowSelected: [data[0]],
        selectedRowKeys: [data[0].id],
        indexTable: 0
      });
    } else {
      this.setState({
        indexTable: index
      });
    }
  }

  deleteData(id) {
    let params = {
      id: this.props.record.id
    }
    StyleListListDataAction.deleteData(params)
      .then(res => {
        message.success('正常に削除されました!');
        this.props.onFinishScreen()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      }
    });
  }


  render() {
    const { current } = this.state;

    return (
      <div className="setup-result-tbl-collect-output">
        <Card title={"結果表一括設定" + [this.props.newFlag ? '追加' : '編集']}>
          <Form
            initialValues={{ Preview: true }}
            ref={this.formRef}
          >
            <div className="box_inner_horizontal">
              <div className="box_inner_vertical">
                <Form.Item
                  name='Code'
                  label='コード'
                  {...smGrid}
                  rules={[
                    {
                      required: true,
                      message: "必須項目です"
                    }
                  ]}
                >
                  <Input className="input-size-number-4" />
                </Form.Item>
                <Form.Item name='DocumentName' label='帳票名'{...smGrid}>
                  <Input className="input-size-30" />
                </Form.Item>
                <Form.Item name='Division' label='区分'{...smGrid}>
                  <Input.Search
                    className="input-search-size-number-4"
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 650,
                          component: (
                            <WS1527008_ClassifyInquiry
                              onFinishScreen={(output) => {
                                this.formRef.current.setFieldsValue({
                                  Division: output.Lio_Division
                                });
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
              </div>

              <div className="box_inner_vertical">
                <Form.Item name='OutputOrder' label='出力順番'{...smGrid}>
                  <Select style={{ width: "100%" }}>
                    <Select.Option value={1}>ｺｰｽ順</Select.Option>
                    <Select.Option value={2}>受付順</Select.Option>
                    <Select.Option value={3}>事業所順</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name='Select' label='出力順番'{...smGrid}>
                  <Select style={{ width: "100%" }}>
                    <Select.Option value="全て対象">全て対象</Select.Option>
                    <Select.Option value="部分対象">部分対象</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="box_button_bottom_right">
              {this.props.newFlag === false ?
                < Popconfirm
                  title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                  overlayStyle={{ width: '250px' }}
                  overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                  icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                  okText='削除'
                  okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                  onConfirm={() =>
                    // 削除
                    this.deleteData()
                  }
                  cancelText='キャンセル'
                  cancelButtonProps={{ style: { height: '32px' } }}
                >
                  <Button
                    danger
                    type='primary'
                    icon={<DeleteOutlined />}
                  >
                    <span className='btn_label'>
                      削除
                    </span>
                  </Button>
                </Popconfirm>
                :
                null
              }
              {/* 保存ボタン */}
              <Button
                type='primary'
                htmlType="submit"
                icon={<SaveOutlined />}
                onClick={() => {
                  this.updateRecordData()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1527100_SetupResultTblCollectOutputEdit);
