import GovernmentTubePrimaryAggregateService from 'services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/GovernmentTubePrimaryAggregateService'
import { message } from "antd";

const GovernmentTubePrimaryAggregateAction = {
  index() {
    return GovernmentTubePrimaryAggregateService.index()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  save(data) {
    return GovernmentTubePrimaryAggregateService.save(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  delete(data) {
    return GovernmentTubePrimaryAggregateService.delete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  }

};
export default GovernmentTubePrimaryAggregateAction;