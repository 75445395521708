import React from "react";
import { connect } from "react-redux";

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, message } from "antd";
import { getSetSubprocessAction, saveAndUpdateCourseSubInputAction } from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/CourseSelect.action";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

import WS0267001_CategorySearchQuerySingle from "pages/KK_ResultOutput/V4KK0012000_PrintParamMaintain/WS0267001_CategorySearchQuerySingle.jsx";
import WS1176001_InspectSetInfoSubInspectSetInfo from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176001_InspectSetInfoSubInspectSetInfo.jsx";


const labelCol = {
  span: 4
}
const styleFormItem = {
  margin: 0,
}

class WS1174003_CourseSelect_InspectGroup extends React.Component {

  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      isLoadingTable: false,
      dataSource: []
    };
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  componentDidMount() {
    if (this.props.record) {
      this.formRef.current.setFieldsValue({
        screen_display_order: this.props.record.screen_display_order ?? '',
        exam_group: this.props.record.exam_group ?? '',
        group_name: this.props.record.group_name ?? '',
        test_set: this.props.record.test_set ?? '',
        test_set_name: this.props.record.test_set_name ?? ''
      })

      this.setState({
        group_name: this.props.record.group_name,
        test_set_name: this.props.record.test_set_name
      })
    }

    this.getScreenData();
  }

  getScreenData = () => {
    this.setState({ isLoading: true, isLoadingTable: true, dataAddNew: null });

    getSetSubprocessAction()
      .then(res => {
        this.setState({
          dataSource: res,
          isLoadingTable: false,
          isLoading: false
        })
      })
      .catch(error => {
        this.setState({ isLoading: false, isLoadingTable: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  onDelete() {
    this.props.onDelete()
  }

  onSave() {
    this.setState({ isLoadingTableSubSite: true })

    const params = {
      id: this.props.record ? this.props.record.id : null,
      course_code: this.props.courseCode,
      screen_display_order: this.formRef.current.getFieldValue('screen_display_order') ?? 0,
      exam_group: this.formRef.current.getFieldValue('exam_group') ?? 0,
      group_name: this.formRef.current.getFieldValue('group_name') ?? '',
      test_set: this.formRef.current.getFieldValue('test_set') ?? '',
      test_set_name: this.formRef.current.getFieldValue('test_set_name') ?? ''
    }

    saveAndUpdateCourseSubInputAction(params)
      .then(res => {
        this.props.onFinishScreen()
        this.forceUpdate()
        message.info('登録しました');
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      }).finally(() => this.setState({ isLoadingTableSubSite: false }))
  }

  render() {
    return (
      <div>
        <Card title={this.props.newFlag ? "新規" : "更新"}>
          <Form
            ref={this.formRef}
            initialValues={{ Pattern: 1 }}
            autoComplete='off'
          >

            <Form.Item
              name='screen_display_order'
              label='SEQ'
              labelCol={labelCol}
            >
              <Input style={{ textAlign: 'right', width: 100 }} />
            </Form.Item>

            <Form.Item
              label='グループ'
              labelCol={labelCol}
              style={styleFormItem}
            >
              <div className="box_inner_horizontal">
                <Form.Item name='exam_group'>
                  <Input.Search
                    style={{ width: 150 }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (
                            <WS0267001_CategorySearchQuerySingle
                              onFinishScreen={(value) => {
                                this.formRef.current.setFieldsValue({
                                  exam_group: value.Lio_CategoryCode,
                                  group_name: value.Lio_CategoryName
                                })
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>{this.formRef.current?.getFieldValue('group_name') ?? ""}</div>
              </div>
            </Form.Item>

            <Form.Item
              label='セットコード'
              labelCol={labelCol}
              style={styleFormItem}
            >
              <div className="box_inner_horizontal">
                <Form.Item name='test_set'>
                  <Input.Search
                    style={{ width: 150 }}
                    onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 500,
                          component: (
                            <WS1176001_InspectSetInfoSubInspectSetInfo
                              onFinishScreen={(value) => {
                                this.formRef.current.setFieldsValue({
                                  test_set: value.test_set,
                                  test_set_name: value.test_set_name
                                })
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>{this.formRef.current?.getFieldValue('test_set_name') ?? ""}</div>
              </div>
            </Form.Item>

            <div className='box_button_bottom_right'>
              <Button
                type='danger'
                icon={<DeleteOutlined />}
                disabled={this.props.newFlag}
                onClick={() => {
                  this.onDelete()
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                htmlType='submit'
                icon={<SaveOutlined />}
                onClick={() => {
                  this.onSave()
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>

        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1174003_CourseSelect_InspectGroup);
