import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Select, Table, Button, Modal, message, Tooltip, Dropdown, Menu, Input, } from 'antd'
import { PlusOutlined, QuestionCircleOutlined, MoreOutlined, MenuOutlined, CheckOutlined } from '@ant-design/icons'
import { download_file } from "helpers/CommonHelpers";
import AnotherInspectItemSettingCategoryService from 'services/InspectionMaintenance/AnotherInspectItemSettingCategory/AnotherInspectItemSettingCategoryService'
import WS2705075_PatternSettingSub from './WS2705075_PatternSettingSub'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS2705500_CategorySetting from './WS2705500_CategorySetting'
import WS2705501_CategoryInquiry from './WS2705501_CategoryInquiry'
import WS0294001_UseInspectCheck from 'pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS0294001_UseInspectCheck'
import WS0431001_CsvPreviewConfirm from 'pages/TO_StatisticalServices/V4TO0014000_OfficeVisitsAchievementSituation/WS0431001_CsvPreviewConfirm'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS2705001_AnotherInspectItemSettingCategory extends React.Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)

    // document.title = 'カテゴリ別検査項目設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      searchCategorySettingsDisplayList: [],
      PatternCodeDisplayList: [],
      /**
       * 検索用カテゴリ名
       * @type {string}
       */
      searchCategoryName: '',
      /**
       * 検索用カテゴリ番号
       * @type {string}
       */
      searchCategoryNumber: '',
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getScreenData()
  }

  /**
   * パターン一覧を取得
   */
  getScreenData = async () => {
    await AnotherInspectItemSettingCategoryService.getScreenDataService()
      .then((res) => {
        const data = [...res.data]

        let value = null
        if (data.length > 0) {
          value = data[0].pattern_code
        }
        // Select選択
        this.formRef.current.setFieldsValue({
          PatternCode: value
        })

        // パターン一覧設定
        this.setState({
          PatternCodeDisplayList: data
        })
        this.forceUpdate()
        this.searchCategorySettings()
      })
  }

  /**
   * パターンに紐づくカテゴリ一覧を取得
   */
  searchCategorySettings = () => {
    let params = {
      PatternCode: this.formRef.current.getFieldValue('PatternCode'),
      Search: this.formRef.current.getFieldValue('Search') || this.state.searchCategoryName,
    }
    AnotherInspectItemSettingCategoryService.getCategorySettingsService(params)
      .then((res) => {
        let Search = this.formRef.current.getFieldsValue('Search').Search ? this.formRef.current.getFieldsValue('Search').Search : ''
        this.setState({
          searchCategorySettingsDisplayList: res.data
        })

        if (Search !== '') {
          this.onSearchInputChange()
        } else {
          this.formRef.current.setFieldsValue({
            searchCategorySettingsDisplayList: res.data,
            searchInspectSetDisplayList: []
          })
        }

        this.forceUpdate()

      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  searchInspectSet = (category_code) => {
    const { PatternCode } = this.formRef.current.getFieldsValue()

    AnotherInspectItemSettingCategoryService.getInspectSetService({
      category_code: category_code,
      pattern_code: PatternCode,
    })
      .then((res) => {
        this.formRef.current.setFieldsValue({
          searchInspectSetDisplayList: res.data,
        })
        this.forceUpdate()
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  onSearchInputChange = () => {
    let Search = this.formRef.current.getFieldsValue('Search') ? this.formRef.current.getFieldsValue('Search').Search : ''
    let dataSource = this.state.searchCategorySettingsDisplayList
    if (Search !== '') {
      dataSource = dataSource.filter((record) => {
        if (record.CategoryDeterminingPresence.toString().includes(Search)
          || record.category_code.toString().includes(Search)
          || record.category_judgement_use.toString().includes(Search)
          || record.category_name.includes(Search)
          || record.display_no.toString().includes(Search)
          || record.id.toString().includes(Search)
          || record.pattern_code.includes(Search))
          return record
      })

    }
    this.formRef.current.setFieldsValue({
      searchCategorySettingsDisplayList: dataSource
    })
    this.forceUpdate()
  }

  deleteDataCategorySettings = (id, pattern_code, category_code) => {
    AnotherInspectItemSettingCategoryService.deleteDataCategorySettingsService({
      id,
      pattern_code: pattern_code,
      category_code: category_code,
    })
      .then((res) => {
        message.success('成功')
        this.formRef.current.setFieldsValue({
          searchInspectSetDisplayList: [],
        })
        this.forceUpdate()
        this.savePatternInspection();
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
        this.searchCategorySettings()
      })
  }

  deleteDataInspectSet = (id, pattern_code, category_code) => {
    AnotherInspectItemSettingCategoryService.deleteDataInspectSetService({
      id,
      pattern_code,
      category_code,
    })
      .then((res) => {
        message.success('成功')
        this.savePatternInspection();
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
        this.searchInspectSet(category_code)
      })
  }

  saveDataCategorySetting = (id, record) => {
    const { PatternCode } = this.formRef.current.getFieldsValue()
    const params = {
      category_code: record.category_code,
      pattern_code: PatternCode,
      display_no: record.display_no,
      category_name: record.category_name,
      CategoryDeterminingPresence: record.CategoryDeterminingPresence,
      id: id === null ? '' : id,
    }

    AnotherInspectItemSettingCategoryService.saveDataCategorySettingService(params)
      .then((res) => {
        message.success('保存が完了しました')
        this.savePatternInspection();
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
        this.searchCategorySettings()
      })
  }

  saveDataInspectSet = (id) => {
    const { searchInspectSetDisplayList, PatternCode } =
      this.formRef.current.getFieldsValue()
    let dataFormatToCreateOrUpdate = searchInspectSetDisplayList.filter(
      (item) => {
        if (item != undefined && item.id === id) {
          return item
        }
      }
    )
    if (id === 'create_new') {
      dataFormatToCreateOrUpdate = [
        { ...dataFormatToCreateOrUpdate[0], id: '' },
      ]
    }

    AnotherInspectItemSettingCategoryService.saveDataInspectSetService({
      category_code: this.state.category_code,
      pattern_code: PatternCode,
      display_order: dataFormatToCreateOrUpdate[0].display_order,
      exam_code: dataFormatToCreateOrUpdate[0].exam_code,
      id:
        dataFormatToCreateOrUpdate[0].id === ''
          ? ''
          : dataFormatToCreateOrUpdate[0].id,
    })
      .then((res) => {
        message.success('成功')
        this.savePatternInspection();
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
      .finally(() => {
        this.searchInspectSet(this.state.category_code)
      })
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    if (selectedRows[0].id === 'create_new') return
    this.setState({ category_code: selectedRows[0].category_code })
    this.searchInspectSet(selectedRows[0].category_code)
  }

  PatternCodeChange = (e) => {
    this.formRef.current.setFieldsValue({
      PatternCode: this.formRef.current.getFieldValue('PatternCode')
    })
    this.forceUpdate()
    this.searchCategorySettings()
  }

  //ms0611を更新する
  savePatternInspection = (value) => {
    let params = { pattern: value ? value : this.formRef.current.getFieldValue('PatternCode') }
    AnotherInspectItemSettingCategoryService.reconstructionPatternData(params)
  }
  /**
   * 検索対象カテゴリ名称入力時の処理 stateの更新
   * @param {string} val 入力値
   */
  onChangeSearchCategoryName(val) {
    this.setState({ searchCategoryName: val !== null ? val : '' })
  }

  /**
  * 出力処理
  */
  outputItem = () => {
    let params = {
      Output: 'カテゴリ別検査項目設定.csv',
    }

    AnotherInspectItemSettingCategoryService.outputItem(params)
      .then(res => {
        download_file(res, false);
      })

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='another-inspect-item-setting-category'>
        <Card title='カテゴリ別検査項目設定' >

          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.record) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              icon={<MenuOutlined />}
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item
                      key='1'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.outputItem()

                        // this.setState({
                        //   childModal: {
                        //     ...this.state.childModal,
                        //     visible: true,
                        //     width: 500,
                        //     component:
                        //       <WS0431001_CsvPreviewConfirm
                        //         onFinishScreen={(output) => {
                        //           this.closeModal()
                        //           this.outputItem(output)
                        //         }}
                        //       />
                        //     ,
                        //   },
                        // })
                      }}
                    >
                      出力
                    </Menu.Item>
                    <Menu.Item
                      key='2'
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: '70%',
                            component: (
                              <WS0294001_UseInspectCheck
                                patternCode={this.formRef.current?.getFieldValue('PatternCode')}
                                onFinishScreen={() => {
                                  this.closeModal()
                                }}
                              />
                            ),
                          },
                        })
                      }}
                    >
                      検査照会
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <div className='box_inner_vertical'>
              <div className='box_inner_horizontal'>
                <Form.Item
                  name='PatternCode'
                  label='パターン'
                  style={{ marginBottom: 0 }}>
                  <Select
                    style={{ width: '200px' }}
                    onChange={(e) => this.PatternCodeChange()}
                  >
                    {this.state.PatternCodeDisplayList.map((item, index) => (
                      <Select.Option
                        value={item.pattern_code}
                        key={`pattern-${index}`}
                      >
                        <Tooltip title={item.pattern_code}>
                          {item.pattern_name}
                        </Tooltip>
                      </Select.Option>
                    ))
                    }
                  </Select>
                </Form.Item>
                <Tooltip title='パターン登録'>
                  <Button
                    type='primary'
                    size='small'
                    style={{ borderRadius: '12px', marginTop: 'auto', marginBottom: 'auto', marginRight: '10px' }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS2705075_PatternSettingSub
                              onFinishScreen={() => {
                                // wait(1)
                                // データ再取得
                                this.getScreenData()
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }}></Button>
                </Tooltip>

                <Form.Item
                  style={{ marginBottom: 0 }}
                >
                  <Input className="input-size-30"
                    // placeholder="カテゴリ名称"
                    value={this.state.searchCategoryName}
                    onChange={(e) => {
                      this.onChangeSearchCategoryName(e.target.value)
                    }}
                    onPressEnter={() => this.searchCategorySettings()}
                  />
                </Form.Item>

                {/* 課題：ALPHASALUS-2785 */}
                {/* <Form.Item
                  style={{ marginBottom: 0 }}
                >
                  <Input className="input-size-10"
                    placeholder="カテゴリ番号"
                    value={this.state.searchCategoryNumber}
                    onChange={(e) => {
                      this.onChangeSearchCategoryNumber(e.target.value)
                    }}
                  />
                </Form.Item> */}

                <Button style={{ marginBottom: 0 }}
                  onClick={() => {
                    this.searchCategorySettings();
                  }}>
                  検索
                </Button>

              </div>

              <Table
                bordered
                size='small'
                pagination={false}
                dataSource={
                  this.formRef.current
                    ? this.formRef.current.getFieldValue(
                      'searchCategorySettingsDisplayList'
                    )
                    : []
                }
                rowKey={(record) => record.id}
                scroll={{ y: resizableTableScroll(30), x: 'max-content' }}
                onRow={(record, rowIndex) => {
                  return {
                    // double click row
                    onDoubleClick: () => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 350,
                          component: (
                            <WS2705500_CategorySetting
                              record={record}
                              patternCode={this.formRef.current.getFieldsValue('PatternCode')}
                              onFinishScreen={(output) => {
                                this.saveDataCategorySetting(record.id, output.Li_record)
                                this.closeModal()
                              }}
                            />
                          ),
                        }
                      })
                    },
                  }
                }}
              >
                <Table.Column
                  width={100}
                  title='順'
                  dataIndex='display_no'
                  render={(record) => {
                    return <div style={{ textAlign: 'right' }}>{record}</div>
                  }}
                />
                <Table.Column
                  width={100}
                  title='カテゴリ'
                  dataIndex='category_code'
                  render={(record) => {
                    return <div style={{ textAlign: 'right' }}>{record}</div>
                  }}

                />
                <Table.Column
                  width={60}
                  title='判定'
                  align='center'
                  // width={50}
                  dataIndex='CategoryDeterminingPresence'
                  render={(value, record, index) => {
                    return (
                      <div >{record.CategoryDeterminingPresence === 0 ? '' : <CheckOutlined />}</div>
                    )
                  }}
                />
                <Table.Column
                  className='column-size-40'
                  title='カテゴリ名称'
                  dataIndex='category_name'
                />
                <Table.Column
                  width={50}
                  align='center'
                  fixed='right'
                  title={() => (
                    <Button
                      size='small'
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        // カテゴリを新規追加
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 350,
                            component: (
                              <WS2705500_CategorySetting
                                record={null}
                                patternCode={this.formRef.current.getFieldsValue('PatternCode')}
                                searchCategorySettingsDisplayList={this.state.searchCategorySettingsDisplayList}
                                onFinishScreen={(output) => {
                                  this.saveDataCategorySetting(null, output.Li_record)
                                  this.closeModal()
                                }}
                              />
                            ),
                          }
                        })
                      }}
                    />
                  )}
                  render={(text, record, index) => (
                    <Dropdown
                      size='small'
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='詳細'
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 1200,
                                  component: (
                                    <WS2705501_CategoryInquiry
                                      Li_categoryCode={record.category_code}
                                      Li_patternCode={this.formRef.current.getFieldsValue('PatternCode')}
                                      record={record}
                                      onFinishScreen={(output) => {
                                        this.closeModal()
                                        // this.savePatternInspection();
                                      }}
                                    />
                                  ),
                                }
                              })
                            }}
                          >
                            詳細
                          </Menu.Item>
                          <Menu.Item
                            key='修正'
                            // onClick={() => this.saveDataCategorySetting(record.id)}
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 350,
                                  component: (
                                    <WS2705500_CategorySetting
                                      record={record}
                                      patternCode={this.formRef.current.getFieldsValue('PatternCode')}
                                      searchCategorySettingsDisplayList={this.state.searchCategorySettingsDisplayList}
                                      onFinishScreen={(output) => {
                                        this.saveDataCategorySetting(record.id, output.Li_record)
                                        this.closeModal()
                                        // this.savePatternInspection();
                                      }}
                                    />
                                  ),
                                }
                              })
                            }}
                          >
                            修正
                          </Menu.Item>
                          <Menu.Item
                            key='削除'
                            onClick={() => {
                              Modal.confirm({
                                title: '確認',
                                icon: (
                                  <QuestionCircleOutlined
                                    style={{ color: '#1890ff' }}
                                  />
                                ),
                                content: '削除しますか',
                                okText: '削除',
                                cancelText: 'キャンセル',
                                onOk: () => {
                                  this.deleteDataCategorySettings(
                                    record.id,
                                    record.pattern_code,
                                    record.category_code
                                  )
                                },
                              })
                            }}
                          >
                            削除
                          </Menu.Item>
                        </Menu>
                      )}>
                      <Button size='small' icon={<MoreOutlined />} />
                    </Dropdown>
                  )}
                />
              </Table>
            </div>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS2705001_AnotherInspectItemSettingCategory)
