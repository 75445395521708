import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Table, Button, Select, Input, Modal, Tooltip, Dropdown, Menu } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import DataImportAction from 'redux/DataMigration/DataImport.action.js'

// Form labelの幅
const labelCol = {
  labelCol: { style: { width: 70 } }
}

class SiteFindingsGroupInspectionSetting extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }


  render() {
    return (
      <div className='site-findings-group-inspection-setting'>
        <Card title='検査設定'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <Form.Item
                  label='検査'
                  name='inspection_code'
                  {...labelCol}
                >
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-8'
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        this.formRef.current?.setFieldsValue({ inspection_name: '' })
                      } else {
                        console.log('検査検索')
                      }
                    }}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }} >{this.formRef.current?.getFieldValue('inspection_name')}</div>
              </div>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                danger
                icon={<DeleteOutlined />}
                style={{ display: (this.props.record) ? '' : 'none' }}
                onClick={() => {
                  // 確認モーダルを表示してから、削除
                  Modal.confirm({
                    okButtonProps: {
                      danger: true,
                      icon: <DeleteOutlined />
                    },
                    content: '削除しますか？',
                    okText: <span className='btn_label'>{'削除'}</span>,
                    cancelText: 'キャンセル',
                    onOk: () => {
                      console.log('削除')
                    }
                  })
                }}
              >
                <span className='btn_label'>
                  削除
                </span>
              </Button>

              <Button
                type='primary'
                icon={<SaveOutlined />}
                onClick={() => {
                  console.log('保存')
                }}
              >
                <span className='btn_label'>
                  保存
                </span>
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteFindingsGroupInspectionSetting)