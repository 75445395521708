import React from "react";
import { connect } from "react-redux";

import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Button, Table, Space, Modal, message } from "antd";
import { PlusOutlined, MoreOutlined } from "@ant-design/icons";

import InspectSetInfo from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/InspectSetInfo.action";
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

import WS0271001_InspectItemSearchQuerySingle from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx";
import WS1176003_CopyRegisterScreenInput from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176003_CopyRegisterScreenInput.jsx";
import WS1176002_InspectSetInfoEditModal from "pages/KY_AssociationHealthInsuranceReport/V4KY0001000_AssociateInsureParamMaintain/WS1176002_InspectSetInfoEditModal.jsx"

class WS1176001_InspectSetInfo extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },

      mainPageSize: 10,

      dataSourceMain: [],
      rowSelectMain: {},
      isLoadingTableMain: false,

      dataSourceSubSite: [],
      rowSelectSubSite: {},
      isLoadingTableSubSite: false,

      dataSourceSubFinding: [],
      rowSelectSubFinding: {},
      isLoadingTableSubFinding: false,

      test_set_code: null
    };
  }

  componentDidMount() {
    this.loadDataMainTable();
  }


  loadDataMainTable() {
    InspectSetInfo.getDataMainCosAction()
      .then(res => {
        if (res?.data) {
          this.formRef.current?.setFieldsValue({
            tableMainData: res.data
          });
          this.setState({
            dataSourceMain: res.data
          });

          if (res.data.length > 0) {
            this.getCourseSubInput(res.data[0]["test_set_code"]);
          } else {
            this.setState({
              dataSourceSubSite: []
            });
          }

          this.forceUpdate()
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  }


  getCourseSubInput(Li_SetCode) {
    this.setState({
      isLoadingTableSubSite: true,
      isLoadingTableSubFinding: true,
      test_set_code: Li_SetCode
    });
    let pram = {
      Li_SetCode: Li_SetCode
    }
    InspectSetInfo.getCourseSubInputAction(pram)
      .then(res => {
        if (res?.data) {
          this.formRef.current?.setFieldsValue({
            dataSourceSubSite: res.data
          });

          this.setState({
            dataSourceSubSite: res.data
          });
        }

        this.forceUpdate()
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() =>
        this.setState({
          isLoadingTableSubSite: false,
          isLoadingTableSubFinding: false
        })
      );
  }

  BatchProcess_F9() {
    InspectSetInfo.BatchProcess_F9Action()
      .then(res => {
        if (res?.data) {
          message.info(res.data.message);
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingTableMain: false }));
  }


  renderMenuBar = () => (
    <Space>
      <Button type="primary" onClick={this.BatchProcess_F9}>
        一括処理
      </Button>
    </Space>
  );


  SaveTableMain(record, output) {
    let params = {
      id: record ? record.id : null,
      test_set_code: output.test_set_code,
      test_set_name: output.test_set_name
    }

    InspectSetInfo.SaveTableMain(params)
      .then(res => {
        message.success(res.data.message);

        InspectSetInfo.getDataMainCosAction()
          .then(res => {
            if (res?.data) {
              this.formRef.current?.setFieldsValue({
                tableMainData: res.data
              });
              this.setState({
                dataSourceMain: res.data
              });
            }
            this.forceUpdate()
          })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }

        message.error(res.data.message);
      })
  }


  SaveSourceSubSite(record, output) {
    let params = {
      test_set_code: this.state.test_set_code,
      id: record ? record.id : null,
      exam_item: output.exam_item,
      screen_display_order: output.screen_display_order
    }

    InspectSetInfo.SaveSourceSubSite(params)
      .then(res => {
        message.success(res.data.message);
        this.getCourseSubInput(this.state.test_set_code)
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }

        message.error(res.data.message);
      })
  }


  DeleteTableMain(record) {
    let params = {
      id: record ? record.id : null,
      test_set_code: record.test_set_code,
      test_set_name: record.test_set_name
    }

    InspectSetInfo.DeleteTableMain(params)
      .then(res => {
        message.success(res.data.message);

        InspectSetInfo.getDataMainCosAction()
          .then(res => {
            if (res?.data) {
              this.formRef.current?.setFieldsValue({
                tableMainData: res.data
              });
              this.setState({
                dataSourceMain: res.data
              });
            }
            this.forceUpdate()
          })
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }

        message.error(res.data.message);
      })
  }


  DeleteSourceSubSite(record) {
    let params = {
      test_set_code: this.state.test_set_code,
      id: record ? record.id : null,
      exam_item: record.exam_item,
      screen_display_order: record.screen_display_order
    }

    InspectSetInfo.DeleteSourceSubSite(params)
      .then(res => {
        message.success(res.data.message);
        this.getCourseSubInput(this.state.test_set_code)
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }

        message.error(res.data.message);
      })
  }


  editModal(record, newFlag, setFlag) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "30%",
        component: (
          <WS1176002_InspectSetInfoEditModal
            newFlag={newFlag}
            setFlag={setFlag}
            test_set_code={record.test_set_code}
            test_set_name={record.test_set_name}
            screen_display_order={record.screen_display_order}
            exam_item={record.exam_item}
            exam_short_name={record.exam_short_name}
            exam_name={record.exam_name}
            exam_type={record.exam_type}
            onSave={(output) => {
              if (setFlag) {
                this.SaveTableMain(record, output)
              } else {
                this.SaveSourceSubSite(record, output)
              }

              this.closeModal();
            }}

            onDelete={(output) => {
              Modal.confirm({
                content: "消去してもよろしいですか？",
                okText: "はい",
                cancelText: "いいえ",
                onOk: () => {
                  if (setFlag) {
                    this.DeleteTableMain(record, output)
                  } else {
                    this.DeleteSourceSubSite(record, output)
                  }

                  this.closeModal();
                }
              });
            }}
          />
        )
      }
    });
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  }


  render() {
    return (
      <div className="inspect-set-info">
        <Card title="V4-VNS02600:検査セット情報">
          {this.renderMenuBar()}
          <hr></hr>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className="box_inner_horizontal">
              <div style={{ width: '30%' }}>
                <Table
                  bordered
                  size="small"
                  dataSource={this.state.dataSourceMain}
                  pagination={this.state.dataSourceMain.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                  loading={this.state.isLoadingTableMain}
                  rowKey={record => record.id}
                  onRow={(record, index) => ({
                    onClick: event => {
                      this.getCourseSubInput(record.test_set_code);
                    }
                  })}
                >
                  <Table.Column
                    title="コード"
                    dataIndex="test_set_code"
                    sorter={(a, b) => a.test_set_code.localeCompare(b.test_set_code)}
                    showSorterTooltip={false}
                    width={120}
                  />
                  <Table.Column
                    title="セット名称"
                    dataIndex="test_set_name"
                    sorter={(a, b) => a.test_set_name.localeCompare(b.test_set_name)}
                  />
                  <Table.Column
                    width={40}
                    align="center"
                    title={
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.editModal(false, true, true)
                        }}
                      >
                      </Button>
                    }
                    render={(text, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.editModal(record, false, true)
                        }}
                      />
                    )}
                  />
                </Table>
              </div>

              <div style={{ width: '70%' }}>
                <Table
                  bordered
                  size="small"
                  loading={this.state.isLoadingTableSubSite}
                  rowKey={record => record.id}
                  dataSource={this.state.dataSourceSubSite}
                  pagination={this.state.dataSourceSubSite.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                >
                  <Table.Column
                    title="SEQ"
                    dataIndex="screen_display_order"
                    sorter={(a, b) => a.screen_display_order - b.screen_display_order}
                    width={100}
                  />
                  <Table.Column
                    title="コード"
                    dataIndex="exam_item"
                    sorter={(a, b) => a.exam_item - b.exam_item}
                    width={100}
                  />
                  <Table.Column
                    title="略称名"
                    dataIndex="exam_short_name"
                    sorter={(a, b) => a.exam_short_name.localeCompare(b.exam_short_name, "jp")}
                  />
                  <Table.Column
                    title="検査名称"
                    dataIndex="exam_name"
                    sorter={(a, b) => a.exam_name.localeCompare(b.exam_name, "jp")}
                  />
                  <Table.Column
                    title="タイプ"
                    dataIndex="exam_type"
                    sorter={(a, b) => a.exam_type.localeCompare(b.exam_type)}
                    width={100}
                  />
                  <Table.Column
                    width={40}
                    align="center"
                    title={
                      <Button
                        size="small"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.editModal(false, true, false)
                        }}
                      ></Button>
                    }

                    render={(text, record, index) => (
                      <Button
                        size='small'
                        icon={<MoreOutlined />}
                        onClick={() => {
                          this.editModal(record, false, false)
                        }}
                      />
                    )}
                  />
                </Table>
              </div>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS1176001_InspectSetInfo);
