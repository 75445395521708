import { message } from 'antd'
import MedicalLocationService from 'services/SM_SystemMaintenance/MedicalLocation/MedicalLocationService'

const MedicalLocationAction = {
  getScreenData(data) {
    return MedicalLocationService.getScreenData(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  saveData(data) {
    return MedicalLocationService.save(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },
  Delete(data) {
    return MedicalLocationService.Delete(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }
}
export default MedicalLocationAction
