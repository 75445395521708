import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Select, Table, Modal, } from 'antd'
import moment from 'moment'

import { SearchOutlined, PlusOutlined, MoreOutlined } from '@ant-design/icons'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub'
import WS0963001_InvoiceMaintain from '../V4BL0031000_DepositWithdrawalInquiry/WS0963001_InvoiceMaintain'
import WS0948500_BillingAggregationPerson from './WS0948500_BillingAggregationPerson'
import BillingAggregationProcess from 'redux/AccountingBusiness/Invoice/BillingAggregationProcess'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'

class WS0948001_BillingAggregationProcess extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_Ctxgetname: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '請求集計処理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      showOffice: false,
      showInsurer: false,
      InvoiceDateA: '',
      PeriodAStart: '',
      PeriodDFinal: '',
      AllSelect: false,
      AggregateSpecified: null,
      selectedRowKeys: [],
      selectedRows: [],
      dataSourceSelectedRows: [],
      dataSourceSelectedRowKeys: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData = () => {
    BillingAggregationProcess.getScreenData()
      .then(res => {
        this.setState({
          InvoiceDateA: res.InvoiceDateD,
          AllSelect: res.AllSelect,
          AggregateSpecified: res.AggregateSpecified,
        })
        this.formRef.current?.setFieldsValue({
          InvoiceDateA: res.InvoiceDateD,
          AllSelect: res.AllSelect,
          AggregateSpecified: res.AggregateSpecified,
        })
        this.changeInvoiceDateA(res.InvoiceDateD)
      })
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([
      {
        name: namePath,
        value,
      },
    ])
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  checkSelected(value) {
    if (value === 4) {
      this.setState({
        showOffice: false,
        showInsurer: true,
      })
      this.setFormFieldValue('OfficeCode', '')
      this.setFormFieldValue('BranchCodeStart', '')
      this.setFormFieldValue('office_kanji_name', '')
    } else {
      if (value === 5) {
        this.setState({
          showOffice: true,
          showInsurer: false,
        })
        this.setFormFieldValue('InsurerCode', '')
        this.setFormFieldValue('insurer_kanji_name', '')
      } else {
        this.setState({
          showOffice: false,
          showInsurer: false,
        })
        this.setFormFieldValue('OfficeCode', '')
        this.setFormFieldValue('BranchCodeStart', '')
        this.setFormFieldValue('office_kanji_name', '')
        this.setFormFieldValue('InsurerCode', '')
        this.setFormFieldValue('insurer_kanji_name', '')
      }
    }
  }

  checkCourseCode() {
    let CourseCodeF = this.formRef.current?.getFieldValue('MedicalExamCourseStart')
    let CourseCodeT = this.formRef.current?.getFieldValue('MedicalExamCourseFinal')
    if (!CourseCodeT || CourseCodeF > CourseCodeT) {
      this.formRef.current?.setFieldsValue({
        MedicalExamCourseFinal: CourseCodeF,
      })
    }
  }

  showBasicCourseInquiry = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={(output) => {
              this.setFormFieldValue(
                'MedicalExamCourseStart',
                output.Lo_CourseCode
              )
              this.checkCourseCode()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showBasicCourseInquiry2 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        component: (
          <WS0265001_BasicCourseInquiry
            onFinishScreen={(output) => {
              this.setFormFieldValue(
                'MedicalExamCourseFinal',
                output.Lo_CourseCode
              )
              this.checkCourseCode()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showOfficeInfoRetrievalQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            onFinishScreen={(output) => {
              this.setFormFieldValue(
                'OfficeCode',
                output.Lio_OfficeCode
              )
              this.setFormFieldValue(
                'BranchCodeStart',
                output.Lio_BranchStoreCode
              )
              this.setFormFieldValue(
                'office_kanji_name',
                output.Lo_Kanji_Name
              )
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showInsurerInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '60%',
        component: (
          <WS0246001_InsurerInfoSearchQuery
            onFinishScreen={(output) => {
              this.setFormFieldValue(
                'InsurerCode',
                output.Lo_InsurerCode
              )
              this.setFormFieldValue(
                'insurer_kanji_name',
                output.Lo_Name
              )
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showInvoiceMaintain = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0963001_InvoiceMaintain
            Li_BillingManageNum={record.billing_management_number}
            Li_flag={1}
            Li_W1_identify={record.W1_identify}
            onFinishScreen={(output) => { }}
          />
        ),
      },
    })
  }

  showConsultInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={(output) => { }}
          />
        ),
      },
    })
  }

  changeInvoiceDateA = (date) => {
    this.setFormFieldValue(
      'PeriodAStart',
      moment(date).startOf('month').format('YYYY/MM/DD')
    )
    this.setFormFieldValue(
      'PeriodAFinal',
      moment(date).endOf('month').format('YYYY/MM/DD')
    )
  }

  createInvoice = () => {
    Modal.confirm({
      content: (<p>請求情報を作成しますか？</p>),
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        this.createInvoiceConfirm()
      }
    })
  }

  createInvoiceConfirm = async () => {
    let edditedDatas = []
    this.state.selectedRows.forEach(item => {
      let addData = {
        'reservation_number': item.reservation_number,
        'identify': item.identify,
        'identify_org': item.identify //集計処理内で元のidentifyを書き換えさせないため
      }
      edditedDatas.push(addData)
    })

    let params = {
      selectedDatas: edditedDatas,
      InvoiceDateA: moment(this.formRef.current?.getFieldValue('InvoiceDateA')).format('YYYY/MM/DD'),
    }
    BillingAggregationProcess.createInvoiceConfirm(params)
      .then(res => {
        Modal.info({
          content: (<p>請求情報作成が終了しました</p>),
          okText: 'OK',
        })
        this.setState({
          dataSource: [],
          selectedRows: []
        })
      })
  }

  /**
   * 検索ボタン処理
   * データ取得
   */
  onSearch = () => {
    let params = {
      InvoiceDateA: this.formRef.current?.getFieldValue('InvoiceDateA') ?? '',
      PeriodAStart: moment(this.formRef.current?.getFieldValue('PeriodAStart')).format('YYYY/MM/DD') ?? '',
      PeriodAFinal: moment(this.formRef.current?.getFieldValue('PeriodAFinal')).format('YYYY/MM/DD') ?? '',
      MedicalExamCourseStart: this.formRef.current?.getFieldValue('MedicalExamCourseStart') ?? '',
      MedicalExamCourseFinal: this.formRef.current?.getFieldValue('MedicalExamCourseFinal') ?? '',
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode') ?? '',
      BranchCodeStart: this.formRef.current?.getFieldValue('BranchCodeStart') ?? '',
      InsurerCode: this.formRef.current?.getFieldValue('InsurerCode') ?? '',
      AggregateSpecified: this.formRef.current?.getFieldValue('AggregateSpecified') ?? null,
      AllSelect: this.state.AllSelect,
    }
    BillingAggregationProcess.Extract(params)
      .then(res => {
        let arrID = []
        let rowKeys = []
        let rows = []
        if (res.length > 0) {
          res.forEach(element => {
            arrID.push(element.id)
            if (element.BillingExist == false) {
              let newData = String(element.reservation_number) + String(element.division)
              rowKeys.push(newData)
              rows.push(element)
            }
          })
        }
        this.setState({
          dataSource: res,
          selectedRowKeys: rowKeys,
          selectedRows: rows
        })
      })
  }

  render() {
    return (
      <div className='billing-aggregation-process' style={{ width: 1400 }}>
        <Card className='mb-2' title='請求集計処理'>
          <Form ref={this.formRef} onFinish={this.onFinish}>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <Form.Item
                      name='InvoiceDateA'
                      label='請求年月'
                      style={{ marginRight: '10px' }}
                    >
                      <VenusDatePickerCustom
                        onChange={(date, dateString) => {
                          this.changeInvoiceDateA(date)
                        }}
                        formRefDatePicker={this.formRef} format='YYYY/MM' picker='month' />
                    </Form.Item>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='PeriodAStart' label='　受診日'>
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          style={{ width: '120px' }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='PeriodAFinal'
                        label='~'
                        style={{ marginRight: '10px' }}
                      >
                        <VenusDatePickerCustom formRefDatePicker={this.formRef}
                          format='YYYY/MM/DD'
                          style={{ width: '120px' }}
                        />
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='MedicalExamCourseStart' label='　コース'>
                        <Input.Search
                          type='text'
                          style={{ width: '100px' }}
                          allowClear={true}
                          onPressEnter={() => {
                            this.showBasicCourseInquiry()
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                MedicalExamCourseStart: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.showBasicCourseInquiry()
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='MedicalExamCourseFinal'
                        label='~'
                        style={{ marginRight: '10px' }}
                      >
                        <Input.Search
                          type='text'
                          style={{ width: '100px' }}
                          allowClear={true}
                          onPressEnter={() => {
                            this.showBasicCourseInquiry2()
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                MedicalExamCourseFinal: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.showBasicCourseInquiry2()
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='box_search_inner'>
                    <Form.Item name='AggregateSpecified' label='　区分'>
                      <Select
                        initialvalue={0}
                        onChange={(value) => this.checkSelected(value)}
                        style={{ width: '100px' }}
                      >
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={4}>保険者</Select.Option>
                        <Select.Option value={5}>事業所</Select.Option>
                        <Select.Option value={6}>他団体</Select.Option>
                        <Select.Option value={9}>個人未収</Select.Option>
                      </Select>
                    </Form.Item>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='InsurerCode' label='保険者'>
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-8'
                          disabled={!this.state.showInsurer}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                insurer_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '90%',
                                  component: (
                                    <WS0246001_InsurerInfoSearchQuery
                                      Li_insurerSearch={this.formRef.current?.getFieldValue('InsurerCode')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          InsurerCode: output.Lo_InsurerCode,
                                          insurer_kanji_name: output.Lo_Name
                                        })
                                        this.setState({
                                          insurer_kanji_name: output.Lo_Name
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='insurer_kanji_name'>
                        <Input
                          type='text'
                          readOnly
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item name='OfficeCode' label='事業所'>
                        <Input.Search
                          allowClear={true}
                          type='text'
                          disabled={!this.state.showOffice}
                          className='input-search-size-8'
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: '90%',
                                  component: (
                                    <WS0247001_OfficeInfoRetrievalQuery
                                      Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
                                      Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchCodeStart')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current.setFieldsValue({
                                          OfficeCode: output.Lio_OfficeCode,
                                          BranchCodeStart: output.Lio_BranchStoreCode,
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.setState({
                                          office_kanji_name: output.Lo_Kanji_Name
                                        })
                                        this.closeModal()
                                      }}
                                    />
                                  ),
                                },
                              })
                            }
                          }}
                        />
                      </Form.Item>

                      <Form.Item name='office_kanji_name'>
                        <Input
                          type='text'
                          readOnly
                          style={{ border: 'none', background: 'transparent' }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <div className='box_search_btn_inner'>
                    <Button icon={<SearchOutlined />} onClick={() => { this.onSearch() }}>
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                rowKey={(record) => String(record.reservation_number) + String(record.division)}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected, selectedRows, nativeEvent) => {
                    let arrTemp = [...this.state.selectedRowKeys]
                    let arrTempRecord = [...this.state.selectedRows]
                    let idx = arrTemp.indexOf(String(record.reservation_number) + String(record.division))
                    if (idx === -1) {
                      arrTemp.push(String(record.reservation_number) + String(record.division))
                      arrTempRecord.push(record)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      })
                    } else {
                      arrTemp.splice(idx, 1)
                      arrTempRecord.splice(idx, 1)
                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord
                      })
                    }
                  },

                  onSelectAll: (selected, selectedRows, changeRows) => {
                    if (selected) {
                      let arrTemp = []
                      let arrTempRecord = []
                      this.state.dataSource.forEach(item => {
                        if (item.BillingExist == false) {
                          let values = String(item.reservation_number) + String(item.division)
                          arrTemp.push(values)
                          arrTempRecord.push(item)
                        }
                      })

                      this.setState({
                        selectedRowKeys: arrTemp,
                        selectedRows: arrTempRecord,
                        isSelectAll: true
                      })
                    } else {
                      this.setState({
                        selectedRowKeys: [],
                        selectedRows: [],
                        isSelectAll: false
                      })
                    }
                  },
                  getCheckboxProps: (record) => ({
                    // チェックボックスのdisabled設定
                    disabled: record.BillingExist
                  }),
                }}
              >
                <Table.Column
                  title='区分'
                  dataIndex='identifyName'
                  className='column-size-6'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'center' }}>{value}</div>
                    )
                  }}
                />
                <Table.Column
                  title='請求先名称'
                  dataIndex='office_kanji_name'
                />
                <Table.Column
                  title='請求金額'
                  dataIndex='W2_total_price'
                  className='column-size-10'
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />
                <Table.Column
                  title='受診日'
                  dataIndex='W2_consult_date'
                  className='column-size-8'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'center' }}>{value}</div>
                    )
                  }}
                />
                <Table.Column
                  title='個人ID'
                  dataIndex='W2_person_num'
                  className='column-size-10'
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    )
                  }}
                />
                <Table.Column
                  title='氏名'
                  dataIndex='W2_full_name'
                  className='column-size-30'
                />
                <Table.Column
                  title='契約情報'
                  dataIndex=''
                  className='column-size-30'
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'left' }}>
                        <span>{record.W2_visits_courses}</span>
                        <span>{record.contract_short_name}</span>
                      </div>
                    )
                  }}
                />
                <Table.Column
                  title=''
                  dataIndex=''
                  width={40}
                  className='column-size-2'
                  render={(value, record) => {
                    return (
                      <Button
                        icon={<MoreOutlined />}
                        size='small'
                        onClick={() => {
                          this.showConsultInquirySub(record)
                        }}
                      />
                    )
                  }}
                />
              </Table>
            </div>
            <div className='box_button_bottom_right'>
              <Button
                onClick={() => [
                  this.createInvoice()
                ]}
                type='primary'
              >
                <span className='btn_label'>
                  作成
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS0948001_BillingAggregationProcess)
