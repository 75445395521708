import React from "react";
import { connect } from "react-redux";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import { Card, Form, Input, Button, Col, Popconfirm, message } from "antd";
import { getFindingsData, registerFindings, deleteFindings } from "redux/InspectionMaintenance/SiteFindingsMasterMaintain/SiteFindingsMasterMaintain.actions";
import { ModalError } from "components/Commons/ModalConfirm";

const smGrid = {
  labelCol: { style: { width: 120 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


class WS0356022_RegisterFindingsData extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_findings_classification: PropTypes.any,
    Li_findings_code: PropTypes.any,
    newFlag: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);

    // document.title = '所見マスタ登録';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      site_classification_code: 0,
      findings_code: 0,
      newFlag: false
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    if (!this.props.newFlag) {
      //編集
      this.getFindingsData()
    } else {
      //新規追加
      this.setState({
        site_classification_code: this.props.Li_findings_classification,
        newFlag: this.props.newFlag
      })
    }
  }

  getFindingsData = () => {
    let params = {
      site_classification_code: this.props.Li_findings_classification,
      findings_code: this.props.Li_findings_code
    }
    getFindingsData(params)
      .then(res => {
        if (res) {
          let data = res.data[0]
          this.formRef.current?.setFieldsValue({
            new_findings_code: data.findings_code,
            search_short_name: data.search_short_name,
            findings_name: data.findings_name,
            judgment_value: data.judgment_value
          });
          this.setState({
            site_classification_code: this.props.Li_findings_classification,
            findings_code: this.props.Li_findings_code,
            newFlag: this.props.newFlag
          })
        }
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }

  onFinish() {
    let params = {
      findings_classification: this.state.site_classification_code,
      findings_code: this.state.findings_code,
      newFlag: this.state.newFlag === true ? 1 : 0,
      new_findings_code: this.formRef.current?.getFieldValue('new_findings_code') ?? '',
      search_short_name: this.formRef.current?.getFieldValue('search_short_name') ?? '',
      findings_name: this.formRef.current?.getFieldValue('findings_name') ?? '',
      judgment_value: this.formRef.current?.getFieldValue('judgment_value') ?? ''
    };
    this.registerAction(params);
  }

  registerAction(params) {
    this.setState({ isLoading: true })
    registerFindings(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {
    deleteFindings({
      id: this.props.record.id
    })
      .then(() => {
        message.success('削除しました');
        this.props.onFinishScreen()
      })
      .catch(() => message.success('エラーが発生しました'))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="所見登録・修正">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete='off'
          >
            <Col span={7}>
              <Form.Item
                name="new_findings_code"
                label="所見コード"
                {...smGrid}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "入力値が正しくありません"
                  },
                ]}>
                <Input
                  maxLength={5}
                  style={{ width: 70, textAlign: 'right' }}
                />
              </Form.Item>

              <Form.Item
                name="search_short_name"
                label="検索略名"
                {...smGrid}
              >
                <Input
                  type="text"
                  maxLength={30}
                  style={{ width: 300 }}
                />
              </Form.Item>

              <Form.Item
                name="findings_name"
                label="所見名称"
                {...smGrid}
              >
                <Input
                  type="text"
                  maxLength={60}
                  style={{ width: 550 }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="judgment_value"
                label="判定値"
                {...smGrid}>
                <Input
                  maxLength={3}
                  style={{ width: 40 }}
                />
              </Form.Item>
            </Col>

            <Form.Item >
              <div className="box_button_bottom_right"
                style={{ display: 'flex', gap: '20px' }}>
                {!this.props.newFlag ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.Li_findings_classification === 0) ? 'none' : '' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    onConfirm={() => this.deleteData()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.props.Li_findings_classification === 0}
                    >
                      削除
                    </Button>
                  </Popconfirm>
                  :
                  null
                }
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => { this.onFinish() }}
                >
                  保存
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0356022_RegisterFindingsData);
