import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import { Button, Card, message, } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { download_file } from 'helpers/CommonHelpers'
import RequestCsvOutputAction from 'redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/RequestCsvOutput.action'
import AssociateInsureQualifyConfirmAction from 'redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/AssociateInsureQualifyConfirm.action'
import { outputStatus } from 'redux/AssociationHealthInsuranceReport/AssociateInsureQualifyConfirm/ResultConfirmCsvOutput.action'
import { DownloadOutlined, } from '@ant-design/icons'

class WS2674002_R6AmendmentCsvOutput extends React.Component {

  formRef = React.createRef();

  static propTypes = {
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)

    // document.title = '出力オプション'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRows: props.selectedRows,

      isChecked: false, //R6改正対応チェックボックスON,OFF
      postalcodeChecked: false, //郵便番号(被保険者の居住地)Checkbox
      office_kanaChecked: false, //事務所名(ｶﾅ)Checkbox

      dis: false, //R06改正対応がOFFの場合、Checkboxを入力出来ないようにする
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.getCheckbox()
  }

  /**
  * モーダルを閉じる
  */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  /**
  * 出力　CSV出力
  */
  exportProc = () => {
    if (this.state.selectedRows.length > 0) {
      const params = {
        selectedList: this.state.selectedRows,
        isChecked: this.state.isChecked,
        postalcodeChecked: this.state.postalcodeChecked,
        office_kanaChecked: this.state.office_kanaChecked,
      }
      RequestCsvOutputAction.outputCSV(params)
        .then(async (res) => {
          await download_file(res)
          await message.success('出力が完了しました')
        })
    } else {
      message.error('出力対象を選択してください')
    }
  }

  resultListCSV = () => {
    let params = {
      data: this.state.dataSource
    }
    outputStatus(params)
      .then(async (res) => {
        await download_file(res)
        await message.success('出力が完了しました')
      })
  }

  /*
  * R6改正対応チェックボックス
  * ON,OFF判別
  * 郵便番号や事業所名のCheckboxのdisableも操作
  */
  handleChange = (event) => {
    const isChecked = event.target.checked;

    if (isChecked === true) {
      this.setState({
        isChecked: true,
        dis: true,
      });
    } else {
      // チェック状態を更新
      this.setState({
        isChecked: false,
        postalcodeChecked: false,
        office_kanaChecked: false,
        dis: false
      });
    }
  };

  postalChange = (event) => {
    const postalcodeChecked = event.target.checked;

    this.setState({ postalcodeChecked });
  }

  office_kanaChange = (event) => {
    const office_kanaChecked = event.target.checked;
    this.setState({ office_kanaChecked });
  }

  /*
  * //オプション設定側で協会情報提供サービス、令和6年改正対応の設定によってCheckboxが変化
  */
  getCheckbox() {
    AssociateInsureQualifyConfirmAction.getScreenData(this.params)
      .then((res) => {
        if (res.isChecked === true) { //R06改正対応をONにしている場合
          this.setState({ isChecked: true, dis: true })
          if (res.postalcodeChecked === true) { //R06改正対応で郵便番号の設定をONにしている場合
            this.setState({ postalcodeChecked: true })
          } else {
            this.setState({ postalcodeChecked: false })
          }
          if (res.office_kanaChecked === true) { //R06改正対応で事業所名の設定をONにしている場合
            this.setState({ office_kanaChecked: true })
          } else {
            this.setState({ office_kanaChecked: false })
          }
        } else { //R06改正対応をOFFにしている場合
          this.setState({
            isChecked: false,
            postalcodeChecked: false,
            office_kanaChecked: false,
            dis: false,
          })
        }
      })
  }

  render() {
    return (
      <div className='set-csv-create'>
        <Card title='出力オプション'>
          <div className='box_container' style={{ alignItems: 'flex-end', alignContent: 'center' }}>
            <div style={{ marginBottom: '0' }}> {/* marginBottom: 0 を指定 */}
              <label style={{ marginRight: '30px' }}>令和6年改正対応</label> {/* ラベル */}
              <Checkbox
                checked={this.state.isChecked} // チェック状態をstateから取得
                onChange={this.handleChange} // チェックが変更されたときにhandleChangeを呼ぶ
                style={{ marginRight: '50px' }}
              />
            </div>

            <div style={{ marginBottom: '0' }}> {/* marginBottom: 0 を指定 */}
              <label style={{ marginRight: '30px' }}>郵便番号(被保険者居住地) 出力</label> {/* ラベル */}
              <Checkbox
                checked={this.state.postalcodeChecked} // チェック状態をstateから取得
                onChange={this.postalChange} // チェックが変更されたときにhandleChangeを呼ぶ
                style={{ marginRight: '50px' }}
                disabled={!this.state.dis}
              />
            </div>

            <div style={{ marginBottom: '0' }}> {/* marginBottom: 0 を指定 */}
              <label style={{ marginRight: '30px' }}>事業所名(ｶﾅ) 出力</label> {/* ラベル */}
              <Checkbox
                checked={this.state.office_kanaChecked} // チェック状態をstateから取得
                onChange={this.office_kanaChange} // チェックが変更されたときにhandleChangeを呼ぶ
                style={{ marginRight: '50px' }}
                disabled={!this.state.dis}
              />
            </div>
          </div>

          <div className='box_button_bottom_right'>
            <Button
              icon={<DownloadOutlined />}
              type='primary'
              onClick={() => this.exportProc()}
            >
              <span className='btn_label'>
                出力
              </span>
            </Button>
          </div>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          centered={this.state.childModal.centered}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2674002_R6AmendmentCsvOutput);
