import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Checkbox, Table, message } from "antd";
import PropTypes from "prop-types";
import SettleInputSubAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/SettleInputSub.action'


// 表示行数
const rowCount = 10
class WS1350100_SettleInputSubHealthCheckupAdd extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  };


  constructor(props) {
    super(props);

    // document.title = '決済入力SUB';

    this.state = {
      billing_division: 1,
      tableData: [],
      data: []
    };
  }

  renderSelect(data) {
    switch (data) {
      case "0":
        return ""
      case "1":
        return "負担なし"
      case "2":
        return "定額負担"
      case "3":
        return "定率負担"
      case "4":
        return "保険者上限負担"
      default:
        break;
    }
  }

  getScreenData(list) {
    let params = {
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum
    }
    SettleInputSubAction.healthCheckupAdd(params)
      .then(res => {
        console.log(res);
        let array = []
        let data = res.data
        let obj, nameNumber, additional_medical_exam_name, additional_medical_exam_unit_price, AddMedicalExamItem
        // 2桁ゼロ埋めリスト
        let numberList = list
        for (let i = 0; i < rowCount; i++) {
          // 2桁ゼロ埋めの値を取得
          nameNumber = numberList[i]
          // 表示名称
          additional_medical_exam_name = data[`additional_medical_exam_name_${nameNumber}`]
          additional_medical_exam_unit_price = data[`additional_medical_exam_unit_price_${nameNumber}`].toLocaleString()
          AddMedicalExamItem = data[`AddMedicalExamItem${nameNumber}`]
          obj = {
            id: i + 1,
            additional_medical_exam_name: additional_medical_exam_name,
            additional_medical_exam_unit_price: additional_medical_exam_unit_price,
            AddMedicalExamItem: AddMedicalExamItem
          }
          // 配列に追加
          array.push(obj)
        }
        console.log(array);
        this.setState({ tableData: array, data: data })
        this.formRef.current?.setFieldsValue({
          tableData: array,
        })
        this.forceUpdate();
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  componentDidMount() {
    let array = []
    // 2ケタゼロ埋めのリストを作成、「01」開始、rowCountの分ループ
    for (let i = 0; i < rowCount; i++) {
      let ret = ('00' + (i + 1)).slice(-2)
      array.push(ret)
    }
    // 2ケタゼロ埋めのリスト
    this.setState({ rowList: array })
    this.getScreenData(array)
  }

  onChange(e, index, name) {
    let copyData = [...this.state.tableData]
    copyData[index][name] = e.target.checked
    this.setState({ tableData: copyData })
  }


  onFinish() {
    let params = {
      id: this.state.data.id,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      AddMedicalExamItem01: this.state.tableData[0].AddMedicalExamItem,
      AddMedicalExamItem02: this.state.tableData[1].AddMedicalExamItem,
      AddMedicalExamItem03: this.state.tableData[2].AddMedicalExamItem,
      AddMedicalExamItem04: this.state.tableData[3].AddMedicalExamItem,
      AddMedicalExamItem05: this.state.tableData[4].AddMedicalExamItem,
      AddMedicalExamItem06: this.state.tableData[5].AddMedicalExamItem,
      AddMedicalExamItem07: this.state.tableData[6].AddMedicalExamItem,
      AddMedicalExamItem08: this.state.tableData[7].AddMedicalExamItem,
      AddMedicalExamItem09: this.state.tableData[8].AddMedicalExamItem,
      AddMedicalExamItem10: this.state.tableData[9].AddMedicalExamItem
    }
    SettleInputSubAction.healthCheckupAddSave(params)
      .then(res => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  render() {
    return (
      <div className="settle-input-sub">
        <Card title="追加健診">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Table
              bordered
              dataSource={this.state.tableData ? this.state.tableData : []}
              size='small'
              rowKey={(record) => record.id}
              pagination={false}
            >
              <Table.Column
                title='No'
                dataIndex='id'
                width={40}
              />
              <Table.Column
                title='追加健診名'
                dataIndex='additional_medical_exam_name'
              />
              <Table.Column
                title='単価'
                dataIndex='additional_medical_exam_unit_price'
                align="right"
                width={80}
              />
              <Table.Column
                title='区分'
                dataIndex='AddMedicalExamItem'
                align="center"
                width={40}
                render={(text, record, index) => (
                  <Form.Item
                    name={['tableData', index, 'AddMedicalExamItem']}
                    valuePropName='checked'
                  >
                    <Checkbox
                      onChange={(e) => this.onChange(e, index, 'AddMedicalExamItem')}
                    />
                  </Form.Item>
                )}
              />
            </Table>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                onClick={() => this.onFinish()}
              >
                保存
              </Button>
            </div>
          </Form>
        </Card >
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1350100_SettleInputSubHealthCheckupAdd);
