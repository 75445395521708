import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Card, Form, Input, message, Button, Checkbox, Table, Modal, Select, Tag } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'

import { SearchOutlined, InfoCircleOutlined, WarningOutlined, CloseCircleOutlined, InfoOutlined, SendOutlined } from '@ant-design/icons'
import WS2584019_PersonalInfoInquirySub from 'pages/KS_CooperationRelated/V4CP0020000_InspectRequestMain/WS2584019_PersonalInfoInquirySub.jsx'
import WS0271001_InspectItemSearchQuerySingle from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0271001_InspectItemSearchQuerySingle.jsx'
import WS0433001_PrinterConfirm from 'pages/SK_IntroductionLetter/V4SK0005000_IntroduceLetterIssuedMain/WS0433001_PrinterConfirm.jsx'
import InspectItemMasterAction from 'redux/CooperationRelated/InspectRequestMain/InspectRequestMain.action'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import { download_file } from 'helpers/CommonHelpers'
import { PrinterOutlined } from '@ant-design/icons'


const grid = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
}

const smGrid = {
  labelCol: { style: { width: 50 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

const smGrid2 = {
  labelCol: { style: { width: 75 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS1063001_InspectRequestMain extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // // document.title = '検査依頼メイン'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedRowTableFirst: [],
      dataSource: [], // targetList
      selectedRowKeys: [],
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      ReserveDate: '',
      ExtractDivision: 0,
      StsForceInitialTransmission: 0,
      Indent: '',
      Text: '',
      rowSelect: {},
      listID: [],
      StsSelectOne: false,
      W1_urgent_specimen_flg: false,
      GfacilitiesNum: [],
      institutionItem: [],
      institution: 0,
      option_CP0020000: '',
      dir: '',
    }
  }

  componentDidMount() {
    this.getScreenData()
  }

  getScreenData() {
    InspectItemMasterAction.screenData()
      .then((res) => {
        let data = res ? res.data : []
        let institutionItems = res.data.institutionItem
        this.setState({
          ReserveDate: this.isEmpty(
            this.formRef.current?.getFieldValue('ReserveDate')) ? '' :
            this.formRef.current?.getFieldValue('ReserveDate').format('YYYY-MM-DD'),
          ExtractDivision: data.ExtractDivision,
          StsForceInitialTransmission: data.StsForceInitialTransmission,
          Indent: data.Indent,
          institutionItem: institutionItems,
          institution: institutionItems[0].facility_type,
          option_CP0020000: data.option_CP0020000,
        })
        this.formRef.current.setFieldsValue({
          institution: institutionItems[0].facility_type,
        })
      })
  }

  displayBtn() {
    const data = {
      ReserveDate: this.formRef.current?.getFieldValue('ReserveDate').format('YYYY-MM-DD'),
      institution: this.formRef.current?.getFieldValue('institution'),
      acceptStatus: this.formRef.current?.getFieldValue('acceptStatus'),
      sendStatus: this.formRef.current?.getFieldValue('sendStatus'),
      examCode: this.formRef.current?.getFieldValue('examCode'),
      Indent: this.state.Indent,
      option_CP0020000: this.state.option_CP0020000
    }
    InspectItemMasterAction.displayBtn(data)
      .then((res) => {
        let arrTemp = res.data.map(item => item.id)
        this.setState({
          dataSource: res.data,
          selectedRowKeys: arrTemp,
        })
      })
      .catch((error) => {
        const res = error.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
      })
  }

  getTargetList() {
    InspectItemMasterAction.targetList()
      .then((res) => {
        if (res) {
          this.formRef.current.setFieldsValue({ tableData: res })
          let arrID = []
          if (res.length > 0) {
            res.forEach((element) => {
              if (element.StsSelectAll) arrID.push(element.id)
            })
          }
          this.setState({
            dataSource: res,
            listID: arrID,
          })
        }
      })
      .catch((err) => {
        const res = err.response
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(err.response.data.message)
      })
  }

  showWS2584019_PersonalInfoInquirySub = (personalNum) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <WS2584019_PersonalInfoInquirySub
            Li_PersonalNum={personalNum}
          />
        ),
      },
    })
  }

  onSelect = (record, selected) => {
    let tempArr = [...this.state.selectedRowKeys]
    if (selected) {
      // selectedRowKeys に 該当id を追加
      tempArr.push(record.id)
    } else {
      // selectedRowKeys から 該当id を削除
      // 該当id 以外のデータを抽出
      tempArr = tempArr.filter((id) => id !== record.id)
    }
    // ソート
    tempArr.sort()
    this.setState({ selectedRowKeys: tempArr })
  }

  /**
   * チェックボックス 全選択
   * @param {*} selected
   */
  onSelectAll = (selected) => {
    let tempArr = []
    // 全選択の場合（全選択無しの場合は空の配列を設定）
    if (selected) {
      let data = [...this.state.dataSource]
      tempArr = data.map(item => item.id)
    }
    this.setState({ selectedRowKeys: tempArr })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  isEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false
  }

  ChangeCheck(id, value) {
    let array = this.state.dataSource
    let Index = array.findIndex(item => item.id === id)
    array[Index].W1_urgent_specimen_flg = value
    this.setState({ dataSource: array })
  }

  showWS0433001_PrinterConfirm = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <WS0433001_PrinterConfirm
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                this.iraiPrint(output)
              }
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              })
            }}
          />
        ,
      },
    })
  }

  iraiPrint = () => {
    const selectedIdList = [...this.state.selectedRowKeys]
    const data = [...this.state.dataSource]
    let selectedData = [], obj
    for (let i = 0; i < data.length; i++) {
      obj = { ...data[i] }
      for (let j = 0; j < selectedIdList.length; j++) {
        if (obj.id === selectedIdList[j]) {
          selectedData.push(obj)
          break
        }
      }
    }

    let params = {
      selectedData: selectedData,
    }

    InspectItemMasterAction.print_F11(params)
      .then(res => {
        let fileURL = URL.createObjectURL(res.data)
        // pdfを別タブで表示
        var fileOpen = window.open(fileURL)
      })
      .catch(error => {
        if (error.message.includes('504')) {
          return Modal.error({
            title: 'タイムアウトしました',
            content: '',
          })
        }
      })
  }

  numberingProcess = () => {
    // チェックボックスにチェックがついているデータの一覧を取得
    const selectedIdList = [...this.state.selectedRowKeys]
    const data = [...this.state.dataSource]
    let selectedData = [], obj
    for (let i = 0; i < data.length; i++) {
      obj = { ...data[i] }
      for (let j = 0; j < selectedIdList.length; j++) {
        if (obj.id === selectedIdList[j]) {
          selectedData.push(obj)
          break
        }
      }
    }

    let params = {
      selectedData: selectedData,
      option_CP0020000: this.state.option_CP0020000
    }

    if (selectedData.length !== 0) {
      InspectItemMasterAction.numberingProcessAction(params)
        .then((res) => {
          if (res.data) {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.success(json.message)
              })
          }

          this.displayBtn()
        })
        .catch((err) => {
          const res = err.response
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          } else {
            if (!res || res.data || res.data.message) {
              message.error('エラーが発生しました')
              return
            }
            message.error(err.response.data.message)
          }
        })
    } else {
      message.error('送信するデータを選択して下さい')
    }
  }

  render() {
    return (
      <div className='inspect-request-main'>
        <Card title='検査依頼'>
          <Form
            {...grid}
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              ReserveDate: moment(),
              ExtractDivision: this.state.ExtractDivision,
              StsForceInitialTransmission:
                this.state.StsForceInitialTransmission,
            }}
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <Form.Item
                      name='ReserveDate'
                      label='受診日'
                      {...smGrid}
                    >
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        allowClear={false}
                      />
                    </Form.Item>

                    <Form.Item
                      name='acceptStatus'
                      label='状態'
                      initialValue={9}
                      {...smGrid}
                    >
                      <Select style={{ width: 80 }}>
                        <Select.Option value={9}>全て</Select.Option>
                        <Select.Option value={0}>予約</Select.Option>
                        <Select.Option value={1}>受付</Select.Option>
                        <Select.Option value={2}>保留</Select.Option>
                        <Select.Option value={3}>キャンセル</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='sendStatus'
                      label='送信'
                      initialValue={0}
                      {...smGrid}
                      style={{ marginBottom: 0 }}
                    >
                      <Select style={{ width: 100 }}>
                        <Select.Option value={0}>全て</Select.Option>
                        <Select.Option value={1}>未送信</Select.Option>
                        <Select.Option value={2}>送信済</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className='box_search_inner'>
                    <Form.Item
                      name='institution'
                      label='施設'
                      style={{ width: 300 }}
                      {...smGrid2}
                    >
                      <Select >
                        {this.state.institutionItem?.map(item => (
                          <Select.Option
                            key={item.facility_type}
                            value={item.facility_type}
                          >
                            {item.facility_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name='examCode'
                      label='検査コード'
                      {...smGrid2}
                    >
                      <Input.Search allowClear
                        className='input-search-size-number-allow-clear-8'
                        maxLength={8}
                        style={{ textAlign: 'right' }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.formRef.current.setFieldsValue({ exam_name: '' })
                            this.setState({
                              childModal: {
                                ...this.state.childModal,
                                visible: true,
                                width: 800,
                                component: (
                                  <WS0271001_InspectItemSearchQuerySingle
                                    onFinishScreen={({
                                      recordData
                                    }) => {
                                      this.formRef.current.setFieldsValue({
                                        examCode: recordData.test_item_code,
                                        exam_name: recordData.exam_name
                                      })
                                      this.setState({
                                        childModal: {
                                          ...this.state.childModal,
                                          visible: false,
                                        },
                                      })
                                    }}
                                  />
                                ),
                              },
                            })
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => this.displayBtn()}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                rowKey={(record) => record.id}
                onRow={(record, index) => ({
                  onClick: (event) => {
                  },
                })}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: this.state.selectedRowKeys,
                  onSelect: (record, selected) => {
                    // 選択
                    this.onSelect(record, selected)
                  },
                  onSelectAll: (selected) => {
                    // 全選択
                    this.onSelectAll(selected)
                  }
                }}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ y: resizableTableScroll(120), x: 'max-content' }}
              >
                <Table.Column
                  title='至急'
                  className='column-size-2'
                  dataIndex='W1_urgent_specimen_flg'
                  width={45}
                  render={(value, record, index) => {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={record.W1_urgent_specimen_flg}
                          onChange={(e) => this.ChangeCheck(record.id, e.target.checked)}
                        ></Checkbox>
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='依頼No'
                  dataIndex='W1_request_num'
                  className='column-size-10'
                  render={(value) => {
                    return (
                      <div style={{ textAlign: 'right' }}>
                        {value === 0 ? '' : value}
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title='個人番号'
                  dataIndex='W1_person_id'
                  className='column-size-10'
                  render={(value) => (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )}
                />

                <Table.Column
                  title='メモ'
                  dataIndex='importance'
                  className='column-size-2'
                  width={45}
                  render={(value, record, index) => {
                    let icon = null
                    switch (record.importance) {
                      case 1:
                        icon = (<InfoCircleOutlined style={{ fontSize: 20, color: '#1890ff' }} />)
                        break
                      case 3:
                        icon = (<WarningOutlined style={{ fontSize: 20, color: '#faad14' }} />)
                        break
                      case 5:
                        icon = (<CloseCircleOutlined style={{ fontSize: 20, color: '#ff4d4f' }} />)
                        break
                      default:
                        icon = (<Button size='small' icon={<InfoOutlined />} style={{ borderRadius: '12px' }} />)
                        break
                    }
                    return (
                      <div
                        style={{
                          cursor: 'pointer',
                          textAlign: 'center'
                        }}
                        hidden={!record.W1_person_id}
                        onClick={() => {
                          // 個人情報照会 モーダル
                          this.showWS2584019_PersonalInfoInquirySub(record.W1_person_id)
                        }}>
                        {icon}
                      </div>)
                  }}
                />

                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                  className='column-size-30'
                />

                <Table.Column
                  title='性別'
                  width={40}
                  dataIndex='gender'
                  className='column-size-4'
                  render={(res) => {
                    if (res === '女性') {
                      return <div style={{ color: 'red', textAlign: 'center' }}>{res}</div>
                    } else {
                      return <div style={{ color: 'blue', textAlign: 'center' }}>{res}</div>
                    }
                  }}
                />

                <Table.Column
                  title='状態'
                  width={60}
                  dataIndex='acceptStatus'
                  className='column-size-4'
                  render={(value) => {
                    return (<div style={{ textAlign: 'center' }}>
                      {value == '予約' ?
                        <Tag color='#0d33ff'>{value}</Tag>
                        :
                        <Tag color='#e60033'>{value}</Tag>
                      }
                    </div>)
                  }}
                />

                <Table.Column
                  title='施設'
                  width={120}
                  dataIndex='facility_name'
                  className='column-size-30'
                />

                <Table.Column
                  title='送信'
                  width={40}
                  dataIndex='finishSending'
                  className='column-size-4'
                />

                <Table.Column
                  title='送信日'
                  width={120}
                  dataIndex='processing_date_on'
                  className='column-size-date'
                  render={(value) => {
                    return (
                      <div>{moment(value).format('YYYY/MM/DD (ddd)')}</div>
                    )
                  }}
                />

                <Table.Column
                  title='送信時刻'
                  width={120}
                  dataIndex='processing_time_at'
                  className='column-size-10'
                />
              </Table>
            </div>
          </Form>

          <div className='box_button_bottom_right'>
            <Button
              type='primary'
              icon={<PrinterOutlined />}
              disabled={this.state.disabledBtn}
              onClick={() => {
                this.iraiPrint()
              }}
            >
              <span className='btn_label'>
                依頼票
              </span>
            </Button>

            <Button
              icon={<SendOutlined />}
              onClick={() => {
                this.numberingProcess()
              }}
              type='primary'
            >
              <span className='btn_label'>
                作成
              </span>
            </Button>
          </div>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WS1063001_InspectRequestMain)
