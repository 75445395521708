import React from 'react'
import { connect } from 'react-redux'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Modal, message } from 'antd'
import { ArrowDownOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import WS0248001_PersonalInfoSearchQuery from '../V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery'
import PersonalNumberIntegrationAction from 'redux/basicInfo/PersonalNumberIntegration/PersonalNumberIntegration.actions'
import WS0264001_VisitHistoricalQuery from '../V4MS0003300_PersonalNumberMigration/WS0264001_VisitHistoricalQuery'
import { ModalInfo } from 'components/Commons/ModalConfirm'

class WS0402001_PersonalNumberIntegration extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '個人番号統合'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      PersonalNumF: {
        kana_name: null,
        kanji_name: null,
        birthday_on: null,
        sex: null,
      },
      PersonalNumT: {
        kana_name: null,
        kanji_name: null,
        birthday_on: null,
        sex: null,
      },
      historyDisabledF: true,
      historyDisabledT: true,
    }
  }


  /**
   * 個人データ取得
   * @param {*} personalNumType
   */
  index = (personalNumType) => {
    let id = ''
    if (personalNumType === 'PersonalNumF') {
      id = this.formRef?.current?.getFieldValue('PersonalNumF')
    } else {
      id = this.formRef?.current?.getFieldValue('PersonalNumT')
    }
    const params = {
      personalNumId: id
    }
    PersonalNumberIntegrationAction.index(params)
      .then(res => {
        if (res) {
          let data = res
          // 個人情報設定
          this.setState({
            [personalNumType]: {
              kana_name: data.kana_name,
              kanji_name: data.kanji_name,
              birthday_on: data.birthday_on,
              sex: data.sexName,
            },
          })

          if (personalNumType === 'PersonalNumF' && data.kana_name !== null) {
            // 統合元の個人情報が取得できたら、統合先のInputにフォーカス移動
            this.formRef.current?.getFieldInstance('PersonalNumT').focus()
          }
        }
      })
  }

  /**
   * 個人番号チェック
   * @param {*} id
   * @param {*} personalNumType
   */
  searchPersonalNumberID = (id, personalNumType) => {
    if (id && id !== '') {
      const params = {
        PersonalNumber: id
      }
      PersonalNumberIntegrationAction.changePersonalNumber(params)
        .then(res => {
          if (res) {
            // 受診履歴ボタンの活性・非活性
            if (personalNumType === 'PersonalNumF') {
              this.setState({ historyDisabledF: !res.historyDisabled })
            } else {
              this.setState({ historyDisabledT: !res.historyDisabled })
            }
            // 個人データ取得
            this.index(personalNumType)
          }
        })

    } else {
      this.setState({
        [personalNumType]: {
          kana_name: null,
          kanji_name: null,
          birthday_on: null,
          sex: null,
        },
      })
    }
  }

  ModalError = () => (
    Modal.error({
      content: '個人番号が無効です',
      icon: <CloseCircleOutlined style={{ color: 'red', fontSize: '30px' }} />,
      onOk: () => {
        this.formRef.current.setFieldsValue({ PersonalNumT: '' })
      }
    })
  )

  /**
   * 統合処理
   */
  integrate = () => {
    const values = this.formRef.current.getFieldsValue();
    if (values.PersonalNumF && values.PersonalNumT && (values.PersonalNumF !== values.PersonalNumT)) {
      Modal.confirm({
        content: '個人番号の統合処理を実行しますか？',
        onOk: () => {
          PersonalNumberIntegrationAction.integrate(values)
            .then(res => {
              this.searchPersonalNumberID(this.formRef?.current?.getFieldValue('PersonalNumT'), 'PersonalNumT')
              this.setState({ historyDisabledF: true })
            })
            .catch(err => message.error(err.response.data.message || 'エラーが発生しました'))
        },
        icon: <QuestionCircleOutlined style={{ color: '#006CC8' }} />,
        okText: '実　行',
        cancelText: 'キャンセル',
      })
    } else if (!values.PersonalNumF || (values.PersonalNumF === values.PersonalNumT)) {
      message.error('個人番号が同一です')
    }
  }

  /**
   * 個人番号をクリア
   * @param {*} personalNumType
   */
  clearPersonalInfo = (personalNumType) => {
    let historyDisabled = ''
    if (personalNumType === 'PersonalNumF') {
      historyDisabled = 'historyDisabledF'
    } else {
      historyDisabled = 'historyDisabledT'
    }

    this.setState({
      [personalNumType]: {
        kana_name: null,
        kanji_name: null,
        birthday_on: null,
        sex: null,
      },
      [historyDisabled]: true
    })
  }

  /**
   * 個人情報検索・照会　モーダル
   * @param {*} personalNum
   * @param {*} personalNumType
   */
  showWS0248001_PersonalInfoSearchQuery = (personalNum, personalNumType) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1500,
        component: (
          <WS0248001_PersonalInfoSearchQuery
            searchName={personalNum}
            onFinishScreen={(output) => {
              console.log(output);
              this.formRef.current.setFieldsValue({
                [personalNumType]: output.Lo_PersonalNumId
              })
              this.searchPersonalNumberID(output.Lo_PersonalNumId, personalNumType)
              this.closeModal()
            }}
          />),
      },
    })
  }

  /**
   * 受診履歴
   * @param {*} personalNumType
   */
  showWS0264001_VisitHistoricalQuery = (personalNumType) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (<WS0264001_VisitHistoricalQuery
          Li_PersonalNum={this.formRef.current?.getFieldValue(personalNumType)}
          onFinishScreen={() => {
            this.closeModal()
          }}
        />),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    const { PersonalNumF, PersonalNumT } = this.state
    return (
      <div className='personal-number-integration' style={{ width: 500 }}>
        <Card title='個人番号統合' >
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_inner_horizontal' style={{ gap: 30 }}>

              {/* PersonalNumF */}
              <div className='box_inner_vertical' style={{ width: 'fit-content', gap: 0 }}>
                <Form.Item name='PersonalNumF'>
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-10'
                    cleartriggersearch='true'
                    onPressEnter={(event) => {
                      // 個人番号変更時の処理
                      this.searchPersonalNumberID(event.target.value, 'PersonalNumF')
                    }}
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        // ✕ボタン押下
                        this.clearPersonalInfo('PersonalNumF')
                      } else {
                        // 虫眼鏡マーク押下
                        this.showWS0248001_PersonalInfoSearchQuery(value, 'PersonalNumF')
                      }
                    }}
                    onChange={(value, event) => {
                      this.clearPersonalInfo('PersonalNumF')
                    }}
                  />
                </Form.Item>
                <Button
                  disabled={this.state.historyDisabledF}
                  type='primary'
                  onClick={() => {
                    this.showWS0264001_VisitHistoricalQuery('PersonalNumF')
                  }}
                  style={{ width: 120 }}
                >
                  受診履歴
                </Button>
              </div>

              <div className='box_inner_vertical'>
                <div >{PersonalNumF.kana_name}</div>
                <div >{PersonalNumF.kanji_name}</div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span >{PersonalNumF.birthday_on}</span>
                  <span style={{
                    float: 'right', padding: '4px 8px', color: 'white',
                    display: PersonalNumF.sex === '' || PersonalNumF.sex === null || PersonalNumF.sex === undefined ? 'none' : '',
                    backgroundColor: PersonalNumF.sex === '男性' ? '#0000FF' : '#FF0000'
                  }}
                  >
                    {PersonalNumF.sex}
                  </span>
                </div>
              </div>
            </div>

            <div style={{ width: '100%', textAlign: 'center' }} >
              <span style={{ padding: '0 3px', fontSize: '30px', color: '#408080', }}>
                <ArrowDownOutlined />
              </span>
            </div>

            {/* PersonalNumT */}
            <div className='box_inner_horizontal' style={{ gap: 30 }}>
              <div className='box_inner_vertical' style={{ width: 'fit-content', gap: 0 }}>
                <Form.Item name='PersonalNumT'>
                  <Input.Search
                    allowClear
                    className='input-search-size-number-allow-clear-10'
                    cleartriggersearch='true'
                    onPressEnter={(event) => {
                      this.searchPersonalNumberID(event.target.value, 'PersonalNumT')
                    }}
                    onSearch={(value, event) => {
                      if (event.target.localName === 'input') {
                        // ✕ボタン押下
                        this.clearPersonalInfo('PersonalNumT')
                      } else {
                        // 虫眼鏡マーク押下
                        this.showWS0248001_PersonalInfoSearchQuery(value, 'PersonalNumT')
                      }
                    }}
                    onChange={(value, event) => {
                      this.clearPersonalInfo('PersonalNumT')
                    }}
                  />
                </Form.Item>


                <Button
                  disabled={this.state.historyDisabledT}
                  type='primary'
                  onClick={() => {
                    this.showWS0264001_VisitHistoricalQuery('PersonalNumT')
                  }}
                  style={{ width: 120 }}
                >
                  受診履歴
                </Button>
              </div>

              <div className='box_inner_vertical'>
                <div>{PersonalNumT.kana_name}</div>
                <div>{PersonalNumT.kanji_name}</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span >{PersonalNumT.birthday_on}</span>
                  <span style={{
                    float: 'right', padding: '4px 8px', color: 'white',
                    display: PersonalNumT.sex === '' || PersonalNumT.sex === null || PersonalNumT.sex === undefined ? 'none' : '',
                    backgroundColor: PersonalNumT.sex === '男性' ? '#0000FF' : '#FF0000'
                  }}
                  >
                    {PersonalNumT.sex}
                  </span>
                </div>
              </div>
            </div>
          </Form>

          <div className='box_button_bottom_right' style={{ marginTop: 20 }}>
            <Button
              type='primary'
              onClick={() => this.integrate()}
              disabled={(this.state.PersonalNumF.kanji_name === null || this.state.PersonalNumT.kanji_name == null)}
            >
              統合処理
            </Button>
          </div>
        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0402001_PersonalNumberIntegration)
