
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Select, Button, Table, Space, Menu, Modal, Dropdown, Tooltip, message } from 'antd'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS2583001_ConsultInquirySub from 'pages/IN_InputBusiness/V4DS0212000_ProgressSetting/WS2583001_ConsultInquirySub.jsx'
import WS1499001_ProgressInfoMaintain from 'pages/SM_SystemMaintenance/V4SM0006001_ProgressInfoMaintain/WS1499001_ProgressInfoMaintain.jsx';
import WS0432001_CsvConfirm from 'pages/TO_StatisticalServices/V2MS0140_PersonalInfoCsvOutput/WS0432001_CsvConfirm.jsx'
import { SearchOutlined, MoreOutlined, PlusOutlined, ReloadOutlined, MenuOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import ProgressSetAction from 'redux/InputBusiness/ProgressSetting/ProgressSet.action'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import moment from 'moment'
import WS0535001_VisitsKeyInfoSetting from './WS0535001_VisitsKeyInfoSetting'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import Color from 'constants/Color'
import ResultDisplay from "pages/IN_InputBusiness/SANAI_InterviewSupport/ResultDisplay.jsx"
import { download_file } from 'helpers/CommonHelpers'


const labelCol = {
  labelCol: { style: { width: 70 } }
}

class WS0256001_ProgressSet extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Lio_itemValueList: PropTypes.any,
    Lio_itemColumnList: PropTypes.any,

    onFinishScreen: PropTypes.func,
  }

  constructor(props) {
    super(props)

    // document.title = '進捗状況設定'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      itemColumnList: [], //資料一覧
      dataSource: [],
      changeCheckList: [], //変更されたチェック一覧
      KeyInfos: [],
      SpecifiedDateChar: moment(new Date())?.format('YYYY/MM/DD'), //指定日付

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },

      initialValues: {
        DateFChar: moment(new Date()),
        DateTChar: moment(new Date()),
        // BranchStoreCodeT: 99999,
        State: 4,
        KeyInfo: '',
        SpecifiedDateChar: moment(new Date()),
      },
      ResultDisplayUpdateBtn: true,

    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.index()
  }

  /**
   * 配列で何番目かを取得
   * @param {*} arrayData
   * @param {*} recordID
   * @returns
   */
  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id)
  }

  /**
   * 初期データ取得
   */
  index = () => {
    ProgressSetAction.index()
      .then((res) => {
        this.setState({
          KeyInfos: res ? res.KeyInfo : []
        })

        let data = {
          ...this.state.initialValues,
          KeyInfo: res && res.KeyInfo.length > 0 ? this.state.KeyInfos[0]?.LinkedField : ''
        }
        this.formRef.current?.setFieldsValue(data)
        // 受診者ごとの進捗を一覧で取得する
        this.getProgressList()
      })
  }

  /**
   * 受診者ごとの進捗を一覧で取得する
   */
  getProgressList() {
    const params = {
      ...this.formRef.current?.getFieldValue(),
      DateFChar: moment(this.formRef.current?.getFieldValue('DateFChar'))?.format('YYYY/MM/DD'),
      DateTChar: moment(this.formRef.current?.getFieldValue('DateTChar'))?.format('YYYY/MM/DD'),
      SpecifiedDateChar: moment(this.formRef.current?.getFieldValue('SpecifiedDateChar'))?.format('YYYY/MM/DD'),
    }

    ProgressSetAction.getProgressList(params)
      .then((res) => {
        if (res) {
          let data = [...res.itemValueList]
          let columnData = [...res.itemColumnList]
          let checkTrueCnt, indeterminateFlag, allFlag

          // タイトル部のチェックボックスの設定
          // カラム一覧でループ　
          columnData.forEach(item => {
            // 初期値設定
            checkTrueCnt = 0
            indeterminateFlag = false
            allFlag = false
            // データを全件確認
            data.forEach(x => {
              if (x['Check' + item.indexNum]) {
                // 該当のカラムのチェックボックスがtrueならばカウント
                checkTrueCnt++
              }
            })
            if (checkTrueCnt > 0 && checkTrueCnt < data.length) {
              // 1つ以上　かつ　全てではないチェックボックスがtrueの場合
              indeterminateFlag = true
            }
            if (checkTrueCnt === data.length) {
              // 全てのチェックボックスがtrue場合
              allFlag = true
            }
            // 全選択でも全解除でもない中途半端な状態の場合、ture
            item.checkIndeterminate = indeterminateFlag
            // 全選択:ture/全解除:false
            item.checkAll = allFlag
          })

          this.setState({
            dataSource: data,
            itemColumnList: columnData,
          })
        }
      })
  }

  /**
   * ChangeCheckList更新処理　セルごとに配列に追加していく
   * @param {*} reservation_number
   * @param {*} code
   * @param {*} check
   * @param {*} date
   */
  setChangeCheckList(reservationNum, code, check, date) {
    // 元のchangeCheckListをコピー
    let changeList = [...this.state.changeCheckList]
    // changeCheckList
    let index = changeList.findIndex(x => x.reservation_number === reservationNum && x.code === code)

    // チェックデータ
    const changeObj = {
      reservation_number: reservationNum,
      code: code,
      check: check,
      date: date
    }

    if (index > -1) {
      // すでにchangeCheckListにある場合、置換
      changeList[index] = changeObj
    } else {
      // 新規追加
      changeList.push(changeObj)
    }
    this.setState({
      changeCheckList: changeList
    })
  }

  /**
   * dataSourceの変更処理
   * @param {*} index
   * @param {*} field
   * @param {*} value
   */
  updateDatasource(index, field, value) {
    let data = [...this.state.dataSource]

    data[index][field] = value

    this.setState({
      dataSource: data
    })
  }

  /**
   * テーブルヘッダ、タイトル部のチェック
   * 全選択/全解除
   * @param {*} event
   */
  onCheckAllChange = (event, item) => {
    const bool = event.target.checked
    const indexNum = item.indexNum
    const code = item.code

    // == テーブルヘッダ（タイトル部）のチェックボックスを更新
    // checkIndeterminateはfalseにする
    let columnData = [...this.state.itemColumnList]
    columnData.forEach(x => {
      if (x.indexNum === indexNum) {
        x.checkAll = bool
        x.checkIndeterminate = false
      }
    })
    this.setState({
      itemColumnList: columnData
    })

    // == データ部のチェックボックスが更新
    let data = [...this.state.dataSource]
    let checkDate = ''
    if (bool) {
      // 全選択
      checkDate = moment(this.state.SpecifiedDateChar)?.format('MM/DD')
    }
    // チェック状態を反映
    data.forEach(dataItem => {
      if (Boolean(dataItem['Check' + indexNum]) !== bool) {
        dataItem['Check' + indexNum] = (bool) ? 1 : 0
        dataItem['Check' + indexNum + 'Date'] = checkDate
      }
    })
    this.setState({
      dataSource: data
    })


    // == 全選択/全解除の時にchangeCheckListを更新
    // 元のchangeCheckListをコピー
    let changeList = [...this.state.changeCheckList]
    let reservationNum
    // dataSourceのデータを元に、changeCheckListを更新する
    data.forEach(dataItem => {
      reservationNum = dataItem.reservation_number
      // changeCheckList
      let index = changeList.findIndex(x => x.reservation_number === reservationNum && x.code === code)
      // チェックデータ
      const changeObj = {
        reservation_number: reservationNum,
        code: code,
        check: bool,
        date: checkDate
      }
      if (index > -1) {
        // すでにchangeCheckListにある場合、置換
        changeList[index] = changeObj
      } else {
        // 新規追加
        changeList.push(changeObj)
      }
    })
    // changeCheckListを更新
    this.setState({
      changeCheckList: changeList
    })
  }

  /**
   * チェックボックス選択
   * @param {*} event
   * @param {*} record
   * @param {*} check
   * @param {*} checkDate
   * @param {*} item
   */
  onCheckChange = (event, record, check, checkDate, item) => {
    // 何番目かを取得
    const index = this.findIndexByID(this.state.dataSource, record.id)
    let bool, date1, date2
    if (event.target.checked) {
      // 選択時
      bool = 1
      date1 = moment(this.state.SpecifiedDateChar)?.format('MM/DD')
      date2 = moment(this.state.SpecifiedDateChar)?.format('YYYY/MM/DD')
    } else {
      // 選択を外した時
      bool = 0
      date1 = ''
      date2 = ''
    }

    // dataSourceの変更処理
    this.updateDatasource(index, checkDate, date1)
    this.updateDatasource(index, check, bool)
    // params更新処理
    this.setChangeCheckList(record.reservation_number, item.code, bool, date2)
    // タイトル部のチェックタイプ変更
    this.setCheckIndeterminate(item)
  }

  /**
   * タイトル部のチェックタイプ変更
   * @param {*} item
   */
  setCheckIndeterminate = (item) => {
    const indexNum = item.indexNum
    // == テーブルヘッダ（タイトル部）のチェックボックスを更新
    // デフォルト・全解除
    let checkAll = false
    let checkIndeterminate = false

    let trueCnt = 0
    let data = [...this.state.dataSource]
    data.forEach(dataItem => {
      if (dataItem['Check' + indexNum]) {
        trueCnt++
      }
    })
    if (data.length === trueCnt) {
      // 全選択
      checkAll = true
    }
    if (0 < trueCnt && trueCnt < data.length) {
      // 部分選択
      checkIndeterminate = true
    }

    let columnData = [...this.state.itemColumnList]
    columnData.forEach(x => {
      if (x.indexNum === indexNum) {
        x.checkAll = checkAll
        x.checkIndeterminate = checkIndeterminate
      }
    })
    this.setState({
      itemColumnList: columnData
    })
  }

  /**
   * 「更新」ボタン押下処理
   */
  update = () => {
    let params = {
      changeCheckList: this.state.changeCheckList
    }
    ProgressSetAction.update(params)
      .then((res) => {
        this.getProgressList()
      })
  }

  /**
   * チェックボックス　title
   * @param {*} item
   * @returns
   */
  renderCeckboxTitle = (item) => {
    return (
      <div style={{ display: 'flex', gap: 5 }}>
        <Checkbox
          indeterminate={item.checkIndeterminate} // 1つ以上から全選択-1までtrueとする
          checked={item.checkAll}
          onChange={(event) =>
            // 全選択・全解除
            this.onCheckAllChange(event, item)
          }
        />
        {item.name}
      </div>
    )
  }

  /**
   * チェックボックス　render
   * @param {*} value
   * @param {*} record
   * @param {*} checkNum
   * @param {*} checkCode
   * @returns
   */
  renderCeckboxValue = (value, record, item) => {
    const check = 'Check' + item.indexNum
    const checkDate = 'Check' + item.indexNum + 'Date'
    const checkManager = 'Check' + item.indexNum + 'Manager'
    const index = this.findIndexByID(this.state.dataSource, record.id)
    return (
      <Tooltip title={record[checkManager]}>
        <div style={{ display: 'flex', gap: 5 }}>
          <Checkbox
            checked={value}
            onChange={(event) => {
              // チェックボックス選択
              this.onCheckChange(event, record, check, checkDate, item)
            }}
          >
            {value ?
              <div style={{ color: '#fff', background: '#2a8e64', padding: '0 5px' }}>
                {record[checkDate]}
              </div>
              :
              null
            }</Checkbox>
        </div>
      </Tooltip>
    )
  }

  /**
   * 個人情報検索・照会　モーダル
   */
  showWS0248001_PersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS0248001_PersonalInfoSearchQuery
            date={moment(new Date()).format('YYYY/MM/DD')}
            Lo_PersonalNumId={this.formRef.current?.getFieldValue('PersonalNum')}
            onFinishScreen={(output) => {
              this.formRef.current.setFieldsValue({
                PersonalNum: output.recordData.personal_number_id,
                kanji_name: output.recordData.kanji_name
              })
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 事業所情報検索・照会 モーダル
   */
  showWS0247001_OfficeInfoRetrievalQuery = (branchStoreName) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue(branchStoreName)}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output.Lio_OfficeCode,
                [branchStoreName]: output.Lio_BranchStoreCode,
                // BranchStoreCodeFCopy: output.Lio_BranchStoreCode === 0 ? null : output.Lio_BranchStoreCode,
                office_kanji_name: output.Lo_Kanji_Name
              })
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  /**
   * 受診照会　モーダル
   * @param {*} record
   */
  showWS2583001_ConsultInquirySub = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <WS2583001_ConsultInquirySub
            Li_ReserveNum={record.reservation_number}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  showResultDisplay = (record, index) => {
    console.log(record.reservation_number)
    this.setState({
      ...this.state,
      childModal: {
        width: 'fit-content',
        visible: true,
        centered: true,
        component: (
          <ResultDisplay
            Li_ReserveNum={record.reservation_number}
            protectFlag={record.protectFlag ?? ''}
            provisionalFlag={record.provisionalFlag ?? ''}
            onUpdate={() => {
              this.index()
            }}
            // 結果入力画面での保存ボタンの状態
            onFlagUpdate={(bool) => {
              this.setState({ ResultDisplayUpdateBtn: bool })
            }}
          />
        ),
      },
    })
  }

  /**
   * 進捗情報保守　モーダル
   */
  showWS1499001_ProgressInfoMaintain = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component:
          <WS1499001_ProgressInfoMaintain
            onFinishScreen={() => {
              this.index()
            }}
          />
      },
    })
  }

  // CSV主力
  csvOutput = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0432001_CsvConfirm
            onFinishScreen={(output) => {
              if (output.Lo_StsOutput) {
                this.formRef.current.setFieldsValue({ csvName: output.Lio_Output })
                this.csvOutputProcess()
              }
              this.closeModal()
            }}
          />)
      }
    })
  }

  csvOutputProcess() {
    let params = {
      csv: this.formRef.current?.getFieldValue('csvName'),
      dataSource: this.state.dataSource,
      itemColumnList: this.state.itemColumnList
    }

    console.log(params);

    ProgressSetAction.csvOutput(params)
      .then((res) => {
        download_file(res)
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * モーダル閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }


  render() {
    return (
      <div className='progress-set'>
        <Card
          title='進捗状況設定'
          style={{ position: 'relative', minWidth: '890px' }}
        >
          <Tooltip title='オプションメニュー' placement='topRight'>
            <Dropdown
              className={(this.props.modalFlag) ? 'option_btn_right_top_modal' : 'option_btn_right_top'}
              trigger='click'
              size='small'
              overlay={() =>
                <Menu>
                  <Menu.Item
                    key='menu-1'
                    onClick={() => {
                      // 進捗情報保守
                      // ※優先度が低い為、まだ対応しない。あとで対応する
                      this.showWS1499001_ProgressInfoMaintain()
                    }}
                  >
                    進捗情報保守
                  </Menu.Item>

                  <Menu.Item
                    key='menu-2'
                    onClick={() => {
                      // 進捗情報保守
                      // ※優先度が低い為、まだ対応しない。あとで対応する
                      this.csvOutput()
                    }}
                  >
                    CSV出力
                  </Menu.Item>
                </Menu>
              }>
              <Button
                className='option_btn_right_top'
                size='small'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border' style={{ paddingTop: 0 }}>
                    <Space>
                      <Form.Item
                        label='受診日'
                        name='DateFChar'
                        {...labelCol}
                      >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          style={{ width: 112 }}
                          allowClear={false}
                        />
                      </Form.Item>

                      <Form.Item name='DateTChar' label='~' >
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          style={{ width: 112 }}
                          allowClear={false}
                        />
                      </Form.Item>
                    </Space>

                    <Space>
                      <Form.Item
                        label='個人番号'
                        name='PersonalNum'
                        {...labelCol}
                      >
                        <Input.Search
                          type='number'
                          style={{ width: 120, textAlign: 'right' }}
                          onSearch={() => {
                            // 個人情報検索・照会　モーダル
                            this.showWS0248001_PersonalInfoSearchQuery()
                          }}
                          onChange={(event) => {
                            this.formRef.current.setFieldsValue({
                              PersonalNum: event.target.value,
                              kanji_name: ''
                            })
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='kanji_name' style={{ marginRight: 10 }} >
                        <Input type='text' readOnly style={{ border: 'none' }} />
                      </Form.Item>
                    </Space>

                    <div style={{ display: 'flex', gap: '10px' }}>
                      <Form.Item
                        label='事業所'
                        name='OfficeCode'
                        {...labelCol}
                        style={{ marginBottom: 0 }}
                      >
                        <Input.Search
                          className='input-search-size-number-10'
                          type='number' style={{ width: 120, textAlign: 'right' }} min={0}
                          onSearch={() => {
                            // 事業所情報検索・照会 モーダル
                            this.showWS0247001_OfficeInfoRetrievalQuery('BranchStoreCode')
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='BranchStoreCode'
                        className='input-search-size-number-4'
                        style={{ marginBottom: 0 }}
                        maxLength={5}
                        min={0}
                      >
                        <Input.Search type='number' style={{ width: 100, textAlign: 'right' }} min={0}
                          onSearch={() => {
                            // 事業所情報検索・照会 モーダル
                            this.showWS0247001_OfficeInfoRetrievalQuery('BranchStoreCode')
                          }}
                          onBlur={(event) => {
                            this.formRef.current.setFieldsValue({
                              BranchStoreCode: event.target.value,
                              BranchStoreCodeCopy: event.target.value == 0 ? null : event.target.value
                            })
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name='office_kanji_name'
                        style={{ marginBottom: 0 }}
                      >
                        <Input type='text' readOnly style={{ border: 'none' }} />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='box_search_inner' style={{ paddingTop: 0 }}>
                    <Form.Item
                      label='状態'
                      name='State'
                    >
                      <Select style={{ width: '120px' }}>
                        <Select.Option value={0}>予約</Select.Option>
                        <Select.Option value={1}>受付</Select.Option>
                        <Select.Option value={2}>保留</Select.Option>
                        <Select.Option value={3}>キャンセル</Select.Option>
                        <Select.Option value={4}>全て</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className='box_search_btn'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      this.getProgressList()
                    }}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>

              <Table
                bordered
                size='small'
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={record => record.id}
                scroll={{ x: 'max-content', y: resizableTableScroll(70) }}
              >
                <Table.Column
                  title='受診日'
                  dataIndex='visit_date_on'
                  width={85}
                  render={(value, record, index) =>
                    <div>
                      {value !== '0000/00/00' ? moment(value)?.format('YYYY/MM/DD(ddd)') : ''}
                    </div>
                  }
                />

                <Table.Column
                  title='受付番号'
                  dataIndex='contract_number'
                  width={75}
                  render={(value, record, index) =>
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  } />

                <Table.Column
                  title='個人番号'
                  dataIndex='personal_number_id'
                  width={100}
                  render={(value, record, index) =>
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  } />
                <Table.Column
                  title='氏名'
                  dataIndex='kanji_name'
                />
                <Table.Column
                  title='事業所'
                  dataIndex='office_kanji_name'
                  render={(value, record, index) => {
                    let title = '[事業所ｺｰﾄﾞ]' + '\xa0\xa0\xa0' + record.office_code + '-' + record.branch_store_code
                    return (
                      <Tooltip title={title}>
                        <div style={{ cursor: 'pointer' }}>{value}</div>
                      </Tooltip>
                    )
                  }}
                />
                <Table.Column title='状態' dataIndex='state' width={50} align='center'
                  render={(value, record, index) =>
                    <div
                      style={{ color: Color(record.stateColorNum)?.Foreground }}
                      hidden={record.reservation_number <= 0}
                    >
                      {value}
                    </div>
                  }
                />
                <Table.Column
                  title='契約情報'
                  dataIndex='contract_short_name'
                  render={(value, record, index) =>
                    <div>
                      <span style={{ marginRight: 5 }}>
                        {record.visit_course?.toString().substr(0, 1) + '-' + record.visit_course?.toString().substr(1, 2)}
                      </span>
                      <span>
                        <Tooltip title={value}>
                          <span style={{ cursor: 'pointer' }}>{value}</span>
                        </Tooltip>
                      </span>
                    </div>
                  }
                />
                {/* チェック項目でループ */}
                {this.state.itemColumnList.map((item, i) =>
                  <Table.Column
                    key={'column-' + i}
                    dataIndex={'Check' + item.indexNum}
                    width={130}
                    title={
                      // タイトル設定
                      this.renderCeckboxTitle(item)
                    }
                    render={(value, record) =>
                      // チェックと日付の処理
                      this.renderCeckboxValue(value, record, item)
                    }
                  />
                )}
                <Table.Column
                  width={40}
                  align='center'
                  render={(record) => {
                    return (
                      <Dropdown
                        trigger='click'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='menu-1'
                              onClick={() => {
                                // 受診照会　モーダル
                                this.showWS2583001_ConsultInquirySub(record)
                              }}
                            >
                              受診照会
                            </Menu.Item>
                            <Menu.Item
                              key='menu-2'
                              onClick={() => {
                                // 面談支援　モーダル
                                this.showResultDisplay(record)
                              }}
                            >
                              面談支援
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button size='small' icon={<MoreOutlined />} />
                      </Dropdown>
                    )
                  }}
                />
              </Table>
            </div>

            <div className='box_button_bottom_right'>
              <Form.Item
                name='SpecifiedDateChar'
                label='指定日付'
                style={{ marginBottom: 0 }}
              >
                <VenusDatePickerCustom
                  formRefDatePicker={this.formRef}
                  format='YYYY/MM/DD'
                  onChange={(value) => {
                    this.setState({ SpecifiedDateChar: moment(value)?.format('YYYY/MM/DD') })
                  }} />
              </Form.Item>
              <Button
                type='primary'
                disabled={this.state.dataSource.length === 0}
                icon={<ReloadOutlined />}
                onClick={() => {
                  // 更新処理
                  this.update()
                }}
              >
                <span className='btn_label'>
                  更新
                </span>
              </Button>
            </div>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0256001_ProgressSet)
