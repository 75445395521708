import React from "react";
import { connect } from "react-redux";
import { Card, Form, Button, Select, Checkbox, Table, message, Tooltip, Dropdown, Menu } from "antd";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import SettleInputSubAction from 'redux/SpecificMedicalExamination/SpecificMedicalExamSettleProcess/SettleInputSub.action'
import { MenuOutlined } from "@ant-design/icons";
import WS1350100_SettleInputSubHealthCheckupAdd from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1350100_SettleInputSubHealthCheckupAdd'
import WS1350200_SettleInputSubSameTime from 'pages/TK_SpecificMedicalExamination/V4TK4000003_SpecificMedicalExamSettleProcess/WS1350200_SettleInputSubSameTime'


class WS1350013_SettleInputSub extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  };


  constructor(props) {
    super(props);

    // document.title = '決済入力SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0
      },
      billing_division: 1,
      tableData: [],
      data: []
    };
  }

  renderSelect(data) {
    switch (data) {
      case "0":
        return ""
      case "1":
        return "負担なし"
      case "2":
        return "定額負担"
      case "3":
        return "定率負担"
      case "4":
        return "保険者上限負担"
      default:
        break;
    }
  }

  getScreenData() {
    let params = {
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum
    }
    SettleInputSubAction.getScreenData(params)
      .then(res => {
        let obj = []
        let list = {
          id: 1,
          specificHealthClinic: '基本的な健診の項目',
          unitPrice: res.data.Expresstion_69.toLocaleString(),
          settlement: res.data.BasicHealthCheckupItems,
          classification: null
        }
        obj.push(list)
        let list2 = {
          id: 2,
          specificHealthClinic: '詳細な健診・貧血検査',
          unitPrice: res.data.Expresstion_70.toLocaleString(),
          settlement: res.data.DetailedMedicalExamanemiaInspect,
          classification: res.data.DivisionanemiaInspect
        }
        obj.push(list2)
        let list3 = {
          id: 3,
          specificHealthClinic: '詳細な健診・心電図検査',
          unitPrice: res.data.Expresstion_71.toLocaleString(),
          settlement: res.data.DetailedMedicalExamecg,
          classification: res.data.Divisionelectrocardiography
        }
        obj.push(list3)
        let list4 = {
          id: 4,
          specificHealthClinic: '詳細な健診・眼底検査',
          unitPrice: res.data.Expresstion_72.toLocaleString(),
          settlement: res.data.DetailedMedicalExamfundusExam,
          classification: res.data.DivisionfundusExam
        }
        obj.push(list4)
        let list5 = {
          id: 5,
          specificHealthClinic: '詳細な健診・クレアチニン',
          unitPrice: res.data.Expresstion_73.toLocaleString(),
          settlement: res.data.H30DetailedMedicalExamcreatinine,
          classification: res.data.H30DivisioncreatinineInspect
        }
        obj.push(list5)
        this.setState({ tableData: obj, data: res.data })
        this.formRef.current.setFieldsValue({
          tableData: obj,
          billing_division: res.data.billing_division
        })
        this.forceUpdate();
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  componentDidMount() {
    this.getScreenData();
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false
      }
    });
  };

  onFinish() {
    let params = {
      id: this.state.data.id,
      Li_CourseLevel: this.props.Li_CourseLevel,
      Li_ReserveNum: this.props.Li_ReserveNum,
      billing_division: this.formRef.current?.getFieldValue("billing_division"),
      BasicHealthCheckupItems: this.state.tableData[0].settlement,
      DetailedMedicalExamanemiaInspect: this.state.tableData[1].settlement,
      DetailedMedicalExamecg: this.state.tableData[2].settlement,
      DetailedMedicalExamfundusExam: this.state.tableData[3].settlement,
      H30DetailedMedicalExamcreatinine: this.state.tableData[4].settlement,
      DivisionanemiaInspect: this.state.tableData[1].classification,
      Divisionelectrocardiography: this.state.tableData[2].classification,
      DivisionfundusExam: this.state.tableData[3].classification,
      H30DivisioncreatinineInspect: this.state.tableData[4].classification,
    }
    SettleInputSubAction.save(params)
      .then(res => {
        this.props.onFinishScreen()
      }).catch(error => {
        const res = error.response;
        if (!res || res.data || res.data.message) {
          message.error('エラーが発生しました');
        }
      })
  }

  onChange(e, index, name) {
    let copyData = [...this.state.tableData]
    if (name === 'settlement') {
      if (index === 0) {
        copyData[index][name] = e.target.checked
      } else {
        copyData[index].classification = false
        copyData[index][name] = e.target.checked
      }
    }
    if (name === 'classification') {
      copyData[index].settlement = false
      copyData[index][name] = e.target.checked
    }
    this.setState({ tableData: copyData })
    this.formRef.current.setFieldsValue({ tableData: copyData })
  }

  render() {
    return (
      <div className="settle-input-sub">
        <Card title="決済入力">
          <Form
            ref={this.formRef}
          >
            <Tooltip title='オプションメニュー' placement='topRight'>
              <Dropdown
                className='option_btn_right_top_modal'
                trigger='click'
                size='small'
                overlay={() => {
                  return (
                    <Menu>
                      <Menu.Item
                        key='1' style={{ width: '100%' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 500,
                              component: (
                                <WS1350100_SettleInputSubHealthCheckupAdd
                                  Li_CourseLevel={this.props.Li_CourseLevel}
                                  Li_ReserveNum={this.props.Li_ReserveNum}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                  }}
                                />
                              )
                            }
                          });
                        }}
                      >
                        追加健診
                      </Menu.Item>

                      <Menu.Item
                        key='2' style={{ width: '100%' }}
                        onClick={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 500,
                              component: (
                                <WS1350200_SettleInputSubSameTime
                                  Li_CourseLevel={this.props.Li_CourseLevel}
                                  Li_ReserveNum={this.props.Li_ReserveNum}
                                  onFinishScreen={() => {
                                    this.closeModal()
                                  }}
                                />
                              )
                            }
                          })
                        }}
                      >
                        同時実施健診
                      </Menu.Item>
                    </Menu>
                  )
                }}
              >
                <Button
                  className={'option_btn_right_top_modal'}
                  size='small'
                  icon={<MenuOutlined />}
                />
              </Dropdown>
            </Tooltip>

            <div className="box_inner_vertical">
              <Form.Item
                name="billing_division"
                label="請求区分"
              >
                <Select
                  value={this.formRef.current?.getFieldValue("billing_division")}
                  style={{ width: "170px" }}
                  onChange={(value) => this.setState({ ...this.state, billing_division: value })}
                >
                  <Select.Option value={0}>請求なし</Select.Option>
                  <Select.Option value={1}>特定健診と追加検査</Select.Option>
                  <Select.Option value={2}>人間ドック</Select.Option>
                </Select>
              </Form.Item>
              <Table
                bordered
                dataSource={this.state.tableData ? this.state.tableData : []}
                size='small'
                rowKey={(record) => record.id}
                pagination={false}
              >
                <Table.Column
                  title='No'
                  dataIndex='id'
                  width={40}
                />
                <Table.Column
                  title='特定健診 集団健診'
                  dataIndex='specificHealthClinic'
                />
                <Table.Column
                  title='単価'
                  dataIndex='unitPrice'
                  align="right"
                  width={80}
                />
                <Table.Column
                  title='決済'
                  dataIndex='settlement'
                  align="center"
                  width={40}
                  render={(value, record, index) => {
                    return (
                      <Form.Item
                        name={['tableData', index, 'settlement']}
                        valuePropName='checked'
                      >
                        <Checkbox
                          onChange={(e) => this.onChange(e, index, 'settlement')}
                        />
                      </Form.Item>
                    )
                  }}
                />
                <Table.Column
                  title='区分'
                  dataIndex='classification'
                  align="center"
                  width={40}
                  render={(value, record, index) => {
                    return (
                      record.classification !== null ?
                        <Form.Item
                          name={['tableData', index, 'classification']}
                          valuePropName='checked'
                        >
                          <Checkbox
                            onChange={(e) => this.onChange(e, index, 'classification')}
                          />
                        </Form.Item>
                        :
                        null
                    )
                  }}
                />
              </Table>
            </div>
            <div className="box_button_bottom_right">
              <Button
                type="primary"
                onClick={() => this.onFinish()}
              >
                保存
              </Button>
            </div>
          </Form>
        </Card >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false
              }
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1350013_SettleInputSub);
