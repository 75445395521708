import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Table, Form, Space, Button, message } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { number_format } from "helpers/CommonHelpers";
import moment from "moment-timezone";
import ItemizedAction from "redux/AccountingBusiness/DepositWithdrawalInquiry/Itemized.action";
import ModalDraggable from "components/Commons/ModalDraggable";

import WS0964020_SelectBreakdown from "./WS0964020_SelectBreakdown";

const labelStyle = {
  labelCol: { style: { width: 90 } },
}

class WS0964001_Itemized extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_Identify: PropTypes.any,
    Li_BillingManageNum: PropTypes.any,
    Li_Destination: PropTypes.any,
    Li_Subject: PropTypes.any,
    Li_BillingPeriodBeginning: PropTypes.any,
    Li_BillingPeriodFinal: PropTypes.any,
    Li_Remarks: PropTypes.any,
    Li_AmountBilled: PropTypes.any,
    Li_ProtectionFlag: PropTypes.any,
    Li_DeleteFlag: PropTypes.any,
    Li_IntegrationFlag: PropTypes.any,
    Li_TaxRate: PropTypes.any,
    Li_LessThanTaxCircle: PropTypes.any,
    Li_SpecifyRemarksNumBytes: PropTypes.any,
    Li_TaxClassifyDefault: PropTypes.any,
    Li_SpecifySortButton: PropTypes.any,
    Lo_ScreenEditing: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    // document.title = "明細内訳";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingForm: false,

      isLoadingTable: false,
      dataSourceAll: [],
      expand: true,
      dataSource: [],
      selectedRow: {},
      selectedRowKeys: [],
      indexTable: 0,

      dataScreen: {},

      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    };
    this.handleAddRowTable = this.handleAddRowTable.bind(this)
  }

  componentDidMount() {
    this.getScreenData(true)
  }

  componentDidUpdate(prv) {
    if (this.props !== prv) {
      this.getScreenData(true)
    }
  }

  // 初期表示
  getScreenData(reload) {
    let params = {
      Li_Identify: this.props.Li_Identify,
      Li_BillingManageNum: this.props.Li_BillingManageNum,
      Li_Destination: this.props.Li_Destination,
      Li_Subject: this.props.Li_Subject,
      Li_BillingPeriodBeginning: this.props.Li_BillingPeriodBeginning,
      Li_BillingPeriodFinal: this.props.Li_BillingPeriodFinal,
      Li_Remarks: this.props.Li_Remarks,
      Li_AmountBilled: this.props.Li_AmountBilled,
      Li_ProtectionFlag: this.props.Li_ProtectionFlag,
      Li_DeleteFlag: this.props.Li_DeleteFlag,
      Li_IntegrationFlag: this.props.Li_IntegrationFlag,
      Li_TaxRate: this.props.Li_TaxRate,
      Li_LessThanTaxCircle: this.props.Li_LessThanTaxCircle,
      Li_SpecifyRemarksNumBytes: this.props.Li_SpecifyRemarksNumBytes,
      Li_TaxClassifyDefault: this.props.Li_TaxClassifyDefault,
      Li_SpecifySortButton: this.props.Li_SpecifySortButton,
      Lo_ScreenEditing: this.props.Lo_ScreenEditing,
    }

    this.setState({ isLoadingForm: true })
    ItemizedAction.getScreenData(params)
      .then((res) => {
        const data = res.data
        this.formRef.current?.setFieldsValue(data)

        let index = reload ? 0 : this.state.indexTable

        this.setState({
          dataScreen: data,
          dataSource: data.Data?.length > 0 ? data.Data : [],
          dataSourceAll: data.Data?.length > 0 ? data.Data : [],
          selectedRow: data.Data?.length > 0 ? data.Data[index] : {},
          selectedRowKeys: data.Data?.length > 0 ? [data.Data[index].id] : [],
          indexTable: index,
          isLoadingForm: false
        })
      })
      .catch((err) => {
        this.setState({ isLoadingForm: false })
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  // 左側の展開・縮小ボタン
  toggleExpand = () => {
    if (this.state.expand) {
      this.toggleExpandData(false)
    } else {
      this.toggleExpandData(true)
    }
  }

  // 展開・縮小処理
  toggleExpandData(expand) {
    let index = 0;
    if (this.state.dataSourceAll?.length == 0) return;

    if (expand) {
      if (this.state.expand) return;

      this.setState({
        expand: true,
        dataSource: this.state.dataSourceAll,
        selectedRow: this.state.dataSourceAll?.length > 0 ? this.state.dataSourceAll[index] : {},
        selectedRowKeys: this.state.dataSourceAll?.length > 0 ? [this.state.dataSourceAll[index].id] : [],
        indexTable: index,
      })
    }
    else {
      if (!this.state.expand) return;

      var newData = this.state.dataSource.slice(0);
      newData = newData.splice(0, 1);

      this.setState({
        expand: false,
        dataSource: newData,
        selectedRow: this.state.dataSourceAll?.length > 0 ? this.state.dataSourceAll[index] : {},
        selectedRowKeys: this.state.dataSourceAll?.length > 0 ? [this.state.dataSourceAll[index].id] : [],
        indexTable: index,
      })
    }
  }

  // 並替処理
  eventRearrangeBtn() {
    let params = {
      Li_SpecifySortButton: this.props.Li_SpecifySortButton,
      Li_ProtectionFlag: this.props.Li_ProtectionFlag,
      Li_IntegrationFlag: this.props.Li_IntegrationFlag,
    }

    ItemizedAction.eventRearrangeBtn(params)
      .then((res) => {
        this.getScreenData(true)
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  // 集計処理
  eventSummaryBtn(record) {
    let params = {
      Li_ProcessDivision: this.props.ProcessDivision,
      Li_ProtectionFlag: this.props.Li_ProtectionFlag,
      Li_IntegrationFlag: this.props.Li_IntegrationFlag,
      $InsurerCode: this.props.InsurerCode,
      $OfficeCode: this.props.OfficeCode,
      $BranchStoreCode: this.props.BranchStoreCode,
      $PersonalCode: this.props.PersonalCode,
      Li_TaxRate: this.props.Li_TaxRate,
      W2_item_sect: record.W2_item_sect,
      Amount1Fixed: record.Amount1Fixed,
      ReferenceNum: record.ReferenceNum,
      Li_Identification: record.row.identification,
      Lio_Billing_management_number: this.props.Li_BillingManageNum,
      Lio_office_code: this.props.office_code,
      Lio_billing_date_on: this.props.billing_date_on
    }

    ItemizedAction.eventSummaryBtn(params)
      .then((res) => {
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen({
            Lo_ScreenEditing: true
          })
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  /**
   * 項目内容更新処理
   * @param {*} output 
   * @param {*} record 
   */
  updateRecordData = (output, record) => {
    let fixParams = {
      ...output,
      Li_Identify: this.props.Li_Identify,
      Li_BillingManageNum: this.props.Li_BillingManageNum,
      Li_ItemSequentialNumber: record.W2_item_sequential_num,
    }

    ItemizedAction.updateRecordData(fixParams)
      .then(res => {
        this.getScreenData(true)
        if (this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  checkDisabledBtnAdd() {
    if (Object.keys(this.state.selectedRow).length > 0) {
      if (this.checkAddItem() || this.checkIdTemp(this.state.selectedRow.id)) {
        return true;
      } return false;
    } return false;
  }

  checkIdTemp(id) {
    if (id === '') {
      return true
    }
    return false;
  }

  checkAddItem() {
    if (this.state.dataSource.length > 0) {
      let index = this.state.dataSource.findIndex(x => !x.W2_item_sequential_num);
      if (index === -1) {
        return false;
      }
      return true
    }
  }

  // 新規追加
  async handleAddRowTable() {
    await this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0964020_SelectBreakdown
            newFlag={1}
            Li_id={''}
            Lio_SpecifyClassify={1}
            Lio_TaxClassify={1}
            Lio_Tax_ExcludedAmount={0}
            Lio_Tax_IncludedAmount={0}
            Lio_Tax={0}
            Lio_PeopleNum={0}
            Lio_TotalAmount={0}

            onFinishScreen={(output) => {
              this.updateRecordData(output, output)
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });

    this.forceUpdate();
  }

  // 削除処理
  async handleDeleteRowTable(value) {
    ItemizedAction.deleteRowTable(value)
      .then(res => {
        this.getScreenData(true)
      })
  }

  // 編集
  showSelectBreakdown = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '70%',
        component: (
          <WS0964020_SelectBreakdown
            Li_id={record?.row.id}
            Lio_ItemSequentialNum={record?.row.item_sequential_number}
            Lio_SpecifyClassify={record?.row.item_category}
            Lio_Content={record?.row.item_name}
            Lio_PersonalNum={record?.row.personal_number_id}
            Lio_Name={record?.row.full_name}
            Lio_TaxClassify={record?.row.tax_division}
            Lio_Tax_ExcludedAmount={record?.row.unit_price}
            Lio_Tax_IncludedAmount={record?.row.unit_price + record?.row.consumption_tax}
            Lio_Tax={record?.row.consumption_tax}
            Lio_PeopleNum={record?.row.implement_people_num}
            Lio_TotalAmount={record?.row.total_fee}
            Lio_Remarks={record?.row.remarks}
            Lio_DateChar={record?.row.visit_date_on}
            Li_BillingPeriodBeginning={this.state.dataScreen.Li_BillingPeriodBeginning}

            onRowDelete={(output) => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });

              let params = {
                identification: record?.row.identification,
                billing_management_number: record?.row.billing_management_number,
                item_sequential_number: record?.row.item_sequential_number,
                item_category: record?.row.item_category
              }

              this.handleDeleteRowTable(params)
            }}

            onFinishScreen={(output) => {
              this.updateRecordData(output, record)
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: false,
                },
              });
            }}
          />
        ),
      },
    });
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    const { Li_DeleteFlag, Li_IntegrationFlag, Li_ProtectionFlag, Li_SpecifySortButton } = this.props
    return (
      <div className="itemized">
        <Card className="mb-2" title="明細内訳">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <div className="box_container">
              <div className="box_inner_horizontal">
                <div className="box_search">
                  <div className="box_inner_vertical">
                    <div className="box_inner_horizontal">
                      <Form.Item
                        label='請求管理番号'
                        name="Li_BillingManageNum"
                        style={{ marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div style={{ textAlign: 'right' }}>
                          {this.state.dataScreen.Li_BillingManageNum}
                        </div>
                      </Form.Item>

                      <Form.Item
                        name="Expression_21"
                        style={{ marginBottom: 0, marginLeft: 'auto' }}
                      >
                        <div
                          style={{
                            border: 'solid 1px #ABADB3',
                            textAlign: 'center',
                            width: 50
                          }}
                        >{this.formRef.current?.getFieldValue('Expression_21')}</div>
                      </Form.Item>

                      <Form.Item
                        name="Expression_22"
                        style={{ marginBottom: 0, marginLeft: 'auto' }}
                        hidden={!(Li_DeleteFlag == 1 || Li_DeleteFlag == 2 || Li_IntegrationFlag == 1)}
                      >
                        <div>{this.formRef.current?.getFieldValue('Expression_22')}</div>
                      </Form.Item>
                    </div>

                    <div className="box_inner_horizontal">
                      <Form.Item
                        label='請求期間'
                        name="Li_BillingPeriodBeginning"
                        style={{ marginBottom: 0 }}
                        {...labelStyle}
                      >
                        <div >
                          {this.state.dataScreen.Li_BillingPeriodBeginning && this.state.dataScreen.Li_BillingPeriodBeginning != '0000-00-00' ? moment(this.state.dataScreen.Li_BillingPeriodBeginning).format('YYYY/MM/DD') : ''}
                        </div>
                      </Form.Item>
                      <Form.Item style={{ padding: '0 5px', marginBottom: 0 }}>
                        <span>~</span>
                      </Form.Item>
                      <Form.Item
                        name="Li_BillingPeriodFinal"
                        style={{ marginBottom: 0 }}
                      >
                        <div >
                          {this.state.dataScreen.Li_BillingPeriodFinal && this.state.dataScreen.Li_BillingPeriodFinal != '0000-00-00' ? moment(this.state.dataScreen.Li_BillingPeriodFinal).format('YYYY/MM/DD') : ''}
                        </div>
                      </Form.Item>
                    </div>

                    <Form.Item
                      name="Li_AmountBilled"
                      label="請求金額"
                      {...labelStyle}
                    >
                      <div>
                        {this.state.dataScreen.Li_AmountBilled > 0 ? number_format(this.state.dataScreen.Li_AmountBilled) : ''}
                      </div>
                    </Form.Item>
                  </div>
                </div>

                <div className="box_search">
                  <div className="box_inner_vertical">
                    <Form.Item
                      name="Li_Destination"
                      label="宛名"
                      style={{ marginBottom: 0 }}
                    >
                      <div >
                        {this.state.dataScreen.Li_Destination}
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="Li_Subject"
                      label="件名"
                      style={{ marginBottom: 0 }}
                    >
                      <div>
                        {this.state.dataScreen.Li_Subject}
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="Li_Remarks"
                      label="備考"
                      style={{ marginBottom: 0 }}
                    >
                      <div >
                        {this.state.dataScreen.Li_Remarks}
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <Table
                bordered
                size='small'
                style={{ cursor: 'pointer' }}
                rowClassName={(record, index) => record.id === this.state.selectedRow.id ? 'table-row-light' : ''}
                dataSource={this.state.dataSource}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                rowKey={(record) => record.id}
                scroll={{ y: 250, x: 'max-content' }}
              >
                <Table.Column
                  dataIndex="Expression_121"
                  className="column-size-2"
                  align='center'
                  render={(value, record) => {
                    return (
                      <div hidden={record.W2_item_sect != 0 && record.W2_item_sect != 10}>
                        <Button type="primary" size='small'
                          onClick={() => {
                            this.toggleExpand()
                          }}
                        >{this.state.expand ? '-' : '+'}</Button>
                      </div>
                    )
                  }}
                />

                <Table.Column
                  title="連番"
                  dataIndex="W2_item_sequential_num"
                  className="column-size-5"
                  render={(value, record) => {
                    return (
                      <div style={{ textAlign: 'right' }}>{value}</div>
                    );
                  }}
                />

                <Table.Column
                  title="区分"
                  dataIndex="W2_item_sect"
                  className="column-size-10"
                  render={(value, record) => {
                    return (
                      <div>
                        {value == 0 ? 'ｺﾒﾝﾄ区切'
                          : value == 1 ? 'セット'
                            : value == 3 ? 'コース'
                              : value == 5 ? 'コメント'
                                : value == 7 ? '明細無効'
                                  : value == 9 ? '無効'
                                    : value == 10 ? '区切' : ''
                        }
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="内容"
                  className="column-size-60"
                  render={(value, record) => {
                    return (
                      <div className="box_inner_vertical" >
                        {record.W2_content}
                        <div className="box_inner_horizontal" hidden={record.W2_item_sect == 5}>
                          <div>{record.W2_consult_date_char === '0000/00/00' ? '' : record.W2_consult_date_char}</div>
                          <div>{record.W2_full_name}</div>
                        </div>
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="税区分"
                  dataIndex='W2_tax_sect'
                  className="column-size-10"
                  render={(value, record) => {
                    return (
                      <div
                        hidden={record.W2_item_sect == 5}
                      >
                        {value == 0 ? '税指定'
                          : value == 1 ? '外税'
                            : value == 2 ? '内税'
                              : value == 3 ? '非課税' : ''
                        }
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="金額"
                  className="column-size-10"
                  render={(value, record) => {
                    let conditon_tax = !(record.W2_item_sect == 0 || record.W2_item_sect == 5 || record.W2_item_sect == 10)
                    let conditon_excluding = record.W2_tax_sect != 2 && conditon_tax
                    let conditon_include = record.W2_tax_sect == 2 && conditon_tax
                    return (
                      <div
                        className="box_inner_vertical"
                        hidden={record.W2_item_sect == 5}
                      >
                        <div hidden={!conditon_excluding} style={{ textAlign: 'right' }}>{number_format(record.W2_price_excluding_tax)}</div>
                        <div hidden={!conditon_include} style={{ textAlign: 'right' }}>{number_format(record.W2_include_tax_price)}</div>
                        <div hidden={!conditon_tax} style={{ textAlign: 'right' }}>({number_format(record.W2_tax)})</div>
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="人数"
                  dataIndex="W2_person"
                  className="column-size-10"
                  render={(value, record) => {
                    return (
                      <div
                        style={{ textAlign: 'right' }}
                        hidden={record.W2_item_sect == 5}
                      >
                        {value}
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="合計額"
                  dataIndex="W2_total_price"
                  className="column-size-10"
                  render={(value, record) => {
                    return (
                      <div
                        style={{ textAlign: 'right' }}
                        hidden={record.W2_item_sect == 5}
                      >
                        {number_format(value)}
                      </div>
                    );
                  }}
                />

                <Table.Column
                  title="備考"
                  dataIndex="W2_remark"
                  className="column-size-60"
                  render={(value, record) => {
                    return (
                      <div>{value}</div>
                    );
                  }}
                />

                {(Li_ProtectionFlag == 0 && Li_IntegrationFlag == 0) ?
                  <Table.Column
                    className="column-size-2"
                    align='center'
                    fixed='right'
                    title={
                      <Button
                        size='small'
                        disabled={this.checkDisabledBtnAdd()}
                        onClick={this.handleAddRowTable}
                        type="primary"
                        icon={<PlusOutlined />}>
                      </Button>
                    }
                    render={(text, record, index) => (
                      <Button
                        icon={<MoreOutlined />}
                        size='small'
                        onClick={() => {
                          this.showSelectBreakdown(record)
                        }}
                      />
                    )}
                  />
                  :
                  ''
                }

              </Table>
            </div>
          </Form>

          <br></br>
          <Space style={{ float: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                this.toggleExpandData(true)
              }}
            >
              展開
            </Button>

            <Button
              type="primary"
              onClick={() => {
                this.toggleExpandData(false)
              }}
            >
              縮小
            </Button>

            <Button
              type="primary"
              disabled={!(Li_SpecifySortButton && Li_ProtectionFlag == 0 && Li_IntegrationFlag == 0)}
              onClick={() => {
                this.eventRearrangeBtn()
              }}
            >
              並替
            </Button>

            <Button
              type="primary"
              disabled={!(Li_ProtectionFlag == 0 && Li_IntegrationFlag == 0)}
              onClick={() => { this.eventSummaryBtn(this.state.selectedRow) }}>
              集計
            </Button>
          </Space>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WS0964001_Itemized);
