import React from "react";
import { connect } from "react-redux";
import { Card, List, Switch, Tooltip, Modal, message, Dropdown, Menu, Form } from 'antd';
import {
  CheckOutlined, MailOutlined, DeleteFilled, TeamOutlined, ProfileOutlined, DesktopOutlined,
  CarryOutOutlined, PlusOutlined, ClockCircleOutlined, HistoryOutlined, FilterOutlined
} from '@ant-design/icons';
import moment from "moment";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS2522001_TodoCreate from 'pages/MN_Basis/V5MN0001000_Main/WS2522001_TodoCreate.jsx';
import WS2520001_SendEmail from 'pages/MN_Basis/V5MN0001000_Main/WS2520001_SendEmail.jsx';
import WS2519150_HistoryList from 'pages/MN_Basis/V5MN0001000_Main/WS2519150_HistoryList.jsx';
import DashboardAction from 'redux/MN_Basis/Dashboard/Dashboard.action';
import "./WS2519500_Dashboard.scss";
import man from "assets/img/性別-男性.png";
import woman from "assets/img/性別-女性.png";
import WS2521001_Emaillnquiry from "./WS2521001_Emaillnquiry";
import WS0206001_ScheduleRegisterScreen from "pages/YK_ReservationBusiness/V5YK0001000_ReserveStatusSearch/WS0206001_ScheduleRegisterScreen.jsx"


//======== style ========
// CardとCardの間のサイズ
const cardGap = 15;
// Card header
const cardHeadStyle = {
  minHeight: '42px',
  fontSize: '16px',
  color: '#FFFFFF',
}
const cardHeadStyleBule = {
  ...cardHeadStyle,
  backgroundImage: 'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)'
}
const cardHeadStyleOrange = {
  ...cardHeadStyle,
  backgroundImage: 'linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)'
}
// Card body
const cardBodyStyle = {
  height: '100%', padding: 0,
  overflow: "auto", display: 'flex', flexDirection: 'column'
}
// menuItemの幅
const menuItemWidth = 14
//======== style ========

class WS2519500_Dashboard extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'ダッシュボード';

    this.state = {
      loading: false,
      toDoList: [],
      examineeList: [],
      emailList: [],
      memoList: [],
      showFlag: 0,
      Usepercent: '',
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.index();
    this.intervalId = setInterval(() => {
      this.getMemo();
      this.getEmail();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  /**
   * 初期表示処理
   */
  index() {
    this.getMemo();
    this.getEmail();
    this.getTODOList(this.state.showFlag);
    this.getExaminee();
    this.getCapacity();
  }

  /**
   * 端末容量取得
   */
  getCapacity = () => {
    DashboardAction.getCapacity()
      .then((res) => {
        if (res) {
          console.log(res)
          const index = res.Usepercent.indexOf('%'); // % のインデックスを取得
          const beforePercent = res.Usepercent.substring(0, index); // インデックスまでの部分文字列を取得
          this.setState({ Usepercent: res.Usepercent, UsepercentNum: beforePercent })
          if (beforePercent > 90) {
            message.error('容量が圧迫されています。使用率:' + res.Usepercent);
          }
        }
      })
  }

  completed() {
    let param = {
      userName: this.props.userName,
      showFlag: this.state.showFlag
    }
    DashboardAction.completed(param)
      .then((res) => {
        if (res === undefined || res === null || res.length === 0) {
          this.setState({
            toDoList: [],
          })
        } else {
          this.setState({
            toDoList: res
          })
        }
      })
  }

  fullView() {

  }

  getColorBasedOnCapacity() {
    if (this.state.UsepercentNum > 90) {
      return 'red'; // 容量が80以上なら赤色
    } else {
      return 'black';
    }
  }


  getMemo() {
    let param = {
      userName: this.props.userName
    }
    DashboardAction.getMemo(param)
      .then((res) => {
        if (res === undefined || res === null || res.length === 0) {
          this.setState({
            memoList: []
          })
        } else {
          this.setState({
            memoList: res
          })
        }
      })
  }

  getEmail() {
    let param = {
      userName: this.props.userName
    }
    DashboardAction.getEmail(param)
      .then((res) => {
        if (res === undefined || res === null || res.length === 0) {
          this.setState({
            emailList: []
          })
        } else {
          this.setState({
            emailList: res
          })
        }
      })
  }

  getExaminee() {
    DashboardAction.getExaminee()
      .then((res) => {
        if (res === undefined || res === null || res.length === 0) {
          this.setState({
            examineeList: []
          })
        } else {
          this.setState({
            examineeList: res
          })
        }
      })
  }

  getTODOList(showFlag) {
    let param = {
      userName: this.props.userName,
      showFlag: showFlag
    }
    DashboardAction.getTODOList(param)
      .then((res) => {
        if (res === undefined || res === null || res.length === 0) {
          this.setState({
            toDoList: [],
            showFlag: showFlag
          })
        } else {
          this.setState({
            toDoList: res,
            showFlag: showFlag
          })
        }
      })
  }

  /**
   * CardのMaxの高さを取得する
   * @param {*} percent Cardの高さ割合
   * @param {*} gapSize CardとCardの間のサイズ
   * @param {*} gapCount 縦のCardの並びの中にgapがいくつあるか
   * @returns
   */
  getCardMaxHeight(percent, gapSize, gapCount) {
    if (gapSize === 0 || gapCount === 0) {
      return percent;
    }
    // gapの高さを考慮したCardの高さMAX値を計算、この計算がないと画面内にコンポーネントが収まらない。。
    const size = Math.floor((gapSize / (gapCount + 1)));
    return 'calc(' + percent + ' - ' + size + 'px)';
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        component: null,
      },
    });
  }

  updateTodo(obj) {
    const array = [...this.state.toDoList]

    if (obj.id === '') {
      obj.id = array.length + 1;
      // 新規追加
      array.push(obj);
    } else {
      let index = -1;

      for (let i = 0; i < array.length; i++) {
        if (obj.id === array[i].id) {
          index = i;
          break;
        }
      }
      if (index < 0) return;
      // 編集
      array.splice(index, 1, obj);
    }
    this.setState({ toDoList: array })
  }

  deleteTodo(id) {
    let param = {
      Li_id: id,
    }
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        DashboardAction.deleteTodo(param)
          .then((res) => {
            this.index()
            message.success('正常に削除されました');
          }).catch((err) => {
            message.error(err.response.data.message);
          })
      }
    });
  }

  deleteEmail(id) {
    let param = {
      Li_id: id,
    }
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        DashboardAction.deleteEmail(param)
          .then((res) => {
            this.index()
            message.success('正常に削除されました');
          }).catch((err) => {
            message.error(err.response.data.message);
          })
      }
    });
  }

  deleteMemo(id) {
    let param = {
      Li_id: id
    }
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        DashboardAction.deleteMemo(param)
          .then((res) => {
            this.index()
            message.success('正常に削除されました');
          }).catch((err) => {
            message.error(err.response.data.message);
          })
      }
    });
  }

  saveCompleted(id, event) {
    // toDoListのコピーを作る
    let array = [...this.state.toDoList]
    // 選択したcompletedを変更する
    array.forEach(item => {
      if (item.id === id) {
        item.completed = event
      }
    })
    this.setState({
      toDoList: array
    })
    let param = {
      Li_id: id,
      Li_completed: event ? 1 : 0
    }
    DashboardAction.saveCompleted(param)
      .then((res) => {
      }).catch((err) => {
        message.error(err.response.data.message);
      })
  }

  render() {

    return (
      <div className="dashboard"
        style={{ padding: '10px', height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }} >
        <div style={{ height: '100%', width: '100%', display: 'flex' }}>
          <div style={{ height: '100%', width: '60%', display: 'flex', flexDirection: 'column', gap: cardGap }}>
            {/* TODOリスト */}
            <Card
              title={
                <div >
                  <CarryOutOutlined style={{ fontSize: '20px' }} />
                  <label style={{ marginLeft: '10px' }}>{'TODO'}</label>
                </div>
              }
              headStyle={cardHeadStyleBule}
              bodyStyle={cardBodyStyle}
              style={{ height: '50%', maxHeight: this.getCardMaxHeight('50%', cardGap, 1), display: 'flex', flexDirection: 'column' }}
              size='small'
              extra={
                <div>
                  <Dropdown overlay={
                    <Menu>
                      <Menu.Item
                        key={'not_completed'}
                        onClick={() =>
                          this.getTODOList(0)
                        }
                      >
                        <div style={{ display: 'flex' }}>
                          {(this.state.showFlag === 0) ? <CheckOutlined /> : <div style={{ minWidth: menuItemWidth, maxWidth: menuItemWidth, }} />} {'未完了'}
                        </div>
                      </Menu.Item>
                      <Menu.Item
                        key={'completed'}
                        onClick={() =>
                          this.getTODOList(1)
                        }
                      >
                        <div style={{ display: 'flex' }}>
                          {(this.state.showFlag === 1) ? <CheckOutlined /> : <div style={{ minWidth: menuItemWidth, maxWidth: menuItemWidth }} />} {'完了済'}
                        </div>
                      </Menu.Item>
                      <Menu.Item
                        key={'allselect'}
                        onClick={() =>
                          this.getTODOList(2)
                        }
                      >
                        <div style={{ display: 'flex' }}>
                          {(this.state.showFlag === 2) ? <CheckOutlined /> : <div style={{ minWidth: menuItemWidth, maxWidth: menuItemWidth }} />} {'すべて'}
                        </div>
                      </Menu.Item>
                    </Menu>
                  }>
                    <FilterOutlined
                      style={{ fontSize: '20px', color: '#FFFFFF', marginRight: '15px' }}
                    />
                  </Dropdown>
                  <Tooltip placement="topLeft" title="新規追加">
                    <PlusOutlined
                      style={{ fontSize: '20px', color: '#FFFFFF' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 600,
                            component: (
                              <WS2522001_TodoCreate
                                todoData={null}
                                onFinishScreen={() => {
                                  // this.updateTodo(output);
                                  this.closeModal()
                                }}
                                index={() => {
                                  this.index()
                                }}
                              />
                            ),
                          },
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              }
            >
              <List
                dataSource={this.state.toDoList}
                renderItem={(item) => (
                  <List.Item
                    key={'todo_' + item.id}
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS2522001_TodoCreate
                              todoData={item}
                              onFinishScreen={() => {
                                // this.updateTodo(output);
                                this.closeModal()
                              }}
                              index={() => {
                                this.index()
                              }}
                            />
                          ),
                        },
                      });
                    }}>
                    <Switch
                      style={{ backgroundColor: (item.completed) ? "#52c41a" : '', margin: '0 10px' }}
                      checkedChildren={<CheckOutlined />}
                      defaultChecked={item.completed}
                      onChange={(event) => this.saveCompleted(item.id, event)}
                    />
                    <List.Item.Meta
                      style={{ textDecoration: (item.completed) ? 'line-through' : 'none' }}
                      title={<label style={{ fontWeight: 'bold' }}>{item.title}</label>}
                      description={item.content}
                    />
                    <div style={{ textDecoration: (item.completed) ? 'line-through' : 'none' }}>
                      {moment(item.date_on).format('YYYY/MM/DD')}  {(item.time_at !== null) ? moment(item.time_at, 'HH:mm').format('HH:mm') : ''}
                    </div>
                    <DeleteFilled
                      onClick={() => this.deleteTodo(item.id)}
                      style={{ color: '#8c8c8c', marginLeft: '10px', marginRight: '10px', fontSize: '20px' }}
                    />
                  </List.Item>
                )}
              />
            </Card>
            {/* メール */}
            <Card
              title={
                <div >
                  <MailOutlined style={{ fontSize: '20px' }} />
                  <label style={{ marginLeft: '10px' }}>{'メール'}</label>
                </div>
              }
              headStyle={cardHeadStyleBule}
              bodyStyle={cardBodyStyle}
              style={{ height: '50%', maxHeight: this.getCardMaxHeight('50%', cardGap, 1), display: 'flex', flexDirection: 'column' }}
              size='small'
              extra={
                <div style={{ display: 'flex' }}>
                  <Tooltip placement="topLeft" title="履歴">
                    <HistoryOutlined
                      style={{ fontSize: '20px', color: '#FFFFFF', marginRight: '15px' }}
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 900,
                            component: (
                              <WS2519150_HistoryList
                                onFinishScreen={() => {
                                  this.closeModal()
                                }}
                                index={() => {
                                  this.index();
                                }}
                              />
                            ),
                          },
                        });
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="topLeft" title="新規追加">
                    <PlusOutlined
                      style={{ fontSize: '20px', color: '#FFFFFF' }}
                      shape="round"
                      onClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 600,
                            component: (
                              <WS2520001_SendEmail
                                onFinishScreen={() => {
                                  this.closeModal()
                                }}
                                index={() => {
                                  this.index();
                                }}
                              />
                            ),
                          },
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              }
            >
              <List
                dataSource={this.state.emailList}
                renderItem={item => (
                  <List.Item key={'email_' + item.id}
                    onDoubleClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS2521001_Emaillnquiry
                              selectEmail={item.id}
                              index={() => this.index()}
                              onFinishScreen={(output) => {
                                this.updateTodo(output);
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  >
                    <List.Item.Meta
                      // avatar={<Avatar src={item.picture.large} />}
                      style={{ margin: '0 10px' }}
                      title={<label style={{ fontWeight: 'bold', color: item.emergency == 1 ? 'red' : 'black' }}>{item.title}</label>}
                      description={item.transmission_user_code}
                    />
                    <div style={{ marginRight: '10px' }}>{moment(item.date_on).format('YYYY/MM/DD')} {item.time_at}</div>
                    <DeleteFilled
                      onClick={() => this.deleteEmail(item.id)}
                      style={{ color: '#8c8c8c', marginLeft: '10px', marginRight: '10px', fontSize: '20px' }}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </div>

          <div style={{ minWidth: cardGap }} />

          <div style={{ height: '100%', width: '40%', display: 'flex', flexDirection: 'column', gap: cardGap }}>
            {/* 本日の受診者 */}
            <Card
              title={
                <div >
                  <TeamOutlined style={{ fontSize: '20px' }} />
                  <label style={{ marginLeft: '10px' }}>{'本日の受診者'}</label>
                </div>
              }
              headStyle={cardHeadStyleOrange}
              bodyStyle={cardBodyStyle}
              style={{ height: '70%', maxHeight: this.getCardMaxHeight('70%', cardGap, 1), paddingBottom: '10px', display: 'flex', flexDirection: 'column' }}
              size='small'
            >
              <List
                dataSource={this.state.examineeList}
                renderItem={item => (
                  <List.Item key={'examinee_' + item.id}>
                    <ClockCircleOutlined style={{ marginLeft: '10px' }} />
                    <div style={{ margin: '0 20px 0 5px' }}>{item.period_time}</div>
                    <div style={{ marginRight: '5px', color: '#8c8c8c' }}>{'ID:'}</div>
                    <div style={{ marginRight: '20px', width: 80, textAlign: "right" }}>{item.personal_number_id}</div>
                    <div style={{ marginRight: '20px', width: 120, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item.office_kanji_name}</div>
                    <img
                      src={
                        item.sex === 1 ? man : woman
                      }
                      alt="sex"
                      style={{ bottom: "0", right: "0", height: 40, width: 40, marginRight: 10 }}
                    />
                    <List.Item.Meta
                      style={{ marginRight: '10px', width: '160px' }}
                      title={<div style={{ fontWeight: 'bold', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item.kanji_name}</div>}
                      description={item.kana_name}
                    />
                  </List.Item>
                )}
              />
            </Card>
            {/* メモ */}
            <Card
              title={
                <div >
                  <ProfileOutlined style={{ fontSize: '20px' }} />
                  <label style={{ marginLeft: '10px' }}>{'メモ'}</label>
                </div>
              }
              headStyle={cardHeadStyleBule}
              // headStyle={{ ...cardHeadStyle, color: '#000000' }}
              bodyStyle={cardBodyStyle}
              style={{ height: '30%', maxHeight: this.getCardMaxHeight('30%', cardGap, 1), display: 'flex', flexDirection: 'column' }}
              size='small'
              extra={
                <Tooltip placement="topLeft" title="新規追加">
                  <PlusOutlined
                    style={{ fontSize: '20px', color: '#FFFFFF' }}
                    shape="round"
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: 600,
                          component: (
                            <WS0206001_ScheduleRegisterScreen
                              flag={1}
                              onScreenFinish={() => {
                                this.closeModal()
                                this.getMemo()
                              }}
                            />
                          ),
                        },
                      });
                    }}
                  />
                </Tooltip>
              }
            >
              <List
                dataSource={this.state.memoList}
                renderItem={item => (
                  <List.Item key={'memo_' + item.id}>
                    <List.Item.Meta
                      style={{ margin: '0 10px' }}
                      title={<label style={{ fontWeight: 'bold' }}>{item.title}</label>}
                      description={item.content}
                      onDoubleClick={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 600,
                            component: (
                              <WS0206001_ScheduleRegisterScreen
                                flag={0}
                                // Li_ModifyPermission={item.Li_ModifyPermission}
                                Li_Situation={1}
                                Li_Date_FI={moment(item.date_on).format("YYYY/MM/DD")}
                                Li_Title={item.title}
                                Li_Comment={item.content}
                                Li_Date_FL={moment(item.updated_on).format("YYYY/MM/DD")}
                                Li_UserId={item.user_code}
                                Li_DisplayDate={""}
                                onScreenFinish={() => {
                                  this.closeModal()
                                  this.getMemo()
                                }}
                              />
                            ),
                          },
                        });
                      }}
                    />
                    <DeleteFilled
                      onClick={() => this.deleteMemo(item.id)}
                      style={{ color: '#8c8c8c', marginLeft: '10px', marginRight: '10px', fontSize: '20px' }}
                    />
                  </List.Item>
                )}
              />
            </Card>
            <Card
              title={
                <div>
                  <DesktopOutlined style={{ fontSize: '20px' }} />
                  <label style={{ marginLeft: '10px' }}>{'容量'}</label>
                </div>
              }
              headStyle={cardHeadStyleBule}
              bodyStyle={cardBodyStyle}
              style={{ height: '10%', maxHeight: this.getCardMaxHeight('70%', cardGap, 1), paddingBottom: '10px', display: 'flex', flexDirection: 'column' }}
              size='small'
            >
              <Form>
                <Form.Item>
                  <div style={{ color: this.getColorBasedOnCapacity(), padding: 10 }}>{this.state.Usepercent}</div>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div >
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    );
  }
}
const mapStateToProps = ({ userReducer }) => ({
  userName: userReducer.user.username
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS2519500_Dashboard);
