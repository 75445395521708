import React from 'react'
import { connect } from 'react-redux'
import { Card, Tabs, Form, Button, Table, Input, TimePicker, message, Modal, Select } from 'antd'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { SearchOutlined, } from '@ant-design/icons'
import LogQueryAction from 'redux/Others/LogQuery/LogQuery.action.js'
import moment from 'moment'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import WS0248001_PersonalInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery.jsx'
import WS0273001_VisitsTargetSearchQuery from "pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS0273001_VisitsTargetSearchQuery"
import WS0262068_UserChoice from "pages/ZZ_Others/V4DS0230000_LogDisplay/WS0262068_UserChoice"


const today = moment(new Date().toISOString())
const format = 'YYYY/MM/DD'
const timeformat = 'HH:mm'



class ApplicationLogQuery extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // // document.title = '検査依頼メイン'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      selectedTabKey: '1',
      selectItem: [],
      KindSelectItem: []

    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      DateFChar: today.format(format),
      DateTChar: today.format(format),
      TimeFChar: moment('00:00:00', 'HH:mm'),
      TimeTChar: moment('23:59:00', 'HH:mm'),
    })

    // ログを取得
    this.getLog()
  }

  getLog = () => {
    const params = { initial_display: true }
    LogQueryAction.getLogApplication(params)
      .then((res) => {
        if (res) {
          const selectItem = []
          res.map(item => {
            selectItem.push({ published_name: item.published_name })
          })
          const uniqueArray = selectItem.filter((item, index, self) =>
            index === self.findIndex(t => (
              t.published_name === item.published_name
            ))
          );
          this.setState({
            dataSource: res,
            selectItem: uniqueArray
          })
        }
        LogQueryAction.getLogKind(params)
          .then((res) => {

            if (res) {
              const KindSelectItem = []
              res.map(item => {
                KindSelectItem.push({ PublicName: item.PublicName, LogKind: item.LogKind })
              })
              const KinduniqueArray = KindSelectItem.filter((item, index, self) =>
                index === self.findIndex(t => (
                  t.LogKind === item.LogKind
                ))
              );
              this.setState({
                KindSelectItemOrigin: res,
                KindSelectItem: KinduniqueArray
              })
            }

          })
      })

  }

  /**
   * 選択 処理
   * @param {*} record
   */
  onSelect = (record) => {
    Modal.info({
      width: 600,
      title: <div style={{
        whiteSpace: 'pre-line'
      }}>{'明細'}<br />{record.recorded_contents}</div>,
      onOk: () => {
      }
    })
  }

  onSearch = () => {
    // 検索記録日か個人番号が必須
    // 記録日と時間の範囲チェック
    const DateFChar = this.formRef.current.getFieldValue('DateFChar') ?? ''
    const DateTChar = this.formRef.current.getFieldValue('DateTChar') ?? ''

    if (!DateFChar && !DateTChar && !this.formRef.current.getFieldValue('PersonalNumberId')) {
      Modal.error({
        width: 300,
        title: '記録日か個人を指定してください',
        onOk: () => {
        }
      })
    } else if (DateFChar > DateTChar) {
      Modal.error({
        width: 300,
        title: '日付の範囲が不正です',
        onOk: () => {
        }
      })
    } else {
      const params = {
        initial_display: false,
        DateFChar: DateFChar,
        DateTChar: DateTChar,
        PersonalNumberId: this.formRef.current.getFieldValue('PersonalNumberId') ?? '',
        ProgramName: this.formRef.current.getFieldValue('ProgramName') ?? '',
        ReservationNumberId: this.formRef.current.getFieldValue('ReservationNumberId') ?? '',
        SearchWord: this.formRef.current.getFieldValue('SearchWord') ?? '',
        TimeFChar: this.formRef.current.getFieldValue('TimeFChar') ? this.formRef.current.getFieldValue('TimeFChar').format("HH:mm") : '',
        TimeTChar: this.formRef.current.getFieldValue('TimeTChar') ? this.formRef.current.getFieldValue('TimeTChar').format("HH:mm") : '',
        VisitDateChar: this.formRef.current.getFieldValue('VisitDateChar') ? this.formRef.current.getFieldValue('VisitDateChar').format("YYYY/MM/DD") : '',
        logKind: this.formRef.current.getFieldValue('logKind') ?? '',
        user_code: this.formRef.current.getFieldValue('user_code') ?? '',
        KindSelectItemOrigin: this.state.KindSelectItemOrigin
      }
      LogQueryAction.getLogApplication(params)
        .then((res) => {
          if (res) {
            this.setState({
              dataSource: res
            })
          }
        })
    }
  }

  /**
 * 個人検索・照会　モーダル
 */
  showWS0248001_PersonalInfoSearchQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0248001_PersonalInfoSearchQuery
            // searchName={PersonalNumId}
            date={moment(new Date()).format('YYYY/MM/DD')}
            searchName={this.formRef.current?.getFieldValue('PersonalNumberId')}
            Li_OfficeCode=''
            Li_BranchStoreCode=''
            onFinishScreen={(output) => {
              if (output.recordData.personal_number_id !== this.state.PersonalNumId) {
                this.formRef.current?.setFieldsValue({
                  PersonalNumberId: output.recordData.personal_number_id,
                  kanji_name: output.recordData.kanji_name
                })
                this.forceUpdate()
              }
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showWS0273001_VisitsTargetSearchQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1600,
        component:
          <WS0273001_VisitsTargetSearchQuery
            LnkOutReserveNum={this.formRef.current.getFieldValue('ReservationNumberId')}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                ReservationNumberId: output.recordData.reservation_number
              })
              this.closeModal()
            }}
          />
        ,
      },
    })
  }

  showWS0262068_UserChoice() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1600,
        component:
          <WS0262068_UserChoice
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                user: output.recordData.user_name,
                user_code: output.recordData.user_code
              })
              this.closeModal()
            }}
          />
        ,
      },
    })
  }


  /**
   * モーダルを閉じる
   */
  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  render() {
    return (
      <div className='log-query'>
        <Card title='アプリケーションログ照会'>
          <Form ref={this.formRef}>
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border'>
                    <div className='box_inner_vertical'>
                      <div className='box_inner_horizontal'>
                        <Form.Item label='記録日' name='DateFChar' style={{ marginBottom: 0 }}>
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={format}
                          />
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }}> ~ </Form.Item>

                        <Form.Item name='DateTChar' style={{ marginBottom: 0, marginRight: 10 }}>
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={format}
                          />
                        </Form.Item>
                      </div>
                      <div className='box_inner_horizontal'>
                        <Form.Item label='時間　' name='TimeFChar' style={{ marginBottom: 0 }}>
                          <TimePicker formRefDatePicker={this.formRef} format={timeformat} style={{ width: 85 }} />
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }}> ~ </Form.Item>

                        <Form.Item name='TimeTChar' label='' style={{ marginBottom: 0 }}>
                          <TimePicker formRefDatePicker={this.formRef} format={timeformat} style={{ width: 85 }} />
                        </Form.Item>
                      </div>
                      <Form.Item label='　検索' name='SearchWord' style={{ width: 200 }}>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='box_search_inner_border' >
                    <Form.Item label='受診日　' name='VisitDateChar'>
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        format={format}
                      />
                    </Form.Item>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='個人番号' name='PersonalNumberId' style={{ marginRight: 10 }} >
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-allow-clear-10'
                          style={{ textAlign: 'right' }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== 'input') {
                              this.showWS0248001_PersonalInfoSearchQuery()
                            }
                          }}
                        />
                      </Form.Item>

                    </div>
                    <Form.Item label='予約番号' name='ReservationNumberId' style={{ marginRight: 10 }}>
                      <Input.Search
                        allowClear={true}
                        className='input-search-size-number-allow-clear-10'
                        style={{ textAlign: 'right' }}
                        onSearch={(value, event) => {
                          if (event.target.localName !== 'input') {
                            this.showWS0273001_VisitsTargetSearchQuery()
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='box_search_inner'>
                    <Form.Item label='公開名　' name='ProgramName' >
                      <Select allowClear={true}>
                        {this.state.selectItem.map((item, index) => (
                          <Select.Option key={index} value={item.published_name}>{item.published_name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='ユーザー' name='user_code'>
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-allow-clear-10'
                          style={{ textAlign: 'right' }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== 'input') {
                              this.showWS0262068_UserChoice()
                            }
                          }}
                        />
                      </Form.Item>

                    </div>
                    <Form.Item label='ログ種別' name='logKind'>
                      <Select allowClear={true}>
                        {this.state.KindSelectItem.length > 0 ?
                          this.state.KindSelectItem.map((item, index) => (
                            <Select.Option key={index} value={item.LogKind}>{item.LogKind}</Select.Option>
                          ))
                          : <Select.Option></Select.Option>}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      this.onSearch()
                    }}
                  >
                    <span className='btn_label'>
                      検索
                    </span>
                  </Button>
                </div>
              </div>

              <div className='box_container' style={{ gap: 0 }}>
                <div className='box_inner_horizontal'>
                  <Table
                    bordered
                    size='small'
                    style={{ cursor: 'pointer' }}
                    dataSource={this.state.dataSource}
                    rowKey={(record) => record.id}
                    scroll={{ y: (this.state.rowSelect?.id) ? 300 : resizableTableScroll(90) }}
                    rowClassName={(record, index) => record.id === this.state.rowSelect?.id ? 'table-row-light' : ''}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.onSelect(record)
                        }
                      }
                    }}
                  >
                    <Table.Column
                      title='レベル'
                      dataIndex='level'
                      width={60}
                    />
                    <Table.Column
                      title='日付'
                      dataIndex='recording_date_on'
                      width={100}
                      render={(value) => {
                        const view_recording_date_on = value.replace(/-/g, '/')
                        return <div> {view_recording_date_on}</div>
                      }}
                    />
                    <Table.Column
                      title='時間'
                      dataIndex='recording_time'
                      width={100}
                      render={(value) => {
                        const totalSeconds = value / 1000;
                        const hours = Math.floor(totalSeconds / 3600);
                        const minutes = Math.floor((totalSeconds % 3600) / 60);
                        const seconds = Math.floor(totalSeconds % 60);

                        // フォーマットして返す
                        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                        return <div> {formattedTime}</div>
                      }}
                    />
                    <Table.Column
                      title='ユーザー'
                      dataIndex='name'
                      width={100}
                    />
                    <Table.Column
                      title='カテゴリ'
                      dataIndex='title'
                      width={100}
                      render={(value) => {
                        const view_title = value ? value : 'その他'
                        return <div> {view_title}</div>
                      }}
                    />
                    <Table.Column
                      title='発生プログラム'
                      dataIndex='published_name'
                      width={150}
                    />
                    <Table.Column
                      title='ログ内容'
                      dataIndex='title'
                      width={100}
                    />
                    <Table.Column
                      title='受診日'
                      dataIndex='visit_date_on'
                      width={100}
                      render={(value) => {
                        const view_visit_date_on = value !== null ? value.replace(/-/g, '/') : value
                        return <div> {view_visit_date_on}</div>
                      }}
                    />
                    <Table.Column
                      title='個人番号'
                      dataIndex='personal_number_id'
                      width={100}
                    />
                    <Table.Column
                      title='氏名'
                      dataIndex='kanji_name'
                      width={200}
                    />
                    <Table.Column
                      title='明細'
                      dataIndex='recorded_contents'
                      render={(value, record) =>
                        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {value}
                        </div>
                      }
                    />
                  </Table>
                </div>
              </div>
            </div>
          </Form>

        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationLogQuery)
