import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Button, Table, Space, Row, Col, message, DatePicker, Modal, Select, Tooltip, Dropdown, Menu } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import { PlusOutlined, DeleteOutlined, SaveOutlined, MoreOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import WS1314005_XmlMedicalExamItemRef from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314005_XmlMedicalExamItemRef.jsx";
import WS1314007_CsvOutputScreen from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314007_CsvOutputScreen.jsx";
import WS1314008_DateDisplay from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314008_DateDisplay";
import WS1314010_CsvCaptureScreen from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314010_CsvCaptureScreen";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS1313001_XmlMedicalExamItemMaster from "pages/TM_SpecificInsureMaintenance/V4TK0070000_DataRangeCheck/WS1314011_XmlMedicalExamItemMaster.jsx";

import DataRangeCheckInputAction from 'redux/SpecificInsureMaintenance/DataRangeCheck/DataRangeCheckInput.action'
import { generateUUID } from 'components/Commons/generateUUID'

const styleFormItem = {
  margin: 0
}
class WS1314001_DataRangeCheck extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'V4-XML03001:データ範囲チェック';

    this.state = {
      isLoading: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      rowSelected: null,


      dataSource: [],
      SelectStartDateChar: [],
      SelectDateValue: ''


    };

  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadScreenData = () => {
    this.setState({ isLoading: true })

    DataRangeCheckInputAction.dataRangeCheckInput()
      .then(res => {

        const groupedDates = [...new Set(res.data.map(item => item.DateAdoptionChars))].map(date => ({ value: date, label: date }))
        groupedDates.unshift({ value: '', label: '' })
        this.setState({ SelectStartDateChar: groupedDates })

        const tmp = res.data.map(item => ({ ...item, selected: true, changed: false }))
        this.setState({ dataSource: tmp })

      })
      .catch(error => {
        message.error("エラーが発生しました");
      }).finally(() => this.setState({ isLoading: false }))
  };

  // 同期版
  syncloadScreenData = () => {

    return DataRangeCheckInputAction.dataRangeCheckInput()
      .then(res => {

        const groupedDates = [...new Set(res.data.map(item => item.DateAdoptionChars))].map(date => ({ value: date, label: date }))
        groupedDates.unshift({ value: '', label: '' })
        this.setState({ SelectStartDateChar: groupedDates })

        const tmp = res.data.map(item => ({ ...item, selected: true, changed: false }))
        this.setState({ dataSource: [] })

        this.setState(() => { }, () => {
          this.setState({ dataSource: tmp })
        })

        return true

      })
      .catch(error => {
        message.error("エラーが発生しました");
        return false

      }).finally(() => this.setState({ isLoading: false }))

  };

  onFinish(values) {
  }

  handleAddRowTable() {

    const newRow = {
      DateAdoptionChars: '',
      item_code_jlac10_15: '',
      result_identification_jlac10_2: '',
      input_min_value: '',
      input_max_value: '',
      character_input_min_value: '',
      character_input_max_value: '',
      option_remark: '',
      id: generateUUID(),
      selected: true,
      changed: false,
    };

    const dataSource = [...this.state.dataSource]

    dataSource.length > 0 ? dataSource.unshift(newRow) : dataSource.push(newRow)

    this.setState({ dataSource: dataSource })

    this.forceUpdate();
  }

  createOrUpdateData = (record) => {



    const param = record ?? [...this.state.dataSource].filter(item => item.selected && item.changed)

    const params = Array.isArray(param) ? param : [param]

    for (let index = 0; index < params.length; index++) {
      if (this.dataExists(params[index])) {
        return;
      }
    }



    if (param.length === 0) {
      message.warning('変更対象はありません')
      return
    }

    DataRangeCheckInputAction.saveDataRangeCheckInput(params)
      .then(async (res) => {

        if (await this.syncloadScreenData()) {
          this.setState({ SelectDateValue: '' })
          this.forceUpdate();
          message.success(res.data.message);
        }

      }).catch(err => {
        message.error(err.response.data.message)
      })
  }

  deleteData(record) {

    DataRangeCheckInputAction.deleteDataRangeCheckInput(record)
      .then(res => {
        message.success(res.data.message);
        this.loadScreenData();


        this.forceUpdate();
      }).catch(err => {
        message.error(err.response.data.message)
      })

  }

  handleF8 = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '95%',
        component: (
          <WS1313001_XmlMedicalExamItemMaster
            onFinishScreen={(output) => {
              this.closeModal()
            }}
            // item_code_jlac10_15={this.state.rowSelected.item_code_jlac10_15}
            record={record}
          />
        ),
      },
    });
  }

  handleF9 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS1314007_CsvOutputScreen
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  handleF10 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '30%',
        component: (
          <WS1314010_CsvCaptureScreen
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    });
  }

  renderMenuBar = () => (
    <Space>
      {/* <Button type="primary" onClick={() => this.handleF8()}>
        JLac10
      </Button> */}

      {/* <Button type="primary" onClick={() => this.handleF9()}>出力</Button>

      <Button type="primary" onClick={() => this.handleF10()}>取込</Button> */}
    </Space>
  )

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  isNumber = (value) => {
    // 数値または小数点付き数値を許容する正規表現
    const decimalPattern = /^-?\d+(\.\d+)?$/;

    return decimalPattern.test(value);
  }

  dataExists = (record) => {

    const data = [...this.state.dataSource]

    const tmp = data.filter(item =>
      item.DateAdoptionChars === record.DateAdoptionChars
      && item.item_code_jlac10_15 === record.item_code_jlac10_15
      && item.result_identification_jlac10_2 == record.result_identification_jlac10_2)

    // 自身と同一のデータは無視
    if (tmp.length > 1
    ) {
      message.error('既にデータが存在します')
      record.changed = false
      return true
    } else {
      return false
    }
  }


  render() {

    // データソース更新用
    const reflashDataSource = () => {
      const tmp = [...this.state.dataSource];
      this.setState({ dataSource: [] })
      this.setState(() => { }, () => { this.setState({ dataSource: tmp }) })
      this.forceUpdate()
    }

    // 入力必須チェック
    const validate = (record) => {

      if (record.DateAdoptionChars.length > 0
        && record.item_code_jlac10_15.length > 0
        && record.result_identification_jlac10_2.length > 0
      ) {
        return true
      } else {
        return false
      }
    }

    // 文字列入力チェック
    const validateInputCharacter = (record, dataIndex, value) => {

      if (record[dataIndex] !== value) {
        record[dataIndex] = value
        record.changed = validate(record)
      }

    }

    // 数値入力チェック
    const validateInputNumber = (record, dataIndex, value) => {

      if (this.isNumber(value)) {
        record[dataIndex] = value
        record.changed = validate(record)
      }


    }

    return (
      <div className="data-range-check">
        <Card className="mb-2" title="V4-XML03001:データ範囲チェック">
          {this.renderMenuBar()}
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            <Space style={{ marginTop: 10, marginLeft: 10 }}>
              <div style={{ fontWeight: "bold", color: "#419087", textAlign: "center", padding: "5px 0" }}>開始日</div>
              <Select style={{ width: 170 }} allowClear options={this.state.SelectStartDateChar}
                onChange={(value) => {

                  const tmp = [...this.state.dataSource].map(item => {
                    if (item.DateAdoptionChars === value || value === undefined) {
                      return { ...item, selected: true }
                    } else {
                      return { ...item, selected: false }
                    }
                  })

                  this.setState({ dataSource: tmp, SelectDateValue: value })
                  this.forceUpdate()

                }}
                value={this.state.SelectDateValue}
              >

              </Select>
            </Space>

            <Table
              style={{ marginTop: 10 }}
              bordered
              size="small"
              dataSource={this.state.dataSource.filter(item => item.selected)}
              loading={this.state.isLoading}
              pagination={false}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.setState({ rowSelected: record })
                  },
                };
              }}
            >

              <Table.Column
                width={'200px'}
                title="採用日"
                dataIndex={'DateAdoptionChars'}
                showSorterTooltip={false}
                sorter={(a, b) => a.DateAdoptionChars.localeCompare(b.DateAdoptionChars, 'jp')}
                render={(text, record, index) => {
                  return (
                    <VenusDatePickerCustom value={text} onChange={(value) => {
                      record.DateAdoptionChars = value.format('YYYY/MM/DD')
                      record.changed = validate(record)
                      reflashDataSource()
                    }} />
                  )
                }}
              />
              <Table.Column
                width={'250px'}
                title="項目コード"
                dataIndex="item_code_jlac10_15"
                showSorterTooltip={false}
                sorter={(a, b) => a.item_code_jlac10_15.localeCompare(b.item_code_jlac10_15, 'jp')}
                render={(text, record, index) => {
                  return (
                    <Input.Search
                      defaultValue={text}
                      onSearch={() => {
                        this.setState({
                          ...this.state,
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 1000,
                            component: (<WS1314005_XmlMedicalExamItemRef
                              onFinishScreen={(output) => {

                                record.item_code_jlac10_15 = output.item_code_jlac10_15;
                                record.result_identification_jlac10_2 = output.result_identification_jlac10_2;
                                record.changed = validate(record)
                                reflashDataSource()
                                this.closeModal()

                              }}

                            />),
                          },
                        })
                      }}
                    />
                  )
                }}

              />
              <Table.Column
                width={'100px'}
                title="結果識別"
                dataIndex="result_identification_jlac10_2"
                showSorterTooltip={false} sorter={(a, b) => a.result_identification_jlac10_2.localeCompare(b.result_identification_jlac10_2, 'jp')}
                render={(text, record, index) => {
                  return (
                    <Input style={{ textAlign: 'center' }}
                      defaultValue={text}
                      onBlur={(e) => {
                        validateInputCharacter(record, 'result_identification_jlac10_2', e.target.value)
                        reflashDataSource()
                      }}
                      onPressEnter={(e) => {
                        validateInputCharacter(record, 'result_identification_jlac10_2', e.target.value)
                        reflashDataSource()
                      }}
                    >

                    </Input>
                  )
                }}
              />
              <Table.Column
                width={'150px'}
                title="入力最小値"
                dataIndex="input_min_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.input_min_value - b.input_min_value}
                render={(text, record, index) => {
                  return (
                    <Input style={{ textAlign: 'right' }}
                      defaultValue={text}
                      onBlur={(e) => {
                        validateInputNumber(record, 'input_min_value', e.target.value)
                        reflashDataSource()

                      }}
                      onPressEnter={(e) => {
                        validateInputNumber(record, 'input_min_value', e.target.value)
                        reflashDataSource()
                      }}
                    >
                    </Input>
                  )
                }}
              />
              <Table.Column
                width={'150px'}
                title="入力最大値"
                dataIndex="input_max_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.input_max_value - b.input_max_value}
                render={(text, record, index) => {
                  return (
                    <Input style={{ textAlign: 'right' }}
                      defaultValue={text}
                      onBlur={(e) => {
                        validateInputNumber(record, 'input_max_value', e.target.value)
                        reflashDataSource()

                      }}
                      onPressEnter={(e) => {
                        validateInputNumber(record, 'input_max_value', e.target.value)
                        reflashDataSource()
                      }}
                    >
                    </Input>
                  )
                }}
              />
              <Table.Column
                width={'150px'}
                title="文字入力最小値"
                dataIndex="character_input_min_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.character_input_min_value.localeCompare(b.character_input_min_value, 'jp')}
                render={(text, record, index) => {
                  return (
                    <Input defaultValue={text}
                      onBlur={(e) => {
                        validateInputNumber(record, 'character_input_min_value', e.target.value)
                        reflashDataSource()

                      }}
                      onPressEnter={(e) => {
                        validateInputNumber(record, 'character_input_min_value', e.target.value)
                        reflashDataSource()
                      }}
                    >
                    </Input>
                  )
                }}

              />
              <Table.Column
                width={'150px'}
                title="文字入力最大値"
                dataIndex="character_input_max_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.character_input_max_value.localeCompare(b.character_input_max_value, 'jp')}
                render={(text, record, index) => {
                  return (
                    <Input defaultValue={text}
                      onBlur={(e) => {
                        validateInputNumber(record, 'character_input_max_value', e.target.value)
                        reflashDataSource()
                      }}
                      onPressEnter={(e) => {
                        validateInputNumber(record, 'character_input_max_value', e.target.value)
                        reflashDataSource()
                      }}
                    >
                    </Input>
                  )
                }}
              />
              <Table.Column
                width={''}
                title="オプション"
                dataIndex="option_remark"
                showSorterTooltip={false}
                sorter={(a, b) => a.option_remark.localeCompare(b.option_remark, 'jp')}
                render={(text, record, index) => {
                  return (
                    <Input defaultValue={text}
                      onBlur={(e) => {
                        validateInputCharacter(record, 'option_remark', e.target.value)
                        reflashDataSource()
                      }}
                      onPressEnter={(e) => {
                        validateInputCharacter(record, 'option_remark', e.target.value)
                        reflashDataSource()
                      }}
                    >

                    </Input>
                  )
                }}
              />
              <Table.Column width={'100px'} align='center'
                title={() => (
                  <>
                    <Space>
                      <Tooltip title="一括保存">
                        <Button size='small' style={{ border: 'none', }}
                          icon={<SaveOutlined style={{ color: 'green' }} />}
                          onClick={() => {
                            this.createOrUpdateData()
                          }} />
                      </Tooltip>


                      <Button size='small' type='primary' icon={<PlusOutlined />}
                        onClick={() => {
                          this.handleAddRowTable()
                        }} />

                    </Space>
                  </>
                )}
                render={(text, record, index) => (
                  <>
                    <Space>
                      <Button size='small' style={{ border: 'none', }}
                        icon={<SaveOutlined style={{ color: !record.changed ? 'gainsboro' : 'green' }} />}
                        disabled={!record.changed}
                        onClick={() => {

                          this.createOrUpdateData(record)
                        }}
                      />

                      <Button size='small' style={{ border: 'none', }}
                        danger icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            content: '消去してもよろしいですか？',
                            okText: 'は　い',
                            cancelText: 'いいえ',
                            onOk: () => {
                              if (!isNaN(Number(record.id))) {
                                this.deleteData(record)
                              } else {
                                this.loadScreenData()
                              }

                              this.setState({ SelectDateValue: '' })
                              this.forceUpdate()

                            }
                          })
                        }}
                      />


                      <Dropdown
                        trigger='click'
                        size='small'
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              key='menu1'
                              onClick={() => {
                                this.handleF8(record)
                              }}>
                              JLac10
                            </Menu.Item>
                          </Menu>
                        )}>
                        <Button
                          size='small'
                          icon={<MoreOutlined />}
                        />
                      </Dropdown>

                    </Space>

                  </>
                )}
              />
            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1314001_DataRangeCheck);
