import React, { useState, createRef, useEffect } from 'react'
import { Card, Form, Input, Select, Button, message, Popconfirm, Typography } from 'antd'
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import ModalDraggable from 'components/Commons/ModalDraggable'
import GovernmentTubePrimaryAggregateAction from "redux/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/GovernmentTubePrimaryAggregate.actions";

const WS1177001_GovernmentTubePrimaryAggregate_sub = (props) => {
  const formRef = createRef()
  const [childModal, setChildModal] = useState({
    visible: false,
    component: null,
    width: 0,
  })

  const [itemChange, setItemChange] = useState([])
  const [selectItem, setSelectItem] = useState(null)

  useEffect(() => {
    // 編集での初期表示で保存値を表示
    if (props.record) {
      formRef.current?.setFieldsValue({ "medical_exam_course": props.record.medical_exam_course })
      formRef.current?.setFieldsValue({ "medical_exam_course_name": props.record.medical_exam_course_name })
      formRef.current?.setFieldsValue({ "invoice_summary_division": props.record.invoice_summary_division })
      formRef.current?.setFieldsValue({ "course_exam_basic_health": props.record.course_exam_basic_health })

      setItemChange({
        id: props.record.id,
        medical_exam_course: props.record.medical_exam_course,
        medical_exam_course_name: props.record.medical_exam_course_name,
        invoice_summary_division: props.record.invoice_summary_division,
        course_exam_basic_health: props.record.course_exam_basic_health,
      })
    }
  }, []);

  useEffect(() => {
    // コースと基本コースの選択値を表示
    if (selectItem) {
      formRef.current?.setFieldsValue({ "medical_exam_course": itemChange.medical_exam_course })
      formRef.current?.setFieldsValue({ "medical_exam_course_name": itemChange.medical_exam_course_name })
    }
  }, [selectItem]);

  const onSearch = (output) => {
    // 健診コース選択
    if (output.medicalExamCourseFlag) {
      setItemChange({
        ...itemChange,
        medical_exam_course: output.Lo_CourseCode,
      })
      setSelectItem({
        ...selectItem,
        medical_exam_course: output.Lo_CourseCode
      })
    } else {
      // 健診コース名選択
      setItemChange({
        ...itemChange,
        medical_exam_course_name: output.Lo_CourseName
      })
      setSelectItem({
        ...selectItem,
        medical_exam_course_name: output.Lo_CourseName
      })
    }
    closeModal()
  }

  const handleChange = () => {
    const invoice_summary_division = formRef.current?.getFieldValue("invoice_summary_division")
    const course_exam_basic_health = formRef.current?.getFieldValue("course_exam_basic_health")

    setItemChange({
      ...itemChange,
      invoice_summary_division: invoice_summary_division,
      course_exam_basic_health: course_exam_basic_health
    })
  }

  /**
   * モーダルを閉じる
   */
  const closeModal = () => {
    setChildModal({
      ...childModal,
      visible: false,
    })
  }

  const onSave = () => {
    if (!itemChange.medical_exam_course || !itemChange.medical_exam_course_name) {
      message.error("健診コースまたは健診コース名が入力されていません");
      return;
    }

    GovernmentTubePrimaryAggregateAction.save({
      id: itemChange.id ?? null,
      medical_exam_course: itemChange.medical_exam_course,
      medical_exam_course_name: itemChange.medical_exam_course_name,
      invoice_summary_division: itemChange.invoice_summary_division ?? 0,
      course_exam_basic_health: itemChange.course_exam_basic_health
    })
      .then(res => {
        if (!res.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.info(res.message);
        props.getScreenData()
      }).catch(error => {
        console.log(error)
      });
    props.onFinishScreen()
  }

  const deleteData = (record) => {
    const param = { id: record }
    GovernmentTubePrimaryAggregateAction.delete(param)
      .then(res => {
        message.info('削除しました');
        props.getScreenData()

      }).catch(error => {
        const res = error.response;
        console.log(res)
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
    props.onFinishScreen()
  }

  return (

    <div className='modal-add' >
      <Card title={(props.newFlag ?? false) === true ? '新規' : '変更'}>
        <Form
          ref={formRef}
          autoComplete='off'
        >
          <Typography style={{ marginBottom: '5px' }}>
            健診コース
          </Typography>
          <Form.Item name="medical_exam_course">
            <Input.Search
              style={{ marginBottom: '5px' }}
              className={'input-size-12'}
              onSearch={() => {
                setChildModal({
                  visible: true,
                  width: '60%',
                  component: (
                    <WS0265001_BasicCourseInquiry
                      medicalExamCourseFlag={true}
                      onFinishScreen={onSearch}
                    />)
                })
              }}
            />
          </Form.Item>

          <Typography style={{ marginBottom: '5px' }}>
            健診コース名
          </Typography>
          <Form.Item name="medical_exam_course_name">
            <Input.Search
              className={'input-size-13'}
              onSearch={() => {
                setChildModal({
                  visible: true,
                  width: '60%',
                  component: (
                    <WS0265001_BasicCourseInquiry
                      medicalExamCourseFlag={false}
                      onFinishScreen={onSearch}
                    />)
                })
              }}
            />
          </Form.Item>

          <Typography style={{ marginBottom: '5px' }}>
            集計区分
          </Typography>
          <Form.Item name='invoice_summary_division' className={'input-size-12'}>
            <Select
              onChange={handleChange}
              style={{ marginBottom: '5px' }}>

              <Select.Option value={0}>&nbsp;</Select.Option>
              <Select.Option value={1}>一般</Select.Option>
              <Select.Option value={2}>付加</Select.Option>
              <Select.Option value={3}>子宮</Select.Option>

            </Select>
          </Form.Item>

          <Typography style={{ marginBottom: '5px' }}>
            基本コース
          </Typography>
          <Form.Item name='course_exam_basic_health' className={'input-size-12'}>
            <Input onChange={handleChange} />
          </Form.Item>

        </Form>

        <div className='box_button_bottom_right'>
          < Popconfirm
            title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
            overlayStyle={{ width: '250px' }}
            overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
            icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
            okText='削除'
            okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
            onConfirm={() =>
              // 削除
              deleteData(props.record.id)
            }
            cancelText='キャンセル'
            cancelButtonProps={{ style: { height: '32px' } }}
          >
            <Button
              danger
              type='primary'
              icon={<DeleteOutlined />}
            >
              <span className='btn_label'>
                削除
              </span>
            </Button>
          </Popconfirm>

          {/* 保存ボタン */}
          <Button
            type='primary'
            icon={<SaveOutlined />}
            onClick={() => {
              onSave()
            }}
          >
            <span className='btn_label'>
              保存
            </span>
          </Button>
        </div>

      </Card >

      <ModalDraggable
        width={childModal.width}
        visible={childModal.visible}
        component={childModal.component}
        onCancel={() => {
          closeModal()
        }}
      />
    </div >
  )
}

export default WS1177001_GovernmentTubePrimaryAggregate_sub
