import React from 'react'
import { connect } from 'react-redux'
import { Card, Tabs, Table, Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import MasterListOutputAttributeAction from 'redux/StatisticalServices/MasterListOutput/MasterListOutputAttribute.action'

// タブ用配列
const tabList = [
  { key: '1', title: '所属情報', keyName: 'AffiliationInfo' },
  { key: '2', title: '特記情報', keyName: 'SpecialInfo' },
  { key: '3', title: '補足情報', keyName: 'SupplementaryInfo' },
  { key: '4', title: '住所', keyName: 'StreetAddress' },
  { key: '5', title: '送付先情報', keyName: 'DestinationInfo' },
]

class WS2585002_MasterListOutputPersonalInfoSub extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    // document.title = 'マスタ一覧出力[個人情報] - 個人名'
    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount() {
    const record = this.props.record
    let params = {
      ...this.props.record
    }
    console.log(params)
    MasterListOutputAttributeAction.personalInfoDetail(params)
      .then(res => {
        if (res.data.result) {
          console.log(res.data.result)
          this.setState({dataSource: res.data.result})
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * タブのレイアウト設定
   * @param {*} item
   * @returns
   */
  renderBodyTab = (item) => {
    let columns = []
    if(item.key == 1) {
      // 所属情報 AffiliationInfo
      columns = [
        { key: '1', title: '', keyName: 'enabled_disabled', className: 'column-size-2'},
        { key: '2', title: '事業所コード', keyName: 'office_code', className: 'column-size-10'},
        { key: '3', title: '支店コード', keyName: 'branch_store_code', className: 'column-size-10'},
        { key: '4', title: '事業所名', keyName: 'office_kanji_name', className: 'column-size-20'},
        { key: '5', title: '保険者番号', keyName: 'insurer_number', className: 'column-size-10'},
        { key: '6', title: '保険者名', keyName: 'insurer_kanji_name', className: 'column-size-20'},
        { key: '7', title: '保険記号', keyName: 'insurer_card_symbol', className: 'column-size-10'},
        { key: '8', title: '番号', keyName: 'insurer_card_number', className: 'column-size-5'},
        { key: '9', title: '続柄', keyName: 'relationship', className: 'column-size-5'},
        { key: '10', title: '受給者番号', keyName: 'recipient_number', className: 'column-size-10'},
        { key: '11', title: '保険開始', keyName: 'insurer_start_date_on', className: 'column-size-10'},
        { key: '12', title: '保険終了', keyName: 'insurer_end_date_on', className: 'column-size-10'},
        { key: '13', title: '雇用形態', keyName: 'employment_status', className: 'column-size-10'},
        { key: '14', title: '職種', keyName: 'occupations', className: 'column-size-5'},
        { key: '15', title: '登録日', keyName: 'registration_date_on', className: 'column-size-10'},
      ]
    } else if(item.key == 2) {
      // 特記情報 SpecialInfo
      columns = [
        { key: '1', title: '重要度', keyName: 'importance', className: 'column-size-5'},
        { key: '2', title: '特記メモ', keyName: 'content', className: 'column-size-20'},
        { key: '3', title: '登録日', keyName: 'registration_date_on', className: 'column-size-10'},
        { key: '4', title: '登録者', keyName: 'registration_user', className: 'column-size-5'},
      ]
    } else if(item.key == 3) {
      // 補足情報 SupplementaryInfo
      columns = [
        { key: '1', title: '項目', keyName: 'item', className: 'column-size-5'},
        { key: '2', title: '補足情報内容', keyName: 'additional_explanation', className: 'column-size-20'},
      ]
    } else if(item.key == 4) {
      // 住所 StreetAddress
      columns = [
        { key: '1', title: '〒', keyName: 'postal_code', className: 'column-size-10'},
        { key: '2', title: '住所1', keyName: 'address_1', className: 'column-size-20'},
        { key: '3', title: '住所2', keyName: 'address_2', className: 'column-size-20'},
        { key: '4', title: '宛名', keyName: 'address', className: 'column-size-10'},
        { key: '5', title: '自宅TEL', keyName: 'phone_number', className: 'column-size-10'},
        { key: '6', title: '携帯', keyName: 'cell_phone_number', className: 'column-size-10'},
        { key: '7', title: 'e-Mail', keyName: 'email', className: 'column-size-20'},
        { key: '8', title: '携帯Mail', keyName: 'mobile_mail', className: 'column-size-20'},
      ]
    } else if(item.key == 5) {
      // 送付先情報 DestinationInfo
      columns = [
        { key: '1', title: '書類', keyName: 'document_title', className: 'column-size-20'},
        { key: '2', title: '事業所1', keyName: 'destination_office_1', className: 'column-size-5'},
        { key: '3', title: '事業所2', keyName: 'destination_office_2', className: 'column-size-5'},
        { key: '4', title: '個人1', keyName: 'destination_personal_1', className: 'column-size-5'},
        { key: '5', title: '個人2', keyName: 'destination_personal_2', className: 'column-size-5'},
        { key: '6', title: '個人3', keyName: 'destination_personal_3', className: 'column-size-5'},
      ]
    }

    return (
      <Table
        bordered
        dataSource={this.state.dataSource[item.keyName]}
        size='small'
        pagination={false}
        scroll={{ x: 'max-content', y: 380 }}
        rowKey={(record) => record.id + '-' + Math.random()}
        key={'table_' + item.keyName}
      >
        {columns.map((column) => {
          if(column.title) {
            return (
              <Table.Column
                title={column.title}
                dataIndex={column.keyName}
                key={column.keyName + '-' + Math.random()}
                className={column.className}
              />
            )
          } else {
            return (
              <Table.Column
                title={column.title}
                dataIndex={column.keyName}
                key={column.keyName + '-' + Math.random()}
                className={column.className}
                render={(value) => {
                  return (
                    <Checkbox checked={value} disabled></Checkbox>
                  )
                }}
              />
            )
          }
        })}
      </Table>
    )
  }

  render() {
    return (
      <div className='support-menu'>
        <Card
          title={'マスタ一覧出力[個人情報] - ' + this.props.record.kanji_name}
          // style={{ width: '600px' }}
        >
          <Tabs
            type='card'
            key='default'
            defaultActiveKey='1'
          >
            {/* タブリスト */}
            {tabList.map((item) =>
              <Tabs.TabPane
                tab={item.title}
                key={item.key}
              >
                {/* タブのレイアウト設定 */}
                {this.renderBodyTab(item)}
              </Tabs.TabPane>
            )}
          </Tabs>
        </Card>

      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(WS2585002_MasterListOutputPersonalInfoSub)
