import { message } from "antd";
import InspectSetInfoService from "services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/InspectSetInfoService";

const InspectSetInfoAction = {
  getDataMainCosAction() {
    return InspectSetInfoService.getDataMainCosService()
  },
  getCourseSubInputAction(params) {
    return InspectSetInfoService.getCourseSubInputService(params)
  },
  getSetSubprocessAction(params) {
    return InspectSetInfoService.getSetSubprocessService(params)
  },
  saveAndUpdateCourseSubInputAction(params) {
    return InspectSetInfoService.saveAndUpdateCourseSubInputService(params)
  },
  saveAndUpdateCourseSetSubprocessAction(params) {
    return InspectSetInfoService.saveAndUpdateCourseSetSubprocessService(params)
  },
  BatchProcess_F9Action(params) {
    return InspectSetInfoService.BatchProcess_F9Service(params)
  },
  SaveTableMain(params) {
    return InspectSetInfoService.SaveTableMain(params)
  },
  DeleteTableMain(params) {
    return InspectSetInfoService.DeleteTableMain(params)
  },
  SaveSourceSubSite(params) {
    return InspectSetInfoService.SaveSourceSubSite(params)
  },
  DeleteSourceSubSite(params) {
    return InspectSetInfoService.DeleteSourceSubSite(params)
  },
};

export default InspectSetInfoAction;


