import React from "react";
import moment from "moment";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from "components/Commons/ResizableTableScroll";

import { Card, Table, message, Button, Form, Space, Menu, Dropdown } from "antd";
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';

import InsureGuideCourseAction from "redux/SpecificInsureMaintenance/InsureGuideCourse/InsureGuideCourse.action";
import WS1398010_InsureGuideCourseDetail from "./WS1398010_InsureGuideCourseDetail";
import WS1398002_InsureGuideCourseEdit from "pages/TM_SpecificInsureMaintenance/V4TH0000300_InsureGuideCourse/WS1398002_InsureGuideCourseEdit.jsx";

class WS1398001_InsureGuideCourse extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '保健指導コース';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        total: 1,
        size: 'small',
        showQuickJumper: true,
        current: 1
      },

      dataSource: [],
      isLoadingPage: false,
      colorText: 208,

    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource() {
    InsureGuideCourseAction.getIndex()
      .then(res => {
        this.setState({
          dataSource: res.MainSource
        });
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      })

  }

  // 新規・編集・削除モーダル
  showEdit(record) {
    let flag = true
    if (record) {
      flag = false
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '400px',
        className: '',
        component:
          <WS1398002_InsureGuideCourseEdit
            newFlag={flag}
            record={record ?? null}
            onFinishScreen={(output) => {
              this.onAdd(output)
              this.closeModal();
            }}
            onUpdate={(output) => {
              this.onSave(output)
              this.closeModal();
            }}
            onDelete={async (output) => {
              await this.onDelete(output)
              await this.closeModal();
            }}
          />
      }
    })
  }

  // 保健指導コース　新規追加処理
  onAdd(params) {
    InsureGuideCourseAction.addData(params.itemChange)
      .then(res => {
        this.loadMainSource();
      })
  }

  // 保健指導コース編集処理
  onSave(params) {
    InsureGuideCourseAction.postData(params)
      .then(res => {
        this.loadMainSource();
      })
  }

  // 保健指導コース　削除処理
  onDelete(params) {
    InsureGuideCourseAction.deleteData(params)
      .then(res => {
        this.loadMainSource();
      })
  }

  renderRightClick = (record) => {
    const {
      delete_flag,
      integrated_destination_manageme,
      integrated_order,
      invoice_number,
      billing_management_number,
      identification,
      W1_billing_manage_num,
      W1_identify
    } = record;
    let eventName3 =
      delete_flag === 0
        ? invoice_number === 0 ? '削除' : '欠損'
        : integrated_order === 0 ? '復元' : '';
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            this.callModal({ Li_BillingManageNum: '' }, '70%', 'WS0963001_InvoiceMaintain')
          }}
        >
          新規
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            !(identification === 1 || billing_management_number === 2 || billing_management_number === 3)
              ? this.callModal({ Li_BillingManageNum: billing_management_number }, '70%', 'WS0963001_InvoiceMaintain')
              : this.callModal({ Li_BillingManageNum: billing_management_number }, '70%', 'WS0952001_CounterPayment')
          }}
        >
          修正
        </Menu.Item>

        <Menu.Item
          key="3"
          hidden={!eventName3}
          onClick={() => {
            const params = {
              invoice_number,
              W1_identify,
              W1_billing_manage_num,
              delete_flag: eventName3 === '復元' || eventName3 === ''
                ? 0
                : eventName3 === '削除' ? 1 : 2
            }
            this.MissingOrDeleted(params)
          }}
        >
          {eventName3}
        </Menu.Item>

        <Menu.Item
          key="4"
          hidden={!(delete_flag === 0 && integrated_destination_manageme > 0 && integrated_order === 0)}
          onClick={() => {
            this.callModal({ Li_Identify: W1_identify, Li_BillingManageNum: W1_billing_manage_num }, '40%', 'WS2626028_IntegrationReleased')
          }}
        >
          統合解除
        </Menu.Item>
      </Menu >
    )
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card
          className="mb-2"
          title="保健指導コース"
        >
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{
              Outstanding: 2,
              Target: 0,
              ClaimIdentify: 0,
              ClaimYearsChar: moment()
            }}
          >
            <Table
              bordered size='small'
              dataSource={this.state.dataSource}
              rowKey={(record) => (record.id || Math.random())}
              scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
                onChange: (page, pageSize) => { },
                onShowSizeChange: (page, pageSize) => { },

              }}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () =>
                  this.showEdit(record)
              })}
            >
              <Table.Column
                width={150}
                title="コースコード"
                dataIndex="insure_guide_course_code"
              />

              <Table.Column
                width={400}
                title="略称名"
                dataIndex="short_name"
              />

              <Table.Column
                title="コース名称"
                dataIndex="course_name"
              />

              <Table.Column
                width={150}
                title="保健指導区分"
                dataIndex="insure_guide_division_value"
              />

              <Table.Column
                width={100}
                title="支援A"
                dataIndex="SupportATotalPoints"
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                width={100}
                title="支援B"
                dataIndex="SupportBTotalPoints"
                render={(value) => {
                  return (
                    <div style={{ textAlign: 'right' }}>{value}</div>
                  )
                }}
              />

              <Table.Column
                width={100}
                title="合計"
                dataIndex="Expression_13"
                render={(value, record) => record.id ? <div style={{ textAlign: 'right' }}>{record.SupportATotalPoints + record.SupportBTotalPoints}</div> : null}
              />

              <Table.Column
                align='center'
                width={40}
                fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.showEdit()
                    }}
                  />
                }
                render={(text, record, index) => (
                  <Space>
                    <Dropdown
                      trigger='click'
                      overlay={() => (
                        <Menu>
                          <Menu.Item
                            key='1'
                            style={{ width: '100%' }}
                            size="small"

                            onClick={() => {
                              this.showEdit(record)
                            }}
                          >
                            編集
                          </Menu.Item>

                          <Menu.Item
                            key='2'
                            style={{ width: '100%' }}
                            disabled={!record.id}
                            type="primary"
                            size="small"
                            onClick={() => {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: "1400px",
                                  afterClose: () => {
                                    this.loadMainSource();
                                  },
                                  component: (
                                    <WS1398010_InsureGuideCourseDetail
                                      Li_CourseCode={record.insure_guide_course_code}
                                      onScreenUpdate={() => { this.loadMainSource() }}
                                    />
                                  ),
                                },
                              });
                            }}
                          >
                            明細
                          </Menu.Item>
                        </Menu>
                      )}
                    >
                      <Button size='small' icon={<MoreOutlined />}></Button>
                    </Dropdown>
                  </Space>
                )}
              />

            </Table>


            <Form.Item
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
            </Form.Item>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS1398001_InsureGuideCourse;
