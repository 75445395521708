import { message } from "antd";
import ComplexDecisionMasterMaintenanceService from "services/Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenanceService";


const ComplexDecisionMasterMaintenanceAction = {
  getScreenData(data) {
    return ComplexDecisionMasterMaintenanceService.getScreenData(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getLeftTable(data) {
    return ComplexDecisionMasterMaintenanceService.getLeftTable(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getRightTable(data) {
    return ComplexDecisionMasterMaintenanceService.getRightTable(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getSubTable(data) {
    return ComplexDecisionMasterMaintenanceService.getSubTable(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  formatChange(data) {
    return ComplexDecisionMasterMaintenanceService.formatChange(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  sexDivisionChange(data) {
    return ComplexDecisionMasterMaintenanceService.sexDivisionChange(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  addOrEdit(data) {
    return ComplexDecisionMasterMaintenanceService.addOrEdit(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  delete(data) {
    return ComplexDecisionMasterMaintenanceService.delete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  editSubTable(data) {
    return ComplexDecisionMasterMaintenanceService.editSubTable(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getFormatLists(data) {
    return ComplexDecisionMasterMaintenanceService.getFormatLists(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  addOrEditFormatName(data) {
    return ComplexDecisionMasterMaintenanceService.addOrEditFormatName(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteFormat(data) {
    return ComplexDecisionMasterMaintenanceService.deleteFormat(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  editFormat(data) {
    return ComplexDecisionMasterMaintenanceService.editFormat(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },
}

export default ComplexDecisionMasterMaintenanceAction;