import React from "react";
import { connect } from "react-redux";
import { DeleteOutlined, SaveOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
import ModalDraggable from "components/Commons/ModalDraggable";
import Color from 'constants/Color';
import { Card, Form, Input, Button, Popconfirm, InputNumber, Modal } from "antd";
import WS0447011_ColorSample from 'pages/MS_InspectionMaintenance/V4MS9900700_DetermineLevelModify/WS0447011_ColorSample.jsx';

const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const smGrid = {
  labelCol: { style: { width: 60 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}

class WS0448500_DetermineLevelBulkModifyEdit extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_CategoryCode: PropTypes.any,
    onFinishScreen: PropTypes.func
  };
  constructor(props) {
    super(props);

    // document.title = 'カテゴリーマスタ SUB';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  /**
   * 初期表示
   */
  componentDidMount = () => {
    if (this.props.Li_EditFlag) {
      this.formRef.current.setFieldsValue(this.props.record)
      this.forceUpdate()
    }
  }

  closeModal = () => {
    this.setState((prevState) => ({
      childModal: { ...prevState.childModal, visible: false },
    }));
  }

  onSave() {
    if (this.formRef.current?.getFieldValue('condition_2') >= 0) {
      let getFieldsValue = this.formRef.current?.getFieldsValue()
      if (this.props.Li_EditFlag) {
        getFieldsValue = {
          ...getFieldsValue,
          id: this.props.record.id,
          condition_2: this.props.record.condition_2
        }
      }
      this.props.onSave(getFieldsValue)
    } else {
      Modal.warning({
        width: 300,
        title: 'レベルを入力してください',
        okText: '確認'
      })
    }
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title={this.props.Li_EditFlag ? "修正" : "登録"}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            {!this.props.Li_EditFlag ?
              <Form.Item name="condition_2" label="レベル" {...smGrid}>
                <div className="box_inner_horizontal">
                  <InputNumber
                    type='number'
                    // maxLength={2}
                    max={99}
                    style={{ width: 50 }}
                    onBlur={(e) => {
                      this.formRef.current?.setFieldsValue({ condition_2: e.target.value > 99 ? 99 : e.target.value })
                    }}
                  />
                  <div style={{ color: 'red', marginTop: 'auto', marginBottom: 'auto', fontSize: 'small' }}>{'2桁まで入力可能'}</div>
                </div>
              </Form.Item> :
              null
            }

            <Form.Item name="item" label="名称" {...smGrid}>
              <Input type="text" maxLength={20} style={{ width: 300 }} />
            </Form.Item>

            <Form.Item name="remarks" label="備考" {...smGrid}>
              <Input type="text" maxLength={20} style={{ width: 300 }} />
            </Form.Item>

            <Form.Item >
              <div className="box_button_bottom_right"
                style={{ display: 'flex', gap: '20px' }}>

                {this.props.Li_EditFlag ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.Li_CategoryCode === 0) ? 'none' : '' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    // onConfirm={() => this.deleteTableData()}
                    onConfirm={() => this.props.onDelete()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.props.record.Li_CategoryCode === 0}
                    >
                      削除
                    </Button>
                  </Popconfirm> : null}

                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => { this.onSave() }}
                >
                  保存
                </Button>

              </div>
            </Form.Item>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0448500_DetermineLevelBulkModifyEdit);