import SupportItemService from "services/SpecificInsureMaintenance/SupportItem/SupportItemService";

export const getDataSupportItemAction = () => {
  return SupportItemService.getDataSupportItemService()
}

export const saveAndUpdateSupportItemAction = (params) => {
  return SupportItemService.saveAndUpdateSupportItemService(params)
}

export const deleteSupportItemAction = (params) => {
  return SupportItemService.deleteSupportItemService(params)
}

export const batchSaveItemAction = (params) => {
  return SupportItemService.batchSaveItemService(params)
}