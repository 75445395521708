import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import ContractInfoInquiryAction from 'redux/ReservationBusiness/GroupBookings/ContractInfoInquiry.actions'
import { Card, Form, Input, Checkbox, Button, Table, Dropdown, Menu, message, Tooltip } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import WS0265001_BasicCourseInquiry from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry'
import WS0605127_ContractLineItemDisplay from 'pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0605127_ContractLineItemDisplay'
import Color from 'constants/Color'
import { ModalError, ModalWarning } from 'components/Commons/ModalConfirm'
import ModalDraggable from 'components/Commons/ModalDraggable'
import WS0307071_HistorySwitching from 'pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0307071_HistorySwitching'

class WS0289012_ContractInfoInquiry extends React.Component {
  static propTypes = {
    Li_State: PropTypes.any,
    Li_EffectiveLimitedDisplay: PropTypes.any,
    Lio_ConsultCourse: PropTypes.any,
    Li_OfficeCode: PropTypes.any,
    Li_BranchStoreCode: PropTypes.any,
    Li_Date: PropTypes.any,
    Li_Gender: PropTypes.any,
    Li_DateBirth: PropTypes.any,
    Li_Relationship: PropTypes.any,
    Li_HospitalOut: PropTypes.any,
    Li_Am_Pm: PropTypes.any,
    Li_NTsugikenmi: PropTypes.any,
    Li_Other: PropTypes.any,
    Lio_ContractType: PropTypes.any,
    Lio_ContractOrgCode: PropTypes.any,
    Lio_ContractStartDate: PropTypes.any,
    Lio_ContractNum: PropTypes.any,
    Lo_Status: PropTypes.any,
    Lo_ErrorMessage: PropTypes.any,
    onFinishScreen: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.formRef = React.createRef()

    // document.title = '契約情報照会'

    this.state = {
      dataSource: [],
      rowSelect: {},
      initParams: {
        Li_EffectiveLimitedDisplay: '',
        Lio_ConsultCourse: '',
        Li_MsgOutput: '',
        Li_Gender: '',
        Li_Relationship: '',
        Li_DateBirth: '',
        Li_AgeDate: '',
        Li_AgeYearEnd: '',
        Li_AgeSchool: '',
        Li_HospitalOut: '',
        Li_Am_Pm: '',
        Li_NTsugikenmi: '',
        Li_Other: '',
        Lo_ContractType: '',
        Lo_ContractOrgCode: '',
        Lo_ContractStartDate: '',
        Lo_ContractNum: '',
        Lo_Status: '',
        ContractType: '',
        Course: '',
        Search: '',
        Gender: 0,
        Relationship: 0,
        HospitalOut: 0,
        Ampm: 0,
        NTsugikenmi: 0,
        Age: 0,
        Other: 0,
        StsCurrentYearOnly: 0,
        DateYear: '',
      },
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    let date = this.props.Li_Date
    let formContractStartDate = ''

    // 親画面からコースが渡ったきている場合
    if (this.props.Lio_ConsultCourse) {
      // 渡って来たコースの契約開始日を「date」に設定、契約開始日が無い場合は受診日を設定
      date = this.props.Lio_ContractStartDate ? this.props.Lio_ContractStartDate : (this.props.Li_Date ? this.props.Li_Date : '')
      formContractStartDate = date ? moment(date).format('YYYY/MM/DD') : ''
    }

    // コース・契約年度を入力フォームに反映
    this.formRef?.current?.setFieldsValue({
      Course: this.props.Lio_ConsultCourse || '',
      contractStartDate: formContractStartDate
    })

    let params = {
      Li_State: this.props.Li_State,
      Li_EffectiveLimitedDisplay: this.props.Li_EffectiveLimitedDisplay,
      Lio_ConsultCourse: this.props.Lio_ConsultCourse,
      Li_OfficeCode: this.props.Li_OfficeCode,
      Li_BranchStoreCode: this.props.Li_BranchStoreCode,
      Li_Date: date,
      Li_Gender: this.props.Li_Gender,
      Li_DateBirth: this.props.Li_DateBirth,
      Li_Relationship: this.props.Li_Relationship,
      Li_HospitalOut: this.props.Li_HospitalOut,
      Li_Am_Pm: this.props.Li_Am_Pm,
      Li_NTsugikenmi: this.props.Li_NTsugikenmi,
      Li_Other: this.props.Li_Other,
      Lio_ContractType: this.props.Lio_ContractType,
      Lio_ContractOrgCode: this.props.Lio_ContractOrgCode,
      Lio_ContractStartDate: this.props.Lio_ContractStartDate,
      Lio_ContractNum: this.props.Lio_ContractNum,
      Lo_Status: this.props.Lo_Status,
      Lo_ErrorMessage: this.props.Lo_ErrorMessage,
    }

    this.setState({ initParams: params })
    this.index(params)
  }

  /**
   * データ取得
   * @param {*} params
   */
  index = (params) => {
    ContractInfoInquiryAction.index(params)
      .then((res) => {
        if (res) {

          if (res.SUB) {

            const data = Array.from(
              new Map(res.SUB.map((item) => [item.id, item])).values()
            )
            if (data) {
              this.setState({
                dataSource: data,
                rowSelect: {} //選択解除
              })
            }

          }


        }
      })
  }

  /**
   * データ再取得
   */
  loadData = () => {
    let contractStartDate = this.formRef.current?.getFieldValue('contractStartDate')

    if (!contractStartDate) {
      contractStartDate = this.props.Li_Date;
    }

    let params = {
      ...this.state.initParams,
      Lio_ConsultCourse: this.formRef.current?.getFieldValue('Course'),
      Li_Date: contractStartDate,
      Li_Search: this.formRef.current?.getFieldValue('Search')
    }

    this.index(params)
  }

  /**
   * 検索処理
   * @param {*} value
   * @param {*} name
   */
  handleSearch = (value, name) => {
    this.setState({
      initParams: {
        ...this.state.initParams,
        [name]: value,
      }
    }, () => this.loadData())
  }

  /**
   * 選択
   * @param {*} record
   */
  Select = (record) => {
    let ErrorAtTime = false
    let {
      StsCurrentYearOnly,
      DateYear,
      contract_start_date,
      Li_EffectiveLimitedDisplay,
      condition_status,
      Lio_ConsultCourse,
      Lo_ContractNum,
      Lo_ContractOrgCode,
      Lo_ContractStartDate,
      Lo_ContractType,
      Lo_Status,
      pattern_code,
    } = record
    let Lo_ErrorMessage = ''

    if (StsCurrentYearOnly && DateYear > contract_start_date) {
      ModalError(moment(DateYear).format('YYYY') + '年度' + 'の契約を選択してください')
      ErrorAtTime = true
    }

    if (Li_EffectiveLimitedDisplay !== 2) {
      if (condition_status === 1) {
        message.error('コース条件に合致しません')
        ErrorAtTime = true
      } else if (condition_status === 2) {
        ModalWarning('コース条件に合致しません')
      }
    }

    if (Lo_Status === 9) {
      Lo_ErrorMessage = moment(DateYear).format('YYYY') + '年度' + 'の契約を作成してください'
    } else if (Lo_Status === 1 || Lo_Status === 2) {
      Lo_ErrorMessage = '受診ｺｰｽが正しくありません'
    }

    if (this.props.onFinishScreen && !ErrorAtTime) {
      this.props.onFinishScreen({
        Lio_ConsultCourse,
        Lo_ContractType,
        Lo_ContractOrgCode,
        Lo_ContractStartDate,
        Lo_ContractNum,
        Lo_Status,
        Lo_ErrorMessage,
        recordData: record,
        pattern_code
      })
    }
  }

  /**
   * 基本コース照会 モーダル
   */
  showWS0265001_BasicCourseInquiry = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS0265001_BasicCourseInquiry
            Li_CourseName={this.formRef.current?.getFieldValue('Course')}
            onFinishScreen={({ Lo_CourseCode }) => {
              this.formRef.current.setFieldsValue({ Course: Lo_CourseCode })
              this.handleSearch(Lo_CourseCode, 'Course')
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 契約年度 モーダル
   */
  showWS0307071_HistorySwitching = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 350,
        component: (
          <WS0307071_HistorySwitching
            onSelected={({ Lio_Date }) => {
              this.formRef.current.setFieldsValue({
                contractStartDate: Lio_Date
              })
              this.handleSearch(Lio_Date, 'Li_Date')
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダル閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  renderButton = (record) => (
    <Dropdown
      trigger='click'
      overlay={(
        <Menu>
          <Menu.Item
            key='1'
            onClick={() => {
              this.setState({
                childModal: {
                  ...this.state.childModal,
                  visible: true,
                  width: 1000,
                  component: (
                    <WS0605127_ContractLineItemDisplay
                      Li_ContractType={record.Lo_ContractType}
                      Li_ContractOrgCode={record.Lo_ContractOrgCode}
                      Li_ContractStartDate={record.Lo_ContractStartDate}
                      Li_ContractNum={record.Lo_ContractNum}
                      onClick={() => {
                        this.closeModal()
                      }}
                    />
                  ),
                },
              })
            }}>契約内容
          </Menu.Item>
        </Menu>)}
    >
      <Button
        size='small'
        icon={<MoreOutlined />}
      />
    </Dropdown>
  )

  render() {
    return (
      <div className='contract-info-inquiry'>
        <Card title='契約情報照会'>
          <Form
            ref={this.formRef}
            autoComplete='off'
          >
            <div className='box_inner_horizontal'>
              <Form.Item
                label='コース'
                name='Course'
              >
                <Input.Search
                  allowClear
                  style={{ width: 110 }}
                  onSearch={(value, event) => {
                    if (event.target.localName !== 'input') {
                      // 検索ボタン
                      this.showWS0265001_BasicCourseInquiry()
                    } else {
                      // ✕ボタン
                      this.handleSearch('', 'Course')
                    }
                  }}
                  onPressEnter={() => {
                    this.showWS0265001_BasicCourseInquiry()
                  }}
                />
              </Form.Item>

              <Form.Item
                label='契約年度'
                name='contractStartDate'
              >
                <Input.Search
                  style={{ width: 150 }}
                  onSearch={(value, event) => {
                    this.showWS0307071_HistorySwitching()
                  }}
                  onPressEnter={(e) => {
                    let date = e.target.value
                    if (date === '') {
                      // 契約年度の入力が空の場合、親の受診日を設定
                      date = this.props.Li_Date
                    }
                    this.handleSearch(date, 'Li_Date')
                  }}
                />
              </Form.Item>

              <Form.Item
                label='検索'
                name='Search'
              >
                <Input
                  style={{ width: 500 }}
                  onPressEnter={e => this.loadData(e.target.value)}
                />
              </Form.Item>
            </div>

            <Table
              size='small'
              dataSource={this.state.dataSource}
              bordered
              pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
              rowKey={(record) => record.id}
              scroll={{ x: 'max-content', y: 500 }}
              onRow={(record, index) => ({
                onClick: event => this.setState({ rowSelect: record }),
                onDoubleClick: () => this.Select(record)
              })}
            >
              <Table.Column
                title='番号'
                dataIndex='contract_number'
                className='column-size-5'
                sorter={(a, b) => a.contract_number - b.contract_number}
                render={(text, record) => (
                  <div style={{ textAlign: 'right', color: Color(record.currentYearOnlyColorNum).Foreground }}>{text}</div>
                )}
              />

              <Table.Column
                title='契約'
                dataIndex='contract_type'
                className='column-size-5'
                sorter={(a, b) => a.contract_type.localeCompare(b.contract_type)}
                render={(text, record) => (
                  <div style={{ color: Color(record.contractTypeColorNum).Foreground }}>{text}</div>
                )}
              />

              <Table.Column
                title='コース'
                dataIndex='chkup_course'
                className='column-size-5'
                sorter={(a, b) => a.chkup_course.localeCompare(b.chkup_course)}
                render={(text, record) => (
                  <Tooltip title={record?.courseName}>
                    <div style={{ color: Color(record.currentYearOnlyColorNum).Foreground }}>{text}</div>
                  </Tooltip>
                )}
              />

              <Table.Column
                title='契約内容'
                dataIndex='contract_short_name'
                className='column-size-30'
                sorter={(a, b) => a.contract_short_name.localeCompare(b.contract_short_name)}
                render={(text, record) => (
                  <Tooltip title={record?.courseName}>
                    <div style={{ color: Color(record.currentYearOnlyColorNum).Foreground }}>{text}</div>
                  </Tooltip>
                )}
              />

              <Table.Column
                dataIndex='apply_gender'
                width={34}
                title={<>
                  <Form.Item name='Gender' valuePropName='checked' style={{ marginRight: 0 }}>
                    <Checkbox onChange={e => this.handleSearch(e.target.checked ? 1 : 0, 'Gender')}></Checkbox>
                  </Form.Item>
                  性
                </>}
                render={(text, record) =>
                  <div style={{
                    color: Color(record.currentYearOnlyColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {record.condition_gender ? text : ''}
                  </div>
                }
              />

              <Table.Column
                dataIndex='apply_relation'
                width={34}
                title={<>
                  <Form.Item name='Relationship' valuePropName='checked' style={{ marginRight: 0 }}>
                    <Checkbox onChange={e => this.handleSearch(e.target.checked ? 1 : 0, 'Relationship')}></Checkbox>
                  </Form.Item>
                  続
                </>}
                render={(text, record) =>
                  <div style={{
                    color: Color(record.currentYearOnlyColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {record.condition_relation ? text : ''}
                  </div>
                }
              />

              <Table.Column
                dataIndex='apply_hospital_out'
                width={34}
                title={<>
                  <Form.Item name='HospitalOut' valuePropName='checked' style={{ marginRight: 0 }}>
                    <Checkbox onChange={e => this.handleSearch(e.target.checked ? 1 : 0, 'HospitalOut')}></Checkbox>
                  </Form.Item>
                  院
                </>}
                render={(text, record) =>
                  <div style={{
                    color: Color(record.currentYearOnlyColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {record.condition_hospital_out ? text : ''}
                  </div>
                }
              />

              <Table.Column
                dataIndex='apply_am_pm'
                width={34}
                title={<>
                  <Form.Item name='Ampm' valuePropName='checked' style={{ marginRight: 0 }}>
                    <Checkbox onChange={e => this.handleSearch(e.target.checked ? 1 : 0, 'Ampm')}></Checkbox>
                  </Form.Item>
                  時
                </>}
                render={(text, record) =>
                  <div style={{
                    color: Color(record.currentYearOnlyColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {record.condition_am_pm ? text : ''}
                  </div>
                }
              />

              <Table.Column
                dataIndex='apply_sect_chkup'
                width={34}
                title={<>
                  <Form.Item name='NTsugikenmi' valuePropName='checked' style={{ marginRight: 0 }}>
                    <Checkbox onChange={e => this.handleSearch(e.target.checked ? 1 : 0, 'NTsugikenmi')}></Checkbox>
                  </Form.Item>
                  n
                </>}
                render={(text, record) =>
                  <div style={{
                    color: Color(record.currentYearOnlyColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {record.condition_sect_chkup ? text : ''}
                  </div>
                }
              />

              <Table.Column
                dataIndex='apply_age_sect'
                width={34}
                title={<>
                  <Form.Item name='Age' valuePropName='checked' style={{ marginRight: 0 }}>
                    <Checkbox onChange={e => this.handleSearch(e.target.checked ? 1 : 0, 'Age')}></Checkbox>
                  </Form.Item>
                  年
                </>}
                render={(text, record) =>
                  <div style={{
                    color: Color(record.currentYearOnlyColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {record.condition_age_identify_cd ? text : ''}
                  </div>
                }
              />

              <Table.Column
                dataIndex='apply_more'
                width={34}
                title={<>
                  <Form.Item name='Other' valuePropName='checked' style={{ marginRight: 0 }}>
                    <Checkbox onChange={e => this.handleSearch(e.target.checked ? 1 : 0, 'Other')}></Checkbox>
                  </Form.Item>
                  他
                </>}
                render={(text, record) =>
                  <div style={{
                    color: Color(record.currentYearOnlyColorNum).Foreground,
                    textAlign: 'center'
                  }}>
                    {record.condition_more ? text : ''}
                  </div>
                }
              />

              <Table.Column
                title='年齢'
                dataIndex='condition_age_calc_sect'
                align='center'
                className='column-size-4'
                sorter={(a, b) => a.condition_age_calc_sect - b.condition_age_calc_sect}
                render={(text, record) => (
                  <Tooltip title={record?.ageCalcSect}>
                    <div>{record.condition_age_identify_cd ? text : ''}</div>
                  </Tooltip>
                )}
              />

              <Table.Column
                title='保険者'
                dataIndex='insurer_unit_price'
                className='column-size-6'
                sorter={(a, b) => a.insurer_unit_price - b.insurer_unit_price}
                render={(text, record, index) => (
                  <div style={{ textAlign: 'right', color: Color(record.currentYearOnlyColorNum).Foreground }}>{text !== 0 ? text.toLocaleString() : ''}</div>
                )}
              />

              <Table.Column
                title='事業所'
                dataIndex='office_unit_price'
                className='column-size-6'
                sorter={(a, b) => a.office_unit_price - b.office_unit_price}
                render={(text, record, index) => (
                  <div style={{ textAlign: 'right', color: Color(record.currentYearOnlyColorNum).Foreground }}>{text !== 0 ? text.toLocaleString() : ''}</div>
                )}
              />

              <Table.Column
                title='他団体'
                dataIndex='other_org_unit_price'
                className='column-size-6'
                sorter={(a, b) => a.other_org_unit_price - b.other_org_unit_price}
                render={(text, record, index) => (
                  <div style={{ textAlign: 'right', color: Color(record.currentYearOnlyColorNum).Foreground }}>{text !== 0 ? text.toLocaleString() : ''}</div>
                )}
              />

              <Table.Column
                title='個人'
                dataIndex='person_1_unit_price'
                className='column-size-6'
                sorter={(a, b) => a.person_1_unit_price - b.person_1_unit_price}
                render={(text, record, index) => (
                  <div style={{ textAlign: 'right', color: Color(record.currentYearOnlyColorNum).Foreground }}>{text !== 0 ? text.toLocaleString() : ''}</div>
                )}
              />

              <Table.Column
                title='備考'
                className='column-size-10'
                dataIndex='remarks'
                sorter={(a, b) => a.remarks - b.remarks}
              />

              <Table.Column
                title='契約年度'
                className='column-size-8'
                dataIndex='contract_start_date'
                sorter={(a, b) => a.contract_start_date - b.contract_start_date}

              />

              <Table.Column
                fixed='right'
                width={40}
                align='center'
                render={(text, record, index) => this.renderButton(record)}
              />
            </Table>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0289012_ContractInfoInquiry)
