import axios from 'configs/axios';

const API_LIST = {
  getInit: '/api/associate-insure-param-maintain',
  input: '/api/associate-insure-param-maintain/input',
  getScreenData: '/api/associate-insure-param-maintain/getScreenData',
  getParamCreate: '/api/associate-insure-param-maintain/param-create',
  save: '/api/associate-insure-param-maintain/save',
  delete: '/api/associate-insure-param-maintain/delete'
};

const AssociateInsureParamMaintainService = {
  async getInit() {
    return axios.get(API_LIST.getInit);
  },
  async input(params) {
    return axios.get(API_LIST.input, { params });
  },
  async getScreenData(params) {
    return axios.get(API_LIST.getScreenData, { params });
  },
  async getParamCreate(params) {
    return axios.post(API_LIST.getParamCreate, params);
  },
  async save(data) {
    return axios.post(API_LIST.save, data);
  },
  async delete(data) {
    return axios.post(API_LIST.delete, { data: data });
  },

};

export default AssociateInsureParamMaintainService;
