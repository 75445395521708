import React from "react";
import { connect } from "react-redux";
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { Card, Form, Input, Checkbox, Table, Select, Button, Tooltip, message } from "antd";
import { SearchOutlined, MoreOutlined, } from '@ant-design/icons'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { download_file_binary } from "helpers/CommonHelpers";
import locale from 'antd/es/date-picker/locale/ja_JP'
import WS2585001_OfficeInfoInquirySub from 'pages/YK_ReservationBusiness/V5YK0002000_GroupBookings/WS2585001_OfficeInfoInquirySub.jsx'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery'
import WS2585002_MasterListOutputPersonalInfoSub from 'pages/TO_StatisticalServices/V5TO0001000_MasterListOutput/WS0418002_MasterListOutputPersonalInfoSub'
import MasterListOutputAttributeAction from 'redux/StatisticalServices/MasterListOutput/MasterListOutputAttribute.action'

const labelCol = {
  labelCol: { style: { width: 70 } },
}

class WS0418001_MasterListOutputPersonalInfo extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = 'マスタ一覧出力[個人情報]';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
        className: '',
      },
      dataSource: [],
      pagination: {
        // eslint-disable-next-line no-undef
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      kana_name: '',
      kanji_name: '',
      birthday_on: '',
      sex: '',
      OfficeCode: '',
      BranchStoreCode: '',
    }
  }

  /**
   * 事業所情報検索・照会 モーダル
   */
  showWS0247001_OfficeInfoRetrievalQuery() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component:
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCode') || 0}
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({
                OfficeCode: output.Lio_OfficeCode,
                BranchStoreCode: output.Lio_BranchStoreCode === 0 ? '' : output.Lio_BranchStoreCode,
              })

              this.closeModal()
            }}
          />
      }
    })
  }

  /**
   * 事業所情報照会 モーダル
   */
  showModalOfficeInfoInquirySub() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '90%',
        component: (
          <WS2585001_OfficeInfoInquirySub
            Li_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Li_BranchCode={this.formRef.current?.getFieldValue('BranchStoreCode') ? this.formRef.current?.getFieldValue('BranchStoreCode') : 0}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 検索ボタン
   */
  personalInfoSearchBtn() {
    let params = {
      kana_name: this.formRef.current?.getFieldValue('kana_name') ?? '',
      kanji_name: this.formRef.current?.getFieldValue('kanji_name') ?? '',
      birthday_on: this.formRef.current?.getFieldValue('birthday_on') ? this.formRef.current?.getFieldValue('birthday_on').format('YYYY/MM/DD') : '',
      sex: this.formRef.current?.getFieldValue('sex') ?? '',
      OfficeCode: this.formRef.current?.getFieldValue('OfficeCode') ?? '',
      BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') ?? '',
    }
    MasterListOutputAttributeAction.personalInfoSearchBtn(params)
      .then(res => {
        if (res.data.result) {
          this.setState({
            dataSource: res.data.result,
            kana_name: this.formRef.current?.getFieldValue('kana_name') ?? '',
            kanji_name: this.formRef.current?.getFieldValue('kanji_name') ?? '',
            birthday_on: this.formRef.current?.getFieldValue('birthday_on') ? this.formRef.current?.getFieldValue('birthday_on').format('YYYY/MM/DD') : '',
            sex: this.formRef.current?.getFieldValue('sex') ?? '',
            OfficeCode: this.formRef.current?.getFieldValue('OfficeCode') ?? '',
            BranchStoreCode: this.formRef.current?.getFieldValue('BranchStoreCode') ?? '',
          })
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  /**
   * 各個人情報確認用モーダル
   */
  showModalWS0418002(record) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS2585002_MasterListOutputPersonalInfoSub
            record={record}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * モーダルを閉じる
   */
  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  /**
   * 出力ボタン
   */
  personalInfoOutputBtn() {
    let params = {
      StreetAddress: this.formRef.current?.getFieldValue('StreetAddress') ?? false,
      AffiliationInfo: this.formRef.current?.getFieldValue('AffiliationInfo') ?? false,
      DestinationInfo: this.formRef.current?.getFieldValue('DestinationInfo') ?? false,
      SpecialInfo: this.formRef.current?.getFieldValue('SpecialInfo') ?? false,
      SupplementaryInfo: this.formRef.current?.getFieldValue('SupplementaryInfo') ?? false,
      lastVisit: this.formRef.current?.getFieldValue('lastVisit') ?? false,
      kana_name: this.state.kana_name ?? '',
      kanji_name: this.state.kanji_name ?? '',
      birthday_on: this.state.birthday_on ?? '',
      sex: this.state.sex ?? 0,
      OfficeCode: this.state.OfficeCode ?? '',
      BranchStoreCode: this.state.BranchStoreCode ?? '',
    }
    MasterListOutputAttributeAction.personalInfoOutputBtn(params)
      .then(res => {
        if(res) {
          download_file_binary(res);
        }
      })
      .catch(error => {
        const res = error.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  }

  render() {
    return (
      <div className="master-list-output-insurance-info">
        <Form ref={this.formRef} onFinish={this.onFinish}>
          <Card title="マスタ一覧出力[個人情報]">
            <div className='box_container'>
              <div className='box_inner_horizontal'>
                <div className='box_search'>
                  <div className='box_search_inner_border' style={{ paddingTop: 0 }}>
                    <Form.Item
                      name="kana_name"
                      label="カナ氏名"
                      style={{ width: '90%' }}
                      {...labelCol}
                    >
                      <Input type="text" allowClear={true}/>
                    </Form.Item>
                    <Form.Item
                      name="kanji_name"
                      label="漢字氏名"
                      style={{ width: '90%' }}
                      {...labelCol}
                    >
                      <Input type="text" allowClear={true}/>
                    </Form.Item>
                    <Form.Item
                      name="sex"
                      label="性別"
                      style={{ width: '60%' }}
                      {...labelCol}
                    >
                      <Select allowClear={true}>
                        <Select.Option key="m" value="1">男</Select.Option>
                        <Select.Option key="f" value="2">女</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='box_search_inner' style={{ paddingTop: 0 }}>
                    <Form.Item
                      name="birthday_on"
                      label="生年月日"
                      style={{ width: '60%' }}
                      {...labelCol}
                    >
                      <VenusDatePickerCustom
                        allowClear={true}
                        formRefDatePicker={this.formRef}
                        locale={locale}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      name='OfficeCode'
                      label='事業所'
                      {...labelCol}
                    >
                      <Input.Search
                        allowClear={true}
                        className='input-search-size-number-allow-clear-8'
                        onPressEnter={() => {
                          this.showWS0247001_OfficeInfoRetrievalQuery()
                        }}
                        onSearch={(value, event) => {
                          if (event.target.localName === 'input') {
                            this.formRef.current?.setFieldsValue({
                              BranchStoreCode: '',
                            })
                            this.setState({
                              BranchStoreCode: '',
                            })
                          } else {
                            this.showWS0247001_OfficeInfoRetrievalQuery()
                          }
                        }}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <Form.Item
                        name='BranchStoreCode'
                        label='枝番'
                        {...labelCol}
                      >
                        <Input.Search
                          type='number'
                          allowClear={true}
                          className='input-search-size-number-allow-clear-4'
                          style={{ textAlign: 'right' }}
                          onPressEnter={() => {
                            this.showWS0247001_OfficeInfoRetrievalQuery()
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName !== 'input') {
                              this.showWS0247001_OfficeInfoRetrievalQuery()
                            }
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='importance_office'
                      >
                        <div style={{ width: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Tooltip title='事業所情報照会'>
                            <Button
                              icon={<MoreOutlined />}
                              disabled={!this.formRef.current?.getFieldValue('OfficeCode')}
                              onClick={() => {
                                this.showModalOfficeInfoInquirySub()
                              }} />
                          </Tooltip>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className='box_search_btn'>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      icon={<SearchOutlined />}
                      onClick={() => {
                        this.personalInfoSearchBtn()
                      }}
                    >
                      <span className='btn_label'>
                        検索
                      </span>
                    </Button>
                  </Form.Item>
                </div>
              </div>

              <div style={{ display: 'flex', gap: 10 }}>
                <Form.Item
                  name="StreetAddress"
                  valuePropName="checked"
                >
                  <Checkbox>住所</Checkbox>
                </Form.Item>
                <Form.Item
                  name="AffiliationInfo"
                  valuePropName="checked"
                >
                  <Checkbox>所属情報</Checkbox>
                </Form.Item>
                <Form.Item
                  name="DestinationInfo"
                  valuePropName="checked"
                >
                  <Checkbox>送付先情報</Checkbox>
                </Form.Item>
                <Form.Item
                  name="SpecialInfo"
                  valuePropName="checked"
                >
                  <Checkbox>特記情報</Checkbox>
                </Form.Item>
                <Form.Item
                  name="SupplementaryInfo"
                  valuePropName="checked"
                >
                  <Checkbox>補足情報</Checkbox>
                </Form.Item>
                <Form.Item
                  name="lastVisit"
                  valuePropName="checked"
                >
                  <Checkbox>最終受診日</Checkbox>
                </Form.Item>
              </div>

              <Table
                size='small'
                bordered
                dataSource={this.state.dataSource}
                rowKey={record => record.id + '-' + Math.random()}
                pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                scroll={{ x: 'max-content', y: 500 }}
                rowClassName={(record) => record.id === this.state.selectedRow?.id ? 'table-row-light' : ''}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      if (record.id !== this.state.selectedRow?.id) {
                        //　選択変更
                        this.setState({ selectedRow: record })
                      }
                    },
                    onDoubleClick: () => {
                      this.showModalWS0418002(record)
                    }
                  }
                }}
              >
                <Table.Column
                  title="個人番号"
                  dataIndex="personal_number_id"
                  className='column-size-5'
                />
                <Table.Column
                  title="氏名"
                  dataIndex="kanji_name"
                  className='column-size-20'
                />
                <Table.Column
                  title="性"
                  dataIndex="sex"
                  className='column-size-2'
                />
                <Table.Column
                  title="生年月日"
                  dataIndex="birthday_on"
                  className='column-size-10'
                />
                <Table.Column
                  title="カルテ整理番号"
                  dataIndex="medical_chart_number"
                  className='column-size-10'
                />
                <Table.Column
                  title="旧氏名"
                  dataIndex="old_kanji_name"
                  className='column-size-10'
                />
                <Table.Column
                  title="登録日"
                  dataIndex="registration_date_on"
                  className='column-size-10'
                />
                <Table.Column
                  title="更新日"
                  dataIndex="updated_on"
                  className='column-size-10'
                />
                <Table.Column
                  align='center'
                  className='column-size-2'
                  render={(text, record, Index) => (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      onClick={() => {
                        this.showModalWS0418002(record)
                      }}
                    />
                  )}
                />
              </Table>
            </div>

            <div className='box_button_bottom_right'>
              <Button
                type='primary'
                onClick={() => {
                  this.personalInfoOutputBtn()
                }}
                disabled={this.state.dataSource.length === 0}
              >
                <span className='btn_label'>
                  出力
                </span>
              </Button>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0418001_MasterListOutputPersonalInfo);
