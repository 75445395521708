import UserOptionInfoMaintainService from "services/SystemMaintenance/UserOptionInfoMaintain/UserOptionInfoMaintainService";
import { message } from "antd";

const UserOptionInfoMaintainAction = {
  getScreenData() {
    return UserOptionInfoMaintainService.getScreenDataUserOptionInfoMaintainService()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getDataOptionCode(params) {
    return UserOptionInfoMaintainService.getDataOptionCodeService(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  getDataOptionInput(params) {
    return UserOptionInfoMaintainService.getDataOptionInputService(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  saveOrUpdateDataOptionCode(params) {
    return UserOptionInfoMaintainService.saveOrUpdateDataOptionCodeService(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  saveOrUpdateDataOptionInput(params) {
    return UserOptionInfoMaintainService.saveOrUpdateDataOptionInputService(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  deleteOptionCode(params) {
    return UserOptionInfoMaintainService.deleteOptionCodeService(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  //ALPHASALUS-8608　オプション修正の削除機能用
  deleteOptionalItems(params) {
    return UserOptionInfoMaintainService.deleteOptionalItemsService(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

  //ALPHASALUS-8608　オプション修正の更新機能用
  updateOption(params) {
    return UserOptionInfoMaintainService.updateOptionService(params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  },

}
export default UserOptionInfoMaintainAction;
