import React from "react";
import { connect } from "react-redux";

import { Card, Table, Row, Col, Form, Input, Button, message, Modal } from "antd";
import DocumentClassifyMasterAction from "redux/SpecificInsureMaintenance/DocumentClassifyMaster/DocumentClassifyMaster.action";

import 'pages/TM_SpecificInsureMaintenance/V4TH0000500_InspectItemMaster/WS1400001_InspectItemMaster.scss';

import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { generateUUID } from 'components/Commons/generateUUID'

class WS1402001_DocumentClassifyMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '文章分類マスタ';

    this.state = {
      dataSourceClassify: [],
      isLoadingTableClassify: false,

    };

  }

  componentDidMount() {
    this.getDataClassifyMaster();
  }

  //// Table Classify

  getDataClassifyMaster() {
    this.setState({ isLoadingTableClassify: true })
    DocumentClassifyMasterAction.getDataClassifyMaster()
      .then(res => {
        const data = res.map((item, index) => ({ ...item, newflg: false, changed: false, key: item.id }));

        this.setState({
          dataSourceClassify: data,
          isLoadingTableClassify: false,
        })

      })
      .finally(() => this.setState({ isLoadingTableClassify: false }))
  }

  handleAddRowTableClassify() {

    const newData = { key: generateUUID(), document_classification_code: '', document_classification_name: '', newflg: true, changed: false }

    const dataSourceClassify = [...this.state.dataSourceClassify]

    dataSourceClassify.length > 0 ? dataSourceClassify.unshift(newData) : dataSourceClassify.push(newData)

    this.setState(() => { }, () => { this.setState({ dataSourceClassify: dataSourceClassify }) })
  }

  callApiDeleteItemClassifyMaster(id) {
    let params = {
      id: id
    };
    Modal.confirm({
      width: "250px",
      title: "削除を行いますか？",
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        DocumentClassifyMasterAction.deleteItemClassifyMaster(params)
          .then((res) => {
            this.getDataClassifyMaster();
            message.success('正常に削除されました');
          })
          .catch((err) => {
            message.error(err.response.data.message);
          });
      },
    });
  }

  callApiSaveAndUpdateInspectItem(record) {

    DocumentClassifyMasterAction.saveAndUpdateClassifyMaster(record)
      .then((res) => {
        this.getDataClassifyMaster()
        message.success('更新しました。')
      })
      .catch((err) => {
        message.error(err.response.data.message)
      });
  }



  render() {

    const changeValue = (record, dataIndex, value) => {
      if (record[dataIndex] !== value) {
        record[dataIndex] = value
        record.changed = true
        this.forceUpdate()
      }

    }

    return (
      <div className="document-classify-master">
        <Card className="mb-2" title="文章分類マスタ">
          <Form ref={this.formRef}>

            <Card style={{ height: '100%' }}>
              <Table bordered size='small'
                dataSource={this.state.dataSourceClassify}
                loading={this.state.isLoadingTableClassify}
                pagination={false}
              >
                <Table.Column title="文章分類コード"
                  width={'20%'}
                  dataIndex="document_classification_code"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.document_classification_code.localeCompare(b.document_classification_code, 'ja')}
                  render={(value, record, index) => {
                    if (record.newflg == true) {
                      return <Input maxLength={10}
                        onBlur={(event) => {
                          changeValue(record, 'document_classification_code', event.target.value)
                        }}
                        onPressEnter={(event) => {
                          changeValue(record, 'document_classification_code', event.target.value)
                        }}>

                      </Input>
                    } else {
                      return value
                    }
                  }}

                />
                <Table.Column title="文章分類名"
                  dataIndex="document_classification_name"
                  showSorterTooltip={false}
                  sorter={(a, b) => a.document_classification_name.localeCompare(b.document_classification_name, 'ja')}
                  render={(value, record, index) => (
                    <Input maxLength={40} type="text" defaultValue={value}
                      onBlur={(event) => {
                        changeValue(record, 'document_classification_name', event.target.value)
                      }}
                      onPressEnter={(event) => {
                        changeValue(record, 'document_classification_name', event.target.value)
                      }}
                    >
                    </Input>
                  )}

                />

                <Table.Column width={90} align='center'
                  title={() => (
                    <Button size='small' type='primary' icon={<PlusOutlined />}
                      onClick={() => this.handleAddRowTableClassify()}></Button>)}
                  render={(text, record, index) => {

                    return (
                      <>
                        <Button size='small' icon={<SaveOutlined />} style={{ color: !record.changed ? 'gainsboro' : 'green', border: 'none' }}
                          onClick={() => this.callApiSaveAndUpdateInspectItem(record)}>
                        </Button>
                        <Button size='small' danger icon={<DeleteOutlined />} style={{ border: 'none' }}
                          onClick={() => this.callApiDeleteItemClassifyMaster(record.id)}>
                        </Button>
                      </>

                    )

                  }}
                />

              </Table>
            </Card>


          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1402001_DocumentClassifyMaster);
