import React from 'react'
import { connect } from 'react-redux'
import { DeleteOutlined, SaveOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Form, Input, Button, Table, Row, Col, Modal, Checkbox, Popconfirm, InputNumber, message, Space } from 'antd'
import { saveJudgementData, deleteJudgementData } from 'redux/InspectionMaintenance/InspectItemJudgeValueSetting/InspectItemJudgeValueSetting.actions'
import { ModalConfirm, ModalError } from 'components/Commons/ModalConfirm'
import WS0285001_JudgeQuery from 'pages/SK_IntroductionLetter/V4SK0012000_PersonRequireExamIntroduceLetter/WS0285001_JudgeQuery'
import WS0272001_CautionGuideNotesSearchQuery from 'pages/MS_InspectionMaintenance/V4MS0106002_InspectItemJudgeValueSetting/WS0272001_CautionGuideNotesSearchQuery.jsx'
import WS0274001_InspectCmtSearchQuery from 'pages/IN_InputBusiness/V4IN0101000_SpreadInput/WS0274001_InspectCmtSearchQuery'


const grid = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const smGrid = {
  labelCol: { style: { width: 80 } },
  wrapperCol: { style: { width: 'calc(100% - 80px)' } }
}


//「判定」の正確なスペルは'judgement'だが、テーブルのカラム名が'judgment'になっているため、そのまま'judgment'とする


class WS0362021_RegisterJudgementDataSingle extends React.Component {
  formRef = React.createRef()
  static propTypes = {
    // exam_name: PropTypes.any,
    Li_InspectType: PropTypes.any,
    Li_judgement_code: PropTypes.any,
    newFlag: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  constructor(props) {
    super(props)
    // document.title = ''

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      AClassJudge: 'A',
      stsNormalValue: false,
      maxLength: 10
    }
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
    this.formRef.current.setFieldsValue(this.props)

    //上限、下限の最大桁数を設定
    switch (this.props.exam_type) {
      case 'N1':
      case '+1': {
        this.setState({
          maxLength: 8
        })
        break
      }

      case 'N2':
      case '+2': {
        this.setState({
          maxLength: 7
        })
        break
      }

      case 'N3':
      case '+3': {
        this.setState({
          maxLength: 6
        })
        break
      }

      case 'N4':
      case '+4': {
        this.setState({
          maxLength: 5
        })
        break
      }
      default:
    }

    this.checkJudgeClass()
  }

  checkJudgeClass = () => {
    if (this.props.AClassJudge === this.formRef.current.getFieldValue('judgment_result')) {
      this.setState({ stsNormalValue: true })
    } else {
      this.setState({ stsNormalValue: false })
    }
  }


  onFinish() {
    let values = this.formRef.current.getFieldsValue(true)
    let getId = this.props.id
    let exam_type = this.props.exam_type
    let judgment_code = this.props.judgment_code
    let sex = this.props.sex
    let judgment_level = this.props.judgment_level
    let start_date_on = this.props.start_date_on
    let normal_value_pattern_character = this.formRef.current.getFieldValue('normal_value_pattern_character')
    let AClassJudge = this.props.AClassJudge
    let params = {
      ...values,
      id: getId,
      exam_type,
      judgment_code,
      judgment_level,
      sex,
      start_date_on,
      newFlag: this.props.newFlag,
      normal_value_pattern_character,
      AClassJudge
    }
    this.saveData(params)
  }

  saveData(params) {
    this.setState({ isLoading: true })
    saveJudgementData(params)
      .then((res) => {
        message.info(res.data.message)
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || 'エラーが発生しました'))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {

    deleteJudgementData({
      id: this.props.record.id
    })
      .then(() => {
        message.success('削除しました')
        this.props.onFinishScreen()

      })
      .catch(() => message.success('エラーが発生しました'))
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    })
  }

  keyPress = (params) => {
    if (params.key === 'Enter') {
      this.digitConversion(params)
    }
  }

  roundValue = (params) => {
    let value = params.value
    let digit = params.digit
    let newValue = (Math.round(value * (10 ** digit))) / (10 ** digit)
    if (!Number.isFinite(newValue) || value === '') {
      newValue = ''
    } else {
      newValue = newValue.toFixed(digit)
    }
    return (newValue)
  }

  digitConversion = (params) => {
    let newValue = 0
    switch (this.props.exam_type) {
      case 'N':
      case '+': {
        newValue = Math.round(params.value)
        break
      }

      case 'N1':
      case '+1': {
        let value = this.toHalfWidth(params.value)
        let item = {
          value: value,
          digit: 1
        }
        newValue = this.roundValue(item)
        break
      }

      case 'N2':
      case '+2': {
        let item = {
          value: params.value,
          digit: 2
        }
        newValue = this.roundValue(item)
        break
      }

      case 'N3':
      case '+3': {
        let item = {
          value: params.value,
          digit: 3
        }
        newValue = this.roundValue(item)
        break
      }

      case 'N4':
      case '+4': {
        let item = {
          value: params.value,
          digit: 4
        }
        newValue = this.roundValue(item)
        break
      }

      default:
    }

    this.formRef.current.setFieldsValue({ [params.field]: newValue })
  }

  // 全角英数字を半角に変換
  toHalfWidth = (params) => {
    params = params.replace(/[Ａ-Ｚａ-ｚ０-９．]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
    return params;
  }

  render() {
    return (
      <div className='inspect-cmt-info-maintain-sub'>
        <Card title={'登録・修正：' + this.props.exam_name}>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >
            {/* 数値タイプ（下限値） */}
            {this.props.exam_type !== 'X' && this.props.exam_type !== 'J' ?
              <Form.Item name='lower_limit_value' label='下限値' {...smGrid}>
                <Input
                  maxLength={this.state.maxLength}
                  style={{ width: 115, textAlign: 'right' }}
                  onBlur={(e) => this.digitConversion({ value: e.target.value, field: 'lower_limit_value' })}
                  onPressEnter={(e) => this.digitConversion({ value: e.target.value, field: 'lower_limit_value' })}
                />
              </Form.Item>
              : null}

            {/* 数値タイプ（上限値） */}
            {this.props.exam_type !== 'X' && this.props.exam_type !== 'J' ?
              <Form.Item name='upper_limit_value' label='上限値' {...smGrid}>
                <Input
                  maxLength={this.state.maxLength}
                  style={{ width: 115, textAlign: 'right' }}
                  onBlur={(e) => this.digitConversion({ value: e.target.value, field: 'upper_limit_value' })}
                  onPressEnter={(e) => this.digitConversion({ value: e.target.value, field: 'upper_limit_value' })}
                />
              </Form.Item>
              : null}

            {/* 文字タイプ */}
            {this.props.exam_type == 'X' || this.props.exam_type == 'J' ?
              <Form.Item name='lower_limit_value' label='結果値' {...smGrid}>
                <Input.Search
                  maxLength={10}
                  style={{ width: 200 }}
                  onSearch={() => {
                    this.setState({
                      childModal: {
                        ...this.state.childModal,
                        visible: true,
                        width: '40%',
                        component: (
                          <WS0274001_InspectCmtSearchQuery
                            Lio_CmtClassify={this.props.exam_comment_code}
                            onFinishScreen={(res) => {
                              this.formRef.current.setFieldsValue({
                                lower_limit_value: res.LnkOutInspectCmtScreen,
                              })
                              this.closeModal()
                            }} />
                        ),
                      },
                    })
                  }}
                />
              </Form.Item>
              : null}

            <Form.Item name='judgment_result' label='判定' {...smGrid}>
              <Input.Search type='text' maxLength={3} style={{ width: 80 }}
                onSearch={() => {
                  this.setState({
                    childModal: {
                      ...this.state.childModal,
                      visible: true,
                      width: '40%',
                      component: (
                        <WS0285001_JudgeQuery
                          Li_JudgeLevel={Number(this.props.judgment_level)}
                          onFinishScreen={(res) => {
                            this.formRef.current.setFieldsValue({
                              judgment_result: res.recordData.judgment_result,
                            })
                            this.checkJudgeClass()
                            this.closeModal()
                          }} />
                      ),
                    },
                  })
                }}
                onChange={() => { this.checkJudgeClass() }} />
            </Form.Item>

            {this.state.stsNormalValue ?
              <Form.Item name='normal_value_pattern_character' label='基準値' {...smGrid}>
                <Input maxLength={25} style={{ width: 200 }}
                />
              </Form.Item> : null}


            <div className='box_inner_horizontal'>
              <Form.Item name='guidance_comment_code' label='コメント' {...smGrid}>
                <Input.Search
                  type='text'
                  maxLength={5}
                  allowClear={true}
                  style={{ width: 120 }}
                  onSearch={(value, event) => {
                    if (event.target.localName === "input") {
                      this.formRef.current?.setFieldsValue({ comment_content: '' })
                      this.forceUpdate()
                    } else {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '60%',
                          component: (
                            <WS0272001_CautionGuideNotesSearchQuery
                              onFinishScreen={(obj) => {
                                this.formRef.current.setFieldsValue({
                                  guidance_comment_code: obj.Lo_LnkOutCmtCode,
                                  comment_content: obj.Lo_recordData.comment_content
                                })
                                this.closeModal()
                              }}
                            />
                          ),
                        },
                      })
                    }
                  }} />
              </Form.Item>

              <Form.Item name='comment_content'>
                <div style={{ width: 500 }}>{this.formRef.current?.getFieldValue('comment_content')}</div>
              </Form.Item>
            </div>

            <Form.Item name='priority' label='優先' {...smGrid}>
              <InputNumber maxLength={1} style={{ width: 40 }} />
            </Form.Item>

            <Form.Item >
              <div className='box_button_bottom_right'>

                {!this.props.newFlag ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px', display: (this.props.Li_id === 0) ? 'none' : '' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    // onConfirm={() => this.deleteTableData()}
                    onConfirm={() => this.deleteData()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type='primary'
                      icon={<DeleteOutlined />}
                      disabled={this.props.Li_id === 0}
                    >
                      <span className='btn_label'>
                        削除
                      </span>
                    </Button>
                  </Popconfirm> : null}
                <Button
                  type='primary'
                  icon={<SaveOutlined />}
                  onClick={() => { this.onFinish() }}>
                  <span className='btn_label'>
                    保存
                  </span>
                </Button>

              </div>
            </Form.Item>
          </Form>


        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.closeModal()
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0362021_RegisterJudgementDataSingle)
