import React from "react";
import { connect } from "react-redux";

import { Card, Form, Input, Modal, Button, Table, Space, Row, Col, message, Dropdown, Menu } from "antd";
import VenusApiRoutesV2 from "redux/VenusApiRoutesV2/VenusApiRoutesV2";
import moment from "moment";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { MoreOutlined, SearchOutlined, ScissorOutlined, CopyOutlined, SnippetsOutlined, ZoomInOutlined } from '@ant-design/icons';
import WS1313006_XmlMedicalExamItemRef from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313006_XmlMedicalExamItemRef.jsx";
import ModalDraggable from "components/Commons/ModalDraggable";
import XmlMedicalExamItemMasterSaveAction from 'redux/SpecificInsureMaintenance/XmlMedicalExamItemMaster/XmlMedicalExamItemMaster.action.js'
import WS1313012_DataRangeCheckChild from "pages/TM_SpecificInsureMaintenance/V4TK0060000_XmlMedicalExamItemMaster/WS1313012_DataRangeCheckChild.jsx";

class WS1313005_DataRangeCheck extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'XML03001:データ範囲チェック';

    //WS1313005_DataRangeCheck.phpに紐づく
    this.state = {
      dataSource: [],
      isLoadingTable: false,
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.getScreenData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.getScreenData();
    }
  }

  //★V2からなおした。エラーになる。
  getScreenData() {
    this.setState({ isLoadingTable: true });
    // VenusApiRoutesV2.callApi("API001313005002", {
    //   date_of_adoption_history_on: moment(this.props.date_of_adoption_history_on).format("YYYY/MM/DD"),
    //   item_code_jlac10_15: this.props.item_code_jlac10_15,
    //   result_identification_jlac10_2: this.props.result_identification_jlac10_2,
    this.setState({ isLoadingTable: true });
    XmlMedicalExamItemMasterSaveAction.dataRangeCheckIndex({
      date_of_adoption_history_on: moment(this.props.date_of_adoption_history_on).format("YYYY/MM/DD"),
      item_code_jlac10_15: this.props.item_code_jlac10_15,
      result_identification_jlac10_2: this.props.result_identification_jlac10_2,
    })
      .then(res => {
        if (res && res.length > 0) {
          this.setState({ dataSource: res, isLoadingTable: false });
        } else {
          this.setState({
            dataSource: [
              {
                id: 0,
                DateAdoptionChars: moment(this.props.date_of_adoption_history_on).format("YYYY/MM/DD"),
                date_of_adoption_history_on: moment(this.props.date_of_adoption_history_on).format("YYYY/MM/DD"),
                item_code_jlac10_15: this.props.item_code_jlac10_15,
                result_identification_jlac10_2: this.props.result_identification_jlac10_2,
                input_min_value: 0,
                input_max_value: 0,
                character_input_min_value: "",
                character_input_max_value: "",
                option_remark: "",
              }
            ], isLoadingTable: false
          });
        }
      });

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }


  /**
  * 編集(新規画面必要)
  */
  eventF11(record, flug) {
    console.log(record)
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        //開きたい画面をかく
        component: (
          <WS1313012_DataRangeCheckChild
            //左：次の画面でつかう名前　右：現在の画面で使用している名称　持ってくる前に書く
            newFlag={flug}
            // date_of_adoption_history_on={this.state.rowSelected.date_of_adoption_history_on}
            DateAdoptionChars={record ? record.DateAdoptionChars : ''}
            item_code_jlac10_15={record ? record.item_code_jlac10_15 : ''}
            result_identification_jlac10_2={record ? record.result_identification_jlac10_2 : ''}
            division_number={record ? record.division_number : ''}
            order_number={record ? record.order_number : ''}
            condition_code_string_12={record ? record.condition_code_string_12 : ''}
            input_min_value={record ? record.input_min_value : ''}
            input_max_value={record ? record.xml_display_name : ''}
            character_input_min_value={record ? record.data_type : ''}
            character_input_max_value={record ? record.xml_pattern : ''}
            max_bytes_or_format={record ? record.max_bytes_or_format : ''}
            xml_data_type={record ? record.xml_data_type : ''}
            option_remark={record ? record.display_units : ''}
          />
        ),
      },
    })
  }

  //モーダルを作って編集する
  render() {
    return (
      <div
        className="data-range-check"
        style={{ width: '100%' }}
      >
        <Card title="XML03001:データ範囲チェック">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            autoComplete="off"
            initialValues={{ Classify: 5 }}
          >
            <Table
              bordered
              dataSource={this.state.dataSource}
              loading={this.state.isLoadingTable}
              pagination={true}
              rowKey={(record) => record.id}
            >
              <Table.Column
                title="採用日"
                dataIndex="DateAdoptionChars"
                showSorterTooltip={false}
                sorter={(a, b) => a.DateAdoptionChars?.localeCompare(b.DateAdoptionChars, 'ja')}
              />

              <Table.Column
                title="項目コード"
                dataIndex="item_code_jlac10_15"
                showSorterTooltip={false}
                sorter={(a, b) => a.item_code_jlac10_15?.localeCompare(b.item_code_jlac10_15, 'ja')}
                render={(value, record, index) => {
                  return (
                    <Input.Search value={value} onSearch={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '80%',
                          component: (
                            <WS1313006_XmlMedicalExamItemRef
                              onFinishScreen={(output) => {
                                this.closeModal();
                                let data = [...this.state.dataSource];
                                data[index].item_code_jlac10_15 = output;
                                this.setState({ dataSource: data });
                              }}
                            />
                          ),
                        },
                      });
                    }}
                    />
                  );
                }}
              />

              <Table.Column
                title="結果識別"
                dataIndex="result_identification_jlac10_2"
                showSorterTooltip={false}
                sorter={(a, b) => a.result_identification_jlac10_2?.localeCompare(b.result_identification_jlac10_2, 'ja')}
              />

              <Table.Column
                title="入力最小値"
                dataIndex="input_min_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.input_min_value - b.input_min_value}
              />

              <Table.Column
                title="入力最大値"
                dataIndex="input_max_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.input_max_value - b.input_max_value}
              />

              <Table.Column
                title="文字入力最小値"
                dataIndex="character_input_min_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.character_input_min_value?.localeCompare(b.character_input_min_value, 'ja')}
              />

              <Table.Column
                title="文字入力最大値"
                dataIndex="character_input_max_value"
                showSorterTooltip={false}
                sorter={(a, b) => a.character_input_max_value?.localeCompare(b.character_input_max_value, 'ja')}
              />

              <Table.Column
                title="オプション"
                dataIndex="option_remark"
                showSorterTooltip={false}
                sorter={(a, b) => a.option_remark?.localeCompare(b.option_remark, 'ja')}
              />
              <Table.Column
                align='center'
                width={40}
                fixed='right'
                title={
                  <Button
                    size='small'
                    type='primary'
                    disabled={this.state.isAddRow}
                    icon={<PlusOutlined />}
                    onClick={() => this.eventF11(null, true)}
                  />
                }
                //★三点ボタンが表示されない。
                render={(text, record, index) => {
                  return (
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                      style={{ width: '100%' }}
                      onClick={() => {
                        this.eventF11(record, false)
                      }}
                    />
                  )
                }}
              />

            </Table>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1313005_DataRangeCheck);
