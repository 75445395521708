import React from "react";
import { Card, Table, message, Button, Form } from "antd";
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import ModalAdd from "components/Commons/ModalAdd"
import ModalChange from "components/Commons/ModalChange"
import resizableTableScroll from "components/Commons/ResizableTableScroll";
import MedicalLocationAction from "redux/SM_SystemMaintenance/MedicalLocation/MedicalLocation.action";
import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine.jsx";


const columns = [
  {
    title: 'コード',
    dataIndex: 'code',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '略名',
    dataIndex: 'short_name',
    classNumber: '10',
    textAlign: 'right',
    type: 1,
  },
  {
    title: '正式名称',
    dataIndex: 'formal_name',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '電話番号',
    dataIndex: 'tel',
    classNumber: '30',
    type: 1,
  },
  {
    title: '郵便番号',
    dataIndex: 'postal_code',
    classNumber: '30',
    type: 4,
    childModal: {
      width: '60%',
      component: WS0084001_PostCodeSearchEngine
    },
  },
  {
    title: '住所1',
    dataIndex: 'address_1',
    // classNumber: '40',
    type: 1,
  },
  {
    title: '住所2',
    dataIndex: 'address_2',
    // classNumber: '40',
    type: 1,
  },

]


class WS0454001_MedicalLocationMaster extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '健診場所マスタ';

    this.state = {
      childModal: {
        implement_division: null,
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      dataSource: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }

  loadMainSource = () => {
    this.setState({ isLoading: true });
    MedicalLocationAction.getScreenData()
      .then(res => {
        this.setState({
          dataSource: res
        })
      })
      .catch(error => {
        console.log(error);

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  saveData(params) {
    console.log(params)
    this.setState({ isLoading: true });
    MedicalLocationAction.saveData(params.itemChange)
      .then(res => {
        this.loadMainSource();
        if (res.message) {
          message.success(res.message);
        }
        this.closeModal();
      })
      .catch(error => {
        this.closeModal();
        console.log(error.response)
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  Delete(params) {
    this.setState({ isLoading: true });
    const { id } = params.itemChange
    MedicalLocationAction.Delete({ id: id })
      .then(res => {
        this.loadMainSource();
        if (res.message) {
          message.success('削除しました');
        }
        this.closeModal();
      })
      .catch(error => {
        const res = error.response;
        if (res.message) {
          message.error("削除に失敗しました");
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  findIndexByID = (arrayData, recordID) => {
    return arrayData.findIndex((item) => recordID === item.id);
  };

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card className="mb-2" title="健診場所マスタ">
          <Form ref={this.formRef}>
            <Table bordered
              size="small"
              dataSource={this.state.dataSource}
              loading={this.state.isLoading}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 10,
                showSizeChanger: true,
              }}
              rowKey={(record) => (record.id || Math.random())}
              onRow={(record, rowIndex) => ({
                onDoubleClick: () => {
                  MedicalLocationAction.getScreenData({ id: record.id })
                    .then(res => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalChange
                              columns={columns}
                              record={res}
                              onUpdate={(output) => {
                                this.saveData(output)
                              }}
                              onDelete={(output) => {
                                this.Delete(output)
                              }}
                            />
                        },
                      })
                    })
                    .catch(error => {
                      console.log(error);

                      const res = error.response;
                      if (!res || !res.data || !res.data.message) {
                        message.error('エラーが発生しました');
                        return;
                      }

                      message.error(res.data.message);
                    })
                }
              })}
              scroll={{ y: resizableTableScroll(80), x: 'max-content' }}
            >
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.short_name.localeCompare(b.short_name, 'ja')} title="健診場所略名" dataIndex="short_name" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.formal_name.localeCompare(b.formal_name, 'ja')} title="健診場所正式名" dataIndex="formal_name" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.postal_code.localeCompare(b.postal_code, 'ja')} title="郵便番号" dataIndex="postal_code" />
              <Table.Column showSorterTooltip={false} sorter={(a, b) => a.address_1.localeCompare(b.address_1, 'ja')} title="住所" dataIndex="address_1" />
              <Table.Column align='center' width={40} fixed='right'
                title={
                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    loading={this.state.isLoadingInsurersList}
                    onClick={() => {
                      this.setState({
                        childModal: {
                          ...this.state.childModal,
                          visible: true,
                          width: '550px',
                          className: '',
                          component:
                            <ModalAdd
                              columns={columns}
                              onFinishScreen={(output) => {
                                console.log(output)
                                this.saveData(output)
                              }}
                            />
                        },
                      });
                    }}
                  />
                }
                render={(text, record, index) => (
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                    onClick={() => {
                      MedicalLocationAction.getScreenData({ id: record.id })
                        .then(res => {
                          console.log(res)
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: '550px',
                              className: '',
                              component:
                                <ModalChange
                                  columns={columns}
                                  record={res}
                                  onUpdate={(output) => {
                                    console.log(output)
                                    this.saveData(output)
                                  }}
                                  onDelete={(output) => {
                                    this.Delete(output)
                                  }}
                                />
                            },
                          })
                        })
                        .catch(error => {
                          console.log(error);
                          const res = error.response;
                          if (!res || !res.data || !res.data.message) {
                            message.error('エラーが発生しました');
                            return;
                          }

                          message.error(res.data.message);
                        })
                    }}
                  />

                )}
              />
            </Table>

          </Form>
        </Card>

        <ModalDraggable
          // destroyOnClose={true}
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS0454001_MedicalLocationMaster;
