import scrollIntoView from 'scroll-into-view'

/**
 * スクロールをtopに移動する共通関数処理
 * ※主に<Table>コンポーネントのスクロールで使用
 * @param {*} calssName　任意のクラス名を設定。<Table>ではrowClassNameに設定されたクラス名を設定する。
 */
const setScrollToTop = (calssName) => {
  scrollIntoView(document.querySelector('.' + calssName), {
    align: {
      top: 0,
      left: 0
    },
  })
}
export default setScrollToTop
