import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";

import { Card, Form, Input, Select, Button, Col, Row, Space, DatePicker, Modal } from "antd";
import WS0246001_InsurerInfoSearchQuery from 'pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery.jsx';
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx';

import { download_file } from 'helpers/CommonHelpers'

import OfficeInfoCsvOutput from "redux/StatisticalServices/OfficeInfoCsvOutput/OfficeInfoCsvOutput.action";

class WS0480003_OfficeInfoCsvOutput extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '事業所情報CSV出力';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
    };
  }

  componentDidMount() {
    this.formRef.current?.setFieldsValue({
      KanshoCodeFrom: 0,
      KanshoCodeTo: 99999999,
      BranchStoreCodeT: 9999,
    })
  }

  onFinish(values) {

  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  showInsurerInfoSearchQuery(val) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0246001_InsurerInfoSearchQuery
            onFinishScreen={(output) => {
              console.log(output)
              this.formRef.current?.setFieldsValue({
                KanshoCode: output.Lo_InsurerCode,
              })
              if (val === 1) {
                this.formRef.current?.setFieldsValue({
                  KanshoCodeFrom: output.Lo_InsurerCode,
                })
              } else {
                this.formRef.current?.setFieldsValue({
                  KanshoCodeTo: output.Lo_InsurerCode,
                })
              }
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  showOfficeInfoRetrievalQuery(val) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: '80%',
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            onFinishScreen={(output) => {
              console.log(output)
              if (val === 1) {
                this.formRef.current?.setFieldsValue({
                  BranchStoreCodeF: output.Lio_BranchStoreCode,
                })
              } else if (val == 2) {
                this.formRef.current?.setFieldsValue({
                  BranchStoreCodeT: output.Lio_BranchStoreCode,
                })
              } else {
                this.formRef.current?.setFieldsValue({
                  OfficeCode: output.Lio_OfficeCode,
                  OfficeCodeNum: (output.Lio_OfficeCode && output.Lio_OfficeCode.length > 8) ? (output.Lio_OfficeCode)?.substring(0, 8) : output.Lio_OfficeCode,
                  BranchStoreCodeF: output.Lio_BranchStoreCode,
                })
              }
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      }
    })
  }

  csvOutput() {
    let params = {
      OutputSpecifyDivision: this.formRef.current?.getFieldValue("OutputSpecifyDivision"),
      KanshoCodeFrom: this.formRef.current?.getFieldValue("KanshoCodeFrom"),
      KanshoCodeTo: this.formRef.current?.getFieldValue("KanshoCodeTo"),
      OfficeCodeNum: this.formRef.current?.getFieldValue("OfficeCodeNum"),
      BranchStoreCodeF: this.formRef.current?.getFieldValue("BranchStoreCodeF"),
      BranchStoreCodeT: this.formRef.current?.getFieldValue("BranchStoreCodeT"),
    }
    console.log(params)
    //MasterListOutputAttributeAction.csvOutput(params)
    OfficeInfoCsvOutput.csvOutput(params)
      .then((res) => {
        console.log(res)
        if (res) {
          // csvファイルダウンロード
          download_file(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    return (
      <div className="office-info-csv-output" style={{ width: 700 }}>
        <Card className="mb-2" title="事業所情報CSV出力">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            initialValues={{ OutputSpecifyDivision: 2, }}
          >
            <div style={{ border: '1px solid rgba(0, 0, 0, 0.06)', padding: '1em' }} >
              <Row>
                <Col span={6}>
                  <Form.Item name="OutputSpecifyDivision" label="出力順">
                    <Select   >
                      <Select.Option value={1}>事業所</Select.Option>
                      <Select.Option value={2}>保険者</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={14}>
                  <Space>
                    <Form.Item name="KanshoCodeFrom" label="保険者" >
                      <Input.Search onSearch={() => this.showInsurerInfoSearchQuery(1)} />
                    </Form.Item>
                    <Form.Item>~</Form.Item>
                    <Form.Item name="KanshoCodeTo" >
                      <Input.Search onSearch={() => this.showInsurerInfoSearchQuery()} />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <Form.Item name="OfficeCodeNum" label="事業所">
                    <Input.Search style={{ width: '95%' }} onSearch={() => this.showOfficeInfoRetrievalQuery()} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Space>
                    <Form.Item name="BranchStoreCodeF" >
                      <Input.Search type="number" onSearch={() => this.showOfficeInfoRetrievalQuery(1)} />
                    </Form.Item>
                    <Form.Item>~</Form.Item>
                    <Form.Item name="BranchStoreCodeT" >
                      <Input.Search type="number" onSearch={() => this.showOfficeInfoRetrievalQuery(2)} />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </div>
            <Button type="primary" style={{ float: 'right', marginTop: '1em' }} onClick={() => this.csvOutput()}  >出力</Button>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS0480003_OfficeInfoCsvOutput);
