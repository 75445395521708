import React from "react";
import PropTypes from "prop-types";

import {
  Card,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Modal,
  message,
  Spin,
  Tag,
  InputNumber,
} from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import WS0328001_ContractOfficeInfoInquirySub from "./WS0328001_ContractOfficeInfoInquirySub.jsx";
import WS0330001_ContractHistoricalQuerySub from "./WS0330001_ContractHistoricalQuerySub.jsx";
import WS0315004_ContractNumberInquiry from "./WS0315004_ContractNumberInquiry.jsx";
import WS0329001_ContractInsurerInfoInquirySub from "pages/BS_BasicInfo/V4KB0201000_ContractInfoMaintain/WS0329001_ContractInsurerInfoInquirySub";
import WS0265001_BasicCourseInquiry from "pages/BS_BasicInfo/V4KB0201400_ContractInfoBatchProcess/WS0265001_BasicCourseInquiry";
import ContractHistoricalQuerySubAction from 'redux/basicInfo/ContractInfoMaintain/ContractHistoricalQuerySub.action'

import axios from "configs/axios";
import moment from "moment";
import ConditionCopyService from "services/basicInfo/ContractInfoMaintain/ConditionCopyService.js";
import ConditionCopyAction from 'redux/basicInfo/ContractInfoMaintain/ConditionCopy.action'

const smGrid = {
  labelCol: { style: { width: 70 } },
  wrapperCol: { style: { width: 'calc(100% - 70px)' } }
}
const dateFormat = "YYYY/MM/DD"

/**
 * @extends {React.Component<{Li_Context:any, Li_ContractType:any, Li_ContractOrgCode:any, Li_ContractStartDate:any, Li_ContractNum:any}>}
 */
class WS0315002_ConditionCopy extends React.Component {
  static propTypes = {
    Li_Context: PropTypes.any,
    Li_ContractType: PropTypes.any,
    Li_ContractOrgCode: PropTypes.any,
    Li_ContractStartDate: PropTypes.any,
    Li_ContractNum: PropTypes.any,

    recordData: PropTypes.any,

    onFinishScreen: PropTypes.func,
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = "条件複写";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },

      screenData: {},
      recordData: this.props.recordData,

      isLoadingScreenData: false,
      isLoadingExec: false,
    };

    this.onFinish = this.onFinish.bind(this);
    this.loadScreenData = this.loadScreenData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState(
        {
          recordData: this.props.recordData,
        },
        () => {
          this.formRef.current.resetFields();
        }
      );
    }
  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadScreenData() {
    this.setState({ isLoadingScreenData: true });
    const data = {
      Li_ContractType: this.props.Li_ContractType,
      Li_ContractOrgCode: this.props.Li_ContractOrgCode,
      Li_ContractStartDate: this.props.Li_ContractStartDate,
      Li_ContractNum: this.props.Li_ContractNum,
    };
    ConditionCopyAction.loadScreenData(data)
      .then((res) => {
        this.setState({
          screenData: res.data,
        });
        let data = moment(this.props.Li_ContractStartDate).format(dateFormat)
        this.formRef.current.setFieldsValue({
          ContractStartDateTo: data,
          ContractStartDateOriginal: data
        })
        this.setState({ isLoadingScreenData: false });
      })
      .catch((error) => {
        message.error("画面情報の取得にはエラーが発生しました");
      });
  }

  fillInput = (e) => {
    const params = {
      Li_ContractType: e.target.value,
      Li_ContractOrgCode: this.getFormFieldValue(
        "ContractOrgCodeOriginal"
      ),
      Li_ContractStartDate: this.getFormFieldValue(
        "ContractStartDateOriginal"
      ),
    }
    ConditionCopyAction.fillInput(params)
      .then((res) => {
        this.formRef.current.setFieldsValue({
          contract_name: res.data.contract_short_name,
          GT_contract_name: res.data.contract_name,
          medical_exam_course: res.data.medical_exam_course
        })
      })
      .catch(error => {
      })
      .finally(() => {

      })
  }
  getFormFieldValue(namePath) {
    return this.formRef.current
      ? this.formRef.current.getFieldValue(namePath)
      : undefined;
  }

  onFinish(values) {
    ContractHistoricalQuerySubAction.getHistoryList({
      Li_ContractType: this.getFormFieldValue("ContractTypeTo"),
      Li_ContractOrgCode: this.getFormFieldValue("ContractOrgCodeTo"),
    })
      .then(res => {
        let dateArr = res.data.map(value => {
          return (
            moment(value?.contract_start_date_on).format('YYYY/MM/DD')
          )
        })
        if(dateArr.indexOf(moment(values.ContractStartDateTo).format('YYYY/MM/DD')) == -1) {
          message.error("年度の指定が正しくありません");
          return;
        }
        let duplicateCheck = this.props.dataSource.find((item) => item.contract_number === Number(this.getFormFieldValue('ContractNumTo')))
        if (duplicateCheck) {
          return message.error('番号が重複しています')
        }
        this.setState({ isLoadingExec: true });
        ConditionCopyService.CopyExec(values)
          .then((res) => {
            message.success(res.data.message);

            if (this.props.onFinishScreen) {
              this.props.onFinishScreen();
            }
          })
          .catch((error) => {
            const res = error.response;
            if (!res || !res.data || !res.data.message) {
              message.error("エラーが発生しました");
              return;
            }

            message.error(res.data.message);
          })
          .finally(() => this.setState({ isLoadingExec: false }));
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }

        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoadingList: false }))
  }

  ContractTypeOriginalValue(value) {
    switch (value) {
      case 0:
        return '共通';
      case 1:
        return '保険者';
      case 2:
        return '事業所';
      default:
        return '';
    }
  }


  getContractName(value) {
    let ContractTypeTo = this.getFormFieldValue("ContractTypeTo")
    let params = {
      ContractTypeTo: ContractTypeTo,
      value: value
    }
    ConditionCopyService.getContractName(params)
      .then((res) => {
        if (ContractTypeTo === 1) {
          this.formRef.current.setFieldsValue({
            contract_name_to: res.data?.StsInsurer?.insurer_kanji_name
          })
        } else if (ContractTypeTo === 2) {
          this.formRef.current.setFieldsValue({
            contract_name_to: res.data?.StsOffice?.office_kanji_name
          })
        }
        this.forceUpdate()
      })
      .catch((error) => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }

        message.error(res.data.message);
      })
  }

  render() {
    return (
      <div className="condition-copy">
        <Card title="条件複写">
          <Form
            ref={this.formRef}
            // onFinish={this.onFinish}
            initialValues={{
              ContractTypeOriginal: this.props.Li_ContractType,
              ContractOrgCodeOriginal: this.props.Li_ContractOrgCode,
              contract_name_original: this.props.Li_ContractOrgName,
              ContractStartDateOriginal: this.props.Li_ContractStartDate,
              ContractNumOriginal: this.props.Li_ContractNum,
              contract_name_to: this.props.Li_ContractOrgName,
              ContractTypeTo: this.props.contractType,
              ContractOrgCodeTo: this.props.contractOrgCode,
              ContractStartDateTo: this.props.Li_ContractStartDate,
              ContractNumTo: '',
              CourseCodeTo: this.state.recordData?.medical_exam_course,
              ContractOfficallyTo: this.state.recordData?.contract_short_name,
              ContractAbbreviationTo: this.state.recordData?.contract_name,
              txtYearContract: this.props.recordData.HM_contract_name,
              medical_exam_course: this.props.recordData.medical_exam_course,
              contract_name: this.props.recordData.GS_contract_short_name,
              GT_contract_name: this.props.recordData.GT_contract_name,
            }}
          >
            <div className="box_inner_horizontal">
              <div className="box_search" style={{ width: "100%" }}>
                <div className="box_inner_vertical">
                  <Form.Item name="ContractTypeOriginal" label="種別" {...smGrid} style={{ marginBottom: 0 }}>
                    <div>{this.ContractTypeOriginalValue(this.getFormFieldValue('ContractTypeOriginal'))}</div>
                  </Form.Item>
                  <div className="box_inner_horizontal">
                    <Form.Item label="団体" name="ContractOrgCodeOriginal" {...smGrid} style={{ marginBottom: 0 }}>
                      <div>{this.getFormFieldValue('ContractOrgCodeOriginal')}</div>
                    </Form.Item>
                    <div>{this.getFormFieldValue('contract_name_original')}</div>
                  </div>
                  <div className="box_inner_horizontal">
                    <Form.Item name="ContractStartDateOriginal" label="年度" {...smGrid} style={{ marginBottom: 0 }}>
                      <div>{this.getFormFieldValue('ContractStartDateOriginal')}</div>
                    </Form.Item>
                    <Form.Item name="txtYearContract" style={{ marginBottom: 0 }}>
                      <div>{this.getFormFieldValue('txtYearContract')}</div>
                    </Form.Item>
                  </div>
                  <Form.Item name="ContractNumOriginal" label="番号" {...smGrid} style={{ marginBottom: 0 }}>
                    <div>{this.getFormFieldValue('ContractNumOriginal')}</div>
                  </Form.Item>
                  <Form.Item name="medical_exam_course" label="コース" {...smGrid} style={{ marginBottom: 0 }}>
                    <div>{this.getFormFieldValue('medical_exam_course')}</div>
                  </Form.Item>
                  <Form.Item name="contract_name" label="略名称" {...smGrid} style={{ marginBottom: 0 }}>
                    <div>{this.getFormFieldValue('contract_name')}</div>
                  </Form.Item>
                  <Form.Item name="GT_contract_name" label="正式名称" {...smGrid} style={{ marginBottom: 0 }}>
                    <div>{this.getFormFieldValue('GT_contract_name')}</div>
                  </Form.Item>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {<DoubleRightOutlined />}
              </div>

              <div className="box_search" style={{ width: "100%" }}>
                <div className="box_inner_vertical" style={{ gap: 0 }}>
                  <Form.Item name="ContractTypeTo" label="種別" {...smGrid}>
                    <div>{this.ContractTypeOriginalValue(this.getFormFieldValue('ContractTypeTo'))}</div>
                  </Form.Item>
                  <div className="box_inner_horizontal">
                    <Form.Item name="ContractOrgCodeTo" label="団体" {...smGrid}>
                      <Input.Search
                        className="input-search-size-number-8"
                        allowClear={true}
                        onChange={(e) => {
                          this.setState({ contract_name_to: '' })
                        }}
                        onPressEnter={(e) => {
                          this.getContractName(e.target.value)
                        }}
                        onSearch={(value, e) => {
                          let component = null;
                          const ContractTypeTo = parseInt(
                            this.getFormFieldValue("ContractTypeTo")
                          );
                          if (e.target.localName === 'input') {
                            this.formRef.current?.setFieldsValue({
                              contract_name: ''
                            })
                            this.forceUpdate()
                          } else {
                            if (ContractTypeTo === 1) {
                              component = (
                                <WS0329001_ContractInsurerInfoInquirySub
                                  Li_Type={ContractTypeTo}
                                  onFinishScreen={(data) => {
                                    this.formRef.current.setFieldsValue({
                                      ContractOrgCodeTo: data.Lo_InsurerCode,
                                      contract_name_to:
                                        data.recordData.contract_name,
                                    });

                                    this.setState({
                                      contract_name_to:
                                        data.recordData.contract_name,
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    });
                                  }}
                                />
                              );
                            } else if (ContractTypeTo === 2) {
                              component = (
                                <WS0328001_ContractOfficeInfoInquirySub
                                  Li_Type={ContractTypeTo}
                                  onClickedSelect={(data) => {
                                    this.formRef.current.setFieldsValue({
                                      ContractOrgCodeTo: data.Lo_OfficeCode,
                                      contract_name_to:
                                        data.recordData.contract_name,
                                    });

                                    this.setState({
                                      contract_name_to:
                                        data.recordData.contract_name,
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    });
                                  }}
                                />
                              );
                            }

                            if (component) {
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: "60%",
                                  component: component,
                                },
                              });
                            }
                          }
                        }}
                        disabled={
                          !(this.getFormFieldValue("ContractTypeTo") > 0)
                        }
                      />
                    </Form.Item>
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '180px',
                        overflow: 'hidden',
                        marginTop: '5px'
                      }}>
                      {this.getFormFieldValue('contract_name_to')}
                    </div>
                  </div>
                  <div className="box_inner_horizontal">
                    <Form.Item name="ContractStartDateTo" label="年度" {...smGrid}>
                      <Input.Search
                        style={{ width: '150px' }}
                        onSearch={() => {
                          this.setState({
                            childModal: {
                              ...this.state.childModal,
                              visible: true,
                              width: 400,
                              component: (
                                <WS0330001_ContractHistoricalQuerySub
                                  Li_ContractType={this.getFormFieldValue(
                                    "ContractTypeTo"
                                  )}
                                  Li_ContractOrgCode={this.getFormFieldValue(
                                    "ContractOrgCodeTo"
                                  )}
                                  onFinishScreen={(data) => {
                                    this.formRef.current.setFieldsValue({
                                      ContractStartDateTo:
                                        data.Lo_ContractStartDate,
                                      txtYearContract: data.recordData.contract_name,
                                    });

                                    this.setState({
                                      childModal: {
                                        ...this.state.childModal,
                                        visible: false,
                                      },
                                    });
                                  }}
                                />
                              ),
                            },
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="txtYearContract">
                      <div>{this.getFormFieldValue('txtYearContract')}</div>
                    </Form.Item>
                  </div>
                  <Form.Item name="ContractNumTo" label="番号" {...smGrid} rules={[{ required: true }]} >
                    <InputNumber className="input-size-number-4" />
                  </Form.Item>
                  <Form.Item name="CourseCodeTo" label="コース" {...smGrid}>
                    <Input.Search
                      className="input-search-size-4"
                      onSearch={() => {
                        this.setState({
                          childModal: {
                            ...this.state.childModal,
                            visible: true,
                            width: 800,
                            component: (
                              <WS0265001_BasicCourseInquiry
                                onSelect={({ Lo_CourseCode, recordData }) => {
                                  this.formRef.current.setFieldsValue({
                                    CourseCodeTo: Lo_CourseCode,
                                    ContractOfficallyTo: recordData.course_name_short_name,
                                    ContractAbbreviationTo: recordData.course_name_formal
                                  });

                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: false,
                                    },
                                  });
                                }}
                              />
                            ),
                          },
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="ContractOfficallyTo" label="略名称" {...smGrid}>
                    <Input type="text" className="input-size-35" />
                  </Form.Item>
                  <Form.Item name="ContractAbbreviationTo" label="正式名称" {...smGrid}>
                    <Input type="text" className="input-size-35" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <Form.Item style={{ float: "right", marginTop: '8px', marginBottom: 0 }}>
              <Button
                type="primary"
                onClick={() => this.onFinish(this.formRef.current?.getFieldValue())}
              >
                複写
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

export default WS0315002_ConditionCopy;
