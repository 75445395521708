import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Checkbox, Button, message } from 'antd'
import { ImportOutlined } from '@ant-design/icons'
import DataMigrationAction from 'redux/DataMigration/DataMigration.action.js'

class MasterDataMigration extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      person: false,
      office: false,
      insurer: false,
      inspection: false,
      course: false,
      recreate: false,
      nonDelete: false
    }
  }

  execution = () => {
    console.log('個人：' + this.state.person)
    console.log('事業所：' + this.state.office)
    console.log('保険者：' + this.state.insurer)
    console.log('検査：' + this.state.inspection)
    console.log('コース：' + this.state.course)
    console.log('再作成：' + this.state.recreate)
    console.log('削除なし：' + this.state.nonDelete)

    let params = {
      person: this.state.person ? 1 : 0,  //個人
      office: this.state.office ? 1 : 0,  //事業所
      insurer: this.state.insurer ? 1 : 0,  //保険者
      inspection: this.state.inspection ? 1 : 0,  //検査
      course: this.state.course ? 1 : 0,  //コース
      recreate: this.state.recreate ? 1 : 0,  //再作成
      nonDelete: this.state.nonDelete ? 1 : 0  //削除無し
    }

    DataMigrationAction.executeMasterDataMigration(params)
      .then((res) => {
        message.info(res.message)
      })

  }

  render() {
    return (
      <div className='master-data-migration'>
        <Card title='マスタ系移行' style={{ width: 'fit-content' }}>
          <Form ref={this.formRef} autoComplete='off'>
            <div className='box_container' style={{ width: 'fit-content', marginRight: 30 }}>
              <Form.Item label='マスタ選択' labelCol={{ span: 7 }}>
                <div className='box_search' style={{ width: 275 }}>
                  <div className='box_inner_vertical'>
                    <div className='box_inner_horizontal'>
                      <Checkbox onChange={(e) => this.setState({ person: e.target.checked })}>個人</Checkbox>
                      <Checkbox onChange={(e) => this.setState({ office: e.target.checked })}>事業所</Checkbox>
                      <Checkbox onChange={(e) => this.setState({ insurer: e.target.checked })}>保険者</Checkbox>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Checkbox onChange={(e) => this.setState({ inspection: e.target.checked })}>検査</Checkbox>
                      <Checkbox onChange={(e) => this.setState({ course: e.target.checked })}>コース</Checkbox>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item label='移行オプション' labelCol={{ span: 7 }}>
                <div className='box_search' style={{ width: 275 }}>
                  <div className='box_inner_vertical'>
                    <Checkbox onChange={(e) => this.setState({ recreate: e.target.checked })}>再作成</Checkbox>
                    <Checkbox
                      style={{ marginLeft: 0 }}
                      onChange={(e) => this.setState({ nonDelete: e.target.checked })}
                    >
                      検査の削除は行わない
                    </Checkbox>
                  </div>
                </div>
              </Form.Item>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  icon={<ImportOutlined />}
                  disabled={this.state.disabledSaveBtn}
                  onClick={() => this.execution()}
                >
                  <span className='btn_label'>
                    実行
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }

}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterDataMigration)