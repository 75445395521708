import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, Table, Tree, Row, Col, Form, Input, Dropdown, Button, message, Menu, Modal, Checkbox, Popconfirm, InputNumber, Select } from "antd";
import { MoreOutlined, CarryOutOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import ModalDraggable from "components/Commons/ModalDraggable";
import { ModalError } from "components/Commons/ModalConfirm";
import {
  loadAgeSubDataAction,
  registerDataAction,
  deleteDataAction
} from "redux/SM_SystemMaintenance/AgeManageInfoMaintain/AgeManageInfoMaintain.action";
import WS1505010_CopyRegister from "./WS1505010_CopyRegister";

const treeRoot = {
  title: '年齢テーブル',
  key: 'Root',
  isParent: true,
  children: []
};
class WS1505011_AgeManageInfoMaintainSub extends React.Component {
  formRef = React.createRef();
  static propTypes = {
    Li_ageIdCode: PropTypes.any,
    newFlag: PropTypes.any,

  };
  constructor(props) {
    super(props);

    // document.title = '年齢管理情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      dataSource: [],
      Li_ageIdCode: '',
      isLoading: false,
      isLoadingTree: false,
      isLoadingTable: false,
      changeCheckbox: [],
      ageIdCode: '',
      newFlag: true,
      newRegistrationFlag: this.props.newRegistrationFlag,
      selectBox: [],
      startAge: 0,
      endAge: 0,
      disabled: true
    };
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount = () => {
    this.formRef.current.setFieldsValue(this.props.record)
    this.loadData(this.props.record)
    this.setState({ newFlag: this.props.newFlag });
    let selectArray = []
    for (let i = 0; i <= 150; i++) {
      selectArray.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
    }
    this.setState({ selectBox: selectArray })
  }

  loadData = (record) => {
    this.setState({ isLoadingPage: true });
    loadAgeSubDataAction(record)
      .then(res => {
        if (res) {
          this.setState({ dataSource: res, changeCheckbox: res })
          this.formRef.current?.setFieldsValue({
            tableData: res
          })
        }
      })
      .catch()
      .finally(() => this.setState({ isLoadingPage: false }))
  }

  onFinish = (value) => {
    let params
    if (!this.state.newFlag) {
      //更新
      params = {
        ...value,
        changeData: this.state.changeCheckbox,
        newFlag: this.state.newFlag,
      };
      //新規
    } else {
      params = {
        ...value,
        newFlag: this.state.newFlag
      };
    }
    this.RegisterBtn(params);
  }

  RegisterBtn(params) {
    this.setState({ isLoading: true })
    registerDataAction(params)
      .then((res) => {
        message.info(res.message)
        /*         if (this.state.newFlag) {
                  this.setState({ newFlag: false })
                  this.loadData({
                    age_id_code: this.formRef.current.getFieldValue("age_id_code")
                  })
                } else {

                  this.props.onFinishScreen && this.props.onFinishScreen()
                } */
        this.props.onFinishScreen && this.props.onFinishScreen()
      })
      .catch(err => ModalError(err?.response?.data?.message || "エラーが発生しました"))
      .finally(() => this.setState({ isLoading: false }))
  }

  deleteData() {
    deleteDataAction({
      age_id_code: this.formRef.current.getFieldValue("age_id_code")
    })
      .then(() => {
        message.success('削除しました');
        this.props.onFinishScreen()
      })
      .catch(() => message.success('エラーが発生しました'))
  }


  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  selectStartAge = (event) => {
    this.setState({
      startAge: event,
      disabled: false
    })
  }

  selectEndAge = (event) => {
    this.setState({
      endAge: event,
      disabled: false
    })
  }

  targetSelectAge = () => {

    if (this.state.startAge > this.state.endAge) {
      message.error('対象範囲の選択範囲が正しくありません')
      return
    }

    let array = [
      ...this.state.changeCheckbox
    ]

    // 対象範囲選択で指定したものを対象にする
    for (let i = this.state.startAge; i <= this.state.endAge; i++) {
      array[i]['enabled_disabled'] = 1
      array[i]['changeFlag'] = 1
    }

    this.setState({ changeCheckbox: array })
    this.formRef.current?.setFieldsValue({
      tableData: array
    })
  }

  targetSelectAgeCancel = () => {
    let array = [
      ...this.state.changeCheckbox
    ]

    // 対象範囲選択で指定したものを対象外にする
    for (let i = this.state.startAge; i <= this.state.endAge; i++) {
      array[i]['enabled_disabled'] = 0
      array[i]['changeFlag'] = 1
    }

    this.setState({ changeCheckbox: array })
    this.formRef.current?.setFieldsValue({
      tableData: array
    })
  }

  changeData = (value, record, index) => {

    if (this.state.changeCheckbox[index].enabled_disabled === 0) {
      this.state.changeCheckbox[index].enabled_disabled = 1
      this.state.changeCheckbox[index].changeFlag = 1
      this.setState({ changeCheckbox: this.state.changeCheckbox })
      return
    }

    this.state.changeCheckbox[index].enabled_disabled = 0
    this.state.changeCheckbox[index].changeFlag = 1
    this.setState({ changeCheckbox: this.state.changeCheckbox })
  }

  render() {
    return (
      <div className="inspect-cmt-info-maintain-sub">
        <Card className="mb-2" title="登録・修正">
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
          >

            <Col span={6}>
              <Form.Item name="age_id_code" label="コード"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "入力値が正しくありません"
                  },
                ]}
              >
                <Input
                  style={{ width: 120 }}
                  maxLength={10}
                  disabled={(!this.state.newFlag)}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="age_title" label="タイトル" >
                <Input type="text" maxLength={100} />
              </Form.Item>
            </Col>

            {!this.state.newRegistrationFlag ?
              <>
                <span>対象範囲選択</span>
                <Select
                  style={{ marginLeft: 10, marginBottom: 10, marginRight: 20, width: 90 }}
                  onSelect={(event) => { this.selectStartAge(event) }}
                >
                  {this.state.selectBox}
                </Select>
                ～
                <Select
                  style={{ marginLeft: 10, marginBottom: 10, marginRight: 20, width: 90 }}
                  onSelect={(event) => { this.selectEndAge(event) }}
                >
                  {this.state.selectBox}
                </Select>

                <Button type="primary" onClick={() => this.targetSelectAge()} style={{ marginLeft: 20 }} disabled={this.state.disabled}>
                  選択
                </Button>

                <Button type="danger" onClick={() => this.targetSelectAgeCancel()} style={{ marginLeft: 20 }} disabled={this.state.disabled}>
                  解除
                </Button>

              </> : null}

            {!this.state.newFlag ?
              <Table bordered
                pagination={false}
                dataSource={this.state.dataSource}
                loading={false}
                size='small'
                rowKey={(record) => record.id}
                scroll={{ y: 500 }}
              >
                <Table.Column width={100} title="年齢" dataIndex="age" />

                <Table.Column
                  width={80} title="対象" dataIndex="enabled_disabled"
                  render={(text, record, index) => (
                    <Form.Item name={['tableData', index, 'enabled_disabled']} valuePropName='checked'>
                      <Checkbox onChange={(event) => this.changeData(event.target.checked, record, index)} />
                    </Form.Item>
                  )}
                />
              </Table> : null}

            <Form.Item >
              <div className="box_button_bottom_right"
                style={{ display: 'flex', gap: '20px' }}>

                <Button type="primary" htmlType='submit'>
                  {!this.state.newFlag ? '変更' : '登録'}
                </Button>

                {!this.state.newFlag ?
                  <Popconfirm
                    title={<div style={{ fontSize: '16px', marginBottom: '20px', textAlign: 'center' }}>削除を行いますか？<br /></div>}
                    overlayStyle={{ width: '250px' }}
                    overlayInnerStyle={{ height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}
                    okButtonProps={{ style: { width: '100px', height: '32px', backgroundColor: 'red', borderColor: 'red' } }}
                    cancelButtonProps={{ style: { height: '32px' } }}
                    icon={<DeleteOutlined style={{ color: 'red', fontSize: '18px', marginLeft: '18px' }} />}
                    // onConfirm={() => this.deleteTableData()}
                    onConfirm={() => this.deleteData()}
                    okText='削除'
                    cancelText='キャンセル'
                  >
                    <Button
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                      disabled={this.state.newFlag}
                    >削除
                    </Button>
                  </Popconfirm> : null}
              </div>
            </Form.Item>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1505011_AgeManageInfoMaintainSub);
