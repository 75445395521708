import GovernmentTubeHepatitisAggregateService from 'services/AssociationHealthInsuranceReport/AssociateInsureParamMaintain/GovernmentTubeHepatitisAggregateService'
import { message } from "antd";

const GovernmentTubeHepatitisAggregateAction = {
  index() {
    return GovernmentTubeHepatitisAggregateService.index()
      .then((res) => {
        console.log(res)
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  save(data) {
    return GovernmentTubeHepatitisAggregateService.save(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  },
  delete(data) {
    return GovernmentTubeHepatitisAggregateService.delete(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  }

};
export default GovernmentTubeHepatitisAggregateAction;