import React from 'react'
import { connect } from 'react-redux'
import { Card, Form, Button, Select, Upload, message } from 'antd'
import { ImportOutlined, UploadOutlined } from '@ant-design/icons'
import DataImport from 'redux/DataMigration/DataImport.action.js'
import Cookies from 'js-cookie'

class DataImportScreen extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      targetTable: 0,
      importFile: {}
    }
  }

  import = () => {
    DataImport.importFileContents({ targetTable: this.state.targetTable, contents: this.state.importFile })
      .then((res) => {
        message.info(res.message)
      })
  }

  render() {
    return (
      <div className='data-import-screen' style={{ width: 'fit-content' }}>
        <Card title='データ取込'>
          <Form ref={this.formRef} autoComplete='off'>
            <div className='box_container' style={{ width: 'fit-content' }}>
              <Form.Item
                name='table'
                label='テーブル'
              >
                <Select
                  style={{ width: 200 }}
                  defaultValue={0}
                  onChange={(value) => this.setState({ targetTable: value })}
                >
                  <Select.Option value={0} >{'個人情報'}</Select.Option>
                  <Select.Option value={1} >{'個人情報（住所）'}</Select.Option>
                  <Select.Option value={2} >{'事業所情報'}</Select.Option>
                  <Select.Option value={3} >{'事業所情報（職場）'}</Select.Option>
                  <Select.Option value={4} >{'保険者情報'}</Select.Option>
                  <Select.Option value={5} >{'パターン'}</Select.Option>
                  <Select.Option value={6} >{'コース'}</Select.Option>
                  <Select.Option value={7} >{'カテゴリ'}</Select.Option>
                  <Select.Option value={8} >{'検査項目情報'}</Select.Option>
                  <Select.Option value={9} >{'部位分類'}</Select.Option>
                  <Select.Option value={10} >{'部位マスタ'}</Select.Option>
                  <Select.Option value={11} >{'所見マスタ'}</Select.Option>
                  <Select.Option value={12} >{'指導事項'}</Select.Option>
                </Select>
              </Form.Item>
              {/* アップロード */}
              <Upload
                headers={{
                  'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
                }}
                // directory
                withCredentials={true}

                //dataList:テキスト内容を含むデータ一覧
                //uploadList:アップロード対象のファイル一覧
                beforeUpload={() => false}
                onChange={(info) => {
                  let files = info.file;
                  //アップロードボタンを押した場合の処理
                  if (files.status !== 'removed') {
                    const reader = new FileReader();

                    //sjisにしか対応してないけど大丈夫？
                    reader.readAsText(files, 'SJIS');

                    reader.onload = (e) => {
                      let tempObj = {
                        name: files.name,
                        text: reader.result
                      }

                      // tempArry.push(obj)
                      this.setState({
                        importFile: tempObj
                      })
                    };
                    //削除（ゴミ箱）ボタンを押した場合の処理
                  } else {
                    this.setState({
                      importFile: {}
                    })
                  }
                }}
              >
                <Button
                  type='primary'
                  icon={<UploadOutlined />} >
                  アップロード
                </Button>
              </Upload>
              <div className='box_button_bottom_right'>
                <Button
                  type='primary'
                  icon={<ImportOutlined />}
                  disabled={Object.keys(this.state.importFile).length == 0}
                  onClick={() => this.import()}
                >
                  <span className='btn_label'>
                    取込
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}
const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DataImportScreen)