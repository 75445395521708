import React from "react";
import ModalDraggable from "components/Commons/ModalDraggable";

import {
  Card, Table, message, Button, Form, Input, Popconfirm, Space
} from "antd";
import {
  PlusOutlined, DeleteOutlined
} from '@ant-design/icons';
import DiseaseNameMasterMaintainAction from "redux/InspectionMaintenance/DiseaseNameMasterMaintain/DiseaseNameMasterMaintain.action";
// import axios from "configs/axios";
import { SaveOutlined } from '@ant-design/icons'

class WS2303001_DiseaseNameMasterMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '病名マスタ保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        afterClose: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 10,
        size: 'small',
        showQuickJumper: true,
        showSizeChanger: true
      },
      MainSource:[],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadMainSource();
  }


  // 一覧取得
  loadMainSource = () => {
    this.setState({ isLoading: true });

    DiseaseNameMasterMaintainAction.index()
      .then(res => {
        this.setState({
          isLoading: false,
          MainSource: res
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });

        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  save() {
    this.onFinish(this.formRef.current?.getFieldValue('MainSource'))
  }

  onFinish = (values) => {
    DiseaseNameMasterMaintainAction.save(values)
      .then(res => {
        if(res.message) {
          message.success(res.message);
        }
        this.loadMainSource();
      })
      .catch(error => {
        console.log(error);
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました');
          return;
        }
        message.error(res.data.message);
      });
  }

  render() {
    return (
      <div className="insure-guide-course">
        <Card title="病名マスタ保守" className="mb-2">
          <Form ref={this.formRef}>
            <Table bordered
              size='small'
              dataSource={this.state.MainSource}
              scroll={{ y: 600 }}
              loading={false}
              pagination={this.state.pagination}
              rowKey={(record) => (record.id || Math.random())}
            >
              <Table.Column title="病名コード" dataIndex="disease_name_code" width={250} render={(value, record, index) => (
                <Input
                  defaultValue={value}
                  onBlur={(e) => {
                    record.disease_name_code = e.target.value
                  }}
                >
                </Input>
              )} />
              <Table.Column title="検索キー" dataIndex="search_key" width={400} render={(value, record, index) => (
                <Input
                  defaultValue={value}
                  onBlur={(e) => {
                    record.search_key = e.target.value
                  }}
                >
                </Input>
              )} />
              <Table.Column title="病名" dataIndex="name_of_a_disease" width={900} render={(value, record, index) => (
                <Input
                  defaultValue={value}
                  onBlur={(e) => {
                    record.name_of_a_disease = e.target.value
                  }}
                >
                </Input>
              )} />
              <Table.Column
                key="action"
                width={40}
                title={(
                  <Button type="primary" size="small" icon={<PlusOutlined />} onClick={() => {
                    this.setState({
                      MainSource: [
                        {},
                        ...this.state.MainSource
                      ]
                    })
                  }} />
                )}
                render={(value, record) => (<Space>
                  <Popconfirm title="削除しますか？" onConfirm={() => {
                    const newData = this.state.MainSource.filter(item => item.id !== record.id);
                    this.setState({
                      MainSource: newData
                    });
                    if(record.id) {
                      DiseaseNameMasterMaintainAction.delete({ id: record.id })
                        .then(res => {
                          message.success('削除しました');
                        })
                        .catch(error => {
                          console.log(error);
                          const res = error.response;
                          if (!res || !res.data || !res.data.message) {
                            message.error('エラーが発生しました');
                            return;
                          }
                          message.error(res.data.message);
                        });
                    }
                  }}>
                    <Button type="primary" size="small" danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Space>)}
              />
            </Table>

            <Form.Item
              className="mt-3"
              style={{ textAlign: 'right' }}
            >
              <Button type="primary" onClick={() => this.onFinish(this.state.MainSource)} icon={<SaveOutlined />}>
                保存
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          afterClose={this.state.childModal.afterClose}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div >
    );
  }
}

export default WS2303001_DiseaseNameMasterMaintain;
