import React from "react";
import { connect } from "react-redux";
import ModalDraggable from "components/Commons/ModalDraggable";
import resizableTableScroll from 'components/Commons/ResizableTableScroll';
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import { Card, Button, Table, Menu, Dropdown, message } from "antd";
import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import DistrictInfoMaintainAction from "redux/Others/DistrictInfoMaintain/DistrictInfoMaintain.action";
import DistrictInfoMaintainEdit from "pages/ZZ_Others/DistrictInfoMaintain/DistrictInfoMaintainEdit.jsx";

class DistrictInfoMaintain extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '地区情報保守';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        defaultPageSize: 100,
        size: 'small',
        showQuickJumper: true,
        showSizeChanger: true
      },
      isLoading: false,
      dataSource: [],
    };
  }

  componentDidMount = () => {
    this.getScreenData()
  }

  getScreenData() {
    DistrictInfoMaintainAction.getScreenData()
      .then((res) => {
        this.setState({
          dataSource: res ? res : [],
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  openEditModal(id) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <DistrictInfoMaintainEdit
            id={id}
            flag={id ? 0 : 1} // flagが0なら更新、1なら新規
            onFinishScreen={(output) => {
              this.getScreenData()
              this.closeModal()
            }}
          />)
      }
    })
  }

  delete(id) {
    let params = {
      id: id ?? 0
    }
    ModalConfirm({
      content: '地区情報を削除しますか',
      styleIconModal: { color: '#006CC9' },
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        DistrictInfoMaintainAction.delete(params)
          .then((res) => {
            if(res.message) {
              message.info(res.message)
            }
            this.getScreenData()
          })
          .catch(error => {
            console.log(error);
          })
      }
    })
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  }

  render() {
    return (
      <div className="rising-document-create">
        <Card className="mb-2" title="地区情報保守">
          <Table
            size='small'
            bordered
            rowKey={record => record.id}
            dataSource={this.state.dataSource}
            pagination={this.state.pagination ? this.state.pagination : false}
            scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
            onRow={(record) => {
              return {
                onDoubleClick: async () => {
                  this.openEditModal(record.id)
                }
              }
            }}
          >
            <Table.Column
              title='コード'
              dataIndex='area_code'
              className='column-size-5'
            />
            <Table.Column
              title='地区名称'
              dataIndex='district_name'
            />
            <Table.Column
              width={40}
              align='center'
              title={
                <Button
                  size='small'
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.openEditModal()
                  }}
                />
              }
              render={(text, record) => (
                <Dropdown
                  trigger='click'
                  size='small'
                  overlay={() => (
                    <Menu>
                      <Menu.Item
                        key='menu-1'
                        onClick={() => {
                          this.openEditModal(record.id)
                        }}>
                        変更
                      </Menu.Item>
                      <Menu.Item
                        key='menu-2'
                        onClick={() => {
                          this.delete(record.id)
                        }}>
                        削除
                      </Menu.Item>
                    </Menu>
                  )}>
                  <Button
                    size='small'
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              )}
            />
          </Table>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0}}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DistrictInfoMaintain);
