import axios from 'configs/axios';

const apiPaths = {
  getDataMainCos: '/api/associate-insure-param-maintain/inspect-set-info',
  getCourseSubInput: '/api/associate-insure-param-maintain/inspect-set-info/getCourseSubInput',
  getSetSubprocess: '/api/associate-insure-param-maintain/inspect-set-info/getSetSubprocess',
  saveCourseSubInput: '/api/associate-insure-param-maintain/inspect-set-info/saveCourseSubInput',
  saveSubprocess: '/api/associate-insure-param-maintain/inspect-set-info/saveSubprocess',
  BatchProcess_F9: '/api/associate-insure-param-maintain/inspect-set-info/BatchProcess_F9',
  SaveTableMain: '/api/associate-insure-param-maintain/inspect-set-info/SaveTableMain',
  DeleteTableMain: '/api/associate-insure-param-maintain/inspect-set-info/DeleteTableMain',
  SaveSourceSubSite: '/api/associate-insure-param-maintain/inspect-set-info/SaveSourceSubSite',
  DeleteSourceSubSite: '/api/associate-insure-param-maintain/inspect-set-info/DeleteSourceSubSite',
};

const InspectSetinfoService = {

  async getDataMainCosService() {
    return axios.get(apiPaths.getDataMainCos);
  },

  async getCourseSubInputService(params) {
    return axios.get(apiPaths.getCourseSubInput, { params });
  },

  async getSetSubprocessService(params) {
    return axios.get(apiPaths.getSetSubprocess, { params });
  },

  async saveAndUpdateCourseSubInputService(params) {
    return axios.get(apiPaths.saveCourseSubInput, { params })
  },

  async saveAndUpdateCourseSetSubprocessService(params) {
    return axios.get(apiPaths.saveSubprocess, { params })
  },

  async deleteSiteFindingsMasterMaintainService(params) {
    return axios.delete(apiPaths.deleteSiteFindingsMasterMaintain, { params })
  },

  async deleteSiteTableService(params) {
    return axios.delete(apiPaths.deleteSiteTable, { params })
  },

  async deleteFindingsTableService(params) {
    return axios.delete(apiPaths.deleteFindingsTable, { params })
  },

  async BatchProcess_F9Service(params) {
    return axios.get(apiPaths.BatchProcess_F9, { params })
  },

  async SaveTableMain(params) {
    return axios.post(apiPaths.SaveTableMain, params)
  },

  async DeleteTableMain(params) {
    return axios.delete(apiPaths.DeleteTableMain, { params })
  },

  async SaveSourceSubSite(params) {
    return axios.post(apiPaths.SaveSourceSubSite, params)
  },

  async DeleteSourceSubSite(params) {
    return axios.delete(apiPaths.DeleteSourceSubSite, { params })
  },
};

export default InspectSetinfoService;
