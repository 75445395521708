import React from "react";
import { connect } from "react-redux";
import { ModalConfirm } from 'components/Commons/ModalConfirm'
import { Card, Form, Button, Col, Input, message } from "antd";
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import DistrictInfoMaintainAction from "redux/Others/DistrictInfoMaintain/DistrictInfoMaintain.action";

class DistrictInfoMaintainEdit extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    // document.title = '判定・単位一覧表';

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoading: false,
    };
  }

  componentDidMount = () => {
    console.log(this.props)
    this.getDistrictInfo()
  }

  getDistrictInfo() {
    let params = {
      id: this.props.id ?? 0
    }
    DistrictInfoMaintainAction.getDistrictInfo(params)
      .then((res) => {
        this.formRef.current?.setFieldsValue({
          area_code: res.area_code,
          district_name: res.district_name
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  save() {
    let params = {
      id: this.props.id ?? 0,
      flag: this.props.flag, // flagが0なら更新、1なら新規
      area_code: this.formRef.current?.getFieldValue('area_code'),
      district_name: this.formRef.current?.getFieldValue('district_name'),
    }
    DistrictInfoMaintainAction.save(params)
      .then((res) => {
        if(res.message) {
          message.info(res.message)
        }
        if(this.props.onFinishScreen) {
          this.props.onFinishScreen()
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  delete() {
    let params = {
      id: this.props.id ?? 0
    }
    ModalConfirm({
      content: '地区情報を削除しますか',
      styleIconModal: { color: '#006CC9' },
      okText: 'はい',
      cancelText: 'いいえ',
      onOk: () => {
        DistrictInfoMaintainAction.delete(params)
          .then((res) => {
            if(res.message) {
              message.info(res.message)
            }
            if(this.props.onFinishScreen) {
              this.props.onFinishScreen()
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    })
  }

  render() {
    return (
      <div className="judge-unit-list">
        <Card className="mb-2" title="判定・単位一覧表">
          <Form
            ref={this.formRef}
          >
            <Form.Item
              name="area_code"
              label="コード"
              labelCol={{ span: 6 }}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="district_name"
              label="地区名称"
              labelCol={{ span: 6 }}
            >
              <Input/>
            </Form.Item>
            <Col style={{ textAlign: 'right' }}>
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                disabled={this.props.flag} 
                onClick={() => this.delete()}
              >
                削除
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                style={{marginLeft: '8px'}}
                onClick={() => this.save()}
              >
                保存
              </Button>
            </Col>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DistrictInfoMaintainEdit);
