import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ModalDraggable from 'components/Commons/ModalDraggable'
import VenusDatePickerCustom from 'components/Commons/VenusDatePickerCustom'
import { SearchOutlined, PlusOutlined, MoreOutlined, FileExcelOutlined } from '@ant-design/icons'
import { Card, Form, Input, Button, Table, Tooltip, Modal, Menu, Dropdown } from 'antd'
import { ResizableBox } from 'react-resizable';
import { download_file_binary } from 'helpers/CommonHelpers'
import WS0247001_OfficeInfoRetrievalQuery from 'pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery.jsx'
import WS2786001_ConditionAddSub from 'pages/BS_BasicInfo/V4KB0203000_ConsultInfoReconstruction/WS2786001_ConditionAddSub.jsx'
import TentativeReserveAction from 'redux/ReservationBusiness/SANAI_TentativeReserve/TentativeReserve.action.js'
import resizableTableScroll from 'components/Commons/ResizableTableScroll'
import TimeSelectDisplay from 'pages/YK_ReservationBusiness/SANAI_TentativeReserve/TimeSelectDisplay.jsx'
import WS2537001_PersonalReserveProcess from 'pages/YK_ReservationBusiness/V5YK0004000_PersonalReserveProcess/WS2537001_PersonalReserveProcess.jsx'
import WS2736001_MiraisElectronicMedicalRecordsSent from 'pages/KS_CooperationRelated/FJYM01000_MiraisElectronicMedicalRecordsSent/WS2736001_MiraisElectronicMedicalRecordsSent.jsx'
import ParticularsScreen from 'pages/ZZ_Others/SANAI_FrameReserve/ParticularsScreen.jsx'


import ReserveStatusSearchCustom from 'pages/ZZ_Others/SANAI_ReserveStatusSearch/ReserveStatusSearchCustom.jsx'


class DetermineTentativeReserve extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = '仮予約確定処理'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      columnList: [],
      Expression_36: 163,
      recordData: {},
      conditionAddData: null,
      personal_number_id: ''
    }
  }

  componentDidMount = () => {
    this.formRef.current?.setFieldsValue({
      dataSource: [],
      ReserveDateFChar: moment(new Date()),
      ReserveDateTChar: moment(new Date()),
      total: 0
    })
  }

  checkDateStart() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateFChar')
        .format('YYYY/MM/DD')
      : null;
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateTChar')
        .format('YYYY/MM/DD')
      : null;
    if (dateEnd && dateStart >= dateEnd) {
      this.formRef.current?.setFieldsValue({
        ReserveDateFChar:
          this.formRef.current?.getFieldValue('ReserveDateFChar'),
      });
    }
  }

  checkDateEnd() {
    let dateStart = this.formRef.current?.getFieldValue('ReserveDateFChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateFChar')
        .format('YYYY/MM/DD')
      : null;
    let dateEnd = this.formRef.current?.getFieldValue('ReserveDateTChar')
      ? this.formRef.current
        ?.getFieldValue('ReserveDateTChar')
        .format('YYYY/MM/DD')
      : null;
    if ((!dateEnd && dateStart) || (dateStart && dateStart >= dateEnd)) {
      this.formRef.current?.setFieldsValue({
        ReserveDateTChar:
          this.formRef.current?.getFieldValue('ReserveDateFChar'),
      });
    }
  }

  DisplayBtn = () => {
    if (!this.formRef.current?.getFieldValue('ReserveDateFChar') || !this.formRef.current?.getFieldValue('ReserveDateTChar')) {
      Modal.error({
        width: '300px',
        content: '日付の範囲を指定してください'
      })
    } else {
      let params = {
        ReserveDateFChar: this.formRef.current?.getFieldValue('ReserveDateFChar').format('YYYY/MM/DD'),
        ReserveDateTChar: this.formRef.current?.getFieldValue('ReserveDateTChar').format('YYYY/MM/DD'),
        officeCode: this.formRef.current?.getFieldValue('OfficeCode'),
        conditionAddData: this.state.conditionAddData ?? {}
      }
      TentativeReserveAction.getTentativeReserve(params)
        .then(res => {
          //オプションを表示するためのカラムリストを作成
          let length = 0
          let newColumnList = []
          let total = res.length
          res.map((value) => {
            //要素数の最も多いoptionListをcolumnListとして再利用
            if (value.optionList.length > length) {
              length = value.optionList.length
              newColumnList = value.optionList
            }
          })
          this.formRef.current?.setFieldsValue({
            dataSource: res,
            columnList: newColumnList,
            total: total
          })
          this.setState({
            dataSource: res,
            columnList: newColumnList
          })
        })
    }
  }

  closeModal() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      },
    })
  }

  getPersonalInfo = (value, record, index) => {
    let duplication = false

    //入力した個人番号の重複チェック
    this.state.dataSource.map((element) => {
      if (element.personal_number_id == value) {
        duplication = true
      }
    })

    if (duplication) {
      let newDataSource = this.state.dataSource
      newDataSource[index]['personal_number_id'] = value
      newDataSource[index]['kanji_name'] = '登録済みです'
      newDataSource[index]['warningFlag'] = true
      this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
      this.setState({
        dataSource: newDataSource
      })
    } else {
      let params = {
        personal_number_id: value,
        office_code: record.office_code,
        branch_store_code: record.branch_store_code,
        date: record.date
      }
      TentativeReserveAction.getPersonalInfo(params)
        .then(res => {
          if (res) {
            let newDataSource = this.state.dataSource
            newDataSource[index]['personal_number_id'] = value
            newDataSource[index]['kanji_name'] = res.kanji_name
            newDataSource[index]['kana_name'] = res.kana_name
            newDataSource[index]['gender'] = res.gender
            newDataSource[index]['genderColor'] = res.genderColor
            newDataSource[index]['birthday_on'] = res.birthday_on
            newDataSource[index]['visit_age'] = res.visit_age
            newDataSource[index]['age'] = res.age
            newDataSource[index]['warningFlag'] = res.warningFlag
            this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
            this.setState({
              dataSource: newDataSource
            })
          }
        })
    }
  }

  showTimeSelectDisplay = (date, index) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <TimeSelectDisplay
            Li_Date={date}
            dataSource={this.state.dataSource}
            onFinishScreen={(obj) => {
              // 受診日・時間帯設定
              let newDataSource = this.state.dataSource
              newDataSource[index]['period_time'] = obj.timeZone
              this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
              this.setState({ dataSource: newDataSource })
              // this.formRef.current.setFieldsValue({
              //   // DateChar: obj.dateChar,
              //   TimeZone: obj.timeZone
              // })
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  showPersonalReserveProcess = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS2537001_PersonalReserveProcess
            Li_Date={record.date}
            Li_CourseLevel={record.course_level}
            Li_ReserveNum={record.reservation_number}
            Li_PersonalNum={
              record.personal_number_id
            }
            Li_Getctxname={''}
            Li_ProcessDivision={''}
            Li_Option={''}
            Li_Child={true}
            onFinishScreen={() => {
              // this.closeModal()
              this.DisplayBtn()
            }}
          />
        ),
      },
    })
  }

  callMiraisElectronicMedicalRecordsSent(dataF, dataT, reserveNumList) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1200,
        component: (
          <WS2736001_MiraisElectronicMedicalRecordsSent
            DateFChar={dataF}
            DateTChar={dataT}
            reserve_nums={reserveNumList}
            onFinishScreen={(output) => {
              this.closeModal()
              if (reserveNumList.length !== 0) {
                // 明細画面
                this.callParticularsScreen(reserveNumList)
              }
            }}
          />
        ),
      },
    })
  }

  callParticularsScreen(reserveNumList) {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1000,
        component: (
          <ParticularsScreen
            OfficeCode={this.formRef.current.getFieldValue('OfficeCode')}
            BranchStoreCode={this.formRef.current.getFieldValue('BranchStoreCode')}
            reserve_nums={reserveNumList}
            dayList={[]}
            dataScreen={this.state.dataScreen}
            onFinishScreen={(output) => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  getReserveInfo = (personal_number_id, period_time, record, index) => {
    this.getPersonalInfo(personal_number_id, record, index)

    // 受診日・時間帯設定
    let newDataSource = this.state.dataSource
    newDataSource[index]['period_time'] = period_time
    this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
    this.setState({ dataSource: newDataSource })
  }

  /**
   * Excel出力
   */
  outputExcel = () => {
    const params = {
      dataList: this.state.dataSource,
      officeCode: this.formRef.current.getFieldValue('OfficeCode'),
      officeCodeName: this.formRef.current?.getFieldValue('office_kanji_name'),
    }
    TentativeReserveAction.outputExcel(params)
      .then((res) => {
        if (res) {
          this.downloadExcelFile(res?.filePath)
        }
      })
  }

  /**
   * Excelファイル ダウンロード
   * @param {*} path
   */
  downloadExcelFile(path) {
    let params = {
      filePath: path
    }
    TentativeReserveAction.downloadExcelFile(params)
      .then((res) => {
        if (res) {
          // xlsxファイルダウンロード
          download_file_binary(res)
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  changeOffice = (newValue, record, index) => {
    let newDataSource = [...this.state.dataSource]
    newDataSource[index]['office_code'] = newValue.Lio_OfficeCode
    newDataSource[index]['branch_store_code'] = newValue.Lio_BranchStoreCode
    newDataSource[index]['office_kanji_name'] = newValue.recordData?.office_kanji_name

    this.formRef.current?.setFieldsValue({ dataSource: newDataSource })
    this.setState({ dataSource: newDataSource })
  }

  /**
   * 事業所情報検索・照会
   */
  showWS0247001_OfficeInfoRetrievalQuery = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 1400,
        component: (
          <WS0247001_OfficeInfoRetrievalQuery
            Lio_OfficeCode={this.formRef.current?.getFieldValue('OfficeCode')}
            Lio_BranchStoreCode={this.formRef.current?.getFieldValue('BranchStoreCodeF')}
            onFinishScreen={(output) => {
              if (output?.Lio_OfficeCode) {
                this.setState({ disableBranch: false })
              }
              this.formRef.current?.setFieldsValue({
                OfficeCode: output?.Lio_OfficeCode,
                BranchStoreCodeF: output?.Lio_BranchStoreCode,
                office_kanji_name: output?.recordData?.office_kanji_name
              })
              this.forceUpdate()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  render() {
    return (

      <div className='determine-tentative-reserve'>
        <ResizableBox
          height={400}
          width={Infinity}
          axis="y"
          resizeHandles={['s']}
          minConstraints={[Infinity, 100]}
          maxConstraints={[Infinity, 800]}
          style={{
            border: '1px solid #ddd',
            // padding: '20px',
            background: '#f0f2f5',
            overflow: 'auto',
          }}
        >

          <Card title='仮予約出力'>
            <Form
              ref={this.formRef}
              autoComplete='off'
            >
              <div className='box_container'>
                <div className='box_inner_horizontal'>
                  <div className='box_search' style={{ flexDirection: 'column' }}>
                    <div className='box_inner_horizontal' >
                      <Form.Item label='受診日' name='ReserveDateFChar' style={{ marginBottom: 0 }}>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          onBlur={() => {
                            this.checkDateStart();
                          }} />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>~</Form.Item>
                      <Form.Item name='ReserveDateTChar' style={{ marginBottom: 0 }}>
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          onBlur={() => {
                            this.checkDateEnd();
                          }} />
                      </Form.Item>
                    </div>
                    <div className='box_inner_horizontal'>
                      <Form.Item label='事業所' name='OfficeCode' style={{ marginBottom: 0 }}>
                        <Input.Search
                          allowClear={true}
                          className='input-search-size-number-allow-clear-8'
                          onPressEnter={() => {
                            if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                              this.showWS0247001_OfficeInfoRetrievalQuery()
                            }
                          }}
                          onSearch={(value, event) => {
                            if (event.target.localName === 'input') {
                              this.formRef.current?.setFieldsValue({
                                office_kanji_name: ''
                              })
                              this.forceUpdate()
                            } else {
                              if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                                this.showWS0247001_OfficeInfoRetrievalQuery()
                              }
                            }
                          }
                          }
                        />
                      </Form.Item>
                      <Form.Item name='office_kanji_name' style={{ marginBottom: 0 }}>
                        <div
                          style={{
                            display: 'flex',
                            width: 'fit-content',
                            height: 'fit-content',
                          }}
                        >{this.formRef.current?.getFieldValue('office_kanji_name')}</div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className='box_search_btn'>
                    <div className='box_search_btn_inner'>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          if (!this.state.errorBranchStore && !this.state.errorOfficeCode)
                            this.formRef.current?.setFieldsValue({
                              updateFlag: false
                            })
                          this.DisplayBtn()
                        }}
                      >
                        <span className='btn_label'>
                          検索
                        </span>
                      </Button>
                      <Tooltip title='詳細な条件追加'>
                        <Button size='small'
                          type='primary'
                          style={{ borderRadius: '12px' }}
                          className={(this.state.conditionAddData) ? 'condition-add-btn' : ''} //追加条件がある場合ボタン色変更
                          icon={<PlusOutlined />}
                          onClick={() => {
                            if (!this.state.errorBranchStore && !this.state.errorOfficeCode) {
                              this.setState({ isSearch: false })
                              this.setState({
                                childModal: {
                                  ...this.state.childModal,
                                  visible: true,
                                  width: 900,
                                  component: (
                                    <WS2786001_ConditionAddSub
                                      Li_DateF={
                                        this.formRef.current?.getFieldValue('ReserveDateFChar')
                                          ? this.formRef.current?.getFieldValue('ReserveDateFChar')?.format('YYYY/MM/DD')
                                          : ''
                                      }
                                      Li_DateT={
                                        this.formRef.current?.getFieldValue('ReserveDateTChar')
                                          ? this.formRef.current?.getFieldValue('ReserveDateTChar')?.format('YYYY/MM/DD')
                                          : ''
                                      }
                                      Li_Office={this.formRef.current?.getFieldValue('OfficeCode')}
                                      Li_ConditionAddData={this.formRef.current?.getFieldValue('recordData')}
                                      onFinishScreen={(output) => {
                                        this.formRef.current?.setFieldsValue({
                                          OutputOrder: output?.Lio_KeyInfo,
                                          recordData: output?.conditionAddData
                                        });
                                        this.setState({
                                          conditionAddData: output.conditionAddData
                                        })
                                        // this.forceUpdate();
                                        this.closeModal();
                                      }
                                      }
                                    />
                                  ),
                                },
                              });
                            }
                          }} />
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'end', margin: '0 0 0 auto' }}>
                    <div style={{ fontSize: 15 }}>{'合計' + '　' + (this.formRef.current?.getFieldValue('total') ?? '') + '人'}</div>
                  </div>
                </div>
                <Table
                  bordered
                  size='small'
                  dataSource={this.formRef.current?.getFieldValue('dataSource')}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'max-content', y: resizableTableScroll(120) }}
                  pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        this.showPersonalReserveProcess(record)
                      }
                    }
                  }}
                >
                  <Table.Column
                    title='日付'
                    dataIndex='date'
                    className='column-size-date'
                    render={(value, record, index) => {
                      let day = new Date(value)
                      return (
                        <div>{moment(day).format('YYYY/MM/DD (ddd)') ?? ''}</div>
                      )
                    }}
                  />
                  <Table.Column
                    title='事業所コード'
                    dataIndex='office_code'
                    className='input-search-size-single-byte-10'
                  />
                  <Table.Column
                    title='事業所名称'
                    dataIndex='office_kanji_name'
                    className='column-size-40'
                  />
                  <Table.Column
                    title='コース'
                    dataIndex='course'
                    className='column-size-60'
                  />
                  {
                    this.state.columnList.map((value, index) => {
                      return (
                        <Table.Column
                          key={index}
                          title={'オプション' + (index + 1)}
                          className='column-size-60'
                          render={(element, record) => {
                            return (
                              <div>{record.optionList[index]}</div>
                            )
                          }}
                        />
                      )
                    })
                  }
                  <Table.Column
                    key='action'
                    fixed='right'
                    width={40}
                    render={(text, record, index) => {
                      return (
                        <Dropdown
                          trigger='click'
                          size='small'
                          overlay={() => (
                            <Menu>
                              <Menu.Item
                                key='1'
                                onClick={() => {
                                  this.setState({
                                    childModal: {
                                      ...this.state.childModal,
                                      visible: true,
                                      width: 'fit-content',
                                      centered: true,
                                      component: (
                                        <WS2537001_PersonalReserveProcess
                                          Li_Date={record.date}
                                          Li_CourseLevel={record.course_level}
                                          Li_ReserveNum={record.reservation_number}
                                          Li_PersonalNum={
                                            record.personal_number_id
                                          }
                                          Li_Getctxname={''}
                                          Li_ProcessDivision={''}
                                          Li_Option={''}
                                          Li_Child={true}
                                          onDetermineReserve={(output) => {
                                            this.getReserveInfo(
                                              output.personal_number_id,
                                              output.period_time,
                                              record,
                                              index
                                            )
                                          }}
                                        />
                                      ),
                                    },
                                  })
                                }}
                              >
                                {/* 2537 */}
                                <label>変更</label>
                              </Menu.Item>
                            </Menu>
                          )}
                        >
                          <Button
                            size='small'
                            icon={<MoreOutlined />} />
                        </Dropdown>
                      )
                    }}
                  />
                </Table>
              </div>
            </Form>

            <div className='box_button_bottom_right'>
              <Button
                icon={<FileExcelOutlined />}
                type='primary'
                disabled={!(this.state.dataSource.length > 0)}
                onClick={() => this.outputExcel()}
              >
                <span className='btn_label'>
                  出力
                </span>
              </Button>
            </div>

            <ModalDraggable
              width={this.state.childModal.width}
              visible={this.state.childModal.visible}
              component={this.state.childModal.component}
              centered={this.state.childModal.centered}
              onCancel={() => {
                this.closeModal()
              }}
            />
          </Card>
        </ResizableBox>
        <ReserveStatusSearchCustom />



      </div>
    )
  }

}
const mapStateToProps = ({ userReducer, alertReducer }) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetermineTentativeReserve)
