import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ModalDraggable from 'components/Commons/ModalDraggable'
import { Card, Table, Modal, Button, message, Tooltip, Dropdown, Menu } from 'antd'
import { PlusOutlined, MoreOutlined, MenuOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import PrintParamInputAction from 'redux/ResultOutput/PrintParamMaintain/PrintParamMaintain.actions.js'
import WS0855009_PrintParamInput from './WS0855009_PrintParamInput'
import WS0855028_StyleCopy from './WS0855028_StyleCopy'
import WS0863001_PrintParamInputOutput from './WS0863001_PrintParamInputOutput'
import WS0434016_PrinterDesignScreenResultTbl from './WS0434016_PrinterDesignScreenResultTbl'
import WS0434022_PrinterDesignScreenReserveRelated from './WS0434022_PrinterDesignScreenReserveRelated'
import WS0857001_OldItem from './WS0857001_OldItem'
import Color from 'constants/Color'
import WSFileManager_FileManager from 'pages/ZZ_Others/CUSTOMIZE_Custom/WSFileManager_FileManager'

class WS0855001_PrintParamMaintain extends React.Component {
  static propTypes = {
    Li_MenuOption: PropTypes.any,
    Li_MenuAdminRights: PropTypes.any,
    Li_MenuAuthority: PropTypes.any,
    Li_SubjectNotBeChangedMode: PropTypes.any,
    Li_CourseLevel: PropTypes.any,
    Li_ReserveNum: PropTypes.any,
  }

  constructor(props) {
    super(props)

    // document.title = '印刷パラメータ保守'

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      pagination: {
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        size: 1,
        showQuickJumper: false,
        showSizeChanger: false
      },
      dataSource: [],
      rowSelect: {},
      isAddRow: false,
      modalConfirmFalg: false, //確認モーダルの表示フラグ
    }
  }

  /**
   * 初期表示処理
   */
  componentDidMount = () => {
    this.index()
  }

  /**
   * データ取得
   */
  index = () => {
    PrintParamInputAction.index()
      .then(res => {
        if (res) {
          this.setState({
            dataSource: res,
            rowSelect: res.length > 0 ? res[0] : {}
          })
        }
      })
  }

  // createOrUpdateRecord = (record) => {
  //   const params = {
  //     style_code: record.style_code,
  //     format_name: record.format_name
  //   }
  //   PrintParamInputAction.savePrintParamMaintainAction(params)
  //     .then(res => {
  //       message.success('成功')
  //       this.index()
  //       this.setState({ isAddRow: false })
  //     })
  // }

  /**
   * 削除
   * @param {*} record
   */
  deleteRecord = (record) => {
    Modal.confirm({
      content: '削除してもよろしいですか？',
      okText: 'は　い',
      cancelText: 'いいえ',
      onOk: () => {
        const params = {
          id: record.id
        }
        PrintParamInputAction.deletePrintParamMaintainAction(params)
          .then(res => {
            message.success('削除が完了しました')
            this.index()
          })
      }
    })

    // let arrTemp = [...this.state.dataSource]
    // arrTemp.splice(index, 1)
    // this.setState({
    //   dataSource: arrTemp,
    //   rowSelect: arrTemp.length > 0 ? arrTemp[0] : {},
    //   isAddRow: false
    // })
  }

  onChangeInput = (event, record) => {
    let { value, name } = event.target
    let arrTemp = [...this.state.dataSource]
    let index = arrTemp.indexOf(record)
    if (index !== -1) {
      let objTemp = {
        ...record,
        [name]: value
      }
      arrTemp[index] = objTemp
      this.setState({ dataSource: arrTemp })
    }
  }

  findIndexByID = (arrayData, recordID) => arrayData.findIndex((item) => recordID === item.id)

  /**
   * 新規追加
   */
  addRow = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS0857001_OldItem
            newFlag={true}
            Li_StyleCode={null}
            Li_StsListFormat={null}
            onFinishScreen={(output) => {
              this.index()
              this.closeModal()
            }}
            onEdit={(output) => {
              // 編集画面で変更状態で確認モーダルフラグを変更
              this.setState({ modalConfirmFalg: output })
            }}
          />
        ),
      },
    })
  }

  ReturnComponent = (component) => {
    let components = {
      WS0855009_PrintParamInput,
      WS0855028_StyleCopy,
      WS0863001_PrintParamInputOutput,
      WS0434016_PrinterDesignScreenResultTbl,
      WS0434022_PrinterDesignScreenReserveRelated,
      WS0857001_OldItem,
    }
    return components[component]
  }

  /**
   * モーダルを呼ぶ
   * @param {*} props
   * @param {*} width
   * @param {*} nameScreen
   */
  // callModal = (props, width, nameScreen) => {
  //   let Component = this.ReturnComponent(nameScreen)

  //   this.setState({
  //     childModal: {
  //       ...this.state.childModal,
  //       visible: true,
  //       width: 'fit-content',
  //       component: (
  //         <Component
  //           {...props}
  //           onFinishScreen={({ Lo_StsChange }) => {
  //             if (nameScreen === 'WS0855009_PrintParamInput') {
  //               PrintParamInputAction.stsChangeAction({ style_code: this.state.rowSelect.style_code, StsChange: Lo_StsChange })
  //                 .then()
  //             }
  //             this.closeModal()
  //           }}
  //         />
  //       ),
  //     },
  //   })
  // }

  /**
   * 複写
   */
  eventF7 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <WS0855028_StyleCopy
            Li_FormatName={this.state.rowSelect.format_name}
            Li_StyleCode={this.state.rowSelect.style_code}
            onFinishScreen={(output) => {
              this.index()
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 入出力
   */
  eventF9 = () => {
    let props = {
      Li_WindowType: 1,
      Li_StyleCode: this.state.rowSelect.style_code,
      Li_FormatName: this.state.rowSelect.format_name
    }
    // this.callModal(props, 400, 'WS0863001_PrintParamInputOutput')
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0863001_PrintParamInputOutput
            {...props}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })
  }

  /**
   * 印刷
   */
  eventF10 = (record) => {
    let props = {
      styleCode: record.style_code
    }

    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 400,
        component: (
          <WS0434016_PrinterDesignScreenResultTbl
            {...props}
            onFinishScreen={() => {
              this.closeModal()
            }}
          />
        ),
      },
    })


    // PrintParamInputAction.printf10({ style_code: this.state.rowSelect.style_code, StsListFormat: this.state.rowSelect.StsListFormat })
    //   .then(res => {
    //     if (res.message == 'WS0434016_PrinterDesignScreenResultTbl') {
    //       let props = {
    //         Li_TextFile: res.variables.Li_TextFile,
    //         Li_Printer: 1,
    //         Li_Preview: res.variables.Lo_Preview,
    //         Li_ReserveRelatedAndResultsTabl: 1,
    //         Li_StyleCodeResultTable: this.state.rowSelect.style_code,
    //         Li_FormatList: res.variables.Li_FormatList
    //       }
    //       // this.callModal(props, 400, 'WS0434016_PrinterDesignScreenResultTbl')
    //       this.setState({
    //         childModal: {
    //           ...this.state.childModal,
    //           visible: true,
    //           width: 400,
    //           component: (
    //             <WS0434016_PrinterDesignScreenResultTbl
    //               {...props}
    //               onFinishScreen={() => {
    //                 this.closeModal()
    //               }}
    //             />
    //           ),
    //         },
    //       })
    //     }
    //     if (res.message == 'WS0434022_PrinterDesignScreenReserveRelated') {
    //       let props = {
    //         Li_TextFile: res.variables.Li_TextFile,
    //         Li_Printer: 1,
    //         Li_Preview: res.variables.Lo_Preview,
    //         Li_ReserveRelatedAndResultsTabl: 1,
    //         Li_StyleCodeResultTable: this.state.rowSelect.style_code,
    //         Li_FormatList: res.variables.Li_FormatList
    //       }
    //       // this.callModal(props, 400, 'WS0434022_PrinterDesignScreenReserveRelated')
    //       this.setState({
    //         childModal: {
    //           ...this.state.childModal,
    //           visible: true,
    //           width: 400,
    //           component: (
    //             <WS0434022_PrinterDesignScreenReserveRelated
    //               {...props}
    //               onFinishScreen={() => {
    //                 this.closeModal()
    //               }}
    //             />
    //           ),
    //         },
    //       })
    //     }
    //   })
  }

  /**
   * 編集
   */
  eventF11 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        centered: true,
        component: (
          <WS0857001_OldItem
            newFlag={false}
            Li_StyleCode={this.state.rowSelect.style_code}
            Li_StsListFormat={this.state.rowSelect.StsListFormat}
            onFinishScreen={(output) => {
              this.index()
              this.closeModal()
            }}
            onEdit={(output) => {
              // 編集画面で変更状態で確認モーダルフラグを変更
              this.setState({ modalConfirmFalg: output })
            }}
          />
        ),
      },
    })
  }

  /**
   * 明細
   */
  eventF12 = () => {
    const params = { style_code: this.state.rowSelect.style_code, StsListFormat: this.state.rowSelect.StsListFormat }
    PrintParamInputAction.itemF12Action(params)
      .then(res => {
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: 'fit-content',
            centered: true,
            component: (
              <WS0855009_PrintParamInput
                newFlag={false}
                Li_StyleCode={this.state.rowSelect.style_code}
                Li_StsListFormat={this.state.rowSelect.StsListFormat}
                onFinishScreen={(output) => {
                  this.index()
                  this.closeModal()
                }}
              />
            ),
          },
        })
      }
      )
  }

  //   PrintParamInputAction.itemF12Action({ style_code: this.state.rowSelect.style_code, StsListFormat: this.state.rowSelect.StsListFormat })
  //     .then(res => {
  //       let props = {
  //         Li_StyleCode: this.state.rowSelect.style_code,
  //         Li_StsListFormat: this.state.rowSelect.StsListFormat,
  //         Lo_StsChange: false,
  //       }
  //       // this.callModal(props, '80%', 'WS0855009_PrintParamInput')

  //       this.setState({
  //         childModal: {
  //           ...this.state.childModal,
  //           visible: true,
  //           width: 1400,
  //           component: (
  //             <WS0863001_PrintParamInputOutput
  //               {...props}
  //               onFinishScreen={({ Lo_StsChange }) => {
  //                 PrintParamInputAction.stsChangeAction({ style_code: this.state.rowSelect.style_code, StsChange: Lo_StsChange })
  //                   .then()
  //                 this.closeModal()
  //               }}
  //             />
  //           ),
  //         },
  //       })
  //     })
  // }

  VariousSetting01 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 700,
        component: <WSFileManager_FileManager Li_Directory={'VREPORT'} />,
      },
    })
  }

  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
        centered: false,
      }
    })
  }

  render() {
    return (
      <div className='print-param-maintain'>
        <Card title='印刷パラメータ保守'>
          <Tooltip>
            <Dropdown
              size='small'
              className='option_btn_right_top'
              trigger='click'
              overlay={() => {
                return (
                  <Menu>
                    <Menu.Item key='1' style={{ width: '100%' }} onClick={() => this.VariousSetting01()}>
                      VREPORTフォルダ
                    </Menu.Item>
                  </Menu>
                )
              }}
            >
              <Button
                size='small'
                className='option_btn_right_top'
                icon={<MenuOutlined />}
              />
            </Dropdown>
          </Tooltip>

          <Table
            bordered
            size='small'
            dataSource={this.state.dataSource}
            pagination={this.state.dataSource.length > process.env.REACT_APP_PAGE_SIZE ? this.state.pagination : false}
            scroll={{ y: 600 }}
            rowKey={(record) => record.id}
            onRow={(record, index) => ({
              onClick: event => this.setState({ rowSelect: record }),
              onDoubleClick: () => { this.eventF11() }
            })}
          >
            <Table.Column
              title='様式'
              width={70}
              dataIndex='style_code'
            />
            <Table.Column
              title='様式名称'
              dataIndex='format_name'
            />
            <Table.Column
              title='区分'
              dataIndex='expression_16'
              width={70}
              align='center'
              render={(text, record) =>
                <div style={{ color: Color(record?.expression_17)?.Foreground }}>
                  {text}
                </div>
              }
            />
            <Table.Column
              title='ファイル名'
              dataIndex='expression_14'
            />
            <Table.Column
              align='center'
              width={40}
              title={
                <Button
                  size='small'
                  type='primary'
                  disabled={this.state.isAddRow}
                  icon={<PlusOutlined />}
                  onClick={() => this.addRow()}
                />
              }
              render={(text, record, index) => {
                return (
                  <Dropdown
                    trigger='click'
                    size='small'
                    overlay={() => (
                      <Menu>
                        <Menu.Item
                          key='1'
                          onClick={() => this.eventF11()}
                        >
                          編集
                        </Menu.Item>
                        <Menu.Item
                          key='5'
                          onClick={() => this.eventF7()}
                        >
                          複写
                        </Menu.Item>
                        <Menu.Item
                          key='3'
                          onClick={() => this.eventF9()}
                        >
                          入出力
                        </Menu.Item>
                        <Menu.Item
                          key='4'
                          onClick={() => this.eventF10(record)}
                        >
                          印刷
                        </Menu.Item>
                        <Menu.Item
                          key='7'
                          onClick={() => this.eventF12()}
                        >
                          明細
                        </Menu.Item>
                        <Menu.Item
                          key='6'
                          onClick={() => this.deleteRecord(record)}
                        >
                          削除
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button
                      size='small'
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                )
              }}
            />
          </Table>

        </Card >

        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          centered={this.state.childModal.centered}
          onCancel={() => {
            if (this.state.modalConfirmFalg) {
              //  [編集]のModalを開いていた場合、確認モーダルを表示
              Modal.confirm({
                title: '確認',
                content: '保存処理がされていません。このまま画面を閉じますか？',
                width: 480,
                icon: <QuestionCircleTwoTone />,
                okText: 'は　い',
                cancelText: 'いいえ',
                onOk: () => {
                  // 確認モーダルフラグの初期化
                  this.setState({ modalConfirmFalg: false })
                  this.closeModal()
                },
                onCancel: () => {
                  // 何もしない
                }
              })
            } else {
              this.closeModal()
            }
          }}
        />
      </div >
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0855001_PrintParamMaintain)
