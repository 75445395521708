import React from "react";
import { connect } from "react-redux";
import { Card, Table, message, Button, Input, Space } from "antd";

import XmlMedicalExamItemRefAction from "redux/SpecificInsureMaintenance/DataRangeCheck/XmlMedicalExamItemRef.action";

class WS1314005_XmlMedicalExamItemRef extends React.Component {
  constructor(props) {
    super(props);

    // document.title = 'XML用健診項目参照';

    this.state = {
      dataSource: [],
      searchText: '',
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadScreenData = () => {

    XmlMedicalExamItemRefAction.index({})
      .then(res => {
        this.setState({ dataSource: res.data })
      })
      .catch(error => {
        console.log(error);
        message.error("エラーが発生しました");
      }).finally();
  };


  render() {
    return (
      <div className="xml-medical-exam-item-ref">
        <Card className="mb-2" title="XML用">

          <Input style={{ width: 965 }}
            placeholder="項目コードまたは厚労省項目名の一部を入力することで絞り込みができます"
            onChange={(e) => {
              this.setState({ searchText: e.target.value })
              this.forceUpdate()
            }}
          />

          <Table
            style={{ marginTop: 10 }}
            size="small"
            bordered
            dataSource={this.state.dataSource.filter(item => item.item_code_jlac10_15.indexOf(this.state.searchText) > -1
              || item.ministry_item_name.indexOf(this.state.searchText) > -1)}
            loading={false}
            pagination={false}
            rowKey={(record) => record.id}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => {
                  this.props.onFinishScreen(record);
                },
              };
            }}
          >
            <Table.Column title="順番号" dataIndex="order_number" showSorterTooltip={false} sorter={(a, b) => a.order_number - b.order_number} />
            <Table.Column title="項目コード" dataIndex="item_code_jlac10_15" showSorterTooltip={false} sorter={(a, b) => a.item_code_jlac10_15.localeCompare(b.item_code_jlac10_15, 'jp')} />
            <Table.Column title="結果識別" dataIndex="result_identification_jlac10_2" showSorterTooltip={false} sorter={(a, b) => a.result_identification_jlac10_2.localeCompare(b.result_identification_jlac10_2, 'jp')} />
            <Table.Column title="厚労省項目名" dataIndex="ministry_item_name" showSorterTooltip={false} sorter={(a, b) => a.ministry_item_name.localeCompare(b.ministry_item_name, 'jp')} />
          </Table>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WS1314005_XmlMedicalExamItemRef);
