import React from "react";
import { connect } from "react-redux";
import { Card, Table, Form, message, Button, Modal, Space, Menu, Dropdown, Input, Radio, Select, } from "antd";
import { PlusOutlined, QuestionCircleOutlined, MoreOutlined, } from "@ant-design/icons";
import ModalDraggable from "components/Commons/ModalDraggable";
import ComplexDecisionMasterMaintenanceAddOrEdit from "pages/ZZ_Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenanceAddOrEdit.jsx";
import ComplexDecisionMasterMaintenanceFormat from "pages/ZZ_Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenanceFormat.jsx";
import ComplexDecisionMasterMaintenanceSubTableEdit from "pages/ZZ_Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenanceSubTableEdit.jsx";
import ComplexDecisionMasterMaintenanceAction from "redux/Others/ComplexDecisionMasterMaintenance/ComplexDecisionMasterMaintenance.action";

class ComplexDecisionMasterMaintenance extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    // document.title = "複合判定マスタ保守";

    this.state = {
      childModal: {
        visible: false,
        component: null,
        width: 0,
      },
      isLoadingTableParent: false,
      selectedRow: null,
      selectedRightRow: null,
      patterns: [],
      levels: [],
      leftTable: [],
      rightTable: [],
      rightTableColumns: [],
      showRightSubTable: false,
      rightSubTable: [],
    };
  }

  componentDidMount = () => {
    this.getScreenData();
  };


  getScreenData = () => {
    ComplexDecisionMasterMaintenanceAction.getScreenData()
      .then(res => {
        // セレクトボックスの中身取得　※パターンと判定レベル
        this.setState({
          patterns: res.result.patterns,
          levels: res.result.levels
        });
        this.formRef.current?.setFieldsValue({
          category: 0,
          patterns: res.result.patterns[0].pattern_code,
          levels: res.result.levels[0].condition_1
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.getLeftTable()
        this.setState({
          isLoadingTableParent: false,
        });
      });
  }


  getLeftTable = () => {
    let params = {
      category: this.formRef.current?.getFieldValue('category'),
      pattern: this.formRef.current?.getFieldValue('patterns'),
      level: this.formRef.current?.getFieldValue('levels')
    }
    ComplexDecisionMasterMaintenanceAction.getLeftTable(params)
      .then(res => {
        this.setState({leftTable: res.result})
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.formRef.current?.setFieldsValue({
          format: '',
          sex_division: '',
        })
        this.setState({
          selectedRow: null,
        });
        this.getRightTable()
        this.setState({ isLoadingTableParent: false });
      });
  }


  getRightTable = () => {
    let params = {
      category: this.formRef.current?.getFieldValue('category'),
      format: this.formRef.current?.getFieldValue('format'),
    }
    ComplexDecisionMasterMaintenanceAction.getRightTable(params)
      .then(res => {
        this.setState({
          rightTable: res.result,
          rightTableColumns: res.columns,
          showRightSubTable: this.formRef.current?.getFieldValue('category') ? true : false,
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({
          rightSubTable: [],
          isLoadingTableParent: false
        });
      });
  }


  getSubTable = (record) => {
    let params = {
      format: this.formRef.current?.getFieldValue('format'),
      j46Id: record.id
    }
    console.log(record)
    ComplexDecisionMasterMaintenanceAction.getSubTable(params)
      .then(res => {
        console.log(res.result)
        this.setState({
          rightSubTable: res.result ? res.result : [],
        })
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableParent: false });
      });
  }


  formatChange = () => {
    if(!this.state.selectedRow?.id) {
      message.error("対象のコードを選択してください");
      return;
    }
    let params = {
      id: this.state.selectedRow?.id ?? 0,
      category: this.formRef.current?.getFieldValue('category'),
      format: this.formRef.current?.getFieldValue('format'),
    }
    ComplexDecisionMasterMaintenanceAction.formatChange(params)
      .then(res => {
        let newLeftTable = this.state.leftTable.map(record => {
          if(record.id == this.state.selectedRow.id) {
            record.format = res.format
          }
          return record
        })
        this.setState({leftTable: newLeftTable})
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.getRightTable()
        this.setState({ isLoadingTableParent: false });
      });
  }


  sexDivisionChange = () => {
    if(!this.state.selectedRow?.id) {
      message.error("対象のコードを選択してください");
      return;
    }
    let params = {
      id: this.state.selectedRow?.id ?? 0,
      category: this.formRef.current?.getFieldValue('category'),
      sex_division: this.formRef.current?.getFieldValue('sex_division'),
    }
    ComplexDecisionMasterMaintenanceAction.sexDivisionChange(params)
      .then(res => {
        let newLeftTable = this.state.leftTable.map(record => {
          if(record.id == this.state.selectedRow.id) {
            record.sex_division = res.sex_division
          }
          return record
        })
        this.setState({leftTable: newLeftTable})
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableParent: false });
      });
  }


  addOrEdit = (record) => {
    let params = {
      record: record, // あれば編集なければ追加
      category: this.formRef.current?.getFieldValue('category'),
      format: this.formRef.current?.getFieldValue('format'),
    }
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        centered: true,
        width: 'fit-content',
        component: (
          <ComplexDecisionMasterMaintenanceAddOrEdit
            columns={this.state.rightTableColumns}
            params={params}
            onFinishScreen={(output) => {
              params = {
                ...params,
                ...output
              }
              ComplexDecisionMasterMaintenanceAction.addOrEdit(params)
                .then(res => {
                  if(res?.message) {
                    message.info(res.message);
                  }
                  this.getRightTable()
                  this.closeModal()
                })
                .catch((err) => {
                  const res = err.response;
                  if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                  }
                  message.error(res.data.message);
                })
                .finally(() => {
                  this.setState({ isLoadingTableParent: false });
                });
            }}
          />
        )
      }
    })
  }


  delete = (record) => {
    let params = {
      id: record.id,
      category: this.formRef.current?.getFieldValue('category'),
    }
    ComplexDecisionMasterMaintenanceAction.delete(params)
      .then(res => {
        if(res?.message) {
          message.info(res.message);
        }
        this.getRightTable()
        if(this.state.selectedRightRow == record) {
          this.setState({
            selectedRightRow: [],
            rightSubTable: [],
          });
        }
        this.closeModal()
      })
      .catch((err) => {
        const res = err.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => {
        this.setState({ isLoadingTableParent: false });
      });
  }


  editSubTable = (record) => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ComplexDecisionMasterMaintenanceSubTableEdit
            record={record}
            onFinishScreen={(output) => {
              let params = {
                ...output,
                j46Id: record.j46Id,
                no: record.no,
              }
              ComplexDecisionMasterMaintenanceAction.editSubTable(params)
                .then(res => {
                  if(res?.message) {
                    message.info(res.message);
                  }
                  let id = {
                    id: record.j46Id
                  }
                  this.getSubTable(id)
                  this.closeModal()
                })
                .catch((err) => {
                  const res = err.response;
                  if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return;
                  }
                  message.error(res.data.message);
                })
                .finally(() => {
                  this.setState({ isLoadingTableParent: false });
                });
            }}
          />
        )
      }
    })
  }


  showComplexDecisionMasterMaintenanceFormat = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 'fit-content',
        component: (
          <ComplexDecisionMasterMaintenanceFormat
            onFinishScreen={(output) => {
              this.formRef.current?.setFieldsValue({format: output})
              this.getRightTable()
              this.formatChange()
              this.closeModal();
            }}
          />
        )
      }
    })
  }


  closeModal = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: false,
      },
    });
  };


  render() {
    return (
      <div className="spreadsheet-input-setting">
        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          initialValues={{}}
        >
          <Card title="複合判定マスタ保守">
            {/* 上部選択エリア */}
            <div className="box_inner_horizontal" >
              <Form.Item
                name='category'
              >
                <Radio.Group
                  style={{ display: 'flex' }}
                  onChange={event => {
                    this.getLeftTable()
                  }}
                >
                  <Radio
                    key={0}
                    value={0}
                  >
                    カテゴリ
                  </Radio>
                  <Radio
                    key={1}
                    value={1}
                  >
                    検査
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='パターン'
                name='patterns'
              >
                <Select
                  style={{ width: 150 }}
                  onChange={event => this.getLeftTable()}
                >
                  {this.state.patterns.map((pattern, index) => {
                    return(
                      <Select.Option
                        key={pattern.pattern_code}
                        value={pattern.pattern_code}
                      >
                        {pattern.pattern_name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label='判定レベル'
                name='levels'
              >
                <Select
                  style={{ width: 150 }}
                  onChange={event => this.getLeftTable()}
                >
                  {this.state.levels.map((level, index) => {
                    return(
                      <Select.Option
                        key={level.condition_1}
                        value={level.condition_1}
                      >
                        {level.item}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </div>

            {/* 下部テーブルエリア */}
            <div className="box_inner_horizontal" >
              {/* 左テーブル */}
              <div>
                <Table
                  bordered={true}
                  dataSource={this.state.leftTable}
                  style={{ width: 300 }}
                  size='small'
                  pagination={false}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'max-content', y: "69vh" }}
                  rowClassName={(record, index) => {
                    if (this.state.selectedRow) {
                      return record.category_code ===
                        this.state.selectedRow.category_code
                        ? "ant-table-row-selected"
                        : "";
                    }
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        this.formRef.current?.setFieldsValue({
                          format: record.format,
                          sex_division: record.sex_division,
                        })
                        this.getRightTable()
                        this.setState({
                          selectedRow: record,
                        });
                      },
                    };
                  }}
                >
                  <Table.Column
                    width={80}
                    title="コード"
                    dataIndex="category_code"
                  />
                  <Table.Column
                    width={240}
                    title="カテゴリ名称"
                    dataIndex="category_name"
                  />
                </Table>
              </div>
              {/* 右側 */}
              <div>
                {/* FORMATと性別 */}
                <div className="box_inner_horizontal" >
                  <Form.Item
                    label='FORMAT'
                    name='format'
                  >
                    <Input.Search
                      onBlur={() => {
                        if(this.formRef.current?.getFieldValue('format') != this.state.selectedRow.format) {
                          this.formatChange()
                        }
                      }}
                      onPressEnter={() => this.formatChange()}
                      onSearch={(value, event) => {this.showComplexDecisionMasterMaintenanceFormat()}}
                    />
                  </Form.Item>
                  <Form.Item
                    label='性別'
                    name='sex_division'
                  >
                    <Select
                      style={{ width: 150 }}
                      onChange={() => this.sexDivisionChange()}
                    >
                      <Select.Option key={0} value={0}>共通</Select.Option>
                      <Select.Option key={1} value={1}>個別</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                {/* 右テーブル */}
                <Table
                  bordered={true}
                  dataSource={this.state.rightTable}
                  size={'small'}
                  pagination={false}
                  scroll={{ x: 'max-content', y: "69vh" }}
                  rowKey={(record) => record.id}
                  rowClassName={(record, index) => {
                    if (this.state.selectedRightRow) {
                      return record.id === this.state.selectedRightRow.id ? "ant-table-row-selected" : "";
                    }
                  }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: () => {
                        if(record != this.state.selectedRightRow) {
                          this.setState({
                            selectedRightRow: record,
                          });
                          if(this.formRef.current?.getFieldValue('category')) {
                            this.getSubTable(record)
                          }
                        }
                      },
                      onDoubleClick: () => {
                        this.addOrEdit(record)
                      },
                    };
                  }}
                >
                  {Object.keys(this.state.rightTableColumns).map(key => {
                    return (
                      <Table.Column
                        title={this.state.rightTableColumns[key]}
                        dataIndex={key}
                        key={key}
                        className='column-size-5'
                        render={(text, record, index) => {
                          if(this.state.rightTableColumns[key] == '性別') {
                            if(text == 0) {
                              return '共通'
                            } else if (text == 1) {
                              return '男性'
                            } else if (text == 2) {
                              return '女性'
                            }
                          } else {
                            return text
                          }
                        }}
                      />
                    )
                  })}
                  <Table.Column
                    width={50}
                    key="action"
                    align="center"
                    title={() =>
                      <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.addOrEdit()
                        }}
                      />
                    }
                    render={(text, record, index) => {
                      return (
                        <Space>
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                <Menu.Item
                                  key='1'
                                  onClick={() => {
                                    this.addOrEdit(record)
                                  }}
                                >
                                  編集
                                </Menu.Item>
                                <Menu.Item
                                  key='2'
                                  onClick={() => {
                                    Modal.confirm({
                                      title: "確認",
                                      icon: (
                                        <QuestionCircleOutlined
                                          style={{ color: "#1890ff" }}
                                        />
                                      ),
                                      content: "削除しますか",
                                      onOk: () => {
                                        this.delete(record)
                                      },
                                    });
                                  }}
                                >
                                  削除
                                </Menu.Item>
                              </Menu>
                            )}
                          >
                            <Button
                              icon={<MoreOutlined />}
                              size="small"
                            />
                          </Dropdown>
                        </Space>
                      );
                    }}
                  />
                </Table>
                <div
                  style={{display: this.state.showRightSubTable ? 'block' : 'none'}}
                >
                  <Table
                    bordered={true}
                    dataSource={this.state.rightSubTable}
                    size={'small'}
                    pagination={false}
                    style={{marginTop: '12px'}}
                    scroll={{ x: 'max-content', y: "69vh" }}
                    rowKey={(record) => record.no}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.editSubTable(record)
                        },
                      };
                    }}
                  >
                    <Table.Column
                      dataIndex='inspection'
                      className='column-size-5'
                    />
                    <Table.Column
                      dataIndex='hl'
                      className='column-size-5'
                    />
                    <Table.Column
                      dataIndex='exam_judgment'
                      className='column-size-5'
                    />
                    <Table.Column
                      dataIndex='exam_hl'
                      className='column-size-5'
                    />
                    <Table.Column
                      width={50}
                      key="action"
                      align="center"
                      render={(text, record, index) => {
                        return (
                          <Space>
                            <Dropdown
                              overlay={() => (
                                <Menu>
                                  <Menu.Item
                                    key='1'
                                    onClick={() => {
                                      this.editSubTable(record)
                                    }}
                                  >
                                    編集
                                  </Menu.Item>
                                </Menu>
                              )}
                            >
                              <Button
                                icon={<MoreOutlined />}
                                size="small"
                              />
                            </Dropdown>
                          </Space>
                        );
                      }}
                    />
                  </Table>
                </div>
              </div>
            </div>
          </Card>
        </Form>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              childModal: {
                ...this.state.childModal,
                visible: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplexDecisionMasterMaintenance);
