import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, Form, InputNumber, Select, Space, Modal, message } from 'antd'
import coppy from 'assets/img/coppy.png'
import print from 'assets/img/print.png'
import PrinterDesignScreenResultTblAction from 'redux/ResultOutput/PrintParamMaintain/PrinterDesignScreenResultTbl.action'
import { download_file } from 'helpers/CommonHelpers'

const styleImg = {
  marginBottom: '0.5em',
  width: '50px',
  padding: '2px'
}

const buttonStyle = {
  height: 100,
  width: 125,
  // border: '1px solid #14468C',
  borderRadius: 5,
}
class WS0434016_PrinterDesignScreenResultTbl extends React.Component {
  static propTypes = {
    Li_TextFile: PropTypes.string,
    Li_PreviewSpecifiedValue: PropTypes.any,
    Li_PrinterNoSpecifiedValue: PropTypes.number,
    Lo_Preview: PropTypes.any,
    Lo_PrinterNo: PropTypes.number,
    Lo_NumOfCopies: PropTypes.number,
    Lo_StsOutput: PropTypes.any,
    onFinishScreen: PropTypes.func
  }
  formRef = React.createRef()

  constructor(props) {
    super(props)

    // document.title = 'ﾌﾟﾘﾝﾀｰ指定画面[結果表]'

    this.state = {
      parramIndex: {
        Li_PreviewSpecifiedValue: '',
        Li_PrinterNoSpecifiedValue: ''
      },
      screenData: {
        NumCopies: '',
        Preview: '',
        PrinterNo: '',
        PrinterNo_GB: []
      },
      isLoadingPrint: false,
    }
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    this.setState({
      parramIndex: {
        ...this.state.parramIndex,
        // Li_PreviewSpecifiedValue: this.props.Li_Preview,
        // Li_PrinterNoSpecifiedValue: this.props.Li_Printer
      }
    })
    this.onFinish()
  }

  onFinish(values) {
    PrinterDesignScreenResultTblAction.getScreenDataTbl(this.state.parramIndex).then(res => {
      let data = res ? res : []
      data.PrinterNo = data.PrinterNo > 0 ? data.PrinterNo : ''
      this.setState({
        screenData: data
      })
      // this.formRef.current?.setFieldsValue(this.state.screenData = res)
    })
  }

  onSelectCBB(value) {
    this.setState({
      screenData: {
        ...this.state.screenData,
        PrinterNo: value
      }
    })
  }

  onEvent_F12 = (previewFlag) => {
    this.setState({
      isLoadingPrint: true
    })

    let params = {
      // Li_FormatList: this.props.Li_FormatList || '',
      // TextFile: this.props.Li_TextFile || '',
      Preview: previewFlag ? 1 : 0,
      Printer: this.formRef.current.getFieldValue('PrinterNo'),
      NumCopies: this.formRef.current.getFieldValue('NumCopies') || this.state.screenData.NumCopies,
      styleCode: this.props.styleCode
    }

    PrinterDesignScreenResultTblAction.onEventF12(params)
      .then(res => {
        if (previewFlag) {
          // pdfのObject作成
          // Serviceで{ responseType: "blob" }を設定する必要がある
          let fileURL = URL.createObjectURL(res.data);
          // pdfを別タブで表示
          window.open(fileURL)

          message.success('プレビューが完了しました')
        } else {
          message.success('印刷が完了しました')
        }
      })
      .catch(error => {
        const res = error.response
        if (res) {
          if (res.request.responseType === 'blob') {
            res.data.text()
              .then(str => {
                let json = JSON.parse(str);
                message.error(json.message)
              })
          }
          if (error.message.includes('504')) {
            return Modal.error({
              title: 'タイムアウトしました',
              content: '',
            });
          }
        }
      }).finally(() => {
        this.setState({
          isLoadingPrint: false
        })
      })

    // .then(res => {
    //   if (res.data.message) {
    //     return Modal.warning({
    //       title: res.data.message,
    //       width: 300,
    //     })
    //   } else {
    //     download_file(res)
    //     message.success('完了！')
    //   }
    // })
    // .catch(error => {
    //   const res = error.response
    //   if (!res || !res.data || !res.data.message) {
    //     message.error('エラーが発生しました')
    //     return
    //   }
    //   message.error(res.data.message)
    // }).finally(() => {
    //   this.setState({
    //     isLoadingPrint: false
    //   })
    // })
  }

  render() {
    return (
      <div className='printer-design-screen-result-tbl' >

        <Form
          initialValues={{ PrinterNo: true }}
          ref={this.formRef}
          onFinish={this.onFinish}
        >
          <Card title='ﾌﾟﾘﾝﾀｰ指定画面[結果表]'>
            <Form.Item name='PrinterNo' label='プリンタ' >
              <Select
                defaultValue={this.state.screenData?.PrinterNo ?? ''}
                onChange={(value) => this.onSelectCBB(value)}
              >
                {this.state.screenData.PrinterNo_GB?.map(value => (
                  <Select.Option key={value.Linked} value={value.Linked}>
                    {value.Display}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='NumCopies' label='　　部数' >
              <InputNumber style={{ width: '30%' }} />
            </Form.Item>

            <div className='box_button_bottom_center'>
              <Button
                style={buttonStyle}
                onClick={() => this.onEvent_F12(false)}
              >
                <div
                  style={{ textAlign: 'center', padding: '0.5em' }}
                  key='print'
                >
                  <img src={print} style={styleImg} />
                  <br />
                  印刷
                </div>
              </Button>

              <Button
                style={buttonStyle}
                onClick={() => this.onEvent_F12(true)}
              >
                <div
                  style={{ textAlign: 'center', padding: '0.5em' }}
                  key='preview'
                >
                  <img src={coppy} style={styleImg} />
                  <br />
                  プレビュー
                </div>
              </Button>
            </div>

          </Card>

        </Form>
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(WS0434016_PrinterDesignScreenResultTbl)
