import VenusDatePickerCustom from "components/Commons/VenusDatePickerCustom";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalDraggable from "components/Commons/ModalDraggable";
import {
  Card,
  Form,
  Input,
  Select,
  Table,
  Row,
  Col,
  Modal,
  Spin,
  message,
  Space,
  Button,
  InputNumber,
  Menu,
  Dropdown,
  Tooltip
} from "antd";
import { QuestionCircleOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { number_format } from "helpers/CommonHelpers";

import WS0961004_TamperProofRelease from "./WS0961004_TamperProofRelease";
import WS0947001_Invoice from "./WS0947001_Invoice";
import WS0975001_BillingInquiry from "../V4BL0030000_BillingInquiry/WS0975001_BillingInquiry";
import WS0246001_InsurerInfoSearchQuery from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0246001_InsurerInfoSearchQuery";
import WS0248001_PersonalInfoSearchQuery from "pages/BS_BasicInfo/V4MS0003000_PersonalInfoMaintainDirectly/WS0248001_PersonalInfoSearchQuery";
import WS0247001_OfficeInfoRetrievalQuery from "pages/BS_BasicInfo/V4MS0002000_OfficeInfoMaintainDirectly/WS0247001_OfficeInfoRetrievalQuery";
import WS0084001_PostCodeSearchEngine from "pages/BS_BasicInfo/V4MS0001000_InsurerInfoMaintain/WS0084001_PostCodeSearchEngine";
import WS2626028_IntegrationReleased from "pages/BL_AccountingBusiness/V5BL0001000_Invoice/WS2626028_IntegrationReleased";
import WS0961005_BillingDateChange from "./WS0961005_BillingDateChange";
import BillingIntegrationAction from "redux/AccountingBusiness/BillingIntegration/BillingIntegration.actions";

const grid = { labelCol: { span: 4 }, wrapperCol: { span: 20 } };
const gridSM = { labelCol: { span: 7 }, wrapperCol: { span: 17 } };

class WS0961001_BillingIntegration extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    Li_BillingManageNum: PropTypes.any,
    onFinishScreen: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      childModal: { visible: false, component: null, width: 0 },
      showLessThanTaxCircle: false,
      isloadFrm: false,
      isLoadingTable: false,
      isIntegrated: false,
      StyleNum: [],
      tableNull: [
        {
          W1_serial_num: "",
          W1_billing_manage_num: 0,
          W1_identify: 5,
          W1_address: "",
          W1_subject: "",
          W1_billing_period_beginning: null,
          W1_billing_period_final: null,
          W1_person: "",
          W1_billing_amount: "",
          W1_price_exclude_tax_sum: "",
          W1_billing_tax_price: "",
          W1_deposit_amount: "",
          W1_invoice_num: "",
          id: "",
          w1_aggregate_flag: 0
        }
      ],
      count: "a",
      dataScreen: {},
      ClassifyState: null,
      TaxCalculateUnit: null,
      TaxClassifyState: null
    };
  }

  componentDidMount() {
    this.initializeComponent();
    this.checkIntegrationStatus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.Li_BillingManageNum !== prevProps.Li_BillingManageNum) {
      this.handleLiBillingManageNumChange();
      this.checkIntegrationStatus();
    }
  }

  checkIntegrationStatus() {
    const billingManageNum = this.formRef.current?.getFieldValue("BillingManageNum");
    if (billingManageNum) {
      BillingIntegrationAction.checkIntegrationStatus({ BillingManageNum: billingManageNum })
        .then(res => {
          this.setState({ isIntegrated: res.data.isIntegrated });
        })
        .catch(error => {
          message.error("統合状態の確認に失敗しました");
        });
    } else {
      this.setState({ isIntegrated: false });
    }
  }

  initializeComponent() {
    // 初期化処理
    localStorage.setItem("BillingIntegration_is_update", "0");
    localStorage.removeItem("BillingIntegration");
    localStorage.removeItem("BillingIntegrationTableData");
    this.Expression_141();

    const billingManageNum = this.props.Li_BillingManageNum || null;
    this.formRef.current?.setFieldsValue({
      BillingManageNum: billingManageNum
    });

    if (billingManageNum) {
      this.getDataDisplay(true);
    } else {
      this.getInit();
    }
  }

  handleLiBillingManageNumChange() {
    this.Expression_141();
    const billingManageNum = this.props.Li_BillingManageNum || null;
    this.formRef.current?.setFieldsValue({
      BillingManageNum: billingManageNum
    });

    if (billingManageNum) {
      this.getDataDisplay(true);
    } else {
      this.getInit();
    }
  }

  // localStorageの更新確認を別途関数で処理する
  handleLocalStorageUpdate() {
    const isUpdated = localStorage.getItem("BillingIntegration_is_update") === "1";
    if (isUpdated) {
      localStorage.setItem("BillingIntegration_is_update", "0");
      const storedData = JSON.parse(localStorage.getItem("BillingIntegration"));
      const tableData = JSON.parse(localStorage.getItem("BillingIntegrationTableData"));

      this.formRef.current?.setFieldsValue({
        ...storedData,
        tableData
      });
    }
  }

  Expression_141() {
    BillingIntegrationAction.Expression_141().then(res => {
      this.setState({ StyleNum: res ? res : [] });
    });
  }

  getInit() {
    this.setState({ isloadFrm: true });
    BillingIntegrationAction.getInit()
      .then(res => {
        let data = this.setValueDisplay(res?.data);
        this.formRef.current?.setFieldsValue(data);

        this.formRef.current?.setFieldsValue({
          TaxClassifyState: 0
        });
   
        let classify = this.formRef.current?.getFieldValue("Classify");
        this.setState({
          dataScreen: res?.data,
          ClassifyState: classify,
          TaxCalculateUnit: res?.data?.TaxCalculateUnit,
     
          TaxClassifyState: 0
      
        });
      })
      .catch(error => {
        let res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isloadFrm: false }));
  }

  setValueDisplay(res) {
    let getFieldValue = this.formRef.current?.getFieldValue();
    let DepositAmountOld = this.isEmpty(getFieldValue.DepositAmount)
      ? 0
      : Number(getFieldValue.DepositAmount.replace(/,/g, ""));
    res.BillingDateChar = this.isEmpty(res.BillingDateChar) ? null : moment(res.BillingDateChar);
    res.BillingPeriodBeginningChar = this.isEmpty(res.BillingPeriodBeginningChar)
      ? null
      : moment(res.BillingPeriodBeginningChar);
    res.BillingPeriodFinalChar = this.isEmpty(res.BillingPeriodFinalChar)
      ? null
      : moment(res.BillingPeriodFinalChar);
    res.ClaimYearsChar = this.isEmpty(res.ClaimYearsChar) ? null : moment(res.ClaimYearsChar);
    res.DepositAmount =
      res.DepositAmount == 0 || this.isEmpty(res.DepositAmount)
        ? number_format(DepositAmountOld)
        : number_format(res.DepositAmount + DepositAmountOld);
    this.changeAmount();
    if (res.Expression_27 == null) {
      res.Expression_27 = "解除";
    }
    return res;
  }

  getDataDisplay(reload = false) {
    this.setState({ isLoadingTable: true });
    var isUpdate = localStorage.getItem("BillingIntegration_is_update");
    let storeData;
    if (isUpdate === "1") {
      storeData = JSON.parse(localStorage.getItem("BillingIntegration"));
    } else {
      storeData = this.formRef.current?.getFieldsValue();
    }
    let params = {
      BillingManageNum: this.formRef.current?.getFieldValue("BillingManageNum"),
      W1_identify: this.formRef.current?.getFieldValue("W1_identify")
    };
    if (storeData) {
      params = {
        BillingManageNum: storeData.BillingManageNum,
        W1_identify: storeData.W1_identify
      };
      if (params.W1_identify == null) {
        params.W1_identify = this.formRef.current?.getFieldValue("W1_identify")
      }
      if (params.W1_identify == null) {
        params.W1_identify = storeData.Classify
      }
    }
    BillingIntegrationAction.getDataDisplay(params)
      .then(res => {
        if (res?.data?.message === "Call ScreenDisplayAfter") {
          this.getDataDisplayAfter();
        } else if (res?.data?.message === "削除データは選択できません") {
          alert("削除データは選択できません");
        } else {
          const val = this.setValueDisplay(res?.data?.BillingInfo);
          val.BillingManageNum = params.BillingManageNum;
          this.formRef.current?.setFieldsValue(val);
          this.formRef.current?.setFieldsValue({
            TaxClassifyState: res?.data?.BillingInfo?.TaxClassify
          });
          this.setState({
            dataScreen: res?.data?.BillingInfo,
            ClassifyState: res?.data?.BillingInfo?.Classify,
            TaxCalculateUnit: res?.data?.BillingInfo?.TaxCalculateUnit,
            showLessThanTaxCircle: res?.data?.BillingInfo?.TaxCalculateUnit === 1,
            TaxClassifyState: res?.data?.BillingInfo?.TaxClassify,
            isLoadingTable: false
          });
          this.formRef.current.setFieldsValue({
            TaxClassifyState: this.state.TaxClassifyState,
          });
          if (res?.data?.BillingDetail && res?.data?.BillingDetail.length > 0) {
            let data = [];
            let idx = 0;
            res?.data?.BillingDetail.forEach(detail => {
              idx++;
              const newData = {
                W1_serial_num: idx,
                W1_billing_manage_num: detail.W1_billing_manage_num ?? "",

                W1_billing_period_beginning: detail.W1_consult_start_date ?? detail.W1_billing_period_beginning ?? "",
                W1_billing_period_final: detail.W1_consult_end_date ?? detail.W1_billing_period_final ?? "",

                W1_billing_amount: detail.W1_billing_amount ?? "",
                W1_price_exclude_tax_sum: detail.W1_price_exclude_tax_sum ?? "",
                W1_billing_tax_price: detail.W1_billing_tax_price ?? "",
                W1_deposit_amount: detail.W1_deposit_amount ?? "",
                W1_invoice_num: detail.W1_invoice_num ?? "",
                W1_address: detail.W1_address,
                W1_subject: detail.W1_subject,
                W1_identify: detail.W1_identify,
                W1_identify_name: detail.Expression_9,
                w1_aggregate_flag: detail.w1_aggregate_flag
              };
              data.push(newData);
            });
            this.formRef.current?.setFieldsValue({ tableData: data });
            this.setState({ ...this.state, count: data.length });
            localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
          }
          localStorage.setItem("BillingIntegration_is_update", "1");
          localStorage.setItem("BillingIntegration", JSON.stringify(val));
          this.getDataTableSub(reload);

        }
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  getDataDisplayAfter() {
    let params = { BillingManageNum: this.formRef.current?.getFieldValue("BillingManageNum") };
    BillingIntegrationAction.getDataDisplayAfter(params)
      .then(res => {
        const val = this.setValueDisplay(res?.data?.BillingInfo);
        this.formRef.current?.setFieldsValue(val);
        this.setState({
          dataScreen: res?.data?.BillingInfo,
          ClassifyState: res?.data?.BillingInfo?.Classify,
          TaxCalculateUnit: res?.data?.BillingInfo?.TaxCalculateUnit,
          showLessThanTaxCircle: res?.data?.BillingInfo?.TaxCalculateUnit === 1,
          TaxClassifyState: res?.data?.BillingInfo?.TaxClassify,
          isLoadingTable: false
        });
        this.formRef.current.setFieldsValue({
          TaxClassifyState: this.state.TaxClassifyState,
        });
        this.getDataTableSub();
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      });
  }

  getDataTableSub(reload = false) {
    let params = { BillingManageNum: this.formRef.current?.getFieldValue("BillingManageNum") };
    BillingIntegrationAction.getDataTableSub(params)
      .then(res => {
        if (reload) {
          localStorage.setItem("BillingIntegration_is_update", "1");
          localStorage.setItem(
            "BillingIntegration",
            JSON.stringify(this.formRef.current?.getFieldValue())
          );
        }
        let index = reload ? 0 : this.state.indexTable;
        this.setState({
          dataSource: res && res.data.length > 0 ? res.data : this.state.tableNull,
          selectedRow: res && res.data.length > 0 ? res.data[index] : {},
          selectedRowKeys: res && res.data.length > 0 ? [res.data[index]] : [],
          indexTable: index
        });
        if (!reload) {
          localStorage.setItem("BillingIntegration_is_update", "1");
          localStorage.setItem(
            "BillingIntegration",
            JSON.stringify(this.formRef.current?.getFieldValue())
          );
        }
        this.changeAmount();
      })
      .catch(error => {
        this.setState({ isLoadingTable: false });
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return;
        }
        message.error(res.data.message);
      })
      .finally(() => this.setState({ isLoadingTable: false }));
  }

  SetBillingDetail(data) {
    const arrData = [...this.formRef.current?.getFieldValue("tableData")];
    if (arrData.length > 0) {
      let arrCheck = [];
      let arrKey = [];
      arrData.map((value, index) => {
        arrKey.push(value.W1_billing_manage_num);
        if (value.W1_billing_manage_num !== 0) {
          arrCheck.push(value);
        }
        if (arrKey.length === arrData.length) {
          if (data && data.length === 0) {
            this.formRef.current?.setFieldsValue({ tableData: arrCheck });
          } else {
            data.map((val, index) => {
              if (arrKey.indexOf(val.W1_billing_manage_num) === -1) {
                arrCheck.push(val);
              }
              if (data.length - 1 === index) {
                this.formRef.current?.setFieldsValue({ tableData: arrCheck });
              }
            });
          }
        }
      });
    }
  }

  // 金額再計算処理
  changeAmount = () => {
    let getFieldValue = this.formRef.current?.getFieldValue();
    let tableData = getFieldValue.tableData ?? [];


    let tax = this.isEmpty(getFieldValue.Tax)
      ? null
      : getFieldValue.Tax;
    let taxRate = this.isEmpty(getFieldValue.TaxRate) ? 10 : Number(getFieldValue.TaxRate);
    let TaxCalculateUnit = this.isEmpty(getFieldValue.TaxCalculateUnit)
      ? null
      : getFieldValue.TaxCalculateUnit;
    let LessThanTaxCircle = this.isEmpty(getFieldValue.LessThanTaxCircle)
      ? null
      : getFieldValue.LessThanTaxCircle;
    let TaxClassifyState = this.isEmpty(getFieldValue.TaxClassifyState)
      ? 0
      : getFieldValue.TaxClassifyState;


      var isUpdate = localStorage.getItem("BillingIntegration_is_update");
      if (localStorage.getItem("BillingIntegration") && isUpdate === "1") {
        var temp = localStorage.getItem("BillingIntegrationTableData")
        if (temp && temp != 'undefined')
          tableData = JSON.parse(localStorage.getItem("BillingIntegrationTableData"));
        getFieldValue = JSON.parse(localStorage.getItem("BillingIntegration"));
      }

    let showTotalAmount = 0;
    let showTax = 0;
    let showAmountBilled = 0;
    let showAmountDeposit = 0;

    try {
      tableData.forEach(row => {
        showTotalAmount += this.isEmpty(row.W1_price_exclude_tax_sum)
          ? 0
          : Number(row.W1_price_exclude_tax_sum);
          showAmountDeposit += this.isEmpty(row.W1_deposit_amount)
          ? 0
          : Number(row.W1_deposit_amount);
      });
    } catch {}

    if (TaxCalculateUnit === 1 && this.isEmpty(getFieldValue.TaxClassifyState)) {
      // 税計算単位が合計で税区分が未設定の場合、「消費税指定」をセット
      TaxClassifyState = 0;
      this.formRef.current?.setFieldsValue({
        TaxClassifyState: 0
      });
    }

    if (TaxClassifyState === 3) {
      // 非課税
      showTax = 0;
      showAmountBilled = Number(showTotalAmount);
    } else if (TaxCalculateUnit == 0) {
      // 明細ごとに計算
      try {
        tableData.forEach(row => {
          showTax += this.isEmpty(row.W1_billing_tax_price) ? 0 : Number(row.W1_billing_tax_price);
          showAmountBilled += this.isEmpty(row.W1_billing_amount)
            ? 0
            : Number(row.W1_billing_amount);
        });
      } catch {}
    } else {
      // 合計で計算
      if (TaxClassifyState === 0) {
        // 消費税指定

        showTax = String(tax).replace(/,/g, "") ?? 0; // 税を指定しない
        
        if (showTax === "null") {
          showTax = 0;
        }

        showAmountBilled = Number(showTotalAmount) + Number(showTax);
      } else {
        let taxPrice = showTotalAmount * (taxRate / 100);
        if (LessThanTaxCircle == 0) {
          taxPrice = Math.round(taxPrice);
        } else if (LessThanTaxCircle == 1) {
          taxPrice = Math.trunc(taxPrice);
        } else if (LessThanTaxCircle == 2) {
          taxPrice = Math.ceil(taxPrice);
        }
        showTax = taxPrice;
        showAmountBilled = Number(showTotalAmount) + Number(taxPrice);
      }
    }

    this.formRef.current?.setFieldsValue({
      TotalAmount: number_format(showTotalAmount),
      Tax: showTax,
      AmountBilled: number_format(showAmountBilled),
      DepositAmount: number_format(showAmountDeposit)
    });

    localStorage.setItem("BillingIntegration_is_update", "1");
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
  };

  handleTaxCalculateUnitChange = value => {
    this.setState({ TaxCalculateUnit: value, showLessThanTaxCircle: value === 1 }, () => {
      if (value === 1 && this.isEmpty(this.formRef.current?.getFieldValue("TaxClassifyState"))) {
        // 税計算単位が合計で税区分が未設定の場合、「消費税指定」をセット
        this.formRef.current?.setFieldsValue({
          TaxClassifyState: 0
        });
        this.setState({ TaxClassifyState: 0 });
      }
      this.changeAmount();
    });
  };

  handleTaxClassifyChange = value => {
    this.setState({ TaxClassifyState: value }, () => {
      if (this.state.TaxCalculateUnit === 1 && this.isEmpty(value)) {
        // 税計算単位が合計で税区分が未設定の場合、「消費税指定」をセット
        this.formRef.current?.setFieldsValue({
          TaxClassifyState: 0
        });
        this.setState({ TaxClassifyState: 0 });
      }
      this.changeAmount();
    });
  };

  closeModal() {
    this.setState({ childModal: { ...this.state.childModal, visible: false } });
  }

  isEmpty(val) {
    return val === undefined || val == null || val.length <= 0 ? true : false;
  }

  setFormFieldValue(namePath, value) {
    this.formRef.current.setFields([{ name: namePath, value }]);
  }

  onFinish(values) {}

  CheckMaxLength(e, number, namePath) {
    let val = e.target.value;
    if (!this.isEmpty(val)) {
      if (val.length > number) {
        val.slice(0, number);
        this.formRef.current.setFields([{ name: namePath, value: val }]);
      }
    }
  }

  showPoopupLoadData() {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 350,
        component: (
          <Card title="確認">
            <Row>
              <QuestionCircleOutlined style={{ fontSize: "30px", color: "#08c" }} />{" "}
              <span>&emsp;&emsp;統合先に反映しますか？</span>
            </Row>
            <Space style={{ float: "right", marginTop: "1em" }}>
              <Button
                type="primary"
                onClick={() => {
                  this.getDataDisplay();
                  this.closeModal();
                }}
              >
                &emsp;&emsp;は　い&emsp;&emsp;
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  this.getDataDisplay(1);
                  this.closeModal();
                }}
              >
                &emsp;&emsp;いいえ&emsp;&emsp;
              </Button>
            </Space>
          </Card>
        )
      }
    });
  }

  AddNewData() {
    let tableData = this.formRef.current?.getFieldValue("tableData");
    if (!tableData || tableData.length === 0) {
      // テーブルデータが空の場合は、新しいデータ行を追加しない
      this.search_W1_billing_manage_num(0);
    } else {
      // テーブルデータが存在する場合は、データ行の数を引数として search_W1_billing_manage_num を呼び出す
      this.search_W1_billing_manage_num(tableData.length);
    }
  }

  handleAdd() {
    const { count } = this.state;
    const newData = {
      W1_serial_num: "",
      W1_billing_manage_num: 0,
      W1_identify: 5,
      W1_identify_name: "",
      W1_address: "",
      W1_subject: "",
      W1_billing_period_beginning: null,
      W1_billing_period_final: null,
      W1_person: "",
      W1_billing_amount: "",
      W1_price_exclude_tax_sum: "",
      W1_billing_tax_price: "",
      W1_deposit_amount: "",
      W1_invoice_num: "",
      id: count
    };
    let data = [];
    let tableData;

    var isUpdate = localStorage.getItem("BillingIntegration_is_update");
    if (localStorage.getItem("BillingIntegration") && isUpdate === "1") {
      tableData = JSON.parse(localStorage.getItem("BillingIntegrationTableData"));
    } else {
      tableData = this.formRef.current?.getFieldValue("tableData");
    }

    if (tableData !== undefined && tableData !== null) {
      data = [...tableData];
    }

    data.push(newData);
    this.formRef.current?.setFieldsValue({ tableData: data });
    this.setState({ ...this.state, count: count + 1 });
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
  }

  handleDelete(index) {
    const { count } = this.state;
    let data = [...this.formRef.current?.getFieldValue("tableData")];
    data.splice(index, 1);
    let idx = 0;
    data.forEach(row => {
      idx++;
      row.W1_serial_num = idx;
    });
    this.formRef.current?.setFieldsValue({ tableData: data });
    this.setState({ ...this.state, count: count - 1 });
    localStorage.setItem("BillingIntegration_is_update", "1");
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
    this.changeAmount();
  }

  handleReset() {
    let data = [];
    this.formRef.current?.setFieldsValue({ tableData: data });
    this.setState({ ...this.state, count: 0 });
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
    localStorage.setItem("BillingIntegration_is_update", "1");
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
  }

  changeScreenEditting(Lo_ScreenEditing) {
    let data = { ...this.state.dataScreen, ScreenEditing: Lo_ScreenEditing };
    this.setState({ dataScreen: data });
  }

  // 保護解除処理
  showTamperProofRelease = () => {
    localStorage.setItem("BillingIntegration_is_update", "0");
    let data = this.formRef.current?.getFieldValue("tableData");
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 500,
        component: (
          <WS0961004_TamperProofRelease
            onFinishScreen={output => {
              let params = { BillingManageNum: allFields.BillingManageNum };
              BillingIntegrationAction.updateUnprotected(params)
                .then(res => {
                  this.setState({ isLoadingTable: false });
                  localStorage.setItem("BillingIntegration_is_update", "1");
                  let data = this.formRef.current?.getFieldValue("tableData");
                  localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
                  var allFields = this.formRef.current.getFieldsValue();
                  localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
                  this.getDataDisplay(false);
                })
                .catch(error => {
                  const res = error.response;
                  if (!res || !res.data || !res.data.message) {
                    message.error("エラーが発生しました");
                    return "";
                  }
                  message.error(res.data.message);
                });
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  // 統合解除
  showIntegrationReleased = () => {
    localStorage.setItem("BillingIntegration_is_update", "1");
    let data = this.formRef.current?.getFieldValue("tableData");
    if (data == null || data.length === 0) {
      alert("請求情報が選択されていません");
      return false;
    }
    //let BillingManageNum = data[0].W1_billing_manage_num;
	let BillingManageNum = this.formRef.current?.getFieldValue("BillingManageNum");
    if (this.isEmpty(BillingManageNum) || BillingManageNum == 0) {
      alert("請求情報が選択されていません");
      return false;
    }

    let title = <span>統合された請求書を解除しますか？</span>;
    Modal.confirm({
      width: 350,
      title: title,
      icon: <QuestionCircleOutlined style={{ color: "#1890ff", fontSize: 28 }} />,
      onOk: () => {
        let params = { BillingManageNum: BillingManageNum };
        BillingIntegrationAction.updateUnintegration(params)
          .then(res => {
            this.formRef.current?.resetFields();
            this.handleReset();
            this.setState({
              isIntegrated: false, // 統合解除後に統合状態を更新
              dataScreen: { ...this.state.dataScreen, ProtectionFlag: 0, ProcessDivision: 0 }
            });
            localStorage.setItem("BillingIntegration_is_update", "1");
            var allFields = this.formRef.current?.getFieldsValue();
            allFields.BillingManageNum = null;
            allFields.W1_identify = null;
            allFields.Destination = null;
            allFields.DestinationZipCode = null;
            allFields.Address1 = null;
            allFields.Address2 = null;
            allFields.Subject = null;
            allFields.Remarks = null;
            this.formRef.current?.setFieldsValue(allFields);
            localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
            this.getInit();
          })
          .catch(error => {
            message.error("統合解除に失敗しました");
          });
      }
    });
  };

  showInvoice = () => {
    localStorage.setItem("BillingIntegration_is_update", "1");
    let data = this.formRef.current?.getFieldValue("tableData");
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
    let Destination = this.formRef.current?.getFieldValue("Destination");
    let BillingDateChar = this.formRef.current?.getFieldValue("BillingDateChar");
    let BillingPeriodBeginningChar = this.formRef.current?.getFieldValue(
      "BillingPeriodBeginningChar"
    );
    let BillingPeriodFinalChar = this.formRef.current?.getFieldValue("BillingPeriodFinalChar");
    let ClaimYearsChar = this.formRef.current?.getFieldValue("ClaimYearsChar");
    if (
      this.isEmpty(Destination) ||
      this.isEmpty(BillingDateChar) ||
      this.isEmpty(BillingPeriodBeginningChar) ||
      this.isEmpty(BillingPeriodFinalChar) ||
      this.isEmpty(ClaimYearsChar)
    ) {
      alert("宛名, 請求日, 請求期間, 請求年月を入力してください");
      return false;
    }
    let BillingManageNums = [];
    data && data.forEach(val => {
      if (val.W1_billing_manage_num > 0) {
        BillingManageNums.push(val.W1_billing_manage_num);
      }
    });
    if (BillingManageNums.length === 0) {
      alert("請求情報が選択されていません");
      return "";
    }
    this.showInvoiceModal();

    // 統合フラグを更新する
    this.setState({ isIntegrated: true });
  };

  showInvoiceModal = () => {
    let data = this.formRef.current?.getFieldValue("tableData");
    let getFieldValue = this.formRef.current?.getFieldValue();
    this.formRef.current?.setFieldsValue({
      tableData: data,
      isLoadingTable: false,
      dataScreen: getFieldValue
    });
    localStorage.setItem("BillingIntegration_is_update", "0");
    localStorage.setItem(
      "BillingIntegration",
      JSON.stringify(this.formRef.current?.getFieldValue())
    );
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
    this.createIntegration();
    /*
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 600,
        _component: (
          <WS0947001_Invoice
            selectList={data}
            onFinishScreen={output => {
              if (output.finish) {
                this.closeModal();
              }
            }}
          />
        ),
        get component() {
          return this._component;
        },
        set component(value) {
          this._component = value;
        }
      },
      isLoadingTable: false
    });*/
  };

  updateBillingInfo = recordData => {
    this.handleReset();
    this.handleAdd();
    let data = JSON.parse(localStorage.getItem("BillingIntegrationTableData"));
    var isUpdate = localStorage.getItem("BillingIntegration_is_update");
    if (localStorage.getItem("BillingIntegration") && isUpdate === "1") {
      data = JSON.parse(localStorage.getItem("BillingIntegrationTableData"));
    } else {
      data = this.formRef.current?.getFieldValue("tableData");
    }
    data[0]["W1_billing_manage_num"] = recordData.W1_billing_manage_num ?? "";
    data[0]["W1_billing_period_beginning"] = recordData.W1_consult_start_date ?? "";
    data[0]["W1_billing_period_final"] = recordData.W1_consult_end_date ?? "";
    data[0]["W1_billing_amount"] = recordData.W1_billing_amount ?? "";
    data[0]["W1_price_exclude_tax_sum"] = recordData.W1_price_exclude_tax_sum ?? "";
    data[0]["W1_billing_tax_price"] = recordData.W1_billing_tax_price ?? "";
    data[0]["W1_deposit_amount"] = recordData.W1_deposit_amount ?? "";
    data[0]["W1_invoice_num"] = recordData.W1_invoice_num ?? "";
    data[0]["W1_address"] = "";
    data[0]["W1_subject"] = "";
    data[0]["W1_identify"] = recordData.W1_identify;
    data[0]["W1_identify_name"] = recordData.Expression_9;
    data[0]["w1_aggregate_flag"] = recordData.w1_aggregate_flag;
    this.formRef.current?.setFieldsValue({
      tableData: data,
      BillingManageNum: recordData.W1_billing_manage_num,
      W1_identify: recordData.W1_identify
    });
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
    localStorage.setItem("BillingIntegration_is_update", "1");
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
    this.getDataDisplay(false);
  };

  searchBillingManageNum = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "90%",
        _component: (
          <WS0975001_BillingInquiry
            Li_flag={true}
            Li_ProcessDivision={3}
            Li_IdentifyInitialDisplay={5}
            Li_TargetInitialDisplay={2}
            Li_PayRemainingInitialDisplay={0}
            Li_OutstandingInitialDisplay={0}
            onFinishScreen={output => {
              if (Number(output.recordData.W1_delete_flg) === 1) {
                message.error('削除データは選択できません。');
              } else if (Number(output.recordData.W1_total_flg) === 0) {
                message.error('未統合データです。');
              } else {
                if (this.state.dataScreen.ScreenEditing) {
                  let title = (
                    <span>
                      更新中のデータは無効になります <br /> よろしいですか?
                    </span>
                  );
                  Modal.confirm({
                    width: 350,
                    title: title,
                    icon: <QuestionCircleOutlined style={{ color: "#1890ff", fontSize: 28 }} />,
                    onOk: () => {
                      this.updateBillingInfo(output.recordData);
                      // 統合フラグを更新する
                      this.setState({ isIntegrated: true });
                    }
                  });
                } else {
                  this.updateBillingInfo(output.recordData);
                  // 統合フラグを更新する
                  this.setState({ isIntegrated: true });
                }
                this.closeModal();
              }
            }}
          />
        ),
        get component() {
          return this._component;
        },
        set component(value) {
          this._component = value;
        }
      }
    });
  };

  doubleClickDestination = () => {
    let condition =
      !this.formRef.current?.getFieldValue("DestinationZipCode") &&
      !this.formRef.current?.getFieldValue("Address1") &&
      !this.formRef.current?.getFieldValue("Address2");
    switch (this.state.ClassifyState) {
      case 4:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "90%",
            component: (
              <WS0246001_InsurerInfoSearchQuery
                onFinishScreen={output => {
                  this.formRef.current?.setFieldsValue({ Destination: output.Lo_Name });
                  if (condition) {
                    this.formRef.current?.setFieldsValue({
                      DestinationZipCode: output.recordData.postal_code,
                      Address1: output.recordData.location_1,
                      Address2: output.recordData.location_2
                    });
                  }
                  this.changeScreenEditting(true);
                  this.closeModal();
                }}
              />
            )
          }
        });
        break;
      case 5:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "90%",
            component: (
              <WS0247001_OfficeInfoRetrievalQuery
                onFinishScreen={output => {
                  this.formRef.current?.setFieldsValue({ Destination: output.Lo_Kanji_Name });
                  if (condition) {
                    this.formRef.current?.setFieldsValue({
                      DestinationZipCode: output.recordData.postal_code,
                      Address1: output.recordData.address_1,
                      Address2: output.recordData.address_2
                    });
                  }
                  this.changeScreenEditting(true);
                  this.closeModal();
                }}
              />
            )
          }
        });
        break;
      case 9:
        this.setState({
          childModal: {
            ...this.state.childModal,
            visible: true,
            width: "90%",
            component: (
              <WS0248001_PersonalInfoSearchQuery
                date={moment(new Date()).format("YYYY/MM/DD")}
                onFinishScreen={output => {
                  this.formRef.current?.setFieldsValue({
                    PersonalNumAddressSearch: output.Lo_PersonalNumId,
                    Destination: output.recordData.kanji_name
                  });
                  if (condition) {
                    this.formRef.current?.setFieldsValue({
                      DestinationZipCode: output.recordData.postal_code,
                      Address1: output.recordData.address_1,
                      Address2: output.recordData.address_2
                    });
                  }
                  this.changeScreenEditting(true);
                  this.closeModal();
                }}
              />
            )
          }
        });
        break;
      default:
        break;
    }
  };

  searchDestinationZipCode = () => {
    localStorage.setItem("BillingIntegration_is_update", "1");
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS0084001_PostCodeSearchEngine
            onFinishScreen={output => {
              let address = this.formRef.current?.getFieldValue("Address1");
              this.formRef.current?.setFieldsValue({
                DestinationZipCode: output.Lio_ZipCode,
                Address1: address ? address : output.Lio_Address
              });
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  searchAddress1 = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "50%",
        component: (
          <WS0084001_PostCodeSearchEngine
            onFinishScreen={output => {
              let address = this.formRef.current?.getFieldValue("Address1");
              this.formRef.current?.setFieldsValue({
                DestinationZipCode: output.Lio_ZipCode,
                Address1: address ? address : output.Lio_Address
              });
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  clickClaimYearsChar = () => {
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: 300,
        component: (
          <WS0961005_BillingDateChange
            Lio_ClaimYears={this.formRef.current?.getFieldValue("ClaimYearsChar")}
            onFinishScreen={output => {
              if (output.Lo_YearsChangePresence) {
                let data = {
                  ...this.state.dataScreen,
                  ProcessDivision: 3,
                  ClaimYearsChar: output.Lio_ClaimYears,
                  BillingManageNumYearsBeforeChange:
                    this.formRef.current?.getFieldValue("BillingManageNum"),
                  IdentifyDateChangeBefore: this.formRef.current?.getFieldValue("Classify"),
                  ScreenEditing: true
                };
                this.setState({ dataScreen: data });
                this.formRef.current?.setFieldsValue({ ClaimYearsChar: output.Lio_ClaimYears });
              }
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  search_W1_billing_manage_num = index => {
    this.changeScreenEditting(true);
    this.setState({
      childModal: {
        ...this.state.childModal,
        visible: true,
        width: "90%",
        component: (
          <WS0975001_BillingInquiry
            Li_ProcessDivision={3}
            Li_IdentifyInitialDisplay={5}
            Li_TargetInitialDisplay={0}
            Li_PayRemainingInitialDisplay={0}
            Li_OutstandingInitialDisplay={0}
            onFinishScreen={output => {
              let data = this.formRef.current?.getFieldValue("tableData") || [];
              let dupCheck = false;
              data.forEach(val => {
                if (val.W1_billing_manage_num === output.Lo_BillingManageNum) {
                  dupCheck = true;
                }
              });
              if (dupCheck) {
                message.error('すでに選択済みです。')
                return;
              }
              if (Number(output.recordData.W1_delete_flg) === 1) {
                message.error('削除データは選択できません。')
                return;
              }
              if (Number(output.recordData.W1_total_flg) === 1) {
                message.error('既に統合済みです。')
                return;
              }

              // 新しいデータ行を追加
              const newData = {
                //W1_serial_num: "",
                W1_serial_num: data.length + 1,
                W1_billing_manage_num: output.Lo_BillingManageNum ?? "",
                W1_identify: output.recordData.W1_identify,
                W1_identify_name: output.recordData.Expression_9,
                W1_address: output.recordData.W1_according_to_destination_name,
                W1_subject: output.recordData.W1_according_to_subject,
                W1_billing_period_beginning: output.recordData.W1_consult_start_date ?? "",
                W1_billing_period_final: output.recordData.W1_consult_end_date ?? "",
                W1_billing_amount: output.recordData.W1_billing_amount ?? "",
                W1_price_exclude_tax_sum: output.recordData.W1_price_exclude_tax_sum ?? "",
                W1_billing_tax_price: output.recordData.W1_billing_tax_price ?? "",
                W1_deposit_amount: output.recordData.W1_deposit_amount ?? "",
                W1_invoice_num: output.recordData.W1_invoice_num ?? "",
                id: data.length,
                w1_aggregate_flag: output.recordData.w1_aggregate_flag
              };
              data.push(newData);
              this.formRef.current?.setFieldsValue({
                tableData: data,
                //BillingManageNum: output.Lo_BillingManageNum,
                //W1_identify: output.recordData.W1_identify
              });

    
              let isShowPoopupLoadData = false;
              let billingIntegrationTableData;
              if (localStorage.getItem('BillingIntegrationTableData') === 'undefined') {
                billingIntegrationTableData = null;
              }
              else {
                billingIntegrationTableData = localStorage.getItem('BillingIntegrationTableData');
              }
              let parsedData = billingIntegrationTableData ? JSON.parse(billingIntegrationTableData) : [];
              if (billingIntegrationTableData === null || parsedData.length === 0) {
            
              //if (localStorage.getItem('BillingIntegrationTableData') === null) {
                isShowPoopupLoadData = true;
              }
            

              localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
              localStorage.setItem("BillingIntegration_is_update", "1");
              var allFields = this.formRef.current.getFieldsValue();
              localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
              if (this.formRef.current?.getFieldValue("tableData")?.length > 0) {
                this.changeAmount();
              } else {
                this.showPoopupLoadData();
              }
            
              if (isShowPoopupLoadData === true) {
                let title = <span>統合先に反映しますか？</span>;
                Modal.confirm({
                  width: 350,
                  title: title,
                  icon: <QuestionCircleOutlined style={{ color: '#1890ff', fontSize: 28 }} />,
                  onOk: () => {
                    this.formRef.current?.setFieldsValue({
                      Destination: output.recordData.W1_according_to_destination_name,
                      DestinationZipCode: output.recordData.W1_according_to_destination_zip_code,
                      Address1: output.recordData.W1_according_to_destination_address_1,
                      Address2: output.recordData.W1_according_to_destination_address_2,
                      Subject: output.recordData.W1_according_to_subject,
                      ClaimYearsChar: output.recordData.W1_billing_year_month_on,
                      BillingDateChar: output.recordData.W1_billing_date,
                      BillingPeriodBeginningChar: output.recordData.W1_consult_start_date,
                      BillingPeriodFinalChar: output.recordData.W1_consult_end_date,
                      Remarks: output.recordData.W1_remarks,
                    });
                    this.formRef.current?.setFieldsValue({
                      Expression_192: "新規"
                    });
                    localStorage.setItem('BillingIntegration_is_update', '1');
                    var allFields = this.formRef.current.getFieldsValue();
                    localStorage.setItem('BillingIntegration', JSON.stringify(allFields));

                    //this.getDataDisplay(false);
                  }
                });
              }else {
                // 開始日
                if (this.formRef.current?.getFieldValue("BillingPeriodBeginningChar") > output.recordData.W1_consult_end_date) {
                  this.formRef.current?.setFieldsValue({
                    BillingPeriodBeginningChar: output.recordData.W1_consult_start_date,
                  });
                }
                // 終了日
                if (this.formRef.current?.getFieldValue("BillingPeriodFinalChar") < output.recordData.W1_consult_end_date) {
                  this.formRef.current?.setFieldsValue({
                    BillingPeriodFinalChar: output.recordData.W1_consult_end_date,
                  });
                }
              }
            
              this.closeModal();
            }}
            onCancel={() => {
              this.closeModal();
            }}
          />
        )
      }
    });
  };

  billing_manage_num_delete = index => {
    let data = this.formRef.current?.getFieldValue("tableData");
    let record = data[index];
    if (record["W1_billing_manage_num"] === "") {
      return false;
    } else {
      this.handleDelete(index);
    }
  };

  createIntegration = () => {
    let data = this.formRef.current?.getFieldValue("tableData");
    let BillingManageNums = [];
    data.forEach(val => {
      if (val.W1_billing_manage_num > 0) {
        BillingManageNums.push(val.W1_billing_manage_num);
      }
    });
    localStorage.setItem("BillingIntegration_is_update", "1");
    var allFields = this.formRef.current.getFieldsValue();
    localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));

    let getFieldValue = this.formRef.current?.getFieldValue();
    let BillingPeriodBeginningChar = getFieldValue.BillingPeriodBeginningChar;
    let BillingPeriodFinalChar = getFieldValue.BillingPeriodFinalChar;
    let BillingDateChar = getFieldValue.BillingDateChar;
    let ClaimYearsChar = getFieldValue.ClaimYearsChar;

    let BillingManageNum = getFieldValue.BillingManageNum;
    if (BillingManageNums.includes(BillingManageNum)) {
      BillingManageNum = null;
    }
    let ClaimNum = getFieldValue.ClaimNum;
 
    let params = {

      Integration_BillingManageNum: BillingManageNum,
      InvoiceNum: ClaimNum,

      BillingManageNum: BillingManageNums,
      ClassifyState: this.state.ClassifyState,
      BillingPeriodBeginningChar: this.isEmpty(BillingPeriodBeginningChar)
        ? null
        : moment(BillingPeriodBeginningChar).format("YYYY-MM-DD"),
      BillingPeriodFinalChar: this.isEmpty(BillingPeriodFinalChar)
        ? null
        : moment(BillingPeriodFinalChar).format("YYYY-MM-DD"),
      Destination: getFieldValue.Destination,
      DestinationZipCode: getFieldValue.DestinationZipCode,
      Address1: getFieldValue.Address1,
      Address2: getFieldValue.Address2,
      Subject: getFieldValue.Subject,
      Remarks: getFieldValue.Remarks,
      TaxClassify: getFieldValue.TaxClassifyState,
      LessThanTaxCircle: getFieldValue.LessThanTaxCircle,
      TaxCalculateUnit: getFieldValue.TaxCalculateUnit,
      BillingDateChar: this.isEmpty(BillingDateChar)
        ? null
        : moment(BillingDateChar).format("YYYY-MM-DD"),
      ClaimYearsChar: this.isEmpty(ClaimYearsChar)
        ? null
        : moment(ClaimYearsChar).format("YYYY-MM-DD"),
      TotalAmount: String(getFieldValue.TotalAmount).replace(/,/g, ""),
      Tax: String(getFieldValue.Tax).replace(/,/g, ""),
      AmountBilled: String(getFieldValue.AmountBilled).replace(/,/g, ""),
      DepositAmount: String(getFieldValue.DepositAmount).replace(/,/g, "")
    };

    BillingIntegrationAction.createIntegration(params)
      .then(res => {
        this.handleReset();
        this.handleAdd();

        let newData = this.formRef.current?.getFieldValue("tableData");
        if (newData == null || newData.length === 0) {
          newData = JSON.parse(localStorage.getItem("BillingIntegrationTableData"));
        }

        newData[0]["W1_billing_manage_num"] = res.data.BillingManageNumNew;
        newData[0]["W1_identify"] = null;
        newData[0]["W1_billing_period_beginning"] = res.data.data.consultation_start_date_on ?? "";
        newData[0]["W1_billing_period_final"] = res.data.data.consultation_end_date_on ?? "";
        newData[0]["W1_billing_amount"] = res.data.data.billing_price ?? "";
        newData[0]["W1_price_exclude_tax_sum"] = res.data.data.unit_price_sum ?? "";
        newData[0]["W1_billing_tax_price"] = res.data.data.Tax ?? "";
        newData[0]["W1_deposit_amount"] = res.data.data.deposit_price ?? "";
        newData[0]["w1_aggregate_flag"] = res.data.data.aggregate_flag;

        this.formRef.current?.setFieldsValue({
          tableData: newData,
          BillingManageNum: res.data.BillingManageNumNew,
          W1_identify: null
        });

        this.setState({
          isIntegrated: false,
          dataScreen: { ...this.state.dataScreen, ProtectionFlag: 0, ProcessDivision: 0 }
        });

        localStorage.setItem("BillingIntegration_is_update", "1");

        var allFields = this.formRef.current.getFieldsValue();

        allFields.BillingManageNum = null;
        allFields.W1_identify = null;
        allFields.Destination = null;
        allFields.DestinationZipCode = null;
        allFields.Address1 = null;
        allFields.Address2 = null;
        allFields.Subject = null;
        allFields.Remarks = null;
        this.formRef.current?.setFieldsValue(allFields);

        localStorage.setItem("BillingIntegration", JSON.stringify(allFields));
        localStorage.setItem("BillingIntegrationTableData", JSON.stringify(newData));

        this.getInit();

        this.getDataDisplay(false);

        this.formRef.current?.setFieldsValue({
          TotalAmount: '',
          Tax: '',
          AmountBilled: '',
          DepositAmount: ''
        });
      })
      .catch(error => {
        const res = error.response;
        if (!res || !res.data || !res.data.message) {
          message.error("エラーが発生しました");
          return "";
        }
        message.error(res.data.message);
      });
  };

  listSort(index) {

    const data_Bef = JSON.parse(localStorage.getItem("BillingIntegrationTableData"));

    let data = this.formRef.current?.getFieldValue("tableData");
    if (data[index].W1_serial_num == data_Bef[index].W1_serial_num) {
      return;
    }

    data.sort((a, b) =>
      Number(a.W1_serial_num ?? 0) > Number(b.W1_serial_num ?? 0) ? 1 : -1
    );

    let idx = 0;
    data.forEach(row => {
      idx++;
      row.W1_serial_num = idx;
    });

    this.formRef.current?.setFieldsValue({ tableData: data });
    localStorage.setItem("BillingIntegrationTableData", JSON.stringify(data));
    localStorage.setItem("BillingIntegration_is_update", "1");
    this.forceUpdate();
  }

  render() {
    const { ProtectionFlag, ProcessDivision, KeyEditingPermission } = this.state.dataScreen;
    return (
      <div className="billing-integration">
        <Card className="mb-2" title="請求統合">
          <Space className="mb-3">
            <Button
              type="primary"
              disabled={ProtectionFlag === 0 && ProcessDivision !== 4}
              onClick={() => {
                this.showTamperProofRelease();
              }}
            >
              保護解除
            </Button>
            <Button
              type="primary"
              disabled={!this.state.isIntegrated} // 統合されていない場合は無効
              onClick={() => {
                this.showIntegrationReleased();
              }}
            >
              統合解除
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.showInvoice();
              }}
            >
              完了
            </Button>
          </Space>

          <hr style={{ margin: "15px 0" }} />

          <Spin spinning={this.state.isloadFrm}>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              autoComplete="off"
              initialValues={{ Classify: 5 }}
              {...grid}
            >
              <Row>
                <Col span={13}>
                  <Form.Item label="請求管理番号">
                    <Row>
                      <Col span={8} style={{ paddingRight: 10 }}>
                        <Form.Item name="BillingManageNum">
                          <Input.Search
                            style={{ textAlign: "right" }}
                            readOnly
                            onSearch={() => {
                              this.searchBillingManageNum();
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8} style={{ paddingRight: 5 }}>
                        <Form.Item name="Classify" label="請求先区分">
                          <Select
                            style={{ width: 100 }}
                            disabled={ProtectionFlag !== 0 || ProcessDivision === 4} // 保護データの場合は無効
                            onChange={value => this.setState({ ClassifyState: value })}
                          >
                            <Select.Option value={4}>保険者</Select.Option>
                            <Select.Option value={5}>事業所</Select.Option>
                            <Select.Option value={6}>他団体</Select.Option>
                            <Select.Option value={9}>個人未収</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2} style={{ paddingRight: 5 }}>
                        <Form.Item name="Expression_27">
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={2} style={{ paddingRight: 5 }}>
                        <Form.Item name="Expression_28">
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={2} offset={2} style={{ textAlign: "right" }}>
                        <Form.Item name="Expression_192">
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item name="Destination" label="宛名">
                    <Input
                      maxLength={60}
                      className="custom-disabled-input"
                      disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                      onDoubleClick={() => {
                        this.doubleClickDestination();
                      }}
                      onBlur={e => {
                        if (this.state.dataScreen.Destination !== e.target.value) {
                          this.changeScreenEditting(true);
                        }
                        if (
                          ProcessDivision == 1 &&
                          !this.formRef.current?.getFieldValue("Subject")
                        ) {
                          this.formRef.current?.setFieldsValue({
                            Subject: this.state.dataScreen.Subject
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="DestinationZipCode" label="送付先">
                    <Input.Search
                      style={{ width: "150px" }}
                      maxLength={8}
                      className="custom-disabled-input"
                      disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                      onSearch={() => {
                        this.searchDestinationZipCode();
                      }}
                      onBlur={e => {
                        if (this.state.dataScreen.DestinationZipCode !== e.target.value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="Address1" label=" ">
                    <Input
                      maxLength={60}
                      className="custom-disabled-input"
                      disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                      onBlur={e => {
                        if (this.state.dataScreen.Address1 !== e.target.value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="Address2" label=" ">
                    <Input
                      maxLength={60}
                      className="custom-disabled-input"
                      disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                      onBlur={e => {
                        if (this.state.dataScreen.Address2 !== e.target.value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="Subject" label="件名">
                    <Input
                      maxLength={100}
                      className="custom-disabled-input"
                      disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                      onBlur={e => {
                        if (this.state.dataScreen.Subject !== e.target.value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="Remarks" label="備考">
                    <Input
                      maxLength={100}
                      className="custom-disabled-input"
                      disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                      onBlur={e => {
                        if (this.state.dataScreen.Remarks !== e.target.value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={11} style={{ paddingLeft: 20 }}>
                  <Form.Item label="請求番号" name="ClaimNum">
                    <div style={{ width: 112, textAlign: "right" }}>
                      {this.formRef.current?.getFieldValue("ClaimNum")}
                    </div>
                  </Form.Item>
                  <Form.Item label="請求日">
                    <Row gutter={24}>
                      <Col span={8}>
                        <Form.Item name="BillingDateChar">
                          <VenusDatePickerCustom
                            formRefDatePicker={this.formRef}
                            format={"YYYY/MM/DD"}
                            style={{ width: 112 }}
                            disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                            onBlur={e => {
                              if (this.state.dataScreen.BillingDateChar !== e.target.value) {
                                this.changeScreenEditting(true);
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8} offset={3}>
                        <Form.Item label="税率">
                          <span>{this.formRef.current?.getFieldValue("TaxRate")} %</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item name="ClaimYearsChar" label="請求年月">
                    {ProcessDivision == 2 ? (
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                        format="YYYY/MM"
                        picker='month'
                        style={{ width: 90 }}
                        onClick={() => {
                          this.clickClaimYearsChar();
                        }}
                      />
                    ) : (
                      <VenusDatePickerCustom
                        formRefDatePicker={this.formRef}
                        format="YYYY/MM"
                        picker='month'
                        style={{ width: 90 }}
                        disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                        onBlur={e => {
                          if (this.state.dataScreen.ClaimYearsChar !== e.target.value) {
                            this.changeScreenEditting(true);
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="請求期間">
                    <Space>
                      <Form.Item name="BillingPeriodBeginningChar">
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format="YYYY/MM/DD"
                          style={{ width: 112 }}
                          disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                        />
                      </Form.Item>
                      <Form.Item>~</Form.Item>
                      <Form.Item name="BillingPeriodFinalChar">
                        <VenusDatePickerCustom
                          formRefDatePicker={this.formRef}
                          format="YYYY/MM/DD"
                          style={{ width: 112 }}
                          disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item label="税計算単位">
                    <Row gutter={24}>
                      <Col span={6.5}>
                        <Form.Item name="TaxCalculateUnit">
                          <Select
                            style={{ width: 112 }}
                            disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                            onChange={value => {
                              this.setState({
                                TaxCalculateUnit: value,
                                showLessThanTaxCircle: value === 1
                              });
                              this.formRef.current?.setFieldsValue({
                                TaxClassifyState: 0
                              });
                              this.changeAmount();
                            }}
                          >
                            <Select.Option value={0}>明細</Select.Option>
                            <Select.Option value={1}>合計</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12} offset={2}>
                        <Form.Item name="LessThanTaxCircle" label="税円未満">
                          <Select
                            style={{ width: 100 }}
                            disabled={
                              (!this.state.showLessThanTaxCircle &&
                                ProtectionFlag === 0 &&
                                ProcessDivision !== 4) ||
                              !(ProtectionFlag === 0 && ProcessDivision !== 4)
                            }
                            onChange={value => {
                              this.setState({ LessThanTaxCircle: value });
                              this.changeAmount();
                            }}
                          >
                            <Select.Option value={0}>四捨五入</Select.Option>
                            <Select.Option value={1}>切捨</Select.Option>
                            <Select.Option value={2}>切上</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item name="TaxClassifyState" label="税区分">
                    <Select
                      style={{ width: 112 }}
                      disabled={
                        (!this.state.showLessThanTaxCircle &&
                          ProtectionFlag === 0 &&
                          ProcessDivision !== 4) ||
                        !(ProtectionFlag === 0 && ProcessDivision !== 4)
                      }
                      onChange={this.handleTaxClassifyChange}
                    >
                      <Select.Option value={0}>消費税指定</Select.Option>
                      <Select.Option value={1}>外税</Select.Option>
                      <Select.Option value={2}>内税</Select.Option>
                      <Select.Option value={3}>非課税</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <br />

              <Table
                bordered
                size="small"
                dataSource={
                  this.formRef.current?.getFieldValue("tableData")
                    ? this.formRef.current?.getFieldValue("tableData")
                    : []
                }
                loading={this.state.isLoadingTable}
                pagination={false}
                rowKey={record => record.id}
              >
                <Table.Column
                  title="連番"
                  dataIndex="W1_serial_num"
                  width={50}
                  sorter={(a, b) => a.W1_serial_num - b.W1_serial_num}
                  render={(value, record, index) => (
                    <Form.Item name={['tableData', index, 'W1_serial_num']}>
                      <Input
                        min={1}
                        initialvalues={1}
                        className="input-size-10"
                        style={{ textAlign: "center" }}
                        disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                        onBlur={(event) => {
                          this.listSort(index);
                        }}
                        onPressEnter={(event) => {
                          this.listSort(index);
                        }}
                      />
                    </Form.Item>
                  )}
                />
                <Table.Column
                  title="請求管理番号"
                  dataIndex="W1_billing_manage_num"
                  width={180}
                  sorter={(a, b) => a.W1_billing_manage_num - b.W1_billing_manage_num}
                  render={(value, record, index) => {
                    return (
                      <div
                        style={{ width: "100%" }}
                        //onDoubleClick={() => {
                        //  if (ProtectionFlag === 0 && ProcessDivision !== 4) {
                        //    this.search_W1_billing_manage_num(index);
                        //  }
                        // }}
                      >
                        {value}
                      </div>
                    );
                  }}
                />
                <Table.Column
                  title="請 求 先"
                  dataIndex="W1_identify"
                  width={130}
                  sorter={(a, b) => a.W1_identify - b.W1_identify}
                  render={(value, record, index) => {
                    return <div>{record.W1_identify_name}</div>;
                  }}
                />
                <Table.Column
                  title="請　求　情　報"
                  render={(value, record) => {
                    return (
                      <div>
                        <div>{record.W1_address}</div>
                        <div>{record.W1_subject}</div>
                        <Row gutter={24}>
                          <Col span={2} style={{ textAlign: "center" }}>
                            <label>期間</label>
                          </Col>
                          <Col span={3.5}>
                            <span>{record.W1_billing_period_beginning}</span>
                          </Col>
                          ~
                          <Col span={3.5}>
                            <span>{record.W1_billing_period_final}</span>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <span>請求額</span>
                          </Col>
                          <Col span={2.5} style={{ textAlign: "right" }}>
                            <span>{record.W1_billing_amount}</span>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <span>入金合計</span>
                          </Col>
                          <Col span={2.5} style={{ textAlign: "right" }}>
                            <span>{record.W1_deposit_amount}</span>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}>
                            <span>請求番号</span>
                          </Col>
                          <Col span={2.5} style={{ textAlign: "right" }}>
                            <span>{record.W1_invoice_num}</span>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                />
                <Table.Column
                  width={50}
                  title={
                    <Button
                      size="small"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        this.AddNewData();
                      }}
                      disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                    ></Button>
                  }
                  render={(value, record, index) => {
                    return (
                      <Tooltip title="オプションメニュー" placement="topRight">
                        <Dropdown
                          className="option_btn_middle"
                          trigger="click"
                          size="small"
                          icon={<MoreOutlined />}
                          disabled={!(ProtectionFlag === 0 && ProcessDivision !== 4)}
                          overlay={() => {
                            return (
                              <Menu>
                                <Menu.Item
                                  key="2"
                                  style={{ width: "100%" }}
                                  onClick={() => this.billing_manage_num_delete(index)}
                                >
                                  削除
                                </Menu.Item>
                              </Menu>
                            );
                          }}
                        >
                          <Button
                            className="option_btn_middle"
                            size="small"
                            icon={<MoreOutlined />}
                          />
                        </Dropdown>
                      </Tooltip>
                    );
                  }}
                />
              </Table>
              <br />
              <hr style={{ border: "1px solid #F0F0F0", marginBottom: "1.2rem" }} />
              <Row gutter={24}>
                <Col span={5}>
                  <Form.Item name="TotalAmount" label="合計金額" {...gridSM}>
                    <InputNumber
                      maxLength={10}
                      min={0}
                      disabled
                      formatter={value => {
                        if (value == "null") {
                          return "";
                        } else if (value == "") {
                          return "";
                        } else if (value == 0) {
                          return "";
                        } else {
                          return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                      }}
                      onBlur={value => {
                        if (this.state.dataScreen.TotalAmount !== value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="Tax" label="税">
                    <InputNumber
                      maxLength={10}
                      min={0}
                      disabled={
                        !(
                          this.state.TaxCalculateUnit === 1 &&
                          this.state.TaxClassifyState == 0 &&
                          ProtectionFlag == 0 &&
                          ProcessDivision !== 4
                        ) || !(ProtectionFlag === 0 && ProcessDivision !== 4)
                      }
                      formatter={value =>
                        value == 0 ? "" : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      onBlur={value => {
                        if (this.state.dataScreen.Tax !== value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                      onChange={value => {
                        this.setState({ Tax: value });
                        this.changeAmount();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="AmountBilled" label="請求金額" {...gridSM}>
                    <InputNumber
                      maxLength={10}
                      min={0}
                      disabled
                      formatter={value =>
                        value == 0 ? "" : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      onBlur={value => {
                        if (this.state.dataScreen.AmountBilled !== value) {
                          this.changeScreenEditting(true);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item style={{ display: 'none' }} name="DepositAmount" label="入 金 額" {...gridSM}>
                    <InputNumber
                      disabled
                      maxLength={8}
                      formatter={value =>
                        value == 0 ? "" : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Card>
        <ModalDraggable
          width={this.state.childModal.width}
          visible={this.state.childModal.visible}
          component={this.state.childModal.component}
          bodyStyle={{ margin: 0, padding: 0 }}
          maskClosable={false}
          onCancel={() => {
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, alertReducer }) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(WS0961001_BillingIntegration);
