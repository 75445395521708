import { message } from 'antd'
import NormalValueSettingService from 'services/InspectionMaintenance/NormalValueSettingMaintain/NormalValueSettingService'
const NormalValueSettingAction = {
  GetPatterFormatData() {
    return NormalValueSettingService.GetPatterFormatData()
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  GetScreenData(values) {
    return NormalValueSettingService.GetScreenData(values)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  onSave(data) {
    return NormalValueSettingService.onSave(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  onDelete(data) {
    return NormalValueSettingService.onDelete(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  getSiteClassification(data) {
    return NormalValueSettingService.getSiteClassification(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },

  deleteRadioInfo(data) {
    return NormalValueSettingService.deleteRadioInfo(data)
      // return RadiographyJudgeMaintainService.deleteRadioInfo(data)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const res = err.response
        if (!res || !res.data || !res.data.message) {
          message.error('エラーが発生しました')
          return
        }
        message.error(res.data.message)
      })
  },



}
export default NormalValueSettingAction